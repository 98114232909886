import { Badge, Button, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  listNotifications,
  updateNotifications,
} from "../../services/notifications";
import { useBoolean, useClickAway } from "ahooks";

import { BellOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { StyledButton } from "../../shared/commonStyles";
import { getSurveyById } from "../../services/surveys";
import { getTaskDeploymentById } from "../../services/taskDeployment";
import { getTimeAgo } from "../../shared/utils";
import styles from "./NotificationsBox.module.scss";
import { useCheckRolesPermissions } from "./../../hooks/useCheckRolesPermissions";
import { useNavigate } from "react-router-dom";
import { encodeUrlName } from "../../shared/commonFunctions";

export const NotificationsBox = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [data, setData] = useState<Notification[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [
    isNotificationsBoxVisible,
    {
      setTrue: showNotificationsBox,
      setFalse: hideNotificationsBox,
      toggle: toggleNotificationsBox,
    },
  ] = useBoolean(false);
  const { checkRolesPermission } = useCheckRolesPermissions();

  useClickAway(() => {
    hideNotificationsBox();
  }, ref);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    const payload = {
      filter: {
        isRead: false,
      },
    };
    const list = await listNotifications(payload);

    setData(list);
  };
  const isUser =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:elevateduser"]);

  const handleOpen = async (item: Notification) => {
    const type = item.notificationType;
    if (type == "IMPORT_PENDING") {
      navigate(
        `/data/import-data/${item.itemId}/${encodeUrlName(
          item.itemName
        )}/review`,
        {
          state: { action: "review" },
        }
      );
    } else if (type == "SURVEY_PENDING") {
      navigate(
        `/data/data-sheets/${item.itemId}/${encodeUrlName(
          item.itemName
        )}/documents/pending`
      );
    } else if (type == "RECORD_DENIED") {
      const deployment = await getTaskDeploymentById(item.itemId);

      const survey =
        deployment && (await getSurveyById(deployment.surveyManagementID));

      if (survey) {
        if (survey.status === "ACTIVE") {
          navigate(
            `/management/collect-data/${survey?.id}/${encodeUrlName(
              survey?.name
            )}/view-survey`,
            {
              state: {
                taskDeploymentId: deployment?.id,
                metadataRecordId: deployment?.entityId,
              },
            }
          );
        } else {
          isUser
            ? message.error("There's no such active survey anymore.")
            : navigate(
                `/management/collect-data/${survey?.id}/${encodeUrlName(
                  survey?.name
                )}/view-survey`
              );
        }
      } else {
        isUser
          ? message.error("There's no such active survey anymore.")
          : navigate(`/management/collect-data`);
      }
    }
    const request_payload = {
      filter: {
        _id: { $in: [item._id.$oid] },
      },
      update_operations: {
        $set: {
          isRead: true,
        },
      },
    };

    setLoading(true);
    updateNotifications(request_payload).then(() => {
      getNotifications().then(() => setLoading(false));
    });

    hideNotificationsBox();
  };
  const handleAllAsRead = async () => {
    const request_payload = {
      filter: {
        recipientUsername: { $in: [data[0].recipientUsername] },
      },
      update_operations: {
        $set: {
          isRead: true,
        },
      },
    };
    setLoading(true);
    updateNotifications(request_payload).then(() => {
      getNotifications().then(() => setLoading(false));
    });
  };

  return (
    <>
      <>
        <Badge
          offset={[-15, 15]}
          dot={data?.some((v) => !v.isRead)}
          className={styles.notificationButton}
        >
          <Button
            className={styles.roundedButton}
            type="default"
            onClick={toggleNotificationsBox}
          >
            <BellOutlined />
          </Button>
        </Badge>
      </>
      {isNotificationsBoxVisible && (
        <div className={`purple-scroll ${styles.wrapper}`} ref={ref}>
          <div className={styles.title}>Notifications</div>
          <div className={styles.subtitle}>
            <div className={styles.subtitleLatest}>Latest</div>
            <Button
              type="link"
              onClick={() => handleAllAsRead()}
              className={styles.subtitleMarkRead}
            >
              Mark all as read
            </Button>
          </div>
          <Spin spinning={isLoading}>
            {data.map((item) => (
              <div
                key={item._id.$oid}
                className={`${styles.notificationBlock} ${
                  !item.isRead && styles.notificationBlockUnread
                }`}
              >
                <div className={styles.notificationBlockWrapper}>
                  {!item.isRead && <div className={styles.statusBadge} />}
                  <div className={styles.notificationBlockMessage}>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.message }}
                    ></div>
                    <p className={styles.notificationBlockTime}>
                      {getTimeAgo(item)}
                    </p>
                  </div>
                  <StyledButton
                    type="primary"
                    onClick={() => {
                      handleOpen(item);
                    }}
                  >
                    {(item.notificationType == "IMPORT_PENDING" &&
                      "Open data import") ||
                      (item.notificationType == "SURVEY_PENDING" &&
                        "Open data sheet") ||
                      (item.notificationType == "RECORD_DENIED" &&
                        "Open survey")}
                  </StyledButton>
                </div>
              </div>
            ))}
          </Spin>
        </div>
      )}
    </>
  );
};
