import React, { useEffect, useState } from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Spin, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { checkPermission } from "../../../services/permissions";
import { useBoolean } from "ahooks";
import SearchMetaEntityModal from "../../collectData/Components/SearchMetaEntityModal";
import { useGetMetadataUserSettings } from "../../../hooks/useGetMetadataUserSettings";
import { useChangeMetadataSettings } from "../../../hooks/useChangeMetadataSettings";
import { encodeUrlName } from "../../../shared/commonFunctions";

const { Title } = Typography;

const Success = ({ action, survey, step }) => {
  const [loading, setLoading] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);
  const [
    isMetaDataModalVisible,
    { setTrue: showMetaDataModal, setFalse: hideMetaDataModal },
  ] = useBoolean(false);
  const navigate = useNavigate();
  const userSettings = useGetMetadataUserSettings();
  const changeMetadataSettings = useChangeMetadataSettings();

  const getPermissions = async () => {
    const payload = {
      permissionType: "REFERENCE_DATA",
      itemId: survey.defaultMetaDataSheetId,
    };
    const permissions = await checkPermission(payload);
    setPermissionsData(permissions);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      await getPermissions();
    } catch (e) {
      message.error("Something went wrong", e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    survey.defaultMetaDataSheetId && loadData();
  }, [step]);

  const handleOpenSurvey = () => {
    survey.defaultMetaDataSheetId
      ? showMetaDataModal()
      : navigate(
          `/management/collect-data/${survey.id}/${encodeUrlName(
            survey.name
          )}/view-survey`
        );
  };
  const handleFinishMetaData = () => {
    hideMetaDataModal();
    navigate(
      `/management/collect-data/${survey.id}/${encodeUrlName(
        survey.name
      )}/view-survey`
    );
  };

  const handleChangeSettings = (value, searchEntityName) => {
    try {
      changeMetadataSettings(
        survey.defaultMetaDataSheetId,
        value,
        searchEntityName
      ).then(() => {
        handleFinishMetaData();
      });
    } catch (e) {
      message.error("Something went wrong", e);
    }
  };
  return (
    <>
      <FormWrap width="530px">
        <Spin spinning={loading}>
          <Row justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <CheckCircleOutlined style={{ fontSize: "40px" }} />
                <Title className="color-white" level={4}>
                  Survey was {action === "update" ? "updated" : "created"}{" "}
                  successfully!
                </Title>
                <p>
                  <span>
                    {" "}
                    Survey was {action === "update"
                      ? "updated"
                      : "created"}{" "}
                    successfully! It is already available in Collect Standards
                    Data section
                  </span>
                </p>
                <Row justify="space-between">
                  <Col>
                    <StyledButton type="custom" onClick={handleOpenSurvey}>
                      <span>Open Survey</span>
                    </StyledButton>
                  </Col>
                  <Col>
                    <StyledButton
                      type="custom"
                      onClick={() => navigate("/management/collect-data")}
                    >
                      <span>Go to All Surveys</span>
                    </StyledButton>
                  </Col>
                  <Col>
                    <StyledButton
                      type="custom"
                      onClick={() => {
                        navigate("/management/collect-data", {
                          state: { showModal: true },
                        });
                      }}
                    >
                      <span>Create Another Survey</span>
                    </StyledButton>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
      </FormWrap>
      {isMetaDataModalVisible && (
        <SearchMetaEntityModal
          userSettings={userSettings}
          permissions={permissionsData}
          rowData={survey}
          visible={isMetaDataModalVisible}
          onChangeSettings={handleChangeSettings}
          onClose={hideMetaDataModal}
        />
      )}
    </>
  );
};

export default Success;
