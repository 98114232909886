import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";
import { routes } from "../../index";
import {
  containsNumber,
  containsWhitespace,
} from "../../shared/commonFunctions.js";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSize } from "ahooks";
import styles from "./SiteLayout.module.scss";

const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const size = useSize(document.querySelector("body"));

  useEffect(() => {
    const pathParts = decodeURIComponent(location.pathname)
      .split("/")
      .filter((part) => part.trim() !== "")
      .map((part) => part.replace(/%2F/g, "/"));
    if (pathParts.length <= 1) {
      setBreadcrumbs([]);
      return;
    }
    const _breadcrumbs = [];
    for (let partIndex = 0; partIndex < pathParts.length; partIndex++) {
      const part = pathParts[partIndex];
      const previousParts = pathParts.slice(0, partIndex);
      const href =
        previousParts?.length > 0
          ? `/${previousParts?.join("/")}/${part}`
          : `/${part}`;
      let matched = {};
      const preDefined = routes.find((route) => {
        let routeSegments;
        if (route.path[0] === "/")
          routeSegments = route.path.substr(1).split("/");
        else routeSegments = route.path.split("/");

        const parts = [...previousParts, part];
        if (parts.length !== routeSegments.length) return false;

        let arrangeRoute = "",
          arrangeHref = "";
        for (let i = 0; i < routeSegments.length; i++) {
          if (routeSegments[i][0] !== ":") {
            arrangeRoute += `/${routeSegments[i]}`;
            arrangeHref += `/${parts[i]}`;
          } else {
            matched[routeSegments[i]] = parts[i];
          }
        }

        if (arrangeRoute === arrangeHref) {
          return true;
        } else {
          matched = {};
          return false;
        }
      });

      if (preDefined && preDefined.breadcrumb) {
        if (typeof preDefined.breadcrumb === "function") {
          preDefined.breadcrumb(matched).then((label) => {
            _breadcrumbs[partIndex] = {
              label,
              href: href == decodeURIComponent(location.pathname) ? null : href,
              state: location.state || null,
            };
            setBreadcrumbs(_breadcrumbs.concat());
          });

          _breadcrumbs.push({
            label: "",
            href: href == decodeURIComponent(location.pathname) ? null : href,
            state: location.state || null,
          });
        } else {
          _breadcrumbs.push({
            label: preDefined.breadcrumb,
            href: href == decodeURIComponent(location.pathname) ? null : href,
            state: location.state || null,
          });
        }
      } else {
        _breadcrumbs.push({
          label: part,
          href: href == decodeURIComponent(location.pathname) ? null : href,
          state: location.state || null,
        });
      }
    }
    if (
      _breadcrumbs?.find((v) => v.href?.includes("data-sheets")) &&
      _breadcrumbs?.find((v) => v.href?.includes("documents"))
    ) {
      const newHref = _breadcrumbs[3]?.href?.concat("/documents");
      const newBreadcrumbs = _breadcrumbs.map((v, index) => {
        return index === 3 ? { ...v, href: newHref } : v;
      });
      setBreadcrumbs(newBreadcrumbs);
    } else if (
      _breadcrumbs?.find((v) => v.href?.includes("collect-data")) &&
      _breadcrumbs?.find((v) => v.href?.includes("/deploy-survey"))
    ) {
      const newHref = _breadcrumbs[3]?.href?.concat("/deploy-survey");
      const newBreadcrumbs = _breadcrumbs.map((v, index) => {
        return index === 3 ? { ...v, href: newHref } : v;
      });
      setBreadcrumbs(newBreadcrumbs);
    } else setBreadcrumbs(_breadcrumbs);
  }, [location]);

  const checkIds = (label) => {
    try {
      const containNumber = containsNumber(label);
      const containSpace = containsWhitespace(label);
      const labelLengthGreater20 = label.length > 20;
      if (containNumber && !containSpace && labelLengthGreater20) {
        return true;
      }
    } catch (e) {
      return false;
    }
  };

  const ignoreHref = (href) => {
    try {
      const hrefArray = href.split("/");
      const surveyPage =
        !location.pathname.includes("add-users-manually") &&
        !location.pathname.includes("import-users") &&
        hrefArray?.find((item) => item === "collect-data");
      const standardPage = hrefArray?.find(
        (item) => item === "sustainability-standards"
      );
      const dataSheetPage =
        !location.pathname.includes("deleted") &&
        !location.pathname.includes("pending") &&
        !location.pathname.includes("denied") &&
        !location.pathname.includes("generate-report") &&
        !location.pathname.includes("update-records") &&
        hrefArray?.find((item) => item === "data-sheets");
      const kpiPage = hrefArray?.find((item) => item === "kpi");
      const standardAudit = hrefArray?.find((item) => item === "manage");
      const dataSheetAudit = hrefArray?.find(
        (item) => item === "audit-data-sheets"
      );
      const importPage = hrefArray?.find((item) => item === "import-data");
      const reportingPage = hrefArray?.find((item) => item === "saved-reports");
      const documentReviewPage = hrefArray?.find(
        (item) => item === "document-review"
      );
      const auditPage = hrefArray?.find((item) => item === "audit-standards");
      if (
        (surveyPage ||
          standardPage ||
          dataSheetPage ||
          kpiPage ||
          standardAudit ||
          dataSheetAudit ||
          importPage ||
          reportingPage ||
          documentReviewPage ||
          auditPage) &&
        hrefArray?.length > 3
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  };
  const previousBreadcrumb = breadcrumbs
    ?.filter((breadcrumb) => breadcrumb.href && ignoreHref(breadcrumb.href))
    ?.slice(-1)[0];
  return (
    <>
      {breadcrumbs?.length > 0 && (
        <Breadcrumb className={styles.breadcrumbsTag}>
          {size && size?.width <= 600 ? (
            <Breadcrumb.Item className={styles.breadcrumbMobile}>
              <Link
                to={previousBreadcrumb?.href}
                state={previousBreadcrumb?.state}
              >
                <ArrowLeftOutlined /> Back to {previousBreadcrumb?.label}
              </Link>
            </Breadcrumb.Item>
          ) : (
            <>
              {breadcrumbs.map((breadcrumb, index) => {
                if (!checkIds(breadcrumb.label)) {
                  if (ignoreHref(breadcrumb.href) && breadcrumb.href) {
                    return (
                      <Breadcrumb.Item key={index}>
                        <Link to={breadcrumb.href} state={breadcrumb.state}>
                          {breadcrumb.label}
                        </Link>
                      </Breadcrumb.Item>
                    );
                  } else {
                    return (
                      <Breadcrumb.Item key={index}>
                        {breadcrumb.label.concat(
                          decodeURIComponent(location.hash)
                        )}
                      </Breadcrumb.Item>
                    );
                  }
                }
              })}
            </>
          )}
        </Breadcrumb>
      )}
    </>
  );
};

export default Breadcrumbs;
