import { ArrowDownOutlined } from "@ant-design/icons";
import { Col, message, Row, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getUserGroups } from "../../../services/users";
import { getFirstAndLastNameByUsername } from "../../../shared/commonFunctions";
import {
  StyledButton,
  StyledTag,
  TaskDetailsWrap,
} from "../../../shared/commonStyles";
const { Title, Text } = Typography;
const AuditDetailCard = ({
  onTableRowClose,
  rowDataView,
  renderStatusTag,
  priorityTag,
  navigate,
  sendUserReminder,
  onChangeStatus,
  loading,
}) => {
  const [loader, setLoader] = useState(false);
  const [usersGroupList, setUsersGroupList] = useState([]);
  const [ownerName, setOwnerName] = useState([]);

  const loadUsersGroupData = async () => {
    try {
      setLoader(true);
      const userGroups = JSON.parse(rowDataView?.assigned_groups);
      if (userGroups?.length > 0) {
        const payload = {
          filter: { _id: { $oid: userGroups } },
        };
        const groups = await getUserGroups(payload);
        const data = groups.map((item) => {
          return { name: item.name || "" };
        });
        setUsersGroupList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadUsersGroupData();
  }, [rowDataView]);

  useEffect(() => {
    if (!rowDataView) return;
    const fetchOwnerGroup = async () => {
      const data = await getFirstAndLastNameByUsername(rowDataView?.owner);
      if (data) {
        setOwnerName(data);
      }
    };

    fetchOwnerGroup();
  }, [rowDataView]);

  return (
    <Spin spinning={loader}>
      <TaskDetailsWrap
        borderRadius="none"
        style={{
          lineHeight: "40px",
          backgroundColor: "#2D273F",
        }}
      >
        <Row justify="end">
          <span style={{ cursor: "pointer" }} onClick={onTableRowClose}>
            X
          </span>
        </Row>
        <Row className="margin-5">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Title level={4} title="Title">
              <strong>{rowDataView?.name}</strong>
            </Title>
          </Col>
        </Row>

        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Audit Type</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {rowDataView?.audit_type === "DATA_SHEETS"
              ? "Data Sheets"
              : rowDataView?.audit_type === "GHG_EMISSIONS"
              ? "GHG Emissions"
              : "Sustainability Standards"}
          </Col>
        </Row>
        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Assignee group</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {usersGroupList &&
              usersGroupList.map((item, index) => {
                return (
                  <StyledTag key={index} className="margin-5">
                    {item?.name}
                  </StyledTag>
                );
              })}
          </Col>
        </Row>
        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Owner group</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {ownerName}
          </Col>
        </Row>
        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Timeline</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {rowDataView?.start_date && rowDataView?.end_date
              ? `${rowDataView?.start_date} - ${rowDataView?.end_date}`
              : "NA"}
          </Col>
        </Row>
        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Priority</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {priorityTag(rowDataView?.priority)}
          </Col>
        </Row>
        <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Status</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {renderStatusTag(rowDataView?.status)}
          </Col>
        </Row>
        <Row
          className="margin-5"
          style={{ lineHeight: "20px", display: "flex" }}
        >
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Description</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Text type="secondary">{rowDataView?.description}</Text>
          </Col>
        </Row>
        {/* <Row className="margin-5" style={{ display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <p className="CustomTitle">Total (kg/CO2e)</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            25578.90
          </Col>
        </Row> */}

        {/* <Row className="margin-5" justify="space-center">
          <Col md={12} lg={12} style={{ display: "flex" }}>
            <StyledButton 
              bordercolor="#FFFFFF" 
              type="default" 
              onClick={()=> { 
                navigate(`/audit/manage/${rowDataView?.id}/update-audit`)
              }}
            >
              <span>Edit</span>
            </StyledButton>
            <StyledButton onClick={()=>{onChangeStatus(rowDataView)}} bordercolor="#FFFFFF" type="default">
              <span>Change status</span>
            </StyledButton>
            <StyledButton onClick={()=>{sendUserReminder(rowDataView)}} bordercolor="#FFFFFF" type="default">
              <span>Send reminder</span>
            </StyledButton>
          </Col>
        </Row> */}
      </TaskDetailsWrap>
    </Spin>
  );
};

export default AuditDetailCard;
