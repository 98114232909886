import {
  Alert,
  Col,
  Form,
  Progress,
  Row,
  Space,
  Spin,
  Steps,
  message,
} from "antd";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import {
  addReportingStandards,
  getReportingStandardsById,
  isJsonString,
  updateReportingStandards,
  getUniqueFrameworks
} from "../../services/standards";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";

const { Step } = Steps;

const CustomStandard = () => {
  const navigate = useNavigate();
  const { custom_standard_id: customStandardId } = useParams();
  const [formOption, setFormOption] = useState(false);
  const [step, setStep] = useState(1);
  const [schema, setSchema] = useState("{}");
  const [UISchema, setUISchema] = useState("{}");
  const [form] = Form.useForm();
  const [loadingCreateForm, setLoadingCreateForm] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [standardDataList, setStandardDataList] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [loader, setLoader] = useState(false);
  const [disableContinueToStep2, setDisableContinueToStep2] = useState(true);
  const [disableNewStandard, setDisableNewStandard] = useState(true);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    instructions: "",
    standard_code: "",
    standardsList: [
      {
        standardName: "",
        standardValue: "",
      },
    ],
    standardInfo: [
      {
        pageNumber: "",
        standardUrl: "",
        name: "",
      },
    ],
  });
  useEffect(() => {
    if (rowData) {
      setLoader(true);

      let standardsList = [];

      const standardInfo = [];
      if (rowData?.resource_urls) {
        const resourceUrls =
          rowData.resource_urls instanceof Array
            ? rowData.resource_urls
            : JSON.parse(rowData?.resource_urls);
        resourceUrls?.forEach((item) => {
          const standardInfoData = {
            name: item.name,
            standardUrl: item.url,
            pageNumber: item.page_num,
          };
          if (standardInfoData) standardInfo.push(standardInfoData);
        });
      }



      if(rowData?.tags) {
        standardsList = Object.entries(rowData.tags).map(([key, value]) => ({
          standardName: key,
          standardValue: value
        }));
      }


      const initData = {
        name: rowData?.name ? rowData.name : "",
        description: rowData?.description ? rowData?.description : "",
        instructions: rowData?.question_field ? rowData?.question_field : "",
        standard_code: rowData?.standard_code
          ? rowData.standard_code
          : "",
        standardsList: standardsList,
        standardInfo: standardInfo,
      };
      form.setFieldsValue(initData);
      setSelectedStandard(rowData?.frameworks)
      setInitialValues(initData);
      setLoader(false);
    }
  }, [rowData]);

  const loadData = async () => {
    setLoader(true);
    const response = await getReportingStandardsById(customStandardId);
    setRowData(response);
    setLoader(false);
  };

  const getFrameworkList = async () => {
    const result = await getUniqueFrameworks();
    const frameworksArray = result
      .slice(1, -1)
      .split(",")
      .map((item) => item.trim());
    const listValue = frameworksArray.map((item) => {
      return item;
    });
    setStandardDataList(listValue);
  }

  useEffect(() => {
    if (customStandardId) {
      loadData();
    }
  }, [customStandardId]);

  useEffect(() => {
    getFrameworkList();
  }, []);

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onChangeStep = async (selectedStep) => {
    try {
      await form.validateFields();
      if (
        initialValues.name &&
        initialValues.description &&
        initialValues.instructions
      ) {
        setStep(selectedStep + 1);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const onClickPrevious = async () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (formOption) {
      onSubmitHandler();
    }
  }, [formOption]);

  const onSubmitHandler = async (dataToSubmit) => {
    if (formOption) {
      message.error("Please enter some custom form data!!!");
      return false;
    }
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateForm(true);
          // We need to put in the group from the Cognito user session
          const {
            name,
            description,
            standard_code,
            standardsList,
            standardInfo,
          } = initialValues;
          // return ;

          const standard = [];
          const resourceUrlsData = [];
          let themeValue = "";
          let categoryValue = "";
          let industryValue = "";


          if (standardsList && standardsList.length) {
            standardsList.forEach((item) => {
              const standardDataName = item?.standardName || "";
              const standardDataValue = item?.standardValue || "";
              const standardData = {
                standardName: standardDataName,
                standardValue: standardDataValue,
              };
              standard.push(standardData);
            });
 
            const theme = standardsList
              .filter((item) => item.standardName.toLowerCase() === "theme")
              .map((val) => val.standardValue);
            themeValue = theme.toString();
            const category = standardsList
              .filter((item) => item.standardName.toLowerCase() === "category")
              .map((val) => val.standardValue);
            categoryValue = category.toString();
            const industry = standardsList
              .filter((item) => item.standardName.toLowerCase() === "industry")
              .map((val) => val.standardValue);
            industryValue = industry.toString();
          } else {
            const standardData = {
              standardName: "",
              standardValue: "",
            };
            standard.push(standardData);
          }
          if (standardInfo && standardInfo.length) {
            standardInfo.forEach((item) => {
              const PageNumberData = item?.pageNumber || "";
              const standardUrlData = item?.standardUrl || "";
              const name = item?.name || "";

              const resourceUrls = {
                url: standardUrlData,
                page_num: PageNumberData,
                name: name,
              };
              resourceUrlsData.push(resourceUrls);
            });
          } else {
            const resourceUrls = {
              url: "",
              page_num: "",
              name: "",
            };
            resourceUrlsData.push(resourceUrls);
          }

          const tags = standardsList.reduce((obj, item) => {
              obj[item.standardName] = item.standardValue;
              return obj;
          }, {});
          let payload;
          payload = {
            tags: tags,
            name: name,
            description: description,
            standard_code: standard_code,
            status: "ACTIVE",
            resource_urls: resourceUrlsData,
          };
          if (selectedStandard && selectedStandard.length > 0) {
            payload = {
              tags: tags,
              name: name,
              description: description,
              standard_code: standard_code,
              status: "ACTIVE",
              resource_urls: resourceUrlsData,
              frameworks: selectedStandard
            };
          }
          let submitResponse = null;
          if (customStandardId) {
            payload._id = rowData._id;
            submitResponse = await updateReportingStandards(payload);
          } else {
            submitResponse = await addReportingStandards(payload);
          }

          if (submitResponse) {
            setLoadingCreateForm(false);
            form.resetFields();
            setStep(step + 1);
          } else {
            message.error("Error while creating data model. contact support.");
          }
        } catch (error) {
          message.error("Error while creating custom standard.");
          console.log("error while creating custom standard", error);
        } finally {
          setLoadingCreateForm(false);
        }
      })
      .catch((error) => {
        message.error(`Error while creating custom standard ${error}.`);
      });
  };

  // store data in state when form data change
  const onChangeFieldsData = (data) => {
    let newInitialValues = {};
    Object.keys(initialValues)?.forEach((key, index) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = initialValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem = { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };
            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          //const items  = initialValues[key]
          newInitialValues = { ...newInitialValues, [key]: initialValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const onCancelClick = () => { };

  const onChangeStandards = (value) => {
    setSelectedStandard(value)
  }

  useEffect(() => {
    if (
      (initialValues.name || rowData?.name) &&
      (initialValues.description || rowData?.description)
    ) {
      setDisableContinueToStep2(false);
    }
    if (
      initialValues.name &&
      initialValues.description &&
      initialValues.standard_code
    ) {
      setDisableNewStandard(false);
    }
  }, [initialValues]);

  const showSectionsAccordingSteps = (step, standardDataList, selectedStandard) => {
    if (step == 1) {
      return (
        <Step1
          onCancelClick={onCancelClick}
          disable={disableContinueToStep2}
          onClickNext={onClickNext}
        />
      );
    } else if (step === 2) {
      return (
        <Step2
          disable={disableNewStandard}
          onClickNext={onSubmitHandler}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          onClickPrevious={onClickPrevious}
          loadingCreateForm={loadingCreateForm}
          standardDataList={standardDataList}
          selectedStandard={selectedStandard}
          onChangeStandards={onChangeStandards}
        />
      );
    } else if (step === 3) {
      return <Step4 rowData={rowData} />;
    }
  };
  const styledStepStyle = {
    display: "block",
    width: "85%",
    textAlign: "center",
  };
  return (
    <PageWrap>
      {rowData?.status == "INACTIVE" ? (
        <Alert
          message="Sorry, you cannot access this archived custom standard!"
          type="info"
          showIcon
        />
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Spin spinning={loader}>
            <Row gutter={[16, 16]} className="exitAlign">
              <Col lg={1} md={24} sm={24} xs={24}>
                <StyledButton
                  type="custom"
                  hovercolor="#A68DFB"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#fff"
                  onClick={() => navigate("/reporting/custom-standard")}
                >
                  <ArrowLeftOutlined /> <span>Exit</span>
                </StyledButton>
              </Col>
              <Col
                className="user-flow-steps-wrap color-white"
                lg={23}
                md={24}
                sm={24}
                xs={24}
              >
                <StyledSteps
                  current={step - 1}
                  onChange={onChangeStep}
                  style={styledStepStyle}
                >
                  <Step
                    className="stepper-wrapper "
                    key={1}
                    title={<span>Standard description</span>}
                  />
                  <Step
                    className="stepper-wrapper "
                    key={2}
                    title={<span>Standard details</span>}
                  />
                </StyledSteps>
              </Col>
            </Row>
            <Progress
              percent={step * 50}
              strokeColor={"#A68DFB"}
              showInfo={false}
            />
            <Row>
              <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
                <div className="form-wrap">
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={(data) => onChangeFieldsData(data)}
                    onFinish={(data) => onSubmitHandler(data)}
                  >
                    {showSectionsAccordingSteps(step, standardDataList, selectedStandard)}
                  </Form>
                </div>
              </Col>
            </Row>
          </Spin>
        </Space>
      )}
    </PageWrap>
  );
};

export default CustomStandard;
