import React, { useEffect, useState } from "react";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
} from "../../../shared/commonStyles";

import { Form, Row, Col, Typography, Select, message } from "antd";

import { DataSheetDocuments } from "./DataSheetDocuments";
import {
  aggregateRecords,
  convertFiltersList,
  countMissingFields,
} from "../../../services/dataSheet";
import { WarningOutlined } from "@ant-design/icons";
import { aggregationOperators } from "../../../services/mongoOperators";
const { Title } = Typography;

const Step2 = ({
  onClickPrevious,
  initialValues,
  onClickNext,
  dataSheet,
  resetInput,
  loadingDataSheet,
  setAnalyticData,
  setHasMissingFields,
}) => {
  const [activityFields, setActivityFields] = useState([]);
  const [filterOperations, setFilterOperations] =
    useState(aggregationOperators);
  const [aggregationInfo, setAggregationInfo] = useState({
    schemaName: "",
    operationLabel: "",
    aggregationResult: "",
  });
  const [missingFieldsAmount, setMissingFieldsAmount] = useState(0);

  useEffect(() => {
    dataSheet && getAnalyticData(initialValues.aggregate[0].op);
  }, [initialValues?.aggregate[0]?.column, initialValues?.aggregate[0]?.op]);

  useEffect(() => {
    try {
      const schemasArray = [];
      if (dataSheet?.sheet_schema?.length) {
        dataSheet.sheet_schema.forEach((schema) => {
          schemasArray.push({
            value: schema.entity_name,
            label: schema.display_name,
          });
        });
      }
      setActivityFields(schemasArray);
    } catch (error) {
      console.log("Something went wrong!", error);
      message.error("Something went wrong!");
    }
  }, [dataSheet]);

  const getAnalyticData = () => {
    if (initialValues?.aggregate[0]?.column && initialValues.aggregate[0].op) {
      const schemaData = dataSheet?.sheet_schema.find(
        (schema) => schema.entity_name === initialValues?.aggregate[0]?.column
      );
      const operationData = aggregationOperators.find(
        (operation) => operation.value === initialValues.aggregate[0].op
      );

      const aggregationField = {
        [`${initialValues?.aggregate[0]?.column}`]: [
          initialValues.aggregate[0].op,
        ],
      };

      const conditionalFilters = initialValues?.conditional_filters?.length
        ? initialValues?.conditional_filters
        : initialValues?.filters?.length
        ? convertFiltersList(initialValues?.filters, dataSheet)
        : [];

      aggregateRecords(
        dataSheet?._id?.$oid,
        dataSheet.sheet_name,
        conditionalFilters,
        aggregationField
      ).then((aggregationResponse) => {
        const aggregationResult = aggregationResponse?.length
          ? aggregationResponse?.[0]?.[
              `${initialValues?.aggregate[0]?.column}`
            ]?.[0]?.value
          : "NA";
        setAggregationInfo({
          schemaName: schemaData?.display_name,
          operationLabel: operationData.label,
          aggregationResult: aggregationResult,
        });
        setAnalyticData({
          [`${initialValues?.aggregate[0]?.column}_${initialValues.aggregate[0].op}`]:
            aggregationResult,
        });
      });

      const fields = [`${initialValues?.aggregate[0]?.column}`];

      countMissingFields(
        dataSheet._id.$oid,
        dataSheet.sheet_name,
        conditionalFilters,
        fields
      ).then((result) => {
        setMissingFieldsAmount(
          result[`${initialValues?.aggregate[0]?.column}`] || 0
        );
        result[`${initialValues?.aggregate[0]?.column}`] > 0 &&
          setHasMissingFields(true);
      });
    }
  };

  const handleChangeField = (value, index) => {
    resetInput(index);
    setAggregationInfo({
      schemaName: "",
      operationLabel: "",
      aggregationResult: "",
    });
    const columnType = dataSheet?.sheet_schema.find(
      (schema) => schema.entity_name === value
    )?.data_type;
    if (columnType !== "number") {
      const options = aggregationOperators.filter(
        (v) => v.value === "count" || v.value === "distinct"
      );
      setFilterOperations(options);
    } else setFilterOperations(aggregationOperators);
  };
  return (
    <FormWrap width={"100%"}>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col
            span={initialValues.sheetId ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Title level={3}>Analytics Aggregation</Title>
              <p className="color-grey">
                Select the field and the operation you want to perform on it
              </p>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.List name="aggregate" key={"aggregate"}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row key={key} gutter={[16]}>
                            <Col span={16}>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                label="DataSheet Field"
                                name={[name, "column"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Datasheet field name"
                                  options={activityFields}
                                  onChange={(v) => handleChangeField(v, index)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                label="Aggregation operators"
                                name={[name, "op"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select aggregation operators"
                                  options={filterOperations}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
                {aggregationInfo.aggregationResult !== "" && (
                  <Col>
                    <Title level={4}>
                      {`${aggregationInfo.schemaName} ${aggregationInfo.operationLabel}`}{" "}
                      : {aggregationInfo.aggregationResult}
                    </Title>
                  </Col>
                )}
                {missingFieldsAmount != 0 && (
                  <Col span={24}>
                    <div className="missingDataMessage">
                      <div>
                        <WarningOutlined />
                      </div>
                      <div>
                        {missingFieldsAmount}{" "}
                        {missingFieldsAmount === 1
                          ? "record is"
                          : "records are"}{" "}
                        missing. Review the data sheet to increase the accuracy
                        of the calculation
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div>
              <Col span={24}>
                <Form.Item>
                  <StyledButton onClick={() => onClickPrevious()} type="custom">
                    <span>Back</span>
                  </StyledButton>
                  <StyledButton onClick={() => onClickNext()} type="custom">
                    <span>Save KPI</span>
                  </StyledButton>
                </Form.Item>
              </Col>
            </div>
          </Col>
          {initialValues.sheetId && (
            <Col span={12}>
              <DataSheetDocuments
                loadingDataSheet={loadingDataSheet}
                dataSheet={dataSheet}
                initialValues={initialValues}
              />
            </Col>
          )}
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step2;
