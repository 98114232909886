import React from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  Label,
  Tooltip as Tooltips,
  Legend as Legends,
  CommonSeriesSettings,
  SeriesTemplate,
} from "devextreme-react/chart";

export const MyResponsiveBar = ({ data /* see data tab */ }) => {
  function customizeTooltip(e) {
    return {
      text: `${e.argumentText}  ${e.valueText}% `,
    };
  }

  const customizeSeries = (index) => {
    return { color: fetchColor(index), barWidth: 200, width: 200 };
  };

  function customizePercentageText({ valueText }) {
    return `${valueText}`;
  }

  const fetchColor = (index) => {
    const item = data.find((item) => item.name === index);
    if (item !== undefined) {
      return item.color;
    }
    return "#5168F4";
  };

  return (
    <>
      <div className="chart-inner-box">
        <Chart
          id="charts"
          className="line-chart"
          dataSource={data}
          height={400}
        >
          <CommonSeriesSettings
            type="stackedbar"
            argumentField="name"
            valueField="value"
            barPadding={0.7}
          >
          </CommonSeriesSettings>
          <Tooltips enabled={true} customizeTooltip={customizeTooltip} />
          <Legends
            verticalAlignment="top"
            horizontalAlignment="center"
            visible={false}
          />
          <SeriesTemplate nameField="name" customizeSeries={customizeSeries} />
          <ArgumentAxis />
          <ValueAxis
            name="percentage"
            position="left"
            tickInterval={20}
            showZero={true}
            valueMarginsEnabled={false}
          >
            <Label customizeText={customizePercentageText} />
          </ValueAxis>
        </Chart>
      </div>
    </>
  );
};
