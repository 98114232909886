import React, { useState } from "react";
import { Form, Row, Typography, Divider } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "./../../../../../shared/commonStyles";
import { ConditionalDataSheetFilters } from "../../../../../components/ConditionalDataSheetFilters/ConditionalDataSheetFilters";

const { Title } = Typography;

export const FiltersModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
  initialValues,
  onReset,
}) => {
  const [submiting] = useState(false);
  const [form] = Form.useForm();

  const onFinishHandler = (data) => {
    onFinish(data);
    onClose();
  };

  const onClickReset = () => {
    onReset();
  };

  const handleChangeFilters = (filters) => {
    form.setFieldsValue({ conditional_filters: filters });
  };

  return (
    <CommonModal
      title={"Filter Records"}
      visible={visible}
      onCancel={onClose}
      width={800}
      okText="Apply"
      onOk={onFinishHandler}
      footer={null}
      maskClosable={false}
      confirmLoading={submiting}
      destroyOnClose
      centered
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.Item label="">
            <Title level={4}>Filter Audit Datasheet fields</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <ConditionalDataSheetFilters
              initialFilters={initialValues.conditional_filters}
              dataSheet={dataSheet}
              addButtonText="Add datasheet filters"
              onChangeFilters={handleChangeFilters}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <StyledButton
                type="default"
                style={{ padding: "4px 15px" }}
                onClick={onClickReset}
              >
                Reset
              </StyledButton>
              <StyledButton type="custom" htmlType="submit">
                Apply
              </StyledButton>
            </Row>
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
