import React from 'react';
import {  Input, Form } from 'antd';
import {  CommonModal, FormWrapper } from '../../../shared/commonStyles';

const { TextArea } = Input;

const ConfirmModal = ({ visible, onSubmit, onCancel, submitting }) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then(values => {
        onSubmit(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <CommonModal
      title="Confirmation"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={submitting}
    >
      <FormWrapper>
          <Form
            layout="vertical"
            form={form}
          >
            <Form.Item
              name="editReason"
              label="Please enter the reason for your modification: "
              rules={[{ required: true, message: 'Please enter the reason for your modification' }]}
            >
              <TextArea showCount maxLength={100} />
            </Form.Item>
          </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default ConfirmModal;
