import React, { useState, useEffect } from "react";
import { PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Typography, Row, message, Col, Spin, Tooltip } from "antd";
import { AggregateAnalyticsModal } from "../../../../components/DataSheetDocuments/AggregateAnalyticsModal";
import {
  getLastAggregateAnalytics,
  addLastAnalyticsRecord,
} from "../../../../services/dataSheet";
import {
  StyledButton,
  TableWrap,
  TableHeaderRightButtonWrap,
} from "../../../../shared/commonStyles";
import { aggregationOperators } from "../../../../services/mongoOperators";

const { Title } = Typography;

const DataSheetAggregateAnalytics = ({
  dataSheet,
  filteringMatch,
  refreshDataSheetRecords,
  decimalPlacesNumber,
}) => {
  const [showAddAggregationModal, setShowAddAggregationModal] = useState(false);
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const [aggregateData, setAggregateData] = useState(null);

  const loadAggregateResults = async () => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = {
        sheet_id: dataSheet._id.$oid,
        sheet_name: dataSheet.sheet_name,
        conditional_filters: filteringMatch.conditional_filters,
        ...(filteringMatch.filter && { filters: filteringMatch.filter }),
        record_types: filteringMatch.record_types,
      };
      const res = await getLastAggregateAnalytics(requestPayload);
      setAggregateData(res);
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading analytic records!");
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  useEffect(() => {
    loadAggregateResults();
  }, [filteringMatch, refreshDataSheetRecords]);

  const addMakeQuery = (params) => {
    const result = [];
    if (params) {
      params.aggregate.forEach((item) => {
        if (item.column) {
          const sameColumnIndex = result.findIndex((v) => {
            return (
              Object.keys(v)[0] === `${dataSheet?.sheet_name}.${item.column}`
            );
          });
          if (sameColumnIndex >= 0) {
            Array.isArray(item.operator)
              ? result[sameColumnIndex]?.[
                  `${dataSheet?.sheet_name}.${item.column}`
                ].push(...item.operator)
              : result[sameColumnIndex]?.[
                  `${dataSheet?.sheet_name}.${item.column}`
                ].push(item.operator);
          } else {
            result.push({
              [`${dataSheet?.sheet_name}.${item.column}`]: [
                item.operator,
              ].flat(),
            });
          }
        }
      });
    }

    return {
      request_details: result,
      sheet_id: dataSheet._id.$oid,
    };
  };

  const addAggregateData = async (params) => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = addMakeQuery(params);
      await addLastAnalyticsRecord(requestPayload);
      loadAggregateResults();
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading data sheet records!");
    }
  };

  return (
    <>
      {dataSheet && (
        <>
          {showAddAggregationModal && (
            <AggregateAnalyticsModal
              initialValues={aggregateData}
              visible={showAddAggregationModal}
              onClose={() => setShowAddAggregationModal(false)}
              onFinish={addAggregateData}
              dataSheet={dataSheet}
              mongoOperations={aggregationOperators}
            />
          )}

          <TableWrap style={{ marginTop: 0 }}>
            <Row style={{ marginBottom: "15px" }}>
              <Col
                span={24}
                style={{
                  backgroundColor: "#2D273F",
                  padding: "15px",
                  borderLeft: "4px solid #FC7786",
                }}
              >
                <Title className="color-white" level={3}>
                  Aggregate Analytics{" "}
                  <Tooltip title="Calculate and save aggregations for the numeric fields of the data sheet">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Title>
              </Col>
            </Row>
            <Spin spinning={loadingSheetRecords}>
              {dataSheet && (
                <>
                  {aggregateData?.map((data, index) => {
                    const schemaData = dataSheet?.sheet_schema.find(
                      (schema) => schema.entity_name === Object.keys(data)[0]
                    );

                    return (
                      <React.Fragment key={index}>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              backgroundColor: "#423A5A",
                              padding: "8px 15px",
                            }}
                          >
                            <Title className="color-white" level={4}>
                              {schemaData?.display_name}
                            </Title>
                          </Col>
                        </Row>
                        <Row style={{ width: "100%", marginBottom: "15px" }}>
                          {data[Object.keys(data)[0]].map((col, opIndex) => {
                            const colWidth = (
                              100 / data[Object.keys(data)[0]].length
                            ).toFixed(2);
                            const operationData = aggregationOperators.find(
                              (operation) => operation.value === col.operator
                            );
                            return (
                              <Col
                                style={{ width: `${colWidth}%` }}
                                key={opIndex}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#5C5179",
                                    padding: "2px 15px",
                                  }}
                                >
                                  <Title className="color-white" level={5}>
                                    {operationData.label}
                                  </Title>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#2D273F",
                                    padding: "10px 15px",
                                    color: "#fff",
                                  }}
                                >
                                  {+col.value.toFixed(decimalPlacesNumber)}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </Spin>
            <TableHeaderRightButtonWrap>
              <StyledButton
                type="custom"
                icon={<PlusCircleOutlined />}
                onClick={() => setShowAddAggregationModal(true)}
              >
                Add new calculation
              </StyledButton>
            </TableHeaderRightButtonWrap>
          </TableWrap>
        </>
      )}
    </>
  );
};

export default DataSheetAggregateAnalytics;
