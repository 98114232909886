import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Row, Col, Typography } from "antd";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";

const { Title } = Typography;

const Step3 = ({ onClickCreateKpi, loadingCreateKpi }) => {
  const navigate = useNavigate();
  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>
              Congratulations! KPI was created successfully
            </Title>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input KPI name!" }]}
            >
              <Input placeholder="Type in a name for the KPI" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please input KPI description!",
                },
              ]}
            >
              <Input.TextArea placeholder="Provide a short description of what kind of data will be collected in this KPI" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <StyledButton onClick={() => navigate(-1)} type="custom">
                <span>Cancel</span>
              </StyledButton>
              <StyledButton
                onClick={onClickCreateKpi}
                type="custom"
                loading={loadingCreateKpi}
              >
                <span>Finish</span>
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step3;
