import React from "react";
import { CommonModal, StyledButton } from "../../../shared/commonStyles";

interface SubmitConfirmationModalProps {
  visible: boolean;
  onClose: () => void;
  onExit: () => void;
  onComplete: () => void;
}

export const SubmitConfirmationModal = ({
  visible,
  onClose,
  onExit,
  onComplete,
}: SubmitConfirmationModalProps) => {
  return (
    <>
      <CommonModal
        title={"Submit confirmation"}
        visible={visible}
        onCancel={onClose}
        width={600}
        destroyOnClose
        centered
        footer={
          <>
            <StyledButton
              className="margin-5"
              type="custom"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              onClick={() => {
                onExit();
                onClose();
              }}
            >
              <span>Exit</span>
            </StyledButton>
            <StyledButton
              type="custom"
              htmlType="submit"
              onClick={() => {
                onComplete();
                onClose();
              }}
            >
              Submit Survey
            </StyledButton>

            <StyledButton
              className="margin-5"
              type="custom"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              onClick={() => {
                onExit();
                onClose();
              }}
            >
              <span>Save and Exit</span>
            </StyledButton>
          </>
        }
      >
        <p>
          Are you sure you wish to submit this survey? The data will be sent for
          administrative review. If your response is incomplete and you intend
          to return to this survey later, you should save and exit instead
        </p>
      </CommonModal>
    </>
  );
};
