import {
  EllipsisOutlined,
  EyeOutlined,
  FilterOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Col,
  message,
  Pagination,
  Typography,
  Row,
  Tag,
  Select,
  Spin,
  Dropdown,
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardsWrap,
  CommonSelect,
  FiltersContainer,
  FormWrapper,
  PageWrap,
  StyledButton,
  TableCommonMenu,
} from "../../shared/commonStyles";
import { SortDropdown } from "../../components/SortDropdown";
import { FilterDropdown } from "../../components/FilterDropdown";
import {
  getDatasheetFilters,
  getDataSheetsWithPagination,
} from "../../services/dataSheet";
import { encodeUrlName, stripHtmlTags } from "../../shared/commonFunctions";
import { getKpiMetric } from "../../services/kpi";
import AssignPermissionsModal from "../dataSheetsPage/Components/AssignPermissionsModal/AssignPermissionsModal";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";

const { Title } = Typography;
const { Option } = Select;

const KpiData = () => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const sortOptions = [
    { name: "Name", value: "sheet_name" },
    { name: "Type", value: "sheet_type" },
  ];

  const filterOptions = [
    { name: "Name", value: "sheet_name" },
    /*{ name: "Description", value: "sheet_desc" },*/
  ];
  const regexFiltersNames = ["sheet_name"];
  const [loadingDataSheetsList, setLoadingDataSheetsList] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 12 });
  const [externalTableFilters, setExternalTableFilters] = useState({
    sheet_name: [],
    sheet_type: [],
    description: [],
  });
  const [sheetTypesList, setSheetTypesList] = useState([]);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortOffsets, setSortOffsets] = useState({
    type: "key",
    sortAlphabetically: true,
  });

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [filterOffsets, setFilterOffsets] = useState({ filters: [] });

  const [filteredDataSheetsList, setFilteredDataSheetsList] = useState([]);
  const [showAssignPermissionsModal, setShowAssignPermissionsModal] =
    useState(false);
  const [rowData, setRowData] = useState(null);
  const navigate = useNavigate();

  const makeQuery = (params) => {
    const { pagination: _pagination } = params;
    let filter = {
      ...externalTableFilters,
      is_reference_data: false,
    };
    let regexFilters = {};
    const sortingData = {
      [sortOffsets.type === "key" ? "_id" : sortOffsets.type]:
        sortOffsets.sortAlphabetically ? 1 : -1,
    };

    filterOffsets?.filters.forEach((item) => {
      if (regexFiltersNames.includes(item.type)) {
        regexFilters = { ...regexFilters, [item.type]: item.value };
      } else if (item.type !== "key") {
        filter = { ...filter, [item.type]: [item.value] };
      }
    });

    return {
      skip: (_pagination.current - 1) * _pagination.pageSize,
      limit: _pagination.pageSize,
      filter: filter,
      page_type: "kpi",
      sort: sortingData,
      regexFilters,
    };
  };

  const loadDataSheets = async (params) => {
    setLoadingDataSheetsList(true);
    try {
      const requestPayload = makeQuery(params);
      const { pagination } = params;
      const datasheets = await getDataSheetsWithPagination(requestPayload);
      setPagination({ ...pagination, total: datasheets.filteredCount });
      const datasheetIds = datasheets.data.map((item) => item._id.$oid);
      const payload = { filter: { sheetId: { $in: datasheetIds } } };
      const kpiForDatasheets = await getKpiMetric(payload);
      const datasheetsWithKpi = datasheets.data.map((item) => ({
        ...item,
        key: item._id.$oid,
        kpiData: kpiForDatasheets.filter(
          (kpiData) => kpiData.sheetId == item._id.$oid
        ),
      }));
      setFilteredDataSheetsList(datasheetsWithKpi);
    } catch (error) {
      message.error("Something went wrong while loading data sheets!");
      console.log("Something went wrong while loading data sheets!");
    } finally {
      setLoadingDataSheetsList(false);
    }
  };

  const loadSheetType = async () => {
    try {
      const dataSheetList = await getDatasheetFilters({ page_type: "kpi" });
      if (dataSheetList?.sheet_type?.regular?.length) {
        setSheetTypesList(dataSheetList?.sheet_type.regular);
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    loadSheetType();
  }, []);

  useEffect(() => {
    loadDataSheets({ pagination: { ...pagination, current: 1 } });
  }, [filterOffsets, externalTableFilters]);

  const onShowSizeChange = (current, pageSize) => {
    const newPagination = { ...pagination, current, pageSize };
    setPagination(newPagination);
    loadDataSheets({ pagination: newPagination });
  };

  const onChangeExternalFilters = (data, filterName) => {
    try {
      setPagination({ ...pagination, current: 1 });
      setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
    } catch (error) {
      console.log("Error while filter carbon table", error);
    }
  };
  const onClickViewKpi = (rowData) => {
    const sheet_id = rowData?.["_id"]?.["$oid"];
    if (sheet_id) {
      navigate(
        `/data/kpi/${sheet_id}/${encodeUrlName(
          rowData?.sheet_name.replace("/", " ")
        )}/details`
      );
    }
  };

  const handleSorting = () => {
    loadDataSheets({ pagination });
  };

  const handleFiltering = (data) => {
    setFilterOffsets(data);
  };
  const resetFiltering = () => {
    setFilterOffsets({ filters: [] });
  };

  const fillColor = (record) => {
    let tagColoris = "#7D89B0";
    if (record === "Energy Usage") {
      tagColoris = "#E69B56";
    }
    if (record === "Water Usage") {
      tagColoris = "#5690E6";
    }
    if (
      record === "Metadata" ||
      record === "Real Estate List" ||
      record === "Supplier List" ||
      record === "Portfolio Company List"
    ) {
      tagColoris = "#A68DFB";
    }
    if (record === "Waste") {
      tagColoris = "#3DA48B";
    }
    return (
      <Tag color={tagColoris} className="tag-rounded">
        {record}
      </Tag>
    );
  };
  const { sheet_type } = externalTableFilters;
  /*const handleSortOffsets = () => {
    setScopeData((scopeData: Offset[]) => {
      const newData = scopeData.slice().sort((a: Offset, b: Offset) => {
        const aStatement = a[sortOffsets.type as keyof Offset] || "";
        const bStatement = b[sortOffsets.type as keyof Offset] || "";
        if (sortOffsets.sortAlphabetically) {
          return aStatement > bStatement ? 1 : -1;
        } else {
          return aStatement < bStatement ? 1 : -1;
        }
      });
      return [...newData];
    });
  };*/

  const onClickAssignPermissions = (record) => {
    console.log("record", record);
    setRowData(record);
    setShowAssignPermissionsModal(true);
  };

  return (
    <Spin spinning={loadingDataSheetsList}>
      {showAssignPermissionsModal && (
        <AssignPermissionsModal
          visible={showAssignPermissionsModal}
          onClose={() => {
            loadDataSheets({ pagination });
            setRowData(null);
            setShowAssignPermissionsModal(false);
          }}
          permissionsType={"kpi"}
          rowData={rowData}
        />
      )}
      <PageWrap>
        <FiltersContainer>
          <FormWrapper>
            <Row justify="start" gutter={[8, 8]}>
              <Col span={4} style={{ alignSelf: "center" }}>
                <CommonSelect
                  mode="multiple"
                  allowClear
                  showArrow
                  style={{ width: "100%" }}
                  placeholder="All sheet types"
                  value={sheet_type}
                  onChange={(data) =>
                    onChangeExternalFilters(data, "sheet_type")
                  }
                  options={sheetTypesList?.map((item) => ({
                    label: item,
                    value: item,
                    type: "regular",
                  }))}
                />
              </Col>
              <Col span={20} style={{ textAlign: "end", paddingRight: "15px" }}>
                <StyledButton
                  className="margin-5"
                  type="transparent"
                  bgcolor="#1d1729"
                  bordercolor="#ffffff"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Dropdown
                    overlay={
                      <SortDropdown
                        sorting={sortOffsets}
                        changeSorting={setSortOffsets}
                        options={sortOptions}
                        onConfirm={() => {
                          handleSorting();
                          setShowSortPopup(false);
                        }}
                      />
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                    onVisibleChange={(open) => {
                      setShowSortPopup(open);
                    }}
                    visible={showSortPopup}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="color-white"
                    >
                      <SwapOutlined rotate={90} />
                      <span>Sort</span>
                    </a>
                  </Dropdown>
                </StyledButton>
                <StyledButton
                  className="margin-5"
                  type="transparent"
                  bgcolor="#1d1729"
                  bordercolor="#ffffff"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Dropdown
                    overlay={
                      <FilterDropdown
                        filtering={filterOffsets}
                        //changeFiltering={setFilterOffsets}
                        options={filterOptions}
                        onConfirm={(data) => {
                          handleFiltering(data);
                          setShowFilterPopup(false);
                        }}
                        onReset={() => {
                          resetFiltering();
                          setShowFilterPopup(false);
                        }}
                      />
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                    onVisibleChange={(open) => {
                      setShowFilterPopup(open);
                    }}
                    visible={showFilterPopup}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="color-white"
                    >
                      <FilterOutlined />
                      <span>Filter</span>
                    </a>
                  </Dropdown>
                </StyledButton>
              </Col>
            </Row>
          </FormWrapper>
        </FiltersContainer>
        <CardsWrap style={{ marginTop: "10px" }}>
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            {filteredDataSheetsList.map((val, index) => (
              <Col xs={24} sm={24} md={12} lg={6} key={index}>
                <div
                  style={{
                    width: "100%",
                    padding: "28px 12px 22px 28px",
                    // backgroundColor: "rgb(187 187 187 / 9%)",
                    backgroundColor: "#2D273F",
                    borderRadius: "4px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <p className="color-grey">xyz</p> */}
                  <Title
                    level={5}
                    className="card-title"
                    style={{ marginBottom: "1px" }}
                  >
                    {val.sheet_name}
                  </Title>
                  {fillColor(val.sheet_type)}
                  <Title
                    level={5}
                    style={{
                      color: "#b1afbc",
                      margin: "20px 0px 5px 0px",
                      fontSize: "14px",
                      fontWeight: "400",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {stripHtmlTags(val?.description)}
                  </Title>

                  <Title
                    level={5}
                    style={{
                      marginTop: "-2px",
                      marginBottom: "16px",
                      color: "white",
                    }}
                  >
                    {val?.kpiData?.length} KPIs created
                  </Title>
                  <Row justify="space-between" gutter={[8, 8]}>
                    <StyledButton
                      style={{
                        marginLeft: "0",
                        flexGrow: "5",
                      }}
                      type="default"
                      onClick={() => onClickViewKpi(val)}
                    >
                      <EyeOutlined />
                      View KPIs
                    </StyledButton>
                    {!checkRolesPermission(["role:auditor"]) && (
                      <Dropdown
                        overlay={() => (
                          <TableCommonMenu>
                            <Menu.Item>
                              <StyledButton
                                style={{
                                  marginRight: "0",
                                  width: "100%",
                                }}
                                type="default"
                                onClick={() => onClickAssignPermissions(val)}
                              >
                                Permissions
                              </StyledButton>
                            </Menu.Item>
                          </TableCommonMenu>
                        )}
                      >
                        <StyledButton
                          style={{
                            marginRight: "0",
                            flexGrow: "1",
                          }}
                          type="default"
                        >
                          <EllipsisOutlined />
                        </StyledButton>
                      </Dropdown>
                    )}
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </CardsWrap>

        <FormWrapper>
          <Row>
            <Col
              span={12}
              offset={6}
              style={{ textAlign: "center", marginTop: "15px" }}
            >
              <Pagination
                showSizeChanger={false}
                onChange={onShowSizeChange}
                defaultCurrent={1}
                pageSize={pagination.pageSize}
                total={pagination.total}
              />
            </Col>
          </Row>
        </FormWrapper>
      </PageWrap>
    </Spin>
  );
};

export default KpiData;
