import { DeleteOutlined, EllipsisOutlined, MenuOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message } from "antd";
import React, {useState} from "react";
import {
  CommonTable,
  StyledButton,
  TableStyledMenu,
} from "../../../../shared/commonStyles";
import AddAssignees from "./Modal/AddAssignees";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import InputData from './InputData'
import Assignees from "./Assignees";
import { siteThemeColors } from "../../../../shared/colors";
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
import { arrayMoveImmutable } from "array-move";

const SubSectionTable = ({ childData, dataSource, parentData,setDataSource, setLoader }) => {
  const [addAssigneeModal, setAddAssigneeModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [assignType, setAssignType] = useState("");
 

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = childData?.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(childData),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const indexKeyUpdate = newData.map((item, indexx) => {
        const index = indexx + 1;
        return {
          ...item, 
          index         
        };
      });
      const newDataSource = dataSource?.map(item => {
        if(item?.index === parentData?.index){
          return {...item, 'childs': indexKeyUpdate}
        }
        return {...item}
      })
      setDataSource(newDataSource);
    }
  };  
  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Section name",
      dataIndex: "sectionName",
      width: "150px",
      className: "drag-visible",
      render: (_, record) => {
        return <InputData rowData={record} handleSave={handleSave} type="child" />
      },
    },

    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return <Assignees record={record} onAddEditors={onAddEditors} type="editor" />
      },
    },
    {
      title: "Auditors",
      dataIndex: "auditors",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return <Assignees record={record} onAddEditors={onAddEditors} type="auditor" />
      },
    },
    {
      title: "Order",
      dataIndex: "index",
      width: "120px",
      className: "drag-visible",
      render: (data) => {
        return (
          <>
            {`${parentData?.index}.${data}`}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "index",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return (
          <Dropdown overlay={() => menuButtons(record)} trigger={["click"]}>
            <StyledButton
              type="default"
              style={{
                marginLeft: "10",
                padding: "10px 18px",
              }}
            >
              <EllipsisOutlined />
            </StyledButton>
          </Dropdown>
        );
      },
    },
  ];
  const handleSave = (value, row) => {
    const newData = childData.map((item) => {
      if (item.index === row.index) {
        return {
          ...item,
          sectionName: value,
        };
      }
      return item;
    });
    const newDataSource = dataSource?.map(item => {
      if(item?.index === parentData?.index){
        return {...item, 'childs': newData}
      }
      return {...item}
    })
    setDataSource(newDataSource);
  };

  const handleDeleteSection = (index) => {    
    try{
      setLoader(true)
      const newData = childData.filter((item) => item.index !== index);
      const indexKeyUpdate = newData.map((item, indexx) => {
        const index = indexx + 1;
        return {
          ...item,
          index,
        };
      });
      const newDataSource = dataSource.map(item => {
        if(item.index === parentData.index){
          return {...item, 'childs': indexKeyUpdate}
        }
        return {...item}
      })
      setDataSource(newDataSource);
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
  };

  const onAddEditors = (record, type) => {
    setRowData(record);
    setAddAssigneeModal(true);
    setAssignType(type);
  };

  const onSaveAssignee = async (data, type, form) => {
    try{
      setLoader(true)
      const values = await form.validateFields();
      const { userIds, groupIds } = values;
      let newData = null;
      if (type === "editor") {
        newData = childData.map((item) => {
          if (item.index === data.index) {
            return {
              ...item,
              editors: {userIds,groupIds},
            };
          }
          return item;
        });
      } else {
        newData = childData.map((item) => {
          if (item.index === data.index) {
            return {
              ...item,
              auditors: {userIds,groupIds},
            };
          }
          return item;
        });
      }
      const newDataSource = dataSource.map(item => {
        if(item.index === parentData.index){
          return {...item, 'childs': newData}
        }
        return {...item}
      })
      setDataSource(newDataSource);
      setAddAssigneeModal(false);
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
  };
  const onCloseModal = () => {
    setRowData(null);
    setAddAssigneeModal(false);
  };

  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        onClick={() => onAddEditors(record, "editor")}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<PlusCircleOutlined />}
        >
          Add Editors
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onAddEditors(record, "auditor")}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<PlusCircleOutlined />}
        >
          Assign Auditors
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => handleDeleteSection(record.index)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  return (
    <>
      <CommonTable
        showHeader={false}
        dataSource={childData}
        columns={columns}
        rowKey="index"
        scroll={{ x: true }}      
        pagination={false}  
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      {addAssigneeModal && 
        <AddAssignees
          isVisible={addAssigneeModal}
          onClose={() => onCloseModal()}
          rowData={rowData}
          onSaveAssignee={onSaveAssignee}
          assignType={assignType}
        />
      }
    </>
  );
};

export default SubSectionTable;
