import { Row } from "antd";
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { StyledButton } from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";

export const AddDocumentReview = () => {
  const navigate = useNavigate();

  return (
    <>
      <Row justify="end">
        <>
          <StyledButton
            type="primary"
            onClick={() => navigate("/reporting/document-review/create")}
          >
            <PlusCircleOutlined /> <span>Create Document Review</span>
          </StyledButton>
        </>
      </Row>
    </>
  );
};
