import React, { useState } from "react";
import { useBoolean } from "ahooks";
import moment from "moment";
import { Button, DatePicker, Drawer, Radio, Select } from "antd";
import styles from "../CalculationsAudit/Components/HistoryDrawer/HistoryDrawer.module.scss";
import { FormWrapper, StyledButton } from "../../../shared/commonStyles";

interface EmissionAuditHistoryProps {
  visible: boolean;
  data: Emission | Offset;
  onClose: () => void;
}

export const EmissionAuditHistory = ({
  visible,
  data,
  onClose,
}: EmissionAuditHistoryProps) => {
  const [decision, setDecision] = useState("all");
  const [dateFilter, setDateFilter] = useState("all");
  const [date, setDate] = useState<RangeValue>([moment(), moment()]);
  const [
    isDateModalVisible,
    { setTrue: showDateModal, setFalse: hideDateModal },
  ] = useBoolean(false);

  const dateFormat = "MM/DD/YYYY";

  const getDatePanel = (panelNode: React.ReactNode) => {
    return (
      <>
        <p className={styles.dateRangeTitle}>Time period</p>
        <div className={styles.dateRangeResult}>
          <span>{date?.[0]?.format(dateFormat)}</span> -{" "}
          <span>{date?.[1]?.format(dateFormat)}</span>
        </div>
        <div className={styles.dateRangeSelector}>{panelNode}</div>
        <div className={styles.dateRangeButtons}>
          <StyledButton type="primary" onClick={hideDateModal}>
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <Drawer
      width={400}
      className={styles.drawer}
      closable={false}
      placement="right"
      visible={visible}
      onClose={onClose}
    >
      <FormWrapper>
        <div className={styles.drawerContent}>
          <h2 className={styles.drawerTitle}>History</h2>
          <ul className={styles.drawerList}>
            <li className={styles.drawerListItem}>
              <h4 className={styles.drawerListItemTitle}>
                Energy usage -{" "}
                {(data as Emission)?.factor_data?.region ||
                  (data as Offset)?.region}
              </h4>
              <p className={styles.drawerListItemText}>
                {((data as Emission)?.factor_data as Factor)?.description ||
                  (data as Offset)?.description}
              </p>
            </li>
            <li className={styles.drawerListItem}>
              <Radio.Group
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <Radio value="all" onClick={hideDateModal}>
                  All time
                </Radio>
                <Radio value="custom" onClick={showDateModal}>
                  Custom time
                </Radio>
              </Radio.Group>
              <FormWrapper>
                <DatePicker.RangePicker
                  className={
                    dateFilter === "custom"
                      ? styles.datePicker
                      : styles.datePickerHidden
                  }
                  open={isDateModalVisible}
                  onFocus={showDateModal}
                  onChange={setDate}
                  panelRender={getDatePanel}
                  placeholder={["Date", "Date"]}
                  dropdownClassName={styles.dateRangeBox}
                  format={dateFormat}
                  value={date}
                  ranges={{
                    "Date Presets": [moment(), moment()],
                    "This Year": [
                      moment().startOf("year"),
                      moment().endOf("year"),
                    ],
                    "Last Year": [
                      moment().startOf("year").subtract(1, "year"),
                      moment().endOf("year").subtract(1, "year"),
                    ],
                    Custom: [moment(), moment()],
                  }}
                />
              </FormWrapper>
              <Select
                defaultValue={decision}
                onChange={setDecision}
                className={styles.drawerSelector}
              >
                <Select.Option value="all">All decisions</Select.Option>
                <Select.Option value="approved">Approved</Select.Option>
                <Select.Option value="declined">Declined</Select.Option>
              </Select>
            </li>
            {data.audits
              ?.filter((v) => (decision !== "all" ? v.status === decision : v))
              .filter((v) =>
                dateFilter === "custom"
                  ? moment(v.timestamp) > date?.[0] &&
                    moment(v.timestamp) < date?.[1]
                  : v
              )
              .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
              .map((audit) => (
                <li
                  key={audit.timestamp + audit.user_id}
                  className={styles.drawerListItem}
                >
                  <h4 className={styles.drawerListItemTitle}>
                    <div>
                      {audit.auditor_name}{" "}
                      <span className={styles.drawerListItemTitleStatus}>
                        {audit.status}
                      </span>
                    </div>
                    <div className={styles.drawerListItemTitleDate}>
                      {" "}
                      {audit.timestamp}
                    </div>
                  </h4>
                  <div className={styles.drawerListItemText}>
                    {audit.comments}
                  </div>
                </li>
              ))}
          </ul>
          <div className={styles.drawerFooter}>
            <StyledButton onClick={onClose}>Close</StyledButton>
          </div>
        </div>
      </FormWrapper>
    </Drawer>
  );
};
