import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  CommonTable,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { v4 as uuidv4 } from "uuid";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import TableActionsButtons from "../TableActionsButtons";
import { Input, InputNumber, Upload, UploadFile } from "antd";
import { useDynamicList } from "ahooks";

import styles from "./EditableCalculationOffsetsTable.module.scss";
import { removeNullKeys } from "../../../../Carbon/CalculationUtils";

export const EditableContext = React.createContext(null);

interface EditableCalculationOffsetsTableProps {
  isCalculationLocked?: boolean;
  data: Offset[];
  isDeleted: boolean;
  editOffset?: (data: Offset) => void;
  addOffset?: (data: Offset) => void;
  onDelete: (data: Offset) => void;
  onClickUndo?: (data: Offset) => void;
  onRestore?: (data: Offset) => void;
  onDeleteEmptyOffset?: (data: Offset) => void;
  emissionsUnit: string;
  conversionFactor: number;
}

const EditableCalculationOffsetsTable = observer(
  ({
    isCalculationLocked,
    data,
    isDeleted,
    onDelete,
    editOffset,
    addOffset = ({}: Offset) => {},
    onClickUndo = () => {},
    onRestore,
    onDeleteEmptyOffset = ({}: Offset) => {},
    emissionsUnit,
    conversionFactor,
  }: EditableCalculationOffsetsTableProps) => {
    const [offsetsData, setOffsetsData] = useState(data);
    useEffect(() => {
      setOffsetsData(data);
    }, [data]);

    const [filterData, setFilterData] = useState<any>();
    const { list: files, resetList } = useDynamicList<UploadFile>();

    const onRemoveFileFromListHandler = (file: UploadFile) => {
      const filesList = files.filter((v) => v !== file);
      resetList(filesList);
    };

    const onDeleteFromEmissionsData = (emission: Offset) => {
      const newData = offsetsData.filter((item) => item.key !== emission.key);
      setOffsetsData(newData);
    };
    const onClickUndoDeleted = () => {
      setOffsetsData(data);
    };
    const emptyOffset = {
      name: "",
      region: "",
      cost: 0,
      emission: 0,
      proof: "",
      description: "",
    } as Offset;
    const [newOffset, setNewOffset] = useState(emptyOffset as Offset);

    const areFieldsFilled =
      newOffset.name && newOffset.cost != null && newOffset.emission != null;

    const handleDownload = (offset: Offset) => {
      calculatorStore.downloadOffsetProof(offset);
    };

    const getFilterData = () => {
      const filterObject: any = {};
      offsetsData.forEach((value) => {
        if (
          "name" in value &&
          value["name"] &&
          !filterObject["name"]?.includes(value["name"])
        ) {
          filterObject["name"]?.length
            ? filterObject["name"]?.push(value["name"])
            : (filterObject["name"] = [value["name"]]);
        }
        if (
          "region" in value &&
          value["region"] &&
          !filterObject["region"]?.includes(value["region"])
        ) {
          filterObject["region"]?.length
            ? filterObject["region"]?.push(value["region"])
            : (filterObject["region"] = [value["region"]]);
        }
      });
      setFilterData(filterObject);
    };

    useEffect(() => {
      offsetsData?.length && getFilterData();
    }, [offsetsData]);

    const handleViewVersion = (historyData: CalculatorEntryHistory) => {
      const viewingData = historyData.data as Offset;
      const offsetIndex = offsetsData.findIndex(
        (item) => item.key === viewingData.key
      );
      const { data, ...rest } = historyData;
      const newData = offsetsData.map((item, index) =>
        index === offsetIndex
          ? { ...viewingData, historyInfo: historyData }
          : item
      );
      setOffsetsData(newData);
    };

    const handleRestoreVersion = (
      historyData: CalculatorEntryHistory,
      originalItem: Offset
    ) => {
      calculatorStore
        .editCalculationEntry({
          ...historyData.data,
          _id: { $oid: originalItem?._id?.$oid },
          history: originalItem.history,
        } as Offset)
        .then(() => calculatorStore.getCalculationById());
    };

    const handleEditNewOffset = (value: string | number, field: string) => {
      setNewOffset((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };

    const handleAddNewEmission = (index: number, offset: Offset) => {
      if (files[0]?.name) {
        newOffset.uploadedProofId = uuidv4();
        newOffset.proofName = files[0].name;
      }
      addOffset({
        ...offset,
        ...newOffset,
      });
      setOffsetsData((prevData) => {
        prevData[index] = offset;
        return prevData;
      });
      calculatorStore.uploadOffsetProof(
        files[0] as unknown as File,
        newOffset.uploadedProofId
      );
      setNewOffset(emptyOffset);
      resetList([]);
    };

    const handleCancel = (offset: Offset) => {
      onDeleteEmptyOffset(offset);
      setNewOffset(emptyOffset);
    };

    const handleGoCurrent = () => {
      setOffsetsData(data);
    };

    const proofColumn = (link: string, offset: Offset) => {
      if (!offset.name) {
        return (
          <div className={styles.emptyProof}>
            <FormWrapper>
              <Input
                className={styles.emptyInput}
                placeholder="Enter link"
                value={newOffset.proof}
                onChange={(e) =>
                  handleEditNewOffset(e.target.value || "", "proof")
                }
              />
            </FormWrapper>
            <span className={styles.emptyProofText}>And/Or</span>
            <Upload
              accept=".XLSX, .XLS, .CSV,  .pdf, .JPEG, .PNG, .GIF, .TXT, .PPT, .PPTX"
              customRequest={({ file }) => {
                resetList([file as UploadFile]);
              }}
              fileList={files}
              multiple={false}
              onRemove={onRemoveFileFromListHandler}
            >
              <StyledButton icon={<UploadOutlined />}>Upload</StyledButton>
            </Upload>
            {newOffset.proofName && <span>{newOffset.proofName}</span>}
          </div>
        );
      }
      if (offset.proofName) {
        return (
          <a onClick={() => handleDownload(offset)}>
            <VerticalAlignBottomOutlined />
            <span className={styles.downloadFileText}>Download File</span>
          </a>
        );
      } else if (link) {
        return (
          <a target="_blank" rel="noreferrer" href={link}>
            {link}
          </a>
        );
      }
      return <span>-</span>;
    };

    const emptyActionColumn = (offset: Offset, index: number) => (
      <div className={styles.emptyActionsColumn}>
        <StyledButton
          type="primary"
          onClick={() => handleAddNewEmission(index, offset)}
          disabled={!areFieldsFilled}
        >
          <CheckOutlined />
          Confirm
        </StyledButton>
        <StyledButton type="primary" onClick={() => handleCancel(offset)}>
          <CloseOutlined />
        </StyledButton>
      </div>
    );

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filters: filterData?.name?.map((v: string) => ({
          value: v,
          text: v,
        })),
        filteredValue:
          calculatorStore.calculationItemsFilter?.[ScopeName.OFFSETS]?.name ||
          null,
        width: "15%",
        sorter: (a: Offset, b: Offset) => a.name.localeCompare(b.name),
        render: (data: string, offset: Offset) => {
          return (
            <>
              {!offset.name ? (
                <FormWrapper>
                  <Input
                    className={styles.emptyInput}
                    placeholder="Enter name"
                    size="large"
                    value={newOffset.name}
                    onChange={(e) => {
                      handleEditNewOffset(e.target.value || "", "name");
                    }}
                  />
                </FormWrapper>
              ) : (
                <span>{offset.name}</span>
              )}
            </>
          );
        },
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        filters: filterData?.region?.map((v: string) => ({
          value: v,
          text: v,
        })),
        filteredValue:
          calculatorStore.calculationItemsFilter?.[ScopeName.OFFSETS]?.region ||
          null,
        width: "10%",
        sorter: (a: Offset, b: Offset) => a.region.localeCompare(b.region),
        render: (data: string, offset: Offset) => (
          <>
            {!offset.name ? (
              <FormWrapper>
                <Input
                  className={styles.emptyInput}
                  placeholder="Enter region"
                  size="large"
                  value={newOffset.region}
                  onChange={(e) => {
                    handleEditNewOffset(e.target.value || "", "region");
                  }}
                />
              </FormWrapper>
            ) : (
              <span>{offset.region}</span>
            )}
          </>
        ),
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        width: "10%",
        sorter: (a: Offset, b: Offset) => a.cost - b.cost,
        render: (data: string, offset: Offset) => (
          <>
            {!offset.name ? (
              <FormWrapper>
                <InputNumber
                  min={0}
                  className={styles.emptyInput}
                  placeholder="Enter cost"
                  size="large"
                  value={newOffset.cost}
                  onChange={(value) => {
                    handleEditNewOffset(value || 0, "cost");
                  }}
                />
              </FormWrapper>
            ) : (
              <span>{offset.cost}</span>
            )}
          </>
        ),
      },
      {
        title: "Proof of offset",
        dataIndex: "proof",
        key: "proof",
        width: "15%",
        sorter: (a: Offset, b: Offset) => a.proof.localeCompare(b.proof),
        render: (link: string, offset: Offset) => proofColumn(link, offset),
      },
      {
        title: `Offsetted amount (${emissionsUnit} CO2e)`,
        dataIndex: "emission",
        key: "emission",
        width: "20%",
        sorter: (a: Offset, b: Offset) => a.emission - b.emission,
        render: (data: string, offset: Offset) => {
          return (
            <>
              {!offset.name ? (
                <FormWrapper>
                  <InputNumber
                    min={0}
                    className={styles.emptyInput}
                    placeholder="Enter offsetted amount"
                    size="large"
                    value={newOffset.emission}
                    onChange={(value) =>
                      handleEditNewOffset(value || 0, "emission")
                    }
                  />
                </FormWrapper>
              ) : (
                <span>{offset.emission * conversionFactor}</span>
              )}
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (_: any, offset: Offset, index: number) => {
          const item = data[index];

          return isDeleted ? (
            <TableActionsButtons
              isCalculationLocked={isCalculationLocked}
              data={offset}
              isDeletedItems={true}
              onDelete={() => {
                onDelete(offset);
              }}
              onClickUndo={() => {
                onClickUndoDeleted();
              }}
              onViewHistory={handleViewVersion}
              onGoCurrent={handleGoCurrent}
              onRestoreElement={() => onRestore && onRestore(offset)}
              onEditOffset={editOffset}
              onDeleteFromEmissionsData={() =>
                onDeleteFromEmissionsData(offset)
              }
            />
          ) : offset.name ? (
            <TableActionsButtons
              isCalculationLocked={isCalculationLocked}
              data={offset}
              onDelete={() => {
                onDelete(offset);
              }}
              onDeleteFromEmissionsData={() =>
                onDeleteFromEmissionsData(offset)
              }
              onClickUndo={() => {
                onClickUndo(offset);
              }}
              onViewHistory={handleViewVersion}
              onRestoreHistory={(history) =>
                handleRestoreVersion(history, item)
              }
              onGoCurrent={handleGoCurrent}
              onEditOffset={editOffset}
            />
          ) : (
            emptyActionColumn(offset, index)
          );
        },
      },
    ];

    const handleTableChange = (_: any, filters: any) => {
      const updatedFilters = Object.values(removeNullKeys(filters))?.length
        ? removeNullKeys(filters)
        : null;
      calculatorStore.changeCalculationItemsFilter(
        updatedFilters,
        ScopeName.OFFSETS
      );
    };

    return (
      <CommonTable
        className="calculationTable"
        rowClassName={() => "editableRow"}
        columns={columns}
        dataSource={offsetsData}
        loading={calculatorStore.loadingCalculationItems}
        pagination={false}
        scroll={{ x: true }}
        size="small"
        onChange={handleTableChange}
      />
    );
  }
);

export default EditableCalculationOffsetsTable;
