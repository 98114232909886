import React from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Typography } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";
import { encodeUrlName } from "../../../shared/commonFunctions";

const { Title } = Typography;

const Step4 = ({ sheetId, sheetName, kpiId }) => {
  const navigate = useNavigate();
  return (
    <>
      <FormWrap width="500px">
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <CheckCircleOutlined style={{ fontSize: "40px" }} />
              <Title className="color-white" level={4}>
                KPI was {kpiId ? "updated" : "created"} successfully!
              </Title>
              <p>
                <span>
                  {" "}
                  KPI was {kpiId ? "updated" : "created"} successfully! It is
                  already available in KPI section
                </span>
              </p>
              <Form.Item>
                <Row justify="center">
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      navigate(
                        `/data/kpi/${sheetId}/${encodeUrlName(
                          sheetName
                        )}/details`
                      )
                    }
                  >
                    <span>{`Go to All KPI's`}</span>
                  </StyledButton>
                </Row>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};

export default Step4;
