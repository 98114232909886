import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";

import {
  getSheetById,
  getDataSheets,
  createDataSheet,
  insertData,
  getDatasheetFilters,
} from "../../services/dataSheet";
import { isValidJSONString } from "../../shared/commonFunctions";
import { Space, Form, Row, Col, message, Steps, Progress, Spin } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";

import SheetDescription from "./components/SheetDescription";
import TemplateSelection from "./components/TemplateSelection";
import CustomFields from "./components/CustomFields";
import Success from "./components/Success";

const { Step } = Steps;

const cdpTemplate = require("../../shared/Json/CDP.json");
const csrdTemplate = require("../../shared/Json/CSRD.json");
const griTemplate = require("../../shared/Json/GRI.json");
const sasbTemplate = require("../../shared/Json/SASB.json");
const tcfdTemplate = require("../../shared/Json/TCFD.json");
const csrdChecklistTemplate = require("../../shared/Json/CSRD_Checklist.json");

const CreateDataSheet = ({ isReference }) => {
  const [searchParams] = useSearchParams();
  const fieldsRef = useRef();
  const sheetId = searchParams.get("copyId");

  const [step, setStep] = useState(1);
  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [loadingCreateDataSheet, setLoadingCreateDataSheet] = useState(false);
  const [schema, setSchema] = useState([]);
  const [submitKey, setSubmitKey] = useState();
  const [descriptionData, setDescriptionData] = useState("");
  const [sheetNames, setSheetNames] = useState([]);
  const [initialValues, setInitialValues] = useState({
    sheetName: "",
    description: "",
    sheet_type: null,
    metadata_sheet_id: "",
    enableComments: true,
    enableChangeHistory: true,
    is_reference_data: isReference,
    sheet_schema: [
      {
        display_name: "",
        entity_name: "",
        input_type: "",
        unit_id: "",
        data_type: "",
        required: false,
      },
    ],
  });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const getSheetNames = async () => {
    const dataSheetsList = await getDatasheetFilters();
    if (dataSheetsList?.sheet_name?.length) {
      setSheetNames(dataSheetsList?.sheet_name);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoadDataSheets(true);
      const payload = {
        filter: { archive: false, is_reference_data: true },
      };
      const dataSheetsList = await getDataSheets(payload);
      setDataSheetsList(dataSheetsList);

      setLoadDataSheets(false);
    };
    !isReference && loadData();
    getSheetNames();
  }, []);

  const loadSheetRowData = async () => {
    try {
      setLoadDataSheets(true);
      const sheetData = await getSheetById(sheetId);
      if (sheetData) {
        setRowData(sheetData);
      } else {
        message.error("Data Sheet not found");
        navigate(`/data/data-sheets`);
      }
    } catch (error) {
      console.log("Something went wrong while load flow items!", error);
      message.error("Something went wrong while load flow items!");
    } finally {
      setLoadDataSheets(false);
    }
  };

  useEffect(() => {
    if (sheetId) {
      loadSheetRowData();
    }
  }, [sheetId]);

  useEffect(() => {
    if (rowData) {
      const initData = {
        _id: rowData?._id,
        sheetName: rowData?.sheet_name,
        description: rowData?.description,
        sheet_type: rowData?.sheet_type,
        metadata_sheet_id: rowData?.metadata_sheet_id,
        sheet_schema: rowData?.sheet_schema,
        validationRules: rowData?.validationRules,
        is_reference_data: rowData?.is_reference_data,
        is_standard_data: rowData?.is_standard_data,
      };
      setDescriptionData(rowData?.description);
      form.setFieldsValue(initData);
      setInitialValues({ ...initData });
    }
  }, [rowData]);

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const changeDescription = (data) => {
    setDescriptionData(data);
    setInitialValues((oldState) => ({
      ...oldState,
      description: data,
    }));
  };
  const onStepChange = async (selectedStep) => {
    try {
      await form.validateFields();
      setStep(selectedStep + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const getRecordsForTemplate = (sheetType) => {
    let template = [];
    switch (sheetType) {
      case "CDP":
        template = cdpTemplate;
        break;
      case "CSRD":
        template = csrdTemplate;
        break;
      case "GRI":
        template = griTemplate;
        break;
      case "SASB":
        template = sasbTemplate;
        break;
      case "TCFD":
        template = tcfdTemplate;
        break;
      case "CSRD Checklist":
        template = csrdChecklistTemplate;
        break;
      default:
        [];
    }
    template = template?.map((item) => {
      const newObj = {};
      Object.entries(item)?.forEach(([key, value]) => {
        const newKey =
          key === "Resource URL"
            ? "source"
            : initialValues.sheet_schema?.find((v) => v.display_name === key)
                ?.entity_name || key;
        newObj[newKey] = value;
      });
      return newObj;
    });
    return template;
  };

  const handleSubmitStandard = async (sheet_id) => {
    try {
      const records = getRecordsForTemplate(initialValues.sheet_type);
      const recordsPayload = {
        sheet_id,
        sheet_name: initialValues.sheetName,
        documents: records,
      };
      await insertData(recordsPayload);
    } catch (e) {
      console.log("e", e);
    }
  };

  const onClickCreateSheet = async (validationRules, sheetSchema) => {
    if (!sheetSchema.length) {
      message.error("Please add atleast one field...");
      return;
    }
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateDataSheet(true);
          const {
            sheetName,
            sheet_type,
            description,
            metadata_sheet_id,
            enableComments,
            enableChangeHistory,
            is_reference_data,
            is_standard_data,
          } = initialValues;

          const payload = {
            sheet_name: sheetName,
            sheet_type,
            metadata_sheet_id,
            description: description,
            sheet_schema: sheetSchema,
            ...(validationRules?.length && {
              validationRules: validationRules,
            }),
            archive: false,
            enable_comments: enableComments,
            is_reference_data,
            enable_change_history: enableChangeHistory,
            is_standard_data,
          };

          const response = await createDataSheet(payload);

          const datalake = isValidJSONString(response["data"]["datalake"]);
          if (datalake["_id"]["$oid"]) {
            setLoadingCreateDataSheet(false);
            setInitialValues((prev) => {
              return {
                ...prev,
                sheet_schema: sheetSchema,
                sheetId: datalake["_id"]["$oid"],
              };
            });
            setSchema(sheetSchema);
            if (is_standard_data) {
              await handleSubmitStandard(datalake["_id"]["$oid"]);
            }
            form.setFieldsValue({
              ...initialValues,
              sheet_schema: sheetSchema,
            });
            setSubmitKey(1);
            setStep(step + 1);
          } else {
            if (typeof response["data"]["datalake"] === "string") {
              //backend error message
              const errorMessage = response["data"]["datalake"];
              message.error(errorMessage);
              return;
            }
          }
        } catch (error) {
          console.log("Something went wrong while creating data sheet!", error);
          message.error("Something went wrong while creating data sheet!");
          setLoadingCreateDataSheet(false);
        }
      })
      .catch((error) => console.log("error form in data sheet", error));
  };

  const onClickPrevious = () => {
    step === 1 ? navigate(-1) : setStep((prevState) => prevState - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep((prevState) => prevState + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onChangeSheetTypeTemplate = (value, sheetSchema) => {
    setSchema(sheetSchema);
    form.setFieldsValue({
      sheet_type: value,
      sheetName: value,
      description: value,
      metadata_sheet_id: "",
      sheet_schema: sheetSchema,
    });
    setDescriptionData(value);
    setInitialValues((oldState) => ({
      ...oldState,
      sheet_type: value,
      sheetName: value,
      description: value,
      metadata_sheet_id: "",
      sheet_schema: sheetSchema,
    }));
  };

  const onClickSelectTemplateSheetType = async () => {
    try {
      await form.validateFields();
      setStep((pre) => pre + 1);
    } catch (error) {
      console.log("error", error);
    }
  };
  const isDuplicateMode = !!sheetId && !!initialValues.sheetName;
  const styledStepStyle = {
    ...(isDuplicateMode && {
      display: "block",
      width: "85%",
      textAlign: "center",
    }),
  };

  const showSectionsAccordingSteps = [
    <TemplateSelection
      key="templateSelection"
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      initialValues={initialValues}
      onChangeSheetTypeTemplate={onChangeSheetTypeTemplate}
      onClickSelectTemplateSheetType={onClickSelectTemplateSheetType}
    />,
    <SheetDescription
      form={form}
      descriptionData={descriptionData}
      key="sheetDescription"
      changeDescription={changeDescription}
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      dataSheetsList={dataSheetsList}
      loadDataSheets={loadDataSheets}
      initialValues={initialValues}
      isDuplicateMode={isDuplicateMode}
      sheetNames={sheetNames}
    />,
    <CustomFields
      key="customFields"
      onClickCreateSheet={onClickCreateSheet}
      onClickPrevious={onClickPrevious}
      initialValues={initialValues}
      loadingCreateDataSheet={loadingCreateDataSheet}
      isDuplicateMode={isDuplicateMode}
      schema={schema}
      submitKey={submitKey}
    />,
    <Success
      key="success"
      sheetId={initialValues.sheetId}
      sheetName={initialValues.sheetName}
    />,
  ]?.filter((step) =>
    isDuplicateMode ? step.key !== "templateSelection" : step
  );

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                onClick={() => navigate("/data/data-sheets")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps
                current={step - 1}
                onChange={onStepChange}
                style={styledStepStyle}
              >
                <>
                  {!isDuplicateMode && (
                    <Step
                      className="stepper-wrapper"
                      key={1}
                      title={<span>Template Selection</span>}
                    />
                  )}
                  <Step
                    className="stepper-wrapper "
                    key={isDuplicateMode ? 1 : 2}
                    title={<span>Sheet Description</span>}
                    disabled={!initialValues.sheet_type}
                  />
                  <Step
                    className="stepper-wrapper"
                    key={isDuplicateMode ? 2 : 3}
                    title={<span>Custom Fields</span>}
                    disabled={
                      !initialValues.sheet_type && !initialValues.sheetName
                    }
                  />
                </>
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 33.33}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps[step - 1].key ===
                  "customFields" ? (
                    <div ref={fieldsRef} />
                  ) : (
                    showSectionsAccordingSteps[step - 1]
                  )}
                </Form>
                {showSectionsAccordingSteps[step - 1].key ===
                  "customFields" && (
                  <div container={fieldsRef.current}>
                    {showSectionsAccordingSteps[step - 1]}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default CreateDataSheet;
