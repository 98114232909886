import { useContext, useEffect, useState } from "react";
import { useUserSettings } from "./useUserSettings";
import { AuthContext } from "../contexts/AuthContext";

export function useGetMetadataUserSettings() {
  const { auth } = useContext(AuthContext);
  const [metadataUserSettings, setMetadataUserSettings] = useState<any[]>();
  const { getUserSettings } = useUserSettings();
  const username = auth["auth"]["accessToken"]["payload"]["username"];
  const payload = {
    owner: username,
    settingName: "default_metadata_sheet_record",
  };

  useEffect(() => {
    getUserSettings(payload).then((res) => {
      const settingValue = JSON.parse(res?.settingValue || "{}");
      if (Array.isArray(settingValue)) {
        setMetadataUserSettings(settingValue);
      } else {
        setMetadataUserSettings([settingValue]);
      }
    });
  }, []);

  return metadataUserSettings;
}
