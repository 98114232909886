import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Slider,
  Switch,
  Tooltip,
  Typography,
  Popover,
} from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormWrapper } from "../../../shared/commonStyles";
import { ConfirmModal } from "../../carbonPage/Calculator/Components/ConfirmModal";
import { UnitConversionsModal } from "../../../components/UnitConversionsModal/UnitConversionsModal";
import RenderOptionsSchema from "../../../components/CustomFormBuilder/RenderOptionsSchema";
import styles from "./FormBuilder.module.scss";
import { CreateExpressionModal } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/CreateExpressionModal";

interface UnitConversion {
  unitGroup?: string;
  unit?: string;
  conversionFactor?: number;
}
interface SettingsInfoProps {
  isEditMode: boolean;
  form: FormInstance<any>;
  schemasTemplate?: SheetSchema[];
  currentSchema: SheetSchema[];
  field?: SheetSchema;
  measures?: string[];
  units?: {
    display_name: string;
    measure: string;
    unit_id: string;
  }[];
  currentFieldIndex?: number;
  metaDataEntityItems: any;
  handleSaveConversions: (unitConversions: UnitConversion[]) => void;
  handleSaveExpression: (field?: SheetSchema) => void;
  handleMoveColumn: (number: number) => void;
  handleDeleteColumn: () => void;
  isMetadata: boolean;
  handleChangeColumnType: () => void;
}
const inputTypesOptions = [
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Text",
    value: "string",
  },
  {
    label: "Date",
    value: "date",
  },
];

const datSheetInputTypesOptions = [
  {
    label: "Dropdown",
    value: "dropdown",
  },
  {
    label: "Radio",
    value: "radio",
  },
  {
    label: "Checkbox",
    value: "checkbox",
  },
  {
    label: "Long Answer",
    value: "long-answer",
  },
  {
    label: "Rich Text",
    value: "rich-text",
  },
  {
    label: "Expression",
    value: "expression",
  },
];

const thresholdMarks = {
  0: "0%",
  25: "25%",
  75: "75%",
  100: "100%",
};

export const SettingsInfo = ({
  isEditMode,
  form,
  currentSchema,
  schemasTemplate,
  field,
  measures,
  units,
  currentFieldIndex,
  metaDataEntityItems,
  handleSaveConversions,
  handleMoveColumn,
  handleDeleteColumn,
  isMetadata,
  handleSaveExpression,
  handleChangeColumnType,
}: SettingsInfoProps) => {
  const dataTypeOptions = isMetadata
    ? inputTypesOptions
    : [...inputTypesOptions, ...datSheetInputTypesOptions];
  const [formValues, setFormValues] = useState(field as any);
  const [conversionModal, setConversionModal] = useState(false);
  const [isConfirmConversionModalVisible, setConfirmConversionModalVisible] =
    useState(false);
  const [isExpressionsModalVisible, setIsExpressionModalVisible] =
    useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);

  useEffect(() => {
    form.setFieldsValue(field);
    setFormValues(field);
  }, [field]);

  const measureUnits = units?.filter(
    (item) => formValues?.measure === item?.measure
  );

  const idenTifierField = metaDataEntityItems?.find(
    (v: SheetSchema) => v.isIdentifier
  );

  const isIdentifierConnected =
    !isMetadata &&
    currentSchema?.find(
      (v) =>
        v.metadata_entity_name === idenTifierField?.entity_name &&
        formValues?.entity_name !== v?.entity_name
    );
  const isExpression = formValues?.input_type === "expression";
  const filteredMetaDataEntityItems = metaDataEntityItems?.filter(
    (item: SheetSchema) => item.input_type === formValues?.input_type
  );
  const isAlreadyExists = () => {
    return schemasTemplate?.some((v) => v?.schemasKey === field?.schemasKey);
  };

  const checkInputType = formValues?.input_type;
  const isCheckboxGroup = formValues?.is_checkbox_group;
  const isPrimaryKey = formValues?.primaryKey;
  const isSecondaryKey = formValues?.secondaryKey;

  const onOpenConfirmModal = () => {
    setConfirmConversionModalVisible(true);
  };

  const onOpenUnitModal = () => {
    setConfirmConversionModalVisible(false);
    setConversionModal(true);
  };

  const handleCloseConversionModal = () => {
    setConversionModal(false);
  };

  const infoConfirmModal = {
    title: "You already have measures for this field.",
    description: "Are you sure you want to edit it?",
    confirmText: "Edit",
    cancelText: "Cancel",
  };
  const handleAddIdentifier = () => {
    setShowPopconfirm(false);
  };
  const handleCancelIdentifier = () => {
    setShowPopconfirm(false);
    form.setFieldsValue({ ...field, isIdentifier: false });
    setFormValues({ ...field, isIdentifier: false });
  };
  const handleChangeIdentifier = (checked: boolean) => {
    form.setFieldsValue({ ...field, isIdentifier: checked });
    setFormValues({ ...field, isIdentifier: checked });
    if (checked) {
      setShowPopconfirm(true);
    }
  };
  const isIdentifierDisabled = !!currentSchema?.find(
    (item) => item.isIdentifier && item.entity_name !== field?.entity_name
  );

  return (
    <FormWrapper>
      <Typography.Title level={3}>General settings</Typography.Title>
      <Form.Item
        label="Column name"
        name="display_name"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,

            message: "Please input unique display name!",
            validator: (_, value) => {
              try {
                const displayName = value?.trim();

                if (!displayName) {
                  throw new Error("Display name is required!");
                } else if (
                  currentSchema?.some(
                    (v) =>
                      v?.schemasKey !== field?.schemasKey &&
                      v?.entity_name === field?.entity_name
                  )
                ) {
                  throw new Error("Display name is already exist!");
                } else {
                  return Promise.resolve(true);
                }
              } catch (err) {
                throw new Error("Display name is required!");
              }
            },
          },
        ]}
      >
        <Input
          disabled={isEditMode && isAlreadyExists()}
          placeholder="Enter column name"
        />
      </Form.Item>
      <Form.Item
        label="Data type"
        name="input_type"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please select a object type!",
          },
        ]}
      >
        <Select
          disabled={isAlreadyExists() && isEditMode}
          onChange={() => handleChangeColumnType()}
        >
          {dataTypeOptions.map((item) => {
            return (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {!!filteredMetaDataEntityItems?.length &&
        !isExpression &&
        !isMetadata && (
          <Form.Item
            labelCol={{ span: 24 }}
            label="Metadata Entity Item"
            name={"metadata_entity_name"}
          >
            <Select
              allowClear
              placeholder="Select Metadata Entity Item"
              disabled={
                (isEditMode &&
                  !!schemasTemplate?.find(
                    (v) => v.entity_name === field?.entity_name
                  )?.metadata_entity_name) ||
                !filteredMetaDataEntityItems?.length
              }
            >
              {filteredMetaDataEntityItems
                ?.filter((v: any) => v?.status === "ACTIVE" || v?.status === true)
                .map((item: any, i: number) => {
                  return (
                    <Select.Option
                      disabled={
                        !!isIdentifierConnected &&
                        isIdentifierConnected?.metadata_entity_name ===
                          item?.entity_name
                      }
                      value={item?.entity_name}
                      key={i}
                    >
                      {item?.display_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}
      {!isExpression && checkInputType === "number" && !isMetadata && (
        <>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item label="Unit of measurement" name="measure">
                <Select disabled={isAlreadyExists() && isEditMode}>
                  {measures?.map((measure) => {
                    return (
                      <Select.Option key={measure} value={measure}>
                        {measure}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" " name="unit_id">
                <Select disabled={isAlreadyExists() && isEditMode}>
                  {measureUnits?.map((unit) => {
                    return (
                      <Select.Option key={unit.unit_id} value={unit.unit_id}>
                        {unit.display_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!!formValues?.measure?.length && (
            <Row>
              <Form.Item label="" name="customConversions">
                <Button
                  type="text"
                  onClick={() =>
                    formValues?.customConversions?.length
                      ? onOpenConfirmModal()
                      : onOpenUnitModal()
                  }
                >
                  Add another measure
                </Button>
              </Form.Item>
            </Row>
          )}
        </>
      )}
      <Form.Item
        label="Description (optional)"
        name="description"
        labelCol={{ span: 24 }}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>
      {!isExpression && !isMetadata && (
        <>
          {checkInputType === "number" && (
            <>
              <Form.Item label="Set up Variance Threshold" name="threshold">
                <Slider
                  disabled={isAlreadyExists() && isEditMode}
                  min={0}
                  max={100}
                  marks={thresholdMarks}
                  style={{ width: "50%" }}
                />
              </Form.Item>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Form.Item label="" name="threshold">
                    <InputNumber
                      placeholder="0%"
                      disabled={isAlreadyExists() && isEditMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {checkInputType === "checkbox" && (
            <Form.Item name="is_checkbox_group" valuePropName="checked">
              <Checkbox>
                <b style={{ color: "#fff" }}>is checkbox group?</b>
              </Checkbox>
            </Form.Item>
          )}
          {(checkInputType === "dropdown" ||
            (isCheckboxGroup && checkInputType === "checkbox") ||
            checkInputType === "radio") && (
            <RenderOptionsSchema
              restField={{} as any}
              initialValues={schemasTemplate?.find(
                (v) => v.display_name == field?.display_name
              )}
            />
          )}
        </>
      )}
      {isExpression && (
        <Row>
          <Form.Item label="" name="">
            <Button
              type="text"
              onClick={() => setIsExpressionModalVisible(true)}
            >
              Expressions settings
            </Button>
          </Form.Item>
        </Row>
      )}
      {isMetadata && formValues?.input_type !== "date" && (
        <>
          <Row justify="space-between">
            <Col>
              <Form.Item label="" name={"isIdentifier"} valuePropName="checked">
                <Checkbox
                  disabled={isIdentifierDisabled}
                  onChange={(v) => handleChangeIdentifier(v.target.checked)}
                >
                  <Popconfirm
                    visible={showPopconfirm && formValues?.isIdentifier}
                    title={
                      <p style={{ width: "300px" }}>
                        By marking this field as Reference Identifier you
                        confirm that all the existing data for this field is
                        unique. You will not be able to change the identifier
                        once it's been chosen. Do you want to continue?
                      </p>
                    }
                    onConfirm={handleAddIdentifier}
                    onCancel={handleCancelIdentifier}
                    overlayClassName="popoverContent"
                  >
                    <Typography.Text>Reference Identifier</Typography.Text>
                  </Popconfirm>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label=""
                name={"expose"}
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>
                  <b style={{ marginRight: 5 }}>Expose ?</b>
                  <Tooltip
                    title={
                      "Check this field to expose this field to the user for searching"
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Row align="middle">
                <Form.Item
                  name={"status"}
                  valuePropName="checked"
                  initialValue={true}
                  label="Active"
                >
                  <Switch />
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row justify="space-between">
        <Form.Item label="" name="required" valuePropName="checked">
          <Checkbox className={styles.checkBox} disabled={isPrimaryKey}>
            Required
          </Checkbox>
        </Form.Item>
        {!isExpression && !isMetadata && (
          <>
            <Form.Item label="" name="primaryKey" valuePropName="checked">
              <Checkbox className={styles.checkBox} disabled={isSecondaryKey}>
                Primary Key
              </Checkbox>
            </Form.Item>
            <Popover
              overlayClassName="popoverContent"
              content={
                <div style={{ width: "250px", wordWrap: "break-word" }}>
                  Primary keys consist of one or more fields that uniquely
                  identify a record in the table. All primary key fields
                  together create a unique identifier for each record, ensuring
                  no two records have the same combination of values in these
                  fields. Use primary keys to uniquely define and access
                  specific records.
                </div>
              }
              placement="right"
              autoAdjustOverflow
              arrowPointAtCenter
            >
              <InfoCircleOutlined className={styles.popoverIcon} />
            </Popover>
            <Form.Item label="" name="secondaryKey" valuePropName="checked">
              <Checkbox className={styles.checkBox} disabled={isPrimaryKey}>
                Secondary Key
              </Checkbox>
            </Form.Item>
            <Popover
              overlayClassName="popoverContent"
              content={
                <div style={{ width: "250px", wordWrap: "break-word" }}>
                  Secondary keys are fields used to compare incoming data with
                  existing records in the table. If the incoming data has the
                  same primary key as an existing record but differs in at least
                  one of the secondary key fields, an upsert is performed to
                  update the record. Use secondary keys to determine when to
                  update existing data based on specific attributes, without
                  altering the unique identification of records.
                </div>
              }
              placement="right"
              autoAdjustOverflow
              arrowPointAtCenter
            >
              <InfoCircleOutlined className={styles.popoverIcon} />
            </Popover>
          </>
        )}
      </Row>
      <Typography.Title level={3}>Actions</Typography.Title>
      <Row>
        <Button
          disabled={currentFieldIndex == 0}
          className={`margin-5 ${styles.ghostButton}`}
          type="primary"
          onClick={() => handleMoveColumn(-1)}
        >
          Move to the left
        </Button>
        <Button
          disabled={currentFieldIndex == currentSchema?.length - 1}
          onClick={() => handleMoveColumn(+1)}
          className={`margin-5 ${styles.ghostButton}`}
          type="primary"
        >
          Move to the right
        </Button>
        <Button
          className={`margin-5 ${styles.ghostButton}`}
          type="primary"
          onClick={() => handleDeleteColumn()}
        >
          <DeleteOutlined />
        </Button>
      </Row>

      <UnitConversionsModal
        visible={conversionModal}
        data={{
          measure: formValues?.measure || "",
          customConversions: formValues?.customConversions || [],
          index: currentFieldIndex || 0,
        }}
        onClose={handleCloseConversionModal}
        onSave={handleSaveConversions}
      />
      <CreateExpressionModal
        visible={isExpressionsModalVisible}
        schema={currentSchema}
        onClose={() => setIsExpressionModalVisible(false)}
        onConfirm={handleSaveExpression}
        columnData={formValues}
        isEditMode={isAlreadyExists()}
      />
      <ConfirmModal
        onConfirm={() => onOpenUnitModal()}
        visible={isConfirmConversionModalVisible}
        setFalse={() => setConfirmConversionModalVisible(false)}
        infoConfirmModal={infoConfirmModal}
      />
    </FormWrapper>
  );
};
