import React, { ChangeEvent, useEffect, useState } from "react";
import { useBoolean, useDynamicList } from "ahooks";
import { Button, Checkbox, Input, Badge } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { StyledButton, FormWrapper } from "../../shared/commonStyles";
import { SelectedItems } from "../SelectedItems";
import { FormattedCategory } from "../../pages/carbonPage/Calculator/Components/EmissionsLibraryModal";
import styles from "./EmissionsFilter.module.scss";

interface EmissionsFilterProps {
  data: FormattedCategory[];
  datatype: string;
  onChange: (data: FormattedCategory[], type: string) => void;
}

export interface FormattedData {
  key: string;
  name: string;
  checked: boolean;
  children?: React.ReactNode;
}

export const EmissionsFilter = ({
  data,
  datatype,
  onChange,
}: EmissionsFilterProps) => {
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const [searchName, setSearchName] = useState("");

  const { list, replace, resetList } = useDynamicList<FormattedCategory>(data);

  useEffect(() => {
    if (data) {
      resetList(
        data.map((item) => {
          return { name: item.name, checked: item.checked };
        })
      );
    }
    setSearchName("");
  }, [data]);

  const handleChange = (item: FormattedCategory) => {
    const indexItem = list.findIndex((i) => i.name === item.name);
    replace(indexItem, item);
  };

  const handleChangeSelectedItems = (item: FormattedCategory) => {
    const indexItem = list.findIndex((i) => i.name === item.name);
    if (data[indexItem].checked) {
      const newList = [...list];
      newList[indexItem] = item;
      resetList(newList);
      onChange(newList, datatype);
    } else {
      handleChange(item);
    }
  };

  const handleClear = () => {
    let newList = [...list];
    list.forEach((v, index) =>
      replace(index, { name: v.name, checked: false })
    );
    newList = list.map((v) => ({ name: v.name, checked: false }));
    onChange(newList, datatype);
  };

  const handleCancel = () => {
    resetList(data);
    hideModal();
  };

  const handleChangeSearchName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const filterData = list.filter((item) =>
    searchName
      ? item.name.toLowerCase().includes(searchName.toLowerCase())
      : item
  );

  const handleConfirm = () => {
    onChange(list, datatype);
    hideModal();
  };

  return (
    <div>
      <div>
        <Badge
          count={list.filter((item) => item.checked).length}
          offset={[-50, 42]}
          color="#A68DFB"
          size="small"
        >
          <Input
            readOnly
            className={styles.selector}
            value={datatype.split("_")[0]}
            suffix={<DownOutlined />}
            onFocus={showModal}
          />
        </Badge>
      </div>
      <div
        className={
          isModalVisible ? styles.selectionBox : styles.selectionBoxHidden
        }
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={datatype.split("_")[0]}
          className={styles.checkboxSearch}
          value={searchName}
          onChange={handleChangeSearchName}
        />
        <div
          className={
            list.find((item) => item.checked)
              ? styles.checkSelected
              : styles.checkHidden
          }
        >
          <SelectedItems
            data={list.map(({ name, checked }) => {
              return { key: name, name, checked };
            })}
            datatype={datatype}
            onChange={(checked, _, value) => {
              handleChangeSelectedItems({
                name: value,
                checked,
              });
            }}
          />
        </div>
        <FormWrapper className={styles.filtersList}>
          {filterData.map((v) => (
            <div
              key={v.name}
              className={v.checked ? styles.checkActive : styles.check}
            >
              <Checkbox
                checked={v.checked}
                onChange={(e) =>
                  handleChange({
                    name: v.name,
                    checked: e.target.checked,
                  })
                }
              >
                {v.name}
              </Checkbox>
            </div>
          ))}
        </FormWrapper>
        <div className={styles.footerButtons}>
          <Button type="text" onClick={handleCancel}>
            Cancel
          </Button>
          <StyledButton type="primary" onClick={handleConfirm}>
            Confirm
          </StyledButton>
          <Button
            type="text"
            className={styles.footerButtonsLast}
            onClick={handleClear}
          >
            Clear All
          </Button>
        </div>
      </div>
      {isModalVisible && (
        <div className={styles.backdrop} onClick={handleConfirm} />
      )}
    </div>
  );
};
