import React, { useEffect, useState } from "react";
import moment from "moment";
import { Spin, Row, Typography, message, Tooltip } from "antd";

import { YearMonthDayFormat } from "../../../shared/constants";
import { fetchRecordData, makeQuery } from "../../../services/dataSheet";
import { CommonTable } from "../../../shared/commonStyles";
import _ from "lodash";
import { getCompanyInfo } from "../../../services/companyInfo";
import { stripHtmlTags } from "../../../shared/commonFunctions";

const { Title } = Typography;

interface DataSheetDocumentsProps {
  dataSheet: DataSheet;
  loadingDataSheet: boolean;
  initialValues: any;
  step?: number;
}

export const DataSheetDocuments = ({
  dataSheet,
  loadingDataSheet,
  initialValues,
  step,
}: DataSheetDocumentsProps) => {
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const [orgDataSheetRecords, setOrgDataSheetRecords] = useState([]);
  const [dataSheetRecords, setDataSheetRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );

  const recordsTypes = {
    include: [],
    exclude: [
      RecordTypes.ARCHIVED,
      RecordTypes.IMPORTED_PENDING,
      RecordTypes.PENDING,
    ],
  };
  const loadDataSheetRecords = async () => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = makeQuery(
        {
          conditionalFilters: initialValues?.conditional_filters,
        },
        dataSheet,
        recordsTypes
      );
      const sheetRecords = await fetchRecordData(requestPayload);
      setTotalRecords(sheetRecords.filteredCount);

      const _dataSheetRecords = sheetRecords.data.map(
        (record: DataSheetRecord) => ({
          ...record,
          key: record._id.$oid,
        })
      );
      setOrgDataSheetRecords(_dataSheetRecords);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  const updateDataSheetRecords = async () => {
    if (!dataSheet) {
      return;
    }
    setLoadingSheetRecords(true);
    const schemas: any = {};
    dataSheet?.sheet_schema?.forEach((schema) => {
      schemas[schema.entity_name] = schema.unit_id;
    });

    const tempRecords = await Promise.all(
      orgDataSheetRecords.map(async (record: DataSheetRecord) => {
        const records = record[record.sheet_name];
        let temp: any = {};
        Object.keys(records || {}).forEach((key) => {
          if (schemas[key]) {
            temp[`${key}_unit_id`] = schemas[key];
          }
        });

        temp = {
          ...temp,
          ...records,
        };

        return {
          ...record,
          [record.sheet_name]: temp,
        };
      })
    );
    setDataSheetRecords(tempRecords as any);
    setLoadingSheetRecords(false);
  };

  useEffect(() => {
    if (dataSheet) {
      loadDataSheetRecords();
    }
  }, [dataSheet]);

  useEffect(() => {
    if (dataSheet) {
      updateDataSheetRecords();
    }
  }, [dataSheet, orgDataSheetRecords?.length]);

  useEffect(() => {
    step === 1 && dataSheet && loadDataSheetRecords();
  }, [initialValues?.conditional_filters]);

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const generateDynamicColumns = (dataSheetSchema: SheetSchema[]) => {
    if (dataSheetSchema?.length) {
      const columns = dataSheetSchema?.reduce((acc, item) => {
        const column = [];

        column.push({
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: 250,
          className:
            dataSheetRecords.length > 0 && initialValues?.aggregate[0]?.column
              ? item?.entity_name === initialValues?.aggregate[0]?.column
                ? "red"
                : ""
              : "",
          ellipsis: "false",
          editable: "true",

          render: (_: any, data: DataSheetRecord) => {
            try {
              if (item?.input_type === "date") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                let date;
                if (typeof value === "object" && value !== null) {
                  date =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value.$date).format(YearMonthDayFormat);
                } else {
                  date =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value).format(YearMonthDayFormat);
                }

                return date;
              } else if (item?.input_type === "checkbox") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                let newValue = "";
                if (Array.isArray(value)) {
                  newValue = value?.toString();
                } else {
                  newValue = value ? "TRUE" : "FALSE";
                }
                return newValue;
              } else if (item?.input_type === "number") {
                return companyDetails.decimal_places_number
                  ? Number(
                      data?.[data?.sheet_name]?.[item?.entity_name] || 0
                    )?.toFixed(companyDetails.decimal_places_number)
                  : Number(data?.[data?.sheet_name]?.[item?.entity_name] || 0);
              } else {
                return data?.[data?.sheet_name]?.[item?.entity_name];
              }
            } catch (error) {
              console.log("error", error);
              return "";
            }
          },
        });

        if (item.unit_id) {
          column.push({
            title: item.display_name + " Unit",
            dataIndex: item.entity_name + "_unit_id",
            width: "100px",
            editable: false,
            render: (_: any, data: DataSheetRecord) => {
              try {
                const value =
                  data?.[data?.sheet_name]?.[item.entity_name + "_unit_id"];
                return value ? value : null;
              } catch (error) {
                console.log("error", error);
                return "";
              }
            },
          });
        }
        return acc.concat(column as any);
      }, []);

      return columns;
    }
    return [];
  };
  const generateColumns = generateDynamicColumns(dataSheet?.sheet_schema);

  return (
    <Spin spinning={loadingDataSheet}>
      {dataSheet && (
        <CommonTable
          title={() => (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "baseline",
                }}
              >
                {!!dataSheet?.description?.length && (
                  <div
                    style={{
                      marginTop: "20px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {stripHtmlTags(dataSheet?.description)}
                  </div>
                )}

                <span
                  style={{
                    color: "#C1BFCD",
                    alignItems: "baseline",
                    padding: "5px 16px",
                  }}
                >
                  ({totalRecords || 0} records)
                </span>
              </Row>
            </div>
          )}
          loading={loadingSheetRecords}
          rowKey={(data: DataSheetRecord) => data?._id?.$oid}
          dataSource={dataSheetRecords}
          columns={generateColumns}
          rowClassName="dataSheetsTable"
          scroll={{ x: "max-content" }}
          pagination={false}
          className="tableUpperScroll"
        />
      )}
    </Spin>
  );
};
