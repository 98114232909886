import { Button, Form, Input, Spin } from "antd";
import React from "react";
import { CommonModal, FormWrapper } from "../../../../shared/commonStyles";

const ConfirmModal = ({
  isVisible,
  onClose,
  onSave,
  setInitialValues,
  loader,
}) => {
  const [form] = Form.useForm();
  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };
  return (
    <CommonModal
      width={500}
      title="Save report"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="Cancel" ghost onClick={onClose}>
          Cancel
        </Button>,
        <Button key="Create" type="primary" onClick={onSave} loading={loader}>
          Save
        </Button>,
      ]}
    >
      <FormWrapper>
        <Form form={form} onFieldsChange={(data) => onChangeFieldsData(data)}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="name"
            label="Report Name"
            rules={[{ required: true, message: "Please input !" }]}
          >
            <Input placeholder={"Enter Report name"} />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            name="description"
            label="Report Description"
          >
            <Input placeholder={"Enter Report Description"} />
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default ConfirmModal;
