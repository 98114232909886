import React, { useState, useEffect } from "react";
import { Space } from "antd";
import { Auth } from "aws-amplify";
import { ApprovedSection } from "./ApprovedSection";

const ApproveStatus = ({
  rowData,
  submitSignoffStatus,
  submitting,
  refreshUploadedFiles,
  isOtherButtonsDisabled,
}) => {
  const [auditRecord, setAuditRecord] = useState(null);
  useEffect(() => {
    try {
      const sheetName = rowData["sheet_name"];
      const dataSheetAudit = rowData[sheetName + " Audit"];
      if (dataSheetAudit) {
        getDataLog(dataSheetAudit);
      }
    } catch (e) {
      return null;
    }
  }, [rowData]);

  const getDataLog = async (auditDetails) => {
    const data = await Auth.currentSession();
    const username = data["accessToken"]["payload"]["username"];
    const selectedAuditDetail = auditDetails?.find(
      (item) => item?.username === username
    );
    setAuditRecord(selectedAuditDetail);
  };

  return (
    <Space direction="horizontal">
      <ApprovedSection
        isOtherButtonsDisabled={isOtherButtonsDisabled}
        rowData={rowData}
        submitSignoffStatus={submitSignoffStatus}
        submitting={submitting}
        status="approved"
        auditRecord={auditRecord}
        refreshUploadedFiles={refreshUploadedFiles}
      />
      <ApprovedSection
        isOtherButtonsDisabled={isOtherButtonsDisabled}
        rowData={rowData}
        submitSignoffStatus={submitSignoffStatus}
        submitting={submitting}
        status="denied"
        auditRecord={auditRecord}
        refreshUploadedFiles={refreshUploadedFiles}
      />
    </Space>
  );
};

export default ApproveStatus;
