import React from "react";
import { Popover, Row, Spin, Typography } from "antd";
import { CommonTable } from "../../../../shared/commonStyles";
import { stripHtmlTags } from "../../../../shared/commonFunctions";

interface DataSheetsRecordsTableProps {
  dataSheet: DataSheet;
  dataSheetRecords: DataSheetRecord[];
  getFieldValue: (
    data: DataSheetRecord,
    item: SheetSchema,
    isUnitId?: boolean
  ) => void;
  loading: boolean;
  recordsCount: number;
}
export const DataSheetsRecordsTable = ({
  dataSheet,
  dataSheetRecords,
  getFieldValue,
  loading,
  recordsCount,
}: DataSheetsRecordsTableProps) => {
  const generateColumns = () => {
    const columns = dataSheet.sheet_schema?.map((item) => {
      const dataColumn = {
        title: item.display_name,
        dataIndex: item.entity_name,
        key: item.entity_name,
        width: "10%",
        render: (_: any, record: DataSheetRecord) => {
          const value = getFieldValue(record, item);

          return value;
        },
      };
      if (item.unit_id) {
        const unitColumn = {
          title: item.display_name + " Unit",
          dataIndex: item.entity_name + "_unit_id",
          key: item.entity_name + "_unit_id",
          width: "10%",
          render: (_: any, record: DataSheetRecord) => {
            const value = getFieldValue(record, item, true);
            return value;
          },
        };
        return [dataColumn, unitColumn];
      }
      return dataColumn;
    });

    return columns.flat();
  };

  return (
    <Spin spinning={false}>
      <CommonTable
        title={() => (
          <Row justify="space-between" wrap={false}>
            <Popover
              overlayClassName="popoverContent"
              placement="bottomLeft"
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataSheet.description,
                  }}
                />
              }
            >
              <Typography.Title
                className="color-white"
                level={4}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {stripHtmlTags(dataSheet.description)}
              </Typography.Title>
            </Popover>
            <span
              style={{
                color: "#C1BFCD",
                alignItems: "baseline",
                padding: "5px 16px",
                whiteSpace: "nowrap",
              }}
            >
              ({recordsCount} records)
            </span>
          </Row>
        )}
        pagination={false}
        dataSource={dataSheetRecords}
        columns={generateColumns()}
        rowClassName="dataSheetsTable"
        scroll={{ x: "max-content" }}
        className="tableUpperScroll"
        loading={loading}
      />
    </Spin>
  );
};
