import React from "react";
import { Col, Row } from "antd";
import { reductionPlanStore } from "../WhatIfOnboarding/ReductionPlanStore";
import { observer } from "mobx-react-lite";
import { getProgress } from "./ReductionPlanUtils";
import { KanbanTarget } from "./Components/KanbanTarget/KanbanTarget";
import styles from "./ReductionPlanKanbanView.module.scss";

export const ReductionPlanKanbanView = observer(() => {
  return (
    <Row justify="start">
      <Col className={styles.statusBox} span={7}>
        <span className={styles.statusTitle}>Not started</span>

        {reductionPlanStore.targets.map((item) => {
          if (getProgress(item) === 0)
            return <KanbanTarget data={item} key={item._id.$oid} />;
        })}
      </Col>
      <Col className={styles.statusBox} span={7}>
        <span className={styles.statusTitle}>In progress</span>

        {reductionPlanStore.targets.map((item) => {
          if (getProgress(item) > 0 && getProgress(item) < 100)
            return <KanbanTarget data={item} key={item._id.$oid} />;
        })}
      </Col>
      <Col className={styles.statusBox} span={7}>
        <span className={styles.statusTitle}>Completed</span>

        {reductionPlanStore.targets.map((item) => {
          if (getProgress(item) === 100)
            return <KanbanTarget data={item} key={item._id.$oid} />;
        })}
      </Col>
    </Row>
  );
});
