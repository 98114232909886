import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import * as queries from "../../../../graphql/queries";
import {
  TranserWrapper,
  CommonModal,
  FormWrapper,
  CommonTable,
} from "../../../../shared/commonStyles";
import { Space, message, Button, Input, Typography, Divider } from "antd";
import "../Components/styles.css";
import {
  getFirstAndLastNameByUsername,
  fetchProfileImage,
} from "../../../../shared/commonFunctions";
import { updateUserGroups, fetchUsers } from "./../../../../services/users";
import { AvatarImage } from "./AvatarImage";
const { Title, Text } = Typography;

const NewAssignUsersModal = ({
  visible,
  onClose,
  rowData,
  setShowAssignUsersModal,
}) => {
  const [userValue, setUserValue] = useState("");
  const [memeberValue, setMemberValue] = useState("");
  const [groupUsersList, setGroupUsersList] = useState([]);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [loadingGroupUsersList, setLoadingGroupUsersList] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [assignedUsersGroup, setAssignedUsersGroup] = useState([]);
  const [unAssignedUsersGroup, setUnAssignedUsersGroup] = useState([]);
  const [updateData, setUpdateData] = useState(0);
  const [userFilterTable, setUserFilterTable] = useState(null);
  const [memberFilterTable, setMemberFilterTable] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    const checkPermission = async () => {
      const data = await Auth.currentSession();
      const role = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("role:")
      )[0];
      if (role === "role:admin") {
        setIsAdmin(true);
      }
    };
    checkPermission();
  }, []);

  useEffect(() => {
    const assignedUsersGroupMap = [];
    const unAssignedUsersGroupMap = [];
    if (usersList && usersList.length /* && selectedUsersList */) {
      usersList.map((item) => {
        if (rowData?.group_users != undefined) {
          if (rowData.group_users.includes(item.key)) {
            assignedUsersGroupMap.push(item);
          } else {
            unAssignedUsersGroupMap.push(item);
          }
        } else {
          unAssignedUsersGroupMap.push(item);
        }
      });
    }
    setAssignedUsersGroup(assignedUsersGroupMap);
    setUnAssignedUsersGroup(unAssignedUsersGroupMap);
  }, [usersList, groupUsersList]);

  const getDataRelatedAttribute = (Attributes, key, email) => {
    try {
      const havingName = Attributes?.find(
        (item) => item?.["Name"] === key && item?.["Value"]
      );
      if (havingName != undefined) {
        return havingName; //return users who have name
      } else {
        return Attributes?.find((item) => item?.["Name"] === email); // return users who have email and empty name
      }
    } catch (error) {
      console.log("error while get attribute data in user management", error);
      return null;
    }
  };

  useEffect(() => {
    const dataLoad = async () => {
      setLoadingUsersList(true);
      try {
        const { users: usersList } = await fetchUsers({
          parameters: "{}",
          users: [],
        });

        if (usersList) {
          const updatedList = await Promise.all(
            await usersList.map(async (user) => {
              const nameDataObject = getDataRelatedAttribute(
                user["Attributes"],
                "name",
                "email"
              );
              let name = nameDataObject?.["Value"]
                ? nameDataObject?.["Value"]
                : "";
              if (!name && user?.["Username"]) {
                name = await getFirstAndLastNameByUsername(user["Username"]);
              }
              const imgUrl = await fetchProfileImage(user.Username);
              return {
                ...user,
                name,
                key: user["Username"],
                profieImgUrl: imgUrl,
              };
            })
          );
          setUsersList(updatedList);
        }
      } catch (error) {
        console.log("error while fetching users list", error);
        message.error("Error while fetching users list!");
      } finally {
        setLoadingUsersList(false);
      }
    };
    dataLoad();
  }, [updateData]);

  const onClickAssignLocalUsers = async () => {
    // handle assign local users
    try {
      // pushing username to payload
      setLoading(true);
      const newArr = assignedUsersGroup.reduce((arr, val) => {
        if ("Username" in val && !arr.includes(val["Username"])) {
          arr.push(val["Username"]);
        }
        return arr;
      }, []);

      rowData.group_users = newArr;
      delete rowData.first_last_name;

      delete rowData.permissionsData;
      delete rowData.metaDataRecords;
      const responseData = updateUserGroups(rowData);
      if (responseData) {
        message.success("Assigning Users to a group process completed");
        setShowAssignUsersModal(false);
      } else {
        message.error("error while assigning user");
      }
    } catch (error) {
      console.log("Something went wrong while try to assign users!", error);
      message.error("Something went wrong while try to assign users!");
    } finally {
      setUpdateData(updateData + 1);
      setLoading(false);
    }
  };

  const renderItem = (record) => {
    return (
      <span className="custom-item">
        <AvatarImage url={record.profieImgUrl} size={35} /> {record.name}
      </span>
    );
  };

  const handleAddUser = (record) => {
    setUnAssignedUsersGroup((current) =>
      current.filter((obj) => {
        return obj.Username !== record.Username;
      })
    );
    setAssignedUsersGroup((current) => [...current, record]);
  };

  const handleDeleteUser = (record) => {
    setAssignedUsersGroup((current) =>
      current.filter((obj) => {
        return obj.Username !== record.Username;
      })
    );
    setUnAssignedUsersGroup((current) => [...current, record]);
  };

  const unSelectedUserListColumn = [
    {
      key: "key",
      dataIndex: "name",
      title: "",
      width: "100%",
      render: (_, record) => renderItem(record),
    },
    {
      key: "operation",
      title: "",
      render: (_, record) =>
        isAdmin ? (
          <Button
            type="custom"
            className="addUserBtn"
            onClick={() => handleAddUser(record)}
          >
            Add User
          </Button>
        ) : null,
    },
  ];

  const selectedUserListColumn = [
    {
      key: "key",
      dataIndex: "name",
      title: "",
      width: "80%",
      render: (_, record) => renderItem(record),
    },
    {
      key: "operation",
      title: "",
      render: (_, record) =>
        isAdmin ? (
          <Button
            type="custom"
            className="deleteUserBtn"
            onClick={() => handleDeleteUser(record)}
          >
            <DeleteOutlined style={{ color: "white" }} />
          </Button>
        ) : null,
      width: "20%",
    },
  ];

  if (!rowData) return;
  return (
    <CommonModal
      title="Assign Users"
      visible={visible}
      onCancel={onClose}
      width={"37%"}
      footer={[
        <Button
          type="default"
          key="cancel"
          style={{
            background: "transparent",
            marginRight: "10px",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button type="primary" key="confirm" onClick={onClickAssignLocalUsers}>
          Confirm
        </Button>,
      ]}
      maskClosable={false}
      destroyOnClose
    >
      <FormWrapper style={{ width: "auto", position: "relative" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div className="groupTable">
            <Title level={4}>Add users</Title>
            <Text style={{ color: "#cbcbcb" }}>
              Select the users you want to add to the group
            </Text>
            <Divider
              style={{
                backgroundColor: "#ffffff2e",
                margin: "10px 0px 10px 0px",
              }}
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search for user"
              value={userValue}
              onChange={(e) => {
                const currValue = e.target.value;
                setUserValue(currValue);
                const filterData = unAssignedUsersGroup.filter((o) =>
                  Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(currValue.toLowerCase())
                  )
                );

                setUserFilterTable(filterData);
              }}
            />
            <TranserWrapper>
              <CommonTable
                loading={loadingUsersList}
                className="assignUserTable"
                columns={unSelectedUserListColumn}
                dataSource={
                  userFilterTable === null
                    ? unAssignedUsersGroup
                    : userFilterTable
                }
                pagination={false}
                style={{ width: "100%" }}
                scroll={{
                  y: 200,
                }}
              />
            </TranserWrapper>
          </div>
          <div className="groupTable">
            <Title level={4} style={{ display: "inline", marginRight: "5px" }}>
              Members{" "}
              <span style={{ color: "#cbcbcb", fontSize: "14px" }}>
                ({assignedUsersGroup.length} members)
              </span>
            </Title>
            <Divider
              style={{
                backgroundColor: "#ffffff2e",
                margin: "10px 0px 10px 0px",
              }}
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search for member"
              value={memeberValue}
              onChange={(e) => {
                const currValue = e.target.value;
                setMemberValue(currValue);
                const filterData = assignedUsersGroup.filter((o) =>
                  Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(currValue.toLowerCase())
                  )
                );

                setMemberFilterTable(filterData);
              }}
            />
            <TranserWrapper>
              <CommonTable
                loading={loadingGroupUsersList}
                className="assignUserTable"
                columns={selectedUserListColumn}
                dataSource={
                  memberFilterTable === null
                    ? assignedUsersGroup
                    : memberFilterTable
                }
                pagination={false}
                style={{ width: "100%" }}
              />
            </TranserWrapper>
          </div>
        </Space>
      </FormWrapper>
    </CommonModal>
  );
};

export default NewAssignUsersModal;
