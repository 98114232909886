import { Col, Radio, Row } from "antd";
import React, { useState } from "react";
import {
  CommonRadio,
  FormWrapper,
  PageWrap,
  TabContentWrap,
} from "../../../../shared/commonStyles";
import DeletedGeneralGoals from "./DeletedGeneralGoals";
import SdgGoal from "./sdgGoals/SdgGoal";
const GoalsTable = () => {
  const [section, setSection] = useState("general_goals");
  const onChange = (e) => {
    setSection(e.target.value);
  };
  return (
    <>
      <PageWrap>
        <FormWrapper>
          <Row gutter={[16, 18]} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={24} md={24} lg={17} style={{ marginTop: "5px" }}>
              <TabContentWrap>
                <Radio.Group
                  value={section}
                  onChange={onChange}
                  style={{ marginBottom: 16 }}
                  buttonStyle="solid"
                  size="large"
                >
                  <CommonRadio value="general_goals">General Goals</CommonRadio>
                  <CommonRadio value="sustainable_goals">
                    Sustainable Development Goals
                  </CommonRadio>
                </Radio.Group>
              </TabContentWrap>
            </Col>
          </Row>
          <Row>
            <Col lg={24} sm={24} md={24} xs={24}>
            {section === "general_goals" && <DeletedGeneralGoals /> }
            {section === "sustainable_goals" && <SdgGoal />}
            </Col>
          </Row>
        </FormWrapper>
      </PageWrap>
    </>
  );
};

export default GoalsTable;
