import {
  DeleteOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { getSheetById } from "../../../services/dataSheet";
import { queryOperators } from "../../../services/mongoOperators";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { DataSheetDocuments } from "./DataSheetDocuments";
import { ConditionalDataSheetFilters } from "../../../components/ConditionalDataSheetFilters/ConditionalDataSheetFilters";

const { Title } = Typography;

const DataSheetFilter = ({
  onClickNext,
  onClickPrevious,
  initialValues,
  setInitialValues,
  form,
}) => {
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  const [dataSheet, setDataSheet] = useState([]);

  const getSheetData = async () => {
    try {
      setLoadingDataSheet(true);

      const datasheet = await getSheetById(initialValues?.audit_items[0]);
      setDataSheet(datasheet);
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };
  useEffect(() => {
    if (initialValues?.audit_items?.length > 0) {
      getSheetData();
    }
  }, [initialValues?.audit_items]);

  const handleChangeFilters = (filters) => {
    setInitialValues((prev) => ({ ...prev, conditional_filters: filters }));
    // form.setFieldsValue({ conditional_filters: filters });
  };

  return (
    <FormWrap width={dataSheet ? "100%" : "40%"}>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col
            span={dataSheet ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Title level={3}>
                Data Filtering <span className="color-grey">(optional)</span>
              </Title>
              <p className="color-grey">
                Filter the data you need for the audit
              </p>
              <Row justify="space-between">
                <Col span={24}>
                  <Form.Item name="">
                    <ConditionalDataSheetFilters
                      dataSheet={dataSheet}
                      initialFilters={initialValues.conditional_filters}
                      addButtonText="Add datasheet filter"
                      onChangeFilters={handleChangeFilters}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <Col span={24}>
                <Form.Item>
                  <StyledButton
                    onClick={onClickPrevious}
                    type="custom"
                    hovercolor="#A68DFB"
                    hoverbgcolor="transparent"
                    bgcolor="transparent"
                    bordercolor="transparent"
                    color="#fff"
                  >
                    <span>Back</span>
                  </StyledButton>
                  <StyledButton onClick={onClickNext} type="custom">
                    <span>Continue to step 3</span> <RightOutlined />
                  </StyledButton>
                </Form.Item>
              </Col>
            </div>
          </Col>
          {dataSheet && (
            <Col span={12}>
              <DataSheetDocuments
                loadingDataSheet={loadingDataSheet}
                dataSheet={dataSheet}
                initialValues={initialValues}
              />
            </Col>
          )}
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default DataSheetFilter;
