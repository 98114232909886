import React, { useEffect, useState } from "react";
import { Input, Form, message, DatePicker, Button, Select } from "antd";
import {
  YearMonthDay24HourFormat,
  YearMonthDayFormat,
} from "../../../shared/constants";
import { CommonModal, FormWrapper } from "../../../shared/commonStyles";
import {
  addReportFilters,
  updateReportFilters,
} from "../../../services/filter";
import moment from "moment";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const FilterModalCommon = ({ visible, onClose, section, rowData, refresh }) => {
  const [title, setTitle] = useState("");
  const [loaderSubmitingData, setLoaderSubmitingData] = useState(false);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    period_range: "",
    status: "",
  });
  useEffect(() => {
    
    if (rowData) {
      try {
        const dateFormat = "YYYY/MM/DD";
        const today = moment();
        const dateStart = rowData?.date_start
          ? moment(rowData.date_start, dateFormat)
          : moment(today, dateFormat);
        const dateEnd = rowData?.date_end
          ? moment(rowData.date_end, dateFormat)
          : moment(today, dateFormat);
        const periodRange = [dateStart, dateEnd];

        const data = {
          description: rowData?.description ? rowData?.description : "",
          name: rowData?.name ? rowData?.name : "",
          period_range: periodRange,
          status: rowData?.status ? rowData?.status : "INACTIVE",
        };
        setInitialValues(data);
        form.setFieldsValue(data);
      } catch (error) {
        console.log("Error while pre fill form values", error);
      }
    }else{
      const data = {
        name: "",
        description: "",
        period_range: "",
        status: "",
      }
      setInitialValues(data)
      form.setFieldsValue(data);
    }
    
    
  }, [rowData]);

 
  const submitForm = () => {
    form
      .validateFields()

      .then(async (val) => {
        try {
          setLoaderSubmitingData(true);
          const { name, description, period_range, status } = val;
          const payload = {
            name,
            description,
            filter_type: section ? section : rowData?.filter_type,
            status: status ? status : "ACTIVE",
            date_start: period_range
              ? period_range[0].format(YearMonthDayFormat)
              : "",
            date_end: period_range
              ? period_range[1].format(YearMonthDayFormat)
              : "",
            modify_timestamp: moment().format(YearMonthDay24HourFormat),
            create_timestamp: rowData?.create_timestamp
              ? rowData?.create_timestamp
              : moment().format(YearMonthDay24HourFormat),
          };
          if (rowData?._id) {
            payload._id = rowData._id;

            const resp = await updateReportFilters(payload);

            if (resp) {
              message.success(`Successfully updated ${title}!`);
              refresh();
              onClose();
            } else {
              message.error("Something went wrong while creating!");
            }
          } else {
            const resp = await addReportFilters(payload);
            if (resp) {
              message.success(`Successfully created ${title}!`);
              refresh();
              onClose();

              console.log("Successfully created");
            } else {
              message.error("Something went wrong while Creating!");
            }
          }
        } catch (error) {
          message.error("Something went wrong creating by period.");
        } finally {
          setLoaderSubmitingData(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong creating by period.");
      });
  };

  useEffect(() => {
    if (section === "location") {
      setTitle("Location");
    }
    if (section === "business_unit") {
      setTitle("Business Unit");
    }
    if (section === "reporting_period") {
      setTitle("Reporting Period");
    }
  }, [section]);
  return (
    <CommonModal
      visible={visible}
      title={rowData ? `Update ${title}` : `Create New ${title}`}
      onCancel={(_) => onClose()}
      onOk={submitForm}
      centered
      loading={loaderSubmitingData}
      destroyOnClose={true}
      footer={[
        <Button key="Cancel" ghost onClick={(_) => onClose()}>
          Cancel
        </Button>,
        rowData ? (
          <Button
            key="Update"
            loading={loaderSubmitingData}
            type="primary"
            onClick={submitForm}
          >
            Update
          </Button>
        ) : (
          <Button
            key="Create"
            loading={loaderSubmitingData}
            type="primary"
            onClick={submitForm}
          >
            Create
          </Button>
        ),
      ]}
    >
      <FormWrapper>
        <Form form={form} initialValues={initialValues}>
          <Form.Item labelCol={{ span: 24 }} name="name" label="Name">
            <Input placeholder={"Enter name"} />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            name="description"
            label="Description"
          >
            <TextArea
              showCount
              maxLength={250}
              placeholder={"Enter description"}
            />
          </Form.Item>
          {(rowData && rowData.filter_type == "reporting_period") ||
          section === "reporting_period" ? (
            <Form.Item
              labelCol={{ span: 24 }}
              name="period_range"
              label="Date Range:"
              className="clearIcon"
            >
              <RangePicker />
            </Form.Item>
          ) : null}
          {rowData?.status ? (
            <Form.Item labelCol={{ span: 24 }} name="status" label="Status">
              <Select style={{ width: "30%" }}>
                <Option value="ACTIVE">ACTIVE</Option>
                <Option value="INACTIVE">INACTIVE</Option>
              </Select>
            </Form.Item>
          ) : null}
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default FilterModalCommon;
