import React, { useEffect, useState } from "react";
import {
  CommonModal,
  FormWrapper,
  StyledCollapse,
} from "../../../../../shared/commonStyles";
import { Col, Collapse, Popover, Row, Select, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { getDataSheets } from "../../../../../services/dataSheet";
import { InfoCircleOutlined } from "@ant-design/icons";

interface EmissionsDrillDownConfigureProps {
  data?: DrillDownFilter[];
  visible: boolean;
  onClose: () => void;
  onConfirm: (data: DrillDownFilter[]) => void;
}

export const EmissionsDrillDownConfigure = observer(
  ({ data, visible, onClose, onConfirm }: EmissionsDrillDownConfigureProps) => {
    const [drillDownFilters, setDrillDownFilters] = useState<DrillDownFilter[]>(
      []
    );
    const [dataSheetsList, setDataSheetsList] = useState<DataSheet[]>([]);
    const dataSheetIds = [
      ...new Set(
        calculatorStore.emissions
          ?.filter((v) => !v.manual)
          ?.map((item) => (item as AutomatedEmission).activity_sheet)
      ),
    ];
    const getDataSheetsList = async () => {
      const payload = {
        filter: { archive: false, _id: { $oid: dataSheetIds } },
      };
      const dataSheets = await getDataSheets(payload);
      setDataSheetsList(dataSheets);
    };
    useEffect(() => {
      visible && !!dataSheetIds?.length && getDataSheetsList();
      data && setDrillDownFilters(data);
    }, [visible]);
    const handleChangeColumn = (value: string, dataSheet: DataSheet) => {
      setDrillDownFilters((prev) => [
        ...prev?.filter((item) => item?.dataSheetId !== dataSheet._id.$oid),
        ...(!!value
          ? [
              {
                dataSheetId: dataSheet._id.$oid,
                column: value,
              },
            ]
          : []),
      ]);
    };
    const handleChangePeriod = (value: string, dataSheet: DataSheet) => {
      setDrillDownFilters((prev) => [
        ...prev?.filter((item) => item.dataSheetId !== dataSheet._id.$oid),
        {
          ...prev?.find((item) => item.dataSheetId === dataSheet?._id.$oid),
          column_value: value,
        } as DrillDownFilter,
      ]);
    };

    const handleConfirm = () => {
      onConfirm(drillDownFilters);
      onClose();
      setDataSheetsList([]);
      setDrillDownFilters([]);
    };

    const handleClose = () => {
      onConfirm(calculatorStore.calculation.drilldownFilters || []);
      setDataSheetsList([]);
      setDrillDownFilters([]);
      onClose();
    };

    return (
      <CommonModal
        onCancel={handleClose}
        onOk={handleConfirm}
        visible={visible}
        title="Configure Emissions Drill Down"
        width={"70%"}
      >
        <FormWrapper>
          <Row>
            The data sheet(s) below are used in at least one emission line item
            in this calculation. For each data sheet, please specify the data
            sheet column which you would like to use to break down each emission
            line item using data from that data sheet.{" "}
          </Row>
          {!dataSheetsList?.length ? (
            <Spin spinning={!dataSheetsList?.length}>
              <Typography.Text>No sheets to drill down</Typography.Text>
            </Spin>
          ) : (
            <StyledCollapse
              defaultActiveKey={drillDownFilters?.map((v) => v.dataSheetId)}
            >
              {dataSheetsList?.map((dataSheet) => {
                const currentFilter = drillDownFilters?.find(
                  (v) => v.dataSheetId === dataSheet._id.$oid
                );
                const dataType = dataSheet?.sheet_schema?.find(
                  (v) => v.entity_name === currentFilter?.column
                )?.input_type;
                return (
                  <Collapse.Panel
                    header={dataSheet?.sheet_name}
                    key={dataSheet?._id.$oid}
                  >
                    <Row align="middle" justify="space-between">
                      <Col span={5}>
                        <Typography.Text>Column Name: </Typography.Text>
                      </Col>
                      <Col span={18}>
                        <Select
                          allowClear
                          value={currentFilter?.column}
                          defaultValue={"None"}
                          options={dataSheet?.sheet_schema?.map((item) => ({
                            label: item.display_name,
                            value: item.entity_name,
                          }))}
                          placeholder="Select Column"
                          onChange={(value) =>
                            handleChangeColumn(value, dataSheet)
                          }
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    {!!drillDownFilters?.length && dataType === "date" && (
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ marginTop: 20 }}
                      >
                        <Col span={5}>
                          <Typography.Text>
                            Time period :{" "}
                            <Popover
                              overlayClassName="popoverContent"
                              overlayStyle={{ width: "50%" }}
                              placement="topRight"
                              content="Select the time period for which you want to view emissions breakdown. If you choose “Annual”, an emission line will be created for each year within the specified range. Alternatively, if you select “Monthly”, an emission line will be generated for each month within the chosen timeframe."
                            >
                              <InfoCircleOutlined />
                            </Popover>{" "}
                          </Typography.Text>
                        </Col>
                        <Col span={18}>
                          <Select
                            onChange={(value) =>
                              handleChangePeriod(value, dataSheet)
                            }
                            value={currentFilter?.column_value || ""}
                            options={[
                              { value: "monthly", label: "Monthly" },
                              { value: "annual", label: "Annual" },
                            ]}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    )}
                  </Collapse.Panel>
                );
              })}
            </StyledCollapse>
          )}
        </FormWrapper>
      </CommonModal>
    );
  }
);
