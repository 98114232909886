import React, { useState } from "react";
import { useSetState } from "ahooks";
import { Button, Input, InputNumber } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import { calculatorStore } from "../../../../Carbon";
import { observer } from "mobx-react-lite";
import styles from "./EditManualEmissionsModal.module.scss";

interface EditEmissionsModalProps {
  disabled?: boolean;
  data: Emission;
  editEmission: (data: Emission) => void;
}

export const EditManualEmissionsModal = observer(
  ({ data, editEmission, disabled }: EditEmissionsModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [manualEmissionData, setManualEmissionData] =
      useSetState<Emission>(data);

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleChangeValue = (value: number | string, field: string) => {
      setManualEmissionData((prevState) => ({ ...prevState, [field]: value }));
    };

    const isDisabledConfirm =
      manualEmissionData.factor_data.name &&
      manualEmissionData.factor_data.region_name &&
      manualEmissionData.factor_data.unit;

    const handleOk = async () => {
      editEmission(manualEmissionData);
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setManualEmissionData({ ...data });
      setIsModalVisible(false);
    };

    return (
      <>
        <Button onClick={showModal} disabled={disabled}>
          <EditOutlined />
          Edit
        </Button>
        <CommonModal
          centered
          title="Edit emission"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={758}
          footer={[
            <Button type="text" onClick={handleCancel} key="cancel">
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={handleOk}
              disabled={!isDisabledConfirm}
              key="confirm"
            >
              Edit Emission
            </Button>,
          ]}
        >
          <FormWrapper>
            <div className={styles.inputBlock}>
              <div>Name</div>
              <Input
                className={styles.inputSuccessStep}
                placeholder="Enter name"
                value={manualEmissionData.factor_data.name}
                onChange={(e) => handleChangeValue(e.target.value, "name")}
              />
            </div>

            <div className={styles.inputBlock}>
              <div>Region</div>
              <Input
                className={styles.inputSuccessStep}
                placeholder="Enter region"
                value={manualEmissionData.factor_data.region}
                onChange={(e) => {
                  handleChangeValue(e.target.value, "region");
                  handleChangeValue(e.target.value, "region_name");
                }}
              />
            </div>

            <div className={styles.inputBlock}>
              <div>Factor</div>
              <InputNumber
                className={styles.inputSuccessStep}
                placeholder="Enter factor"
                value={manualEmissionData.factor_data.factor}
                onChange={(value) => handleChangeValue(value, "factor")}
              />
            </div>

            <div className={styles.inputBlock}>
              <div>Unit</div>
              <Input
                className={styles.inputSuccessStep}
                placeholder="Enter unit"
                value={manualEmissionData.factor_data.unit}
                onChange={(e) => handleChangeValue(e.target.value, "unit")}
              />
            </div>

            <div className={styles.inputBlock}>
              <div>Consumption amount</div>
              <InputNumber
                className={styles.inputSuccessStep}
                placeholder="Enter consumption amount"
                value={manualEmissionData.consumption_amount}
                onChange={(value) =>
                  handleChangeValue(value, "consumption_amount")
                }
              />
            </div>

            {manualEmissionData.override && (
              <div className={styles.inputBlock}>
                <div>Total emission</div>
                <InputNumber
                  className={styles.inputSuccessStep}
                  placeholder="Enter total emission"
                  value={manualEmissionData.total_emission?.co2e_total || 0}
                  onChange={(value) =>
                    handleChangeValue(value, "total_emission?.co2e_total")
                  }
                />
              </div>
            )}
          </FormWrapper>
        </CommonModal>
      </>
    );
  }
);
