

import React, { useState, useEffect } from "react";
import { StyledProgress } from "../../../../shared/commonStyles";
import { message, Spin  } from 'antd';
import {sendTaskReminder} from '../../../../services/goals';

const ProgressBar = ({record}) => {
  const [loader, setLoader] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const loadMetricData = async() => {
    try{
      setLoader(true)
      const data = await sendTaskReminder('GOALS_OVERVIEW')
      const metricsData = data ? JSON.parse(data) : ''
      if(metricsData && metricsData?.general_progress_by_name){
        const percentageData = Object.keys(metricsData?.general_progress_by_id)?.find(key => key === record?.id)
        setPercentage(percentageData ? metricsData?.general_progress_by_id[percentageData]?.progress : 0)
      }
      
    }catch(e){
      message.error(e)
    }finally{
      setLoader(false)
    }    
  }

  useEffect(() => {
    loadMetricData()
  },[record])

  return (
    <Spin spinning={loader}>
      <StyledProgress percent={percentage} size="default" strokeWidth={10} trailColor="#3E3850" strokeColor="#A68DFB" width="60" style={{ width: '100%', margin:'15px 0'}} />
    </Spin>
  )
}
export default ProgressBar;
