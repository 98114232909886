import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useDynamicList } from "ahooks";

import {
  EllipsisOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Popover, InputNumber, message } from "antd";
import { CommonTable, FormWrapper } from "../../../../../shared/commonStyles";
import {
  fractionalNumber,
  getLinkDataSheet,
} from "../../../../Carbon/CalculationUtils";
import styles from "./DeletedItemsTable.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { carbonStore } from "../../../../Carbon/CarbonStore";
import TableActionsButtons from "../../../Calculator/Components/TableActionsButtons";
import { getCompanyInfo } from "../../../../../services/companyInfo";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";

export const EditableContext = React.createContext(null);

interface DeletedItemsTableProps {
  data: Emission[];
  isCalculationLocked?: boolean;
  onRestore: (data: Emission) => void;
  onDelete: (data: Emission) => void;
  onSaveTableData: (data: Emission, consumptionAmount: number) => void;
  emissionsUnit: string;
  conversionFactor: number;
}

type Record = {
  co2e_total: number;
  co2: number;
  ch4: number;
  n2o: number;
};

const DeletedItemsTable = observer(
  ({
    isCalculationLocked,
    data,
    onRestore,
    onDelete,
    onSaveTableData,
    emissionsUnit,
    conversionFactor,
  }: DeletedItemsTableProps) => {
    const {
      list: consumptionAmount,
      replace,
      resetList,
    } = useDynamicList(
      toJS(data).map((emission) => emission.consumption_amount)
    );
    const navigate = useNavigate();
    const [emissionsData, setEmissionsData] = useState(data);

    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );

    useEffect(() => {
      resetList(toJS(data).map((emission) => emission.consumption_amount));
      setEmissionsData(data);
    }, [data]);

    useEffect(() => {
      loadCompanyInfo();
    }, []);

    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";

          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
        message.error("Error while load company details!");
      }
    };

    const onDeleteFromEmissionsData = (emission: Emission) => {
      const newData = emissionsData.filter((item) => item.key !== emission.key);
      setEmissionsData(newData);
    };
    const onClickUndo = () => {
      setEmissionsData(data);
    };
    const handleViewVersion = (historyData: CalculatorEntryHistory) => {
      const viewingData = historyData.data as Emission;

      const emissionIndex = emissionsData.findIndex(
        (item) => item?.key === viewingData?.key
      );
      const newData = emissionsData.map((item, index) =>
        index === emissionIndex ? viewingData : item
      );

      setEmissionsData(newData);
    };
    const onGoCurrentVersion = () => {
      setEmissionsData(data);
    };
    const convertedNumber = fractionalNumber(
      companyDetails.decimal_places_number
    );

    const convertedFactor = fractionalNumber(4);

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "10%",
        sorter: (a: Emission, b: Emission) =>
          a.factor_data.name.localeCompare(b.factor_data.name),
        render: (data: any, elem: Emission) => <>{elem.factor_data.name}</>,
      },
      {
        title: "Region",
        dataIndex: "region_name",
        key: "region_name",
        width: "10%",
        sorter: (a: Emission, b: Emission) =>
          a.factor_data.region_name.localeCompare(b.factor_data.region_name),
        render: (data: any, elem: Emission) => (
          <>{elem.factor_data.region_name}</>
        ),
      },
      {
        title: "Custom name",
        dataIndex: "custom_name",
        key: "custom_name",
        width: "10%",
        sorter: (a: Emission, b: Emission) =>
          !!a.custom_name?.length &&
          !!b.custom_name?.length &&
          a.custom_name.localeCompare(b.custom_name),
      },
      {
        title: "Consumption Amount",
        dataIndex: "consumption_amount",
        key: "consumption_amount",
        width: "15%",
        className: styles.consumptionColumn,

        render: (data: number, elem: Emission, index: number) => {
          return (
            <>
              {!elem.manual ? (
                <div className={styles.emissionColumn}>
                  <div className={styles.itemCell}>
                    <span
                      className={`${
                        ((elem as AutomatedEmission).missing_data &&
                          styles.itemSpanWarning) ||
                        styles.emissionFullWidth
                      } 
                          ${styles.itemSpanInfo}`}
                    >
                      <span
                        className={`${
                          ((elem as AutomatedEmission).missing_data &&
                            styles.emissionNumber) ||
                          ""
                        }`}
                      >
                        {convertedNumber(data)}
                      </span>
                      <span
                        className={`${
                          ((elem as AutomatedEmission).missing_data &&
                            styles.emissionUnit) ||
                          styles.emissionUnitFullWidth
                        }`}
                      >
                        {elem.factor_data.unit.split("/").at(-1)}
                      </span>

                      {(elem as AutomatedEmission).missing_data && (
                        <span className={styles.emissionWarning}>
                          <Popover
                            placement="right"
                            content={
                              <>
                                <div className={styles.warningPopoverTitle}>
                                  Warning
                                </div>
                                <div>
                                  Some pieces of data are missing. Review the
                                  data sheet to increase the accuracy of the
                                  calculation
                                </div>
                                <div className={styles.warningPopoverLink}>
                                  <Link
                                    to={
                                      getLinkDataSheet(
                                        elem as AutomatedEmission,
                                        carbonStore.getSheetById(
                                          (elem as AutomatedEmission)
                                            .activity_sheet
                                        )
                                      ).url
                                    }
                                    onClick={() =>
                                      localStorage.setItem(
                                        "filters",
                                        JSON.stringify(
                                          getLinkDataSheet(
                                            elem as AutomatedEmission,
                                            carbonStore.getSheetById(
                                              (elem as AutomatedEmission)
                                                .activity_sheet
                                            )
                                          ).filtersArray
                                        )
                                      )
                                    }
                                    target="_blank"
                                  >
                                    View Data Sheet
                                  </Link>
                                </div>
                              </>
                            }
                            className={styles.warningIcon}
                            overlayClassName={`popoverContent ${styles.warningPopover}`}
                            autoAdjustOverflow
                          >
                            <WarningOutlined />
                          </Popover>
                        </span>
                      )}
                    </span>
                  </div>
                  <Popover
                    placement="right"
                    title={"Consumption Amount is calculated automatically"}
                    content={
                      <>
                        <p>
                          Go to{" "}
                          <EllipsisOutlined
                            className={styles.infoIconOutlined}
                          />{" "}
                          then click ”Edit” to edit this emission
                          <br /> Go to{" "}
                          <EyeOutlined className={styles.infoIconOutlined} /> to
                          view more details
                        </p>
                      </>
                    }
                    className={styles.infoIcon}
                    overlayClassName="popoverContent"
                    arrowPointAtCenter
                    autoAdjustOverflow
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              ) : (
                <div className={styles.emissionColumn}>
                  <span className={styles.itemCell}>
                    <FormWrapper>
                      <InputNumber
                        className={styles.itemInput}
                        size="large"
                        min={0}
                        value={data}
                        onChange={(value) => replace(index, Number(value || 0))}
                        onPressEnter={() => {
                          onSaveTableData(elem, consumptionAmount[index]);
                        }}
                        onBlur={() => {
                          onSaveTableData(elem, consumptionAmount[index]);
                        }}
                      />
                    </FormWrapper>
                    <span style={{ width: "40px" }}>
                      {elem.factor_data.unit.split("/").at(-1)}
                    </span>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        title: "CO2e Factor",
        dataIndex: "co2e_emission_factor",
        key: "co2e_emission_factor",
        width: "10%",
        render: (_: any, data: Emission) => {
          const co2 = data?.factor_data.constituent_gases?.co2
            ? data?.factor_data.constituent_gases?.co2
            : 0;
          const ch4 = data?.factor_data.constituent_gases?.ch4
            ? data?.factor_data.constituent_gases?.ch4
            : 0;
          const n2o = data?.factor_data.constituent_gases?.n2o
            ? data?.factor_data.constituent_gases?.n2o
            : 0;
          const unit = data?.factor_data.unit ? data?.factor_data.unit : "";
          const content = (
            <div>
              <p>
                <b>
                  Co<sub>2</sub>
                </b>
                : {co2 ? convertedFactor(co2) + " " + unit : "NA"}
              </p>
              <p>
                <b>
                  Ch<sub>4</sub>
                </b>
                : {ch4 ? convertedFactor(ch4) + " " + unit : "NA"}
              </p>
              <p>
                <b>
                  {" "}
                  N<sub>2</sub>o
                </b>
                : {n2o ? convertedFactor(n2o) + " " + unit : "NA"}
              </p>
            </div>
          );
          return (
            <div className={styles.emissionColumn}>
              <div className={styles.itemCell}>
                <span className={styles.itemSpan}>
                  {data?.factor_data.factor
                    ? convertedFactor(data?.factor_data.factor)
                    : 0}
                </span>

                <Popover
                  placement="top"
                  title="Emission Factors"
                  content={content}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </div>
          );
        },
      },

      {
        title: `Total Emission (${emissionsUnit} CO2e)`,
        dataIndex: "total_emission",
        key: "total_emission",
        width: "10%",
        render: (record: Record, data: Emission) => {
          const emissionTotal = Number(data.emission || 0) * conversionFactor;

          return (
            <div className={styles.emissionColumn}>
              {emissionTotal && (
                <div>{convertedNumber(emissionTotal * conversionFactor)}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (_: any, data: Emission, index: number) => {
          return (
            <TableActionsButtons
              isCalculationLocked={isCalculationLocked}
              data={data}
              isDeletedItems={true}
              onRestoreElement={() => onRestore(data)}
              onDeleteFromEmissionsData={() =>
                onDeleteFromEmissionsData(data as Emission)
              }
              onDelete={() => onDelete(data)}
              onViewHistory={(historyData) => handleViewVersion(historyData)}
              onClickUndo={onClickUndo}
              onGoCurrent={onGoCurrentVersion}
            />
          );
        },
      },
    ];

    return (
      <CommonTable
        rowKey={(record:Emission) => record?._id?.$oid || record?.key}
        className="calculationTable"
        loading={calculatorStore.loadingCalculationItems}
        rowClassName={() => "editableRow"}
        columns={columns}
        dataSource={emissionsData}
        pagination={false}
        scroll={{ x: true }}
        size="small"
        childrenColumnName="empty"
      />
    );
  }
);

export default DeletedItemsTable;
