import { Button } from "antd";
import React from "react";
import { StyledButton } from "../../../../../shared/commonStyles";
import styles from "../../WhatIfOnboarding.module.scss";

interface ScienceBasedTargetsInfoProps {
  onNext: () => void;
  onBack: () => void;
}

export const ScienceBasedTargetsInfo = ({
  onNext,
  onBack,
}: ScienceBasedTargetsInfoProps) => {
  return (
    <div className={styles.content}>
      <span className={styles.subtitle}>Science based targets</span>
      <div>
        <p className={styles.text}>
          Science-based targets provide a clearly-defined pathway for companies
          to reduce greenhouse gas (GHG) emissions, helping prevent the worst
          impacts of climate change and future-proof business growth.{" "}
        </p>{" "}
        <p className={styles.text}>
          Targets are considered ‘science-based’ if they are in line with what
          the latest climate science deems necessary to meet the goals of the
          Paris Agreement – limiting global warming to well-below 2°C above
          pre-industrial levels and pursuing efforts to limit warming to 1.5°C.
        </p>
      </div>
      <span className={styles.textBold}>
        Learn more at{" "}
        <a href="https://sciencebasedtargets.org">
          https://sciencebasedtargets.org
        </a>
      </span>
      <div className={styles.buttons}>
        <Button type="text" onClick={onBack}>
          Back
        </Button>
        <StyledButton type="primary" onClick={onNext}>
          Continue
        </StyledButton>
      </div>
    </div>
  );
};
