import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Spin, message } from "antd";
import {
  BarChartOutlined,
  AreaChartOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import { API, graphqlOperation } from "aws-amplify";
import { ContentSection, CardsWrap } from "../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { datalake } from "../../graphql/queries";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { COLORS } from "../../shared/colors";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { openInNewTab } from "../../shared/commonFunctions";
import { getCompanyInfo } from "../../services/companyInfo";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

export const Analytics = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);
  const [isBaseUrl, setIsBaseURl] = useState("");

  useEffect(() => {
    const loadCompanyInfo = async () => {
      const baseUrlResponse = await getCompanyInfo();
      if (baseUrlResponse) {
        const companyDetailsData = JSON.parse(baseUrlResponse["data"]);
        if (companyDetailsData.chart_base_url) {
          setIsBaseURl("isAvailable");
        } else {
          setIsBaseURl("isNotAvailable");
        }
      }
    };
    loadCompanyInfo();
  }, []);

  useEffect(() => {
    let unMounted = false;
    (async () => {
      try {
        setLoading(true);
        const response = await API.graphql(
          graphqlOperation(datalake, {
            request_type: "get_dashboard_charts",
          })
        );

        const records = JSON.parse(response["data"]["datalake"]);

        setCards(records.data);
      } catch (error) {
        console.log("Error while loading dashboard charts list!");
      } finally {
        setLoading(false);
      }

      if (unMounted) return;
    })();

    return () => (unMounted = true);
  }, []);

  const filterdCardData = cards.filter((item) => item.status == "ACTIVE");

  const categorySection = filterdCardData.reduce((acc, curr) => {
    const { section } = curr;

    if (!acc[section]) {
      acc[section] = {
        items: [],
      };
    }

    acc[section].items.push(curr);

    return acc;
  }, {});

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/analytics/create-new-dashboard":
        openInNewTab("https://account.mongodb.com/account/login");
        break;
      case "/analytics/manage-dashboards":
        navigate(section);
        break;

      default:
    }
  };
  const popularActions = [
    {
      permission: "analytics-charts-page",
      cardTitle: "Create new dashboard",
      cardIcon: <AreaChartOutlined />,
      iconColor: "linear-gradient(180deg, #FC7786 0%, #E66270 100%)",
      cardLink: "/analytics/create-new-dashboard",
      moduleName: "ANALYTICS_DASHBOARDS",
    },
    {
      permission: "analytics-charts-page",
      cardTitle: "Manage Dashboards",
      cardIcon: <CalculatorOutlined />,
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/analytics/manage-dashboards",
      moduleName: "ANALYTICS_DASHBOARDS",
    },
  ];
  useEffect(() => {
    popularActions.map((card) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);

  message.config({
    maxCount: 1,
    duration: 10,
  });

  return (
    <ContentSection>
      {popularActions.length > 0 &&
        isBaseUrl === "isAvailable" &&
        !checkRolesPermission(["role:elevateduser"]) && (
        <>
          {showPopluarActions && (
            <Typography.Title
              level={2}
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Popular Actions
            </Typography.Title>
          )}
          <CardsWrap>
            {popularActions.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <PopularActionCard
                    title={card.cardTitle}
                    iconColor={card.iconColor}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}
      {checkPermissions(["analytics-charts-page"]) &&
        isBaseUrl === "isAvailable" && (
          <Spin spinning={loading}>
            {Object.keys(categorySection).map((value, index) => (
              <React.Fragment key={index}>
                <Typography.Title
                  level={2}
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                >
                  {value}
                </Typography.Title>
                <CardsWrap>
                  {categorySection[value].items.map((card, index) => (
                    <CustomIconCard
                      title={card.chart_name}
                      customCardStyle={customIconCardStyle.cardStyle}
                      titleStyle={customIconCardStyle.titleStyle}
                      onClick={() => navigate(`/analytics/${card?._id?.$oid}`)}
                      iconComponent={
                        <BarChartOutlined
                          style={customIconCardStyle.iconStyle}
                        />
                      }
                      key={index}
                      isLockModule={card.moduleName ? card.moduleName : false}
                    />
                  ))}
                </CardsWrap>
              </React.Fragment>
            ))}
          </Spin>
        )}
      {isBaseUrl === "isNotAvailable" && (
        <>
          {message.error(
            "In order to use Analytics, a Chart Base URL must be specified. Please specify it in company settings or contact your account manager."
          )}
        </>
      )}
    </ContentSection>
  );
};
