import React from "react";
import { Link } from "react-router-dom";
import { Button, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { StyledButton } from "../../../shared/commonStyles";
import { companyInfoStore } from "../../Carbon/CompanyInfoStore";
import styles from "./WhatIfOnboarding.module.scss";

export const HeaderWhatIf = () => {
  const handleSave = () => {
    companyInfoStore.updateOnboardingCompletion();
  };

  return (
    <>
      <Row justify="space-between" align="middle" className={styles.header}>
        <StyledButton
          type="custom"
          hovercolor="#A68DFB"
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#fff"
        >
          <Link to="/carbon">
            <ArrowLeftOutlined className={styles.exitIcon} />
            <span>Exit</span>
          </Link>
        </StyledButton>
        <StyledButton type="primary" onClick={handleSave}>
          <Link to="/carbon/reduction-plan">
            Skip & Go to reduction tracker
          </Link>
        </StyledButton>
      </Row>
    </>
  );
};
