import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { datalake } from "../graphql/queries";
import * as queries from "../graphql/queries";
import { DatalakeQuery } from "../graphql/API";

class SurveyKpiService {
  async makeRequest(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    return JSON.parse(response.data?.datalake || "{}");
  }

  async makeQuery(query: string, variables?: any) {
    return (await API.graphql({
      query: queries[query as keyof typeof queries],
      variables: variables,
    })) as GraphQLResult<any>;
  }

  public async surveyKpisList(payload?: ListKpiQuery) {
    return await this.makeRequest("list_survey_kpi", payload);
  }
  public async surveyReviewKpisList(payload?: ListKpiQuery) {
    return await this.makeRequest("list_survey_kpi_for_review", payload);
  }
  public async addSurveyKpi(payload?: any[]) {
    return await this.makeRequest("add_survey_kpi", payload);
  }
  public async updateSurveyKpi(payload?: any[]) {
    return await this.makeRequest("update_survey_kpi", payload);
  }

  public async massApproveSurveyKpi(payload?: ListKpiQuery) {
    return await this.makeRequest("mass_approve_survey_kpi", payload);
  }
  public async approveSurveyKpi(id?: string) {
    return await this.makeRequest("approve_survey_kpi", { _id: { $oid: id } });
  }
  public async getSurveyKpiFilters(sheet_id?: string) {
    return await this.makeRequest("get_survey_kpi_filters", { sheet_id });
  }
  public async sendUserDenyKPIReminder(id: string) {
    const payload = {
      request_type: "KPI_DENIAL",
      record_id: id,
    };
    const response: any = await this.makeQuery("sendTaskReminder", payload);
    return response["data"]["sendTaskReminder"];
  }
  public async sendUserApproveKPIReminder(id: string) {
    const payload = {
      request_type: "KPI_APPROVAL",
      record_id: id,
    };
    const response: any = await this.makeQuery("sendTaskReminder", payload);
    return response["data"]["sendTaskReminder"];
  }
}

export default new SurveyKpiService();
