import React from "react";
import { Drawer } from "antd";
import { OffsetInfo } from "../InfoAndNotesDrawer/OffsetInfo";
import styles from "./OffsetDrawer.module.scss";

interface OffsetDrawerProps {
  visible: boolean;
  data: Offset;
  onClose: () => void;
}

export const OffsetDrawer = ({ visible, data, onClose }: OffsetDrawerProps) => {
  return (
    <Drawer
      width={400}
      className={styles.drawer}
      closable={false}
      placement="right"
      visible={visible}
      onClose={onClose}
    >
      <h2 className={styles.drawerTitle}>{data.name}</h2>
      <OffsetInfo data={data} onClose={onClose} />
    </Drawer>
  );
};
