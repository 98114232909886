import React, { useEffect } from "react";
import { Spin } from "antd";
import { SignOffProgressBar } from "./SignOffProgressBar";
import { useAssignedAuditorsList } from "./../../../hooks/useAssignedAuditorsList";
export const SignOffCircleProgress = ({ rowData,auditData }) => {

  const {
    loading,
    assignedAuditorsList,
    getUniqueUsersList,
    loadAssignedAuditors,
  } = useAssignedAuditorsList();

  const signedOffStatusUsersList = getUniqueUsersList(assignedAuditorsList);
  useEffect(() => {    
    if (rowData) {
      loadAssignedAuditors(rowData, auditData);
    }
  }, [rowData, auditData]);  

  return (
    <>
      {rowData?.id && (
        <Spin spinning={loading}>
          {signedOffStatusUsersList && (
            <SignOffProgressBar
              signedOffStatusUsersList={signedOffStatusUsersList}
            />
          )}
          <br />
        </Spin>
      )}
    </>
  );
};
