import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const dataIntegration = async () => {
  const response = await API.graphql({
    query: queries["dataIntegration_job"],
    variables: { request_type: "EHS_REPORT" },
  });
  const resp = response["data"]["dataIntegration_job"];
  const responseMatch = resp?.match(/job_id=([^;\n]+)/);
  const responseName = responseMatch
    ? responseMatch[1]?.replace(/}/g, "")
    : null;
  return responseName;
};
