import React, { useState, useEffect } from "react";
import { Space } from "antd";
import { useNavigate } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUserGroups } from "../../services/users";
import moment from "moment";
import { YearMonthDayFormat } from "../../shared/constants";
import { PageWrap, StyledButton, CommonTable } from "../../shared/commonStyles";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getSheetById,
  updateDataSheet,
  uploadDataSheetAuditFile,
} from "../../services/dataSheet";
import { byAuditManagementAuditType } from "../../graphql/queries";
import { useBoolean, useDynamicList } from "ahooks";
import { StatusReasonModal } from "../dataSheetsPage/dataSheetDocumentsPage/Components/StatusReasonModal";
import { v4 as uuidv4 } from "uuid";
import { encodeUrlName } from "../../shared/commonFunctions";

const AuditDataSheetsPage = () => {
  const [
    loadingDataSheetsAudits,
    {
      setTrue: setLoadingDataSheetsAudits,
      setFalse: finishLoadingDataSheetsAudits,
    },
  ] = useBoolean(false);
  const [dataSheetsAuditsList, setDataSheetsAuditsList] = useState<Audit[]>([]);
  const [isSubmitting, { setTrue: setSubmitting, setFalse: finishSubmitting }] =
    useBoolean(false);
  const { list: dataSheets, resetList, push } = useDynamicList<DataSheet>([]);
  const [username, setUsername] = useState<string>();

  const navigate = useNavigate();

  const getUsername = async () => {
    const data: any = await Auth.currentSession();
    setUsername(data.accessToken.payload.username);
  };

  const loadDataSheet = async (id: string) => {
    try {
      return await getSheetById(id);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addSheetNameToAudit = async (audits: Audit[]) => {
    const auditItems = audits?.map(async (audit: Audit) => {
      const sheet = await loadDataSheet(JSON.parse(audit.audit_items)[0]);
      push(sheet);
      return { ...audit, sheet_name: sheet.sheet_name };
    });

    return await Promise.all(auditItems);
  };

  const getDataSheetAudits = async () => {
    setLoadingDataSheetsAudits();
    const auditList: any = await API.graphql(
      graphqlOperation(byAuditManagementAuditType, {
        audit_type: "DATA_SHEETS",
      })
    );
    const result = auditList.data?.byAuditManagementAuditType.items?.filter(
      (item: Audit) => item?.audit_items?.length
    );
    const userData = await Auth.currentSession();
    const username = userData.getAccessToken()["payload"]["username"];
    const groups = await getUserGroups();
    const userGroups = groups?.filter((group: UserGroup) =>
      group?.group_users?.find((v) => v === username)
    );
    const isGroupsFound = (group_id: string) => {
      return userGroups?.some(
        (group: UserGroup) => group?._id?.$oid === group_id
      );
    };
    const assignedItems =
      result?.filter((audit: Audit) => {
        const isUserGroups = JSON.parse(audit?.assigned_groups)?.some(
          (group_id: string) => isGroupsFound(group_id)
        );
        const isUser = JSON.parse(audit?.assigned_users)?.some(
          (user: string) => user === username
        );
        if (isUserGroups || isUser) return audit;
      }) || [];

    setDataSheetsAuditsList(await addSheetNameToAudit(assignedItems));
    finishLoadingDataSheetsAudits();
  };

  useEffect(() => {
    getUsername();
    getDataSheetAudits();
  }, []);

  const uploadSingleFile = async (
    file: File,
    dataId: string,
    fileName?: string
  ) => {
    try {
      const signedURL = await uploadDataSheetAuditFile(dataId, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
    }
  };

  const uploadFile = async (files: File[], reasonKey: string) => {
    try {
      for (const file of files) {
        await uploadSingleFile(file, reasonKey, file.name);
      }
    } catch (error) {
      console.log("Something went wrong while uploading files!", error);
    }
  };
  const submitSignoffStatus = async (
    comment: string,
    status: string,
    dataSheet?: DataSheet,
    audit?: Audit,
    files?: File[]
  ) => {
    setSubmitting();
    const key = uuidv4();
    if (files?.length) {
      await uploadFile(files, key);
    }
    const data: any = await Auth.currentSession();
    const username = data.accessToken.payload.username;

    const auditItem = {
      audit_id: audit?.id,
      key,
      comment,
      last_modified: moment(new Date(), YearMonthDayFormat),
      status,
      username,
      ...(!!files?.length && { files: files?.map((item) => item.name) }),
    };

    const payload = {
      ...dataSheet,
      audits: [...(dataSheet?.audits || []), auditItem],
    };

    updateDataSheet(payload).then(() => {
      finishSubmitting();
      resetList([]);
      getDataSheetAudits();
    });
  };

  const columns = [
    {
      title: "Audit Name",
      dataIndex: "name",
      width: "120px",
    },
    {
      title: "Sheet Name",
      dataIndex: "sheet_name",
      width: "120px",
    },
    {
      title: "Audit Description",
      dataIndex: "description",
      width: "200px",
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "80px",
      className: "Fixed_Actions_Right_Side",
      render: (auditRecord: Audit) => {
        const dataSheet = dataSheets.find(
          (sheet) => sheet.sheet_name === auditRecord.sheet_name
        );
        const audit = dataSheet?.audits
          ?.filter((item) => {
            return (
              item.username === username && item.audit_id === auditRecord.id
            );
          })
          ?.reduce((prev, current) => {
            if (!prev) return current;
            return prev.last_modified > current.last_modified ? prev : current;
          }, null);

        return (
          <Space direction="horizontal">
            <StyledButton
              type="default"
              icon={<EyeOutlined style={{ width: 20, fontSize: "20px" }} />}
              onClick={() => {
                navigate(
                  `/audit/audit-data-sheets/${auditRecord.id}/${encodeUrlName(
                    auditRecord?.sheet_name?.replace("/", " ")
                  )}/documents`
                );
              }}
            />
            <StatusReasonModal
              button={{
                type:
                  audit && audit?.status === "approved" ? "primary" : "default",
                icon:
                  audit && audit?.status === "approved" ? (
                    <CheckOutlined />
                  ) : (
                    ""
                  ),
                text:
                  audit && audit?.status === "approved"
                    ? "Approved"
                    : "Mark as approved",
                submitting: isSubmitting,
              }}
              onConfirm={(reason, files) =>
                audit?.status !== "approved" &&
                submitSignoffStatus(
                  reason,
                  "approved",
                  dataSheet,
                  auditRecord,
                  files
                )
              }
            />

            <StatusReasonModal
              button={{
                type:
                  audit && audit?.status === "denied" ? "primary" : "default",
                icon:
                  audit && audit?.status === "denied" ? <CloseOutlined /> : "",
                text: audit && audit?.status === "denied" ? "Declined" : "Deny",
                submitting: isSubmitting,
              }}
              onConfirm={(reason, files) =>
                audit?.status !== "denied" &&
                submitSignoffStatus(
                  reason,
                  "denied",
                  dataSheet,
                  auditRecord,
                  files
                )
              }
            />
          </Space>
        );
      },
    },
  ];

  return (
    <PageWrap>
      <CommonTable
        loading={loadingDataSheetsAudits}
        rowKey={(data: any) => data?._id?.$oid}
        dataSource={dataSheetsAuditsList}
        columns={columns}
        rowClassName="dataSheetsTable"
        scroll={{ x: true }}
      />
    </PageWrap>
  );
};

export default AuditDataSheetsPage;
