import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import React, { useState, useEffect, useRef } from "react";
import { StyledButton } from "../../../../shared/commonStyles";
import { Report } from "./styles";
import { Spin, Table } from "antd";
import { columnsB, parseDataFromResponse } from "./util";
import { staticImagesList } from "../../../../shared/constants";
import { Storage } from "aws-amplify";
import "./style.css";
import { getReportFilters } from "../../../../services/filter";

const GeneratedReport = ({
  setStep,
  title,
  description,
  metricColumnTitle,
  reportingColumnTitle,  
  dataLakeMapReport,
  primary,
  secondary,
  reportTitleColor,
  reportDescriptionColor,
  tableHeaderTextColor,
  topicTextColor,
  setShowGeneratedReport,
}) => {
  const [loadReportingData, setLoadingReportingData] = useState(false);
  const [reportDataForSingleTable, setReportDataForSingleTable] = useState([]);
  const exportHtmlRef = useRef();
  const [businessLogo, setBusinessLogo] = useState(null);  

  useEffect(() => {    
    if (dataLakeMapReport?.length) {
      const filteredData = dataLakeMapReport?.filter(item => item.publish_status && item?.user_status === 'closed' && item?.status === 'ACTIVE')
      setLoadingReportingData(true);
      setReportDataForSingleTable([]);
      
      filteredData.forEach((item) => {
        //const foundRecord = formItems?.find((d) => d?.data_id === item?._id.$oid);
        const data = parseDataFromResponse(item);        
        if (data) {
          setReportDataForSingleTable((prev) =>
            [...prev, data].sort((a, b) => a.theme.localeCompare(b.theme))
          );
        }
      })
      setLoadingReportingData(false);      
    }
  }, [dataLakeMapReport]);  

  // get logo using business unit id
  const getFile = async (defaultBusinessUnitId) => {
    try {
      const resp = await Storage.get(
        `businessuniticons/${defaultBusinessUnitId}.png`
      );
      
      setBusinessLogo(resp);
      
    } catch (e) {
      console.log("There was an error fetching image");
    }
  };

  const getBusinessId = async () => {
 
    const payload = { filter: { filter_type: "business_unit" } };
    const response = await getReportFilters(payload);

    if (response) {
      const defaultBusinessUnitId = response.find(
        ({ status }) => status === "ACTIVE"
      )?._id["$oid"];
      getFile(defaultBusinessUnitId);
    }
  };

  useEffect(() => {
    getBusinessId();
  }, []);

  return (
    <div className="tableContainer reportTableContainer">
      <style jsx="true">
        {`
          #test-table-xls-button {
            height: auto;
          }
        `}
      </style>

      <div className="action-buttons">
        <StyledButton
          type="custom"
          onClick={() => {
            setShowGeneratedReport(false);
            setStep(2);
          }}
        >
          Edit Report
        </StyledButton>
        <ReactToPrint
          trigger={() => (
            <StyledButton type="custom" disabled={loadReportingData}>
              Export Pdf{" "}
            </StyledButton>
          )}
          content={() => exportHtmlRef.current}
        />
        {loadReportingData ? (
          <StyledButton type="custom" disabled={true}>
            Export Excel
          </StyledButton>
        ) : (
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="custom-primary-button"
            table="table-to-xls"
            filename="Report"
            sheet="Report"
            buttonText="Export Excel"
          />
        )}
      </div>
      <Spin spinning={loadReportingData}>
        <div>
          <table id="table-to-xls" style={{ display: "none" }}>
            <thead>
              <tr>
                <th>STANDARDS</th>
                <th>CATEGORY</th>
                <th>METRIC</th>
                <th>THEME</th>
                <th>SUMMARY</th>
                <th>CODE</th>
                <th>DATA</th>
              </tr>
            </thead>
            <tbody>
              {reportDataForSingleTable?.length
                ? reportDataForSingleTable.map((item) => {
                    return (
                      <tr key={item._id.$oid}>
                        <td>{item.standards}</td>
                        <td>{item.category}</td>
                        <td>{item.metric}</td>
                        <td>{item.theme}</td>
                        <td>{item.summary}</td>
                        <td>{item.specific_standard}</td>
                        <td>
                          {item?.form_data_details &&
                            item?.form_data_details?.map((data, index) => {
                              return (
                                <div key={item._id.$oid + "-" + index}>
                                  <p>
                                    {data.title}: {data.value}
                                  </p>
                                </div>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <Report
          ref={exportHtmlRef}
          primary={primary}
          secondary={secondary}
          id="standardsReport"
          reportTitleColor={reportTitleColor}
          reportDescriptionColor={reportDescriptionColor}
          tableHeaderTextColor={tableHeaderTextColor}
          topicTextColor={topicTextColor}
        >
          <div className="report-header">
            <div className="header-logo-wrap">
              <img
                src={businessLogo}
                onError={() => {
                  setBusinessLogo(staticImagesList.hydrusGreyLogo);
                }}
                alt="hydrus logo"
              />
            </div>
            <div className="header-content-wrap">
              <p
                className="report-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
          {reportDataForSingleTable?.length ? (
            <Table
              //rowKey={"data_id"}
              rowKey={(data) => {
                return data?.["_id"]?.["$oid"];
              }}
              columns={columnsB(metricColumnTitle, reportingColumnTitle)}
              dataSource={reportDataForSingleTable}
              pagination={false}
            />
          ) : null}

          <div
            className="report-footer"
            style={{ background: "white", borderTop: "1px solid #F0F0F0" }}
          >
            <a
              href="https://hydrus.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ color: "#929292" }}>powered by</span>
              <img src={staticImagesList.hydrusGreyLogo} alt="hydrus logo" />
            </a>
          </div>
        </Report>
      </Spin>
    </div>
  );
};

export default GeneratedReport;
