import React, { useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import { getUsersGroupList, getUsersList } from "../../../../services/users";

interface ReplacementParametersProps {
  dataSheet: DataSheet;
  column: string;
  onChangeColumn: () => void;
}

export const ReplacementParameters = ({
  dataSheet,
  column,
  onChangeColumn,
}: ReplacementParametersProps) => {
  const [usersList, setUsersList] = useState<User[]>([]);
  const [groupsList, setGroupsList] = useState<UserGroup[]>([]);
  const [loader, setLoader] = useState(false);
  const getUsersGroups = async () => {
    setLoader(true);
    const groups: UserGroup[] = await getUsersGroupList();
    setGroupsList(groups);
    const users: User[] = await getUsersList();
    setUsersList(users);
    setLoader(false);
  };

  useEffect(() => {
    getUsersGroups();
  }, []);

  const getColumnOptions = () => {
    const options = dataSheet.sheet_schema
      .filter((v) => !v.primaryKey)
      .map((v) => {
        return {
          value: v.entity_name,
          label: v.display_name,
          inputType: v.input_type,
        };
      });

    options.push({
      value: "owner_users",
      label: "Owner Users",
      inputType: "select",
    });
    options.push({
      value: "owner_groups",
      label: "Owner Groups",
      inputType: "select",
    });
    return options;
  };

  const getSelectOptions = () => {
    let options: any[] = [];
    if (column === "owner_users") {
      options = usersList;
    }
    if (column === "owner_groups") {
      options = groupsList;
    }
    return options;
  };

  const getFieldType = (fieldPlaceholder: string) => {
    const columnType = getColumnOptions().find(
      (v) => v.value === column
    )?.inputType;
    switch (columnType) {
      case "date":
        return (
          <DatePicker
            placeholder={fieldPlaceholder}
            style={{ width: "100%" }}
          />
        );
      case "number":
        return (
          <InputNumber
            placeholder={fieldPlaceholder}
            style={{ width: "100%" }}
          />
        );
      case "select":
        return (
          <Select
            placeholder={fieldPlaceholder}
            style={{ width: "100%" }}
            mode="multiple"
            allowClear
            notFoundContent={loader && <Spin size="small" />}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={getSelectOptions()}
          />
        );
      default:
        return <Input placeholder={fieldPlaceholder} />;
    }
  };

  return (
    <Row justify="space-between" align="bottom">
      <Col span={12}>
        <Form.Item
          labelCol={{ span: 24 }}
          name="column"
          label="Select column"
          style={{ marginRight: "5px" }}
        >
          <Select
            onChange={onChangeColumn}
            placeholder="Select column"
            options={getColumnOptions()}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          labelCol={{ span: 24 }}
          name="column_value"
          label="Enter value"
          style={{ marginLeft: "5px" }}
        >
          {getFieldType("Enter initial value")}
        </Form.Item>
      </Col>
    </Row>
  );
};
