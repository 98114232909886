import React, { useState } from "react";
import moment from "moment";
import { Dropdown, Menu } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../../shared/constants";

import {
  StyledButton,
  TableWrap,
  FormWrapper,
  CommonTable,
  TableCommonMenu,
} from "./../../../../shared/commonStyles";

export const DataSheetMultiLineForm = ({ dataSheet, rowData }) => {
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);

  const generateDynamicColumns = (dataSheetSchema) => {
    const filteredSchemas = dataSheet?.sheet_schema?.filter(
      (schema) =>
        !schema.isExpression &&
        schema.input_type !== "expression" &&
        (rowData.display_fields
          ? rowData.display_fields?.find((item) => item === schema.entity_name)
          : !rowData?.hide_fields && !schema?.metadata_entity_name)
    );
    if (filteredSchemas?.length) {
      const columns = filteredSchemas?.reduce((acc, item) => {
        const column = [];
        column.push({
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: 150,
          editable: "true",
          render: (_, data) => {
            try {
              if (item?.input_type === "date") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                const date =
                  value === "" || value === undefined || value === null
                    ? ""
                    : moment.utc(value).format(YearMonthDayFormat);
                return date;
              } else if (item?.input_type === "checkbox") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                if (Array.isArray(value)) {
                  return value?.toString();
                } else {
                  return value ? "TRUE" : "FALSE";
                }
              } else {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                return value ? value : null;
              }
            } catch (error) {
              console.log("error", error);
              return "";
            }
          },
        });

        if (item.unit_id) {
          column.push({
            title: item.display_name + " Unit",
            dataIndex: item.entity_name + "_unit_id",
            required: item.required,
            schemaField: item,
            dataType: item.convertable_units,
            inputType: "unit_id",
            width: 50,
            editable: true,
            render: (_, data) => {
              try {
                const value =
                  data?.[data?.sheet_name]?.[item.entity_name + "_unit_id"];
                return value ? value : null;
              } catch (error) {
                console.log("error", error);
                return "";
              }
            },
          });
        }

        return acc.concat(column);
      }, []);
      columns.push({
        title: "Actions",
        dataIndex: "action",
        width: "50px",
        render: (_, data) => {
          return (
            <>
              {data?._id && (
                <Dropdown
                  overlay={
                    <TableCommonMenu>
                      <Menu.Item key="files">
                        <StyledButton type="default">
                          <UploadOutlined /> Upload file
                        </StyledButton>
                      </Menu.Item>
                      {data.show_notes && (
                        <Menu.Item key="notes">
                          <StyledButton type="default">
                            <EditOutlined /> Add notes
                          </StyledButton>
                        </Menu.Item>
                      )}
                    </TableCommonMenu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <StyledButton>
                    <EllipsisOutlined />
                  </StyledButton>
                </Dropdown>
              )}
            </>
          );
        },
      });
      return columns;
    }
    return [];
  };
  const generateColumns = generateDynamicColumns(
    dataSheet?.sheet_schema,
    rowData
  );

  const data = [];

  if (generateColumns.length > 0) {
    for (let i = 0; i < generateColumns.length; i++) {
      const dataIndex = generateColumns[i].dataIndex;
      data.push({
        key: i,
        [dataIndex]: "",
      });
    }
  }

  return (
    <>
      {dataSheet ? (
        <>
          <TableWrap style={{ marginTop: "0", width: "100%" }}>
            <FormWrapper>
              <CommonTable
                loading={loadingSheetRecords}
                dataSource={data}
                columns={generateColumns}
                rowClassName={() => "gridtable"}
                scroll={{ x: true }}
                pagination={false}
                className="headCustom"
              />
            </FormWrapper>
          </TableWrap>
        </>
      ) : null}
    </>
  );
};
