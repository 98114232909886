import React, { useState } from 'react';
import { PageWrap } from "../../shared/commonStyles";
import AccountsDataTable from "./Components/AccountsDataTable";
import AccountDetail from './Components/AccountDetail';
import { getConnectToken } from './Components/session';
import { useConnect } from "@arcadia-eng/connect-react";
import { notification } from "antd";

const ConnectUtilitiesPage = () => {
  const [showMetaDataUserInfoModal, setShowMetaDataUserInfoModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(null);
  const [timedOut, setTimedOut] = useState(false);
  const [utilityCredentialId, setUtilityCredentialId] = useState(null);

  const [{ connectSetupError }, open] = useConnect();

  if (connectSetupError) {
    setError(connectSetupError.message);
  }

  const handleViewUserFlow = (rowData) => {
    setRowData(rowData);
    setShowMetaDataUserInfoModal(true);
  };

  const handleAccountUpdate = (utility_credential_id) => {
    getConnectToken(utility_credential_id)
      .then((connectToken) => {
        open(generateConfig(connectToken))
      }).catch((e) => {
        setError(e.message);
      })
  }

  const generateConfig = (connectToken) => {
    return {
      connectToken,
      callbacks: {
        onOpen: () => {
          setModalOpen(true);
        },
        onCredentialsSubmitted: ({ utilityCredentialId }) => {
          setUtilityCredentialId(utilityCredentialId);
        },
        onClose: ({ status }) => {
          switch (status) {
            case "verified":
              setSuccessful(true);
              break;
            case "timed_out":
              setTimedOut(true);
              break;
            default:
              break;
          }
          setModalOpen(false);
        },
        onError: ({ error }) => {
          setError(error);
        },
      },
    };
  };

  const openNotificationWithIcon = (type, title, content) => {
    notification[type]({
      message: `${title}`,
      description: `${content}`,
      placement: 'top',
      maxCount : 1,
    });
  }

  if (modalOpen) return null;

  if (error) {
    openNotificationWithIcon('error', `Update Utility Failed','Uh oh! We hit a problem: ${error}  !!`)
  }

  if (timedOut) {
    openNotificationWithIcon('info', `Utility Credential ${utilityCredentialId} was updated but the credentials weren't verified before the Component timed out and closed. The credentials will be verified in the background. If you've configured a webhook, check your console for incoming webhooks about verification.`);
  }

  if (successful) {
    openNotificationWithIcon(
      'success',
      'Account Updated Successfully',
      `You have updated Utility Credential ${utilityCredentialId}! 
      If you've configured a webhook, check your console for incoming data.In order to try connecting these same credentials`,
    );
  }


  return (
    <PageWrap>
      {showMetaDataUserInfoModal && (
        <AccountDetail
          rowData={rowData}
          showMetaDataUserInfoModal={showMetaDataUserInfoModal}
          setShowMetaDataUserInfoModal={setShowMetaDataUserInfoModal}
        />
      )};

      <AccountsDataTable
        setLoadingTable={setLoadingTable}
        loadingTable={loadingTable}
        handleAccountUpdate={handleAccountUpdate}
        handleViewUserFlow={handleViewUserFlow}
      />
    </PageWrap>
  )
}

export default ConnectUtilitiesPage
