import React from "react";
import { StyledProgress } from "./../../../../shared/commonStyles";

export const SignOffProgressBar = ({
  uniqueUsersList,
  type = "stroke",
}) => {
  
    let approved = 0;    
    const total = uniqueUsersList ? uniqueUsersList?.length : 0 ;
    const dataList = uniqueUsersList?.length ? uniqueUsersList : [];
    
    for(const userRecord of dataList){
      if(userRecord?.audit_log_data?.status === "approved"){
        approved = approved + 1;
      }
    }

  const getProps = (type) => {
    if (type === "stroke") {
      return {
        percent: percentage,
        size: "default",
        strokeWidth: 10,
        trailColor: "#3E3850",
        strokeColor: "#A68DFB",
        width: 60,
        // steps:total,
      };
    }
  };

  const percentage = Math.round((approved / total) * 100);
  const props = getProps(type);

  return <StyledProgress {...props} style={{ width: '100%', margin:'15px 0'}} />;
};
