import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Popover,
  Progress,
  Row,
  Space,
  Spin,
  message,
  DatePicker,
  Divider,
  Select,
} from "antd";
import { purple } from "@ant-design/colors";
import {
  CopyOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PlusCircleOutlined,
  RightSquareOutlined,
  SearchOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useBoolean } from "ahooks";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { calculationStore, calculatorStore } from "../../Carbon";
import { fetchDataWithRetry } from "../../../services/importData";
import { getAssignedIdentifiersForDefaultMetadata } from "../../../services/dataSheet";
import {
  CommonTable,
  StyledButton,
  TableCommonMenu,
} from "../../../shared/commonStyles";
import { ConfirmModal } from "../Calculator/Components/ConfirmModal";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import { getCompanyInfo } from "../../../services/companyInfo";

import styles from "./Calculations.module.scss";
import { getKpiMetric } from "../../../services/kpi";
import { getEmissionsUnit } from "../../Carbon/CalculationUtils";
import { doConversion } from "../../../services/dataSheet";
import { calculationGroupsStore } from "../../Carbon/CalculationGroupsStore";
import { GroupCalculationsModal } from "./Components/CalculationsAuditorsModal/GroupCalculationsModal";
import { encodeUrlName } from "../../../shared/commonFunctions";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";

interface CalculationGroupInfo extends CalculationGroup {
  date_start?: string;
  date_end?: string;
  total_kg_CO2e?: number;
  indicatorType?: string;
  isGroup?: boolean;
  calculations: ICalculation[];
}
interface CalculationInGroup extends ICalculation {
  groupId: string;
}

interface CarbonSettings {
  revenue_total: number;
  total_floor_space: number;
  full_time_employees: number;
}

export const Calculations = observer(() => {
  const [isConfirmModalVisible, { setTrue, setFalse }] = useBoolean(false);
  const [modalData, setModalData] = useState({
    name: "",
    id: "",
    isGroup: false,
    groupId: "",
  });
  const [assignedIdentifiers, setAssignedIdentifiers] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [scopeFilter, setScopeFilter] = useState<any[]>([]);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [carbonSettings, setCarbonSettings] = useState({} as CarbonSettings);
  const [dateFilter, setDateFilter] = useState<RangeValue>();
  const [conversionFactor, setConversionFactor] = useState(1);
  const [groupModal, setGroupModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedIdentifiers, setSelectedIdentifiers] = useState<string[]>([]);
  const navigate = useNavigate();
  const { checkRolesPermission } = useCheckRolesPermissions();
  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };
  const getIdentifiers = async () => {
    try {
      const identifiers = await getAssignedIdentifiersForDefaultMetadata();
      const isError = identifiers?.statusCode >= 400;
      if (isError) {
        setAssignedIdentifiers([]);
        return Promise.reject();
      }
      setAssignedIdentifiers(identifiers);
    } catch {
      message.error("Error while getting identifiers!");
    }
  };

  useEffect(() => {
    calculationStore.fetchCalculations();
    calculationGroupsStore.fetchCalculationGroups();
    getIdentifiers();
    userInfoStore.fetchUserInfo();
    loadCompanyInfo();
  }, []);

  useEffect(() => {
    checkRolesPermission(["role:elevateduser"]) &&
      assignedIdentifiers?.length &&
      getUpdatedCalculations(assignedIdentifiers, scopeFilter);
  }, [assignedIdentifiers]);

  const getFloorSpaceUnit = () => {
    switch (companyDetails.floor_space_unit) {
      case "ft3":
        return (
          <span>
            ft<sup>3</sup>
          </span>
        );
      case "m3":
        return (
          <span>
            m<sup>3</sup>
          </span>
        );
      default:
        return (
          <span>
            m<sup>3</sup>
          </span>
        );
    }
  };
  const getCurrencyType = () => {
    switch (companyDetails.currency_type) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "AUD":
        return "A$";
      default:
        return "$";
    }
  };

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    setSearchName("");
  }, [calculationStore.savedCalculations]);

  const handleDelete = (id: string) => {
    modalData.isGroup
      ? calculationGroupsStore.removeCalculationGroup(id)
      : calculationStore.deleteCalculation(id);
    const group = calculationGroupsStore.calculationGroups.find(
      (v) => v._id?.$oid == modalData.groupId
    );
    const calculations = group?.calculationsList.filter((v) => v !== id);
    modalData?.groupId?.length &&
      group &&
      calculationGroupsStore.updateCalculationGroup({
        ...group,
        calculationsList: calculations || [],
      });
    setFalse();
  };

  const handleOpenDeleteModal = (
    id: string,
    name: string,
    isGroup: boolean,
    groupId: string
  ) => {
    setModalData({ id, name, isGroup, groupId });

    if (isGroup) {
      setInfoConfirmModal({
        ...groupModalContent,
        title: `Are you sure you want to ungroup ${name}?`,
      });
    } else {
      setInfoConfirmModal({
        ...singleModalContent,
        title: `Are you sure you want to delete ${name}?`,
      });
    }

    setTrue();
  };

  const getIndicatorFilters = (selectedScopes: any[]) => {
    const filter: any = {};
    selectedScopes.forEach((scopeFilter: any) => {
      filter[scopeFilter.item.indicator_type]?.length
        ? filter[scopeFilter.item.indicator_type]?.push(scopeFilter.item.scope)
        : (filter[scopeFilter.item.indicator_type] = [scopeFilter.item.scope]);
    });
    return filter;
  };
  const getUpdatedCalculations = (
    identifiers: string[],
    selectedScopes: any[]
  ) => {
    try {
      if (identifiers.length === 0 && selectedScopes.length === 0) {
        calculationStore.fetchCalculations();
        calculationGroupsStore.fetchCalculationGroups();
      } else {
        const indicatorFilters = selectedScopes?.length
          ? getIndicatorFilters(selectedScopes)
          : null;
        calculationStore.recalculateCalculationsFilteredByIdentifiers(
          identifiers,
          indicatorFilters
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeSearchName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };
  const onChangeDateFilters = (value: RangeValue) => {
    setDateFilter(value);
  };

  const onChangeScopeFilters = (option: any) => {
    setScopeFilter(option);
    getUpdatedCalculations(selectedIdentifiers, option);
  };
  const onChangeIdentifiers = (value: string[]) => {
    const selectedValue =
      checkRolesPermission(["role:elevateduser"]) && !value?.length
        ? assignedIdentifiers
        : value;
    setSelectedIdentifiers(selectedValue);
    getUpdatedCalculations(selectedValue, scopeFilter);
  };
  const handleDuplicate = (id: string, isGroup?: boolean) => {
    isGroup
      ? calculationGroupsStore.duplicateCalculationGroup(
          id,
          calculationStore.fetchCalculations
        )
      : calculationStore.duplicateCalculation(id);
  };

  const handleRefreshJob = async (id: string) => {
    try {
      calculationStore.changeLoadingState(true);
      const jobId = await calculationStore.refreshCalculations_job(
        calculationGroupsStore.fetchCalculationGroups,
        { filter: { _id: { $oid: id } } }
      );
      const result = await fetchDataWithRetry(jobId);
      const resultSummary = result?.resultSummary || "";
      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary.body);
          return Promise.reject();
        }
        result.resultSummary?.message && message.success(resultSummary.message);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      message.error("Error while refreshing calculations!");
    } finally {
      calculationStore.changeLoadingState(false);
    }
  };

  const handleLock = (data: ICalculation) => {
    calculatorStore
      .partialUpdateCalculation({ _id: data._id, isLocked: !data.isLocked })
      .then(() => {
        calculationStore.fetchCalculations();
      });
  };
  const getLastDateKpi = async (
    name: "revenue_total" | "full_time_employees" | "total_floor_space"
  ) => {
    const lastKpi = companyDetails?.[name]?.sort((a, b) =>
      a.end_date < b.end_date ? 1 : -1
    )[0].kpi;
    const kpiData = await getKpiMetric({ filter: { _id: { $oid: lastKpi } } });
    if (kpiData.length) {
      const result =
        kpiData[0]?.analyticResult[
          `${kpiData[0]?.aggregateColumn}_${kpiData[0]?.aggregateOp}`
        ];
      setCarbonSettings((prev) => {
        return { ...prev, [name]: result };
      });
    }
  };

  useEffect(() => {
    getLastDateKpi("revenue_total");
    getLastDateKpi("full_time_employees");
    getLastDateKpi("total_floor_space");
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const countPercent = (number: string | number): number => {
    if (calculationsTotal && calculationsTotal > 0) {
      const newNumber = Number((+number).toFixed(4));

      const result = Number((+newNumber / calculationsTotal) * 100).toFixed(2);

      return +result;
    }
    return 0;
  };

  const newGroups = calculationGroupsStore.calculationGroups?.map((group) => {
    const calculationsData = calculationStore.savedCalculations
      ?.filter((item) =>
        group?.calculationsList?.find((v) => item._id?.$oid === v)
      )
      .map((v) => ({ ...v, groupId: group._id?.$oid }));
    const dateStart = calculationsData?.sort((a, b) =>
      moment(
        (a?.date_start as { $date: string })?.$date || (a?.date_start as string)
      ) >
      moment(
        (b?.date_start as { $date: string })?.$date || (b?.date_start as string)
      )
        ? 1
        : -1
    )[0]?.date_start;
    const dateEnd = calculationsData?.sort((a, b) =>
      moment(
        (a?.date_end as { $date: string })?.$date || (a?.date_end as string)
      ) <
      moment(
        (b?.date_end as { $date: string })?.$date || (b?.date_end as string)
      )
        ? 1
        : -1
    )[0]?.date_end;
    const indicatorType =
      !!calculationsData?.length &&
      (calculationsData?.every((item) => item.indicatorType === "l")
        ? "l"
        : calculationsData?.every((item) => item.indicatorType === "m")
        ? "m"
        : "default");
    const total = calculationsData.reduce((acc, item) => {
      return acc + +item.total_calculate?.all_total!;
    }, 0);
    return {
      ...group,
      calculations: calculationsData,
      indicatorType: indicatorType,
      date_start: dateStart,
      date_end: dateEnd,
      total_kg_CO2e: total,
      isGroup: true,
    };
  });

  const filterData = calculationStore.savedCalculations
    .filter(
      (v) =>
        !calculationGroupsStore.calculationGroups
          ?.map((v) => v.calculationsList)
          .flat()
          ?.find((item) => v._id?.$oid === item)
    )
    ?.filter(
      (item) =>
        (searchName
          ? item.name?.toLowerCase().includes(searchName.toLowerCase()) ||
            item.description?.toLowerCase().includes(searchName.toLowerCase())
          : item) &&
        (dateFilter
          ? moment(
              (item.date_start as { $date: string })?.$date ||
                (item.date_start as string)
            ).format("YYYY-MM-DD") >= dateFilter[0].format("YYYY-MM-DD") &&
            moment(
              (item.date_end as { $date: string })?.$date ||
                (item.date_end as string)
            ).format("YYYY-MM-DD") <= dateFilter[1].format("YYYY-MM-DD")
          : item)
    );

  const groupFilterData = newGroups.filter(
    (item) =>
      (searchName
        ? item.name.toLowerCase().includes(searchName.toLowerCase()) ||
          item.description.toLowerCase().includes(searchName.toLowerCase())
        : item) &&
      (dateFilter
        ? moment(
            (item.date_start as { $date: string })?.$date ||
              (item.date_start as string)
          ).format("YYYY-MM-DD") >= dateFilter[0].format("YYYY-MM-DD") &&
          moment(
            (item.date_end as { $date: string })?.$date ||
              (item.date_end as string)
          ).format("YYYY-MM-DD") <= dateFilter[1].format("YYYY-MM-DD")
        : item)
  );

  const calculationsTableData = [...groupFilterData, ...filterData];
  const calculationsData = [
    ...filterData,
    ...groupFilterData.map((v) => v.calculations).flat(),
  ];

  const setTimePeriod = () => {
    if (dateFilter) {
      calculationStore.changeCalculationPeriod(dateFilter);
    } else {
      const dateStart = calculationsTableData?.sort((a, b) =>
        moment(
          (a?.date_start as { $date: string })?.$date ||
            (a?.date_start as string)
        ) >
        moment(
          (b?.date_start as { $date: string })?.$date ||
            (b?.date_start as string)
        )
          ? 1
          : -1
      )[0]?.date_start;
      const dateEnd = calculationsTableData?.sort((a, b) =>
        moment(
          (a?.date_end as { $date: string })?.$date || (a?.date_end as string)
        ) <
        moment(
          (b?.date_end as { $date: string })?.$date || (b?.date_end as string)
        )
          ? 1
          : -1
      )[0]?.date_end;
      calculationStore.changeCalculationPeriod([dateStart, dateEnd]);
    }
  };

  useEffect(() => {
    setTimePeriod();
  }, [filterData]);

  const calculationsTotal = calculationsData.reduce((acc, item) => {
    return acc + +(item.total_calculate?.all_total || 0);
  }, 0);

  const otherEmissionPercentage: (string | number)[] = [];

  const [infoConfirmModal, setInfoConfirmModal] = useState({
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
  });

  const groupModalContent = {
    title: "Are you sure you want to ungroup?",
    description: "This will ungroup the calculations but not delete them.",
    confirmText: "Ungroup",
    cancelText: "Continue without ungrouping",
  };

  const singleModalContent = {
    title: "Are you sure you want to delete this calculation?",
    description: "This action cannot be undone.",
    confirmText: "Delete permanently",
    cancelText: "Continue without deleting",
  };

  const scopeOptions = [
    {
      options: [
        {
          label: "Scope 1",
          value: `${ScopeName.SCOPE_1}_l`,
          title: "Scope 1 (Location-based)",
          item: { scope: ScopeName.SCOPE_1, indicator_type: "l" },
        },
        {
          label: "Scope 2",
          value: `${ScopeName.SCOPE_2}_l`,
          title: "Scope 2 (Location-based)",
          item: { scope: ScopeName.SCOPE_2, indicator_type: "l" },
        },
        {
          label: "Scope 3",
          value: `${ScopeName.SCOPE_3}_l`,
          title: "Scope 3 (Location-based)",
          item: { scope: ScopeName.SCOPE_3, indicator_type: "l" },
        },
      ],
      value: "l",
      label: "Location-based emissions",
    },
    {
      options: [
        {
          label: "Scope 1",
          value: `${ScopeName.SCOPE_1}_m`,
          title: "Scope 1 (Market-based)",
          item: { scope: ScopeName.SCOPE_1, indicator_type: "m" },
        },
        {
          label: "Scope 2",
          value: `${ScopeName.SCOPE_2}_m`,
          title: "Scope 2 (Market-based)",
          item: { scope: ScopeName.SCOPE_2, indicator_type: "m" },
        },
        {
          label: "Scope 3",
          value: `${ScopeName.SCOPE_3}_m`,
          title: "Scope 3 (Market-based)",
          item: { scope: ScopeName.SCOPE_3, indicator_type: "m" },
        },
      ],
      value: "m",
      label: "Market-based emissions",
    },
    {
      options: [
        {
          label: "Scope 1",
          value: `${ScopeName.SCOPE_1}_default`,
          title: "Scope 1 (Uncategorized)",
          item: { scope: ScopeName.SCOPE_1, indicator_type: "default" },
        },
        {
          label: "Scope 2",
          value: `${ScopeName.SCOPE_2}_default`,
          title: "Scope 2 (Uncategorized)",
          item: { scope: ScopeName.SCOPE_2, indicator_type: "default" },
        },
        {
          label: "Scope 3",
          value: `${ScopeName.SCOPE_3}_default`,
          title: "Scope 3 (Uncategorized)",
          item: { scope: ScopeName.SCOPE_3, indicator_type: "default" },
        },
      ],
      value: "default",
      label: "Uncategorized emissions",
    },
  ];

  const getBasedOptions = (item: string) => {
    switch (item) {
      case "l":
        return IndicatorType.l;
      case "m":
        return IndicatorType.m;
      case "default":
        return IndicatorType.default;
      default:
        return IndicatorType.default;
    }
  };

  const handleEditGroup = (data: CalculationGroupInfo) => {
    calculationGroupsStore.getCalculationGroup(data._id?.$oid);
    setGroupModal(true);
  };

  const handleExpandRow = (data: CalculationGroupInfo) => {
    const rowKey = data._id?.$oid || "";
    setExpandedRows((prev) => {
      return prev.find((v) => v === rowKey)
        ? prev.filter((v) => v !== rowKey)
        : [...prev, rowKey];
    });
  };
  const actionsButtons = (data: CalculationGroupInfo | ICalculation) => (
    <TableCommonMenu>
      {(data as CalculationGroupInfo)?.isGroup && (
        <Menu.Item key="edit">
          <Button onClick={() => handleEditGroup(data as CalculationGroupInfo)}>
            <EditOutlined /> Edit
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="duplicate">
        <Button
          onClick={() =>
            handleDuplicate(
              data._id?.$oid as string,
              (data as CalculationGroupInfo)?.isGroup
            )
          }
        >
          <CopyOutlined />
          Duplicate
        </Button>
      </Menu.Item>
      {!(data as CalculationGroupInfo)?.isGroup && (
        <>
          <Menu.Item key="audit-new">
            <Button
              onClick={() =>
                navigate("/carbon/create-audit", {
                  state: {
                    id: data._id?.$oid,
                    type: "GHG_EMISSIONS",
                  },
                })
              }
            >
              <RightSquareOutlined />
              Assign Auditors
            </Button>
          </Menu.Item>
          <Menu.Item key="isLock">
            <Button
              type="primary"
              onClick={() => handleLock(data as ICalculation)}
            >
              {(data as ICalculation).isLocked ? (
                <>
                  <UnlockOutlined /> Unlock Calculation
                </>
              ) : (
                <>
                  <LockOutlined /> Lock Calculation
                </>
              )}
            </Button>
          </Menu.Item>
          <Menu.Item key="delete">
            <Button
              type="primary"
              disabled={(data as ICalculation).isLocked}
              onClick={() => handleRefreshJob(data._id?.$oid as string)}
            >
              <ReloadOutlined />
              Refresh
            </Button>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="delete">
        <Button
          type="primary"
          disabled={(data as ICalculation).isLocked}
          onClick={() =>
            handleOpenDeleteModal(
              data._id?.$oid || "",
              data.name,
              !!(data as CalculationGroupInfo)?.isGroup,
              (data as CalculationInGroup).groupId
            )
          }
        >
          <DeleteOutlined />
          {(data as CalculationGroupInfo)?.isGroup ? "Ungroup" : "Delete"}
        </Button>
      </Menu.Item>
    </TableCommonMenu>
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: ICalculation, b: ICalculation) =>
        a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: ICalculation, b: ICalculation) =>
        a.description.localeCompare(b.description),
    },
    {
      title: "Type of Emission",
      dataIndex: "indicatorType",
      key: "type",
      sorter: (a: ICalculation, b: ICalculation) =>
        getBasedOptions(a.indicatorType || "").localeCompare(
          getBasedOptions(b.indicatorType || "")
        ),
      render: (_: any, data: ICalculation) => {
        return (
          <>
            <span className={styles.dataQualityScoreLabel}>
              {getBasedOptions(data.indicatorType || "")}
            </span>
          </>
        );
      },
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      sorter: (a: ICalculation, b: ICalculation) => {
        const dateStartA = a?.date_start || "";
        const dateStartB = b?.date_start || "";

        const parsedDateA =
          typeof dateStartA === "string"
            ? dateStartA
            : (dateStartA as { $date: string })?.$date || "";

        const parsedDateB =
          typeof dateStartB === "string"
            ? dateStartB
            : (dateStartB as { $date: string })?.$date || "";

        return parsedDateA.localeCompare(parsedDateB);
      },
      render: (_: any, calculation: ICalculation) => {
        return (
          <>
            {calculation.date_start &&
              calculation.date_end &&
              `${moment(
                (calculation.date_start as { $date: string })?.$date ||
                  (calculation.date_start as string)
              ).format("YYYY-MM-DD")} - ${moment(
                (calculation.date_end as { $date: string })?.$date ||
                  (calculation.date_start as string)
              ).format("YYYY-MM-DD")}`}
          </>
        );
      },
    },
    {
      title: `Total (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      key: "total_kg_CO2e",
      dataIndex: "total_kg_CO2e",
      sorter: (
        a: ICalculation | CalculationGroupInfo,
        b: ICalculation | CalculationGroupInfo
      ) =>
        ((a as CalculationGroupInfo).total_kg_CO2e ||
          (a as ICalculation).total_calculate?.all_total!) -
        ((b as CalculationGroupInfo).total_kg_CO2e ||
          (b as ICalculation).total_calculate?.all_total!),
      render: (_: any, data: ICalculation | CalculationGroupInfo) => {
        const total = (
          Number(
            (
              (Number((data as CalculationGroupInfo).total_kg_CO2e) ||
                Number((data as ICalculation).total_calculate?.all_total!)) *
              conversionFactor
            )
              .toString()
              .split("e")[0]
          ) || 0
        ).toFixed(companyDetails.decimal_places_number);
        return (
          <>
            <span>{total || 0}</span>
            {(data as ICalculation).attribution_factor &&
              (data as ICalculation).attribution_factor !== 100 && (
                <Popover
                  content={
                    <Row>
                      <Col span={24}>
                        Attribution factor:{" "}
                        {(data as ICalculation).attribution_factor}%
                      </Col>
                      <Col span={24}>
                        Unattributed -{" "}
                        {(
                          (data as ICalculation).total_calculate
                            ?.unattributed_total! * conversionFactor
                        ).toFixed(companyDetails.decimal_places_number)}{" "}
                        {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                      </Col>
                    </Row>
                  }
                  overlayClassName="popoverContent"
                >
                  <InfoCircleOutlined className={styles.icon} />
                </Popover>
              )}
          </>
        );
      },
    },
    {
      title: "Data Quality",
      dataIndex: "dataQualityScore",
      key: "dataQualityScore",
      width: "150px",
      render: (_: any, data: Emission) => {
        return (
          <>
            <Progress
              percent={data.qualityScore?.percent}
              steps={3}
              strokeColor={[purple[2], purple[3], purple[4]]}
              trailColor="#4E4268"
              showInfo={false}
            />
            <span className={styles.dataQualityScoreLabel}>
              {data.qualityScore?.label || "N/A"}
            </span>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      align: "right",
      render: (
        _: any,
        data: ICalculation | CalculationGroupInfo | CalculationInGroup
      ) => {
        return (
          <>
            {(data as CalculationGroupInfo)?.isGroup ? (
              <StyledButton
                onClick={() => handleExpandRow(data as CalculationGroupInfo)}
                type="primary"
              >
                {expandedRows?.find((item) => item === data._id?.$oid)
                  ? "Close"
                  : "Open"}
              </StyledButton>
            ) : (
              <StyledButton type="primary">
                <Link
                  to={`/carbon/calculations/${encodeUrlName(data.name)}`}
                  state={{
                    calcId: data._id?.$oid,
                    ...((data as CalculationInGroup).groupId && {
                      groupId: (data as CalculationInGroup).groupId,
                    }),
                  }}
                >
                  Open
                </Link>
              </StyledButton>
            )}
            {!checkRolesPermission(["role:elevateduser"]) && (
              <Dropdown
                placement="bottomLeft"
                overlay={actionsButtons(data)}
                trigger={["click"]}
              >
                <Button className={styles.deleteCalcBtn}>
                  <EllipsisOutlined />
                </Button>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  const getEmissionVisualizationColumns = (item: ICalculation) => {
    return (
      <>
        <Col
          className={styles.emissionVisualizationBlockCol}
          flex={`${countPercent(item.total_calculate?.all_total!)}`}
          key={item._id?.$oid}
        >
          {" "}
          <span className={styles.emissionVisualizationColName}>
            {item.name}
          </span>
          <Row className={styles.emissionVisualizationBlockColor}>
            <Popover
              content={
                <span>
                  {(
                    item.total_calculate?.all_total! * conversionFactor
                  ).toFixed(companyDetails.decimal_places_number)}{" "}
                  {getEmissionsUnit(companyDetails.emissions_unit)} CO2e -{" "}
                  {countPercent(item.total_calculate?.all_total!)}%
                </span>
              }
              overlayClassName="popoverContent"
            >
              <span>
                {(item.total_calculate?.all_total! * conversionFactor).toFixed(
                  companyDetails.decimal_places_number
                )}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e -{" "}
                {countPercent(item.total_calculate?.all_total!)}%
              </span>
            </Popover>
          </Row>
        </Col>
      </>
    );
  };

  const emissionVisualizationContent = calculationsData.map((item) => {
    if (countPercent(item?.total_calculate?.all_total || 0) > 4) {
      return getEmissionVisualizationColumns(item);
    } else {
      otherEmissionPercentage.push(item?.total_calculate?.all_total || 0);
      return;
    }
  });

  return (
    <>
      {calculationStore.savedCalculations.length === 0 &&
      !scopeFilter?.length &&
      !selectedIdentifiers?.length &&
      !calculationStore.loadingCalculations ? (
        checkRolesPermission(["role:elevateduser"]) ? (
          <div className={styles.emptyContainer}>
            <h2 className={styles.emptyTitle}>No data available</h2>
          </div>
        ) : (
          <div className={styles.emptyContainer}>
            <h2 className={styles.emptyTitle}>
              You don't have any saved calculations yet
            </h2>
            <div className={styles.emptyText}>
              Create new calculation to calculate your emissions
            </div>
            <StyledButton type="primary">
              <Link to="/carbon/new-calculation">
                <PlusCircleOutlined /> Create new calculation
              </Link>
            </StyledButton>
          </div>
        )
      ) : (
        <Spin
          spinning={
            calculationStore.loadingCalculations ||
            calculationGroupsStore.loadingGroups ||
            calculationGroupsStore.dataRetry
          }
        >
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Row>
              <Col span={5}>
                <Input
                  addonAfter={<SearchOutlined />}
                  placeholder="Search by name, description"
                  className={styles.search}
                  value={searchName}
                  onChange={handleChangeSearchName}
                />
              </Col>
              <Col span={5} className={styles.dateFilter}>
                <DatePicker.RangePicker
                  className={styles.filterDatepicker}
                  format="YYYY-MM-DD"
                  value={dateFilter}
                  onChange={(value) => onChangeDateFilters(value)}
                />
              </Col>
              <Col span={5} className={styles.select}>
                <Select
                  allowClear
                  placeholder="Select scope"
                  value={scopeFilter.map((v) => ({
                    value: v.value,
                    label: v.title,
                  }))}
                  labelInValue
                  options={scopeOptions}
                  mode="multiple"
                  onChange={(value, option) => onChangeScopeFilters(option)}
                />
              </Col>
              {!!assignedIdentifiers.length && (
                <Col span={5} className={styles.select}>
                  <Select
                    defaultValue={
                      checkRolesPermission(["role:elevateduser"]) &&
                      assignedIdentifiers.length === 1
                        ? assignedIdentifiers[0]
                        : []
                    }
                    value={selectedIdentifiers}
                    disabled={
                      checkRolesPermission(["role:elevateduser"]) &&
                      assignedIdentifiers.length === 1
                    }
                    showSearch
                    allowClear
                    showArrow
                    style={{ width: "100%" }}
                    placeholder="Select Identifier"
                    filterOption={(input: string, option: any) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    options={assignedIdentifiers?.map((item: any) => ({
                      value: item,
                      label: item?.toString(),
                    }))}
                    onChange={(value: string[]) => onChangeIdentifiers(value)}
                    mode="multiple"
                  />
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              justify="space-between"
              className={styles.emissionVisualization}
            >
              <Col span={20}>
                <Row className={styles.emissionVisualizationRow} wrap={false}>
                  {emissionVisualizationContent}
                  <Col
                    className={styles.emissionVisualizationBlockCol}
                    flex={countPercent(
                      otherEmissionPercentage.reduce((acc, v) => {
                        return +acc + +v;
                      }, 0)
                    )}
                  >
                    <Row className={styles.emissionVisualizationColName}>
                      Other
                    </Row>
                    <Row className={styles.emissionVisualizationBlockColor}>
                      <Popover
                        content={
                          <span>
                            {countPercent(
                              otherEmissionPercentage.reduce((acc, v) => {
                                return +acc + +v;
                              }, 0)
                            )}
                            %
                          </span>
                        }
                        overlayClassName="popoverContent"
                      >
                        <span>
                          {countPercent(
                            otherEmissionPercentage.reduce((acc, v) => {
                              return +acc + +v;
                            }, 0)
                          )}
                          %
                        </span>
                      </Popover>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <div className={styles.emissionVisualizationTotal}>
                  <div className={styles.emissionVisualizationTotalText}>
                    Total
                  </div>
                  <Popover
                    content={
                      <span>
                        {(calculationsTotal * conversionFactor).toFixed(
                          companyDetails.decimal_places_number
                        )}{" "}
                        {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                      </span>
                    }
                    overlayClassName="popoverContent"
                  >
                    <div className={styles.emissionVisualizationTotalValue}>
                      {(calculationsTotal * conversionFactor).toFixed(
                        companyDetails.decimal_places_number
                      )}{" "}
                      {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                    </div>{" "}
                  </Popover>
                  <Divider
                    style={{ border: "1px solid #3A3C5A", margin: "10px 0px" }}
                  />
                  <div className={styles.emissionVisualizationTotalDetails}>
                    {Object.keys(companyDetails)?.length &&
                    carbonSettings.revenue_total
                      ? (
                          (calculationsTotal * conversionFactor) /
                          carbonSettings.revenue_total
                        ).toFixed(companyDetails.decimal_places_number)
                      : "N/A"}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e/1
                    {getCurrencyType()}
                  </div>
                  <div className={styles.emissionVisualizationTotalDetails}>
                    {Object.keys(companyDetails)?.length &&
                    carbonSettings.full_time_employees
                      ? (
                          (calculationsTotal * conversionFactor) /
                          carbonSettings.full_time_employees
                        ).toFixed(companyDetails.decimal_places_number)
                      : "N/A"}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e/FTE
                  </div>
                  <div className={styles.emissionVisualizationTotalDetails}>
                    {Object.keys(companyDetails)?.length &&
                    carbonSettings.total_floor_space
                      ? (
                          (calculationsTotal * conversionFactor) /
                          carbonSettings.total_floor_space
                        ).toFixed(companyDetails.decimal_places_number)
                      : "N/A"}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e/
                    {getFloorSpaceUnit()}
                  </div>
                </div>
              </Col>
            </Row>
            <CommonTable
              rowKey={(data: ICalculation | CalculationGroupInfo) =>
                data?._id?.$oid
              }
              dataSource={calculationsTableData}
              columns={columns}
              expandable={{
                showExpandColumn: false,
                defaultExpandedRowKeys: expandedRows,
                expandedRowKeys: expandedRows,
                expandedRowRender: (record: CalculationGroupInfo) => (
                  <CommonTable
                    rowKey={(data: ICalculation) => data?._id?.$oid}
                    columns={columns}
                    dataSource={record?.calculations}
                    pagination={false}
                    size="small"
                  />
                ),
                rowExpandable: (record: CalculationGroupInfo) =>
                  record?.calculations,
              }}
              pagination={true}
              scroll={{ x: true }}
            />
          </Space>
        </Spin>
      )}
      <ConfirmModal
        onConfirm={() => handleDelete(modalData.id)}
        visible={isConfirmModalVisible}
        setFalse={setFalse}
        infoConfirmModal={infoConfirmModal}
      />
      <GroupCalculationsModal
        data={calculationGroupsStore.calculationGroup}
        visible={groupModal}
        onClose={() => setGroupModal(false)}
      />
    </>
  );
});
