import React from 'react';
import {Avatar} from 'antd';

export const AvatarImage = ({url,size='large',gap='2'}) =>{
  return(
      <Avatar 
        style={{ backgroundColor: '#7265e6', verticalAlign: 'middle', marginRight:'5px' }} 
        size={size} 
        gap={gap}
        src = {url}
    />
  )
}
   