import React from 'react';
import { Typography, Popconfirm} from 'antd';
import ReportDataFiles from './components/ReportDataFiles';
import { StyledButton } from './../../../../shared/commonStyles';

export const getKeyValueFromObject = (obj) => {
    const tempArr  = []
    try{
    for (const [key, value] of Object.entries(obj)) {
       tempArr.push({
          key : key,
          value : value
       });
    }
    return tempArr;
   }catch(error){
       console.log("Error in getKeyValueFromObject",error);
       return tempArr;
   }
 }

 export const generateDynamicColums = ({data,submitting,onClickUpdate,editingKey,onClickEditBtn,onCancelHandler}) => {    
    const Columns = [];
    try{
      if(data?.length){
         const getFirstRecordFormDetail = data[0]['form_data_details'];         
         Columns.push({
            title : "Name",
            key: 'name',
            width: '10%',
            ellipsis: false
         });
         const abc = getFirstRecordFormDetail?.forEach((item)=>{            
            Columns.push({
               title : item.title,
               dataIndex: item.key,
               width: '15%',
               ellipsis: false,
               editable:true,
               render: (record) => {                     
                  return record ? <div>{record}</div> : null;
               }
            })
            return;
         });

         Columns.push({
            title : "Files",
            dataIndex: 'files',
            width: '10%',
            ellipsis: false,
            editable:false,
            render: (_,record) => {
                 return <ReportDataFiles dataId={record.key}/>
            }
         });

         Columns.push({
            title: 'Action',
            dataIndex: 'action',
            width: '150px',
            render: (_, record) => {
                  const editable = record.key === editingKey;
            return editable ? (
               <span>
                  <StyledButton
                  type="link"
                  loading={submitting}
                  onClick={() => onClickUpdate(record)}
                  style={{
                     marginRight: 8,
                  }}
                  >
                  Update
                  </StyledButton>
                  <Popconfirm title="Sure to cancel?" onConfirm={onCancelHandler}>
                  <StyledButton type="link">Cancel</StyledButton>
                  </Popconfirm>
               </span>
            ) : (
               <Typography.Link disabled={editingKey !== ''} onClick={() => onClickEditBtn(record)}>
                  Edit
               </Typography.Link>
            );
            },
         });
      }
      return Columns;
   }catch(error){
      return Columns;
   }
}



export const generateEditableCell = (dynamicColums,editingKey) =>{
   const mergedColumns = [];
   try{
      return dynamicColums && dynamicColums?.map((col) => {          
          if (!col.editable) {
            return col;
          }
       
          return {
            ...col,
            onCell: (record) => {
               return{
                  record,
                  dataIndex: col.dataIndex,
                  title: col.title,
                  editing: record?.key === editingKey,
               };
            }
          };
        });
   }catch(error){
      return mergedColumns;
   }
  
  } 
  

  
export const generateDynamicExcelTableRows = (data) => {
    const Columns = [];
    try{
       if(data?.length){
          const getFirstRecordFormDetail = data[0]['form_data_details'];
          Columns.push(<th key={"name"}>Name</th>);          
          const DynamicColumns = getFirstRecordFormDetail?.map((item,index)=>{
               return <th key={index}>{item.title}</th>;
          });
          Columns.push(DynamicColumns);
      }
      return Columns;
    }catch(error){
       return Columns;
    }
   
 }


 export const generateDynamicExcelTableData = (data) => {
    const TableRowData = [];
    const total = {};
    try{
       if(data?.length){
         const trData = data.map((item,indx)=>{
             return (
                <tr key={item.data_id +"-tr-"+ indx}>
                      <td key={"name"+ indx}>
                         <p></p>
                      </td>
                {item?.form_data_details && item?.form_data_details?.map((data,index)=>{
                         if(indx === 0){   
                            total[data.key] = 0;
                         }
                         if(!isNaN(data.value)){
                            total[data.key]  += data.value;  
                         }else{
                            total[data.key] = '';
                         }
    
                          return  (
                             <td key={item.data_id + "-td-" + index}>
                                <p>{data.value}</p>
                             </td>  
                          );
                    })
                 }
              </tr>
             );
          });
          const dynamicTotalRowData = (total) =>{
                const data = [];
                data.push(
                   <td key="total">
                       <p>Total</p>
                   </td>  
                );
                for (const property in total) {                   
                   data.push(
                     <td key={property}>
                           <p>{total[property]}</p>
                     </td>  
                );
                }
                return data;
             }
           const totalTrData = <tr key={"tr-total"}>{dynamicTotalRowData(total)}</tr>;              
          TableRowData.push([...trData,totalTrData]);
       }
       return TableRowData;
    }catch(error){
       return TableRowData;
    }
 }

 export const getFormDataDetailsList = (data) =>{
     
     const DataList = [];
       try{
         if(data?.length){
            for(const parentItem of data){
               const data = {};
               data['key'] = parentItem?.data_id;
               for(const formDataItem of parentItem?.form_data_details){
                  const importantData = {
                     [formDataItem.key]:formDataItem
                  };
                  data['data'] = {...data['data'],...importantData};
                  data[formDataItem.key] = formDataItem.value; 
               }
               DataList.push(data);
            }
         }
         return DataList;
     }catch(error){
       return DataList;
     }  
 }

 
export const getFormDataDetails = (formJsonCode,formData) =>{
   const formDataDetails = [];
   const formData2 = [];    
   try{
      // get titles from formJsonCode
      for (const [key, value] of Object.entries(formJsonCode.properties)) {              
         if(value.type === 'array'){
            for (const [key2, value2] of Object.entries(value.items.properties)) {                  
            formDataDetails.push({...value2,key:key2,title:value2.title})
            }
         } else {
            formDataDetails.push({...value,key:key,title:value.title})
         }
      }
      

      for(const [formDataKey,formDataValue] of Object.entries(formData)){
         // get values from formData
         if(typeof formDataValue === 'object') {
            formDataValue.forEach((data)=>{
            //  const arrData =  getaValueFromObject(data);
            const arrData = getKeyValueFromObject(data);
            arrData.map(r=> formData2.push(r));
            });
         } else {
            const data = {key:formDataKey,value:formDataValue};
            formData2.push(data);
         }
      }

      const lastRecord = [];
      // merge formTitles and formValues array.
      
      formDataDetails.forEach((record, index)=>{             
         const obj = {
            ...record,
            title : record.title,
            value: formData2?.find((item)=> item.key === record.key)?.value,
            key : record.key,
            form_data:formData
         }
         lastRecord.push(obj);
      })          
   return lastRecord;
   }
   catch(error){
      console.log("error while getting questions from form json",error);
      return formDataDetails;
   }
 }
 