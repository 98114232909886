import React, { useState } from 'react';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import ChangePasswordForm from './ChangePasswordForm';
import { FormWrapper, CommonModal } from '../../../shared/commonStyles';

const ChangePasswordModal = ({ visible, onFinish }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const initialFields = [
    {
      name: ['old_password'],
      value: '',
      errors: [],
    },
    {
      name: ['new_password'],
      value: '',
      errors: [],

    },
    {
      name: ['new_password_confirm'],
      value: '',
      errors: [],
    },
  ];
  const [fields, setFields] = useState(initialFields);

  const changePassword = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, fields[0].value, fields[1].value);
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      await changePassword();
      message.success("Password changed successfully.");
      onFinish();
      setFields(initialFields);
    } catch (e) {
      // Making sure it's server returned error
      if (e.message) {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setError(null);
    setFields(initialFields);
    onFinish();
  };

  const updateFields = (newFields) => {
    setError(null);
    setFields(newFields);
  };

  return (
    <CommonModal
      title="Change Password"
      visible={visible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={handleCancel}
    >
      <FormWrapper>
        <ChangePasswordForm
          form={form}
          fields={fields}
          onChange={updateFields}
          error={error}
        />
      </FormWrapper>
    </CommonModal>
  );
};

export default ChangePasswordModal;
