import React, { useState } from "react";
import {
  CommonTable,
  FormWrap,
  FormWrapper,
  StyledButton,
  StyledDivider,
  StyledTitle,
  TableWrap,
} from "../../../../shared/commonStyles";
import { Col, Collapse, Row, Switch } from "antd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { siteThemeColors } from "../../../../shared/colors";
import { arrayMoveImmutable } from "array-move";
import styles from "./StandardSheetColumns.module.scss";

interface StandardSheetColumnsProps {
  standardSheet: DataSheet;
  onClickBack: () => void;
  onClickNext: (columns: any[]) => void;
  reportColumns: any[];
}

export const StandardSheetColumns = ({
  onClickBack,
  onClickNext,
  reportColumns,
}: StandardSheetColumnsProps) => {
  const [standardColumns, setStandardColumns] = useState(reportColumns);

  const onSortColumnsOrder = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(standardColumns as any),
        oldIndex,
        newIndex
      )?.filter((el) => !!el);
      const indexKeyUpdate = newData.map((item: any, index) => ({
        ...item,
        index,
      }));
      setStandardColumns(indexKeyUpdate);
    }
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const SortableItem = SortableElement((props: any) => <tr {...props} />);

  const SortableContain = SortableContainer((props: any) => (
    <tbody {...props} />
  ));

  const DraggableContainer = (props: any) => (
    <SortableContain
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortColumnsOrder}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = standardColumns.findIndex(
      (x: any) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{
          ...style,
          color: siteThemeColors.TableColor.color,
        }}
      />
    );
  };

  const handleChangeColumnVisible = (visible: boolean, record: any) => {
    setStandardColumns((prev) =>
      prev.map((column) =>
        column.value === record.value
          ? { ...column, isDisplayed: visible }
          : column
      )
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: "10%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Field name",
      dataIndex: "label",
      width: "50%",
      className: "drag-visible",
    },
    {
      title: "Display",
      dataIndex: "isDisplayed",
      width: "20%",
      className: "drag-visible",
      render: (isDisplayed: boolean, record: any) => (
        <Switch
          checked={isDisplayed}
          onChange={(checked) => handleChangeColumnVisible(checked, record)}
        />
      ),
    },

    {
      title: "Order",
      dataIndex: "index",
      width: "20%",
      className: "drag-visible",
      render: (record: any) => {
        return record + 1;
      },
    },
  ];

  return (
    <FormWrap
      width="600px"
      style={{ marginTop: "50px", backgroundColor: "#2D273F" }}
    >
      <style>
        {`
          .ck.ck-placeholder:before,
          .ck .ck-placeholder:before {
            color: #f5f5f5b8;
          }
        `}
      </style>
      <FormWrapper style={{ margin: "20px 0px" }}>
        <Row>
          <div>
            <StyledTitle level={3}>Fields order</StyledTitle>
          </div>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTitle level={4}>
              Change order of the surveys inside the flow if needed
            </StyledTitle>
          </Col>
        </Row>
        <StyledDivider />
        <Row>
          <Col span={24}>
            <TableWrap className="dataSheet">
              <Collapse
                activeKey="open"
                bordered={false}
                ghost
                expandIcon={({ isActive }) => null}
              >
                <Collapse.Panel
                  className={styles.collapsePanel}
                  key="open"
                  header={<div className="font-16 color-white">Fields</div>}
                >
                  <CommonTable
                    pagination={false}
                    dataSource={standardColumns}
                    columns={columns}
                    rowKey="index"
                    scroll={{ x: true }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </TableWrap>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <StyledButton
            type="custom"
            onClick={() => onClickBack()}
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="white"
            style={{ padding: "11px 5px 11px 0px" }}
          >
            <span>Cancel</span>
          </StyledButton>
          <StyledButton
            className="margin-5"
            onClick={() => onClickNext(standardColumns)}
            type="custom"
          >
            <span>Continue to step 3</span>
          </StyledButton>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};
