import React from "react";
import { CommonTable } from "../../../shared/commonStyles";
import moment from "moment";
import { YearMonthDayFormat } from "../../../shared/constants";
import { Col } from "antd";
import { FilesDropdown } from "./FilesDropdown";
import styles from "./ImportData.module.scss";

interface ImportState {
  importId: string;
  action?: string;
}

interface RecordsTableProps {
  dataSheet?: DataSheet;
  loading: boolean;
  dataSource: DataSheetRecord[];
  files?: ImportedFile[];
  pagination: Pagination;
  handleTableChange: (pagination: Pagination) => void;
  updateRecord: (record: DataSheetRecord) => void;
  state?: ImportState;
}

export const RecordsTable = ({
  dataSheet,
  loading,
  dataSource,
  files,
  pagination,
  handleTableChange,
  updateRecord,
  state,
}: RecordsTableProps) => {
  const getFieldValue = (
    data: DataSheetRecord,
    item: SheetSchema,
    isUnitId?: boolean
  ) => {
    if (item.input_type === "date") {
      let value = data
        ? data?.[data?.sheet_name]?.[item?.entity_name]
        : "";
      value = value && moment(value).format(YearMonthDayFormat);
      return value;
    } else if (isUnitId) {
      const value =
        data?.[data?.sheet_name]?.[item?.entity_name + "_unit_id"] ||
        item.unit_id;
      return value;
    } else {
      const value =
        typeof data?.[data?.sheet_name]?.[item?.entity_name] === "object"
          ? ""
          : data?.[data?.sheet_name]?.[item?.entity_name];
      return value;
    }
  };

  const isFilesColumnVisible = !!(
    dataSource?.find((record) => record?.fileIds?.length) || files?.length
  );
  const generateColumns = () => {
    const columns: any = dataSheet?.sheet_schema
      ?.map((item) => {
        const dataColumn = {
          title: item.display_name,
          dataIndex: item.entity_name,
          key: item.entity_name,
          width: "10%",
          render: (_: any, record: DataSheetRecord) => {
            return getFieldValue(record, item);
          },
        };
        if (item.unit_id) {
          const unitColumn = {
            title: item.display_name + " Unit",
            dataIndex: item.entity_name + "_unit_id",
            key: item.entity_name + "_unit_id",
            width: "10%",
            render: (_: any, record: DataSheetRecord) => {
              const value = getFieldValue(record, item, true);
              return value;
            },
          };
          return [dataColumn, unitColumn];
        }
        return dataColumn;
      })
      .flat();
    isFilesColumnVisible &&
      columns?.push({
        title: "File",
        dataIndex: "file",
        fixed: "right",
        width: "200px",
        className: "actionsRightBorder",
        render: (_: any, data: DataSheetRecord) => {
          return (
            <Col span={24} className={styles.fileSelector}>
              <FilesDropdown
                record={data}
                files={files}
                updateRecord={updateRecord}
                state={state}
              />
            </Col>
          );
        },
      });
    return columns;
  };
  return (
    <CommonTable
      columns={generateColumns()}
      loading={loading}
      dataSource={dataSource}
      scroll={{ x: "max-content" }}
      rowClassName={`dataSheetsTable ${
        isFilesColumnVisible && "actionsRightBorderRow"
      }`}
      className="tableUpperScroll"
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};
