import React, { useEffect, useState } from "react";
import { useBoolean } from "ahooks";
import { Link, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { Layout, message } from "antd";
import { Space } from "antd";
import { PlusCircleOutlined, FileSearchOutlined } from "@ant-design/icons";
import LogoSideBar from "../LogoSideBar";
import Header from "../Header";
import { LayoutProvider } from "../../contexts/LayoutContext";
import { CreateDataCollection } from "../../components/Modals/CreateDataCollection";
import { AddNewUtilityAccount } from "../../components/Modals/AddNewUtilityAccount";
import { AddNewStandard } from "../../components/Modals/AddNewStandard";
import { DocumentAddBtn } from "../Modals/DocumentAddBtn";
import { CreateContent } from "../Modals/CreateContent";
import { ContentWrapper, StyledButton } from "../../shared/commonStyles";
import Breadcrumbs from "./Breadcrumbs";
import { HeaderBtns } from "../../pages/carbonPage/CalculatorAudit/HeaderBtns";
import { HeaderBtnSingle } from "../../pages/carbonPage/ReductionPlan/Components/SingleTarget/HeaderBtnSingle.tsx";
import { AnalyticsChartModal } from "../../components/Modals/AnalyticsChartModal";
import { CreateTaskDeployment } from "../../components/Modals/CreateTaskDeployment";
import { AddTargetModal } from "../../pages/carbonPage/WhatIfOnboarding/Components/AddTargetModal";
import { reductionPlanStore } from "../../pages/carbonPage/WhatIfOnboarding/ReductionPlanStore";
import { EditCustomFactor } from "../../pages/carbonPage/CustomFactors/EditCustomFactor";
import { AddNewKpiData } from "../Modals/AddNewKpi";
import { AddNewGoals } from "../Modals/AddNewGoals";
import { AddDocumentReview } from "../Modals/AddDocumentReview";
import ExportCalculationButton from "../../pages/carbonPage/Calculator/Components/ExportCalculationButton/ExportCalculationButton";

import styles from "./SiteLayout.module.scss";
import { calculatorStore } from "../../pages/Carbon";
import { AddUnitModal } from "../../pages/Data/UnitsManagement/Components/AddUnitModal";
import DashboardButtons from "../../components/Modals/AnalyticsChartModal/DashboardButtons";
import TimeoutModal from "../TimeoutModal/TimeoutModal";
import { CalculationsButtons } from "../../pages/carbonPage/Calculations/Components/CalculationsAuditorsModal/CalculationsButtons";
import { UnitsManagementHeaderButtons } from "../../pages/Data/UnitsManagement/Components/UnitsManagementHeaderButtons";
import { AddUnitConversionModal } from "../../pages/Data/UnitsManagement/Components/ManageUnitConversions/AddUnitConversionModal";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { ExportAndEditCustomFactor } from "../../pages/carbonPage/CustomFactors/ExportAndEditCustomFactor.tsx";
import { PendingPageButtons } from "../../pages/dataSheetsPage/dataSheetDocumentsPage/DataSheetDocuments/PendingPageButtons";
import { DataSheetsButtons } from "../../pages/dataSheetsPage/Components/DataSheetsButtons";

export const SiteLayout = observer(({ children }) => {
  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();
  const [collapsed, setCollapsed] = useState(true);
  const [userSettingSidebarCollapsed, setUserSettingSidebarCollapsed] =
    useState(null);
  const [
    isAddTargetModalVisible,
    { setTrue: showAddTargetModal, setFalse: hideAddTargetModal },
  ] = useBoolean(false);

  const [isAddUnitModalVisible, setAddUnitModalVisible] = useState(false);
  const [isAddUnitConversionModalVisible, setAddUnitConversionModalVisible] =
    useState(false);

  const location = useLocation();

  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const data = await Auth.currentSession();
        const username = data["accessToken"]["payload"]["username"];
        const resp = await API.graphql({
          query: queries.getUserSettings,
          variables: { owner: username, settingName: "sidebar-collapse" },
        });
        const sideBarCollapeSetting = resp["data"]["getUserSettings"];
        if (sideBarCollapeSetting) {
          const userSettingForCollapsed =
            sideBarCollapeSetting?.settingValue === "true";
          setUserSettingSidebarCollapsed(sideBarCollapeSetting);
          setCollapsed(
            userSettingForCollapsed ? userSettingForCollapsed : false
          );
        }
      } catch (error) {
        console.log("Error while load user settings!", error);
        message.error(
          "Error while loading user settings for sidebar collapsed!"
        );
      }
    };
    getUserSettings();
  }, []);

  const handleAddTarget = (data) => {
    reductionPlanStore
      .saveTarget(data)
      .then(() => reductionPlanStore.getAllTargets());
    hideAddTargetModal();
  };

  const onCollapse = async (collapse) => {
    setCollapsed(collapse);
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      if (userSettingSidebarCollapsed) {
        //if sidebar-collapse is already exist then call update api.
        const payload = {
          owner: username,
          settingName: "sidebar-collapse",
          settingValue: collapse,
        };
        updateUserSettings(payload);
      } else {
        // call create user settings api for sidebar-collapse.
        const payload = {
          owner: username,
          settingName: "sidebar-collapse",
          settingValue: collapse,
        };
        createUserSettings(payload);
      }
    } catch (error) {
      console.log("Somethng went wrong!");
    }
  };

  const createUserSettings = async (payload) => {
    try {
      const resp = await API.graphql({
        query: mutations.createUserSettings,
        variables: { input: payload },
      });
    } catch (error) {
      console.log("Error while create user settings!", error);
    }
  };

  const updateUserSettings = async (payload) => {
    try {
      const resp = await API.graphql({
        query: mutations.updateUserSettings,
        variables: { input: payload },
      });
    } catch (error) {
      console.log("Error while update user settings!", error);
    }
  };

  const ignoreHref = (href) => {
    try {
      const hrefArray = href.split("/");
      const surveyPage = hrefArray?.find((item) => item === "collect-data");
      const standardPage = hrefArray?.find(
        (item) => item === "sustainability-standards"
      );
      const dataSheetPage = hrefArray?.find((item) => item === "data-sheets");
      const standardAudit = hrefArray?.find(
        (item) => item === "audit-standards"
      );
      const dataSheetAudit = hrefArray?.find(
        (item) => item === "audit-data-sheets"
      );
      if (
        (surveyPage ||
          standardPage ||
          dataSheetPage ||
          standardAudit ||
          dataSheetAudit) &&
        hrefArray?.length > 3
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  };

  const isDisabledDeletedCalculation =
    calculatorStore.calculation._id?.$oid === location?.state?.calcId &&
    !calculatorStore?.emissions?.find((item) => item.archived) &&
    !calculatorStore?.offsets?.find((item) => item.archived);

  const getBreadcrumbButton = () => {
    if (
      location.pathname.includes("/carbon/calculations/") &&
      !location.pathname.endsWith("/carbon/calculations") &&
      !location.pathname.includes("/deleted-items")
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledButton
              type="primary"
              disabled={isDisabledDeletedCalculation}
              className={styles.breadcrumbButton}
            >
              <Link
                to={`${location.pathname}/deleted-items`}
                state={location.state}
              >
                <FileSearchOutlined /> <span>View deleted items</span>
              </Link>
            </StyledButton>
            <ExportCalculationButton calculation={location.pathname} />
          </div>
        </div>
      );
    } else if (location.pathname.endsWith("/carbon/calculations")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <CalculationsButtons />
        </div>
      );
    } else if (location.pathname.includes("/audit/calculations-audit/")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <HeaderBtns />
        </div>
      );
    } else if (location.pathname.endsWith("/management/collect-data")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <CreateDataCollection />
        </div>
      );
    } else if (location.pathname.endsWith("/deploy-survey")) {
      const locationArray = location.pathname.split("/");
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <CreateTaskDeployment surveyId={locationArray[3]} />
        </div>
      );
    } else if (
      location.pathname.endsWith("/reporting/sustainability-standards")
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div>
            <AddNewStandard />
            <StyledButton type="primary">
              <Link to="/reporting/sustainability-standards/deleted-standards">
                View deleted standards
              </Link>
            </StyledButton>
          </div>
        </div>
      );
    } else if (
      location.pathname.endsWith(
        "/reporting/sustainability-standards/generate-report"
      )
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    } else if (location.pathname == "/carbon/custom-factors") {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <ExportAndEditCustomFactor />
        </div>
      );
    } else if (location.pathname == "/carbon/reduction-plan") {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <Space>
            <StyledButton onClick={showAddTargetModal} type="primary">
              <PlusCircleOutlined /> <span>Add new target</span>
            </StyledButton>
          </Space>
          <AddTargetModal
            isShown={isAddTargetModalVisible}
            modalType={"combined"}
            targetType={"scienceBased"}
            onClose={hideAddTargetModal}
            onConfirm={(data) => handleAddTarget(data)}
          />
        </div>
      );
    } else if (
      location.pathname.includes("/carbon/reduction-plan/") &&
      location.pathname !== "/carbon/reduction-plan/onboarding-flow"
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <HeaderBtnSingle />
        </div>
      );
    } else if (location.pathname.endsWith("/analytics/manage-dashboards")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <AnalyticsChartModal />
        </div>
      );
    } else if (
      location.pathname.includes("/analytics/") &&
      location.pathname !== "/analytics/manage-dashboards"
    ) {
      const locationArray = location.pathname.split("/");
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <DashboardButtons chartId={locationArray[2]} />
        </div>
      );
    } else if (location.pathname.endsWith("/management/documents")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <DocumentAddBtn />
        </div>
      );
    } else if (location.pathname.endsWith("/management/learning-center")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <CreateContent />
        </div>
      );
    } else if (location.pathname.endsWith("/data/data-sheets")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <DataSheetsButtons />
        </div>
      );
    } else if (location.pathname.endsWith("/audit/manage")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div>
            <StyledButton type="primary">
              <Link to="/audit/manage/deleted">View deleted audits</Link>
            </StyledButton>
          </div>
        </div>
      );
    } else if (location.pathname.endsWith("/audit/manage/deleted")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div>
            <StyledButton type="primary">
              <Link to="/audit/manage">Manage audits</Link>
            </StyledButton>
          </div>
        </div>
      );
    } else if (
      location.pathname.endsWith("/management/goals-and-progress/deleted")
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div>
            <AddNewGoals isDeleted={true} />
          </div>
        </div>
      );
    } else if (
      location.pathname.includes("/data/units-management/") &&
      location.pathname.endsWith(
        "/data/units-management/manage-unit-conversions"
      )
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <StyledButton
            type="primary"
            onClick={() => setAddUnitConversionModalVisible(true)}
          >
            <PlusCircleOutlined /> <span>Add New Unit Conversion</span>
          </StyledButton>
          <AddUnitConversionModal
            visible={isAddUnitConversionModalVisible}
            onClose={() => setAddUnitConversionModalVisible(false)}
          />
        </div>
      );
    } else if (
      location.pathname.includes("/data/units-management/") &&
      !location.pathname.endsWith("/data/units-management")
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <StyledButton
            type="primary"
            onClick={() => setAddUnitModalVisible(true)}
          >
            <PlusCircleOutlined /> <span>Add new unit</span>
          </StyledButton>
          <AddUnitModal
            visible={isAddUnitModalVisible}
            onClose={() => setAddUnitModalVisible(false)}
            groupName={location.state}
          />
        </div>
      );
    } else if (location.pathname.endsWith("/data/units-management")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <UnitsManagementHeaderButtons location={location} />
        </div>
      );
    } else if (location.pathname.endsWith("/reporting/custom-standard")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <StyledButton type="primary">
            <Link to="/reporting/custom-standard/create">
              <PlusCircleOutlined /> <span>Create custom Standard</span>
            </Link>
          </StyledButton>
        </div>
      );
    } else if (location.pathname.endsWith("/data/import-data")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <StyledButton type="primary">
            <Link to="/data/import-data/import-new-data">
              <PlusCircleOutlined />{" "}
              <span style={{ margin: "0px 5px" }}> Upload data </span>
            </Link>
          </StyledButton>
        </div>
      );
    } else if (location.pathname.endsWith("/data/connect-utilities")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <AddNewUtilityAccount />
        </div>
      );
    } else if (
      location.pathname === "/data/kpi" ||
      (location.pathname.includes("/data/kpi") &&
        location.pathname.endsWith("details"))
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <AddNewKpiData />
        </div>
      );
    } else if (location.pathname === "/management/goals-and-progress") {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <AddNewGoals />
        </div>
      );
    } else if (
      !location.pathname.endsWith("/data/data-sheets/duplicate/") &&
      (location.pathname.endsWith("/") ||
        location.pathname.endsWith("/home") ||
        location.pathname.endsWith("/data") ||
        location.pathname.endsWith("/management") ||
        location.pathname.endsWith("/reporting") ||
        location.pathname.endsWith("/analytics") ||
        location.pathname.endsWith("/carbon") ||
        location.pathname.endsWith("/audit") ||
        location.pathname.endsWith("/ratings") ||
        location.pathname.endsWith("/tools"))
    ) {
      return <Breadcrumbs />;
    } else if (location.pathname.endsWith("/data/connect-utilities")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <AddNewUtilityAccount />
        </div>
      );
    } else if (
      location.pathname.endsWith("/data/import-data/import-new-data")
    ) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    } else if (location.pathname.endsWith("data/pending-items")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <PendingPageButtons />
        </div>
      );
    } else if (location.pathname.endsWith("/data/connect-api")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    } else if (location.pathname.endsWith("/tools/ai")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    } else if (location.pathname.endsWith("/tools/esg-handbook")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    } else if (location.pathname.endsWith("/reporting/document-review")) {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
          <div>
            <AddDocumentReview />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.breadcrumbWrap}>
          <Breadcrumbs />
        </div>
      );
    }
  };

  return (
    <LayoutProvider>
      <TimeoutModal />
      <Layout>
        <LogoSideBar />

        <ContentWrapper>
          {<Header />}
          {getBreadcrumbButton()}
          {children}
        </ContentWrapper>
      </Layout>
    </LayoutProvider>
  );
});
