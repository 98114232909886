import React from "react";
import { Resizable } from "react-resizable";

interface ResizableHeaderCellProps {
  onResize: (column: any) => void;
  width: number;
}

export const ResizableHeaderCell = ({
  onResize,
  width,
  ...restProps
}: ResizableHeaderCellProps) => {
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => e.stopPropagation()}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
