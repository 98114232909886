import React, { useEffect, useState } from "react";
import { useBoolean } from "ahooks";
import { Col, Radio, RadioChangeEvent, Row, Space, Spin } from "antd";
import {
  FormWrapper,
  StyledButton,
  TabContentWrap,
} from "../../../shared/commonStyles";
import { ReductionPlanListView } from "./ReductionPlanListView";
import { ReductionPlanKanbanView } from "./ReductionPlanKanbanView";
import { reductionPlanStore } from "../WhatIfOnboarding/ReductionPlanStore";
import { observer } from "mobx-react-lite";
import { AddTargetModal } from "../WhatIfOnboarding/Components/AddTargetModal";
import styles from "./ReductionPlan.module.scss";

export const ReductionPlan = observer(() => {
  const [planView, setPlanView] = useState<"list" | "kanban" | "gaant">("list");

  const [
    isAddTargetModalVisible,
    { setTrue: showAddTargetModal, setFalse: hideAddTargetModal },
  ] = useBoolean(false);

  useEffect(() => {
    reductionPlanStore.getAllTargets();
  }, []);

  const handleChangeView = (e: RadioChangeEvent) => {
    setPlanView(e.target.value);
  };

  const handleAddTarget = (data: TargetPayload) => {
    reductionPlanStore
      .saveTarget(data)
      .then(() => reductionPlanStore.getAllTargets());
    hideAddTargetModal();
  };

  if (reductionPlanStore.loadingTargets && !reductionPlanStore.targets.length) {
    return <Spin className={styles.loader} />;
  }

  return (
    <TabContentWrap>
      {reductionPlanStore.targets.length ? (
        <div className={styles.contentWrap}>
          <Row justify="space-between">
            <Col>
              <Space>
                <span className="font-18">
                  <b>Reduction Plan</b>
                </span>
              </Space>
            </Col>
            <Col>
              <Radio.Group
                defaultValue="list"
                onChange={handleChangeView}
                className={styles.radioView}
              >
                <FormWrapper>
                  <Radio value="list">List view</Radio>
                  <Radio value="kanban">Kanban view</Radio>
                  {/* <Radio value="gaant">Gaant chart</Radio> */}
                </FormWrapper>
              </Radio.Group>
            </Col>
          </Row>
          {planView === "list" && <ReductionPlanListView />}
          {planView === "kanban" && <ReductionPlanKanbanView />}
        </div>
      ) : (
        <>
          <Row justify="center" className={styles.emptyTitle}>
            You don&apos;t have any targets yet
          </Row>
          <Row justify="center" className={styles.emptyText}>
            Add company&apos;s reduction target to track progress
          </Row>
          <Row justify="center">
            <StyledButton onClick={showAddTargetModal}>Add target</StyledButton>
          </Row>
          <AddTargetModal
            isShown={isAddTargetModalVisible}
            modalType={AddTargetModalType.COMBINED}
            targetType={TargetType.SCIENCE_BASED}
            onClose={hideAddTargetModal}
            onConfirm={(data) => handleAddTarget(data)}
          />
        </>
      )}
    </TabContentWrap>
  );
});
