import { useState, useEffect } from "react";
import { message } from "antd";
import { getReportFilters } from "../services/filter";

export const useBusinessUnitLocationPeriodFilter = () => {
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingReportPeriods, setLoadingReportPeriods] = useState(false);
  const [loadingBusinessUnits, setLoadingBusinessUnits] = useState(false);
  const [locations, setLocations] = useState(null);
  const [businessUnits, setBusinessUnits] = useState(null);
  const [reportPeriods, setReportPeriods] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [businessUnitId, setBusinessUnitId] = useState(null);
  const [reportPeriodId, setReportPeriodId] = useState(null);

  useEffect((_) => {
    const loadData = async () => {
      setLoadingReportPeriods(true);
      try {
        const payload = { filter: { filter_type: "reporting_period" } };
        const response = await getReportFilters(payload);

        if (response) {
          setReportPeriods(response);
        }
      } catch (err) {
        message.error(
          "Error loading report period options. Please contact support."
        );
        console.log(err);
      } finally {
        setLoadingReportPeriods(false);
      }
    };
    loadData();
  }, []);

  useEffect((_) => {
    const loadData = async () => {
      setLoadingLocations(true);
      try {
        const payload = { filter: { filter_type: "location" } };
        const response = await getReportFilters(payload);
        if (response) {
          setLocations(response);
        }

      } catch (err) {
        message.error("Error Setting Default Location.");
        console.log(err);
      } finally {
        setLoadingLocations(false);
      }
    };
    loadData();
  }, []);

  useEffect((_) => {
    const loadData = async () => {
      setLoadingBusinessUnits(true);
      try {
        const payload = { filter: { filter_type: "business_unit" } };
        const response = await getReportFilters(payload);
    
        if (response) {
          setBusinessUnits(response);
        }

      } catch (err) {
        message.error("Error loading business units. Please contact support.");
        console.log(err);
      } finally {
        setLoadingBusinessUnits(false);
      }
    };
    loadData();
  }, []);

  return {
    loadingBusinessUnits,
    loadingLocations,
    loadingReportPeriods,
    businessUnits,
    locations,
    reportPeriods,
    locationId,
    setLocationId,
    businessUnitId,
    setBusinessUnitId,
    reportPeriodId,
    setReportPeriodId,
    setLocations,
    setBusinessUnits,
    setReportPeriods,
  };
};
