import React from 'react';
import { Empty } from "antd";

import ServiceCard from './ServiceCard';
import { CardsWrap, StyledEmpty, Center } from "../../../shared/commonStyles";

const ServiceCardContainer = ({ services }) => {
  if(!services || !services.length) {
    return (
      <Center>
        <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Center>
    );
  }

  return (
    <CardsWrap>
      {services?.map(service => 
        <ServiceCard key={service._id.$oid} data={service} />  
      )}
    </CardsWrap>
  );
}

export default ServiceCardContainer;
