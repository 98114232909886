import React, { useState } from 'react';
import {
  Typography, Row, Col, Switch, Divider, Image, Alert, Steps
} from 'antd';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, CloudSyncOutlined, MergeCellsOutlined, TeamOutlined, BlockOutlined, CarryOutOutlined, AlertOutlined, SettingOutlined } from '@ant-design/icons';
import { TabContentWrap, OverviewContentWrap } from './../../shared/commonStyles';

const { Step } = Steps;

const ProtectPage = () => {
  const [dataIntegritySwitchChecked, setDataIntegritySwitchChecked] = useState(true);
  const [, setCurrentHover] = useState(0);
  

  const DataIntegrityRow = () => (
    <Row
      justify="space-around"
      align="middle"
      onMouseEnter={() => setCurrentHover(0)}      
    >
      <Col span={16}>
        <Row>
          <Typography.Title level={4}>
            Data Integrity
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Text>
            Hydrus provides seamlessly integrated data integrity using a blockchain-like ledger database to store data.
          </Typography.Text>
          <Typography.Text>
            Data integrity feature cannot be turned off at this time.
          </Typography.Text>
        </Row>
      </Col>
      <Col span={2} offset={6}>
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          disabled={true}
          checked={dataIntegritySwitchChecked}
          onChange={(checked) => setDataIntegritySwitchChecked(checked)}
        />
      </Col>
    </Row>
  );
  
  const InfoMessage = () => (
    <Row>
    <Col span={24}>
          <Alert
            message="Info"
            description="For the below section, users without a technical background will find the material challenging to understand - please contact Hydrus support if you have any questions."
            type="info"
            showIcon
          />
    </Col>
    </Row>
  );

  const ShowTimeline = () => (
    <>
  <Row style={{padding: '25px'}}>
  <Typography.Title level={4}>
    Normal Flow
  </Typography.Title>
    <Steps>
      <Step status="finish" title="Data Collection" icon={<UserOutlined />} />
      <Step status="finish" title="Blockchain Processing" icon={<LoadingOutlined />} />
      <Step status="finish" title="Algorithmic Verification" icon={<SettingOutlined />} />
      <Step status="finish" title="Done" icon={<SmileOutlined />} />
    </Steps>
  </Row>
  <Row style={{padding: '25px'}}>
  <Typography.Title level={4}>
    Error Flow
  </Typography.Title>
    <Steps>
      <Step status="finish" title="Data Collection" icon={<UserOutlined />} />
      <Step status="finish" title="Blockchain Processing" icon={<LoadingOutlined />} />
      <Step status="finish" title="Algorithmic Verification" icon={<SettingOutlined />} />
      <Step status="finish" title="Error Detected" icon={<AlertOutlined />} />
      <Step status="finish" title="Manual Review" icon={<SolutionOutlined />} />
      <Step status="finish" title="Done" icon={<SmileOutlined />} />
    </Steps>
  </Row>
  <Row style={{padding: '25px'}}>
  <Typography.Title level={4}>
    Automation Flow
  </Typography.Title>
    <Steps>
      <Step status="finish" title="External System" icon={<CloudSyncOutlined />} />
      <Step status="finish" title="Data Sync/Integration" icon={<MergeCellsOutlined />} />
      <Step status="finish" title="Done" icon={<SmileOutlined />} />
    </Steps>
  </Row>
  <Row style={{padding: '25px'}}>
  <Typography.Title level={4}>
    Audit Flow
  </Typography.Title>
    <Steps>
      <Step status="finish" title="Auditors" icon={<TeamOutlined />} />
      <Step status="finish" title="Blockchain Data Ledger" icon={<BlockOutlined />} />
      <Step status="finish" title="Attestation" icon={<CarryOutOutlined />} />
      <Step status="finish" title="Done" icon={<SmileOutlined />} />
    </Steps>
  </Row>

    </>
  );



  const DataStructure = () => (
    <Row
      justify="space-around"
      align="middle"
      onMouseEnter={() => setCurrentHover(1)}
    >
      <Col span={24}>
        <Row>
          <Typography.Title level={4}>
            Ledger Data Structure
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Text>
            Below is a visual representation of the block data structure for reference.
            The diagram shows that transactions are committed to the journal as blocks that contain document revision entries. It also shows that each block is hash-chained to subsequent blocks and has a sequence number to specify its address within the strand.
          </Typography.Text>
        <Row>
          <Image width={"75%"} src={process.env.PUBLIC_URL + '/journal-structure.png'} />
        </Row>
        </Row>
      </Col>
    </Row>
  );

  const VerificationSteps = () => (
    <Row
      justify="space-around"
      align="middle"
      onMouseEnter={() => setCurrentHover(1)}
    >
      <Col span={24}>
        <Row>
          <Typography.Title level={4}>
            Verification Details
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Text>
            SHA-256 hash is calculated on each operation to the datastore. Changes result in a new hash being created. The digest is a cryptographic representation of the ledger at a given point in time and collected every 4 hours automatically. 
          </Typography.Text>
        <Row>
          <Image width={"75%"} src={process.env.PUBLIC_URL + '/sha256.png'} />
        </Row>

        </Row>
      </Col>
    </Row>
  );

  const ProofExample = () => (
    <Row
      justify="space-around"
      align="middle"
      onMouseEnter={() => setCurrentHover(1)}
    >
      <Col span={24}>
        <Row>
          <Typography.Title level={4}>
            Example Proof
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Text>
            Full hash chain recalculation is challenging when the data ledger becomes large over time so a merkle audit proof is run to only validate a small subset of the data for validity. 
          </Typography.Text>
        </Row>
        <Row>
          <Image width={"50%"} src={process.env.PUBLIC_URL + '/hash-tree.png'} />
        </Row>
      </Col>
    </Row>
  );


  return (
    <TabContentWrap>
      <OverviewContentWrap>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <div>
              <DataIntegrityRow />
              <Divider />
              <InfoMessage />
              <ShowTimeline />
              <Divider />
              <DataStructure />
              <Divider />
              <VerificationSteps />
              <Divider />
              <ProofExample />
              <Divider />
            </div>
          </Col>
        </Row>
    </OverviewContentWrap>
    </TabContentWrap>
  );
};

export default ProtectPage;
