import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import { fileUploadSize, fileUploadTypes } from "../../../../shared/constants";

interface StatusReasonModalProps {
  button: {
    icon: React.ReactChild;
    text: string;
    className?: string;
    type: string;
    disabled?: boolean;
    submitting?: boolean;
  };
  onConfirm: (reason: string, files?: File[]) => void;
}

export const StatusReasonModal = ({
  button,
  onConfirm,
}: StatusReasonModalProps) => {
  const [statusReason, setStatusReason] = useState("");
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const handleConfirm = () => {
    onConfirm(statusReason, files);
    setStatusReason("");
    setFiles([]);
  };

  const beforeUpload = async (file: File) => {
    const filename = file?.name;
    const fileSize = file?.size || 0;
    const ext = (filename && filename?.split(".")?.pop()?.toLowerCase()) || "";

    if (!fileUploadTypes.includes(ext)) {
      message.error(`${file?.name} is not a valid file type`);
      return;
    }
    if (fileSize > fileUploadSize) {
      message.error("File Upload size is larger than 25MB");
      return;
    }
    setFiles([...files, file]);
    return false;
  };

  const onRemoveFileFromListHandler = (file: any) => {
    const filesList = files.filter((v) => v.uid !== file.uid);
    setFiles(filesList);
  };

  return (
    <>
      <Popconfirm
        visible={visible}
        okText="Confirm"
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        onVisibleChange={setVisible}
        placement="bottom"
        overlayClassName="popoverContent"
        title={
          <FormWrapper>
            <Row>
              <Typography.Title level={5}>
                Add a data audit note (optional)
              </Typography.Title>
            </Row>
            <div>Reason</div>
            <Form.Item required>
              <Input.TextArea
                value={statusReason}
                onChange={(e) => setStatusReason(e.target.value)}
              />
            </Form.Item>
            <Row>
              <div>
                Attach related files <span>(optional)</span>
              </div>
            </Row>
            <Upload
              customRequest={({ file, onSuccess }) => {
                onSuccess && onSuccess("ok");
              }}
              beforeUpload={beforeUpload}
              fileList={files}
              className="upload-list-inline"
              listType="text"
              multiple={false}
              onRemove={onRemoveFileFromListHandler}
            >
              <StyledButton icon={<UploadOutlined />}>Upload</StyledButton>
            </Upload>
          </FormWrapper>
        }
        onConfirm={handleConfirm}
      >
        {button.type === "text" ? (
          <Button
            type={button.type}
            onClick={() => setVisible(true)}
            icon={button.icon}
            disabled={button.disabled}
            loading={button.submitting}
            className={button.className || ""}
          >
            {button.text}
          </Button>
        ) : (
          <StyledButton
            type={button.type}
            onClick={() => setVisible(true)}
            icon={button.icon}
            disabled={button.disabled}
            loading={button.submitting}
            className={button.className || ""}
          >
            {button.text}
          </StyledButton>
        )}
      </Popconfirm>
    </>
  );
};
