import React, { useState } from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Divider,
  Checkbox,
  FormInstance,
  Select,
} from "antd";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const { Title } = Typography;

interface SheetDescriptionProps {
  form: FormInstance<any>;
  descriptionData: string;
  onClickNext: (descriptionData: any) => Promise<void>;
  onClickPrevious: () => void;
  changeDescription: (data: any) => void;
  loadDataSheets: boolean;
  dataSheetsList: DataSheet[];
  initialValues: any;
  isDuplicateMode: boolean;
  sheetNames?: string[];
}

const SheetDescription = ({
  form,
  descriptionData,
  changeDescription,
  onClickNext,
  onClickPrevious,
  dataSheetsList,
  loadDataSheets,
  initialValues,
  isDuplicateMode,
  sheetNames,
}: SheetDescriptionProps) => {
  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>Sheet Description</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Name"
              name="sheetName"
              rules={[
                {
                  required: true,
                  pattern: /^((?![.$]).)*$/gm,
                  message:
                    "Please input unique sheet name! The name cannot include characters '.', '$'",
                  validator: (_, value) => {
                    try {
                      if (
                        sheetNames?.some((v) => v === initialValues?.sheetName)
                      ) {
                        throw new Error("Sheet name is already exist!");
                      } else {
                        return Promise.resolve(true);
                      }
                    } catch (err) {
                      throw new Error("Sheet name is required!");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="Type in a name for the data sheet" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={descriptionData.length ? descriptionData : ""}
                onReady={(editor: any) => {
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  changeDescription(data);
                  const values = form.getFieldsValue();
                  form.setFieldsValue({ ...values, description: data });
                }}
              />
            </Form.Item>
          </Col>
          {!initialValues.is_reference_data && !isDuplicateMode && (
            <Col span={24} style={{ width: "100%" }}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="metadata_sheet_id"
                label="Select Metadata Entity Sheet to autofill the data"
              >
                <Select
                  showSearch
                  loading={loadDataSheets}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={dataSheetsList
                    ?.filter((sheet) => sheet?.is_reference_data)
                    ?.map((sheet) => {
                      return {
                        value: sheet?._id?.$oid,
                        label: sheet?.sheet_name,
                      };
                    })}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item name="enableComments" valuePropName="checked">
              <Checkbox>Enable comments</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="enableChangeHistory" valuePropName="checked">
              <Checkbox>Enable change history</Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <StyledButton onClick={() => onClickPrevious()} type="custom">
                <LeftOutlined /> <span>Back</span>
              </StyledButton>
              <StyledButton onClick={onClickNext} type="custom">
                <span>Continue to step {isDuplicateMode ? "2" : "3"}</span>{" "}
                <RightOutlined />
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default SheetDescription;
