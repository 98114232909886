import React, { useState, useEffect } from 'react';
import {
  Spin,
  Space,
  Menu
} from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { getEsgImprovementIndicators } from "../../services/esg";

import {
  TableWrap,
  PageWrap,
  StyledButton,
  CommonTable,
  StyledTabbedMenu,
  Heading2
} from "./../../shared/commonStyles";
import IndicatorDetailDrawer from './components/IndicatorDetailDrawer';

const PILLARS = {
  E: "E",
  S: "S",
  G: "G"
}

const PILLAR_FILTERS = [
  {value: PILLARS.E, label: 'E (Environmental)'},
  {value: PILLARS.S, label: 'S (Social)'}, 
  {value: PILLARS.G, label: 'G (Governance)'},
]

const EsgHandbookPage = () => {
  const [indicatorList, setIndicatorList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [isLoadingIndicatorList, setIsLoadingIndicatorList] = useState(false);
  const [indicatorDetailToView, setIndicatorDetailToView] = useState(null);
  const [externalTableFilters, setExternalTableFilters] = useState({
    pillar: PILLARS.E
  })

  const makeQuery = (params) => {
    const { pagination: _pagination } = params;
    const filter = { ...externalTableFilters };
    return {
      skip: (_pagination.current - 1) * _pagination.pageSize,
      limit: _pagination.pageSize,
      filter
    };
  };

  const fetchIndicators = async (params) => {
    setIsLoadingIndicatorList(true);
    try {
      const requestPayload = makeQuery(params);
      const { pagination } = params;

      const response = await getEsgImprovementIndicators(requestPayload);
      setPagination({ ...pagination, total: response.filteredCount });
      setIndicatorList(response.data)
    } catch (error) {
      console.log("Error while fetching esg improvement indicator list", error);
    } finally {
      setIsLoadingIndicatorList(false);
    }
  }

  useEffect(() => {
    fetchIndicators({ pagination });
  },[externalTableFilters]);

  const handleTableChange = (pagination) => {
    fetchIndicators({ pagination });
  };

  const onClickViewDetails = (rowData) => {
    setIndicatorDetailToView(rowData);
  }

  const onCloseDetailDrawer = () => {
    setIndicatorDetailToView(null);
  }
 
  const onChangeExternalFilters = (data, filterName) => {
    if(externalTableFilters[filterName] === data) return;

    setPagination({ ...pagination, current: 1 });
    setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "indicator_name",
      width: '20%'
    },
    {
      title: "Subpillar",
      dataIndex: "sub_pillar",
      width: '12%'
    },
    {
      title: "Sector",
      dataIndex: "sectors",
      width: '12%'
    },
    {
      title: "Applicability",
      dataIndex: "applicability",
      width: '12%'
    },
    {
      title: "Description",
      dataIndex: "description",
      width: '34%'
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: '10%',
      render: (_, data) => (
        <Space direction="horizontal">
          <StyledButton type="default" onClick={() => onClickViewDetails(data)}>
            <EyeOutlined />
            <span>View Details</span>
          </StyledButton>
        </Space>
      ),
    }
  ]

  return (
    <Spin spinning={isLoadingIndicatorList}>
      <PageWrap>
        <Space direction="vertical" size={28}>
          <Heading2 mt="12px">Select indicators you want to improve</Heading2>

          <StyledTabbedMenu
            mode="horizontal"
            selectedKeys={[externalTableFilters.pillar]}
          >
            {PILLAR_FILTERS.map(
              (pillar) =>
                  <Menu.Item key={pillar.value} onClick={() => onChangeExternalFilters(pillar.value, 'pillar')}>
                    <span>{pillar.label}</span>
                  </Menu.Item>
                )
            }
          </StyledTabbedMenu>
        </Space>

        <TableWrap>
          <CommonTable
            rowKey={(data) => {
              const id = data?.["_id"]?.["$oid"];
              return id;
            }}
            dataSource={indicatorList}
            columns={columns}
            scroll={{ x: true }}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </TableWrap>
        <IndicatorDetailDrawer 
          data={indicatorDetailToView} 
          visible={!!indicatorDetailToView} 
          onClose={onCloseDetailDrawer} 
        />
      </PageWrap>
    </Spin>
  )
}

export default EsgHandbookPage;
