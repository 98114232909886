import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Spin,
  Divider, 
  Typography,  
  Row,
  Col,  
  Select,
  Timeline
} from "antd";
import {  
  FormWrapper
} from "../../../../shared/commonStyles";

import { useAssignedAuditorsList } from "../../hooks/useAssignedAuditorsList";

const { Title } = Typography;
const { Option } = Select;

export const AuditHistorySection = ({ rowData, auditRecord}) => {  
  const [selectedAuditor, setSelectedAuditor] = useState(null);
  const {
    loading,
    assignedAuditorsList,
    getUniqueUsersList,
    loadAssignedAuditors,
  } = useAssignedAuditorsList();
  
  const uniqueAuditorsList = getUniqueUsersList(assignedAuditorsList);
  useEffect(() => {    
    if (rowData && auditRecord) {
      loadAssignedAuditors(auditRecord, rowData);
    }
  }, [auditRecord, rowData]);  

  const onChangeAuditor = (auditorId) => {
    const selectedAuditor = uniqueAuditorsList.find(item => item.group_user_id === auditorId)
    if(selectedAuditor){
      setSelectedAuditor(selectedAuditor)
    }  else {
      setSelectedAuditor(null);
    }
  }
  return (
    <>
      <div className="signoff-section">
        <Title className="color-white" level={4}>Audit History</Title>
        <Divider style={{backgroundColor:'#3A3C5A', marginTop:'15px'}} />
        <Spin spinning={loading}>
          <div className="width-100">
            
            {uniqueAuditorsList && (
              <FormWrapper>
                <Select placeholder="All auditors" style={{width:'100%'}} onChange={onChangeAuditor}>
                  <Option key="key" value="key">All Auditors</Option>
                  {uniqueAuditorsList?.map((item,index)=>{            
                    return(
                      <Option key={index} value={item.group_user_id}>{item.first_last_name}</Option>
                    )
                  })}
                </Select>
            </FormWrapper> 
            )}           
            
            <Row>  
              <Col span={24} className="data-form-history-timeline-section">
                <Timeline>
                  {selectedAuditor ? 
                    <TimelineComponent item={selectedAuditor} />
                  :
                  (
                    <>
                      {(uniqueAuditorsList || []).map((item,index)=>{                        
                        return(
                          <TimelineComponent item={item} key={index} />                          
                        )})}
                      </>
                    )}
                </Timeline>
              </Col>
            </Row>            
          </div>          
        </Spin>
      </div>
    </>
  );
};

export const TimelineComponent = ({item}) => {
  let status = "Not Started"
  if(item?.audit_log_data?.status === "approved") status = "approved";
  if(item?.audit_log_data?.status === "denied") status = "denied";
  const dotColor = status === "approved" ? "green" : status === "denied" ? "red" : "blue";
  const auditTimestamp = item.audit_log_data?.last_modified ? moment(item.audit_log_data?.last_modified).format('MMMM Do YYYY, h:mm a') : 'No Date Information';
  
  return (
    <Timeline.Item color={dotColor}>
      {!item?.audit_log_data ? 
        <p>{item.first_last_name} <b>has not approved or denied this record yet.</b></p>
      :
        <>
          <p>{auditTimestamp} </p>
          <p>{item.first_last_name} <b>{status} this record.</b></p>
          <p>Comment : <b>{item?.audit_log_data?.comment}</b></p>
        </>
      }
    </Timeline.Item>
  )
}