import React, { useState, useEffect } from "react";
import { Tag, message, Space, Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import SearchableColumn from "../../../components/SearchableColumn";
import { Auth } from "aws-amplify";
import {
  StyledButton,
  CommonTable,
  StyledTag,
  TableStyledMenu,
} from "../../../shared/commonStyles";
import { useNavigate } from "react-router";
import ConfirmModal from "./ConfirmModal";
import {
  getReportingStandards,
  getReportingStandardsById,
  deleteReportingStandards,
  isJsonString,
  parsedStandardData,
} from "../../../services/standards";
const ESGStandardsDataTable = () => {
  const [dataSource, setDatasource] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);
  const [standardFilter, setStandardFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [themeFilter, setThemeFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [codeFilter, setCodeFilter] = useState([]);
  const [status, setStatus] = useState();
  const [modaldata, setModalData] = useState(null);
  const [confirmationModal, setComfirmationModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const [schema, setSchema] = useState("{}");
  const [UISchema, setUISchema] = useState("{}");
  const navigate = useNavigate();

  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  //const Add

  const handleChange = (pagination, filters, sorter) => {
    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const data = await Auth.currentSession();
      const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const payload = { filter: { group_id } };
      const formItems = await getReportingStandards(payload);

      const parsedData = await Promise.all(
        formItems.map(async (item) => {
          return await parsedStandardData(item, "reporting");
        })
      );

      setDatasource(parsedData);
    } catch (error) {
      console.log("Found Some Error", error);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource) {
      const standardSet = new Set();
      const codeSet = new Set();

      dataSource.forEach((val) => {
        (val["frameworks"] ?? []).forEach((stan) => standardSet.add(stan));
        if ("standard_code" in val) codeSet.add(val["standard_code"]);
      });
      setStandardFilter(Array.from(standardSet));
      setCodeFilter(Array.from(codeSet));
    }
  }, [dataSource])

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    SearchableColumn({
      title: "Name",
      dataIndex: "name",
      width: "15%",
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend"],
      searchPlaceHolder: "Search Name",
      filteredValue: tableFilters?.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
    }),
    SearchableColumn({
      title: "Description",
      dataIndex: "description",
      width: "20%",
      searchPlaceHolder: "Search description",
      filteredValue: tableFilters?.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
    }),

    {
      title: "Standard",
      dataIndex: "frameworks",
      width: "12%",
      render: (record, data) => {
        return record?.map((a, i) => {
          return <StyledTag className="margin-2" key={data?._id?.$oid + i}>
            {a}
          </StyledTag>
        })
      },
      sorter: standardSorter,
      sortDirections: ["descend", "ascend"],
      filters: standardFilter.map((fil) =>
        fil !== "" ? { text: fil, value: fil } : { text: "None", value: "" }
      ),
      onFilter: (value, record) => record?.frameworks?.includes(value),
      filteredValue: tableFilters?.filteredInfo?.frameworks
        ? tableFilters.filteredInfo.frameworks
        : null,
      sortOrder:
        tableFilters?.sortedInfo?.field === "frameworks" &&
        tableFilters?.sortedInfo?.order,
    },
    {
      title: "Code",
      dataIndex: "standard_code",
      width: "15%",
      sorter: (a, b) => { if (a.name && b.name) { return b.name.localeCompare(a.name) } },
      sortDirections: ["descend", "ascend"],
      filters: codeFilter?.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) =>
        record?.standard_code?.indexOf(value) === 0,
      filteredValue: tableFilters?.filteredInfo?.standard_code
        ? tableFilters?.filteredInfo.standard_code
        : null,
      sortOrder:
        tableFilters?.sortedInfo?.field === "standard_code" &&
        tableFilters?.sortedInfo?.order,
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "6%",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton
            type="default"
            onClick={() => onRowEdit(record)}
            disabled={record?.status == "INACTIVE"}
          >
            <EditOutlined />
          </StyledButton>
          <Dropdown overlay={() => menuButtons(record)} trigger={["click"]}>
            <StyledButton
              type="default"
              style={{
                marginLeft: "10",
                padding: "10px 18px",
              }}
            >
              <EllipsisOutlined />
            </StyledButton>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        onClick={() => onRowDeleteRestore(record, "DELETE")}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  const onRowEdit = (rowData) => {
    navigate(`/reporting/custom-standard/${rowData._id["$oid"]}/update`);
  };

  const onRowDeleteRestore = async (rowData, status) => {
    try {
      setLoading(true);
      const payload = rowData._id["$oid"];
      const response = await getReportingStandardsById(payload);

      setModalData(response);
      setStatus(status);
      setComfirmationModal(true);
    } catch (e) {
      message.error("Some problem occured while doing this action!!!");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (modaldata) {
  //     const formSchema = isJsonString(modaldata?.form_json_code)
  //       ? modaldata?.form_json_code
  //       : JSON.stringify(modaldata?.form_json_code);
  //     const objectLength = Object.keys(
  //       isJsonString(modaldata?.form_json_code)
  //         ? JSON.parse(JSON.parse(modaldata?.form_json_code))
  //         : JSON.parse(modaldata?.form_json_code)
  //     ).length;
  //     setSchema(objectLength === 0 ? "{}" : formSchema);
  //     const formUiSchema = isJsonString(modaldata?.form_ui_code)
  //       ? modaldata?.form_ui_code
  //       : JSON.stringify(modaldata?.form_ui_code);
  //     //const formUiSchema = JSON.parse(modaldata?.form_ui_code);
  //     setUISchema(objectLength === 0 ? "{}" : formUiSchema);
  //   }
  // }, [modaldata]);

  const onConfirmModal = async () => {
    const showMsg = status === "INACTIVE" ? "archived" : "restored";
    try {
      setLoader(true);
      if (status === "DELETE") {
        const payload = {
          _id: modaldata._id,
        };
        const response = await deleteReportingStandards(payload);
        if (response) {
          message.success("Custom Standard deleted successfully");
          setComfirmationModal(false);
          loadData();
        } else {
          message.error(
            "Something went wrong while deleting the custom standard!"
          );
        }
      } else {
        const formJsonCode = JSON.stringify(schema);
        const formUiCode = JSON.stringify(UISchema);

        const payload = {
          _id: modaldata._id,
          name: modaldata.name,
          description: modaldata.description,
          standard_code: modaldata.standard_code,
          standard_name: modaldata.standard_name,
          status: status,
          form_json_code: formJsonCode,
          form_ui_code: formUiCode,
          resource_urls: modaldata.resource_urls,
          tags: {
            category: modaldata.category,
            theme: modaldata.theme,
            industry: modaldata.industry,
          }
        };
      }
    } catch (error) {
      console.log("found error", error);
      message.error(
        `Something went wrong while ${showMsg} the custom standard!`
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <CommonTable
        loading={loading}
        rowKey={(data) => {
          const id = data?.["_id"]?.["$oid"];
          return id;
        }}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: true }}
        onChange={handleChange}
      />
      {confirmationModal && (
        <ConfirmModal
          description="Are you sure you want to delete this custom standard?"
          visible={confirmationModal}
          onConfirm={onConfirmModal}
          onClose={() => setComfirmationModal(false)}
          confirmLoading={loader}
        />
      )}
    </>
  );
};

export default ESGStandardsDataTable;

const standardSorter = (a, b) => {
  a = a.frameworks;
  b = b.frameworks;
  const a_length = a.length;
  const b_length = b.length;
  const diff = a_length - b_length;
  if (diff > 0) {
    return 1;
  }
  if (diff < 0) {
    return -1;
  } else {
    let i = 0;
    while (i < a_length) {
      const comp = a[i].localeCompare(b[i]);

      if (comp === 0) {
        i++;
      } else {
        return comp;
      }
    }
  }

  return 0;
};
