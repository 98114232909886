import { Row } from "antd";
import React, { useState } from "react";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { StyledButton } from "../../../shared/commonStyles";
import LongTermGoalModal from "../../../pages/goalsProgressPageNew/manageGoals/LongTermGoalModal";
import { useNavigate } from "react-router-dom";

export const AddNewGoals = ({ isDeleted }) => {
  console.log("main", isDeleted);
  const navigate = useNavigate();
  const [longTermModal, setLongTermModal] = useState(false);

  return (
    <>
      <Row justify="end">
        {isDeleted ? (
          <StyledButton
            type="primary"
            onClick={() => navigate("/management/goals-and-progress")}
          >
            <span>Manage Goals</span>
          </StyledButton>
        ) : (
          <>
            {" "}
            <StyledButton
              type="primary"
              onClick={() => navigate("/management/goals-and-progress/create")}
            >
              <PlusCircleOutlined /> <span>Create SDG</span>
            </StyledButton>
            <StyledButton type="primary" onClick={() => setLongTermModal(true)}>
              <PlusCircleOutlined /> <span>Create Long-Term Goal</span>
            </StyledButton>
            {longTermModal && (
              <LongTermGoalModal
                visible={longTermModal}
                action={"create"}
                type="GEN_LONG"
                onClose={() => setLongTermModal(false)}
              />
            )}
            <StyledButton
              type="primary"
              onClick={() => navigate("/management/goals-and-progress/deleted")}
            >
              <DeleteOutlined /> <span>View Deleted Goals</span>
            </StyledButton>
          </>
        )}
      </Row>
    </>
  );
};
