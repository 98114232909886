import React, { useEffect, useState } from "react";
import { useSetState } from "ahooks";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import { getScopeNumber } from "../../../../Carbon/CalculationUtils";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import styles from "../AddTargetModal/AddTargetModal.module.scss";

interface AddTargetModalProps {
  isShown: boolean;
  isCustom: boolean;
  target: TargetPayload | Target;
  onClose: () => void;
  onConfirm: (data: TargetPayload | Target) => void;
}

const SDAscenarios = ["SBTi 1.5", "SBTi 2", "SBTi 2.5", "SBTi 3"];
const customSDAscenarios = ["None", "Net-Zero"];

export const EditTargetModal = ({
  isShown,
  isCustom,
  target,
  onClose,
  onConfirm,
}: AddTargetModalProps) => {
  const [data, setData] = useSetState<TargetPayload | Target>({
    ...target,
    scope: getScopeNumber(target.scope) as TargetScope,
  });
  const [reductionAmountType, setReductionAmountType] = useState("percent");

  useEffect(() => {
    setData({
      goal: getPercentageValue(target.goal),
    });
  }, []);

  useEffect(() => {
    setData({
      currentConsumptionAmount: target.currentConsumptionAmount,
    });
  }, [target.currentConsumptionAmount]);

  const getPercentageValue = (value: number) => {
    if (value && data.baseYearConsumptionAmount) {
      return (
        (value - data.baseYearConsumptionAmount) /
        -(data.baseYearConsumptionAmount / 100)
      );
    } else return 0;
  };

  const handleChangeData = (value: string | number, dataType: keyof Target) => {
    setData((prevData) => ({
      ...prevData,
      [dataType]: value,
    }));
  };

  const handleConfirm = () => {
    let goal = data.baseYearConsumptionAmount - data.goal;
    if (reductionAmountType === "percent") {
      goal =
        data.baseYearConsumptionAmount -
        (data.baseYearConsumptionAmount / 100) * data.goal;
    }
    onConfirm({ ...data, goal });
  };

  const handleChangeGoalType = (value: string) => {
    setReductionAmountType(value);
  };

  const selectBefore = (
    <Select
      defaultValue="percent"
      value={reductionAmountType}
      className="select-before"
      onChange={handleChangeGoalType}
    >
      <Select.Option value="percent">%</Select.Option>
      <Select.Option value="number">Number</Select.Option>
    </Select>
  );

  return (
    <CommonModal
      centered
      title={isCustom ? "Edit Custom Target" : "Edit science based target"}
      visible={isShown}
      onOk={handleConfirm}
      onCancel={onClose}
      footer={[
        <Button type="text" onClick={onClose} key="cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          key="confirm"
          onClick={handleConfirm}
          disabled={data.emissionName === "" || !data.scenario}
        >
          Edit target
        </Button>,
      ]}
      width="50%"
    >
      <FormWrapper>
        <Row className={styles.subTitle}>General information</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={6}>
            <div className={styles.label}>SDA scenario</div>
            <Select
              showSearch
              className={styles.select}
              value={data.scenario}
              onChange={(value) => handleChangeData(value, "scenario")}
            >
              {(isCustom
                ? [...customSDAscenarios, ...SDAscenarios]
                : SDAscenarios
              ).map((v) => {
                return (
                  <Select.Option key={v} value={v}>
                    {v}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>

          <Col span={6}>
            <div className={styles.label}>Scope</div>
            <FormWrapper>
              <Radio.Group
                value={data.scope}
                onChange={(e) => handleChangeData(e.target.value, "scope")}
                className={styles.scopeSelect}
              >
                <Radio value="1">1</Radio>
                <Radio value="2">2</Radio>
                <Radio value="3">3</Radio>
              </Radio.Group>
            </FormWrapper>
          </Col>
          <Col span={11}>
            <div className={styles.label}>Emission name</div>
            <Input
              className={styles.input}
              placeholder="Enter emission"
              value={data.emissionName}
              onChange={(e) => handleChangeData(e.target.value, "emissionName")}
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Base year</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Base year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              disabledDate={(current) => {
                return current.isAfter(moment(data.year));
              }}
              value={moment(data.baseYear)}
              onChange={(value) =>
                handleChangeData(
                  value?.format("YYYY").toLocaleString() || "",
                  "baseYear"
                )
              }
            />
          </Col>
          <Col span={11}>
            <div className={styles.label}>Consumption amount</div>
            <InputNumber
              className={styles.inputNumber}
              value={data.baseYearConsumptionAmount}
              max={1000000}
              min={0}
              onChange={(value) =>
                handleChangeData(value || 0, "baseYearConsumptionAmount")
              }
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Current data</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              value={moment(data.year)}
              onChange={(value) =>
                handleChangeData(
                  value?.format("YYYY").toLocaleString() || "",
                  "year"
                )
              }
            />
          </Col>
          <Col span={11}>
            <div className={styles.label}>Consumption amount</div>
            <InputNumber
              className={styles.inputNumber}
              value={data.currentConsumptionAmount}
              max={1000000}
              min={0}
              onChange={(value) =>
                handleChangeData(value || 0, "currentConsumptionAmount")
              }
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Target year</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Target year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              disabledDate={(current) => {
                return current.isBefore(moment(data.year));
              }}
              value={moment(data.targetYear)}
              onChange={(value) =>
                handleChangeData(
                  value?.format("YYYY").toLocaleString() || "",
                  "targetYear"
                )
              }
            />
          </Col>
          <Col span={11}>
            <div className={styles.label}>Reduction amount (%)</div>
            <InputNumber
              className={styles.inputReductionAmount}
              addonBefore={selectBefore}
              placeholder={
                reductionAmountType === "percent"
                  ? "Enter percentage"
                  : "Enter number"
              }
              value={data.goal}
              max={reductionAmountType === "percent" ? 100 : 1000000}
              min={0}
              onChange={(value) => handleChangeData(value || 0, "goal")}
            />
          </Col>
        </Row>
      </FormWrapper>
    </CommonModal>
  );
};
