import React from "react";
import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
  Tooltip as Tooltips,
} from "devextreme-react/pie-chart";

export const MyResponsivePie = ({ data /* see data tab */ }) => {
  const customizePoint = (pointInfo) => {    
    return pointInfo.value === pointInfo.data.value
      ? { color: pointInfo.data.color }
      : {};
  };
  function customizeTooltip(e) {    
    const selectedData = data?.find((item) => item.name === e.argument)
    return {
      text: selectedData ? selectedData.tooltip : `${e.argumentText}  ${e.valueText} `,
    };
  }
  return (
    <>
      <div className="chart-inner-box">
        <PieChart
          id="pie"
          dataSource={data}
          palette="Dark Violet"
          customizePoint={customizePoint}
        >
          <Legend verticalAlignment="bottom" horizontalAlignment="left" />
          <Tooltips enabled={true} customizeTooltip={customizeTooltip} />

          <Series argumentField="name" valueField="value" tooltipField="tooltip">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
            
          </Series>
        </PieChart>
      </div>
    </>
  );
};
