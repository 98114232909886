import React from "react";
import { useBoolean } from "ahooks";
import { observer } from "mobx-react-lite";

import { Button, Dropdown } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { InputReasonsModal } from "../../CalculationsAudit/Components/InputReasonsModal";
import EmissionInfoButton from "../EmissionInfoButton";

import { userInfoStore } from "../../../Carbon/UserInfoStore";
import { OffsetDrawer } from "../../Calculator/Components/OffsetDrawer/OffsetDrawer";

import { EmissionAuditHistory } from "../EmissionAuditHistory";
import styles from "./CalculatorActions.module.scss";

interface CalculatorActionsProps {
  data: Emission | Offset;
  onConfirm: (reason: string, audit_state: AuditState) => void;
  isOffset?: boolean;
}

export const CalculatorActions = observer(function CalculatorActions({
  data,
  onConfirm,
  isOffset,
}: CalculatorActionsProps) {
  const [
    isApproveModalVisible,
    {
      setTrue: setApproveModalTrue,
      setFalse: setApproveModalFalse,
      set: setApprove,
    },
  ] = useBoolean(false);

  const [
    isDeclineModalVisible,
    {
      setTrue: setDeclineModalTrue,
      setFalse: setDeclineModalFalse,
      set: setDecline,
    },
  ] = useBoolean(false);

  const [
    drawerVisible,
    { setTrue: setDrawerVisible, setFalse: setDrawerHidden },
  ] = useBoolean(false);

  const [isHistoryVisible, { setTrue: showHistory, setFalse: hideHistory }] =
    useBoolean(false);

  return (
    <div className={styles.allBtns}>
      {isOffset ? (
        <>
          <Button className={styles.buttonTable} onClick={setDrawerVisible}>
            <EyeOutlined />
          </Button>
          <OffsetDrawer
            data={data as Offset}
            visible={drawerVisible}
            onClose={setDrawerHidden}
          />
        </>
      ) : (
        <EmissionInfoButton data={data as Emission} />
      )}
      <Button
        type="primary"
        ghost
        className={styles.buttonTable}
        onClick={showHistory}
      >
        <FileSearchOutlined />
      </Button>
      <EmissionAuditHistory
        data={data}
        visible={isHistoryVisible}
        onClose={hideHistory}
      />
      <div>
        <Dropdown
          overlay={
            <InputReasonsModal
              onConfirm={(reason) => onConfirm(reason, AuditState.APPROVED)}
              onClose={setApproveModalFalse}
            />
          }
          trigger={["click"]}
          placement="bottomRight"
          disabled={
            data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.APPROVED
          }
          onVisibleChange={(open) => setApprove(open)}
          visible={isApproveModalVisible}
        >
          <Button
            type="primary"
            ghost
            className={styles.buttonTable}
            onClick={setApproveModalTrue}
          >
            <CheckOutlined />
          </Button>
        </Dropdown>
      </div>
      <div>
        <Dropdown
          overlay={
            <InputReasonsModal
              onConfirm={(reason) => onConfirm(reason, AuditState.DECLINED)}
              onClose={setDeclineModalFalse}
              isRequired
            />
          }
          trigger={["click"]}
          placement="bottomRight"
          disabled={
            data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.DECLINED
          }
          onVisibleChange={(open) => setDecline(open)}
          visible={isDeclineModalVisible}
        >
          <Button
            type="primary"
            ghost
            className={styles.buttonTable}
            onClick={setDeclineModalTrue}
          >
            <CloseOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
});
