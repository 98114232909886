import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { 
  Row,
  Col,
  Tooltip,
  Typography,  
  Spin,  
  Alert
} from "antd";
import { ReportingContext } from "../ReportingProvider";
import {
  TabContentWrap,
  StyledProgress,
  StyledButton  
} from "../../../shared/commonStyles";
const { Title } = Typography;
import { MyResponsiveBar } from "./Components/BarChart";
import { MyResponsivePie } from "./Components/CustomPieChart";
const ProgressTracker = ({ dataSource }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      > 
        {Object.keys(dataSource?.results_by_category).map((key, index) => {
          return (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                width: 110,
                justifyContent: "center",
                alignItems: "center",
                margin: 20,
              }}
              key={index}
            >
              <Link to={`/reporting/sustainability-standards?category=${key}`}>
                <Tooltip title={`${key} ${dataSource?.results_by_category[key]}%`}>
                  <StyledProgress
                    tickcolor="#6affdb"
                    width={90}
                    percent={dataSource?.results_by_category[key]}
                    type="circle"
                    trailColor="#403655"
                    strokeColor={{
                      "0%": "#6AFFDB",
                      "100%": "#6AFFDB",
                    }}
                    strokeWidth={10}
                  />
                </Tooltip>
                <Title
                  level={5}
                  style={{
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  {key}
                </Title>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const StandardProgressPage = () => {
  const navigate = useNavigate();
  const { loading, reportingStatus } = useContext(ReportingContext);
  const [standardsGraphData, setStandardsGraphData] = useState([]);
  const [standardsPublishData, setStandardsPublishData] = useState([]);
  const [standardsStatusData, setStandardsStatusData] = useState([]);
  const [standardsSignoffData, setStandardsSignoffData] = useState([]);  

  const generateStandardData = (data) => {
    try {
      if(data){
        const newStandards = Object.keys(data).map((key) => {
          
          return {
            name: key,
            color: parseInt(data[key]) === 100 ? "#7F5FEE" : "#A68DFB",
            count: Object.keys(data).length,
            total: 100,
            parent: data[key],
            value: data[key],
          };
        });
        setStandardsGraphData(newStandards);
      }
    } catch (error) {
      console.log(
        "Something went wrong while generate data for standards bar chart!",
        error
      );
      return [];
    }
  };

  const statusData = [
    {
      published_count: "Published",
      color: "#F8C078",
      tooltipData: 'Data for the standard is ready to be included in the report.',
    },
    {
      not_published_count: "Not Published",
      color: "#C89A5E",
      tooltipData: 'Data for the standard is not ready to be included in the report.',
    },
    {
      signed_off: "Signed off",
      color: "#9A6273",
      tooltipData: 'Data collection has been completed for the standard and the data has been signed off by all assigned auditors.',
    },
    {
      in_progress: "In progress",
      color: "#F8B7CF",
      tooltipData: 'Data collection has been completed for the standard and the data has been sent to the assigned auditors.',
    },
    {
      closed_count: "Closed",
      color: "#303779",
      tooltipData: 'Data collection has been completed for the standard.',
    },
    {
      open_count: "Open",
      color: "#5078E1",
      tooltipData: 'Data collection has not been started yet for the standard. All standards are set to “Open” by default',
    },
    {
      pending_count: "Pending",
      color: "#4967B8",
      tooltipData: 'Data collection is in progress for the standard.',
    },
    {
      escalated_count: "Escalated",
      color: "#7095F4",
      tooltipData: 'Data collection is on high-priority for the standard.',
    },
  ];

  const standardsPublish = (data, type) => {
    try {
      if(data){      
        let total = 0
        Object.keys(data).forEach(item => {        
          total = total + data[item]
        })
        const newPublish = Object.keys(data).reduce((acc, key) => {                
          const value = data[key];
          const findedData = statusData.find((i) => i[key]);
          if(findedData){
            const dup = acc.find((d) => d?.name === findedData[key]);
            
            if (dup) {
              dup.value += value;
              return acc;
            } else {
              return acc.concat({
                name: `<span style="font-size:16px; color:white">${findedData[key]}     ${value}/${total}</span>`,
                color: findedData.color,
                value: value,
                tooltip: findedData.tooltipData,
              });
            }
          }
        }, []);

        if (type === "results_by_publish_status") {
          setStandardsPublishData(newPublish);
        } else if (type === "results_by_status") {
          setStandardsStatusData(newPublish);
        } else if (type === "results_by_signoff_status") {
          setStandardsSignoffData(newPublish);
        } else console.log("first");
      }
    } catch (error) {
      console.log(
        "Something went wrong while generate data for standards pie chart!",
        error
      );
      return [];
    }
  };

  useEffect(() => {        
    if(Object.keys(reportingStatus).length > 0){
      generateStandardData(reportingStatus?.results_by_standard);
      standardsPublish(
        reportingStatus?.results_by_publish_status,
        "results_by_publish_status"
      );
      standardsPublish(reportingStatus?.results_by_status, "results_by_status");
      standardsPublish(
        reportingStatus?.results_by_signoff_status,
        "results_by_signoff_status"
      );
    }
  }, [reportingStatus]);
  
  return (
    <TabContentWrap style={{marginTop:"15px"}}>
       {/* <OverviewContentWrap style={{ background: "transparent" }}> */}
        <Spin spinning={loading}>
          {Object.keys(reportingStatus).length > 0 ? (
            <>
              <div style={{ background: "#2D273F", padding: 20 }}>
                <Row>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <p className="color-white">Level of completeness: </p>
                      <span>
                        <Title
                          level={5}
                          className="color-white"
                          style={{ paddingLeft: 10 }}
                        >
                          {reportingStatus.group_position === 1 && (
                              `Initial`
                          )}
                          {reportingStatus.group_position === 2 && (
                              `Intermediate`
                          )}
                          {reportingStatus.group_position === 3 && (
                              `Nearly Completed`
                          )}
                        </Title>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <h3 className="color-white">Tip:</h3>
                      <span>
                        <p className="color-white" style={{ paddingLeft: 10 }}>
                          Enter and review the data, configure automated data
                          pipelines, and more.
                        </p>
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {reportingStatus.group_position === 2 && (
                        <StyledButton
                          type="default"
                          onClick={() =>
                            navigate("/management/collect-data")
                          }
                        >
                          <span>Go to Surveys</span>
                        </StyledButton>
                      )}
                      {reportingStatus.group_position === 3 && (
                        <StyledButton
                          type="default"                          
                          onClick={() =>
                            navigate("/reporting/sustainability-standards")
                          }
                        >
                          <span>Generate Report</span>
                        </StyledButton>
                      )}
                    </div>
                    
                  </Col>
                </Row>
                <Row>
                  <StyledProgress
                    strokeColor={{
                      "0%": "#BB8CEA",
                      "99.9%": "#BB8CEA",
                    }}
                    percent={reportingStatus.group_position === 3 ? 100 : (reportingStatus.group_position === 2 ? 67  : 33.33)}
                    showInfo={false}
                  />
                </Row>
              </div>
              <Title
                level={5}
                style={{
                  color: "white",
                  fontWeight: "500",
                  marginTop: 20,
                }}
              >
                Category
              </Title>
              <Row justify="space-around" align="bottom">
                <Col span={20}>
                  <ProgressTracker dataSource={reportingStatus} />
                </Col>
              </Row>
              <Title
                level={5}
                style={{
                  color: "white",
                  fontWeight: "500",
                }}
              >
                Standard type
              </Title>
              {standardsGraphData?.length ? (
                <Row justify="space-around" align="bottom">
                  <Col span={24}>
                    <MyResponsiveBar data={standardsGraphData} />
                  </Col>
                </Row>
              ) : null}

              <Title
                level={5}
                style={{
                  color: "white",
                  fontWeight: "500",
                  marginTop: 20,
                }}
              >
                Status
              </Title>
              <Row>
                <Col span={8}>
                  <MyResponsivePie data={standardsStatusData} />
                </Col>
                <Col span={8}>
                  <MyResponsivePie data={standardsPublishData} />
                </Col>
                <Col span={8}>
                  <MyResponsivePie data={standardsSignoffData} />
                </Col>
              </Row>
            </>          
          )
          :
          <Alert
            message="Please wait... loading progress dashboard"
            type="info"
            showIcon
          />
        }
        </Spin>
    </TabContentWrap>
  );
};
