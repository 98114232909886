import React, { useState, useEffect, useRef } from "react";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Spin,
  message,
  Collapse,
  Typography,
  Form,
  Input,
} from "antd";
import {
  PageWrap,
  StyledButton,
  StyledCollapse,
  FormWrapper,
  StyledTag,
} from "../../shared/commonStyles";
import ContentEditable from "react-contenteditable";
import {
  getAiResult,
  getAiRecommendations,
  addAiRecommendations,
  removeAiRecommendations,
} from "../../services/ai";
import { getCompanyInfo } from "../../services/companyInfo";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const { Title } = Typography;
const ImprovementRecommendations = () => {
  const scrollRef = useRef();
  const [form] = Form.useForm();
  const [companyLoader, setCompanyLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [questionLoader, setQuestionLoader] = useState(false);
  const [aiQuestion, setAiQuestion] = useState(
    "Enter your question or request"
  );
  const [ansLoader, setAnsLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [aiText, setAiText] = useState("The answer will be shown here");

  const [txt, setTxt] = useState(null);
  const [index, setIndex] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState(0);
  const [requestArray, setRequestArray] = useState([
    "Formulate ESG Strategy for",
    "Improvement of the environmental footprint for",
    "5 material ESG topics for",
  ]);

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    industry: "",
  });
  const speed = 10;
  const defaultRequestArray = [
    "Formulate ESG Strategy for",
    "Improvement of the environmental footprint for",
    "5 material ESG topics for",
  ];
  const [rowData, setRowData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const loadCompanyInfo = async () => {
    try {
      setCompanyLoader(true);

      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";
        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    } finally {
      setCompanyLoader(false);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const fetchResults = async () => {
    try {
      setLoader(true);
      const payload = { filter: { type: "score_improvement" } };
      const response = await getAiRecommendations(payload);
      const newDataSource = response.reverse();
      setDataSource(newDataSource);
    } catch (e) {
      message.error(`Some problem occured ${e}`);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [submitRefresh]);

  useEffect(() => {
    try {
      if (companyDetails) {
        const initData = {
          company_name: companyDetails.business_unit_name,
          industry: companyDetails.industry,
        };
        setInitialValues(initData);
        form.setFieldsValue(initData);
      }
    } catch (error) {
      console.log("Error while set form values!");
      message.error("Something went wrong!");
    }
  }, [companyDetails]);

  useEffect(() => {
    const newArray = [];
    defaultRequestArray.map((item, index) => {
      let name = "";
      if (index > 0) {
        name = item.concat(" " + initialValues?.industry);
      } else {
        name = item.concat(
          " " + initialValues?.company_name + " " + initialValues?.industry
        );
      }
      newArray.push(name);
    });
    setRequestArray(newArray);
  }, [initialValues]);

  const typeWriter = () => {
    if (index <= txt.length) {
      if (index === 1) {
        setAiText((prevState) => prevState.concat("<mark>"));
      }
      setAiText((prevState) => prevState.concat(txt.charAt(index - 1)));
      scrollRef?.current.scrollTo(0, scrollRef?.current.scrollHeight);
      if (index === txt.length) {
        setAiText((prevState) => prevState.concat("</mark>"));
        setIndex("");
        setTxt(null);
        setAnsLoader(false);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const getAiData = async (question) => {
    const results = await getAiResult(question);
    //console.log('results', results)
    return results;
  };

  const submitData = async (que) => {
    try {
      setAiQuestion(que);
      setQuestionLoader(true);
      if (que) {
        setTxt("");
        const response = await getAiData(que);
        if (response?.choices.length > 0) {
          await Promise.all(
            await response?.choices?.map(async (item) => {
              const newText = item?.text
                ?.replace(/^\n+|\n+$/g, "")
                .replace(/(?:\r\n|\r|\n)/g, "<br>");
              setTxt(newText);
            })
          );
        }
      }
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setQuestionLoader(false);
    }
  };

  useEffect(() => {
    if (index) {
      setTimeout(typeWriter, speed);
    }
  }, [index]);

  useEffect(() => {
    if (txt) {
      setAnsLoader(true);
      setAiText("");
      setIndex(1);
    }
  }, [txt]);
  const onDelete = (record) => {
    setRowData(record);
    setShowConfirmModal(true);
  };
  const onConfirmDelete = async () => {
    if (!rowData) return;
    try {
      setLoader(true);
      const payload = {
        _id: rowData?._id,
      };
      await removeAiRecommendations(payload);
      setSubmitRefresh(submitRefresh + 1);
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setLoader(false);
      setShowConfirmModal(false);
    }
  };
  const submitAiData = async () => {
    form.validateFields().then(async (values) => {
      try {
        setSubmitLoader(true);
        if (aiText && aiText !== "The answer will be shown here") {
          const payload = {
            type: "score_improvement",
            answer: aiText?.replace("<mark>", "")?.replace("</mark>", ""),
            question: aiQuestion,
            company_name: values.company_name,
            industry: values.industry,
          };
          await addAiRecommendations(payload);
          setSubmitRefresh(submitRefresh + 1);
          setAiText("The answer will be shown here");
          setAiQuestion("");
        }
      } catch (e) {
        message.error(`Some problem occured while fetching data ${e}`);
      } finally {
        setSubmitLoader(false);
      }
    });
  };

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  return (
    <PageWrap>
      <Spin spinning={loader || companyLoader || questionLoader}>
        <FormWrapper>
          <Form
            form={form}
            initialValues={initialValues}
            onFieldsChange={(data) => onChangeFieldsData(data)}
          >
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Form.Item
                  name="company_name"
                  label="Company Name"
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please input company name!" },
                  ]}
                >
                  <Input placeholder={""} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="industry"
                  label="Industry"
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please input industry!" },
                  ]}
                >
                  <Input placeholder={""} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={4} className="color-white">
                  Select your request
                </Title>
              </Col>
              <Col span={24}>
                {requestArray.map((item, index) => {
                  return (
                    <StyledTag
                      key={index}
                      bgcolor="#443A59"
                      style={{
                        padding: "15px",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                      onClick={() => submitData(item)}
                    >
                      {item}
                    </StyledTag>
                  );
                })}
              </Col>
              <Col span={24}>
                <Title level={4} className="color-white">
                  Recommendations
                </Title>
              </Col>
              <Col span={24}>
                <ContentEditable
                  html={aiText} // innerHTML of the editable div
                  disabled={true} // use true to disable editing
                  style={{
                    background: "#3C3453",
                    color: "#fff",
                    fontSize: "14px",
                    height: "150px",
                    padding: "4px 11px",
                    overflowX: "auto",
                    width: "100%",
                  }}
                  innerRef={scrollRef}
                />
              </Col>
              <Col span={24}>
                <StyledButton
                  onClick={submitAiData}
                  loading={
                    aiText === "" || questionLoader || ansLoader
                      ? true
                      : submitLoader
                  }
                  disabled={
                    aiText === "" ||
                    aiText === "The answer will be shown here" ||
                    questionLoader ||
                    ansLoader
                  }
                >
                  {aiText === "" || questionLoader || ansLoader
                    ? "Waiting"
                    : "Save"}
                </StyledButton>
              </Col>
              {dataSource?.length > 0 && (
                <Col span={24}>
                  <Title level={3} className="color-white">
                    Saved Result
                  </Title>
                  <StyledCollapse
                    ghost
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusCircleOutlined
                          style={{ fontSize: "20px", color: "#A68DFB" }}
                        />
                      ) : (
                        <PlusCircleOutlined style={{ fontSize: "20px" }} />
                      )
                    }
                    collapsible="header"
                    defaultActiveKey={0}
                  >
                    {dataSource.map((item, index) => {
                      return (
                        <Collapse.Panel
                          header={<span>{item?.question}</span>}
                          extra={
                            <>
                              <Row
                                gutter={[8]}
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Col style={{ marginTop: "-5px" }}>
                                  <StyledButton
                                    type="default"
                                    style={{ padding: "8px" }}
                                    onClick={() => onDelete(item)}
                                    icon={<DeleteOutlined />}
                                  />
                                </Col>
                              </Row>
                            </>
                          }
                          className="development"
                          key={index}
                        >
                          <div className="color-white">
                            <ContentEditable
                              html={item?.answer} // innerHTML of the editable div
                              disabled={true} // use true to disable editing
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                              }}
                            />
                          </div>
                        </Collapse.Panel>
                      );
                    })}
                  </StyledCollapse>
                </Col>
              )}
            </Row>
          </Form>
        </FormWrapper>
      </Spin>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          visible={showConfirmModal}
          okText="Delete"
          onConfirm={onConfirmDelete}
          onClose={() => setShowConfirmModal(false)}
          description="Are you sure you want to delete saved result?"
          ConfirmLoader={loader}
        />
      )}
    </PageWrap>
  );
};

export default ImprovementRecommendations;
