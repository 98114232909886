import { GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { datalake } from "../../../graphql/queries";
import { DatalakeQuery } from "../../../graphql/API";
import moment from "moment";

class ReductionPlanStore {
  newTargets: TargetPayload[];
  targets: Target[];
  currentTarget: Target;
  loadingTargets: boolean;

  constructor() {
    this.newTargets = [];
    this.targets = [];
    this.currentTarget = {} as Target;
    this.loadingTargets = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get onboardingScienceBasedTargets() {
    return this.newTargets.filter(
      (target) => target.type === TargetType.SCIENCE_BASED
    );
  }

  get onboardingCustomTargets() {
    return this.newTargets.filter(
      (target) => target.type === TargetType.CUSTOM
    );
  }

  saveTargetsList = async (data: TargetPayload[]) => {
    this.changeLoadingState(true);
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "bulk_add_reduction_targets",
          request_payload: JSON.stringify(data),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then(() => this.getAllTargets());
  };

  saveTarget = (data: TargetPayload) => {
    return API.graphql(
      graphqlOperation(datalake, {
        request_type: "add_reduction_target",
        request_payload: JSON.stringify(data),
      })
    ) as Promise<GraphQLResult<DatalakeQuery>>;
  };

  addNewTarget(target: TargetPayload) {
    this.newTargets = [...this.newTargets, target];
  }

  editOnboardingTarget(target: TargetPayload) {
    const updatedNewTarget = this.newTargets;
    updatedNewTarget[this.newTargets.findIndex((el) => el.key === target.key)] =
      target;
    this.newTargets = updatedNewTarget;
  }
  editOnboardingConsumptionAmount(target: TargetPayload) {
    const updatedNewTarget = this.newTargets;
    updatedNewTarget[
      this.newTargets.findIndex((el) => el.key === target.key)
    ].currentConsumptionAmount = target.currentConsumptionAmount;
    this.newTargets = updatedNewTarget;
  }

  deleteOnboardingTarget(target: TargetPayload) {
    this.newTargets = this.newTargets.filter((el) => el.key !== target.key);
  }

  getTargetById = async (id: string) => {
    this.changeLoadingState(true);
    await (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_reduction_target_by_id",
          request_payload: JSON.stringify({ id }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    )
      .then((res) => {
        if (res.data?.datalake) {
          runInAction(() => {
            const target = JSON.parse(res.data?.datalake || "");
            this.currentTarget = target;
          });
        }
      })
      .finally(() => runInAction(() => this.changeLoadingState(false)));
  };

  getAllTargets = () => {
    this.changeLoadingState(true);
    return new Promise((resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(datalake, {
            request_type: "list_reduction_targets",
          })
        ) as Promise<GraphQLResult<DatalakeQuery>>
      )
        .then((targets) => {
          runInAction(() => {
            this.targets = JSON.parse(targets.data?.datalake || "[]");
            resolve(this.targets);
          });
        })
        .catch((error) => {
          console.error("error", error);
          reject(error);
        })
        .finally(() => {
          runInAction(() => this.changeLoadingState(false));
        });
    });
  };

  deleteTarget = async (id: string) => {
    this.changeLoadingState(true);
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "remove_reduction_target",
          request_payload: JSON.stringify({ _id: { $oid: id } }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then(() => this.getAllTargets());
  };

  updateTarget = async (data: Target) => {
    (await API.graphql(
      graphqlOperation(datalake, {
        request_type: "update_reduction_target",
        request_payload: JSON.stringify(data),
      })
    )) as Promise<GraphQLResult<DatalakeQuery>>;
  };

  updateCurrentTargetData = async (
    id: string,
    changedData: ConsumptionEntry
  ) => {
    this.changeLoadingState(true);
    const target = this.currentTarget;

    if (!target) return;

    const dateFormat = "MM/DD/YYYY";

    const newEntries = target.actualConsumptionEntries?.map((entry) => {
      if (
        moment(entry.date).format(dateFormat) ===
        moment(changedData.date).format(dateFormat)
      ) {
        return {
          ...entry,
          consumptionAmount: changedData.consumptionAmount,
        };
      }
      return entry;
    });
    const isSameDateEntries = target.actualConsumptionEntries?.find(
      (entry) => entry.date.toString() == changedData.date.toString()
    );

    const isCurrentConsumptionAmount = target.actualConsumptionEntries?.find(
      (entry) => new Date(entry.date) > new Date(changedData.date)
    );

    const payload = {
      ...target,

      actualConsumptionEntries: [...(newEntries || [])],
    };
    if (!isSameDateEntries) {
      payload.actualConsumptionEntries = [
        ...(newEntries || []),
        changedData as ConsumptionEntry,
      ];
    }
    if (!isCurrentConsumptionAmount) {
      payload.year = (changedData as ConsumptionEntry).date;
      payload.currentConsumptionAmount = +(changedData as ConsumptionEntry)
        .consumptionAmount;
    }
    this.updateTarget(payload)
      .then(() => {
        this.getTargetById(id);
      })
      .finally(() => runInAction(() => this.changeLoadingState(false)));
  };

  addTargetPlan = async (plan: Plan) => {
    const payload = {
      ...this.currentTarget,
      plans: [...(this.currentTarget.plans || []), plan as Plan],
    };

    await this.updateTarget(payload);
    await this.getTargetById(this.currentTarget._id.$oid);
    this.changeLoadingState(false);
  };
  updateTargetPlan = (index: number, data: Plan) => {
    this.currentTarget.plans![index] = data;
    this.currentTarget.plans = [...(this.currentTarget.plans || [])];
    this.updateTarget(this.currentTarget);
  };

  deleteTargetPlan = (index: number) => {
    const plans = this.currentTarget.plans!.filter((_, i) => i !== index);
    this.currentTarget.plans = plans;
    this.updateTarget(this.currentTarget);
  };

  addTargetAction = (action: Action) => {
    const payload = {
      ...this.currentTarget,
      actions: [...(this.currentTarget.actions || []), action as Action],
    };

    this.updateTarget(payload)
      .then(() => {
        this.getTargetById(this.currentTarget._id.$oid);
      })
      .finally(() => runInAction(() => this.changeLoadingState(false)));
  };

  updateTargetAction = (index: number, data: Action) => {
    this.currentTarget.actions![index] = data;
    this.updateTarget(this.currentTarget);
  };

  deleteTargetAction = (index: number) => {
    const actions = this.currentTarget.actions!.filter((_, i) => i !== index);
    this.currentTarget.actions = actions;
    this.updateTarget(this.currentTarget);
  };

  changeLoadingState(isLoading: boolean) {
    this.loadingTargets = isLoading;
  }
}

const reductionPlanStore = new ReductionPlanStore();

export { reductionPlanStore };
