import React from "react";
import { CommonTable, PageWrap } from "../../../../../shared/commonStyles";
import { Spin } from "antd";
import { TableConversionsButtons } from "./TableConversionsButtons";

export const ManageUnitConversions = () => {
  //TODO: get conversions from db
  const data: UnitConversion[] = [
    {
      base_unit: "kg unit",
      base_symbol: "kg",
      target_unit: "mg unit",
      target_symbol: "mg",
      conversion_factor: 0.005,
    },
  ];
  const columns = [
    {
      title: "Unit 1",
      key: "base_unit",
      dataIndex: "base_unit",
      width: "20%",
    },
    {
      title: "Symbol",
      key: "base_symbol",
      dataIndex: "base_symbol",
      width: "10%",
    },
    {
      title: "Unit 2",
      key: "target_unit",
      dataIndex: "target_unit",
      width: "20%",
    },
    {
      title: "Symbol",
      key: "target_symbol",
      dataIndex: "target_symbol",
      width: "10%",
    },
    {
      title: "Conversion factor",
      key: "conversion_factor",
      dataIndex: "conversion_factor",
      width: "20%",
    },
    {
      title: "",
      key: "actions",
      dataIndex: "",
      align: "right",
      width: "20%",
      render: (data: UnitConversion) => <TableConversionsButtons data={data} />,
    },
  ];
  return (
    <PageWrap>
      <Spin spinning={false}>
        <CommonTable
          rowKey={(record: UnitConversion) =>
            `${record.base_symbol}_${record.target_symbol}`
          }
          columns={columns}
          dataSource={data}
          scroll={{ x: true }}
        />
      </Spin>
    </PageWrap>
  );
};
