import { evaluate } from "mathjs";
import { makeAutoObservable } from "mobx";

const listedCompanyRules = require("./rules/listedCompanyRules.json");
const fieldSpecs = require("./rules/fieldSpecs.json");

// TODO: replace rules
const rulesList = {
  [FinancedEmissionType.LISTED_COMPANY]: listedCompanyRules,
  [FinancedEmissionType.MORTGAGE]: listedCompanyRules,
  [FinancedEmissionType.MOTOR_VEHICLE]: listedCompanyRules,
  [FinancedEmissionType.PRIVATE_COMPANY]: listedCompanyRules,
  [FinancedEmissionType.PROJECT]: listedCompanyRules,
  [FinancedEmissionType.REAL_ESTATE]: listedCompanyRules
}

//TODO: add displayFields and rename fields into requiredFields in the rules

class FinancedEmission {
  type!: FinancedEmissionType;
  scoreIndex: number = 0;
  scoreRules!: ScoreRule[];
  fields: FinancedEmissionField[];

  constructor(type: FinancedEmissionType){
    this.type = type;
    this.scoreRules = rulesList[type];

    this.fields = this.getNeededFieldNames().map(field => ({
      fieldName: field,
      isKnown: true,
      knownOnly: !!this.scoreRules[this.scoreIndex].knownOnlyFields.find(element => element == field),
      value: ""
    }));
    makeAutoObservable(this)
  }

  private increaseScore(){
    this.scoreIndex++;
    const newFields = this.getNeededFieldNames()
      .filter(fieldName => !this.fields.find(element => element.fieldName == fieldName))
      .map(field => ({
        fieldName: field,
        isKnown: true,
        knownOnly: !!this.scoreRules[this.scoreIndex].knownOnlyFields.find(element => element == field),
        value: ""
      }));
    this.fields = [...this.fields, ...newFields];
  }

  private decreaseScore(score: number){
    const oldFieldNames = this.fields.map(field => field.fieldName);
    this.scoreIndex = score;

    const newFieldNames = this.getNeededFieldNames();
    //const fieldsToRemove = oldFieldNames.filter(fieldName => !newFieldNames.includes(fieldName));
    this.fields = this.fields.filter((field: FinancedEmissionField) => {
      if(newFieldNames.includes(field.fieldName)) return true;
      const lowestPossibleScore = this.scoreRules.findIndex(scoreObj => scoreObj.fields.includes(field.fieldName));
      return lowestPossibleScore < score;
    });
  }

  refreshScore(changedField: FinancedEmissionField){
    if(changedField.isKnown && this.scoreIndex > 0){
      const lowestPossibleScore = this.scoreRules.findIndex(scoreObj => scoreObj.fields.includes(changedField.fieldName));
      let isPrevScoreSufficient = true;
      this.scoreRules[lowestPossibleScore].fields.forEach(fieldName => {
        const field = this.fields.find(element => element.fieldName == fieldName);
        if(!field || !field.isKnown){
          isPrevScoreSufficient = false;
          return;
        }
      });
      if(isPrevScoreSufficient){
        this.decreaseScore(lowestPossibleScore);
      }
    }
    else if(!changedField.isKnown){
      let isSufficient = true;
      this.getNeededFieldNames().forEach(fieldName => {
        const field = this.fields.find(element => element.fieldName == fieldName);
        if(!field || !field.isKnown){
          isSufficient = false;
          return;
        }
      });
      if(!isSufficient){
        this.increaseScore();
      }
    }
  }

  setFieldValue(index: number, value: string | number){
    this.fields[index].value = value;
  }

  setFieldIsKnown(fieldName: FieldName, isKnown: boolean){
    let fieldIndex = this.fields.findIndex(element => element.fieldName == fieldName);
    if(fieldSpecs[fieldName]["parentField"]){
      this.fields[fieldIndex].isKnown = isKnown;
      fieldIndex--;
    }
    else if(fieldSpecs[fieldName]["childField"]){
      this.fields[fieldIndex].isKnown = isKnown;
      fieldIndex++;
    } 

    this.fields[fieldIndex].isKnown = isKnown;

    this.refreshScore(this.fields[fieldIndex]);
  }

  addField(fieldName: FieldName){
    this.fields.push({
      fieldName,
      isKnown: true,
      knownOnly: !!this.scoreRules[this.scoreIndex].knownOnlyFields.find(element => element == fieldName),
      value: ""
    });
    if(fieldSpecs[fieldName]["childField"]){
      const childFieldName = fieldSpecs[fieldName]["childField"];
      this.fields.push({
        fieldName: childFieldName,
        isKnown: true,
        knownOnly: !!this.scoreRules[this.scoreIndex].knownOnlyFields.find(element => element == childFieldName),
        value: ""
      });
    }
  }
  
  get currentFields(): FinancedEmissionField[] {
    return this.fields;
  }

    
  get currentScore(): string {
    return this.scoreRules[this.scoreIndex].score.split('-')[0];
  }

  getNeededFieldNames(): FieldName[] {
    return this.scoreRules[this.scoreIndex].fields;
  }

  
  get calculatedAmount(): number {
    // parse formula of the current score and calculate by fields list
    const formula = this.scoreRules[this.scoreIndex].formula;
    const fieldObjects = this.fields.map(field => ({[field.fieldName]: field.value}));
    let scope = Object.assign({}, ...fieldObjects);
    const result = evaluate(formula, scope)

    return result;
  }

}

export { FinancedEmission };
