import React, { useEffect, useMemo } from 'react';
import { Button, Form, Input } from 'antd';
import { sdgGoalsStaticList } from '../../../shared/constants';

export const Impact = ({ companySave, handleStep, company }) => {
  const [form] = Form.useForm();
  const goals = company?.goals || [];

  const handleSubmit = (values) => {
    const keys = Object.keys(values);

    const payloadGoals = keys.map((el) => {
      return {
        goal: el,
        description: values[el] || '',
      };
    });

    companySave({ goals: payloadGoals });
  };

  useEffect(() => {
    const obj = {};
    goals.forEach((el) => {
      obj[el.goal] = el.description || '';
    });
    form.setFieldsValue(obj);
  }, [goals]);

  const fields = useMemo(() => {
    if (!goals || goals.length < 1) return null;

    return goals.map((item,index) => {
      const fullItem = sdgGoalsStaticList.find((element) => element.goal == item.goal);

      return (
        <Form.Item
          label={
            <>
              {`${fullItem.goal} ${fullItem.title}`} <span>(340 characters remaining)</span>
            </>
          }
          name={fullItem.goal}
          key={index}
        >
          <Input.TextArea
            placeholder="Provide a short description about what the company does"
            maxLength={555}
          />
        </Form.Item>
      );
    });
  }, [goals]);

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      autoComplete="off"
      layout="vertical"
      onFinish={handleSubmit}
      className="report__impact-form"
      form={form}
    >
      {fields}
      <div className="report-form-controls">
        <Button type="link" htmlType="reset" onClick={() => handleStep(1)}>
          Back
        </Button>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </div>
    </Form>
  );
};
