import Styled from 'styled-components';
import { Modal } from 'antd';

export const NewReportWrap = Styled.div`
    padding:23px 33px 10px 33px;
    h1{
    font-size:32px;
    font-weight:600;
    margin-bottom:40px
    }
    .ant-steps{
    margin-bottom: 35px;
    }
    .steps-content{
        margin: 0 20px;
    }
    .sub-title{
        color:#595959;
        font-weight:500;
        margin-bottom:20px;
        font-size:13px;
    }
    .ant-form{
    .ant-row{
        justify-content:space-between;
        flex-wrap:wrap;
    }
    
    }
    .ant-form-item{
    display:block;
    }
    
    .ant-input,.ant-picker,.ant-select-selector{
    background: #F2F2F2;
    width:100%;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px !important;
    min-height:44px;
    }
    
    textarea.ant-input{
        min-height:75px;
    }
    .ant-select-selector{
        input{
            height:100% !important;   
        }
        .ant-select-selection-placeholder{
        line-height:44px;
        }
    }
    .ant-input-group-addon{
        width:unset !important;
        border-radius:8px 0px 0px 8px !important;
        background-color:#CFCFCF;
        height:100% !important;
        border:none !important;
    }
    .ant-input-group-wrapper .ant-input{
    border-radius:0px 8px 8px 0px !important;
    border:none !important;
    box-shadow:none;
    }
    
    .ant-form-item-label  label {
        color:#344054;
        font-weight: 500 !important;
        span{
            display:inline-block;
            font-size:12px;
            margin-left:10px;
            color:#595959;
        }
    }
    .ant-checkbox-wrapper{
        display:flex;
        align-items:center;
    }
    .ant-checkbox-group{
    width:100%;
    .ant-checkbox + span{
    flex-grow:1;
    }
    }
    .report-goals{
        .ant-row{
            justify-content:flex-start !important;
        }
    }
    .report-form-controls{
      display:flex;
      justify-content:center;
    }
   button[type="submit"]{
        background-color:#6D23CB !important;
        border-radius: 4px;
    }
      button[type="reset"]{
        color:#4F4F4F;
        font-weight:600;
        margin-right:10px;
    }
`;

export const GoalItem = Styled.div`
    display:flex;
    align-items:center;
    padding:8px 35px 8px 12px;
    background: #F2F2F2;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width:100%;
    flex-grow:1;
    max-height:55px;
    max-width:230px;
    position:relative;
    img{
        margin-right:8px;
        width:30px;
        height:30px;
        object-fit:cover;
    }
    span{
    font-size:12px;
    font-weight:500;
    color:#83827F;
    }
     .anticon-info-circle{
       position:absolute;
       right:16px;
       font-size:14px;
    }
 
`;

export const StyledReportCompanies = Styled.div`
    width:100%;
    padding:20px 65px 50px 120px;
    >.ant-row{
        margin-bottom:30px;
    }
    .ant-btn{
        border-radius:4px;        
    }
`;

export const ReportTemplateStyle = Styled.div`
    max-width:600px;
    margin:0 auto;
    position:relative;
`;
//

export const CardWrapper = Styled.div`
   padding:10px;
   background-color : #ffffff;
`;

export const CardDetails = Styled.div`
   padding:10px;
`;

export const TopCompanyInfoWrap = Styled.div`
  display : flex;
  justify-content: space-between;
  flex-wrap:wrap;
  margin-bottom:20px;
  align-items : center;
  
  h3{
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-bottom:0;
  }
`;

export const Paragraph = Styled.p`
  margin:0px;
`;

export const CompanyLogo = Styled.img`
 width : auto;
 height : auto;
 margin : 5px;
 max-width : 230px;
`;

export const CompanyInfoCard = Styled.div`
   margin : 15px;
   *{
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 130%;
}
`;

export const SdgCardWrap = Styled.div`
   margin-bottom : 30px;
   display : flex;
   img{
    width:63px;
    height:63px;
    margin-right:6px;
   }
    *{
        font-size:13px !important;
        font-weight: 300;
    }
   .title{
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom:0px;
   }

`;

export const ReportTemplateModal = Styled(Modal)`
        .report-template-header{
            background-color: #402B87;
            padding:10px 20px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding-right:70px;
            margin-bottom:6px;
            
            span{
                color:#fff;
                font-weight: 600;
                font-size: 20px;
            }
            button{
                background-color:transparent;
                height:41px;
                font-size:20px;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                margin-left:20px;
            }
        }
        .ant-modal-content{
         background-color:transparent;
         box-shadow:none;
         padding-top:30px;
         }
          width:100% !important;
          max-width:900px;
          .ant-modal-body{
            padding:0;
          }
          .ant-modal-close-x{
          margin-top:30px;
            svg{
                width:20px;
                height:20px;
                path{
                    fill:#fff;
                }
            }
          }
        .bottom_logo{
            position:absolute;
            bottom:11px;
            left: 6px;
        }
`;
