import React, { useEffect, useState } from "react";
import { notification, Tooltip } from "antd";
import { IconWrapper } from "../../shared/commonStyles";
import { LockOutlined } from "@ant-design/icons";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";

export const CustomIconCard = ({
  customCardStyle = {},
  title = "",
  iconComponent = "",
  onClick,
  section,
  titleStyle = {},
  isSelected = false,
  isTooltip = false,
  tooltipText = "",
  isLockModule = false,
}) => {
  const { checkLockModules } = useCheckRolesPermissions();
  const [allModules, setAllModules] = useState(null);
  const [isModuleLocked, setIsModuleLocked] = useState(false);
  
  const callCheckLockModules = async() => {
    const response = await checkLockModules()
    setAllModules(response)
  }
  useEffect(() => {    
    if(!localStorage.getItem("modules")){
      callCheckLockModules()
    }      
  },[]);
    
  useEffect(() => {    
    if(isLockModule){     
      let moduleArray = allModules      
      if(localStorage.getItem("modules") !== null){        
        moduleArray = localStorage.getItem("modules")?.split(",")
      }

      if(moduleArray){
        if(moduleArray?.includes(isLockModule) || moduleArray?.includes("ALL_MODULES")){
          setIsModuleLocked(false)
        }else{
          setIsModuleLocked(true)
        }
      }
    }
      
  },[isLockModule,allModules]);
  
  let cardStyle = {
    width: !iconComponent ? 180 : 248,
    height: !iconComponent ? 180 : 220,
    padding: 20,
    cursor: "pointer",
    // backgroundColor: "rgb(187 187 187 / 9%)",
    borderRadius : 10,
    background : isModuleLocked ? '' : "linear-gradient(#322B46, #2F2844)",
    textAlign: "center",
    ...customCardStyle,
  };

  cardStyle = isSelected
    ? { border: "4px solid white", ...cardStyle }
    : cardStyle;
  const titleIconWrapper = {
    width: "100%",
    height: !iconComponent ? "100%" : "",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity : isModuleLocked ? 0.6 : 1,
    marginTop: !iconComponent ? 0 : 29,
  };

  const showInformation = () => {
    //setGreyOut(true);
    notification["info"]({
      message: "Information",
      description: `Please contact your account manager or email sales@hydrus.ai to access this module.`,
    });
  };

  if (isTooltip) {
    return (
      <Tooltip title={tooltipText}>
        <div
          style={cardStyle}
          className={isModuleLocked ? "card-grey" : ""}
          onClick={() => isModuleLocked ? showInformation() : onClick(section) }
        >
          <div style={{ float: "right" }} className="lock-right-pan">
            {isModuleLocked &&
              <LockOutlined style={{ fontSize: "26px" }} />
            }
          </div>
          <div style={titleIconWrapper}>
            {iconComponent ? <IconWrapper>{iconComponent}</IconWrapper> : null}
            <p
              style={{
                fontSize: 20,
                color: "#000",
                fontFamily: "Roboto",
                ...titleStyle,
              }}
            >
              {title}
            </p>
          </div>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div
        style={cardStyle}
        className={isModuleLocked ? "card-grey" : ""}
        onClick={() => isModuleLocked ? showInformation() : onClick(section) }
      >
        <div style={{ float: "right" }} className="lock-right-pan">
          {isModuleLocked &&
            <LockOutlined style={{ fontSize: "26px" }} />
          }
        </div>
        <div style={titleIconWrapper}>
          {iconComponent ? <IconWrapper>{iconComponent}</IconWrapper> : null}
          <p
            style={{
              fontSize: 18,
              color: "#000",
              fontFamily: "Roboto",
              ...titleStyle,
            }}
          >
            {title}
          </p>
        </div>
      </div>
    );
  }
};
