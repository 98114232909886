import React, { useState } from "react";
import { LeftOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Row, Col, Typography, Divider, Tooltip } from "antd";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
  CommonTable,
} from "../../../shared/commonStyles";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { stripHtmlTags } from "../../../shared/commonFunctions";
import { siteThemeColors } from "../../../shared/colors";
import ShowSortedSurveyFormViewModal from "../components/ShowSortedSurveyFormViewModal";
import { CreateWelcomePageModal } from "./CreateWelcomePageModal";

const { Title } = Typography;
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SurveyOrder = ({
  onSubmitHandler,
  onClickPrevious,
  sortedDataSurveyList,
  setSortedDataSurveyList,
  loadingCreateSurvey,
  onSortEnd,
  action,
}) => {
  const [rowDataView, setRowDataView] = useState(
    sortedDataSurveyList.length ? sortedDataSurveyList[0] : null
  );
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [welcomePageModal, setWelcomePageModal] = useState(false);
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = sortedDataSurveyList.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "sheet_name",
      width: "25%",
      className: "drag-visible",
      render: (value, data) => {
        return value || data.subject;
      },
    },
    {
      title: "Sheet Type",
      dataIndex: "sheet_type",
      width: "15%",
      className: "drag-visible",
      render: (data, record) => {
        return record.type === "welcome_page" ? "Welcome Page" : data;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "50%",
      className: "drag-visible",
      render: (data, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "300px",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {stripHtmlTags(record?.description)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Order",
      dataIndex: "index",
      width: "5%",
      className: "drag-visible",
      render: (record, data) => {
        return record + 1;
      },
    },
  ];
  return (
    <>
      <FormWrapper>
        <ShowSortedSurveyFormViewModal
          rowDataView={rowDataView || sortedDataSurveyList[0]}
          showFormViewModal={showFormViewModal}
          setShowFormViewModal={setShowFormViewModal}
          sortedDataSurveyList={sortedDataSurveyList}
          setRowDataView={setRowDataView}
        />
        <CreateWelcomePageModal
          visible={welcomePageModal}
          onClose={() => setWelcomePageModal(false)}
          setSortedDataSurveyList={setSortedDataSurveyList}
        />
        <FormWrap width="90%">
          <>
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item>
                  <Title level={3}>Survey order</Title>

                  <Row>
                    <Col span={12}>
                      <Title level={4}>
                        Change order of the data sheets inside the survey if
                        needed
                      </Title>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      {" "}
                      <StyledButton
                        type="custom"
                        onClick={() => setWelcomePageModal(true)}
                      >
                        <PlusOutlined />
                        <span>Add welcome page</span>
                      </StyledButton>
                      <StyledButton
                        type="custom"
                        onClick={() => setShowFormViewModal(true)}
                      >
                        <span>Open preview</span>
                      </StyledButton>
                    </Col>
                    <Col span={24}>
                      <Divider
                        style={{
                          backgroundColor: "#3A3C5A",
                          marginTop: "15px",
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <CommonTable
                    pagination={false}
                    dataSource={sortedDataSurveyList}
                    columns={columns}
                    rowKey="index"
                    scroll={{ x: true }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="flex-start">
                    <StyledButton
                      className="margin-5"
                      onClick={() => onClickPrevious()}
                      type="custom"
                    >
                      <LeftOutlined /> <span>Previous</span>
                    </StyledButton>

                    <StyledButton
                      type="custom"
                      onClick={onSubmitHandler}
                      loading={loadingCreateSurvey}
                    >
                      {action === "update" ? "Update Survey" : "Create Survey"}
                    </StyledButton>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </>
        </FormWrap>
      </FormWrapper>
    </>
  );
};

export default SurveyOrder;
