
import { Card, Col, Row, Typography, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import {sendTaskReminder} from '../../../../services/goals';

const { Title } = Typography;

const MetricsData = ({category, refreshData}) => { 
  const [loader, setLoader] =  useState(false)
  const [metricData, setMetricData] = useState(null); 
  
  const loadMetricData = async() => {
    try{
      setLoader(true)
      const data = await sendTaskReminder('GENERAL_GOALS', category)
      setMetricData(data ? JSON.parse(data) : '')
    }catch(e){
      message.error(e)
    }finally{
      setLoader(false)
    }    
  }
  
  useEffect(() => {
    loadMetricData()
  },[category, refreshData])  

  return ( 
    <Spin spinning={loader}>      
      {metricData && 
        <Row gutter={[16, 16]}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card
              title={<Title level={4}>Total Goals</Title>}
              bordered={false}
              style={{
                backgroundColor: "#2d273f",
                borderLeft: "4px solid #77f4fc",
                padding: "20px",
                textAlign: "center",
              }}
              bodyStyle={{
                padding: "10px", 
              }}
            >
              {metricData?.total_general && 
                <Row gutter={[8, 8]}>
                  <Col span={12}>         
                    <Title level={5} className="color-grey">Subgoals</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.total_general?.short_term}</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={5} className="color-grey">Long-term</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.total_general?.long_term}</Title>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card
              title={<Title level={4}>In Progress</Title>}
              bordered={false}
              style={{
                backgroundColor: "#2d273f",
                borderLeft: "4px solid #fcc777",
                padding: "20px",
                textAlign: "center",
              }}
              bodyStyle={{
                padding: "10px", 
              }}
            >
              {metricData?.in_progress_general && 
                <Row gutter={[8, 8]}>
                  <Col span={12}>         
                    <Title level={5} className="color-grey">Subgoals</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.in_progress_general?.short_term}</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={5} className="color-grey">Long-term</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.in_progress_general?.long_term}</Title>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card
              title={<Title level={4}>Over Due</Title>}
              bordered={false}
              style={{
                backgroundColor: "#2d273f",
                borderLeft: "4px solid #7794fc",
                padding: "20px",
                textAlign: "center",
              }}
              bodyStyle={{
                padding: "10px", 
              }}
            >
              {metricData?.overdue_general && 
                <Row gutter={[8, 8]}>
                  <Col span={12}>         
                    <Title level={5} className="color-grey">Subgoals</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.overdue_general?.short_term}</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={5} className="color-grey">Long-term</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.overdue_general?.long_term}</Title>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card
              title={<Title level={4}>Completed</Title>}
              bordered={false}
              style={{
                backgroundColor: "#2d273f",
                borderLeft: "4px solid #77fca4",
                padding: "20px",
                textAlign: "center",
              }}
              bodyStyle={{
                padding: "10px", 
              }}
            >
              {metricData?.completed_general && 
                <Row gutter={[8, 8]}>
                  <Col span={12}>         
                    <Title level={5} className="color-grey">Subgoals</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.completed_general?.short_term}</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={5} className="color-grey">Long-term</Title>
                    <Title level={3} style={{marginTop:'5px'}}>{metricData?.completed_general?.long_term}</Title>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
          
        </Row>
      } 
    </Spin>
  )
  
}
export default MetricsData;
