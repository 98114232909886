import {
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  CommonModal,
  FormWrapper,
  IconWrap,
  StyledButton,
  StyledSteps,
} from "../../../shared/commonStyles";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";

import { Theme as AntDTheme } from "@rjsf/antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DataSheetMultiLineForm } from "./../DataSheetFormViewModal/components/DataSheetMultiLineForm";
import { DataSheetSingleForm } from "./../DataSheetFormViewModal/components/DataSheetSingleForm";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { getSheetById } from "./../../../services/dataSheet";
import { handleLongString } from "../../../shared/commonFunctions";
import { withTheme } from "@rjsf/core";

const { Title } = Typography;
const Form = withTheme(AntDTheme);
const { Step } = Steps;

const MixedSurveyFormViewModal = ({
  visible,
  rowData,
  onClose,
  isShowSteps = false,
  sortedSurveyList = [],
  setRowDataView = null,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dataSheet, setDataSheet] = useState();
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  const editorData = rowData?.response ?? "";
  const [formData] = useState(editorData);
  const schema = useMemo(() => rowData?.form_json_code, [rowData]);
  const uiSchema = useMemo(() => rowData?.form_ui_code, [rowData]);
  if (!rowData) return <></>;

  const loadDataSheet = async (dataSheetId) => {
    setLoadingDataSheet(true);
    try {
      const _dataSheet = await getSheetById(dataSheetId);
      if (_dataSheet) {
        setDataSheet(_dataSheet);
      }
    } catch (error) {
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };

  const checkVariable = (variable) => {
    if (typeof variable === "string") {
      return variable;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (rowData && rowData.type === "data_sheets") {
      loadDataSheet(rowData._id["$oid"]);
    }
  }, [rowData]);
  const onStepChange = (currentStep, state) => {
    const pretarget = document.getElementById(`step${currentStep - 1}`);
    const nexttarget = document.getElementById(`step${currentStep + 1}`);
    switch (state) {
      case "previous":
        if (currentStep === 0) {
          return;
        }
        pretarget && pretarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep - 1);
        setRowDataView(sortedSurveyList[currentStep - 1]);
        break;
      case "next":
        if (currentStep === sortedSurveyList?.length - 1) {
          return;
        }
        nexttarget && nexttarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep + 1);
        setRowDataView(sortedSurveyList[currentStep + 1]);
        break;
      default:
        break;
    }
  };
  return (
    <CommonModal
      title="Survey Preview"
      visible={visible}
      onCancel={onClose}
      width="60%"
      maskClosable={false}
      destroyOnClose
      footer={null}
    >
      {isShowSteps && (
        <Row style={{ padding: 1, backgroundColor: "#362E4B" }}>
          <Col span={16}>
            <div
              style={{ overflowX: "scroll", padding: 10 }}
              className="custom-scroll"
              id="scrollable-steps"
            >
              <StyledSteps
                onChange={(value) => {
                  setRowDataView(sortedSurveyList[value]);
                  setCurrentStep(value);
                }}
                current={currentStep}
              >
                {sortedSurveyList.map((data, index) => {
                  return (
                    <>
                      <Step
                        id={"step" + index}
                        className="stepper-wrapper"
                        key={index}
                        title={<span>Step {index + 1}</span>}
                      ></Step>
                    </>
                  );
                })}
              </StyledSteps>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "15px 10px",
              }}
            >
              <IconWrap
                disabled={true}
                onClick={() => onStepChange(currentStep, "previous")}
              >
                <LeftCircleOutlined
                  style={{
                    fontSize: 20,
                    color: currentStep == 0 ? "#716A88" : "white",
                    marginRight: 10,
                  }}
                />
                <span style={{ color: currentStep == 0 ? "#716A88" : "white" }}>
                  Back
                </span>
              </IconWrap>

              <IconWrap onClick={() => onStepChange(currentStep, "next")}>
                <RightCircleOutlined
                  style={{
                    fontSize: 20,
                    color:
                      currentStep === sortedSurveyList?.length - 1
                        ? "#716A88"
                        : "white",
                    marginRight: 10,
                  }}
                />
                <span
                  style={{
                    color:
                      currentStep === sortedSurveyList?.length - 1
                        ? "#716A88"
                        : "white",
                  }}
                >
                  Next
                </span>
              </IconWrap>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          span={16}
          style={{ padding: 10, backgroundColor: "#3C3453" }}
          className="preview-left-part"
        >
          <Title className="color-white" level={3}>
            Data <span className="color-grey">(View Only)</span>
          </Title>
          {rowData.type === "data_sheets" && (
            <Spin spinning={loadingDataSheet}>
              <Title className="color-white" level={4}>
                {dataSheet?.sheet_name}
              </Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "1px" }}
              />
              <FormWrapper>
                {rowData.multiline_form_flag ? (
                  <DataSheetMultiLineForm
                    dataSheet={dataSheet}
                    rowData={rowData}
                  />
                ) : (
                  <DataSheetSingleForm
                    dataSheet={dataSheet}
                    rowData={rowData}
                  />
                )}
              </FormWrapper>
            </Spin>
          )}
          {rowData.type === "standards" && (
            <>
              <Title className="color-white" level={3}>
                {rowData?.tags?.theme}
              </Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
              />
              <FormWrapper>
                {/* {Object.keys(rowData?.form_json_code).length > 0 ? (
                  <Form formData={{}} uiSchema={uiSchema} schema={schema}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <StyledButton
                        type="custom"
                        htmlType="submit"
                        style={{ display: "none" }}
                      >
                        Submit
                      </StyledButton>
                    </Col>
                  </Form>
                ) : ( */}
                <div className="ckSmallHeight ">
                  <CKEditor
                    editor={DecoupledDocumentEditor}
                    data={checkVariable(formData)}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      if (editor?.ui) {
                        editor.ui.view.editable.element.parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.view.editable.element
                        );
                      }
                    }}
                  />
                </div>
                {/* )} */}
              </FormWrapper>
            </>
          )}
          {rowData.type === "welcome_page" && (
            <>
              <Title className="color-white" level={4}>
                {rowData?.subject}
              </Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
              />
              <FormWrapper>
                <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rowData.body,
                    }}
                  ></div>
                </Row>
              </FormWrapper>
            </>
          )}
        </Col>
        <Col span={8} className="preview-right-part">
          <Title className="color-white " level={4}>
            Information
          </Title>
          {rowData.type === "data_sheets" && (
            <Space direction="vertical">
              <div
                dangerouslySetInnerHTML={{ __html: dataSheet?.instructions }}
              ></div>
            </Space>
          )}
          {rowData.type === "standards" && (
            <Space direction="vertical">
              <p className="color-white"> {rowData.description}</p>
              <div>
                {(rowData?.standardLink || []).map((val, index) => (
                  <Tooltip title={val.name} key={index}>
                    <Row>
                      <Col span={24}>
                        <a
                          style={{ margin: 5, color: "#7F5FEE" }}
                          className="padding-0"
                          href={val.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link to {`"${handleLongString(val.name, 10)}"`}
                          {val.page_num ? `, Page No: ${val.page_num}` : ""}
                        </a>
                      </Col>
                    </Row>
                  </Tooltip>
                ))}
              </div>
            </Space>
          )}
          <div style={{ position: "absolute", bottom: 0 }}>
            <StyledButton key="1" type="custom" onClick={() => onClose()}>
              Close Preview
            </StyledButton>
          </div>
        </Col>
      </Row>
    </CommonModal>
  );
};

export default MixedSurveyFormViewModal;
