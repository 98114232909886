import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";

/* get kpi metric */
export const getKpiMetric = async (payload) => {
  let response = null;
  if (payload) {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_kpi_metric",
        request_payload: JSON.stringify(payload),
      },
    });
  } else {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_kpi_metric",
      },
    });
  }
  return JSON.parse(response.data.datalake);
};

export const getKpiMetricById = async (kpiId) => {
  const kpi = await getKpiMetric({ filter: { _id: { $oid: kpiId } } });
  return kpi[0];
};

/* add kpi metric */
export const insertKpiMetric = async (payload) => {
  const data = await Auth.currentSession();

  const username = data["accessToken"]["payload"]["username"];
  payload.username = username;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_kpi_metric",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

/* update kpi metric */

export const updateKpiMetric = async (payload) => {
  const data = await Auth.currentSession();
  const username = data["accessToken"]["payload"]["username"];
  payload.username = username;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_kpi_metric",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

/* remove kpi metric */

export const removeKpiMetric = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_kpi_metric",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};
