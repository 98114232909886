import React, { useState, useEffect } from "react";
import moment from "moment";
import { Spin, Col, Button, Typography, Input } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import {
  downloadDataLakeFileHandler,
  downloadDataSheetImportFileHandler,
} from "../../../shared/commonFunctions";
import { YearMonthDayFormat } from "../../../shared/constants";
import {
  StyledButton,
  CommonModal,
  CommonTable,
  FormWrapper,
  StyledTag,
} from "../../../shared/commonStyles";
import { getDataLakeFiles } from "../../../services/standards";
import { getFilesById } from "../../../services/importData";
import { useNavigate } from "react-router";
import { getSheetById } from "../../../services/dataSheet";
import { encodeUrlName } from "../../../shared/commonFunctions";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
const { Link } = Typography;
interface KpiFilesModal {
  visible: boolean;
  onClose: () => void;
  onChangeFiles?: (files: KPIFile[]) => void;
  rowData: SurveyKPI;
  uploadEnabled?: boolean;
  isViewOnly?: boolean;
}
const KpiFilesModal = ({
  visible,
  onClose,
  onChangeFiles,
  rowData,
  uploadEnabled = true,
  isViewOnly = false,
}: KpiFilesModal) => {
  const [uploadedFilesList, setUploadedFilesList] = useState<any[]>([]);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const navigate = useNavigate();
  const { checkRolesPermission } = useCheckRolesPermissions();
  useEffect(() => {
    const loadDataLakeFiles = async (dataId: string) => {
      setLoaderUploadedFilesList(true);
      try {
        const uploadedFiles: any = [];

        if (rowData?.files) {
          for (const file of rowData?.files) {
            if (file?.isExcel) {
              const filesList = await getFilesById(
                file.fileId,
                "dataSheetImport",
                "DATASHEET_LIST"
              );

              if (filesList && filesList !== null) {
                const list = filesList
                  .replace(/['"]+/g, "")
                  .replace(/^\[(.+)\]$/, "$1")
                  .split(",");
                list?.forEach((v: any) =>
                  uploadedFiles.push({ ...file, url: v })
                );
              }
            } else {
              const filesList = await getDataLakeFiles(file.fileId);

              if (filesList && filesList !== null) {
                const list = filesList
                  .replace(/['"]+/g, "")
                  .replace(/^\[(.+)\]$/, "$1")
                  .split(",");
                list?.forEach((v: any) =>
                  uploadedFiles.push({ ...file, url: v })
                );
              } else {
                const filesList = await getFilesById(
                  file.fileId,
                  "dataSheetImport",
                  "DATASHEET_LIST"
                );

                if (filesList && filesList !== null) {
                  const list = filesList
                    .replace(/['"]+/g, "")
                    .replace(/^\[(.+)\]$/, "$1")
                    .split(",");
                  list?.forEach((v: any) =>
                    uploadedFiles.push({ ...file, url: v })
                  );
                }
              }
            }
          }
        }
        setUploadedFilesList(uploadedFiles);
      } catch (error) {
        console.log("Error while fetching data sheet files", error);
      } finally {
        setLoaderUploadedFilesList(false);
      }
    };
    const oid = rowData?.["_id"]?.["$oid"];
    if (rowData && oid) {
      loadDataLakeFiles(oid);
    }
  }, [rowData]);

  const handleUploadFile = async (
    currentStep?: number,
    kpiFile?: KPIFile,
    action?: string
  ) => {
    const dataSheet = await getSheetById(rowData?.mainKPISheetId);
    if (dataSheet) {
      if (currentStep) {
        navigate(
          `/data/import-data/${kpiFile?.importId}/${encodeUrlName(
            kpiFile?.importName || "Import Survey Kpi Data"
          )}/update`,
          {
            state: {
              dataSheet: dataSheet,
              type: "pendingKpi",
              surveyId: rowData.surveyId,
              taskDeploymentId: rowData?.taskDeploymentId,
              kpiData: rowData,
              currentStep,
              kpiFile,
              action,
              importId: kpiFile?.importId,
            },
          }
        );
      } else {
        navigate("/data/import-data/import-new-data", {
          state: {
            dataSheet: dataSheet,
            type: "pendingKpi",
            surveyId: rowData.surveyId,
            taskDeploymentId: rowData?.taskDeploymentId,
            kpiData: rowData,
          },
        });
      }
    }
  };

  const downloadFileHandler = async (key: string, file?: KPIFile) => {
    try {
      setLoaderUploadedFilesList(true);
      key.endsWith("xlsx")
        ? await downloadDataSheetImportFileHandler(key, file?.fileId)
        : await downloadDataLakeFileHandler(key, file?.fileId);
    } catch (error) {
      console.log("Error while upload file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const handleChangeComment = (value: string, file: KPIFile) => {
    setUploadedFilesList((prev) =>
      prev?.map((v) =>
        v.fileId === file.fileId ? { ...v, comment: value } : v
      )
    );
  };
  const handleSaveComment = () => {
    const list: KPIFile[] = [];
    uploadedFilesList.map((v) => {
      const newItem = { ...v };
      const url = v.url;
      delete newItem.url;
      list.push(newItem);
      return { ...v, url };
    });
    onChangeFiles && onChangeFiles(list);
  };

  const getTagColor = (status: string) => {
    let tagColor = "#A58CFA";
    switch (status) {
      case "NOT_STARTED":
        tagColor = "#569CE6";
        break;
      case "PENDING_REVIEW":
        tagColor = "#E69B56";
        break;
      case "APPROVED":
        tagColor = "#A68DFB";
        break;
      case "FAILED":
        tagColor = "#EC5E5E";
        break;
      case "SUCCESS":
        tagColor = "#A58CFA";
        break;
      default:
        tagColor = "#A58CFA";
    }
    return tagColor;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "url",
      key: "url",
      render: (value: any) => (
        <>{value?.split("/").pop().split(".").slice(0, -1).join(".")}</>
      ),
    },
    {
      title: "Date Uploaded",
      dataIndex: "url",
      key: "url",
      render: (value: any) => {
        const dateUpload = parseInt(value?.split("/").pop().split("-")[0]);
        return <>{moment.unix(dateUpload).format(YearMonthDayFormat)} </>;
      },
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Status",
      dataIndex: "importStatus",
      key: "importStatus",
      render: (value: any, file: KPIFile) => {
        return (
          <>
            {value && (
              <StyledTag bgcolor={getTagColor(value)}>{value}</StyledTag>
            )}
            {file.automapping_status && (
              <StyledTag bgcolor={getTagColor(file.automapping_status)}>
                AUTOMAPPING {file.automapping_status}
              </StyledTag>
            )}
          </>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (value: any, record: KPIFile) => {
        return isViewOnly ? (
          value || "-"
        ) : (
          <FormWrapper>
            <Input
              value={value}
              onChange={(e) => handleChangeComment(e.target.value, record)}
              onBlur={() => handleSaveComment()}
              onPressEnter={() => handleSaveComment()}
            />
          </FormWrapper>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "url",
      render: (value: any, record: KPIFile, index: number) => (
        <>
          <VerticalAlignBottomOutlined
            style={{ marginRight: "5px" }}
            onClick={() => downloadFileHandler(value, record)}
          />
          <Link onClick={() => downloadFileHandler(value, record)}>
            Download File
          </Link>
        </>
      ),
    },
  ];
  uploadedFilesList?.find(
    (v) =>
      v.automapping_status === AutomappingStatus.FAILED ||
      v.importStatus === "APPROVED" ||
      v.importStatus === "PENDING_REVIEW"
  ) &&
    !checkRolesPermission(["role:auditor"]) &&
    columns.push({
      title: "",
      dataIndex: "",
      key: "",
      render: (_: any, record: KPIFile, index: number) => (
        <>
          {record.automapping_status === AutomappingStatus.FAILED ? (
            <StyledButton
              onClick={() => handleUploadFile(3, record, "kpiMapping")}
            >
              Complete mapping
            </StyledButton>
          ) : (
            (record.importStatus === "APPROVED" ||
              record.importStatus === "PENDING_REVIEW") && (
              <StyledButton
                onClick={() => handleUploadFile(4, record, "update")}
              >
                Review
              </StyledButton>
            )
          )}
        </>
      ),
    });

  return (
    <CommonModal
      title={"KPIs Files"}
      visible={visible}
      onCancel={onClose}
      width={1000}
      maskClosable={false}
      okText="Save"
      destroyOnClose
      centered
      footer={
        <>
          <Button ghost onClick={() => onClose()}>
            Cancel
          </Button>
        </>
      }
    >
      <div className="file-upload" style={{ padding: 10 }}>
        <Spin spinning={loaderUploadedFilesList}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {uploadedFilesList?.length > 0 ? (
              <CommonTable dataSource={uploadedFilesList} columns={columns} />
            ) : (
              "No files were found for the selected record"
            )}
            {!isViewOnly && uploadEnabled && (
              <Col xs={24} sm={24} md={24} lg={24}>
                <StyledButton
                  style={{ marginTop: 10 }}
                  type="custom"
                  icon={<UploadOutlined />}
                  onClick={() => handleUploadFile()}
                >
                  Upload
                </StyledButton>
              </Col>
            )}
          </Col>
        </Spin>
      </div>
    </CommonModal>
  );
};

export default KpiFilesModal;
