import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useBoolean } from "ahooks";
import { observer } from "mobx-react-lite";
import {
  StyledProgress,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";
import { EditTargetModal } from "../../../WhatIfOnboarding/Components/EditTargetModal";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import { getProgress } from "../../ReductionPlanUtils";
import img from "../../scienceBasedTargets.png";
import styles from "../../ReductionPlanKanbanView.module.scss";

interface KanbanTargetProps {
  data: Target;
}

export const KanbanTarget = observer(({ data }: KanbanTargetProps) => {
  const [
    editTargetModal,
    { setTrue: showEditTargetModal, setFalse: hideEditTargetModal },
  ] = useBoolean(false);

  const handleEditTarget = (data: Target) => {
    reductionPlanStore
      .updateTarget(data)
      .then(() => reductionPlanStore.getAllTargets());
    hideEditTargetModal();
  };

  const handleDelete = (data: Target) => {
    reductionPlanStore.deleteTarget(data._id.$oid);
  };

  const moreButtons = (data: Target) => (
    <TableCommonMenu>
      <Menu.Item key={"view"}>
        <Button>
          <Link
            to={`/carbon/reduction-plan/${data.emissionName}`}
            state={data._id.$oid}
          >
            <EyeOutlined />
            View
          </Link>
        </Button>
      </Menu.Item>
      <Menu.Item key={"edit"}>
        <Button onClick={showEditTargetModal}>
          <EditOutlined />
          Edit
        </Button>
        <EditTargetModal
          target={data}
          isShown={editTargetModal}
          isCustom={data.type === "custom"}
          onClose={hideEditTargetModal}
          onConfirm={(data) => handleEditTarget(data as Target)}
        />
      </Menu.Item>
      <Menu.Item key={"delete"}>
        <Button
          className={styles.menuButton}
          onClick={() => handleDelete(data)}
        >
          <DeleteOutlined />
          Delete
        </Button>
      </Menu.Item>
    </TableCommonMenu>
  );

  return (
    <div key={data._id.$oid} className={styles.statusItem}>
      <Row justify="space-around" align-items="baseline">
        <Col className={styles.statusItemTitle} span={22}>
          {data.type === "custom" && (
            <img
              className={styles.statusItemTitleIcon}
              src={img}
              alt="Science Based Target"
            />
          )}
          {data.emissionName}
        </Col>
        <Col span={1} className={styles.statusItemIcon}>
          <Dropdown
            overlay={() => moreButtons(data)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <div className={styles.statusItemYear}>{data.baseYear || "-"}</div>
          <div className={styles.statusItemAmount}>
            {Number(data.baseYearConsumptionAmount)
              .toFixed(2)
              .replace(/\.00$/, "") || 0}{" "}
            kWh
          </div>
        </Col>
        <Col>
          <div className={styles.statusItemYear}>Current</div>
          <div className={styles.statusItemAmount}>
            {Number(data.currentConsumptionAmount)
              .toFixed(2)
              .replace(/\.00$/, "") || 0}{" "}
            kWh
          </div>
        </Col>
        <Col>
          <div className={styles.statusItemYear}>{data.targetYear || "-"}</div>
          <div className={styles.statusItemAmount}>
            {Number(data.goal).toFixed(2).replace(/\.00$/, "")} kWh
          </div>
        </Col>
      </Row>
      <Row>
        <StyledProgress
          percent={getProgress(data)}
          format={(percent: number) => percent.toFixed(0) + "%"}
        />
      </Row>
    </div>
  );
});
