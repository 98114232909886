import { LeftOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Alert,
} from "antd";
import React from "react";
import { FormWrap, FormWrapper, StyledButton } from "../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { e } from "mathjs";
const { Title } = Typography;
import { useLocation } from 'react-router-dom'

const EmailTemplate = ({
  form,
  onClickPrevious,
  setInitialValues,
  initialValues,
  onClickNext,
}) => {
  const location = useLocation();
  const routeName = location.pathname;
  const routeRegex = /^\/management\/collect-data\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/update-mixed-survey$/;
  
  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>Email Template</Title>
            <Form.Item name="email_type" label=" " labelCol={{ span: 24 }}>
              <Radio.Group
                style={{ marginTop: "5px" }}
                value={initialValues.email_type}
              >
                <Space direction="vertical" size="middle">
                  <Row>
                    <Col>
                      <Radio value="Primary">Primary Email</Radio>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio value="Reminder">Reminder Email</Radio>
                    </Col>
                  </Row>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Title level={4}>Email Settings</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Email Subject"
              name={
                initialValues.email_type === "Primary"
                  ? "email_subject"
                  : "email_reminder_subject"
              }
              rules={[
                {
                  required: true,
                  message: "Please enter an email subject",
                },
              ]}
            >
              <Input placeholder="Please enter an email subject" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>Email Body</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Alert
              message="Email Placeholder Options"
              description="There are 3 placeholders currently available: {SIGNUP_LINK}, {SIGNIN_LINK} and {FULL_NAME}. {SIGNUP_LINK} will send the user a sign up link if they do not exist. {SIGNIN_LINK} takes an existing user to sign in immediately and {FULL_NAME} is the user's full display name and both should be used only when the user already exists. The placeholders will be autofilled when the emails are sent and can be used in links as well."
              type="warning"
              showIcon
            />
            <Title style={{ fontSize: "14px" }}>{`Email Data`}</Title>
            <div className="ckMediumHeight">
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={
                  initialValues.email_type === "Primary"
                    ? initialValues.email_body
                    : initialValues.email_reminder_body || ""
                }
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  const typeEmail = form.getFieldValue("email_type");

                  if (typeEmail === "Primary") {
                    setInitialValues((prev) => ({ ...prev, email_body: data }));
                  }
                  if (typeEmail === "Reminder") {
                    setInitialValues((prev) => ({
                      ...prev,
                      email_reminder_body: data,
                    }));
                  }
                }}
              />
            </div>
          </Col>

          {initialValues.email_type === "Reminder" && (
            <Col span={24}>
              <Form.Item
                name="reminder_interval"
                label="Reminder Frequency"
                labelCol={{ span: 24 }}
              >
                <Radio.Group
                  style={{ marginTop: "5px" }}
                  value={initialValues.reminder_interval}
                >
                  <Space direction="vertical" size="middle">
                    <Row>
                      <Col>
                        <Radio value="op1">No reminder</Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio value={1000000}>One-time reminder</Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio value="recurring_reminder">
                          Recurring reminder
                        </Radio>
                      </Col>
                    </Row>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {initialValues.reminder_interval === "recurring_reminder" && (
                <Form.Item name="recurring_reminder_val">
                  <Row
                    style={{
                      alignItems: "center",
                      color: "white",
                      marginLeft: "25px",
                    }}
                  >
                    <Space direction="horizontal" size="small">
                      <Col>Remind every</Col>
                      <Col>
                        <Input
                          maxLength={10}
                          value={initialValues.recurring_reminder_val}
                          style={{ textAlign: "center", width: "100px" }}
                        />
                      </Col>
                      <Col>days</Col>
                    </Space>
                  </Row>
                </Form.Item>
              )}
            </Col>
          )}
          <Col span={24}>
            <Form.Item>
              <StyledButton
                className="margin-5"
                onClick={() => onClickPrevious()}
                type="custom"
              >
                <LeftOutlined /> <span>Previous</span>
              </StyledButton>
              <StyledButton type="custom" onClick={() => onClickNext()}>
                  {routeName === '/management/collect-data/create-mixed-survey' || routeRegex.test(routeName) ? 'Continue to step 7' : 'Continue to step 5'}
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default EmailTemplate;
