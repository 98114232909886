import { makeAutoObservable, runInAction } from "mobx";
import { Auth } from "aws-amplify";
import { getFirstAndLastNameByUsername } from "../../shared/commonFunctions";

class UserInfoStore {
  userName: string;
  userID: string;

  constructor() {
    this.userName = ""
    this.userID = ""  
    makeAutoObservable(this)
    this.fetchUserInfo()
  }

  fetchUserInfo = async() => {
    try {
      const data = await Auth.currentSession();
      const user_id = data.getAccessToken().payload["username"];
      const username = await getFirstAndLastNameByUsername(user_id);
      runInAction(() => {
        this.userName = username;
        this.userID = user_id;
      })
    }
    catch(e){
      runInAction(() => setTimeout(this.fetchUserInfo, 3000));
    }
  }
}

const userInfoStore = new UserInfoStore();

export { userInfoStore };
