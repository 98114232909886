import React from "react";
import { StyledButton } from "../../shared/commonStyles";
import { Typography } from "antd";
import styles from "./Common.module.scss";

export const Common = ({ handleFormType, provider }) => {
  return (
    <>
      <Typography.Title level={2} className={styles.title}>
        Welcome To Hydrus
      </Typography.Title>
      <p className="color-grey">
        Hydrus simplifies the process of collecting sustainability data
      </p>
      {provider === null && (
        <div className={styles.container}>
          <StyledButton
            className={styles.buttonNewAcc}
            type="custom"
            onClick={() => handleFormType("signUp")}
          >
            Create an Account
          </StyledButton>
          <div className={styles.boxSignIn}>
            Already have an account?
            <StyledButton
              type="custom"
              onClick={() => handleFormType("signIn")}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#A68DFB"
            >
              Sign in
            </StyledButton>
          </div>
        </div>
      )}
    </>
  );
};
