import React,{useState,createContext} from "react";
// create context 
export const ReportingPageContext = createContext(null);

// create provider
export const ReportingPageProvider = ({ children }) => {
  const [dataLakeMapList,setDataLakeMapList] = useState([]);
  const [loadingDataLakeMapList,setLoadingDataLakeMapList] = useState(false);

  const data = {
     dataLakeMapList : [dataLakeMapList,setDataLakeMapList],
     loadingDataLakeMapList : [loadingDataLakeMapList,setLoadingDataLakeMapList]
  };

    return <ReportingPageContext.Provider value={data}>{children}</ReportingPageContext.Provider>;
};