import React, { useEffect, useState } from "react";
import {
  listNotifications,
  updateNotifications,
} from "../../../../services/notifications";
import { useAsync, useAsyncEffect } from "../../../../hooks/use-async";

import NotificationsPage from "../Components/NotificationsPage";
import { executeOpenDataSheet } from "../../../../shared/utils";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { useNavigate } from "react-router";

export type NotificationType = {
  _id: {
    $oid: string;
  };
  createdAt: {
    $date: string;
  };
  recipientUsername: string;
  notificationType: "SURVEY_PENDING" | "IMPORT_PENDING" | "RECORD_DENIED";
  message: string;
  itemId: string;
  itemName: string;
  isRead: boolean;
};

export type UpdateNotificationsResponse = {
  modifiedCount: number;
};

export type PaginationModel = {
  page: number;
  pageSize: number;
};

export const NotificationsPageContainer: React.FC = () => {
  const [totalNotificationsCount, setTotalNotificationsCount] =
    useState<number>();
  const [isOpeningDataSheet, setIsOpeningDataSheet] = useState<boolean>(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationModel>({
    page: 1,
    pageSize: 5,
  });

  const navigate = useNavigate();
  const { checkRolesPermission } = useCheckRolesPermissions();

  const [notificationsList, getNotificationsListCallable] = useAsyncEffect<
    NotificationType[]
  >({
    fn: async () => {
      return await listNotifications({ filter: {} });
    },
    // TODO: later add pagination details as dependency
    dependencies: [],
  });

  const handlePageSizeChange = (page: number, pageSize: number) => {
    setPaginationDetails({
      page,
      pageSize,
    });
  };

  const [markAllNotificationsReadResult, markAllNotificationsReadCallable] =
    useAsync<UpdateNotificationsResponse>({
      fn: async () => {
        const result = await updateNotifications({
          filter: {},
          update_operations: {
            $set: {
              isRead: true,
            },
          },
        });
        return result;
      },
    });

  const [markNotificationReadResult, markNotificationReadCallable] =
    useAsync<UpdateNotificationsResponse>({
      fn: async (notificationId: string) => {
        const result = await updateNotifications({
          filter: {
            _id: { $in: [notificationId] },
          },
          update_operations: {
            $set: {
              isRead: true,
            },
          },
        });
        return result;
      },
    });

  useEffect(() => {
    if (notificationsList.result) {
      setTotalNotificationsCount(notificationsList.result.length);
    }
  }, [notificationsList.result]);

  const handleMarkAllRead = async () => {
    await markAllNotificationsReadCallable();
  };

  useEffect(() => {
    (async () => {
      if (markAllNotificationsReadResult.result) {
        await getNotificationsListCallable();
      }
    })();
  }, [markAllNotificationsReadResult.result]);

  const isUser =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:elevateduser"]);

  const handleOpenDataSheet = async (notificationItem: NotificationType) => {
    try {
      setIsOpeningDataSheet(true);
      await executeOpenDataSheet(navigate, notificationItem, isUser);
      await markNotificationReadCallable(notificationItem._id.$oid);
    } catch (e) {
      console.log(e);
    } finally {
      setIsOpeningDataSheet(false);
    }
  };

  return (
    <NotificationsPage
      isLoading={notificationsList.isLoading}
      hasError={notificationsList.error}
      notificationCount={totalNotificationsCount ?? 0}
      notificationData={notificationsList.result}
      page={paginationDetails.page}
      pageSize={paginationDetails.pageSize}
      onClickOpenDataSheet={handleOpenDataSheet}
      onPageSizeChange={handlePageSizeChange}
      onClickMarkAllRead={handleMarkAllRead}
    />
  );
};
