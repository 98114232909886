import React, { useEffect, useState } from "react";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { calculationGroupsStore } from "../../../../Carbon/CalculationGroupsStore";
import { observer } from "mobx-react-lite";
import { calculationStore } from "../../../../Carbon/CalculationsStore";

interface GroupCalculationsModalProps {
  data?: CalculationGroup;
  visible: boolean;
  onClose: () => void;
}

export const GroupCalculationsModal = observer(
  ({ data, visible, onClose }: GroupCalculationsModalProps) => {
    useEffect(() => {
      calculationStore.fetchCalculations();
      calculationGroupsStore.fetchCalculationGroups();
    }, []);
    const calculations = calculationStore.savedCalculations?.filter(
      (calculation) =>
        !calculationGroupsStore.calculationGroups
          ?.map((group) => group.calculationsList)
          .flat()
          .filter((value) => !data?.calculationsList?.find((v) => v === value))
          .find((value) => calculation._id?.$oid === value)
    );
    const initialData = {
      name: "",
      description: "",
      calculationsList: [],
    };
    const [groupData, setGroupData] = useState<CalculationGroup>(initialData);
    useEffect(() => {
      data && setGroupData(data);
    }, [data]);
    const handleChangeData = (value: string | string[], key: string) => {
      setGroupData((prev) => ({ ...prev, [key]: value }));
    };
    const handleConfirm = () => {
      data
        ? calculationGroupsStore.updateCalculationGroup(groupData)
        : calculationGroupsStore.addCalculationGroup(groupData);
      onClose();
      setGroupData(initialData);
    };

    const handleClose = () => {
      setGroupData(initialData);
      onClose();
    };

    return (
      <CommonModal
        title={`${data ? "Edit" : "Create"} group for calculations`}
        visible={visible}
        onCancel={handleClose}
        footer={[
          <Button type="text" key="cancel" onClick={handleClose}>
            Cancel
          </Button>,
          <StyledButton
            disabled={
              !groupData?.name?.length || !groupData?.calculationsList?.length
            }
            onClick={handleConfirm}
            type="primary"
            key="confirm"
          >
            Save Group
          </StyledButton>,
        ]}
      >
        <Spin spinning={calculationGroupsStore.loadingGroup}>
          <FormWrapper>
            <Row>
              <Col span={24}>
                <Form.Item label="Name" labelCol={{ span: 24 }}>
                  <Input
                    value={groupData.name}
                    onChange={(e) => handleChangeData(e.target.value, "name")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Description" labelCol={{ span: 24 }}>
                  <Input
                    value={groupData.description}
                    onChange={(e) =>
                      handleChangeData(e.target.value, "description")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Calculations" labelCol={{ span: 24 }}>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={groupData.calculationsList}
                    onChange={(value) =>
                      handleChangeData(value, "calculationsList")
                    }
                    showSearch
                    notFoundContent={
                      !calculations.length && <Spin size="small" />
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={calculations?.map((item) => ({
                      value: item._id?.$oid,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </FormWrapper>
        </Spin>
      </CommonModal>
    );
  }
);
