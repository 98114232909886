import React, { useState } from "react";
import { CommonTable, StyledButton } from "../../../shared/commonStyles";
import { Tooltip } from "antd";
import { stripHtmlTags } from "../../../shared/commonFunctions";
import DataSheetFormViewModal from "../../../components/Modals/DataSheetFormViewModal/DataSheetFormViewModal";

const DataSheetSelectionTable = ({
  dataSheetsList,
  rowSelection,
  loadDataSheets,
}) => {
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [rowDataView, setRowDataView] = useState(null);

  const onClickViewForm = (record) => {
    setRowDataView(record);
    setShowFormViewModal(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "sheet_name",
      width: "120px",
    },
    {
      title: "Sheet Type",
      dataIndex: "sheet_type",
      width: "120px",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "200px",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "300px",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {stripHtmlTags(record?.description)}
            </div>
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "10%",
      render: (_, record) => (
        <StyledButton
          type="default"
          bordercolor="#fff"
          onClick={() => onClickViewForm(record)}
        >
          Open preview
        </StyledButton>
      ),
    },
  ];

  return (
    <>
      {showFormViewModal && (
        <DataSheetFormViewModal
          visible={showFormViewModal}
          onClose={() => setShowFormViewModal(false)}
          rowData={rowDataView}
        />
      )}
      <CommonTable
        loading={loadDataSheets}
        rowKey={(data) => {
          const id = data?.["_id"]?.["$oid"];
          return id;
        }}
        dataSource={dataSheetsList}
        columns={columns}
        rowSelection={rowSelection}
        scroll={{ x: true }}
      />
    </>
  );
};

export default DataSheetSelectionTable;
