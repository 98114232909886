import {
    API, Auth
} from 'aws-amplify';
import * as queries from '../../../graphql/queries';

export const getConnectToken = async (utility_credential_id) => {
    const requestPayload = {
        utility_credential_id: utility_credential_id
    };

    const responseGetUtilityBills = await API.graphql({
        query: queries["getUtilityBills"],
        variables: {
            request_type: "connect_token",
            request_payload: JSON.stringify(requestPayload)
        }
    });

    if (responseGetUtilityBills) {
        return responseGetUtilityBills['data']['getUtilityBills']
    }
};
