import React from "react";
import {
  StyledButton,  
  FormWrap,
  FormWrapper
} from "../../../shared/commonStyles";
import {  
  Form,  
  Row,
  Col,
  Typography,
  Divider,
  Select,  
} from "antd";

import {  
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { DateSelect, LocationSelect } from "../../reportingPage/Components";
import { BusinessUnitSelect } from "../../reportingPage/Components/BusinessUnitSelect/BusinessUnitSelect";


const { Title } = Typography;

const Step2 = (
  {
    locations,
    businessUnits,
    reportPeriods,
    locationId,
    setLocationId,
    businessUnitId,
    setBusinessUnitId,
    reportPeriodId,
    setReportPeriodId,onClickNext,onClickPrevious
  }) => { 
  
  return (  
    <FormWrap width="500px">
      <FormWrapper>
        <Row justify="space-between ">
          <Col lg={24} md={24} sm={24} xs={24}>            
            <Title className="color-white" level={3}>
              Business information{" "}
            </Title>
            <Title level={4} className="color-white">
                Select business unit
            </Title>
            <Divider style={{backgroundColor:'#3A3C5A', marginTop:'15px'}} />

            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Business Unit</span>}
            >
              <BusinessUnitSelect
                className="inner-color-input"
                key={1}
                selectedBusinessUnit={businessUnitId}
                businessUnits={businessUnits}
                setSelectedBusinessUnit={setBusinessUnitId}
                visibleAddBtn={false}
                selectWidth={450}
                extraOptions={
                  <Select.Option value="" label={"None"}>
                    None
                  </Select.Option>
                }
              />
            </Form.Item>
            {/* </Col> */}
            {/* <Col lg={24} md={24} sm={24} xs={24}>     */}
            <Title level={4} className="color-white" style={{marginTop :'1.2em'}}>Select location </Title>
            <Divider style={{backgroundColor:'#3A3C5A', marginTop:'15px'}} />
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white ">Location</span>}
            >
              <LocationSelect
                key={2}
                setRange={setLocationId}
                value={locationId}
                visibleAddBtn={false}
                locations={locations}
                selectWidth={450}
                extraOptions={
                  <Select.Option value="" label={"None"}>
                    None
                  </Select.Option>
                }
              />
            </Form.Item>
            {/* </Col> */}
            {/* <Col lg={24} md={24} sm={24} xs={24}> */}
            <Title level={4} className="color-white" style={{marginTop :'1.2em'}}>              
                Select report period
            </Title>
            <Divider style={{backgroundColor:'#3A3C5A', marginTop:'15px'}} />
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Report Period</span>}
            >
              <DateSelect
                key={3}
                setRange={setReportPeriodId}
                value={reportPeriodId}
                timeperiods={reportPeriods}
                visibleAddBtn={false}
                selectWidth={450}
                extraOptions={
                  <Select.Option value="" label={"None"}>
                    None
                  </Select.Option>
                }
              />
            </Form.Item>
            <Form.Item>
              <Row justify="flex-start">
                <StyledButton
                  className="margin-5"
                  onClick={() => onClickPrevious()}
                  type="custom"
                >
                  <LeftOutlined /> <span>Previous</span>
                </StyledButton>

                <StyledButton
                  className="margin-5"
                  onClick={() => onClickNext()}
                  type="custom"
                >
                  <span>Continue to step 3</span> <RightOutlined />
                </StyledButton>
              </Row>
            </Form.Item>
            {/* </Col>     */}
            {/* </Row>   */}
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
}

export default Step2;