import React from 'react'
import { FormWrap, FormWrapper, StyledButton } from '../../../../../shared/commonStyles'
import { Form, Input, Typography,DatePicker } from "antd";
import { RightOutlined } from '@ant-design/icons';
import UsersList from './../../UsersList'
import UsersGroupList from './../../UsersGroupList'

const { Title } = Typography;
const Step3 = ({onClickPrevious,onClickNext}) => {
  const { RangePicker } = DatePicker;

  return (
   
    <FormWrap style={{width:"50%"}}> 
        <FormWrapper>
        <Title level={4}>Create Custom Goal based on selected parameters</Title>
            <Form.Item 
              labelCol={{ span: 24 }} 
              name="name" 
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name",
                },
              ]}
            >
              <Input placeholder={"Enter name"} />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <Input placeholder={"Enter Description"} />
            </Form.Item>
            <Form.Item
              name="dateRange"
              label="Specify a timeline for the goal"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Please specify timeline!" }]}
              className="clearIcon"
            >
              <RangePicker
                placeholder={["Start date", "End date"]}
                style={{ background: "#625D75" }}
              />
            </Form.Item>
            <UsersList />
            <UsersGroupList />
            
            <Form.Item>
              <StyledButton
                onClick={() => onClickPrevious()}
                type="default"
                ghost
              >
                <span>Back</span>
              </StyledButton>
              <StyledButton onClick={onClickNext} type="custom">
                <span>Continue to step 4</span> <RightOutlined />
              </StyledButton>
            </Form.Item>
        </FormWrapper>
    </FormWrap> 
   
  )
}

export default Step3