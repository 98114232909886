import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import { Storage } from "aws-amplify";

export function getDistinctIndustries(items) {
  const industriesSet = new Set();

  items.forEach((item) => {
    if (item && item.tags && item.tags.industry) {
      industriesSet.add(item.tags.industry.trim());
    }
  });

  const distinctIndustries = Array.from(industriesSet);
  return distinctIndustries;
}

export function getDistinctCategories(items) {
  const categoriesSet = new Set();

  items.forEach((item) => {
    if (item && item.tags && item.tags.category) {
      categoriesSet.add(item.tags.category.trim());
    }
  });

  const distinctCategories = Array.from(categoriesSet);
  return distinctCategories;
}

export function getDistinctThemes(items) {
  const themesSet = new Set();

  items.forEach((item) => {
    if (item && item.tags && item.tags.theme) {
      themesSet.add(item.tags.theme.trim());
    }
  });

  const distinctThemes = Array.from(themesSet);
  return distinctThemes;
}

export function getUniqueFrameworks(items) {
  const frameworksSet = new Set();

  items.forEach((item) => {
    if (item && item.frameworks && Array.isArray(item.frameworks)) {
      item.frameworks.forEach((framework) => {
        frameworksSet.add(framework.trim());
      });
    }
  });

  const uniqueFrameworks = Array.from(frameworksSet);
  return uniqueFrameworks;
}

export const getFirstAndLastNameByUsername = async (username) => {
  try {
    const parameters = {
      username,
    };
    const parms = JSON.stringify(parameters);
    const response = await API.graphql({
      query: queries["manageUserAccess"],
      variables: { request_type: "get-real-username", parameters: parms },
    });
    return response?.data?.manageUserAccess;
  } catch (error) {
    console.log("error while get user first and last name", error);
    return "";
  }
};

export const handleLongString = (name, limit) =>
  name && (name.length > limit ? `${name.substring(0, limit)}...` : name);

export const capitalizeFirstLetter = (string) => {
  try {
    const frags = string.split("_");
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  } catch (err) {
    console.log("Error while capitalizeFirstLetter", err);
    return "";
  }
};

export const convertToSlug = (Text) => {
  try {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  } catch (err) {
    console.log("Error while convertToSlug", err);
    return "";
  }
};

export const getFocusAreaCardItemFromList = (id, focusAreas) => {
  // get focus area card data by id.
  if (focusAreas) {
    for (const item of focusAreas) {
      const data = item?.cards?.find((card) => card.id === id);
      if (data) {
        // found focus area data stop loop and return data;
        return data;
      }
    }
  }
  return null;
};

export const getFlowItemFromList = (flow_id, flowsList) => {
  if (flowsList) {
    return flowsList?.find((item) => item?.id === flow_id);
  }
};

export const getGroupNameByIdFromList = (groupNamesList, group_name_id) => {
  const getGroupNameData = groupNamesList
    ? groupNamesList.find((item) => item._id["$oid"] === group_name_id)
    : null;
  return getGroupNameData ? getGroupNameData.name : "";
};

export const getToFixedNumber = (number) => {
  const value = parseFloat(number);
  if (!value) return 0;
  return value ? value.toFixed(2) : 0;
};

export const calculateDigit = (num) => {
  const numberString = num && num.toString().slice(-8);
  const lengthMain = num && num.length;
  if (lengthMain >= 8) {
    const newNum = num.substring(0, lengthMain - 8) + "." + numberString;
    return newNum && getToFixedNumber(newNum);
  }
  return num;
};

const numberRegEx = new RegExp(/\B(?=(\d{3})+(?!\d))/g);

export const numberWithCommas = (number) => {
  const numParts = number.toString().split(".");
  const wholeNumbers = numParts[0].replace(numberRegEx, ",");
  const decimalNumbers = numParts[1];
  return `${wholeNumbers}${decimalNumbers ? "." + decimalNumbers : ""}`;
};

export const convertToLowUnderScore = (Text) => {
  // convert string to lowe case spaces with signle underscore, remove number and special charactor.
  try {
    return Text?.toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/gi, "_")
      .replace(/^_+/, "")
      .replace(/_$/, "");
  } catch (err) {
    console.log("Error while convertToSlug", err);
    return "";
  }
};

export const isValidJSONString = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const downloadDataLakeFileHandler = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const requestPayload = {
      file_id: "",
      full_path: key,
    };
    const response = await API.graphql({
      query: queries["dataSheetFiles"],
      variables: {
        request_type: "DOWNLOAD_FILE",
        data_id: dataId,
        request_payload: JSON.stringify(requestPayload),
      },
    });
    const signedURL = response["data"]["dataSheetFiles"];
    if (signedURL) {
      const resp = await fetch(signedURL);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    //  return false;
    throw Error("Something went wrong!");
  }
};

export const downloadDataSheetFileHandler = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const requestPayload = {
      file_id: "",
      full_path: key,
    };
    const response = await API.graphql({
      query: queries["dataSheetFiles"],
      variables: {
        request_type: "DOWNLOAD_FILE",
        data_id: dataId,
        request_payload: JSON.stringify(requestPayload),
      },
    });
    const signedURL = response["data"]["dataSheetFiles"];
    if (signedURL) {
      const resp = await fetch(signedURL);

      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    throw Error("Something went wrong!");
  }
};
export const downloadDataSheetFileHandlerNew = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const requestPayload = {
      file_id: "",
      full_path: key,
    };
    const response = await API.graphql({
      query: queries["dataSheetFiles"],
      variables: {
        request_type: "DOWNLOAD_FILE",
        data_id: dataId,
        request_payload: JSON.stringify(requestPayload),
      },
    });
    const signedURL = response["data"]["dataSheetFiles"];
    const parsedUrl = signedURL;
    if (signedURL) {
      const resp = await fetch(parsedUrl);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    throw Error("Something went wrong!");
  }
};

export const downloadDataSheetAuditFileHandler = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const response = await API.graphql({
      query: queries["dataSheetAuditFiles"],
      variables: {
        request_type: "AUDIT_DOWNLOAD",
        data_id: dataId,
        version: 1,
        file_name: fileName,
      },
    });
    const signedURL = response["data"]["dataSheetAuditFiles"];
    if (signedURL) {
      const resp = await fetch(signedURL);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    //  return false;
    throw Error("Something went wrong!");
  }
};

export const downloadAuditDataLakeFileHandler = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const response = await API.graphql({
      query: queries["dataLakeAuditFiles"],
      variables: {
        request_type: "AUDIT_DOWNLOAD",
        data_id: dataId,
        version: 1,
        file_name: fileName,
      },
    });
    const signedURL = response["data"]["dataLakeAuditFiles"];
    if (signedURL) {
      const resp = await fetch(signedURL);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    //  return false;
    throw Error("Something went wrong!");
  }
};

export const downloadDataSheetImportFileHandler = async (key, dataId) => {
  try {
    const fileName = key && key?.split("/")?.pop();
    const version = key && key?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
    const response = await API.graphql({
      query: queries["dataSheetImport"],
      variables: {
        request_type: "DATASHEET_DOWNLOAD",
        data_id: dataId,
        version: 1,
        file_name: fileName,
      },
    });
    const signedURL = response["data"]["dataSheetImport"];
    if (signedURL) {
      const resp = await fetch(signedURL);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  } catch (error) {
    //  return false;
    throw Error("Something went wrong!");
  }
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
export const fetchProfileImage = async (username) => {
  try {
    const profile = await Storage.get(`userprofile/${username}.png`);
    return profile;
  } catch (error) {
    throw Error("Something went wrong!");
  }
};

export const containsNumber = (str) => {
  return /\d/.test(str);
};

export const containsWhitespace = (str) => {
  return /\s/.test(str);
};

export const truncateString = (str, charCount) => {
  if (str.length > charCount) {
    return str.slice(0, charCount) + "...";
  } else {
    return str;
  }
};

export const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export const encodeUrlName = (name) =>
  encodeURIComponent(name.replace(/\//g, "%2F"));
