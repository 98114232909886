import React, { useState, useEffect } from "react";
import { Space, message } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

import {
  CommonTable,
  StyledButton,
  FormWrapper,
  PageWrap,
} from "../../../shared/commonStyles";
import {
  getDocumentReview,
  removeDocumentReview,
} from "../../../services/documentReview";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { encodeUrlName } from "../../../shared/commonFunctions";

const DataTable = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rowDataId, setRowDataId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();

  const loadData = async () => {
    try {
      setLoading(true);
      const data = await getDocumentReview();
      if (data) {
        setDataSource(data.reverse());
      }
    } catch (error) {
      console.log("Error while fetching", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "description",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton
            type="default"
            icon={<EyeOutlined />}
            onClick={() =>
              navigate(
                `/reporting/document-review/${
                  record?._id["$oid"]
                }/${encodeUrlName(record.name)}/view`
              )
            }
          />
          <StyledButton
            type="default"
            icon={<EditOutlined />}
            onClick={() =>
              navigate(
                `/reporting/document-review/${
                  record?._id["$oid"]
                }/${encodeUrlName(record.name)}/update`
              )
            }
          />
          <StyledButton
            type="default"
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          />
        </Space>
      ),
    },
  ];

  const onDelete = (record) => {
    setRowDataId(record?._id);
    setShowConfirmModal(true);
  };
  const onConfirmDelete = async () => {
    if (!rowDataId) return;
    try {
      setBtnLoading(true);
      const payload = {
        _id: rowDataId,
      };
      const response = await removeDocumentReview(payload);
      if (response) {
        message.success("Document deleted successfully");
        setShowConfirmModal(false);
        loadData();
      } else {
        message.error("Something went wrong!");
      }
    } catch (error) {
      console.log("Error while deleting document", error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
      <PageWrap>
        <FormWrapper>
          <CommonTable
            loading={loading}
            rowKey={(data) => {
              const id = data?.["_id"]?.["$oid"];
              return id;
            }}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
          />
        </FormWrapper>
        {showConfirmModal && (
          <ConfirmModal
            title="Confirmation"
            visible={showConfirmModal}
            okText="Delete"
            onConfirm={onConfirmDelete}
            onClose={() => setShowConfirmModal(false)}
            description="Are you sure you want to delete document"
            ConfirmLoader={btnLoading}
          />
        )}
      </PageWrap>
    </>
  );
};

export default DataTable;
