import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Space,
  DatePicker,
  Button,
  message,
  Tooltip
} from "antd";
import {
  CommonModal,
  FormWrapper,
} from "../../../shared/commonStyles";
import { InfoCircleOutlined } from '@ant-design/icons';
import UsersList from "../../../components/Modals/AnalyticsChartModal/UsersList";
import UsersGroupList from "../../../components/Modals/AnalyticsChartModal/UsersGroupList";
import { YearMonthDayFormat } from "../../../shared/constants";
import { updateAnalyticsDashboardCharts } from "../../../services/analyticsChart";
import moment from "moment";
const { RangePicker } = DatePicker;

const AssignPermissionsModal = ({ isVisible, onClose, rowData, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    dateRange: "",
    userIds: [],
    groupIds: [],
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (rowData.permissions) {
      const permissions = rowData.permissions;
      const startDate = permissions?.startdate
          ? moment(permissions.startdate, YearMonthDayFormat)
          : null;
      
      const endDate = permissions?.enddate
          ? moment(permissions.enddate, YearMonthDayFormat)
          : null;
      const dateRange = [startDate, endDate];

      const initData = {
        groupIds: permissions?.group_name_id ? permissions?.group_name_id : "",
        userIds: permissions?.user_ids ? permissions?.user_ids : "",
        date_range: dateRange ? dateRange : "",
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    }else {
        const initData = {
          group_name_id: [],
          user_ids: [],
          date_range: [],
        };
        setInitialValues(initData);
        form.setFieldsValue(initData);
      }
  }, [rowData]);

  const onClickGrantAccess = async () => {
    form.validateFields().then(async (val) => {
      if (!rowData) return;
  
      try {
        setLoading(true);
        const { userIds, groupIds, date_range } = val;
        const startdate = date_range && date_range.length > 0 && date_range[0]
          ? date_range[0].format(YearMonthDayFormat)
          : null;
        const enddate = date_range && date_range.length > 1 && date_range[1]
          ? date_range[1].format(YearMonthDayFormat)
          : null;
  
        const permissions = {
          group_name_id: groupIds,
          user_ids: userIds,
          startdate: startdate,
          enddate: enddate,
        };
        const payload = {
          ...rowData,
          permissions: permissions,
        };
  
        const data = await updateAnalyticsDashboardCharts(payload);
        if (data) {
          message.success("Permissions assigned successfully");
          onClose();
          refresh();
        } else {
          message.error("Error while assigning permissions");
        }
      } catch (error) {
        console.log("Error while assigning permissions", error);
      } finally {
        setLoading(false);
      }
    });
  };
 
  return (
    <CommonModal
      title={"Permissions"}
      visible={isVisible}
      onCancel={onClose}
      width={700}
      maskClosable={false}
      destroyOnClose
      centered
      footer={[
        <Button key={"Cancel"} ghost onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={"grant_access"}
          type="primary"
          loading={loading}
          onClick={onClickGrantAccess}
        >
          Grant access
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <FormWrapper>
          <Form
            form={form}
            initialValues={initialValues}
          >
            <Row justify="space-between">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Col span={24}>
                  <UsersList />
                  <UsersGroupList />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="date_range"
                    label={
                      <span>
                        Specify an optional access timeline&nbsp;&nbsp;
                        <Tooltip title="If no access timeline is specified, the assigned users and groups will have indefinite access to this data sheet.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    labelCol={{ span: 24 }}
                    className="clearIcon"
                    rules={[
                      { required: false, message: "Please select a timeline" },
                    ]}
                  >
                    <RangePicker
                      placeholder={["Start date", "End date"]}
                      style={{ background: "#625D75" }}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Space>
    </CommonModal>
  );
};

export default AssignPermissionsModal;
