import React from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Divider,
  Select,
  Checkbox,
  FormInstance,
} from "antd";

import { RightOutlined } from "@ant-design/icons";
const { Title } = Typography;

interface Step1Props {
  form: FormInstance<any>;
  initialValues: InitialDataSheetFormValues;
  dataSheetsList: DataSheet[];
  onClickNext: () => Promise<void>;
  changeDescription: (data: any) => void;
  sheetNames?: string[];
  rowData?: DataSheet;
}

const Step1 = ({
  form,
  onClickNext,
  initialValues,
  dataSheetsList,
  changeDescription,
  sheetNames,
  rowData,
}: Step1Props) => {
  const { sheetName, description, is_reference_data } = initialValues;

  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>Sheet Description</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Name"
              name="sheetName"
              rules={[
                {
                  pattern: /^((?![.$]).)*$/gm,
                  required: true,
                  message:
                    "Please input unique sheet name! The name cannot include characters '.', '$'",
                  validator: (_, value) => {
                    try {
                      if (
                        sheetNames?.some(
                          (v) => v === sheetName && v !== rowData?.sheet_name
                        )
                      ) {
                        throw new Error("Sheet name is already exist!");
                      } else {
                        return Promise.resolve(true);
                      }
                    } catch (err) {
                      throw new Error("Sheet name is required!");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="Type in a name for the data sheet" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={description?.length ? description : ""}
                onReady={(editor: any) => {
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  changeDescription(data);
                  const values = form.getFieldsValue();
                  form.setFieldsValue({ ...values, description: data });
                }}
              />
            </Form.Item>
          </Col>

          {is_reference_data && (
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="metadata_sheet_id"
                label="Metadata Entity Sheet"
              >
                <Select placeholder="Please Select Metadata Entity Sheet">
                  <Select.Option key="None" value="">
                    None
                  </Select.Option>
                  {dataSheetsList
                    ?.filter((sheet) => sheet?.is_reference_data)
                    ?.map((sheet) => {
                      return (
                        <Select.Option
                          key={sheet?._id?.$oid}
                          value={sheet?._id?.$oid}
                        >
                          {sheet?.sheet_name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item name="enableComments" valuePropName="checked">
              <Checkbox>Enable comments</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="enableChangeHistory" valuePropName="checked">
              <Checkbox>Enable change history</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <StyledButton
                className="margin-5"
                onClick={() => onClickNext()}
                type="custom"
              >
                <span>Continue to step 2</span> <RightOutlined />
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step1;
