import React,{useEffect,useMemo} from 'react';
import { Spin,Typography} from 'antd';
import { useAssignedAuditorsList } from '../../hooks/useAssignedAuditorsList';
const {Title} = Typography;

export const AuditorsStatus = ({record,auditRecord}) =>{
    const {loading,auditorsProgress,loadAssignedAuditors} = useAssignedAuditorsList();
  
    const recordMemo = useMemo(()=> (record),[record]);
    const auditRecordMemo = useMemo(()=> (auditRecord),[auditRecord]);
  
    useEffect(()=>{
      if(recordMemo && auditRecordMemo){
        loadAssignedAuditors(auditRecordMemo,recordMemo);
      }
    },[recordMemo,auditRecordMemo]);
   
    if(loading){
      return (
        <Spin spinning={loading}/>
       );
    }
    const {progressStatus,denied} = auditorsProgress;
      if(progressStatus === "approved"){
        return  <Title className="color-grey" level={4}>Status: {progressStatus}</Title>
      }
      else if(progressStatus === "denied"){
        return  <Title className="color-grey" level={4}>Status: {progressStatus}</Title>
      }else if(denied > 0){
        return  <Title className="color-grey" level={4}>Status: Not Approved</Title>
      }else{
        return  <Title className="color-grey" level={4}>Status: Incomplete</Title>
      }
    
  }