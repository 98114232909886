import React from "react";
import { observer } from "mobx-react-lite";

import {
  EllipsisOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  VerticalAlignBottomOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { CommonTable } from "../../../shared/commonStyles";
import { Col, Popover, Row } from "antd";
import {
  fractionalNumber,
  getEmissionsUnit,
  getLinkDataSheet,
} from "../../Carbon/CalculationUtils";
import { CalculatorActions } from "./CalculatorActions";

import styles from "./EditableCalculationAuditTable.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { carbonStore } from "../../Carbon/CarbonStore";
import { calculatorStore } from "../../Carbon/CalculatorStore";

interface SelectedItem extends AutomatedEmission {
  key: string;
  name: string;
  checked?: boolean;
  scope: ScopeName;
}

export const EditableContext = React.createContext(null);

interface EditableCalculationAuditTableProps {
  data: Emission[] | Offset[];
  scope: ScopeName;
  attributionFactor?: number;
  selectedItems: SelectedItem[];
  onChangeSelectedItems: (data: SelectedItem[], scope: ScopeName) => void;
  onConfirmItem: (
    emissionKey: string,
    reason: string,
    audit_state: AuditState
  ) => void;
  emissionsUnit: string;
  conversionFactor: number;
  companyDetails: CompanyDetails;
}

type Record = {
  co2e_total: number;
  co2: number;
  ch4: number;
  n2o: number;
};

const EditableCalculationAuditTable = observer(
  ({
    data,
    scope,
    attributionFactor,
    onChangeSelectedItems,
    onConfirmItem,
    emissionsUnit,
    conversionFactor,
    companyDetails,
  }: EditableCalculationAuditTableProps) => {
    const navigate = useNavigate();
    const rowSelection = {
      onChange: (
        _selectedRowKeys: string,
        selectedItems: Array<SelectedItem>
      ): void => {
        onChangeSelectedItems(selectedItems, scope);
      },
    };
    const convertedNumber = fractionalNumber(
      companyDetails.decimal_places_number
    );
    const isFieldExist = (
      factorData: Factor | CustomManualFactor,
      field: string
    ) => {
      return field in factorData;
    };
    const columns =
      scope !== ScopeName.OFFSETS
        ? [
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              width: "10%",
              sorter: (a: Emission, b: Emission): number =>
                a.factor_data.name.localeCompare(b.factor_data.name),
              render: (_: any, data: Emission) => data.factor_data.name,
            },
            {
              title: "Region",
              dataIndex: "region_name",
              key: "region_name",
              width: "13%",
              sorter: (a: Emission, b: Emission): number =>
                a.factor_data.region_name.localeCompare(
                  b.factor_data.region_name
                ),
              render: (_: any, data: Emission) => data.factor_data.region_name,
            },
            {
              title: "Category",
              dataIndex: "category",
              key: "category",
              width: "10%",
              align: "center",
              sorter: (a: Emission, b: Emission) => {
                isFieldExist(a.factor_data, "category") &&
                  isFieldExist(b.factor_data, "category") &&
                  (a.factor_data as Factor).category.localeCompare(
                    (b.factor_data as Factor).category
                  );
              },
              render: (_: any, data: Emission) =>
                (data.factor_data as Factor).category,
            },
            {
              title: "Data source",
              dataIndex: "source",
              key: "source",
              width: "10%",
              align: "center",
              sorter: (a: Emission, b: Emission) => {
                isFieldExist(a.factor_data, "source") &&
                  isFieldExist(b.factor_data, "source") &&
                  (a.factor_data as Factor).source.localeCompare(
                    (b.factor_data as Factor).source
                  );
              },
              render: (_: any, data: Emission) =>
                (data.factor_data as Factor).source,
            },
            {
              title: "Consumption Amount",
              dataIndex: "consumption_amount",
              key: "consumption_amount",
              width: "20%",
              align: "center",
              className: styles.consumptionColumn,

              render: (data: number, elem: Emission): JSX.Element => {
                return (
                  <>
                    <div className={styles.emissionColumn}>
                      <div className={styles.itemCell}>
                        <span
                          className={`${
                            ((elem as AutomatedEmission).missing_data &&
                              styles.itemSpanWarning) ||
                            styles.emissionFullWidth
                          } 
                          ${styles.itemSpanInfo}`}
                        >
                          <span
                            className={`${
                              ((elem as AutomatedEmission).missing_data &&
                                styles.emissionNumber) ||
                              ""
                            }`}
                          >
                            {convertedNumber(data)}
                          </span>
                          <span
                            className={`${
                              ((elem as AutomatedEmission).missing_data &&
                                styles.emissionUnit) ||
                              styles.emissionUnitFullWidth
                            }`}
                          >
                            {elem.factor_data.unit.split("/").at(-1)}
                          </span>

                          {(elem as AutomatedEmission).missing_data && (
                            <span className={styles.emissionWarning}>
                              <Popover
                                placement="right"
                                content={
                                  <>
                                    <div className={styles.warningPopoverTitle}>
                                      Warning
                                    </div>
                                    <div>
                                      Some pieces of data are missing. Review
                                      the data sheet to increase the accuracy of
                                      the calculation
                                    </div>
                                    <div className={styles.warningPopoverLink}>
                                      <Link
                                        to={
                                          getLinkDataSheet(
                                            elem as AutomatedEmission,
                                            carbonStore.getSheetById(
                                              (elem as AutomatedEmission)
                                                .activity_sheet
                                            )
                                          ).url
                                        }
                                        onClick={() =>
                                          localStorage.setItem(
                                            "filters",
                                            JSON.stringify(
                                              getLinkDataSheet(
                                                elem as AutomatedEmission,
                                                carbonStore.getSheetById(
                                                  (elem as AutomatedEmission)
                                                    .activity_sheet
                                                )
                                              ).filtersArray
                                            )
                                          )
                                        }
                                        target="_blank"
                                      >
                                        View Data Sheet
                                      </Link>
                                    </div>
                                  </>
                                }
                                className={styles.warningIcon}
                                overlayClassName={`popoverContent ${styles.warningPopover}`}
                                autoAdjustOverflow
                              >
                                <WarningOutlined />
                              </Popover>
                            </span>
                          )}
                        </span>
                      </div>
                      <Popover
                        placement="right"
                        title={"Consumption Amount is calculated automatically"}
                        content={
                          <>
                            <p>
                              Go to{" "}
                              <EllipsisOutlined
                                className={styles.infoIconOutlined}
                              />{" "}
                              then click ”Edit” to edit this emission
                              <br /> Go to{" "}
                              <EyeOutlined
                                className={styles.infoIconOutlined}
                              />{" "}
                              to view more details
                            </p>
                          </>
                        }
                        className={styles.infoIcon}
                        overlayClassName="popoverContent"
                        arrowPointAtCenter
                        autoAdjustOverflow
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </>
                );
              },
            },
            {
              title: "CO2e Factor",
              dataIndex: "co2e_emission_factor",
              key: "co2e_emission_factor",
              width: "10%",
              align: "center",

              render: (_: any, data: Emission): JSX.Element => {
                const co2 = data.factor_data?.constituent_gases?.co2
                  ? data?.factor_data.constituent_gases?.co2
                  : 0;
                const ch4 = data?.factor_data.constituent_gases?.ch4
                  ? data?.factor_data.constituent_gases?.ch4
                  : 0;
                const n2o = data?.factor_data.constituent_gases?.n2o
                  ? data?.factor_data.constituent_gases?.n2o
                  : 0;
                const unit = data?.factor_data.unit
                  ? data?.factor_data.unit
                  : "";
                const content = (
                  <div>
                    <p>
                      <b>
                        Co<sub>2</sub>
                      </b>
                      : {co2 ? co2 + " " + unit : "NA"}
                    </p>
                    <p>
                      <b>
                        Ch<sub>4</sub>
                      </b>
                      : {ch4 ? ch4 + " " + unit : "NA"}
                    </p>
                    <p>
                      <b>
                        {" "}
                        N<sub>2</sub>o
                      </b>
                      : {n2o ? n2o + " " + unit : "NA"}
                    </p>
                  </div>
                );
                return (
                  <div className={styles.itemCell}>
                    <span className={styles.itemSpan}>
                      {" "}
                      {data?.factor_data.factor
                        ? convertedNumber(data?.factor_data.factor)
                        : 0}{" "}
                    </span>
                    <Popover
                      placement="top"
                      title="Emission Factors"
                      content={content}
                      className={styles.infoIcon}
                      overlayClassName="popoverContent"
                      arrowPointAtCenter
                      autoAdjustOverflow
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </div>
                );
              },
            },

            {
              title: `Total Emission (${emissionsUnit} CO2e)`,
              dataIndex: "total_emission",
              key: "total_emission",
              width: "10%",
              align: "center",

              render: (record: Record, data: Emission): JSX.Element => {
                return (
                  <div className={styles.emissionColumn}>
                    {convertedNumber(data.emission! * conversionFactor)}
                    {attributionFactor && attributionFactor !== 100 && (
                      <Popover
                        placement="top"
                        className={styles.infoIcon}
                        content={
                          <Row>
                            <Col span={24}>
                              Attribution factor: {attributionFactor}%
                            </Col>
                            <Col span={24}>
                              Unattributed -{" "}
                              {(
                                data?.unattributed_emission! * conversionFactor
                              ).toFixed(
                                companyDetails.decimal_places_number
                              )}{" "}
                              {getEmissionsUnit(companyDetails.emissions_unit)}{" "}
                              CO2e
                            </Col>
                          </Row>
                        }
                        overlayClassName="popoverContent"
                      >
                        <InfoCircleOutlined className={styles.icon} />
                      </Popover>
                    )}
                  </div>
                );
              },
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              width: "10%",
              render: (_: any, data: Emission, index: number): JSX.Element => {
                return (
                  <CalculatorActions
                    data={data}
                    onConfirm={(reason, audit_state) =>
                      onConfirmItem(data?.key || "", reason, audit_state)
                    }
                  />
                );
              },
            },
          ]
        : [
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              width: "20%",
              sorter: (a: Offset, b: Offset) => a.name.localeCompare(b.name),
            },
            {
              title: "Region",
              dataIndex: "region",
              key: "region",
              width: "20%",
              sorter: (a: Offset, b: Offset) =>
                a.region.localeCompare(b.region),
            },
            {
              title: "Cost",
              dataIndex: "cost",
              key: "cost",
              width: "10%",
              sorter: (a: Offset, b: Offset) => a.cost - b.cost,
            },
            {
              title: "Proof of offset",
              dataIndex: "proof",
              key: "proof",
              width: "15%",
              align: "center",
              sorter: (a: Offset, b: Offset) => a.proof.localeCompare(b.proof),
              render: (data: string, offset: Offset) => {
                return offset.proofName ? (
                  <a
                    onClick={() => calculatorStore.downloadOffsetProof(offset)}
                  >
                    <VerticalAlignBottomOutlined />{" "}
                    <span className={styles.downloadFileText}>
                      Download File
                    </span>
                  </a>
                ) : (
                  <a href={data} rel="noopener noreferrer" target="_blank">
                    {data}
                  </a>
                );
              },
            },
            {
              title: `Offsetted amount (${emissionsUnit} CO2e)`,
              dataIndex: "emission",
              key: "emission",
              width: "15%",
              align: "center",
              sorter: (a: Offset, b: Offset) => a.emission - b.emission,
              render: (data: number) => {
                return data * conversionFactor;
              },
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              width: "10%",
              render: (_: any, data: Offset) => {
                return (
                  <CalculatorActions
                    data={data}
                    onConfirm={(reason, audit_state) =>
                      onConfirmItem(data?.key || "", reason, audit_state)
                    }
                    isOffset={true}
                  />
                );
              },
            },
          ];

    return (
      <>
        <CommonTable
          rowSelection={rowSelection}
          className={styles.calculationTable}
          loading={calculatorStore.loadingCalculationItems}
          rowClassName={(): string => "editable-row"}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          size="small"
        />
      </>
    );
  }
);

export default EditableCalculationAuditTable;
