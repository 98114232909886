import React, { useEffect } from "react";
import {
  Button,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  UploadFile,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react-lite";
import { useDynamicList, useSetState } from "ahooks";
import { carbonStore } from "../../../../Carbon/CarbonStore";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import styles from "./AddOffset.module.scss";
import { relativeTimeRounding } from "moment";

interface AddOffsetProps {
  visible: boolean;
  data: Offset;
  modalName: string;
  onCancel: () => void;
  onConfirm: (offset: Offset) => void;
}

export const AddOffset = observer(
  ({ visible, data, modalName, onCancel, onConfirm }: AddOffsetProps) => {
    const generateOffset = () => {
      return {
        ...data,
        key: data.key || uuidv4(),
        name: data.name || "",
        region: data.region || "",
        cost: data.cost,
        emission: data.emission,
        proof: data.proof || "",
        description: data.description || "",
        scope: ScopeName.OFFSETS,
      };
    };
    useEffect(() => {
      carbonStore.fetchRegions();
    }, []);

    const [offsetData, setOffsetData] = useSetState<Offset>(
      generateOffset() as Offset
    );

    useEffect(() => {
      setOffsetData(generateOffset() as Offset);
    }, [data]);

    const { list: files, resetList } = useDynamicList<UploadFile>();

    const handleChangeData = (data: Offset) => {
      setOffsetData(data);
    };

    const onRemoveFileFromListHandler = (file: UploadFile) => {
      const filesList = files.filter((v) => v !== file);
      resetList(filesList);
    };

    const handleConfirm = () => {
      const { uploadedProofId, ...restData } = offsetData;
      offsetData.uploadedProofId = data.uploadedProofId || uuidv4();

      if (files[0]) {
        onConfirm({
          ...offsetData,
          proofName: files[0].name,
          uploadedProofId: offsetData.uploadedProofId,
        });
        calculatorStore.uploadOffsetProof(
          files[0] as unknown as File,
          offsetData.uploadedProofId
        );
      } else if (data.uploadedProofId) {
        onConfirm({
          ...restData,
          uploadedProofId: data.uploadedProofId,
          proofName: data.proofName,
        });
      } else {
        onConfirm(offsetData);
      }
      setOffsetData({} as Offset);
      resetList([]);
    };

    const handleGoBack = () => {
      resetList([]);
      onCancel();
    };

    const handleCancel = () => {
      setOffsetData({} as Offset);
      onCancel();
    };

    const areFieldsFilled =
      offsetData.name && offsetData.cost != null && offsetData.emission != null;

    return (
      <CommonModal
        visible={visible}
        centered
        title={modalName}
        onCancel={handleCancel}
        footer={[
          <Button type="text" key="cancel" onClick={handleGoBack}>
            Go back
          </Button>,
          <Button
            type="primary"
            key="confirm"
            onClick={handleConfirm}
            disabled={!areFieldsFilled}
          >
            {modalName}
          </Button>,
        ]}
        width={"50%"}
      >
        <p className={styles.selectOffsetHeader}>
          Enter the information about offset/certificate
        </p>
        <FormWrapper>
          <Row justify="space-between">
            <Col span={11}>
              <Row className={styles.modalText}>Name</Row>
              <Row>
                <Input
                  className={styles.itemInput}
                  placeholder="Enter name"
                  value={offsetData.name}
                  onChange={(e) =>
                    handleChangeData({ ...offsetData, name: e.target.value })
                  }
                />
              </Row>
            </Col>
            <Col span={11}>
              <Row className={styles.modalText}>Region</Row>
              <Row>
                <Col span={24}>
                  <Select
                    placeholder="Select region"
                    showSearch
                    className={styles.itemSelector}
                    value={offsetData.region}
                    onChange={(value) =>
                      handleChangeData({ ...offsetData, region: value })
                    }
                  >
                    {carbonStore.regions.map((item) => {
                      const region = item.region_name;
                      return (
                        <Select.Option key={region} value={region}>
                          {region}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Row className={styles.modalText}>Cost</Row>
              <Row>
                <InputNumber
                  min={0}
                  className={styles.itemNumber}
                  placeholder="Enter cost"
                  value={offsetData.cost}
                  onChange={(value) =>
                    handleChangeData({ ...offsetData, cost: value || 0 })
                  }
                />
              </Row>
            </Col>
            <Col span={11}>
              <Row className={styles.modalText}>Emissions</Row>
              <Row>
                <InputNumber
                  min={0}
                  className={styles.itemNumber}
                  placeholder="Enter amount of emissions"
                  value={offsetData.emission}
                  onChange={(value) =>
                    handleChangeData({ ...offsetData, emission: value || 0 })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row justify="start">
            <Col span={24}>
              <Row className={styles.modalText}>Description</Row>
              <Row>
                <Input
                  className={styles.itemInput}
                  placeholder="Enter description"
                  value={offsetData.description}
                  onChange={(e) =>
                    handleChangeData({
                      ...offsetData,
                      description: e.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row className={styles.modalText}>
            Enter the link or attach the files confirming the purchase of the
            offset
          </Row>
          <Row justify="start">
            <Col span={24}>
              <Row className={styles.modalText}>Link</Row>
              <Row>
                <Input
                  className={styles.itemInput}
                  placeholder="Enter link"
                  value={offsetData.proof}
                  onChange={(e) =>
                    handleChangeData({
                      ...offsetData,
                      proof: e.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row className={styles.modalText}>And/Or</Row>

          <Upload
            accept=".XLSX, .XLS, .CSV,  .pdf, .JPEG, .PNG, .GIF, .TXT, .PPT, .PPTX"
            customRequest={({ file }) => {
              resetList([file as UploadFile]);
            }}
            fileList={files}
            multiple={false}
            onRemove={onRemoveFileFromListHandler}
          >
            <StyledButton
              icon={<UploadOutlined />}
              disabled={modalName === "Edit offset" && !!data.proofName}
            >
              Upload
            </StyledButton>
          </Upload>
          {data.proofName && <Row>{data.proofName}</Row>}
        </FormWrapper>
      </CommonModal>
    );
  }
);
