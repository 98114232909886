import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const updateApprovedData = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "audit_update_one",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};
