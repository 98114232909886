import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card, Spin, Space, Form, message } from "antd";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  FormTableWrap,
  ReportWrap,
  ReportContainer,
  HeaderLogoWrap,
  FooterLogoWrap,
} from "../styles";
import { API, Auth, Storage } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import logo from "../../../../components/hydrus_app_logo.png";
import ConfirmModal from "../../PoliciesPage/components/ComfirmModal";
import { EditableCell } from "./components/EditableCell";
import { SummaryRow } from "./components/SummaryRow";
import {
  generateDynamicColums,
  generateEditableCell,
  generateDynamicExcelTableRows,
  generateDynamicExcelTableData,
  getFormDataDetailsList,
  getFormDataDetails,
} from "./IndividualReportFunctions";
import moment from "moment";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  TabContentWrap,
  OverviewContentWrap,
  StyledButton,
  CommonTable,
} from "./../../../../shared/commonStyles";

const IndividualReport = ({
  report_period,
  location_id,
  business_unit_id,
  rowDataLakeMap,
  setShowIndivisualReport,
}) => {
  const [loadReportingData, setLoadingReportingData] = useState(false);
  const [reportDataForSingleTable, setReportDataForSingleTable] = useState([]);
  const [c_logo, setLogo] = useState(logo);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [editingRowData, setEditingRowData] = useState(null);
  const [refreshPage, setRefreshPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [confirmModalShowing, setConfirmModalShowing] = useState(false);

  const exportHtmlRef = useRef();

  const logoErrorHandle = () => {
    setLogo(logo);
  };

  const onClickEditBtn = (record) => {
    const initialValues = {};
    const data = record?.["data"];
    if (typeof data === "object") {
      for (const [key, value] of Object.entries(data)) {
        const inputType = value?.type;
        const inputFormat = value?.format;
        const inputValue = value?.value;
        if (inputType === "number") {
          initialValues[key] = inputValue;
        } else if (inputFormat === "date" && inputType === "string") {
          const date = moment(inputValue);
          initialValues[key] = date.isValid() ? date : "";
        } else {
          initialValues[key] = inputValue;
        }
      }

      const fields = {
        ...initialValues,
      };
      form.setFieldsValue(fields);
      setEditingKey(record.key);
    }
  };

  const onCancelHandler = () => {
    setEditingKey("");
  };

  const onClickUpdate = (record) => {
    setConfirmModalShowing(true);
    setEditingRowData(record);
  };

  const onConfirmSubmit = async ({ editReason }) => {
    try {
      const findRecord = reportDataForSingleTable?.find(
        (item) => item.data_id === editingRowData.key
      );
      if (!findRecord) {
        message.error("Something went wrong!");
        return;
      }

      const values = await form.validateFields();
      setSubmitting(true);
      const data = editingRowData?.["data"];
      const todayDate = moment().format(YearMonthDayFormat);
      const dateFields = {};
      if (typeof data === "object") {
        for (const [key, value] of Object.entries(data)) {
          const inputType = value?.type;
          const inputFormat = value?.format;
          if (inputFormat === "date" && inputType === "string") {
            const date = moment(values?.[key]);
            dateFields[key] = date.isValid()
              ? date.format(YearMonthDayFormat)
              : todayDate;
          }
        }
      }
      const updatedValues = { ...values, ...dateFields };

      const dataToSubmit = {
        data_id: findRecord.data_id,
        form_data: updatedValues,
        modify_reason: editReason,
        publish_status: findRecord.publish_status,
        status: findRecord.status,
      };
    } catch (error) {
      message.error("Error while saving data. Please contact support.");
    } finally {
      setSubmitting(false);
    }
  };

  const dataLoad = useCallback(async () => {
    setReportDataForSingleTable([]);
    setLoadingReportingData(true);

    setLoadingReportingData(false);
  }, [business_unit_id, location_id, report_period, rowDataLakeMap]);

  useEffect(() => {
    if (rowDataLakeMap) {
      dataLoad();
    }
  }, [rowDataLakeMap, dataLoad, refreshPage]);

  useEffect((_) => {
    const getFile = async () => {
      try {
        const data = await Auth.currentSession();
        const groups = data["accessToken"]["payload"]["cognito:groups"].filter(
          (element) => element.includes("org:")
        )[0];
        const a_logo = await Storage.get(
          `logos/${groups.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
        );
        setLogo(a_logo);
      } catch {}
    };
    getFile();
  }, []);

  //generate dynamic columns from data
  const dynamicColums = generateDynamicColums({
    data: reportDataForSingleTable,
    submitting,
    onClickEditBtn,
    onClickUpdate,
    onCancelHandler,
    editingKey,
  });

  const mergedColumns = generateEditableCell(dynamicColums, editingKey);

  // generate datalist format according to data columns.
  const dynamicFormDataList = getFormDataDetailsList(reportDataForSingleTable);
  //Dymanic colums for excel
  const dynamicTableHeadings = generateDynamicExcelTableRows(
    reportDataForSingleTable
  );
  const dynamicTableData = generateDynamicExcelTableData(
    reportDataForSingleTable
  );

  const onClickBackButton = () => {
    setShowIndivisualReport(false);
  };

  const onClickExportPdf = () => {
    console.log("export pdf button clicked");
  };

  return (
    <TabContentWrap>
      <OverviewContentWrap>
        <ConfirmModal
          visible={confirmModalShowing}
          onSubmit={(e) => onConfirmSubmit(e)}
          onCancel={() => setConfirmModalShowing(false)}
          submitting={submitting}
        />
        <Card
          title="Report"
          extra={
            <Space direction="horizontal" align="end">
              <ReactToPrint
                trigger={() => (
                  <StyledButton
                    type="custom"
                    onClick={onClickExportPdf}
                    disabled={loadReportingData}
                  >
                    Export Pdf{" "}
                  </StyledButton>
                )}
                content={() => exportHtmlRef.current}
              />
              {loadReportingData ? (
                <StyledButton type="custom" disabled={true}>
                  Export Excel
                </StyledButton>
              ) : (
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="custom-primary-button"
                  table="table-to-xls"
                  filename="Report"
                  sheet="Report"
                  buttonText="Export Excel"
                />
              )}
              <StyledButton type="custom" onClick={() => onClickBackButton()}>
                Back
              </StyledButton>
            </Space>
          }
        >
          <Spin spinning={loadReportingData}>
            <div className="hidden-table-wrap-excel">
              <table id="table-to-xls" style={{ display: "none" }}>
                <thead>
                  <tr>{dynamicTableHeadings}</tr>
                </thead>
                <tbody>{dynamicTableData}</tbody>
              </table>
            </div>
            <div
              ref={exportHtmlRef}
              style={{ backgroundColor: "#ffffff", height: "100%" }}
            >
              <ReportContainer>
                <ReportWrap>
                  <HeaderLogoWrap>
                    <img
                      src={c_logo}
                      alt="hydrus logo"
                      onError={() => logoErrorHandle()}
                    />
                  </HeaderLogoWrap>
                  {dynamicFormDataList?.length ? (
                    <Form form={form} component={false}>
                      <FormTableWrap>
                        <CommonTable
                          rowKey={"key"}
                          components={{
                            body: {
                              cell: EditableCell,
                            },
                          }}
                          columns={mergedColumns}
                          dataSource={dynamicFormDataList}
                          pagination={false}
                          scroll={{ x: true }}
                          summary={(pageData) => <SummaryRow data={pageData} />}
                        />
                      </FormTableWrap>
                    </Form>
                  ) : null}
                </ReportWrap>
                <FooterLogoWrap>
                  <img
                    src={c_logo}
                    alt="hydrus logo"
                    onError={() => logoErrorHandle()}
                  />
                </FooterLogoWrap>
              </ReportContainer>
            </div>
          </Spin>
        </Card>
      </OverviewContentWrap>
    </TabContentWrap>
  );
};

export default IndividualReport;
