import React, { useState, useEffect } from "react";
import { CreateTaskDeploymentModal } from "./Components/CreateTaskDeploymentModal";
import { StyledButton } from "../../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { useLocation } from "react-router-dom";
import {
  PlusCircleOutlined,
  ExportOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { getTaskDeploymentsBySurveyId } from "../../../services/taskDeployment";
import XLSX from "xlsx";
import RemindAllUsers from "../../../pages/deploySurvey/Components/RemindAllUsers";

export const CreateTaskDeployment = ({ surveyId }) => {
  const { checkPermissions } = useCheckRolesPermissions();
  const [showCreateTaskDeploymentModal, setShowCreateTaskDeploymentModal] =
    useState(false);
  const [taskDeployments, setTaskDeployments] = useState([]);
  const [remindUserModal, setRemindUserModal] = useState(false);
  const [emailType, setEmailType] = useState("deploy");
  const { state } = useLocation();

  const loadData = async () => {
    try {
      const response = await getTaskDeploymentsBySurveyId(surveyId);
      if (response?.items) {
        setTaskDeployments(response.items);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadData();
  }, [surveyId, showCreateTaskDeploymentModal]);

  const ExcelFields = {
    Email: "email",
    Priority: "priority",
    Status: "status",
    Description: "description",
    "Last Contacted": "last_contacted",
  };

  const generateExportReport = () => {
    try {
      const dataList = taskDeployments.map((item, index) => {
        const data = {};
        for (const [key, value] of Object.entries(item)) {
          for (const [k, v] of Object.entries(ExcelFields)) {
            if (key === v) {
              data[k] = value;
            }
          }
        }
        return data;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataList);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
    } catch (error) {
      console.log("Error while generating Excel Report!", error);
    }
  };

  return (
    <>
      {showCreateTaskDeploymentModal && (
        <CreateTaskDeploymentModal
          isVisible={showCreateTaskDeploymentModal}
          taskDeployments={taskDeployments}
          onClose={() => setShowCreateTaskDeploymentModal(false)}
        />
      )}
      {checkPermissions(["manage-collect-data"]) && (
        <div>
          <StyledButton
            type="custom"
            disabled={!taskDeployments?.length}
            onClick={() => generateExportReport()}
            style={{ display: "inline", borderRadius: "4px" }}
          >
            <ExportOutlined /> <span>Export Report</span>
          </StyledButton>
          <StyledButton
            type="custom"
            disabled={
              !taskDeployments?.length ||
              state.surveyStatus === "INACTIVE" ||
              !taskDeployments?.find((item) => item.status === "Not contacted")
            }
            onClick={() => {
              setRemindUserModal(true);
              setEmailType("deploy");
            }}
            style={{ display: "inline", borderRadius: "4px" }}
          >
            <SendOutlined />
            <span>Deploy Survey</span>
          </StyledButton>
          <StyledButton
            type="custom"
            disabled={
              !taskDeployments?.length ||
              state.surveyStatus === "INACTIVE" ||
              taskDeployments?.every((item) => item.status === "Not contacted")
            }
            onClick={() => {
              setRemindUserModal(true);
              setEmailType("reminder");
            }}
            style={{ display: "inline", borderRadius: "4px" }}
          >
            <SendOutlined />
            <span>Remind Users</span>
          </StyledButton>
          <StyledButton
            disabled={state.surveyStatus === "INACTIVE"}
            type="primary"
            onClick={() => setShowCreateTaskDeploymentModal(true)}
          >
            <PlusCircleOutlined /> <span>Create New Deployment</span>
          </StyledButton>
        </div>
      )}
      {remindUserModal && (
        <RemindAllUsers
          title="Confirmation"
          visible={remindUserModal}
          description={`Are you sure you want to send ${
            emailType === "reminder" ? "reminders" : "invites"
          } to all users who have not been contacted yet?`}
          okText={emailType === "reminder" ? "Remind Users" : "Deploy Survey"}
          onClose={() => setRemindUserModal(false)}
          surveyId={surveyId}
          setRemindUserModal={setRemindUserModal}
          emailType={emailType}
          state={state}
        />
      )}
    </>
  );
};
