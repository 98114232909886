import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined, CheckOutlined, PlusSquareOutlined, MinusSquareOutlined, UndoOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row, Space, Spin, message, Tag } from "antd";
import { CommonTable, StyledButton, TableStyledMenu } from "../../../shared/commonStyles";
import Assignees from "./generalGoalsDetails/Assignees";
import RelatedItems from "./generalGoalsDetails/RelatedItems";
import ProgressBar from "./sustainableGoalsDetails/ProgressBar";
import SubgoalCount from "./sustainableGoalsDetails/SubgoalCount";
import SubSdgGoalsTable from "./sustainableGoalsDetails/SubSdgGoalsTable";
import IndicatorModal from "./sustainableGoalsDetails/IndicatorModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import LongTermGoalModal from "../manageGoals/LongTermGoalModal";
import { updateGolasAndProgress } from "../../../services/goals";
import {  
  parentSdgGoals,
} from "../../../services/goals";
import {
  sdgGoalsStaticList
} from "../../../shared/constants";

const SustainableGoals = () => {
  const navigate = useNavigate();
  const renderStatusTag = (key) => {
    //const status = key ? key?.toLowerCase() : "";
    if (key === "COMPLETED") {
      return (
        <Tag color="#5BA85A" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "IN_PROGRESS") {
      return (
        <Tag color="#1890FF" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "DELETED") {
      return (
        <Tag color="#807C8B" className="tag-rounded">
          {key}
        </Tag>
      );
    }else {
      return (
        <Tag color="#FF0101" className="tag-rounded">
          {key}
        </Tag>
      );
    }
  };
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [refreshData, setRefreshData] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [status, setStatus] = useState();
  const [expended, setExpended] = useState();
  const [confirmLoader, setConfirmLoader] = useState(false);
  //const [type, setType] = useState("SDG_SHORT");
  const [action, setAction] = useState("create");
  const [showLongTermModal, setShowLongTermModal] = useState(false);
  const [showIndicatorModal, setShowIndicatorModal] = useState(false);
  const type = 'SDG_SHORT'
  const loadGoals = async () => {
    try {
      setLoader(true);
      const goals = await parentSdgGoals();
      const filteredGoals = goals?.filter(item => item.status !== 'DELETED')
      setDataSource(filteredGoals);
      
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
    
  useEffect(() => {
    loadGoals();
  }, [refreshData]);

  const expend = (index) => {
    if (expended === index) setExpended(undefined);
    else setExpended(index);
  };

  const columns = [
    {
      title: "SDG",
      dataIndex: "sdg_num",
      key: "sdg_num",
      width: "5%",
      render:(data) => {
        const sdgGoal = sdgGoalsStaticList.find(item => item.goal === data)
        return (sdgGoal ? <img src={sdgGoal.image_url} width="100%" /> : null)
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {       
        const messageColor = renderStatusTag(status);
        return <>{messageColor}</>;
      },
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "10%",
      render:(_,record) => {
        return <Assignees record={record} />
      }
    },
    {
      title: "Related Items",
      dataIndex: "relatedItems",
      width: "10%",
      render:(_,record) => {
        return <RelatedItems record={record} />
      }
    },
    {
      title: "Subgoals",
      dataIndex: "shortTermGoals",
      width: "10%",
      render: (_, record) => {
        return <SubgoalCount record={record} />
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      width: "10%",
      render:(_,record) => {
        return <ProgressBar record={record} />
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "30%",
      render: (_,record) => (
        <Space direction="horizontal">
          <StyledButton
            type="default"
            icon={
              record?.id === expended ? (
                <MinusSquareOutlined />
              ) : (
                <PlusSquareOutlined />
              )
            }
            onClick={() => expend(record.id)}
          >
            {record.id === expended ? "Hide Subgoals" : "View Subgoals"}
          </StyledButton>
          <Dropdown overlay={() => menu(record)} trigger={["click"]}>
            <StyledButton type="default" icon={<EllipsisOutlined />} />
          </Dropdown>
       
        </Space>
      ),
    },
  ];
  const menu = (record) => {
    return (
      <TableStyledMenu style={{ padding: "0" }}>
        <Menu.Item
          key={record.id + 1}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={record?.status === 'COMPLETED' ? <UndoOutlined /> : <CheckOutlined />}
            onClick={() => markAsCompleted(record)}
          >
            Mark as {record?.status === 'COMPLETED' ? 'In Progress' : 'Completed'}
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 2}
          onClick={() => addSubGoals(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<PlusCircleOutlined />}
          >
            Add Sub Goals
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 2}
          onClick={() => viewIndicators(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<PlusCircleOutlined />}
          >
            View Indicators
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 3}
          onClick={() => editGoals(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<EditOutlined />}
          >
            Edit
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 4}
          onClick={() => handleOnDelete(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<DeleteOutlined />}
          >
            Delete
          </StyledButton>
        </Menu.Item>
      </TableStyledMenu>
    )
  }

  const markAsCompleted = (data) => {
    setStatus(data.status === 'COMPLETED' ? 'IN_PROGRESS' : 'COMPLETED')
    setRowData(data);
    setShowConfirmModal(true)
  }

  const handleOnDelete = (data) => {
    setStatus('DELETED')
    setRowData(data);
    setShowConfirmModal(true)
  }

  const onConfirmSubmit = async() => {
    
    if(!rowData) return;
    try{
      setConfirmLoader(true);
      delete rowData.createdAt
      delete rowData.updatedAt
      const response = await updateGolasAndProgress({...rowData, status});
      if(response){
        message.success((status === 'COMPLETED' || status === 'IN_PROGRESS')  ? `Goal status changed to ${status === 'COMPLETED' ? 'completed' : 'in progress'} successfully` : 'Goal deleted successfully');
        setRefreshData(refreshData + 1);
        setRowData(null)
        setShowConfirmModal(false)
        
      }
    }
    catch(error){
      message.error(`error while confirmimg goal ${error}`);
    }finally{
      setConfirmLoader(false);
    }
  }

  const editGoals = (data) => {
    navigate(`/management/goals-and-progress/${data?.id}/update`);
  };

  const addSubGoals = (data) => {    
    setAction("create");
    setRowData(data);
    setShowLongTermModal(true);
  };

  const editSubSdgGoals = (data) => {    
    setAction("edit");
    setRowData(data);
    setShowLongTermModal(true);
  };

  const onCloseTermModal = () => {
    setShowLongTermModal(false);
    setRefreshData(refreshData + 1);
  };

  const viewIndicators = (data) => {
    setRowData(data);
    setShowIndicatorModal(true);
  };

  return ( 
    <Spin spinning={loader}>
      {showIndicatorModal && (
        <IndicatorModal
          visible={showIndicatorModal}          
          rowData={rowData}
          onClose={()=> {setShowIndicatorModal(false)}}
        />
      )}  
      {showLongTermModal && (
        <LongTermGoalModal
          visible={showLongTermModal}
          action={action}
          type={type}
          rowData={rowData}
          onClose={onCloseTermModal}
        />
      )}      
      {showConfirmModal && 
        <ConfirmModal
          title="Confirmation"
          description={(status === 'COMPLETED' || status === 'IN_PROGRESS') ? `Are you sure you want to mark it as ${status === 'COMPLETED' ? 'completed' : 'in progress'}?` : 'Are you sure want to delete the goal?'}
          visible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmSubmit}
          ConfirmLoader={confirmLoader}
        />
      }
      <Row gutter={[16, 16]}>        
        <Col span={24}>
          <CommonTable
            rowKey="id"
            rowClassName={(record) => {          
              return record?.status === 'DELETED' ? 'rowDenied' : ''
            }}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
            expandable={{
              expandedRowRender: (record) => <SubSdgGoalsTable goalData={record} markAsCompleted={markAsCompleted} editGoals={editSubSdgGoals} handleOnDelete={handleOnDelete} refreshData={refreshData} />,
              //rowExpandable: (record) => record.key % 2,
              expandIcon: () => <></>,
            }}
            expandedRowKeys={[expended]}
          />
        </Col>
      </Row>
    </Spin>
  )
}

export default SustainableGoals;
