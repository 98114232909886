import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Input } from "antd";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import { CalculatorData } from "../CalculationStepper";
import styles from "./Success.module.scss";
import { disallowedNameKeys } from "../../../Carbon/CalculationUtils";

interface SuccessProps {
  data: CalculatorData;
  goBack: () => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onSave: () => void;
}

const Success = ({
  data,
  goBack,
  onChangeName,
  onChangeDescription,
  onSave,
}: SuccessProps) => {
  const navigate = useNavigate();

  function handleChangeName(e: any) {
    onChangeName(e.target.value);
  }

  function handleChangeDescription(e: any) {
    onChangeDescription(e.target.value);
  }

  function handleSave() {
    onSave();
    navigate("/carbon/calculations");
  }

  return (
    <FormWrapper>
      <div className={styles.headerText}>
        Success!
        <br />
        You added all emissions!
        <br />
        Continue to see calculation results
      </div>
      <div className={styles.subHeader}>
        Name your table to save calculations
      </div>

      <div className={styles.inputBlock}>
        <div>Name</div>
        <Divider className={styles.divider} />
        <Input
          className={styles.inputSuccessStep}
          placeholder="Name your calculation"
          value={data.name}
          onChange={handleChangeName}
          onKeyDown={(e) => {
            if (disallowedNameKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </div>

      <div className={styles.inputBlock}>
        <div>Description</div>
        <Divider className={styles.divider} />
        <Input
          className={styles.inputSuccessStep}
          placeholder="Enter description"
          value={data.description}
          onChange={handleChangeDescription}
        />
      </div>
      <div className={styles.stepBtnsGroup}>
        <Button onClick={goBack} className={styles.whiteBtn} type="text">
          Back
        </Button>
        <StyledButton
          className={styles.continueBtn}
          type="primary"
          onClick={handleSave}
          disabled={!data.name.length}
        >
          Save & Continue
        </StyledButton>
      </div>
    </FormWrapper>
  );
};

export default Success;
