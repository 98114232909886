import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { Input, Row, message } from "antd";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  FileOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";

import * as queries from "../../../graphql/queries";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";

import {
  TabContentWrap,
  PageWrap,
  CommonTable,
  StyledButton,
  StyledTag,
  FormWrapper,
  FilterWrap,
  CommonModal,
} from "../../../shared/commonStyles";
import { createUser, fetchUsers } from "../../../services/users";
import UpdateUserModal from "./Components/UpdateUserModal";
import CreateUserModal from "./Components/CreateUserModal";
import { AddUsersImport } from "../../collectData/Components/AddUsersImport";
import {
  getDataSheets,
  listMetaDataRecords,
  makeQueryMetadata,
} from "../../../services/dataSheet";
import { addPermissions } from "../../../services/permissions";

const UserManagementPage = () => {
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [loadingRefDataSheetsData, setLoadingRefDataSheetsData] =
    useState(false);
  const [loaderImportUser, setLoaderImportUser] = useState(false);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [rowData, setRowData] = useState(null);
  const [importUserData, setImportUserData] = useState<any[]>([]);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showImportUserModal, setShowImportUserModal] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [refDataSheetsData, setRefDataSheetsData] = useState([]);
  const { checkRolesPermission } = useCheckRolesPermissions();
  const showAddUserBtn = checkRolesPermission(["role:admin"]);
  const [dataSheets, setDataSheets] = useState<any[]>([]);
  useEffect(() => {
    getRefDataSheets();
  }, []);

  useEffect(() => {
    const dataLoad = async () => {
      setLoadingUsersList(true);
      try {
        const { users: usersList } = await fetchUsers({
          parameters: "{}",
          users: [],
        });

        const modifiedUsersList = await Promise.all(
          usersList.map(async (user: any) => {
            const userGropsAndRoles = await getUserRolesAndGroupsByUsername(
              user.Username
            );
            const name = getDataRelatedAttribute(user.Attributes, "name");
            const email = getDataRelatedAttribute(user.Attributes, "email");
            return {
              ...user,
              groups: userGropsAndRoles.groups,
              roles: userGropsAndRoles.roles,
              name: name ? name["Value"] : "",
              email: email ? email["Value"] : "",
            };
          })
        );

        setUsersList(modifiedUsersList as any);
      } catch (error) {
        console.log("error while fetching users list", error);
      } finally {
        setLoadingUsersList(false);
      }
    };
    dataLoad();
  }, [refreshToggle]);

  const getUserRolesAndGroupsByUsername = async (username: string) => {
    try {
      const parameters = {
        username,
      };
      const parms = JSON.stringify(parameters);
      const response: any = await API.graphql({
        query: queries["manageUserAccess"],
        variables: { request_type: "list-groups-user", parameters: parms },
      });
      const manageUserAccess = JSON.parse(response["data"]["manageUserAccess"]);
      const responseGroups = manageUserAccess["Groups"];
      const groups = [];
      const roles = [];

      for (const element of responseGroups) {
        if (element.GroupName.includes("org:")) {
          groups.push(element.GroupName);
        }
        if (element.GroupName.includes("role:")) {
          roles.push(element.GroupName);
        }
      }
      // const groupsArray = responseGroups.filter(element => {
      //   if(element.GroupName.includes("org:")){
      //     groups.push(element.GroupName);
      //     return element;
      //   }
      //   return [];
      // });
      // const rolesArray = responseGroups.filter(element => {
      //   if(element.GroupName.includes("role:")){
      //     roles.push(element.GroupName);
      //     return element;
      //   }
      //   return [];
      // });

      return {
        groups,
        roles,
      };
    } catch (error) {
      console.log("error while get user roles and groups", error);

      return {
        groups: [],
        roles: [],
      };
    }
  };

  const getDataRelatedAttribute = (Attributes: any, key: string) => {
    try {
      return Attributes
        ? Attributes?.find((item: any) => item["Name"] === key && item["Value"])
        : null;
    } catch (error) {
      console.log("error while get attribute data in user management", error);
      return null;
    }
  };

  const onClickEditUserBtn = (record: any) => {
    setRowData(record);
    setShowUpdateUserModal(true);
  };

  const refreshUserList = () => {
    setRefreshToggle((prevState) => !prevState);
  };

  const filteredUserList = useMemo(() => {
    const trimmedSearchKeyword = searchKeyword?.toLowerCase()?.trim();

    if (!trimmedSearchKeyword) {
      return usersList;
    }

    return usersList.filter(
      (item: any) =>
        item.name?.toLowerCase().includes(trimmedSearchKeyword) ||
        item.email?.toLowerCase().includes(trimmedSearchKeyword)
    );
  }, [searchKeyword, usersList]);

  const getRefDataSheets = async () => {
    setLoadingRefDataSheetsData(true);
    try {
      const payload = {
        filter: { is_reference_data: true, archive: { $ne: true } },
      };
      getDataSheets(payload).then(async (result) => {
        setDataSheets(result);
        const treeData = [] as any;
        await Promise.all(
          result.map(async (element: DataSheet) => {
            const identifierField = element.sheet_schema.find(
              (v) => v.isIdentifier
            )?.entity_name;
            if (identifierField) {
              const payload = makeQueryMetadata({
                sheetId: element?._id?.$oid,
                pagination: { current: 1, pageSize: 1000 },
                filters: [
                  {
                    column: identifierField,
                    op: "isNotEmpty",
                    column_value: "",
                  },
                ],
              });
              const metadataRecords = await listMetaDataRecords(payload);
              const entities = metadataRecords.data
                ?.filter(
                  (v: MetaDataRecord) => typeof v[identifierField!] !== "object"
                )
                ?.map((item: any, index: number) => ({
                  label: item[item.sheet_name][identifierField!],
                  title: item[item.sheet_name][identifierField!],
                  parent: item.sheet_id,
                  value: item[item.sheet_name].id,
                  key: item[item.sheet_name].id,
                }));
              entities?.length &&
                treeData.push({
                  label: element.sheet_name,
                  title: element.sheet_name,
                  value: element?._id?.$oid,
                  key: element?._id?.$oid,
                  children: entities,
                });
            }
          })
        );

        setRefDataSheetsData(treeData);
        setLoadingRefDataSheetsData(false);
      });
    } catch (error) {
      console.log("error while getting reference datasheets!", error);
      setLoadingRefDataSheetsData(false);
    }
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "Username",
      width: "10%",
    },
    {
      title: "First and Last name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Group",
      dataIndex: "groups",
      width: "10%",
      // render : (records) =>{
      //   return records && records.toString();
      // }
      render: (records: any) => {
        return records?.length
          ? records.map((group: any, index: number) => (
              <StyledTag key={index} style={{ fontSize: 14 }}>
                {group}
              </StyledTag>
            ))
          : "";
      },
    },
    {
      title: "Current Roles",
      dataIndex: "roles",
      width: "10%",
      render: (records: any) => {
        return records?.length
          ? records.map((role: any) => (
              <StyledTag
                className="margin-2"
                key={role}
                style={{ fontSize: 14 }}
              >
                {role}
              </StyledTag>
            ))
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "Enabled",
      width: "10%",
      render: (record: any) => {
        return record ? (
          <StyledTag bgColor="green">Enabled</StyledTag>
        ) : (
          <StyledTag bgColor="red">Disabled</StyledTag>
        );
      },
    },
    {
      title: "User Status",
      dataIndex: "UserStatus",
      width: "10%",
      render: (record: any) => {
        return record;
      },
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "6%",
      render: (_: any, record: any) => (
        <StyledButton type="custom" onClick={() => onClickEditUserBtn(record)}>
          <EditOutlined />
        </StyledButton>
      ),
    },
  ];
  const handleOkImportUsers = async () => {
    importUserData.forEach(async (item) => {
      try {
        setLoaderImportUser(true);
        const parameters = {
          user_attributes: {
            name: item.name,
            email: item.email.replace(/ /g, ""),
          },
          role_groups: item.role.replace(/ /g, "").split(","),
        };

        const response = await createUser(parameters);

        if (response.data?.manageUserAccess.statusCode) {
          message.error(
            response.data?.manageUserAccess.body ?? "Error while adding user!"
          );
        } else {
          message.success(`Successfully added user (${item.name}).`);
        }

        const sheetId = dataSheets.find(
          (v) => v.sheet_name == item.ref_data_sheet
        )._id.$oid;
        const permissionsPayload = {
          permissionType: "REFERENCE_DATA",
          itemId: sheetId,
          assignedUsers: [response.data?.manageUserAccess],
          assignedItems: item.identifier.replace(/ /g, "").split(","),
        };

        addPermissions(permissionsPayload);
      } catch (error) {
        message.error("Error while adding user!");
        console.log("Error while adding user!", error);
      } finally {
        setRefreshToggle((prevState) => !prevState);
        setLoaderImportUser(false);
      }

      setShowImportUserModal(false);
    });
  };

  const handleRefreshUserList = () => {
    setRefreshToggle((prevState) => !prevState);
  };

  return (
    <>
      <PageWrap>
        <TabContentWrap>
          {showUpdateUserModal && (
            <UpdateUserModal
              visible
              onClose={() => setShowUpdateUserModal(false)}
              rowData={rowData}
              refDataSheets={refDataSheetsData}
              loadingRefDataSheetsData={loadingRefDataSheetsData}
              updateUserDataInList={setUsersList}
              onDeleteUser={handleRefreshUserList} // Pass the refresh function as a prop
            />
          )}
          {showCreateUserModal && (
            <CreateUserModal
              visible
              loadingRefDataSheetsData={loadingRefDataSheetsData}
              onClose={() => setShowCreateUserModal(false)}
              refresh={refreshUserList}
              refDataSheets={refDataSheetsData}
            />
          )}
          {showImportUserModal && (
            <CommonModal
              width={"615px"}
              closable={false}
              visible={showImportUserModal}
              style={{ padding: "10px" }}
              onOk={handleOkImportUsers}
              confirmLoading={loaderImportUser}
              onCancel={() => setShowImportUserModal(false)}
            >
              <AddUsersImport
                setEmails={() => {}}
                isManagement
                setImportUserData={setImportUserData}
              />
            </CommonModal>
          )}

          <FilterWrap>
            <FormWrapper>
              <Input
                placeholder="Search by name, email"
                prefix={<SearchOutlined />}
                size="large"
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
              />
            </FormWrapper>
            <Row justify="end">
              {
                <StyledButton
                  onClick={() => setShowImportUserModal(true)}
                  type="custom"
                >
                  <FileOutlined /> <span>Import Users</span>
                </StyledButton>
              }
              {/* {
                <StyledButton
                  onClick={() => setShowCreateUserModal(true)}
                  type="custom"
                >
                  <FileSearchOutlined /> <span>Assign user</span>
                </StyledButton>
              } */}
              {showAddUserBtn && (
                <StyledButton
                  onClick={() => setShowCreateUserModal(true)}
                  type="custom"
                >
                  <PlusOutlined /> <span>Add User</span>
                </StyledButton>
              )}
            </Row>
          </FilterWrap>

          <CommonTable
            loading={loadingUsersList}
            rowKey="Username"
            dataSource={filteredUserList}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
            }}
          />
        </TabContentWrap>
      </PageWrap>
    </>
  );
};

export default UserManagementPage;
