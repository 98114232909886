import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { DatalakeQuery } from "../../graphql/API";
import { datalake } from "../../graphql/queries";
import { makeAutoObservable, runInAction } from "mobx";
import { message } from "antd";

class CalculationGroupsStore {
  calculationGroups: CalculationGroup[];
  calculationGroup: CalculationGroup;
  loadingGroups: boolean;
  loadingGroup: boolean;
  dataRetry: boolean;

  constructor() {
    this.calculationGroup = {} as CalculationGroup;
    this.calculationGroups = [];
    this.loadingGroups = false;
    this.loadingGroup = false;
    this.dataRetry = false;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  fetchCalculationGroups() {
    this.loadingGroups = true;
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "list_calculation_groups",
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    )
      .then((data) => {
        const groupsList = JSON.parse(data.data?.datalake || "{}");
        runInAction(() => {
          this.calculationGroups = groupsList;
        });
      })
      .finally(() => (this.loadingGroups = false));
  }
  getCalculationGroup(id?: string) {
    this.loadingGroup = true;
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_calculation_group",
          request_payload: JSON.stringify({ _id: { $oid: id } }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    )
      .then((data) => {
        const group = JSON.parse(data.data?.datalake || "{}");
        runInAction(() => {
          this.calculationGroup = group;
        });
      })
      .finally(() => (this.loadingGroup = false));
  }

  addCalculationGroup(group: CalculationGroup) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "add_calculation_group",
          request_payload: JSON.stringify(group),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchCalculationGroups();
      });
    });
  }

  updateCalculationGroup(group: CalculationGroup) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "update_calculation_group",
          request_payload: JSON.stringify(group),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchCalculationGroups();
      });
    });
  }

  removeCalculationGroup(id: string) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "remove_calculation_group",
          request_payload: JSON.stringify({ _id: { $oid: id } }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchCalculationGroups();
      });
    });
  }

  duplicateCalculationGroup(id: string, callback: () => void) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "duplicate_calculation_group_new",
          request_payload: JSON.stringify({ _id: { $oid: id } }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchCalculationGroups();
        callback();
        message.success("Group was successfully duplicated");
      });
    });
  }
}

const calculationGroupsStore = new CalculationGroupsStore();

export { calculationGroupsStore };
