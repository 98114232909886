import React, { useState } from "react";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FormWrapper, StyledButton } from "./../shared/commonStyles";

const SearchableColumn = ({
  title,
  dataIndex,
  ellipsis,
  sorter,
  sortDirections,
  width,
  searchPlaceHolder,
  hasLink,
  linkSearchKey,
  ...rest
}) => {
  const [searchText, setSearchText] = useState("");

  const onSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const onSearchFilter = (value, record) =>
    record[linkSearchKey ?? dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase());

  const onReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  /* if `hasLink` is set to true, each row should contain a link object consist of {link: ..., description: ...} */
  const searchColumnRender = (obj) =>
    hasLink ? (
      <a href={obj.link} target="_blank" rel="noopener noreferrer">
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={obj.description}
        />
      </a>
    ) : (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={obj ?? ""}
      />
    );

  return {
    title: title,
    dataIndex: dataIndex,
    ellipsis: ellipsis,
    sorter: sorter,
    sortDirections: sortDirections,
    width: width,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FormWrapper style={{ padding: 8 }}>
        <Input
          id="searchInput"
          placeholder={searchPlaceHolder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={(e) => {
            e.stopPropagation();
            onSearch(selectedKeys, confirm);
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <StyledButton
            type="default"
            onClick={() => onReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </StyledButton>
          <StyledButton
            type="custom"
            onClick={() => onSearch(selectedKeys, confirm)}
            size="small"
            style={{ width: 90, justifyContent: "center" }}
          >
            OK
          </StyledButton>
        </Space>
      </FormWrapper>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: onSearchFilter,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById("searchInput").focus(), 100);
      }
    },
    render: searchColumnRender,
    ...rest,
  };
};

export default SearchableColumn;
