import React, { useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Form, Input, Row, Typography } from "antd";
import { AvatarUsername } from "../Avatar/AvatarUsername";
import {
  FormWrapper,
  StyledTag,
  TransparentCustomTable,
} from "../../shared/commonStyles";
import styles from "./AssignUserAndGroups.module.scss";

interface AssignUserAndGroupsProps {
  usersList: UserInfo[];
  groupsList: UserGroupInfo[];
  emails: string[];
  setEmails: (data: string[]) => void;
  handleChangeSelectionUser: (data: UserInfo, isAssigned: boolean) => void;
  handleChangeSelectionGroup: (
    data: UserGroupInfo,
    isAssigned: boolean
  ) => void;
}

interface UserGroupInfo extends UserGroup {
  assigned?: boolean;
  isGroup?: boolean;
}

interface UserInfo extends User {
  profileImage?: string;
  assigned?: boolean;
}

export const AssignUserAndGroups = ({
  usersList,
  groupsList,
  emails,
  setEmails,
  handleChangeSelectionUser,
  handleChangeSelectionGroup,
}: AssignUserAndGroupsProps) => {
  const [form] = Form.useForm();
  const [searchName, setSearchName] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const tableUsers = usersList
    .filter((item) =>
      searchName
        ? item.label.toLowerCase().includes(searchName.toLowerCase())
        : item
    )
    .sort((item) => (item.assigned ? -1 : 1));
  const filteredGroups = groupsList
    .filter((item) =>
      searchGroup
        ? item.name.toLowerCase().includes(searchGroup.toLowerCase())
        : item
    )
    .sort((item) => (item.assigned ? -1 : 1));

  const renderGroupAvatar = (group: UserGroupInfo) => {
    return (
      <Row gutter={[8, 8]} justify="start" align="middle">
        <Col>
          <AvatarUsername
            size={"35"}
            key={group._id.$oid}
            username={group.name}
          />
        </Col>
        <Col>{group.name}</Col>
      </Row>
    );
  };

  const renderUserAvatar = (user: UserInfo) => {
    return (
      <Row gutter={[8, 8]} justify="start" align="middle">
        <Col>
          <Avatar
            src={user.profileImage}
            icon={<UserOutlined style={{ color: "#fff" }} />}
          />
        </Col>
        <Col>{user.name}</Col>
      </Row>
    );
  };

  const columns = [
    {
      title: "user",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
      render: (_: any, data: UserInfo | UserGroupInfo) => {
        return (data as UserGroupInfo).isGroup
          ? renderGroupAvatar(data as UserGroupInfo)
          : renderUserAvatar(data as UserInfo);
      },
    },
    {
      title: "action",
      dataIndex: "key",
      key: "key",
      align: "right",
      render: (_: any, data: UserInfo | UserGroupInfo) => {
        return (
          <Button
            ghost
            className={
              data.assigned ? styles.selectedButton : styles.ghostButton
            }
            onClick={() => {
              setSearchGroup("");
              setSearchName("");
              (data as UserGroupInfo)?.isGroup
                ? handleChangeSelectionGroup(
                    data as UserGroupInfo,
                    !data.assigned
                  )
                : handleChangeSelectionUser(data as UserInfo, !data.assigned);
            }}
          >
            {data.assigned ? (
              <span>
                <CheckOutlined /> Selected
              </span>
            ) : (
              "Select"
            )}
          </Button>
        );
      },
    },
  ];

  const handleRemoveEmail = (email: string) => {
    const newEmails = emails.filter((item) => item !== email);
    setEmails(newEmails);
  };

  const handleChangeFormValue = (data: { email: string }) => {
    setEmails([...emails, data.email]);
    form.setFieldsValue({ email: "" });
  };

  return (
    <FormWrapper>
      <Row style={{ marginBottom: 10 }}>Users</Row>
      <Row>
        <Col span={24}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search for user"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Col>
      </Row>
      <TransparentCustomTable
        columns={columns}
        pagination={false}
        dataSource={tableUsers}
        loading={!tableUsers.length}
        showHeader={false}
        className={styles.userTable}
        scroll={{ x: false, y: "15vh" }}
        size="small"
      />
      <Row>
        <Col span={24}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search for groups"
            value={searchGroup}
            onChange={(e) => setSearchGroup(e.target.value)}
          />
        </Col>
      </Row>
      <TransparentCustomTable
        columns={columns}
        pagination={false}
        dataSource={filteredGroups}
        loading={!filteredGroups.length}
        showHeader={false}
        className={styles.userTable}
        scroll={{ x: false, y: "15vh" }}
        size="small"
      />
      <Typography.Text>
        Need to assign external user? Type the email below
      </Typography.Text>
      <Form
        form={form}
        initialValues={{ email: "" }}
        onFinish={handleChangeFormValue}
      >
        <Row align="top" justify="space-between">
          <Col span={21}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>
          <Col>
            <Button ghost className={styles.ghostButton} htmlType="submit">
              Add
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {!!emails.length &&
          emails.map((email, index) => (
            <StyledTag className={styles.selectedEmail} key={index}>
              <span>{email}</span>
              <CloseOutlined onClick={() => handleRemoveEmail(email)} />
            </StyledTag>
          ))}
      </Row>
    </FormWrapper>
  );
};
