import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getDocumentReview = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_document_review",
    },
  });

  return JSON.parse(response.data.datalake);
};

export const addDocumentReview = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const payload = {...requestPayload,'group':group,'owner':username}
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_document_review",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};
export const updateDocumentReview = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const payload = {...requestPayload,'group':group,'owner':username}
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_document_review",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const removeDocumentReview = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_document_review",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const getDocumentByid = async (docID) => {
  const allDocuments = await getDocumentReview();
  return allDocuments?.find((item) => item._id.$oid === docID);
};
