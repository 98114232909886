import React from "react";
import { PageWrap } from "../../shared/commonStyles";
import AuditDataTable from "./components/AuditDataTable";

const ManageAudits = ({ isDeleted }) => {
  return (
    <PageWrap>
      <AuditDataTable isDeleted={isDeleted} />
    </PageWrap>
  );
};

export default ManageAudits;
