import React, { useEffect, useState } from 'react';
import { message, Spin, Steps } from 'antd';
import { StepsWithStyles } from '../../../shared/commonStyles';
import { NewReportWrap } from './styles';
import { BasicInformation } from './BasicInformation';
import { Impact } from './Impact';
import { Goals } from './Goals';
import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../../../graphql/queries';

const { Step } = Steps;

const steps = [
  {
    title: 'Basic information',
    content: (props) => <BasicInformation {...props} />,
    subtitle: null,
  },
  {
    title: 'Goals',
    content: (props) => <Goals {...props} />,
    subtitle: 'Select all SDGs this company adheres to',
  },
  {
    title: 'Impact',
    content: (props) => <Impact {...props} />,
    subtitle: 'Explain how the company adheres to each goal (max. 340 characters each)',
  },
];

const initVal = {
  website_domain: '',
  company_name: '',
  tagline: '',
  description: '',
  ceo_name: '',
  location: '',
  industry: '',
  goals: [],
  logo: null,
};

export const NewCompany = ({ afterAdding, initValue }) => {
  const [current, setCurrent] = useState(0);
  const [company, setCompany] = useState(initVal);
  const [loading, setLoading] = useState(false);
  const isEdit = !!initValue;
  const handleStep = (step) => {
    setCurrent(step);
  };

  const onAddCompanyDetails = async (company) => {
    try {
      const data = await Auth.currentSession();
      const group = data['accessToken']['payload']['cognito:groups'].filter((element) =>
        element.includes('org:'),
      )[0];
      const username = data['accessToken']['payload']['username'];
      const payloadData = {
        ...initVal,
        ...company,
        group_id: group,
        username: username,
        created_date: moment().format(),
        updated_date: moment().format(),
      };
      const response = await API.graphql({
        query: queries['datalake'],
        variables: {
          request_type: 'add_portfolio_companies',
          request_payload: JSON.stringify(payloadData),
        },
      });
      const responseData = JSON.parse(response['data']['datalake']);
      const oid = responseData?.['$oid'];
      if (oid) {
        message.success('Data saved succcessfully!');
        afterAdding();
      }
    } catch (error) {
      console.log('Error while saving data!', error);
      console.log('Error while saving data!');
      message.error('Error while saving data!');
    }
  };

  const onUpdateCompanyDetails = async (company) => {
    try {
      const data = await Auth.currentSession();
      const group = data['accessToken']['payload']['cognito:groups'].filter((element) =>
        element.includes('org:'),
      )[0];
      const username = data['accessToken']['payload']['username'];

      const payloadData = {
        ...initVal,
        ...company,
        group_id: group,
        username: username,
        updated_date: moment().format(),
      };
      const response = await API.graphql({
        query: queries['datalake'],
        variables: {
          request_type: 'update_portfolio_companies',
          request_payload: JSON.stringify(payloadData),
        },
      });
      const responseData = JSON.parse(response['data']['datalake']);
      if (responseData === 1) {
        message.success('Data updated succcessfully!');
        afterAdding();
      }
    } catch (error) {
      console.log('Error while updating data!', error);
      console.log('Error while updating data!');
      message.error('Error while updating data!');
    }
  };

  const companySave = async (values) => {
    try {
      if (current !== 2) {
        setCompany((prevState) => {
          return {
            ...prevState,
            ...values,
          };
        });
        handleStep((prevState) => prevState + 1);
      } else {
        const sand = isEdit
          ? await onUpdateCompanyDetails({ ...company, ...values })
          : await onAddCompanyDetails({ ...company, ...values });
        await afterAdding();
        setCompany(initVal);
        setCurrent(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setCompany(initValue);
  }, [initValue]);

  const { content, subtitle } = steps[current];

  return (
    <NewReportWrap>
      <h1>{isEdit ? 'Edit Portfolio Company' : 'Create a report'}</h1>
      <Spin spinning={loading}>
        <StepsWithStyles current={current}>
          {steps.map((item) => (
            <Step key={`step_${item.title}`} title={item.title} />
          ))}
        </StepsWithStyles>
        <div className="steps-content">
          {subtitle && <div className="sub-title">{subtitle}</div>}
          {content({ handleStep, companySave, company, setLoading })}
        </div>
      </Spin>
    </NewReportWrap>
  );
};
