import { Avatar, Col, message, Row, Spin, Typography } from "antd";
import { GroupOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { AvatarUsername } from "../../../../../components/Avatar/AvatarUsername";
import { COLORS } from "../../../../../shared/colors";
import { getUserGroups } from "../../../../../services/users";
import { getFirstAndLastNameByUsername } from "../../../../../shared/commonFunctions";
import { StyledButton } from "../../../../../shared/commonStyles";
const { Title } = Typography;
const Assignees = ({ rowData, type }) => {
  const [usersGroupList, setUsersGroupList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [groupLoader, setGroupLoader] = useState(false);
  const loadUsersGroupData = async () => {
    try {
      setGroupLoader(true);
      const userGroups =
        type === "auditor"
          ? rowData?.auditors?.groupIds
          : rowData?.editors?.groupIds;
      if (userGroups?.length > 0) {
        const payload = {
          filter: { _id: { $oid: userGroups } },
        };
        const groups = await getUserGroups(payload);
        const data = groups.map((item) => {
          return { name: item.name || "" };
        });
        setUsersGroupList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setGroupLoader(false);
    }
  };

  const userData = async () => {
    try {
      setLoader(true);

      const assignedUsers =
        type === "auditor"
          ? rowData?.auditors?.userIds
          : rowData?.editors?.userIds;
      if (assignedUsers?.length > 0) {
        const data = await Promise.all(
          assignedUsers?.map(async (item) => {
            const usersList = await getFirstAndLastNameByUsername(item);
            return {
              name: usersList ? usersList : "",
            };
          })
        );
        setUsersList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadUsersGroupData();
    userData();
  }, [rowData]);

  return (
    <>
      <Spin spinning={loader || groupLoader}>
        <div style={{ marginTop: "5px", marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title className="color-white" level={5}>
              {type === "auditor" ? "Auditors" : "Editors"}
            </Title>
            <StyledButton
              bgcolor={COLORS.martinique}
              bordercolor={COLORS.white}
              type="custom"
              style={{ padding: "5px 10px" }}
            >
              <span>Edit List</span>
            </StyledButton>
          </div>
          {usersList.length > 0 &&
            usersList?.map((item, index) => {
              return (
                <Row style={{ marginBottom: "13px" }} key={index}>
                  <Col>
                    <AvatarUsername
                      size="medium"
                      username={item?.name}
                      customTooltipTitle={item?.name}
                    />{" "}
                    {item.name}
                  </Col>
                </Row>
              );
            })}
          {usersGroupList?.map((item, index) => {
            return (
              <Row style={{ marginBottom: "13px" }} key={index}>
                <Col>
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                    }}
                    icon={<GroupOutlined />}
                  />{" "}
                  {item?.name}
                </Col>
              </Row>
            );
          })}
        </div>
      </Spin>
    </>
  );
};

export default Assignees;
