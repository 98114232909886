import React, { useEffect, useState } from "react";
import {  
  Select,
  message,
  Spin,
  Form
} from "antd";

import { getUsersList } from "../../../services/users";

import { capitalizeFirstLetter } from "../../../shared/constants";

const UsersList = () => {
  const [loader, setLoader] = useState(false);  
  const [usersList, setUsersList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [userLoader, setUserLoader] = useState(false); 
  

  const loadData = async () => {
    try {
      setLoader(true);
      const usersList = await getUsersList();
      if (usersList) {
        setUsersList(usersList);
        setSelectedUsersList(usersList)
      }
      
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const searchUsers = (value) => {
    setUserLoader(true)
    if(value){
      const selectedUsers = usersList?.filter(item => {
        return item?.label?.includes(value) || item?.label?.includes(capitalizeFirstLetter(value))
      })
      setSelectedUsersList(selectedUsers ? selectedUsers : [])
    }else{
      setSelectedUsersList(usersList)
    }
    setUserLoader(false)
  }
  return (    
      <Spin spinning={loader}> 
        <Form.Item
          labelCol={{ span: 24 }}
          label="Assign a user"
          name="userIds"
        >       
          <Select  
            mode="multiple"
            placeholder="Please select a user"
            notFoundContent={userLoader ? <Spin size="small" /> : null}
            filterOption={false}
            showArrow
            style={{ width: "100%" }}
            onSearch={searchUsers}
            options={selectedUsersList}
          />
        </Form.Item>
      </Spin>
            
  );
};

export default UsersList;
