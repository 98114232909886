import React, { useState, useEffect } from "react";
import {
  Divider,
  Space,
  Typography,
  Col,
  Row,
  Steps,
  message,
  Spin,
} from "antd";
import {
  StyledButton,
  FormWrapper,
  StyledSteps,
  IconWrap,
  CommonModal,
} from "../../../shared/commonStyles";
import { DataSheetMultiLineForm } from "./components/DataSheetMultiLineForm";
import { DataSheetSingleForm } from "./components/DataSheetSingleForm";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { getSheetById } from "./../../../services/dataSheet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
const { Title } = Typography;
const { Step } = Steps;

const DataSheetFormViewModal = ({
  visible,
  rowData,
  onClose,
  isShowSteps = false,
  sortedDataSurveyList = [],
  setRowDataView = null,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dataSheet, setDataSheet] = useState();
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  if (!rowData) return <></>;

  const loadDataSheet = async (dataSheetid) => {
    setLoadingDataSheet(true);
    try {
      const _dataSheet = await getSheetById(dataSheetid);
      if (_dataSheet) {
        setDataSheet(_dataSheet);
      }
    } catch (error) {
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };

  useEffect(() => {
    if (rowData && rowData.type !== "welcome_page") {
      loadDataSheet(rowData?._id?.["$oid"]);
    }
  }, [rowData]);
  const onStepChange = (currentStep, state) => {
    const pretarget = document.getElementById(`step${currentStep - 1}`);
    const nexttarget = document.getElementById(`step${currentStep + 1}`);
    switch (state) {
      case "previous":
        if (currentStep === 0) {
          return;
        }
        pretarget && pretarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep - 1);
        setRowDataView(sortedDataSurveyList[currentStep - 1]);
        break;
      case "next":
        if (currentStep === sortedDataSurveyList?.length - 1) {
          return;
        }
        nexttarget && nexttarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep + 1);
        setRowDataView(sortedDataSurveyList[currentStep + 1]);
        break;
      default:
        break;
    }
  };

  return (
    <CommonModal
      title={dataSheet?.sheet_name}
      visible={visible}
      onCancel={onClose}
      width="50%"
      maskClosable={false}
      destroyOnClose
      footer={null}
    >
      {isShowSteps && (
        <Row style={{ padding: 10, backgroundColor: "#362E4B" }}>
          <Col span={16}>
            <div
              style={{ overflowX: "scroll", padding: 10 }}
              className="custom-scroll"
              id="scrollable-steps"
            >
              <StyledSteps
                onChange={(value) => {
                  setRowDataView(sortedDataSurveyList[value]);
                  setCurrentStep(value);
                }}
                current={currentStep}
              >
                {sortedDataSurveyList.map((data, index) => {
                  return (
                    <Step
                      id={"step" + index}
                      className="stepper-wrapper"
                      key={index}
                      title={<span>Step {index + 1}</span>}
                    ></Step>
                  );
                })}
              </StyledSteps>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "15px 10px",
              }}
            >
              <IconWrap onClick={() => onStepChange(currentStep, "previous")}>
                <LeftCircleOutlined
                  style={{
                    fontSize: 20,
                    color: "white",
                    marginRight: 10,
                  }}
                />
                <span className="color-white">Back</span>
              </IconWrap>

              <IconWrap onClick={() => onStepChange(currentStep, "next")}>
                <RightCircleOutlined
                  style={{
                    fontSize: 20,
                    color: "white",
                    marginRight: 10,
                  }}
                />
                <span className="color-white">Next</span>
              </IconWrap>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          span={16}
          style={{ padding: 10, backgroundColor: "#3C3453" }}
          className="preview-left-part"
        >
          <Title className="color-white" level={3}>
            Data <span className="color-grey">(View Only)</span>
          </Title>
          {rowData.type === "welcome_page" ? (
            <>
              <Title className="color-white" level={4}>
                {rowData?.subject}
              </Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
              />
              <FormWrapper>
                <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rowData.body,
                    }}
                  ></div>
                </Row>
              </FormWrapper>
            </>
          ) : (
            <Spin spinning={loadingDataSheet}>
              <Title className="color-white" level={4}>
                {dataSheet?.sheet_name}
              </Title>
              <Divider
                style={{ backgroundColor: "#4F4669", marginTop: "15px" }}
              />
              <FormWrapper>
                {rowData.multiline_form_flag ? (
                  <DataSheetMultiLineForm
                    dataSheet={dataSheet}
                    rowData={rowData}
                  />
                ) : (
                  <DataSheetSingleForm
                    dataSheet={dataSheet}
                    rowData={rowData}
                  />
                )}
              </FormWrapper>
            </Spin>
          )}
        </Col>
        <Col span={8} className="preview-right-part">
          <Title className="color-white " level={4}>
            Information
          </Title>
          <Space direction="vertical">
            <div
              dangerouslySetInnerHTML={{ __html: dataSheet?.instructions }}
            ></div>
          </Space>
          <div style={{ position: "absolute", bottom: 10 }}>
            <StyledButton key="1" type="custom" onClick={() => onClose()}>
              Close Preview
            </StyledButton>
          </div>
        </Col>
      </Row>
    </CommonModal>
  );
};

export default DataSheetFormViewModal;
