import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { RightOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Divider, Form, Select } from "antd";
import { DataSheetDocuments } from "./DataSheetDocuments";
import { ConditionalDataSheetFilters } from "../../../components/ConditionalDataSheetFilters/ConditionalDataSheetFilters";
const { Title } = Typography;

const Step1 = ({
  urlSheetId,
  dataSheetsList,
  loadDataSheets,
  initialValues,
  dataSheet,
  loadingDataSheet,
  onClickNext,
  handleOnChange,
  onChangeFilters,
  step,
}) => {
  const navigate = useNavigate();
  return (
    <FormWrap width={initialValues.sheetId ? "100%" : "40%"}>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col
            span={initialValues.sheetId ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Title level={3}>Data Filtering</Title>
              <p className="color-grey">
                Select the data sheet and filter the data you need for the KPI
              </p>
              <Title level={5}>Select Data Sheet</Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
              />

              <Row justify="space-between">
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="sheetId"
                    label="Data Sheet"
                    rules={[
                      { required: true, message: "Please select data sheet!" },
                    ]}
                  >
                    <Select
                      loading={loadDataSheets}
                      onChange={handleOnChange}
                      placeholder="Select data Sheet"
                      disabled={urlSheetId}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={dataSheetsList?.map((sheet) => ({
                        value: sheet?._id?.$oid,
                        label: sheet?.sheet_name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                {initialValues.sheetId && (
                  <Col span={24}>
                    <Title level={5}>
                      Filter Data <span className="color-grey">(optional)</span>
                    </Title>
                    <Divider
                      style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                    />
                    <ConditionalDataSheetFilters
                      dataSheet={dataSheet}
                      initialFilters={initialValues.conditional_filters}
                      onChangeFilters={onChangeFilters}
                      addButtonText="Add datasheet filter"
                    />
                  </Col>
                )}
              </Row>
            </div>
            <div>
              <Col span={24}>
                <Form.Item>
                  <StyledButton
                    onClick={() => navigate(-1)}
                    type="default"
                    ghost
                  >
                    <span>Cancel</span>
                  </StyledButton>
                  <StyledButton
                    onClick={onClickNext}
                    type="custom"
                    disabled={loadingDataSheet}
                  >
                    <span>Continue to step 2</span> <RightOutlined />
                  </StyledButton>
                </Form.Item>
              </Col>
            </div>
          </Col>
          {initialValues?.sheetId && (
            <Col span={12}>
              <DataSheetDocuments
                loadingDataSheet={loadingDataSheet}
                dataSheet={dataSheet}
                initialValues={initialValues}
                step={step}
              />
            </Col>
          )}
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step1;
