import React from "react";
import { Button, Col, Drawer, Row } from "antd";
import styles from "./ReasonDrawer.module.scss";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { downloadDataSheetFileHandler } from "../../../../../shared/commonFunctions";

interface ReasonDrawerProps {
  visible: boolean;
  data: string;
  onClose: () => void;
  reasons: any[];
}

const ReasonDrawer = ({ visible, onClose, reasons }: ReasonDrawerProps) => {
  const handleDownload = async (file: any, reasonId: string) => {
    await downloadDataSheetFileHandler(file, reasonId);
  };
  return (
    <>
      <Drawer
        width={400}
        className={styles.drawer}
        placement="right"
        visible={visible}
        onClose={onClose}
        title="Reasons"
      >
        <div>
          <div className={styles.drawerTitle}>Reasons</div>
          {reasons?.map((reason, index) => (
            <Col key={index} className={styles.drawerItem}>
              <Row justify="space-between">
                <Col className={styles.drawerItemTitleDescription}>
                  {reason.user_full_name || reason.user_name}
                </Col>
                <Col className={styles.drawerItemTitleDescription}>
                  {reason.timestamp}
                </Col>
              </Row>
              <Row>
                <Col className={styles.drawerItemText}>{reason.comments}</Col>
              </Row>

              {reason?.files?.map((item: string, index: number) => (
                <Row key={index}>
                  <Col className={styles.drawerItemText}>
                    <Button
                      type="text"
                      onClick={() => handleDownload(item, reason.key)}
                      icon={<VerticalAlignBottomOutlined />}
                    >
                      <span className={styles.downloadFileText}>{item}</span>
                    </Button>
                  </Col>
                </Row>
              ))}
            </Col>
          ))}
        </div>
        <div className={styles.drawerFooter}>
          <Button type="text" onClick={onClose}>
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default ReasonDrawer;
