import React, { useEffect, useState } from "react";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import { Col, InputNumber, Row, Select } from "antd";
import { observer } from "mobx-react-lite";
import { unitsManagementStore } from "../../UnitsManagementStore";
import styles from "../AddUnitModal.module.scss";

interface AddUnitConversionModalProps {
  visible: boolean;
  onClose: () => void;
  data?: UnitConversion;
}

export const AddUnitConversionModal = observer(
  ({ visible, onClose, data }: AddUnitConversionModalProps) => {
    const [conversionData, setConversionData] = useState<UnitConversion>(
      data || ({} as UnitConversion)
    );

    useEffect(() => {
      unitsManagementStore.fetchUnitGroups();
    }, []);

    const handleChangeUnit = (value: string, unitType: string) => {
      const unit = unitsManagementStore.unitGroups
        .map((item) => item.units)
        .flat()
        .find((v) => v.key === value);

      console.log("unitName", unit);
      setConversionData({
        ...conversionData,
        [`${unitType}_unit`]: unit?.name,
        [`${unitType}_symbol`]: unit?.symbol,
      });
    };

    const handleCreateConversion = () => {
      //TODO: save conversion to db
      console.log("conversionData", conversionData);
    };
    return (
      <CommonModal
        visible={visible}
        onCancel={onClose}
        title="Add new unit conversion"
        okText="Add unit conversion"
        onOk={handleCreateConversion}
      >
        <FormWrapper>
          <Row justify="space-between">
            <Col className={styles.fieldBox} span={12}>
              <Row className={styles.fieldLabel}>Unit 1</Row>
              <Row>
                <Select
                  className={styles.fieldSelect}
                  placeholder="Select unit"
                  value={conversionData.base_unit}
                  onChange={(value) => handleChangeUnit(value, "base")}
                >
                  {unitsManagementStore.unitGroups.map((item) => (
                    <Select.OptGroup key={item.name} value={item.name}>
                      {item.units.map((v) => (
                        <Select.Option key={v.key} value={v.key}>
                          {v.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Row>
            </Col>
            <Col className={styles.fieldBox} span={12}>
              <Row className={styles.fieldLabel}>Unit 2</Row>
              <Row>
                <Select
                  className={styles.fieldSelect}
                  placeholder="Select unit"
                  value={conversionData.target_unit}
                  onChange={(value) => handleChangeUnit(value, "target")}
                >
                  {unitsManagementStore.unitGroups.map((item) => (
                    <Select.OptGroup key={item.name} value={item.name}>
                      {item.units.map((v) => (
                        <Select.Option key={v.key} value={v.key}>
                          {v.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className={styles.fieldBox} span={24}>
              <Row className={styles.fieldLabel}>Conversion factor</Row>
              <Row>
                <InputNumber
                  placeholder="Enter factor"
                  value={conversionData.conversion_factor}
                  onChange={(value) =>
                    setConversionData({
                      ...conversionData,
                      conversion_factor: value,
                    })
                  }
                />
              </Row>
            </Col>
          </Row>
        </FormWrapper>
      </CommonModal>
    );
  }
);
