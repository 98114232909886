import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import {
  FileTextOutlined,
  FundProjectionScreenOutlined,
  FileOutlined,
  FileAddOutlined,
  HighlightOutlined,
  FormOutlined,
  SnippetsOutlined,
  BuildOutlined,
  FolderViewOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { ContentSection, CardsWrap } from "../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { COLORS } from "../../shared/colors";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

const cards = [
  {
    permission: "reporting-page",
    cardTitle: "Standards Progress",
    cardIcon: (
      <FundProjectionScreenOutlined style={customIconCardStyle.iconStyle} />
    ),
    cardLink: "/reporting/standards-progress",
    type: "standards",
    moduleName: "REPORTING_STANDARDS",
  },
  {
    permission: "reporting-page",
    cardTitle: "Sustainability Standards",
    cardIcon: <FileTextOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/sustainability-standards",
    type: "standards",
    moduleName: "REPORTING_STANDARDS",
  },
  {
    permission: "reporting-page",
    cardTitle: "Generate Standards Report",
    cardIcon: <HighlightOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/sustainability-standards",
    type: "",
  },
  {
    permission: "reporting-page",
    cardTitle: "Reporting Settings",
    cardIcon: <FileAddOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/settings",
    type: "standards",
    moduleName: "REPORTING_STANDARDS",
  },
  {
    permission: "custom-standard",
    cardTitle: "Create Custom Standard",
    cardIcon: <FormOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/custom-standard/create",
    type: "standards",
    moduleName: "REPORTING_STANDARDS",
  },
  {
    permission: "esg-custom-standard",
    cardTitle: "Custom Standard",
    cardIcon: <SnippetsOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/custom-standard",
    type: "standards",
    moduleName: "REPORTING_STANDARDS",
  },
  {
    permission: "report-builder",
    cardTitle: "Report Builder",
    cardIcon: <BuildOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/report-builder",
    type: "standards",
    moduleName: "REPORTING_BUILDER",
  },
  {
    permission: "saved-reports",
    cardTitle: "Saved Reports",
    cardIcon: <FolderViewOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/saved-reports",
    type: "standards",
    moduleName: "REPORTING_BUILDER",
  },
  {
    permission: "saved-reports",
    cardTitle: "Create Report",
    cardIcon: <FormOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/create-report",
    type: "standards",
    moduleName: "REPORTING_BUILDER",
  },
  {
    permission: "document-review",
    cardTitle: "Document Review",
    cardIcon: <ReadOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/reporting/document-review",
    type: "standards",
    moduleName: "REPORTING_BUILDER",
  },
];

export const Reporting = () => {
  const navigate = useNavigate();
  const { checkPermissions } = useCheckRolesPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/data/data-sheets":
        navigate(section);
        break;
      case "/reporting/standards-progress":
        navigate(section);
        break;
      case "/reporting/sustainability-standards":
        navigate(section);
        break;
      case "/reporting/settings":
        navigate(section);
        break;
      case "/reporting/custom-standard/create":
        navigate(section);
        break;
      case "/reporting/custom-standard":
        navigate(section);
        break;
      case "/reporting/report-builder":
        navigate(section);
        break;
      case "/reporting/saved-reports":
        navigate(section);
        break;
      case "/reporting/create-report":
        navigate(section);
        break;
      case "/reporting/document-review":
        navigate(section);
        break;
      default:
    }
  };

  const allowedStandardCards = cards.filter(
    (card) => card.type === "standards" && checkPermissions([card.permission])
  );

  const popularActions = [
    {
      permission: "standards-page",
      cardTitle: "Sustainability Standards",
      cardIcon: <FileOutlined />,
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/reporting/sustainability-standards",
      type: "standards",
      moduleName: "REPORTING_STANDARDS",
    },
    {
      permission: "reporting-page",
      cardTitle: "Standards Progress",
      cardIcon: <HighlightOutlined />,
      iconColor: "linear-gradient(180deg, #44C6A6 0%, #31AC8F 100%)",
      cardLink: "/reporting/standards-progress",
      type: "standards",
      moduleName: "REPORTING_STANDARDS",
    },
  ];
  useEffect(() => {
    popularActions.map((card) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);
  return (
    <ContentSection>
      {popularActions.length > 0 && (
        <>
          {showPopluarActions && (
            <Typography.Title
              level={2}
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Popular Actions
            </Typography.Title>
          )}
          <CardsWrap>
            {popularActions.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <PopularActionCard
                    title={card.cardTitle}
                    iconColor={card.iconColor}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}

      {allowedStandardCards.length > 0 && (
        <>
          <Typography.Title
            level={2}
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontWeight: "500",
              marginTop: "20px",
            }}
          >
            Standards
          </Typography.Title>
          <CardsWrap>
            {allowedStandardCards.map((card, index) => (
              <CustomIconCard
                title={card.cardTitle}
                customCardStyle={customIconCardStyle.cardStyle}
                titleStyle={customIconCardStyle.titleStyle}
                onClick={onClickCardsSelectSection}
                iconComponent={card.cardIcon}
                section={card.cardLink}
                key={index}
                isLockModule={card.moduleName ? card.moduleName : false}
              />
            ))}
          </CardsWrap>
        </>
      )}
    </ContentSection>
  );
};
