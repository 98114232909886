import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBoolean } from "ahooks";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";

import {
  CaretRightFilled,
  QuestionCircleFilled,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Collapse,
  Spin,
  Popover,
  Button,
  Dropdown,
  message,
} from "antd";
import { PageWrap } from "../../../shared/commonStyles";
import EditableCalculationAuditTable from "./EditableCalculationAuditTable";
import { calculationStore } from "../../Carbon/CalculationsStore";
import { calculatorStore, carbonStore } from "../../Carbon";
import {
  fractionalNumber,
  getEmissionsUnit,
  getScopeNumber,
} from "../../Carbon/CalculationUtils";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import moment from "moment";
import { SortDropdownCalculator } from "../Calculator/Components/SortDropdownCalculator";
import { ReasonsDropdown } from "./ReasonsDropdown";

import { SortDropdown } from "../../../components/SortDropdown";

import styles from "../Calculator/Calculator.module.scss";
import { getCompanyInfo } from "../../../services/companyInfo";
import { doConversion } from "../../../services/dataSheet";

const numeral = require("numeral");
interface SelectedItem extends AutomatedEmission {
  key: string;
  name: string;
  checked?: boolean;
  audit_state?: AuditState;
  scope: ScopeName;
}

const CalculatorAudit = observer(() => {
  const [emissionData, setEmissionData] = useState<Emission[]>([]);
  const [calculation, setCalculation] = useState<ICalculation>();
  const [offsets, setOffsets] = useState<Offset[]>([]);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );

  const [conversionFactor, setConversionFactor] = useState(1);
  const { state } = useLocation();
  const navigate = useNavigate();
  if (state === null) navigate("/audit/calculations-audit");

  const [allSelectedItems, setSelectedItems] = useState<SelectedItem[]>([]);

  // sorting
  const [
    isDropdownOffsetsShown,
    { toggle: toggleDropdownOffsets, setFalse: hideDropdownOffsets },
  ] = useBoolean(false);
  const [sortOffsets, setSortOffsets] = useState<SortEmission>({
    type: "key",
    sortAlphabetically: true,
  });

  const convertedNumber = fractionalNumber(
    companyDetails.decimal_places_number
  );

  useEffect(() => {
    calculatorStore.resetState();
    calculatorStore.getCalculationById(state as string);
    userInfoStore.fetchUserInfo();
    loadCompanyInfo();
    carbonStore.fetchSheets();
  }, []);

  useEffect(() => {
    autorun(() => {
      if (calculatorStore.emissions.length || calculatorStore.offsets.length) {
        setEmissionData(calculatorStore.emissions);
        setOffsets(calculatorStore.offsets);
      }

      setCalculation(calculatorStore.calculation);
    });
  }, []);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  const getSelectedScopeData = (scope: ScopeName) => {
    return allSelectedItems.filter((item) => item.scope === scope);
  };

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const scopes = [
    ScopeName.SCOPE_1,
    ScopeName.SCOPE_2,
    ScopeName.SCOPE_3,
    ScopeName.OFFSETS,
  ];

  const scopesInformation = [
    {
      scope: ScopeName.SCOPE_1,
      title: "Scope 1 emissions",
      info: "Scope 1 covers emissions from sources that an organisation owns or controls directly – for example from burning fuel in our fleet of vehicles (if they’re not electrically-powered).",
    },
    {
      scope: ScopeName.SCOPE_2,
      title: "Scope 2 emissions",
      info: "Scope 2 are emissions that a company causes indirectly when the energy it purchases and uses is produced. For example, for our electric fleet vehicles the emissions from the generation of the electricity they're powered by would fall into this category.",
    },
    {
      scope: ScopeName.SCOPE_3,
      title: "Scope 3 emissions",
      info: "Scope 3 encompasses emissions that are not produced by the company itself, and not the result of activities from assets owned or controlled by them, but by those that it’s indirectly responsible for, up and down its value chain. An example of this is when we buy, use and dispose of products from suppliers. Scope 3 emissions include all sources not within the scope 1 and 2 boundaries.",
    },
  ];

  const scopesPopover = (item: any) => {
    return (
      <div className={styles.popoverScope}>
        <h2>{item.title}</h2>
        <p>{item.info}</p>
      </div>
    );
  };

  const offsetsSortOptions = [
    { name: "Name", value: "name" },
    { name: "Region", value: "region" },
  ];

  const handleSort = (scope: ScopeName, sortEmissions: SortEmission) => {
    setEmissionData((scopeData: Emission[]) => {
      const filterData = scopeData.filter((item) => item.scope !== scope);
      const newData = scopeData
        .slice()
        .filter((item) => item.scope === scope)
        .sort((a: Emission, b: Emission) => {
          if (a.factor_data && b.factor_data) {
            const factorA = a.factor_data[sortEmissions.type as keyof CustomManualFactor];
            const factorB = b.factor_data[sortEmissions.type as keyof CustomManualFactor];

            if (factorA !== undefined && factorB !== undefined) {
              if (sortEmissions.sortAlphabetically) {
                return factorA > factorB ? 1 : -1;
              } else {
                return factorA < factorB ? 1 : -1;
              }
            }
          } else {
          }
          // Handle case where factor_data is undefined for either object
          return 0; // or handle differently based on your sorting logic
        });
      return [...newData, ...filterData];
    });
  };

  const handleConfirmEmission = (
    key: string,
    reason: string,
    audit_state: AuditState,
    scope: ScopeName
  ) => {
    const item_type = scope === "offsets" ? "offsets" : "emissions";
    const index = calculatorStore[item_type].findIndex(
      (item) => item.key === key
    );

    if (index !== undefined && calculation) {
      const prevAudits =
        calculatorStore[item_type][index].audits?.filter(
          (item) => item.user_id !== userInfoStore.userID
        ) || [];
      const payload: Emission | Offset = {
        _id: calculatorStore[item_type][index]._id,
        calculation_id: calculatorStore[item_type][index].calculation_id,
        audits: [
          ...prevAudits,
          {
            status: audit_state,
            comments: reason,
            timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
            auditor_name: userInfoStore.userName,
            user_id: userInfoStore.userID,
          },
        ],
      } as Emission | Offset;

      calculatorStore.editPartialCalculationEntry(payload);
    }
  };

  const handleConfirmAllItems = (
    scope: ScopeName,
    reason: string,
    audit_state: AuditState
  ) => {
    if (!calculation) return false;
    const newData = getSelectedScopeData(scope).map((v, index) => {
      const prevAudits =
        v.audits?.filter((item) => item.user_id !== userInfoStore.userID) || [];
      return {
        _id: v._id,
        calculation_id: v.calculation_id,
        audits: [
          ...prevAudits,
          {
            status: audit_state,
            comments: reason,
            timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
            auditor_name: userInfoStore.userName,
            user_id: userInfoStore.userID,
          },
        ],
      } as Emission | Offset;
    });

    newData.forEach((v) => {
      calculatorStore.editPartialCalculationEntry(v);
    });
  };

  const handleConfirmOffset = (
    key: string,
    reason: string,
    audit_state: AuditState
  ) => {
    const index = calculatorStore.offsets.findIndex(
      (offsets) => offsets.key === key
    );

    if (index !== undefined && calculation) {
      const prevAudits =
        calculatorStore.offsets[index].audits?.filter(
          (item) => item.user_id !== userInfoStore.userID
        ) || [];

      const payload: Offset = {
        ...calculatorStore.offsets[index],
        audits: [
          ...prevAudits,
          {
            status: audit_state,
            comments: reason,
            timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
            auditor_name: userInfoStore.userName,
            user_id: userInfoStore.userID,
          },
        ],
      };

      calculatorStore.editCalculationEntry(payload);
    }
  };

  const isApproveDeclineButtonDisabled = (
    selectedItems: SelectedItem[],
    audit_state: AuditState
  ) => {
    return !!selectedItems.some((v) => v?.audit_state === audit_state);
  };

  const filterEmissions = (scope?: ScopeName) => {
    if (scope && scope !== "offsets") {
      return emissionData.filter(
        (item) => !item.archived && item.scope === scope
      );
    } else if (scope && scope === "offsets") {
      return offsets.filter((item) => !item.archived);
    } else {
      return emissionData.filter((item) => !item.archived);
    }
  };

  const handleSortOffsets = (
    setScopeData: React.Dispatch<React.SetStateAction<Offset[]>>
  ) => {
    setScopeData((scopeData: Offset[]) => {
      const newData = scopeData.slice().sort((a: Offset, b: Offset) => {
        const aStatement = a[sortOffsets.type as keyof Offset] || "";
        const bStatement = b[sortOffsets.type as keyof Offset] || "";
        if (sortOffsets.sortAlphabetically) {
          return aStatement > bStatement ? 1 : -1;
        } else {
          return aStatement < bStatement ? 1 : -1;
        }
      });
      return [...newData];
    });
  };

  function countPercent(number: number, sum: number | 0) {
    const total = numeral(number);
    if (+sum) {
      return total.divide(+sum).multiply(100).value();
    }
    return 0;
  }

  const handleChangeCalculationTable = (
    selectedItems: SelectedItem[],
    scope: ScopeName
  ) => {
    setSelectedItems((prevData) => {
      const newData = [
        ...prevData.filter((item) => item.scope !== scope),
        ...selectedItems,
      ];
      return newData.filter((x, i, a) => a.indexOf(x) == i);
    });
  };

  const getScopeClassName = (scope: ScopeName) => {
    if (scope === ScopeName.SCOPE_1) {
      return styles.scopePanel1;
    } else if (scope === ScopeName.SCOPE_2) {
      return styles.scopePanel2;
    } else if (scope === ScopeName.SCOPE_3) {
      return styles.scopePanel3;
    } else if (scope === ScopeName.OFFSETS) {
      return styles.scopePanelOffsets;
    }
  };

  const producedTotal =
    calculation?.total_calculate?.scope1_total! +
    calculation?.total_calculate?.scope2_total! +
    calculation?.total_calculate?.scope3_total!;

  return (
    <>
      {calculatorStore.loadingCalculation && (
        <Spin className={styles.calculatorLoader} />
      )}
      {calculation && (
        <PageWrap
          className={
            calculatorStore.loadingCalculation && styles.calculatorContent
          }
        >
          <Row style={{ marginBottom: 10 }}>
            <div className={styles.calculationName}>
              {calculation.name}
              <span className={styles.calculationPeriod}>
                {moment(
                  (calculation.date_start as { $date: string })?.$date ||
                    (calculation.date_start as string)
                ).format("YYYY-MM-DD")}{" "}
                -{" "}
                {moment(
                  (calculation.date_end as { $date: string })?.$date ||
                    (calculation.date_start as string)
                ).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className={styles.calculationDesc}>
              {calculation.description}
            </div>
          </Row>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col flex={"auto"}>
              <Row
                className={styles.scopeBlock}
                style={{ height: "100%" }}
                gutter={2}
                align="middle"
              >
                <Col
                  flex={
                    countPercent(
                      calculation.total_calculate?.scope1_total!,
                      producedTotal || 0
                    ) || 1
                  }
                >
                  <div className={styles.scopeNum}>Scope 1</div>
                  <div className={styles.scopeValue}>
                    {convertedNumber(
                      calculation.total_calculate?.scope1_total! *
                        conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                  </div>
                  <div className={`${styles.scopeRange} ${styles.scopeRange1}`}>
                    Scope 1 -{" "}
                    {
                      +countPercent(
                        calculation.total_calculate?.scope1_total!,
                        producedTotal || 0
                      )
                        .toFixed(companyDetails.decimal_places_number)
                        .replace(/\.00$/, "")
                    }
                    %
                  </div>
                </Col>
                <Col
                  flex={
                    countPercent(
                      calculation.total_calculate?.scope2_total!,
                      producedTotal || 0
                    ) || 1
                  }
                >
                  <div className={styles.scopeNum}>Scope 2</div>
                  <div className={styles.scopeValue}>
                    {convertedNumber(
                      calculation.total_calculate?.scope2_total! *
                        conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                  </div>
                  <div className={`${styles.scopeRange} ${styles.scopeRange2}`}>
                    Scope 2 -{" "}
                    {
                      +countPercent(
                        calculation.total_calculate?.scope2_total!,
                        producedTotal || 0
                      )
                        .toFixed(companyDetails.decimal_places_number)
                        .replace(/\.00$/, "")
                    }
                    %
                  </div>
                </Col>
                <Col
                  flex={
                    countPercent(
                      calculation.total_calculate?.scope3_total!,
                      producedTotal || 0
                    ) || 1
                  }
                >
                  <div className={styles.scopeNum}>Scope 3</div>
                  <div className={styles.scopeValue}>
                    {convertedNumber(
                      calculation.total_calculate?.scope3_total! *
                        conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                  </div>
                  <div className={`${styles.scopeRange} ${styles.scopeRange3}`}>
                    Scope 3 -{" "}
                    {
                      +countPercent(
                        calculation.total_calculate?.scope3_total!,
                        producedTotal || 0
                      )
                        .toFixed(companyDetails.decimal_places_number)
                        .replace(/\.00$/, "")
                    }
                    %
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row
                className={styles.totalBlock}
                style={{ height: "100%" }}
                gutter={2}
              >
                <Col flex={1}>
                  <div className={styles.totalTitle}>Total</div>
                  <div className={styles.totalValue}>
                    {convertedNumber(
                      calculation.total_calculate?.all_total! * conversionFactor
                    )}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO
                    <sub>2</sub>e
                  </div>
                  <div className={styles.totalDesc}>
                    <span className={styles.totalDescName}>Produced</span>
                    <span>
                      {convertedNumber(
                        (calculation.total_calculate?.scope1_total! +
                          calculation.total_calculate?.scope2_total! +
                          calculation.total_calculate?.scope3_total!) *
                          conversionFactor
                      )}{" "}
                      {getEmissionsUnit(companyDetails.emissions_unit)} CO
                      <sub>2</sub>e
                    </span>
                  </div>
                  <div className={styles.totalDesc}>
                    <span className={styles.totalDescName}>Offsetted</span>
                    <span>
                      {convertedNumber(
                        calculation.total_calculate?.offsetted_total! *
                          conversionFactor
                      )}{" "}
                      {getEmissionsUnit(companyDetails.emissions_unit)} CO
                      <sub>2</sub>e
                    </span>
                  </div>
                  {calculation.attribution_factor !== 100 && (
                    <div className={styles.totalDesc}>
                      <span className={styles.totalDescName}>Unattributed</span>
                      <span>
                        {convertedNumber(
                          calculation.total_calculate?.unattributed_total! *
                            conversionFactor
                        )}{" "}
                        {getEmissionsUnit(companyDetails.emissions_unit)} CO
                        <sub>2</sub>e
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Collapse
            defaultActiveKey={
              scopes.find(
                (item) =>
                  !!(filterEmissions(item) as any[]).filter(
                    (v: any) => v.scope! === item
                  )?.length
              ) || ScopeName.SCOPE_1
            }
            expandIcon={({ isActive }) => (
              <div className={styles.expandContainer}>
                <CaretRightFilled
                  className={styles.expandIcon}
                  rotate={isActive ? 90 : 0}
                />
                <span className={styles.expandText}>
                  {isActive ? "Collapse table" : "Expand table"}
                </span>
              </div>
            )}
            expandIconPosition="end"
            className={styles.scopeCollapse}
            bordered={false}
            ghost
          >
            {scopes.map((scope) => (
              <Collapse.Panel
                collapsible="header"
                className={`${styles.scopePanel} ${getScopeClassName(scope)}`}
                header={
                  <div className={styles.panelHeader}>
                    {scope !== "offsets"
                      ? `Scope ${getScopeNumber(scope)}`
                      : `Offsets   `}
                    <Popover
                      overlayClassName="popoverContent"
                      content={
                        scope !== "offsets" ? (
                          () =>
                            scopesPopover(
                              scopesInformation.find(
                                (item) => item.scope === scope
                              )
                            )
                        ) : (
                          <div className={styles.popoverScope}>
                            <h2>Offsets</h2>
                          </div>
                        )
                      }
                      placement="right"
                      autoAdjustOverflow
                      arrowPointAtCenter
                    >
                      <QuestionCircleFilled className={styles.popoverIcon} />
                    </Popover>
                    <span className={styles.recordsCount}>
                      ({filterEmissions(scope).length} records)
                    </span>
                  </div>
                }
                extra={
                  <div className={styles.extraElements}>
                    {!!getSelectedScopeData(scope).length && (
                      <div className={styles.markAllBtns}>
                        <ReasonsDropdown
                          buttonName="Mark all as approved"
                          isDisabled={isApproveDeclineButtonDisabled(
                            getSelectedScopeData(scope),
                            AuditState.APPROVED
                          )}
                          selectedItems={getSelectedScopeData(scope)}
                          onConfirm={(reason) =>
                            handleConfirmAllItems(
                              scope,
                              reason,
                              AuditState.APPROVED
                            )
                          }
                        />
                        <ReasonsDropdown
                          buttonName="Decline all"
                          isDisabled={isApproveDeclineButtonDisabled(
                            getSelectedScopeData(scope),
                            AuditState.DECLINED
                          )}
                          selectedItems={getSelectedScopeData(scope)}
                          onConfirm={(reason) =>
                            handleConfirmAllItems(
                              scope,
                              reason,
                              AuditState.DECLINED
                            )
                          }
                          isRequired
                        />
                      </div>
                    )}
                    {scope !== "offsets" ? (
                      <SortDropdownCalculator
                        onSort={(sortEmission) =>
                          handleSort(scope, sortEmission)
                        }
                      />
                    ) : (
                      <Button
                        type="text"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Dropdown
                          overlay={
                            <>
                              <SortDropdown
                                sorting={sortOffsets}
                                changeSorting={setSortOffsets}
                                options={offsetsSortOptions}
                                onConfirm={() => {
                                  handleSortOffsets(setOffsets);
                                  hideDropdownOffsets();
                                }}
                              />
                            </>
                          }
                          trigger={["click"]}
                          placement="bottomRight"
                          onVisibleChange={toggleDropdownOffsets}
                          visible={isDropdownOffsetsShown}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <SwapOutlined rotate={90} />
                            <span className={styles.expandText}>Sort</span>
                          </a>
                        </Dropdown>
                      </Button>
                    )}
                  </div>
                }
                key={scope}
              >
                <EditableCalculationAuditTable
                  data={filterEmissions(scope)}
                  scope={scope}
                  attributionFactor={calculation.attribution_factor}
                  onChangeSelectedItems={handleChangeCalculationTable}
                  selectedItems={getSelectedScopeData(scope)}
                  onConfirmItem={(key, reason, state) =>
                    handleConfirmEmission(key, reason, state, scope)
                  }
                  emissionsUnit={getEmissionsUnit(
                    companyDetails.emissions_unit
                  )}
                  conversionFactor={conversionFactor}
                  companyDetails={companyDetails}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </PageWrap>
      )}
    </>
  );
});

export default CalculatorAudit;
