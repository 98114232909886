import styled from 'styled-components'
import Color from "color";

export const TopBarBox = styled.div` width: 100%;
                              margin-top: 10px;
                              margin-bottom: 10px;
                              display : flex;
                              flex-direction: row;
                              align-items: space-around;
                              justify-content: space-around`;

export const MainHeadingWrap  = styled.div`
  width : 100%;
  text-align : center;
  font-size : 24px;
  font-weight : bold;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const Report = styled.div`
  font-family : Montserrat;
  background-color: white;
  .tableContainer {
    margin: 30px 8% 50px;
    background-color: white;
    padding: 15px;
  }

  .reportTableContainer {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .ant-table-container table > thead > tr:first-child th {
    background: ${({ secondary }) => secondary};
    color: ${({ tableHeaderTextColor }) => tableHeaderTextColor};
    padding: 14px 14px 6px;
    font-weight: bold;
    border-radius: 0px;
    border : none;
  }

  .ant-table-row td:first-child,
  .ant-table-row:hover td:first-child {
    background-color: ${({ primary }) => primary}10; // 10% opacity of primary
    color: ${({ topicTextColor }) => topicTextColor};
    font-weight: bold;
  }

  .reportTableContainer tbody tr td {
    border-bottom: 1px solid #c8c8c8;
  }

  .ant-spin-container:first-child {
    padding: 0;
  }

  .ant-card-bordered {
    border: none;
  }

  .report-header {
    background: ${({ primary }) => primary};
    padding: 2rem;
  }

  .header-content-wrap .report-title {
    color: ${({ reportTitleColor }) => reportTitleColor};
  }
  

  .header-content-wrap .description {
    color: ${({ reportDescriptionColor }) => reportDescriptionColor};
  }

  .header-content-wrap{
    width : 100%;
  }
  
  .header-logo-wrap{
    width : 100%;
    display: flex;
    justify-content: end;
  }

  .header-logo-wrap img{
    width : 20%;
    background-color : "transparent";
    margin-bottom : 10px;
  }

  .report-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
  }
  .report-footer a {
    color: white;
  }

  .report-footer img {
    max-width: 100px;
    margin-left: 4px;
    filter: grayscale(100%);
  }
  table,
  thead,
  th,
  tr,
  td {
    border-collapse: collapse;
    border : none;
  }
  tr
  {
    border-bottom : 1px solid #f2ebeb;
  }
`;

export const FormTableWrap = styled.div`
 margin-top : 50px;
 margin-bottom : 20px;

 table thead {
  background-color : #1561a7;
 }

 table thead tr{
  background-color : #1561a7;
 }
 
 table thead tr th{
  background-color : #1561a7;
  color : #ffffff
 }

    table td {
      vertical-align: top;
    }
  footer {
    color: #fffff;
  }
  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }
  }
    // table { 
    //   page-break-after:auto 
    // }
    // tr { 
    //   page-break-inside:avoid; 
    //   page-break-after:auto
    //  }
    // td {
    //    page-break-inside:avoid; 
    //    page-break-after:auto 
    //   }
    // thead { 
    //   display: table-row-group; 
    // }
// tfoot { display:table-footer-group }
`;

export const ReportWrap = styled.div`
  //  padding: 30px 20px 30px 20px;
`;

export const CategoryText = styled.div`
  font-size : 18px;
  font-weight : bold;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const SummaryText = styled.div`
  font-size : 14px;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const CustomText = styled.div`
  font-size : 14px;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const ReportContainer = styled.div`
   width : 100%;
  //  padding: 30px 20px 30px 20px;
`;

export const HeadingText = styled.div`
  font-size : 14px;
  font-weight : bold;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const FormDataWrap = styled.div`
  margin-top : 20px;
  margin-bottom : 20px;
`;


export const FormDataHeading = styled.div`
  font-size : 18px;
  font-weight : bold;
  margin-top : 10px;
  margin-bottom : 10px;
`;

export const StandardLinksWrap = styled.div`
  margin-top : 10px;
  margin-bottom : 10px;
  span {
    margin : 5px;
  }
`;

export const ExportButtonsWrap = styled.div`
  padding : 20px
`;

export const HeaderLogo = styled.div`
  background-color : #000;
`;


export const FooterLogo = styled.div`
   background-color : #000;
`;

export const HeaderLogoWrap = styled.div`
   display:flex;
   justify-content: center;

   img{
      padding 10px;
      background-color : #1561a7;
      height : 60px;
      min-width : 40px;
   }
`;


export const FooterLogoWrap = styled.div`
  display:flex;
  justify-content: flex-end;
  img{
    padding 10px;
    background-color : #1561a7;
    height : 40px;
    min-width : 40px;
  }
`;
