import React, { useState } from "react";

import { Button, Input, Form } from "antd";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";

import styles from "./InputReasonsModal.module.scss";

interface InputReasonsModalProps {
  onClose: () => void;
  onConfirm: ((data: string) => void) | ((reason: string) => false | void);
  isRequired?: boolean;
}

export const InputReasonsModal = ({
  onClose,
  onConfirm,
  isRequired,
}: InputReasonsModalProps) => {
  const [reason, setReason] = useState("");

  const handleConfirm = () => {
    onClose();
    onConfirm(reason);
  };

  return (
    <FormWrapper className={styles.container}>
      <h3>Note/Reason{!isRequired && "(optional)"}</h3>
      <Form.Item required={!!isRequired}>
        <Input
          className={styles.inputField}
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Form.Item>
      <div className={styles.buttonsWrap}>
        <Button
          key="back"
          type="text"
          onClick={onClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <StyledButton
          className={styles.saveBtn}
          key="submit"
          type="primary"
          onClick={handleConfirm}
          disabled={!!isRequired && !reason}
        >
          Confirm
        </StyledButton>
      </div>
    </FormWrapper>
  );
};
