import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { useDynamicList } from "ahooks";
import {
  fractionalNumber,
  getScopeNumber,
} from "../../../../Carbon/CalculationUtils";

import { Button, Col, Collapse, Input, Row, Select } from "antd";
import {
  ArrowLeftOutlined,
  CaretRightFilled,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  CommonTable,
  FormWrapper,
  PageWrap,
  StyledButton,
  StyledDivider,
  StyledProgress,
  StyledSteps,
} from "../../../../../shared/commonStyles";

import styles from "./UpdateRecords.module.scss";

export const UpdateRecords = observer(() => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [emissionData, setEmissionData] = useState<Emission[]>([]);
  const [calculation, setCalculation] = useState<ICalculation>();
  useEffect(() => {
    calculatorStore.getCalculationById(state as string);
  }, []);
  useEffect(() => {
    const disposer = autorun(() => {
      if (calculatorStore.emissions) {
        setEmissionData(calculatorStore.emissions);
      }
      calculatorStore.calculation.hasOwnProperty("data") &&
        setCalculation(calculatorStore.calculation);
    });
    return () => {
      disposer();
    };
  }, []);
  const [currentStep, setCurrentStep] = useState(0);
  const { list, remove, push } = useDynamicList([{} as any]);
  const goBack = () => {
    navigate(-1);
  };
  const scopes = [ScopeName.SCOPE_1, ScopeName.SCOPE_2, ScopeName.SCOPE_3];
  const filteredEmissions = (scope: ScopeName) => {
    const emissions =
      emissionData
        ?.filter((item) => !item.archived)
        ?.filter((item) => item.scope === scope) || [];
    return emissions;
  };

  const convertedNumber = fractionalNumber();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Consumption Amount",
      dataIndex: "consumption_amount",
      key: "consumption_amount",
    },

    {
      title: "CO2e Factor",
      dataIndex: "co2e_emission_factor",
      key: "co2e_emission_factor",
      render: (_: any, data: Emission) => {
        return (
          <span>
            {data?.factor_data.factor
              ? convertedNumber(data?.factor_data.factor)
              : 0}
          </span>
        );
      },
    },
    {
      title: "Total Emission (kg CO2e)",
      dataIndex: "total_emission",
      key: "total_emission",
      render: (_: any, data: Emission) => {
        return (
          <span>
            {convertedNumber(
              Number(data?.factor_data.factor) *
                Number(data?.consumption_amount)
            )}
          </span>
        );
      },
    },
  ];
  const getScopeClassName = (scope: ScopeName) => {
    if (scope === ScopeName.SCOPE_1) {
      return styles.scopePanel1;
    } else if (scope === ScopeName.SCOPE_2) {
      return styles.scopePanel2;
    } else if (scope === ScopeName.SCOPE_3) {
      return styles.scopePanel3;
    }
  };
  const handleAddField = () => {
    push({} as any);
  };

  const handleDeleteField = (index: number) => {
    remove(index);
  };

  //   const handleSave = () => {};
  return (
    <>
      <div className={styles.stepperHeader}>
        <Button onClick={goBack} type="text" className={styles.exitBtn}>
          <ArrowLeftOutlined />
          <span>Exit</span>
        </Button>
        <StyledSteps
          current={currentStep}
          type="navigation"
          className={styles.stepper}
          onChange={setCurrentStep}
        >
          <StyledSteps.Step title="Update records" />
        </StyledSteps>
      </div>
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={50}
        showInfo={false}
        className={styles.progress}
      />
      <PageWrap>
        <Row justify="space-between" className={styles.stepperWrap}>
          <Col span={11}>
            <Row className={styles.title}>Update multiple records</Row>
            <Row className={styles.greyText}>
              Bulk update multiple records with just one click
            </Row>
            <FormWrapper>
              <Row className={styles.subTitle}>
                Set up replacement parameters
              </Row>
              <StyledDivider />
              <Col span={24}>
                <Row className={styles.fieldLabel}>Scope</Row>
                <Row>
                  <Select defaultValue={"all"} className={styles.field}>
                    <Select.Option value={"all"}>All</Select.Option>
                    <Select.Option value={ScopeName.SCOPE_1}>
                      Scope 1
                    </Select.Option>
                    <Select.Option value={ScopeName.SCOPE_2}>
                      Scope 2
                    </Select.Option>
                    <Select.Option value={ScopeName.SCOPE_3}>
                      Scope 3
                    </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Row justify="space-between" align="bottom">
                <Col span={11}>
                  <Row className={styles.fieldLabel}>Select column</Row>
                  <Row>
                    <Select
                      placeholder="Select column"
                      className={styles.field}
                    >
                      {columns.map((column) => (
                        <Select.Option key={column.key} value={column.key}>
                          {column.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col span={11}>
                  <Row className={styles.fieldLabel}>
                    Enter value that needs to be updated
                  </Row>
                  <Row>
                    <Input
                      className={styles.field}
                      placeholder="Enter initial value"
                    />
                  </Row>
                </Col>
              </Row>
              <Col>
                <Row className={styles.fieldLabel}>Replace with</Row>
                <Row>
                  <Input className={styles.field} placeholder="Enter value" />
                </Row>
              </Col>
            </FormWrapper>
            <FormWrapper>
              <Row className={styles.subTitle} align="middle">
                Add additional filters
                <span className={styles.greyTextField}>(optional)</span>
              </Row>
              <StyledDivider />
              <Row justify="space-between" align="bottom">
                <Col span={8} className={styles.fieldLabel}>
                  Calculation field
                </Col>
                <Col span={6} className={styles.fieldLabel}>
                  Equality operator
                </Col>
                <Col span={6} className={styles.fieldLabel}>
                  Input variable
                </Col>
                <Col span={2}></Col>
              </Row>
              {list.map((item, index) => (
                <Row justify="space-between" align="bottom" key={index}>
                  <Col span={8}>
                    <Select
                      placeholder="Select calculation field"
                      className={styles.field}
                    />
                  </Col>
                  <Col span={6}>
                    <Select placeholder="Select" className={styles.field} />
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="Enter variable"
                      className={styles.field}
                    />
                  </Col>
                  <Col>
                    <StyledButton
                      type="default"
                      className={styles.buttonDelete}
                      onClick={() => handleDeleteField(index)}
                      icon={
                        <DeleteOutlined className={styles.buttonDeleteIcon} />
                      }
                    />
                  </Col>
                </Row>
              ))}
              <div>
                <StyledButton
                  type="primary"
                  bgcolor="#7F5FEE"
                  hoverbgcolor="#A68DFB"
                  className={styles.buttonAddField}
                  onClick={handleAddField}
                >
                  <PlusOutlined /> Add one more filter
                </StyledButton>
              </div>
            </FormWrapper>
          </Col>
          <Col span={11}>
            <Collapse
              defaultActiveKey={ScopeName.SCOPE_1}
              expandIcon={({ isActive }) => (
                <div className={styles.expandContainer}>
                  <CaretRightFilled
                    className={styles.expandIcon}
                    rotate={isActive ? 90 : 0}
                  />
                  <span className={styles.expandText}>Expand table</span>
                </div>
              )}
              expandIconPosition="end"
              className={styles.scopeCollapse}
              bordered={false}
              ghost
            >
              {scopes.map((scope) => (
                <Collapse.Panel
                  key={scope}
                  className={`${styles.scopePanel} ${getScopeClassName(scope)}`}
                  header={
                    <div className={styles.scopePanelHeader}>
                      Scope {getScopeNumber(scope)}
                      <span className={styles.greyTextCount}>
                        ({filteredEmissions(scope).length} records)
                      </span>
                    </div>
                  }
                >
                  <CommonTable
                    dataSource={filteredEmissions(scope)}
                    columns={columns}
                    pagination={false}
                    className="tableContiner"
                    scroll={{ x: "max-content" }}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </Col>
          <Row justify="start" align="middle">
            <Button type="text" className={styles.buttonCancel}>
              Cancel
            </Button>
            <StyledButton
              type="primary"
              bgcolor="#7F5FEE"
              hoverbgcolor="#A68DFB"
              className={styles.buttonUpdate}
            >
              Update records
            </StyledButton>
          </Row>
        </Row>
      </PageWrap>
    </>
  );
});
