import React, { Fragment } from "react";
import { PageWrap } from "../../shared/commonStyles";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Designer } from "@grapecity/activereports-react";
import { Core } from "@grapecity/activereports";
import { updateSavedReports, addSavedReports } from "../../services/reports";

Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY);

const ReportBuilder = () => {
  const updateReport = async (info) => {
    try {
      const payload = {
        _id: { $oid: info.id },
        report_data: info,
      };
      const response = await updateSavedReports(payload);
      if (response?.$oid) {
        return {
          id: response?.$oid,
          displayName: info.displayName,
        };
      } else {
        return {
          id: null,
          displayName: "Untitled",
        };
      }
    } catch (error) {
      console.log("Error while saving report !", error);
    }
  };

  function onSaveReport(info) {
    return updateReport(info);
  }

  const createNewReport = async (info) => {
    try {
      const payload = {
        created_date: moment().format(),
        report_data: info,
      };

      const response = await addSavedReports(payload);
      if (response?.$oid) {
        return {
          id: response?.$oid,
          displayName: info.displayName,
        };
      } else {
        return {
          id: null,
          displayName: "Untitled",
        };
      }
    } catch (error) {
      console.log("Error while saving report !", error);
    }
  };

  function onSaveAsReport(info) {
    info.id = uuidv4();
    const display_name = prompt("Enter Report Name !", "");
    if (display_name !== null) {
      info.displayName = display_name !== "" ? display_name : "Untitled";
      return createNewReport(info);
    }
  }

  return (
    <PageWrap>
      <Fragment>
        <div id="designer-host" style={{ height: "80vh" }}>
          <Designer onSave={onSaveReport} onSaveAs={onSaveAsReport} />
        </div>
      </Fragment>
    </PageWrap>
  );
};

export default ReportBuilder;
