import React, { useState } from 'react';
import {PlusCircleOutlined} from "@ant-design/icons";
import { AddNewStandardModal } from "./Components/AddNewStandardModal";
import { StyledButton } from "../../../shared/commonStyles";
import ESGTemplateModal from '../../../pages/reportingPage/PoliciesPage/components/ESGTemplateModal';

export const AddNewStandard = () => {
  const [showModal, setShowModal] = useState(false);
  const [templateLibraryModal, setTemplateLibraryModal] = useState(false);
  return (
    <>
      {templateLibraryModal && 
        <ESGTemplateModal
          visible={templateLibraryModal}
          onClose={() => setTemplateLibraryModal(false)}
          onFinish={()=> setTemplateLibraryModal(false)}
        />
      }
      {showModal && (
        <AddNewStandardModal
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          setTemplateLibraryModal={setTemplateLibraryModal}
        />
      )}
        <StyledButton type="primary" onClick={() => setShowModal(true)}>
          <PlusCircleOutlined /> <span>Add New Standard</span>
        </StyledButton>
    </>
  );
};

