import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getDocumentType = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_client_documents_types",
    },
  });

  return JSON.parse(response.data.datalake);
};
export const getDocumentTypeList = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_client_documents",
    },
  });
  return JSON.parse(response.data.datalake);
};