import React,{useState,useEffect} from 'react';
import { API } from 'aws-amplify';
import {Spin } from 'antd';
import {LinkOutlined } from '@ant-design/icons';
import * as queries from '../../../../../graphql/queries';
import { StyledTag } from '../../../../../shared/commonStyles';

const ReportDataFiles = ({dataId}) =>{
    const [uploadedFilesList,setUploadedFilesList] = useState([]);
    const [loaderUploadedFilesList,setLoaderUploadedFilesList] = useState(false);

    
  useEffect(()=>{
    const loadDataLakeFiles = async() =>{
      setLoaderUploadedFilesList(true);
      // load uploaded files from s3 
      try{
        const response = await API.graphql({ query: queries["dataLakeFiles"], variables: { request_type: "LIST",data_id:dataId}});
         const filesList = response['data']['dataLakeFiles'];
         const list = filesList.replace(/['"]+/g, '').replace(/^\[(.+)\]$/,'$1').split(',');
         setUploadedFilesList(list);
      }catch(error){
        console.log("Error while fetching data lake files",error);
      }finally{
        setLoaderUploadedFilesList(false);
      }
    }
    if(dataId){
      loadDataLakeFiles();
    }
 },[dataId]);

 
const downloadFileHandler = async(key) =>{
    try{
      setLoaderUploadedFilesList(true);
      const fileName = key && key?.split('/')?.pop();
      const version = key && key?.split('/')?.slice(-2, -1)[0]?.replace("v",'');
      const response = await API.graphql({ query: queries["dataLakeFiles"], variables: { request_type: "DOWNLOAD",data_id:dataId,version,file_name:fileName}});
      const signedURL = response['data']['dataLakeFiles'];
      if(signedURL){
        const resp = await fetch(signedURL);
        const imageBody = await resp.blob();
        const url = window.URL.createObjectURL(
          new Blob([imageBody]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }catch(error){
      console.log("Error while download file",error);
    }finally{
      setLoaderUploadedFilesList(false);
    }
}


return (
  <Spin spinning={loaderUploadedFilesList}>  
    {uploadedFilesList && uploadedFilesList?.map((item,i)=>{
        const fileName = item && item?.split('/').pop();
          return(
              <StyledTag 
                bgColor="transparent"
                key={dataId + i}
                className="margin-2"
                icon={<LinkOutlined />} 
                onClick={()=>downloadFileHandler(item)}>
                {fileName}
              </StyledTag>
          );
        })
      }
   </Spin>   
);
}


export default ReportDataFiles;