import React, { useEffect } from "react";
import { useBoolean, useDynamicList } from "ahooks";
import { PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, InputNumber, Popover, Row } from "antd";
import {
  CommonTable,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import TableRowButtons from "./TableRowButtons";
import { AddTargetModal } from "../AddTargetModal";
import { reductionPlanStore } from "../../ReductionPlanStore";
import styles from "./CreateTargets.module.scss";
import { observer } from "mobx-react-lite";

interface CreateTargetsProps {
  data: TargetPayload[];
  title: string;
  subtitle: string;
  targetType: TargetType;
  isCustomInfo?: boolean;
  onNext: () => void;
  onBack: () => void;
}

export const CreateTargets = observer(
  ({
    data,
    title,
    subtitle,
    targetType,
    isCustomInfo,
    onNext,
    onBack,
  }: CreateTargetsProps) => {
    const {
      list: tableData,
      remove,
      replace,
      resetList,
      push,
    } = useDynamicList<TargetPayload>(data);

    useEffect(() => {
      resetList(data);
    }, [data]);

    const [
      addTargetModal,
      { setTrue: showAddTargetModal, setFalse: hideAddTargetModal },
    ] = useBoolean(false);

    const handleAddTarget = (data: TargetPayload) => {
      push(data);
      reductionPlanStore.addNewTarget(data);
      hideAddTargetModal();
    };

    const handleDelete = (data: TargetPayload, index: number) => {
      reductionPlanStore.deleteOnboardingTarget(data);
      remove(index);
    };

    const handleClearTargets = () => {
      resetList([]);
      reductionPlanStore.newTargets = reductionPlanStore.newTargets.filter(
        (item) => item.type !== targetType
      );
      onNext();
    };

    const handleChangeAmount = (
      index: number,
      currentConsumptionAmount: number
    ) => {
      replace(index, {
        ...tableData[index],
        currentConsumptionAmount,
      });
    };

    const handleSaveAmount = (data: TargetPayload) => {
      reductionPlanStore.editOnboardingConsumptionAmount(data);
    };

    const handleEdit = (data: TargetPayload, index: number) => {
      if (data) {
        reductionPlanStore.editOnboardingTarget(data);
      }
      replace(index, {
        ...data,
      });
    };
    const getPercentageValue = (value: number, index: number) => {
      if (value && tableData[index].baseYearConsumptionAmount) {
        return (
          (value - tableData[index].baseYearConsumptionAmount) /
          -(+tableData[index].baseYearConsumptionAmount / 100)
        );
      } else return 0;
    };

    const columns = [
      {
        title: "Emission",
        dataIndex: "emissionName",
        key: "EmissionName",
        sorter: (a: TargetPayload, b: TargetPayload) =>
          a.emissionName.localeCompare(b.emissionName),
      },
      {
        title: "Scenario",
        dataIndex: "scenario",
        key: "scenario",
      },
      {
        title: "Scope",
        dataIndex: "scope",
        key: "scope",
        render: (value: string) => {
          return <span>{value} </span>;
        },
      },
      {
        title: "Current Consumption Amount",
        dataIndex: "currentConsumptionAmount",
        key: "currentConsumptionAmount",
        render: (_value: number, record: TargetPayload, index: number) => {
          return (
            <>
              <FormWrapper>
                <InputNumber
                  size="large"
                  min={0}
                  value={tableData[index].currentConsumptionAmount}
                  onChange={(value) => handleChangeAmount(index, value || 0)}
                  onPressEnter={() => handleSaveAmount(record)}
                  onBlur={() => handleSaveAmount(record)}
                />
              </FormWrapper>
            </>
          );
        },
      },
      {
        title: "Reduction amount",
        dataIndex: "goal",
        key: "goal",

        render: (value: number, _record: any, index: number) => {
          return (
            <span>
              {getPercentageValue(value, index).toFixed(2).replace(/\.00$/, "")}{" "}
              %
            </span>
          );
        },
      },
      {
        title: "Target year",
        dataIndex: "targetYear",
        key: "targetYear",
        render: (value: number) => {
          return <span>{value}</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "btns",
        render: (_: any, data: TargetPayload, index: number) => {
          return (
            <TableRowButtons
              data={data}
              onDelete={() => handleDelete(data, index)}
              onConfirm={(data) => handleEdit(data, index)}
            />
          );
        },
      },
    ];

    return (
      <Row>
        <Col
          className={tableData.length ? styles.contentTable : styles.content}
        >
          <h2 className={styles.titleWrap}>
            <span className={styles.title}>{title}</span>
            <Popover
              overlayClassName="popoverContent"
              arrowPointAtCenter
              autoAdjustOverflow
              placement="bottomLeft"
              content={
                <div className={styles.popoverContent}>
                  <span className={styles.textPopover}>
                    Want to know how to setup company targets according to SBT
                    approach?
                  </span>
                  <div className={styles.textPopover}>
                    Visit{" "}
                    <a>
                      https://sciencebasedtargets.org/step-by-step-process#develop-a-target
                    </a>{" "}
                    for more information!
                  </div>
                </div>
              }
            >
              <QuestionCircleOutlined
                className={styles.icon}
                width={40}
                height={40}
              />
            </Popover>
          </h2>
          <div>
            <div>
              <span className={styles.subtitle}>{subtitle}</span>
              {tableData.length ? (
                <div>
                  <CommonTable
                    rowKey={"key"}
                    title={() => <span>{title}</span>}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                  <div>
                    <StyledButton onClick={showAddTargetModal}>
                      <PlusCircleOutlined />
                      Add Target
                    </StyledButton>
                  </div>
                </div>
              ) : (
                <div className={styles.buttonAdd}>
                  <StyledButton onClick={showAddTargetModal}>
                    <PlusCircleOutlined />
                    Add Target
                  </StyledButton>
                </div>
              )}
            </div>
            <div className={styles.buttons}>
              <Button type="text" onClick={onBack} className={styles.whiteBtn}>
                Back
              </Button>
              <StyledButton onClick={onNext} disabled={!tableData.length}>
                Confirm & Continue
              </StyledButton>
              <Button
                type="text"
                onClick={handleClearTargets}
                className={styles.whiteBtn}
              >
                Skip for now
              </Button>
            </div>
          </div>
        </Col>
        {!tableData.length && isCustomInfo && (
          <Col className={styles.infoWrap}>
            <span className={styles.titlePopover}>
              Want to know how to setup company targets according to SBT
              approach?
            </span>
            <div className={styles.textPopover}>
              Visit{" "}
              <a>
                https://sciencebasedtargets.org/step-by-step-process#develop-a-target
              </a>{" "}
              for more information!
            </div>
          </Col>
        )}
        <AddTargetModal
          isShown={addTargetModal}
          modalType={AddTargetModalType.SINGLE}
          targetType={targetType}
          onClose={hideAddTargetModal}
          onConfirm={(data) => handleAddTarget(data)}
        />
      </Row>
    );
  }
);
