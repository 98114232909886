import React, { useState } from "react";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
  CommonTable,
  StyledTag,
} from "../../../shared/commonStyles";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
import { Form, Row, Col, Typography } from "antd";

import { LeftOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import ShowStandardFormViewModal from "./ShowStandardFormViewModal";
import { siteThemeColors } from "../../../shared/colors";
import { CreateWelcomePageModal } from "../../createDataSheetSurvey/Steps/CreateWelcomePageModal";
const { Title } = Typography;

const Step5 = ({
  onSubmitHandler,
  onClickPrevious,
  sortedDataLakeList,
  setSortedDataLakeList,
  loadingCreateFlow,
  onSortEnd,
  action,
}) => {
  const [rowDataView, setRowDataView] = useState(
    sortedDataLakeList.length ? sortedDataLakeList[0] : null
  );
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [welcomePageModal, setWelcomePageModal] = useState(false);
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = sortedDataLakeList.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "metric",
      width: "25%",
      className: "drag-visible",
      render: (value, data) => {
        return value || data.name;
      },
    },
    {
      title: "Theme",
      dataIndex: "theme",
      width: "20%",
      className: "drag-visible",
      render: (data, record) => {
        return record.type === "welcome_page" ? "Welcome Page" : record.tags?.theme;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
      className: "drag-visible",
      render: (data, record) => {
        return record.type === "welcome_page" ? "Welcome Page" : record.tags?.category;
      },
    },
    {
      title: "Standards",
      dataIndex: "standards",
      width: "10%",
      className: "drag-visible",
      render: (record, data) =>
        data.type === "welcome_page"
          ? "-"
          : record.map((a, i) => (
            <StyledTag className="margin-2" key={data._id.$oid + i}>
              {a}
            </StyledTag>
          )),
    },
    {
      title: "Order",
      dataIndex: "index",
      width: "5%",
      className: "drag-visible",
      render: (record, data) => {
        return record + 1;
      },
    },
  ];
  return (
    <>
      <FormWrapper>
        <ShowStandardFormViewModal
          rowDataView={rowDataView || sortedDataLakeList[0]}
          showFormViewModal={showFormViewModal}
          setShowFormViewModal={setShowFormViewModal}
          sortedDataLakeList={sortedDataLakeList}
          setRowDataView={setRowDataView}
        />
        <CreateWelcomePageModal
          visible={welcomePageModal}
          onClose={() => setWelcomePageModal(false)}
          setSortedDataSurveyList={setSortedDataLakeList}
        />
        <FormWrap width="90%">
          <>
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item>
                  <Title level={3} className="color-white">
                    Standards order
                  </Title>
                  <Row>
                    <Col span={12}>
                      <Title level={4}>
                        Change order of the standards inside the flow if needed
                      </Title>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      {" "}
                      <StyledButton
                        type="custom"
                        onClick={() => setWelcomePageModal(true)}
                      >
                        <PlusOutlined />
                        <span>Add welcome page</span>
                      </StyledButton>
                      <StyledButton
                        type="custom"
                        onClick={() => setShowFormViewModal(true)}
                      >
                        <span>Open preview</span>
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <CommonTable
                    pagination={false}
                    dataSource={sortedDataLakeList}
                    columns={columns}
                    rowKey="index"
                    scroll={{ x: true }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="flex-start">
                    <StyledButton
                      className="margin-5"
                      onClick={() => onClickPrevious()}
                      type="custom"
                    >
                      <LeftOutlined /> <span>Previous</span>
                    </StyledButton>

                    <StyledButton
                      type="custom"
                      onClick={onSubmitHandler}
                      loading={loadingCreateFlow}
                    >
                      {action === "update" ? "Update Survey" : "Create Survey"}
                    </StyledButton>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </>
        </FormWrap>
      </FormWrapper>
    </>
  );
};

export default Step5;
