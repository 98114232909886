import { useBoolean } from "ahooks";
import { Button, Dropdown } from "antd";
import React from "react";
import { InputReasonsModal } from "../CalculationsAudit/Components/InputReasonsModal";
import styles from "../Calculator/Calculator.module.scss";

interface SelectedItem {
  key: string;
  name: string;
  checked?: boolean;
  scope: ScopeName;
}

interface SelectedOffset {
  key: string;
  name: string;
  checked?: boolean;
}

interface ReasonsDropdownProps {
  buttonName: string;
  isDisabled: boolean;
  selectedItems: SelectedItem[] | SelectedOffset[];
  onConfirm: (reason: string) => void;
  isRequired?: boolean;
}

export const ReasonsDropdown = ({
  buttonName,
  isDisabled,
  selectedItems,
  onConfirm,
  isRequired,
}: ReasonsDropdownProps) => {
  const [
    isScopeHeaderModalVisible,
    { setFalse: setScopeHeaderModalFalse, set: setScopeHeaderModal },
  ] = useBoolean(false);

  return (
    <>
      <Dropdown
        overlay={
          <InputReasonsModal
            onConfirm={(reason) => {
              onConfirm(reason);
              setScopeHeaderModalFalse();
            }}
            onClose={setScopeHeaderModalFalse}
            isRequired={isRequired}
          />
        }
        trigger={["click"]}
        placement="bottomRight"
        disabled={isDisabled}
        onVisibleChange={setScopeHeaderModal}
        visible={isScopeHeaderModalVisible}
      >
        <Button type="primary" className={styles.buttonHeader}>
          {buttonName} ({selectedItems.length})
        </Button>
      </Dropdown>
    </>
  );
};
