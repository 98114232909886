/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompanyInfo = /* GraphQL */ `
  mutation CreateCompanyInfo(
    $input: CreateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    createCompanyInfo(input: $input, condition: $condition) {
      group
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyInfo = /* GraphQL */ `
  mutation UpdateCompanyInfo(
    $input: UpdateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    updateCompanyInfo(input: $input, condition: $condition) {
      group
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyInfo = /* GraphQL */ `
  mutation DeleteCompanyInfo(
    $input: DeleteCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    deleteCompanyInfo(input: $input, condition: $condition) {
      group
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      settingName
      settingValue
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      settingName
      settingValue
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      settingName
      settingValue
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClientSettings = /* GraphQL */ `
  mutation CreateClientSettings(
    $input: CreateClientSettingsInput!
    $condition: ModelClientSettingsConditionInput
  ) {
    createClientSettings(input: $input, condition: $condition) {
      id
      group
      modules
      db_string
      session_timeout
      sso_callback_url
      sso_signout_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientSettings = /* GraphQL */ `
  mutation UpdateClientSettings(
    $input: UpdateClientSettingsInput!
    $condition: ModelClientSettingsConditionInput
  ) {
    updateClientSettings(input: $input, condition: $condition) {
      id
      group
      modules
      db_string
      session_timeout
      sso_callback_url
      sso_signout_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientSettings = /* GraphQL */ `
  mutation DeleteClientSettings(
    $input: DeleteClientSettingsInput!
    $condition: ModelClientSettingsConditionInput
  ) {
    deleteClientSettings(input: $input, condition: $condition) {
      id
      group
      modules
      db_string
      session_timeout
      sso_callback_url
      sso_signout_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoalsAndProgress = /* GraphQL */ `
  mutation CreateGoalsAndProgress(
    $input: CreateGoalsAndProgressInput!
    $condition: ModelGoalsAndProgressConditionInput
  ) {
    createGoalsAndProgress(input: $input, condition: $condition) {
      id
      parent_id
      group
      owner
      name
      description
      priority
      category
      status
      start_date
      end_date
      goal_type
      assigned_groups
      assigned_users
      related_items
      sdg_num
      sdg_indicators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoalsAndProgress = /* GraphQL */ `
  mutation UpdateGoalsAndProgress(
    $input: UpdateGoalsAndProgressInput!
    $condition: ModelGoalsAndProgressConditionInput
  ) {
    updateGoalsAndProgress(input: $input, condition: $condition) {
      id
      parent_id
      group
      owner
      name
      description
      priority
      category
      status
      start_date
      end_date
      goal_type
      assigned_groups
      assigned_users
      related_items
      sdg_num
      sdg_indicators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoalsAndProgress = /* GraphQL */ `
  mutation DeleteGoalsAndProgress(
    $input: DeleteGoalsAndProgressInput!
    $condition: ModelGoalsAndProgressConditionInput
  ) {
    deleteGoalsAndProgress(input: $input, condition: $condition) {
      id
      parent_id
      group
      owner
      name
      description
      priority
      category
      status
      start_date
      end_date
      goal_type
      assigned_groups
      assigned_users
      related_items
      sdg_num
      sdg_indicators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAuditManagement = /* GraphQL */ `
  mutation CreateAuditManagement(
    $input: CreateAuditManagementInput!
    $condition: ModelAuditManagementConditionInput
  ) {
    createAuditManagement(input: $input, condition: $condition) {
      id
      group
      owner
      name
      description
      priority
      status
      start_date
      end_date
      audit_type
      assigned_groups
      assigned_users
      audit_items
      audit_settings
      email_subject
      email_body
      reminder_interval
      last_contacted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuditManagement = /* GraphQL */ `
  mutation UpdateAuditManagement(
    $input: UpdateAuditManagementInput!
    $condition: ModelAuditManagementConditionInput
  ) {
    updateAuditManagement(input: $input, condition: $condition) {
      id
      group
      owner
      name
      description
      priority
      status
      start_date
      end_date
      audit_type
      assigned_groups
      assigned_users
      audit_items
      audit_settings
      email_subject
      email_body
      reminder_interval
      last_contacted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuditManagement = /* GraphQL */ `
  mutation DeleteAuditManagement(
    $input: DeleteAuditManagementInput!
    $condition: ModelAuditManagementConditionInput
  ) {
    deleteAuditManagement(input: $input, condition: $condition) {
      id
      group
      owner
      name
      description
      priority
      status
      start_date
      end_date
      audit_type
      assigned_groups
      assigned_users
      audit_items
      audit_settings
      email_subject
      email_body
      reminder_interval
      last_contacted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskManagement = /* GraphQL */ `
  mutation CreateTaskManagement(
    $input: CreateTaskManagementInput!
    $condition: ModelTaskManagementConditionInput
  ) {
    createTaskManagement(input: $input, condition: $condition) {
      id
      group
      owner
      assignee_group
      owner_group
      description
      focus_area
      priority
      status
      name
      task_type
      start_date
      end_date
      task_ids
      reminder_interval
      surveyManagementID
      surveyManagement {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskManagement = /* GraphQL */ `
  mutation UpdateTaskManagement(
    $input: UpdateTaskManagementInput!
    $condition: ModelTaskManagementConditionInput
  ) {
    updateTaskManagement(input: $input, condition: $condition) {
      id
      group
      owner
      assignee_group
      owner_group
      description
      focus_area
      priority
      status
      name
      task_type
      start_date
      end_date
      task_ids
      reminder_interval
      surveyManagementID
      surveyManagement {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskManagement = /* GraphQL */ `
  mutation DeleteTaskManagement(
    $input: DeleteTaskManagementInput!
    $condition: ModelTaskManagementConditionInput
  ) {
    deleteTaskManagement(input: $input, condition: $condition) {
      id
      group
      owner
      assignee_group
      owner_group
      description
      focus_area
      priority
      status
      name
      task_type
      start_date
      end_date
      task_ids
      reminder_interval
      surveyManagementID
      surveyManagement {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurveyManagement = /* GraphQL */ `
  mutation CreateSurveyManagement(
    $input: CreateSurveyManagementInput!
    $condition: ModelSurveyManagementConditionInput
  ) {
    createSurveyManagement(input: $input, condition: $condition) {
      id
      group
      owner
      survey_type
      status
      name
      survey_order
      entityIds
      description
      popup_title
      info_message
      search_field_label
      search_field_label_value
      table_intro_text
      defaultMetaDataSheetId
      email_subject
      email_body
      email_reminder_subject
      email_reminder_body
      reminder_interval
      TaskManagementID
      TaskManagement {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      taskDeployments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyManagement = /* GraphQL */ `
  mutation UpdateSurveyManagement(
    $input: UpdateSurveyManagementInput!
    $condition: ModelSurveyManagementConditionInput
  ) {
    updateSurveyManagement(input: $input, condition: $condition) {
      id
      group
      owner
      survey_type
      status
      name
      survey_order
      entityIds
      description
      popup_title
      info_message
      search_field_label
      search_field_label_value
      table_intro_text
      defaultMetaDataSheetId
      email_subject
      email_body
      email_reminder_subject
      email_reminder_body
      reminder_interval
      TaskManagementID
      TaskManagement {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      taskDeployments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyManagement = /* GraphQL */ `
  mutation DeleteSurveyManagement(
    $input: DeleteSurveyManagementInput!
    $condition: ModelSurveyManagementConditionInput
  ) {
    deleteSurveyManagement(input: $input, condition: $condition) {
      id
      group
      owner
      survey_type
      status
      name
      survey_order
      entityIds
      description
      popup_title
      info_message
      search_field_label
      search_field_label_value
      table_intro_text
      defaultMetaDataSheetId
      email_subject
      email_body
      email_reminder_subject
      email_reminder_body
      reminder_interval
      TaskManagementID
      TaskManagement {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      taskDeployments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskDeployment = /* GraphQL */ `
  mutation CreateTaskDeployment(
    $input: CreateTaskDeploymentInput!
    $condition: ModelTaskDeploymentConditionInput
  ) {
    createTaskDeployment(input: $input, condition: $condition) {
      id
      group
      owner
      email
      last_contacted
      priority
      status
      description
      completion_date
      entityId
      surveyManagementID
      assignedGroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskDeployment = /* GraphQL */ `
  mutation UpdateTaskDeployment(
    $input: UpdateTaskDeploymentInput!
    $condition: ModelTaskDeploymentConditionInput
  ) {
    updateTaskDeployment(input: $input, condition: $condition) {
      id
      group
      owner
      email
      last_contacted
      priority
      status
      description
      completion_date
      entityId
      surveyManagementID
      assignedGroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskDeployment = /* GraphQL */ `
  mutation DeleteTaskDeployment(
    $input: DeleteTaskDeploymentInput!
    $condition: ModelTaskDeploymentConditionInput
  ) {
    deleteTaskDeployment(input: $input, condition: $condition) {
      id
      group
      owner
      email
      last_contacted
      priority
      status
      description
      completion_date
      entityId
      surveyManagementID
      assignedGroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConsultantSettings = /* GraphQL */ `
  mutation CreateConsultantSettings(
    $input: CreateConsultantSettingsInput!
    $condition: ModelConsultantSettingsConditionInput
  ) {
    createConsultantSettings(input: $input, condition: $condition) {
      id
      group
      client_group
      access_type
      access_start
      access_end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConsultantSettings = /* GraphQL */ `
  mutation UpdateConsultantSettings(
    $input: UpdateConsultantSettingsInput!
    $condition: ModelConsultantSettingsConditionInput
  ) {
    updateConsultantSettings(input: $input, condition: $condition) {
      id
      group
      client_group
      access_type
      access_start
      access_end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConsultantSettings = /* GraphQL */ `
  mutation DeleteConsultantSettings(
    $input: DeleteConsultantSettingsInput!
    $condition: ModelConsultantSettingsConditionInput
  ) {
    deleteConsultantSettings(input: $input, condition: $condition) {
      id
      group
      client_group
      access_type
      access_start
      access_end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJobExecutionStatus = /* GraphQL */ `
  mutation CreateJobExecutionStatus(
    $input: CreateJobExecutionStatusInput!
    $condition: ModelJobExecutionStatusConditionInput
  ) {
    createJobExecutionStatus(input: $input, condition: $condition) {
      id
      group
      owner
      status
      functionName
      resultSummary
      requestParams
      completionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJobExecutionStatus = /* GraphQL */ `
  mutation UpdateJobExecutionStatus(
    $input: UpdateJobExecutionStatusInput!
    $condition: ModelJobExecutionStatusConditionInput
  ) {
    updateJobExecutionStatus(input: $input, condition: $condition) {
      id
      group
      owner
      status
      functionName
      resultSummary
      requestParams
      completionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJobExecutionStatus = /* GraphQL */ `
  mutation DeleteJobExecutionStatus(
    $input: DeleteJobExecutionStatusInput!
    $condition: ModelJobExecutionStatusConditionInput
  ) {
    deleteJobExecutionStatus(input: $input, condition: $condition) {
      id
      group
      owner
      status
      functionName
      resultSummary
      requestParams
      completionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAIOverlay = /* GraphQL */ `
  mutation CreateAIOverlay(
    $input: CreateAIOverlayInput!
    $condition: ModelAIOverlayConditionInput
  ) {
    createAIOverlay(input: $input, condition: $condition) {
      id
      app_page
      app_page_subfield
      name
      description
      field
      status
      prompt_engineering
      system_prompt
      settings
      model_name
      config
      group
      roles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAIOverlay = /* GraphQL */ `
  mutation UpdateAIOverlay(
    $input: UpdateAIOverlayInput!
    $condition: ModelAIOverlayConditionInput
  ) {
    updateAIOverlay(input: $input, condition: $condition) {
      id
      app_page
      app_page_subfield
      name
      description
      field
      status
      prompt_engineering
      system_prompt
      settings
      model_name
      config
      group
      roles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAIOverlay = /* GraphQL */ `
  mutation DeleteAIOverlay(
    $input: DeleteAIOverlayInput!
    $condition: ModelAIOverlayConditionInput
  ) {
    deleteAIOverlay(input: $input, condition: $condition) {
      id
      app_page
      app_page_subfield
      name
      description
      field
      status
      prompt_engineering
      system_prompt
      settings
      model_name
      config
      group
      roles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAIConversations = /* GraphQL */ `
  mutation CreateAIConversations(
    $input: CreateAIConversationsInput!
    $condition: ModelAIConversationsConditionInput
  ) {
    createAIConversations(input: $input, condition: $condition) {
      id
      group
      owner
      overlay_id
      prompt
      response
      error_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAIConversations = /* GraphQL */ `
  mutation UpdateAIConversations(
    $input: UpdateAIConversationsInput!
    $condition: ModelAIConversationsConditionInput
  ) {
    updateAIConversations(input: $input, condition: $condition) {
      id
      group
      owner
      overlay_id
      prompt
      response
      error_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAIConversations = /* GraphQL */ `
  mutation DeleteAIConversations(
    $input: DeleteAIConversationsInput!
    $condition: ModelAIConversationsConditionInput
  ) {
    deleteAIConversations(input: $input, condition: $condition) {
      id
      group
      owner
      overlay_id
      prompt
      response
      error_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
