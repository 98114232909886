import React, { useState, useEffect } from "react";
import {Upload,message, Col, Row ,Popconfirm  } from 'antd'
import { Storage, Auth } from 'aws-amplify';
import { StyledButton } from "./../../../../shared/commonStyles";

const UpdateIcon = () =>{
    const [e_logo, setLogo] = useState();
    const [imageLoader, setImageLoader] = useState(false);
    const [noImage, setNoImage] = useState(false);
    const getLogoFile = async () => {
        try {
          const data = await Auth.currentSession()
          const groups = data['accessToken']['payload']['cognito:groups'].filter(element => element.includes("org:"))[0];
          const a_logo = await Storage.get(`icons/${groups.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`)

          setLogo(a_logo)
        } catch (error) {
          console.log("Error while load header icon", error);
        }
    }
    useEffect(() => {        
        getLogoFile()
    }, []) 

    const deleteLogo = async () => {
        const data = await Auth.currentSession()
        const groups= data['accessToken']['payload']['cognito:groups'].filter(element => element.includes("org:"))[0];  
        const resp = await Storage.remove(`icons/${groups.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`);
    }
    const UploadToS3 = async (file) => {        
        const data = await Auth.currentSession()
        const groups= data['accessToken']['payload']['cognito:groups'].filter(element => element.includes("org:"))[0];  
        const resp = await Storage.put(`icons/${groups.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`, file, {contentType: file.type});
        const a_logo = await Storage.get(`icons/${groups.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`);
        setNoImage(false)
        setLogo(a_logo);
        
        return resp
    }
    const onUploadProfileImage = async (file) => {        
        if (file) {
          try {
            
            setImageLoader(true)
            const validExtensions = ['png','jpg','jpeg'];
            const extension = file.name.split(".")?.pop()?.toLowerCase();
            if (!validExtensions.includes(extension) > 0) {
              message.error(`${file.name} is not a valid file type, valid file types are png, jpg, jpeg`);
              return false;
            }
            await UploadToS3(file);
            message.success('Icon uploaded successfully. Please wait for refresh…');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            return false;
          } catch (error) {
            message.error(`Some problem occured while uploading image ${error}`)
            return false;
          }finally{
            setImageLoader(false)
          }
        }
      }
    const confirm = (e) => {
        console.log(e);
        const resp = deleteLogo();
        if(resp){
            message.success('Icon removed successfully. Please wait for refresh…');
            setNoImage(true)
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }

    };
    const onError = () => {
        setNoImage(true);
    };
      
      const cancel = (e) => {
        message.error('Icon not removed');
      };    
   return  (
        <div style={{display:"flex", paddingTop:'20px'}}>                
           <Row gutter={[16]}>
                <Col span={12} style={{display:'flex'}}>
                    <>
                        {noImage ? 
                            <p className="color-white" style={{margin:'auto'}}>No Icon Found</p>
                        :
                            <img src={e_logo} style={{maxWidth:'100%', margin:'auto'}} onError={onError} />
                        }
                    </>
                </Col>
                <Col span={6}>
                    <Upload
                        customRequest={({ file, onSuccess }) => {
                        onSuccess("ok");
                        }}
                        beforeUpload={onUploadProfileImage}
                        fileList={[]}
                        className="color-white"
                        multiple={false}
                    >
                        <StyledButton
                            type="custom"
                            ghost
                            hovercolor="#A68DFB"
                            hoverbgcolor="transparent"
                            bgcolor="transparent"
                            bordercolor="transparent"
                            color="#fff"
                            loading={imageLoader}
                        >
                            Update
                        </StyledButton>
                    </Upload>
                </Col>
                <Col span={6}>
                    {!noImage &&
                        <Popconfirm
                            title="Are you sure to delete this icon?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <StyledButton
                                type="custom"
                                hovercolor="#A68DFB"
                                hoverbgcolor="transparent"
                                bgcolor="transparent"
                                bordercolor="transparent"
                                color="#fff"
                            >
                                Remove
                            </StyledButton>
                        </Popconfirm>
                    }
                </Col>
           </Row>
        </div>
    ) 
}

export default UpdateIcon;