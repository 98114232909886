import {
  Breadcrumb,
  Button,
  Card,
  Collapse,
  Divider,
  Empty,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Progress,
  Radio,
  Segmented,
  Select,
  Steps,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Transfer,
  Typography,
} from "antd";
import { COLORS, siteThemeColors } from "./colors";

import Styled from "styled-components";

// region notifications page
export const LatestText = Styled(Text)`
  line-height: 32px;
  color: #b1afbc;
  font-weight: bold;

  &:hover {
    color: white;
  }
`;
// end region

export const StyledModal = Styled(Modal)`
  .ant-modal-header {
    background-color: ${siteThemeColors.ModalColor.bgColor} !important;
    align-items: 'center' !important;
  }

  .ant-modal-title {
    color: ${siteThemeColors.ModalColor.color};
  }

  .ant-modal-body {
    padding-top: 5px;
  }

  .ant-modal-close-icon svg {
    color: ${siteThemeColors.ModalColor.color} !important;

  }
  .ant-btn{
    color : ${siteThemeColors.ButtonColor.defaultColor};
    background-color :  ${siteThemeColors.ButtonColor.defaultBgColor};
    border-color :  ${siteThemeColors.ButtonColor.defaultColor};
  }
  .ant-btn:hover{
    border-color : ${siteThemeColors.ButtonColor.defaultHoverColor}; 
    color : ${siteThemeColors.ButtonColor.defaultHoverColor};
    background-color :  ${siteThemeColors.ButtonColor.defaultHoverBgColor};
  }

  .ant-btn-primary:not(:disabled){
  color : ${siteThemeColors.ButtonColor.color};
  background-color :  ${siteThemeColors.ButtonColor.bgColor};
  border-color :  ${siteThemeColors.ButtonColor.color};
  }

  .ant-btn-primary:hover:not(:disabled) {
  border-color : ${siteThemeColors.ButtonColor.hoverColor}; 
  color : ${siteThemeColors.ButtonColor.hoverColor};
  background-color :  ${siteThemeColors.ButtonColor.hoverBgColor};
  }
`;

export const CommonModal = Styled(Modal)`
  .preview-left-part{
    background:${siteThemeColors.ModalColor.bgHeaderColor};
  }
  .preview-right-part{
    border: 1px solid #3A3C5A;
    box-shadow: 8px 7px 19px 3px rgba(0, 0, 0, 0.08);
    padding:15px;
  }
  .ant-modal-content {
    font-family: "Roboto", sans-serif;
    background: ${siteThemeColors.ModalColor.bgColor};
    color: ${siteThemeColors.ModalColor.color};
  }
  .ant-typography{
    color:${siteThemeColors.ModalColor.color};
    font-weight: normal;
  }
  .ant-modal-header {
    background: ${siteThemeColors.ModalColor.bgHeaderColor};
    border: none;
    color: ${siteThemeColors.ModalColor.color};
    padding-top: 20px;
    padding-left: 40px;

  }
  .ant-modal-title {
    color: ${siteThemeColors.ModalColor.color};    
  }
  .ant-modal-close {
    padding-right: 20px;
    border-bottom: none;
    color: ${siteThemeColors.ModalColor.color};
  }
  .ant-modal-body {
    padding-right: 40px;
    padding-left: 40px;
  }
  .ant-modal-footer {
    border-top: 1px solid #3A3C5A;
    padding: 10px 16px;
  }
  .ant-modal-footer .ant-btn {
    border-radius: 2px !important;
  }
  .ant-modal-footer .ant-btn-text {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .ant-modal-footer .ant-btn-background-ghost {
    background: transparent !important;
    color: ${siteThemeColors.ModalColor.color} !important;
    border: none;
    border-color: ${siteThemeColors.ModalColor.color} !important;
  }
  .ant-modal-footer .ant-btn-background-ghost:hover, .ant-modal-footer .ant-btn-background-ghost:focus {
    color: ${siteThemeColors.ModalColor.bgButtonColor} !important;
    border-color: ${siteThemeColors.ModalColor.bgButtonColor} !important;
  }
  .ant-modal-footer .ant-btn-primary {
    background: ${siteThemeColors.ModalColor.bgButtonColor} !important;
    border: none;    
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .ant-modal-footer .ant-btn-primary:hover, .ant-modal-footer .ant-btn-primary:focus {
    background: ${siteThemeColors.ModalColor.bgButtonHoverColor} !important;
    border: none;
  }
  .ant-modal-footer .ant-btn-default  {
    color: ${siteThemeColors.ModalColor.color};
    background: transparent;
    border: none;
  }
  .ant-modal-footer .ant-btn-default:hover, .ant-modal-footer .ant-btn-default:focus {
    color: ${siteThemeColors.ModalColor.bgButtonColor} !important;
    background: transparent !important;
  }
  .ant-modal-footer .ant-btn-primary[disabled],
  .ant-modal-footer .ant-btn-primary[disabled]:hover,
  .ant-modal-footer .ant-btn-primary[disabled]:focus,
  .ant-modal-footer .ant-btn-primary[disabled]:active {
    background: ${siteThemeColors.ModalColor.disabledBgColor} !important;
    color: ${siteThemeColors.ModalColor.disabledColor} !important;
} 
  
`;

export const Center = Styled.div`
  display: flex; 
  justify-content : center;
  align-items: center;
  width: 100%;
`;

export const HeadingWrap = Styled.div`
  display: flex; 
  justify-content : space-between;
  align-items: baseline;
  width: 100%;
`;

export const IconWrap = Styled.div`
  cursor:pointer;
  display:flex;
  align-items:center;
`;

export const StyledButton = Styled(Button)`
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  cursor : pointer;
  font-weight:   ${(props) =>
    props.fontWeight ? props.fontWeight : "normal !important"};
  border-radius: 4px;
  padding:${(props) =>
    props.padding ? props.padding : "11px 18px"} !important;
  height:${(props) => (props.height ? props.height : "auto")} ;
  border-radius:${(props) => (props.borderRadius ? props.borderRadius : 0)};
  width:${(props) => props.width || "auto"};
  color : ${(props) =>
    props.color
      ? props.color
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultColor
      : siteThemeColors.ButtonColor.color} !important;
  background-color :  ${(props) =>
    props.bgcolor
      ? props.bgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultBgColor
      : siteThemeColors.ButtonColor.bgColor} !important;
  border-color :  ${(props) =>
    props.bordercolor
      ? props.bordercolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultBgColor
      : siteThemeColors.ButtonColor.bgColor} !important;
  :hover{
    border-color : ${(props) =>
      props.hoverbgcolor
        ? props.hoverbgcolor === "transparent"
          ? props.bordercolor
          : props.hoverbgcolor
        : props.type === "default"
        ? siteThemeColors.ButtonColor.defaultHoverColor
        : siteThemeColors.ButtonColor.hoverBgColor} !important; 
    color : ${(props) =>
      props.hovercolor
        ? props.hovercolor === "transparent"
          ? props.color
          : props.hovercolor
        : props.type === "default"
        ? siteThemeColors.ButtonColor.defaultColor
        : siteThemeColors.ButtonColor.hoverColor} !important;
    background-color :  ${(props) =>
      props.hoverbgcolor
        ? props.hoverbgcolor
        : props.type === "default"
        ? siteThemeColors.ButtonColor.defaultHoverBgColor
        : siteThemeColors.ButtonColor.hoverBgColor} !important;
  }
  :focus{
  border-color : ${(props) =>
    props.hoverbgcolor
      ? props.hoverbgcolor === "transparent"
        ? props.bordercolor
        : props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultHoverColor
      : siteThemeColors.ButtonColor.hoverBgColor} !important; 
   border: 1px solid ${(props) =>
     props.bordercolor && props.hoverbgcolor === "transparent"
       ? props.bordercolor
       : "transparent"} !important; 

  color : ${(props) =>
    props.hovercolor
      ? props.hovercolor === "transparent"
        ? props.color
        : props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultColor
      : siteThemeColors.ButtonColor.hoverColor} !important;
  background-color :  ${(props) =>
    props.hoverbgcolor
      ? props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultHoverBgColor
      : siteThemeColors.ButtonColor.hoverBgColor} !important;
  }
  :active{
  border-color : ${(props) =>
    props.hoverbgcolor
      ? props.hoverbgcolor === "transparent"
        ? props.bordercolor
        : props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultHoverColor
      : siteThemeColors.ButtonColor.hoverBgColor} !important; 
  color : ${(props) =>
    props.hovercolor
      ? props.hovercolor === "transparent"
        ? props.color
        : props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultColor
      : siteThemeColors.ButtonColor.hoverColor} !important;
  background-color :  ${(props) =>
    props.hoverbgcolor
      ? props.hoverbgcolor
      : props.type === "default"
      ? siteThemeColors.ButtonColor.defaultHoverBgColor
      : siteThemeColors.ButtonColor.hoverBgColor} !important;
      
  
}
`;
export const StyledHeader = Styled(Layout.Header)`
  padding: 0 28px;
  height: 80px;
  background: ${siteThemeColors.headerBgColor};
`;

export const HeaderWrapper = Styled.div`
  padding-right: 0;
  color:#ffffff;
  display:flex;
  justify-content:end;
  align-items:center;
  height:inherit;
`;

export const CustomCard = Styled(Card)`
 border : 1px solid lightgrey;
 min-height : 320px;
 background: #2D273F;
 color: ${siteThemeColors.commonColor.color};
.ant-card-head{
    background-color : #362E4B;
    color :  ${COLORS.white};
}
 position:relative;
`;

export const LogoWrapper = Styled.div`  
  z-index: 10000;
  text-align: center;
  position: fixed;
  padding: 14px;
  left:5px;
  top: 10px;
`;
export const StyledSider = Styled(Layout.Sider)`
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background : ${siteThemeColors.sideBarColors.mainBgColor};
    min-width:70px !important;
    width:70px !important;
    max-width:70px !important;
    z-index : 100;
`;

export const StyledHeader1 = Styled(Layout.Header)`
  background: ${siteThemeColors.headerBgColor};
`;

export const DetailsWrap = Styled.div`
   width: 100%;
   background-color: #ffffff;
   border-radius: 15px;
   padding: 20px;
   margin: 10px;
   min-height:400px;
`;

export const StyledMenu = Styled(Menu)`
  margin-top:76px;
  background-color : ${siteThemeColors.sideBarColors.menuBackground} !important;
`;
export const UserFlowViewStyle = Styled.div`
  background: #3C3453;
`;

export const TableStyledMenu = Styled(Menu)`
  background-color : #53476C;
  .ant-dropdown-menu-item{
    background-color : #A68DFB;
    color : #ffffff;
  }
`;
export const TableCommonMenu = Styled(Menu)`
  display: flex;
  flex-direction: column;
  background-color: #443A59 !important;
  padding: 0;
  .ant-dropdown-menu-item{
    background-color: transparent !important;
    padding: 0  !important;
  }
  .ant-dropdown-menu-item:focus {
  background: #A68DFB !important;
  color: #fff !important;
}

.ant-menu-title-content, .ant-menu-submenu-arrow{
    color: #fff !important;

  }
  button {
    background-color: transparent !important;
    border: none;
    color: #fff !important;
    width: 100%;        
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
  button:focus, button:hover {
      background: #A68DFB !important;
      color: #fff !important;
  }
  button svg {
      margin-right: 10px;
    }
    .ant-menu-item-selected, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #A68DFB !important;
      color: #fff !important;
    }
`;

export const StyledSubMenu = Styled(Menu.SubMenu)`
  ul.ant-menu.ant-menu-sub{
     background-color : ${siteThemeColors.sideBarColors.subMenuBackground} !important;
  }
`;

export const ContentWrapper = Styled(Layout.Content)`
  background: ${COLORS.backgroundDark};
  font-family: "Roboto", sans-serif;
  .ant-page-header {
    padding-left:95px;
    background-color: transparent;
    color: #fff;
    }
    .ant-page-header-heading-title {
    color: #ffffff;
    }
`;

export const ContentSection = Styled.div`
  color: white;
  padding: 1.5rem 25px 1.5rem 95px;
`;

export const AppWrapper = Styled.div`
  color:#fff;
  font-size:16px;
`;

export const AppRowContainer = Styled.div`
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const BackgroundDiv = Styled.div`
  background-color: rgba(187, 187, 187, 0.09);
`;

export const PaddingDiv = Styled.div`
  padding:20px;
`;

export const CustomPageHeader = Styled(PageHeader)`
   padding : 20px 24px;
   background-color: ${siteThemeColors.PageHeaderColor.bgColor} !important;
   color: ${siteThemeColors.PageHeaderColor.bgColor};
   .ant-page-header-typ-sub-title{color: #fff}}
`;

export const DashboardPageWrap = Styled.div`
   padding : 20px 25px 20px 95px;   
   min-height : 100vh;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`;

export const CardsWrap = Styled.div`
  display: flex;
  flex-wrap : wrap;
`;

export const CommonCard = Styled.div`
  font-size: 18px;
  font-weight: 600;
  background-color: #5d4985ba;
  border-radius: 8px;
  height: 180px;
  padding: 16px;
  transform: scale(1);
  transition: transform .3s;
  
  &:hover {
    cursor: pointer;
    box-shadow: 10px 10px 11px rgba(33,33,33,.2);
    transform: scale(1.05);
  }
  
  a {
    color: white;
    
    .card-type {
      font-size: 12px;
      text-transform: uppercase;
    }
    .card-title {
      line-height: 20px;
    }
    .card-icon {
      font-size: 30px;
    }
  }
`;

export const FlexWrapCard = Styled(CommonCard)`
  width: 180px;
  margin: 10px;
`;

export const PageWrap = Styled.div`
   padding: ${(props) => props?.padding || "20px 32px 20px 100px"} ;   
   min-height : 100vh;   
   background: ${COLORS.backgroundDark};
   font-family: Roboto;
`;

export const PageWrapDark = Styled.div`
   padding : 20px 32px 20px 95px;   
   min-height : 100vh;   
   font-family: Roboto;
   background-color: #1D1729;
`;

export const Section = Styled.div`
  margin-bottom:30px;
`;

export const CustomMenu = Styled(Menu)`
  .ant-dropdown-menu {
    background-color: #53476C !important;
  }
  .ant-dropdown-menu-item {
    color: #fff;
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-item-selected {
    background: #A68DFB;
  }
`;

export const CustomTable = Styled(Table)`
 .ant-table-title{
   background-color : ${COLORS.purpleMagenta};
   color :  ${COLORS.white};
 }
 
 .ant-pagination-item-active {   
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item-active a {
  color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: ${siteThemeColors.TablePagintionColor.bgColor};
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color:${siteThemeColors.TablePagintionColor.bgColor};
}
`;

export const TransparentCustomTable = Styled(Table)`
  .ant-table {
    background-color: transparent !important;
    color:#fff;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding:10px 10px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }
  .ant-pagination-item-active {   
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item-active a {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color:${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-radio-wrapper, .ant-checkbox-wrapper {
    color: #fff;
  }
`;

export const TableHeaderRightButtonWrap = Styled.div`
  display : flex;
  align-items : center;
  justify-content: space-between;
`;

export const TableHeaderRightTabWrap = Styled.div`
  display : flex;
  align-items : right;
  justify-content: space-between;
`;

export const FilterCardsWrap = Styled.div`
  display: flex;
  flex-wrap : wrap;
  margin-top : 15px;
  margin-bottom : 15px;
`;

export const StyledProgress = Styled(Progress)`
  .ant-progress-text{
    color : ${(props) => props?.color || "#ffffff"};
  }
   .anticon svg  {
        color: ${(props) => props?.tickcolor || "#ffffff"}!important;
      }
  .ant-progress-success-bg, .ant-progress-bg {
    background-color : ${(props) => props?.color || "#A68DFB"};
  }
  .ant-progress-inner {
    background: #716A88;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: ${(props) => props?.color || "#A68DFB"};
  }
`;

export const ChartProgress = Styled(Progress)`
  .ant-progress-text{
    color : #ffffff;
  }
  .anticon svg {
    color: #ffffff !important;
  }
  .ant-progress-success-bg, .ant-progress-bg {
    background-color: #A68DFB;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-trail {
    stroke:  #403655;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke:  #A68DFB;
  }
`;

export const StyledSegmented = Styled(Segmented)`
  background-color: #625D75;
  color: #fff;
  :hover{
    background-color: #625D75 !important;
  }
  .ant-segmented-item-label {
    color: #fff;
  }
  .ant-segmented-item-selected, .ant-segmented-item-label:hover, .ant-segmented-thumb {
    background: #A68DFB;
  }
`;

export const AfterFilterWrap = Styled.div`
  margin : 20px 0px 21px 0px;
  @media (max-width: 768px) {
    margin : 0px;
  } 
`;

export const TableWrap = Styled.div`
  margin : 20px 0px 21px 0px;
  @media (max-width: 768px) {
    margin : 0px;
  } 
`;

export const TranserWrapper = Styled.div`
  width : 100%;
  display : flex;
  justify-content: center;
  overflow-x : auto;
`;

export const ButtonsContainer = Styled.div`
   display : flex;
   justify-content: center;
   align-items: center;
   width:100%;
   height:100%;
`;

export const CustomTag = Styled(Tag)`
    padding : 5px;
    border-radius : 15px;
    color : #ffffff;
    cursor: pointer;
    min-width: 50px;
    text-align: center;
    background-color: ${(props) =>
      props?.status === "active" ? "#52C41A" : "#F5222D"};
    font-size : 14px;
`;

export const StyledTag = Styled(Tag)`    
  background-color: ${(props) =>
    props.bgcolor
      ? props.bgcolor
      : siteThemeColors.TagColor.bgColor} !important;
  color: ${(props) =>
    props?.color ? props?.color : siteThemeColors.TagColor.color} !important;
  border-radius: ${(props) =>
    props?.borderRadius ? props?.borderRadius : "15px"} !important;
  border: none;
  cursor:pointer;
  .anticon svg {
    color: ${siteThemeColors.TagColor.color};
  }

`;

export const PriorityTag = Styled(Tag)`
  padding : 5px;
  border-radius : 15px;
  color : #ffffff;
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  background-color: ${(props) =>
    props.priority === 1
      ? "#F5222D"
      : props.priority === 2
      ? "#FBDB14"
      : "#52C41A"};
  font-size : 14px;
`;

export const StyledCollapse = Styled(Collapse)`
    
  background-color: transparent;
  border: none;
  border-bottom: 0;
  border-radius: 0;
  .ant-collapse-item > .ant-collapse-header{
     background-color : #1F182C;
     color :  #fff;
     border : none;
     padding: 12px 0;
  }
  .ant-collapse-item{
    background-color: #1F182C;
    border: 1px solid #1F182C;
    border-radius: 8px !important;
    border:none;
    padding:12px;
    margin-top:15px;
  } 
  .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: #1F182C;
    border-top: 1px solid #rgb(58, 60, 90);
    padding: 12px 0;
  } 
  .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    width: 60%;
    justify-content: flex-end;
  }
`;

export const StyledCollapseForGoals = Styled(Collapse)`
    
  background-color: transparent;
  border: none;
  border-bottom: 0;
  border-radius: 0;
  .ant-collapse-item > .ant-collapse-header{
     background-color : #4D4265;
     color :  #fff;
     border : none;
     padding: 12px;
  }
  .ant-collapse-item{
    background-color: transparent;
    border: 1px solid #1F182C;
    border-radius: 8px !important;
    border:none;
    padding:0;
    margin-top:15px;
  } 
  .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color:transparent;
    border-top: none;
    padding: 12px 0;
  } 
  .ant-collapse-content > .ant-collapse-content-box{ padding:0 }
  .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    width: 60%;
    justify-content: flex-end;
  }
`;

export const AccountPageInfoWrap = Styled.div`
  background: #fff;
  padding: 25px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;    
`;

export const FiltersContainer = Styled.div`
   // background-color: #2c2149;
   color : #000;
   width: 100%;
   padding-bottom: 10px;
`;

export const FiltersWrap = Styled.div`
   display : flex;
   flex-wrap : wrap;
`;

export const FilterButtonsWrap = Styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: flex-end;
`;

export const BottomSection = Styled.div`
  padding : 10px;
`;

export const StyledFilterButton = Styled(Button)`
  margin:5px;
  cursor : pointer;
  border : 1px solid ${COLORS?.white};
  color : ${COLORS?.white};
  background-color :  ${COLORS?.purple7};
   :hover{
  border-color : ${COLORS?.white}; 
  color : ${COLORS?.purple7};
   }
   :focus{
    border-color : ${COLORS?.white}; 
    color : ${COLORS?.purple7};
   }
   :active{
    border-color : ${COLORS?.white}; 
    color : ${COLORS?.purple7};
   }
`;
export const StyledSelect = Styled(Select)`
  .ant-select-selector{    
     border : none;
     .ant-select-selection-placeholder{
      color: #000;
     }
  }
  .ant-select-arrow{
    color: #000;
  }
`;

export const CommonSelect = Styled(Select)`
  .ant-select-selector{
    border-color: ${(props) =>
      props.borderColor
        ? props.borderColor
        : siteThemeColors.FormColor.borderColor} !important;
    background-color:  ${(props) =>
      props.bgColor
        ? props.bgColor
        : siteThemeColors.FormColor.bgFormColor} !important;
    min-height :${(props) => (props.minHeight ? props.minHeight : "32px")};
    display: flex;
    align-items: center; 
    color: #fff !important; 
    border: 1px solid;
   
  }
  .ant-select-selector:hover{
    border-color: ${(props) =>
      props.borderColor
        ? props.borderColor
        : siteThemeColors.FormColor.hoverBorderColor} !important;
  }
  .ant-select-selection-item-content{
    color: ${(props) =>
      props.selectionItemContent
        ? `${props.selectionItemContent};`
        : "#fff !important;"}
  .ant-select-selection-placeholder{
    color: #fff;
  }
  .ant-select-arrow  {
    color: #fff !important; 
  }
  .ant-select-multiple .ant-select-selection-item{

  }
`;
export const CalcSelect = Styled(Select)`
  .ant-select-selection-item-content{
    color: ${(props) =>
      props.selectionItemContent
        ? `${props.selectionItemContent};`
        : "#fff !important;"}
        
`;

export const TabContentWrap = Styled.div`
   padding: 0;
`;
export const ButtonWrapper = Styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RightSideWrap = Styled.div`
   padding:1px 20px 20px 20px;
   width:100%;
   height:100%;
   background: #3C3451;
`;

export const VideoHeader = Styled.div`
  margin-top:15px;
  margin-bottom:15px;
  font-size : 24px;
  display: flex;
  justify-content: space-between;
`;

export const OverviewContentWrap = Styled.div`
  background-color: white;
  padding: 30px;
`;

export const StyledBreadcrumbs = Styled(Breadcrumb)`
  
  .ant-breadcrumb-link{color: #fff;}
  .ant-breadcrumb-separator{color: #fff;}
`;

export const StyledTitle = Styled(Typography.Title)`
  color: #fff !important;
`;

export const StyledCard = Styled(Card)`
  border-radius: 10px !important;
  position: relative;
  .ant-card-head-title{font-weight:bold;}
  .ant-card-cover {
    padding: 4px;
    height: 250px;
    justify-content: center;
    align-items: center;
  }
  .ant-card-body{
    padding:0px 24px;
    height : 140px;
    margin-bottom: 40px;
  }

  .document-card-buttons{
    position:absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    .action-button{
   
    margin : 10px;
    }
  }
  `;

export const StyledTabs = Styled(Tabs)`
  .ant-tabs-nav::before {   
    border-bottom: none;
  }
  .ant-tabs-tab { color: #fff; font-size:16px;}
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #A68DFB;
  }
  .ant-tabs-tab:focus, .ant-tabs-tab-btn:active {
    color: #A68DFB;
  }
  .ant-tabs-ink-bar {
    background-color: #A68DFB;
  }
`;

export const ExpendableDetailsWrap = Styled.div`
  font-size:14px;
`;

export const StyledUL = Styled.ul`
  list-style-type : none;
  padding : 0px;
  margin-top: 5px;
  margin-bottom : 5px;
  li{
    display: flex;
    margin-bottom : 5px;
    strong{
      width: 10rem;
      text-transformtion: uppercase;
    }
  }
`;

export const LinkText = Styled.a`
  color : #1899FF;
  text-decoration : underline;
  text-decoration-color : #1899FF;
  margin : 2px;
  &:hover{
     color : #1899FF;
     text-decoration : underline;
     text-decoration-color : #1899FF;
  }
`;

export const BgGreyText = Styled.span`
  background-color : #D1D5DB;
  padding : 2px;
`;

export const CommonDarkTransparentTable = Styled(Table)`
.ant-table {
  background-color: transparent !important;
}
table {
  border-collapse: separate;
  border-spacing: 0 0.8em;
 }
 table thead {
   background-color : transparent;
  }
  table thead tr{
   background-color : transparent;
  }
  table thead tr th{
   background-color : transparent;
   padding-top : 0px;
   padding-bottom : 10px;
   color: ${siteThemeColors.TableColor.color};
   border: none;
  }
  .ant-table-empty .ant-table-tbody > tr td  {
    background-color: ${siteThemeColors.TableColor.bgColor} !important;    
  }
  .ant-table-empty .ant-table-tbody > tr:hover td  {
    background-color: ${siteThemeColors.TableColor.bgColorHover} !important;    
  }
  .ant-table .ant-empty-description {
    color: ${siteThemeColors.TableColor.color};
  }
  .ant-table .ant-empty-description {
    color: ${siteThemeColors.TableColor.color};
  }
  table tbody tr {
   background-color : transparent;
   padding: 10px;
   cursor:pointer;
  }
  .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${siteThemeColors.TableColor.bgColorHover};
  }
  .ant-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: ${siteThemeColors.TableColor.bgColorHover};
  }
  table tbody tr td{
   background-color: ${siteThemeColors.TableColor.bgColor};
   color: ${siteThemeColors.TableColor.color};
   border: none;
 }
  table tbody tr td:first-child
  {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
   table tbody tr td:last-child
   {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
   }
`;

export const CommonTable = Styled(Table)`    
  .ant-table {
    background-color: ${siteThemeColors.TableColor.bgColorHeader};
  }
  .ant-table-title {
    background-color: ${siteThemeColors.TableColor.bgColor};
  }
  .ant-table .ant-table-thead > tr > th {
    background-color: ${siteThemeColors.TableColor.bgColorHeader};
    color: ${siteThemeColors.TableColor.color};
    height: ${(props) =>
      props?.headerHeight ? props?.rowHeight + "px" : "auto"} !important;
    padding: ${(props) => props?.headerHeight && "5px"};
  }
  .ant-table tbody {
  background-color: ${siteThemeColors.TableColor.bgColor} !important;
  }
  .ant-table-row {
    background: ${siteThemeColors.TableColor.bgColor} ;
    color: ${siteThemeColors.TableColor.color};
  }
  .ant-table .ant-table-empty .ant-table-tbody > tr > td {
    background-color: ${siteThemeColors.TableColor.bgColor} !important;    
  }
  .ant-table .ant-empty-description {
    color: ${siteThemeColors.TableColor.color};
  }
  .ant-table-row td {
    background-color: ${siteThemeColors.TableColor.bgColor} ;
    height: ${(props) => (props?.rowHeight ? props?.rowHeight + "px" : "auto")};
    padding: ${(props) => props?.rowHeight && "0 15px"};
  }
  .ant-table .ant-table-tbody > tr.ant-table-row:hover > td{
    background-color: ${(props) =>
      props?.hoverBgColor || siteThemeColors.TableColor.bgColorHover}!important;
  }
  .ant-table .ant-table-tbody > tr > td{
    background-color: ${siteThemeColors.TableColor.bgColor};
    color: ${siteThemeColors.TableColor.color};
  }
  .ant-table tr.ant-table-row-selected > td {
    background-color: ${siteThemeColors.TableColor.bgColorHover} !important;
  }
  .ant-table-row a {
    color: ${siteThemeColors.TableColor.color};
  }
  .ant-table-column-sorters-with-tooltip {
    background: transparent;
  }
  .ant-table-cell, .ant-table-tbody > tr > td {
    border-bottom: ${(props) =>
      props?.border
        ? `${props?.border} !important`
        : `1px solid ${siteThemeColors.TableColor.bgColorHover}`};
    
  }
  .ant-table-cell, .ant-typography, .ant-timeline {
    color: ${siteThemeColors.TableColor.color};
  }

  .editable-cell-value-wrap {
    background-color: ${siteThemeColors.TableColor.editableColor};
    border: none;
    border-radius: 4px;
  }
  .ant-table-row:hover .editable-cell-value-wrap {
    border: none !important;
  }
  .ant-table-row:active .editable-cell-value-wrap {
    background: ${siteThemeColors.TableColor.editableColor};
    border: none;
    border-radius: 4px;
  }
  .editable-cell-value-wrap p {
    margin: 0;
  }
  .ant-table-cell .ant-form-item .ant-input-number {
    background: ${siteThemeColors.FormColor.bgFormColor};
    color: ${siteThemeColors.FormColor.color};
    border-color: ${siteThemeColors.FormColor.borderColor};
    border-radius: 4px;
  }
  .ant-table-cell .ant-form-item .ant-input-number:hover{border-color: ${
    siteThemeColors.FormColor.hoverBorderColor
  };}
  .ant-table-cell .ant-form-item .ant-input-number .ant-input-number-handler-wrap {
    background: ${siteThemeColors.TableColor.editableColor};
    color: ${siteThemeColors.TableColor.color};
  }

  .ant-table-filter-dropdown {
    background: ${siteThemeColors.TableColor.bgFilterColor} !important;
  }
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    background: ${siteThemeColors.TableColor.bgFilterHoverColor} !important;
    color: ${siteThemeColors.TableColor.color} !important;
  }  
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${siteThemeColors.TablePagintionColor.borderColor} !important;
  }
  
  .ant-table-pagination.ant-pagination {
    justify-content: center;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${siteThemeColors.TablePagintionColor.ellipsisColor};
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
    color: ${siteThemeColors.TablePagintionColor.borderColor};
  } 
  .ant-pagination-item {
    background: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item-active {   
    border-color: ${siteThemeColors.TablePagintionColor.borderColor};
    background: ${siteThemeColors.TablePagintionColor.bgColor};
  } 
  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item a {
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-pagination-item:focus a, .ant-pagination-item:hover a, .ant-pagination-item-active a {
    color: ${siteThemeColors.TablePagintionColor.borderColor};
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    background: ${siteThemeColors.TablePagintionColor.bgColor};
    border: none;
  }
  .ant-pagination-prev button, .ant-pagination-next button {
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-select {
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-select-show-arrow .ant-select-arrow {
    color: ${siteThemeColors.TablePagintionColor.color} !important;
  }
  .ant-select-dropdown {
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-select-item {
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-select-item:hover {
    color: ${siteThemeColors.TablePagintionColor.color};
    background-color: ${siteThemeColors.TablePagintionColor.borderColor};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {    
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
    color: ${siteThemeColors.TablePagintionColor.color};
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color:${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }

  .ant-checkbox-checked::after {    
    border: 1px solid ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {   
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-btn:hover, .ant-btn:focus {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
    background: ${siteThemeColors.TablePagintionColor.color};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-table-filter-trigger.active {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #A68DFB !important;
  }  

  .ant-table { color: ${siteThemeColors.TablePagintionColor.color};
  
  .ant-table-row-expand-icon {
    background: ${siteThemeColors.TablePagintionColor.bgColor};
  }
}

.ant-table-footer {background: ${COLORS.backgroundDark}

 };
`;
export const StyledTimeline = Styled(Timeline)`

  .ant-timeline-item-head-blue{
    background: ${siteThemeColors.TimelineColor.bgColor};
    border-color: ${siteThemeColors.TimelineColor.bgBorderColor};
  }
  .ant-timeline-item-tail{
    border-color:  ${siteThemeColors.TimelineColor.bgTailBorderColor};
  };
`;

export const StyledTable = Styled(Table)`
    
  .ant-table {
    background-color: transparent !important;
  }
 
  .ant-table .ant-table-content {
   position: relative;
 }

 table {
   // background-color : #f0f2f5;
   border-collapse: separate;
  border-spacing: 0 0.8em;
 }
 table thead {
   background-color : transparent;
  }
 
  table thead tr{
   background-color : transparent;
  }
  
  table thead tr th{
   background-color : transparent;
   padding-top : 0px;
   padding-bottom : 10px;
   color: #fff;
   border-bottom: none;
  }

  table tbody tr {
   background-color : transparent;
   padding : 10px;
   cursor:pointer;
  }
  table tbody tr:hover {
    border:1px solid red;
  }

  table tbody tr td{
   background-color: white;
 }
  table tbody tr td:first-child
  {
    border-bottom-left-radius: 21px;
    border-top-left-radius: 21px;
    background-color: white;
  }
   table tbody tr td:last-child
   {
    border-bottom-right-radius: 21px;
    border-top-right-radius: 21px;
    background-color: white;
   }

   .ant-pagination-item-active {   
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item-active a {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.bgColor};
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color:${siteThemeColors.TablePagintionColor.bgColor};
  }
   
`;

export const StyledTestTable = Styled(Table)`

.ant-table {
   background-color: transparent !important;
 }
 
.ant-table .ant-table-content {
   position: relative;
 }

 table {
   // background-color : #f0f2f5;
   border-collapse: separate;
  border-spacing: 0 0.8em;
 }
 table thead {
   background-color : transparent;
  }
 
  table thead tr{
   background-color : transparent;
  }
  
  table thead tr th{
   background-color : transparent;
   padding-top : 0px;
   padding-bottom : 10px;
   color: #fff;
   border-bottom: none;
  }

  table tbody tr {
    
   background-color : transparent;
   padding : 10px;
   cursor:pointer;
  }
  table tbody tr:hover {
    border:1px solid red;
  }

  table tbody tr td{
    background-color: rgba(187, 187, 187, 0.09);
    color : white;
   border-bottom: none;
 }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(187, 187, 187, 0.09);
    color : white;
  }
  table tbody tr td:first-child
  {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-color: rgba(187, 187, 187, 0.09);
  }
   table tbody tr td:last-child
   {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(187, 187, 187, 0.09);
   }
   table tbody tr.active td
{
 border-top: 2px solid white;
 border-bottom: 2px solid white;
}

table tbody tr.active td:first-child
{
 border-left: 2px solid white;
}
 table tbody tr.active td:last-child
 {
  border-right: 2px solid white;
 } 

 .ant-table-pagination.ant-pagination {
  justify-content: center;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: ${siteThemeColors.TablePagintionColor.ellipsisColor};
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
  color: ${siteThemeColors.TablePagintionColor.borderColor};
} 
.ant-pagination-item {
  background: ${siteThemeColors.TablePagintionColor.bgColor};
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item-active {   
  border-color: ${siteThemeColors.TablePagintionColor.borderColor};
  background: ${siteThemeColors.TablePagintionColor.bgColor};
} 
.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item a {
  color: ${siteThemeColors.TablePagintionColor.color};
}
.ant-pagination-item:focus a, .ant-pagination-item:hover a, .ant-pagination-item-active a {
  color: ${siteThemeColors.TablePagintionColor.borderColor};
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background: ${siteThemeColors.TablePagintionColor.bgColor};
  border: none;
}
.ant-pagination-prev button, .ant-pagination-next button {
  color: ${siteThemeColors.TablePagintionColor.color};
}
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: ${siteThemeColors.TablePagintionColor.bgColor};
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
`;

export const StyledTransfer = Styled(Transfer)`
  .ant-transfer-list-header {
    background: ${siteThemeColors.TransferColor.bgHeaderColor};
    color: ${siteThemeColors.TransferColor.color};
  }
  .ant-transfer-list-content {
    background: ${siteThemeColors.TransferColor.bgColor};
  }
  .ant-transfer-list-body-not-found .ant-empty {
    color: ${siteThemeColors.TransferColor.color};
  }
  .ant-transfer-list-content-item, .ant-transfer-list-content-item:hover, .ant-transfer-list-content-item-checked {
    background: ${siteThemeColors.TransferColor.bgItemColor} !important;
    color: ${siteThemeColors.TransferColor.color};
    margin-top: 5px;
    margin-left: 25px;
    padding-left: 0px;
  }
  .ant-pagination {
    color: ${siteThemeColors.TransferColor.color};
  }
  .ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
    color: ${siteThemeColors.TransferColor.color};
  }
`;

export const UploadContenador = Styled.div`  
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 1vh;
  padding-top: 3vh;  
  width : 100%;
  .ant-upload-drag {
    margin : 0px !important;
  }
  button{
    width: 100%;
  }
`;
export const TopBarBox = Styled.div` 
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display : flex;
  flex-direction: row;
  align-items: space-around;
  justify-content: space-around;
`;

export const TaskDetailsWrap = Styled.div`
   width: 100%;
   background-color: rgba(187, 187, 187, 0.09);
   border-radius: ${(props) =>
     props?.borderRadius ? props?.borderRadius : "15px"} !important;
   
   padding: 20px;
  //  margin: 10px 0;
   min-height:400px;
   color:#fff;
   .ant-typography{
    color:#fff;
    font-weight:normal;
   }
`;

export const PopularActionWrapper = Styled.div`
  width:315px;
  height:250px;
  padding:20px;
  // background-color: rgb(187 187 187 / 9%);
  border-radius : 10px;
  background : linear-gradient(#322B46, #2F2844);
  margin: 10px 20px 10px 0;
  font-family: 'Roboto';

  .title{
    font-size:24px;
    color:#fff;
    font-weight:500;
    line-height:32px
  }

  .description{
    font-size:16px;
    color:#fff;
    font-weight:400;
    line-height:24px
  }

  .link{
    font-size:16px;
    color:#fff;
    font-weight:700;
    line-height:24px;
    cursor:pointer;
  }
  
`;

export const IconWrapper = Styled.div`
   width: 100%;
  //  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : "#53476C"};
   background: ${(props) =>
     props.bgcolor
       ? props.bgcolor
       : "linear-gradient(150.1deg, #594A77 9.14%, #4A3D64 93.63%)"};
   border-radius: 50%;
   padding: 10px 16px;
   margin: 10px 0 20px 0;
   width:60px; height: 60px;
   font-size:26px;
   color:#fff;

`;

export const FormWrap = Styled.div`
   padding : 20px 30px;
   width: ${(props) => (props.width ? props.width : "500px")};
   margin-left: auto;
   margin-right: auto;
   background-color : rgb(187 187 187 / 9%);
   color: #FFFFFF;
   }
   .preview-left-part{
    background:${siteThemeColors.ModalColor.bgHeaderColor};
  }
  .preview-right-part{
    border: 1px solid #3A3C5A;
    box-shadow: 8px 7px 19px 3px rgba(0, 0, 0, 0.08);
    padding:15px;
  }
`;

export const FormWrapper = Styled.div`
  .ant-typography{
    color:${siteThemeColors.ModalColor.color};
    font-weight: normal;
  }
  .ant-form-item{margin-bottom:10px;}
  .ant-form-item-label > label {
    color: ${siteThemeColors.FormColor.color};
  }
  .ant-form {
    color: ${siteThemeColors.FormColor.color};
  }
  .ant-form-item-explain, .ant-form-item-extra {
    color: ${siteThemeColors.FormColor.color}
  }
  .ant-select-clear{
    background-color: ${siteThemeColors.FormColor.bgFormColor};
  }
  .ant-input-affix-wrapper, .ant-input-number-affix-wrapper {
    background-color: ${siteThemeColors.FormColor.bgFormColor};
    border-color: ${siteThemeColors.FormColor.borderColor};
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-color: ${siteThemeColors.FormColor.hoverBorderColor};
    
  }
  .ant-input-affix-wrapper input[type="text"], .ant-input-number-affix-wrapper input[type="text"], .ant-input-affix-wrapper .ant-input{
    border:none !important;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: ${siteThemeColors.FormColor.bgFormColor};
  }
  input[type="text"], input[type="password"], textarea, .ant-input {
    border-color:${siteThemeColors.FormColor.borderColor};
    background: ${siteThemeColors.FormColor.bgFormColor};
    color: ${siteThemeColors.FormColor.color};
  }
  input[type="text"]:hover, input[type="password"]:hover, textarea:hover, .ant-input:hover, .ant-input-number:hover {
    border-color:${siteThemeColors.FormColor.hoverBorderColor};
    
  }
  /*.ant-divider{border-top-color: ${siteThemeColors.FormColor.bgColor}}*/
  .ant-input:focus, .ant-input-focused {    
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
    border-color: ${siteThemeColors.FormColor.borderColor};
    background: ${siteThemeColors.FormColor.bgFormColor};
    color: ${siteThemeColors.FormColor.color};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: ${siteThemeColors.FormColor.borderColor};
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${siteThemeColors.FormColor.hoverBorderColor} !important;
  }
  .ant-input-number{
    border:1px solid ${siteThemeColors.FormColor.borderColor};
    background: ${siteThemeColors.FormColor.bgFormColor};
    color: ${siteThemeColors.FormColor.color};
    width:100%;
  }
  .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number{
    background: ${siteThemeColors.FormColor.bgFormColor};
  }
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number{
    background: ${siteThemeColors.FormColor.bgFormColor};
  }
  .ant-input-number-group-addon {
    background: ${siteThemeColors.FormColor.bgFormColor};
    }

  .ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon span {
    color: #e9d155 !important;
  }

  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: ${siteThemeColors.FormColor.borderColor};
    background: ${siteThemeColors.FormColor.bgFormColor};
    color: ${siteThemeColors.FormColor.color};
  }
  
  .ant-select-multiple .ant-select-selection-item-remove {
    color: ${siteThemeColors.FormColor.color};
  }

  .ant-select .ant-select-selector {
    border-color: ${siteThemeColors.FormColor.borderColor} !important;
    background-color: ${siteThemeColors.FormColor.bgFormColor} ;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${siteThemeColors.FormColor.bgFormColor} ;
  }
  .ant-input-number-handler-wrap{
    background-color: ${siteThemeColors.FormColor.bgFormColor} ;
  }
  .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner{
    color: ${siteThemeColors.FormColor.color} !important;
  }
  .ant-select-selection-item {
    color: ${siteThemeColors.FormColor.color} !important;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-search-input {
    color: ${siteThemeColors.FormColor.color} !important;
  }

  .anticon {
    color: ${siteThemeColors.FormColor.color};
  }

  .ant-select-multiple .ant-select-selection-item {
    
    background-color: ${siteThemeColors.TablePagintionColor.bgColor};
    border-color: ${siteThemeColors.TablePagintionColor.borderColor};
  }
  .ant-input-number:focus, .ant-input-number-focused {    
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
    
  }
  
  .ant-select-arrow {
    color: ${siteThemeColors.FormColor.borderColor} !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
  }

  .ant-radio-inner{
    border-color: ${siteThemeColors.FormColor.hoverBorderColor} !important;
  }
  .ant-radio-inner::after{
    background-color: ${siteThemeColors.FormColor.hoverBorderColor} !important;
  }
  .ant-picker{
    border-color: ${siteThemeColors.FormColor.borderColor} !important;
    background-color: ${siteThemeColors.FormColor.bgFormColor} !important;
  }
  .ant-picker:hover, .ant-picker-focused{
    border-color: ${siteThemeColors.FormColor.hoverBorderColor} !important;
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
  }
  .ant-picker-range .ant-picker-active-bar{
    background:${siteThemeColors.FormColor.hoverBorderColor};
  }

  .ant-switch {
    background-color: ${siteThemeColors.FormColor.uncheckColor};
  }

  .ant-switch-checked {
    background-color: ${siteThemeColors.FormColor.bgColor};
  }

  .ant-switch-checked:focus {
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
  }

  .ant-radio-button-wrapper {
    
    color: ${siteThemeColors.FormColor.color};
    
    background: ${siteThemeColors.FormColor.bgFormColor};
    border-color: ${siteThemeColors.FormColor.borderColor};      
  }
  .ant-radio-button-wrapper:not(:first-child)::before {    
    background-color: ${siteThemeColors.FormColor.borderColor};    
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {    
    background: ${siteThemeColors.FormColor.bgColor};
    border-color: ${siteThemeColors.FormColor.bgColor};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${siteThemeColors.FormColor.bgColor};
  }
  .ant-radio-button-wrapper-checked {
    background-color: ${siteThemeColors.FormColor.hoverBorderColor};
    border-color: ${siteThemeColors.FormColor.hoverBorderColor} !important;
  }

  .ant-radio-button-wrapper:hover {    
    color: ${siteThemeColors.FormColor.color};
  }

  .ant-radio-wrapper, .ant-checkbox-wrapper, .ant-checkbox-wrapper-disabled > span {
    color: #fff !important;
  }

  .ant-radio-checked {
    background: transparent;
  }

  .ant-checkbox-checked::after{
    border: 1px solid ${siteThemeColors.FormColor.bgColor};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${siteThemeColors.FormColor.hoverBorderColor};
    border-color: ${siteThemeColors.FormColor.hoverBorderColor};
  }
  .ant-checkbox-inner{
    border: 1px solid ${siteThemeColors.FormColor.hoverBorderColor};    
    background: ${siteThemeColors.FormColor.bgFormColor}
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${siteThemeColors.FormColor.hoverBorderColor};
  }
  .ant-transfer-list {   
    border: 1px solid ${siteThemeColors.FormColor.borderColor};
  }
  .ant-transfer-list-pagination {    
    border-top: 1px solid ${siteThemeColors.FormColor.borderColor};
  }
  .ant-transfer-list-header {   
    border-bottom: 1px solid ${siteThemeColors.FormColor.borderColor};
  }

  .ant-slider-handle {    
    border: solid 2px ${siteThemeColors.FormColor.hoverBorderColor};    
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${siteThemeColors.FormColor.hoverBorderColor};
  }
  .ant-slider-handle:focus{
    box-shadow: 0 0 0 1px ${siteThemeColors.FormColor.boxShadowColor};
  }
  .ant-slider-dot-active {
    border-color: ${siteThemeColors.FormColor.hoverBorderColor};
  }
  .ant-slider-track, .ant-slider:hover .ant-slider-track {
    background-color: ${siteThemeColors.FormColor.hoverBorderColor};
  }

  .ant-input-number-affix-wrapper{
    padding-inline-start : 0px !important;
  }
  .ant-input-number-affix-wrapper input.ant-input-number-input {
    padding: 5px;
}

 .ant-input-status-error{
  background-color: ${siteThemeColors.FormColor.bgColor} !important;
 }
.ant-input-textarea-show-count::after { 
  color:#fff !important;
}

.ant-pagination {
  justify-content: center;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: ${siteThemeColors.TablePagintionColor.ellipsisColor};
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
  color: ${siteThemeColors.TablePagintionColor.borderColor};
} 
.ant-pagination-item {
  background: ${siteThemeColors.TablePagintionColor.bgColor};
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item-active {   
  border-color: ${siteThemeColors.TablePagintionColor.borderColor};
  background: ${siteThemeColors.TablePagintionColor.bgColor};
} 
.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}
.ant-pagination-item a {
  color: ${siteThemeColors.TablePagintionColor.color};
}
.ant-pagination-item:focus a, .ant-pagination-item:hover a, .ant-pagination-item-active a {
  color: ${siteThemeColors.TablePagintionColor.borderColor};
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-options .ant-select-selector {
  background: ${siteThemeColors.TablePagintionColor.bgColor} !important;
  border: none;
}
.ant-pagination-prev button, .ant-pagination-next button {
  color: ${siteThemeColors.TablePagintionColor.color};
}
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: ${siteThemeColors.TablePagintionColor.bgColor};
  border-color: ${siteThemeColors.TablePagintionColor.bgColor};
}

.ant-alert-warning {
  background: #2D273F;
  border: none;
  border-left: 4px solid #E9D155;
}
.ant-alert-message, .ant-alert-description {
  color: #fff;
}
.ant-alert-warning .ant-alert-icon {
  color: #E9D155 !important;
}

`;
export const StyledSteps = Styled(Steps)`
  
  display: inline-block;
  vertical-align: top;
  
  .stepper-wrapper .ant-steps-item-title::after {
    display: none !important;
  }
  .ant-steps-item {
    flex: 0.3 !important;
    padding-right: 15px;
    overflow:initial;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${siteThemeColors.StepColor.color};
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${siteThemeColors.StepColor.borderColor};
  }
  .ant-steps-item-icon{
    line-height:27px;
  }
  .ant-steps-item-wait .ant-steps-item-icon{
    background: transparent !important;
    border-color: ${siteThemeColors.StepColor.borderColor};
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
    color: ${siteThemeColors.StepColor.borderColor};
  }

 .ant-steps-item-active .ant-steps-item-icon {
    background-color:${siteThemeColors.StepColor.bgColor} !important;
    color: ${siteThemeColors.StepColor.color};
    border: none;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${siteThemeColors.StepColor.color} !important;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${siteThemeColors.StepColor.bgColor} !important;
    border: none;
  }

  .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
    color: ${siteThemeColors.StepColor.color};
    border-color:${(props) =>
      props.borderColor
        ? props.borderColor
        : `${siteThemeColors.StepColor.bgColor}`}
    !important;

    background-color:${(props) =>
      props.bgColor
        ? props.bgColor
        : `${siteThemeColors.StepColor.bgColor}`}!important;
   
   color:${(props) =>
     props.color
       ? props.color
       : `${siteThemeColors.StepColor.color}`}!important;
  }

  .ant-steps-item-active .ant-steps-item-icon:hover .ant-steps-icon {
    color:${(props) =>
      props.color
        ? props.color
        : `${siteThemeColors.StepColor.color}`}!important;
  }

  .ant-steps-item-icon:hover {
    border-color:${(props) =>
      props.borderColor
        ? props.borderColor
        : `${siteThemeColors.StepColor.bgColor}`}
    !important;
  }
  .ant-steps-item-icon:hover .ant-steps-icon {
    color:${(props) =>
      props.color
        ? props.color
        : `${siteThemeColors.StepColor.color}`}!important;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {    
    background-color:${(props) =>
      props.bgColor
        ? props.bgColor
        : `${siteThemeColors.StepColor.bgColor}`}!important;
        border-color:${(props) =>
          props.borderColor
            ? props.borderColor
            : `${siteThemeColors.StepColor.bgColor}`}
        !important;
        color:${(props) =>
          props.color ? props.color : `${siteThemeColors.StepColor.color}`}
        !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .anticon svg  {
    color:${(props) =>
      props.color ? props.color : `${siteThemeColors.StepColor.bgColor}`};
  }
  .ant-steps-item-finish .ant-steps-item-icon .anticon svg {
    color: ${siteThemeColors.StepColor.bgColor};
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${siteThemeColors.StepColor.color};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color:  ${siteThemeColors.StepColor.borderColor};
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${siteThemeColors.StepColor.bgColor};
  }
  .ant-steps-item::before {
    background-color: ${siteThemeColors.StepColor.bgColor} !important;
  }
  .ant-steps-item::after {
    border: ${siteThemeColors.StepColor.bgColor} !important;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
    color:${(props) =>
      props.color
        ? props.color
        : `${siteThemeColors.StepColor.color}`}!important;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
    border-color:${(props) =>
      props.borderColor
        ? props.borderColor
        : `${siteThemeColors.StepColor.bgColor}`}
    !important;

  }

  .ant-steps-item-finish  .ant-steps-item-icon{
    color : #716A88 !important;
  }
  .ant-steps-item-finish  .ant-steps-item-title{
    color : #ffffff !important;
  }
  .ant-steps-icon{
    color: #716A88 !important;
  }ant-steps-icon{
    color: #716A88 !important;
  }
  .ant-steps-item-title{
    color: #716A88  !important;
  }

  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon {
    border-color: #716A88 !important;

  }
  .stepper-wrapper > div > div.ant-steps-item-icon{
    border:2px solid #716A88;
  }
  .ant-steps-item-finish  .ant-steps-item-icon .ant-steps-icon > span > svg{
    color:#716A88 !important;
  }
  .ant-steps-item-process .ant-steps-item-title{
    color:#fff !important;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after { 
    background:none !important;
    width:auto !important;
    height: auto !important;
  }
  .ant-steps-item-tail::after{ 
    background:none !important;
    width:auto !important;
    height: auto !important;
  }
  
`;

export const FormModalDark = Styled(Modal)`
  .ant-modal-header {
  background-color: #362E4B
  !important;
  align-items: 'center' !important;
border:none!important;
  }

  .ant-modal-title  {
    color: ${siteThemeColors.ModalColor.color};
    }
  .ant-modal-body {
    background-color:#2D273F !important;
    padding-top: 5px;
  }

  .ant-modal-close-icon svg {
  color: #fff !important;
  }
  
  .ant-form-item-label > label{
    color: #fff !important;
  }
  .ant-form-item-extra{
    color: #B1AFBC !important;
  }
  .ant-col-16{
    background:#3C3453 !important;
  }
  .ant-col .ant-col-8{
    background:#2D273F !important;
  }
  .ant-alert-info{
    background:#2D273F !important;
    border: none  !important;
    
  }
  .ant-alert-message{
    color: #ffffff !important;
  }
  .ant-tag{
    background:none!important;
    border:none!important;
  }
  .ant-tag > a:first-child:last-child{
    color: #A68DFB!important;
    padding-left:10px !important;
  }
  .ant-input-number {
    background: #625D75;
    border: 1px solid #87809C;
    border-radius: 2px;
    color:#fff!important;
    }
    .ant-form-item textarea.ant-input {
      background: #625D75 !important;
      border:none !important;
    }
    .ant-checkbox + span{
    color:#fff!important;
    }
    span.ant-radio + *{color:#fff!important;
    }
    .ant-modal-footer{
      background:#2D273F!important;
      border:none;
    }
  .ant-steps-item {
    flex: none !important;
  }
  
  .ant-steps-item-title {
    color:#716A88!important;

  }

  .ant-steps-icon span{
    color: none !important;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color:#716A88!important;

  }

  .ant-steps-item .ant-steps-item-finish{
     .ant-steps-icon{
      color: #fff !important;
     }

  .ant-steps-item .ant-steps-item-process .ant-steps-item-active span{
  color: #fff !important;
  }}
`;

export const CustomizedForm = Styled.div`
background: #3C3453 !important;
.ant-input-number{
  background: #625D75;
  border: 1px solid #87809C;
  border-radius: 2px;
  color:#fff!important;
  }
  .ant-form-item textarea.ant-input {
    background: #625D75 !important;
    border:none !important;
    color:#fff!important;
  }
  .ant-form-item-label > label{
    color: #fff !important;
  }
  .ant-form-item-extra{
    color: #B1AFBC !important;
  }
  .ant-alert-info{
    border: none  !important;
    background-color: none!important;
  }
  .ant-tag{
    background:none!important;
    border:none!important;
  }
  
  
  
`;

export const ModalWithStyles = Styled(Modal)`
 &{
  background-color: #D9D9D9 !important;
  box-shadow: 8px 7px 19px rgba(0, 0, 0, 0.08), inset 4px 4px 4px rgba(224, 224, 224, 0.25);
  border-radius: 4px;
  overflow: hidden;
  padding-bottom:0;
  width:100% !important;
  max-width:1000px;
 }
 .ant-modal-body{
 background: #D9D9D9;
 }
 .ant-modal-close-x{
 svg{
  path{
    fill:#000;  
  }
 }
 }
`;

export const StepsWithStyles = Styled(Steps)`
  *{
  color:#595959 !important;
   font-weight:600 !important;
  }
  
  .ant-steps-icon{
  font-size:14px;
  }
  
  .ant-steps-item-title{
  font-size:12px !important;
  padding-right:25px !important;
    &:after{
      background-color:#000 !important;
    }
  }
  .ant-steps-item-icon{
    border:none;
    background-color:#D1D1D1;
    margin-right:11px;
  }
  .ant-steps-item-active{
    *{
      color:#000 !important;
    }
      .ant-steps-item-icon{
    background-color:#fff;
  }
  }
   .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
        padding-left:25px;
    }
`;

export const TooltipWithStyles = Styled(Tooltip)`
       .ant-tooltip-inner{
        background: #FFF;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
    }
`;

export const TableWithStyles = Styled(Table)`
    border-radius: 8px;
    tr > th{
      padding: 4px 20px !important;
      background: #2D273F;
      color:#fff;
      font-weight:600;
      border:none !important;
    }
    .report-table__controls{
      display:flex;
      justify-content:flex-end;
      >button:not(:last-child){
        margin-right:10px
      }
      .ant-btn{
      height:37px !important;
      }
      .ant-btn:not(.ant-btn-primary){
        background-color:transparent;
        color:#FFF;
      }
      .ant-btn-primary{
         background-color:#fff !important;
         color:#676A6F !important;
         border:none !important;
      }
     }
    td{
        border:none !important;
        background:#7A7D81 !important;
        color:#fff;
        border-bottom:1px solid rgba(242, 242, 242,0.5) !important;
    }
    .sdgs-row{
      img{
        width:40px;
        height:40px;
        margin-right:10px;
        margin-bottom:10px
      }
    }
`;

export const CommonRadio = Styled(Radio.Button)`
color :#B1AFBC !important;
background-color :#53476C !important;
border-color : #53476C !important;

&:not(:first-child)::before {
  background-color : #62567A !important; 
  width : 1px !important;
 }

&.ant-radio-button-wrapper-checked{
    color : #fff !important;
  background-color :
  ${(props) =>
    props.bgColor
      ? props.bgColor
      : `${siteThemeColors.ButtonColor.bgColor}`} !important;
  border-color : #B1AFBC !important;
}
  
`;

export const ListCardWrapper = Styled.div`
  width:315px;
  height:300px;
  padding:20px;
  background-color: rgb(187 187 187 / 9%);
  margin: 10px 20px 10px 0;
  font-family: 'Roboto';

  .title{
    font-size:24px;
    color:#fff;
    font-weight:500;
    line-height:32px
  }

  .description{
    font-size:16px;
    color:#fff;
    font-weight:400;
    line-height:24px
  }

  .link{
    font-size:16px;
    color:#fff;
    font-weight:700;
    line-height:24px;
    cursor:pointer;
  }
  
`;

// BORDERED TABLE
export const BorderedTable = Styled(Table)`
  .ant-table-title {
    background: ${siteThemeColors.TableColor.bgColor};
    color: white;
    border: 1px solid ${siteThemeColors.TableColor.bgColor} !important;
    border-radius: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-table .ant-table-thead > tr > th, .ant-table .ant-table-tbody > tr > td{
    background-color: ${siteThemeColors.TableColor.bgColorHeader};
    color: ${siteThemeColors.TableColor.color};
    padding-top: 5px;
    padding-bottom: 5px;
    border : 1px solid #87809C !important;
  }

  .ant-table .ant-table-tbody > tr > td:nth-child(1) {
    border-top: none !important;
  }
  .ant-table .ant-table-thead > tr > th:nth-child(2) {
    border-right: none !important;
    border-left: none !important;
  }

  .ant-table .ant-table-tbody > tr > td:nth-child(2) {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }
  .ant-table .ant-table-tbody > tr > td:last-child {
    border-left: none !important;
    border-top: none !important;
  }

  .ant-table .ant-table-thead > tr > th:last-child {
    padding: 0px !important;
    border-left : none !important;
    border-left: none !important;
  }
`;

export const BorderedSpecialTable = Styled(BorderedTable)`
  .ant-table {
    background-color: transparent !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 0.8em;
  }
  .ant-table .ant-table-tbody > tr > td:last-child {
    padding: 0 !important;
  }
  .ant-table .ant-table-tbody > tr > td:nth-child(1) {
    border-top: 1px solid #87809C !important;
  }
`;

export const StyledEmpty = Styled(Empty)`
  .ant-empty-description {
    color: ${siteThemeColors.commonColor.color};
  }
`;

export const Heading2 = Styled.h2`
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  line-height: 28px;
  margin-top: ${(props) => props.mt || "0px"};
  margin-bottom: ${(props) => (props.nogutterbottom ? "0px" : "auto")};
`;

export const Heading6 = Styled.h2`
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: ${(props) => (props.nogutterbottom ? "0px" : "auto")};
`;

export const BodyText = Styled.p`
  font-size: ${(props) => (props.size === "sm" ? "14px" : "16px")};
  font-weight: ${(props) => (props.medium ? "500" : "400")};
  line-height: ${(props) => props.lineheight || "28px"};
  color: ${(props) => (props.faded ? "#B1AFBC" : "#fff")};
  margin-bottom: ${(props) => (props.nogutterbottom ? "0px" : "auto")};
`;

export const StyledDivider = Styled(Divider)`
  margin: ${(props) => props.margin || "0px"};
  min-width: ${(props) => props.width || "100%"} !important;
  width: ${(props) => props.width || "100%"} !important;
  background-color:  ${(props) => props.bgColor || "#4F4669"};
  height: 1px;
`;

export const StyledTabbedMenu = Styled(Menu)`
  border: 0;
  color: white;
  background-color: transparent;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  
  li { 
    padding: 0 !important;
    height: 36px;
    &:hover,
    &.ant-menu-item-selected { 
      height: 34px;
      color: #A68DFB !important;      
      border-bottom: 2px solid #A68DFB !important;
    }
    
  .ant-menu-item:hover {
    & .ant-menu-title-content: {
        color: #A68DFB !important;   
      } 
    }
  }

  li:not(:first-child) {
    margin-left: 32px; 
  }
`;

export const FilterWrap = Styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & input {
    width: 400px;
  }
`;

export const ProfilePhotoWrap = Styled.div`
   display:flex; 
   flex-direction: column;
   margin-right:20px;

   .profileContaner {
      background-color: lightgrey;
      width:110px;
      height:110px;
      border-radius:50%;
      overFlow:hidden;
   }

   .profileContaner img {
      width : auto;
      height : 100%;
   }
`;
