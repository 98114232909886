import React, { useState } from "react";
import { CommonModal, StyledButton } from "../../../../shared/commonStyles";
import { Col, Collapse, Row, Spin, Typography } from "antd";
import styles from "./KPIsListModal.module.scss";
import { CheckOutlined } from "@ant-design/icons";

interface KPIsListModalProps {
  visible: boolean;
  kpisList: any[];
  data: {
    index: number;
    fieldName:
      | "revenue_total"
      | "full_time_employees"
      | "total_floor_space"
      | null;
    fieldLabel: string;
    period: string;
  };
  onClose: () => void;
  onConfirm: (data: string, index: number) => void;
}
export const KPIsListModal = ({
  visible,
  kpisList,
  data,
  onClose,
  onConfirm,
}: KPIsListModalProps) => {
  const [selectedKPI, setSelectedKPI] = useState("");
  const handleSelectData = (dataId: string) => {
    selectedKPI === dataId ? setSelectedKPI("") : setSelectedKPI(dataId);
  };

  return (
    <CommonModal
      centered
      visible={visible}
      onCancel={onClose}
      onOk={() => onConfirm(selectedKPI, data.index)}
      okText="Confirm selection"
      width={"60%"}
    >
      <Spin spinning={!kpisList.length}>
        <Typography.Title level={4}>
          Select KPI that will reflect {data.fieldLabel} for {data.period}
        </Typography.Title>
        <Collapse className={styles.collapse}>
          {kpisList.map((item) => (
            <Collapse.Panel
              key={item?._id?.$oid}
              header={item.sheet_name}
              className={styles.collapsePanel}
            >
              {item?.kpiData?.map((v: any) => {
                return (
                  <div className={styles.list} key={v?._id?.$oid}>
                    <Row className={styles.listItem} justify="space-between">
                      <Col>{v.name}</Col>
                      <Col>
                        <StyledButton
                          type="default"
                          className={styles.selectButton}
                          onClick={() => handleSelectData(v?._id?.$oid)}
                        >
                          {selectedKPI === v?._id?.$oid ? (
                            <>
                              <CheckOutlined />
                              Selected
                            </>
                          ) : (
                            "Select"
                          )}
                        </StyledButton>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Spin>
    </CommonModal>
  );
};
