import React, { useState, useEffect } from "react";
import { notification, Tooltip } from "antd";
import { IconWrapper, PopularActionWrapper } from "../../shared/commonStyles";
import { LockOutlined } from "@ant-design/icons";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";

export const PopularActionCard = ({
  title = "",
  description = "",
  iconComponent = "",
  onClick,
  section,
  isSelected = false,
  isTooltip = false,
  tooltipText = "",
  iconColor,
  isLockModule = false,
}) => {
  const { checkLockModules } = useCheckRolesPermissions();
  const [allModules, setAllModules] = useState(null);
  const [isModuleLocked, setIsModuleLocked] = useState(false);
  
  const callCheckLockModules = async() => {
    const response = await checkLockModules()
    setAllModules(response)
  }
  useEffect(() => {    
    if(!localStorage.getItem("modules")){
      callCheckLockModules()
    }      
  },[]);
  
  useEffect(() => {    
    if(isLockModule){     
      let moduleArray = allModules      
      if(localStorage.getItem("modules") !== null){        
        moduleArray = localStorage.getItem("modules")?.split(",")
      }      
      if(moduleArray?.includes(isLockModule) || moduleArray?.includes("ALL_MODULES")){
        setIsModuleLocked(false)
      }else{
        setIsModuleLocked(true)
      }
    }
      
  },[isLockModule,allModules]);
 
  const showInformation = () => {
    notification["info"]({
      message: "Information",
      description: `Please contact your account manager or email sales@hydrus.ai to access this module.`,
    });
  };
  if (isTooltip) {
    return (
      <Tooltip title={tooltipText}>
        <PopularActionWrapper className={isModuleLocked ? "card-grey" : ""}  style={{cursor: "pointer"}}
        onClick={() => isModuleLocked ? showInformation() : onClick(section) }>
          <div>
            <div style={{ float: "right" }} className="lock-right-pan">
              {isModuleLocked &&
                <LockOutlined style={{ fontSize: "26px" }} />
              }
            </div>
            {iconComponent ? (
              <IconWrapper bgcolor={iconColor}>{iconComponent}</IconWrapper>
            ) : null}
            <p className="title">{title}</p>
            {description && <p className="description">{description}</p>}
            <p>
              Go to {title} →
            </p>
          </div>
        </PopularActionWrapper>
      </Tooltip>
    );
  } else {
    return (
      <PopularActionWrapper className={isModuleLocked ? "card-grey" : ""} style={{cursor: "pointer"}}
       onClick={() => isModuleLocked ? showInformation() : onClick(section) }>
        <div>
          
          <div style={{ float: "right" }} className="lock-right-pan">
            {isModuleLocked &&
              <LockOutlined style={{ fontSize: "26px" }} />
            }
          </div>
          {iconComponent ? (
            <IconWrapper bgcolor={iconColor}>{iconComponent}</IconWrapper>
          ) : null}
          <p className="title">{title}</p>
          {description && <p className="description">{description}</p>}
          <p>
            Go to {title} →
          </p>
        </div>
      </PopularActionWrapper>
    );
  }
};
