import { message, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getAuditManagements } from "../../../../services/audits";
import { getSurveyById } from "../../../../services/surveys";

const RelatedItems = ({ record }) => {
  const [loader, setLoader] = useState(false);
  const [attachedItems, setAttachedItem] = useState([]);

  const loadData = async () => {
    try {
      setLoader(true);
      const relatedItems = JSON.parse(record?.related_items);
      if(relatedItems?.length > 0){
        const data = await Promise.all(
          relatedItems?.map(async (item) => {
            if (item?.type === "audits") {
              const auditResponse = await getAuditManagements(item?.value);

              return {
                name: auditResponse?.name ? auditResponse?.name : "",
              };
            }else if (item?.type === "surveys") {
              const surveyResponse = await getSurveyById(item?.value);
              return {
                name: surveyResponse?.name ? surveyResponse?.name : "",
              };
            }
          })
        );
        setAttachedItem(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadData();
  }, [record]);

  return (
    <Spin spinning={loader}>
      {attachedItems?.length > 0 ?
        <>
          {attachedItems?.length === 1 ?
            <p>
              {attachedItems[0]?.name}
            </p>
          :
            <Tooltip
              placement="top"
              title={
                <ul>
                  {attachedItems?.map((item, index) => {
                    return (
                      <li key={index + 1} style={{ padding: "3px 0px" }}>
                        {item?.name}
                      </li>
                    );
                  })}
                </ul>
              }
            >
            <p>
              {attachedItems?.length}{" "}
              <span className="color-grey">(hover to view list)</span>
            </p>
            </Tooltip>
          }
        </>
      : '- - -'
      }
    </Spin>
  );
};
export default RelatedItems;
