import * as queries from "../graphql/queries";

import { API, Auth } from "aws-amplify";

export const getReportingStandards = async (payload) => {
  let response = null;
  if (payload) {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_reporting_standards",
        request_payload: JSON.stringify(payload),
      },
    });
  } else {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_reporting_standards",
        request_payload: JSON.stringify(payload),
      },
    });
  }

  return JSON.parse(response.data.datalake);
};

export const getReportingStandardsById = async (standardId) => {
  const standardData = await getReportingStandards();
  return standardData?.find((item) => item._id.$oid === standardId);
};

export const getUniqueFrameworks = async () => {
  const result = await (API.graphql({
      query: queries.datalake,
      variables: {
          request_type: "get_unique_frameworks",
      },
  }));
  return result.data?.datalake || "[]";
};

export const addReportingStandards = async (payload) => {
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const requestPayload = { ...payload, group_id, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_reporting_standards",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const updateReportingStandards = async (payload) => {
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const requestPayload = { ...payload, group_id, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_reporting_standards",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const deleteReportingStandards = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_reporting_standards",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const listSelectedStandards = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_selected_standards",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const getStandardsFilters = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_standards_filters",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const addSelectedStandards = async (payload) => {
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const requestPayload = { ...payload, group_id, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_selected_standards",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const updateSelectedStandards = async (payload) => {
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const requestPayload = { ...payload, group_id, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_selected_standards",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const getSelectedStandardById = async (standardId) => {
  const payload = { filter: { _id: { $oid: standardId } } };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_selected_standards",
      request_payload: JSON.stringify(payload),
    },
  });
  const data = JSON.parse(response.data.datalake).data || [];
  return data[0] || null;
};

export const getSelectedStandardForSurveyById = async (standardId) => {
  const payload = { filter: { _id: { $oid: standardId } } };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_selected_standards_for_survey",
      request_payload: JSON.stringify(payload),
    },
  });
  const data = JSON.parse(response.data.datalake).data || [];
  return data[0] || null;
};

export const parseStandardDataForAudits = async (item, type = "datalake") => {
  try {
    if (item) {
      const modalTitle = { modalTitle: item?.name };
      const standards = item?.frameworks;
      const response = item?.response;
      const formJSONCode = {};
      const formUICode = {};
      const standardLink = {
        standardLink: item?.resource_urls
          ? item?.resource_urls instanceof Array
            ? item?.resource_urls?.map((urlObject) => ({
                url: urlObject.url,
                name: urlObject.name ?? "",
                page_num: urlObject.page_num,
              }))
            : JSON.parse(
                type === "datalake"
                  ? JSON.parse(item?.resource_urls)
                  : item?.resource_urls
              )?.map((urlObject) => ({
                url: urlObject.url,
                name: urlObject.name ?? "",
                page_num: urlObject.page_num,
              }))
          : [],
      };
      return {
        standards,
        ...item,
        ...standardLink,
        ...modalTitle,
        ...formJSONCode,
        ...formUICode,
        response,
      };
    }
    return null;
  } catch (e) {
    console.log("Error while load data lake map!", e);
  }
};

export const parsedStandardData = async (item, type = "datalake") => {
  try {
    if (item) {
      const modalTitle = { modalTitle: item?.name };
      const standards = item?.frameworks;
      const formJSONCode = {};
      const formUICode = {};
      const standardLink = {
        standardLink: item?.resource_urls
          ? item?.resource_urls instanceof Array
            ? item?.resource_urls?.map((urlObject) => ({
                url: urlObject.url,
                name: urlObject.name ?? "",
                page_num: urlObject.page_num,
              }))
            : JSON.parse(
                type === "datalake"
                  ? JSON.parse(item?.resource_urls)
                  : item?.resource_urls
              )?.map((urlObject) => ({
                url: urlObject.url,
                name: urlObject.name ?? "",
                page_num: urlObject.page_num,
              }))
          : [],
      };
      return {
        standards,
        ...item,
        ...formJSONCode,
        ...formUICode,
        ...standardLink,
        ...modalTitle,
      };
    }

    return null;
  } catch (e) {
    console.log("Error while load data lake map!", e);
  }
};

export const getDataLakeFiles = async (dataId) => {
  const response = await API.graphql({
    query: queries["dataLakeFiles"],
    variables: {
      request_type: "LIST",
      data_id: dataId,
    },
  });

  return response["data"]["dataLakeFiles"];
};

export const uploadFile = async (dataId, fileName) => {
  const response = await API.graphql({
    query: queries["dataLakeFiles"],
    variables: {
      request_type: "UPLOAD",
      data_id: dataId,
      version: 1,
      file_name: fileName,
    },
  });
  return response["data"]["dataLakeFiles"];
};

export const createStandardComments = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_standards_comment",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const updateStandardComments = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_standards_comment",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const createStandardAuditDetail = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_standards_audit_detail",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const updateStandardAuditDetail = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_standards_audit_detail",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(JSON.parse(str));
  } catch (e) {
    return false;
  }
  return true;
};
