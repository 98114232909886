import React, { useState } from "react";
import { useSetState } from "ahooks";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import { getScopeNumber } from "../../../../Carbon/CalculationUtils";
import { v4 as uuidv4 } from "uuid";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import styles from "./AddTargetModal.module.scss";

interface AddTargetModalProps {
  isShown: boolean;
  modalType: AddTargetModalType;
  targetType: TargetType;
  onClose: () => void;
  onConfirm: (data: TargetPayload) => void;
}

const SDAscenarios = ["SBTi 1.5", "SBTi 2", "SBTi 2.5", "SBTi 3"];
const customSDAscenarios = ["None", "Net-Zero"];

export const AddTargetModal = ({
  isShown,
  modalType,
  targetType,
  onClose,
  onConfirm,
}: AddTargetModalProps) => {
  const dateFormat = "YYYY-MM-DD";
  const startTargetValue: TargetPayload = {
    key: uuidv4(),
    type: targetType,
    scenario: targetType === TargetType.CUSTOM ? "None" : "SBTi 1.5",
    scope: getScopeNumber(ScopeName.SCOPE_1) as TargetScope,
    emissionName: "",
    baseYear: (moment().year() - 1).toString(),
    baseYearConsumptionAmount: 0,
    year: moment().format(dateFormat),
    currentConsumptionAmount: 0,
    targetYear: (moment().year() + 1).toString(),
    goal: 0,
  };

  const [data, setData] = useSetState<TargetPayload>(startTargetValue);
  const [goalType, setGoalType] = useState("percent");

  const handleChangeData = (value: string, dataType: keyof TargetPayload) => {
    setData((prevData) => ({
      ...prevData,
      [dataType]: value,
    }));
  };
  const handleChangeAmount = (value: number, dataType: keyof TargetPayload) => {
    setData((prevData) => ({
      ...prevData,
      [dataType]: value,
    }));
  };

  const handleChangeGoalType = (value: string) => {
    setGoalType(value);
  };

  const handleConfirm = () => {
    let goal = data.baseYearConsumptionAmount - data.goal;
    if (goalType === "percent") {
      goal =
        data.baseYearConsumptionAmount -
        (data.baseYearConsumptionAmount / 100) * data.goal;
    }
    onConfirm({
      ...data,
      goal: goal,
      actualConsumptionEntries: [
        {
          key: "planStart",
          date: moment(data.baseYear).format(dateFormat),
          consumptionAmount: `${data.baseYearConsumptionAmount}`,
        },
        {
          key: "current",
          date: moment(data.year).format(dateFormat),
          consumptionAmount: `${data.currentConsumptionAmount}`,
        },
      ],
    });
    setData(startTargetValue);
  };

  const areFieldsFilled =
    data.emissionName &&
    data.scenario &&
    data.baseYearConsumptionAmount != null &&
    data.currentConsumptionAmount != null &&
    data.goal != null;

  const selectBefore = (
    <FormWrapper>
      <Select defaultValue="percent" onChange={handleChangeGoalType}>
        <Select.Option value="percent">%</Select.Option>
        <Select.Option value="number">Number</Select.Option>
      </Select>
    </FormWrapper>
  );

  const getModalTitle = () => {
    if (modalType === AddTargetModalType.SINGLE) {
      return data.type === TargetType.CUSTOM
        ? "Add Custom Target"
        : "Add Science Based Target";
    }

    return "Add Target";
  };

  return (
    <CommonModal
      centered
      title={getModalTitle()}
      visible={isShown}
      onOk={onConfirm}
      onCancel={onClose}
      footer={[
        <Button type="text" onClick={onClose} key="cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          key="confirm"
          onClick={handleConfirm}
          disabled={!areFieldsFilled}
        >
          Add target
        </Button>,
      ]}
      width={modalType === AddTargetModalType.COMBINED ? "70%" : "50%"}
    >
      <FormWrapper>
        <Row className={styles.subTitle}>General information</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          {modalType === AddTargetModalType.COMBINED && (
            <Col span={4}>
              <div className={styles.label}>Target type</div>
              <Select
                showSearch
                className={styles.select}
                value={data.type}
                onChange={(value) => handleChangeData(value, "type")}
              >
                <Select.Option key={"scienceBased"} value={"scienceBased"}>
                  SBT approved
                </Select.Option>
                <Select.Option key={"custom"} value={"custom"}>
                  Custom
                </Select.Option>
              </Select>
            </Col>
          )}

          <Col span={modalType === AddTargetModalType.COMBINED ? 4 : 5}>
            <div className={styles.label}>SDA scenario</div>
            <Select
              showSearch
              className={styles.select}
              value={data.scenario}
              onChange={(value) => handleChangeData(value, "scenario")}
            >
              {(data.type === TargetType.CUSTOM
                ? [...customSDAscenarios, ...SDAscenarios]
                : SDAscenarios
              ).map((v) => {
                return (
                  <Select.Option key={v} value={v}>
                    {v}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>

          <Col span={5}>
            <div className={styles.label}>Scope</div>
            <FormWrapper>
              <Radio.Group
                value={data.scope}
                onChange={(e) => handleChangeData(e.target.value, "scope")}
                className={styles.scopeSelect}
              >
                <Radio value="1">1</Radio>
                <Radio value="2">2</Radio>
                <Radio value="3">3</Radio>
              </Radio.Group>
            </FormWrapper>
          </Col>
          <Col span={modalType === AddTargetModalType.COMBINED ? 9 : 11}>
            <div className={styles.label}>Emission name</div>
            <Input
              className={styles.input}
              placeholder="Enter emission"
              value={data.emissionName}
              onChange={(e) => handleChangeData(e.target.value, "emissionName")}
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Base year</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Base year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              disabledDate={(current) => {
                return current.isAfter(moment(data.year));
              }}
              value={moment(data.baseYear)}
              onChange={(value) =>
                handleChangeData(
                  value?.format("YYYY").toLocaleString() || "",
                  "baseYear"
                )
              }
            />
          </Col>
          <Col span={modalType === AddTargetModalType.COMBINED ? 12 : 11}>
            <div className={styles.label}>Consumption amount</div>
            <InputNumber
              className={styles.InputNumber}
              value={data.baseYearConsumptionAmount}
              max={1000000}
              min={0}
              onChange={(value) =>
                handleChangeAmount(value || 0, "baseYearConsumptionAmount")
              }
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Current data</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              value={moment(data.year)}
              onChange={(value) =>
                handleChangeData(
                  value?.format(dateFormat).toLocaleString() || "",
                  "year"
                )
              }
            />
          </Col>
          <Col span={modalType === AddTargetModalType.COMBINED ? 12 : 11}>
            <div className={styles.label}>Consumption amount</div>
            <InputNumber
              className={styles.InputNumber}
              value={data.currentConsumptionAmount}
              max={1000000}
              min={0}
              onChange={(value) =>
                handleChangeAmount(value || 0, "currentConsumptionAmount")
              }
            />
          </Col>
        </Row>
        <Space />
        <Row className={styles.subTitle}>Target year</Row>
        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col span={11}>
            <div className={styles.label}>Target year</div>
            <DatePicker
              placeholder="Select year"
              allowClear={false}
              picker="year"
              className={styles.datePicker}
              disabledDate={(current) => {
                return current.isBefore(moment(data.year));
              }}
              value={moment(data.targetYear)}
              onChange={(value) =>
                handleChangeData(
                  value?.format("YYYY").toLocaleString() || "",
                  "targetYear"
                )
              }
            />
          </Col>
          <Col span={modalType === AddTargetModalType.COMBINED ? 12 : 11}>
            <div className={styles.label}>Reduction amount</div>

            <InputNumber
              className={styles.InputReductionAmount}
              addonBefore={selectBefore}
              placeholder="Select percentage"
              value={data.goal}
              max={goalType === "percent" ? 100 : 1000000}
              min={0}
              onChange={(value) => handleChangeAmount(value || 0, "goal")}
            />
          </Col>
        </Row>
      </FormWrapper>
    </CommonModal>
  );
};
