import React from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Typography } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";
import { encodeUrlName } from "../../../shared/commonFunctions";

const { Title } = Typography;

interface SuccessProps {
  sheetId: string;
  sheetName: string;
}

const Step3 = ({ sheetId, sheetName }: SuccessProps) => {
  const navigate = useNavigate();
  return (
    <>
      <FormWrap width="500px">
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <CheckCircleOutlined style={{ fontSize: "40px" }} />
              <Title className="color-white" level={4}>
                Data Sheet was updated successfully!
              </Title>
              <p>
                <span>
                  Data Sheet was updated successfully! It is already available
                  in Data Sheets section
                </span>
              </p>
              <Form.Item>
                <Row justify="center">
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      navigate(
                        `/data/data-sheets/${sheetId}/${encodeUrlName(
                          sheetName?.replace("/", " ")
                        )}/documents`
                      )
                    }
                  >
                    <span>Open Data Sheet</span>
                  </StyledButton>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => navigate("/data/data-sheets")}
                  >
                    <span>Go to All Data Sheets</span>
                  </StyledButton>
                </Row>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};

export default Step3;
