import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { StyledButton, TableCommonMenu } from "../../../../shared/commonStyles";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateRecordModal from "../../../../components/DataSheetDocuments/CreateRecordModal/CreateRecordModal";

interface TableFooterProps {
  onClickAddEmptyLine: () => void;
  newRecordKey: string;
  metadataSheet?: DataSheet;
  metaDataRecords?: MetaDataRecord[];
  identifierConnectedValues?: (string | number)[];
  onFinishCreateNewData: () => void;
  dataSheet: DataSheet;
}

export const TableFooter = ({
  onClickAddEmptyLine,
  newRecordKey,
  metadataSheet,
  metaDataRecords,
  identifierConnectedValues,
  onFinishCreateNewData,
  dataSheet,
}: TableFooterProps) => {
  const [showAddNewRecordModal, setShowAddNewRecordModal] = useState(false);
  const onClickAddNewRecord = () => {
    setShowAddNewRecordModal(true);
  };
  return (
    <>
      <Dropdown
        overlay={
          <TableCommonMenu>
            <Menu.Item key="emptyLine">
              <StyledButton
                type="custom"
                icon={<PlusCircleOutlined />}
                onClick={onClickAddEmptyLine}
                disabled={!!newRecordKey.length}
              >
                Inline form
              </StyledButton>
            </Menu.Item>
            <Menu.Item key="modal">
              <StyledButton
                type="custom"
                icon={<PlusCircleOutlined />}
                onClick={onClickAddNewRecord}
              >
                Input form
              </StyledButton>
            </Menu.Item>
          </TableCommonMenu>
        }
        trigger={["click"]}
        disabled={!!newRecordKey.length}
      >
        <StyledButton type="custom" icon={<PlusCircleOutlined />}>
          Add New Record
        </StyledButton>
      </Dropdown>
      {showAddNewRecordModal && (
        <CreateRecordModal
          identifier={
            metadataSheet?.sheet_schema.find((value) => value.isIdentifier)
              ?.entity_name
          }
          metaDataRecords={metaDataRecords}
          identifierConnectedValues={identifierConnectedValues}
          visible={showAddNewRecordModal}
          onClose={() => setShowAddNewRecordModal(false)}
          onFinish={onFinishCreateNewData}
          dataSheet={dataSheet!}
        />
      )}
    </>
  );
};
