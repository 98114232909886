import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import { unitsManagementStore } from "../UnitsManagementStore";
import { observer } from "mobx-react-lite";
import { CommonTable, PageWrap } from "../../../../shared/commonStyles";
import { UnitTableButtons } from "./UnitTableButtons";
import styles from "./UnitsTable.module.scss";

export const UnitsTable = observer(() => {
  const { state } = useLocation();

  useEffect(() => {
    unitsManagementStore.getUnitGroupByName(state as string);
  }, []);

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "19%",
    },
    {
      title: "Symbol",
      key: "symbol",
      dataIndex: "symbol",
      width: "5%",
    },
    {
      title: "Base Unit",
      key: "base_unit",
      dataIndex: "base_unit",
      width: "19%",
      render: (data: any) => {
        return <span>{unitsManagementStore.getUnitByKey(data).name}</span>;
      },
    },
    {
      title: "Conversion formula",
      key: "multiplication_factor",
      dataIndex: "multiplication_factor",
      width: "19%",
      render: (factor: number, elem: Unit) => {
        return (
          <>
            {!elem.is_base_unit ? (
              <span>
                1 {elem.symbol} = {factor}{" "}
                {unitsManagementStore.getUnitByKey(elem.base_unit).symbol}
              </span>
            ) : (
              <span>--</span>
            )}
          </>
        );
      },
    },
    {
      title: "Is Base Unit",
      key: "is_base_unit",
      dataIndex: "is_base_unit",
      width: "19%",
      render: (data: boolean) => {
        return <span>{!data ? "No" : "Yes"}</span>;
      },
    },
    {
      title: "",
      key: "actions",
      dataIndex: "",
      align: "right",
      width: "19%",
      render: (data: Unit) => (
        <UnitTableButtons data={data} groupName={state as string} />
      ),
    },
  ];
  return (
    <PageWrap>
      <Spin spinning={unitsManagementStore.loadingUnits}>
        <CommonTable
          rowKey={(record: Unit) => record.key}
          columns={columns}
          dataSource={unitsManagementStore.units}
          scroll={{ x: true }}
        />
      </Spin>
    </PageWrap>
  );
});
