import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React from "react";
import { StyledButton } from "../../../shared/commonStyles";
import { useNavigate } from "react-router";
import { encodeUrlName } from "../../../shared/commonFunctions";

interface SuccessProps {
  action: string;
  survey: any;
}

export const Success = ({ action, survey }: SuccessProps) => {
  const navigate = useNavigate();
  return (
    <Row justify="space-between">
      <Col lg={24} md={24} sm={24} xs={24}>
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <CheckCircleOutlined style={{ fontSize: "40px" }} />
          <Typography.Title className="color-white" level={4}>
            Survey was {action === "update" ? "updated" : "created"}{" "}
            successfully!
          </Typography.Title>
          <p>
            <span>
              Survey was {action === "update" ? "updated" : "created"}{" "}
              successfully! It is already available in Surveys section
            </span>
          </p>
          <Row justify="space-between">
            <Col>
              <StyledButton
                type="custom"
                onClick={() =>
                  navigate(
                    `/management/collect-data/${survey?.id}/${encodeUrlName(
                      survey?.name
                    )}/view-survey`
                  )
                }
              >
                <span>Open Survey</span>
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                type="custom"
                onClick={() => navigate("/management/collect-data")}
              >
                <span>Go to All Surveys</span>
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                type="custom"
                onClick={() => {
                  navigate("/management/collect-data", {
                    state: { showModal: true },
                  });
                }}
              >
                <span>Create Another Survey</span>
              </StyledButton>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
