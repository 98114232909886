import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useClickAway, useBoolean, useDynamicList } from "ahooks";
import { Button, Checkbox, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { StyledButton, FormWrapper } from "../../shared/commonStyles";
import styles from "./RegionsSelectorCheckbox.module.scss";

interface RegionsSelectorCheckboxProps {
  data: any[];
  onChange: (data: any[]) => void;
  disabled?: boolean;
}

export const RegionsSelectorCheckbox = ({
  data,
  onChange,
  disabled,
}: RegionsSelectorCheckboxProps) => {
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const { list, replace, resetList } = useDynamicList(data);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    data && resetList(data);
  }, [data]);

  useEffect(() => {
    onChange(list);
  }, [list]);

  function handleChangeCheckbox(
    checked: boolean,
    item: { checked: boolean; name: string }
  ) {
    const newItem = { ...item, checked };
    const index = list.findIndex((object) => object.name === item.name);
    replace(index, newItem);
  }

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const searchData = list.filter((item) =>
    inputValue
      ? item.name.toLowerCase().includes(inputValue.toLowerCase())
      : item
  );

  const removeChecked = () => {
    list.forEach((item, index) => {
      const newItem = { ...item, checked: false };
      replace(index, newItem);
    });
  };

  const handleCancel = () => {
    hideModal();
    removeChecked();
  };

  const handleConfirm = () => {
    hideModal();
    onChange(list);
  };

  const ref = useRef(null);
  useClickAway(() => {
    isModalVisible && hideModal();
  }, ref);

  return (
    <FormWrapper ref={ref}>
      <Input
        disabled={disabled}
        className={styles.selector}
        placeholder="Select regions"
        suffix={<DownOutlined />}
        value={inputValue}
        onChange={handleChangeInputValue}
        onFocus={showModal}
      />
      <div
        className={
          isModalVisible ? styles.selectionBox : styles.selectionBoxHidden
        }
      >
        <FormWrapper className={styles.regionsList}>
          {searchData.map((v) => (
            <div
              key={v.key}
              className={v.checked ? styles.checkActive : styles.check}
            >
              <Checkbox
                checked={v.checked}
                value={v.value}
                onChange={(e) => handleChangeCheckbox(e.target.checked, v)}
              >
                {v.name}
              </Checkbox>
            </div>
          ))}
        </FormWrapper>
        <div className={styles.footerButtons}>
          <Button type="text" onClick={handleCancel}>
            Cancel
          </Button>
          <StyledButton type="custom" onClick={handleConfirm}>
            Confirm
          </StyledButton>
          <Button
            type="text"
            className={styles.footerButtonsLast}
            onClick={removeChecked}
          >
            Clear All
          </Button>
        </div>
      </div>
    </FormWrapper>
  );
};
