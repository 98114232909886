import React from "react";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { calculatorStore } from "../../../../Carbon";
import styles from "./InfoAndNotesDrawer.module.scss";

interface OffsetInfoProps {
  data: Offset;
  onClose: () => void;
}

export const OffsetInfo = ({ data, onClose }: OffsetInfoProps) => {
  const handleDownload = () => {
    calculatorStore.downloadOffsetProof(data);
  };

  return (
    <FormWrapper>
      <div className={styles.drawerContent}>
        <ul className={styles.drawerList}>
          <li className={styles.drawerListItem}>
            <h4 className={styles.drawerListItemTitle}>Description</h4>
            <p className={styles.drawerListItemText}>{data.description}</p>
          </li>
          <li className={styles.drawerListItem}>
            <h4 className={styles.drawerListItemTitle}>Proof of offset</h4>
            {data.proof && (
              <div>
                <a
                  className={styles.drawerListItemLink}
                  target="_blank"
                  rel="noreferrer"
                  href={data.proof}
                >
                  {data.proof}
                </a>
              </div>
            )}
            {data.proofName && (
              <div>
                <a
                  className={styles.drawerListItemLink}
                  onClick={handleDownload}
                >
                  <VerticalAlignBottomOutlined />
                  <span className={styles.downloadFileText}>Download File</span>
                </a>
              </div>
            )}
            {!data.proof && !data.proofName && <span>-</span>}
          </li>
          <li className={styles.drawerListItem}>
            <h4 className={styles.drawerListItemTitle}>Region</h4>
            <div className={styles.drawerListItemText}>{data.region}</div>
          </li>
          <li className={styles.drawerListItem}>
            <h4 className={styles.drawerListItemTitle}>Cost</h4>
            <div className={styles.drawerListItemText}>{data.cost}</div>
          </li>
          <li className={styles.drawerListItem}>
            <h4 className={styles.drawerListItemTitle}>
              Offsetted amount (kg CO2e)
            </h4>
            <div className={styles.drawerListItemText}>{data.emission}</div>
          </li>
        </ul>
      </div>
      <div className={styles.drawerFooter}>
        <StyledButton onClick={onClose}>Close</StyledButton>
      </div>
    </FormWrapper>
  );
};
