import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useBoolean, useDynamicList } from "ahooks";
import { Button, InputNumber, Dropdown, Popover, message } from "antd";
import type { ColumnsType } from "antd/lib/table";
import {
  QuestionCircleOutlined,
  SwapOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { calculatorStore } from "../../../Carbon/CalculatorStore";
import { observer } from "mobx-react-lite";
import {
  fractionalNumber,
  getLinkDataSheet,
} from "../../../Carbon/CalculationUtils";
import { CommonTable, FormWrapper } from "../../../../shared/commonStyles";
import { SortDropdown } from "../../../../components/SortDropdown";

import { StyledButton } from "../../../../shared/commonStyles";
import { carbonStore } from "../../../Carbon/CarbonStore";
import { ConfirmModal } from "../../Calculator/Components/ConfirmModal";
import TableActionsButtons from "../../Calculator/Components/TableActionsButtons";
import styles from "./Scope.module.scss";
import { getCompanyInfo } from "../../../../services/companyInfo";
import { AddEmissionsModal } from "../../Calculator/Components/AddEmissionsModal";

interface ScopeProps {
  info: any;
  data: Emission[];
  goBack: () => void;
  goNext: () => void;
  emissionsUnit: string;
  conversionFactor: number;
}

interface SortScope {
  type: string;
  sortAlphabetically: boolean;
}

const Scope = observer(
  ({
    info,
    data,
    goBack,
    goNext,
    emissionsUnit,
    conversionFactor,
  }: ScopeProps) => {
    const [scopeFilled, setScopeFilled] = useState(!!data.length);
    const navigate = useNavigate();
    const { list: tableData, resetList, replace } = useDynamicList(data);
    const [sortScope, setSortScope] = useState<SortScope>({
      type: "key",
      sortAlphabetically: true,
    });
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [addingType, setAddingType] = useState<"automated" | "manual">();
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );
    const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
      useBoolean(false);

    const [
      isConfirmModalVisible,
      { setTrue: showConfirmModal, setFalse: hideConfirmModal },
    ] = useBoolean(false);

    useEffect(() => {
      loadCompanyInfo();
    }, []);

    const regions = calculatorStore.regions;

    const scopesSortOptions = [
      { name: "Name", value: "name" },
      { name: "Region", value: "region_name" },
    ];

    const convertedNumber = fractionalNumber(
      companyDetails.decimal_places_number
    );
    useEffect(() => {
      !!data.length ? setScopeFilled(true) : setScopeFilled(false);
      resetList(data);
    }, [data]);

    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";

          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
        message.error("Error while load company details!");
      }
    };

    const onDelete = (data: Emission) => {
      calculatorStore.saveDeletedEmission(data);
      calculatorStore.deleteEmission(data);
    };

    const editEmission = (editedEmissionData: Emission) => {
      if (data) {
        calculatorStore.editEmission(editedEmissionData);
      }
    };
    const onMarkAsEstimated = (data: Emission) => {
      calculatorStore.editCalculationStepperEntry({
        ...data,
      });
    };
    const handleSort = () => {
      sortScope;
      const newData = [...tableData].sort((a, b) => {
        const aStatement =
          sortScope.type === "key"
            ? a?.[sortScope.type]
            : a.factor_data?.[sortScope.type as keyof CustomManualFactor];
        const bStatement =
          sortScope.type === "key"
            ? b?.[sortScope.type]
            : b.factor_data?.[sortScope.type as keyof CustomManualFactor];
        if (sortScope.sortAlphabetically) {
          return aStatement! > bStatement! ? 1 : -1;
        } else {
          return aStatement! < bStatement! ? 1 : -1;
        }
      });
      resetList(newData);
    };

    const handleChangeConsumptionAmount = (
      consumption_amount: number,
      emissionKey: string
    ) => {
      const index = tableData.findIndex(
        (emission) => emission.key === emissionKey
      );
      replace(index, { ...tableData[index], consumption_amount });
    };

    const handleSaveConsumptionAmount = (emissionKey: string) => {
      const index = tableData.findIndex(
        (emission) => emission.key === emissionKey
      );
      calculatorStore.editScopeConsumptionAmount(
        emissionKey,
        tableData[index].consumption_amount,
        (emissions: Emission[]) => {
          resetList(emissions);
        }
      );
    };

    const handleSkip = () => {
      calculatorStore.clearScopeEmission(info.scopeName);
      goNext();
    };

    function onClickUndo() {
      calculatorStore.returnDeletedEmission();
    }

    const infoConfirmModal = {
      title: "Warning: Data may be lost",
      description: "Do you want to continue without saving?",
      confirmText: "Continue without saving",
      cancelText: "Return & Save",
    };

    const columns: ColumnsType<ManualEmission> = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (data, elem) => {
          return <>{elem?.factor_data?.name}</>;
        },
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        render: (data, elem) => {
          return <>{elem?.factor_data?.region}</>;
        },
      },

      {
        title: "Consumption Amount",
        dataIndex: "consumption_amount",
        key: "consumption_amount",
        className: styles.consumptionColumn,
        render: (data, elem, index) => {
          return (
            <>
              {!elem?.manual ? (
                <div className={styles.itemCell}>
                  <div className={styles.itemCell}>
                    <span
                      className={
                        (elem as AutomatedEmission).missing_data &&
                        styles.itemSpanWarning
                      }
                    >
                      <span className={styles.itemSpan}>
                        {convertedNumber(data)}
                      </span>
                      <span>{elem?.factor_data?.unit.split("/").at(-1)}</span>
                      {(elem as AutomatedEmission).missing_data && (
                        <Popover
                          placement="right"
                          className={styles.warningIcon}
                          overlayClassName={`popoverContent ${styles.warningPopover}`}
                          autoAdjustOverflow
                          content={
                            <>
                              <div className={styles.warningPopoverTitle}>
                                Warning
                              </div>
                              <div>
                                Some pieces of data are missing. Review the data
                                sheet to increase the accuracy of the
                                calculation
                              </div>

                              <div className={styles.warningPopoverLink}>
                                <Link
                                  to={
                                    getLinkDataSheet(
                                      elem as AutomatedEmission,
                                      carbonStore.getSheetById(
                                        (elem as AutomatedEmission)
                                          .activity_sheet
                                      )
                                    ).url
                                  }
                                  onClick={() =>
                                    localStorage.setItem(
                                      "filters",
                                      JSON.stringify(
                                        getLinkDataSheet(
                                          elem as AutomatedEmission,
                                          carbonStore.getSheetById(
                                            (elem as AutomatedEmission)
                                              .activity_sheet
                                          )
                                        ).filtersArray
                                      )
                                    )
                                  }
                                  target="_blank"
                                >
                                  View Data Sheet
                                </Link>
                              </div>
                            </>
                          }
                        >
                          <WarningOutlined />
                        </Popover>
                      )}
                    </span>
                  </div>
                  <Popover
                    placement="right"
                    title="Consumption Amount is calculated automatically"
                    content={<b>Go to ”...” - ”Edit” to edit this emission</b>}
                    className={styles.infoIcon}
                    autoAdjustOverflow
                    arrowPointAtCenter
                    overlayClassName="popoverContent"
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              ) : (
                <FormWrapper className={styles.consumptionAmountColumn}>
                  <span className={styles.itemCell}>
                    <InputNumber
                      className={styles.itemInput}
                      size="large"
                      min={0}
                      value={data}
                      onChange={(value) =>
                        handleChangeConsumptionAmount(value || 0, elem.key!)
                      }
                      onPressEnter={() =>
                        handleSaveConsumptionAmount(elem.key!)
                      }
                      onBlur={() => handleSaveConsumptionAmount(elem.key!)}
                    />
                    <span>{elem?.factor_data?.unit?.split("/").at(-1)}</span>
                  </span>
                </FormWrapper>
              )}
            </>
          );
        },
      },
      {
        title: "CO2e Factor (kg)",
        dataIndex: "factor",
        key: "factor",

        render: (_: any, data: Emission) => {
          const co2 = data?.factor_data?.constituent_gases?.co2
            ? data?.factor_data.constituent_gases?.co2
            : 0;
          const ch4 = data?.factor_data?.constituent_gases?.ch4
            ? data?.factor_data.constituent_gases?.ch4
            : 0;
          const n2o = data?.factor_data?.constituent_gases?.n2o
            ? data?.factor_data.constituent_gases?.n2o
            : 0;
          const unit = data?.factor_data?.unit ? data?.factor_data?.unit : "";
          const content = (
            <div>
              <p>
                <b>
                  Co<sub>2</sub>
                </b>
                : {co2 ? co2 + " " + unit : "NA"}
              </p>
              <p>
                <b>
                  Ch<sub>4</sub>
                </b>
                : {ch4 ? ch4 + " " + unit : "NA"}
              </p>
              <p>
                <b>
                  {" "}
                  N<sub>2</sub>o
                </b>
                : {n2o ? n2o + " " + unit : "NA"}
              </p>
            </div>
          );
          return (
            <div className={styles.itemCell}>
              <span className={styles.itemSpan}>
                {data?.factor_data?.factor ? data?.factor_data?.factor : 0}
              </span>
              <Popover
                overlayClassName="popoverContent"
                content={content}
                placement="bottom"
                title="Emission Factors "
                autoAdjustOverflow
                arrowPointAtCenter
              >
                <InfoCircleOutlined />
              </Popover>
            </div>
          );
        },
      },
      {
        title: `Total Emission (${emissionsUnit} CO2e)`,
        dataIndex: "total_emission",
        key: "total_emission",
        render: (data) => {
          return (
            <span className={styles.item}>
              {convertedNumber(Number(data.co2e_total) * conversionFactor)}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "btns",
        render: (data, _, index) => {
          return (
            <TableActionsButtons
              data={data}
              onDelete={() => onDelete(data)}
              onClickUndo={onClickUndo}
              onEditEmission={editEmission}
              onMarkAsEstimated={onMarkAsEstimated}
            />
          );
        },
      },
    ];

    return (
      <>
        <h2 className={styles.stepTitle}>
          <span>
            Scope {info.number} - ”{info.name}”
          </span>
          <Popover
            overlayClassName="popoverContent"
            content={
              <div className={styles.popoverScope}>
                <h2>Scope {info.number} emissions</h2>
                <p>{info.content}</p>
              </div>
            }
            placement="right"
            autoAdjustOverflow
            arrowPointAtCenter
          >
            <QuestionCircleOutlined />
          </Popover>
        </h2>
        <div className={styles.stepSubtitle}>
          {info.subTitle} {!!regions?.length && regions?.join(", ")}{" "}
          {info.period}
        </div>
        <p
          className={
            scopeFilled && !!tableData.length
              ? styles.stepTextFilled
              : styles.stepText
          }
        >
          {info.description} {!!regions?.length && `in ${regions?.join(", ")}`}{" "}
          in {info.period}
        </p>
        {scopeFilled && !!tableData.length && (
          <CommonTable
            className={styles.calculationTable}
            rowKey={"key"}
            rowClassName={() => styles.editableRow}
            title={() => (
              <div className={styles.TableHeader}>
                <h1 className={styles.TableHeaderTitle}>
                  <span>
                    {info.subTitle} {!!regions?.length && regions?.join(", ")}{" "}
                    {info.period}
                  </span>
                </h1>
                <Button type="text" className={styles.sortScopeButton}>
                  <Dropdown
                    overlay={
                      <SortDropdown
                        sorting={sortScope}
                        changeSorting={setSortScope}
                        options={scopesSortOptions}
                        onConfirm={() => {
                          handleSort();
                          hideDropdown();
                        }}
                      />
                    }
                    trigger={["click"]}
                    onVisibleChange={toggle}
                    visible={isDropdownShown}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <SwapOutlined rotate={90} />
                      <span>Sort</span>
                    </a>
                  </Dropdown>
                </Button>
              </div>
            )}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: true }}
          />
        )}

        <div
          className={
            scopeFilled && !!tableData.length
              ? styles.stepBtnWrapFilled
              : styles.stepBtnWrap
          }
        >
          <StyledButton
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
              setAddingType("automated");
            }}
          >
            <PlusCircleOutlined />
            Add automated emission calculation
          </StyledButton>

          <span className={styles.stepEmissionsText}>or</span>
          <StyledButton
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
              setAddingType("manual");
            }}
          >
            <PlusCircleOutlined />
            Add manual emission calculation
          </StyledButton>

          <AddEmissionsModal
            adding={addingType}
            visible={isModalVisible}
            scopeName={info.scopeName}
            onClose={() => setIsModalVisible(false)}
          />
        </div>
        <div className={styles.stepBtnsGroup}>
          <Button onClick={goBack} className={styles.whiteBtn} type="text">
            Back
          </Button>
          <StyledButton
            className={styles.continueBtn}
            onClick={goNext}
            type="primary"
            disabled={!tableData.length}
          >
            Confirm & Continue{" "}
            {info.number < 3 && `to Scope ${info.number + 1}`}
          </StyledButton>
          <Button
            onClick={!tableData.length ? goNext : showConfirmModal}
            className={styles.whiteBtn}
            type="text"
          >
            Skip for now
          </Button>
          <ConfirmModal
            infoConfirmModal={infoConfirmModal}
            onConfirm={handleSkip}
            visible={isConfirmModalVisible}
            setFalse={hideConfirmModal}
          />
        </div>
      </>
    );
  }
);

export default Scope;
