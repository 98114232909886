import React from "react";
import { BulletPointProps } from "./BulletPointPatch";

export const BulletPoint: React.FC<BulletPointProps> = ({
    isSelected,
    isLastItem,
}) => {
    const bulletStyle: React.CSSProperties = {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: isSelected ? "#A68DFB" : "#B1AFBC",
    };
    const lineStyle: React.CSSProperties = {
        position: "absolute",
        borderLeft: !isLastItem
            ? "1px solid #B1AFBC"
            : "none",
        height: "80px",
        marginLeft: "0px",
        marginTop: "10px",
    };

    return (
        <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
            <div style={bulletStyle}></div>
            {/* {!isLastItem && <div style={lineStyle}></div>} */}
        </div>
    );
};