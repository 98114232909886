import React, { useState, useEffect, useContext } from "react";
import {
  FormWrapper,
  CommonModal,
  StyledButton,
  CommonTable,
} from "../../../shared/commonStyles";
import {
  Form,
  message,
  Row,
  Col,
  Select,
  Input,
  Spin,
  InputNumber,
  Alert,
} from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import { useSize } from "ahooks";
import {
  getModifiedForMetaData,
  getSheetById,
} from "../../../services/dataSheet";
import styles from "./SurveysDataTable.module.scss";

const SearchMetaEntityModal = ({
  userSettings,
  rowData,
  visible,
  onClose,
  onChangeSettings,
  permissions,
}) => {
  if (!rowData) return;
  const [loadingMetaDataRecords, setLoadingMetaDataRecords] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [metaDataRecords, setMetaDataRecords] = useState([]);
  const [inputDataType, setInputDataType] = useState("string");
  const [initialValues, setInitialValues] = useState({
    metadata_sheet_id: rowData?.defaultMetaDataSheetId,
    record_id: null,
  });
  const [dataSheet, setDataSheet] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [metaDataEntityItems, setMetaDataEntityItems] = useState(null);
  const [searchEntityName, setSearchEntityName] = useState(null);
  const [searchByEntity, setSearchByEntity] = useState(null);

  const [form] = Form.useForm();
  const { auth } = useContext(AuthContext);
  const size = useSize(document.querySelector("body"));

  useEffect(() => {
    loadDataSheet();
  }, [rowData?.defaultMetaDataSheetId]);

  const loadPreFillValues = async () => {
    const findedSettingsValue =
      userSettings.length &&
      userSettings.find(
        (v) => v.metadata_sheet_id === rowData?.defaultMetaDataSheetId
      );

    const payload = {
      skip: 0,
      limit: 10,
      filter: {
        sheet_id: rowData?.defaultMetaDataSheetId,
      },
    };

    if (findedSettingsValue) {
      setSearchEntityName(findedSettingsValue?.searchEntityName);
      setInitialValues((prev) => ({ prev, ...findedSettingsValue }));
      form.setFieldsValue(findedSettingsValue);
    }
    loadMetaDataRecords(payload).then(async (result) => {
      findPrevSelectedRow(result, findedSettingsValue);
    });
  };

  const findPrevSelectedRow = async (metaDataRecords, settingValues) => {
    if (!!metaDataRecords && settingValues) {
      const prevRecord = metaDataRecords.find(
        (record) => record.id === settingValues?.record_id
      );
      prevRecord && setSelectedRecords([settingValues?.record_id]);
    }
  };

  useEffect(() => {
    loadPreFillValues();
  }, [userSettings]);

  const loadDataSheet = async () => {
    const dataSheet = await getSheetById(rowData?.defaultMetaDataSheetId);
    setDataSheet(dataSheet);
  };

  useEffect(() => {
    const metaDataEntityItems = dataSheet?.sheet_schema;
    setMetaDataEntityItems(metaDataEntityItems);
  }, [dataSheet]);

  useEffect(() => {
    if (initialValues?.entity_name && metaDataEntityItems) {
      const selectedEntityValue = metaDataEntityItems?.find(
        (item) => item.entity_name === initialValues?.entity_name
      );
      if (selectedEntityValue) {
        setInputDataType(selectedEntityValue.data_type);
      }
    }
  }, [initialValues, metaDataEntityItems]);

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const loadMetaDataRecords = async (requestPayload) => {
    try {
      setLoadingMetaDataRecords(true);
      const metaDataRecord = await getModifiedForMetaData(requestPayload);
      const data = await getSheetById(rowData?.defaultMetaDataSheetId);

      if (metaDataRecord) {
        const records = metaDataRecord[metaDataRecord?.sheet_name];
        const filteredRecords = records?.filter(
          (item) =>
            (item.archive === false || item.archive === undefined) &&
            item.import_status !== "PENDING_REVIEW"
        );

        if (permissions.length) {
          const identifierField =
            data.sheet_schema.find((v) => v.isIdentifier).entity_name || "";
          if (identifierField) {
            const permissionsRecords = filteredRecords.filter((v) =>
              permissions.find((item) => item == v.id)
            );

            setMetaDataRecords(permissionsRecords);
          }
        } else {
          setMetaDataRecords(filteredRecords);
        }

        return records;
      }
    } catch (error) {
      console.log("Error while loading metedata record", error);
      return null;
    } finally {
      setLoadingMetaDataRecords(false);
    }
  };
  const onFormSubmit = async (values) => {
    try {
      setSubmitting(true);
      const { record_id } = values;
      onChangeSettings(record_id, searchEntityName);
    } catch (error) {
      console.log("Something went wrong!", error);
    } finally {
      setSubmitting(false);
    }
  };

  const onClickSearchBtn = () => {
    if (!searchEntityName) {
      message.error("Please select a field to search by!");
      return;
    }
    const payload = {
      skip: 0,
      limit: 10,
      filter: {
        sheet_id: rowData?.defaultMetaDataSheetId,
      },
    };
    if (searchEntityName) {
      payload.search_query = { [searchEntityName]: searchByEntity };
    }
    loadMetaDataRecords(payload);
  };

  const generateDynamicColumns = (dataSheetSchema) => {
    if (dataSheetSchema?.length) {
      const columns = dataSheetSchema?.reduce((acc, item) => {
        const column = [];

        if (item?.expose) {
          column.push({
            title: item.display_name,
            schemaField: item,
            dataType: item.data_type,
            inputType: item.input_type,
            required: item.required,
            dataIndex: item.entity_name,
            width: "100px",
            ellipsis: "false",
            editable: "true",
            render: (value, data) => {
              try {
                return value;
              } catch (error) {
                return "";
              }
            },
          });
        }

        return acc.concat(column);
      }, []);

      return columns;
    }
    return [];
  };
  let generateColumns = null;
  if (metaDataEntityItems) {
    generateColumns = generateDynamicColumns(metaDataEntityItems);
  }

  const onSelectRowChange = (record) => {
    setSelectedRecords(record);
    form.setFieldsValue({ ...initialValues, record_id: record[0] });
  };

  const rowSelection = {
    selectedRowKeys: selectedRecords,
    onChange: onSelectRowChange,
    hideSelectAll: true,
    type: "radio",
  };
  const handleKeyDown = (event) => {
    if (event.nativeEvent.key === "Enter") {
      onClickSearchBtn();
    }
  };

  return (
    <CommonModal
      className={styles.Modal}
      width={size && size?.width > 900 ? "760px" : "100%"}
      title={rowData?.popup_title}
      visible={visible}
      footer={null}
      onCancel={onClose}
    >
      <Spin spinning={loadingMetaDataRecords}>
        {dataSheet?.archive ? (
          <Alert
            style={{ width: "100%" }}
            message="Sorry, you can't access this survey, please contact to admin!!"
            type="info"
          />
        ) : (
          <>
            <Alert message={rowData?.info_message} type="info" />
            <FormWrapper style={{ marginTop: "10px" }}>
              <Row
                gutter={[10, 10]}
                justify={"space-between"}
                style={{ margin: "0", width: "100%" }}
              >
                <Col lg={9} md={9} sm={24} xs={24} style={{ padding: "0px" }}>
                  <Select
                    placeholder={
                      rowData?.search_field_label || "Select a field to search."
                    }
                    disabled={!metaDataEntityItems?.length}
                    style={{ width: "100%" }}
                    onChange={(value) => setSearchEntityName(value)}
                    value={searchEntityName}
                  >
                    {metaDataEntityItems?.map((item, i) => {
                      if (!item?.expose) return;
                      if (item?.status !== "ACTIVE") return;
                      return (
                        <Select.Option value={item?.entity_name} key={i}>
                          {item?.display_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col lg={9} md={9} sm={24} xs={24} style={{ padding: "0px" }}>
                  {inputDataType === "number" ? (
                    <InputNumber
                      placeholder={
                        rowData?.search_field_label_value || "Search..."
                      }
                      disabled={!metaDataEntityItems?.length}
                      onChange={(e) => {
                        setSearchByEntity(e.target.value);
                      }}
                      value={searchByEntity}
                      onKeyPress={handleKeyDown}
                    />
                  ) : (
                    <Input
                      placeholder={
                        rowData?.search_field_label_value || "Search..."
                      }
                      disabled={!metaDataEntityItems?.length}
                      onChange={(e) => setSearchByEntity(e.target.value)}
                      value={searchByEntity}
                      onKeyPress={handleKeyDown}
                    />
                  )}
                </Col>
                <Col lg={5} md={5} sm={24} xs={24} style={{ padding: "0px" }}>
                  <StyledButton
                    loading={loadingMetaDataRecords}
                    type="custom"
                    onClick={() => onClickSearchBtn()}
                    style={{
                      width: "100%",
                      margin: "1px 0px 0px 0px",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                    }}
                  >
                    Search
                  </StyledButton>
                </Col>
              </Row>
              <Form
                form={form}
                initialValues={initialValues}
                onFieldsChange={(data) => onChangeFieldsData(data)}
                onFinish={(data) => onFormSubmit(data)}
              >
                <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      name="record_id"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Please select a record!",
                        },
                      ]}
                    >
                      <CommonTable
                        className="tableContiner"
                        rowKey="id"
                        dataSource={
                          metaDataRecords.length > 0 ? metaDataRecords : ""
                        }
                        columns={generateColumns}
                        rowSelection={rowSelection}
                        scroll={{ x: true }}
                        pagination={true}
                      />
                    </Form.Item>
                  </Col>
                  <Row style={{ width: "100%" }} justify="end">
                    <Col lg={5} md={6} sm={6} xs={6}>
                      <Form.Item>
                        <StyledButton
                          loading={submitting}
                          type="custom"
                          htmlType="submit"
                          disabled={selectedRecords.length === 0}
                        >
                          Select Facility
                        </StyledButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </FormWrapper>
          </>
        )}
      </Spin>
    </CommonModal>
  );
};

export default SearchMetaEntityModal;
