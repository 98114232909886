import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space } from "antd";
import { PageWrap, CommonTable, StyledButton } from "../../shared/commonStyles";
import { EyeOutlined } from "@ant-design/icons";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUserGroups } from "../../services/users";
import moment from "moment";
import { YearMonthDayFormat } from "../../shared/constants";
import { useBoolean } from "ahooks";
import { byAuditManagementAuditType } from "../../graphql/queries";
import { encodeUrlName } from "../../shared/commonFunctions";

const AuditStandardsPage = () => {
  const navigate = useNavigate();
  const [
    isAuditStandardsLoading,
    {
      setTrue: setLoadingAuditStandards,
      setFalse: finishLoadingAuditStandards,
    },
  ] = useBoolean(false);
  const [auditStandards, setAuditStandards] = useState<Audit[]>([]);

  const getStandardsAudits = async () => {
    setLoadingAuditStandards();

    const auditList: any = await API.graphql(
      graphqlOperation(byAuditManagementAuditType, {
        audit_type: "SUSTAINABILITY_STANDARDS",
      })
    );
    const result = auditList.data?.byAuditManagementAuditType.items;
    const userData = await Auth.currentSession();
    const username = userData.getAccessToken()["payload"]["username"];
    const groups = await getUserGroups();
    const userGroups = groups.filter((group: UserGroup) =>
      group?.group_users?.includes(username)
    );
    const isGroupsFound = (group_id: string) => {
      return userGroups?.some(
        (group: UserGroup) => group._id.$oid === group_id
      );
    };
    const assignedItems =
      result.filter((audit: Audit) => {
        const isUserGroups = JSON.parse(audit?.assigned_groups)?.some(
          (group_id: string) => isGroupsFound(group_id)
        );
        const isUser = JSON.parse(audit?.assigned_users)?.some(
          (user: string) => user === username
        );
        if (isUserGroups || isUser) return audit;
      }) || [];

    setAuditStandards(assignedItems);
    finishLoadingAuditStandards();
  };

  useEffect(() => {
    getStandardsAudits();
  }, []);

  const onEditRecord = (audit: Audit) => {
    const auditId = audit.id;
    if (auditId) {
      navigate(
        `/audit/audit-standards/${auditId}/${encodeUrlName(
          audit?.name.replace("/", " ")
        )}/detail`,
        { state: { audit: audit } }
      );
    }
  };

  const columns = [
    {
      title: "AUDIT NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 150,
    },
    {
      title: "START DATE",
      dataIndex: "start_date",
      key: "startdate",
      ellipsis: true,
      width: 100,
      render: (record: string) => {
        const startDate = record
          ? moment(record).format(YearMonthDayFormat)
          : null;
        return startDate;
      },
    },
    {
      title: "DEADLINE",
      dataIndex: "end_date",
      key: "enddate",
      ellipsis: true,
      width: 100,
      render: (record: string) => {
        const endDate = record
          ? moment(record).format(YearMonthDayFormat)
          : null;
        return endDate;
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (audit: Audit) => {
        return (
          <Space direction="horizontal">
            <StyledButton type="default" onClick={() => onEditRecord(audit)}>
              <EyeOutlined />
            </StyledButton>
          </Space>
        );
      },
    },
  ];

  return (
    <PageWrap>
      <CommonTable
        loading={isAuditStandardsLoading}
        rowKey={(audit: Audit) => audit.id}
        dataSource={auditStandards}
        columns={columns}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        scroll={{ x: true }}
      />
    </PageWrap>
  );
};

export default AuditStandardsPage;
