import React, { useEffect } from 'react';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { TooltipWithStyles } from '../../../shared/commonStyles';
import { sdgGoalsStaticList } from '../../../shared/constants';
import { GoalItem } from './styles';

export const Goals = ({ handleStep, companySave, company }) => {
  const [form] = Form.useForm();
  const goals = company?.goals || [];

  const handleSubmit = (values) => {
    const goalsPayload = values.goals.map((el) => {
      const goalItem = goals.find((item) => el == item.goal);
      if (goalItem) {
        return goalItem;
      }
      return {
        goal: el,
        description: '',
      };
    });

    companySave({ goals: goalsPayload });
  };

  useEffect(() => {
    const arrFromIndex = goals.map((el) => +el.goal);
    form.setFieldsValue({ goals: arrFromIndex });
  }, [goals]);

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      autoComplete="off"
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
    >
      <Form.Item
        name="goals"
        required={true}
        rules={[
          {
            required: true,
            message: 'Please choose at least one SDG!',
          },
        ]}
      >
        <Checkbox.Group>
          <Row align="top" style={{ justifyContent: 'flex-start !important' }}>
            {sdgGoalsStaticList.map((item,index) => (
              <Col span={8} style={{ marginBottom: '33px' }} key={index}>
                <Checkbox value={+item.goal}>
                  <GoalItem>
                    <img src={item.image_url} alt="" />
                    <span>{`${item.goal} ${item.title}`}</span>
                    <TooltipWithStyles
                      placement="bottomLeft"
                      title={
                        <>
                          <p style={{ color: '#83827F', fontWeight: 600, marginBottom: '8px' }}>
                            {item.title}
                          </p>
                          <span style={{ color: '#83827F' }}>
                            End poverty in all its forms everywhere
                          </span>
                        </>
                      }
                      color="#FFF"
                    >
                      <InfoCircleOutlined />
                    </TooltipWithStyles>
                  </GoalItem>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <div className="report-form-controls">
        <Button type="link" htmlType="reset" onClick={() => handleStep(0)}>
          Back
        </Button>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </div>
    </Form>
  );
};
