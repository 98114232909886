import React, { useEffect, useState, useMemo } from "react";
import { Spin, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { API, Storage } from "aws-amplify";
import * as queries from "../../graphql/queries";

import {
  PageWrap,
  FormWrapper,
  Center,
  StyledButton,
} from "../../shared/commonStyles";
import CategoryTabs from './components/CategoryTabs'
import ServiceCardContainer from './components/ServiceCardContainer';

import "./MarketplacePage.css";

const ALL_CATEGORIES = 'All Categories';

const MarketplacePage = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [appData, setAppData] = useState(new Map());
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(ALL_CATEGORIES)
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchToggle, setSearchToggle] = useState(false);

  useEffect(() => {
    const getSignedUrl = async (url) => {
      try {   
        const resp = await Storage.get(url)
        return resp;
      } catch (error) {
        console.log('getSignedUrl error', error);
        return '';
      }
    }

    API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_marketplace",
      },
    })
      .then(async (res) => {
        const appCategoryMap = new Map();
        appCategoryMap.set(ALL_CATEGORIES, [])

        const marketData = JSON.parse(res["data"]["datalake"]);

        await Promise.all(marketData.map(async (element) => {
          const category = element.category;

          if(element.image_url) {
            element.image_url = await getSignedUrl(element.image_url)
          }
          
          if (appCategoryMap.has(category)) {
            const list = appCategoryMap.get(category);
            list.push(element);
            appCategoryMap.set(category, list);
          } else {
            appCategoryMap.set(category, [element]);
          }

          const allList = appCategoryMap.get(ALL_CATEGORIES);
          allList.push(element)
          appCategoryMap.set(ALL_CATEGORIES, allList)
        }));

        setAppData(appCategoryMap);
        setCategories([...appCategoryMap.keys()])
        setDataLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const filteredServices = useMemo(() => {
    const services = appData.get(activeCategory) || []
    if (!searchKeyword || searchKeyword.trim() === '') {
      setSearchKeyword('')
      return services;
    }
    
    return services.filter(service => service.name?.toLowerCase()?.includes(searchKeyword.toLowerCase()))
  }, [activeCategory, appData, searchToggle])

  const onChangeActiveCategory = (category) => {
    setSearchKeyword(''); // search keyword is not persisted on category switch
    setActiveCategory(category);
  }

  const onSubmitSearch = (event) => {
    event.preventDefault();
    setSearchToggle(prevState => !prevState);
  }

  if(!dataLoaded) {
    return (
      <PageWrap>
        <Center>
          <Spin />
        </Center>
      </PageWrap>
    );
  }

  return (
    <PageWrap>
      <div className="marketplace-header">
        <h3>Enhance your experience with Hydrus by connecting the most popular services</h3>

        <form className="search-container" onSubmit={onSubmitSearch}>
          <FormWrapper>
            <Input 
              placeholder='Search by name'
              prefix={<SearchOutlined />}
              size="large"
              value={searchKeyword}
              onChange={(event) => setSearchKeyword(event.target.value)}
              required={false}
            />
          </FormWrapper>
          <StyledButton 
            className="margin-0" 
            type="submit"
            onClick={onSubmitSearch}
          >
            Search
          </StyledButton>
        </form>
      </div>

      <div className="marketplace-container">
        <CategoryTabs 
          categories={categories} 
          activeCategory={activeCategory}
          onClick={onChangeActiveCategory}  
        />
        <ServiceCardContainer services={filteredServices} />
      </div>
    </PageWrap>
  );
};

export default MarketplacePage;
