import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import moment from "moment";
import SearchableColumn from "../../../components/SearchableColumn";
import { CustomLink } from "../styles";
import { YearMonthDayFormat } from "../../../shared/constants";
import { CommonTable } from "../../../shared/commonStyles";

const NewsDataTable = ({
  newsListData,
  loadingNewslist,
  pagination,
  onChangePagination,
}) => {
  const [namesFilter, setNamesFilter] = useState([]);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  useEffect(() => {
    if (newsListData && newsListData.length) {
      const filterData = newsListData?.reduce((arr, val) => {
        const { category } = val;
        if (!arr.includes(category)) {
          arr.push(category);
        }
        return arr;
      }, []);

      setNamesFilter(filterData);
    }
  }, [newsListData]);

  const handleChange = (pagination, filters, sorter) => {
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    onChangePagination(pagination);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      width: "15%",
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "30%",
      searchPlaceHolder: "Search Title",
      render: (_, record) => {
        return (
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: record.content }}></div>
            }
          >
            <CustomLink href={record.url} target="__blank">
              <div dangerouslySetInnerHTML={{ __html: record.title }}></div>
            </CustomLink>
          </Tooltip>
        );
      },
    },
    {
      title: "Published",
      dataIndex: "published_date",
      width: "10%",
      render: (record) => {
        return record ? moment(record).format(YearMonthDayFormat) : null;
      },
    },
  ];

  return (
    <>
      <CommonTable
        loading={loadingNewslist}
        rowKey={(data) => {
          const id = data?.["_id"]?.["$oid"];
          return id;
        }}
        dataSource={newsListData}
        columns={columns}
        scroll={{ x: true }}
        onChange={handleChange}
        pagination={pagination}
      />
    </>
  );
};

export default NewsDataTable;
