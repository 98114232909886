import React from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { Col, Form, Row, Typography, Card, Radio } from "antd";
const { Title } = Typography;
import { RightOutlined } from "@ant-design/icons";
import { sdgGoalsStaticList } from "../../../../../shared/constants";
const Step1 = ({ onClickNext, onClickPrevious }) => {
  return (
    <FormWrap width="100%">
      <FormWrapper>
        <Title level={4}>Which goal are you interested in?</Title>
        <Form.Item
          name="selectedGoals"
          label=""
          rules={[
            {
              required: true,
              message: "Please select at least one SDG Goal!",
            },
          ]}
        >
          <Radio.Group
            style={{
              width: "100%",
            }}
          >
            <Row gutter={[16, 16]}>
              {sdgGoalsStaticList.map((data, index) => (
                <Col
                  xs={24}
                  sm={24}
                  md={2}
                  lg={4}
                  key={index}
                  className="sdgClass"
                >
                  <Card
                    style={{
                      backgroundColor: "#443A59",
                      textAlign: "center",
                      minHeight: "75px",
                    }}
                    bodyStyle={{ padding: "15px" }}
                  >
                    <Radio value={data.goal}>
                      <Row
                        gutter={[16, 16]}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Col span={5}>
                          <img src={data.image_url} width="100%" />
                        </Col>
                        <Col
                          span={19}
                          className="color-white"
                          style={{ textAlign: "left" }}
                        >
                          {`${data.goal}-${data.title}`}
                        </Col>
                      </Row>
                    </Radio>
                  </Card>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        <Row style={{ marginTop: "5px" }}>
          <Col span={24}>
            <Form.Item>
              <StyledButton
                onClick={() => onClickPrevious()}
                type="custom"
                // ghost
                disabled={true}
              >
                <span>Back</span>
              </StyledButton>
              <StyledButton onClick={onClickNext} type="custom">
                <span>Continue to step 2</span> <RightOutlined />
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step1;
