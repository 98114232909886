import moment from "moment";

//for creating constituent_gases object in mass upload factors
export const constituentGasesFields = {
  co2: 0,
  ch4: 0,
  n2o: 0,
  hfcs: 0,
  pfcs: 0,
  sf6: 0,
  nf3: 0,
  other: 0,
};

//for mass upload factors generate sample file
export const sampleFields = {
  category: "Enter category here",
  description: "Enter description here",
  name: "Enter name here",
  region: "Enter region here",
  region_name: "Enter region name here",
  sector: "Enter sector here",
  source: "Enter source here",
  source_link: "Enter source link here",
  factor_calculation_method: "Enter ghg scientific method here",
  year: moment().year(),
  factor: 0,
  unit_type: "Enter unit type here",
  unit: "Enter unit here",
  ...constituentGasesFields,
};

//for check uploaded file by fields
export const requiredFields = [
  "name",
  "region",
  "region_name",
  "sector",
  "category",
  "source",
];

//for export custom factors
export const excelFields = {
  category: "category",
  description: "description",
  name: "name",
  region: "region",
  region_name: "region_name",
  sector: "sector",
  source: "source",
  source_link: "source_link",
  ghg_scientific_method: "ghg_scientific_method",
  year: "year",
  factor: "factor",
  unit: "unit",
  co2: "co2",
  ch4: "ch4",
  n2o: "n2o",
  hfcs: "hfcs",
  pfcs: "pfcs",
  sf6: "sf6",
  nf3: "nf3",
  other: "other",
};

//for constituent_gases selection on edit custom factor
export const constituentGasesData = [
  {
    value: "co2",
    label: "Carbon Dioxide (CO2)",
  },
  {
    value: "ch4",
    label: "Methane (CH4)",
  },
  {
    value: "n2o",
    label: "Nitrous Oxide (N2O)",
  },
  {
    value: "hfcs",
    label: "Hydrofluorocarbons (HFCs)",
  },
  {
    value: "pfcs",
    label: "Perfluorocarbons (PFCs)",
  },
  {
    value: "sf6",
    label: "Sulfur Hexafluoride (SF6)",
  },
  {
    value: "nf3",
    label: "Nitrogen Trifluoride (NF3)",
  },
  {
    value: "other",
    label: "Other",
  },
];
