import React,{useEffect, useState} from "react";
import { StyledButton, FormWrapper } from '../../shared/commonStyles';
import { 
  Typography,
  Spin,
  Form,
  Input,
  message
} from "antd";
import { Auth } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import styles from "./PasswordlessSignin.module.scss"

export const PasswordlessSignin = ({email_id, awsmobile}) => {    
    const [isSignupDone, setIsSignupDone] = useState(false);
    const [isSignInError, setIsSignInError] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [loadingCreateAccount, setLoadingCreateAccount] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    
    const [loadingVerify, setLoadingVerifyEmail] = useState(false);
    const [initialValues, setInitialValues] = useState({
      code:''
    }); 
    const [loginInitialValues, setLoginInitialValues] = useState({
      email:''
    }); 
    const onChangeFieldsData = (data) => {
      // store data in state when form data change
      if (data?.[0]?.name[0]) {
          setInitialValues((oldState) => ({
          ...oldState,
          [data?.[0]?.name[0]]: data?.[0]?.value,
          }));
      }
    };
    const onChangeLoginFieldsData = (data) => {
      // store data in state when form data change
      if (data?.[0]?.name[0]) {
        setLoginInitialValues((oldState) => ({
          ...oldState,
          [data?.[0]?.name[0]]: data?.[0]?.value,
          }));
      }
    };  
    async function answerCustomChallenge() {
      form
        .validateFields()
        .then(async () => {
          try {
            setLoadingVerifyEmail(true);
            const { code } = initialValues;
            // Send the answer to the User Pool
            // This will throw an error if it’s the 3rd wrong answer
        
            const user = JSON.parse(localStorage.getItem("cognitoUser"));
            // rehydrate the CognitoUser
            const authenticationData = {
              Username: user.username,
            };
            const authenticationDetails =
              new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
            const poolData = {
              UserPoolId: awsmobile.aws_user_pools_id,
              ClientId: awsmobile.aws_user_pools_web_client_id,
            };
            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const userData = {
              Username: user.username,
              Pool: userPool,
            };
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            // After this set the session to the previously stored user session
            cognitoUser.Session = user.session;
            // rehydrating the user and sending the auth challenge answer directly will not
            // trigger a new email
            cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");        
            
            const results = await Auth.sendCustomChallengeAnswer(cognitoUser, code);            
            // It we get here, the answer was sent successfully,
            // but it might have been wrong (1st or 2nd time)
            // So we should test if the user is authenticated now
        
            // This will throw an error if the user is not yet authenticated:
            await Auth.currentSession();
            localStorage.setItem(
                "loggedIn",
                true
            );           
            window.location.href = window.location.origin;            
            
          } catch (_a) {
            console.log("Apparently the user did not enter the right code", _a);
            message.error(`Apparently the user did not enter the right code ${_a}`);
          }finally {
            setLoadingVerifyEmail(false);
        }
        })
        .catch((error) => message.error(`Error while verifying email  ${error}`));
    }

    const sendVerificationCode = async(emailId) => {
      try {
        setIsLoading(true)
        const user = await Auth.signIn(emailId);        
        localStorage.setItem(
            "cognitoUser",
            JSON.stringify({
                username: emailId,
                session: user.Session,
            })
        );
        setIsSignupDone(true)
      }catch (error) {        
        setIsSignInError(true)
        console.log("error while creating account", error);
      }finally {
        setIsLoading(false);
      }
    }

    const setLoginVerficationCode = () => {
      form
        .validateFields()
        .then(async () => {
            try {
              setLoadingCreateAccount(true);
              const { email } = loginInitialValues;
              sendVerificationCode(email)
              setShowEmailForm(false)
            } catch (error) {
                message.error(`Error while login ${error}`);
                console.log("error while login", error);
            } finally {
                setLoadingCreateAccount(false);
            }
        })
        .catch((error) => console.log("error form in account", error));
    }
    useEffect(() => {
      if(email_id === "' '"){
        setShowEmailForm(true)
      }else{
        sendVerificationCode(email_id)
      }
      
    }, [email_id]);
    
    return(
      <Spin spinning={isLoading}
        wrapperClassName={styles.form}
      >            
        {isSignupDone && (   
          <>
            <Typography.Title level={2} style={{color:'#fff',marginTop:'10px'}}>Success!</Typography.Title>
            <p className="color-grey">Passwordless sign-in request has been sent to your email. Please click on the secure sign-in link or enter the 6 digit verification code that has been sent to your email. The code and link are valid for 15 minutes.</p>
            <FormWrapper>
              <Form
                form={form}
                initialValues={initialValues}
                onFieldsChange={(data) => onChangeFieldsData(data)}
              >
                  <Form.Item
                      name="code"
                      label="Code (6 digit)"
                      labelCol={{ span: 24 }}
                      rules={[
                      {
                          required: true,
                          message: "Please enter 6 digit code",
                      },
                      ]}
                  >
                      <Input placeholder="Enter Code" />
                  </Form.Item>                    
                  <Form.Item>                        
                    <StyledButton
                        onClick={() => answerCustomChallenge()}
                        type="custom"
                        style={{width:'100%',margin:'5px 0'}}
                        loading={loadingVerify}
                    >
                        Verify Email
                    </StyledButton>
                  </Form.Item>
              </Form>
            </FormWrapper>                
          </>
        )}
        {showEmailForm && (
          <>
            <Typography.Title level={2} style={{marginBottom:'0',color:'#fff',marginTop:'10px'}}>Welcome Back</Typography.Title>
            <p className="color-grey">Please enter your details</p>
            <FormWrapper>
              <Form
                form={form}
                initialValues={loginInitialValues}
                onFieldsChange={(data) => onChangeLoginFieldsData(data)}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  labelCol={{ span: 24 }}
                  rules={[
                  {
                      required: true,
                      type: "email",
                      message: "Please Input Email Address!",
                  },
                  ]}
                >
                  <Input
                    className={styles.input}
                    placeholder="Enter your email "
                  />
                </Form.Item>                    
                <Form.Item
                >       
                  <StyledButton                            
                    onClick={() => setLoginVerficationCode()}
                    type="custom"
                    style={{width:'100%', margin:'5px 0'}}
                    loading={loadingCreateAccount}
                  >
                      Login
                  </StyledButton>
                </Form.Item>
              </Form>
            </FormWrapper>
          </>
        )}
        {isSignInError && (   
          <>
            <Typography.Title level={2} style={{color:'#f00',marginTop:'10px'}}>Warning!</Typography.Title>
            <p className="color-grey">Passwordless sign-in request is not successful. Please re-check your email for a secure login link.</p>
          </>
        )}          
      </Spin>
    );
  }