import _ from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { Auth } from "aws-amplify";
import carbonService from "../../services/CarbonService";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
class CarbonStore {
  sheets: DataSheet[];
  ghgCategories: IGHGCategories;
  customFactorFilters: IGHGCategories;
  initialGhgCategories: IGHGCategories;
  customGhgCategories: IGHGCategories;
  aggregatedEmissionFactors: AggregatedFactor[];
  factors: Factor[];
  units: {
    unit_type: string[];
    unit: string;
  }[];
  regions: {
    region_name: string;
    region: string;
  }[];
  aggregatedCustomFactors: AggregatedFactor[];
  customFactorsList: Factor[];
  emissionFactorsList: Factor[];
  loadingSheets: boolean;
  loadingCategories: boolean;
  loadingCustomCategories: boolean;
  loadingAggregatedEmissionsFactors: boolean;
  loadingAggregatedCustomFactors: boolean;
  loadingFactors: boolean;
  aggregatedCustomFactorsPagination: Pagination;
  aggregatedEmissionFactorsPagination: Pagination;
  factorsPagination: Pagination;

  constructor() {
    this.sheets = [];
    this.ghgCategories = {};
    this.customFactorFilters = {};
    this.initialGhgCategories = {};
    this.customGhgCategories = {};
    this.aggregatedEmissionFactors = [];
    this.units = [];
    this.regions = [];
    this.aggregatedCustomFactors = [];
    this.factors = [];
    this.customFactorsList = []; // for custom factors without pagination (export custom factors)
    this.emissionFactorsList = []; // for emission factors without pagination (checkbox in manual creating calculation flow)
    this.loadingSheets = true;
    this.loadingCategories = true;
    this.loadingCustomCategories = true;
    this.loadingAggregatedEmissionsFactors = true;
    this.loadingFactors = true;
    this.loadingAggregatedCustomFactors = false;
    this.aggregatedCustomFactorsPagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    };
    this.aggregatedEmissionFactorsPagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    };
    this.factorsPagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    };

    makeAutoObservable(this);
  }

  resetCategories() {
    this.ghgCategories = this.initialGhgCategories;
  }
  changeCustomFilters(filters: IGHGCategories) {
    this.customFactorFilters = filters;
  }

  fetchCategories(
    requestPayload?: IGHGCategories,
    mainField?: string,
    callback?: () => void
  ) {
    const payload = (
      mainField ? { ...requestPayload, main_field: mainField } : requestPayload
    ) as GHGCategoriesPayload;
    carbonService
      .ghgFactorCategories(payload)
      .then((response) => {
        runInAction(() => {
          const isError = response?.statusCode >= 400;
          if (isError) {
            message.error("Invalid query");
          } else this.ghgCategories = { ...response };

          this.loadingCategories = false;
          callback?.();
        });
      })
      .catch(() => {
        runInAction(() => {
          this.loadingCategories = false;
        });
      });

    carbonService
      .ghgFactorCategoriesCustom(payload)
      .then((response) => {
        const unit_type = response.unit_type.map((type: Array<any>) =>
          type ? type[0] : type
        );
        runInAction(() => {
          this.customGhgCategories = { ...response, unit_type };
          this.loadingCustomCategories = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.loadingCustomCategories = false;
        });
      });
  }

  makeQuery(params: ListQueryParams, isAggregatedFactors?: boolean): ListQuery {
    const { pagination, sortEmission, ...filters } = params;
    const factorsFilter = Object.keys(filters)?.length ? filters : {};
    const matchQuery = _.reduce(
      factorsFilter,
      (result: any, value: any, key) => {
        if (value) {
          if (value instanceof Array) {
            if (value.length > 0) {
              result[key] = key === "name" ? value[0] : { $in: value };
            }
          } else {
            if (value) result[key] = key === "name" ? value : { $in: [value] };
          }
        }
        return result;
      },
      {}
    );
    const query = {
      skip: pagination ? (pagination.current - 1) * pagination.pageSize : 0,
      limit: pagination?.pageSize || 10,
      filter: matchQuery,
      ...(sortEmission && {
        sort:
          isAggregatedFactors && sortEmission.type === "key"
            ? {
                name: sortEmission.sortAlphabetically ? 1 : -1,
                unit_type: sortEmission.sortAlphabetically ? 1 : -1,
              }
            : {
                [sortEmission.type === "key" ? "_id" : sortEmission.type]:
                  sortEmission.sortAlphabetically ? 1 : -1,
              },
      }),
    };
    return query;
  }

  fetchAggregatedFactors(filters: ListQueryParams, isCustom = false) {
    isCustom
      ? this.fetchAggregatedCustomFactors(filters)
      : this.fetchAggregatedEmissionsFactors(filters);
  }
  fetchFactors(
    isCustom: boolean,
    aggregationFilters?: any,
    filter?: any,
    isPaginated = true
  ) {
    if (isPaginated && isCustom) {
      this.loadingFactors = true;
    }

    const query = this.makeQuery(
      isCustom && !isPaginated ? this.customFactorFilters : filter || {}
    );

    const newQuery = isPaginated
      ? {
          ...query,
          filter: { ...aggregationFilters, ...query.filter },
        }
      : { ...query, skip: 0, limit: 1000 };

    carbonService.ghgFactors(isCustom, newQuery).then((response) => {
      runInAction(() => {
        if (isPaginated) {
          this.factors = response.data;
          this.changeFactorsPagination({
            ...(filter?.pagination || this.factorsPagination),
            total: response.filteredCount,
          });
          this.loadingFactors = false;
        } else {
          if (isCustom) {
            this.customFactorsList = response.data;
          } else {
            this.emissionFactorsList = response.data;
          }
        }
        if (isPaginated && isCustom) {
          this.loadingFactors = false;
        }
      });
    });
  }

  fetchAggregatedEmissionsFactors(filters?: ListQueryParams) {
    this.loadingAggregatedEmissionsFactors = true;
    const query = this.makeQuery(filters || {}, true);

    carbonService.ghgAggregatedFactors(false, query).then((response) => {
      runInAction(() => {
        this.aggregatedEmissionFactors = response.data?.map((item: Factor) => ({
          ...item,
          key: uuidv4(),
        }));
        this.changeAggregatedFactorsPagination({
          ...(filters?.pagination || this.aggregatedEmissionFactorsPagination),
          total: response.filteredCount,
        });
        this.loadingAggregatedEmissionsFactors = false;
      });
    });
  }

  fetchAggregatedCustomFactors(filters?: ListQueryParams) {
    this.loadingAggregatedCustomFactors = true;
    const query = this.makeQuery(filters || {}, true);
    carbonService.ghgAggregatedFactors(true, query).then((response) => {
      runInAction(() => {
        this.aggregatedCustomFactors = response.data?.map((item: Factor) => ({
          ...item,
          key: uuidv4(),
        }));
        this.changeAggregatedCustomFactorsPagination({
          ...(filters?.pagination || this.aggregatedCustomFactorsPagination),
          total: response.filteredCount,
        });
        this.loadingAggregatedCustomFactors = false;
      });
    });
  }

  deleteCustomFactor(id: string) {
    this.loadingFactors = true;
    carbonService.removeUserGhgFactors(id).then(() => {
      this.fetchFactors(
        true,
        {},
        {
          pagination: this.factorsPagination,
        }
      );
      this.loadingFactors = false;
    });
  }

  saveCustomFactor = async (customFactorData: Factor) => {
    this.loadingFactors = true;
    const data: any = await Auth.currentSession();
    const group = data["accessToken"]["payload"]["cognito:groups"].filter(
      (element: string) => element.includes("org:")
    )[0];
    // make sure factor value is a number
    let factor_value = Number(0);
    if (!isNaN(Number(customFactorData.factor))) {
      factor_value = Number(customFactorData.factor)
    }
    const username = data["accessToken"]["payload"]["username"];
    const factorPayload: FactorPayload = {
      ...customFactorData,
      factor: factor_value,
      group,
      username,
    };
    if (factorPayload._id) {
      carbonService.updateUserGhgFactors(factorPayload).then(() => {
        this.fetchFactors(
          true,
          {},
          {
            pagination: this.factorsPagination,
          }
        );
      });
    } else {
      carbonService.addUserGhgFactors(factorPayload).then(() => {
        this.fetchFactors(
          true,
          {},
          {
            pagination: this.factorsPagination,
          }
        );
        this.loadingFactors = false;
      });
    }
  };

 async fetchSheets() {
    const payload = {
      filter: { archive: false },
    };
    this.loadingSheets = true;
    carbonService
      .listSheets(payload)
      .then((response) => {
        runInAction(() => {
          if (response?.length) this.sheets = response;
          this.loadingSheets = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.loadingSheets = false;
        });
      });
  }

  getSheetById(sheetId: string) {
    const sheet =
      this.sheets?.find((sheet) => sheet._id.$oid === sheetId) ||
      ({} as DataSheet);
    return sheet;
  }

  fetchUnits() {
    carbonService.listFactorsUnits().then((response) => {
      runInAction(() => {
        this.units = response || [];
      });
    });
  }

  fetchRegions() {
    carbonService.listFactorsRegions().then((response) => {
      runInAction(() => {
        this.regions = response || [];
      });
    });
  }

  changeAggregatedCustomFactorsPagination(pagination: Pagination) {
    this.aggregatedCustomFactorsPagination = pagination;
  }
  changeAggregatedFactorsPagination(pagination: Pagination) {
    this.aggregatedEmissionFactorsPagination = pagination;
  }
  changeFactorsPagination(pagination: Pagination) {
    this.factorsPagination = pagination;
  }
}

const carbonStore = new CarbonStore();

export { carbonStore, CarbonStore };
