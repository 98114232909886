import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import { DatalakeQuery } from "../graphql/API";
import { GraphQLResult } from "@aws-amplify/api";

export const listNotifications = async (requestPayload: any) => {
  const response = await (API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "list_notifications",
      request_payload: JSON.stringify(requestPayload),
    },
  }) as Promise<GraphQLResult<DatalakeQuery>>);
  return JSON.parse(response.data?.datalake || "[]") as Notification[];
};

export const updateNotifications = async (requestPayload: any) => {
  const response = await (API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "update_notifications",
      request_payload: JSON.stringify(requestPayload),
    },
  }) as Promise<GraphQLResult<DatalakeQuery>>);
  return JSON.parse(response.data?.datalake || "[]") as any;
};
