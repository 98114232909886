import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetState } from "ahooks";
import { Col, Row, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { StepperFormWrap } from "../styles";
import Scope from "./Scope/Scope";
import Success from "./Success/Success";
import {
  PageWrap,
  StyledButton,
  StyledProgress,
  StyledSteps,
} from "../../../shared/commonStyles";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import { carbonStore } from "../../Carbon/CarbonStore";
import { calculatorStore } from "../../Carbon/CalculatorStore";
import { observer } from "mobx-react-lite";
import { calculationStore } from "../../Carbon/CalculationsStore";
import styles from "./CalculationStepper.module.scss";
import { Offset } from "./Offset/Offset";
import { getCompanyInfo } from "../../../services/companyInfo";
import { doConversion } from "../../../services/dataSheet";
import { getEmissionsUnit } from "../../Carbon/CalculationUtils";

export interface CalculatorData {
  date: RangeValue;
  region: SelectionItem[];
  name: string;
  description: string;
}

const CalculationStepper = observer(() => {
  const [currentStep, setCurrentStep] = useState(0);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [conversionFactor, setConversionFactor] = useState(1);

  const navigate = useNavigate();

  const [generalInfo, setGeneralInfo] = useSetState<CalculatorData>({
    date: [moment(), moment()],
    region: [],
    name: "",
    description: "",
  } as CalculatorData);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  useEffect(() => {
    loadCompanyInfo();
    carbonStore.fetchRegions();
    calculatorStore.resetState();
    calculatorStore.saveSelectedRegions([]);
  }, []);

  useEffect(() => {
    if (carbonStore.regions.length) {
      setGeneralInfo({
        region: carbonStore.regions.map((item, index) => {
          return {
            key: item.region_name + index,
            name: item.region_name,
            checked: false,
          };
        }),
      });
    }
  }, [carbonStore.regions]);

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const steps = [
    { title: "General information" },
    {
      title: "Scope 1",
      number: 1,
      scopeName: ScopeName.SCOPE_1,
      name: "burn",
      subTitle: "Scope 1 Emissions - ",
      regions: generalInfo.region?.filter((v) => v.checked).map((v) => v.name),
      period: `${generalInfo.date?.[0].format(
        "YYYY-MM-DD"
      )} - ${generalInfo.date?.[1].format("YYYY-MM-DD")}`,
      description: "Add all Scope 1 emissions produced by your company",
      content: `Scope 1 covers emissions from sources that an organisation owns or controls directly – 
        for example from burning fuel in our fleet of vehicles (if they’re not electrically-powered).`,
    },
    {
      title: "Scope 2",
      number: 2,
      scopeName: ScopeName.SCOPE_2,
      name: "buy",
      subTitle: "Scope 2 Emissions - ",
      regions: generalInfo.region?.filter((v) => v.checked).map((v) => v.name),
      period: `${generalInfo.date?.[0].format(
        "YYYY-MM-DD"
      )} - ${generalInfo.date?.[1].format("YYYY-MM-DD")}`,
      description: "Add all Scope 2 emissions produced by your company",
      content: `Scope 2 are emissions that a company causes indirectly when the energy it purchases and uses is produced. 
      For example, for our electric fleet vehicles the emissions from the generation of the electricity they're powered by would fall into this category.`,
    },
    {
      title: "Scope 3",
      number: 3,
      scopeName: ScopeName.SCOPE_3,
      name: "besides",
      subTitle: "Scope 3 Emissions - ",
      regions: generalInfo.region?.filter((v) => v.checked).map((v) => v.name),
      period: `${generalInfo.date?.[0].format(
        "YYYY-MM-DD"
      )} - ${generalInfo.date?.[1].format("YYYY-MM-DD")}`,
      description: "Add all Scope 3 emissions produced by your company",
      content: `Scope 3 encompasses emissions that are not produced by the company itself, and not the result of activities from assets owned or controlled by them, but by those that it’s indirectly responsible for, up and down its value chain. 
      An example of this is when we buy, use and dispose of products from suppliers. 
      Scope 3 emissions include all sources not within the scope 1 and 2 boundaries.`,
    },
    {
      title: "Offsets and certificates",
      scopeName: ScopeName.OFFSETS,
      subTitle: "Offsets and certificates",
      regions: generalInfo.region?.filter((v) => v.checked).map((v) => v.name),
      period: `${generalInfo.date?.[0].format(
        "YYYY-MM-DD"
      )} - ${generalInfo.date?.[1].format("YYYY-MM-DD")}`,
      description: "Add all Offsets and certificates bought by your company",
      content: `Offsets and certificates`,
    },
    { title: "Summary" },
  ];

  const handleChangeRegion = (data: SelectionItem[]) => {
    setGeneralInfo({
      region: data,
    });
    calculatorStore.saveSelectedRegions(
      data.filter((v) => v.checked).map((v) => v.name)
    );
  };

  const handleChangeDate = (date: RangeValue) => {
    setGeneralInfo({ date });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChangeName = (name: string) => {
    setGeneralInfo({ name });
  };

  const handleChangeDescription = (description: string) => {
    setGeneralInfo({ description });
  };

  const handleSaveData = () => {
    const data: CalculationPayload = {
      name: generalInfo.name,
      description: generalInfo.description,
      date_start: generalInfo.date?.[0].format("YYYY-MM-DD"),
      date_end: generalInfo.date?.[1].format("YYYY-MM-DD"),
      regions: generalInfo.region
        .filter((v) => v.checked)
        .map((v) => {
          return { key: v.key, name: v.name };
        }),
      items: [...calculatorStore.emissions, ...calculatorStore.offsets],
    };
    calculationStore.saveCalculation(data);
  };

  const handleClose = () => {
    navigate("/carbon/calculations");
  };

  const handleSaveAndClose = () => {
    handleSaveData();
    handleClose();
  };

  const stepsComponents = [
    <GeneralInfo
      key="generalInfo"
      data={{ date: generalInfo.date, region: generalInfo.region }}
      goNext={nextStep}
      onChangeRegion={handleChangeRegion}
      onChangeDate={handleChangeDate}
    />,
    <Scope
      key="scope-1"
      info={steps[1]}
      data={calculatorStore.savedScope1Emissions}
      goNext={nextStep}
      goBack={prevStep}
      emissionsUnit={getEmissionsUnit(companyDetails.emissions_unit)}
      conversionFactor={conversionFactor}
    />,
    <Scope
      key="scope-2"
      info={steps[2]}
      data={calculatorStore.savedScope2Emissions}
      goNext={nextStep}
      goBack={prevStep}
      emissionsUnit={getEmissionsUnit(companyDetails.emissions_unit)}
      conversionFactor={conversionFactor}
    />,
    <Scope
      key="scope-3"
      info={steps[3]}
      data={calculatorStore.savedScope3Emissions}
      goNext={nextStep}
      goBack={prevStep}
      emissionsUnit={getEmissionsUnit(companyDetails.emissions_unit)}
      conversionFactor={conversionFactor}
    />,
    <Offset
      key="offset"
      info={steps[4]}
      data={calculatorStore.offsets}
      goNext={nextStep}
      goBack={prevStep}
      emissionsUnit={getEmissionsUnit(companyDetails.emissions_unit)}
      conversionFactor={conversionFactor}
    />,
    <Success
      key="success"
      data={generalInfo}
      goBack={prevStep}
      onChangeName={handleChangeName}
      onChangeDescription={handleChangeDescription}
      onSave={handleSaveData}
    />,
  ];

  const goBack = () => {
    if (generalInfo.name.length) {
      handleSaveAndClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <PageWrap>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="top"
        className={styles.calculationStepperHeader}
      >
        <Col lg={2} md={24} sm={24} xs={24}>
          <StyledButton
            type="custom"
            onClick={goBack}
            hovercolor="#A68DFB"
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="#fff"
          >
            <ArrowLeftOutlined /> <span>Exit</span>
          </StyledButton>
        </Col>
        <Col
          className="user-flow-steps-wrap color-white"
          lg={18}
          md={24}
          sm={24}
          xs={24}
        >
          <Row justify="center" align="middle">
            <StyledSteps
              current={currentStep}
              type="navigation"
              className={styles.calculationStepper}
              onChange={setCurrentStep}
            >
              {steps.map((item) => (
                <StyledSteps.Step key={item.title} title={item.title} />
              ))}
            </StyledSteps>
          </Row>
        </Col>
        <Col lg={3} md={24} sm={24} xs={24}>
          <Row justify="end">
            <StyledButton
              type="primary"
              disabled={!generalInfo.name.length}
              onClick={handleSaveAndClose}
            >
              Save & Close
            </StyledButton>
          </Row>
        </Col>
      </Row>
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={(currentStep + 1) * (100 / steps.length)}
        showInfo={false}
        className={styles.progress}
      />
      <div>
        <StepperFormWrap>{stepsComponents[currentStep]}</StepperFormWrap>
      </div>
    </PageWrap>
  );
});

export default CalculationStepper;
