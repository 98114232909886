import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import styles from "./ImportData.module.scss";

interface ImportState {
  importId: string;
  action?: string;
}
interface FilesDropdownProps {
  record: DataSheetRecord;
  files?: ImportedFile[];
  updateRecord: (record: DataSheetRecord) => void;
  state?: ImportState;
}

export const FilesDropdown = ({
  record,
  files,
  updateRecord,
  state,
}: FilesDropdownProps) => {
  const [recordFiles, setRecordFiles] = useState<ImportedFile[]>([]);
  const loadRecordFiles = async () => {
    const newFiles: ImportedFile[] = [];
    files?.forEach((file) =>
      newFiles.push({ name: file.name, dataId: file.dataId })
    );
    setRecordFiles(newFiles);
  };
  useEffect(() => {
    record && loadRecordFiles();
  }, [record]);

  const handleChangeFile = async (options: any[]) => {
    console.log("options", options);
    const selectedFiles = recordFiles
      .filter((file) => options.find((v) => v.value === file.dataId))
      .map((file) => file.dataId);
    if (selectedFiles) {
      const newRecord = {
        ...record,
        filesList: [...record?.filesList, ...selectedFiles],
        operation: "File Upload",
      } as any;
      updateRecord(newRecord);
      // loadRecordFiles();
    }
  };

  return (
    <Select
      disabled={state?.action === "review"}
      mode="multiple"
      labelInValue
      dropdownClassName={styles.fileSelectorDropdown}
      value={
        record.filesList?.length
          ? recordFiles
              .filter((file) => record.filesList?.includes(file.dataId!))
              ?.map((file) => ({
                value: file.dataId,
                label: (
                  <span>
                    <FileTextOutlined className="fileSelectorIcon" />
                    <span
                      className={`${styles.uploadedFileText} uploadedFileText`}
                    >
                      {file.name}
                    </span>
                  </span>
                ),
              }))
          : []
      }
      onChange={(v) => handleChangeFile(v)}
      options={recordFiles.map((item) => ({
        label: item.name,
        value: item.dataId,
        disabled: item.isBaseFile,
      }))}
    />
  );
};
