import React from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";

import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";

const { Title } = Typography;

const step1 = ({ onClickNext, disable, onCancelClick }) => {
  return (
    <>
      <FormWrap width="45%">
        <FormWrapper>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={3}>Standard Description</Title>
              <Divider
                style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Name"
                name="name"
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Brief Description"
                name="description"
              >
                <Input placeholder="Enter Description" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Row justify="flex-start">
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => onCancelClick()}
                    hoverbgcolor="transparent"
                    bgcolor="transparent"
                    bordercolor="transparent"
                    color="#fff"
                  >
                    <span>Cancel</span>
                  </StyledButton>
                  <StyledButton
                    className="margin-5"
                    disabled={disable}
                    onClick={() => onClickNext()}
                    type="custom"
                  >
                    Continue to step 2
                  </StyledButton>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </FormWrapper>
      </FormWrap>
    </>
  )
}

export default step1