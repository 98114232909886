import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import { Rdl as ARJS } from "@grapecity/activereports/core";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { userInfoStore } from "../../../../Carbon/UserInfoStore";
import { staticImagesList } from "../../../../../shared/constants";
import { fractionalNumber } from "../../../../Carbon/CalculationUtils";
import { Col, Dropdown, Menu, Radio, Row, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { fetchDataWithRetry } from "../../../../../services/importData";
import { getEmissionsUnit } from "../../../../Carbon/CalculationUtils";
import { Core } from "@grapecity/activereports";
import moment from "moment";
import { getCompanyInfo } from "../../../../../services/companyInfo";
import { doConversion } from "../../../../../services/dataSheet";
Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY || "");

const numeral = require("numeral");

type TExportType = "pdf" | "xlsx" | "tabular-data" | null;
interface ReportStructure {
  fields: {
    name: string;
    value: string;
    type?: string;
    length: number;
  }[];
}
const ExportCalculationButton = observer(function ExportCalculationButton() {
  const categories = [
    { value: "1", text: "1: Purchased Goods & Services" },
    { value: "2", text: "2: Capital Goods" },
    {
      value: "3",
      text: " 3: Fuel- and Energy-Related Activities Not Included in Scope 1 or Scope 2",
    },
    { value: "4", text: "4: Upstream Transportation and Distribution" },
    { value: "5", text: "5: Waste Generated in Operations" },
    { value: "6", text: "6: Business Travel" },
    { value: "7", text: "7: Employee Commuting" },
    { value: "8", text: "8: Upstream Leased Assets" },
    { value: "9", text: "9: Downstream Transportation and Distribution" },
    { value: "10", text: "10: Processing of Sold Products" },
    { value: "11", text: "11: Use of Sold Products" },
    { value: "12", text: "12: End-of-Life Treatment of Sold Products" },
    { value: "13", text: "13: Downstream Leased Assets" },
    { value: "14", text: "14: Franchises" },
    { value: "15", text: "15: Investments" },
  ];
  const [allExportTypes, setAllExportTypes] = useState([
    { label: "PDF", value: "pdf", available: false },
    { label: "XLSX", value: "xlsx", available: false },
    { label: "CSV", value: "tabular-data", available: false },
  ]);

  const [exportType, setExportType] = useState<TExportType>(null);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );

  const convertedFactorData = fractionalNumber(4);
  const [loading, setLoading] = useState(false);
  const [emissionsData, setEmissionsData] = useState<any[]>([]);
  const [conversionFactor, setConversionFactor] = useState(1);
  const convertedNumber = fractionalNumber(
    companyDetails.decimal_places_number
  );
  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
    }
  };
  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const getEmissionsData = async () => {
    setLoading(true);
    try {
      const job_id =
        calculatorStore.calculation.isDrilldownEnabled &&
        !!calculatorStore.calculation.drilldownFilters?.length
          ? await calculatorStore.massAggregateDrillDownRecords()
          : "";
      let resultSummary: any = null;
      if (job_id) {
        const result = await fetchDataWithRetry(job_id);
        resultSummary = JSON.parse(result?.resultSummary || "");
        if (resultSummary) {
          const isError = resultSummary?.statusCode >= 400;
          if (isError) {
            message.error(resultSummary?.body);
            return Promise.reject();
          }
        } else {
          return Promise.reject();
        }
      }

      const newEmissions =
        calculatorStore.calculation.isDrilldownEnabled &&
        calculatorStore.calculation.drilldownFilters?.length
          ? await Promise.all(
              toJS(calculatorStore.emissions).map(async (record) => {
                const currentFilter =
                  calculatorStore.calculation.drilldownFilters?.find(
                    (v) =>
                      v?.dataSheetId ===
                      (record as AutomatedEmission)?.activity_sheet
                  );
                if (!record.manual && currentFilter) {
                  const records = resultSummary?.[0]?.[record._id.$oid] || [];
                  console.log("records", resultSummary);
                  let category = "";
                  if ("category" in (record as AutomatedEmission).factor_data) {
                    const factorData = (record as AutomatedEmission)
                      .factor_data;
                    category = factorData.category;
                  }
                  const newRecords = records?.map((item: DrillDownRecord) => {
                    const newFilter =
                      currentFilter?.column_value &&
                      moment(item.group).format("YYYY-MM-DD") !== item.group
                        ? [
                            {
                              column: currentFilter?.column,
                              op: "$gte",
                              column_value: moment(item.group)
                                .startOf(
                                  currentFilter?.column_value === "annual"
                                    ? "year"
                                    : "month"
                                )
                                .toISOString(),
                            },
                            {
                              column: currentFilter?.column,
                              op: "$lte",
                              column_value: moment(item.group)
                                .endOf(
                                  currentFilter?.column_value === "annual"
                                    ? "year"
                                    : "month"
                                )
                                .toISOString(),
                            },
                          ]
                        : [
                            {
                              column: currentFilter?.column,
                              op: "$eq",
                              column_value: item.group,
                            },
                          ];
                    return {
                      ...item,
                      name: record.factor_data?.name,
                      region_name: record.factor_data?.region_name,
                      custom_name: record.custom_name || "",
                      drill_down_item: item.group,
                      factor: record.factor_data?.factor,
                      ...(category && {
                        category,
                      }),
                      consumption_amount:
                        item.sum +
                        ` ${record.factor_data?.unit?.split("/").at(-1)}`,
                      emission: item.sum * record.factor_data?.factor,
                      time_period: currentFilter?.column_value,
                      filter: newFilter,
                      isExpanded: true,
                    };
                  });
                  return { ...record, children: newRecords };
                } else return record;
              })
            )
          : toJS(calculatorStore.emissions);
      setEmissionsData(newEmissions);
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    } finally {
      setLoading(false);
    }
  };

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const darkTheme = {
    Theme: {
      Colors: {
        Background1: "#342A48",
        Background2: "#000",
        Color1: "#fff",
        Color2: "#e6e6e6",
        ChartProgress: "#A68DFB",
        ChartRow: "#716A88",
        ChartBorder: "#716A88",
        TableBorder: "#504769",
        TableRows: "#42385F",
        TableHeader: "#35294A",
        TotalTableRows: "#42385F",
      },
      Images: [
        {
          Name: "footerLogo",
          MIMEType: "image/png",
          ImageData: staticImagesList.hydrusIconWithLetterLogo,
        },
      ],
    },
  };
  const lightTheme = {
    Theme: {
      Colors: {
        Background1: "#ffffff",
        Background2: "#f1f1f1",
        Color1: "#000",
        Color2: "#3da7a8",
        ChartProgress: "#A68DFB",
        ChartRow: "#EEEEF4",
        ChartBorder: "#E1E1F0",
        TableBorder: "#CEC8E3",
        TableRows: "#FFFFFF",
        TableHeader: "#E8E8F6",
        TotalTableRows: "#E8E8F6",
      },
      Images: [
        {
          Name: "footerLogo",
          MIMEType: "image/png",
          ImageData: staticImagesList.hydrusGreyLogo,
        },
      ],
    },
  };
  const [exportTheme, setExportTheme] = useState(darkTheme);
  const viewerRef = useRef<Viewer>(null);
  const handleChangeMode = (value: string) => {
    value === "dark" && setExportTheme(darkTheme);
    value === "light" && setExportTheme(lightTheme);
  };
  const calculation = toJS(calculatorStore.calculation);
  const getScopeName = (scope: ScopeName) => {
    switch (scope) {
      case ScopeName.SCOPE_1:
        return "Scope 1";
      case ScopeName.SCOPE_2:
        return "Scope 2";
      case ScopeName.SCOPE_3:
        return "Scope 3";
      default:
        return scope;
    }
  };

  const emissionsScopeOne = () => {
    return emissionsData
      .filter(
        (emission) => !emission.archived && emission.scope === ScopeName.SCOPE_1
      )
      ?.map((emission: Emission, index: number) => {
        return {
          ...emission,
          index: index + 1,
          name: emission.factor_data.name,
          category: emission.factor_data.category,
          region_name: emission.factor_data.region_name,
          factor:
            exportType === "pdf"
              ? convertedFactorData(emission.factor_data.factor).toString()
              : emission.factor_data.factor.toString(),
          scope: getScopeName(emission.scope),
          consumption_amount:
            emission?.consumption_amount +
            ` ${emission?.factor_data?.unit.split("/").at(-1)}`,
          emission: convertedNumber(
            emission?.emission! * conversionFactor
          ).toString(),
        };
      })
      ?.map((item: any) =>
        item?.children?.length
          ? [
              item,
              ...item?.children.map((v: any) => ({
                ...v,
                emission: convertedNumber(
                  v.emission * conversionFactor
                ).toString(),
                factor:
                  exportType === "pdf"
                    ? convertedFactorData(v?.factor).toString()
                    : v?.factor.toString(),
              })),
            ]
          : item
      )
      .flat();
  };
  const emissionsScopeTwo = () => {
    return emissionsData
      .filter(
        (emission) => !emission.archived && emission.scope === ScopeName.SCOPE_2
      )
      .map((emission: Emission, index: number) => {
        return {
          ...emission,
          index: index + 1,
          name: emission.factor_data.name,
          category: emission.factor_data.category,
          region_name: emission.factor_data.region_name,
          factor:
            exportType === "pdf"
              ? convertedFactorData(emission.factor_data.factor).toString()
              : emission.factor_data.factor.toString(),
          scope: getScopeName(emission.scope),
          consumption_amount:
            emission.consumption_amount +
            ` ${emission.factor_data?.unit?.split("/").at(-1)}`,
          emission: convertedNumber(
            emission.emission! * conversionFactor
          ).toString(),
        };
      })
      ?.map((item: any) =>
        item?.children?.length
          ? [
              item,
              ...item?.children.map((v: any) => ({
                ...v,
                emission: convertedNumber(
                  v.emission * conversionFactor
                ).toString(),
                factor:
                  exportType === "pdf"
                    ? convertedFactorData(v?.factor).toString()
                    : v?.factor.toString(),
              })),
            ]
          : item
      )
      .flat();
  };
  const emissionsScopeThree = () => {
    return emissionsData
      .filter(
        (emission) => !emission.archived && emission.scope === ScopeName.SCOPE_3
      )
      .map((emission: Emission, index: number) => {
        return {
          ...emission,
          name: emission.factor_data.name,
          factor_category: emission.factor_data.category,
          region_name: emission.factor_data.region_name,
          category: emission.category,
          factor:
            exportType === "pdf"
              ? convertedFactorData(emission.factor_data.factor).toString()
              : emission.factor_data.factor.toString(),
          index: index + 1,
          scope: getScopeName(emission.scope),
          consumption_amount:
            emission.consumption_amount +
            ` ${emission.factor_data?.unit?.split("/").at(-1)}`,
          emission: convertedNumber(
            emission.emission! * conversionFactor
          ).toString(),
        };
      })
      ?.map((item: any) =>
        item?.children?.length
          ? [
              item,
              ...item?.children.map((v: any) => ({
                ...v,
                emission: convertedNumber(
                  v.emission * conversionFactor
                ).toString(),
                factor:
                  exportType === "pdf"
                    ? convertedFactorData(v?.factor).toString()
                    : v?.factor.toString(),
              })),
            ]
          : item
      )
      .flat();
  };
  const offsets = () => {
    return toJS(calculatorStore.offsets)
      .filter((offset) => !offset.archived)
      ?.map((offset: any, index: number) => {
        return {
          ...offset,
          cost: offset.cost.toString(),
          index: index + 1,
          proofData: offset.proofName || offset.proof,
          emission: convertedNumber(
            offset.emission * conversionFactor
          ).toString(),
        };
      });
  };

  useEffect(() => {
    emissionsScopeOne();
    emissionsScopeTwo();
    emissionsScopeThree();
    offsets();
  }, [calculatorStore.calculationItemsFilter, emissionsData]);

  const calculationInfo = [
    {
      nameTotal: "Produced",
      valueTotal:
        convertedNumber(
          ((calculation?.total_calculate?.scope1_total || 0) +
            (calculation?.total_calculate?.scope2_total || 0) +
            (calculation?.total_calculate?.scope3_total || 0)) *
            conversionFactor
        ) +
        " " +
        getEmissionsUnit(companyDetails.emissions_unit) +
        " CO2e",
    },
    {
      nameTotal: "Offsetted",
      valueTotal:
        convertedNumber(
          calculation.total_calculate?.offsetted_total! * conversionFactor
        ) +
        " " +
        getEmissionsUnit(companyDetails.emissions_unit) +
        " CO2e",
    },
    {
      nameTotal: `Unattributed`,
      valueTotal:
        convertedNumber(
          calculation.total_calculate?.unattributed_total! * conversionFactor
        ) +
        " " +
        getEmissionsUnit(companyDetails.emissions_unit) +
        " CO2e",
    },
  ];
  const getPercentValue = (
    number: string | number,
    sum: string | number | 0
  ) => {
    const total = numeral(number);
    if (+sum) {
      return total.divide(+sum).multiply(100).value();
    }
    return 0;
  };
  const totalScopesInfo = [
    {
      name: getScopeName(ScopeName.SCOPE_3),
      value: convertedNumber(
        calculation.total_calculate?.scope3_total! * conversionFactor
      ),
      percentValue: getPercentValue(
        calculation.total_calculate?.scope3_total!,
        calculation.total_calculate?.all_total!
      ).toFixed(2),
    },
    {
      name: getScopeName(ScopeName.SCOPE_2),
      value: convertedNumber(
        calculation.total_calculate?.scope2_total! * conversionFactor
      ),
      percentValue: getPercentValue(
        calculation.total_calculate?.scope2_total!,
        calculation.total_calculate?.all_total!
      ).toFixed(2),
    },
    {
      name: getScopeName(ScopeName.SCOPE_1),
      value: convertedNumber(
        calculation.total_calculate?.scope1_total! * conversionFactor
      ),
      percentValue: getPercentValue(
        calculation.total_calculate?.scope1_total!,
        calculation.total_calculate?.all_total!
      ).toFixed(2),
    },
  ];
  const calculationStructure: ReportStructure = {
    fields: [
      { name: "nameTotal", value: "nameTotal", type: "text", length: 50 },
      { name: "valueTotal", value: "valueTotal", type: "number", length: 50 },
    ],
  };
  const isDrillDown =
    calculation.isDrilldownEnabled && !!calculation.drilldownFilters?.length;
  const emissionsStructure: ReportStructure = {
    fields: [
      { name: "#", value: "index", length: 4 },
      { name: "Name", value: "name", length: isDrillDown ? 13 : 21 },
      { name: "Region", value: "region_name", length: isDrillDown ? 12 : 16 },
      {
        name: isDrillDown ? "Drill-down item" : "",
        value: isDrillDown ? "drill_down_item" : "",
        length: isDrillDown ? 12 : 0,
      },
      { name: "Custom name", value: "custom_name", length: 8 },
      { name: "Category", value: "category", length: 13 },
      { name: "Consumption amount", value: "consumption_amount", length: 13 },
      { name: "CO2e Factor", value: "factor", length: 12 },
      {
        name: `Total Emission (${getEmissionsUnit(
          companyDetails.emissions_unit
        )} CO2e)`,
        value: "emission",
        length: 13,
      },
    ],
  };
  const offsetsStructure: ReportStructure = {
    fields: [
      { name: "#", value: "index", length: 5 },
      { name: "Name", value: "name", length: 25 },
      { name: "Region", value: "region", length: 15 },
      { name: "Cost", value: "cost", length: 15 },
      { name: "Proof of offset", value: "proofData", length: 25 },
      {
        name: `Offsetted amount (${getEmissionsUnit(
          companyDetails.emissions_unit
        )} CO2e)`,
        value: "emission",
        length: 15,
      },
    ],
  };
  const getFieldValue = (fieldName: string) => {
    const value = `=Fields!${fieldName}.Value`;
    return value;
  };
  const getColumnWidth = (fieldLength: number) => {
    const result = (7.5 * fieldLength) / 100;
    return result;
  };

  const generateReport = async () => {
    const calculationTitle: ARJS.Table = {
      Name: "CalculationTitle",
      Type: "table",
      DataSetName: "DataSetCalculationTitle",
      TableColumns: [{ Width: "7.5in" }],
      PageBreak: "End",
      Label: getFieldValue("name"),
      Left: "0.5in",
      Style: {
        VerticalAlign: "bottom",
      },
      Details: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_reportName`,
                  Value: "Greenhouse Gas Emissions",
                  CanGrow: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    FontWeight: "Bold",
                    PaddingTop: "3in",
                    FontSize: "20pt",
                    TextAlign: "Center",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_report`,
                  Value: "Calculation Report",
                  CanGrow: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    FontWeight: "Bold",
                    FontSize: "20pt",
                    TextAlign: "Center",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_calculationName`,
                  Value: `${calculation.name}`,
                  CanGrow: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    FontWeight: "SemiBold",
                    FontSize: "16pt",
                    TextAlign: "Center",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_period`,
                  Value: `${moment(
                    (calculation.date_start as { $date: string })?.$date ||
                      (calculation.date_start as string)
                  ).format("YYYY-MM-DD")} - ${moment(
                    (calculation.date_end as { $date: string })?.$date ||
                      (calculation.date_end as string)
                  ).format("YYYY-MM-DD")}`,
                  CanGrow: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    FontWeight: "SemiBold",
                    FontSize: "16pt",
                    TextAlign: "Center",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_description`,
                  Value: `${calculation.description}`,
                  CanGrow: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    FontWeight: "Normal",
                    FontSize: "12pt",
                    TextAlign: "Center",
                  },
                },
              },
            ],
          },
        ],
      },
    };
    const getScopeChart = (dataSet: string) => {
      const chart: ARJS.DvChart = {
        Name: `Chart_Calculation`,
        Type: "dvchart",
        DataSetName: dataSet,
        Palette: "Custom",
        Legend: {
          Hidden: true,
        },
        CustomPalette: [exportTheme.Theme.Colors.ChartProgress],
        Style: {
          VerticalAlign: "middle",
          FontFamily: "Inter, sans-serif",
          BackgroundColor: exportTheme.Theme.Colors.ChartRow,
          Border: {
            Width: "1pt",
            Style: "Solid",
            Color: exportTheme.Theme.Colors.ChartBorder,
          },
        },
        PlotArea: {
          Legends: [
            {
              Hidden: true,
            },
          ],
          Axes: [
            {
              AxisType: "X",
              Plots: ["Plot"],
              Labels: false,
              LineStyle: {
                Border: {
                  Width: "0pt",
                  Style: "Solid",
                },
              },
            },
            {
              AxisType: "Y",
              Plots: ["Plot"],
              Max: `${
                +calculation.total_calculate?.all_total! * conversionFactor
              }`,
              Labels: false,
            },
          ],
        },
        Plots: [
          {
            PlotName: "Plot",
            PlotChartType: "Bar",
            Config: {
              Bar: {
                Width: 1,
              },
              SwapAxes: true,
              LineAspect: "Spline",
              LineStyle: {
                Style: "Solid",
                Width: "0pt",
              },
            },
            Encodings: {
              Values: [
                {
                  Field: {
                    Value: [getFieldValue("value")],
                  },
                  Aggregate: "Sum",
                },
              ],
              Category: {
                Field: {
                  Value: [getFieldValue("name")],
                },
              },
              Color: {
                Field: {
                  Value: [getFieldValue("percentValue")],
                },
              },
            },
          },
        ],
      };
      return chart;
    };
    const calculationTable: ARJS.Table = {
      Name: `Table_Calculation`,
      Type: "table",
      DataSetName: "DataSetCalculation",
      PageBreak: "End",
      Left: "0.5in",
      TableColumns: calculationStructure.fields.map((f) => ({
        Width: `${getColumnWidth(f.length)}in`,
      })),
      Style: {
        VerticalAlign: "middle",
        FontFamily: "Inter, sans-serif",
      },
      Header: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length,
                Item: {
                  Type: "textbox",
                  Name: `totalOverview`,
                  Value: `Short overview`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "16pt",
                    VerticalAlign: "middle",
                    TextAlign: "center",
                    Color: exportTheme.Theme.Colors.Color1,
                    PaddingTop: "13pt",
                    PaddingBottom: "54pt",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 1")?.name
                  }- ${
                    totalScopesInfo.find((v) => v.name === "Scope 1")
                      ?.percentValue
                  }%`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "left",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 1")?.value
                  } ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "right",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
            ],
          },
          {
            Height: "20pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length,
                Item: getScopeChart("DataSetTotalScopeOne"),
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 2")?.name
                  }- ${
                    totalScopesInfo.find((v) => v.name === "Scope 2")
                      ?.percentValue
                  }%`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "left",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 2")?.value
                  } ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "right",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
            ],
          },
          {
            Height: "20pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length,
                Item: getScopeChart("DataSetTotalScopeTwo"),
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 3")?.name
                  }- ${
                    totalScopesInfo.find((v) => v.name === "Scope 3")
                      ?.percentValue
                  }%`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "left",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
              {
                ColSpan: calculationStructure.fields.length / 2,
                Item: {
                  Type: "textbox",
                  Name: `scopeTotal`,
                  Value: `${
                    totalScopesInfo.find((v) => v.name === "Scope 3")?.value
                  } ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "right",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.Background1,
                    PaddingBottom: "10pt",
                    PaddingTop: "25pt",
                  },
                },
              },
            ],
          },
          {
            Height: "20pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length,
                Item: getScopeChart("DataSetTotalScopeThree"),
              },
            ],
          },
          {
            Height: "75pt",
            TableCells: [
              {
                ColSpan: calculationStructure.fields.length,
                Item: {
                  Type: "textbox",
                  Name: "empty_line",
                },
              },
            ],
          },
        ],
      },
      Details: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: calculationStructure.fields.map((f) => ({
              Item: {
                Type: "textbox",
                Name: `calculation_value_${f.value}`,
                Value: `${getFieldValue(f.value)}`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  FontWeight: "SemiBold",
                  FontSize: "12pt",
                  VerticalAlign: "middle",
                  TextAlign: f.type === "text" ? "left" : "right",
                  Color: exportTheme.Theme.Colors.Color1,
                  BackgroundColor: exportTheme.Theme.Colors.TotalTableRows,
                  PaddingLeft: "24pt",
                  PaddingRight: "24pt",
                  PaddingTop: "15pt",
                  PaddingBottom: "15pt",
                },
              },
            })),
          },
        ],
      },
      Footer: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  Name: `calculation_value_total`,
                  Value: `Total`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "left",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.TotalTableRows,
                    TopBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "Solid",
                      Width: "1pt",
                    },
                    PaddingLeft: "24pt",
                    PaddingRight: "24pt",
                    PaddingTop: "8pt",
                    PaddingBottom: "21pt",
                  },
                },
              },
              {
                Item: {
                  Type: "textbox",
                  Name: `textbox_value_totalCalculationValue`,
                  Value: `${convertedNumber(
                    calculation.total_calculate?.all_total! * conversionFactor
                  )} ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "Bold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: "right",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.TotalTableRows,
                    TopBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "Solid",
                      Width: "1pt",
                    },
                    PaddingLeft: "24pt",
                    PaddingRight: "24pt",
                    PaddingTop: "8pt",
                    PaddingBottom: "21pt",
                  },
                },
              },
            ],
          },
        ],
      },
    };
    const getEmissionsDetails = (emissions: any[]) => {
      const table: ARJS.Table = {
        Name: `Table_EmissionsDetails`,
        Type: "table",
        TableColumns: emissionsStructure.fields.map((f) => ({
          Width: `${getColumnWidth(f.length)}in`,
        })),
        Header: {
          TableRows: [
            {
              Height: "10pt",
              TableCells: [
                {
                  ColSpan: emissionsStructure.fields.length,
                  Item: {
                    Type: "textbox",
                    Name: `emissions_value_EmissionsInfo`,
                    Value: `Emissions details`,
                    CanGrow: true,
                    KeepTogether: true,
                    Style: {
                      VerticalAlign: "middle",
                      TextAlign: "center",
                      PaddingTop: "16pt",
                      PaddingBottom: "8pt",
                      FontSize: "14pt",
                      FontWeight: "SemiBold",
                      Color: exportTheme.Theme.Colors.Color1,
                    },
                  },
                },
              ],
            },
          ],
        },
        Footer: {
          TableRows: emissions
            ?.filter((v: any) => !v?.isExpanded)
            ?.map((emission) => ({
              Height: "15pt",
              TableCells: [
                {
                  Item: {
                    Type: "richtext",
                    Name: "EmissionsList",
                    CanGrow: true,
                    MarkupType: "HTML",
                    Value: `<h3>${emission.index}. </h3>`,
                    Style: {
                      Color: exportTheme.Theme.Colors.Color1,
                    },
                  },
                },
                {
                  ColSpan: emissionsStructure.fields.length - 1,
                  Item: {
                    Type: "richtext",
                    Name: "EmissionsList",
                    CanGrow: true,
                    MarkupType: "HTML",
                    Value: `<h3>${emission.factor_data.name}</h3>
                        <h4>General Information</h4>
                        <h4>Custom Name</h4>
                        ${console.log("emission", emission)}
                          <p>
                            ${emission.custom_name || "---"}
                          </p>
                          <h4>Description</h4>
                          <p>
                            ${emission.factor_data.description || "---"}
                          </p>
                          <h4>Source</h4>
                          <p>
                            ${emission.factor_data.source || "---"}
                          </p>
                          <h4>Category</h4>
                          <p>
                            ${emission.factor_data.category || "---"}
                          </p>
                          <h4>Region</h4>
                          <p>
                            ${emission.factor_data.region}
                          </p>
                          <h4>Unit</h4>
                          <p>${emission.factor_data.unit}</p>
                          <h4>LCA activity</h4>
                          <p>
                            ${
                              (emission.factor_data as Factor).lca_activity ||
                              "---"
                            }
                          </p>
                          <h4>Consumption Amount</h4>
                          <p>
                            ${emission.consumption_amount}
                          </p>
                         ${
                           emission?.children?.length
                             ? emission?.children
                                 ?.map((item: any) => {
                                   return `<p>
                                ${item.group || ""}: ${item.consumption_amount}
                              </p>`;
                                 })
                                 .join("")
                             : ""
                         }
                          <h4>Total emission</h4>
                          <p>
                            ${emission.emission}
                          </p>
                          ${
                            emission?.children?.length
                              ? emission?.children
                                  ?.map((item: any) => {
                                    return `<p>
                                 ${item.group || ""}: ${convertedNumber(
                                      item.emission * conversionFactor
                                    )}
                               </p>`;
                                  })
                                  .join("")
                              : ""
                          }
                          ${
                            emission.scope === "Scope 3" &&
                            categories
                              .map((category) => {
                                if (category.value === emission.category) {
                                  return `<h4>Scope 3 Category</h4>
                                            <p>${category.text}</p>`;
                                }
                              })
                              .join("")
                          }
                        
                          <h4>Emission Factor</h4>
                          <h4>CO2e</h4>
                          <p>
                            ${emission.factor_data.factor || "---"}
                          </p>
                          <h4>
                            CO2e calculation method applied
                          </h4>
                          <p>
                            ${
                              emission.factor_data.factor_calculation_method ||
                              "---"
                            }
                          </p>
                          <h4>
                            CO2e calculation origin
                          </h4>
                          <p>
                            ${
                              (emission.factor_data as Factor)
                                .factor_calculation_origin || "---"
                            }
                          </p>`,
                    Style: {
                      Color: exportTheme.Theme.Colors.Color1,
                    },
                  },
                },
              ],
            })),
        },
      };
      return table;
    };
    const getOffsetsDetails = (offsets: any[]) => {
      const table: ARJS.Table = {
        Name: `Table_OffsetsDetails`,
        Type: "table",
        TableColumns: offsetsStructure.fields.map((f) => ({
          Width: `${getColumnWidth(f.length)}in`,
        })),
        Header: {
          TableRows: [
            {
              Height: "10pt",
              TableCells: [
                {
                  ColSpan: offsetsStructure.fields.length,
                  Item: {
                    Type: "textbox",
                    Name: `offsets_value_OffsetsInfo`,
                    Value: `Offsets details`,
                    CanGrow: true,
                    KeepTogether: true,
                    Style: {
                      VerticalAlign: "middle",
                      TextAlign: "center",
                      PaddingTop: "16pt",
                      PaddingBottom: "8pt",
                      FontSize: "14pt",
                      FontWeight: "SemiBold",
                      Color: exportTheme.Theme.Colors.Color1,
                    },
                  },
                },
              ],
            },
          ],
        },
        Footer: {
          TableRows: offsets.map((offset) => ({
            Height: "15pt",
            TableCells: [
              {
                Item: {
                  Type: "richtext",
                  Name: "OffsetsList",
                  CanGrow: true,
                  MarkupType: "HTML",
                  Value: `<h3>${offset.index}. </h3>`,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                  },
                },
              },
              {
                ColSpan: offsetsStructure.fields.length - 1,
                Item: {
                  Type: "richtext",
                  Name: "OffsetsList",
                  CanGrow: true,
                  MarkupType: "HTML",
                  Value: `<h3>${offset.name}</h3>
                  <h4>General Information</h4>
                    <h4>Description</h4>
                    <p>
                      ${offset.description}
                    </p>
                    <h4>Proof of offset</h4>
                    <p>
                      ${offset.proof && offset.proof}
                    </p>
                    <p>
                      ${
                        offset.proofName !== offset.proof
                          ? offset.proofName
                          : ""
                      }
                    </p>
                    <h4>Region</h4>
                    <p>
                      ${offset.region}
                    </p>
                    <h4>Cost</h4>
                    <p>${offset.cost}</p>
                    <h4>Offsetted amount (${getEmissionsUnit(
                      companyDetails.emissions_unit
                    )} CO2e)</h4>
                    <p>
                      ${offset.emission}
                    </p>`,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                  },
                },
              },
            ],
          })),
        },
      };
      return table;
    };
    const getEmissionsTable = (
      dataSet: string,
      emissions: any[],
      scopeTitle: string
    ) => {
      const table: ARJS.Table = {
        Name: `Table_EmissionsTable`,
        Type: "table",
        DataSetName: dataSet,
        Left: "0.5in",
        TableColumns: emissionsStructure.fields.map((f) => ({
          Width: `${getColumnWidth(f.length)}in`,
        })),
      };
      if (emissions.length) {
        table.PageBreak = "End";
        table.Header = {
          RepeatOnNewPage: true,
          TableRows: [
            {
              Height: "15pt",
              TableCells: [
                {
                  ColSpan: emissionsStructure.fields.length,
                  Item: {
                    Type: "textbox",
                    Name: "emissions_header_scopes",
                    Value: scopeTitle,
                    CanGrow: true,
                    KeepTogether: true,
                    Style: {
                      Color: exportTheme.Theme.Colors.Color1,
                      VerticalAlign: "middle",
                      FontWeight: "Bold",
                      FontSize: "16pt",
                      TextAlign: "center",
                      PaddingTop: "13pt",
                    },
                  },
                },
              ],
            },
            {
              Height: "15pt",
              TableCells: [
                {
                  ColSpan: emissionsStructure.fields.length,
                  Item: {
                    Type: "textbox",
                    Name: "emissions_header_scopesOverview",
                    Value: "Emissions overview",
                    CanGrow: true,
                    KeepTogether: true,
                    Style: {
                      Color: exportTheme.Theme.Colors.Color1,
                      VerticalAlign: "middle",
                      FontWeight: "SemiBold",
                      FontSize: "14pt",
                      TextAlign: "center",
                      PaddingTop: "6pt",
                      PaddingBottom: "10pt",
                    },
                  },
                },
              ],
            },
            {
              Height: "15pt",
              TableCells: emissionsStructure.fields.map((f) => ({
                Item: {
                  Type: "textbox",
                  Name: `emissions_header_${f.value}`,
                  Value: f.name,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    VerticalAlign: "middle",
                    FontWeight: "Bold",
                    FontSize: "8pt",
                    TextAlign: "left",
                    PaddingTop: "6pt",
                    PaddingLeft: "6pt",
                    PaddingRight: "6pt",
                    PaddingBottom: "6pt",
                    BackgroundColor: exportTheme.Theme.Colors.TableHeader,
                    BottomBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: "1pt",
                    },
                    TopBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: "1pt",
                    },
                    LeftBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: f.value === "index" ? "1pt" : "0pt",
                    },
                    RightBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: f.value === "emission" ? "1pt" : "0pt",
                    },
                  },
                },
              })),
            },
          ],
        };
        table.Details = {
          TableRows: [
            {
              Height: "15pt",
              TableCells: emissionsStructure.fields.map((f) => ({
                Item: {
                  Type: "textbox",
                  Name: `emissions_value_${f.value}`,
                  Value: `${getFieldValue(f.value)}`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    VerticalAlign: "middle",
                    TextAlign: "left",
                    PaddingTop: "6pt",
                    PaddingLeft: "6pt",
                    PaddingRight: "6pt",
                    PaddingBottom: "6pt",
                    FontSize: "8pt",
                    FontWeight: f.value === "name" ? "Medium" : "Normal",
                    Color: exportTheme.Theme.Colors.Color1,
                    BackgroundColor: exportTheme.Theme.Colors.TableRows,
                    BottomBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: "1pt",
                    },
                    TopBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: "1pt",
                    },
                    LeftBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: f.value === "index" ? "1pt" : "0pt",
                    },
                    RightBorder: {
                      Color: exportTheme.Theme.Colors.TableBorder,
                      Style: "solid",
                      Width: f.value === "emission" ? "1pt" : "0pt",
                    },
                  },
                },
              })),
            },
          ],
        };
        table.Footer = {
          TableRows: [
            {
              Height: "15pt",
              TableCells: [
                {
                  ColSpan: emissionsStructure.fields.length,
                  Item: getEmissionsDetails(emissions),
                },
              ],
            },
          ],
        };
      }
      return table;
    };
    const offsetsTable: ARJS.Table = {
      Name: `Table_Offsets`,
      Type: "table",
      DataSetName: "DataSetOffsets",
      Left: "0.5in",
      TableColumns: offsetsStructure.fields.map((f) => ({
        Width: `${getColumnWidth(f.length)}in`,
      })),
    };
    if (offsets().length) {
      offsetsTable.Header = {
        RepeatOnNewPage: true,
        TableRows: [
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: offsetsStructure.fields.length,
                Item: {
                  Type: "textbox",
                  Name: "offsets_header_offsets",
                  Value: "Offsets",
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    VerticalAlign: "middle",
                    FontWeight: "Bold",
                    FontSize: "16pt",
                    TextAlign: "center",
                    PaddingTop: "13pt",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: offsetsStructure.fields.length,
                Item: {
                  Type: "textbox",
                  Name: "offsets_header_offsetsOverview",
                  Value: "Offsets overview",
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    Color: exportTheme.Theme.Colors.Color1,
                    VerticalAlign: "middle",
                    FontWeight: "SemiBold",
                    FontSize: "14pt",
                    TextAlign: "center",
                    PaddingTop: "6pt",
                    PaddingBottom: "10pt",
                  },
                },
              },
            ],
          },
          {
            Height: "15pt",
            TableCells: offsetsStructure.fields.map((f) => ({
              Item: {
                Type: "textbox",
                Name: `offsets_header_${f.value}`,
                Value: f.name,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  Color: exportTheme.Theme.Colors.Color1,
                  VerticalAlign: "middle",
                  FontWeight: "Bold",
                  FontSize: "8pt",
                  TextAlign: "left",
                  PaddingTop: "6pt",
                  PaddingLeft: "6pt",
                  PaddingRight: "6pt",
                  PaddingBottom: "6pt",
                  BackgroundColor: exportTheme.Theme.Colors.TableHeader,
                  BottomBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: "1pt",
                  },
                  TopBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: "1pt",
                  },
                  LeftBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: f.value === "index" ? "1pt" : "0pt",
                  },
                  RightBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: f.value === "emission" ? "1pt" : "0pt",
                  },
                },
              },
            })),
          },
        ],
      };
      offsetsTable.Details = {
        TableRows: [
          {
            Height: "15pt",
            TableCells: offsetsStructure.fields.map((f) => ({
              Item: {
                Type: "textbox",
                Name: `offset_value_${f.value}`,
                Value: `${getFieldValue(f.value)}`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  VerticalAlign: "middle",
                  TextAlign: "left",
                  PaddingTop: "6pt",
                  PaddingLeft: "6pt",
                  PaddingRight: "6pt",
                  PaddingBottom: "6pt",
                  FontSize: "8pt",
                  FontWeight: f.value === "name" ? "Medium" : "Normal",
                  Color: exportTheme.Theme.Colors.Color1,
                  BackgroundColor: exportTheme.Theme.Colors.TableRows,
                  BottomBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: "1pt",
                  },
                  TopBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: "1pt",
                  },
                  LeftBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: f.value === "index" ? "1pt" : "0pt",
                  },
                  RightBorder: {
                    Color: exportTheme.Theme.Colors.TableBorder,
                    Style: "solid",
                    Width: f.value === "emission" ? "1pt" : "0pt",
                  },
                },
              },
            })),
          },
        ],
      };
      offsetsTable.Footer = {
        TableRows: [
          {
            Height: "15pt",
            TableCells: [
              {
                ColSpan: offsetsStructure.fields.length,
                Item: getOffsetsDetails(offsets()),
              },
            ],
          },
        ],
      };
    }
    const report: ARJS.Report = {
      Name: "report.rdlx",
      Width: "8.5in",
      DataSources: [
        {
          Name: "DataSourceCalculationTitle",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify([calculation]),
          },
        },
        {
          Name: "DataSourceTotalScopeOne",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString:
              "jsondata=" +
              JSON.stringify(
                totalScopesInfo.filter((v) => v.name === "Scope 1")
              ),
          },
        },
        {
          Name: "DataSourceTotalScopeTwo",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString:
              "jsondata=" +
              JSON.stringify(
                totalScopesInfo.filter((v) => v.name === "Scope 2")
              ),
          },
        },
        {
          Name: "DataSourceTotalScopeThree",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString:
              "jsondata=" +
              JSON.stringify(
                totalScopesInfo.filter((v) => v.name === "Scope 3")
              ),
          },
        },
        {
          Name: "DataSourceCalculation",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(calculationInfo),
          },
        },
        {
          Name: "DataSourceScopeOneEmissions",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(emissionsScopeOne()),
          },
        },
        {
          Name: "DataSourceScopeTwoEmissions",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(emissionsScopeTwo()),
          },
        },
        {
          Name: "DataSourceScopeThreeEmissions",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(emissionsScopeThree()),
          },
        },
        {
          Name: "DataSourceOffsets",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(offsets()),
          },
        },
      ],
      DataSets: [
        {
          Name: "DataSetCalculationTitle",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceCalculationTitle",
          },
          Fields: [
            {
              Name: "name",
              DataField: "name",
            },
            {
              Name: "description",
              DataField: "description",
            },
          ],
        },
        {
          Name: "DataSetTotalScopeOne",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceTotalScopeOne",
          },
          Fields: ["name", "value", "percentValue"].map((f) => ({
            Name: f,
            DataField: f,
          })),
        },
        {
          Name: "DataSetTotalScopeTwo",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceTotalScopeTwo",
          },
          Fields: ["name", "value", "percentValue"].map((f) => ({
            Name: f,
            DataField: f,
          })),
        },
        {
          Name: "DataSetTotalScopeThree",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceTotalScopeThree",
          },
          Fields: ["name", "value", "percentValue"].map((f) => ({
            Name: f,
            DataField: f,
          })),
        },
        {
          Name: "DataSetCalculation",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceCalculation",
          },
          Fields: calculationStructure.fields.map((f) => ({
            Name: f.value,
            DataField: f.value,
          })),
        },
        {
          Name: "DataSetScopeOneEmissions",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceScopeOneEmissions",
          },
          Fields: emissionsStructure.fields.map((f) => ({
            Name: f.value,
            DataField: f.value,
          })),
        },
        {
          Name: "DataSetScopeTwoEmissions",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceScopeTwoEmissions",
          },
          Fields: emissionsStructure.fields.map((f) => ({
            Name: f.value,
            DataField: f.value,
          })),
        },
        {
          Name: "DataSetScopeThreeEmissions",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceScopeThreeEmissions",
          },
          Fields: emissionsStructure.fields.map((f) => ({
            Name: f.value,
            DataField: f.value,
          })),
        },
        {
          Name: "DataSetOffsets",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceOffsets",
          },
          Fields: offsetsStructure.fields.map((f) => ({
            Name: f.value,
            DataField: f.value,
          })),
        },
      ],
      Page: {
        PageWidth: "8.5in",
        PageHeight: "11.7in",
      },
      Body: {
        Style: {
          BackgroundColor: exportTheme.Theme.Colors.Background1,
        },
        ReportItems: [
          calculationTitle,
          calculationTable,
          getEmissionsTable(
            "DataSetScopeOneEmissions",
            emissionsScopeOne(),
            "Scope 1"
          ),
          getEmissionsTable(
            "DataSetScopeTwoEmissions",
            emissionsScopeTwo(),
            "Scope 2"
          ),
          getEmissionsTable(
            "DataSetScopeThreeEmissions",
            emissionsScopeThree(),
            "Scope 3"
          ),
          offsetsTable,
        ],
      },
      PageHeader: {
        Height: "25pt",
        Style: {
          BackgroundColor: exportTheme.Theme.Colors.Background1,
        },
      },
      PageFooter: {
        Height: "0.6in",
        ReportItems: [
          {
            Type: "rectangle",
            ReportItems: [
              {
                Type: "image",
                Sizing: "FitProportional",
                Source: "External",
                Value: exportTheme.Theme.Images.find(
                  (v) => v.Name === "footerLogo"
                )?.ImageData,
                Name: "Image2",
                Top: "0in",
                Left: "6.5in",
                Width: "1.5in",
                Height: "0.6in",
              },
            ],
            Name: "Container1",
            Width: "8.3in",
            Height: "0.6in",
            KeepTogether: true,
          },
        ],
        Style: {
          BackgroundColor: exportTheme.Theme.Colors.Background1,
        },
      },
    };
    return report;
  };

  const loadReport = () => {
    viewerRef.current?.open("report", {
      ResourceLocator: {
        getResource: () => {
          return generateReport() as any;
        },
      },
    });
  };

  useEffect(() => {
    loadReport();
  }, [exportType, exportTheme]);

  const exportsSettings = {
    pdf: {
      title: calculation.name,
      author: userInfoStore.userName,
      filename: calculation.name,
      printing: "none",
      pdfVersion: "1.7",
      autoPrint: true,
    },
    xlsx: {
      title: calculation.name,
      author: userInfoStore.userName,
      filename: calculation.name,
      autoPrint: true,
      multiPage: true,
      embedImages: "external",
      outputType: "xlsx",
    },
    "tabular-data": {
      title: calculation.name,
      author: userInfoStore.userName,
      filename: calculation.name,
    },
  };

  const openViewer = (type: TExportType) => {
    getEmissionsData()
      .then(() => {
        setExportType(type);
        const newType = allExportTypes.filter((item) => item.value === type)[0];
        newType.available = true;

        setAllExportTypes([
          ...allExportTypes.filter((v) => v.value !== type),
          newType,
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ExportButtons = (
    <Menu>
      <Menu.Item key="pdf" onClick={() => openViewer("pdf")}>
        PDF
      </Menu.Item>
      <Menu.Item key="excel" onClick={() => openViewer("xlsx")}>
        Excel
      </Menu.Item>
      <Menu.Item key="csv" onClick={() => openViewer("tabular-data")}>
        CSV
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Dropdown
        overlay={ExportButtons}
        disabled={
          calculatorStore.loadingDrillDown ||
          calculatorStore.loadingCalculation ||
          loading
        }
      >
        <StyledButton type="primary">
          <span>Export as</span>
          <DownOutlined />
        </StyledButton>
      </Dropdown>
      {exportType && (
        <CommonModal
          centered
          title={"Export"}
          visible={true}
          onCancel={() => {
            setExportType(null);
            setAllExportTypes([
              { label: "PDF", value: "pdf", available: false },
              { label: "XLSX", value: "xlsx", available: false },
              { label: "CSV", value: "tabular-data", available: false },
            ]);
          }}
          footer={null}
          width={"70%"}
        >
          <FormWrapper>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Radio.Group
                  defaultValue="dark"
                  onChange={(e) => handleChangeMode(e.target.value)}
                >
                  <Radio.Button value="light" id="btn-theme-light">
                    Light mode
                  </Radio.Button>
                  <Radio.Button value="dark" id="btn-theme-dark">
                    Dark mode
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col span={24}>
                <div id="viewer-host">
                  <Viewer
                    ref={viewerRef}
                    exportsSettings={exportsSettings}
                    availableExports={allExportTypes
                      .filter((v) => v.available)
                      .map((exp) => exp.value)}
                  />
                </div>
              </Col>
            </Row>
          </FormWrapper>
        </CommonModal>
      )}
    </div>
  );
});
export default ExportCalculationButton;
