import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";

import {
  getSheetById,
  getDataSheets,
  convertFiltersList,
} from "../../services/dataSheet";
import {
  updateKpiMetric,
  insertKpiMetric,
  getKpiMetricById,
} from "../../services/kpi";
import { Space, Form, Row, Col, message, Steps, Progress, Spin } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";

import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import { encodeUrlName } from "../../shared/commonFunctions";

const { Step } = Steps;
const CreateKpi = () => {
  const {
    sheet_id: urlSheetId,
    sheet_name: urlSheetName,
    kpi_id: kpiId,
  } = useParams();
  const [analyticData, setAnalyticData] = useState(null);
  const [hasMissingFields, setHasMissingFields] = useState(false);
  const [step, setStep] = useState(1);
  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [loadingCreateKpi, setLoadingCreateKpi] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    sheetId: null,
    autoRefresh: false,
    aggregate: [
      {
        column: null,
        op: null,
      },
    ],
  });
  const [dataSheet, setDataSheet] = useState(null);
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const loadData = async () => {
    setLoadDataSheets(true);
    const payload = {
      filter: { archive: false, is_reference_data: false },
    };
    const dataSheetsList = await getDataSheets(payload);

    setDataSheetsList(dataSheetsList);

    setLoadDataSheets(false);
  };

  useEffect(() => {
    if (urlSheetId) {
      const initData = { ...initialValues, sheetId: urlSheetId };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    }
    loadData();
  }, [urlSheetId]);

  const getKpiData = async () => {
    setLoadDataSheets(true);

    const kpiData = await getKpiMetricById(kpiId);
    if (kpiData) {
      const initData = { ...initialValues, ...kpiData };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    } else {
      navigate("/data/kpi");
    }
  };

  useEffect(() => {
    if (kpiId) {
      getKpiData();
    }
  }, [kpiId]);

  const handleOnChange = (value) => {
    const initData = {
      ...initialValues,
      sheetId: value,
      aggregate: [{ column: null, op: null }],
      filters: [],
      conditional_filters: [],
    };
    form.setFieldsValue(initData);
    setInitialValues(initData);
  };

  const onChangeFilters = (filters) => {
    const initData = {
      ...initialValues,
      conditional_filters: filters,
    };
    form.setFieldsValue(initData);
    setInitialValues(initData);
  };

  const getSheetData = async () => {
    try {
      setLoadingDataSheet(true);
      const datasheet = await getSheetById(initialValues.sheetId);
      setDataSheet(datasheet);
      const conditionalFilters =
        initialValues?.filters?.length &&
        convertFiltersList(initialValues?.filters, datasheet);
      const initData = {
        ...initialValues,
        ...(initialValues?.filters?.length && {
          conditional_filters: conditionalFilters,
        }),
      };
      delete initData.filters;
      form.setFieldsValue(initData);
      setInitialValues(initData);
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };

  useEffect(() => {
    if (initialValues.sheetId) {
      getSheetData();
    }
  }, [initialValues.sheetId]);

  const onChangeRefresh = (event) => {
    const initData = { ...initialValues, autoRefresh: event.target.checked };
    form.setFieldsValue(initData);
    setInitialValues(initData);
  };

  const onChangeFieldsData = (data) => {
    let newInitialValues = {};
    const formValues = form.getFieldsValue(true);
    Object.keys(formValues)?.forEach((key, index) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = formValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem =
              data?.[0]?.name[2] === "column"
                ? {
                    ...item,
                    [data?.[0]?.name[2]]: data?.[0]?.value,
                    column_value:
                      item.column === "owner_users" ||
                      item.column === "owner_groups"
                        ? []
                        : "",
                  }
                : { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };

            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          newInitialValues = { ...newInitialValues, [key]: formValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      await form.validateFields();
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onClickCreateKpi = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateKpi(true);
          const newInitialValues = {
            ...initialValues,
            analyticResult: analyticData,
            aggregateColumn: initialValues?.aggregate[0]?.column,
            aggregateOp: initialValues?.aggregate[0]?.op,
            ...(hasMissingFields && { hasMissingFields }),
          };
          setInitialValues(newInitialValues);
          let response = null;
          if (kpiId) {
            response = await updateKpiMetric(newInitialValues);
            if (response) {
              setLoadingCreateKpi(false);
              form.resetFields();
              navigate(
                `/data/kpi/${initialValues?.sheetId}/${encodeUrlName(
                  urlSheetId ? urlSheetName : dataSheet.sheet_name
                )}/details`
              );
            }
          } else {
            response = await insertKpiMetric(newInitialValues);
            if (response["$oid"]) {
              setLoadingCreateKpi(false);
              form.resetFields();
              navigate(
                `/data/kpi/${initialValues?.sheetId}/${encodeUrlName(
                  urlSheetId ? urlSheetName : dataSheet.sheet_name
                )}/details`
              );
            }
          }
        } catch (error) {
          console.log("Something went wrong while creating data sheet!", error);
          message.error("Something went wrong while creating data sheet!");
          setLoadingCreateKpi(false);
        }
      })
      .catch((error) => console.log("error form in data sheet", error));
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return (
        <Step1
          urlSheetId={urlSheetId}
          onClickNext={onClickNext}
          dataSheetsList={dataSheetsList}
          loadDataSheets={loadDataSheets}
          initialValues={initialValues}
          dataSheet={dataSheet}
          loadingDataSheet={loadingDataSheet}
          handleOnChange={handleOnChange}
          onChangeFilters={onChangeFilters}
          step={step}
        />
      );
    } else if (step === 2) {
      return (
        <Step2
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          initialValues={initialValues}
          dataSheet={dataSheet}
          resetInput={resetInput}
          loadingDataSheet={loadingDataSheet}
          setAnalyticData={setAnalyticData}
          setHasMissingFields={setHasMissingFields}
          onChangeRefresh={onChangeRefresh}
        />
      );
    } else if (step === 3) {
      return (
        <Step3
          onClickCreateKpi={onClickCreateKpi}
          loadingCreateKpi={loadingCreateKpi}
        />
      );
    } else {
      console.log("completed");
    }
  };

  const resetInput = (key) => {
    const formValues = form.getFieldsValue(true);
    const aggregationValues = formValues.aggregate.map((item, index) => {
      if (index === key) {
        return { ...item, op: null };
      } else {
        return item;
      }
    });

    form.setFieldsValue({
      ...formValues,
      aggregate: aggregationValues,
    });
    setInitialValues({
      ...formValues,
      aggregate: aggregationValues,
    });
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                onClick={() =>
                  navigate(
                    urlSheetId
                      ? `/data/kpi/${urlSheetId}/${encodeUrlName(
                          urlSheetName
                        )}/details`
                      : `/data/kpi`
                  )
                }
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                <>
                  <Step
                    className="stepper-wrapper "
                    key={1}
                    title={<span>Data filtering</span>}
                  />

                  <Step
                    className="stepper-wrapper"
                    key={2}
                    title={<span>Analytics Aggregation</span>}
                  />
                  <Step
                    className="stepper-wrapper"
                    key={3}
                    title={<span>KPI Creation</span>}
                  />
                </>
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 33.33}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default CreateKpi;
