import React, { useEffect, useState } from "react";
import { useBoolean } from "ahooks";
import { Select, Form, Checkbox } from "antd";
import { AdvancedDataFilters } from "../Components/AdvancedDataFilters";
import { FormWrapper } from "../../../../shared/commonStyles";
import { carbonStore } from "../../../Carbon";
import { observer } from "mobx-react-lite";
import styles from "./AutomatedEmissionsCalculation.module.scss";

interface DataSheet {
  value: string;
  label: string;
  schemas: SheetSchema[];
}

interface AutomatedEmissionsCalculationProps {
  autoEmissionData: AutomatedEmission;

  onChangeActivitySheet: (value: string) => void;
  onChangeValueActivityField: (value: string) => void;
  onChangeFilters: (data: AdvancedDataFilter[]) => void;
  onChangeAutomatic: (isChecked: boolean) => void;
}

export const AutomatedEmissionsCalculation = observer(
  ({
    autoEmissionData,
    onChangeActivitySheet: onChangeActivitySheets,

    onChangeValueActivityField: onChangeValueActivityFields,
    onChangeFilters,
    onChangeAutomatic,
  }: AutomatedEmissionsCalculationProps) => {
    const [form] = Form.useForm();
    const [isActivityFieldsVisible, setIsActivityFieldsVisible] = useState(
      !!autoEmissionData.activity_sheet
    );

    const [isAutomaticChecked, { toggle }] = useBoolean(
      autoEmissionData.automatic
    );

    useEffect(() => {
      carbonStore.fetchSheets();
      const option = getActivitySheets().find(
        (v) => v.value === autoEmissionData.activity_sheet
      );
      if (autoEmissionData.activity_sheet && option)
        onChangeActivityDataSheet(autoEmissionData.activity_sheet, option);
    }, []);

    useEffect(() => {
      onChangeAutomatic(isAutomaticChecked);
    }, [isAutomaticChecked]);

    const activitySheetValue = carbonStore.sheets.find(
      (sheet) => sheet._id.$oid === autoEmissionData.activity_sheet
    )?.sheet_name;

    const getActivityFileds = () => {
      const activitySheet = carbonStore.sheets.find(
        (sheet) => sheet._id.$oid === autoEmissionData.activity_sheet
      );
      return activitySheet
        ? activitySheet?.sheet_schema
            ?.filter((v) => v.data_type === "number")
            ?.map((v) => ({
              value: v.entity_name,
              label: v.display_name,
              sheet_id: activitySheet._id.$oid,
              sheet_name: activitySheet.sheet_name,
              data_type: v.data_type,
            }))
        : [];
    };

    const getActivitySheets = (): DataSheet[] => {
      return carbonStore.sheets.map((sheet) => ({
        value: sheet._id.$oid,
        label: sheet.sheet_name,
        schemas: sheet.sheet_schema,
      }));
    };

    const onChangeActivityDataSheet = (
      value: string,
      option: DataSheet | DataSheet[]
    ) => {
      if (autoEmissionData.activity_sheet !== value)
        onChangeValueActivityFields("");

      setIsActivityFieldsVisible(true);
      onChangeActivitySheets(value);
    };

    const selectedDataSheet = carbonStore.sheets.find(
      (sheet) => sheet._id.$oid === autoEmissionData.activity_sheet
    );

    return (
      <FormWrapper>
        <Form
          layout="vertical"
          form={form}
          className={styles.addAutoEmissionsForm}
        >
          <Form.Item
            label={
              <span className={styles.selectorLabel}>Activity datasheet</span>
            }
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={getActivitySheets()}
              onChange={onChangeActivityDataSheet}
              value={activitySheetValue}
              className={styles.selectorCalc}
              placeholder={
                <span className={styles.selectorPlaceholder}>
                  Select activity datasheet
                </span>
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={styles.selectorLabel}>Aggregation Column</span>
            }
            className={
              isActivityFieldsVisible
                ? styles.containerVisible
                : styles.containerHidden
            }
          >
            <Select
              options={getActivityFileds()}
              className={styles.selectorCalc}
              value={autoEmissionData.activity_field}
              onChange={(value: string) => onChangeValueActivityFields(value)}
              placeholder={
                <span className={styles.selectorPlaceholder}>
                  Select aggregation column
                </span>
              }
            />
          </Form.Item>
        </Form>
        <AdvancedDataFilters
          selectedActivityFields={autoEmissionData.activity_field}
          activityFieldsVisible={isActivityFieldsVisible}
          filters={autoEmissionData.filters}
          onConfirm={onChangeFilters}
          selectedDataSheet={selectedDataSheet}
        />
        <Checkbox
          checked={isAutomaticChecked}
          className={
            !autoEmissionData.activity_field?.length && styles.containerHidden
          }
          onChange={toggle}
        >
          Automatically run this calculation when data is refreshed
        </Checkbox>
      </FormWrapper>
    );
  }
);
