import {
  CommonTable,
  StyledButton,
  StyledTag,
} from "../../../shared/commonStyles";
import { Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  getDistinctCategories,
  getDistinctIndustries,
  getDistinctThemes,
  getUniqueFrameworks,
} from "../../../../src/shared/commonFunctions";

import { SearchOutlined } from "@ant-design/icons";
import StandardFormViewModal from "../../../components/Modals/StandardFormViewModal/StandardFormViewModal";
import styled from "styled-components";

const SearchInput = styled(Input)`
  height: 40px;
  background-color: #625d75;
  border-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
    border-right-width: 2px;
    z-index: 1;
  }

  .ant-input {
    background-color: transparent;
    margin-left: 5px;
    font-size: 16px;
    color: #b1afbc;
  }

  &.customInput {
    background-color: #625d75;
    font-size: 16px;
    border-color: transparent;

    &::placeholder {
      background-color: #625d75;
      font-size: 16px;
      border-color: transparent;
    }
  }
`;

const searchInputContainerStyle = {
  flex: 1,
  alignSelf: "center",
};

const searchBox = {
  display: "flex",
  flex: 1,
  marginRight: "35px",
};

const StandardsTable = ({
  dataLakeMapsList,
  rowSelection,
  loading,
  listAllSurveys,
}) => {
  const [industryFilter, setIndustryFilter] = useState([]);
  const [standardFilter, setStandardFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [themeFilter, setThemeFilter] = useState([]);
  const [rowDataView, setRowDataView] = useState(null);
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [, setCodeFilter] = useState([]);
  const [rowData, setRowData] = useState(dataLakeMapsList);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  useEffect(() => {
    if (dataLakeMapsList) {
      const categoryFilter = getDistinctCategories(dataLakeMapsList);

      setCategoryFilter(categoryFilter);

      const filterStandard = getUniqueFrameworks(dataLakeMapsList);

      setStandardFilter(filterStandard);

      const filterIndustry = getDistinctIndustries(dataLakeMapsList);

      setIndustryFilter(filterIndustry);

      const filterCode = dataLakeMapsList.reduce((arr, val) => {
        const { specific_standard: specificStandard } = val;
        if (!arr.includes(specificStandard)) {
          arr.push(specificStandard);
        }
        return arr;
      }, []);

      setCodeFilter(filterCode);

      const themeFilter = getDistinctThemes(dataLakeMapsList);

      setThemeFilter(themeFilter);
    }
  }, [dataLakeMapsList]);

  const onClickViewForm = (record) => {
    setRowDataView(record);
    setShowFormViewModal(true);
  };

  const usedSurveysInfo = (record) => {
    const standardId = record?.$oid;
    const survey_name_map = [];
    if (listAllSurveys && listAllSurveys.length) {
      listAllSurveys.map((item) => {
        const items = JSON.parse(item?.survey_order);
        const selectedItem = items?.filter((item) => {
          return item?.id === standardId && item?.survey_type === "standards";
        });

        if (selectedItem?.length > 0) {
          survey_name_map.push({ name: item.name });
        }
      });
    }

    if (survey_name_map && survey_name_map.length === 0) {
      return <p key={1}>----</p>;
    } else if (survey_name_map && survey_name_map.length === 1) {
      return <p key={1}>{survey_name_map[0].name}</p>;
    } else {
      return (
        <Tooltip
          placement="bottom"
          overlayInnerStyle={{ width: "300px" }}
          title={
            <ul>
              {survey_name_map.map((item, index) => {
                return (
                  <li key={index + 1} style={{ padding: "3px 0px" }}>
                    {item.name}
                  </li>
                );
              })}
            </ul>
          }
        >
          <p>
            {survey_name_map.length}
            <span style={{ color: "#B1AFBC", marginLeft: "8px" }}>
              (hover to view list)
            </span>
          </p>
        </Tooltip>
      );
    }
  };

  const dataLakeMapTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
      key: "description",
      sorter: (a, b) => {
        const A = (a && a.description) || "";
        const B = (b && b.description) || "";
        if (A && B) {
          return B.localeCompare(A);
        }
      },
      sortDirections: ["descend", "ascend"],
      sortOrder:
        tableFilters.sortedInfo?.field === "description" &&
        tableFilters.sortedInfo?.order,
      render: (_, data) => {
        if (data) {
          return <p> {data.description}</p>;
        }
      },
    },
    {
      title: "Standard",
      dataIndex: "frameworks",
      width: "15%",
      render: (record, data) =>
        record?.map((a, i) => (
          <StyledTag className="margin-2" key={data._id.$oid + i}>
            {a}
          </StyledTag>
        )),
      sortDirections: ["descend", "ascend"],
      sortOrder:
        tableFilters.sortedInfo?.field === "frameworks" &&
        tableFilters.sortedInfo?.order,
    },
    {
      filteredValue:
        tableFilters.filteredInfo && tableFilters.filteredInfo?.standard_code
          ? tableFilters.filteredInfo.standard_code
          : null,
      title: "Standard code",
      dataIndex: "standard_code",
      width: "20%",
      sorter: (a, b) => {
        const A = (a && a.standard_code) || "";
        const B = (b && b.standard_code) || "";
        if (A && B) {
          return B.localeCompare(A);
        }
      },
      sortDirections: ["descend", "ascend"],
      sortOrder:
        tableFilters.sortedInfo?.field === "standard_code" &&
        tableFilters.sortedInfo?.order,
      onFilter: (value, record) =>
        record?.standard_code && record?.standard_code.indexOf(value) === 0,
      render: (_, data) => {
        if (data) {
          return <p> {data.standard_code}</p>;
        }
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "10%",
      render: (_, record) => (
        <StyledButton
          type="custom"
          style={{ color: "#38304F" }}
          onClick={() => onClickViewForm(record)}
        >
          Open preview
        </StyledButton>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValueChange = (value) => {
    if (value === "") {
      setRowData(dataLakeMapsList);
    }
    setSearchValue(value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    if (searchValue && searchValue !== "") {
      const filteredData = searchStandard(dataLakeMapsList, searchValue);
      setRowData(filteredData);
    }
  };

  function searchStandard(data, searchTerm) {
    searchTerm = searchTerm.toLowerCase();

    return data.filter((obj) => {
      const nameMatch = obj.name
        ? obj.name.toLowerCase().includes(searchTerm)
        : false;
      const descriptionMatch = obj.description
        ? obj.description.toLowerCase().includes(searchTerm)
        : false;
      const frameworksMatch = obj.frameworks
        ? obj.frameworks.includes(searchTerm)
        : false;

      const standardCodeMatch = obj.standard_code
        ? obj.standard_code.toLowerCase().includes(searchTerm)
        : false;

      if (
        nameMatch ||
        descriptionMatch ||
        standardCodeMatch ||
        frameworksMatch
      ) {
        return true;
      }

      return false;
    });
  }

  return (
    <>
      {showFormViewModal && (
        <StandardFormViewModal
          visible={showFormViewModal}
          onClose={() => setShowFormViewModal(false)}
          rowData={rowDataView}
          x
          StandardFormViewModal={true}
        />
      )}

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div style={searchInputContainerStyle}>
            <SearchInput
              className="customInput"
              placeholder="Search for sustainability standards"
              value={searchValue}
              prefix={<SearchOutlined style={{ color: "white" }} />}
              onKeyUp={handleKeyUp}
              onChange={(e) => handleSearchValueChange(e.target.value)}
            />
          </div>
          <StyledButton type="primary" onClick={handleSearchClick}>
            Search
          </StyledButton>
        </div>
        <CommonTable
          loading={loading}
          rowKey={(data) => {
            return data?.["_id"]?.["$oid"];
          }}
          dataSource={rowData}
          columns={dataLakeMapTableColumns}
          rowClassName="tableRow"
          rowSelection={rowSelection}
          scroll={{ x: true }}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default StandardsTable;
