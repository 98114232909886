import {
  DeleteOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { listAuditManagements } from "../../../../../services/audits";
import { getSurveysList } from "../../../../../services/surveys";
import { Col, Form, Row, Select, Typography, Spin, message } from "antd";
const { Title } = Typography;
const Step4 = ({ onClickPrevious, onSubmitForm,initialValues,setInitialValues, form, submitLoader}) => {
  const [loader, setLoader] = useState(false);
 
  const [audits, setAudits] = useState([]);
  const [surveysList, setSurveysList] = useState([]);
  const loadData = async () => {

    try{
      setLoader(true)      
      
      const audits = await listAuditManagements();
      if (audits) {
        setAudits(audits);
      }

      const surveyResponse = await getSurveysList();
      if (surveyResponse?.items) {
        setSurveysList(surveyResponse.items);
      }
    }catch(e){
      message.error(e)
    }finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    loadData();
  }, []);
   
  const handleRelatedItem = (index) => {
    const formValues = form.getFieldsValue(true);
    const relatedItems = formValues?.relatedItems?.map((item,i) => {
      if(i === index){
        item.value = null
      }
      return {...item}
    })    
    setInitialValues({...formValues,['relatedItems']:relatedItems});
  } 
  return (
    <FormWrap style={{width:"50%"}}>
      <Spin spinning={loader}>
        <FormWrapper>
          <Title level={5}>Attach related items</Title>
          <Form.Item label="">
            <Form.List 
              name="relatedItems" 
              key={"relatedItems"}              
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={[16, 16]}>
                      <Col span={10}>
                        <Form.Item name={[name, "type"]}>
                          <Select
                            placeholder="Select Field"
                            onChange={(value) =>
                              handleRelatedItem(name)
                            }
                          >
                            <Select.Option value="audits">
                              Audit
                            </Select.Option>                             
                            <Select.Option value="surveys">
                              Surveys
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name={[name, "value"]}>
                          <Select placeholder="Select Value">
                            {initialValues?.relatedItems[name]?.type ===
                              "audits" &&
                              audits?.map((item, i) => {
                                return (
                                  <Select.Option
                                    key={i}
                                    value={item?.id}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              })
                            }                              
                            {initialValues?.relatedItems[name]?.type ===
                              "surveys" &&
                              surveysList?.map((item, i) => {
                                return (
                                  <Select.Option key={i} value={item?.id}>
                                    {item.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <StyledButton
                          type="default"
                          style={{ padding: "8px", marginTop: "0px" }}
                          onClick={() => remove(name)}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  ))}
                  <StyledButton type="custom" onClick={() => add()}>
                    <PlusCircleOutlined /> Attach Related Items
                  </StyledButton>
                </>
              )}
            </Form.List>
          </Form.Item>
          <StyledButton onClick={() => onClickPrevious()} type="default" ghost>
            <span>Back</span>
          </StyledButton>
          <StyledButton type="custom" loading={submitLoader} onClick={onSubmitForm}>
            <span>Finish</span> <RightOutlined />
          </StyledButton>
        </FormWrapper>
      </Spin>
    </FormWrap>
  );
};

export default Step4;
