import { Row } from "antd";
import React from "react";
import { StyledButton } from "../../../shared/commonStyles";

interface FooterButtonsProps {
  step: number;
  onClickNext: () => void;
  onClickBack: () => void;
  disable: boolean;
}

export const FooterButtons = ({
  step,
  onClickNext,
  onClickBack,
  disable,
}: FooterButtonsProps) => {
  return (
    <>
      <Row justify="start" style={{ marginTop: "20px" }}>
        <StyledButton
          className="margin-5"
          type="custom"
          onClick={() => onClickBack()}
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#fff"
        >
          <span>Back</span>
        </StyledButton>
        <StyledButton
          className="margin-5"
          disabled={disable}
          onClick={() => onClickNext()}
          type="custom"
        >
          {(step === 1 && "Create Data Sheet") ||
            (step === 0 && "Continue to Step 2")}
        </StyledButton>
      </Row>
    </>
  );
};
