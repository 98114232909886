import React, { useState, useEffect } from "react";
import { Switch, Form, message, Input } from "antd";
import { Auth } from "aws-amplify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
import { customUploadAdapterPlugin } from "../LearningPageFuctions";
import {
  CommonModal,
  StyledButton,
  FormWrapper,
} from "../../../shared/commonStyles";
import {updateContent} from "../../../services/clientEductaion";

const ContentUpdateModal = ({
  visible,
  onClose,
  rowData,
  RefreshEducationData,
  setRowData,
}) => {
  const [loaderUpdateData, setLoaderUpdateData] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        category: rowData.category ? rowData.category : "",
        content_type: rowData.content_type ? rowData.content_type : "",
        name: rowData.name ? rowData.name : "",
        description: rowData.description ? rowData.description : "",
        content_url: rowData.content_url ? rowData.content_url : "",
        status: rowData?.status
          ? rowData.status === "ACTIVE"
            ? true
            : false
          : false,
      });
    }
  }, [rowData, form]);

  const onSubmitForm = () => {
    form
      .validateFields()
      .then(async (val) => {
        try {
          setLoaderUpdateData(true);
          const {
            category,
            content_type,
            name,
            description,
            content_url,
            status,
          } = val;
          const statusText = status ? "ACTIVE" : "INACTIVE";
          const data = await Auth.currentSession();
          const group_id = data["accessToken"]["payload"][
            "cognito:groups"
          ].filter((element) => element.includes("org:"))[0];
          const username = data["accessToken"]["payload"]["username"];
          const payload = {
            _id: rowData._id,
            category,
            content_type,
            name,
            description,
            content_url,
            username,
            group_id,
            status: statusText,
          };

          const record = await updateContent(payload);

          if (record) {
            message.success("Successfully updated content!");
            setRowData(payload);
            form.resetFields();
            RefreshEducationData();
            onClose();
          }
        } catch (error) {
          console.log("error while updating content", error);
          message.error("error while updating content!");
        } finally {
          setLoaderUpdateData(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong updating content.");
        console.log("Something went wrong updating content.", e);
      });
  };

  const status = rowData?.status
    ? rowData.status === "ACTIVE"
      ? true
      : false
    : false;
  const ckData = rowData?.description ? rowData.description : "";

  return (
    <CommonModal
      title={"Update Content"}
      visible={visible}
      onCancel={onClose}
      width={1000}
      footer={
        <>
          <StyledButton
            type="custom"
            loading={loaderUpdateData}
            onClick={() => onSubmitForm()}
          >
            Update
          </StyledButton>
          <StyledButton type="default" onClick={() => onClose()}>
            Close
          </StyledButton>
        </>
      }
      maskClosable={false}
      destroyOnClose
    >
      <FormWrapper>
        <Form form={form} initialValues={{ content_type: "Video" }}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="status"
            label="Active"
            valuePropName="checked"
            initialValue={status}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="category"
            label="Category"
            rules={[{ required: true, message: "This is a required field!" }]}
          >
            <Input placeholder={""} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="name"
            label="Name"
            rules={[{ required: true, message: "This is a required field!" }]}
          >
            <Input placeholder={""} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="content_type"
            label="Content Type"
            rules={[{ required: true, message: "This is a required field!" }]}
            hidden={true}
          >
            <Input placeholder={""} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="content_url"
            label="Content Url"
            rules={[
              {
                required: true,
                message: "Please enter content url!",
                type: "url",
              },
            ]}
          >
            <Input placeholder={""} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="description"
            label="Description"
            rules={[{ required: true, message: "This is a required field!" }]}
          >
            <CKEditor
              editor={DecoupledDocumentEditor}
              config={{
                extraPlugins: [customUploadAdapterPlugin],
              }}
              data={ckData}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                if (editor?.ui) {
                  editor.ui.view.editable.element.parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.view.editable.element
                  );
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldsValue({
                  description: data,
                });
              }}
            />
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default ContentUpdateModal;
