import { Button, Col, Layout, Pagination, Row, Spin } from "antd";
import {
  FormWrapper,
  PageWrap,
  StyledButton,
} from "../../../../shared/commonStyles";
import { getTimeAgo, paginateDataLocally } from "../../../../shared/utils";

import { NotificationType } from "../Container/NotificationsPage";
import React from "react";
import { Typography } from "antd";
import styles from "./NotificationsPage.module.scss";

const { Header, Footer, Content } = Layout;
const { Text } = Typography;

const layoutStyle: React.CSSProperties = {
  backgroundColor: "#2C263F",
  marginTop: "10px",
  marginBottom: "10px",
  marginLeft: "40px",
  marginRight: "40px",
  border: "1px solid #3A3C5A",
  borderRadius: "2px",
};

const headerStyle: React.CSSProperties = {
  backgroundColor: "#2C263F",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const footerStyle: React.CSSProperties = {
  backgroundColor: "#2C263F",
};

const contentStyle: React.CSSProperties = {
  backgroundColor: "#2C263F",
  display: "flex",
};

const notificationsTitleStyle: React.CSSProperties = {
  fontSize: "24px",
  lineHeight: "30px",
  color: "#FFFFFF",
  fontWeight: 400,
  textAlign: "center",
};

const unreadNotificationsTextStyle: React.CSSProperties = {
  marginLeft: "10px",
  fontSize: "16px",
  color: "#B1AFBC",
  fontWeight: 400,
  textAlign: "center",
};

const innerContentStyle: React.CSSProperties = {
  flex: 1,
  flexDirection: "column",
  marginLeft: "22%",
  marginRight: "22%",
  marginTop: "15px",
};

const loaderErrorOuterLayout: React.CSSProperties = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
};

export type NotificationsPageProps = {
  isLoading: boolean;
  hasError: boolean;
  notificationCount: number;
  page: number;
  pageSize: number;
  notificationData?: NotificationType[];
  onClickOpenDataSheet(item: NotificationType): void;
  onPageSizeChange(page: number, pageSize: number): void;
  onClickMarkAllRead(): void;
};

const NotificationsPage: React.FC<NotificationsPageProps> = ({
  isLoading,
  hasError,
  notificationCount,
  notificationData,
  page,
  pageSize,
  onClickOpenDataSheet,
  onPageSizeChange,
  onClickMarkAllRead,
}) => {
  // TODO: list is sorted in chronological order already
  // TODO: later implement server side pagination
  const unreadNotificationsCount = notificationData?.filter((notification) => !notification.isRead).length ?? 0;
  const sortedNotificationsList = notificationData?.slice().sort((a, b) => {
    return a.isRead === b.isRead ? 0 : a.isRead ? 1 : -1;
  });

  const paginatedResult = paginateDataLocally(
    page,
    pageSize,
    notificationCount,
    sortedNotificationsList ?? []
  );

  return (
    <PageWrap>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <Text style={notificationsTitleStyle}>Notifications</Text>
          <Text
            style={unreadNotificationsTextStyle}
          >{`(${unreadNotificationsCount} unread)`}</Text>
        </Header>
        <Content style={contentStyle}>
          {!isLoading && hasError && (
            <div style={loaderErrorOuterLayout}>
              <Text>Unable to fetch notifications !!</Text>
            </div>
          )}
          {isLoading && !hasError && (
            <div style={loaderErrorOuterLayout}>
              <Spin />
            </div>
          )}
          {!isLoading && !hasError && (
            <>
              <div style={innerContentStyle}>
                <div className={styles.subtitle}>
                  <div className={styles.subtitleLatest}>Latest</div>
                  <Button
                    type="link"
                    onClick={onClickMarkAllRead}
                    className={styles.subtitleMarkRead}
                  >
                    Mark all as read
                  </Button>
                </div>
                {paginatedResult.map((item: NotificationType) => (
                  <div
                    key={item._id.$oid}
                    className={`${styles.notificationBlock} ${!item.isRead && styles.notificationBlockUnread
                      }`}
                  >
                    <div className={styles.notificationBlockWrapper}>
                      <div style={{ display: "flex" }}>
                        {!item.isRead && <div className={styles.statusBadge} />}
                        <div className={styles.notificationBlockMessage}>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.message }}
                          ></div>
                          <p className={styles.notificationBlockTime}>
                            {getTimeAgo(item)}
                          </p>
                        </div>
                      </div>
                      <StyledButton
                        type="primary"
                        onClick={() => {
                          onClickOpenDataSheet(item);
                        }}
                      >
                        {(item.notificationType == "IMPORT_PENDING" &&
                          "Open data import") ||
                          (item.notificationType == "SURVEY_PENDING" &&
                            "Open data sheet") ||
                          (item.notificationType == "RECORD_DENIED" &&
                            "Open survey")}
                      </StyledButton>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </Content>
        <Footer style={footerStyle}>
          <FormWrapper>
            <Row>
              <Col
                span={12}
                offset={6}
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                {notificationCount > 0 && <Pagination
                  showSizeChanger={true}
                  onChange={onPageSizeChange}
                  defaultCurrent={1}
                  pageSize={pageSize}
                  pageSizeOptions={[5, 10]}
                  total={notificationCount}
                />}
              </Col>
            </Row>
          </FormWrapper>
        </Footer>
      </Layout>
    </PageWrap>
  );
};

export default NotificationsPage;
