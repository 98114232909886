import React from 'react';
import StandardFormViewModal from "../../../components/Modals/StandardFormViewModal/StandardFormViewModal";

const ShowStandardFormViewModal = ({
  rowDataView,
  showFormViewModal,
  setShowFormViewModal,
  sortedDataLakeList,
  setRowDataView,
}) => {
  return (
    <>
      {showFormViewModal && (
        <StandardFormViewModal
          visible
          onClose={() => setShowFormViewModal(false)}
          rowData={rowDataView}
          StandardFormViewModal={true}
          isShowSteps={true}
          sortedDataLakeList={sortedDataLakeList}
          setRowDataView={setRowDataView}
        />
      )}
    </>
  );
};

export default ShowStandardFormViewModal;