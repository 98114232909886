import React from "react";
import {  
  FormWrapper,  
  StyledButton,  
  FormWrap,
} from "../../../shared/commonStyles";

import {  
  Form,
  Input,
  Row,
  Col,
  Typography,
  Divider,
  Space,
} from "antd";

import {  
  RightOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const Step1 = ({onClickNext}) => {
    return (
      <FormWrap>
        <FormWrapper>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <Title className="color-white" level={3}>
                  General information
                </Title>
                <Title level={4}>Name new data collection</Title>
                <Divider
                  style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                />

                <Form.Item
                  labelCol={{ span: 24 }}
                  label={<span className="color-white">Name</span>}
                  name="name"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[a-zA-Z0-9 ()]*$"),
                      message: "Please input standards name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Type in a name for the data collection"
                    className="inner-color-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <Title level={4}>Add description</Title>
                <Divider
                  style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                />
                <Form.Item
                  labelCol={{ span: 24 }}
                  className="color-white"
                  name="description"
                  label={<span className="color-white">Description</span>}
                  rules={[
                    {
                      required: false,
                      message: "Please input standards description!",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Provide a short description of what kind of data will be collected "
                    className="inner-color-input"
                  />
                </Form.Item>

                <Form.Item>
                  <Row justify="flex-start">
                    <StyledButton
                      className="margin-5"
                      onClick={() => onClickNext()}
                      type="custom"
                    >
                      <span>Continue to step 2</span> <RightOutlined />
                    </StyledButton>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </FormWrapper>
      </FormWrap>
    );
}

export default Step1;