import React, { useEffect, useState } from "react";
import { Button, Col, Input, InputNumber, Row, Select } from "antd";
import { unitsManagementStore } from "../UnitsManagementStore";
import { observer } from "mobx-react-lite";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import styles from "./AddUnitModal.module.scss";
import { toJS } from "mobx";

interface AddUnitModalProps {
  visible: boolean;
  data?: Unit;
  groupName: string;
  onClose: () => void;
}

export const AddUnitModal = observer(
  ({ visible, data, groupName, onClose }: AddUnitModalProps) => {
    useEffect(() => {
      unitsManagementStore.fetchUnitGroups();
    }, []);

    const isBaseUnitFound = !!unitsManagementStore.units.find(
      (v: Unit) => v.is_base_unit
    );
    const [unitData, setUnitData] = useState<Unit>(
      data ||
        ({
          is_base_unit: !isBaseUnitFound,
          base_unit: unitsManagementStore.units.find(
            (v: Unit) => v.is_base_unit
          )?.key,
        } as Unit)
    );
    useEffect(() => {
      setUnitData(
        data ||
          ({
            is_base_unit: !isBaseUnitFound,
            base_unit: unitsManagementStore.units.find(
              (v: Unit) => v.is_base_unit
            )?.key,
          } as Unit)
      );
    }, [isBaseUnitFound, visible]);

    const onChangeData = (newData: Unit) => {
      setUnitData(newData);
    };

    const handleCancel = () => {
      setUnitData(data || ({} as Unit));
      onClose();
    };

    const handleSaveUnit = () => {
      !data
        ? unitsManagementStore.addUnit(groupName, unitData)
        : unitsManagementStore.editUnit(unitData);
      handleCancel();
    };

    const isUniqSymbol = () => {
      const allSymbols = toJS(unitsManagementStore.unitGroups)
        .map((group: UnitGroup) => group.units.map((unit: Unit) => unit.symbol))
        .flat();
      const isUniq = !(
        allSymbols.includes(unitData.symbol) && data?.symbol !== unitData.symbol
      );
      return isUniq;
    };

    const isDisabled = unitData.is_base_unit
      ? !(unitData.name && unitData.symbol && isUniqSymbol())
      : !(
          unitData.name &&
          unitData.symbol &&
          isUniqSymbol() &&
          unitData.multiplication_factor
        );

    return (
      <CommonModal
        title="Add new unit"
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={[
          <Button key="cancel" type="text" onClick={handleCancel}>
            Cancel
          </Button>,
          <StyledButton
            disabled={isDisabled}
            key="add"
            type="primary"
            onClick={handleSaveUnit}
          >
            {!data ? "Add unit" : "Edit unit"}
          </StyledButton>,
        ]}
      >
        <FormWrapper>
          <Row justify="space-between">
            <Col className={styles.fieldBox} span={12}>
              <Row className={styles.fieldLabel}>Name</Row>
              <Row>
                <Input
                  value={unitData.name}
                  onChange={(e) =>
                    onChangeData({ ...unitData, name: e.target.value })
                  }
                />
              </Row>
            </Col>
            <Col className={styles.fieldBox} span={12}>
              <Row className={styles.fieldLabel}>Symbol</Row>
              <Row>
                <Input
                  value={unitData.symbol}
                  onChange={(e) =>
                    onChangeData({ ...unitData, symbol: e.target.value })
                  }
                />
                <span className={styles.fieldHelp}>
                  {!isUniqSymbol() &&
                    unitData.symbol &&
                    "This symbol is already in use. Please enter another symbol"}
                </span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className={styles.fieldBox} span={8}>
              <Row className={styles.fieldLabel}>Is base unit</Row>
              <Row>
                <Select
                  className={styles.fieldSelect}
                  disabled
                  value={unitData.is_base_unit}
                  onChange={(value) =>
                    onChangeData({ ...unitData, is_base_unit: value })
                  }
                >
                  <Select.Option value={false}>No</Select.Option>
                  <Select.Option value={true}>Yes</Select.Option>
                </Select>
              </Row>
            </Col>
            <Col className={styles.fieldBox} span={16}>
              <Row className={styles.fieldLabel}>Base unit</Row>
              <Row>
                <Select
                  className={styles.fieldSelect}
                  placeholder="Select base unit"
                  value={unitData.base_unit}
                  disabled
                  onChange={(value) =>
                    onChangeData({
                      ...unitData,
                      base_unit: value,
                    })
                  }
                >
                  {unitsManagementStore.units
                    .filter((item: Unit) => item.is_base_unit)
                    .map((item: Unit) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className={styles.fieldBox} span={24}>
              <Row className={styles.fieldLabel}>Multiplication factor</Row>
              <Row>
                <InputNumber
                  placeholder="Enter factor"
                  disabled={unitData.is_base_unit}
                  value={unitData.multiplication_factor}
                  onChange={(value) =>
                    onChangeData({
                      ...unitData,
                      multiplication_factor: value || 0,
                    })
                  }
                />
              </Row>
            </Col>
          </Row>
        </FormWrapper>
      </CommonModal>
    );
  }
);
