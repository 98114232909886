import moment from "moment";
import { uploadFile } from "../../../services/standards";
import {
  uploadFile as uploadImportDataFile,
  uploadFileNew as uploadImportDataFileNew,
} from "../../../services/importData";
import { message } from "antd";
import {
  uploadDataSheetFile,
  uploadDataSheetFileNew,
} from "../../../services/dataSheet";
import { areValidFieldsByRules } from "../../dataSheetsPage/dataSheetDocumentsPage/DataSheetDocumentsFunctions";

export const uploadDataLakeFile = async (file: any, dataId: string) => {
  try {
    const CurrentDate = moment().unix();
    const fileName =
      CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
    const signedURL = await uploadFile(dataId, fileName);

    if (signedURL) {
      const arrayBufferData = await file.arrayBuffer();
      if (arrayBufferData) {
        const blob = new Blob([new Uint8Array(arrayBufferData)], {
          type: file.type,
        });
        const result = await fetch(signedURL, {
          method: "PUT",
          body: blob,
        });
        if (result?.status === 200) {
          message.success("File uploaded Successfully!");
        }
      }
    }
  } catch (error) {
    console.log("Something went wrong while uploading file", error);
    message.error("Something went wrong while uploading file.");
  }
};

export const uploadSingleDataSheetFile = async (file: any) => {
  try {
    const CurrentDate = moment().unix();
    const fileName =
      CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");

    const signedURL = await uploadDataSheetFileNew(fileName); //calling api function
    const parsedsignedURL = JSON.parse(signedURL);

    if (parsedsignedURL) {
      const arrayBufferData = await file.arrayBuffer();
      if (arrayBufferData) {
        const blob = new Blob([new Uint8Array(arrayBufferData)], {
          type: file.type,
        });
        const result = await fetch(parsedsignedURL.url, {
          method: "PUT",
          body: blob,
        });
        if (result?.status === 200) {
          message.success("File uploaded Successfully!");
          return parsedsignedURL.file_id;
        }
      }
    }
  } catch (error) {
    console.log("Something went wrong while uploading file", error);
    message.error("Something went wrong while uploading file.");
  }
};

export const handleCheckSingleValidation = (
  dataSheetSchema: SheetSchema[],
  recordValues: any,
  popconfirms?: PopconfirmAlert[],
  limitsData?: any
) => {
  let newPopconfirms: PopconfirmAlert[] = popconfirms || [];
  const thresholdFieldsNames = dataSheetSchema
    ?.filter((schema) => schema?.threshold)
    ?.map((v) => v.entity_name);
  const validatedThresholdFields: boolean[] = [];

  const limits = !limitsData ? {} : { ...limitsData[0] };

  if (thresholdFieldsNames.length) {
    const thresholdFieldsValues = thresholdFieldsNames.map(
      (name) => recordValues[name]
    );
    const isThreshold = (index: number, item: string) => {
      if (
        !thresholdFieldsNames[index] ||
        !thresholdFieldsValues[index] ||
        !Object.keys(limits).length
      ) {
        return false;
      }
      const { lower_limit: lowerLimit, upper_limit: upperLimit } = limits[item];

      if (lowerLimit === null && upperLimit === null) {
        return false;
      }
      return (
        thresholdFieldsValues[index] < lowerLimit ||
        thresholdFieldsValues[index] > upperLimit
      );
    };

    thresholdFieldsNames.forEach((item: string, index: number) => {
      if (isThreshold(index, item)) {
        const { lower_limit: lowerLimit, upper_limit: upperLimit } =
          limits[item];
        const popconfirm = newPopconfirms?.find((v) => v.name === item);
        if (popconfirms?.length && popconfirm) {
          if (!popconfirm?.reason.length) {
            newPopconfirms = newPopconfirms.map((v, i) => {
              return v.name === item
                ? {
                    ...v,
                    isOpen: false,
                    warning_word:
                      thresholdFieldsValues[index] < lowerLimit
                        ? "below"
                        : "above",
                  }
                : v;
            });
          }
        } else {
          newPopconfirms.push({
            name: item,
            isOpen: false,
            warning_word:
              thresholdFieldsValues[index] < lowerLimit ? "below" : "above",
            reason: "",
          });
        }
        !popconfirm?.reason.length && validatedThresholdFields.push(false);
      } else {
        newPopconfirms = popconfirms?.length
          ? newPopconfirms?.filter((v) => v.name !== item)
          : [];
        validatedThresholdFields.push(true);
      }
    });
  }
  return newPopconfirms;
};

export const handleCheckMultilineValidation = (
  dataSheet: DataSheet,
  records?: DataSheetRecord[],
  isForm = false,
  isMessageShown = true
) => {
  const validation: any[] = [];
  const values = records?.map((item) => {
    const values = item[item.sheet_name];
    const isValid =
      dataSheet?.validationRules &&
      areValidFieldsByRules(
        dataSheet?.validationRules,
        values,
        (fields) =>
          validation.push({
            fields,
            recordId: item?._id?.$oid || item.key || "",
          }),
        isForm,
        isMessageShown
      );
    return isValid;
  });
  return { values, validation };
};

export const getTaskColor = (status: string) => {
  let tagColor = "#A58CFA";
  switch (status) {
    case "In Progress":
      tagColor = "#569CE6";
      break;
    case "Pending":
      tagColor = "#E69B56";
      break;
    case "Not started":
      tagColor = "#A68DFB";
      break;
    case "Completed":
      tagColor = "#3DA48B";
      break;
    case "Action required":
      tagColor = "#EC5E5E";
      break;
    case "Not contacted":
      tagColor = "#979797";
      break;
    default:
      tagColor = "#A58CFA";
  }
  return tagColor;
};

export const uploadImportFile = async (file: ImportedFile) => {
  const CurrentDate = moment().unix();
  const fileName =
    CurrentDate + "-" + file?.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
  const payload = {
    file_category: "DATASHEET",
    version: 1,
    file_name: fileName,
    data_id: "",
  };

  const signedURL = await uploadImportDataFileNew(
    "",
    "",
    payload,
    "dataSheetFiles",
    "UPLOAD_FILE"
  );
  const parsedsignedURL = JSON.parse(signedURL);

  if (parsedsignedURL) {
    const arrayBufferData = await (file as File).arrayBuffer();
    if (arrayBufferData) {
      const blob = new Blob([new Uint8Array(arrayBufferData)], {
        type: file.type,
      });
      const result = await fetch(parsedsignedURL.url, {
        method: "PUT",
        body: blob,
      });
      if (result?.status === 200) {
        message.success("File uploaded Successfully!");
      }
    }
  }
  return parsedsignedURL.file_id;
};

export const getFilesSourceKpiId = (
  currentKPI: SurveyKPI,
  mainKPISheetId?: string,
  kpisList?: SurveyKPI[]
) => {
  const isFiles =
    !!currentKPI?.files?.length || !!currentKPI?.uploadedFiles?.length;
  const filteredKpis = kpisList?.filter(
    (v) =>
      v.mainKPISheetId === mainKPISheetId &&
      (!!v.files?.length || !!v.uploadedFiles?.length)
  );
  const isDuplicateNeeded =
    filteredKpis?.length === 1 &&
    filteredKpis?.filter((v) =>
      v?._id
        ? v?._id?.$oid !== currentKPI?._id?.$oid
        : v?.name !== currentKPI?.name
    )?.length === 1;
  const duplicateKpi = kpisList?.find(
    (v) =>
      (v?._id
        ? v?._id?.$oid !== currentKPI?._id?.$oid
        : v?.name !== currentKPI?.name) &&
      v?.mainKPISheetId === mainKPISheetId &&
      (v?.files?.length || v?.uploadedFiles?.length)
  );
  const res = !isFiles && isDuplicateNeeded ? duplicateKpi?._id?.$oid : "";
  return res;
};
