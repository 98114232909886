import { Form, Select, TreeSelect, Spin, Row, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./CreateUserModal.module.scss";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { StyledButton } from "../../../../shared/commonStyles";
interface Item {
  key: string;
  group?: string;
  groupsEntities?: any[] | undefined;
  entity?: { identifierId?: string; sheetId?: string; title?: string };
  isNewGroup?: boolean;
  isGroup: boolean;
  action?: any;
}
const { SHOW_PARENT } = TreeSelect;
interface TableEditableCellProps {
  editing: boolean;
  dataIndex: keyof Item;
  title: string;
  record: Item;
  form: FormInstance<any>;
  groups: UserGroup[];
  refDataSheets: any[];
  loadingRefDataSheetsData: boolean;
  value: string[];
  children: any;
  rowLoading: boolean;
  onChange: (newValue: string[], label: any, extra: any) => void;
  onSave: (newValue: Item) => void;
  onCancel: (value: Item) => void;
  onDelete: (value: Item) => void;
}

export const TableEditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  groups,
  form,
  refDataSheets,
  loadingRefDataSheetsData,
  value,
  rowLoading,
  onChange,
  onSave,
  onCancel,
  onDelete,
  children,
  ...restProps
}: TableEditableCellProps) => {
  useEffect(() => {
    if (editing) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [editing]);

  let inputNode = null;

  if (dataIndex === ("group" as keyof Item)) {
    inputNode = (
      <Select showArrow>
        {groups &&
          groups.map((group, index) => {
            return (
              <Select.Option key={index} value={group._id.$oid}>
                {group.name}
              </Select.Option>
            );
          })}
      </Select>
    );
  } else if (dataIndex === ("entity" as keyof Item)) {
    inputNode = (
      <TreeSelect
        notFoundContent={loadingRefDataSheetsData && <Spin size="small" />}
        allowClear
        showArrow
        dropdownClassName={styles.treeSelect}
        labelInValue={true}
        treeData={refDataSheets}
        value={value}
        onChange={onChange}
        treeCheckable={true}
        showCheckedStrategy={SHOW_PARENT}
        placeholder={"Please select"}
      />
    );
  } else if (dataIndex === ("action" as keyof Item)) {
    inputNode = <>action</>;
  } else {
    inputNode = null;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <>
          {dataIndex == "action" ? (
            <Row>
              <StyledButton type="default" onClick={() => onSave(record)}>
                <CheckOutlined />
              </StyledButton>
              <StyledButton type="default" onClick={() => onCancel(record)}>
                <CloseOutlined size={2} />
              </StyledButton>
            </Row>
          ) : (
            <>
              {" "}
              {record?.isNewGroup ? (
                (dataIndex === "group" && (
                  <Form.Item name={dataIndex}>{inputNode}</Form.Item>
                )) ||
                (dataIndex === "entity" && <>---</>)
              ) : dataIndex === "entity" ? (
                <Form.Item name={dataIndex}>{inputNode}</Form.Item>
              ) : (
                <>---</>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {dataIndex == "action" ? (
            <StyledButton type="default" onClick={() => onDelete(record)}>
              <DeleteOutlined /> Delete
            </StyledButton>
          ) : (
            children
          )}
        </>
      )}
    </td>
  );
};
