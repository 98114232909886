import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker } from "antd";
import { useBoolean } from "ahooks";
import moment from "moment";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import { RegionsSelectorCheckbox } from "../../../../components/RegionsSelectorCheckbox";
import styles from "./GeneralInfo.module.scss";
import { SelectedItems } from "../../../../components/SelectedItems";

interface GeneralInfoProps {
  data: { date: RangeValue; region: any[] };
  goNext: () => void;
  onChangeRegion: (data: any[]) => void;
  onChangeDate: (date: RangeValue) => void;
}

const GeneralInfo = ({
  data,
  goNext,
  onChangeRegion,
  onChangeDate,
}: GeneralInfoProps) => {
  const [dateRange, setDateRange] = useState<RangeValue>(data.date);
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const navigate = useNavigate();

  const dateFormat = "MM/DD/YYYY";

  const handleChange = (date: RangeValue) => {
    setDateRange(date);
  };
  const handleConfirm = () => {
    onChangeDate(dateRange);
    hideModal();
  };
  const handleCancel = () => {
    setDateRange(data.date);
    hideModal();
  };
  const getDatePanel = (panelNode: React.ReactNode) => {
    return (
      <>
        <p className={styles.dateRangeTitle}>Time period</p>
        <div className={styles.dateRangeResult}>
          <span>{dateRange?.[0]?.format(dateFormat)}</span> -{" "}
          <span>{dateRange?.[1]?.format(dateFormat)}</span>
        </div>
        <div className={styles.dateRangeSelector}>{panelNode}</div>
        <div className={styles.dateRangeButtons}>
          <StyledButton type="primary" onClick={handleConfirm}>
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  const onChangeSelectionRegion = (value: string) => {
    const regions = data.region.map((item) =>
      item.key === value ? { ...item, checked: false } : item
    );
    onChangeRegion(regions);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <h2 className={styles.stepTitle}>General information</h2>
      <h4 className={styles.stepSubtitle}>Time period</h4>
      <p className={styles.stepText}>
        Select the period for which you want to enter data
      </p>
      <div className={styles.selectorTitle}>Time period</div>
      <FormWrapper>
        <DatePicker.RangePicker
          className={styles.datePicker}
          open={isModalVisible}
          onFocus={showModal}
          onChange={handleChange}
          panelRender={getDatePanel}
          placeholder={["Date", "Date"]}
          dropdownClassName={styles.dateRangeBox}
          format={dateFormat}
          value={dateRange}
          ranges={{
            "Date Presets": [moment(), moment()],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment().startOf("year").subtract(1, "year"),
              moment().endOf("year").subtract(1, "year"),
            ],
            Custom: [moment(), moment()],
          }}
        />
      </FormWrapper>
      <h4 className={styles.stepSubtitle}>Region(s)</h4>
      <p className={styles.stepText}>
        Select the region(s) where your company is located for which you want to
        make calculations
      </p>
      <div className={styles.selectorTitle}>Regions</div>
      <RegionsSelectorCheckbox data={data.region} onChange={onChangeRegion} />
      <SelectedItems
        data={data.region}
        datatype={"regions"}
        onChange={(_, _i, value) => onChangeSelectionRegion(value)}
      />

      <div className={styles.stepBtnsGroup}>
        <Button onClick={goBack} className={styles.whiteBtn} type="text">
          Cancel
        </Button>
        <StyledButton
          className={styles.continueBtn}
          onClick={goNext}
          type="primary"
        >
          Continue to Scope 1
        </StyledButton>
      </div>
    </>
  );
};

export default GeneralInfo;
