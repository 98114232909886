import { makeAutoObservable, runInAction } from "mobx";
import { Auth } from "aws-amplify";
import moment from "moment";
import { getUserGroups } from "../../services/users";
import calculationsService from "../../services/CalculationsService";
import { message } from "antd";

class CalculationStore {
  savedCalculations: ICalculation[];
  loadingCalculations: boolean;
  calculationsPeriod: RangeValue;

  constructor() {
    this.savedCalculations = [];
    this.loadingCalculations = false;
    this.calculationsPeriod = [moment(), moment()];

    makeAutoObservable(this, {}, { autoBind: true });
  }

  fetchCalculations() {
    return new Promise<ICalculation[]>((resolve, reject) => {
      this.changeLoadingState(true);
      calculationsService
        .savedCalculationList()
        .then((calculationList) => {
          runInAction(() => {
            this.savedCalculations = calculationList;
            resolve(this.savedCalculations);
          });
        })
        .catch((error) => {
          console.error("error", error);
          reject(error);
        })
        .finally(() => {
          this.changeLoadingState(false);
        });
    });
  }

  getGHGEmissionsAudits = async () => {
    const auditsList = await calculationsService.getGHGEmissionsAudits();
    const userData = await Auth.currentSession();
    const username = userData.getAccessToken()["payload"]["username"];
    const payload = {
      filter: {
        group_users: username,
      },
    };
    const userGroups = await getUserGroups(payload);
    const isGroupsFound = (group_id: string) => {
      const isFound = userGroups.filter(
        (group: any) => group._id.$oid === group_id
      ).length;
      return !!isFound;
    };
    const assignedItems =
      auditsList?.filter((audit: any) => {
        const isUserGroups = JSON.parse(audit.assigned_groups)?.filter(
          (group_id: string) => isGroupsFound(group_id)
        ).length;
        const isUser = JSON.parse(audit.assigned_users)?.filter(
          (user: string) => user === username
        ).length;
        if (!!isUserGroups || !!isUser) return audit;
      }) || [];
    const auditItems = assignedItems
      .map((item: any) => JSON.parse(item.audit_items))
      .flat()
      .filter(
        (value: string, index: number, array: any[]) =>
          array.indexOf(value) === index
      );
    return auditItems;
  };

  getAuditCalculations = async (auditIds?: Array<string>) => {
    const payload = {
      filter: {
        _id: {
          $oid: auditIds || (await this.getGHGEmissionsAudits()),
        },
      },
    };
    this.changeLoadingState(true);
    try {
      const calculationList = await calculationsService.savedCalculationList(
        payload
      );
      this.savedCalculations = calculationList;
      return this.savedCalculations;
    } catch (error) {
      console.log(error);
    } finally {
      this.changeLoadingState(false);
    }
  };

  deleteCalculation(id: string) {
    this.changeLoadingState(true);
    calculationsService.removeCalculation(id).then(() => {
      runInAction(() => {
        this.changeLoadingState(false);
        this.fetchCalculations();
      });
    });
  }

  saveCalculation = async (calculationPayload: CalculationPayload) => {
    const userData = await Auth.currentSession();
    const group = userData
      .getAccessToken()
      ["payload"]["cognito:groups"].filter((element: string) =>
        element.includes("org:")
      )[0];
    const username = userData.getAccessToken()["payload"]["username"];

    const payload: ICalculation = {
      ...calculationPayload,
      group,
      username,
    };

    calculationsService.addCalculation(payload).then(() => {
      runInAction(() => {
        this.fetchCalculations();
      });
    });
  };

  duplicateCalculation(id: string) {
    calculationsService.duplicateCalculation(id).then(() => {
      runInAction(() => {
        this.fetchCalculations();
        message.success("Calculation was successfully duplicated");
      });
    });
  }

  refreshCalculations(
    callback: () => void,
    payload?: { filter: PayloadFilters }
  ) {
    this.changeLoadingState(true);
    calculationsService.refreshCalculations(payload).then(() => {
      runInAction(() => {
        this.fetchCalculations();
        callback();
        this.changeLoadingState(false);
      });
    });
  }
  refreshCalculations_job(
    callback?: () => void,
    payload?: { filter: PayloadFilters }
  ) {
    this.changeLoadingState(true);
    return calculationsService.refreshCalculations_job(payload).then((res) => {
      runInAction(() => {
        this.fetchCalculations();
        callback?.();
        this.changeLoadingState(false);
      });
      return res;
    });
  }
  async recalculateCalculationsFilteredByIdentifiers(
    identifiers: string[],
    scope_filters?: any
  ) {
    try {
      this.changeLoadingState(true);
      const payload = { identifiers, scope_filters };
      const response =
        await calculationsService.recalculateCalculationsFilteredByIdentifiers(
          payload
        );
      this.savedCalculations = response;
    } catch (error) {
      console.log("Filtering calculations by identifiers");
    } finally {
      this.changeLoadingState(false);
    }
  }
  changeLoadingState(isLoading: boolean) {
    this.loadingCalculations = isLoading;
  }

  changeCalculationPeriod(period: RangeValue) {
    this.calculationsPeriod = period;
  }
}

const calculationStore = new CalculationStore();

export { calculationStore };
