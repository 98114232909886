import React from 'react';

import { StyledTab } from  './CategoryTabItem.style.js';

const CategoryTabItem = ({ 
  isActive = false, 
  label= '',
  onClick
}) => {
  return (
    <StyledTab active={isActive} title={label} tabIndex="0" onClick={onClick}>{label}</StyledTab>
  );
}

export default CategoryTabItem;
