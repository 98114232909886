import React, { useState } from "react";
import { Input } from "antd";
import { useBoolean } from "ahooks";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./SuggestedSearch.module.scss";

interface SuggestedSearchProps {
  filterName: string;
  tableData: AggregatedFactor[];
  handleChangeFilterName: (name: string) => void;
}

export const SuggestedSearch = ({
  filterName,
  tableData,
  handleChangeFilterName,
}: SuggestedSearchProps) => {
  const [isOpen, { setTrue, setFalse }] = useBoolean(false);
  const [searchName, setSearchName] = useState(filterName);

  const suggestedSearches = () => {
    let filteredArray: any[] = [];
    tableData?.forEach((v) => {
      !filteredArray.find((value) => value.name === v.name) &&
        filteredArray.push(v);
    });
    return filteredArray
      .filter((v) => v?.name?.toLowerCase().includes(searchName?.toLowerCase()))
      .map((item) => (
        <div key={item.name} onClick={() => handleChangeFilterName(item.name)}>
          {item.name}
        </div>
      ))
      .slice(0, 5);
  };

  return (
    <>
      <div>
        <Input
          className={styles.searchField}
          prefix={<SearchOutlined className={styles.searchIcon} />}
          placeholder="Search by name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          onFocus={setTrue}
          onBlur={() => {
            setTimeout(() => {
              handleChangeFilterName(searchName);
              setFalse();
            }, 500);
          }}
          onPressEnter={() => {
            setTimeout(() => {
              handleChangeFilterName(searchName);
              setFalse();
            }, 500);
          }}
        />
      </div>
      {isOpen && (
        <div className={styles.suggestedBox}>
          <p className={styles.searchesTitle}>Suggested Searches</p>
          <div className={styles.suggestedSearches}>{suggestedSearches()}</div>
        </div>
      )}
    </>
  );
};
