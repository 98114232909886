import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Col, Row, Space, Spin, message, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { CommonTable, StyledButton } from "../../../../shared/commonStyles";
import Assignees from "../../components/generalGoalsDetails/Assignees";
import RelatedItems from "../../components/generalGoalsDetails/RelatedItems";
import ProgressBar from "../../components/generalGoalsDetails/ProgressBar";
import DeleteSubGoals from "./DeleteSubGoals";
import SubgoalCount from "../../components/generalGoalsDetails/SubgoalCount";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import { parentGoals, subGoals } from "../../../../services/goals";
import { updateGolasAndProgress } from "../../../../services/goals";

const DeletedGeneralGoalsDetails = ({ category }) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [refreshData, setRefreshData] = useState(0);
  const [expended, setExpended] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [countSubGoals, setCountSubGoals] = useState([]);
  const renderStatusTag = (key) => {
    if (key === "COMPLETED") {
      return (
        <Tag color="#5BA85A" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "IN_PROGRESS") {
      return (
        <Tag color="#1890FF" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "DELETED") {
      return (
        <Tag color="#807C8B" className="tag-rounded">
          {key}
        </Tag>
      );
    } else {
      return (
        <Tag color="#FF0101" className="tag-rounded">
          {key}
        </Tag>
      );
    }
  };
  const expend = (index) => {
    if (expended === index) setExpended(undefined);
    else setExpended(index);
  };
  const loadGoals = async () => {
    try {
      setLoader(true);
      const goals = await parentGoals(category);
      const filteredGoals = goals.filter((item) => item.status === "DELETED");
      setDataSource(filteredGoals);
      console.log("goals", filteredGoals);
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadGoals();
  }, [refreshData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "10%",
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        const messageColor = renderStatusTag(status);
        return <>{messageColor}</>;
      },
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "10%",
      render: (_, record) => {
        return <Assignees record={record} />;
      },
    },
    {
      title: "Related Items",
      dataIndex: "relatedItems",
      width: "10%",
      render: (_, record) => {
        return <RelatedItems record={record} />;
      },
    },
    {
      title: "Subgoals",
      dataIndex: "shortTermGoals",
      width: "10%",
      render: (_, record) => {
        return <SubgoalCount record={record} />;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      width: "10%",
      render: (_, record) => {
        return <ProgressBar record={record} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "30%",
      render: (_, record) => {
        return (
          <Space direction="horizontal">
            <StyledButton
              type="default"
              icon={
                record.id === expended ? (
                  <MinusSquareOutlined />
                ) : (
                  <PlusSquareOutlined />
                )
              }
              onClick={() => expend(record.id)}
            >
              {record.id === expended ? "Hide Subgoals" : "View Subgoals"}
            </StyledButton>
            <StyledButton
              type="default"
              style={{
                width: "100%",
                margin: "0",
                padding: "11px 18px",
                textAlign: "left",
              }}
              icon={<RollbackOutlined />}
              onClick={() => onRestore(record)}
            >
              Restore
            </StyledButton>
          </Space>
        );
      },
    },
  ];

  const onConfirmSubmit = async () => {
    if (!rowData) return;
    try {
      setConfirmLoader(true);
      delete rowData.createdAt;
      delete rowData.updatedAt;
      rowData.status = "IN_PROGRESS";
      if (countSubGoals.length > 0) {
        const subGoalResponse = await Promise.all(
          countSubGoals?.map(async (item) => {
            if (!item.id) return;
            delete item.createdAt;
            delete item.updatedAt;
            item.status = "IN_PROGRESS";
            const response = await updateGolasAndProgress(item);
            return response;
          })
        );
        console.log('subGoalResponse',subGoalResponse)
        if (subGoalResponse) {
          const response = await updateGolasAndProgress(rowData);
          if (response) {
            message.success("Goal restored successfully");
            setRefreshData(refreshData + 1);
            setRowData(null);
            setShowConfirmModal(false);
          }
        }
      } else {
        const response = await updateGolasAndProgress(rowData);
        if (response) {
          message.success("Goal restored successfully");
          setRefreshData(refreshData + 1);
          setRowData(null);
          setShowConfirmModal(false);
        }
      }
    } catch (error) {
      message.error(`error while confirmimg goal ${error}`);
      console.log("error while confirmimg goal", error);
    } finally {
      setConfirmLoader(false);
    }
  };

  const onRestore = async (data) => {
    setRowData(data);
    if (data) {
      try {
        setLoader(true);
        const goals = await subGoals(data?.id);
        const filteredSubGoals = goals.filter(
          (item) => item.status === "DELETED"
        );
        console.log("DELETED", filteredSubGoals);
        setCountSubGoals(filteredSubGoals);
        setShowConfirmModal(true);
      } catch (e) {
        message.error(e);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <Spin spinning={loader}>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          description={
            countSubGoals.length > 0
              ? "Are you sure you want to restore the main and sub goals"
              : "Are you sure you want to restore this goal"
          }
          visible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmSubmit}
          ConfirmLoader={confirmLoader}
        />
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CommonTable
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
            rowKey="id"
            expandable={{
              expandedRowRender: (record) => (
                <DeleteSubGoals goalData={record} onRestore={onRestore} />
              ),
              expandIcon: () => <></>,
            }}
            expandedRowKeys={[expended]}
          />
        </Col>
      </Row>
    </Spin>
  );
};
export default DeletedGeneralGoalsDetails;
