import React, { useState } from 'react';
import { StyledButton } from "../../../shared/commonStyles";
import CreateContentModal from "./Components/CreateContentModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";

export const CreateContent = () => {
    const [showCreateContentModal,setShowCreateContentModal] = useState(false);
    const { checkPermissions } = useCheckRolesPermissions();
  return (
    <>
        {showCreateContentModal && (
            <CreateContentModal
                visible = {showCreateContentModal}
                onClose={() => setShowCreateContentModal(false)}
            />
        )}
        {checkPermissions("manage-learning-content") && (
            <StyledButton
                onClick={() => setShowCreateContentModal(true)}
                type="custom"
                style = {{borderRadius : '4px'}}
            >
                <PlusCircleOutlined /> <span>Add Content</span>
            </StyledButton>
        )}
    </>
  )
}