import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useUserSettings } from "./useUserSettings";
import { message } from "antd";
import { useGetMetadataUserSettings } from "./useGetMetadataUserSettings";

export function useChangeMetadataSettings() {
  const userSettings = useGetMetadataUserSettings();
  const { auth } = useContext(AuthContext);
  const { createUserSettings, updateUserSettings } = useUserSettings();
  const username = auth["auth"]["accessToken"]["payload"]["username"];
  const changeMetadataSettings = async (
    defaultMetaDataSheetId: string,
    value: string,
    searchEntityName?: string
  ) => {
    if (userSettings?.length && Object.keys(userSettings?.at(0)).length) {
      let newSettingValues = [];
      if (
        userSettings.find(
          (i: any) => i.metadata_sheet_id === defaultMetaDataSheetId
        )
      ) {
        newSettingValues = userSettings.map((v: any) => {
          if (v.metadata_sheet_id === defaultMetaDataSheetId) {
            return {
              ...v,
              record_id: value,
              searchEntityName: searchEntityName || "",
            };
          } else {
            return v;
          }
        });
      } else {
        newSettingValues = [
          ...userSettings,
          {
            metadata_sheet_id: defaultMetaDataSheetId,
            record_id: value,
            searchEntityName: searchEntityName || "",
          },
        ];
      }

      const payload = {
        owner: username,
        settingName: "default_metadata_sheet_record",
        settingValue: JSON.stringify(newSettingValues),
      };
      const response = await updateUserSettings(payload);

      if (response) {
        message.success("Data updated successfully!");
        return JSON.parse(response?.settingValue || "{}");
      }
    } else {
      const payload = {
        owner: username,
        settingName: "default_metadata_sheet_record",
        settingValue: JSON.stringify([
          {
            metadata_sheet_id: defaultMetaDataSheetId,
            record_id: value,
            searchEntityName: searchEntityName || "",
          },
        ]),
      };
      const response = await createUserSettings(payload);

      if (response) {
        message.success("Data saved successfully!");
        return JSON.parse(response?.settingValue || "{}");
      }
    }
  };
  return changeMetadataSettings;
}
