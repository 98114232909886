import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getCompanyInfo = async () => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const response = await API.graphql({
    query: queries["getCompanyInfo"],
    variables: { group: group },
  });
  return response["data"]["getCompanyInfo"];
};
