import React, { ReactElement, useEffect, useState } from "react";
import {
  FormWrap,
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../shared/commonStyles";
import { Col, Form, Progress, Row, Space, Spin } from "antd";
import { useNavigate } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FooterButtons } from "./StandardsSheetComponents/FooterButtons";
import { TemplateSheetSelection } from "./StandardsSheetComponents/StandardsSelection";
import { GeneralInfo } from "./StandardsSheetComponents/GeneralInfo";
import styles from "./CreateStandardsDataSheet.module.scss";
import moment from "moment";
import { Auth } from "aws-amplify";
import {
  createDataSheet,
  getDatasheetFilters,
  insertData,
} from "../../services/dataSheet";
import { isValidJSONString } from "../../shared/commonFunctions";
import Success from "./components/Success";

const cdpTemplate = require("../../shared/Json/CDP.json");
const csrdTemplate = require("../../shared/Json/CSRD.json");
const griTemplate = require("../../shared/Json/GRI.json");
const sasbTemplate = require("../../shared/Json/SASB.json");
const tcfdTemplate = require("../../shared/Json/TCFD.json");
const csrdChecklistTemplate = require("../../shared/Json/CSRD_Checklist.json");

export const CreateStandardsDataSheet = () => {
  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [sheetNames, setSheetNames] = useState([]);
  const [initialValues, setInitialValues] = useState<DataSheet>({
    is_reference_data: false,
    is_standard_data: true,
    sheet_name: "[STANDARD NAME] + [CURRENT YEAR]",
    description: "[COMPANY NAME] + [STANDARD NAME] + [CURRENT YEAR]",
    enable_comments: true,
    enable_change_history: true,
    archive: false,
  } as DataSheet);
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onStepChange = (selectedStep: number) => {
    setStep(selectedStep);
  };

  const getSheetNames = async () => {
    setLoadDataSheets(true);
    const dataSheetsList = await getDatasheetFilters();
    if (dataSheetsList?.sheet_name?.length) {
      setSheetNames(dataSheetsList?.sheet_name);
    }
    setLoadDataSheets(false);
  };

  useEffect(() => {
    getSheetNames();
  }, []);

  const getRecordsForTemplate = (sheetType: string) => {
    let template = [];
    switch (sheetType) {
      case "CDP":
        template = cdpTemplate;
        break;
      case "CSRD":
        template = csrdTemplate;
        break;
      case "GRI":
        template = griTemplate;
        break;
      case "SASB":
        template = sasbTemplate;
        break;
      case "TCFD":
        template = tcfdTemplate;
        break;
      case "CSRD Checklist":
        template = csrdChecklistTemplate;
        break;
      default:
        [];
    }
    template = template?.map((item: any) => {
      const newObj: any = {};
      Object.entries(item)?.forEach(
        ([key, value]: [key: string, value: any]) => {
          const newKey: string =
            key === "Resource URL"
              ? "source"
              : initialValues.sheet_schema?.find((v) => v.display_name === key)
                  ?.entity_name || key;
          newObj[newKey] = value;
        }
      );
      return newObj;
    });
    return template;
  };

  const handleSelectStandard = (standard: any) => {
    const { sheet_type, sheet_schema } = standard;

    form.setFieldsValue({
      sheet_type,
      sheet_schema,
      sheet_name: `${sheet_type} ${moment().year()}`,
      description: `${companyName} ${sheet_type} ${moment().year()}`,
    });
    setInitialValues((prev: any) => ({
      ...prev,
      sheet_type,
      sheet_schema,
      sheet_name: `${sheet_type} ${moment().year()}`,
      description: `${companyName} ${sheet_type} ${moment().year()}`,
    }));
  };

  const loadCompanyInfo = async () => {
    try {
      const data = await Auth.currentSession();
      const group = data
        .getAccessToken()
        ["payload"]["cognito:groups"]?.filter((element: any) =>
          element.includes("org:")
        )[0]
        .replace("org:", "");
      setCompanyName(group);
    } catch (error) {
      console.log("Error while load company details", error);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const steps: ReactElement[] = [
    <TemplateSheetSelection
      key="standard-selection"
      initialValues={initialValues}
      onSelectTemplate={handleSelectStandard}
    />,
    <GeneralInfo
      key="general-info"
      initialValues={initialValues}
      sheetNames={sheetNames}
    />,
    <Success
      key="success"
      sheetId={initialValues._id?.$oid}
      sheetName={initialValues.sheet_name}
    />,
  ];

  const onChangeFieldsData = () => {
    const formValues = form.getFieldsValue(true);
    setInitialValues(formValues);
  };

  const handleSubmit = async () => {
    try {
      const payload = { ...initialValues };
      const response = await createDataSheet(payload);
      const datalake = isValidJSONString(response["data"]["datalake"]);
      if (datalake["_id"]["$oid"]) {
        setInitialValues((prev) => ({ ...prev, _id: datalake["_id"] }));
        const records = getRecordsForTemplate(initialValues.sheet_type);
        const recordsPayload = {
          sheet_id: datalake["_id"]["$oid"],
          sheet_name: initialValues.sheet_name,
          documents: records,
        };
        await insertData(recordsPayload);
      }
      setStep(step + 1);
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleClickNext = async () => {
    try {
      await form.validateFields();
      step === 0 ? setStep(step + 1) : await handleSubmit();
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleClickBack = () => {
    step === 0 ? navigate("/data/data-sheets") : setStep(step - 1);
  };

  const isButtonDisabled = step === 0 && !initialValues.sheet_type;

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                onClick={() => navigate("/data/data-sheets")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step} onChange={onStepChange}>
                <>
                  <StyledSteps.Step
                    className="stepper-wrapper "
                    key={0}
                    title={<span>Standard selection</span>}
                  />

                  <StyledSteps.Step
                    className="stepper-wrapper"
                    key={1}
                    title={<span>General information</span>}
                    disabled={isButtonDisabled}
                  />
                </>
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={(step + 1) * 50}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={onChangeFieldsData}
                >
                  {step === 2 ? (
                    steps[step]
                  ) : (
                    <FormWrap
                      width={step === 0 ? "80%" : "50%"}
                      className={styles.wrapper}
                    >
                      {steps[step]}
                      <FooterButtons
                        step={step}
                        onClickNext={handleClickNext}
                        onClickBack={handleClickBack}
                        disable={isButtonDisabled}
                      />
                    </FormWrap>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
