import React, { useState, useEffect } from "react";
import { message, Space } from "antd";
import { Auth } from "aws-amplify";
import {ApprovedSection} from "./ApprovedSection";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { createStandardAuditDetail, updateStandardAuditDetail  } from "./../../../../services/standards";
import { YearMonthDay24HourFormat } from "../../../../shared/constants";
import {uploadDataLakeAuditFile} from '../../../../services/audits';

const ApproveStatus = ({ rowData, onFinishFormSubmit, refreshUploadedFiles, setRefreshUploadedFiles }) => { 
  
  const [submitting, setSubmitting] = useState(false);
  const [userAuditDetail,setUserAuditDetail] = useState(null);
  useEffect(() => {  
    if (rowData && rowData?.audit_details?.length) {
      getDataLog(rowData?.audit_details);
    }
  }, [rowData]);


  const getDataLog = async (auditDetails) => {
    const userObj = await Auth.currentSession();
    const username = userObj["accessToken"]["payload"]["username"];

    const selectedAuditDetail = auditDetails?.find((item) => item?.username === username);
    setUserAuditDetail(selectedAuditDetail);
  }


  const uploadFile = async(file,dataId) => {
    //fileUploadState is for create or update
      try{
        const CurrentDate = moment().unix();
        const fileName = CurrentDate +'-'+ file.name?.replace(/[/\\$#@?%*:|"<>]/g, '');
        const signedURL = await uploadDataLakeAuditFile(dataId, fileName);
        if(signedURL){
          const arrayBufferData = await file.arrayBuffer(); 
          if(arrayBufferData){
            const blob = new Blob([new Uint8Array(arrayBufferData)], {type: file.type });
            const result = await fetch(signedURL, {
              method: 'PUT',
              body: blob
            });
            if(result?.status === 200){
              message.success("File uploaded Successfully!");
              setRefreshUploadedFiles((prev)=> prev + 1);
            }
            
          }
        }
      }catch(error){
        console.log("Something went wrong while uploading file",error);
        message.error("Something went wrong while uploading file.");
      } 
  }


  const saveFiles = async(files, auditLogData) => {
    if(files && files.length && auditLogData?.id){      
      try{
        await Promise.all(
          await files.map(async(file) => {
            await uploadFile(file,auditLogData?.id);  
          })
        )        
      }catch(error){
         console.log("Something went wrong while uploading files!",error);
      } 
    }
  }
  const submitSignoffStatus = async(comment,status,files) => {     
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      setSubmitting(true);
      let response = null
      let detailPayload  = null
      if(userAuditDetail){
        detailPayload = {
          id:userAuditDetail.id,
          status,
          comment,
          username, 
          create_timestamp: userAuditDetail.create_timestamp,
          modify_timestamp: moment().format(YearMonthDay24HourFormat),            
        }
        const payload = {
          _id: rowData?._id,
          'previous_audit_details':userAuditDetail,
          audit_details:detailPayload
        };   
        response = await updateStandardAuditDetail(payload)
        
      }else{
        detailPayload = {
          id:uuidv4(),
          status,
          comment,   
          username,            
          create_timestamp: moment().format(YearMonthDay24HourFormat),
          modify_timestamp: moment().format(YearMonthDay24HourFormat),       
        }
        const payload = {
          _id: rowData?._id,
          audit_details:detailPayload
        };   
        response = await createStandardAuditDetail(payload)        
      }
      

      if (response) {
        if(files.length > 0 && detailPayload){
          saveFiles(files,detailPayload) 
        }
        message.success("Record updated successfully!");
        onFinishFormSubmit()
        return 'success'
      } else {
        message.error("Error while updating record!");
      }
    } catch (error) {
      console.log("Error while approving data sheet record!",error);
      message.error("Error while approving data sheet record!");
    } finally {
      setSubmitting(false);
    }
    return
  }
  return (
    
    <Space direction="horizontal">
      <ApprovedSection
        submitSignoffStatus={submitSignoffStatus} 
        submitting={submitting}
        status="approved"
        
        refreshUploadedFiles={refreshUploadedFiles}
        auditRecord={userAuditDetail}

      /> 
      <ApprovedSection
        submitSignoffStatus={submitSignoffStatus} 
        submitting={submitting}
        status="denied"
        refreshUploadedFiles={refreshUploadedFiles}
        auditRecord={userAuditDetail}
      /> 
    </Space>
  );
};

export default ApproveStatus;
