import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";
import { Auth } from "aws-amplify";
import {
  addSurveys,
  getSurveyById,
  updateSurveys,
} from "../../services/surveys";
import {
  getDataSheets,
  listMetaDataRecords,
  makeQueryMetadata,
} from "../../services/dataSheet";
import { useBusinessUnitLocationPeriodFilter } from "../../hooks/useBusinessUnitLocationPeriodFilter";
import { Space, Form, Row, Col, message, Steps, Progress, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import {
  listSelectedStandards,
  parsedStandardData,
} from "../../services/standards";
import { v4 as uuidv4 } from "uuid";
import { getSurveysList } from "../../services/surveys";
import GeneralInformation from "./Steps/GeneralInformation";
import BusinessInformation from "./Steps/BusinessInformation";
import StandardsSelection from "./Steps/StandardsSelection";
import DataSheetsSelection from "./Steps/DataSheetsSelection";
import EmailTemplate from "../../components/EmailTemplate/EmailTemplate";
import SurveyOrder from "./Steps/SurveyOrder";
import Success from "./Steps/Success";
import DataSheetsSetup from "../createDataSheetSurvey/Steps/DataSheetsSetup";

const { Step } = Steps;

const CreateStandardsSurvey = () => {
  const {
    locations,
    businessUnits,
    reportPeriods,
    locationId,
    setLocationId,
    businessUnitId,
    setBusinessUnitId,
    reportPeriodId,
    setReportPeriodId,
  } = useBusinessUnitLocationPeriodFilter();
  const { survey_id: surveyId } = useParams();
  const [rowData, setRowData] = useState(null);
  const [action, setAction] = useState("create");
  const [step, setStep] = useState(1);
  const [sortedSurveyList, setSortedSurveyList] = useState([]);

  const [loadingCreateSurvey, setLoadingCreateSurvey] = useState(false);
  const [selectedListDataLake, setSelectedListDataLake] = useState([]);
  const [loadingDataLakeMap, setLoadingDataLakeMap] = useState([]);
  const [dataLakeMapsList, setDataLakeMapsList] = useState([]);
  const [dataLakeMapsListForTable, setDataLakeMapsListForTable] = useState([]);
  const [dataListForUpdate, setDataListForUpdate] = useState(false);
  const [selectedListDataSurvey, setSelectedListDataSurvey] = useState([]);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [metaDataSheetList, setMetaDataSheetList] = useState(null);
  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataSheetFiltered, setIsDataSheetFiltered] = useState(false);
  const [filteredDataSheetsList, setFilteredDataSheetsList] = useState(null);
  const [listAllSurveys, setListAllSurveys] = useState([]);
  const sampleEmailBody = `Hello {FULL_NAME},<br><br>We are conducting the <b>{SURVEY_NAME}</b> survey and would like your input. Please follow {SIGNUP_LINK} to our data management platform, <a href="http://Hydrus.ai target=__blank">Hydrus.ai</a>, where you can complete the survey. If you have any questions about this process, please don’t hesitate to reach out to your administrator.<br><br>
  Thanks,<br><br>Administrator`;

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    status: true,
    facilityPopupTitle: "Search for your entity",
    facilityInfoMessage: "Type in an info message for the user",
    searchFieldLabel: "Select a field to search.",
    searchFieldValueLabel: "Search...",
    //facilityTableText: "",
    defaultMetaDataSheetId: "",
    entityIds: [],
    selectedStandards: [],
    selectedDataSheets: [],
    email_subject: "",
    reminder_interval: "op1",
    email_type: "Primary",
    email_reminder_subject: "",
    email_body: sampleEmailBody,
    email_reminder_body: "",
  });
  const [entitiesList, setEntitiesList] = useState();
  const [isIdentifier, setIsIdentifier] = useState(false);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [form] = Form.useForm();

  const loadDataLake = async () => {
    try {
      setLoadingDataLakeMap(true);
      const data = await Auth.currentSession();
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      // for filtering data
      const businessUnit = businessUnitId ? businessUnitId : undefined;
      const reportPeriod = reportPeriodId ? reportPeriodId : undefined;
      const newLocationId = locationId ? locationId : undefined;

      const payload = {
        filter: {
          group_id: group,
          business_unit: businessUnit,
          location_id: newLocationId,
          report_period: reportPeriod,
          status: "ACTIVE",
        },
      };
      const standardsResponse = await listSelectedStandards(payload);
      const formItems = standardsResponse?.data || [];

      const parsedData = await Promise.all(
        formItems?.map(async (item) => {
          return await parsedStandardData(item);
        })
      );
      setDataLakeMapsList(parsedData);
      setDataLakeMapsListForTable(parsedData);
      // List All Surveys
      const listSurveys = await getSurveysList();
      setListAllSurveys(listSurveys["items"]);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoadingDataLakeMap(false);
    }
  };
  useEffect(() => {
    loadDataLake();
  }, [locationId, reportPeriodId, businessUnitId]);

  const loadSheetData = async () => {
    try {
      setLoadDataSheets(true);
      const payload = {
        filter: { archive: false, is_reference_data: false },
      };
      const dataSheetsList = await getDataSheets(payload);
      const newDataSheetList = dataSheetsList.map((item) => {
        return {
          ...item,
          multiline_form_flag: item.multiline_form_flag
            ? item.multiline_form_flag
            : false,
          hide_fields: item.hide_fields ? item.hide_fields : false,
          show_notes: item.show_notes || false,
          mass_upload_status: false,

          display_fields:
            item.display_fields || !item.hide_fields
              ? item.sheet_schema
                  ?.filter(
                    (v) =>
                      !v.metadata_entity_name &&
                      !v.isExpression &&
                      v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name)
              : item.sheet_schema
                  ?.filter(
                    (v) => !v.isExpression && v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name),
          placeholders: item.placeholders || {},
          instructions: "",
        };
      });
      if (newDataSheetList?.length) {
        setDataSheetsList(newDataSheetList);
        setFilteredDataSheetsList(newDataSheetList);
      }

      const payloadMeta = {
        filter: { archive: false, is_reference_data: true },
      };
      const metaSheetsList = await getDataSheets(payloadMeta);
      const newMetaSheetList = metaSheetsList.map((item) => {
        return {
          ...item,
          multiline_form_flag: item.multiline_form_flag
            ? item.multiline_form_flag
            : false,
          hide_fields: item.hide_fields ? item.hide_fields : false,
          show_notes: item.show_notes || false,
          display_fields:
            item.display_fields || !item.hide_fields
              ? item.sheet_schema
                  ?.filter(
                    (v) =>
                      !v.metadata_entity_name &&
                      !v.isExpression &&
                      v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name)
              : item.sheet_schema
                  ?.filter(
                    (v) => !v.isExpression && v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name),
          placeholders: item.placeholders || {},
          mass_upload_status: false,
          instructions: "",
        };
      });
      setMetaDataSheetList(newMetaSheetList);
      if (newMetaSheetList?.length && newDataSheetList?.length) {
        setIsDataLoaded(true);
      }
    } catch (e) {
      message.error(`Some problem occured while loading data sheet ${e}`);
    } finally {
      setLoadDataSheets(false);
    }
  };
  useEffect(() => {
    loadSheetData();
  }, []);

  const getMetadataEntities = async (metaDataSheetId) => {
    try {
      setEntitiesLoading(true);
      const metadataSheet = metaDataSheetList?.find(
        (item) => item._id.$oid === metaDataSheetId
      );
      const identifier = metadataSheet?.sheet_schema.find(
        (value) => value.isIdentifier
      )?.entity_name;

      setIsIdentifier(!!identifier);
      if (identifier) {
        const payload = makeQueryMetadata({
          sheetId: metadataSheet?._id?.$oid,
          pagination: { current: 1, pageSize: 1000 },
          filters: [{ column: identifier, op: "isNotEmpty", column_value: "" }],
        });
        const metaData = await listMetaDataRecords(payload);
        const entities =
          metaData.data?.map((v) => ({
            ...v,
            [v.sheet_name]: {
              ...v[v.sheet_name],
              entity_name: v[v.sheet_name]?.[identifier],
            },
          })) || [];
        setEntitiesList(entities);
      }
    } catch (e) {
      message.error("Something went wrong....", e);
    } finally {
      setEntitiesLoading(false);
    }
  };

  useEffect(() => {
    setEntitiesList([]);

    if (initialValues?.defaultMetaDataSheetId) {
      const filteredDataSheet = dataSheetsList?.filter(
        (item) =>
          item.metadata_sheet_id === initialValues?.defaultMetaDataSheetId ||
          item.metadata_sheet_id === "" ||
          item.metadata_sheet_id === null ||
          item.metadata_sheet_id === undefined ||
          !item.metadata_sheet_id
      );
      setFilteredDataSheetsList(filteredDataSheet);
      setIsDataSheetFiltered(true);
      getMetadataEntities(initialValues?.defaultMetaDataSheetId);
    }
  }, [initialValues?.defaultMetaDataSheetId]);

  useEffect(() => {
    if (surveyId && isDataLoaded && !dataListForUpdate) {
      setAction("update");
      setLoadDataSheets(true);
      const loadSurveyRowData = async () => {
        try {
          const surveyData = await getSurveyById(surveyId);
          if (surveyData) {
            const initData = {
              status: surveyData?.status === "ACTIVE" ? true : false,
              name: surveyData.name,
              description: surveyData.description,
              facilityPopupTitle: surveyData?.popup_title
                ? surveyData?.popup_title
                : initialValues?.facilityPopupTitle,
              searchFieldLabel: surveyData?.search_field_label
                ? surveyData?.search_field_label
                : initialValues?.searchFieldLabel,
              searchFieldValueLabel: surveyData?.search_field_label_value
                ? surveyData?.search_field_label_value
                : initialValues?.searchFieldValueLabel,
              //facilityTableText: surveyData?.table_intro_text,
              facilityInfoMessage: surveyData?.info_message
                ? surveyData?.info_message
                : initialValues?.facilityInfoMessage,
              defaultMetaDataSheetId: surveyData?.defaultMetaDataSheetId,
              entityIds: surveyData?.entityIds || [],
              email_subject: surveyData?.email_subject,
              email_reminder_subject: surveyData?.email_reminder_subject,
              email_reminder_body: surveyData?.email_reminder_body,
              email_body: surveyData.email_body,
              email_type: "Primary",
              reminder_interval:
                surveyData.reminder_interval === null
                  ? "op1"
                  : surveyData.reminder_interval === 1000000
                  ? surveyData.reminder_interval
                  : "recurring_reminder",
              recurring_reminder_val:
                surveyData.reminder_interval != 1000000
                  ? surveyData.reminder_interval
                  : "",
            };
            initialValues?.defaultMetaDataSheetId &&
              !!metaDataSheetList?.length &&
              (await getMetadataEntities(
                initialValues?.defaultMetaDataSheetId
              ));
            setDataListForUpdate(true);
            form.setFieldsValue(initData);
            setInitialValues(initData);
            setRowData(surveyData);
          } else {
            message.error("Survey not found");
            navigate(`/management/collect-data`);
          }
        } catch (error) {
          console.log("Something went wrong while loading survey!", error);
          message.error("Something went wrong while loading survey!");
        } finally {
          setLoadDataSheets(false);
        }
      };
      loadSurveyRowData();
    }
  }, [surveyId, isDataLoaded]);

  const loadData = async () => {
    try {
      const surveyOrders = JSON.parse(rowData?.survey_order);
      const selectedRecordsIds = surveyOrders?.map((item) => {
        return item.id;
      });

      const dataMapList = filteredDataSheetsList?.filter((sItem) =>
        selectedRecordsIds?.includes(sItem._id["$oid"])
      );
      const dataMapListNotIncluded = filteredDataSheetsList?.filter(
        (sItem) => !selectedRecordsIds?.includes(sItem?._id["$oid"])
      );
      const newArray = [...dataMapList, ...dataMapListNotIncluded];
      const newDataSheetList = newArray?.map((item) => {
        const orderData = surveyOrders?.filter(
          (sItem) => sItem.id === item._id["$oid"]
        );
        return {
          ...item,
          multiline_form_flag: orderData?.length
            ? orderData[0].multiline_form_flag
            : false,
          hide_fields: orderData?.length ? orderData[0].hide_fields : false,
          show_notes: orderData?.length ? orderData[0].show_notes : false,
          instructions: orderData?.length ? orderData[0].instructions : "",
          mass_upload_status: orderData?.length
            ? orderData[0].mass_upload_status
            : false,
          display_fields:
            orderData?.length && !!orderData[0].display_fields
              ? orderData[0].display_fields
              : !item.hide_fields
              ? item.sheet_schema
                  ?.filter(
                    (v) =>
                      !v.metadata_entity_name &&
                      !v.isExpression &&
                      v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name)
              : item.sheet_schema
                  ?.filter(
                    (v) => !v.isExpression && v.input_type !== "expression"
                  )
                  ?.map((v) => v.entity_name),
          placeholders:
            orderData?.length && !!orderData[0].placeholders
              ? orderData[0].placeholders
              : {},
        };
      });
      setFilteredDataSheetsList(newDataSheetList);
      const selectedData = newDataSheetList?.filter((item) =>
        dataMapList.find((v) => v?._id["$oid"] === item?._id["$oid"])
      );
      const dataSheetSortable = selectedData?.map((item, index) => {
        // add index for sortable table otherwise it hide rows when we try to sort.
        return {
          ...item,
          index: index,
        };
      });

      setSelectedListDataSurvey(dataSheetSortable);
      //initData.selectedDataSheets = dataSheetSortable;
      const newInitialValue = {
        ...initialValues,
        selectedDataSheets: dataSheetSortable,
      };
      setInitialValues(newInitialValue);
      form.setFieldsValue(newInitialValue);
      setIsDataSheetFiltered(false);
      //}
    } catch (error) {
      console.log("Something went wrong while loading survey!", error);
      message.error("Something went wrong while loading survey345!");
    } finally {
      setLoadDataSheets(false);
    }
  };

  useEffect(() => {
    if (rowData) {
      loadData();
    }
  }, [rowData, isDataSheetFiltered]);

  const loadSortedData = () => {
    setLoadDataSheets(true);
    try {
      const surveyOrders = JSON.parse(rowData?.survey_order);
      const selectedRecordsIds = surveyOrders?.map((item) => {
        return item.id;
      });
      const dataMapList = dataLakeMapsList?.filter((sItem) =>
        selectedRecordsIds?.includes(sItem._id.$oid)
      );
      const dataMapListNotIncluded = dataLakeMapsList?.filter(
        (sItem) => !selectedRecordsIds?.includes(sItem?._id["$oid"])
      );
      const newArray = [...dataMapList, ...dataMapListNotIncluded];
      setDataLakeMapsListForTable(newArray);
      const dataLakeMapSortable = dataMapList?.map((item, index) => {
        // add index for sortable table otherwise it hide rows when we try to sort.
        return {
          ...item,
          index: index,
        };
      });
      setSelectedListDataLake(dataLakeMapSortable);
      // set initial data in form when update data
      const initData = {
        selectedStandards: dataMapList,
      };
      form.setFieldsValue(initData);
      //setInitialValues(initData);
    } catch (error) {
      console.log("Something went wrong while loading survey!", error);
      message.error("Something went wrong while loading survey567!");
    } finally {
      setLoadDataSheets(false);
    }
  };

  useEffect(() => {
    if (dataLakeMapsList.length && rowData) {
      loadSortedData();
    }
  }, [dataLakeMapsList, rowData]);

  const setDataForSortableTable = () => {
    // sortable Table Data List
    // add index for sortable table otherwise it hide rows when we try to sort.
    const newData = [];
    let newIndex = 0;
    if (selectedListDataLake && selectedListDataLake.length) {
      selectedListDataLake.map((item, index) => {
        newIndex = index;
        const dataObj = {
          ...item,
          type: "standards",
          index: newIndex,
        };
        newData.push(dataObj);
      });
    }
    if (selectedListDataSurvey && selectedListDataSurvey.length) {
      selectedListDataSurvey.map((item, index) => {
        newIndex = newIndex + 1;
        const dataObj = {
          ...item,
          type: "data_sheets",
          index: newIndex,
        };
        newData.push(dataObj);
      });
    }
    const survey_order =
      rowData?.survey_order && JSON.parse(rowData?.survey_order);
    const welcomePageData = survey_order?.filter(
      (item) => item?.survey_type === "welcome_page"
    );
    if (welcomePageData?.length) {
      welcomePageData?.map((item, index) => {
        newIndex = newIndex + 1;
        const dataObj = {
          ...item,
          type: "welcome_page",
          index: newIndex,
        };
        newData.push(dataObj);
      });
    }
    setSorting(newData);
  };

  useEffect(() => {
    setDataForSortableTable();
  }, [selectedListDataLake, selectedListDataSurvey, step]);

  const setSorting = (newData) => {
    const sorter = (a, b) => {
      const aData = a?._id?.["$oid"] || a?.id;
      const bData = b?._id?.["$oid"] || b?.id;
      let positionA = 0;
      let positionB = 0;

      const survey_order =
        rowData?.survey_order && JSON.parse(rowData?.survey_order);
      survey_order?.forEach((value, index) => {
        if (aData?.indexOf(value.id) !== -1) {
          positionA = index;
        }
        if (bData?.indexOf(value.id) !== -1) {
          positionB = index;
        }
      });
      return positionA - positionB;
    };
    newData.sort(sorter);
    const indexKeyUpdate = newData.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
    setSortedSurveyList(indexKeyUpdate);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(sortedSurveyList),
        oldIndex,
        newIndex
      )?.filter((el) => !!el);
      const indexKeyUpdate = newData.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setSortedSurveyList(indexKeyUpdate);
    }
  };

  const onSelectRowChange = (record, selected, selectedRows) => {
    if (selected) {
      const filterNewRecords = selectedRows?.filter(
        (item) =>
          !selectedListDataLake.find(
            (bItem) => bItem?._id.$oid === item?._id.$oid
          )
      );
      const allSelectedRecords = [...selectedListDataLake, ...filterNewRecords];
      setSelectedListDataLake(allSelectedRecords);

      form.setFieldsValue({
        selectedStandards: allSelectedRecords,
      });
    } else {
      const filterNewRecords = selectedRows?.filter(
        (item) =>
          !selectedListDataLake.find(
            (bItem) => bItem?._id.$oid === item?._id.$oid
          )
      );
      const allSelectedRecords = [...selectedListDataLake, ...filterNewRecords];
      const filteredRecords = allSelectedRecords?.filter(
        (item) => item?._id.$oid !== record?._id.$oid
      );
      setSelectedListDataLake(filteredRecords);
      // save selected rows in state
      form.setFieldsValue({
        selectedStandards: filteredRecords,
      });
    }
  };

  const getDataIdsFromSelectedData = (selectedList) => {
    try {
      const selectedRecordsIds = selectedList.map((item) => {
        return item._id.$oid;
      });
      const filterdData = dataLakeMapsList?.filter((item) =>
        selectedRecordsIds.includes(item._id.$oid)
      );
      return filterdData.map((item) => {
        return item._id.$oid;
      });
    } catch (e) {
      return [];
    }
  };

  const rowSelection = {
    selectedRowKeys: getDataIdsFromSelectedData(selectedListDataLake),
    onSelect: onSelectRowChange,
    hideSelectAll: true,
  };

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      data?.[0]?.name[0] === "defaultMetaDataSheetId" &&
        form.setFieldsValue({ entityIds: [] });
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
        ...(data?.[0]?.name[0] === "defaultMetaDataSheetId" && {
          entityIds: [],
        }),
      }));
    }
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = selectedStep + 1;
      /*if(stepForSteps === 3 && !isStepTwoCompleted){
        message.error('Some Steps is not completed yet....')
        return
      } */
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onClickUpdateSurvey = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateSurvey(true);
          const { name, description, status } = initialValues;
          const statusText = status ? "ACTIVE" : "INACTIVE";
          const dataIds = [];
          for (const item of sortedSurveyList) {
            if (item.type === "welcome_page") {
              dataIds.push({
                id: item.id || uuidv4(),
                survey_type: "welcome_page",
                body: item.body,
                subject: item.subject,
              });
            } else if (item.type === "standards") {
              dataIds.push({ id: item._id.$oid, survey_type: "standards" });
            } else {
              dataIds.push({
                id: item._id["$oid"],
                survey_type: "data_sheets",
                multiline_form_flag: item.multiline_form_flag,
                hide_fields: item.hide_fields,
                show_notes: item.show_notes,
                instructions: item.instructions,
                mass_upload_status: item.mass_upload_status,
                display_fields: item.display_fields,
                placeholders: item.placeholders,
              });
            }
          }
          const emailData = initialValues?.email_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminderEmailData = initialValues?.email_reminder_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminder_interval_final =
            initialValues.reminder_interval === "recurring_reminder"
              ? initialValues.recurring_reminder_val
              : initialValues.reminder_interval === "op1"
              ? null
              : 1000000;
          const payload = {};

          payload.id = rowData.id;
          payload.survey_type = "mixed";
          payload.status = statusText;
          payload.name = name;
          payload.survey_order = JSON.stringify(dataIds);
          payload.description = description;
          payload.popup_title = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityPopupTitle
            : "";
          payload.info_message = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityInfoMessage
            : "";
          payload.search_field_label = initialValues?.defaultMetaDataSheetId
            ? initialValues?.searchFieldLabel
            : "";
          payload.search_field_label_value =
            initialValues?.defaultMetaDataSheetId
              ? initialValues?.searchFieldValueLabel
              : "";
          /*payload.table_intro_text = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityTableText
            : "";*/
          payload.defaultMetaDataSheetId =
            initialValues?.defaultMetaDataSheetId;
          payload.email_subject = initialValues?.email_subject
            ? initialValues?.email_subject
            : "";
          payload.email_reminder_subject = initialValues?.email_reminder_subject
            ? initialValues?.email_reminder_subject
            : "";
          payload.email_body = emailData;
          payload.email_reminder_body = reminderEmailData;
          payload.reminder_interval = reminder_interval_final
            ? Number(reminder_interval_final)
            : null;
          if (initialValues?.defaultMetaDataSheetId && initialValues.entityIds)
            payload.entityIds = initialValues.entityIds;
          const response = await updateSurveys(payload);
          if (response) {
            setLoadingCreateSurvey(false);
            form.resetFields();
            setStep(step + 1);
          }
        } catch (error) {
          message.error("Error while updating survey.");
          console.log("error while updating survey", error);
        } finally {
          setLoadingCreateSurvey(false);
        }
      })
      .catch((error) => console.log("error form in survey", error));
  };

  const onClickCreateSurvey = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateSurvey(true);
          const { name, description, status } = initialValues;
          const statusText = status ? "ACTIVE" : "INACTIVE";

          const dataIds = [];
          for (const item of sortedSurveyList) {
            if (item.type === "welcome_page") {
              dataIds.push({
                id: uuidv4(),
                survey_type: "welcome_page",
                body: item.body,
                subject: item.subject,
              });
            } else if (item.type === "standards") {
              dataIds.push({ id: item._id.$oid, survey_type: "standards" });
            } else {
              dataIds.push({
                id: item._id["$oid"],
                survey_type: "data_sheets",
                multiline_form_flag: item.multiline_form_flag,
                hide_fields: item.hide_fields,
                show_notes: item.show_notes,
                instructions: item.instructions,
                mass_upload_status: item.mass_upload_status,
                display_fields: item.display_fields,
                placeholders: item.placeholders,
              });
            }
          }
          const emailData = initialValues?.email_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminderEmailData = initialValues?.email_reminder_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminder_interval_final =
            initialValues.reminder_interval === "recurring_reminder"
              ? initialValues.recurring_reminder_val
              : initialValues.reminder_interval === "op1"
              ? null
              : 1000000;

          const payload = {};

          payload.survey_type = "mixed";
          payload.status = statusText;
          payload.name = name;
          payload.survey_order = JSON.stringify(dataIds);
          payload.description = description;
          payload.popup_title = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityPopupTitle
            : "";
          payload.info_message = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityInfoMessage
            : "";
          payload.search_field_label = initialValues?.defaultMetaDataSheetId
            ? initialValues?.searchFieldLabel
            : "";
          payload.search_field_label_value =
            initialValues?.defaultMetaDataSheetId
              ? initialValues?.searchFieldValueLabel
              : "";
          /*payload.table_intro_text = initialValues?.defaultMetaDataSheetId
            ? initialValues?.facilityTableText
            : "";*/
          payload.defaultMetaDataSheetId =
            initialValues?.defaultMetaDataSheetId;
          payload.email_subject = initialValues?.email_subject
            ? initialValues?.email_subject
            : "";
          payload.email_body = emailData;
          payload.email_reminder_subject = initialValues?.email_reminder_subject
            ? initialValues?.email_reminder_subject
            : "";
          payload.email_reminder_body = reminderEmailData;
          payload.reminder_interval = reminder_interval_final
            ? Number(reminder_interval_final)
            : null;
          if (initialValues?.defaultMetaDataSheetId && initialValues.entityIds)
            payload.entityIds = initialValues.entityIds;
          const response = await addSurveys(payload);
          if (response) {
            setLoadingCreateSurvey(false);
            form.resetFields();
            setRowData(response.data.createSurveyManagement);
            setStep(step + 1);
          }
        } catch (error) {
          message.error("Error while creating survey.");
          console.log("error while creating survey", error);
        } finally {
          setLoadingCreateSurvey(false);
        }
      })
      .catch((error) => console.log("error form in survey", error));
  };

  const onSubmitHandler = () => {
    if (action === "update") {
      onClickUpdateSurvey();
    } else {
      onClickCreateSurvey();
    }
  };

  const handleMultilineFormFlag = async (flag, rowdata) => {
    const data = filteredDataSheetsList.map((item) => {
      return {
        ...item,
        multiline_form_flag:
          rowdata._id["$oid"] === item._id["$oid"]
            ? flag
            : item.multiline_form_flag,
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      return {
        ...item,
        multiline_form_flag:
          rowdata._id["$oid"] === item._id["$oid"]
            ? flag
            : item.multiline_form_flag,
      };
    });
    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(data);
  };

  const getDisplayFields = (item, rowdata, flag) => {
    if (!rowdata._id.$oid === item._id.$oid) {
      return item.display_fields;
    }
    if (flag) {
      return item.sheet_schema
        ?.filter(
          (v) =>
            (item.display_fields.find((field) => field === v.entity_name) ||
              !!v.metadata_entity_name?.length) &&
            !v.isExpression &&
            v.input_type !== "expression"
        )
        ?.map((v) => v.entity_name);
    }
    return item.display_fields?.filter((field) =>
      item.sheet_schema
        ?.filter(
          (v) =>
            !v.metadata_entity_name?.length &&
            !v.isExpression &&
            v.input_type !== "expression"
        )
        ?.find((v) => v.entity_name === field)
    );
  };

  const handleHideFields = async (flag, rowdata) => {
    const data = filteredDataSheetsList.map((item) => {
      return {
        ...item,
        hide_fields:
          rowdata._id["$oid"] === item._id["$oid"] ? flag : item.hide_fields,
        display_fields: getDisplayFields(item, rowdata, flag),
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      return {
        ...item,
        hide_fields:
          rowdata._id["$oid"] === item._id["$oid"] ? flag : item.hide_fields,
        display_fields: getDisplayFields(item, rowdata, flag),
      };
    });

    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(data);
  };
  const handleShowNotes = async (flag, rowdata) => {
    const data = filteredDataSheetsList.map((item) => {
      return {
        ...item,
        show_notes:
          rowdata._id["$oid"] === item._id["$oid"] ? flag : item.show_notes,
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      return {
        ...item,
        show_notes:
          rowdata._id["$oid"] === item._id["$oid"] ? flag : item.show_notes,
      };
    });

    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(data);
  };
  const handleMassUploadToggle = async (status, rowdata) => {
    const data = filteredDataSheetsList.map((item) => {
      return {
        ...item,
        mass_upload_status:
          rowdata._id["$oid"] === item._id["$oid"]
            ? status
            : item.mass_upload_status,
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      return {
        ...item,
        mass_upload_status:
          rowdata._id["$oid"] === item._id["$oid"]
            ? status
            : item.mass_upload_status,
      };
    });
    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(data);
  };

  const handleChangeDisplayFields = async (
    fields,
    placeholders,
    rowdata,
    isAppliedToAll
  ) => {
    const data = filteredDataSheetsList.map((item) => {
      const placeholdersFields = {};
      Object.entries(placeholders).forEach(([key, value]) => {
        if (item?.sheet_schema?.find((v) => v?.entity_name === key))
          placeholdersFields[key] = value;
      });
      return {
        ...item,
        placeholders:
          rowdata._id["$oid"] === item._id["$oid"] || isAppliedToAll
            ? placeholdersFields
            : item.placeholders,
        display_fields:
          rowdata._id["$oid"] === item._id["$oid"]
            ? fields
            : item.display_fields,
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      const placeholdersFields = {};
      Object.entries(placeholders).forEach(([key, value]) => {
        if (item?.sheet_schema?.find((v) => v?.entity_name === key))
          placeholdersFields[key] = value;
      });
      return {
        ...item,
        placeholders:
          rowdata._id["$oid"] === item._id["$oid"] || isAppliedToAll
            ? placeholdersFields
            : item.placeholders,
        display_fields:
          rowdata._id["$oid"] === item._id["$oid"]
            ? fields
            : item.display_fields,
      };
    });

    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(data);
  };

  const handleInstructionsForm = (instructions, rowData) => {
    const dataItem = filteredDataSheetsList.map((item) => {
      return {
        ...item,
        instructions:
          rowData._id["$oid"] === item._id["$oid"]
            ? instructions
            : item.instructions,
      };
    });
    const selectedData = selectedListDataSurvey?.map((item) => {
      return {
        ...item,
        instructions:
          rowData._id["$oid"] === item._id["$oid"]
            ? instructions
            : item.instructions,
      };
    });
    setSelectedListDataSurvey(selectedData);
    setFilteredDataSheetsList(dataItem);
    return "success";
  };

  const onSelectDataSheetsChange = (record, selected, selectedRows) => {
    if (selected) {
      const filterNewRecords = selectedRows?.filter((item) => {
        return (
          item &&
          !selectedListDataSurvey.find(
            (bItem) => bItem?._id["$oid"] === item?._id["$oid"]
          )
        );
      });
      const allSelectedRecords = [
        ...selectedListDataSurvey,
        ...filterNewRecords,
      ];
      setSelectedListDataSurvey(allSelectedRecords);
      form.setFieldsValue({
        selectedDataSheets: allSelectedRecords,
      });
    } else {
      const filterNewRecords = selectedRows?.filter(
        (item) =>
          item &&
          !selectedListDataSurvey.find(
            (bItem) => bItem?._id["$oid"] === item?._id["$oid"]
          )
      );
      const allSelectedRecords = [
        ...selectedListDataSurvey,
        ...filterNewRecords,
      ];
      const filteredRecords = allSelectedRecords?.filter(
        (item) => item?._id["$oid"] !== record?._id["$oid"]
      );
      setSelectedListDataSurvey(filteredRecords);
      // save selected rows in state
      form.setFieldsValue({
        selectedDataSheets: filteredRecords,
      });
    }
  };
  const getDataIdsFromSelectedDataSheets = (selectedList) => {
    try {
      const selectedRecordsIds = selectedList.map((item) => {
        return item._id["$oid"];
      });
      const filterdData = filteredDataSheetsList?.filter((item) =>
        selectedRecordsIds.includes(item._id["$oid"])
      );
      return filterdData.map((item) => {
        return item._id["$oid"];
      });
    } catch (e) {
      return [];
    }
  };

  const rowDataSheetSelection = {
    selectedRowKeys: getDataIdsFromSelectedDataSheets(selectedListDataSurvey),
    // onChange: onSelectRowChange,
    onSelect: onSelectDataSheetsChange,
    hideSelectAll: true,
  };
  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
      /*if (step === 3 || step === 4) {
        setDataForSortableTable();
      }*/
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const stepTitles = [
    "General Information",
    "Business Information",
    "Standards Selection",
    "Data Sheets Selection",
    "Data Sheets Setup",
    "Email",
    "Survey Order",
  ];

  const steps = [
    <GeneralInformation
      key="generalInformation"
      onClickNext={onClickNext}
      metaDataSheetList={metaDataSheetList}
      initialValues={initialValues}
      entitiesList={entitiesList}
      entitiesLoading={entitiesLoading}
      isIdentifier={isIdentifier}
    />,
    <BusinessInformation
      key="businessInformation"
      locations={locations}
      businessUnits={businessUnits}
      reportPeriods={reportPeriods}
      locationId={locationId}
      setLocationId={setLocationId}
      businessUnitId={businessUnitId}
      setBusinessUnitId={setBusinessUnitId}
      reportPeriodId={reportPeriodId}
      setReportPeriodId={setReportPeriodId}
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
    />,
    <StandardsSelection
      key="standardsSelection"
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      rowSelection={rowSelection}
      loadingDataLakeMap={loadingDataLakeMap}
      dataLakeMapsList={dataLakeMapsListForTable}
      listAllSurveys={listAllSurveys}
    />,
    <DataSheetsSelection
      key="dataSheetsSelection"
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      rowSelection={rowDataSheetSelection}
      loadDataSheets={loadDataSheets}
      dataSheetsList={filteredDataSheetsList}
    />,
    <DataSheetsSetup
      key="dataSheetsSetup"
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      loadDataSheets={loadDataSheets}
      dataSheetsList={filteredDataSheetsList?.filter((v) =>
        rowDataSheetSelection.selectedRowKeys.find((key) => key === v._id.$oid)
      )}
      handleMultilineFormFlag={handleMultilineFormFlag}
      handleHideFields={handleHideFields}
      handleShowNotes={handleShowNotes}
      handleInstructionsForm={handleInstructionsForm}
      handleMassUploadToggle={handleMassUploadToggle}
      handleChangeDisplayFields={handleChangeDisplayFields}
      initialSurveyValues={initialValues}
    />,
    <EmailTemplate
      form={form}
      key="emailTemplate"
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      setInitialValues={setInitialValues}
      initialValues={initialValues}
    />,
    <SurveyOrder
      key="surveyOrder"
      action={action}
      onSortEnd={onSortEnd}
      onSubmitHandler={onSubmitHandler}
      sortedSurveyList={sortedSurveyList}
      setSortedSurveyList={setSortedSurveyList}
      onClickPrevious={onClickPrevious}
      loadingCreateSurvey={loadingCreateSurvey}
    />,
    <Success key="success" action={action} survey={rowData} step={step} />,
  ];

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                className="margin-5"
                type="custom"
                onClick={() => navigate("/management/collect-data")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                {stepTitles.map((title, index) => (
                  <Step
                    className="stepper-wrapper"
                    key={index + 1}
                    title={title}
                  />
                ))}
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 16.66}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {steps[step - 1]}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default CreateStandardsSurvey;
