import { Button, Collapse } from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import { carbonStore } from "../../../../Carbon/CarbonStore";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { getLinkDataSheet } from "../../../../Carbon/CalculationUtils";
import styles from "./InfoAndNotesDrawer.module.scss";
import {
  additionalQueryOperators,
  comparisonOperators,
  mathOperators,
} from "../../../../../services/mongoOperators";

interface EmissionInfoProps {
  emission: Emission;
  drillDownRecord?: DrillDownRecord;
  onClose: () => void;
}

export const EmissionInfo = observer(
  ({ emission, drillDownRecord, onClose }: EmissionInfoProps) => {
    const activitySheetValue = carbonStore.sheets.find(
      (sheet) =>
        sheet._id.$oid === (emission as AutomatedEmission)?.activity_sheet
    )?.sheet_name;

    const activityFieldValue = carbonStore.sheets
      .find(
        (sheet) =>
          sheet._id.$oid === (emission as AutomatedEmission)?.activity_sheet
      )
      ?.sheet_schema.find(
        (schema) =>
          schema.entity_name === (emission as AutomatedEmission)?.activity_field
      )?.display_name;

    const getAdvancedFiltersName = (column: string) => {
      const name = carbonStore.sheets
        .find(
          (sheet) =>
            sheet._id.$oid === (emission as AutomatedEmission)?.activity_sheet
        )
        ?.sheet_schema.find(
          (schema) => schema.entity_name === column
        )?.display_name;
      return name;
    };

    const getAdvancedFiltersOperator = (op: string) => {
      const operators = [
        ...mathOperators,
        ...comparisonOperators,
        ...additionalQueryOperators,
      ];
      const operator = operators?.find((item) => item.value === op)?.label;
      return operator?.toLowerCase();
    };

    const isCorrectFilters = drillDownRecord?.time_period
      ? drillDownRecord?.filter?.length === 2
      : true;

    return (
      <FormWrapper>
        <Collapse
          className={styles.drawerContent}
          expandIconPosition="end"
          defaultActiveKey={["1"]}
          ghost
        >
          <Collapse.Panel
            className={styles.drawerTitleCollapse}
            header="General Information"
            key="1"
          >
            <ul className={styles.drawerList}>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Custom Name</h4>
                <p className={styles.drawerListItemText}>
                  {emission.custom_name || "---"}
                </p>
              </li>

              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Description</h4>
                <p className={styles.drawerListItemText}>
                  {emission?.factor_data.description || "---"}
                </p>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Source</h4>
                <a
                  className={styles.drawerListItemLink}
                  href={emission.factor_data.source_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {emission.factor_data.source || "---"}
                </a>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Category</h4>
                <div className={styles.drawerListItemText}>
                  {emission.factor_data.category || "---"}
                </div>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Region</h4>
                <div className={styles.drawerListItemText}>
                  {emission?.factor_data?.region || "---"}
                </div>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>Unit</h4>
                <div className={styles.drawerListItemText}>
                  {emission?.factor_data?.unit || "---"}
                </div>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>LCA activity</h4>
                <div className={styles.drawerListItemText}>
                  {(emission?.factor_data as Factor).lca_activity || "---"}
                </div>
              </li>
            </ul>
          </Collapse.Panel>
          <Collapse.Panel
            className={styles.drawerTitleCollapse}
            header="Emission Factor"
            key="2"
          >
            <ul className={styles.drawerList}>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>CO2e</h4>
                <div className={styles.drawerListItemText}>
                  {emission.factor_data.factor || "---"}
                </div>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>
                  CO2e calculation method applied
                </h4>
                <div className={styles.drawerListItemText}>
                  {emission?.factor_data.factor_calculation_method || "---"}
                </div>
              </li>
              <li className={styles.drawerListItem}>
                <h4 className={styles.drawerListItemTitle}>
                  CO2e calculation origin
                </h4>
                <div className={styles.drawerListItemText}>
                  {(emission?.factor_data as Factor)
                    .factor_calculation_origin || "Custom"}
                </div>
              </li>
            </ul>
          </Collapse.Panel>
          <Collapse.Panel
            className={styles.drawerTitleCollapse}
            header="Calculation details"
            key="3"
          >
            <div>
              <ul className={styles.drawerList}>
                <li className={styles.drawerListItem}>
                  <h4 className={styles.drawerListItemTitle}>
                    Calculation Model
                  </h4>
                  <div className={styles.drawerListItemText}>
                    {emission?.manual ? "Manual" : "Automated"}
                  </div>
                </li>
                {!emission.manual && (
                  <>
                    <li className={styles.drawerListItem}>
                      <h4 className={styles.drawerListItemTitle}>
                        Data Sheet Name
                      </h4>
                      <div className={styles.drawerListItemText}>
                        {activitySheetValue}
                      </div>
                    </li>
                    <li className={styles.drawerListItem}>
                      <h4 className={styles.drawerListItemTitle}>
                        Aggregation Column
                      </h4>
                      <div className={styles.drawerListItemText}>
                        {activityFieldValue}
                      </div>
                    </li>
                    <li className={styles.drawerListItem}>
                      <h4 className={styles.drawerListItemTitle}>
                        Advanced Filters
                      </h4>
                      <div className={styles.drawerListItemText}>
                        {(emission as AutomatedEmission)?.filters ||
                        drillDownRecord
                          ? [
                              ...((emission as AutomatedEmission)?.filters ||
                                []),
                              ...(drillDownRecord?.filter || []),
                            ].map((v) => {
                              v.column_value === false && v.op === "isEmpty";
                              return (
                                <div key={v.column + v.column_value + v.op}>
                                  {getAdvancedFiltersName(v.column)}
                                  <b>
                                    {" "}
                                    {getAdvancedFiltersOperator(
                                      v.column_value === false &&
                                        v.op === "isEmpty"
                                        ? "isNotEmpty"
                                        : v.op
                                    )}{" "}
                                  </b>
                                  {v.column_value}
                                </div>
                              );
                            })
                          : "---"}
                      </div>
                    </li>
                  </>
                )}
                <li className={styles.drawerListItem}>
                  <h4 className={styles.drawerListItemTitle}>
                    Consumption Amount
                  </h4>
                  <div className={styles.drawerListItemText}>
                    {drillDownRecord
                      ? drillDownRecord.sum
                      : emission.consumption_amount}{" "}
                    {emission?.factor_data?.unit?.split("/").at(-1)}
                  </div>
                </li>

                {emission?.manual ? (
                  <li className={styles.drawerListItem}>
                    <h4 className={styles.drawerListItemTitle}>
                      Assigned Entity
                    </h4>
                    <div className={styles.drawerListItemText}>
                      {emission?.metadata_drilldown &&
                      emission?.metadata_drilldown[0].group
                        ? emission?.metadata_drilldown[0].group
                        : "No Entity Assigned"}
                    </div>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </Collapse.Panel>
        </Collapse>

        <div className={styles.drawerFooter}>
          <StyledButton onClick={onClose} type="primary">
            Close
          </StyledButton>
          {!emission?.manual && (
            <Button
              disabled={!isCorrectFilters}
              className={styles.drawerFooterLink}
              type="text"
            >
              <Link
                to={
                  getLinkDataSheet(
                    emission as AutomatedEmission,
                    carbonStore.getSheetById(
                      (emission as AutomatedEmission)?.activity_sheet
                    )
                  ).url
                }
                onClick={() => {
                  localStorage.setItem(
                    "filters",
                    JSON.stringify(
                      getLinkDataSheet(
                        emission as AutomatedEmission,
                        carbonStore.getSheetById(
                          (emission as AutomatedEmission)?.activity_sheet
                        ),
                        drillDownRecord
                      ).filtersArray
                    )
                  );
                  localStorage.setItem(
                    "identifiersValues",
                    JSON.stringify(calculatorStore.selectedIdentifiers)
                  );
                }}
                target="_blank"
              >
                View Data Sheet
              </Link>
            </Button>
          )}
        </div>
      </FormWrapper>
    );
  }
);
