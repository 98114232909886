import Styled from "styled-components";
import { siteThemeColors } from "../../../shared/colors";

export const StyledTab = Styled.div`
  display: inline-block;
  cursor: pointer;
  background-color: ${(props) => 
    (props.active 
      ? '#7F5Fee' 
      : siteThemeColors.commonColor.bgColor)};
  color: ${(props) => 
    (props.active 
      ? siteThemeColors.FontColor.primary 
      : siteThemeColors.FontColor.secondary)};
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px 8px 16px;
  border-radius: 2px 0px 0px 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  :hover {
    color: ${siteThemeColors.FontColor.primary };
  }
`;
