import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Collapse,
  Dropdown,
  Form,
  Row,
  Space,
  Spin,
  Typography,
  message,
  Popconfirm,
  Popover,
} from "antd";
import {
  CommonTable,
  FormWrapper,
  PageWrap,
  StyledButton,
  TableCommonMenu,
  TableHeaderRightButtonWrap,
  TableWrap,
} from "../../../shared/commonStyles";
import {
  CheckOutlined,
  CheckSquareOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import {
  getDataSheetsWithPagination,
  getMessageType,
} from "../../../services/dataSheet";
import { Auth } from "aws-amplify";

import { useBoolean } from "ahooks";

import { DenyReasonModal } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/DenyReasonModal";

import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import styles from "./pendingKpi.module.scss";
import { KpiFilters } from "./KpiFilters";
import KpiFilesModal from "./KpiFilesModal";
import { NotesDrawer } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/NotesDrawer";
import moment from "moment";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import { getSurveys } from "../../../services/surveys";
import { getUsersList } from "../../../services/users";
import { ColumnType } from "antd/lib/table";
import SurveyKpiService from "../../../services/SurveyKpiService";
import { uploadFile } from "../../../services/standards";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { SortDropdown } from "../../../components/SortDropdown";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";

interface DatasheetWithKpi extends DataSheet {
  key: string;
  kpis: SurveyKPI[];
}
interface KpiFormFilters {
  filters: AdvancedDataFilter[];
  operatorType?: string;
}
interface KpiColumn extends ColumnType<SurveyKPI> {
  hide?: boolean;
  name?: string;
  label?: string;
  inputType?: string;
  value?: string;
}
export const PendingKPIs = () => {
  const [dataSheetsList, setDataSheetsList] = useState<DatasheetWithKpi[]>([]);
  const [confirmApproveModal, setConfirmApproveModal] =
    useState<boolean>(false);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);
  const [collapsedPanel, setCollapsedPanel] = useState<string | undefined>();
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
  const [mainLoading, setMainLoading] = useState<boolean>(false);
  const [kpisLoading, setKpisLoading] = useState<boolean>(false);
  const [isManageColumnsVisible, setManageColumnsVisible] =
    useState<boolean>(false);

  const [surveys, setSurveys] = useState<SurveyInfo[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedRows, setSelectedRows] = useState<SurveyKPI[]>([]);
  const [
    isFieldNotesDrawerShown,
    { setTrue: showFieldNotesDrawer, setFalse: hideFieldNotesDrawer },
  ] = useBoolean(false);
  const [filters, setFilters] = useState<KpiFormFilters>({} as KpiFormFilters);
  const [sortRecords, setSortRecords] = useState<SortRecords>({
    type: "key",
    sortAlphabetically: true,
  });
  const [currentRecord, setCurrentRecord] = useState<SurveyKPI>(
    {} as SurveyKPI
  );
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });
  const [manageColumnData, setManageColumnData] = useState<KpiColumn[]>([
    {
      dataIndex: "surveyName",
      key: "surveyName",
      hide: true,
      name: "Survey Name",
      title: "Survey Name",
      label: "Survey Name",
      inputType: "select",
      value: "surveyId",
    },
    {
      dataIndex: "userName",
      key: "userName",
      hide: true,
      name: "User Name",
      title: "User Name",
      label: "User Name",
      inputType: "select",
      value: "username",
    },
    {
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      hide: true,
      name: "Review Status",
      title: "Review Status",
      label: "Review Status",
      inputType: "select",
      value: "reviewStatus",
    },
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    loadDataSheets();
  }, []);

  useEffect(() => {
    setManageColumnData((prev) => prev.map((v) => ({ ...v, hide: true })));
  }, [collapsedPanel]);

  const loadDataSheets = async () => {
    setMainLoading(true);
    try {
      const data = await Auth.currentSession();
      const accessToken = data.getAccessToken();
      const groups = accessToken.payload["cognito:groups"] || [];
      const group = groups?.filter((element: any) =>
        element.includes("org:")
      )[0];
      const sheetRecords = await getDataSheetsWithPagination({
        filter: {
          archive: false,
          group: group,
          is_reference_data: false,
        },
        withKPI: true,
      });
      setDataSheetsList(sheetRecords.data);
      const surveys = await getSurveys();
      const users = await getUsersList();
      setSurveys(surveys);
      setUsers(users);
    } catch (error) {
      console.error("error", error);
      message.error("Something went wrong while loading data sheets!");
    } finally {
      setMainLoading(false);
    }
  };

  useEffect(() => {
    if (
      dataSheetsList?.length &&
      !collapsedPanel &&
      localStorage.getItem("pendingKpiImport") &&
      JSON.parse(localStorage.getItem("pendingKpiImport") as any)?.dataSheetId
    ) {
      handleCollapse(
        JSON.parse(localStorage.getItem("pendingKpiImport") as any)?.dataSheetId
      );
    }
  }, [dataSheetsList]);
  const listKpis = async (dataSheetId?: string, params?: any) => {
    setKpisLoading(true);
    try {
      if (dataSheetId) {
        const payload = makeQuery(dataSheetId, { ...params });
        const kpiForDatasheets = await SurveyKpiService.surveyReviewKpisList(
          payload
        );
        if (kpiForDatasheets) {
          const surveyIds = kpiForDatasheets?.data?.length
            ? [
                ...new Set([
                  ...kpiForDatasheets?.data?.map((v: SurveyKPI) => v.surveyId),
                ]),
              ]
            : [];
          const payload = {
            filter: {
              or: surveyIds?.map((id: string) => ({
                id: { eq: id },
              })),
            },
          };
          const surveys = surveyIds?.length ? await getSurveys(payload) : [];
          const kpiArray = kpiForDatasheets?.data?.map((v: SurveyKPI) => {
            const userName = users?.find(
              (user) => user.key == v.username
            )?.name;
            const surveyName = surveys?.find(

              (survey: any) => survey.id == v.surveyId
            )?.name;
            return { ...v, userName, surveyName };
          });
          const datasheetsWithKpi = dataSheetsList.map((item) => {
            if (item._id.$oid === dataSheetId) {
              return { ...item, kpis: kpiArray };
            }

            return item;
          });
          setDataSheetsList(datasheetsWithKpi);
          setPagination({
            ...(params?.pagination || pagination),
            total: kpiForDatasheets?.filteredCount,
          });

          if (
            localStorage.getItem("pendingKpiImport") &&
            JSON.parse(localStorage.getItem("pendingKpiImport") as any)
              ?.actionType === "data_imported" &&
            kpiArray?.length
          ) {
            const currentKpi = kpiArray?.find(
              (v: SurveyKPI) =>
                v._id?.$oid ===
                JSON.parse(localStorage.getItem("pendingKpiImport") as any)
                  ?.kpiId
            );
            currentKpi && setCurrentRecord(currentKpi);
            currentKpi && setShowFilesModal(true);
            localStorage.removeItem("pendingKpiImport");
          }
        }
      }
    } catch (error) {
      console.error("error", error);
      message.error("Something went wrong while loading kpis!");
    } finally {
      setKpisLoading(false);
    }
  };

  const makeQuery = (dataSheetId?: string, params?: any) => {
    const { sorter, filters, operatorType, pagination } = params;
    const newFilters = filters?.length
      ? filters?.map(
          (item: any) =>
            (item.op === "isEmpty" && { ...item, column_value: true }) ||
            (item.op === "isNotEmpty" && {
              ...item,
              op: "isEmpty",
              column_value: false,
            }) ||
            item
        )
      : [];
    const sortingResult: any = {};
    if (sorter && sorter.order) {
      sortingResult[sorter.field === "key" ? "_id" : sorter.field] =
        sorter.order === "ascend" ? 1 : -1;
    }
    const payload = {
      skip: pagination ? (pagination.current - 1) * pagination.pageSize : 0,
      limit: pagination ? pagination.pageSize : 10,
      filter: {
        reviewStatus: RecordStatus.PENDING,
        isArchived: { $ne: true },
      },
      filters_list: newFilters || [],
      operator_type: operatorType || "$and",
      sort: sortingResult,
      sheet_id: dataSheetId,
    };
    return payload;
  };

  const onSort = async () => {
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    await listKpis(collapsedPanel, {
      filters: filters.filters,
      operatorType: filters.operatorType,
      sorter: newSorter,
      pagination,
    });
  };

  const onClickDeleteRecord = (record: SurveyKPI) => {
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    SurveyKpiService.updateSurveyKpi([{ ...record, isArchived: true }]).then(
      (v) =>
        listKpis(collapsedPanel, {
          filters: filters.filters,
          operatorType: filters.operatorType,
          sorter: newSorter,
          pagination,
        })
    );
  };

  const onClickDenyRecord = async (
    record: any,
    status: RecordStatus,
    reason?: string,
    files?: any
  ) => {
    try {
      const reasonKey = uuidv4();
      delete record.reviewStatus;
      if (!!files?.length) {
        for (const file of files) {
          await uploadSingleKpisSheetFile(file, reasonKey);
        }
      }
      const newStatusReason = {
        review_status: status,
        key: reasonKey,
        comments: reason,
        ...(!!files?.length && { files: files?.map((item: any) => item.name) }),
        timestamp: moment().format("YYYY-MM-DD HH:mm").toLocaleString(),
        user_full_name: userInfoStore.userName,
        username: userInfoStore.userID,
      };

      const payload = {
        _id: record?._id,
        reviewStatus: status,
        isAdminReview: true,
        statusReasons: [...(record?.statusReasons || []), newStatusReason],
      };
      try {
        await SurveyKpiService.updateSurveyKpi([payload]).then(async () => {
          await SurveyKpiService.sendUserDenyKPIReminder(record?._id?.$oid);
        });
      } catch (e) {
        message.error("Error while sending the notification!");
      }
    } catch (error) {
      message.error("Error while updating record!");
    } finally {
      const newSorter: any = {
        order: sortRecords.sortAlphabetically ? "ascend" : "descend",
        field: sortRecords.type,
      };
      await listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter: newSorter,
        pagination,
      });
    }
  };

  const handleApproveRecord = async (record: SurveyKPI) => {
    try {
      await SurveyKpiService.approveSurveyKpi(record?._id?.$oid).then(
        async () => {
          await SurveyKpiService.sendUserApproveKPIReminder(record?._id?.$oid);
        }
      );
    } catch (e) {
      message.error("Error while updating record!");
    } finally {
      const newSorter: any = {
        order: sortRecords.sortAlphabetically ? "ascend" : "descend",
        field: sortRecords.type,
      };
      await listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter: newSorter,
        pagination,
      });
    }
  };
  const uploadSingleKpisSheetFile = async (file: any, reasonKey: string) => {
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      const signedURL = await uploadFile(reasonKey, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    }
  };
  const onResetFilters = async () => {
    setFilters({} as KpiFormFilters);
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    await listKpis(collapsedPanel, {
      filters: [],
      sorter: newSorter,
      pagination,
    });
  };
  const onApplyFilters = async (data: KpiFormFilters) => {
    setFilters(data);
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    await listKpis(collapsedPanel, {
      filters: data.filters,
      operatorType: data.operatorType,
      sorter: newSorter,
      pagination,
    });
  };
  const onChangeFiles = (kpi: SurveyKPI, files: KPIFile[]) => {
    const newKpi = {
      _id: kpi?._id,
      files,
    };
    SurveyKpiService.updateSurveyKpi([newKpi]).then(() => {
      const newSorter: any = {
        order: sortRecords.sortAlphabetically ? "ascend" : "descend",
        field: sortRecords.type,
      };
      listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter: newSorter,
        pagination,
      });
    });
  };
  const onCheckedItem = (value: CheckboxChangeEvent) => {
    setManageColumnData((prev) =>
      prev.map((item) =>
        item.dataIndex === value.target.value
          ? { ...item, hide: !value.target.checked }
          : item
      )
    );
  };
  const handleCollapse = async (key: string | string[]) => {
    setCollapsedPanel(key as string);
    const dataSheetId = dataSheetsList?.find(
      (item) => item?._id?.$oid === (key as string)
    )?._id.$oid;
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    await listKpis(dataSheetId, {
      filters: filters.filters,
      operatorType: filters.operatorType,
      sorter: newSorter,
      pagination,
    });
  };

  const handleChangeNotes = (notes: Note[]) => {
    const newItem = { ...currentRecord, notes: notes };
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    SurveyKpiService.updateSurveyKpi([newItem]).then(() => {
      listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter: newSorter,
        pagination,
      });
      setCurrentRecord(newItem);
    });
  };
  const handleAddNote = (text: string, record: SurveyKPI) => {
    const formattedNote = {
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: text,
      isAddedByAdmin: true,
    } as FieldNote;
    const kpiNotes = record?.notes?.length ? record.notes : [];
    const newItem = { ...record, notes: [...kpiNotes, formattedNote] };
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    SurveyKpiService.updateSurveyKpi([newItem]).then(() => {
      listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter: newSorter,
        pagination,
      });
      setCurrentRecord(newItem);
    });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.map((v) => v?._id?.$oid),
    hideSelectAll: true,
    onChange: (_selectedRowKeys: string, selectedItems: Array<any>) => {
      setSelectedRows(selectedItems);
    },
  };

  const getStatusAndMessageFile = (files: KPIFile[]) => {
    const warningText = "Some files were not mapped successfully";
    const successText = "Files were mapped successfully";
    const isFailedFiles = files.some(
      (file) => file.automapping_status === AutomappingStatus.FAILED
    );
    const notMappingFiles = !files?.find((v) => v.importId);

    if (isFailedFiles) {
      return { text: warningText, status: "warning" };
    } else if (notMappingFiles) {
      return { text: "No files for mapping", status: "default" };
    }
    return { text: successText, status: "success" };
  };

  const columns: KpiColumn[] = [
    {
      title: "KPI name",
      key: "mainKPIName",
      name: "KPI name",
      dataIndex: "mainKPIName",
      hide: false,
      label: "KPI name",
      inputType: "string",
      value: "mainKPIName",
      sorter: true,
      render: (_: any, record: SurveyKPI) => {
        return <>{record?.mainKPIName ?? ""}</>;
      },
    },
    {
      title: "Files",
      key: "files",
      name: "files",
      dataIndex: "files",
      hide: false,
      render: (files: KPIFile[], record: SurveyKPI) => {
        return (
          <>
            {files?.length ? (
              <Popover
                overlayClassName="popoverContent"
                title={<>{getStatusAndMessageFile(files).text}</>}
              >
                <Badge
                  offset={[-5, 5]}
                  dot
                  status={
                    getStatusAndMessageFile(files)
                      .status as PresetStatusColorType
                  }
                >
                  <StyledButton
                    type="default"
                    onClick={() => {
                      setShowFilesModal(true);
                      setCurrentRecord(record);
                    }}
                  >
                    Files
                  </StyledButton>
                </Badge>
              </Popover>
            ) : (
              <StyledButton
                type="default"
                onClick={() => {
                  setShowFilesModal(true);
                  setCurrentRecord(record);
                }}
              >
                Files
              </StyledButton>
            )}
          </>
        );
      },
    },
    {
      title: "Entered value",
      key: "enteredValue",
      name: "Entered value",
      dataIndex: "enteredValue",
      hide: false,
      label: "Entered value",
      inputType: "number",
      sorter: true,
      value: "enteredValue",
      render: (_: any, record: SurveyKPI) => {
        return <>{record?.enteredValue ?? ""}</>;
      },
    },

    {
      title: "System value",
      key: "mainKPIResult",
      name: "System value",
      dataIndex: "mainKPIResult",
      className: "light",
      hide: false,
      label: "System value",
      sorter: true,
      inputType: "number",
      value: "mainKPIResult",
      render: (_: any, record: SurveyKPI) => {
        return <>{record?.mainKPIResult}</>;
      },
    },
    ...manageColumnData,
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      width: "50px",
      hide: false,
      render: (_: any, record: SurveyKPI) => {
        return (
          <>
            <Space direction="horizontal">
              <>
                <StyledButton
                  type="default"
                  onClick={() => {
                    showFieldNotesDrawer();
                    setCurrentRecord(record);
                  }}
                >
                  Notes
                </StyledButton>
                <StyledButton
                  type="default"
                  onClick={() => handleApproveRecord(record)}
                >
                  Approve
                </StyledButton>
                <DenyReasonModal
                  onConfirm={(reason, files) =>
                    onClickDenyRecord(
                      record,
                      RecordStatus.DENIED,
                      reason,
                      files
                    )
                  }
                  button={{
                    icon: <></>,
                    text: "Deny",
                    type: "default",
                  }}
                />
              </>
              <Popconfirm
                title="Are you sure to delete this record?"
                onConfirm={() => onClickDeleteRecord(record)}
                overlayClassName="popoverContent"
              >
                <StyledButton type="default">
                  <span>Delete</span>
                </StyledButton>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const handleTableChange = async (
    pagination: Pagination,
    _filters: any,
    sorter: any
  ) => {
    const newSorter: any = {
      sortAlphabetically: sorter?.order === "ascend",
      type: sorter?.field,
    };
    setSortRecords(newSorter);
    await listKpis(collapsedPanel, {
      filters: filters.filters,
      operatorType: filters.operatorType,
      sorter: newSorter,
      pagination,
    });
  };

  const handleConfirmApprove = async () => {
    const filter = selectedRows?.length
      ? { _id: { $in: selectedRows?.map((v) => v._id.$oid) } }
      : {
          reviewStatus: RecordStatus.PENDING,
          isArchived: { $ne: true },
        };
    const sorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    const newFilters = filters?.filters?.length
      ? filters?.filters?.map(
          (item: any) =>
            (item.op === "isEmpty" && { ...item, column_value: true }) ||
            (item.op === "isNotEmpty" && {
              ...item,
              op: "isEmpty",
              column_value: false,
            }) ||
            item
        )
      : [];
    const sortingResult: any = {};
    if (sorter && sorter.order) {
      sortingResult[sorter.field === "key" ? "_id" : sorter.field] =
        sorter.order === "ascend" ? 1 : -1;
    }
    const payload = {
      filter: filter,
      filters_list: newFilters || [],
      operator_type: filters?.operatorType || "$and",
      sort: sortingResult,
      sheet_id: collapsedPanel,
    };
    SurveyKpiService.massApproveSurveyKpi(payload).then((result) => {
      if (result.statueCode >= 400) {
        message.error(result.body);
      }
      if (result?.message) {
        getMessageType(result?.messageType, result?.message);
      }

      listKpis(collapsedPanel, {
        filters: filters.filters,
        operatorType: filters.operatorType,
        sorter,
        pagination,
      });
    });
    setConfirmApproveModal(false);
    setSelectedRows([]);
  };

  const filteredColumns = columns.filter((v) => !v?.hide);
  return (
    <PageWrap>
      <NotesDrawer
        visible={isFieldNotesDrawerShown}
        data={[]}
        notes={currentRecord?.notes || []}
        loading={false}
        onClose={() => {
          hideFieldNotesDrawer();
        }}
        onSubmit={(text: string) => handleAddNote(text, currentRecord)}
        onUpdateNotes={handleChangeNotes}
        isFieldNote={false}
      />
      {showFilesModal && (
        <KpiFilesModal
          visible={showFilesModal}
          rowData={currentRecord}
          onClose={() => setShowFilesModal(false)}
          onChangeFiles={(files: KPIFile[]) =>
            onChangeFiles(currentRecord, files)
          }
        />
      )}
      {showFiltersModal && (
        <KpiFilters
          columns={columns.filter((v) => v.label)}
          visible={showFiltersModal}
          onClose={() => setShowFiltersModal(false)}
          onFinish={onApplyFilters}
          onReset={() => {
            onResetFilters();
            setShowFiltersModal(false);
          }}
          initialFilters={filters}
          dataSheet={dataSheetsList?.find(
            (v) => v?._id?.$oid === collapsedPanel
          )}
        />
      )}
      {confirmApproveModal && (
        <ConfirmModal
          title={"Confirmation"}
          visible={confirmApproveModal}
          okText={"Approve"}
          onConfirm={handleConfirmApprove}
          onClose={() => setConfirmApproveModal(false)}
          ConfirmLoader={false}
          description={`Are you sure you want to approve all${
            selectedRows?.length ? " selected" : ""
          } records? This is irreversible`}
        />
      )}
      <Form form={form} component={false}>
        <Spin spinning={mainLoading}>
          <FormWrapper>
            <TableWrap className="dataSheet">
              <Collapse
                onChange={handleCollapse}
                activeKey={collapsedPanel}
                accordion
                expandIcon={({ isActive }) =>
                  isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
                bordered={false}
                collapsible="header"
                ghost
              >
                {dataSheetsList?.map((item: DatasheetWithKpi) => {
                  return (
                    <>
                      <Collapse.Panel
                        key={item._id.$oid}
                        header={
                          <div className={styles.title}>
                            <Typography.Title
                              className={styles.titleDescription}
                              level={4}
                            >
                              {item?.sheet_name}
                            </Typography.Title>
                          </div>
                        }
                      >
                        {collapsedPanel === item?._id.$oid && (
                          <Spin spinning={kpisLoading}>
                            <CommonTable
                              title={() => (
                                <Row justify="space-between">
                                  <TableHeaderRightButtonWrap>
                                    <Button
                                      className="sortButton"
                                      type="text"
                                      onClick={() =>
                                        setConfirmApproveModal(true)
                                      }
                                    >
                                      <CheckOutlined /> Approve{" "}
                                      {selectedRows?.length || "all"} records
                                    </Button>
                                    <Button
                                      type="text"
                                      className={`sortButton ${styles.sort}`}
                                    >
                                      <Dropdown
                                        overlay={
                                          <SortDropdown
                                            sorting={sortRecords}
                                            changeSorting={setSortRecords}
                                            options={columns?.filter(
                                              (v) =>
                                                v.value &&
                                                v.value !== "username" &&
                                                v.value !== "surveyId"
                                            )}
                                            onConfirm={() => {
                                              onSort();
                                              hideDropdown();
                                            }}
                                          />
                                        }
                                        trigger={["click"]}
                                        onVisibleChange={toggle}
                                        visible={isDropdownShown}
                                        placement="bottomRight"
                                      >
                                        <a onClick={(e) => e.preventDefault()}>
                                          <SwapOutlined rotate={90} />
                                          <span className={styles.sortSpan}>
                                            Sort
                                          </span>
                                        </a>
                                      </Dropdown>
                                    </Button>
                                    <Button
                                      className={`sortButton ${styles.filter}`}
                                      type="text"
                                      icon={<FilterOutlined />}
                                      onClick={() => setShowFiltersModal(true)}
                                    >
                                      <span className="expandText">
                                        Filters
                                      </span>{" "}
                                      <Badge
                                        count={filters?.filters?.length}
                                        offset={[7, -5]}
                                        style={{
                                          backgroundColor: "#A68DFB",
                                        }}
                                        size="small"
                                      />
                                    </Button>
                                    <Dropdown
                                      placement="bottomRight"
                                      overlay={
                                        <FormWrapper
                                          className={styles.sortableWrap}
                                        >
                                          <Row>
                                            <StyledButton
                                              type="custom"
                                              style={{
                                                width: "100%",
                                                height: "30px",
                                                lineHeight: "10px",
                                              }}
                                              onClick={() =>
                                                setManageColumnsVisible(false)
                                              }
                                            >
                                              Close
                                            </StyledButton>
                                          </Row>

                                          <ul
                                            className={styles.sortableList}
                                            style={{ zIndex: 10 }}
                                          >
                                            {manageColumnData.map((value) => (
                                              <li
                                                key={value.name}
                                                className={styles.sortableItem}
                                                tabIndex={0}
                                              >
                                                <TableCommonMenu>
                                                  <StyledButton
                                                    type="custom"
                                                    className={
                                                      styles.sortableButton
                                                    }
                                                  >
                                                    <Checkbox
                                                      value={value.dataIndex}
                                                      onChange={onCheckedItem}
                                                      className={`rowCheckColor ${styles.checkbox}`}
                                                      checked={!value.hide}
                                                    >
                                                      {value.name}
                                                    </Checkbox>
                                                  </StyledButton>
                                                </TableCommonMenu>
                                              </li>
                                            ))}
                                          </ul>
                                        </FormWrapper>
                                      }
                                      visible={isManageColumnsVisible}
                                    >
                                      <Button
                                        type="text"
                                        className="sortButton"
                                        icon={<CheckSquareOutlined />}
                                        onClick={() =>
                                          setManageColumnsVisible(
                                            (prev) => !prev
                                          )
                                        }
                                      >
                                        Manage columns
                                      </Button>
                                    </Dropdown>
                                  </TableHeaderRightButtonWrap>
                                </Row>
                              )}
                              rowKey={(record: SurveyKPI) => record?._id?.$oid}
                              rowSelection={rowSelection}
                              loading={mainLoading}
                              dataSource={item.kpis}
                              columns={filteredColumns}
                              rowClassName={(record: SurveyKPI) =>
                                "actionsRightBorderRow"
                              }
                              scroll={{ x: "max-content" }}
                              pagination={pagination}
                              onChange={handleTableChange}
                              className="tableContiner"
                            />
                          </Spin>
                        )}
                      </Collapse.Panel>
                    </>
                  );
                })}
              </Collapse>
            </TableWrap>
          </FormWrapper>
        </Spin>
      </Form>
    </PageWrap>
  );
};
