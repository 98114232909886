
import React from "react";
import { Drawer, Row, Space, Col } from "antd";
import { StyledButton, Heading2, Heading6, BodyText, StyledDivider } from "../../../shared/commonStyles";

import './IndicatorDetailDrawer.css';

const KpiDetailCard = ({ data, visible, onClose }) => {
  const acceptanceCriteriaList = data?.acceptable_criteria?.split("\n\n") || [];
  const notAcceptanceCriteriaList = data?.not_acceptable_criteria?.split("\n\n") || [];

  return (
    <>       
      <Drawer
        className="drawerCustom"
        title="Indicator details"
        width={960}
        onClose={onClose}
        visible={visible}
        placement="right"
        footerStyle={{ background: "#2D273F", borderTop: "none" }}
        footer={
          <Space>
            <StyledButton
              className="margin-5"
              type="custom"
              hovercolor="#A68DFB"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#fff"
              onClick={onClose}
            >
              Close
            </StyledButton>
            <StyledButton
              type="custom"
              className="color-white"
              onClick={onClose}
            >
              Create Goal
            </StyledButton>
          </Space>
        }
      > 
        {data &&
          <Space direction="vertical" size={16} style={{ width: '100%' }}> 
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <Heading2 nogutterbottom>{data.indicator_name}</Heading2>
              <Row gutter={16}>
                <Col span={6}>
                  <BodyText faded size="sm" nogutterbottom>Subpillar</BodyText>
                  <BodyText size="sm" nogutterbottom>{data.sub_pillar}</BodyText>
                </Col>
                <Col span={6}>
                  <BodyText faded size="sm" nogutterbottom>Sectors</BodyText>
                  <BodyText size="sm" nogutterbottom>{data.sectors}</BodyText>
                </Col>
                <Col span={6}>
                  <BodyText faded size="sm" nogutterbottom>Applicability</BodyText>
                  <BodyText size="sm" nogutterbottom>{data.applicability}</BodyText>
                </Col>
                <Col span={6}>
                  <BodyText faded size="sm" nogutterbottom>SASB GIC Categories</BodyText>
                  <BodyText size="sm" nogutterbottom>{data.sasb_gic_categories}</BodyText>
                </Col>
              </Row>
            </Space>

            <StyledDivider margin="0px" />
            
            <div>
              <Heading6>Description</Heading6>
              <BodyText size="sm" nogutterbottom>{data.description}</BodyText>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <Heading6>What is acceptable?</Heading6>
                <ul className='unordered-list'>
                  {acceptanceCriteriaList.map(item => 
                    <li key={item}>{item}</li>
                  )}
                </ul>
              </Col>
              <Col span={12}>
                <Heading6>What is NOT acceptable?</Heading6>
                <ul className='unordered-list'>
                  {notAcceptanceCriteriaList.map(item => 
                    <li key={item}>{item}</li>
                  )}
                </ul>
              </Col>
            </Row>

            <StyledDivider margin="0px" />
            
            <Row gutter={16}>
              <Col span={12}>
                <BodyText faded size="sm" nogutterbottom className="label">UNGC Principle Mapping</BodyText>
              </Col>
              <Col span={12}>
                <BodyText size="sm" nogutterbottom>{data.ungc_principle_mapping}</BodyText>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <BodyText faded size="sm" nogutterbottom className="label">GRI Disclosure Number</BodyText>
              </Col>
              <Col span={12}>
                <BodyText size="sm" nogutterbottom>{data.gri_disclosure_number}</BodyText>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <BodyText faded size="sm" nogutterbottom className="label">GRI Disclosure Title</BodyText>
              </Col>
              <Col span={12}>
                <BodyText size="sm" nogutterbottom>{data.gri_disclosure_title}</BodyText>
              </Col>
            </Row>

            <StyledDivider margin="0px" />

            <Row gutter={16}>
              <Col span={12}>
              <BodyText faded size="sm">Keywords</BodyText>
                <BodyText size="sm" nogutterbottom>
                  {data.keywords}
                </BodyText>
              </Col>
              <Col span={12}>
              <BodyText faded size="sm">Sources</BodyText>
                <BodyText size="sm" nogutterbottom>
                  {data.sources}
                </BodyText>
              </Col>
            </Row>

            <StyledDivider margin="0px" />
          </Space>
        }
      </Drawer>
    </>
  );
};

export default KpiDetailCard;
