import { CaretDownOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Collapse, Drawer, Row, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { StyledButton, StyledTimeline } from "../../../../shared/commonStyles";
import styles from "./TableRowBtns.module.scss";

interface CalculatorEntryHistoryDrawerProps {
  data: Emission | Offset;
  isVisible: boolean;
  hideDrawer: () => void;
  onView: (data: CalculatorEntryHistory) => void;
}

export const CalculatorEntryHistoryDrawer = ({
  data,
  isVisible,
  hideDrawer,
  onView,
}: CalculatorEntryHistoryDrawerProps) => {
  const [history, setHistory] = useState<any[]>();
  useEffect(() => {
    const newData = data.history?.length ? [...data.history] : [];
    setHistory([...newData]);
  }, [data]);

  return (
    <Drawer
      width={400}
      closable={false}
      placement="right"
      onClose={hideDrawer}
      visible={isVisible}
      className={styles.drawer}
    >
      <div className={styles.drawerContent}>
        <div className={styles.drawerTitle}>History</div>
        <div className={styles.drawerSubtitle}>
          {(data as Emission)?.factor_data?.name} -{" "}
          {(data as Emission)?.factor_data?.region}
        </div>
        <StyledTimeline>
          {history
            ?.sort((a, b) => b.version - a.version)
            ?.map((item, index) => {
              return (
                <Timeline.Item key={item.version}>
                  <Collapse
                    ghost
                    expandIcon={({ isActive }) => (
                      <CaretDownOutlined rotate={isActive ? 180 : 0} />
                    )}
                    expandIconPosition="end"
                  >
                    <Collapse.Panel
                      header={
                        <span className={styles.drawerItemTitle}>
                          Version {item.version}: {item.timestamp.$date}
                        </span>
                      }
                      key={item.version}
                      className={styles.drawerItem}
                    >
                      <Row className={styles.drawerItemContent}>
                        {item.event}
                      </Row>
                      {index != 0 && (
                        <Button
                          className={styles.changesButton}
                          onClick={() => onView(item)}
                        >
                          <EyeOutlined /> View changes
                        </Button>
                      )}
                    </Collapse.Panel>
                  </Collapse>
                </Timeline.Item>
              );
            })}
        </StyledTimeline>
        <div className={styles.drawerFooter}>
          <StyledButton onClick={hideDrawer}>Close</StyledButton>
        </div>
      </div>
    </Drawer>
  );
};
