import React, { useEffect, useState } from "react";
import {
  CommonTable,
  StyledButton,
  TableStyledMenu,
} from "../../../../shared/commonStyles";
import {
  DeleteOutlined,
  EllipsisOutlined,  
  MenuOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { siteThemeColors } from "../../../../shared/colors";
import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Dropdown, Form, Menu, message, Row, Space } from "antd";
import SubSectionTable from "./SubSectionTable";
import AddAssignees from "./Modal/AddAssignees";
import Assignees from "./Assignees";
import InputData from './InputData'
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DocumentReviewTable = ({
  onClickPrevious,
  onCreateDocumentReview,
  tableData,
  action,
  loading,
}) => {
  const [loader,setLoader] = useState(false)
  const [dataSource, setDataSource] = useState([
    {
      index: 1,
      sectionName: "",
      editors: null,
      auditors: null,      
    },
  ]);
  const [form] = Form.useForm();  

  useEffect(() => {
    if (!tableData) return;
    
    if (tableData.length > 0) {
      setDataSource(tableData);
    }
  }, [tableData]);
  
  const [addAssigneeModal, setAddAssigneeModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [assignType, setAssignType] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);  

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource?.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );    
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Section name",
      dataIndex: "sectionName",
      width: "150px",
      className: "drag-visible",
      render: (_, record) => {
        return <InputData rowData={record} handleSave={handleSave} type="parent" />
      },
    },
    {
      title: "Editors",
      dataIndex: "editors",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return <Assignees record={record} onAddEditors={onAddEditors} type="editor" />
      },
    },
    {
      title: "Auditors",
      dataIndex: "auditors",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return <Assignees record={record} onAddEditors={onAddEditors} type="auditor" />
      },
    },
    {
      title: "Order",
      dataIndex: "index",
      width: "120px",
      className: "drag-visible"      
    },
    {
      title: "",
      dataIndex: "index",
      width: "120px",
      className: "drag-visible",
      render: (_, record) => {
        return (
          <Space>
            <StyledButton type="default" onClick={() => addSubSection(record)}>
              Add Subsection
            </StyledButton>
            <Dropdown overlay={() => menuButtons(record)} trigger={["click"]}>
              <StyledButton
                type="default"
                style={{
                  marginLeft: "10",
                  padding: "10px 18px",
                }}
              >
                <EllipsisOutlined />
              </StyledButton>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        onClick={() => onAddEditors(record, "editor")}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<PlusCircleOutlined />}
        >
          Add Editors
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onAddEditors(record, "auditor")}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<PlusCircleOutlined />}
        >
          Assign Auditors
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => handleDeleteSection(record.index)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
          disabled={dataSource.length === 1}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );
  const onSortEnd = ({ oldIndex, newIndex }) => {   
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const indexKeyUpdate = newData.map((item,indexx) => {
        const index = indexx + 1;
        return {
          ...item,
          index          
        };
      });
      setDataSource(indexKeyUpdate);
    }
  };

  const addSubSection = (record) => {
    try{
      setLoader(true)
      const newDataSource = dataSource.map(item => {
        const childs = item.childs?.length ? item.childs : []
        if(record.index === item.index){
          const newData = {
            index: childs?.length + 1,
            sectionName: "",
            editors: null,
            auditors: null,
          };
          childs.push(newData)
          
          return {...item,'childs':childs}   
        }
        return {...item}
      })
      
      setDataSource(newDataSource) 
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
     
  };

  const handleAddSection = async () => {
    try{
      setLoader(true)
      const newData = {
        index: dataSource.length + 1,
        sectionName: "",
        editors: null,
        auditors: null,
      };    
      setDataSource([...dataSource, newData]);
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
  };

  useEffect(() => {    
    const keys = [];
    dataSource.map(item => {
      if(item?.childs?.length){
        keys.push(item?.index);
      }
    })

    setExpandedRowKeys(keys);
  }, [dataSource]);

  const handleDeleteSection = (index) => { 
    try{
      setLoader(true)   
      const newData = dataSource.filter((item) => item.index !== index);
      const indexKeyUpdate = newData.map((item, indexx) => {
        const index = indexx + 1;
        return {
          ...item,
          index,
        };
      });
      setDataSource(indexKeyUpdate);
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
  };

  const onAddEditors = (record, type) => {
    setRowData(record);
    setAddAssigneeModal(true);
    setAssignType(type);
  };

  const onSaveAssignee = async (data, type, form) => {
    try{
      setLoader(true)  
      const values = await form.validateFields();
      const { userIds, groupIds } = values;
      let newData = null;
      if (type === "editor") {
        newData = dataSource.map((item) => {
          if (item.index === data.index) {
            return {
              ...item,
              editors: {userIds,groupIds},
            };
          }
          return item;
        });
      } else {
        newData = dataSource.map((item) => {
          if (item.index === data.index) {
            return {
              ...item,
              auditors: {userIds,groupIds},
            };
          }
          return item;
        });
      }

      setDataSource(newData);
      setAddAssigneeModal(false);
    }catch(error){
      message.error(`Some Problem Occured ${error}`)
    } finally{
      setLoader(false) 
    }
  };
  const onCloseModal = () => {
    setRowData(null);
    setAddAssigneeModal(false);
  };
  const handleSave = (value, row) => {
    const newData = dataSource.map((item) => {
      if (item.index === row.index) {
        return {
          ...item,
          sectionName: value,
        };
      }
      return item;
    });
    setDataSource(newData);
  };

  useEffect(() => {
    console.log('dataSource',dataSource)
  },[dataSource])
  return (
    <>      
      <CommonTable
        loading={loader}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        scroll={{ x: true }} 
        className="documentParentTable"       
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        expandable={{
          expandedRowRender: (record) => ( 
            <SubSectionTable childData={record?.childs} dataSource={dataSource} setDataSource={setDataSource} parentData={record} setLoader={setLoader} />
          ),         
          expandIcon: () => <></>,
        }}
        expandedRowKeys={expandedRowKeys}
      />

      <div>
        <StyledButton
          onClick={handleAddSection}
          type="custom"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <PlusCircleOutlined /> Add one more section
        </StyledButton>{" "}
      </div>
      <Row justify="flex-start">
        <StyledButton
          className="margin-5"
          onClick={() => onClickPrevious()}
          type="default"
        >
          <span>Cancel</span>
        </StyledButton>

        <StyledButton
          className="margin-5"
          onClick={() => onCreateDocumentReview(dataSource)}
          type="custom"
          loading={loading}
        >
          <span>
            {action === "create" ? "Create" : "Update"} document review
          </span>
        </StyledButton>
      </Row>

      {addAssigneeModal && 
        <AddAssignees
          isVisible={addAssigneeModal}
          onClose={() => onCloseModal()}
          rowData={rowData}
          onSaveAssignee={onSaveAssignee}
          form={form}
          assignType={assignType}
        />
      }
    </>
  );
};

export default DocumentReviewTable;
