import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import {
  Row,
  Col,
  Spin,
  message,
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Space,
  Typography,
  Divider,
  UploadFile,
  InputNumber,
  Popconfirm,
  Button,
  Popover,
} from "antd";
import type { FormInstance } from "antd";
import _ from "lodash";
import {
  UploadOutlined,
  WarningOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DownOutlined,
  UpOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../shared/constants";
import { FormWrapper, StyledButton } from "../../../shared/commonStyles";
import {
  listDataSheetFiles,
  calculateAirDistance,
} from "../../../services/dataSheet";
import SheetFilesModal from "./SheetFilesModal";
import { useLocation } from "react-router-dom";
import { useBoolean } from "ahooks";
import { SurveyNotesDrawer } from "./SurveyNotesDrawer";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import { observer } from "mobx-react-lite";
import { useSize } from "ahooks";
import { v4 as uuidv4 } from "uuid";
import styles from "./SurveyView.module.scss";
import { surveyStore } from "../../../stores/SurveyStore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
const { Title } = Typography;

import "./style.css";
import { SurveyFooterButtons } from "./SurveyFooterButtons";

interface DataSheetSingleFormProps {
  form: FormInstance;
  latestRecord?: DataSheetRecord;
  setDataSheetRecords: (_dataSheetRecords: DataSheetRecord[]) => void;
  setSelectedHistoryVersion: (recordId: string) => void;
  onClickPrevious: () => void;
  onClickNext: (isSkipped?: boolean) => void;
  completeSurvey: () => Promise<void>;
  openDrawer: () => void;
  handleUpdateTaskDeployment: (status: string) => void;
}

interface FormItemProps {
  name: string;
  rules: {
    required: boolean;
    message: string;
  }[];
  type?: string;
  style: {
    width: string;
  };
  valuePropName?: string;
}

interface RecordNote {
  user: string;
  date: string;
  text: string;
}

export const DataSheetSingleForm = observer(function DataSheetSingleForm({
  form,
  latestRecord,
  onClickPrevious,
  onClickNext,
  completeSurvey,
  openDrawer,
  handleUpdateTaskDeployment,
}: DataSheetSingleFormProps) {
  const { state } = useLocation();
  const size = useSize(document.querySelector("body"));
  const [disabledFields, setDisabledFields] = useState<any[]>([]);
  const [showDataSheetFilesModal, setShowDataSheetFilesModal] =
    useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile[]>([] as UploadFile[]);
  const [notes, setNotes] = useState<RecordNote[]>([] as RecordNote[]);
  const [fieldNotes, setFieldNotes] = useState<FieldNote[]>(
    latestRecord?.field_notes || ([] as FieldNote[])
  );

  const [
    isAllCommentsShown,
    { toggle: toggleAllComments, setFalse: hideAllComments },
  ] = useBoolean(false);

  const [recordReasons, setRecordReasons] = useState<StatusReason[]>(
    [] as StatusReason[]
  );
  const [currentFieldData, setCurrentFieldData] = useState({
    name: "",
    notes: [] as FieldNote[],
    status: "",
  });
  const [
    isFieldNotesDrawerShown,
    { setTrue: showFieldNotesDrawer, setFalse: hideFieldNotesDrawer },
  ] = useBoolean(false);
  const [noteVisible, setNoteVisible] = useState(false);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] =
    useState<boolean>(false);
  const [uploadedFilesList, setUploadedFilesList] = useState<string[]>([]);
  const [
    isNotesDrawerShown,
    { setTrue: showNotesDrawer, setFalse: hideNotesDrawer },
  ] = useBoolean(false);

  const [popconfirms, setPopconfirms] = useState<PopconfirmAlert[]>([]);
  const [richTextData, setRichTextData] = useState({});

  const loadDataLakeFiles = async (dataId: string) => {
    setLoaderUploadedFilesList(true);
    try {
      const filesList = await listDataSheetFiles(dataId);
      console.log("filesList", filesList);
      if (filesList && filesList !== null) {
        const list = filesList
          .replace(/['"]+/g, "")
          .replace(/^\[(.+)\]$/, "$1")
          .split(",");
        setUploadedFilesList(list);
      }
    } catch (error) {
      console.log("Error while fetching data sheet files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const handleGetFieldValues = async () => {
    form.resetFields();
    if (latestRecord && latestRecord?._id?.$oid) {
      latestRecord?._id?.$oid &&
        (await loadDataLakeFiles(latestRecord?._id?.$oid));
      latestRecord?.field_notes?.length
        ? setFieldNotes(latestRecord?.field_notes)
        : setFieldNotes([]);
      latestRecord?.notes?.length ? setNotes(latestRecord.notes) : setNotes([]);
      latestRecord.statusReasons?.length
        ? setRecordReasons(latestRecord.statusReasons)
        : setRecordReasons([]);
      const updatedValues: { [key: string]: string | Moment | any } = {} as {
        [key: string]: string | Moment | any;
      };
      const getValues = latestRecord[latestRecord?.sheet_name];

      if (getValues) {
        for (const [key, value] of Object.entries(getValues)) {
          const getSchemaOfField = surveyStore.dataSheet?.sheet_schema?.find(
            (schema: SheetSchema) => schema.entity_name === key
          );
          if (getSchemaOfField && getSchemaOfField?.data_type === "date") {
            updatedValues[key] = value
              ? moment((value as any)?.$date || value)
              : undefined;
          } else {
            updatedValues[key] = typeof value === "object" ? undefined : value;
          }
        }
        Object.keys(updatedValues).forEach((key) => {
          if (key.includes("_actual_value")) {
            updatedValues[key.replace("_actual_value", "")] =
              updatedValues[key];
          } else if (key.includes("_actual_unit_id")) {
            updatedValues[key.replace("_actual_unit_id", "_unit_id")] =
              updatedValues[key];
          }
        });
        form.setFieldsValue(updatedValues);
      }
      if (
        !surveyStore.currentStepInfo?.multiline_form_flag &&
        surveyStore.currentStepInfo?.survey_type === "data_sheets"
      ) {
        surveyStore.changeSurveyData(
          [{ ...latestRecord, [latestRecord.sheet_name]: updatedValues }],
          "dataToSave"
        );
      }
      const openPopconfirmIndex =
        surveyStore.currentStepData.popconfirms?.findIndex(
          (popconfirm) =>
            latestRecord?._id?.$oid === popconfirm.recordId &&
            !popconfirm.reason?.length &&
            !surveyStore.currentStepData.popconfirms?.find((v) => v.isOpen)
        );
      const newPopconfirms =
        surveyStore.currentStepData.popconfirms?.map((popconfirm, index) =>
          index === openPopconfirmIndex
            ? { ...popconfirm, isOpen: true }
            : popconfirm
        ) || [];
      setPopconfirms(newPopconfirms);
      surveyStore.changeSurveyData(newPopconfirms, "popconfirms");
    } else if (
      surveyStore.dataSheet &&
      !!surveyStore.dataSheet?.sheet_schema?.length &&
      surveyStore.defaultMetaDataRecord
    ) {
      const schemas = {} as SheetSchema;
      surveyStore.dataSheet?.sheet_schema?.forEach((schema: SheetSchema) => {
        schemas[schema.entity_name as keyof SheetSchema] = "";
        if (schema?.metadata_entity_name && surveyStore.defaultMetaDataRecord) {
          schemas[schema.entity_name as keyof SheetSchema] =
            surveyStore.defaultMetaDataRecord[schema?.metadata_entity_name];
        }

        if (schema.unit_id) {
          schemas[schema.entity_name + "_unit_id"] = schema.unit_id;
        }
      });
      form.setFieldsValue(schemas);
      if (
        !surveyStore.currentStepInfo?.multiline_form_flag &&
        surveyStore.currentStepInfo?.survey_type === "data_sheets"
      ) {
        surveyStore.changeSurveyData([{} as DataSheetRecord], "dataToSave");
      }
    }
    if (
      (!latestRecord || Object.keys(latestRecord)?.length <= 1) &&
      surveyStore.currentStepInfo?.placeholders &&
      Object.keys(surveyStore.currentStepInfo?.placeholders || {})?.length &&
      surveyStore.dataSheet &&
      !!surveyStore.dataSheet?.sheet_schema?.length
    ) {
      const updatedValues: { [key: string]: string | Moment | any } = {} as {
        [key: string]: string | Moment | any;
      };

      for (const [key, value] of Object.entries(
        surveyStore.currentStepInfo.placeholders
      )) {
        const getSchemaOfField = surveyStore.dataSheet?.sheet_schema?.find(
          (schema: SheetSchema) => schema.entity_name === key
        );
        if (getSchemaOfField && getSchemaOfField?.data_type === "date") {
          updatedValues[key] = value
            ? moment((value as any)?.$date || value)
            : undefined;
        } else {
          updatedValues[key] = typeof value === "object" ? undefined : value;
        }
      }
      form.setFieldsValue(updatedValues);
      if (
        !surveyStore.currentStepInfo?.multiline_form_flag &&
        surveyStore.currentStepInfo?.survey_type === "data_sheets"
      ) {
        surveyStore.changeSurveyData([{} as DataSheetRecord], "dataToSave");
      }
    }

    surveyStore.currentStepData?.validation?.filter(
      (v) => v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid
    )?.length &&
      form.setFields(
        (surveyStore.currentStepData?.validation?.find(
          (v) => v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid
        )?.fields as any) || []
      );
    surveyStore.checkStepStatus(
      surveyStore.currentStepInfo,
      surveyStore.dataSheet!,
      surveyStore.dataToSave as DataSheetRecord[]
    );
  };

  useEffect(() => {
    surveyStore.changeSurveyData([{} as DataSheetRecord], "dataToSave");
    setRecordReasons([]);
    setFieldNotes([]);
    setNotes([]);
    setPopconfirms([]);
    setUploadedFilesList([]);
    setCurrentFieldData({ name: "", notes: [], status: "" });
    hideAllComments();
    handleGetFieldValues();
  }, [latestRecord]);

  const handleChangeDataToSave = (value: any, key: string) => {
    const newRecord = {
      ...surveyStore.dataToSave?.[0],
      sheet_type: latestRecord && latestRecord?._id?.$oid ? "edited" : "new",
      [key]: value,
    };
    surveyStore.changeSurveyData([newRecord], "dataToSave");
    surveyStore.checkStepStatus(
      surveyStore.currentStepInfo,
      surveyStore.dataSheet!,
      surveyStore.dataToSave as DataSheetRecord[]
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleAddNote = (note: string) => {
    const formattedNote = {
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: note,
    };
    setNotes([...notes, formattedNote]);
    handleChangeDataToSave([...notes, formattedNote], "notes");
    hideNotesDrawer();
  };
  const handleOpenFieldDrawer = (name: string) => {
    const currentFieldNotes: FieldNote[] =
      fieldNotes.filter((v: FieldNote) => v.field == name && !v.isDeleted) ||
      [];
    setCurrentFieldData({ name, notes: currentFieldNotes, status: "" });
  };

  const handleAddFieldNote = (note: string) => {
    if (note.length) {
      const formattedFieldNote = {
        key: uuidv4(),
        field: currentFieldData.name,
        username: userInfoStore.userID,
        user: userInfoStore.userName,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        text: note,
      };
      setFieldNotes([...fieldNotes, formattedFieldNote]);
      handleChangeDataToSave(
        [...fieldNotes, formattedFieldNote],
        "field_notes"
      );
      hideFieldNotesDrawer();
    }
  };
  const handleAcknowledgedNotes = (e: boolean, note?: FieldNote) => {
    const newFieldNotes = fieldNotes.map((v) =>
      v.key === note?.key ? { ...v, isAcknowledged: e } : v
    );

    setFieldNotes(newFieldNotes);
    handleChangeDataToSave(newFieldNotes, "field_notes");
  };

  const handleFinishSaving = async (
    isSubmitting: boolean,
    isSavedData: boolean
  ) => {
    if (
      surveyStore.currentStep === surveyStore.surveySteps?.length - 1 &&
      isSubmitting
    ) {
      await completeSurvey();
    } else {
      isSavedData && message.success("Form data was saved successfully!");
      if (state && (state as any).taskDeploymentId) {
        await handleUpdateTaskDeployment("In Progress");
      }
      surveyStore.currentStep !== surveyStore.surveySteps?.length - 1 &&
        onClickNext();
    }
  };

  useEffect(() => {
    surveyStore.currentStepData?.validation?.length &&
      form.setFields(
        (surveyStore.currentStepData?.validation?.find(
          (v) =>
            v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
            v.recordId === surveyStore.dataToSave?.[0]?.key
        )?.fields as any) || []
      );
  }, [surveyStore.currentStepData.validation]);
  useEffect(() => {
    setPopconfirms(surveyStore.currentStepData?.popconfirms || []);
  }, [surveyStore.currentStepData?.popconfirms]);
  const handleSaveFiles = (data: DataSheetRecord, str: string) => {
    const addedFiles = (data.files as UploadFile[]) || [];
    setFiles([...files, ...addedFiles]);
    handleChangeDataToSave(data?.filesList || [], "filesList");
  };

  const saveRecord = async (isSubmitting: boolean) => {
    const isDataToSave = !!(
      surveyStore.dataToSave?.[0] &&
      ((surveyStore.dataToSave as DataSheetRecord[])?.[0]?.sheet_type ===
        "edited" ||
        (surveyStore.dataToSave as DataSheetRecord[])?.[0]?.sheet_type ===
          "new") &&
      Object.keys(surveyStore.dataToSave?.[0])?.length > 1
    );
    try {
      if (isDataToSave) {
        await surveyStore.saveDataSheetRecords();
      }
      setFiles([]);
      setUploadedFilesList([]);
      setPopconfirms([]);
      await handleFinishSaving(isSubmitting, isDataToSave);
    } catch (error) {
      message.error("Error while saving record!");
      console.log("Error while saving record!", error);
      const values = (surveyStore.dataToSave as DataSheetRecord[])?.[0][
        surveyStore.dataSheet.sheet_name
      ];
      if (values) {
        form.resetFields();
        const updatedValues: { [key: string]: string | Moment | any } = {} as {
          [key: string]: string | Moment | any;
        };
        for (const [key, value] of Object.entries(values)) {
          updatedValues[key] = value;
        }
        Object.keys(updatedValues).forEach((key) => {
          if (key.includes("_actual_value")) {
            updatedValues[key.replace("_actual_value", "")] =
              updatedValues[key];
          } else if (key.includes("_actual_unit_id")) {
            updatedValues[key.replace("_actual_unit_id", "_unit_id")] =
              updatedValues[key];
          }
        });
        form.setFieldsValue(updatedValues);
        surveyStore.changeSurveyData(
          [
            {
              ...surveyStore.dataToSave?.[0],
              [surveyStore.dataSheet.sheet_name]: updatedValues,
            },
          ],
          "dataToSave"
        );
      }
    }
  };

  const togglePopconfirm = (schema: SheetSchema) => {
    const index = popconfirms.findIndex(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const popconfirm = popconfirms.find(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const newPopconfirm = {
      ...popconfirm!,
      isOpen: !popconfirm!.isOpen,
    };
    const newList = popconfirms.map((v, i) => {
      return i === index ? newPopconfirm : v;
    });
    setPopconfirms(newList);
    surveyStore.changeSurveyData(newList, "popconfirms");
  };

  const closePopconfirm = (schema: SheetSchema) => {
    const index = popconfirms.findIndex(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const popconfirm = popconfirms.find(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const newPopconfirm = {
      ...popconfirm!,
      isOpen: false,
    };
    const newList = popconfirms.map((v, i) => {
      return i === index ? newPopconfirm : v;
    });
    setPopconfirms(newList);
    surveyStore.changeSurveyData(newList, "popconfirms");
  };

  const handleAddReason = (schema: SheetSchema, text: string) => {
    const fieldName = schema.entity_name.replace("_actual_value", "");
    const index = popconfirms.findIndex(
      (v) =>
        v.name === fieldName &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const popconfirm = popconfirms.find(
      (v) =>
        v.name === fieldName &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );

    const newList = popconfirms.map((v, i) => {
      return i === index ? { ...v, reason: text } : { ...v, isOpen: false };
    });
    setPopconfirms(newList);
    surveyStore.changeSurveyData(newList, "popconfirms");
  };

  const handleReasonConfirm = (schema: SheetSchema) => {
    const index = popconfirms.findIndex(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const popconfirm = popconfirms.find(
      (v) =>
        v.name === schema.entity_name &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );
    const nextPopconfirm = popconfirms.findIndex(
      (v) =>
        v.name !== schema.entity_name &&
        !v.reason &&
        !v.isOpen &&
        (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
          v.recordId === surveyStore.dataToSave?.[0]?.key!)
    );

    if (
      popconfirms.length &&
      popconfirms
        ?.filter(
          (item) =>
            item.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
            item.recordId === surveyStore.dataToSave?.[0]?.key!
        )
        .every((item) => item.reason.length)
    ) {
      closePopconfirm(schema);
    } else {
      const newPopconfirm = {
        ...popconfirm!,
        isOpen: false,
      };
      const newList = popconfirms.map((v, i) => {
        return i === index
          ? newPopconfirm
          : i === nextPopconfirm
          ? { ...v, isOpen: true }
          : v;
      });
      setPopconfirms(newList);
      surveyStore.changeSurveyData(newList, "popconfirms");
    }
    surveyStore.changeSurveyData(
      [
        {
          ...surveyStore.dataToSave?.[0],
          sheet_type:
            latestRecord && latestRecord?._id?.$oid ? "edited" : "new",
        },
      ],
      "dataToSave"
    );
  };
  const isAirDistanceSheet = () => {
    const requiresFields = [
      "destination_city",
      "origination_city",
      "type_of_journey",
      "distance",
      "routing",
    ];
    const entityNames =
      !!surveyStore.dataSheet?.sheet_schema?.length &&
      surveyStore.dataSheet?.sheet_schema.map(
        (v: SheetSchema) => v.entity_name
      );

    return entityNames && requiresFields.every((v) => entityNames.includes(v));
  };

  const handleFormChange = (changedFields: any) => {
    const fieldsValues = form.getFieldsValue(true);
    const newData = fieldsValues;
    if (
      popconfirms?.find(
        (v) =>
          v.name === changedFields[0]?.name[0] &&
          (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
            v.recordId === surveyStore.dataToSave?.[0]?.key!)
      )
    ) {
      const newPopconfirms = popconfirms?.filter((v) =>
        v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
        v.recordId === surveyStore.dataToSave?.[0]?.key!
          ? v.name !== changedFields[0]?.name[0]
          : v
      );
      setPopconfirms(newPopconfirms);
      surveyStore.changeSurveyData(newPopconfirms, "popconfirms");
    }
    if (isAirDistanceSheet()) {
      const isAllRequiredFiedsFilled =
        !!fieldsValues.destination_city &&
        !!fieldsValues.origination_city &&
        !!fieldsValues.type_of_journey;
      if (isAllRequiredFiedsFilled) {
        const payload: any = {
          origin: fieldsValues.origination_city,
          destination: fieldsValues.destination_city,
          trip_type: fieldsValues.type_of_journey,
        };
        !!fieldsValues.transit_city &&
          (payload.transit = fieldsValues.transit_city);

        calculateAirDistance(payload).then((result) => {
          form.setFieldsValue({
            routing: result.routing,
            distance: result.distance,
            distance_unit_id: "km",
          });
          result.distance
            ? setDisabledFields(["routing", "distance", "distance_unit_id"])
            : setDisabledFields([]);
          newData.routing = result.routing;
          newData.distance = result.distance;
          newData.distance_unit_id = "km";
        });
      } else {
        setDisabledFields([]);
      }
    }

    const newRecord = {
      ...surveyStore.dataToSave?.[0],
      sheet_type: latestRecord && latestRecord?._id?.$oid ? "edited" : "new",
      [surveyStore.dataSheet!.sheet_name]: newData,
    };
    surveyStore.changeSurveyData([newRecord], "dataToSave");
    surveyStore.checkStepStatus(
      surveyStore.currentStepInfo,
      surveyStore.dataSheet!,
      surveyStore.dataToSave as DataSheetRecord[]
    );
  };

  const dynamicFormInputs = () => {
    try {
      const filteredSheetSchema = surveyStore.dataSheet.sheet_schema?.filter(
        (v) =>
          !v.isExpression &&
          v.input_type !== "expression" &&
          (surveyStore.currentStepInfo?.display_fields
            ? surveyStore.currentStepInfo?.display_fields?.find(
                (item: string) => v.entity_name === item
              )
            : !surveyStore.currentStepInfo?.hide_fields &&
              !v.metadata_entity_name)
      );
      return filteredSheetSchema?.map((item, index) => {
        let inputNode = null;
        let formItemProps: FormItemProps = {
          name: item.entity_name,
          rules: [
            {
              required: item.required,
              message: `${item.display_name} is required!`,
            },
          ],
          ...(item.input_type === "number" && {
            type: "number",
          }),
          style: { width: "100%" },
        };
        const warningsByAdmin = fieldNotes?.filter(
          (v: FieldNote) =>
            v?.field == item?.entity_name && v.isAddedByAdmin && !v.isDeleted
        );

        const unitWarningsByAdmin = fieldNotes?.filter(
          (v: FieldNote) =>
            v?.field == item?.entity_name + "_unit_id" &&
            v.isAddedByAdmin &&
            !v.isDeleted
        );

        const inputType = item?.input_type;

        const isDisabled = !!(
          (surveyStore.surveyInfo?.defaultMetaDataSheetId.length &&
            !!item.metadata_entity_name?.length) ||
          latestRecord?.isLocked ||
          latestRecord?.import_status ||
          surveyStore.surveyInfo.status !== "ACTIVE" ||
          latestRecord?.review_status === "APPROVED" ||
          disabledFields.some((v) => v == item.entity_name) ||
          (surveyStore.currentStepInfo?.placeholders &&
            Object.keys(surveyStore.currentStepInfo?.placeholders || {})
              ?.length &&
            Object.keys(surveyStore.currentStepInfo?.placeholders || {}).find(
              (key) => item.entity_name === key
            ))
        );

        const isPopconfirmVisible =
          inputType === "number" &&
          popconfirms.length > 0 &&
          !!popconfirms?.find(
            (v) =>
              v.name === item.entity_name &&
              (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
                v.recordId === surveyStore.dataToSave?.[0]?.key!)
          ) &&
          popconfirms?.find(
            (v) =>
              v.name === item.entity_name &&
              (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
                v.recordId === surveyStore.dataToSave?.[0]?.key!)
          )?.isOpen;

        if (inputType === "number") {
          inputNode = (
            <InputNumber
              disabled={isDisabled}
              lang="en"
              //name={`field-${index}`} // not used yet
              addonAfter={
                (popconfirms?.find(
                  (v) =>
                    v.name === item.entity_name &&
                    (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
                      v.recordId === surveyStore.dataToSave?.[0]?.key!)
                ) && (
                  <WarningOutlined
                    onClick={() => togglePopconfirm(item)}
                    style={{ color: "orange" }}
                  />
                )) ||
                false
              }
              status={
                popconfirms?.find(
                  (v) =>
                    v.name === item.entity_name &&
                    (v.recordId === surveyStore.dataToSave?.[0]?._id?.$oid ||
                      v.recordId === surveyStore.dataToSave?.[0]?.key!)
                )
                  ? "warning"
                  : ""
              }
              style={{ width: "100%" }}
            />
          );
        } else if (inputType === "date") {
          inputNode =
            !form.getFieldsValue(true)[item.entity_name] ||
            form.getFieldsValue(true)[item.entity_name] instanceof moment ? (
              <DatePicker
                disabled={isDisabled}
                style={{ width: "100%" }}
                format={YearMonthDayFormat}
              />
            ) : (
              ""
            );
        } else if (inputType === "string") {
          inputNode = <Input style={{ margin: "0px" }} disabled={isDisabled} />;
        } else if (inputType === "dropdown") {
          inputNode = (
            <Select disabled={isDisabled} style={{ width: "100%" }}>
              {item?.options?.map((item: any) => {
                return (
                  <Select.Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                );
              })}
            </Select>
          );
        } else if (inputType === "radio") {
          inputNode = (
            <Radio.Group className={styles.radio} disabled={isDisabled}>
              {item?.options?.map((item: any) => {
                return (
                  <Radio key={item?.value} value={item?.value}>
                    {item?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          );
        } else if (inputType === "checkbox") {
          if (item?.is_checkbox_group) {
            inputNode = (
              <Checkbox.Group disabled={isDisabled}>
                <Row>
                  {item?.options?.map((item: any) => {
                    return (
                      <Col key={item?.value} xs={24} sm={24} md={24} lg={24}>
                        <Checkbox value={item?.value}>{item?.label}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            );
          } else {
            formItemProps = {
              ...formItemProps,
              valuePropName: "checked",
            };
            inputNode = (
              <Checkbox disabled={isDisabled}>{item.display_name}</Checkbox>
            );
          }
        } else if (inputType === "long-answer") {
          inputNode = (
            <Input.TextArea
              disabled={isDisabled}
              style={{ height: 100, margin: "0px" }}
            />
          );
        } else if (inputType === "rich-text") {
          inputNode = (
            <div className="ckSmallHeight">
              <CKEditor
                editor={DecoupledDocumentEditor}
                onReady={(editor: any) => {
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                data={form.getFieldValue(item.entity_name)}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  form.setFieldsValue({ [item.entity_name]: data });
                  handleFormChange([{ name: [item.entity_name] }]);
                }}
                style={{ height: 100 }}
                disabled={isDisabled}
              />
            </div>
          );
        } else {
          inputNode = null;
        }

        return (
          <Row gutter={[16, 16]} key={index}>
            <Col span={24}>
              <Divider className={styles.divider} />
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={<Title level={5}>{item.display_name}</Title>}
                required={item.required}
                className={
                  item.entity_name === currentFieldData?.name &&
                  (currentFieldData?.status === "warning"
                    ? styles.fieldWarning
                    : currentFieldData.status === "comment" &&
                      styles.fieldComment)
                }
              >
                {item.description && (
                  <p className="color-white">{item.description}</p>
                )}
                <Input.Group compact>
                  <Popconfirm
                    visible={isPopconfirmVisible}
                    title={
                      <FormWrapper className="reasonPopconfirmWrapper">
                        <p className="reasonPopconfirmText">
                          Entered data is much{" "}
                          {popconfirms.find(
                            (v) =>
                              v.name === item.entity_name &&
                              v.recordId ===
                                surveyStore.dataToSave?.[0]?._id?.$oid
                          )?.warning_word === "above"
                            ? "higher"
                            : "lower"}{" "}
                          than the baseline. Enter a reason or correct the value
                          to submit the survey
                        </p>
                        <Input
                          disabled={
                            latestRecord?.review_status ===
                            RecordStatus.APPROVED
                          }
                          value={
                            popconfirms.find(
                              (v) =>
                                v.name === item.entity_name &&
                                v.recordId ===
                                  surveyStore.dataToSave?.[0]?._id?.$oid
                            )?.reason
                          }
                          placeholder="Enter reason"
                          onChange={(e) => {
                            handleAddReason(item, e.target.value);
                          }}
                        />
                      </FormWrapper>
                    }
                    onConfirm={() =>
                      latestRecord?.review_status === RecordStatus.APPROVED
                        ? closePopconfirm(item)
                        : handleReasonConfirm(item)
                    }
                    onCancel={() => closePopconfirm(item)}
                    cancelText="Cancel"
                    okText="Submit and go to the next"
                    placement="bottomRight"
                    icon={<WarningOutlined style={{ color: "#e9d155" }} />}
                    overlayClassName={`popoverContent reasonPopover ${
                      isPopconfirmVisible && "reasonPopoverActive"
                    }`}
                  >
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexFlow: "row nowrap",
                        alignItems: "start",
                      }}
                      id={
                        item.entity_name === currentFieldData?.name &&
                        (currentFieldData?.status === "warning" ||
                          currentFieldData.status === "comment")
                          ? "activeRef"
                          : ""
                      }
                    >
                      <Col
                        span={20}
                        onBlur={() =>
                          setCurrentFieldData({
                            name: "",
                            notes: [],
                            status: "",
                          })
                        }
                      >
                        <Form.Item {...formItemProps}>{inputNode}</Form.Item>
                      </Col>
                      {!!warningsByAdmin.length && (
                        <Col span={4}>
                          <Popover
                            overlayClassName="popoverContent"
                            visible={
                              noteVisible &&
                              item.entity_name === currentFieldData?.name
                            }
                            onVisibleChange={setNoteVisible}
                            trigger={["click"]}
                            title={
                              <FormWrapper>
                                {warningsByAdmin
                                  ?.filter((item) =>
                                    currentFieldData.notes?.find(
                                      (v) => v.key === item.key
                                    )
                                  )
                                  .map((v) => (
                                    <Row
                                      justify="space-between"
                                      align="middle"
                                      key={v.key}
                                      gutter={[8, 8]}
                                    >
                                      <Col span={19}>
                                        <p style={{ width: "fit-content" }}>
                                          {v?.text}
                                        </p>
                                      </Col>
                                      <Col span={4}>
                                        <Checkbox
                                          disabled={v?.isAcknowledged}
                                          checked={v?.isAcknowledged}
                                          onChange={(e) => {
                                            setCurrentFieldData((prev) => ({
                                              ...prev,
                                              name: item.entity_name,
                                            }));
                                            handleAcknowledgedNotes(
                                              e.target.checked,
                                              v
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                              </FormWrapper>
                            }
                          >
                            {warningsByAdmin?.some(
                              (v) => v.noteType === "warning"
                            ) ? (
                              <WarningOutlined
                                onClick={() => {
                                  setCurrentFieldData({
                                    name: item.entity_name,
                                    status: "",
                                    notes: fieldNotes?.filter(
                                      (note) =>
                                        note.field === item.entity_name &&
                                        !item.isDeleted
                                    ),
                                  });
                                  setNoteVisible((prev) => !prev);
                                }}
                                className={styles.warningIcon}
                              />
                            ) : (
                              <CommentOutlined
                                onClick={() => {
                                  setCurrentFieldData({
                                    name: item.entity_name,
                                    status: "",
                                    notes: fieldNotes?.filter(
                                      (note) =>
                                        note.field === item.entity_name &&
                                        !item.isDeleted
                                    ),
                                  });
                                  setNoteVisible((prev) => !prev);
                                }}
                                className={styles.commentsIcon}
                              />
                            )}
                          </Popover>
                        </Col>
                      )}

                      {surveyStore.currentStepInfo?.show_notes && (
                        <Col>
                          <CommentOutlined
                            className={styles.fieldNotesBtn}
                            onClick={() => {
                              handleOpenFieldDrawer(item.entity_name);
                              showFieldNotesDrawer();
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Popconfirm>

                  {item.unit_id && (
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexFlow: "row nowrap",
                      }}
                      className={
                        item.entity_name + "_unit_id" ===
                          currentFieldData?.name &&
                        (currentFieldData?.status === "warning"
                          ? styles.fieldWarning
                          : currentFieldData.status === "comment" &&
                            styles.fieldComment)
                      }
                      id={
                        item.entity_name + "_unit_id" ===
                          currentFieldData?.name &&
                        (currentFieldData?.status === "warning" ||
                          currentFieldData.status === "comment")
                          ? "activeRef"
                          : ""
                      }
                    >
                      <Col
                        onBlur={() =>
                          setCurrentFieldData({
                            name: "",
                            notes: [],
                            status: "",
                          })
                        }
                      >
                        <Form.Item
                          name={item.entity_name + "_unit_id"}
                          initialValue={item.unit_id}
                          rules={[
                            {
                              required: true,
                              message: "Please select a Unit!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "170px" }}
                            dropdownMatchSelectWidth={false}
                            placeholder="Unit"
                            disabled={isDisabled}
                          >
                            {item.convertable_units?.map((unit: any) => {
                              return (
                                <Select.Option
                                  key={unit.unit}
                                  value={unit.unit}
                                >
                                  {unit.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      {!!unitWarningsByAdmin.length && (
                        <Col>
                          {" "}
                          <Popover
                            overlayClassName="popoverContent"
                            visible={
                              noteVisible &&
                              item.entity_name + "_unit_id" ===
                                currentFieldData?.name
                            }
                            onVisibleChange={setNoteVisible}
                            trigger={"click"}
                            title={
                              <FormWrapper>
                                {unitWarningsByAdmin
                                  ?.filter((item) =>
                                    currentFieldData.notes?.find(
                                      (v) => v.key === item.key
                                    )
                                  )
                                  .map((v) => (
                                    <Row
                                      key={v.key}
                                      justify="space-between"
                                      align="middle"
                                      gutter={[8, 8]}
                                    >
                                      <Col span={19}>
                                        <p style={{ width: "fit-content" }}>
                                          {v?.text}
                                        </p>
                                      </Col>
                                      <Col span={4}>
                                        <Checkbox
                                          disabled={v?.isAcknowledged}
                                          checked={v?.isAcknowledged}
                                          onChange={(e) =>
                                            handleAcknowledgedNotes(
                                              e.target.checked,
                                              v
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                              </FormWrapper>
                            }
                          >
                            {unitWarningsByAdmin?.some(
                              (v) => v.noteType === "warning"
                            ) ? (
                              <WarningOutlined
                                onClick={() => {
                                  setCurrentFieldData({
                                    name: item.entity_name + "_unit_id",
                                    status: "",
                                    notes: fieldNotes?.filter(
                                      (note) =>
                                        note.field ===
                                          item.entity_name + "_unit_id" &&
                                        !item.isDeleted
                                    ),
                                  });
                                  setNoteVisible((prev) => !prev);
                                }}
                                className={styles.warningIcon}
                              />
                            ) : (
                              <CommentOutlined
                                onClick={() => {
                                  setCurrentFieldData({
                                    name: item.entity_name + "_unit_id",
                                    status: "",
                                    notes: fieldNotes?.filter(
                                      (note) =>
                                        note.field ===
                                          item.entity_name + "_unit_id" &&
                                        !item.isDeleted
                                    ),
                                  });
                                  setNoteVisible((prev) => !prev);
                                }}
                                className={styles.commentsIcon}
                              />
                            )}
                          </Popover>
                        </Col>
                      )}
                      {surveyStore.currentStepInfo?.show_notes && (
                        <CommentOutlined
                          className={styles.fieldNotesBtn}
                          onClick={() => {
                            handleOpenFieldDrawer(
                              item.entity_name + "_unit_id"
                            );
                            showFieldNotesDrawer();
                          }}
                        />
                      )}
                    </Row>
                  )}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  if (!surveyStore.currentStepInfo) return null;
  const getCommentsTitle = () => {
    const fieldComments = fieldNotes?.filter(
      (note) =>
        (note.noteType === "comment" || !note.noteType) &&
        note.isAddedByAdmin &&
        surveyStore.currentStepInfo.display_fields?.includes(
          note.field?.replace("_unit_id", "")
        ) &&
        !note.isDeleted
    )?.length;
    const fieldWarnings = fieldNotes?.filter(
      (note) =>
        note.noteType === "warning" &&
        note.isAddedByAdmin &&
        surveyStore.currentStepInfo.display_fields?.includes(
          note.field?.replace("_unit_id", "")
        ) &&
        !note.isDeleted
    )?.length;
    const result =
      fieldComments || fieldWarnings ? (
        <span className="text-align-center">
          <span className="color-white">You have </span>
          <span className="color-white">
            {fieldComments
              ? fieldComments +
                " comment" +
                (fieldComments > 1 ? "s" : "") +
                (fieldWarnings ? "," : "")
              : ""}
          </span>{" "}
          <span>
            {fieldWarnings
              ? fieldWarnings + " warning" + (fieldWarnings > 1 ? "s" : "")
              : ""}
          </span>
        </span>
      ) : (
        <></>
      );
    return result;
  };

  const getFieldName = (field: string) => {
    const isUnitField = field.includes("unit_id");
    const fieldName = field.replace("_unit_id", "");
    const schemaField = surveyStore.dataSheet.sheet_schema?.find(
      (item) => item.entity_name === fieldName
    );
    const newName = isUnitField
      ? schemaField?.display_name + " Unit"
      : schemaField?.display_name;
    return newName;
  };

  const handleScrollToField = () => {
    document.getElementById("activeRef")?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    currentFieldData.status !== "" && handleScrollToField();
  }, [currentFieldData]);

  const handleClickNote = (note: FieldNote) => {
    setCurrentFieldData({
      name: note.field,
      status: note.noteType || "",
      notes: [note],
    });
    setNoteVisible(true);
    handleScrollToField();
  };
  const getFieldNotes = () => {
    const fieldComments = fieldNotes?.filter(
      (note) =>
        (note.noteType === "comment" || !note.noteType) &&
        note.isAddedByAdmin &&
        surveyStore.currentStepInfo.display_fields?.includes(
          note.field?.replace("_unit_id", "")
        ) &&
        !note.isDeleted
    );
    const fieldWarnings = fieldNotes?.filter(
      (note) =>
        note.noteType === "warning" &&
        note.isAddedByAdmin &&
        surveyStore.currentStepInfo.display_fields?.includes(
          note.field?.replace("_unit_id", "")
        ) &&
        !note.isDeleted
    );
    return (
      <>
        {!!fieldComments?.length && (
          <Row className={styles.notesBlock}>
            <Col span={24} className={styles.comments}>
              <CommentOutlined className={styles.commentsIcon} /> Comments
            </Col>
            <Col span={24}>
              {fieldComments.map((note) => (
                <Row
                  className={styles.commentsText}
                  key={note.key}
                  onClick={() => handleClickNote(note)}
                  style={{ cursor: "pointer" }}
                >
                  <u>{getFieldName(note.field)}</u>
                </Row>
              ))}
            </Col>
          </Row>
        )}
        {!!fieldWarnings?.length && (
          <Row className={styles.notesBlock}>
            <Col span={24} className={styles.warning}>
              <WarningOutlined className={styles.warningIcon} /> Warnings
            </Col>
            <Col span={24} className={styles.warning}>
              {fieldWarnings.map((note) => (
                <Row
                  className={styles.commentsText}
                  key={note.key}
                  onClick={() => handleClickNote(note)}
                  style={{ cursor: "pointer" }}
                >
                  <u>{getFieldName(note.field)}</u>
                </Row>
              ))}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const viewInfoButton = size && size?.width <= 900 && (
    <div>
      <Button
        style={{
          color: " #fff",
          borderColor: "#fff",
          width: "100%",
          height: "40px",
        }}
        type="ghost"
        onClick={openDrawer}
        className="margin-5"
      >
        <QuestionCircleOutlined /> View Information & Progress
      </Button>
    </div>
  );

  return (
    <>
      {showDataSheetFilesModal && (
        <SheetFilesModal
          visible={showDataSheetFilesModal}
          rowData={latestRecord || surveyStore.currentStepInfo}
          onClose={() => setShowDataSheetFilesModal(false)}
          handleSave={handleSaveFiles}
        />
      )}
      <Spin
        spinning={surveyStore.surveyLoading || surveyStore.surveySubmitting}
      >
        {surveyStore.dataSheet ? (
          <div className={styles.formWrap}>
            <Title
              className="color-white"
              style={{ textAlign: "center" }}
              level={3}
            >
              {surveyStore.dataSheet?.sheet_name}
            </Title>
            <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: surveyStore.dataSheet?.description,
                }}
              ></div>
            </Row>
            {(!!recordReasons.length ||
              !!fieldNotes?.filter(
                (note) => note.isAddedByAdmin && !note.isDeleted
              )?.length) && (
              <Row
                justify="center"
                align="middle"
                className={styles.commentsBox}
              >
                <Col
                  className={styles.warning}
                  onClick={() => toggleAllComments()}
                >
                  {!!recordReasons?.length && (
                    <Row>
                      <Col span={24}>
                        {recordReasons
                          ?.slice()
                          ?.sort(
                            (a, b) =>
                              new Date(b.timestamp).getTime() -
                              new Date(a.timestamp).getTime()
                          )
                          ?.map((note) => (
                            <Row
                              className="color-white text-align-center"
                              justify="center"
                              key={note.key}
                            >
                              {note.comments?.length
                                ? note.comments // this displays denied reason
                                : "No reason specified"}
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  )}
                  {!!fieldNotes?.filter(
                    (note) => note.isAddedByAdmin && !note.isDeleted
                  )?.length && (
                    <div className="text-align-center">
                      {getCommentsTitle()}
                      {isAllCommentsShown ? (
                        <UpOutlined className={styles.warning} />
                      ) : (
                        <DownOutlined className={styles.warning} />
                      )}
                    </div>
                  )}
                </Col>
                {isAllCommentsShown && getFieldNotes()}
              </Row>
            )}

            {viewInfoButton}
            <Divider className={styles.divider} />
            <Form {...layout} form={form} onFieldsChange={handleFormChange}>
              {dynamicFormInputs()}
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Spin spinning={loaderUploadedFilesList}>
                    <Title className="color-white" level={4}>
                      Attach related files
                    </Title>
                    <Title className="color-white" level={5}>
                      Attach files that are related to this field
                    </Title>
                    <Divider className={styles.divider} />
                    <Space direction="horizontal">
                      <StyledButton
                        disabled={surveyStore.surveyInfo.status !== "ACTIVE"}
                        onClick={() => setShowDataSheetFilesModal(true)}
                        type="primary"
                        icon={<UploadOutlined />}
                      >
                        Upload
                      </StyledButton>
                    </Space>
                  </Spin>
                </Col>
              </Row>
              {surveyStore.currentStepInfo.show_notes && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row justify="space-between" align="middle">
                      <Title className="color-white" level={4}>
                        Add notes
                      </Title>
                      <StyledButton
                        bgcolor={"transparent"}
                        bordercolor={"#87809C"}
                        type="primary"
                        ghost
                        icon={<EditOutlined />}
                        onClick={showNotesDrawer}
                      >
                        Add note
                      </StyledButton>
                    </Row>
                    <SurveyNotesDrawer
                      visible={isNotesDrawerShown}
                      notes={notes}
                      onClose={hideNotesDrawer}
                      onSubmit={handleAddNote}
                    />
                  </Col>
                </Row>
              )}
              <SurveyFooterButtons
                onClickPrevious={onClickPrevious}
                onClickSave={saveRecord}
                onClickNext={onClickNext}
                form={form}
              />

              <SurveyNotesDrawer
                visible={isFieldNotesDrawerShown}
                notes={currentFieldData.notes || []}
                onClose={hideFieldNotesDrawer}
                onSubmit={handleAddFieldNote}
              />
            </Form>
          </div>
        ) : (
          <div className={styles.formWrap}>
            <Title
              className="color-white"
              style={{ textAlign: "center" }}
              level={3}
            >
              Sorry, data for this step was deleted
            </Title>
            {viewInfoButton}
            <Divider className={styles.divider} />
            <SurveyFooterButtons
              onClickPrevious={onClickPrevious}
              onClickSave={saveRecord}
              onClickNext={onClickNext}
              form={form}
            />
          </div>
        )}
      </Spin>
    </>
  );
});
