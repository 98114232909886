import React, { useState } from "react";
import { StyledButton } from "../../../../shared/commonStyles";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddUnitGroupModal } from "./AddUnitGroupModal";
import { Col } from "antd";
import { Link } from "react-router-dom";

export const UnitsManagementHeaderButtons = () => {
  const [isAddUnitGroupModalVisible, setAddUnitGroupModalVisible] =
    useState(false);
  return (
    <Col>
      <StyledButton type="primary" className="margin-5">
        <Link to="/data/units-management/manage-unit-conversions">
          Manage unit conversions
        </Link>
      </StyledButton>
      <StyledButton
        className="margin-5"
        type="primary"
        onClick={() => setAddUnitGroupModalVisible(true)}
      >
        <PlusCircleOutlined /> <span>Add new unit group</span>
      </StyledButton>
      <AddUnitGroupModal
        visible={isAddUnitGroupModalVisible}
        onClose={() => setAddUnitGroupModalVisible(false)}
      />
    </Col>
  );
};
