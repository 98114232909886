import { Auth, API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import { listSelectedStandards, isJsonString } from '../../../services/standards';

export const selectedAllIndicatorsInCategory = (selectedIndicators,categoryIndicators) => {
   const length = selectedIndicators.reduce((a,b) => { 
    if(categoryIndicators.includes(b)){
      return a + 1;
    }
    return a;
    },0);
  
    return (length === categoryIndicators?.length);
}

export const addOrRemove = (list, setList, item) => {
    if (list.includes(item)) {
      setList(list.filter((ele) => ele !== item));
    } else {
      setList([...list, item]);
    }
  };

  export const selectAllIndicatorsForCategory = (selectedIndicators,setIndicators,categoryIndicators) => {
    setIndicators([...selectedIndicators,...categoryIndicators.filter((ele)=> !selectedIndicators.includes(ele))]);
  }  

export const unSelectAllIndicatorsForCategory = (selectedIndicators,setIndicators,categoryIndicators) => {
  setIndicators(selectedIndicators.filter((ele)=> !categoryIndicators.includes(ele)));
}  
  
export const getAuthData = async () => {
  const{ accessToken: { payload } } = await Auth.currentSession();
  const group_id = payload['cognito:groups']?.filter(e => e.includes("org:"))[0];
  const username = payload.sub;
  return { group_id, username };
};

export const getReportStandards = async ({group_id,business_unit,location_id,report_period}) => {  
  const payload = {filter:{ group_id ,business_unit,location_id,report_period }}
    
  const standardsResponse = await listSelectedStandards(payload);
  const formItems = standardsResponse?.data || [];
  
  return formItems?.map((reportStandard) => {
    const parsedFormJSONCode = isJsonString(reportStandard.form_json_code) ? JSON.parse(JSON.parse(reportStandard.form_json_code)) : JSON.parse(reportStandard.form_json_code);
    reportStandard.form_json_code = { ...parsedFormJSONCode, title: "" }
    reportStandard.standard_name =  reportStandard.standard_name ? JSON.parse(JSON.parse(reportStandard.standard_name)) : [];
    reportStandard.standard_id = reportStandard?._id?.$oid;
    return reportStandard;
  });
};

export const getFilteredReportStandards = (
  reportStandards,
  selectedStandards,
  selectedIndustries,
) => (
  reportStandards && reportStandards.filter(({ standard_name, industry }) => {
    const matchingStandards = Array.isArray(standard_name) && standard_name.filter((standard) => selectedStandards.includes(standard));
    if (selectedStandards.length > 0 && matchingStandards.length === 0)
      return false;
    if (selectedIndustries.length > 0 && !selectedIndustries.includes(industry))
      return false;
    return true;
  })
);

export const getUniqueCategories = (reportStandards) => {
  const categories = {};
  reportStandards && reportStandards.forEach(({ category, summary, _id }) => {
    if (!categories[category])
      categories[category] = {}
    categories[category][_id.$oid] = summary
  });
  return categories;
};

export const getUniqueIndustries = (reportStandards) => {
  const industries = [];
  reportStandards && reportStandards.forEach(({ industry }) => {
    if (!industries.includes(industry) && industry !== "") {
      industries.push(industry);
    }
  });
  return industries;
};

export const getUniqueStandards = (reportStandards) => {
  const standards = [];
  Array.isArray(reportStandards) && reportStandards.forEach(({ standard_name }) => {
    Array.isArray(standard_name) && standard_name.forEach((standard) => {
      if (!standards.includes(standard) && standard !== "") {
        standards.push(standard);
      }
    });
  });
  return standards;
};
