import React from "react";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";

interface GeneralInformationProps {
  form: FormInstance;
  onChangeData: () => void;
}
export const GeneralInformation = ({
  form,
  onChangeData,
}: GeneralInformationProps) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <Typography.Title className="color-white" level={3}>
            General information
          </Typography.Title>
          <Typography.Title level={4}>
            Name new data collection
          </Typography.Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />

          <Form.Item
            labelCol={{ span: 24 }}
            label={<span className="color-white">Name</span>}
            name="name"
            rules={[
              {
                required: true,
                pattern: new RegExp("^[a-zA-Z0-9 ()]*$"),
                message: "Please input survey name!",
              },
            ]}
          >
            <Input placeholder="Enter name" className="inner-color-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={4}>Add description</Typography.Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
          <Form.Item
            labelCol={{ span: 24 }}
            className="color-white"
            name="description"
            label={<span className="color-white">Description</span>}
            rules={[
              {
                required: false,
                message: "Please input survey description!",
              },
            ]}
          >
            <div className="ckSmallHeight">
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={form.getFieldValue("description") || ""}
                onReady={(editor: any) => {
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  form.setFieldsValue({ description: data });

                  onChangeData();
                }}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Space>
  );
};
