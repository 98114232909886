import {useState} from "react";
import { getFirstAndLastNameByUsername } from "../shared/commonFunctions";
import { getAssignedAuditorList } from "./../services/audits";

const initialState = {
  loading : false,
  assignedAuditorsList : [],
};
export const useAssignedAuditorsList = () => {
  const [assignedAuditorsState,setAssignedAuditorsState] = useState(initialState);
  const loadAssignedAuditors = async (auditRecord,record) =>{
    setAssignedAuditorsState(initialState);
    if(!record?._id?.$oid){
      return;
    }
    try{
      setAssignedAuditorsState(prev => ({...prev,loading : true}));
      const mergedListGroupUsers = await getAssignedAuditorList(auditRecord)      
      const standardAuditDetail =  record["audit_details"];

      if(mergedListGroupUsers && mergedListGroupUsers.length){
        try{
          const usersListWithLogs = await Promise.all(mergedListGroupUsers.map(async(username)=>{
            // find if users already logged for audit.
            const getAuditLog = standardAuditDetail && standardAuditDetail.find((logItem) => (logItem.username === username));
            // if log exist for audit id and user which assigned in group then return audit_log_data otherwise null.
            const firstLastName =  await getFirstAndLastNameByUsername(username);
            return {
              username,
              first_last_name:firstLastName,
              audit_log_data : getAuditLog ? getAuditLog : null,
            }
          }));      
          
          setAssignedAuditorsState(prev => ({...prev,assignedAuditorsList:usersListWithLogs}));
          
        }catch(error){
          console.log("Error while geting data lake audit logs list.",error);
        }
      }
    }catch(error){
      console.log("Something went wrong while fetching audits groups users!",error);
    }finally{
        setAssignedAuditorsState(prev => ({...prev,loading : false}));
    }
  }
  

  const getUniqueUsersList = (assignedUsersList)=>{
    if(assignedUsersList && assignedUsersList.length){
      const uniqueUserIds = [];
      const uniqueUserslist = assignedUsersList.filter((item)=>{
        if(uniqueUserIds.indexOf(item.username) < 0){
          uniqueUserIds.push(item.username);
          return item;
        }
        return false;
      });
      return uniqueUserslist;
    }
  }
 
  return {
    ...assignedAuditorsState,
    getUniqueUsersList,
    loadAssignedAuditors
  };
}