import React, { useState, useEffect } from "react";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
  CommonSelect,
} from "../../../shared/commonStyles";
import { stripHtmlTags } from "../../../shared/commonFunctions";
import moment from "moment";
import {
  Form,
  Row,
  Col,
  Typography,
  Divider,
  DatePicker,
  message,
  Select,
} from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import DataSheetSelectionTable from "../../createDataSheetSurvey/components/DataSheetSelectionTable";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const DataSheetsSelection = ({
  onClickNext,
  onClickPrevious,
  rowSelection,
  loadDataSheets,
  dataSheetsList,
}) => {
  const [sheetNamesList, setSheetNamesList] = useState([]);
  const [sheetTypesList, setSheetTypesList] = useState([]);
  const [sheetDescriptionsList, setSheetDescriptionsList] = useState([]);

  const [externalTableFilters, setExternalTableFilters] = useState({});

  useEffect(() => {
    loadAllSheetsDataForDropdown();
  }, []);

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setExternalTableFilters({
        ...externalTableFilters,
        start_date: moment(dateStrings[0]),
        end_date: moment(dateStrings[1]),
      });
    } else {
      setExternalTableFilters({
        ...externalTableFilters,
        start_date: null,
        end_date: null,
      });
    }
  };

  const filterData = dataSheetsList?.filter((item) =>
    !!externalTableFilters?.sheet_name?.length
      ? externalTableFilters?.sheet_name.some(
          (v) => v.toLowerCase() === item.sheet_name.toLowerCase()
        )
      : item && !!externalTableFilters?.description?.length
      ? externalTableFilters?.description?.some(
          (v) => v.toLowerCase() === item?.description?.toLowerCase()
        )
      : item && !!externalTableFilters?.sheet_type?.length
      ? externalTableFilters?.sheet_type?.some(
          (v) => v.toLowerCase() === item?.sheet_type?.toLowerCase()
        )
      : item &&
        (!!externalTableFilters?.start_date || !!externalTableFilters?.end_date
          ? moment(item?.create_date?.$date).format("YYYY-MM-DD") >=
              externalTableFilters?.start_date.format("YYYY-MM-DD") &&
            moment(item?.create_date?.$date).format("YYYY-MM-DD") <=
              externalTableFilters?.end_date.format("YYYY-MM-DD")
          : item)
  );

  const loadAllSheetsDataForDropdown = () => {
    const names = dataSheetsList.map((v) => v.sheet_name);
    const descriptions = dataSheetsList.map((v) => v.description);
    const types = dataSheetsList
      .map((v) => v?.sheet_type)
      .filter((v) => v?.length);
    setSheetNamesList(names);
    setSheetTypesList([...new Set(types)]);
    setSheetDescriptionsList(descriptions);
  };

  const onChangeExternalFilters = async (data, filterName) => {
    setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
  };

  return (
    <FormWrap width="90%">
      <FormWrapper>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Title level={3}>Data Sheets Selection</Title>
          <Title level={4}>
            Select the data sheets required for the current data collection
            survey
          </Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
          <Row gutter={[16, 16]} justify="start">
            <Col xs={24} sm={12} md={6} lg={5}>
              <CommonSelect
                mode="multiple"
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Sheet Name"
                value={externalTableFilters?.sheet_name}
                onChange={(data) => onChangeExternalFilters(data, "sheet_name")}
              >
                {sheetNamesList?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </CommonSelect>
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
              <CommonSelect
                mode="multiple"
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Sheet Type"
                value={externalTableFilters?.sheet_type}
                onChange={(data) => onChangeExternalFilters(data, "sheet_type")}
              >
                {sheetTypesList?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </CommonSelect>
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
              <CommonSelect
                mode="multiple"
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Description"
                value={externalTableFilters?.description}
                onChange={(data) =>
                  onChangeExternalFilters(data, "description")
                }
              >
                {sheetDescriptionsList?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {stripHtmlTags(item)}
                    </Select.Option>
                  );
                })}
              </CommonSelect>
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
              <Form.Item name="rangePicker">
                <RangePicker
                  placeholder={["Start date", "End date"]}
                  onChange={onDateChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            labelCol={{ span: 24 }}
            name="selectedDataSheets"
            label="Data Sheets"
            rules={[{ required: true, message: "Please select Data Sheet!" }]}
          >
            <DataSheetSelectionTable
              rowSelection={rowSelection}
              loadDataSheets={loadDataSheets}
              dataSheetsList={filterData}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="flex-start">
              <StyledButton
                className="margin-5"
                onClick={() => onClickPrevious()}
                type="custom"
              >
                <LeftOutlined /> <span>Previous</span>
              </StyledButton>

              <StyledButton
                className="margin-5"
                onClick={() => onClickNext()}
                type="custom"
              >
                <span>Continue to step 5</span> <RightOutlined />
              </StyledButton>
            </Row>
          </Form.Item>
        </Col>
      </FormWrapper>
    </FormWrap>
  );
};

export default DataSheetsSelection;
