import React, { useEffect, useState } from "react";
import {  
  Select,
  message,
  Spin,
  Form
} from "antd";

import { getUsersGroupList } from "../../../services/users";
import { capitalizeFirstLetter } from "../../../shared/constants";

const UsersGroupList = () => {
  const [loader, setLoader] = useState(false);  
  const [usersGroupList, setUsersGroupList] = useState([]);
  const [selectedGroupsList, setSelectedGroupsList] = useState([]);
  const [groupLoader, setGroupLoader] = useState(false);
  

  const loadData = async () => {
    try {
      setLoader(true);
      const usersGroupList = await getUsersGroupList();
      if (usersGroupList) {
        setUsersGroupList(usersGroupList);
        setSelectedGroupsList(usersGroupList)
      }
      
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const searchGroups = (value) => {
    setGroupLoader(true)
    if(value){
      const selectedGroups = usersGroupList?.filter(item => {
        return item?.label?.includes(value) || item?.label?.includes(capitalizeFirstLetter(value))
      })
      setSelectedGroupsList(selectedGroups ? selectedGroups : [])
    }else{
      setSelectedGroupsList(usersGroupList)
    }
    setGroupLoader(false)
  }
  return (    
    <Spin spinning={loader}> 
      <Form.Item
        labelCol={{ span: 24 }}
        label="Assign a group"
        name="groupIds"
      >
        <Select
          mode="multiple"
          notFoundContent={groupLoader ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={searchGroups}
          placeholder="Please select a group"
          showArrow
          style={{ width: "100%" }}
          options={selectedGroupsList}
        />
      </Form.Item>
    </Spin>            
  );
};

export default UsersGroupList;
