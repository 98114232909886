import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import { DatalakeQuery } from "../graphql/API";
import { GraphQLResult } from "@aws-amplify/api";

export const listReleaseNotes = async (request_payload: any) => {
  const result = await (API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "list_release_notes",
      request_payload: JSON.stringify(request_payload),
    },
  }) as Promise<GraphQLResult<DatalakeQuery>>);
  return JSON.parse(result.data?.datalake || "[]") as any;
};

export const getReleaseNotes = async (requestPayload: any) => {
  const result = await (API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "get_release_note",
      request_payload: JSON.stringify(requestPayload),
    },
  }) as Promise<GraphQLResult<DatalakeQuery>>);
  return JSON.parse(result.data?.datalake || "[]") as any;
};
