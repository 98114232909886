import React, { useState, useEffect } from "react";
import { Spin, message} from "antd";

import { StyledProgress } from "../../../shared/commonStyles";
import { getDeploymentMetrics } from "./../../../services/taskDeployment";

const DeployProgressBar = ({ surveyId }) => {
  const [loader, setLoader] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const getDeploySurveyMetrics = async() => {
    try {
      setLoader(true)
      const deploysurveyMetrics = await getDeploymentMetrics(surveyId)
      if(deploysurveyMetrics){
        const metricsData = JSON.parse(deploysurveyMetrics)
        setPercentage(metricsData?.progress_percentage ? metricsData?.progress_percentage : 0)
      }      
    } catch (e) {
      message.error("Failed to load progress  data");
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {  
    if(surveyId){
      getDeploySurveyMetrics()      
    }
  }, [surveyId])

  

  return (
    <Spin spinning={loader}>
      <StyledProgress percent={percentage} status="active" />     
    </Spin>
  )
}

export default DeployProgressBar;