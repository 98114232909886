import { Tag, message, Switch, Dropdown, Menu } from "antd";
import {
  CommonTable,
  StyledButton,
  PageWrap,
  FormWrapper,
  TableStyledMenu,
} from "../../shared/commonStyles";
import {
  ApartmentOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  getAnalyticsDashboardCharts,
  deleteAnalyticsDashboardCharts,
  updateToggleStatus,
} from "../../services/analyticsChart";

import { getCompanyInfo } from "../../services/companyInfo";

import React, { useEffect, useState } from "react";
import UpdateDashboardModal from "../../components/Modals/AnalyticsChartModal/UpdateDashboardModal";
import ConfirmModal from "../../components/Modals/AnalyticsChartModal/ConfirmModal";
import AnalyticsBaseUrlForm from "../../components/CustomFormBuilder/AnalyticsBaseUrlForm";
import AssignPermissionsModal from "../../components/Modals/AnalyticsChartModal/AssignPermissionsModal";

const AnalyticsManageDashboards = () => {
  const [loadingDataSheetsAnalytics, setLoadingDataSheetsAnalytics] =
    useState();
  const [dataSource, setDataSource] = useState([]);
  const [showUpdateDashboardModal, setShowUpdateDashboardModal] =
    useState(false);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] =
    useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [updateDashboardModalData, setUpdateDashboardModalData] = useState([]);
  const [rowDataId, setRowDataId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [assignPermissionsModal, setAssignPermissionsModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  /* calling api */
  const loadAnalyticsData = async () => {
    setLoadingDataSheetsAnalytics(true);
    try {
      const response = await getAnalyticsDashboardCharts();
      const dataAnalyticsResponse = response.data || []; // Extract the data field from the response, or assign an empty array if it's not available
      setDataSource(dataAnalyticsResponse);
    } catch (error) {
      console.log("Error occurred while fetching analytics data:", error);
    } finally {
      setLoadingDataSheetsAnalytics(false);
    }
  };

  useEffect(() => {
    loadAnalyticsData();
  }, []);

  useEffect(() => {
    const loadBaseUrl = async () => {
      const baseUrlResponse = await getCompanyInfo();
      if (baseUrlResponse) {
        const companyDetailsData = JSON.parse(baseUrlResponse["data"]);
        setCompanyDetails(companyDetailsData);
      }
    };
    loadBaseUrl();
  }, []);

  useEffect(() => {
    if (companyDetails !== null && !companyDetails?.chart_base_url) {
      setVisible(true);
    }
  }, [companyDetails]);

  useEffect(() => {
    const chartCategory = dataSource.map((e) => e.section);
    setCategoryList(chartCategory);
  }, [dataSource]);

  const uniqueCat = [...new Set(categoryList)];

  const columns = [
    {
      title: "Name",
      dataIndex: "chart_name",
      index: "chart_name",
    },
    {
      title: "Category",
      dataIndex: "section",
      render: (index, record) => (
        <>
          <Tag
            className="tag-rounded"
            style={{ backgroundColor: "#A68DFB", color: "white" }}
          >
            {record.section}
          </Tag>
        </>
      ),
    },
    {
      title: "Dashboard ID",
      dataIndex: "chart_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, rowDataSwitch) => {
        return (
          <>
            <Switch
              onChange={(checked) => handleChartStatus(checked, rowDataSwitch)}
              checked={status === "ACTIVE" ? true : false}
            />
            <div style={{ display: "inline-block", marginLeft: "4px" }}>
              {rowDataSwitch.status}
            </div>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      render: (index, record) => (
        <>
          <StyledButton
            type="default"
            onClick={() => onAnalyticsDashboardEdit(record)}
          >
            <EditOutlined
              style={
                {
                  /* width: 20, fontSize: "20px"*/
                }
              }
            />
          </StyledButton>
          <StyledButton
            type="default"
            onClick={() => onAnalyticsDashboardDelete(record)}
          >
            <DeleteOutlined
              style={
                {
                  /* width: 20, fontSize: "20px" */
                }
              }
            />
          </StyledButton>
          <Dropdown overlay={() => menuButtons(record)} trigger={["click"]}>
            <StyledButton
              type="default"
              style={{
                marginLeft: "10",
                padding: "10px 18px",
              }}
            >
              <EllipsisOutlined />
            </StyledButton>
          </Dropdown>
        </>
      ),
    },
  ];

  const onAnalyticsDashboardEdit = (record) => {
    setUpdateDashboardModalData(record);
    setShowUpdateDashboardModal(true);
  };

  const onAnalyticsDashboardDelete = (record) => {
    setRowDataId(record["_id"]);
    setShowDeleteDashboardModal(true);
  };

  const onConfirmDeleteDashboard = async () => {
    try {
      setConfirmLoading(true);
      const payload = {
        _id: rowDataId,
      };

      const deleteChartResponse = await deleteAnalyticsDashboardCharts(payload);

      if (deleteChartResponse) {
        message.success("Dashboard Deleted Successfully!");
        setShowDeleteDashboardModal(false);
        loadAnalyticsData();
        setConfirmLoading(false);
      } else {
        message.error("Failed to delete Dashboard!");
      }
    } catch (error) {
      message.error(
        `Something went wrong while updating analytics Dashboard! ${error}`
      );
    }
  };

  const onFinishUpdateDashboard = () => {
    setShowUpdateDashboardModal(false);
  };

  const handleChartStatus = async (status, rowDataSwitch) => {
    setLoadingDataSheetsAnalytics(true);
    rowDataSwitch.status = status ? "ACTIVE" : "INACTIVE";

    const toggleResponse = await updateToggleStatus(rowDataSwitch);
    if (toggleResponse) {
      setLoadingDataSheetsAnalytics(false);
      message.success("Status changed successfully!");
      loadAnalyticsData();
    }
  };
  const onClickAssignPermissions = (record) => {
    setAssignPermissionsModal(true);
    setRowData(record);
  };

  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        onClick={() => onClickAssignPermissions(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<ApartmentOutlined />}
        >
          Permissions
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );
  return (
    <>
      <PageWrap>
        {visible ? (
          <AnalyticsBaseUrlForm companyDetails={companyDetails} />
        ) : (
          <FormWrapper>
            <CommonTable
              loading={loadingDataSheetsAnalytics}
              rowKey="id"
              dataSource={dataSource}
              columns={columns}
              rowClassName="dataSheetsTable"
              scroll={{ x: true }}
            />
          </FormWrapper>
        )}
        <UpdateDashboardModal
          isVisible={showUpdateDashboardModal}
          onClose={() => setShowUpdateDashboardModal(false)}
          onFinish={onFinishUpdateDashboard}
          catlist={uniqueCat}
          rowData={updateDashboardModalData}
          refresh={loadAnalyticsData}
        />
        <ConfirmModal
          description="Are you sure you want to delete this dashboard?"
          isVisible={showDeleteDashboardModal}
          onClose={() => setShowDeleteDashboardModal(false)}
          onConfirm={onConfirmDeleteDashboard}
          confirmLoading={confirmLoading}
        />
        {assignPermissionsModal && (
          <AssignPermissionsModal
            isVisible={assignPermissionsModal}
            onClose={() => setAssignPermissionsModal(false)}
            rowData={rowData}
            refresh={loadAnalyticsData}
          />
        )}
      </PageWrap>
    </>
  );
};

export default AnalyticsManageDashboards;
