import { API, graphqlOperation } from "aws-amplify";
import {
  byAuditManagementAuditType,
  datalake,
  datalake_job,
} from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { DatalakeQuery } from "../graphql/API";

class CalculationsService {
  async makeRequest(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    return JSON.parse(response.data?.datalake || "{}");
  }
  async makeRequestJob(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake_job, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    const resp = response?.data?.datalake_job;
    const responseMatch = resp?.match(/job_id=([^;\n]+)/);
    const responseName = responseMatch
      ? responseMatch[1]?.replace(/}/g, "")
      : null;
    return responseName;
  }

  public async recalculateCalculationsFilteredByIdentifiers(payload: any) {
    return await this.makeRequest(
      "recalculated_calculations_filtered_by_identifiers",
      payload
    );
  }
  public async savedCalculationList(payload?: ListQuery) {
    return await this.makeRequest("list_calculations", payload);
  }

  public async getGHGEmissionsAudits() {
    const auditList = await (API.graphql(
      graphqlOperation(byAuditManagementAuditType, {
        audit_type: "GHG_EMISSIONS",
      })
    ) as Promise<GraphQLResult<{ byAuditManagementAuditType: any }>>);
    return auditList.data?.byAuditManagementAuditType.items;
  }

  public async removeCalculation(id: string) {
    return await this.makeRequest("remove_calculation_new", {
      _id: { $oid: id },
    });
  }

  public async addCalculation(payload: ICalculation) {
    return await this.makeRequest("add_calculation_new", payload);
  }

  public async duplicateCalculation(calculation_id: string) {
    return await this.makeRequest("duplicate_calculation_new", {
      calculation_id,
    });
  }

  public async refreshCalculations(payload?: ListQuery) {
    return await this.makeRequest("refresh_calculations_new", payload);
  }
  public async refreshCalculations_job(payload?: ListQuery) {
    return await this.makeRequestJob("refresh_calculations_new", payload);
  }
}
export default new CalculationsService();
