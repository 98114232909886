import React, { useState } from "react";
import {
  Button,
  Col,
  InputNumber,
  Radio,
  Row,
  Slider,
  Space,
  Typography,
} from "antd";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import { SliderMarks } from "antd/lib/slider";

interface RecModalProps {
  visible: boolean;
  defaultRecAmount: number;
  defaultPercentageRecValue: number;
  emissionName: string;
  emissionTotal: number;
  onConfirm: (recAmount: number, recPercent: number) => void;
  onCancel: () => void;
}

export const RecModal = ({
  visible,
  emissionName,
  emissionTotal,
  defaultRecAmount,
  defaultPercentageRecValue,
  onConfirm,
  onCancel,
}: RecModalProps) => {
  const [recType, setRecType] = useState("percent");
  const [percentageRecValue, setPercentageRecValue] = useState(
    defaultPercentageRecValue * 100
  );
  const [recValue, setRecValue] = useState(defaultRecAmount);

  const marks: SliderMarks = {
    0: "0",
    25: "25%",
    75: "75%",
    100: "100%",
  };

  const handleChangeRecValue = (value: number) => {
    setRecValue(value);
    setPercentageRecValue((value / emissionTotal) * 100);
  };

  const handleChangePercentageRecValue = (value: number) => {
    setPercentageRecValue(value);
    setRecValue((emissionTotal * value) / 100);
  };

  const handleSave = () => {
    onConfirm(recValue, percentageRecValue / 100);
  };

  return (
    <CommonModal
      visible={visible}
      title="Amount of RECs"
      onCancel={onCancel}
      onOk={onConfirm}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      centered
    >
      <div>
        <Typography.Text>
          Indicate the amount of RECs in <b>{emissionName}</b>
        </Typography.Text>
      </div>
      <FormWrapper>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Radio.Group
            value={recType}
            onChange={(e) => setRecType(e.target.value)}
          >
            <Space>
              <Radio value="percent">%</Radio>
              <Radio value="value">Value</Radio>
            </Space>
          </Radio.Group>
          {recType === "percent" ? (
            <Row gutter={16}>
              <Col span={15}>
                <Slider
                  value={percentageRecValue}
                  onChange={handleChangePercentageRecValue}
                  marks={marks}
                />
              </Col>
              <Col span={9}>
                <InputNumber
                  value={percentageRecValue}
                  onChange={handleChangePercentageRecValue}
                  addonAfter="%"
                />
              </Col>
            </Row>
          ) : (
            <InputNumber value={recValue} onChange={handleChangeRecValue} />
          )}
        </Space>
      </FormWrapper>
    </CommonModal>
  );
};
