import React from "react";
import {
  StyledButton,  
  FormWrap,
} from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { 
  Row,
  Col,
  Typography,
} from "antd";

import {   
  CheckCircleFilled
} from "@ant-design/icons";

const { Title, } = Typography;

const Step4 = () => {    
  const navigate = useNavigate();
  return (  
    <>            
      <FormWrap width="600px" style = {{ backgroundColor: '#38304F', marginTop : '50px'}}>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <CheckCircleFilled style={{fontSize: '50px',color: 'rgb(166, 141, 251)', margin : '20px 0px' }} />
              <Title className="color-white" level={4}>
                Data was saved successfully
              </Title>
              <p style={{padding: '0px 81px',fontSize: '12px'}}>
                You can review and update it anytime
              </p>
                <Row justify="center" style={{marginBottom : '20px'}}>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      navigate("/data/import-data")
                    }
                    bgcolor = '#7F5FEE'
                    borderColor = '#7F5FEE'
                  >
                    <span>Go to All Imports</span>
                  </StyledButton>
                </Row>
            </div>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
}

export default Step4;