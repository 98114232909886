import React from "react";
import {InputNumber, Input, Form, DatePicker} from 'antd';
import { YearMonthDayFormat } from "../../../../../shared/constants";

export const EditableCell = ({
    editing,
    key,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputType = record?.['data']?.[dataIndex]?.type;
    const format = record?.['data']?.[dataIndex]?.format;
    let inputNode = null;
    if( inputType === 'number'){
       inputNode = <InputNumber type="number"/> ;
    }else if(format === 'date' && inputType === 'string'){
      inputNode = <DatePicker format={YearMonthDayFormat}/>;
    }
    else{
       inputNode = <Input />;
    }
   
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };