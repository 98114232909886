import React, { useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  FormWrapper,
  StyledButton,
  TableCommonMenu,
} from "../../../../shared/commonStyles";
import styles from "../DataSheetDocuments/DataSheetDocuments.module.scss";
import { Checkbox, Row, Switch } from "antd";
import { MenuOutlined } from "@ant-design/icons";

interface ManageDataSheetColumnsProps {
  dataSheet?: DataSheet;
  onSortEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  setManageColumnsVisible: (isVisible: boolean) => void;
  manageColumnData: ManageColumnData[];
  handleSwitch: (checked: boolean) => void;
  handleMassCheck: (checked: boolean) => void;
  onCheckedItem: (value: any) => void;
}
export const ManageDataSheetColumns = ({
  dataSheet,
  onSortEnd,
  setManageColumnsVisible,
  manageColumnData,
  handleSwitch,
  handleMassCheck,
  onCheckedItem,
}: ManageDataSheetColumnsProps) => {
  const [metadataCheck, setMetadataCheck] = useState(true);
  useEffect(() => {
    const metadata = dataSheet?.sheet_schema.filter(
      (schema) => schema.metadata_entity_name
    );
    const isMetadataVisible =
      manageColumnData
        .filter((v) =>
          metadata?.find((item) => item.entity_name === v.dataIndex)
        )
        .filter((v) => !v.hide).length === metadata?.length;

    setMetadataCheck(isMetadataVisible);
  }, [manageColumnData]);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined className={styles.menu} />
  ));
  const SortableItem = SortableElement(({ value }: { value: any }, key) => {
    return (
      <li className={styles.sortableItem} tabIndex={0}>
        <TableCommonMenu>
          <StyledButton type="custom" className={styles.sortableButton}>
            <Checkbox
              value={value.dataIndex}
              onChange={onCheckedItem}
              className={`rowCheckColor ${styles.checkbox}`}
              checked={!value.hide}
            >
              {value.name}
            </Checkbox>
            <DragHandle />
          </StyledButton>
        </TableCommonMenu>
      </li>
    );
  });
  const SortableList = SortableContainer(() => {
    return (
      <FormWrapper className={styles.sortableWrap}>
        {dataSheet?.sheet_schema.find(
          (schema) => schema.metadata_entity_name
        ) && (
          <Row className={styles.sortableSwitch}>
            <Switch onChange={handleSwitch} checked={metadataCheck} />
            <span>
              {metadataCheck
                ? "Reference data is visible"
                : " Reference data is hidden"}
            </span>
          </Row>
        )}
        <Row>
          <StyledButton
            type="custom"
            style={{ width: "100%", height: "30px", lineHeight: "10px" }}
            onClick={() => setManageColumnsVisible(false)}
          >
            Close
          </StyledButton>
        </Row>
        {!!manageColumnData?.filter((item) => !item.hide)?.length && (
          <Row>
            <StyledButton
              type="custom"
              style={{ width: "100%", height: "30px", lineHeight: "10px" }}
              onClick={() => {
                handleMassCheck(false);
                setMetadataCheck(false);
              }}
            >
              Deselect all
            </StyledButton>
          </Row>
        )}
        {!!manageColumnData?.filter((item) => item.hide)?.length && (
          <Row>
            <StyledButton
              type="custom"
              style={{ width: "100%", height: "30px", lineHeight: "10px" }}
              onClick={() => {
                handleMassCheck(true);
              }}
            >
              Select all
            </StyledButton>
          </Row>
        )}
        <ul className={styles.sortableList} style={{ zIndex: 10 }}>
          {manageColumnData
            ?.filter((item) => !item.dataIndex.includes("_unit_id"))
            .map((value, index: number) => (
              <SortableItem key={index} index={index} value={value} />
            ))}
        </ul>
      </FormWrapper>
    );
  });
  return <SortableList onSortEnd={onSortEnd} />;
};
