import { makeAutoObservable, runInAction } from "mobx";
import calculatorService from "../../services/CalculatorService";
import { message } from "antd";
import { calculationStore } from "./CalculationsStore";
import { removeNullKeys } from "./CalculationUtils";

class CalculatorStore {
  calculationId: string;
  calculation: ICalculation;
  emissions: Emission[];
  archivedEmisssions: Emission[];
  offsets: Offset[];
  archivedOffsets: Offset[];
  lastDeletedEmission: {
    emission: Emission;
    index: number;
  };
  regions: string[];
  loadingCalculation: boolean;
  loadingCalculationItems: boolean;
  loadingDrillDown: boolean;
  calculationItemsFilter: CalculationItemsFilter | null;
  selectedIdentifiers: string[];
  archivedItems: string[];
  constructor() {
    this.calculationId = "";
    this.calculation = {} as ICalculation;
    this.emissions = [];
    this.offsets = [];
    this.lastDeletedEmission = {
      emission: {} as Emission,
      index: 0,
    };
    this.regions = [];
    this.loadingCalculation = false;
    this.loadingCalculationItems = false;
    this.loadingDrillDown = false;
    this.calculationItemsFilter = {
      scope_1: {},
      scope_2: {},
      scope_3: {},
      offsets: {},
    };
    this.selectedIdentifiers = [];
    this.archivedEmisssions = [];
    this.archivedOffsets = [];
    this.archivedItems = [];
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get savedScope1Emissions() {
    return this.emissions.filter(
      (emission) => emission.scope === ScopeName.SCOPE_1
    );
  }

  get savedScope2Emissions() {
    return this.emissions.filter(
      (emission) => emission.scope === ScopeName.SCOPE_2
    );
  }

  get savedScope3Emissions() {
    return this.emissions.filter(
      (emission) => emission.scope === ScopeName.SCOPE_3
    );
  }

  resetState() {
    this.calculationId = "";
    this.calculation = {} as ICalculation;
    this.emissions = [];
    this.offsets = [];
    this.lastDeletedEmission = {
      emission: {} as Emission,
      index: 0,
    };
    this.regions = [];
    this.calculationItemsFilter = {
      scope_1: {},
      scope_2: {},
      scope_3: {},
      offsets: {},
    };
    this.selectedIdentifiers = [];
  }

  getCalculationById(id?: string, isShownLoader = true): Promise<ICalculation> {
    this.calculationItemsFilter = {
      scope_1: {},
      scope_2: {},
      scope_3: {},
      offsets: {},
    };
    if (id) this.calculationId = id;
    return new Promise<ICalculation>((resolve, reject) => {
      isShownLoader && this.changeLoadingState(true);
      calculatorService.getCalculationById(this.calculationId).then((calc) => {
        const isError = calc?.statusCode >= 400;

        runInAction(() => {
          if (!isError) {
            this.calculation = calc;
            this.getCalculationItems();
            this.regions = calc.regions?.map(
              (v: { key: string; name: string }) => v.name
            );
          }

          this.changeLoadingState(false);

          if (isError) {
            reject(new Error("No calculation with id " + id));
            message.error(calc?.body);
          } else resolve(calc);
        });
      });
    });
  }

  getCalculationItems(): Promise<CalculationItem[]> {
    const filters = this.calculationItemsFilter || {
      scope_1: {},
      scope_2: {},
      scope_3: {},
      offsets: {},
    };
    const payload = {
      identifiers: this.selectedIdentifiers || [],
      filters: filters,
      calculation_id: this.calculationId,
    };
    return new Promise<CalculationItem[]>((resolve, reject) => {
      this.changeLoadingItemsState(true);
      calculatorService
        .getCalculationItems(payload)
        .then((result) => {
          const items = [...result?.filtered_items, ...result?.deleted_items];
          runInAction(() => {
            this.emissions = items?.filter(
              (v: CalculationItem) => v.item_type === "emission"
            );

            this.offsets = items?.filter(
              (v: CalculationItem) => v.item_type === "offset"
            );

            this.calculation = {
              ...this.calculation,
              total_calculate: result.total_calculate,
            };

            // this.changeLoadingItemsState(false);
          });
        })
        .finally(() => this.changeLoadingItemsState(false));
    });
  }

  updateCalculation = async (
    calculationPayload: ICalculation,
    applyDateFilters: boolean
  ) => {
    this.calculation = calculationPayload;
    calculatorService
      .updateCalculation(this.calculation, applyDateFilters)
      .then(() => {
        this.getCalculationById();
        calculationStore.fetchCalculations();
      });
  };
  updateCalculationJob = async (
    calculationPayload: ICalculation,
    applyDateFilters: boolean
  ) => {
    this.calculation = calculationPayload;
    return calculatorService
      .updateCalculationJob(this.calculation, applyDateFilters)
      .then((res) => {
        this.getCalculationById();
        calculationStore.fetchCalculations();
        return res;
      });
  };

  partialUpdateCalculation = async (payload: any) => {
    this.calculation = payload;
    calculatorService.partialUpdateCalculation(payload).then(() => {
      this.calculationId && this.getCalculationById();
      calculationStore.fetchCalculations();
    });
  };

  editEmission(emission: Emission) {
    this.emissions[this.emissions.findIndex((el) => el.key === emission.key)] =
      calcTotalEmission([emission])[0];
    this.emissions = [...this.emissions];
  }

  // calls the service (actual database update)
  saveCalculationEntries = async (entries: Emission[] | Offset[]) => {
    calculatorService
      .addCalculationEntries(this.calculationId, entries)
      .then(() => {
        this.getCalculationById();
      });
  };

  editCalculationEntry = (entry: Emission | Offset) => {
    this.changeLoadingItemsState(true);
    const newEntry = { ...entry };
    delete (newEntry as AutomatedEmission).children;
    return calculatorService
      .updateCalculationEntry(newEntry, this.calculation.attribution_factor)
      .then(() => {
        this.getCalculationItems();
      });
  };

  editPartialCalculationEntry = (entry: Emission | Offset) => {
    this.changeLoadingItemsState(true);
    return calculatorService
      .partialUpdateCalculationEntry(entry)
      .then(() => this.getCalculationItems());
  };

  editCalculationStepperEntry = (entry: Emission | Offset) => {
    if (entry.item_type === "emission") {
      const updatedEmissions = this.emissions.map((v: Emission) => {
        if (v.key === entry.key) {
          return entry;
        } else {
          return v;
        }
      });
      this.emissions = [...(updatedEmissions as Emission[])];
    }
    if (entry.item_type === "offset") {
      const updatedOffsets = this.offsets.map((v: Offset) => {
        if (v.key === entry.key) {
          return entry;
        } else {
          return v;
        }
      });
      this.offsets = [...(updatedOffsets as Offset[])];
    }
  };

  // calcaulates total emissions only
  saveNewEmissions(emissions: Emission[], scopeName: ScopeName) {
    const newEmissions = emissions.map((emission) => ({
      ...emission,
      scope: scopeName,
      item_type: "emission",
    }));
    // doesn't get used when saving total emissions in backend
    const calcEmissions = calcTotalEmission(newEmissions as Emission[]);
    this.emissions = [...this.emissions, ...calcEmissions];
  }

  clearScopeEmission(scopeName: ScopeName) {
    this.emissions = this.emissions.filter(
      (emission) => emission.scope != scopeName
    );
  }

  saveDeletedEmission(emission: Emission) {
    const index = this.emissions.findIndex((v) => v.key == emission.key);
    this.lastDeletedEmission = {
      emission,
      index,
    };
  }

  returnDeletedEmission() {
    this.emissions.splice(
      this.lastDeletedEmission.index,
      0,
      this.lastDeletedEmission.emission
    );

    this.emissions = [...this.emissions];

    this.lastDeletedEmission = { ...this.lastDeletedEmission };
  }

  deleteEmission(emission: Emission) {
    this.emissions = this.emissions.filter((v) => v.key !== emission.key);
  }

  restoreAllRecords() {
    const items = [...this.emissions, ...this.offsets]
      .filter((v) => v.archived)
      .map((v) => ({ ...v, archived: false }));
    Promise.all(
      items.map((item) => {
        return calculatorService.updateCalculationEntry(
          item,
          this.calculation.attribution_factor
        );
      })
    ).then(() => this.getCalculationById());
  }
  deleteDeletedCalcItem(item: Emission | Offset) {
    calculatorService
      .deleteItems({
        items: [item?._id?.$oid || ""],
        calculation_id: item.calculation_id?.$oid || "",
      })
      .then(() => {
        this.emissions = this.emissions.filter(
          (v) => v._id?.$oid !== item._id?.$oid
        );
        this.offsets = this.offsets.filter(
          (v) => v._id?.$oid !== item._id?.$oid
        );
      });
  }

  deleteAllRecords() {
    this.changeLoadingItemsState(true);
    const itemsIds = [...this.emissions, ...this.offsets]
      .filter((item) => item.archived)
      .map((v: Emission | Offset) => {
        return v?._id?.$oid || "";
      });
    calculatorService
      .deleteItems({ items: itemsIds, calculation_id: this.calculationId })
      .then(() => {
        this.getCalculationItems();
      });
  }

  async getAutomatedConsumptionAmount(emission: AutomatedEmission) {
    const record = await calculatorService.getAutomatedConsumptionAmount(
      emission
    );
    return record;
  }

  async aggregateDrillDownRecords(
    drilldownFilter: DrillDownFilter,
    filters: AdvancedDataFilter[],
    aggredateField: string
  ) {
    this.loadingDrillDown = true;
    const payload = {
      sheet_id: drilldownFilter.dataSheetId,
      aggregate_field: aggredateField,
      identifiers: this.selectedIdentifiers || [],
      group_field: drilldownFilter.column,
      filters_list: filters,
      ...(drilldownFilter.column_value && {
        time_period: drilldownFilter.column_value,
      }),
    };
    return await calculatorService
      .aggregateRecordsForDrillDown(payload)
      .then((result) => {
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error("Something went wrong. Please contact support");
          this.loadingDrillDown = false;
          return [];
        }
        this.loadingDrillDown = false;
        return result;
      });
  }

  async massAggregateDrillDownRecords(): Promise<string> {
    const payload = {
      calculation_id: this.calculationId,
      drill_down_filters: this.calculation.drilldownFilters,
      identifiers: this.selectedIdentifiers || [],
    };
    return await calculatorService
      .massAggregateRecordsForDrillDown_job(payload)
      .then((result) => {
        if (result) {
          const isError =
            (result as { statusCode?: number })?.statusCode! >= 400;
          if (isError) {
            message.error("Something went wrong. Please contact support");
            this.loadingDrillDown = false;
            return "";
          }

          this.loadingDrillDown = false;
          return result;
        } else {
          this.loadingDrillDown = false;
          return "";
        }
      });
  }

  editScopeConsumptionAmount(
    emissionKey: string,
    consumptionAmount: number,
    callback?: (emissions: Emission[]) => void
  ) {
    const index = this.emissions.findIndex(
      (emission) => emission.key === emissionKey
    );
    this.emissions[index].consumption_amount = consumptionAmount;
    const totalEmission = calcTotalEmission([...this.emissions]);
    this.emissions = [...totalEmission];
    callback?.(this.emissions);
  }

  editConsumptionAmount(emissionId: string, consumptionAmount: number) {
    const index = this.emissions.findIndex(
      (item) => item._id?.$oid === emissionId
    );
    const isOverride = this.emissions[index].override;

    this.emissions[index].consumption_amount = consumptionAmount;

    this.emissions = [...this.emissions];
    return this.emissions;
  }

  editCustomName(emissionId: string, customName: string) {
    const index = this.emissions.findIndex(
      (item) => item._id?.$oid === emissionId
    );
    this.emissions[index].custom_name = customName;
    this.emissions = [...this.emissions];
    return this.emissions;
  }

  saveSelectedRegions(regions: string[]) {
    runInAction(() => {
      this.regions = regions;
    });
  }

  changeCalculationItemsFilter(
    filter: { [key: string]: string[] } | null,
    scopeName: ScopeName
  ) {
    this.calculationItemsFilter = {
      ...this.calculationItemsFilter,
      [scopeName]: filter || {},
    };
  }

  changeLoadingState(isLoading: boolean) {
    this.loadingCalculation = isLoading;
  }
  changeSelectedIdentifiers(identifiers: string[]) {
    this.selectedIdentifiers = identifiers;
  }
  getSelectedIdentifiers() {
    return this.selectedIdentifiers;
  }
  changeLoadingItemsState(isLoading: boolean) {
    this.loadingCalculationItems = isLoading;
  }

  addOffset(offset: Offset) {
    this.offsets = [...this.offsets, { ...offset, item_type: "offset" }];
  }
  editNewOffset(offset: Offset) {
    const updatedOffsets = this.offsets;
    updatedOffsets[this.offsets.findIndex((el) => el.key === offset.key)] =
      offset;
    this.offsets = updatedOffsets;
  }
  deleteNewOffset(offset: Offset) {
    const updatedOffsets = this.offsets.filter((v) => v.key !== offset.key);
    this.offsets = updatedOffsets;
  }

  async uploadOffsetProof(file: File, proofId: string) {
    const signedURL = await calculatorService.uploadOffsetProof(
      proofId,
      file.name
    );
    const arrayBufferData = await file.arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBufferData)], {
      type: file.type,
    });

    await fetch(signedURL, {
      method: "PUT",
      body: blob,
    });
  }

  async downloadOffsetProof(offset: Offset) {
    if (offset.uploadedProofId && offset.proofName) {
      const signedURL = await calculatorService.downloadOffsetProof(
        offset.uploadedProofId,
        offset.proofName
      );
      const resp = await fetch(signedURL);
      const imageBody = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([imageBody]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", offset.proofName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  }

}


export const calculate_emissions_total = (
    consumption_amount: number,
    factor: number,
    multiplication_factor: number | undefined,
    custom_unit_conversion_factor: number | undefined,
): number  => {
  let new_consumption_amount = consumption_amount;
  /* we won't do multiplicaton here we will let backend do it. we may need to do consumption amount calculation if there is a recacluate */
  /*
  if (multiplication_factor !== undefined) {
      new_consumption_amount = consumption_amount * multiplication_factor;
  }
  */

  if (custom_unit_conversion_factor !== undefined) {
      return custom_unit_conversion_factor * new_consumption_amount;
  } else {
      return factor * new_consumption_amount;

  }
  return 0;
};

// calculates totalemissions from list of emissions
const calcTotalEmission = (emissions: Emission[]): Emission[] => {
  try {
    const savedEmissions = emissions.map((emission: Emission) => {
      const factor = Number(emission?.factor_data?.factor) || 0;
      const consumption_amount = Number(emission?.consumption_amount) || 0;

      // do calcTotalEmission
      // need to add custom factor data here and/or unit conversion
      const total_emission = {
        co2e_total: calculate_emissions_total(consumption_amount, factor, emission?.datasheet_unit_conversion_factor, emission?.factor_data?.custom_unit_conversion_factor).toFixed(8)
      };

      return { ...emission, total_emission };
    });

    return savedEmissions as Emission[];
  } catch (e) {
    console.log("Something went wrong!", e);
    return [] as Emission[];
  }
};

const calculatorStore = new CalculatorStore();

export { calculatorStore };
