import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useBoolean } from "ahooks";
import moment from "moment";

import {
  Row,
  Spin,
  message,
  Space,
  Col,
  Typography,
  Select,
  Tag,
  Dropdown,
  Menu,
  Pagination,
  DatePicker,
  Form,
  Progress,
} from "antd";
import { purple } from "@ant-design/colors";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../contexts/AuthContext";
import {
  TableHeaderRightTabWrap,
  TableWrap,
  FiltersContainer,
  PageWrap,
  StyledButton,
  CommonTable,
  CommonSelect,
  CardsWrap,
  TableStyledMenu,
  FormWrapper,
} from "./../../shared/commonStyles";
import {
  EyeOutlined,
  EditOutlined,
  FileDoneOutlined,
  UsergroupDeleteOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  EllipsisOutlined,
  CopyOutlined,
  DeleteOutlined,
  RollbackOutlined,
  SwapOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import AssignPermissionsModal from "./Components/AssignPermissionsModal/AssignPermissionsModal";
import { useUserSettings } from "../../hooks/useUserSettings";
import { YearMonthDayFormat } from "../../shared/constants";
import {
  getDataSheetsWithPagination,
  updateDataSheet,
  getDatasheetFilters,
} from "../../services/dataSheet";
import { stripHtmlTags } from "../../shared/commonFunctions";
import ConfirmArchiveModal from "./Components/ConfirmArchiveModal";
import { SortDropdown } from "../../components/SortDropdown";
import ConfirmRestoreModal from "./Components/ConfirmRestoreModal";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { encodeUrlName } from "../../shared/commonFunctions";
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DataSheetsPage = ({ isDeleted }) => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 12 });
  const [toggleListCardView, setToggleListCardView] = useState(true);
  const [loadingdataSheetsList, setLoadingDataSheetsList] = useState(false);
  const [refreshSheetsList, setRefreshSheetsList] = useState(1);
  const [rowData, setRowData] = useState(null);
  const [startDate, setStartDate] = useState(
    localStorage.getItem("sheetStartDate")
      ? moment(localStorage.getItem("sheetStartDate")).format("DD-MM-YYYY")
      : null
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem("sheetEndDate")
      ? moment(localStorage.getItem("sheetEndDate")).format("DD-MM-YYYY")
      : null
  );
  const getFiltersFromStorage = () => {
    const filters = JSON.parse(localStorage.getItem("dataSheetfilteredInfo"));
    let newFilters = { ...filters };
    if (filters.sheet_desc) {
      newFilters.description = newFilters.sheet_desc;
      delete newFilters.sheet_desc;
    }
    return newFilters;
  };
  const [externalTableFilters, setExternalTableFilters] = useState(
    localStorage.getItem("dataSheetfilteredInfo")
      ? getFiltersFromStorage()
      : { sheet_name: [], sheet_type: [], description: [] }
  );
  const [filteredDataSheetsList, setFilteredDataSheetsList] = useState([]);
  const [sheetNamesList, setSheetNamesList] = useState([]);
  const [sheetTypesList, setSheetTypesList] = useState({});
  const [sheetDescriptionsList, setSheetDescriptionsList] = useState([]);
  const [showAssignPermissionsModal, setShowAssignPermissionsModal] =
    useState(false);
  const [userSettings, setUserSettings] = useState(null);
  const { auth } = useContext(AuthContext);
  const { createUserSettings, updateUserSettings, getUserSettings } =
    useUserSettings();
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [archiveData, setArchiveData] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [restoreData, setRestoreData] = useState([]);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    rangePicker: "",
  });
  const [sortDataSheets, setSortDataSheets] = useState({
    type: "key",
    sortAlphabetically: true,
  });
  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);
  const sortOptions = [
    { name: "Name", value: "sheet_name" },
    { name: "Sheet Type", value: "sheet_type" },
    { name: "Description", value: "description" },
    { name: "Date Created", value: "update_date" },
  ];
  const handleSort = () => {
    loadDataSheets({
      pagination,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("sheetStartDate") &&
      localStorage.getItem("sheetEndDate")
    ) {
      const startDate = localStorage.getItem("sheetStartDate")
        ? moment(localStorage.getItem("sheetStartDate"), YearMonthDayFormat)
        : null;
      const endDate = localStorage.getItem("sheetEndDate")
        ? moment(localStorage.getItem("sheetEndDate"), YearMonthDayFormat)
        : null;
      const dateRange = [startDate, endDate];

      const initData = {
        rangePicker: dateRange ? dateRange : "",
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
      setStartDate(
        moment(localStorage.getItem("sheetStartDate")).format("DD-MM-YYYY")
      );
      setEndDate(
        moment(localStorage.getItem("sheetEndDate")).format("DD-MM-YYYY")
      );
    } else {
      const initData = {
        rangePicker: [],
      };
      setInitialValues(initData);
      form.setFieldsValue(initData);
    }
  }, []);

  const loadUserData = async () => {
    try {
      setLoadingUserData(true);
      const username = auth["auth"]["accessToken"]["payload"]["username"];
      const payload = {
        owner: username,
        settingName: "default_datasheet_view",
      };
      const response = await getUserSettings(payload);

      setUserSettings(response);
    } catch (error) {
      console.log("Error while loading default metadata information!", error);
    } finally {
      setLoadingUserData(false);
    }
  };
  useEffect(() => {
    if (userSettings) {
      setToggleListCardView(
        userSettings?.settingValue === "true" ? true : false
      );
    }
  }, [userSettings]);

  useEffect(() => {
    if (auth) {
      loadUserData();
    }
  }, [auth]);

  const loadAllSheetsDataForDropdown = async () => {
    try {
      const dataSheetsList = await getDatasheetFilters();
      if (dataSheetsList?.sheet_name?.length) {
        setSheetNamesList(dataSheetsList?.sheet_name);
      }
      if (dataSheetsList?.sheet_type) {
        setSheetTypesList(dataSheetsList?.sheet_type);
      }
      if (dataSheetsList?.description?.length) {
        setSheetDescriptionsList(dataSheetsList?.description);
      }
    } catch (error) {
      console.log("Error while loading sheet data for dropdown!", error);
    } finally {
    }
  };

  useEffect(() => {
    loadAllSheetsDataForDropdown();
  }, []);

  const handleDataSheetView = async (status) => {
    try {
      setToggleListCardView(status);
      const username = auth["auth"]["accessToken"]["payload"]["username"];

      if (userSettings) {
        const payload = {
          owner: username,
          settingName: "default_datasheet_view",
          settingValue: status,
        };

        await updateUserSettings(payload);
      } else {
        const payload = {
          owner: username,
          settingName: "default_datasheet_view",
          settingValue: status,
        };

        await createUserSettings(payload);
      }
    } catch (error) {
      console.log("Something went wrong!", error);
    }
  };

  const makeQuery = (params, group) => {
    const { pagination: _pagination } = params;
    const filter = { ...externalTableFilters };
    if (startDate) {
      filter.start_date = startDate;
    }
    if (endDate) {
      filter.end_date = endDate;
    }
    filter.group = group;
    filter.archive = isDeleted;
    return {
      skip: (_pagination.current - 1) * _pagination.pageSize,
      limit: _pagination.pageSize,
      sort:
        params.sorter || sortDataSheets
          ? {
              [sortDataSheets?.type === "key" ? "_id" : sortDataSheets?.type]:
                sortDataSheets.sortAlphabetically ? 1 : -1,
            }
          : { _id: 1 },
      filter,
    };
  };

  const loadDataSheets = async (params) => {
    setLoadingDataSheetsList(true);
    try {
      const data = await Auth.currentSession();
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const requestPayload = makeQuery(params, group);
      const { pagination } = params;
      const sheetRecords = await getDataSheetsWithPagination(requestPayload);
      setPagination({
        ...pagination,
        total: sheetRecords.filteredCount,
      });
      const dataSheets = sheetRecords.data.map((record) => ({
        ...record,
        key: record._id.$oid,
      }));
      setFilteredDataSheetsList(dataSheets);
    } catch (error) {
      message.error("Something went wrong while loading data sheets!");
    } finally {
      setLoadingDataSheetsList(false);
    }
  };

  useEffect(() => {
    loadDataSheets({ pagination });
    setPagination({ ...pagination, current: 1 });
  }, [isDeleted]);

  useEffect(() => {
    loadDataSheets({ pagination });
    setPagination({ ...pagination, current: 1 });
  }, [refreshSheetsList, externalTableFilters, startDate, endDate]);

  const onClickEditDataSheet = (record) => {
    const sheetId = record?.["_id"]?.["$oid"];
    if (sheetId) {
      navigate(
        `/data/data-sheets/${sheetId}/${encodeUrlName(
          record?.sheet_name.replace("/", " ")
        )}/update`
      );
    }
  };

  const onClickViewDocuments = (rowData) => {
    const sheet_id = rowData?.["_id"]?.["$oid"];
    if (sheet_id) {
      navigate(
        `/data/data-sheets/${sheet_id}/${encodeUrlName(
          rowData?.sheet_name.replace("/", " ")
        )}/documents`
      );
    }
  };

  const onClickAssignPermissions = (record) => {
    setRowData(record);
    setShowAssignPermissionsModal(true);
  };
  const onClickAssignCopy = (record) => {
    const sheetId = record?.["_id"]?.["$oid"];
    if (sheetId) {
      navigate(`/data/data-sheets/duplicate/?copyId=${sheetId}`);
    }
  };

  const onClickArchive = (data) => {
    setArchiveData(data);
    setShowConfirmModal(true);
  };

  const onConfirmArchive = async () => {
    try {
      setConfirmLoading(true);

      const response = await updateDataSheet({ ...archiveData, archive: true });
      if (response) {
        message.success("Data Sheet Moved to archive!");
        setConfirmLoading(false);
        setShowConfirmModal(false);
        setRefreshSheetsList(refreshSheetsList + 1);
      }
    } catch (error) {
      console.log("Something went wrong while archive data sheet!");
      message.error("Something went wrong while archive data sheet!");
    }
  };

  const onClickRestore = async (data) => {
    setRestoreData(data);
    setShowRestoreModal(true);
  };

  const onConfirmRestore = async () => {
    try {
      setConfirmLoading(true);

      const response = await updateDataSheet({
        ...restoreData,
        archive: false,
      });
      if (response) {
        message.success("Data Sheet Restored Successfully");
        setConfirmLoading(false);
        setShowRestoreModal(false);
        setRefreshSheetsList(refreshSheetsList + 1);
      }
    } catch (error) {
      console.log("Something went wrong while restoring data sheet!");
      message.error("Something went wrong while restoring data sheet!");
    }
  };

  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
        <Menu.Item
          key="edit"
          onClick={() => onClickEditDataSheet(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<EditOutlined />}
          >
            Edit
          </StyledButton>
        </Menu.Item>
      )}
      <Menu.Item
        key="4"
        onClick={() =>
          navigate("/data/data-sheets/create-audit", {
            state: {
              id: record?._id?.$oid,
              type: "DATA_SHEETS",
            },
          })
        }
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<FileDoneOutlined />}
        >
          Audit
        </StyledButton>
      </Menu.Item>
      {checkRolesPermission(["role:admin"]) ? (
        <Menu.Item
          key="2"
          onClick={() => onClickAssignPermissions(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<UsergroupDeleteOutlined />}
          >
            Permissions
          </StyledButton>
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="3"
        onClick={() => onClickAssignCopy(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<CopyOutlined />}
        >
          Duplicate
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => onClickArchive(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Archive
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  const fillColor = (record) => {
    let tagColoris = "#7D89B0";
    if (record === "Energy Usage") {
      tagColoris = "#E69B56";
    }
    if (record === "Water Usage") {
      tagColoris = "#5690E6";
    }
    if (
      record === "Metadata" ||
      record === "Real Estate List" ||
      record === "Supplier List" ||
      record === "Portfolio Company List"
    ) {
      tagColoris = "#A68DFB";
    }
    if (record === "Waste") {
      tagColoris = "#3DA48B";
    }
    return (
      <Tag color={tagColoris} className="tag-rounded">
        {record}
      </Tag>
    );
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "sheet_name",
      width: 400,
      sorter: () => {
        return;
      },
    },
    {
      title: "Sheet Type",
      dataIndex: "sheet_type",
      width: 150,
      sorter: () => {
        return;
      },
      render: (record) => {
        return fillColor(record);
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: () => {
        return;
      },
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "300px",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {stripHtmlTags(record?.description)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "update_date",
      width: 200,
      sorter: () => {
        return;
      },
      render: (_, record) => {
        const endDate = record.update_date?.$date
          ? moment(record.update_date?.$date).format(YearMonthDayFormat)
          : "";
        return endDate;
      },
    },
    {
      title: "Data Quality",
      dataIndex: "dataQualityScore",
      key: "dataQualityScore",
      width: "150px",
      render: (_, data) => {
        return (
          <>
            <Progress
              percent={data.qualityScore?.percent}
              steps={3}
              strokeColor={[purple[2], purple[3], purple[4]]}
              trailColor="#4E4268"
              showInfo={false}
            />
            <span style={{ marginLeft: "10px" }}>
              {data.qualityScore?.label || "N/A"}
            </span>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: 300,
      render: (_, data) => (
        <Space direction="horizontal">
          {isDeleted ? (
            <>
              {!checkRolesPermission(["role:auditor"]) && (
                <StyledButton
                  type="default"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "11px 18px",
                    textAlign: "left",
                  }}
                  icon={<RollbackOutlined />}
                  onClick={() => onClickRestore(data)}
                >
                  Restore
                </StyledButton>
              )}
            </>
          ) : (
            <>
              <StyledButton
                type="default"
                onClick={() => onClickViewDocuments(data)}
              >
                <EyeOutlined /> Open Data Sheet
              </StyledButton>
              {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
                <Dropdown overlay={() => menuButtons(data)} trigger={["click"]}>
                  <StyledButton
                    type="default"
                    style={{
                      marginLeft: "10",
                      padding: "10px 18px",
                    }}
                  >
                    <EllipsisOutlined />
                  </StyledButton>
                </Dropdown>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];

  const setFilterDataToLocalStorage = (filters) => {
    localStorage.setItem("dataSheetfilteredInfo", JSON.stringify(filters));
  };

  const onChangeExternalFilters = (data, filterName) => {
    try {
      setPagination({ ...pagination, current: 1 });
      setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
      setFilterDataToLocalStorage({
        ...externalTableFilters,
        [filterName]: data,
      });
    } catch (error) {
      console.log("Error while filter carbon table", error);
    }
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setPagination({ ...pagination, current: 1 });
      setStartDate(moment(dateStrings[0]));
      setEndDate(moment(dateStrings[1]));
      localStorage.setItem("sheetStartDate", dateStrings[0]);
      localStorage.setItem("sheetEndDate", dateStrings[1]);
    } else {
      setStartDate("");
      setEndDate("");
      localStorage.removeItem("sheetStartDate");
      localStorage.removeItem("sheetEndDate");
    }
  };

  const handleTableChange = (pagination, _filters, sorter, extra) => {
    const newSorter =
      extra.action === "sort"
        ? {
            type: sorter.field,
            sortAlphabetically: sorter.order === "ascend",
          }
        : sortDataSheets;
    newSorter && setSortDataSheets(newSorter);
    loadDataSheets({
      pagination,
      ...(extra.action === "sort" && {
        sorter: { [newSorter.type]: newSorter.sortAlphabetically ? 1 : -1 },
      }),
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    const newPagination = { ...pagination, current, pageSize };
    setPagination(newPagination);
    loadDataSheets({ pagination: newPagination });
  };

  const { sheet_name, sheet_type, description } = externalTableFilters;

  const locale = {
    emptyText: (
      <Col span={24} style={{ paddingTop: "100px", paddingBottom: "100px" }}>
        <Row justify="center">
          <Title level={3} style={{ color: "#B1AFBC", fontWeight: "normal" }}>
            No Data Sheets were found.
          </Title>
        </Row>
        {!checkRolesPermission(["role:elevateduser"]) && !isDeleted && (
          <>
            <Row justify="center">
              <Title
                level={4}
                style={{ color: "#B1AFBC", fontWeight: "normal" }}
              >
                Click on the button below to create your first data sheet or
                refine your search criteria.
              </Title>
            </Row>
            <Row justify="center">
              <StyledButton type="primary">
                <Link to="/data/data-sheets/create">
                  <PlusCircleOutlined /> <span>Create New Data Sheet</span>
                </Link>
              </StyledButton>
            </Row>
          </>
        )}
      </Col>
    ),
  };

  return (
    <>
      {showAssignPermissionsModal && (
        <AssignPermissionsModal
          visible={showAssignPermissionsModal}
          onClose={() => {
            setRefreshSheetsList(refreshSheetsList + 1);
            setShowAssignPermissionsModal(false);
          }}
          permissionsType={"dataSheet"}
          rowData={rowData}
        />
      )}
      <ConfirmArchiveModal
        isVisible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirmArchive}
        confirmLoading={confirmLoading}
      />
      <ConfirmRestoreModal
        isVisible={showRestoreModal}
        onClose={() => setShowRestoreModal(false)}
        onConfirm={onConfirmRestore}
        confirmLoading={confirmLoading}
      />
      <Spin spinning={loadingUserData || loadingdataSheetsList}>
        <PageWrap>
          <FiltersContainer>
            <FormWrapper>
              <Form form={form} initialValues={initialValues}>
                {" "}
                <Row gutter={[16, 16]} justify="space-between">
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={15}
                    style={{ marginTop: "5px" }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12} md={6} lg={5}>
                        <CommonSelect
                          mode="multiple"
                          allowClear
                          showArrow
                          style={{ width: "100%" }}
                          placeholder="Sheet Name"
                          value={sheet_name}
                          onChange={(data) =>
                            onChangeExternalFilters(data, "sheet_name")
                          }
                        >
                          {sheetNamesList?.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            );
                          })}
                        </CommonSelect>
                      </Col>
                      <Col xs={24} sm={12} md={6} lg={5}>
                        <CommonSelect
                          mode="multiple"
                          allowClear
                          showArrow
                          style={{ width: "100%" }}
                          placeholder="Sheet Type"
                          value={sheet_type}
                          onChange={(data) => {
                            onChangeExternalFilters(data, "sheet_type");
                          }}
                          options={[
                            {
                              label: "Regular Data Sheet",
                              options:
                                sheetTypesList?.regular?.map((item) => ({
                                  label: item,
                                  value: item,
                                })) || [],
                            },
                            {
                              label: "Reference Data Sheet",
                              options:
                                sheetTypesList?.metadata?.map((item) => ({
                                  label: item,
                                  value: item,
                                })) || [],
                            },
                          ]}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={6} lg={5}>
                        <CommonSelect
                          mode="multiple"
                          allowClear
                          showArrow
                          style={{ width: "100%" }}
                          placeholder="Description"
                          value={description}
                          onChange={(data) =>
                            onChangeExternalFilters(data, "description")
                          }
                        >
                          {sheetDescriptionsList?.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {stripHtmlTags(item)}
                              </Option>
                            );
                          })}
                        </CommonSelect>
                      </Col>
                      <Col xs={24} sm={12} md={6} lg={5}>
                        <Form.Item name="rangePicker">
                          <RangePicker
                            placeholder={["Start date", "End date"]}
                            onChange={onDateChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={9}
                    style={{ marginTop: "5px" }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <TableHeaderRightTabWrap
                        style={{ justifyContent: "end" }}
                      >
                        <StyledButton
                          type="default"
                          style={{
                            borderRadius: "0",
                            marginRight: "10px",
                            padding: "5px 18px",
                          }}
                        >
                          <Dropdown
                            overlay={
                              <SortDropdown
                                sorting={sortDataSheets}
                                changeSorting={setSortDataSheets}
                                options={sortOptions}
                                onConfirm={() => {
                                  handleSort();
                                  hideDropdown();
                                }}
                              />
                            }
                            trigger={["click"]}
                            onVisibleChange={toggle}
                            visible={isDropdownShown}
                            placement="bottomRight"
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <SwapOutlined rotate={90} />
                              <span style={{ paddingLeft: "5px" }}>Sort</span>
                            </a>
                          </Dropdown>
                        </StyledButton>
                        <StyledButton
                          type="default"
                          bgcolor={toggleListCardView ? "#7F5FEE" : undefined}
                          style={{
                            borderRadius: "0",
                            marginRight: "0",
                            padding: "5px 18px",
                          }}
                          icon={<UnorderedListOutlined />}
                          onClick={() => handleDataSheetView(true)}
                        >
                          List View
                        </StyledButton>
                        <StyledButton
                          type="default"
                          bgcolor={!toggleListCardView ? "#7F5FEE" : undefined}
                          style={{
                            borderRadius: "0",
                            marginLeft: "0",
                            padding: "5px 18px",
                          }}
                          icon={<AppstoreOutlined />}
                          onClick={() => handleDataSheetView(false)}
                        >
                          Card View
                        </StyledButton>
                      </TableHeaderRightTabWrap>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </FormWrapper>
          </FiltersContainer>
          <Row gutter={[16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              {toggleListCardView ? (
                <TableWrap>
                  <CommonTable
                    rowKey={(data) => {
                      const id = data?.["_id"]?.["$oid"];
                      return id;
                    }}
                    dataSource={filteredDataSheetsList}
                    columns={columns}
                    rowClassName="dataSheetsTable"
                    scroll={{ x: true }}
                    pagination={pagination}
                    onChange={handleTableChange}
                    locale={locale}
                  />
                </TableWrap>
              ) : (
                <>
                  <CardsWrap style={{ marginTop: "10px" }}>
                    <Row
                      gutter={[16, 16]}
                      style={{
                        width: "100%",
                      }}
                    >
                      {!filteredDataSheetsList.length && (
                        <Col
                          span={24}
                          style={{
                            paddingTop: "100px",
                            paddingBottom: "100px",
                          }}
                        >
                          <Row justify="center">
                            <Title
                              level={3}
                              style={{ color: "#B1AFBC", fontWeight: "normal" }}
                            >
                              No Data Sheets were found.
                            </Title>
                          </Row>
                          {!checkRolesPermission([
                            "role:elevateduser",
                            "role:auditor",
                          ]) &&
                            !isDeleted && (
                              <>
                                <Row justify="center">
                                  <Title
                                    level={4}
                                    style={{
                                      color: "#B1AFBC",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Click on the button below to create your
                                    first data sheet or refine your search
                                    criteria.
                                  </Title>
                                </Row>
                                <Row justify="center">
                                  <StyledButton type="primary">
                                    <Link to="/data/data-sheets/create">
                                      <PlusCircleOutlined />{" "}
                                      <span>Create New Data Sheet</span>
                                    </Link>
                                  </StyledButton>
                                </Row>
                              </>
                            )}
                        </Col>
                      )}
                      {filteredDataSheetsList.map((val, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={6}>
                          <div
                            style={{
                              width: "100%",
                              padding: "24px 12px",
                              backgroundColor: "#2D273F",
                              borderRadius: "4px",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="color-grey">
                              {val.update_date?.$date
                                ? moment(val.update_date?.$date).format(
                                    YearMonthDayFormat
                                  )
                                : ""}
                            </p>
                            <Title level={5} className="card-title">
                              {val.sheet_name}
                            </Title>
                            {fillColor(val.sheet_type)}
                            <Title
                              level={5}
                              style={{
                                color: "#b1afbc",
                                margin: "20px 0px 5px 0px",
                                fontSize: "14px",
                                fontWeight: "400",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {stripHtmlTags(val?.description)}
                            </Title>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "stretch",
                                overflow: "hidden",
                                flexWrap: "wrap",
                              }}
                            >
                              {!isDeleted ? (
                                <>
                                  {!checkRolesPermission([
                                    "role:elevateduser",
                                    "role:auditor",
                                  ]) ? (
                                    <>
                                      <StyledButton
                                        style={{
                                          marginLeft: "5px",
                                          marginRight: "5px",
                                          flexGrow: "8",
                                        }}
                                        type="default"
                                        onClick={() =>
                                          onClickViewDocuments(val)
                                        }
                                      >
                                        <EyeOutlined />
                                        Open Data Sheet
                                      </StyledButton>

                                      <Dropdown
                                        overlay={() => menuButtons(val)}
                                        trigger={["click"]}
                                      >
                                        <StyledButton
                                          type="default"
                                          style={{
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                            flexGrow: "1",
                                          }}
                                        >
                                          <EllipsisOutlined />
                                        </StyledButton>
                                      </Dropdown>
                                    </>
                                  ) : (
                                    <StyledButton
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        flexGrow: "8",
                                      }}
                                      type="default"
                                      onClick={() => onClickViewDocuments(val)}
                                    >
                                      <EyeOutlined />
                                      Open Data Sheet
                                    </StyledButton>
                                  )}
                                </>
                              ) : (
                                <StyledButton
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    flexGrow: "8",
                                  }}
                                  type="default"
                                  onClick={() => onClickRestore(val)}
                                >
                                  <RollbackOutlined />
                                  Restore
                                </StyledButton>
                              )}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </CardsWrap>
                  {filteredDataSheetsList.length && (
                    <FormWrapper>
                      <Row>
                        <Col
                          span={12}
                          offset={6}
                          style={{ textAlign: "center", marginTop: "15px" }}
                        >
                          <Pagination
                            showSizeChanger={false}
                            onChange={onShowSizeChange}
                            defaultCurrent={1}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                          />
                        </Col>
                      </Row>
                    </FormWrapper>
                  )}
                </>
              )}
            </Col>
          </Row>
        </PageWrap>
      </Spin>
    </>
  );
};

export default DataSheetsPage;
