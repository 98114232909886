import React, { useState } from "react";

import { Typography, Row, message, Drawer, Col, Tabs, UploadFile } from "antd";

import { SignOffSection } from "./SignOffSection";
import { AuditHistorySection } from "./AuditHistorySection";
import { StyledTabs } from "../../../../shared/commonStyles";
import { AuditorsStatus } from "./AuditorsStatus";

const { Title } = Typography;
const { TabPane } = Tabs;

interface AuditDetailProps {
  auditRecord: Audit;
  rowData: DataSheetRecord;
  showDetailsDrawer: boolean;
  submitSignoffStatus: (
    rowData: DataSheetRecord,
    comment: string,
    status: string,
    files?: UploadFile[]
  ) => string;
  submitting: boolean;
  setShowDetailsDrawer: (value: boolean) => void;
  refreshUploadedFiles: () => void;
}

const AuditDetail = ({
  auditRecord,
  rowData,
  showDetailsDrawer,
  submitSignoffStatus,
  setShowDetailsDrawer,
  submitting,
  refreshUploadedFiles,
}: AuditDetailProps) => {
  const [selectedTab, setSelectedTab] = useState("sign-off");
  const onTabChange = (selectedTabKey: string) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };
  const onClickPanelHeader = (event: any) => {
    try {
      event.stopPropagation();
    } catch (e) {
      console.log("panel header clicked", e);
    }
  };

  const onClose = () => {
    setShowDetailsDrawer(false);
  };

  return (
    <Drawer
      className="drawerCustom"
      title="Audit details"
      width={700}
      onClose={onClose}
      visible={showDetailsDrawer}
      placement="right"
    >
      <Row
        style={{ background: "#2D273F" }}
        justify="space-between"
        onClick={(event) => onClickPanelHeader(event)}
      >
        <Col span={24}>
          <Title className="color-white" level={3}>
            Audit details
          </Title>
        </Col>
        <Col span={24}>
          <AuditorsStatus auditRecord={auditRecord} record={rowData} />;
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <StyledTabs activeKey={selectedTab} onChange={onTabChange}>
            <TabPane tab="Sign Off" key="sign-off" />
            <TabPane tab="History" key="history" />
          </StyledTabs>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          {selectedTab === "sign-off" && (
            <SignOffSection
              refreshUploadedFiles={refreshUploadedFiles}
              rowData={rowData}
              auditRecord={auditRecord}
              onClose={onClose}
              submitSignoffStatus={submitSignoffStatus}
              submitting={submitting}
            />
          )}

          {selectedTab === "history" && (
            <AuditHistorySection rowData={rowData} auditRecord={auditRecord} />
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default AuditDetail;
