import React from "react";
import { useLocation } from "react-router";
import GenerateReport from "../../reportingPage/generateReportPage/GenerateReport";
import ReportTemplate from "./index";

export const ReportTemplatePage = () => {
  const { state }: any = useLocation();
  return (
    <>
      {state?.type.includes("standard_") ? (
        <GenerateReport />
      ) : (
        <ReportTemplate />
      )}
    </>
  );
};
