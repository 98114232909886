import React, { useState } from 'react';
import { Space, Form, Row, Col, Select, notification } from 'antd';
import './../../ReportingPage.css';
import { DateSelect, LocationSelect } from './../../Components'
import { BusinessUnitSelect } from './../../Components/BusinessUnitSelect/BusinessUnitSelect';
import { CommonModal, StyledButton, FormWrapper } from '../../../../shared/commonStyles';
import { updateSelectedStandards } from '../../../../services/standards'

const StandardCloneModal = ({ visible, onClose, refreshReportDataLake, rowData, locationsList, businessUnitsList, reportPeriodsList }) => {

    const [form] = Form.useForm();
    const [DateRange, SetRange] = useState(null)
    const [LocationId, SetLocationId] = useState(null)
    const [businessUnitId, setBusinessUnitId] = useState(null);
    const [loadingRecategorize, setLoadingRecategorize] = useState(false);

    const onHeaderFiltersChange = (filterName, value) => {
        // this function handle store filters value in state and local storage.
        // setReportingFilters function is used to save data in local storage.

        if (filterName === 'business_unit') {
            // store business unit id
            setBusinessUnitId(value);
        } else if (filterName === 'report_period') {
            // store report period id
            SetRange(value);
        } else {
            // store location id 
            SetLocationId(value);
        }
    }

    const onClickRecategorizeStandards = async () => {
        if (!rowData) return;
        try {            
            setLoadingRecategorize(true);
            const formJson = JSON.stringify(rowData.form_json_code);
            const formUi = JSON.stringify(rowData.form_ui_code);
            const dataToSubmit = {...rowData,
                form_json_code: formJson,
                form_ui_code: formUi,                
                report_period : DateRange,
                location_id:LocationId,
                business_unit : businessUnitId
              };
            /*const dataToSubmit = {
                id: rowData.id,
                standard_id: rowData.standard_id,
                report_period: DateRange,
                location_id: LocationId,
                business_unit: businessUnitId
            };*/
            
            await updateSelectedStandards(dataToSubmit)

        } catch (error) {
            setLoadingRecategorize(false);
            console.log("error while creating esg for user", error);
        } finally {
            setLoadingRecategorize(false);
            refreshReportDataLake();
            onClose()
            notification.success({
                message: 'Success!',
                description:
                    'Standard recategorized successfully',
            });
        }
    }



    return (
        <CommonModal
            title={"Recategorize"}
            visible={visible}
            onCancel={onClose}
            width={700}
            footer={null}
            maskClosable={false}
            destroyOnClose
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                <FormWrapper>
                    <Form form={form} >
                        <Row justify="space-between">
                            <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
                                <BusinessUnitSelect
                                    key={1}
                                    selectedBusinessUnit={businessUnitId}
                                    businessUnits={businessUnitsList}
                                    visibleAddBtn={false}
                                    setSelectedBusinessUnit={(value) => onHeaderFiltersChange('business_unit', value)}
                                    // setBusinessUnits={setBusinessUnits}
                                    selectWidth={650}
                                    extraOptions={
                                        <Select.Option value="" label={"None"}>
                                            None
                                        </Select.Option>
                                    }
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
                                <LocationSelect
                                    key={2}
                                    visibleAddBtn={false}
                                    setRange={(value) => onHeaderFiltersChange('location_id', value)}
                                    value={LocationId}
                                    // setLocation={SetLocationOptions}
                                    locations={locationsList}
                                    selectWidth={650}
                                    extraOptions={
                                        <Select.Option value="" label={"None"}>
                                            None
                                        </Select.Option>
                                    }
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
                                <DateSelect
                                    key={3}
                                    visibleAddBtn={false}
                                    setRange={(value) => onHeaderFiltersChange('report_period', value)}
                                    value={DateRange}
                                    // setTimePeriod={SetTimePeriods}
                                    selectWidth={650}
                                    timeperiods={reportPeriodsList}
                                    extraOptions={
                                        <Select.Option value="" label={"None"}>
                                            None
                                        </Select.Option>
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="align-items-right-wrapper">
                            <StyledButton type="custom"
                                bgcolor="transparent"
                                bordercolor="transparent"
                                hoverbgcolor="transparent"
                                onClick={onClose}
                            >
                                Cancel
                            </StyledButton>
                            <StyledButton type="custom"
                                onClick={onClickRecategorizeStandards}
                                loading={loadingRecategorize}>
                                Recategorize
                            </StyledButton>
                        </div>
                    </Form>
                </FormWrapper>
            </Space>
        </CommonModal>
    );
};

export default StandardCloneModal;
