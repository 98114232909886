import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
  message,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getCompanyInfo } from "../../../../services/companyInfo";
import { KPIsListModal } from "./KPIsListModal";
import {
  FormWrapper,
  StyledButton,
  CommonSelect,
} from "../../../../shared/commonStyles";
import { getDataSheets } from "../../../../services/dataSheet";
import { getKpiMetric } from "../../../../services/kpi";
import { API, Auth } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";
import moment, { Moment } from "moment";
import { calculationStore } from "../../../../pages/Carbon/CalculationsStore";
import { fetchDataWithRetry } from "../../../../services/importData";

type FieldName =
  | "revenue_total"
  | "full_time_employees"
  | "total_floor_space"
  | null;
interface RowData {
  time_period?: RangeValue;
  kpi?: string;
}
interface FormData {
  revenue_total: RowData[];
  full_time_employees: RowData[];
  total_floor_space: RowData[];
  emissions_unit: string;
  currency_type: string;
  floor_space_unit: string;
  default_metadata_sheet_id: string;
}
interface ModalData {
  index: number;
  fieldName: FieldName;
  fieldLabel: string;
  period: string;
}

export const CarbonSettings = () => {
  const [form] = Form.useForm();

  const [kpiModal, setKpiModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [metaDataSheets, setMetaDataSheets] = useState<any[]>([]);

  const [modalData, setModalData] = useState<ModalData>({
    index: 0,
    fieldName: null,
    fieldLabel: "",
    period: "",
  });
  const [kpisList, setKpisList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const initialValues = {
    revenue_total: companyDetails?.revenue_total || [{} as RowData],
    full_time_employees: companyDetails?.full_time_employees || [{} as RowData],
    total_floor_space: companyDetails?.total_floor_space || [{} as RowData],
    emissions_unit: companyDetails?.emissions_unit || "kg",
    currency_type: companyDetails.currency_type || "USD",
    floor_space_unit: companyDetails.floor_space_unit || "m3",
    default_metadata_sheet_id: companyDetails?.default_metadata_sheet_id || "",
  } as FormData;

  const [formValues, setFormValues] = useState<FormData>(initialValues);
  const loadCompanyInfo = async () => {
    try {
      setLoading(true);
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";
        companyData.full_time_employees =
          companyData?.full_time_employees?.length &&
          companyData.full_time_employees.map(
            (item: { start_date: Moment; end_date: Moment; kpi: string }) => {
              return {
                time_period: [moment(item.start_date), moment(item.end_date)],
                kpi: item.kpi,
              };
            }
          );
        companyData.revenue_total =
          companyData?.revenue_total?.length &&
          companyData.revenue_total.map(
            (item: { start_date: Moment; end_date: Moment; kpi: string }) => {
              return {
                time_period: [moment(item.start_date), moment(item.end_date)],
                kpi: item.kpi,
              };
            }
          );
        companyData.total_floor_space =
          companyData?.total_floor_space?.length &&
          companyData.total_floor_space.map(
            (item: { start_date: Moment; end_date: Moment; kpi: string }) => {
              return {
                time_period: [moment(item.start_date), moment(item.end_date)],
                kpi: item.kpi,
              };
            }
          );
        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleRefreshJob = async () => {
    try {
      const jobId = await calculationStore.refreshCalculations_job();
      const result = await fetchDataWithRetry(jobId);
      const resultSummary = result?.resultSummary || "";

      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        result?.resultSummary?.message &&
          message.success(resultSummary?.message);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      message.error("Error while refreshing calculations!");
    }
  };
  const getKpisData = async () => {
    setLoading(true);
    const kpiForDatasheets = await getKpiMetric();
    const sheetIds = kpiForDatasheets.map((item: any) => item.sheetId);
    const uniqSheetIds = [...new Set(sheetIds)];
    const payload = {
      filter: { _id: { $oid: uniqSheetIds } },
    };
    const metadata_payload = {
      filter: { is_reference_data: true },
    };
    const dataSheets = await getDataSheets(payload);
    const metadataSheets = await getDataSheets(metadata_payload);
    const dataSheetsWithKpi = dataSheets.map((item: DataSheet) => {
      return {
        ...item,
        kpiData: kpiForDatasheets.filter(
          (v: any) => v.sheetId === item._id.$oid
        ),
      };
    });
    setKpisList(dataSheetsWithKpi);
    setMetaDataSheets(metadataSheets);
    setLoading(false);
  };

  useEffect(() => {
    loadCompanyInfo();
    getKpisData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
    setLoading(false);
  }, [companyDetails]);

  const onAddKPI = (name: FieldName, title: string, index: number) => {
    const period = `${
      formValues[name!][index]?.time_period?.[0].format("YYYY-MM-DD") || ""
    } - ${
      formValues[name!][index]?.time_period?.[1].format("YYYY-MM-DD") || ""
    }`;
    setModalData({ index, period, fieldName: name, fieldLabel: title });
    setKpiModal(true);
  };
  const onHideModal = () => {
    setModalData({ index: 0, period: "", fieldName: null, fieldLabel: "" });
    setKpiModal(false);
  };

  const handleSelectKpi = (kpi: string, index: number) => {
    const formData = form.getFieldsValue();
    const newFieldData = { ...formData[modalData.fieldName!][index], kpi };
    const rowData = formData[modalData.fieldName!].map(
      (item: RowData, i: number) => (i === index ? newFieldData : item)
    );
    const newValues = {
      ...formData,
      [modalData.fieldName!]: rowData,
    };
    setFormValues(newValues);
    form.setFieldsValue(newValues);
    setModalData({ index: 0, period: "", fieldName: null, fieldLabel: "" });
    setKpiModal(false);
  };

  const handleSaveForm = () => {
    const metadataValue = form.getFieldValue("default_metadata_sheet_id");
    form
      .validateFields()
      .then(async (values) => {
        try {
          setSubmitting(true);
          const data: any = await Auth.currentSession();
          const groupId: any = data["accessToken"]["payload"][
            "cognito:groups"
          ].filter((element: any) => element.includes("org:"))[0];
          const newValues = {
            ...values,
            full_time_employees: values.full_time_employees.map(
              (item: RowData) => {
                return {
                  start_date: item.time_period?.[0],
                  end_date: item.time_period?.[1],
                  kpi: item.kpi,
                };
              }
            ),
            revenue_total: values.revenue_total.map((item: RowData) => {
              return {
                start_date: item.time_period?.[0],
                end_date: item.time_period?.[1],
                kpi: item.kpi,
              };
            }),
            total_floor_space: values.total_floor_space.map((item: RowData) => {
              return {
                start_date: item.time_period?.[0],
                end_date: item.time_period?.[1],
                kpi: item.kpi,
              };
            }),
            default_metadata_sheet_id: metadataValue,
          };
          if (Object.keys(companyDetails)?.length) {
            const payload = {
              data: `${JSON.stringify({ ...companyDetails, ...newValues })}`,
              group: groupId,
            };
            const response: any = await API.graphql({
              query: mutations.updateCompanyInfo,
              variables: { input: payload },
            });

            const updatedCompanyInfo = response.data.updateCompanyInfo;
            if (updatedCompanyInfo) {
              await loadCompanyInfo();
              message.success("Data updated Successfully!");
            }
          } else {
            const payload = {
              data: `${JSON.stringify(newValues)}`,
              group: groupId,
            };
            const response: any = await API.graphql({
              query: mutations.createCompanyInfo,
              variables: { input: payload },
            });
            const createdCompanyInfo = response["data"]["createCompanyInfo"];
            if (createdCompanyInfo) {
              await loadCompanyInfo();
              message.success("Data saved Successfully!");
            }
          }
        } catch (error) {
          message.error(`Error while submitting data ${error}`);
        } finally {
          setSubmitting(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong.");
        console.log(e);
      })
      .finally(() => {
        handleRefreshJob();
      });
  };

  const currency = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "AUD", value: "AUD" },
  ];

  const floorSpaceUnits = [
    {
      label: (
        <span>
          ft<sup>3</sup>
        </span>
      ),
      value: "ft3",
    },
    {
      label: (
        <span>
          m<sup>3</sup>
        </span>
      ),
      value: "m3",
    },
  ];

  const defaultUnits = [
    {
      label: "kg CO2e",
      value: "kg",
    },
    {
      label: "MT CO2e",
      value: "mt",
    },
    {
      label: "lb CO2e",
      value: "lb",
    },
    {
      label: "T CO2e",
      value: "t",
    },
  ];

  const getRowComponent = (
    key: number,
    fieldName: FieldName,
    index: number,
    fieldTitle: string,
    isKpiValue: boolean
  ) => {
    const formData = form.getFieldsValue();
    const kpiValue = kpisList
      .map((item) => item.kpiData)
      .flat()
      ?.find(
        (item) => formData[fieldName!][index]?.kpi === item?._id?.$oid
      )?.name;
    const isLoading =
      !!formData[fieldName!]?.[index]?.kpi?.length && !kpiValue?.length;
    return (
      <Row gutter={[16, 16]} key={key}>
        <Col span={12}>
          <Form.Item
            name={[key, "time_period"]}
            label="Time period"
            labelCol={{ span: 24 }}
          >
            <DatePicker.RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            getValueProps={(value) => {
              const newValue = kpisList
                .map((item) => item.kpiData)
                .flat()
                ?.find((item) => value === item?._id?.$oid)?.name;
              return newValue;
            }}
            name={[key, "kpi"]}
            label="KPI"
            labelCol={{ span: 24 }}
          >
            <Spin spinning={isLoading}>
              <Input
                placeholder="Select KPI"
                readOnly
                value={kpiValue}
                suffix={
                  <StyledButton
                    type="text"
                    onClick={() => onAddKPI(fieldName, fieldTitle, index)}
                    color="#A68DFB"
                    margin="0"
                    padding="0"
                    bgcolor="transparent"
                    hoverbgcolor="transparent"
                    bordercolor="transparent"
                  >
                    {isKpiValue ? "Change KPI" : "Select"}
                  </StyledButton>
                }
              />
            </Spin>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const getFormComponent = (fieldName: FieldName, fieldTitle: string) => {
    return (
      <Form.List name={fieldName!}>
        {(fields, { add }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                const isKpiValue = !!formValues?.[fieldName!]?.[index]?.kpi;
                return getRowComponent(
                  key,
                  fieldName,
                  index,
                  fieldTitle,
                  isKpiValue
                );
              })}
              <StyledButton
                type="custom"
                icon={<PlusCircleOutlined />}
                onClick={() => add()}
                margin={"0px"}
              >
                Add Time Period
              </StyledButton>
            </>
          );
        }}
      </Form.List>
    );
  };

  const cardFooter = (
    <Col span={24} style={{ textAlign: "right" }}>
      <Divider style={{ backgroundColor: "#4F4669", margin: "8px 0" }} />
      <Form.Item>
        <StyledButton
          type="custom"
          hovercolor="#A68DFB"
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#fff"
        >
          <span>Cancel</span>
        </StyledButton>
        <StyledButton loading={submitting} type="custom" htmlType="submit">
          Save changes
        </StyledButton>
      </Form.Item>
    </Col>
  );
  return (
    <Spin spinning={isLoading}>
      <FormWrapper>
        <Form form={form} initialValues={formValues} onFinish={handleSaveForm}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Typography.Title level={4} className="color-white">
                  Emissions Filtering
                </Typography.Title>

                <span className="color-white">
                  Select a reference data sheet from the dropdown provided. The
                  values of the identifier column in the chosen reference data
                  sheet will serve as the basis for filtering emissions and
                  carbon charts. Depending on the identifiers present in the
                  data sheet, you can filter by location, asset, supplier, etc.,
                  with this option.
                </span>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      style={{
                        marginTop: "20px",
                      }}
                      labelCol={{ span: 24 }}
                      label={"Default Emissions Reference Data Sheet"}
                      name="default_metadata_sheet_id"
                    >
                      <Select
                        showSearch
                        allowClear
                        showArrow
                        placeholder="Select Metadata"
                        filterOption={(input: string, option: any) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={metaDataSheets?.map((item) => ({
                          value: item._id?.$oid,
                          label: item.sheet_name,
                        }))}
                        notFoundContent={<Spin />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>{cardFooter}</Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Typography.Title level={4} className="color-white">
                  Company metrics
                </Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <span className="color-white">
                      choose the KPIs that will reflect the company's metrics,
                      as well as the period they measure (you can add different
                      KPIs for different time periods)
                    </span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Title
                      style={{
                        marginTop: "20px",
                      }}
                      level={5}
                      className="color-white"
                    >
                      Revenue Total
                    </Typography.Title>
                    {getFormComponent("revenue_total", "Revenue Total")}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Title
                      style={{
                        marginTop: "20px",
                      }}
                      level={5}
                      className="color-white"
                    >
                      Number of Full Time Employees
                    </Typography.Title>
                    {getFormComponent(
                      "full_time_employees",
                      "Number of Full Time Employees"
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Title
                      style={{
                        marginTop: "20px",
                      }}
                      level={5}
                      className="color-white"
                    >
                      Total Floor Space
                    </Typography.Title>
                    {getFormComponent("total_floor_space", "Total Floor Space")}
                  </Col>
                </Row>
                <Row>{cardFooter}</Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Typography.Title level={4} className="color-white">
                  Unit of measurement
                </Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name="emissions_unit"
                      labelCol={{ span: 24 }}
                      label="Select the default unit in which the emissions will be
                      reported"
                    >
                      <Select
                        style={{ width: "100%" }}
                        options={defaultUnits}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="currency_type"
                      label="Currency Type"
                      labelCol={{ span: 24 }}
                    >
                      <Select defaultValue="USD" options={currency}></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="floor_space_unit"
                      label="Floor Space Unit"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        defaultValue="m3"
                        options={floorSpaceUnits}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>{cardFooter}</Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
      {kpiModal && (
        <KPIsListModal
          visible={kpiModal}
          data={modalData}
          kpisList={kpisList}
          onClose={onHideModal}
          onConfirm={handleSelectKpi}
        />
      )}
    </Spin>
  );
};
