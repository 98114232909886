import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { FormWrapper, StyledButton } from "../../shared/commonStyles";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  additionalQueryOperators,
  queryOperators,
} from "../../services/mongoOperators";
import moment from "moment";
import styles from "./ConditionalDataSheetFilters.module.scss";

interface FiltersFormProps {
  onVisibleChange: (visible: boolean) => void;
  dataSheet: DataSheet;
  initialFilter?: AdvancedDataFilter;
  onSelectFilters: (data: { label: string; value: AdvancedDataFilter }) => void;
  loader: boolean;
  filterOptions: { [key: string]: { label: string; value: string } };
}

export const FiltersForm = ({
  onVisibleChange,
  dataSheet,
  initialFilter,
  loader,
  onSelectFilters,
  filterOptions,
}: FiltersFormProps) => {
  const [form] = Form.useForm();
  const [typeInput, setTypeInput] = useState("");
  const [activityFields, setActivityFields] = useState<ActivityField[]>([]);
  const [formFilter, setFormFilter] = useState<AdvancedDataFilter>(
    {} as AdvancedDataFilter
  );
  const mongoOperations = [...queryOperators, ...additionalQueryOperators];

  useEffect(() => {
    if (initialFilter) {
      setFormFilter(initialFilter);
      form.setFieldsValue(initialFilter);
    }
  }, [initialFilter]);

  useEffect(() => {
    if (formFilter) {
      const newTypeInput = activityFields?.find(
        (item) => formFilter?.column && formFilter?.column === item.value
      )?.inputType;

      setTypeInput(newTypeInput || "");
    }
  }, [formFilter, activityFields?.length]);
  const getOperations = () => {
    let operations = [];
    switch (typeInput) {
      case "select":
        operations = mongoOperations.filter(
          (v) => v.value === "$in" || v.value === "$all" || v.value === "$eq"
        );
        break;
      case "string":
        operations = mongoOperations
          .filter(
            (v) =>
              v.value !== "$all" &&
              v.value !== "$lt" &&
              v.value !== "$gt" &&
              v.value !== "$lte" &&
              v.value !== "$gte"
          )
          ?.map((v) => ({
            ...v,
            label: v.value === "$in" ? "Equals (multiple values)" : v.label,
          }));
        break;
      case "dropdown":
        operations = mongoOperations
          .filter(
            (v) => v.value === "$in" || v.value === "$eq" || v.value === "$ne"
          )
          ?.map((v) => ({
            ...v,
            label: v.value === "$in" ? "Equals (multiple values)" : v.label,
          }));
        break;
      case "checkbox":
        operations = mongoOperations
          .filter(
            (v) => v.value === "$in" || v.value === "$eq" || v.value === "$ne"
          )
          ?.map((v) => ({
            ...v,
            label: v.value === "$in" ? "Equals (multiple values)" : v.label,
          }));
        break;
      case "radio":
        operations = mongoOperations
          .filter(
            (v) => v.value === "$in" || v.value === "$eq" || v.value === "$ne"
          )
          ?.map((v) => ({
            ...v,
            label: v.value === "$in" ? "Equals (multiple values)" : v.label,
          }));
        break;
      default:
        operations = mongoOperations.filter(
          (v) =>
            v.value !== "$in" &&
            v.value !== "$all" &&
            v.value !== "$regex" &&
            v.value !== "doesNotContain"
        );
    }
    return operations;
  };

  const getActivityFields = () => {
    const schemas: any[] = dataSheet.sheet_schema.map((schema) => {
      return {
        value: schema.entity_name,
        label: schema.display_name,
        inputType:
          schema.input_type === "expression"
            ? schema.data_type
            : schema.input_type,
        field_options: schema?.options || filterOptions?.[schema?.entity_name],
      };
    });
    {
      !!dataSheet &&
        schemas.push({
          value: "owner_users",
          label: "Owner Users",
          inputType: "select",
          field_options: filterOptions?.["owner_users"],
        });

      !!dataSheet &&
        schemas.push({
          value: "owner_groups",
          label: "Owner Groups",
          inputType: "select",
          field_options: filterOptions?.["owner_groups"],
        });
    }
    setActivityFields(schemas);
  };

  useEffect(() => {
    if (dataSheet?.sheet_schema?.length) {
      getActivityFields();
    }
  }, [dataSheet, filterOptions]);

  const handleChangeColumn = (value: any, item: any) => {
    setTypeInput(item.inputType);
    setFormFilter({ column: value } as AdvancedDataFilter);
    form.resetFields(["column_value", "op"]);
  };

  const handleChangeValue = (value: any, key: string) => {
    setFormFilter((prev) => {
      key === "op" && delete prev.column_value;
      return {
        ...prev,
        [key]: value,
      };
    });
    key === "op" && form.resetFields(["column_value"]);
  };

  const handleDeleteFilter = () => {
    setFormFilter({} as AdvancedDataFilter);
    form.resetFields();
    onVisibleChange(false);
  };

  const handleSelectFilter = () => {
    form.validateFields().then(() => {
      const column = activityFields.find((v) => v.value === formFilter.column);
      const operator = getOperations().find(
        (v) => v.value === formFilter.op
      )?.label;
      const value =
        column?.inputType === "date"
          ? moment(formFilter.column_value)?.format("YYYY-MM-DD")
          : formFilter.column_value || "";
      const label = `${column?.label} ${operator} ${value}`;
      onSelectFilters({ value: formFilter, label: label });
      form.resetFields();
      setFormFilter({} as AdvancedDataFilter);
      onVisibleChange(false);
    });
  };

  return (
    <FormWrapper>
      <Form form={form} onFinish={() => handleSelectFilter()}>
        <Row
          align={"middle"}
          justify="space-between"
          wrap={false}
          style={{
            padding: "15px",
            backgroundColor: "#4A445D",
            borderColor: "#87809C",
          }}
          gutter={[8, 8]}
        >
          <Col span={7}>
            <Form.Item
              name="column"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                showSearch
                onChange={(value, item) => {
                  handleChangeColumn(value, item);
                }}
                style={{
                  minWidth: document
                    .querySelector(".conditionalSelectColumnOptions")
                    ?.getBoundingClientRect().width,
                  width: "100%",
                }}
                value={formFilter.column}
                placeholder="Datasheet field name"
                options={activityFields}
                filterOption={(input, option) => {
                  return (
                    ((option?.label as string) ?? "")
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                className={`${styles.selectField} conditionalSelectColumn`}
                dropdownClassName={`${styles.selectOptions} conditionalSelectColumnOptions`}
                dropdownStyle={{
                  minWidth: document
                    .querySelector(".conditionalSelectColumn")
                    ?.getBoundingClientRect().width,
                }}
                dropdownMatchSelectWidth={true}
              >
                {activityFields?.map((item) => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                    className={styles.selectOption}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} style={{ width: "100%", minWidth: "fit-content" }}>
            <Form.Item
              style={{ width: "100%", minWidth: "fit-content" }}
              name="op"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                placeholder="Equality operator"
                value={formFilter.op}
                onChange={(value) => handleChangeValue(value, "op")}
                style={{
                  minWidth: document
                    .querySelector(".conditionalSelectOptions")
                    ?.getBoundingClientRect().width,
                  width: "100%",
                }}
                className={`${styles.selectField} conditionalSelectOperator`}
                dropdownClassName={`${styles.selectOptions} conditionalSelectOptions`}
                dropdownStyle={{
                  minWidth: document
                    .querySelector(".conditionalSelectOperator")
                    ?.getBoundingClientRect().width,
                }}
                dropdownMatchSelectWidth={true}
              >
                {getOperations()?.map((item) => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                    className={styles.selectOption}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              {...(typeInput === "date" ? { valuePropName: "date" } : {})}
              name="column_value"
              rules={[
                {
                  required:
                    formFilter.op !== "isEmpty" &&
                    formFilter.op !== "isNotEmpty",
                  message: "This field is required.",
                },
              ]}
            >
              {typeInput == "date" ? (
                <DatePicker
                  disabled={
                    formFilter.op === "isEmpty" ||
                    formFilter.op === "isNotEmpty"
                  }
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  value={
                    formFilter.column_value
                      ? formFilter?.column_value?.length ||
                        formFilter?.column_value instanceof moment
                        ? moment(formFilter?.column_value)
                        : moment(formFilter?.column_value)
                      : undefined
                  }
                  onChange={(value) => handleChangeValue(value, "column_value")}
                  placeholder="Input variable"
                  popupStyle={{ zIndex: 2000 }}
                />
              ) : typeInput == "number" ? (
                <InputNumber
                  disabled={
                    formFilter.op === "isEmpty" ||
                    formFilter.op === "isNotEmpty"
                  }
                  placeholder="Input variable"
                  value={formFilter.column_value}
                  onChange={(value) => handleChangeValue(value, "column_value")}
                />
              ) : ["select", "dropdown", "radio", "checkbox"]?.includes(
                  typeInput
                ) ||
                (activityFields?.find((v) => v.value === formFilter.column)
                  ?.field_options?.length &&
                  (formFilter.op === "$eq" ||
                    formFilter.op === "$ne" ||
                    formFilter.op === "$in")) ? (
                <Select
                  showSearch
                  {...(formFilter.column === "owner_users" ||
                  formFilter.column === "owner_users" ||
                  formFilter.op === "$in"
                    ? { mode: "multiple" }
                    : {})}
                  disabled={
                    formFilter.op === "isEmpty" ||
                    formFilter.op === "isNotEmpty"
                  }
                  placeholder="Input variable"
                  value={formFilter.column_value}
                  onChange={(value) => handleChangeValue(value, "column_value")}
                  style={{
                    minWidth: document
                      .querySelector(".conditionalSelectValueOptions")
                      ?.getBoundingClientRect().width,
                    width: "100%",
                  }}
                  className={`${styles.selectField} conditionalSelectValues`}
                  dropdownClassName={`${styles.selectOptions} conditionalSelectValueOptions`}
                  dropdownStyle={{
                    minWidth: document
                      .querySelector(".conditionalSelectValues")
                      ?.getBoundingClientRect().width,
                  }}
                  dropdownMatchSelectWidth={true}
                  notFoundContent={loader && <Spin size="small" />}
                  allowClear
                  options={
                    activityFields?.find((v) => v.value === formFilter.column)
                      ?.field_options
                  }
                  filterOption={(input, option) => {
                    return (
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {(
                    activityFields?.find((v) => v.value === formFilter.column)
                      ?.field_options || []
                  )?.map((item) => (
                    <Select.Option
                      key={item.value}
                      value={item.value}
                      className={styles.selectOption}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  disabled={
                    formFilter.op === "isEmpty" ||
                    formFilter.op === "isNotEmpty"
                  }
                  placeholder="Input variable"
                  value={formFilter.column_value}
                  onChange={(e) => {
                    handleChangeValue(e.target.value, "column_value");
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item>
              <StyledButton
                htmlType="submit"
                type="default"
                bgcolor="#A68DFB"
                padding="5px 5px"
                icon={<CheckOutlined />}
              />
              <StyledButton
                type="default"
                padding="5px 5px"
                onClick={() => handleDeleteFilter()}
                icon={<DeleteOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  );
};
