import React, { useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { unitsManagementStore } from "../UnitsManagementStore";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import styles from "./AddUnitModal.module.scss";

interface AddUnitGroupModalProps {
  visible: boolean;
  data?: UnitGroup;
  onClose: () => void;
}

export const AddUnitGroupModal = ({
  visible,
  data,
  onClose,
}: AddUnitGroupModalProps) => {
  const [groupData, setGroupData] = useState<UnitGroup>(
    data || ({} as UnitGroup)
  );

  const onChangeData = (newData: UnitGroup) => {
    setGroupData(newData);
  };

  const handleSaveGroup = () => {
    !data
      ? unitsManagementStore.addUnitGroup(groupData)
      : unitsManagementStore.updateUnitGroup(groupData);
    onClose();
  };

  const handleCancel = () => {
    setGroupData({} as UnitGroup);
    onClose();
  };

  return (
    <CommonModal
      title="Add new unit group"
      visible={visible}
      onCancel={handleCancel}
      centered
      footer={[
        <Button key="cancel" type="text" onClick={handleCancel}>
          Cancel
        </Button>,
        <StyledButton
          key="add"
          type="primary"
          onClick={handleSaveGroup}
          disabled={!groupData.name}
        >
          {!data ? "Add unit group" : "Edit unit group"}
        </StyledButton>,
      ]}
    >
      <FormWrapper>
        <Col className={styles.fieldBox} span={24}>
          <Row className={styles.fieldLabel}>Name</Row>
          <Row>
            <Input
              value={groupData.name}
              onChange={(e) =>
                onChangeData({ ...groupData, name: e.target.value })
              }
            />
          </Row>
        </Col>
        <Col className={styles.fieldBox} span={24}>
          <Row className={styles.fieldLabel}>Description</Row>
          <Row>
            <Input
              value={groupData.description}
              onChange={(e) =>
                onChangeData({ ...groupData, description: e.target.value })
              }
            />
          </Row>
        </Col>
      </FormWrapper>
    </CommonModal>
  );
};
