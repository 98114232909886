export const generateEditableCell = (dynamicColums, editingKey) => {
  const mergedColumns = [];
  try {
    return (
      dynamicColums &&
      dynamicColums?.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          sorter: (a, b) => {
            switch (col?.dataType) {
              case "number":
                return (
                  +a[a?.sheet_name][col?.dataIndex] -
                  +b[b?.sheet_name][col?.dataIndex]
                );
              case "date":
                return Date.parse(a[a?.sheet_name][col?.dataIndex]) >
                  Date.parse(b[b?.sheet_name][col?.dataIndex])
                  ? 1
                  : 0;
              default:
                return (
                  a[a?.sheet_name][col?.dataIndex] ||
                  "".localeCompare(b[b?.sheet_name][col?.dataIndex] || "")
                );
            }
          },
          onCell: (record) => {
            return {
              ...col,
              record,
              editing: record?.[record.sheet_name]?.id === editingKey,
            };
          },
        };
      })
    );
  } catch (error) {
    return mergedColumns;
  }
};
export const getMetaDataDocuments = (metadata, isDeleted) => {
  try {
    const documents = metadata[metadata.sheet_name];
    return documents
      .map((item) => {
        return { ...item, archive: item.archive || false };
      })
      .filter((v) => v.archive === isDeleted);
  } catch (error) {
    return [];
  }
};
