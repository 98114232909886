import { Button, Col, Collapse, Input, Row, Spin } from "antd";

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";
import { userInfoStore } from "../../../../Carbon/UserInfoStore";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { useCheckRolesPermissions } from "../../../../../hooks/useCheckRolesPermissions";
import styles from "./InfoAndNotesDrawer.module.scss";
import moment from "moment";

interface Notes {
  isCalculationLocked: boolean;
  calculationEntry: Emission | Offset;
  calculationEntryType: CalculationEntryType;
  onClose: () => void;
}

export const Notes = observer(
  ({
    isCalculationLocked,
    calculationEntry,
    calculationEntryType,
    onClose,
  }: Notes) => {
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState("");
    const [savedNotes, setSavedNotes] = useState<Note[]>();
    const [currentEditableNote, setCurrentEditableNote] = useState<Note>(
      {} as Note
    );
    const { checkRolesPermission } = useCheckRolesPermissions();
    useEffect(() => {
      userInfoStore.fetchUserInfo();
    }, []);

    useEffect(() => {
      const notesWithKeys =
        calculationEntry.notes &&
        calculationEntry.notes.map((item) => ({ ...item, key: uuidv4() }));
      checkRolesPermission(["role:admin"])
        ? setSavedNotes(notesWithKeys)
        : setSavedNotes(notesWithKeys?.filter((v) => !v.isDeleted));
    }, [calculationEntry.notes]);

    const sortedNotes = savedNotes
      ?.slice()
      .sort(
        (objA: Note, objB: Note) =>
          Date.parse(objB.date) - Date.parse(objA.date)
      );

    const handleAddNote = () => {
      if (note) {
        setLoading(true);
        const newNote = {
          user: userInfoStore.userName,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          text: note,
        };
        const newItem = {
          ...calculationEntry,
          _id: calculationEntry._id,
          calculation_id: calculationEntry.calculation_id,
          notes: calculationEntry?.notes
            ? [...calculationEntry?.notes, newNote]
            : [newNote],
        } as Emission | Offset;
        if (calculatorStore.calculationId.length) {
          calculatorStore.editPartialCalculationEntry(newItem);
        } else
          calculatorStore.editCalculationStepperEntry({
            ...newItem,
          });
      }
      setNote("");
      setLoading(false);
    };
    const handleClearNote = () => {
      setNote("");
    };
    const handleClickEditNote = (value: Note) => {
      setCurrentEditableNote(value);
    };
    const handleEditNote = (v: Note) => {
      if (savedNotes) {
        const notes = savedNotes.map((note) => {
          if (note.key == v.key) {
            return {
              ...note,
              text: currentEditableNote.text,
            };
          } else {
            return note;
          }
        });
        editEntry(notes);
      }
    };
    const handleDeleteNote = (v: Note) => {
      if (savedNotes) {
        const notes = savedNotes.map((note) => {
          if (note.key == v.key) {
            return {
              ...note,
              isDeleted: true,
            };
          } else {
            return note;
          }
        });

        editEntry(notes);
      }
    };

    const editEntry = (notes: Note[]) => {
      if (calculatorStore.calculationId.length) {
        calculatorStore.editCalculationEntry({ ...calculationEntry, notes });
      } else
        calculatorStore.editCalculationStepperEntry({
          ...calculationEntry,
          notes,
        });
    };
    return (
      <FormWrapper>
        <Spin spinning={loading}>
          <div className={styles.drawerContent}>
            {!isCalculationLocked && (
              <>
                <p>Add your note</p>
                <Input.TextArea
                  className={styles.drawerItemInput}
                  placeholder="Enter notes"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <StyledButton onClick={handleAddNote}>Add note</StyledButton>
                <Button type="text" onClick={handleClearNote}>
                  Clear
                </Button>
              </>
            )}
            <ul className={styles.drawerList}>
              {sortedNotes &&
                sortedNotes.map((v) => (
                  <li className={styles.drawerListItem} key={v.key}>
                    <Row style={{ alignItems: "baseline" }}>
                      <Col span={16}>
                        <div className={styles.drawerListItemTitle}>
                          <div
                            className={styles.drawerListItemTitleDescription}
                          >
                            {v.user}
                          </div>
                          <div>{v.date.slice(0, 11)}</div>
                        </div>{" "}
                      </Col>

                      {v.user === userInfoStore.userName && !v.isDeleted && (
                        <Col span={8} className={styles.btnIconsWrap}>
                          {" "}
                          <Button
                            className={styles.btnIcon}
                            onClick={() => {
                              handleDeleteNote(v);
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                          <Button
                            className={styles.btnIcon}
                            onClick={() => handleClickEditNote(v)}
                          >
                            <EditOutlined />
                          </Button>
                        </Col>
                      )}
                    </Row>
                    {currentEditableNote?.key === v.key ? (
                      <>
                        <Input.TextArea
                          className={styles.drawerItemInput}
                          placeholder="Enter notes"
                          value={currentEditableNote.text}
                          onChange={(e) =>
                            setCurrentEditableNote((prev) => ({
                              ...prev,
                              text: e.target.value,
                            }))
                          }
                        />
                        <StyledButton onClick={() => handleEditNote(v)}>
                          Ok
                        </StyledButton>
                      </>
                    ) : v.isDeleted ? (
                      <del className={styles.drawerListItemText}>{v.text}</del>
                    ) : (
                      <p className={styles.drawerListItemText}>{v.text}</p>
                    )}
                  </li>
                ))}
            </ul>
            <div className={styles.drawerFooter}>
              <StyledButton onClick={onClose}>Close</StyledButton>
            </div>
          </div>
        </Spin>
      </FormWrapper>
    );
  }
);
