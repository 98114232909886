import React, { useState } from "react";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
  CommonTable,
  StyledTag,
} from "../../../shared/commonStyles";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
import { Form, Row, Col, Typography, Tooltip } from "antd";
import { stripHtmlTags } from "../../../shared/commonFunctions";
import { LeftOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import ShowMixedSurveyFormViewModal from "../components/ShowMixedSurveyFormViewModal";
import { siteThemeColors } from "../../../shared/colors";
import { CreateWelcomePageModal } from "../../createDataSheetSurvey/Steps/CreateWelcomePageModal";
const { Title } = Typography;

const SurveyOrder = ({
  onSubmitHandler,
  onClickPrevious,
  sortedSurveyList,
  setSortedSurveyList,
  loadingCreateSurvey,
  onSortEnd,
  action,
}) => {
  const [rowDataView, setRowDataView] = useState(
    sortedSurveyList.length ? sortedSurveyList[0] : null
  );
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [welcomePageModal, setWelcomePageModal] = useState(false);
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = sortedSurveyList.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "Name",
      width: "25%",
      className: "drag-visible",
      render: (_, record) => {
        if (record.type === "standards") {
          return record.name;
        } else if (record.type === "welcome_page") {
          return record.subject;
        } else {
          return record.sheet_name;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
      className: "drag-visible",
      render: (_, record) => {
        if (record.type === "standards") {
          return record.tags.theme;
        } else {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "300px",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {stripHtmlTags(record?.description)}
              </div>
            </div>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "20%",
      render: (_, record) => {
        if (record.type === "standards") {
          return "Standards";
        } else if (record.type === "welcome_page") {
          return "Welcome Page";
        } else {
          return "Data Collection";
        }
      },
    },
    {
      title: "Standard",
      dataIndex: "standards",
      width: "12%",
      render: (record, data) =>  {
        if (data.type === "standards") {
          return record.map((a, i) => (
            <StyledTag className="margin-2" key={data.id + i}>
              {a}
            </StyledTag>
          ));
        } else {
          return " - ";
        }
      },
      className: "drag-visible",
    },
    {
      title: "Order",
      dataIndex: "index",
      width: "5%",
      className: "drag-visible",
      render: (record) => {
        return record + 1;
      },
    },
  ];
  return (
    <>
      <FormWrapper>
        <ShowMixedSurveyFormViewModal
          rowDataView={rowDataView || sortedSurveyList[0]}
          showFormViewModal={showFormViewModal}
          setShowFormViewModal={setShowFormViewModal}
          sortedSurveyList={sortedSurveyList}
          setRowDataView={setRowDataView}
        />
        <CreateWelcomePageModal
          visible={welcomePageModal}
          onClose={() => setWelcomePageModal(false)}
          setSortedDataSurveyList={setSortedSurveyList}
        />
        <FormWrap width="90%">
          <>
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item>
                  <Title level={3} className="color-white">
                    Survey order
                  </Title>
                  <Row>
                    <Col span={12}>
                      <Title level={4}>
                        Change order of the surveys inside the flow if needed
                      </Title>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      {" "}
                      <StyledButton
                        type="custom"
                        onClick={() => setWelcomePageModal(true)}
                      >
                        <PlusOutlined />
                        <span>Add welcome page</span>
                      </StyledButton>
                      <StyledButton
                        type="custom"
                        onClick={() => setShowFormViewModal(true)}
                      >
                        <span>Open preview</span>
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <CommonTable
                    pagination={false}
                    dataSource={sortedSurveyList}
                    columns={columns}
                    rowKey="index"
                    scroll={{ x: true }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="flex-start">
                    <StyledButton
                      className="margin-5"
                      onClick={() => onClickPrevious()}
                      type="custom"
                    >
                      <LeftOutlined /> <span>Previous</span>
                    </StyledButton>

                    <StyledButton
                      type="custom"
                      onClick={onSubmitHandler}
                      loading={loadingCreateSurvey}
                    >
                      {action === "update" ? "Update Survey" : "Create Survey"}
                    </StyledButton>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </>
        </FormWrap>
      </FormWrapper>
    </>
  );
};

export default SurveyOrder;
