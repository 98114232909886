import { CommonModal, FormWrapper } from "../../../../shared/commonStyles";
import { Form, Input, Switch, message, TreeSelect, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { addUserGroups, updateUserGroups } from "../../../../services/users";
import {
  addPermissions,
  updatePermissionByGroup,
} from "./../../../../services/permissions";
import { Auth } from "aws-amplify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { YearMonthDay24HourFormat } from "../../../../shared/constants";
import moment from "moment";
import styles from "./../../userManagementPage/Components/CreateUserModal.module.scss";

const { SHOW_PARENT } = TreeSelect;
const CreateGroupModal = ({
  visible,
  onClose,
  rowData,
  refresh,
  refDataSheets,
  loadingRefDataSheetsData,
}) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    welcomeMessage: "",
    welcomeMessageTitle: "",
  });

  const [value, setValue] = useState([]);

  const [identifierData, setIdentifierData] = useState([]);
  useEffect(() => {
    if (rowData) {
      try {
        const data = {
          name: rowData?.name ? rowData?.name : "",
          description: rowData?.description ? rowData?.description : "",
          welcomeMessage: rowData?.welcomeMessage ?? "",
          welcomeMessageTitle: rowData?.welcomeMessageTitle ?? "",
          facility: rowData?.metaDataRecords?.length
            ? rowData?.metaDataRecords
            : [],
        };
        rowData?.metaDataRecords?.length && setValue(rowData?.metaDataRecords);
        setInitialValues(data);
        form.setFieldsValue(data);

        const filteredItems = refDataSheets
          .filter((item) =>
            rowData?.permissionsData.find((v) => v.itemId === item.key)
          )
          .map((item) => ({
            identifiers: rowData?.permissionsData.find(
              (v) => v.itemId === item.key
            ).assignedItems,
            sheetId: item.key,
          }));

        setIdentifierData(filteredItems);
      } catch (error) {
        console.log("Error while pre fill form values", error);
      }
    } else {
      const data = {
        name: "",
        description: "",
        welcomeMessage: "",
        welcomeMessageTitle: "",
      };
      setInitialValues(data);
    }
  }, [rowData]);

  const [loaderSaveData, setLoaderSaveData] = useState(false);
  const [form] = Form.useForm();

  const onClickSubmit = () => {
    form
      .validateFields()
      .then(async (val) => {
        try {
          setLoaderSaveData(true);
          const {
            name,
            description,
            status,
            welcomeMessage,
            welcomeMessageTitle,
          } = val;
          const data = await Auth.currentSession();
          const group_id = data["accessToken"]["payload"][
            "cognito:groups"
          ].filter((element) => element.includes("org:"))[0];
          const username = data["accessToken"]["payload"]["username"];

          const payload = {
            name,
            description,
            username,
            group_id,
            status: "ACTIVE",
            created: moment().format(YearMonthDay24HourFormat),
            welcomeMessage,
            welcomeMessageTitle,
          };
          if (rowData?._id) {
            payload._id = rowData._id;
            payload.modified = moment().format(YearMonthDay24HourFormat);
            payload.group_users = rowData?.group_users;

            const responseData = await updateUserGroups(payload);

            if (responseData) {
              const items =
                identifierData?.length &&
                identifierData.map((el) => ({
                  itemId: el.sheetId,
                  assignedItems: el.identifiers,
                }));
              const payload = {
                permissionType: "REFERENCE_DATA",
                groupId: rowData._id.$oid,
                items: items || [],
              };
              await updatePermissionByGroup(payload);
              message.success("Successfully Updated group!");

              onClose(!visible);
            }
          } else {
            const responseData = await addUserGroups(payload);
            if (responseData) {
              message.success("Successfully created group!");

              onClose(!visible);
              form.resetFields();

              identifierData.forEach(async (el) => {
                const payload = {
                  permissionType: "REFERENCE_DATA",
                  itemId: el.sheetId,
                  assignedGroups: [responseData?.$oid],
                  assignedItems: el.identifiers,
                };

                await addPermissions(payload);
              });
            }
          }
          await refresh();
        } catch (error) {
          console.log("Error while create group", error);
          message.error("Error while creating group!");
        } finally {
          setLoaderSaveData(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong while creating group.");
        console.log("Something went wrong while creating group.", e);
        setLoaderSaveData(false);
      });
  };

  const onChange = (newValue, label, extra) => {
    const { allCheckedNodes } = extra;
    const allSelectedItems = [
      ...allCheckedNodes.map((item) => item?.node?.props || item?.props),
    ];
    const chekedDatasheets = allSelectedItems
      .filter((element) => element.children.length)
      .map((v) => ({
        sheetId: v.value,
        identifiers: v.children.map((item) => item.props.value),
      }));

    const groupedChekedRecords = {};
    allSelectedItems
      .filter((element) => element.parent)
      .forEach((obj) => {
        const { parent, value } = obj;
        if (groupedChekedRecords[parent]) {
          groupedChekedRecords[parent].identifiers.push(value);
        } else {
          groupedChekedRecords[parent] = {
            sheetId: parent,
            identifiers: [value],
          };
        }
      });
    const checkedRecords = Object.values(groupedChekedRecords);

    const checkedData = [...chekedDatasheets, ...checkedRecords];
    setIdentifierData(checkedData);

    setValue(newValue);
  };

  const [descriptionData, setDescriptionData] = useState(
    rowData?.welcomeMessage ?? ""
  );

  const changeDescription = (newData) => {
    setDescriptionData(newData);
  };

  return (
    <>
      <CommonModal
        title={rowData?._id ? "Update group" : "Create Group"}
        visible={visible}
        okText={rowData?._id ? "Update" : "Save"}
        onOk={onClickSubmit}
        confirmLoading={loaderSaveData}
        onCancel={(_) => onClose(!visible)}
      >
        <FormWrapper>
          <Form form={form} initialValues={initialValues}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="status"
              label="Active"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input group name!" }]}
            >
              <Input placeholder={""} />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <Input placeholder={""} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="welcomeMessageTitle"
              label="Welcome Banner Title"
            >
              <Input placeholder={""} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="welcomeMessage"
              label="Welcome Message"
            >
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={descriptionData}
                onReady={(editor) => {
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  changeDescription(data);
                  const values = form.getFieldsValue();
                  form.setFieldsValue({ ...values, welcomeMessage: data });
                }}
              />
            </Form.Item>
            <Form.Item labelCol={{ span: 24 }} name="facility" label="Entity">
              <TreeSelect
                notFoundContent={
                  loadingRefDataSheetsData && <Spin size="small" />
                }
                allowClear
                showArrow
                dropdownClassName={styles.treeSelect}
                labelInValue={true}
                treeData={refDataSheets}
                value={value}
                onChange={onChange}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder={"Please select"}
              />
            </Form.Item>
          </Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default CreateGroupModal;
