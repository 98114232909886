import { makeAutoObservable, runInAction } from "mobx";
import { Auth, API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { getCompanyInfo } from "../../services/companyInfo";

class CompanyInfoStore {
  isWhatIfOnboardingCompleted: boolean;

  constructor() {
    this.isWhatIfOnboardingCompleted = false;
    makeAutoObservable(this);
    this.fetchCompanyInfo();
  }

  fetchCompanyInfo = async () => {
    try {
      const baseUrlResponse = await getCompanyInfo();
      if (baseUrlResponse) {
        const response = JSON.parse(baseUrlResponse["data"]);
        runInAction(
          () =>
            (this.isWhatIfOnboardingCompleted =
              !!response.is_whatif_onboarding_completed)
        );
      }
    } catch (e) {
      // ! not sure about this one, but there's a delay before we get the chance to perform GraphQL queries
      runInAction(() => setTimeout(this.fetchCompanyInfo, 3000));
    }
  };

  updateOnboardingCompletion = async () => {
    if (this.isWhatIfOnboardingCompleted) return;

    // getting the most recent data first to update safely
    this.isWhatIfOnboardingCompleted = true;
    const data = await Auth.currentSession();
    const groupId = data
      .getAccessToken()
      .payload["cognito:groups"].filter((element: string) =>
        element.includes("org:")
      )[0];
    const baseUrlResponse = await getCompanyInfo();
    if (baseUrlResponse) {
      const companyDetails = JSON.parse(baseUrlResponse["data"]);

      if (companyDetails) {
        const payload = {
          data: `${JSON.stringify({
            ...companyDetails,
            is_whatif_onboarding_completed: true,
          })}`,
          group: groupId,
        };
        API.graphql({
          query: mutations.updateCompanyInfo,
          variables: { input: payload },
        });
      }
    }
  };
}

const companyInfoStore = new CompanyInfoStore();

export { companyInfoStore };
