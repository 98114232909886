import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../contexts/AuthContext';
import {useCheckRolesPermissions} from "../../hooks/useCheckRolesPermissions";
import { PageWrap } from '../../shared/commonStyles';


const Container = styled.div`
  display : flex;
  justify-content : center;
  width : 100%;
  height : 100%;
`;

const Card = styled.div`
    background-color : #ffffff;
    display : flex;
    justify-content : center;
    align-items: center;
    width : 50%;
    height : 100px;
    margin : 20px;
`;

export const PrivateRoute = ({ children, permissionKey,moduleKey="", ...rest }) => {
    const {loadingAuth} = useContext(AuthContext);
    const {checkPermissions} = useCheckRolesPermissions();
    
    const checkModulePermission = (moduleKey) => {    
        if(moduleKey === "") {return true}
        const isIt = moduleKey && 
        (localStorage.getItem('modules')?.split(",").includes(moduleKey) || localStorage.getItem('modules')?.split(",").includes('ALL_MODULES')) ? true : false ;
        return isIt;
    }


    if(loadingAuth){
       return(
        <PageWrap>
                <Container>
                    <Card>
                        <p>Authenticating...</p>
                    </Card>
                </Container>
        </PageWrap>
       );
    }
    else if (permissionKey && !checkPermissions(permissionKey) || !checkModulePermission(moduleKey) ) {
        // permission not authorized so redirect to dashboard page
        return <Navigate to={{ pathname: '/'}} />;
    }
    else {
        return children;
    }

}