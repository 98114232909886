import React, { useState } from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import { PageWrap, StyledButton } from "../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { StandardSheetTemplateModal } from "./ReportTemplate/Component/StandardSheetTemplateModal";

const { Title } = Typography;

const standardTemplates = require("../../shared/Json/hydrus-standards-sheets.json");

const CreateReport = () => {
  const { checkPermissions } = useCheckRolesPermissions();
  const [standardModalVisible, setStandardModalVisible] = useState(false);
  const [standardCard, setStandardCard] = useState();

  const navigate = useNavigate();
  const cards = [
    {
      title: "Custom",
      link: "/reporting/create-report/report-template",
      type: "custom",
      description: "Create a report from scratch using the report builder",
      icon: "/custom_report_template.jpg",
    },
    {
      title: "Hydrus Default Template",
      link: "/reporting/create-report/report-template",
      type: "default",
      description:
        "Use our default simple report template to disclose needed ESG topics",
      icon: "/hydrus_default_report_template.jpg",
    },
    {
      title: "EHS Report",
      link: "/reporting/create-report/report-template",
      type: "ehs_report",
      description: (
        <>
          Monthly EHS Report Automation <br />
          {"\u00A0"}
        </>
      ),
      icon: "/custom_report_template.jpg",
    },
    ...standardTemplates.map((template) => ({
      title: `${template.sheet_type} Report`,
      link: "/reporting/create-report/report-template",
      type: `standard_${template.sheet_type}`,
      description: "Create a report from scratch using the report builder",
      icon: "/custom_report_template.jpg",
    })),
  ];

  const onSelectStandardReport = (card) => {
    setStandardModalVisible(true);
    setStandardCard(card);
  };
  const onCloseStandardModal = () => {
    setStandardModalVisible(false);
    setStandardCard(null);
  };

  const ReportCard = ({ card, index }) => {
    return (
      <Col xs={12} sm={12} md={6} lg={6} key={index}>
        <Card
          style={{
            width: "100%",
            backgroundColor: "#2d273f",
            padding: "20px",
            height: "100%",
          }}
          bodyStyle={{ padding: "0" }}
        >
          <Title level={4} className="color-white">
            {card.title}
          </Title>
          <div
            style={{
              //backgroundColor: "#403950",
              padding: "15px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <img src={card.icon} width="100%" />
          </div>
          <p className="color-grey">{card.description}</p>
          <StyledButton
            type="custom"
            bgcolor="#443A59"
            bordercolor="#443A59"
            style={{ margin: 0 }}
            onClick={() =>
              card.type.includes("standard_")
                ? onSelectStandardReport(card)
                : navigate(card.link, {
                    state: { type: card.type },
                  })
            }
          >
            Select
          </StyledButton>
        </Card>
      </Col>
    );
  };
  return (
    <PageWrap>
      <StandardSheetTemplateModal
        visible={standardModalVisible}
        onClose={onCloseStandardModal}
        standardCard={standardCard}
      />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <>
          <Title level={4} className="color-white">
            Select Report Template
          </Title>
          {cards?.length && (
            <Row gutter={[16, 16]}>
              {(checkPermissions(["ehs-report"])
                ? cards
                : [...cards.slice(0, -1)]
              ).map((card, index) => {
                return <ReportCard card={card} index={index} key={index} />;
              })}
            </Row>
          )}
        </>
      </Space>
    </PageWrap>
  );
};

export default CreateReport;
