import React, { useState, useEffect } from "react";
import { FormWrap } from "../../../shared/commonStyles";

import { Row, Col } from "antd";
import {
  getUnits,
  getSheetById,
  metadataSheetTypesList,
} from "../../../services/dataSheet";
import { v4 as uuidv4 } from "uuid";
import FormBuilder from "../../createDataSheet/components/FormBuilder";

type ExtendedSheetSchema = SheetSchema & {
  schemasKey?: string;
};
interface Step2Props {
  initialValues: DataSheet;
  loadingCreateDataSheet: boolean;
  onClickUpdateSheet: (
    validationRules: ValidationRule[],
    value: SheetSchema[]
  ) => Promise<void>;
  onClickPrevious: () => void;
}

const Step2 = ({
  onClickUpdateSheet,
  onClickPrevious,
  initialValues,
  loadingCreateDataSheet,
}: Step2Props) => {
  const [measures, setMeasures] = useState([]);
  const [units, setUnits] = useState([]);
  const [metaDataEntityItems, setMetaDataEntityItems] = useState(null);
  const { sheet_type } = initialValues;

  useEffect(() => {
    async function validateFrom() {
      if (initialValues?.metadata_sheet_id) {
        const dataSheet = await getSheetById(initialValues?.metadata_sheet_id);
        setMetaDataEntityItems(dataSheet?.sheet_schema);
      }

      const listUnits = await getUnits();
      if (listUnits) {
        const _measures = listUnits?.reduce(
          (
            result: string[],
            unit: {
              display_name: string;
              measure: string;
              unit_id: string;
            }
          ) => {
            if (result.indexOf(unit.measure) === -1) result.push(unit.measure);
            return result;
          },
          []
        );
        setMeasures(_measures);
        setUnits(listUnits);
      }
    }

    validateFrom();
  }, []);

  const isMetadata = !!metadataSheetTypesList.find(
    (item) => item === sheet_type
  );
  const schemasWithKey = initialValues?.sheet_schema?.map((v) => ({
    ...v,
    schemasKey: uuidv4(),
  }));

  return (
    <FormWrap
      width={"100%"}
      style={{
        padding: "0px",
      }}
    >
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <FormBuilder
            isEditMode
            schema={schemasWithKey as ExtendedSheetSchema[]}
            initialValidationRules={initialValues?.validationRules}
            units={units}
            measures={measures}
            metaDataEntityItems={metaDataEntityItems}
            submitKey={undefined}
            loadingCreateDataSheet={loadingCreateDataSheet}
            onFinishHandler={onClickUpdateSheet}
            onClickPrevious={onClickPrevious}
            isMetadata={isMetadata}
          />
        </Col>
      </Row>
    </FormWrap>
  );
};

export default Step2;
