import React from "react";
import { useBoolean, useSetState } from "ahooks";
import { Button, DatePicker, Input, InputNumber } from "antd";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import styles from "./UpdateCurrentDataModal.module.scss";

interface CreateNewPlanModalProps {
  modalTitle: string;
  data: Plan;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (plan: Plan) => void;
}

export const CreateNewPlanModal = ({
  modalTitle,
  data,
  isOpen,
  onClose,
  onConfirm,
}: CreateNewPlanModalProps) => {
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const dateFormat = "YYYY-MM-DD";

  const [plan, setPlan] = useSetState<Plan>({
    key: uuidv4(),
    name: data.name || "",
    startDate:
      moment(data.startDate).format(dateFormat) || moment().format(dateFormat),
    endDate:
      moment(data.endDate).format(dateFormat) || moment().format(dateFormat),
    goal: data.goal || 0,
    monthlyReduction: data.monthlyReduction || 0,
  });

  // Formula for monthly reduction
  // Monthly reduction = (Base year emission - new goal) / months of this reduction plan
  const calculateMonthlyReduction = (
    goal: number | null,
    startPeriod?: string,
    endPeriod?: string
  ) => {
    const startDate = startPeriod
      ? new Date(startPeriod || "")
      : new Date(plan.startDate);
    const endDate = endPeriod
      ? new Date(endPeriod || "")
      : new Date(plan.endDate);
    const goalValue = goal ? goal : plan.goal;
    const months =
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear());

    const monthlyReduction =
      goalValue && months !== 0
        ? (+reductionPlanStore.currentTarget.baseYearConsumptionAmount -
            goalValue) /
          months
        : 0;
    return Math.round(monthlyReduction);
  };

  const handleChangeName = (value: string) => {
    setPlan({ name: value });
  };

  const handleChangeDate = (value: RangeValue) => {
    setPlan({
      startDate: value?.[0].format(dateFormat),
      endDate: value?.[1].format(dateFormat),
      monthlyReduction: calculateMonthlyReduction(
        null,
        value?.[0].format(dateFormat),
        value?.[1].format(dateFormat)
      ),
    });
  };

  const handleChangeGoal = (value: number) => {
    setPlan({
      goal: value,
      monthlyReduction: calculateMonthlyReduction(value),
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(plan);
    onClose();
  };

  const getDatePanel = (panelNode: React.ReactNode) => {
    return (
      <>
        <p className={styles.dateRangeTitle}>Time period</p>
        <div className={styles.dateRangeResult}>
          <span>{moment(plan.startDate).format(dateFormat)}</span> -{" "}
          <span>{moment(plan.endDate).format(dateFormat)}</span>
        </div>
        <div className={styles.dateRangeSelector}>{panelNode}</div>
        <div className={styles.dateRangeButtons}>
          <StyledButton type="primary" onClick={hideModal}>
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <>
      <CommonModal
        title={modalTitle}
        visible={isOpen}
        footer={[
          <Button type="text" onClick={handleClose} key="cancel">
            Cancel
          </Button>,
          <Button type="primary" onClick={handleConfirm} key="confirm">
            {modalTitle === "Create new reduction plan"
              ? "Create plan"
              : "Edit plan"}
          </Button>,
        ]}
        onCancel={handleClose}
        maskClosable={false}
      >
        <FormWrapper>
          <div>Name</div>
          <div>
            <Input
              className={styles.inputSuccessStep}
              placeholder="Enter the name of reduction plan"
              value={plan.name}
              onChange={(e) => handleChangeName(e.target.value)}
            />
          </div>
          <div>Timeframe</div>
          <FormWrapper>
            <DatePicker.RangePicker
              className={styles.datePicker}
              open={isModalVisible}
              onFocus={showModal}
              onChange={(value) =>
                handleChangeDate(value || [moment(), moment()])
              }
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              panelRender={getDatePanel}
              placeholder={["Date", "Date"]}
              dropdownClassName={styles.dateRangeBox}
              format={dateFormat}
              value={[moment(plan.startDate), moment(plan.endDate)]}
            />
          </FormWrapper>
          <div>Goal</div>
          <div>
            <InputNumber
              className={styles.inputSuccessStep}
              placeholder="Enter the reduction goal"
              value={plan.goal}
              onChange={(value) => handleChangeGoal(value || 0)}
            />
          </div>
          <div>
            To achieve the goal, the monthly reduction must exceed{" "}
            {plan.monthlyReduction} kWh
          </div>
        </FormWrapper>
      </CommonModal>
    </>
  );
};
