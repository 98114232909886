import React, { useEffect, useState } from "react";
import { Form, Button, Select, Col, Row, message, Spin } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { getUsersList } from "../../../../../services/users";
import UsersGroupList from "../../../../goalsProgressPageNew/manageGoals/UsersGroupList";
import UsersList from "../../../../goalsProgressPageNew/manageGoals/UsersList";
const AddAssignees = ({
  isVisible,
  onClose,
  rowData,
  onSaveAssignee,
  assignType,
}) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [initialvalues, setInitialValues] = useState({
    userIds: [],
    groupIds: [],
  });
  useEffect(() => {
    let initData = {}
    if (assignType === "editor") {
      initData = {
        userIds: rowData?.editors ? rowData?.editors?.userIds : [],
        groupIds: rowData?.editors ? rowData?.editors?.groupIds : [],
      };      
    } else {
      initData = {
        userIds: rowData?.auditors ? rowData?.auditors?.userIds : [],
        groupIds: rowData?.auditors ? rowData?.auditors?.groupIds : [],
      };
      
    }
    console.log('initData',initData)
    setInitialValues(initData);
    form.setFieldsValue(initData);
  }, [rowData]); 
 
  return (
    <CommonModal
      title={assignType === "editor" ? "Add Editors" : "Assign Auditors"}
      visible={isVisible}
      width={600}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      centered
      footer={[
        <Button key="close" ghost onClick={(_) => onClose()}>
          Close
        </Button>,
        <StyledButton
          key="save"
          onClick={(_) => onSaveAssignee(rowData, assignType, form)}
        >
          {assignType === "editor" ? "Add Editors" : "Assign Auditors"}
        </StyledButton>,
      ]}
    >
      <Spin spinning={loader}>
        <FormWrapper>
          <Form form={form} initialValues={initialvalues}>
            <Row justify="space-between">
              <Col span={24}>
                <UsersList />
                <UsersGroupList />

              </Col>              
            </Row>
          </Form>
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};

export default AddAssignees;
