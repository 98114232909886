import React, { useEffect, } from "react";
import { Button, Form, Select } from "antd";
import {
  CommonModal,
  FormWrapper,
} from "../../../shared/commonStyles";

const ChangeStatusModal = ({
  visible,
  onClose,
  rowData,
  setInitialValues,
  form,
  onClickUpdateStatus,
  loading,
  initialValues
}) => {
  useEffect(() => {
    if (rowData) {
      const initialValue = {
        status: rowData?.status,
      };
      form.setFieldsValue(initialValue);
      setInitialValues(initialValue);
    }
  }, [rowData]);
  const onChangeFieldsData = async (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name]: data?.[0]?.value,
      }));
    }
  };
 
  return (
    <>
      <CommonModal
        destroyOnClose={true}
        width={600}
        title={"Update Audit Status"}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="Cancel" ghost onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="Create"
            loading={loading}
            type="primary"
            onClick={onClickUpdateStatus}
          >
            Update
          </Button>,
        ]}
      >
        <FormWrapper>
          <Form
            onFieldsChange={(data) => onChangeFieldsData(data)}
            initialValues={initialValues}
            autoComplete="off"
          >
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Status</span>}
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please choose status!",
                },
              ]}
            >
              <Select placeholder="Please select a status">
                <Select.Option key="1" value="IN_PROGRESS">
                  IN PROGRESS
                </Select.Option>
                <Select.Option key="2" value="COMPLETED">
                  COMPLETED
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default ChangeStatusModal;
