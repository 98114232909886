import * as queries from "../../../graphql/queries";

import {
  BorderedTable,
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { Col, Row, Select, Space, Spin, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  fetchRecordData,
  getMessageByType,
  listMetaDataRecords,
  makeQueryMetadata,
} from "../../../services/dataSheet";
import {
  getImportDataById,
  insertImportData,
  updateImportData,
} from "../../../services/importData";
import { API } from "aws-amplify";
import { ExcelRenderer } from "react-excel-renderer";
import FieldMappingIdentifier from "./FieldMappingIdentifier";
import { YearMonthDayFormat } from "../../../shared/constants";
import { getMetaDataDocuments } from "../../dataSheetsPage/dataSheetDocumentsPage/MetaDataDocuments/MetaDataDocumentsFunctions";
import { isAirDistanceSheet } from "../../dataSheetsPage/dataSheetDocumentsPage/DataSheetDocumentsFunctions";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import surveyKpiService from "../../../services/SurveyKpiService";
import XLSX from "xlsx";

const { Title } = Typography;

const Step3 = ({
  files,
  isMetadata,
  onClickPrevious,
  onClickNext,
  headers,
  setHeaders,
  initialValues,
  setInitialValues,
  dataSheet,
  state,
  currentStep,
  identifierValues,
  identifierColumn,
}) => {
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSheetRecords, setDataSheetRecords] = useState([]);
  const [fieldsMapping, setFieldsMapping] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [showDataMapping, setShowDataMapping] = useState(false);
  const [userDataToMapping, setUserDataToMapping] = useState();
  const [userUploadedData, setUserUploadedData] = useState([]);
  const [dataMapping, setDataMapping] = useState();

  useEffect(() => {
    setUserDataToMapping({});
  }, [fieldsMapping]);

  const readFile = (file) => {
    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.log("read file error : ", err);
      } else {
        resp.rows.forEach((item, index) => {
          if (index === 0) {
            item.forEach((v) =>
              setHeaders((current) => [...current, { item: v }])
            );
          } else {
            const objectArr = resp.rows[0].reduce((result, key, i) => {
              result[key] = resp.rows[index][i];
              return result;
            }, {});
            !Object.values(objectArr)?.every((v) => !v) &&
              setUserUploadedData((prev) => [...prev, objectArr]);
          }
        });
      }
    });
  };

  const readKPIFile = async (file) => {
    try {
      const fileName = file.url && file.url?.split("/")?.pop();
      const version =
        file.url && file.url?.split("/")?.slice(-2, -1)[0]?.replace("v", "");
      const response = await API.graphql({
        query: queries["dataSheetImport"],
        variables: {
          request_type: "DATASHEET_DOWNLOAD",
          data_id: file.fileId,
          version: 1,
          file_name: fileName,
        },
      });
      const signedURL = response["data"]["dataSheetImport"];
      if (signedURL) {
        const resp = await fetch(signedURL);
        const fileBlob = await resp.blob();
        const arrayBuffer = await fileBlob.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);

        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        if (!jsonData) {
          console.log("read file error");
        } else {
          jsonData.forEach((item, index) => {
            if (index === 0) {
              item.forEach((v) =>
                setHeaders((current) => [...current, { item: v }])
              );
            } else {
              const objectArr = jsonData[0].reduce((result, key, i) => {
                result[key] = jsonData[index][i];
                return result;
              }, {});
              !Object.values(objectArr)?.every((v) => !v) &&
                setUserUploadedData((prev) => [...prev, objectArr]);
            }
          });
        }
      }
    } catch (error) {
      setBtnDisable(true);
      message.error("Something went wrong while reading uploaded file!", error);
    }
  };

  const loadSheetRecords = async () => {
    try {
      setLoadingSheetRecords(true);
      setBtnDisable(true);

      const Payload = {
        skip: 0,
        limit: 3,
        filter: {},
        sort: { _id: 1 },
        sheet_id: initialValues?.sheet_id,
        record_types: {
          include: [],
          exclude: ["archived", "imported_pending"],
        },
      };
      const sheetRecords = await fetchRecordData(Payload);
      const _dataSheetRecords = sheetRecords?.data?.map((record, index) => ({
        ...record[record?.sheet_name],
        key: index + 1,
      }));
      setDataSheetRecords(_dataSheetRecords);
    } catch (error) {
      setLoadingSheetRecords(false);
      setBtnDisable(false);
    } finally {
      setLoadingSheetRecords(false);
      setBtnDisable(false);
    }
  };

  const loadMetadataRecords = async () => {
    try {
      setLoadingSheetRecords(true);
      setBtnDisable(true);
      const payload = makeQueryMetadata({
        sheetId: dataSheet?._id?.$oid,
        pagination: { current: 1, pageSize: 3 },
      });
      const metaDataDocuments = await listMetaDataRecords(payload);
      const records = metaDataDocuments.data?.map((record, index) => ({
        ...record[record?.sheet_name],
        key: index + 1,
      }));
      setDataSheetRecords(records);
    } catch (error) {
      setLoadingSheetRecords(false);
      setBtnDisable(false);
    } finally {
      setLoadingSheetRecords(false);
      setBtnDisable(false);
    }
  };

  useEffect(() => {
    if (
      headers &&
      headers.length === 0 &&
      files?.length &&
      !loadingSheetRecords
    ) {
      state?.kpiFile ? readKPIFile(files[0]) : readFile(files[0]);
    }
  }, [currentStep]);

  useEffect(() => {
    isMetadata ? loadMetadataRecords() : loadSheetRecords();
  }, [initialValues?.sheet_id]);

  const handleFieldMapChange = (cols) => {
    const data_sheet_col = cols?.split("__")[0];
    const spreed_sheet_col = cols?.split("__")[1];
    const updatedFieldMap = {
      [data_sheet_col]: spreed_sheet_col,
    };

    setFieldsMapping((fieldMap) => ({
      ...fieldMap,
      ...updatedFieldMap,
    }));
  };

  useEffect(() => {
    if (dataSheet?.sheet_schema?.length && headers?.length) {
      filteredSchema?.map((item) => {
        headers?.find((element) => {
          let updatedFieldMap = {};
          if (
            element.item === item.entity_name ||
            element.item === item.display_name
          ) {
            updatedFieldMap = {
              [item.entity_name]: element.item,
            };
          } else if (
            element.item === item.entity_name + "_unit_id" ||
            element.item === item.display_name + " Unit"
          ) {
            updatedFieldMap = {
              [item.entity_name + "_unit_id"]: element.item,
            };
          }

          setFieldsMapping((fieldMap) => ({
            ...fieldMap,
            ...updatedFieldMap,
          }));
        });
      });
    }
  }, [dataSheet, headers]);

  const generateDynamicColumns = (input_type, entity_name, display_name) => {
    const columns = [
      {
        title: "#",
        dataIndex: "key",
        className: "Fixed_Actions_Right_Side",
      },
      {
        title: display_name,
        dataIndex: entity_name,
        width: "35%",
        render: (value, record) => {
          if (input_type === "date") {
            return (
              <div>
                {value === ""
                  ? ""
                  : moment.utc(value).format(YearMonthDayFormat)}
              </div>
            );
          } else if (entity_name?.includes("unit_id")) {
            return <div>{value || input_type}</div>;
          } else {
            return (
              <div>
                {typeof value === "string" ||
                typeof value === "number" ||
                value instanceof String
                  ? value
                  : null}
              </div>
            );
          }
        },
      },
      {
        title: () => {
          const default_value = fieldsMapping[entity_name] || null;
          return (
            <Select
              defaultValue={default_value}
              style={{ width: "100%" }}
              placeholder="Select column"
              onChange={handleFieldMapChange}
              disabled={
                state?.action &&
                state?.action !== "kpiMapping" &&
                initialValues?.import_status !== "REJECTED"
              }
            >
              {headers &&
                headers?.map((item, index) => {
                  return (
                    <Select.Option
                      value={entity_name + "__" + item.item}
                      key={index}
                    >
                      {item.item}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        },
        dataIndex: "action",
      },
    ];
    return columns;
  };

  const fillColor = (display_name, entity_name) => {
    const isMatched = headers?.find((element) => {
      if (element.item === display_name || element.item === entity_name) {
        return true;
      }
      return false;
    });
    if (isMatched) {
      return <span style={{ color: "#28A87A" }}>automapped</span>;
    } else {
      return <span style={{ color: "#FCE622" }}>action required</span>;
    }
  };

  const sheetSchema = dataSheet?.sheet_schema
    ?.filter((item) => {
      if (
        !state ||
        state?.type === "dataSheet" ||
        state?.type === "pendingKpi"
      ) {
        const isIdentifierColumn =
          item.metadata_entity_name && identifierColumn
            ? item.entity_name === identifierColumn
            : item;
        return (
          isIdentifierColumn &&
          !item.isExpression &&
          item.input_type !== "expression"
        );
      } else {
        return (
          !item.metadata_entity_name &&
          !item.isExpression &&
          item.input_type !== "expression"
        );
      }
    })
    ?.filter((item) =>
      isAirDistanceSheet(dataSheet)
        ? item.entity_name !== "distance" && item.entity_name !== "routing"
        : item
    );
  const filteredSchema = state?.displayFields
    ? sheetSchema?.filter((item) =>
        state?.displayFields.find((v) => item.entity_name === v)
      )
    : sheetSchema;

  const schemaWithUnits = filteredSchema
    ?.map((schema) =>
      schema.unit_id
        ? [
            schema,
            {
              entity_name: schema.entity_name + "_unit_id",
              display_name: schema.display_name + " Unit",
              input_type: schema.unit_id,
            },
          ]
        : schema
    )
    .flat();
  const getWrongValues = () => {
    if (identifierColumn) {
      const identifierUserColumn = fieldsMapping[identifierColumn];
      if (identifierUserColumn) {
        const userValuesByCol = userUploadedData.map(
          (item) => item[identifierUserColumn]
        );
        const wrongIdentifierValues = userValuesByCol?.filter(
          (value) => !identifierValues?.find((v) => v === value) && value
        );
        const uniqValues = [...new Set(wrongIdentifierValues)];
        !!uniqValues?.length &&
          setUserDataToMapping((prev) => ({
            ...prev,
            [identifierColumn]: uniqValues,
          }));
      }
    }
    if (filteredSchema?.some((item) => item.unit_id)) {
      filteredSchema
        ?.filter((item) => item.unit_id)
        ?.forEach((item) => {
          const userColumn = fieldsMapping[`${item.entity_name}_unit_id`];
          const userColumnValue = fieldsMapping[`${item.entity_name}`];
          if (userColumn) {
            const userValuesByCol = userUploadedData
              ?.filter(
                (v) => v[userColumnValue]?.toString()?.length && v[userColumn]
              )
              .map((v) => v[userColumn]);
            const wrongValues = [
              ...new Set(
                userValuesByCol?.filter(
                  (value) =>
                    !item.convertable_units?.find((v) => v.unit === value)
                )
              ),
            ];
            !!wrongValues?.length &&
              setUserDataToMapping((prev) => ({
                ...prev,
                [`${item.entity_name}_unit_id`]: wrongValues,
              }));
          }
        });
    }
  };

  useEffect(() => {
    getWrongValues();
  }, [fieldsMapping, userUploadedData, identifierColumn]);

  const generateTable = () => {
    let tableList = null;

    tableList =
      schemaWithUnits?.length &&
      schemaWithUnits?.map((item, index) => {
        return (
          <BorderedTable
            key={index}
            style={{ width: "100%" }}
            columns={generateDynamicColumns(
              item.input_type,
              item.entity_name,
              item.display_name
            )}
            dataSource={dataSheetRecords}
            pagination={false}
            title={(data, record) => {
              return (
                <>
                  <Row justify="space-between" style={{ paddingLeft: "25px" }}>
                    <Col span={9}>
                      <span>Datasheet Column Name</span>
                    </Col>
                    <Col span={9}>
                      <span>Spreadsheet Column Name</span>
                    </Col>
                    <Col span={6} style={{ textAlign: "right" }}>
                      {fillColor(item.display_name, item.entity_name)}
                    </Col>
                  </Row>
                </>
              );
            }}
          />
        );
      });
    return tableList;
  };

  const processFile = async (dataId, fileName, _id, import_statusx) => {
    try {
      const variablesProcess = {
        request_type: !isMetadata ? "PROCESS_FILE" : "PROCESS_METADATA",
        import_id: _id,
        import_status: "PENDING_REVIEW",
        sheet_id: initialValues?.sheet_id,
        sheet_name: dataSheet?.sheet_name,
        ...(!isMetadata && {
          metadata_record: JSON.stringify(state?.defaultMetaDataRecord),
        }),
        ...(state?.surveyId && { data_survey_id: state?.surveyId }),
        ...(state?.taskDeploymentId && {
          taskDeploymentId: state?.taskDeploymentId,
        }),
        version: 1,
        file_name: fileName,
        field_mapping: JSON.stringify(fieldsMapping),
        data_mapping: JSON.stringify(dataMapping),
      };
      const res = await API.graphql({
        query: queries["datalakeImportData"],
        variables: variablesProcess,
      });
      const _res = res?.["data"]["datalakeImportData"];
      if (_res) {
        const isError = _res?.statusCode >= 400;
        if (isError) {
          message.error(_res?.body);
          return Promise.reject();
        }
        _res?.messages && message.success(_res?.messages[0].messageType);
      } else {
        return Promise.reject();
      }
      if (_res) {
        const isError = _res?.statusCode >= 400;
        if (state?.type === "pendingKpi") {
          !!_res?.messages?.length &&
            _res?.messages?.map((item) =>
              getMessageByType(item, dataSheet.sheet_name)
            );
          const isAutomapping =
            _res?.import_document?.automapping_status || isError;
          const files = state?.kpiFile
            ? state?.kpiData?.files?.map((v) => {
                const newRecord = { ...v };
                !isAutomapping &&
                  v.fileId === state?.kpiFile?.fileId &&
                  delete newRecord.automapping_status;
                return v.fileId === state?.kpiFile?.fileId
                  ? {
                      ...newRecord,
                      importStatus: import_status,
                      ...(isAutomapping && {
                        automapping_status:
                          _res?.import_document?.automapping_status ||
                          (isError && "FAILED"),
                      }),
                    }
                  : v;
              })
            : [
                ...(state?.kpiData?.files || []),
                {
                  fileId: dataId,
                  importId: _id,
                  importStatus: import_status,
                  isAdmin: true,
                  userId: userInfoStore.userID,
                  userName: userInfoStore.userName,
                },
              ];
          const payload = {
            _id: state?.kpiData?._id,
            files: files,
          };
          await surveyKpiService.updateSurveyKpi([payload]);
        }

        if (isError) {
          console.log("isError", isError);
          message.error(_res?.body);
        } else onClickNext();
      } else {
        console.log("Something went wrong while processing file!");
        message.error("Something went wrong while processing file!");
      }
    } catch (error) {
      const errorTimeout = error?.errors?.[0].errorType === "ExecutionTimeout";
      if (errorTimeout) {
        message.error(
          "The file is too large. Some records may be unprocessed."
        );
        onClickNext();
      } else console.log("Something went wrong while processing file!", error);
    }
  };

  const onClickContinueToReview = async () => {
    try {
      setLoading(true);
      setBtnDisable(true);

      let import_status = "PENDING_REVIEW";
      if (initialValues?.import_status === "APPROVED") {
        import_status = "APPROVED";
      }
      if (initialValues?.import_status === "REJECTED" && files?.length === 0) {
        import_status = "REJECTED";
      }

      const payloadInsert = {
        import_name: initialValues?.import_name,
        import_type: state?.action ? initialValues?.import_type : files[0].type,
        import_status,
        description: initialValues?.description,
        sheet_id: initialValues.sheet_id,
        data_id: initialValues.data_id || uuidv4(),
        ...(state?.type === "pendingKpi" && {
          surveyKpiId: initialValues?.surveyKpiId || state?.kpiData?._id.$oid,
          surveyId: initialValues?.surveyId || state?.kpiData?.surveyId,
          mainKpiId: initialValues?.mainKpiId || state?.kpiData?.mainKpiId,
          taskDeploymentId:
            initialValues?.taskDeploymentId || state?.kpiData?.taskDeploymentId,
        }),
      };
      const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

      const _id = initialValues?._id?.$oid;
      const dataId = state?.action
        ? initialValues?.data_id
        : payloadInsert.data_id;
      if (files?.length) {
        if (state?.kpiFile) {
          await processFile(
            state?.kpiFile.fileId,
            state?.kpiFile?.url?.split("/")?.pop(),
            _id,
            import_status
          );
        } else {
          const CurrentDate = moment().unix();
          const fileName =
            CurrentDate + "-" + files[0].name?.replace(/[/\\$#@?%*:|"<>]/g, "");
          const requestPayload = {
            file_name: fileName,
            file_category: "DATASHEET",
            version: 1,
            data_id: dataId || initialValues.sheet_id,
          };
          const response = isMetadata
            ? await API.graphql({
                query: queries["datalakeImportData"],
                variables: {
                  request_type: "UPLOAD_METADATA",
                  sheet_id: initialValues.sheet_id,
                  sheet_name: initialValues.sheet_name,
                  file_name: fileName,
                },
              })
            : await API.graphql({
                query: queries["dataSheetFiles"],
                variables: {
                  request_type: "UPLOAD_FILE",
                  request_payload: JSON.stringify(requestPayload),
                  data_id: dataId || initialValues.sheet_id,
                  import_id: _id,
                },
              });
          let signedURL = isMetadata
            ? response["data"]["datalakeImportData"]
            : JSON.parse(response["data"]["dataSheetFiles"]);

          // Ensure signedURL is treated correctly based on its type
          if (typeof signedURL === 'string') {
            signedURL = { url: signedURL, file_id: null }; // Wrap string in an object
          }

          if (signedURL) {
            //console.log("signedURL", signedURL?.file_id);
            const arrayBufferData = await files[0].arrayBuffer();
            if (arrayBufferData) {
              const blob = new Blob([new Uint8Array(arrayBufferData)], {
                type: files[0].type,
              });
              const result = await fetch(signedURL?.url, {
                method: "PUT",
                body: blob,
              });
              let response = null;
              payloadInsert.file_id = signedURL.file_id;
              if (state?.action) {
                payloadInsert._id = initialValues?._id;
                response = await updateImportData(payloadInsert);
              } else {
                payloadInsert.createDate = currentDate;
                response = await insertImportData(payloadInsert);
              }

              if (response) {
                const _id = state?.action
                  ? initialValues?._id.$oid
                  : response.$oid;
                const dataId = state?.action
                  ? initialValues?.data_id
                  : payloadInsert.data_id;
                const dataImport = await getImportDataById(_id);
                const initValues = {
                  ...dataImport,
                  source_file_type: dataImport?.import_type,
                };
                setInitialValues(initValues);
                if (result?.status === 200) {
                  await processFile(dataId, fileName, _id, import_status);
                }
              }
            }
          } else {
            console.log("Something went wrong while uploading file!");
            message.error("Something went wrong while uploading file!");
          }
        }
      } else {
        onClickNext();
      }
    } catch (error) {
      setLoading(false);
      setBtnDisable(false);
      console.log("Error white importing data : ", error);
      message.error("Something went wrong! Data import failed!");
    } finally {
      setLoading(false);
      setBtnDisable(false);
    }
  };
  const isDataMappingCompleted =
    dataMapping &&
    Object.entries(dataMapping)?.every(
      ([key, values]) =>
        Object.keys(values)?.length === userDataToMapping?.[key]?.length
    );
  const isBtnDisabled = showDataMapping ? !isDataMappingCompleted : btnDisable;

  const handleGoNext = () => {
    showDataMapping || _.isEmpty(userDataToMapping)
      ? onClickContinueToReview()
      : setShowDataMapping(true);
  };
  const handleGoBack = () => {
    if (showDataMapping) {
      setShowDataMapping(false);
    } else {
      onClickPrevious();
      setHeaders([]);
    }
  };
  return (
    <>
      <FormWrap
        width="874px"
        style={{
          backgroundColor: "#2D273F",
          borderRadius: "4px",
          marginTop: "50px",
        }}
      >
        {showDataMapping ? (
          <Spin spinning={loading}>
            <FieldMappingIdentifier
              systemValues={identifierValues}
              userValues={userDataToMapping}
              dataMapping={dataMapping}
              identifierColumn={identifierColumn}
              setDataMapping={setDataMapping}
              dataSheet={dataSheet}
            />
          </Spin>
        ) : (
          <FormWrapper style={{ marginTop: "20px" }}>
            <Title level={3} style={{ fontWeight: "600" }}>
              Data mapping {files?.length ? "-" : ""} {files[0]?.name}
            </Title>
            <p className="color-grey font-16">
              Map the corresponding fields from the source file to the scheme of
              the destination file
            </p>
            {loadingSheetRecords ? (
              <Spin spinning={loadingSheetRecords}></Spin>
            ) : (
              <Spin spinning={loading}>
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ width: "100%", marginBottom: "30px" }}
                >
                  {dataSheet?.sheet_schema?.length ? generateTable() : null}
                </Space>
              </Spin>
            )}
          </FormWrapper>
        )}
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <StyledButton
              type="custom"
              onClick={handleGoBack}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              style={{ padding: "11px 11px 11px 0px" }}
            >
              <span>Go back</span>
            </StyledButton>
            <StyledButton
              className="margin-5"
              onClick={handleGoNext}
              type="custom"
              disabled={isBtnDisabled}
            >
              <span>
                {showDataMapping || _.isEmpty(userDataToMapping)
                  ? "Continue to Review"
                  : "Continue to next step"}
              </span>
            </StyledButton>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};

export default Step3;
