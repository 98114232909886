import React from 'react';
import {  Space} from 'antd';
import ESGTemplateDataTable from './ESGTemplateDataTable';
import {  CommonModal } from '../../../../shared/commonStyles';


const ESGTemplateModal = ({ visible,onClose,onFinish }) => {
  
  const businessUnitId = localStorage.getItem('selectedBusinessUnit')
  const reportPeriodId = localStorage.getItem('selectedReportPeriod')
  const locationId = localStorage.getItem('selectedLocation')
  
  return (
    <CommonModal
      title={"ESG Template Library"}
      visible={visible}
      onCancel={onClose}
      width={"100%"}
      footer={null}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <Space direction="vertical" style={{width:"100%"}}>
           <ESGTemplateDataTable
              report_period={reportPeriodId}
              location_id={locationId}
              business_unit_id={businessUnitId}
              onESGTemplateModalClose={onClose}
              refreshReportDataLake={onFinish}
           />
      </Space>
    </CommonModal>
  );
};

export default ESGTemplateModal;
