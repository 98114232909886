import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { PageWrap } from "../../../../../shared/commonStyles";
import { ProgressChart } from "./ProgressChart";
import { ReductionPlans } from "./ReductionPlans";
import { Actions } from "./Actions";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import styles from "./SingleTarget.module.scss";
import moment from "moment";

export const SingleTarget = observer(() => {
  const { state } = useLocation();
  const [data, setData] = useState<Target>({} as Target);
  const [chartData, setChartData] = useState<Plan>({} as Plan);

  useEffect(() => {
    reductionPlanStore
      .getTargetById(state as string)
      .then(() => setData(reductionPlanStore.currentTarget));
  }, [state]);

  useEffect(() => {
    setData(reductionPlanStore.currentTarget);
  }, [reductionPlanStore.currentTarget]);

  return (
    <PageWrap>
      {reductionPlanStore.loadingTargets && (
        <Spin className={styles.calculatorLoader} />
      )}
      <Row justify="space-between">
        <Col span={7} className={styles.generalInfoBlock}>
          <Row>Emission name</Row>
          <Row className={styles.valueOverview}>{data.emissionName}</Row>
        </Col>
        <Col span={4} className={styles.generalInfoBlock}>
          <Row justify="space-between">
            <Col>
              <Row>Scenario</Row>
              <Row className={styles.valueOverview}>{data.scenario}</Row>
            </Col>
            <Col>
              <Row>Scope</Row>
              <Row justify="center" className={styles.valueOverview}>
                {data.scope}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12} className={styles.generalInfoBlock}>
          <Row justify="space-between">
            <Col>
              <Row>Base year consumption amount ({data.baseYear})</Row>
              <Row className={styles.valueOverview}>
                {Number(data.baseYearConsumptionAmount)
                  .toFixed(2)
                  .replace(/\.00$/, "")}
              </Row>
            </Col>
            <Col>
              <Row>Current data ({moment(data.year).format("YYYY")})</Row>
              <Row className={styles.valueOverview}>
                {Number(data.currentConsumptionAmount)
                  .toFixed(2)
                  .replace(/\.00$/, "")}
              </Row>
            </Col>
            <Col>
              <Row>Goal ({data.targetYear})</Row>
              <Row className={styles.valueOverview}>
                {Number(data.goal).toFixed(2).replace(/\.00$/, "")}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ProgressChart
        data={reductionPlanStore.currentTarget}
        reductionPlan={chartData}
      />
      <ReductionPlans onSetChart={setChartData} />
      <Actions targetId={state as string} />
    </PageWrap>
  );
});
