import React from "react";
import { observer } from "mobx-react-lite";
import { InputReasonsModal } from "../CalculationsAudit/Components/InputReasonsModal";
import { calculatorStore } from "../../Carbon";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./HeaderBtns.module.scss";
import { useBoolean } from "ahooks";
import { Button, Dropdown } from "antd";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import moment from "moment";

export const HeaderBtns = observer(() => {
  const calculation = calculatorStore.calculation;

  const [
    isApproveHeaderModalVisible,
    { setFalse: setApproveHeaderModalFalse, set: setApprove },
  ] = useBoolean(false);
  const [
    isDeclineHeaderModalVisible,
    { setFalse: setDeclineHeaderModalFalse, set: setDecline },
  ] = useBoolean(false);

  const handleConfirmCalculation = (
    reason: string,
    audit_state: AuditState
  ) => {
    if (calculation) {
      const prevAudits =
        calculation.audits?.filter(
          (item) => item.user_id !== userInfoStore.userID
        ) || [];
      const payload: ICalculation = {
        ...calculation,
        audits: [
          ...prevAudits,
          {
            status: audit_state,
            comments: reason,
            timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
            auditor_name: userInfoStore.userName,
            user_id: userInfoStore.userID,
          },
        ],
      };
      calculatorStore.updateCalculation(payload, false);
    }
  };

  return (
    <div className={styles.groupOfBtns}>
      <Dropdown
        overlay={() => (
          <InputReasonsModal
            onConfirm={(reason) =>
              handleConfirmCalculation(reason, AuditState.APPROVED)
            }
            onClose={setApproveHeaderModalFalse}
          />
        )}
        trigger={["click"]}
        disabled={
          calculation.audits?.find(
            (item) => item.user_id === userInfoStore.userID
          )?.status === AuditState.APPROVED
        }
        onVisibleChange={(open) => setApprove(open)}
        visible={isApproveHeaderModalVisible}
      >
        <Button type="primary" className={styles.buttonApproved}>
          {calculation.audits?.find(
            (item) => item.user_id === userInfoStore.userID
          )?.status === AuditState.APPROVED ? (
            <>
              <CheckOutlined className={styles.styleOutlined} />
              Approved
            </>
          ) : (
            <>Mark calculation as approved</>
          )}
        </Button>
      </Dropdown>
      <Dropdown
        overlay={() => (
          <InputReasonsModal
            onConfirm={(reason) =>
              handleConfirmCalculation(reason, AuditState.DECLINED)
            }
            onClose={setDeclineHeaderModalFalse}
            isRequired
          />
        )}
        trigger={["click"]}
        disabled={
          calculation.audits?.find(
            (item) => item.user_id === userInfoStore.userID
          )?.status === AuditState.DECLINED
        }
        onVisibleChange={(open) => setDecline(open)}
        visible={isDeclineHeaderModalVisible}
      >
        <Button type="primary" className={styles.buttonDeclined}>
          {calculatorStore.calculation.audits?.find(
            (item) => item.user_id === userInfoStore.userID
          )?.status === AuditState.DECLINED ? (
            <>
              <CloseOutlined className={styles.styleOutlined} />
              Declined
            </>
          ) : (
            <>Decline</>
          )}
        </Button>
      </Dropdown>
    </div>
  );
});
