import {useState} from 'react';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export const useUserSettings = () => {
  const [userSettingsState,setUserSettingsState] = useState({
    userSettings : null,
    loading : false,
  });
    
  const getUserSettings = async(variables) =>{
    try{
        setUserSettingsState(prev => ({...prev,loading:true}));
        const resp =  await API.graphql({query : queries.getUserSettings, variables: variables});
        const settings = resp['data']['getUserSettings'];
        setUserSettingsState(prev => ({...prev,userSettings:settings}));
        return settings;
    }catch(error){
        console.log("Error while load user settings!",error);
        throw Error("Something went wrong!");
    }finally{
        setUserSettingsState(prev => ({...prev,loading:false}));
    }
  }
  
  const createUserSettings = async(payload) =>{
    try{
      setUserSettingsState(prev => ({...prev,loading:true}));
      const response = await API.graphql({query : mutations.createUserSettings, variables: {input : payload}});
      return response['data']['createUserSettings'];
    }catch(error){
      console.log("Error while create user settings!",error);
      throw Error("Something went wrong!");
    }finally{
      setUserSettingsState(prev => ({...prev,loading:false}));
    }
  }

  const updateUserSettings = async(payload) =>{
    try{
      setUserSettingsState(prev => ({...prev,loading:true}));
      const response =  await API.graphql({query : mutations.updateUserSettings, variables: {input : payload}});
      return response['data']['updateUserSettings'];
    }catch(error){
      console.log("Error while update user settings!",error);
      throw Error("Something went wrong!");
    }finally{
      setUserSettingsState(prev => ({...prev,loading:false}));
    }
  }

  return {
    ...userSettingsState,
    createUserSettings,
    updateUserSettings,
    getUserSettings
  };
}