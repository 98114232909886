import React, { useState } from "react";
import { useBoolean } from "ahooks";
import { Button, notification, Menu, Dropdown } from "antd";
import {
  EyeOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  UndoOutlined,
  EditOutlined,
  RedoOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  StyledButton,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";
import { AddOffset } from "../AddOffset";
import { CalculatorEntryHistoryDrawer } from "../CalculatorEntryHistoryDrawer";
import { CalculatorEntryHistoryButtons } from "../CalculatorEntryHistoryButtons";
import styles from "./TableOffsetButtons.module.scss";
import { InfoAndNotesDrawer } from "../InfoAndNotesDrawer";

interface TableOffsetButtonsProps {
  undo: boolean;
  data: Offset;
  editOffset?: (data: Offset) => void;
  onDelete: (data: Offset) => void;
  onClickUndo?: () => void;
  onRestore?: (data: Offset) => void;
  isHistory?: boolean;
  onViewHistory?: (data: CalculatorEntryHistory) => void;
  onRestoreHistory?: (historyData: CalculatorEntryHistory) => void;
  onGoCurrent?: () => void;
}

const TableOffsetButtons = ({
  undo,
  data,
  editOffset,
  onDelete,
  onClickUndo,
  onRestore,
  isHistory,
  onViewHistory,
  onRestoreHistory,
  onGoCurrent,
}: TableOffsetButtonsProps) => {
  const [typeDrawer, setTypeDrawer] = useState<"info" | "notes">("info");
  const [
    drawerVisible,
    { setTrue: setDrawerVisible, setFalse: setDrawerHidden },
  ] = useBoolean(false);
  const [
    isEditModalVisible,
    { setTrue: showEditModal, setFalse: hideEditModal },
  ] = useBoolean(false);
  const [
    drawerHistoryVisible,
    { setTrue: setDrawerHistoryVisible, setFalse: setDrawerHistoryHidden },
  ] = useBoolean(false);
  const [popoverData, setPopoverData] = useState<CalculatorEntryHistory>(
    {} as CalculatorEntryHistory
  );
  const [isHistoryData, setIsHistoryData] = useState(false);

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <StyledButton
        onClick={() => {
          notification.close(key);
          setDrawerHidden();
          onClickUndo && onClickUndo();
        }}
      >
        <UndoOutlined />
        <span>Undo</span>
      </StyledButton>
    );
    notification.open({
      message: (
        <div className={styles.successBox}>
          <div className={styles.successText}>Success</div>
          <div className={styles.successDeleteText}>
            Offset was deleted successfully
          </div>
          {btn}
        </div>
      ),
      key,
      className: styles.successDeleteMessage,
      placement: "top",
    });
  };

  const handleDelete = () => {
    onDelete(data);
    undo && openNotification();
  };

  const handleConfirm = (offset: Offset) => {
    editOffset && editOffset(offset);
    hideEditModal();
  };

  const handleView = (history: CalculatorEntryHistory) => {
    onViewHistory && onViewHistory(history);
    setIsHistoryData(true);
    setDrawerHistoryHidden;
    setPopoverData(history);
  };

  const handleInfoDrawer = () => {
    setDrawerVisible();
    setTypeDrawer("info");
  };
  const handleNotesDrawer = () => {
    setDrawerVisible();
    setTypeDrawer("notes");
  };

  const handleCurrent = () => {
    onGoCurrent && onGoCurrent();
    setIsHistoryData(false);
  };

  const handleRestore = () => {
    onRestoreHistory && onRestoreHistory(popoverData);
    setIsHistoryData(false);
  };

  const menu = (
    <TableCommonMenu>
      {onRestore ? (
        <Menu.Item key={"restore"}>
          <Button onClick={() => onRestore(data)}>
            <RedoOutlined rotate={-90} />
            Restore
          </Button>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item key={"notes"}>
            <Button onClick={handleNotesDrawer}>
              <UnorderedListOutlined />
              View notes
            </Button>
          </Menu.Item>
          <Menu.Item key={"edit"}>
            <Button onClick={showEditModal}>
              <EditOutlined />
              Edit
            </Button>
            <AddOffset
              data={data}
              visible={isEditModalVisible}
              modalName="Edit offset"
              onCancel={hideEditModal}
              onConfirm={handleConfirm}
            />
          </Menu.Item>
        </>
      )}
      {!!isHistory && (
        <Menu.Item key={"history"}>
          <Button onClick={setDrawerHistoryVisible}>
            <UndoOutlined />
            History
          </Button>
          <CalculatorEntryHistoryDrawer
            data={data}
            isVisible={drawerHistoryVisible}
            hideDrawer={setDrawerHistoryHidden}
            onView={handleView}
          />
        </Menu.Item>
      )}
      <Menu.Item key={"delete"}>
        <Button onClick={handleDelete}>
          <DeleteOutlined />
          Delete
        </Button>
      </Menu.Item>
    </TableCommonMenu>
  );

  return (
    <div className={styles.btnIconsWrap}>
      <Button className={styles.btnIcon} onClick={handleInfoDrawer}>
        <EyeOutlined />
      </Button>
      <InfoAndNotesDrawer
        calculationEntry={data}
        typeDrawer={typeDrawer}
        visible={drawerVisible}
        onClose={setDrawerHidden}
        calculationEntryType={CalculationEntryType.OFFSETS}
      />
      {isHistoryData ? (
        <CalculatorEntryHistoryButtons
          popoverData={popoverData}
          onRestore={handleRestore}
          onCurrent={handleCurrent}
        />
      ) : (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button className={styles.btnIcon}>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};
export default TableOffsetButtons;
