import React from "react";
import { CommonModal, StyledButton } from "../../../shared/commonStyles";
import { Typography } from "antd";
import { useNavigate } from "react-router";

interface DataSheetsTypeModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const DataSheetsTypeModal = ({
  isVisible,
  onClose,
}: DataSheetsTypeModalProps) => {
  const navigate = useNavigate();
  return (
    <CommonModal
      width={500}
      title={"Create Data Sheet"}
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      centered
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3}>
          What data would you like to collect?
        </Typography.Title>
        <StyledButton
          type="default"
          style={{
            margin: 10,
            width: "100%",
            height: "75px",
            fontSize: "20px",
          }}
          onClick={() => {
            onClose();
            navigate("/data/data-sheets/create-regular-data-sheet");
          }}
        >
          Regular Data Sheet
        </StyledButton>
        <StyledButton
          type="default"
          style={{
            margin: 10,
            width: "100%",
            height: "75px",
            fontSize: "20px",
          }}
          onClick={() => {
            onClose();
            navigate("/data/data-sheets/create-standards-data-sheet");
          }}
        >
          Standards Data Sheet
        </StyledButton>
        <StyledButton
          type="default"
          style={{
            margin: 10,
            width: "100%",
            height: "75px",
            fontSize: "20px",
          }}
          onClick={() => {
            onClose();
            navigate("/data/data-sheets/create-reference-data-sheet");
          }}
        >
          Reference Data Sheet
        </StyledButton>
      </div>
    </CommonModal>
  );
};
