import React, { useState, useEffect } from "react";
import { Popover, Space, message, Row } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { API } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import CreateGroupModal from "./Components/CreateGroupModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import NewAssignUsersModal from "./Components/NewAssignUsersModal";
import SearchableColumn from "../../../components/SearchableColumn";
import {
  TabContentWrap,
  PageWrap,
  CommonTable,
  StyledButton,
  StyledTag,
} from "../../../shared/commonStyles";
import {
  getDataSheets,
  makeQueryMetadata,
  listMetaDataRecords,
} from "./../../../services/dataSheet";
import { listPermissions } from "./../../../services/permissions";
import { getUserGroups, updateUserGroups } from "../../../services/users";
import { YearMonthDay24HourFormat } from "../../../shared/constants";
import moment from "moment";

const UserGroupsPage = () => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);
  const [loadingGroupsList, setLoadingGroupsList] = useState(true);
  const [groupsList, setGroupsList] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loaderDeleteGroup, setLoaderDeleteGroup] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [groupNameFilter, setGroupNameFilter] = useState([]);
  const [firstLastNameFilter, setFirstLastNameFilter] = useState([]);
  const [status, setStatus] = useState();
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [loadingRefDataSheetsData, setLoadingRefDataSheetsData] =
    useState(true);
  const [refDataSheetsData, setRefDataSheetsData] = useState([]);

  useEffect(() => {
    getRefDataSheets();
  }, []);

  const getRefDataSheets = async () => {
    setLoadingRefDataSheetsData(true);
    try {
      const payload = {
        filter: { is_reference_data: true, archive: { $ne: true } },
      };
      const result = await getDataSheets(payload);
      const treeData = [];
      await Promise.all(
        result.map(async (element) => {
          const identifierField = element.sheet_schema.find(
            (v) => v.isIdentifier
          )?.entity_name;
          if (identifierField) {
            const payload = makeQueryMetadata({
              sheetId: element?._id?.$oid,
              pagination: { current: 1, pageSize: 1000 },
              filters: [
                {
                  column: identifierField,
                  op: "isNotEmpty",
                  column_value: "",
                },
              ],
            });
            const metadataRecords = await listMetaDataRecords(payload);

            const entities = metadataRecords.data
              ?.filter((v) => typeof v[identifierField] !== "object")
              ?.map((item, index) => ({
                label: item[item.sheet_name][identifierField],
                title: item[item.sheet_name][identifierField],
                parent: item.sheet_id,
                value: item[item.sheet_name].id,
                key: item[item.sheet_name].id,
              }));
            entities?.length &&
              treeData.push({
                label: element.sheet_name,
                title: element.sheet_name,
                value: element?._id?.$oid,
                key: element?._id?.$oid,
                children: entities,
              });
          }
        })
      );
      setRefDataSheetsData(treeData);
      await getUserGroupsList(treeData);
    } catch (error) {
      console.log("error while getting reference datasheets!", error);
    } finally {
      setLoadingRefDataSheetsData(false);
    }
  };
  const handleChange = (pagination, filters, sorter) => {
    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const getFirstAndLastNameByUsername = async (username) => {
    try {
      const parameters = {
        username,
      };
      const parms = JSON.stringify(parameters);
      const response = await API.graphql({
        query: queries["manageUserAccess"],
        variables: { request_type: "get-real-username", parameters: parms },
      });
      const manageUserAccess = response["data"]["manageUserAccess"];
      return manageUserAccess;
    } catch (error) {
      console.log("error while get user first and last name", error);
      return "";
    }
  };

  const onDeleteGroupBtnClick = (record, status) => {
    setStatus(status);
    setRowData(record);
    setShowConfirmModal(true);
  };

  const onAssignUsersHandler = (record) => {
    setRowData(record);
    setShowAssignUsersModal(true);
  };

  /* new api data */
  const getUserGroupsList = async (referenceSheetsData) => {
    try {
      setLoadingGroupsList(true);
      const sheetsData = referenceSheetsData || refDataSheetsData;

      const listGroupNames = await getUserGroups();

      const modifiedData = await Promise.all(
        listGroupNames.map(async (item) => {
          const firstLastName = await getFirstAndLastNameByUsername(
            item.username
          );

          const payload = {
            permissionType: "REFERENCE_DATA",
            groupId: item._id.$oid,
          };
          const permissionsData = await listPermissions(payload);

          const metaDataRecords = sheetsData.reduce((result, obj) => {
            const matchingChildren = obj.children.filter((ite) => {
              const foundPermission = permissionsData
                .flatMap((v) => v.assignedItems)
                .includes(ite.key);

              return foundPermission;
            });

            return result.concat(matchingChildren);
          }, []);
          return {
            ...item,
            first_last_name: firstLastName,
            metaDataRecords,
            permissionsData,
          };
        })
      );
      setGroupsList(modifiedData);

      const filterGroupName = modifiedData.reduce((arr, val) => {
        const { name } = val;
        if (!arr.includes(name)) {
          arr.push(name);
        }
        return arr;
      }, []);
      setGroupNameFilter(filterGroupName);

      const filterFirstLastName = modifiedData.reduce((arr, val) => {
        const { first_last_name } = val;
        if (!arr.includes(first_last_name)) {
          arr.push(first_last_name);
        }
        return arr;
      }, []);
      setFirstLastNameFilter(filterFirstLastName);
    } catch (error) {
      console.log("Error while fetching User Group!");
    } finally {
      setLoadingGroupsList(false);
    }
  };
  const columns = [
    {
      title: "Group Name",
      dataIndex: "name",
      width: "20%",
      render: (record) => {
        return (
          <StyledTag key={1} style={{ fontSize: 14 }}>
            {record}
          </StyledTag>
        );
      },
      filters: groupNameFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) => record?.name?.indexOf(value) === 0,
      filteredValue: tableFilters.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
    },
    SearchableColumn({
      title: "Description",
      dataIndex: "description",
      width: "20%",
      searchPlaceHolder: "Search Description",
      filteredValue: tableFilters.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
    }),
    /*{
      title: "Username",
      dataIndex: "username",
      width: "0%",
    },
    {
      title: "Created By",
      dataIndex: "first_last_name",
      width: "0%",
      filters: firstLastNameFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) =>
        record?.first_last_name?.indexOf(value) === 0,
      filteredValue: tableFilters.filteredInfo?.first_last_name
        ? tableFilters.filteredInfo.first_last_name
        : null,
    },*/
    {
      title: "Entity",
      width: "25%",
      render: (record) => {
        return (
          <>
            {record?.metaDataRecords?.length > 1 ? (
              <>
                <Popover
                  content={
                    <ul style={{ padding: "5px 10px", margin: "0px 15px", textAlign: "left" }}>
                      {record?.metaDataRecords?.map((v) => {
                        return <li style={{ textAlign: "left" }}>{v?.label}</li>;
                      })}
                    </ul>
                  }
                  autoAdjustOverflow
                  overlayClassName="popoverContent"
                >
                  {" "}
                  <Row justify="start" style={{ alignItems: "baseline" }}>
                    {record?.metaDataRecords?.length}
                    <p className="color-grey" style={{ marginLeft: "7px" }}>
                      (hover to view list)
                    </p>
                  </Row>
                </Popover>{" "}
              </>
            ) : (
              <> {record?.metaDataRecords?.[0]?.label}</>
            )}
          </>
        );
      },
    },
    /*{
      title: "Creator Group",
      dataIndex: "group_id",
      width: "20%",
      render: (record) => {
        return (
          <StyledTag key={1} style={{ fontSize: 14 }}>
            {record}
          </StyledTag>
        );
      },
    },*/
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (record) => {
        return record === "ACTIVE" ? (
          <StyledTag>ACTIVE</StyledTag>
        ) : (
          <StyledTag>INACTIVE</StyledTag>
        );
      },
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "6%",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton
            type="custom"
            onClick={() => onAssignUsersHandler(record)}
            disabled={record?.status == "INACTIVE"}
          >
            Assign Users
          </StyledButton>
          <StyledButton
            type="custom"
            onClick={() => onEditGroupHandler(record)}
            disabled={record?.status == "INACTIVE"}
          >
            <EditOutlined />
          </StyledButton>
          {record.status == "ACTIVE" ? (
            <StyledButton
              type="custom"
              onClick={() => onDeleteGroupBtnClick(record, "INACTIVE")}
            >
              <DeleteOutlined />
            </StyledButton>
          ) : (
            <StyledButton
              type="custom"
              onClick={() => onDeleteGroupBtnClick(record, "ACTIVE")}
            >
              <RollbackOutlined />
            </StyledButton>
          )}
        </Space>
      ),
    },
  ];

  const onConfirmDeleteGroup = async () => {
    if (!rowData) return;
    try {
      setLoaderDeleteGroup(true);
      const msg = status == "ACTIVE" ? "Restored" : "Archived";
      rowData.status = status;
      rowData.modified = moment().format(YearMonthDay24HourFormat);
      delete rowData.first_last_name;
      delete rowData.permissionsData;
      delete rowData.metaDataRecords;
      const deletedGroupNames = await updateUserGroups(rowData);

      if (deletedGroupNames) {
        message.success(`Group ${msg} successfully`);
        setShowConfirmModal(false);
        await getUserGroupsList();
      }
    } catch (error) {
      console.log("error while deleting group.", error);
      message.error("error while deleting group.");
    } finally {
      setLoaderDeleteGroup(false);
    }
  };

  const onEditGroupHandler = (record) => {
    setRowData(record);
    setShowCreateGroupModal(true);
    // setShowUpdateGroupModal(true);
  };

  const onCreateGroupHandler = () => {
    setRowData(null);
    setShowCreateGroupModal(true);
    // setShowUpdateGroupModal(true);
  };

  return (
    <>
      <PageWrap>
        <TabContentWrap>
          {showAssignUsersModal && (
            <NewAssignUsersModal
              visible
              onClose={() => setShowAssignUsersModal(false)}
              rowData={rowData}
              setShowAssignUsersModal={setShowAssignUsersModal}
            />
          )}
          {showConfirmModal && (
            <ConfirmModal
              title="Confirmation"
              description={`Are you sure you want to ${
                status == "ACTIVE" ? "restore" : "delete"
              } this group?`}
              visible={showConfirmModal}
              onClose={() => setShowConfirmModal(false)}
              onConfirm={onConfirmDeleteGroup}
              ConfirmLoader={loaderDeleteGroup}
              okText={`${status == "ACTIVE" ? "Restore" : "Delete"}`}
            />
          )}
          {showCreateGroupModal && (
            <CreateGroupModal
              visible
              onClose={() => setShowCreateGroupModal(false)}
              rowData={rowData}
              refresh={getUserGroupsList}
              refDataSheets={refDataSheetsData}
              loadingRefDataSheetsData={loadingRefDataSheetsData}
            />
          )}

          <div className="align-items-right-wrapper">
            <Space direction="horizontal" size="middle">
              <StyledButton
                // onClick={() => setShowCreateGroupModal(true)}
                onClick={() => onCreateGroupHandler()}
                type="custom"
              >
                <PlusOutlined /> <span>Create Group</span>
              </StyledButton>
            </Space>
          </div>
          <div>
            <CommonTable
              loading={loadingGroupsList}
              rowKey={(data) => {
                return data?._id?.$oid;
              }}
              dataSource={groupsList}
              columns={columns}
              rowClassName={(record, index) => {
                if (record?.status == "INACTIVE") {
                  return "tableRowColor";
                }
                return "";
              }}
              scroll={{ x: true }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
              }}
              onChange={handleChange}
            />
          </div>
        </TabContentWrap>
      </PageWrap>
    </>
  );
};

export default UserGroupsPage;
