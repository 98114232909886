import React, { useState, useEffect, createContext } from "react";
import { message } from "antd";
import { Auth } from "aws-amplify";
import { fetchProfileImage } from "../shared/commonFunctions";

// create context
export const AuthContext = createContext();

const signOut = async () => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

// create provider
export const AuthProvider = ({ children }) => {
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [auth, setAuth] = useState({ auth: null, roles: null, groups: null });
  const [avatarUrl, setAvatarUrl] = useState(null);

  const getProfileImage = async (username) => {
    try {
      const profile = await fetchProfileImage(username);
      setAvatarUrl(profile);
    } catch (error) {
      console.log("Error while load header logo", error);
    }
  };

  const loadData = async () => {
    try {
      setLoadingAuth(true);
      const data = await Auth.currentSession();
      const roles = data["accessToken"]["payload"]["cognito:groups"]?.filter(
        (element) => element.includes("role:")
      );
      const groups = data["accessToken"]["payload"]["cognito:groups"]?.filter(
        (element) => element.includes("org:")
      );

      setAuth({
        auth: data,
        roles,
        groups,
      });

      getProfileImage(data["accessToken"]["payload"]["username"]);
    } catch (error) {
      console.log("error while fetching auth data in auth context api", error);
      message.error("error while fetching auth data in auth context api");
      await signOut();
      // I believe we need to logut if there is an error while getting auth data.
    } finally {
      setLoadingAuth(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const data = {
    loadingAuth,
    auth,
    avatarUrl,
    getProfileImage,
  };
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
