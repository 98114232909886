import { Button } from "antd";
import React from "react";
import styles from "../../WhatIfOnboarding.module.scss";

interface ScienceBasedTargetsInfoProps {
  onNext: () => void;
  onCustomStep: () => void;
}

export const ApprovedScienceBasedTargets = ({
  onNext,
  onCustomStep,
}: ScienceBasedTargetsInfoProps) => {
  return (
    <div className={styles.content}>
      <span className={styles.title}>
        Do you have approved science based targets?
      </span>
      <div className={styles.buttons}>
        <Button className={styles.buttonGhost} onClick={onNext}>
          Yes
        </Button>
        <Button className={styles.buttonGhost} onClick={onCustomStep}>
          No
        </Button>
      </div>
    </div>
  );
};
