import React, { useEffect, useState } from "react";
import { CommonModal, FormWrapper } from "../../../shared/commonStyles";
import { Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface CommentFileProps {
  visible: boolean;
  file?: ImportedFile;
  onCancel: () => void;
  onConfirm: (comment: string) => void;
}

export const CommentFile = ({
  visible,
  file,
  onCancel,
  onConfirm,
}: CommentFileProps) => {
  const [comment, setComment] = useState("");
  useEffect(() => {
    visible && setComment(file?.comment || "");
  }, [visible]);

  return (
    <CommonModal
      visible={visible}
      okText={"Add comment"}
      onOk={() => onConfirm(comment)}
      onCancel={() => onCancel()}
      title={
        <Row>
          <span className="color-white font-16">Add your comment</span>
          <span className="color-grey font-14">(optional)</span>
        </Row>
      }
    >
      <FormWrapper>
        <TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </FormWrapper>
    </CommonModal>
  );
};
