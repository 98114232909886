import React from "react";
import {
  FormWrapper,
  BorderedSpecialTable,
} from "../../../shared/commonStyles";
import { Row, Col, Typography, Space, Spin, Select } from "antd";

interface FieldMappingIdentifierProps {
  systemValues?: (string | number)[];
  userValues: { [key: string]: (string | number)[] };
  dataMapping?: any;
  identifierColumn?: string;
  setDataMapping: (data: any) => void;
  dataSheet: DataSheet;
}
export const FieldMappingIdentifier = ({
  systemValues,
  userValues,
  dataMapping,
  identifierColumn,
  setDataMapping,
  dataSheet,
}: FieldMappingIdentifierProps) => {
  const { Title } = Typography;
  const generateTable = () => {
    const data = Object.entries(userValues)
      .map(([key, values]) => {
        const unitsList = dataSheet.sheet_schema
          ?.find((item) => item.entity_name === key.replace("_unit_id", ""))
          ?.convertable_units?.map((v: any) => ({
            label: v.name,
            value: v.unit,
          }));
        return values.map((v) => ({
          uploadedData: v,
          field: key,
          systemList:
            key === identifierColumn
              ? systemValues?.map((v) => ({ label: v, value: v }))
              : unitsList,
        }));
      })
      .flat();
    const columns = [
      {
        dataIndex: "uploadedData",
        key: "uploadedData",
        width: "50%",
      },
      {
        dataIndex: "systemList",
        key: "systemList",
        width: "50%",
        render: (item: any, data: any) => {
          return (
            <Select
              value={dataMapping?.[data.field]?.[data.uploadedData]}
              style={{ width: "100%" }}
              onChange={(value) =>
                setDataMapping((prev: any) => ({
                  ...prev,
                  [data.field]: {
                    ...prev?.[data.field],
                    [data.uploadedData]: value,
                  },
                }))
              }
            >
              {item?.map((item: any, index: number) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
    ];

    return (
      <BorderedSpecialTable
        title={() => {
          return (
            <>
              <Row justify="space-between">
                <Col span={12}>Uploaded value</Col>
                <Col span={12}>System value</Col>
              </Row>
            </>
          );
        }}
        dataSource={data}
        showHeader={false}
        columns={columns}
      />
    );
  };
  return (
    <FormWrapper style={{ marginTop: "20px" }}>
      <Title level={3} style={{ fontWeight: "600" }}>
        Some identifier and/or unit fields were not mapped automatically
      </Title>
      <p className="color-grey font-16">
        Please map them manually by selecting the corresponding field
      </p>
      <Spin spinning={false}>
        <Space
          size="middle"
          direction="vertical"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          {generateTable()}
        </Space>
      </Spin>
    </FormWrapper>
  );
};

export default FieldMappingIdentifier;
