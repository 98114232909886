import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import DocumentAddModal from "../../../pages/documentsPage/Components/DocumentAddModal";
import { StyledButton } from "../../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import {getDocumentType,getDocumentTypeList} from "../../../services/documentType"
export const DocumentAddBtn = () => {
const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
const[loadingLoadDocumentType,setLoadingLoadDocumentType]=useState();
const [dataSource, setDataSource] = useState([]);
const [documents, setDocuments] = useState(null);
const { checkPermissions } = useCheckRolesPermissions();

useEffect(() => {
  loadDocumentType();
  DocumentTypeList()
}, []);
  const loadDocumentType = async () => {
    setLoadingLoadDocumentType(true);
    try {
      const dataDocumentResponse = await getDocumentType();
      setDataSource(dataDocumentResponse);

    } catch (error) {
      console.log("found some error");
    } finally {
      setLoadingLoadDocumentType(false);
    }
  };


  const DocumentTypeList = async () => {
    setLoadingLoadDocumentType(true);
    try {
      const dataDocumentResponseList = await getDocumentTypeList();
      setDocuments(dataDocumentResponseList);
    } catch (error) {
      console.log("found some error");
      setLoadingLoadDocumentType(false);
    } finally {
    }
  };

  return (
    <>

      {showAddDocumentModal && (
         <DocumentAddModal
         visible={showAddDocumentModal}
         onClose={() => setShowAddDocumentModal(false)}
         modalTitle={"Add Document"}
         documentTypes={dataSource}
         setDocuments={documents}
         loadingLoadDocumentType ={loadingLoadDocumentType}
       />
      )}
      {checkPermissions("manage-documents") && (
        <StyledButton type="primary" onClick={() => setShowAddDocumentModal(true)}>
          <PlusCircleOutlined /> <span>Add Document</span>
        </StyledButton>
      )}
    </>
  );
};
