import React from "react";
import { InputNumber, Checkbox, Row } from "antd";

interface ClickedCheckBoxGroupProps {
  data: {
    value: string;
    label: string;
    checked: boolean;
    inputValue: number;
  }[];
  onChange: (
    data: {
      value: string;
      label: string;
      checked: boolean;
      inputValue: number;
    }[]
  ) => void;
}

export const ClickedCheckBoxGroup = ({
  data,
  onChange,
}: ClickedCheckBoxGroupProps) => {
  const onCheckChange = (item: any, checked: boolean, index: number) => {
    const newItem = { ...item, checked };
    const newData = data.map((item, i) => (i === index ? newItem : item));
    onChange(newData);
  };

  const handleChangeValue = (newItem: any, index: number) => {
    const newData = data.map((item, i) => (i === index ? newItem : item));
    onChange(newData);
  };

  return (
    <>
      {data?.map((item, index) => (
        <Row justify="space-between" key={item.value}>
          <Checkbox
            checked={item.checked}
            value={item.value}
            onChange={(e) => {
              onCheckChange(item, e.target.checked, index);
            }}
          >
            {item.label}
          </Checkbox>
          {item.checked && (
            <InputNumber
              placeholder="Type in a number"
              style={{ width: "40%", marginTop: 10 }}
              value={item.inputValue}
              onChange={(value) =>
                handleChangeValue({ ...item, inputValue: value || 0 }, index)
              }
            />
          )}
        </Row>
      ))}
    </>
  );
};
