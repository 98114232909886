import React, { useState, useEffect } from "react";
import { Col, message, Row, Input, Typography, Select, Spin } from "antd";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import NewsDataTable from "./Components/NewsDataTable";
import {
  FiltersContainer,
  FiltersWrap,
  PageWrap,
  FilterButtonsWrap,
  StyledButton,
  FormWrapper,
  CommonSelect,
} from "../../shared/commonStyles";

const { Text } = Typography;
const { Option } = Select;

const NewsPage = () => {
  const [newsList, setNewsList] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState({
    title: "",
    category: "",
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const getNewsList = async (payload) => {
    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_news_content",
        request_payload: JSON.stringify(payload),
      },
    });
    const newsData = JSON.parse(response["data"]["datalake"]);
    return newsData;
  };

  const generatePayload = (params) => {
    const { filter, pagination } = params;
    const query = {};
    if (filter?.category) {
      query.category = { $regex: filter.category, $options: "i" };
    }
    if (filter?.title) {
      query.title = { $regex: filter.title, $options: "i" };
    }
    const sortingResult = {};
    sortingResult[`${"published_date"}`] = -1;

    const payload = {
      filter: query,
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      sort: sortingResult,
    };
    return payload;
  };

  const loadNewsContent = async (payload) => {
    setLoadingNews(true);
    try {
      const requestPayload = generatePayload(payload);
      const { pagination } = payload;
      const dataList = await getNewsList(requestPayload);
      setPagination({ ...pagination, total: dataList.filteredCount });
      if (dataList.data?.length > 0) {
        setNewsList(dataList.data);
        return dataList;
      }
    } catch (e) {
      console.log("Error while loading dashboard news list", e);
      message.error("Error while loading dashboard news list", e);
      return null;
    } finally {
      setLoadingNews(false);
    }
  };

  const loadCategories = async () => {
    setLoadingCategories(true);
    const payload = { filter: {} };
    const newsListData = await getNewsList(payload);
    const filterData = newsListData.data?.reduce((arr, val) => {
      const { category } = val;
      if (!arr.includes(category)) {
        arr.push(category);
      }
      return arr;
    }, []);
    setCategories(filterData);
    setLoadingCategories(false);
  };
  useEffect(() => {
    loadNewsContent({ pagination, filter: {} });
    loadCategories();
  }, []);

  const onClickApplyFilters = () => {
    const payload = {
      filter: filter,
      pagination: { ...pagination, current: 1 },
    };
    loadNewsContent(payload);
  };

  const onClickClearFilters = () => {
    const payload = { filter: {}, pagination: { ...pagination, current: 1 } };
    loadNewsContent(payload);
    setFilter({
      title: "",
      category: "",
    });
  };

  const onAdvanceFilterChange = (filterName, value) => {
    setFilter((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleChangePagination = (pagination) => {
    const payload = { filter: filter, pagination: pagination };
    loadNewsContent(payload);
  };

  return (
    <PageWrap>
      <FiltersContainer>
        <FormWrapper>
          <Row>
            <Col xs={24} sm={24} md={20} lg={20}>
              <FiltersWrap>
                <div className="margin-5" style={{ width: 250 }}>
                  <Text className="color-white">CATEGORY</Text>
                  <CommonSelect
                    allowClear
                    showArrow
                    style={{ width: 250 }}
                    placeholder="Category"
                    value={filter?.category}
                    onChange={(data) => onAdvanceFilterChange("category", data)}
                  >
                    {!categories.length ? (
                      <Option
                        value={"spin"}
                        style={{ textAlign: "center" }}
                        disabled
                      >
                        <Spin spinning={loadingCategories} />
                      </Option>
                    ) : (
                      <>
                        {categories?.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </>
                    )}
                  </CommonSelect>
                </div>
                <div className="margin-5" style={{ width: 250 }}>
                  <Text className="color-white">TITLE</Text>
                  <Input
                    placeholder="Search news title..."
                    value={filter?.title}
                    onChange={(e) =>
                      onAdvanceFilterChange("title", e.target.value)
                    }
                  />
                </div>
              </FiltersWrap>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4}>
              <FilterButtonsWrap>
                <StyledButton
                  className="margin-5"
                  type="custom"
                  onClick={() => onClickApplyFilters()}
                >
                  Apply
                </StyledButton>
                <StyledButton
                  className="margin-5"
                  type="custom"
                  onClick={() => onClickClearFilters()}
                >
                  Clear
                </StyledButton>
              </FilterButtonsWrap>
            </Col>
          </Row>
        </FormWrapper>
      </FiltersContainer>
      <NewsDataTable
        loadingNewslist={loadingNews}
        newsListData={newsList}
        pagination={pagination}
        onChangePagination={handleChangePagination}
      />
    </PageWrap>
  );
};

export default NewsPage;
