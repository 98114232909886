import { Button, Input, Layout, Spin } from "antd";
import { PageWrap, StyledButton } from "../../../../shared/commonStyles";
import { formatTimestamp, renderHTML } from "../../../../shared/utils";

import { BulletPoint } from "./BulletPoint";
import { BulletPointPatch } from "./BulletPointPatch";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Typography } from "antd";
// @ts-ignore
import styled from "styled-components";

const Text = Typography;

const SearchInput = styled(Input)`
  height: 40px;
  background-color: #625d75;
  border-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
    border-right-width: 2px;
    z-index: 1;
  }

  .ant-input {
    background-color: transparent;
    margin-left: 5px;
    font-size: 16px;
    color: #b1afbc;
  }

  &.customInput {
    background-color: #625d75;
    font-size: 16px;
    border-color: transparent;

    &::placeholder {
      background-color: #625d75;
      font-size: 16px;
      border-color: transparent;
    }
  }
`;

const layoutStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  marginTop: "10px",
  marginBottom: "10px",
  marginLeft: "80px",
  marginRight: "80px",
  minWidth: "768px",
};

const headerStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  height: "70px",
  justifyContent: "space-between",
  justifyItems: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const contentStyle: React.CSSProperties = {
  display: "flex",
};

const innerLeftContentStyle: React.CSSProperties = {
  width: "25%",
  flexDirection: "column",
  backgroundColor: "#3A3C5A",
  overflowY: "auto",
  maxHeight: "100vh",
};

const innerRightContentStyle: React.CSSProperties = {
  width: "75%",
  flexDirection: "column",
  backgroundColor: "#4E4766",
  paddingLeft: "100px",
  paddingRight: "100px",
  paddingTop: "20px",
  paddingBottom: "20px",
  overflowY: "auto",
  maxHeight: "100vh",
};

const tableStyle: React.CSSProperties = {
  margin: "25px",
  display: "flex",
  flexDirection: "column",
};

const title: React.CSSProperties = {
  color: "white",
  fontSize: "20px",
  marginBottom: "10px",
};

const titleTextStyle: React.CSSProperties = {
  color: "white",
  fontSize: "24px",
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  fontWeight: "700",
};

const htmlContentStyle: React.CSSProperties = {
  flex: 1,
  minHeight: "100%",
  overflowY: "auto",
  fontSize: "16px",
  color: "white",
};

const commonStyleForLoader: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

const commonStyleForError: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  color: "white",
  fontSize: "16px",
};

const dateStyle: React.CSSProperties = {
  fontSize: "16px",
  color: "#B1AFBC",
  marginLeft: "10px",
  marginBottom: "1px",
  fontWeight: "500",
};

const searchInputContainerStyle: React.CSSProperties = {
  flex: 1,
  marginRight: "15px",
};

const buttonStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "40px",
  borderRadius: "2px",
  backgroundColor: "#A68DFB",
  color: "white",
  border: "none",
  outline: "none",
};

const getTableOfContentsStyle = (isLastItem: boolean): React.CSSProperties => {
  return {
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginBottom: "10px",
    cursor: "pointer",
  };
};

const tableOfContentItemStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "30px",
};

const point: React.CSSProperties = {
  color: "white",
  fontSize: "20px",
};

const versionSelected: React.CSSProperties = {
  color: "white",
  fontSize: "16px",
  marginLeft: "10px",
};

const version: React.CSSProperties = {
  color: "#B1AFBC",
  fontSize: "16px",
  marginLeft: "10px",
};

const patchSelected: React.CSSProperties = {
  color: "white",
  fontSize: "16px",
  marginLeft: "10px",
};

const patch: React.CSSProperties = {
  color: "#B1AFBC",
  fontSize: "16px",
  marginLeft: "10px",
};
export type PatchVersion = {
  patchVersion: string;
  isSelected: boolean;
  date?: Date;
  htmlString?: string;
};

export type MinorVersion = {
  minorVersion: string;
  isSelected: boolean;
  patch?: PatchVersion[];
};

export type Version = {
  id: string;
  majorVersion: string;
  minor: MinorVersion[];
};

export type ReleaseNotesPageProps = {
  isLoadingContent: boolean;
  isLoadingTable: boolean;
  hasErrorForContent: boolean;
  hasErrorForTable: boolean;
  releaseNotes: Version[];
  selectedVersion: string;
  searchString?: string;
  date?: string;
  htmlContent?: string;
  onSearchValueChange(value: string): void;
  onSearchClick(): void;
  onVersionClick(version: string): void;
};

const ReleaseNotes: React.FC<ReleaseNotesPageProps> = ({
  isLoadingContent,
  isLoadingTable,
  hasErrorForContent,
  selectedVersion,
  date,
  htmlContent,
  hasErrorForTable,
  releaseNotes,
  searchString,
  onSearchValueChange,
  onSearchClick,
  onVersionClick,
}) => {
  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      onSearchClick();
    }
  };

  return (
    <PageWrap>
      <Layout style={layoutStyle}>
        <div style={headerStyle}>
          <div style={searchInputContainerStyle}>
            <SearchInput
              className="customInput"
              placeholder="Search for the recent updates"
              value={searchString}
              prefix={<SearchOutlined style={{ color: "white" }} />}
              onKeyUp={handleKeyUp}
              onChange={(e: any) => onSearchValueChange(e.target.value)}
            />
          </div>
          <StyledButton type="primary" onClick={onSearchClick}>
            Search
          </StyledButton>
        </div>
        <Content style={contentStyle}>
          <div style={innerLeftContentStyle}>
            {isLoadingTable ? (
              <div style={commonStyleForLoader}>
                <Spin />
              </div>
            ) : hasErrorForTable || releaseNotes.length === 0 ? (
              <div style={commonStyleForError}>
                {hasErrorForTable && releaseNotes.length === 0 ? (
                  "Unable to fetch table of contents"
                ) : (
                  <>
                    {hasErrorForTable
                      ? "Unable to fetch table of contents"
                      : ""}
                    {releaseNotes.length === 0 ? "No items found" : ""}
                  </>
                )}
              </div>
            ) : (
              <div style={tableStyle}>
                <Text style={title}>Table of Contents</Text>
                {releaseNotes.map((item) => (
                  <div key={item.id}>
                    <div style={tableOfContentItemStyle}>
                      <Text style={point}>{`${item.majorVersion}`}</Text>
                    </div>
                    {item.minor?.map((minorItem, index) => (
                      <div key={minorItem.minorVersion}>
                        <div style={tableOfContentItemStyle}>
                          <BulletPoint
                            isSelected={minorItem.isSelected}
                            isLastItem={index === item.minor.length - 1}
                          />
                          <Text
                            style={
                              minorItem.isSelected ? versionSelected : version
                            }
                          >{`${minorItem.minorVersion}`}</Text>
                        </div>
                        {minorItem.patch?.map((patchItem, index) => (
                          <div
                            onClick={() =>
                              onVersionClick(patchItem.patchVersion)
                            }
                            key={patchItem.patchVersion}
                            style={getTableOfContentsStyle(
                              index === minorItem.patch!.length - 1
                            )}
                          >
                            <BulletPointPatch
                              isSelected={patchItem.isSelected}
                              isLastItem={index === minorItem.patch!.length - 1}
                            />
                            <Text
                              style={
                                patchItem.isSelected ? patchSelected : patch
                              }
                            >{`${patchItem.patchVersion}`}</Text>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={innerRightContentStyle}>
            {isLoadingContent || isLoadingTable ? (
              <div style={commonStyleForLoader}>
                <Spin />
              </div>
            ) : hasErrorForContent ||
              hasErrorForTable ||
              !htmlContent ||
              !date ? (
              <div style={commonStyleForError}>
                {!htmlContent || !date ? "No items found" : ""}{" "}
                {hasErrorForContent ? "Unable to fetch table of contents" : ""}
              </div>
            ) : (
              // TODO: handle null checks properly
              <>
                <div style={titleTextStyle}>
                  {`Hydrus ${selectedVersion}`}
                  <span style={dateStyle}>{`(${formatTimestamp(date!)})`}</span>
                </div>
                <div
                  style={htmlContentStyle}
                  dangerouslySetInnerHTML={{
                    __html: renderHTML(htmlContent!),
                  }}
                />
              </>
            )}
          </div>
        </Content>
      </Layout>
    </PageWrap>
  );
};

export default ReleaseNotes;
