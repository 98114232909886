import React, { useEffect, useState } from "react";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Drawer, Row, Spin } from "antd";
import { fetchProfileImage } from "../../../../../shared/commonFunctions";
import {
  StyledButton,
  StyledDivider,
} from "../../../../../shared/commonStyles";
import logo from "../../../../../components/hydrus_app_logo_new.png";
import { AssignOwnerModal } from "../AssignOwnerModal";
import styles from "./RolesDrawer.module.scss";

interface RolesDrawerProps {
  visible: boolean;
  data: string;
  ownerUsers: string[];
  ownerGroups: string[];
  onClose: () => void;
  onSubmit: (users: string[], groups: string[]) => void;
  usersList: User[];
  groupsList: UserGroup[];
}

interface UserData {
  userIcon: string;
  userId: string;
  userName: string;
}

export const RolesDrawer = ({
  visible,
  data,
  ownerUsers,
  ownerGroups,
  onClose,
  onSubmit,
  usersList,
  groupsList,
}: RolesDrawerProps) => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [isAssignOwnerModalVisible, setIsAssignOwnerModalVisible] =
    useState(false);
  const getUserInfo = async (userId: string) => {
    const userName = usersList?.find((user) => user.key === userId)?.name || "";
    const profileImage =
      userId === "SYSTEM" ? logo : await fetchProfileImage(userId);

    return { userId, userName, userIcon: profileImage };
  };

  useEffect(() => {
    if (!!groupsList?.length) {
      const recordGroups = groupsList.filter((item) =>
        ownerGroups?.find((v) => item._id.$oid === v)
      );
      setUserGroups(recordGroups);
    }
  }, [data, groupsList]);
  useEffect(() => {
    !!usersList?.length &&
      ownerUsers?.forEach(async (v) => {
        const userData = await getUserInfo(v);
        setUsers((prev) => {
          return [...prev, userData];
        });
      });
  }, [data, usersList]);

  const handleChangeOwners = (usersData: string[], groupsData: string[]) => {
    setUsers([]);
    setUserGroups([]);
    usersData.forEach(async (v) => {
      const userData = await getUserInfo(v);
      setUsers((prev) => [...prev, userData]);
    });
    const recordGroups = groupsList.filter((item) =>
      groupsData?.find((v) => item._id.$oid === v)
    );
    setUserGroups(recordGroups);
    setIsAssignOwnerModalVisible(false);
  };

  return (
    <>
      <Drawer
        width={400}
        className={styles.drawer}
        placement="right"
        visible={visible}
        onClose={onClose}
        title="Roles & Assignments"
      >
        <Spin spinning={!users.length}>
          <div>
            <div className={styles.drawerTitle}>Roles & Assignments</div>
            <Row
              justify="space-between"
              align="middle"
              className={styles.drawerText}
            >
              <Col>Users</Col>
              <Col>
                <Button
                  className={styles.ghostButton}
                  onClick={() => setIsAssignOwnerModalVisible(true)}
                >
                  Change
                </Button>
              </Col>
            </Row>
            {users?.map((item) => (
              <Row
                key={item.userId}
                gutter={[8, 8]}
                justify="start"
                align="middle"
                className={styles.drawerItem}
              >
                <Col>
                  <Avatar
                    className={
                      (item.userId === "SYSTEM" && `${styles.systemIcon}`) || ""
                    }
                    src={item.userIcon}
                    icon={<UserOutlined style={{ color: "#fff" }} />}
                    size="large"
                  />
                </Col>
                <Col className={styles.drawerItemText}>
                  {item.userId === "SYSTEM" ? "SYSTEM" : item.userName}
                </Col>
              </Row>
            ))}
            {userGroups.length > 0 && (
              <>
                <StyledDivider />
                <Row className={styles.drawerText}>Groups</Row>
                {userGroups?.map((item) => (
                  <Row
                    key={item?._id?.$oid}
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    className={styles.drawerItem}
                  >
                    <Col>
                      <Avatar
                        icon={<TeamOutlined style={{ color: "#fff" }} />}
                        size="large"
                      />
                    </Col>
                    <Col className={styles.drawerItemText}>{item.name}</Col>
                  </Row>
                ))}
              </>
            )}
            <div className={styles.drawerFooter}>
              <Button type="text" onClick={onClose}>
                Close
              </Button>
              <StyledButton
                onClick={() =>
                  onSubmit(
                    users.map((v) => v.userId),
                    userGroups.map((v) => v?._id?.$oid)
                  )
                }
              >
                Submit
              </StyledButton>
            </div>
          </div>
        </Spin>
      </Drawer>
      <AssignOwnerModal
        visible={isAssignOwnerModalVisible}
        usersData={ownerUsers}
        groupsData={ownerGroups}
        onCancel={() => setIsAssignOwnerModalVisible(false)}
        onConfirm={handleChangeOwners}
        usersList={usersList}
        groupsList={groupsList}
      />
    </>
  );
};
