
import { Col, Row, Tabs, message } from "antd";
import React, { useState } from "react";
import { FormWrapper, StyledTabs } from "../../../shared/commonStyles";
import GeneralGoalsDetail from './generalGoalsDetails';
const { TabPane } = Tabs;
const GeneralGoals = () => {
  const [selectedTab, setSelectedTab] = useState('all_categories');
  const onTabChange = (selectedTabKey) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };

  return (
    <FormWrapper>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledTabs activeKey={selectedTab} onChange={onTabChange}>
            <TabPane tab="All Categories" key="all_categories" />
            <TabPane
              tab="Environmental"
              key="environmental"
            />
            <TabPane tab="Social" key="social" />
            <TabPane tab="Governance" key="governance" />
          </StyledTabs>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          {selectedTab === "all_categories" && (
            <GeneralGoalsDetail />
          )}
          {selectedTab === "environmental" && (
            <GeneralGoalsDetail category='environmental' />
          )}
          {selectedTab === "social" && (
            <GeneralGoalsDetail category='social' />
          )}
          {selectedTab === "governance" && (
            <GeneralGoalsDetail category='governance' />
          )}
        </Col>
      </Row>
    </FormWrapper>
  );
};
export default GeneralGoals;
