import React from "react";
import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useBoolean } from "ahooks";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import styles from "../../ReductionPlanListView.module.scss";
import { AddNewActionModal } from "./AddNewActionModal";

interface TableButtonsProps {
  data: Action;
  index: number;
  firstBtnAction: (data: Action) => void;
}

export const TargetActionButtons = ({
  data,
  index,
  firstBtnAction,
}: TableButtonsProps) => {
  const [
    editActionModal,
    { setTrue: showEditActionModal, setFalse: hideEditActionModal },
  ] = useBoolean(false);

  const handleEditAction = (data: Action) => {
    reductionPlanStore.updateTargetAction(index, data);
  };

  const handleDeleteAction = () => {
    reductionPlanStore.deleteTargetAction(index);
  };

  const handleMarkDone = () => {
    firstBtnAction({ ...data, progress: 100 });
  };

  return (
    <Space className={styles.btnIconsWrap}>
      <Button
        className={styles.btnIcon}
        onClick={handleMarkDone}
        disabled={data.progress === 100}
      >
        Mark as Done
      </Button>
      <Button onClick={showEditActionModal} className={styles.btnIcon}>
        <EditOutlined />
      </Button>
      <AddNewActionModal
        modalTitle="Edit action"
        data={data}
        isOpen={editActionModal}
        onClose={hideEditActionModal}
        onConfirm={handleEditAction}
      />
      <Popconfirm
        title="Are you sure to delete this?"
        onConfirm={handleDeleteAction}
        overlayClassName="popoverContent"
      >
        <Button className={styles.btnIcon}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </Space>
  );
};
