import React from 'react';
import { CommonModal } from "../../../../shared/commonStyles";
import {TaskDeploymentForm} from './TaskDeploymentForm'
export const UpdateTaskDeploymentModal = ({ isVisible, onClose, surveyId, rowData, onRefreshAllDeploySurveyData }) => {
  
  return (
    <>
      <CommonModal
        width={500}
        title={"Update Deployment"}        
        visible={isVisible}
        footer={null}
        onCancel={onClose}
      >
        <TaskDeploymentForm surveyId={surveyId} rowData={rowData} onRefreshAllDeploySurveyData={onRefreshAllDeploySurveyData} onCloseModal={onClose} />
      </CommonModal>
    </>
  );
};

