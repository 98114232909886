import React from "react";
import { Row, Col, Typography } from "antd";
import { CommonModal, StyledButton } from "../../../../shared/commonStyles";
import { Link } from "react-router-dom";

import { CheckCircleFilled } from "@ant-design/icons";

const { Title } = Typography;
export const ImportedDataModal = ({ visible, onClose, isSuccess }) => {
  return (
    <CommonModal
      title=""
      visible={visible}
      width={800}
      okText=""
      footer={null}
      maskClosable={false}
      centered
      closable={false}
    >
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            <CheckCircleFilled
              style={{
                fontSize: "50px",
                color: "rgb(166, 141, 251)",
                margin: "20px 0px",
              }}
            />
            <Title className="color-white" level={3}>
              {isSuccess ? "Data imported successfully" : "Data Import Pending"}
            </Title>
            <p style={{ padding: "0px 81px", fontSize: "16px" }}>
              {isSuccess ? (
                "You can review and update it under the data importer at anytime."
              ) : (
                <span>
                  {" "}
                  You can review the data import at anytime from the
                  <Link
                    style={{
                      color: "#a68dfb",
                      cursor: "pointer",
                    }}
                    to="/data/import-data"
                  >
                    {" "}
                    Import Data page
                  </Link>
                  .
                </span>
              )}
            </p>
            <Row justify="center" style={{ marginBottom: "20px" }}>
              <StyledButton
                className="margin-5"
                type="custom"
                onClick={() => onClose()}
                bgcolor="#7F5FEE"
                borderColor="#7F5FEE"
              >
                <span>OK</span>
              </StyledButton>
            </Row>
          </div>
        </Col>
      </Row>
    </CommonModal>
  );
};
