import React, { useState, useEffect } from "react";
import { message, PageHeader } from "antd";
import { PageWrap, StyledButton, CommonTable } from "../../../shared/commonStyles";
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import { getFirstAndLastNameByUsername, getToFixedNumber } from "../../../shared/commonFunctions";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import moment from "moment";

const CarbonOffsetOrders = () => {
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [ordersList, setOrdersList] = useState([]);
    //const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [showConfirmModal, setShowConfirmModal] = useState(null);

    const loadProductsList = async (requestPayload) => {
        try {
            setLoadingOrders(true);
            const response = await API.graphql({
                query: queries["decarbonization"], variables: {
                    request_type: "get_orders",
                    request_payload: JSON.stringify(requestPayload)
                }
            });
            const list = JSON.parse(response['data']['decarbonization']);
            if (list?.all_orders) {
                const ordersList = await Promise.all((list?.all_orders?.map(async (item) => {
                    return {
                        ...item,
                        username: await getFirstAndLastNameByUsername(item.user)
                    }
                })))
                setOrdersList(ordersList);
                setNextPage(list?.next_page);
                setPrevPage(list?.prev_page);
            }
        } catch (error) {
            message.error("Error while load products!");
        } finally {
            setLoadingOrders(false);
        }
    }

    const onCancelOrder = async (orderId) => {
        try {
            setConfirmLoading(true);

            const requestPayload = {
                order_id: orderId
            };

            const response = await API.graphql({
                query: queries["decarbonization"], variables: {
                    request_type: "cancel_order",
                    request_payload: JSON.stringify(requestPayload)
                }
            });

            const resp = JSON.parse(response['data']['decarbonization']);
            if (resp?.success) {
                message.success("Order cancelled successfully!");
                setOrdersList(prev => prev.map((item) => (
                    item?.id === resp?.id ? {
                        ...item,
                        allocation_state: resp?.allocation_state,
                        state: resp?.state
                    } : item)));
                showConfirmModal(false);
            } else {
                throw Error(resp);
            }
        } catch (error) {
            message.error("An error has occurred. Please contact support.");
        } finally {
            setConfirmLoading(false);
        }
    }


    useEffect(() => {
        const requestPayload = {
            page: 1
        }
        loadProductsList(requestPayload);
    }, []);

    const onClickCancelOrder = (orderId) => {
        const confirm = {
            onConfirm: onCancelOrder,
            description: "Are you sure you want to cancel the order?",
            order_id: orderId
        };
        setShowConfirmModal(confirm);
    }


    const columns = [
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            // width: "15%"
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            // width: "15%",
            render: (record) => {
                return record;
            }
        },
        {
            title: 'Mass (g)',
            dataIndex: 'mass_g',
            key: 'mass_g',
            // width: "10%"
        },
        {
            title: 'Tonnes',
            dataIndex: 'mass_g',
            key: 'mass_g',
            // width: "10%",
            render: (record) => {
                return record ? getToFixedNumber((record / 1000000)) + " tn" : null;
            }
        },
        {
            title: 'Price (USD)',
            dataIndex: 'price_cents_usd',
            key: 'price_cent_usd',
            // width: "10%",
            render: (record) => {
                return `${record ? "$" + (record / 100) : null}`;
            }
        },
        {
            title: 'Fee (USD)',
            dataIndex: 'fee_cents_usd',
            key: 'fee_cents_usd',
            // width: "10%",
            render: (record) => {
                return `${record ? "$" + (record / 100) : null}`;
            }
        },
        {
            title: 'Registry url',
            dataIndex: 'registry_url',
            key: 'registry_url',
            // width: "10%",
            render: (link) => {
                return link ? <StyledButton type="link" target="_blank" href={link}>Link</StyledButton> : null;
            }
        }, {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // width: "10%",
            render: (_, data) => {
                return <StyledButton type="custom" onClick={() => onClickCancelOrder(data?.id)}>
                    Cancel
                </StyledButton>
            }
        }
    ];

    return (
        <>
            <PageHeader
                ghost={false}
                title={"Orders"}
                extra={[]}
            />
            <PageWrap>
                {showConfirmModal && <ConfirmModal
                    title="Confirmation"
                    description={showConfirmModal?.description}
                    visible={showConfirmModal ? true : false}
                    onClose={() => setShowConfirmModal(null)}
                    onConfirm={() => showConfirmModal.onConfirm(showConfirmModal?.order_id)}
                    ConfirmLoader={confirmLoading}
                />}

                <CommonTable                    
                    loading={loadingOrders}
                    rowKey="id"
                    dataSource={ordersList}
                    columns={columns}
                    scroll={{ x: true }}
                    expandable={{
                        expandedRowRender: record => {
                            const createdAt = record?.created_at ? moment(record?.created_at).format('Y-M-D H:m:s') : null;
                            return (
                                <>
                                    <p><b>Order id</b>: {record?.id}</p>
                                    <p><b>Created at</b>: {createdAt}</p>
                                    <p><b>State</b>: {record?.state}</p>
                                    <p><b>Allocation State</b>: {record?.allocation_state}</p>
                                </>
                            )
                        }
                    }}
                />
            </PageWrap>
        </>
    );
}

export default CarbonOffsetOrders;
