import React, { useEffect, useState } from "react";
import {  
  Input,  
  Row,
  Col,
  Form, 
  Popover,
   
} from "antd";
import {
  StyledButton,
  FormWrapper
} from "../../../../shared/commonStyles";
import AuditorsFilesModal from "./AuditorsFilesModal"

export const ApprovedSection = ({ submitSignoffStatus, submitting, status,  refreshUploadedFiles, auditRecord}) => { 
  const [files, setFiles] = useState([]);
  const [signOffForm] = Form.useForm();

  const onClickConfirmButton = async (comment) => {    
    const response = await submitSignoffStatus(comment, status, files)    
    if(response === 'success'){ 
      setFiles([]);
      signOffForm.resetFields();
    }    
    
  };

  const approvedForm = (    
    <FormWrapper>
      <Form 
        initialValues={{comment : null}} 
        form={signOffForm} 
        onFinish={({comment})=>onClickConfirmButton(comment)}
      >
        {({comment})=>{
            const commentRequired = (status === "denied");
            const commentLabel = status === "approved" ? "Approval reason (Optional)" : "Provide a reason for rejecting this record (Mandatory)";
          return(                                      
            <Row>  
              <Col span={24}>
                <Form.Item 
                    labelCol={{ span: 24 }} 
                    name="comment" 
                    label={commentLabel}
                    rules={[{ required: commentRequired, message:"Please input the comment"}]}
                    >
                    <Input.TextArea placeholder={''} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <AuditorsFilesModal auditRecord = {auditRecord} setFiles={setFiles} files={files} refreshUploadedFiles={refreshUploadedFiles} />
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                >                  
                  <StyledButton 
                    loading={submitting}
                    type="custom" 
                    htmlType="submit" 
                  >Confirm</StyledButton>
                </Form.Item>
              </Col>
            </Row>
          )
        }}
      </Form>
    </FormWrapper>
  );

  return (
    <>
      {status === 'approved' &&
        <>          
          {auditRecord?.status === 'approved' ?
            <StyledButton bgcolor='#7F5FEE' type="default">Approved</StyledButton>
            :
            <Popover
              overlayClassName="popoverContent"
              autoAdjustOverflow        
              placement="bottomRight"
              content={approvedForm}
              trigger="click"
              arrowPointAtCenter       
            >
              <StyledButton type="default">Approve</StyledButton>
            </Popover>
          }
        </>
      }
      {status === 'denied' &&
        <>
          {auditRecord?.status === 'denied' ?
            <StyledButton bgcolor='#7F5FEE' type="default">Denied</StyledButton>
            :
            <Popover
              overlayClassName="popoverContent"
              autoAdjustOverflow        
              placement="bottomRight"
              content={approvedForm}
              trigger="click"
              arrowPointAtCenter       
            >
              <StyledButton type="default">Deny</StyledButton>
            </Popover>
          }
        </>
      }          
    </>
  );
}