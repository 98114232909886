import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import Linepattern from "./Images/Linepattern.png";
import close from "./Images/close.png";
import { getWelcomeMessage } from "../services/users";
import { renderHTML } from "./utils";
import { useCheckRolesPermissions } from "../hooks/useCheckRolesPermissions";
import { getCompanyInfo } from "../services/companyInfo";

const bannerStyle: React.CSSProperties = {
  backgroundSize: "cover",
  boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
  paddingTop: "16px",
  paddingRight: "28px",
  paddingLeft: "28px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "4px",
  minWidth: "890px",
  backgroundImage: `url(${Linepattern}),linear-gradient(138.99deg, #9C87E6 -0.59%, #6850B8 97.24%)`,
};

const textBlockStyle: React.CSSProperties = {
  color: "white",
};

const headingStyle: React.CSSProperties = {
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "white",
};

const textStyle: React.CSSProperties = {
  fontSize: "15px",
  color: "#F1E5FF",
  lineHeight: "24px",
  fontWeight: 300,
  marginLeft: "2px",
  paddingTop: "10px",
};

const imageStyle: React.CSSProperties = {
  position: "absolute",
  top: "8px",
  right: "10px",
  cursor: "pointer",
  width: "35px",
  height: "35px",
};

const htmlContentStyle: React.CSSProperties = {
  flex: 1,
  minHeight: "100%",
  overflowY: "auto",
  fontSize: "16px",
  color: "white",
};

export const Banner: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>();
  const [welcomeMessage, setWelcomeMessage] = useState<string>();
  const [welcomeMessageTitle, setWelcomeMessageTitle] = useState<string>();
  const defaultWelcomeMessage =
    "The all-in-one sustainability management platform. If you have any questions, please reach out to your ESG manager or contact Hydrus support.";
  const defaultWelcomeMessageTitle = "Welcome to Hydrus!";
  const { checkRolesPermission } = useCheckRolesPermissions();

  const onCloseClick = () => {
    setShowBanner(false);
    localStorage.setItem("showBanner", "false");
  };

  const hasBannerPermission =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:admin"]) ||
    checkRolesPermission(["role:auditor"]) ||
    checkRolesPermission(["role:elevateduser"]) ||
    checkRolesPermission(["role:manager"]);

  const loadWelcomeText = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";
        const welcomeText = companyData.default_welcome_message || "";
        const welcomeTitle = companyData.default_welcome_message_title || "";
        return { welcomeText, welcomeTitle };
      }
    } catch (error) {
      return null;
    }
  };

  const getGroupWelcomeMessages = async () => {
    const data = await Auth.currentSession();
    const username = data.getAccessToken()["payload"]["username"];
    const payload = {
      id: username,
    };
    const welcomeMessages = await getWelcomeMessage(payload);
    return welcomeMessages || [];
  };

  useEffect(() => {
    (async () => {
      const shouldShowBanner = localStorage.getItem("showBanner") === "true";
      if (shouldShowBanner && hasBannerPermission) {
        setShowBanner(true);
      }
    })();
  }, [hasBannerPermission]);

  useEffect(() => {
    (async () => {
      if (showBanner) {
        let welcomeMessage = defaultWelcomeMessage;
        let welcomeMessageTitle = defaultWelcomeMessageTitle;
        const companyText = await loadWelcomeText();
        if (companyText) {
          companyText?.welcomeText &&
            (welcomeMessage = companyText?.welcomeText);
          companyText?.welcomeTitle &&
            (welcomeMessageTitle = companyText?.welcomeTitle);
        }
        const welcomeMessages = await getGroupWelcomeMessages();
        if (welcomeMessages && welcomeMessages.length > 0) {
          // setWelcomeMessage
          if (welcomeMessages[0].welcomeMessage) {
            welcomeMessage = welcomeMessages[0].welcomeMessage;
          }
          // setWelcomeMessageTitle
          if (welcomeMessages[0].welcomeMessageTitle) {
            welcomeMessageTitle = welcomeMessages[0].welcomeMessageTitle;
          }
        }
        setWelcomeMessageTitle(welcomeMessageTitle);
        setWelcomeMessage(welcomeMessage);
      }
    })();
  }, [showBanner]);

  return (
    <>
      {showBanner && welcomeMessage && welcomeMessageTitle && (
        <div style={bannerStyle}>
          <img
            style={imageStyle}
            onClick={onCloseClick}
            src={close}
            alt="img"
          />
          <div style={textBlockStyle}>
            <p style={headingStyle}>
              {welcomeMessageTitle}
              <br />
              <div
                style={htmlContentStyle}
                dangerouslySetInnerHTML={{
                  __html: renderHTML(welcomeMessage),
                }}
              ></div>
              {/* <span style={textStyle}>{message}</span> */}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
