import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import DataSheetAudit from "./Components/DataSheetAudit";
import { ReportingPageProvider } from "../reportingPage/context/ReportingPageContext";
import { ReportingProvider } from "../Reporting";
import StandardAudit from "./Components/StandardAudit";
import { calculatorStore } from "../Carbon";
import { getAuditManagements } from "../../services/audits";

const ViewAudit = () => {
  const { audit_id: auditId } = useParams();
  const [rowData, setRowData] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const loadSurveyRowData = async () => {
    try {
      setLoader(true);
      const auditData = await getAuditManagements(auditId);
      if (auditData) {
        setRowData(auditData);
      } else {
        message.error("Survey not found");
        navigate(`/management/collect-data`);
      }
    } catch (error) {
      console.log("Something went wrong while load flow items!", error);
      message.error("Something went wrong while load flow items!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (auditId) {
      loadSurveyRowData();
    }
  }, [auditId]);

  const navigateAuditCalculation = async () => {
    try {
      const calculation = await calculatorStore.getCalculationById(
        JSON.parse(rowData?.audit_items)[0]
      );
      navigate(`/audit/calculations-audit/${calculation.name}`, {
        state: calculation._id.$oid,
      });
    } catch (error) {
      navigate(`/audit/manage`);
    }
  };
  if (rowData?.audit_type === "GHG_EMISSIONS") {
    navigateAuditCalculation();
  }

  return (
    <ReportingProvider
      isAuditPage
      isFiltersShow={rowData?.audit_type === "SUSTAINABILITY_STANDARDS"}
    >
      <ReportingPageProvider>
        <Spin spinning={loader}>
          {rowData && (
            <>
              {rowData.audit_type === "DATA_SHEETS" && (
                <DataSheetAudit auditRecord={rowData} />
              )}
              {rowData.audit_type === "SUSTAINABILITY_STANDARDS" && (
                <StandardAudit auditRecord={rowData} />
              )}
            </>
          )}
        </Spin>
      </ReportingPageProvider>
    </ReportingProvider>
  );
};

export default ViewAudit;
