import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Select,
  Input,
  InputNumber,
  Dropdown,
  Menu,
  Button,
  Steps,
  Radio,
  Collapse,
  Divider,
  Typography,
  message,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
  StyledCollapse,
  StyledDivider,
  StyledSteps,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";
import { updateDataSheet } from "../../../../../services/dataSheet";
import { convertToLowUnderScore } from "../../../../../shared/commonFunctions";
import styles from "./CreateExpressionModal.module.scss";
import { mathOperators } from "../../../../../services/mongoOperators";
import { DataSheetFilters } from "../../../../../components/DataSheetFilters";
import moment from "moment";

interface CreateExpressionModalProps {
  visible: boolean;
  isEditMode?: boolean;
  dataSheet?: DataSheet;
  schema?: SheetSchema[];
  onClose: () => void;
  onConfirm: (schema?: SheetSchema) => void;
  isRecordsPage?: boolean;
  columnData?: SheetSchema;
}

interface ExpressionVariable {
  value: string;
  type: "column" | "number" | "operator";
}

const { Title } = Typography;

export const CreateExpressionModal = ({
  visible,
  isEditMode,
  dataSheet,
  schema,
  onClose,
  onConfirm,
  isRecordsPage,
  columnData,
}: CreateExpressionModalProps) => {
  const newColumnData = {
    ...columnData,
    expressionType: columnData?.expressionType || "simple",
    operationType: columnData?.operationType || "arithmetic",
    ...(columnData?.operationType === "logical" && {
      logicalExpression: {
        ...(columnData?.logicalExpression as any),
        groups: (columnData?.logicalExpression as any)?.groups?.map((v: any) =>
          v.valueType === "column"
            ? { ...v, valueType: v.newValue, newValue: "" }
            : { ...v, valueType: "string" }
        ),
      },
    }),
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<any>({} as any);
  const newValues = {
    ...initialValues,
    ...(initialValues.operationType === "logical" && {
      logicalExpression: {
        ...initialValues.logicalExpression,
        groups: initialValues.logicalExpression?.groups?.map((v: any) =>
          v?.valueType === "string"
            ? v
            : { ...v, valueType: "column", newValue: v?.valueType }
        ),
      },
    }),
    ...(initialValues?.expressionGroups?.length && {
      expressionGroups: initialValues?.expressionGroups?.map((group: any) => ({
        ...group,
        ...(group?.filtersList && {
          filtersList: group?.filtersList?.map((v: AdvancedDataFilter) => ({
            ...v,
            column_value:
              v?.column_value instanceof moment
                ? moment(v?.column_value).format("YYYY-MM-DD")
                : v?.column_value,
          })),
        }),
      })),
    }),
  };

  useEffect(() => {
    form.resetFields();
    setInitialValues({});
    form.setFieldsValue(newColumnData);
    setInitialValues(newColumnData);
  }, [visible]);

  const onChangeFieldsData = () => {
    const formValues = form.getFieldsValue(true);
    setInitialValues(formValues);
  };
  const handleClose = () => {
    form.resetFields();
    setInitialValues({});
    onClose();
    setCurrentStep(0);
  };
  const handleSave = () => {
    const newSchema = columnData
      ? newValues
      : ({
          ...newValues,
          added: true,
          data_type:
            initialValues.operationType === "logical" ? "string" : "number",
          ...(isRecordsPage && {
            entity_name: convertToLowUnderScore(initialValues.display_name),
            input_type: "expression",
          }),
          required: false,
          visible: true,
        } as any);
    if (dataSheet && isRecordsPage) {
      const payload = {
        _id: dataSheet._id,
        sheet_name: dataSheet.sheet_name,
        sheet_type: dataSheet.sheet_type,
        metadata_sheet_id: dataSheet.metadata_sheet_id,
        description: dataSheet.description,
        sheet_schema: [...dataSheet.sheet_schema, newSchema],
        archive: dataSheet.archive,
        enable_comments: dataSheet.enable_comments,
        enable_change_history: dataSheet.enable_change_history,
      };
      updateDataSheet(payload)
        .then(() => message.success("Expression was successfully created"))
        .catch(() =>
          message.error("An error occurred while creating expression")
        )
        .finally(() => {
          onConfirm();
          form.resetFields();
        });
      onConfirm();
      form.resetFields();
      setInitialValues({});
      setCurrentStep(0);
    } else {
      onConfirm(newSchema);
      onClose();
      form.resetFields();
      setInitialValues({});
      setCurrentStep(0);
    }
  };

  const columnOptions = isRecordsPage
    ? dataSheet?.sheet_schema
        .filter((column) => column.data_type === "number")
        .map((column) => {
          return {
            label: column.display_name,
            value: column.entity_name,
          };
        })
    : schema
        ?.filter((column) => column.input_type === "number")
        .map((column) => {
          return {
            label: column.display_name,
            value: column.entity_name,
          };
        });

  const handleChangeLogicalColumn = (value: string) => {
    const formValues = form.getFieldsValue();
    setInitialValues({
      ...formValues,
      logicalExpression: { ...formValues.logicalExpression, groups: [] },
    });
    form.setFieldsValue({
      ...formValues,
      logicalExpression: { ...formValues.logicalExpression, groups: [] },
    });
  };

  const handleChangeArithmetic = () => {
    const formValues = form.getFieldsValue(true);
    if (formValues.operationType === "arithmetic") {
      delete formValues?.logicalExpression;
    }
    if (formValues.operationType === "logical") {
      delete formValues.expressionProperties;
    }
    form.setFieldsValue(formValues);
    setInitialValues(formValues);
  };

  const variableSettings = (listName: any, groupIndex?: number) => (
    <>
      <div className={styles.label}>
        {" "}
        {initialValues?.operationType === "arithmetic"
          ? "Select variables for new expression"
          : "Select column and filters"}
      </div>
      <StyledDivider margin="0 0 20px 0" />
      {initialValues?.operationType === "arithmetic" ? (
        <Form.List name={listName}>
          {(columns, { add: addColumn, remove: removeColumn }) => {
            const isExpressionCompleted = groupIndex
              ? initialValues?.expressionGroups?.[groupIndex - 1]
                  ?.expressionProperties?.length >= 3
              : initialValues?.expressionProperties?.length >= 3;
            return (
              <>
                {columns.map((item, index) => {
                  const columnType = groupIndex
                    ? initialValues?.expressionGroups?.[groupIndex - 1]
                        ?.expressionProperties?.[item.name]?.type
                    : initialValues?.expressionProperties?.[item.name]?.type;
                  const previousColumnType = groupIndex
                    ? initialValues?.expressionGroups?.[groupIndex - 1]
                        ?.expressionProperties?.[item.name - 1]?.type
                    : initialValues?.expressionProperties?.[item.name - 1]
                        ?.type;

                  if (columnType === "column") {
                    return (
                      <Row
                        justify="space-between"
                        style={{ alignItems: "baseline" }}
                      >
                        <Col
                          span={
                            columns.length > 1 && index === columns.length - 1
                              ? 22
                              : 24
                          }
                        >
                          <Form.Item
                            label="Column"
                            name={[item.name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select the column!",
                              },
                            ]}
                          >
                            <Select
                              className={styles.select}
                              placeholder="Select the column"
                              options={columnOptions}
                            />
                          </Form.Item>
                        </Col>
                        {columns.length > 1 && index === columns.length - 1 && (
                          <Col>
                            <Form.Item label=" ">
                              <StyledButton
                                span={2}
                                type="default"
                                style={{
                                  padding: "8px",
                                  marginTop: "0px",
                                  high: "30px",
                                }}
                                onClick={() => {
                                  removeColumn(index);
                                  previousColumnType === "operator" &&
                                    removeColumn(index - 1);
                                }}
                                icon={
                                  <>
                                    <DeleteOutlined />
                                  </>
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    );
                  } else if (columnType === "number") {
                    return (
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ alignItems: "baseline" }}
                      >
                        <Col
                          span={
                            columns.length > 1 && index === columns.length - 1
                              ? 22
                              : 24
                          }
                        >
                          <Form.Item
                            label="Number"
                            name={[item.name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Please type a number!",
                              },
                            ]}
                          >
                            <InputNumber placeholder="Type a number" />
                          </Form.Item>
                        </Col>
                        {columns.length > 1 && index === columns.length - 1 && (
                          <Col>
                            <Form.Item label=" ">
                              <StyledButton
                                span={2}
                                type="default"
                                style={{
                                  padding: "8px",
                                  marginTop: "0px",
                                  high: "30px",
                                }}
                                onClick={() => {
                                  removeColumn(index);
                                  previousColumnType === "operator" &&
                                    removeColumn(index - 1);
                                }}
                                icon={
                                  <>
                                    <DeleteOutlined />
                                  </>
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    );
                  } else if (columnType === "operator") {
                    return (
                      <Row
                        justify="space-between"
                        style={{ alignItems: "baseline" }}
                      >
                        <Col span={24}>
                          <Form.Item
                            label="Operator"
                            name={[item.name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select an operator!",
                              },
                            ]}
                          >
                            <Select
                              className={styles.select}
                              placeholder="Select operators"
                              options={mathOperators}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  }
                })}
                <Row>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      name={"column"}
                      rules={[
                        {
                          required: !isExpressionCompleted,
                          message: "Please select the column!",
                        },
                      ]}
                    >
                      <Dropdown
                        trigger={["click"]}
                        overlay={
                          <TableCommonMenu style={{ width: "100%" }}>
                            <Menu.Item
                              key="column"
                              style={{
                                borderBottom: "1px solid #8472C1",
                              }}
                            >
                              <StyledButton
                                type="custom"
                                onClick={() => {
                                  if (columns?.length) {
                                    addColumn({ value: "", type: "operator" });
                                    addColumn({ value: "", type: "column" });
                                  } else {
                                    addColumn({ value: "", type: "column" });
                                  }
                                }}
                              >
                                <span>Column</span>
                              </StyledButton>
                            </Menu.Item>
                            <Menu.Item key="number">
                              <StyledButton
                                type="custom"
                                onClick={() => {
                                  if (columns?.length) {
                                    addColumn({ value: "", type: "operator" });
                                    addColumn({ value: "", type: "number" });
                                  } else
                                    addColumn({ value: "", type: "number" });
                                }}
                              >
                                <span>Number</span>
                              </StyledButton>
                            </Menu.Item>
                          </TableCommonMenu>
                        }
                      >
                        <StyledButton
                          style={{ width: "100%" }}
                          type="custom"
                          margin="0"
                          padding="5px auto"
                          icon={<DownOutlined />}
                        >
                          Add variable
                        </StyledButton>
                      </Dropdown>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.List>
      ) : (
        <>
          <Form.Item
            label="Column"
            name={["logicalExpression", "column"]}
            rules={[
              {
                required: true,
                message: "Please select the column!",
              },
            ]}
          >
            <Select
              onChange={handleChangeLogicalColumn}
              className={styles.select}
              placeholder="Select the column"
              options={columnOptions}
            />
          </Form.Item>
          <div style={{ height: "30px", paddingLeft: "14px" }}>
            Filters{" "}
            <Tooltip
              title={
                "Apply the logical expression to a subset of your data by specifying the data sheet filters below."
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>{" "}
          <DataSheetFilters
            initialFilters={initialValues?.logicalExpression?.activationFilters}
            formFilters={initialValues?.logicalExpression?.activationFilters}
            dataSheet={dataSheet}
            schema={filteredSchema}
            addButtonText="Add"
            listName={["logicalExpression", "activationFilters"]}
          />
          <Form.List name={["logicalExpression", "groups"]}>
            {(fields, { add, remove }) => {
              const logicalColumn = isRecordsPage
                ? dataSheet?.sheet_schema?.filter(
                    (item) =>
                      item.entity_name ===
                      initialValues?.logicalExpression?.column
                  )
                : schema?.filter(
                    (item) =>
                      item.entity_name ===
                      initialValues?.logicalExpression?.column
                  );
              const stringOptions =
                (isRecordsPage
                  ? dataSheet?.sheet_schema
                      .filter((column) => column.data_type === "string")
                      .map((column) => {
                        return {
                          label: column.display_name,
                          value: column.entity_name,
                        };
                      })
                  : schema
                      ?.filter((column) => column.input_type === "string")
                      .map((column) => {
                        return {
                          label: column.display_name,
                          value: column.entity_name,
                        };
                      })) || [];
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => {
                    const isCustomValue =
                      initialValues?.logicalExpression?.groups?.[name]
                        ?.valueType === "string";
                    return (
                      <>
                        <Col span={24}>
                          <StyledCollapse
                            collapsible="header"
                            key={name}
                            defaultActiveKey={name}
                          >
                            <Collapse.Panel
                              extra={
                                <Row justify="space-between">
                                  <Col span={isCustomValue ? 10 : 21}>
                                    <Form.Item
                                      name={[name, "valueType"]}
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                      key={name}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select column value",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Select column value"
                                        options={[
                                          ...stringOptions,
                                          { value: "string", label: "Other" },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                  {isCustomValue && (
                                    <Col span={10}>
                                      <Form.Item
                                        name={[name, "newValue"]}
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                        key={name}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input new column value",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="New column value" />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  <Col span={2}>
                                    <StyledButton
                                      type="default"
                                      style={{
                                        padding: "8px",
                                        marginTop: "0px",
                                        high: "30px",
                                      }}
                                      onClick={() => remove(name)}
                                      icon={<DeleteOutlined />}
                                    />
                                  </Col>
                                </Row>
                              }
                              header={
                                <>
                                  <Form.Item
                                    name={[name, "newValue"]}
                                    noStyle
                                    key={name}
                                  >
                                    New column value:
                                  </Form.Item>
                                </>
                              }
                              key={name}
                            >
                              <Title level={5}>Datasheet filters</Title>

                              <DataSheetFilters
                                isLogicalExpression
                                initialFilters={
                                  initialValues?.logicalExpression?.groups[name]
                                    ?.filtersList
                                }
                                formFilters={
                                  initialValues?.logicalExpression?.groups[name]
                                    ?.filtersList
                                }
                                dataSheet={dataSheet}
                                schema={logicalColumn}
                                resetInput={(key) => resetInput(key, name)}
                                addButtonText="Add"
                                listName={[name, "filtersList"]}
                              />
                            </Collapse.Panel>
                          </StyledCollapse>
                        </Col>
                      </>
                    );
                  })}
                  <Form.Item
                    name={[""]}
                    style={{ marginBottom: 30, marginTop: 30 }}
                    rules={[
                      {
                        required: fields.length === 0,
                        message: "Please add group",
                      },
                    ]}
                  >
                    <StyledButton
                      type="custom"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add New Group
                    </StyledButton>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </>
      )}
    </>
  );

  const handleConfirmVariables = () => {
    form
      .validateFields()
      .then(() => {
        !isRecordsPage
          ? handleSave()
          : setCurrentStep((current) => current + 1);
      })
      .catch((error) => {
        form.setFields(error.errorFields);
      });
  };

  const handleGoNext = () => {
    setCurrentStep((current) => current + 1);
  };

  const handleChangeType = () => {
    const formValues = form.getFieldsValue(true);
    if (formValues.expressionType === "simple") {
      delete formValues?.expressionGroups;
    }
    if (formValues.expressionType === "conditional") {
      delete formValues.expressionProperties;
    }
    form.setFieldsValue(formValues);
    setInitialValues(formValues);
  };

  const filteredSchema = schema?.filter(
    (item) => item.entity_name !== initialValues.entity_name
  );

  const steps = [
    {
      title: "Expression Type selection",
      content: (
        <>
          <div className={styles.label}>Select expression type</div>
          <Form.Item
            name="expressionType"
            rules={[
              {
                required: true,
                message: "Please select the expression type!",
              },
            ]}
          >
            <Radio.Group
              disabled={isEditMode}
              onChange={handleChangeType}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Radio className={styles.radioDisabled} value={"simple"}>
                Simple Expression
              </Radio>
              <Radio className={styles.radioDisabled} value={"conditional"}>
                Conditional Expression
              </Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ),
      button: (
        <>
          <Button type="default" onClick={handleClose}>
            Cancel
          </Button>

          <Button type="primary" onClick={() => handleGoNext()}>
            {initialValues?.expressionType &&
            initialValues?.expressionType === "simple"
              ? "Go to Variable selection"
              : "Go to Filter Group"}
          </Button>
        </>
      ),
    },
    {
      title: "Variable selection",
      content:
        initialValues?.expressionType &&
        initialValues?.expressionType === "simple" ? (
          <>
            {" "}
            <Form.Item
              name="operationType"
              rules={[
                {
                  required: true,
                  message: "Please select the expression type!",
                },
              ]}
            >
              <Radio.Group
                disabled={isEditMode}
                onChange={handleChangeArithmetic}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Radio className={styles.radioDisabled} value={"arithmetic"}>
                  Arithmetic Expression{" "}
                  <Tooltip
                    title={
                      "Create a new field using one or more existing columns of the data sheet, and combining them with arithmetic operators like addition, subtraction, multiplication, and division."
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Radio>
                <Radio className={styles.radioDisabled} value={"logical"}>
                  Logical Expression{" "}
                  <Tooltip
                    title={
                      "Create a new field using another existing data sheet column as a reference, and comparing its values with logical operators like equal to, less than, and greater than."
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {variableSettings("expressionProperties")}
          </>
        ) : (
          <>
            <Form.List name="expressionGroups">
              {(fields) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      return (
                        <StyledCollapse
                          collapsible="header"
                          defaultActiveKey={key}
                          key={key}
                        >
                          <Collapse.Panel
                            header={
                              <Row>
                                {
                                  initialValues?.expressionGroups?.[index]
                                    ?.groupName
                                }
                              </Row>
                            }
                            key={index}
                          >
                            <Form.Item>
                              {variableSettings(
                                [name, "expressionProperties"],
                                index + 1
                              )}
                            </Form.Item>
                          </Collapse.Panel>
                        </StyledCollapse>
                      );
                    })}
                  </>
                );
              }}
            </Form.List>
          </>
        ),

      button: (
        <>
          <Button
            type="default"
            onClick={() => setCurrentStep((current) => current - 1)}
          >
            Back
          </Button>
          <Button type="primary" onClick={() => handleConfirmVariables()}>
            {isRecordsPage ? "Go to Name of the new column" : "Finish"}
          </Button>
        </>
      ),
    },
  ];

  const nameStep = {
    title: "Name of the new column",
    content: (
      <>
        <div className={styles.label}>Enter the name of a new column</div>
        <StyledDivider />
        <Form.Item
          name="display_name"
          label="Enter name"
          style={{ marginTop: 16 }}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item name="description" label="Enter description">
          <Input placeholder="Enter description" />
        </Form.Item>
      </>
    ),
    button: (
      <>
        <Button
          type="default"
          onClick={() => setCurrentStep((current) => current - 1)}
        >
          Back
        </Button>
        <Button type="primary" onClick={handleSave}>
          Finish
        </Button>
      </>
    ),
  };
  const handleConfirmFilters = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep((current) => current + 1);
      })
      .catch((error) => {
        form.setFields(error.errorFields);
      });
  };

  const resetInput = (filterIndex: number, groupIndex: number) => {
    const formValues = form.getFieldsValue(true);
    const filterGroups = formValues.expressionGroups.map(
      (group: any, index: number) => {
        if (index === groupIndex) {
          const newFilters = group.filtersList.map(
            (filter: AdvancedDataFilter, i: number) => {
              if (filterIndex === i) {
                return {
                  ...filter,
                  op: null,
                  column_value:
                    filter.column === "owner_users" ||
                    filter.column === "owner_groups"
                      ? []
                      : "",
                };
              } else {
                return filter;
              }
            }
          );
          return { ...group, filtersList: newFilters };
        } else {
          return group;
        }
      }
    );
    form.setFieldsValue({ ...formValues, expressionGroups: filterGroups });
    setInitialValues({ ...formValues, expressionGroups: filterGroups });
  };

  const filtersStep = {
    title: "Filter groups",
    content: (
      <>
        <Divider
          style={{
            backgroundColor: "#3A3C5A",
            marginTop: "15px",
          }}
        />
        <Form.List name="expressionGroups">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <>
                      <Col span={24}>
                        <StyledCollapse
                          collapsible="header"
                          key={name}
                          defaultActiveKey={name}
                        >
                          <Collapse.Panel
                            extra={
                              <Row justify="space-between">
                                <Col span={21}>
                                  <Form.Item
                                    name={[name, "groupName"]}
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    key={name}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter filters group name",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Filter group name" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <StyledButton
                                    type="default"
                                    style={{
                                      padding: "8px",
                                      marginTop: "0px",
                                      high: "30px",
                                    }}
                                    onClick={() => remove(name)}
                                    icon={<DeleteOutlined />}
                                  />
                                </Col>
                              </Row>
                            }
                            header={<>Filter group name:</>}
                            key={name}
                          >
                            {initialValues?.expressionGroups?.[index]
                              ?.filtersList?.length > 1 && (
                              <>
                                <Title
                                  level={5}
                                  style={{
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Filter Operator Types
                                </Title>

                                <Form.Item
                                  name={[name, "operatorType"]}
                                  style={{
                                    marginBottom: "10px",
                                    minHeight: "30px",
                                  }}
                                  key={name}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Select operator type",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value="$and">AND</Radio>
                                    <Radio value="$or">OR</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </>
                            )}
                            <Title level={5}>Datasheet filters</Title>

                            <DataSheetFilters
                              initialFilters={
                                initialValues?.expressionGroups?.[index]
                                  ?.filtersList
                              }
                              formFilters={
                                initialValues?.expressionGroups?.[index]
                                  ?.filtersList
                              }
                              dataSheet={dataSheet}
                              schema={filteredSchema}
                              resetInput={(key) => resetInput(key, name)}
                              addButtonText="Add"
                              listName={[name, "filtersList"]}
                            />
                          </Collapse.Panel>
                        </StyledCollapse>
                      </Col>
                    </>
                  );
                })}
                <Form.Item style={{ marginBottom: 30, marginTop: 30 }}>
                  <StyledButton
                    type="custom"
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}
                  >
                    Add New Group
                  </StyledButton>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </>
    ),
    button: (
      <>
        <Button
          type="default"
          onClick={() => setCurrentStep((current) => current - 1)}
        >
          Back
        </Button>
        <Button
          type="primary"
          disabled={
            !initialValues?.expressionGroups?.length ||
            initialValues?.expressionGroups?.some(
              (item: any) => !item?.filtersList?.length
            )
          }
          onClick={() => handleConfirmFilters()}
        >
          Go to Variable Selection
        </Button>
      </>
    ),
  };

  initialValues?.expressionType &&
    initialValues?.expressionType === "conditional" &&
    steps.splice(1, 0, filtersStep);
  isRecordsPage && steps.push(nameStep);
  return (
    <CommonModal
      title="Create an expression"
      width={1050}
      visible={visible}
      centered
      footer={steps[currentStep]?.button}
      onCancel={handleClose}
    >
      <StyledSteps
        className={
          isRecordsPage ? `${styles.steps}` : `${styles.stepsOnCreate}`
        }
        type="navigation"
        current={currentStep}
      >
        {steps.map((item) => (
          <Steps.Step key={item?.title} title={item?.title} />
        ))}
      </StyledSteps>

      <Form
        form={form}
        initialValues={initialValues}
        onFieldsChange={onChangeFieldsData}
        layout="vertical"
      >
        <FormWrapper>{steps[currentStep]?.content}</FormWrapper>
      </Form>
    </CommonModal>
  );
};
