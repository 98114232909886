import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  CommentOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import moment, { Moment } from "moment";
import { v4 as uuidv4 } from "uuid";
import { YearMonthDayFormat } from "../../../shared/constants";
import { useBoolean } from "ahooks";
import { FormWrapper, StyledButton } from "../../../shared/commonStyles";
import { SurveyNotesDrawer } from "./SurveyNotesDrawer";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import { observer } from "mobx-react-lite";
import { surveyStore } from "../../../stores/SurveyStore";
import styles from "./SurveyView.module.scss";
import { RichTextModal } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/RichTextModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
interface DynamicCellProps {
  disabledFields: any[];
  schema: SheetSchema;
  record: DataSheetRecord;
  rowData: RecordRowData;
  recordId: string;
  isUnitId?: boolean;
  onSave: (record: DataSheetRecord, sheetId: string) => void;
  activeNoteRecord: { recordId: string; noteType?: "comment" | "warning" };
  size?: { width: number };
}

export const DynamicCell = observer(
  ({
    disabledFields,
    schema,
    record,
    rowData,
    recordId,
    isUnitId,
    onSave,
    activeNoteRecord,
    size,
  }: DynamicCellProps) => {
    const [currentAlert, setCurrentAlert] = useState<PopconfirmAlert>();
    const [isRichTextModalVisible, setIsRichTextModalVisible] = useState(false);
    const [
      isNotesDrawerShown,
      { setTrue: showNotesDrawer, setFalse: hideNotesDrawer },
    ] = useBoolean(false);

    const fieldName: any = schema.entity_name.replace("_actual_value", "");

    const [fieldValue, setFieldValue] = useState<
      string | number | string[] | Moment | null
    >();
    const getEmptyStatus =
      schema.required &&
      !fieldValue &&
      surveyStore.currentStepData.fieldsStatus;
    const getNotValidStatus =
      surveyStore.currentStepData?.validation?.find(
        (v) =>
          v.recordId === recordId &&
          v.fields.find((field) => field === fieldName)
      ) && surveyStore.currentStepData.fieldsStatus;

    const [status, setStatus] = useState<"warning" | "error" | "">("");
    const [warningWord, setWarningWord] = useState<"below" | "above" | "">("");

    const [reason, setReason] = useState<string>("");

    const [fieldNotes, setFieldNotes] = useState(record?.field_notes || []);
    const [
      isPopconfirmVisible,
      {
        setTrue: openPopconfirm,
        setFalse: closePopconfirm,
        toggle: togglePopconfirm,
      },
    ] = useBoolean(false);

    const [isFieldEditable, { setTrue: setEditable, setFalse: forbidEditing }] =
      useBoolean(true);

    const adminNotes = (
      !isUnitId
        ? fieldNotes?.filter(
            (v: FieldNote) => v?.field == fieldName && v.isAddedByAdmin
          )
        : fieldNotes?.filter(
            (v: FieldNote) =>
              v?.field == fieldName + "_unit_id" && v.isAddedByAdmin
          )
    )?.filter((note) =>
      recordId === activeNoteRecord?.recordId
        ? activeNoteRecord.noteType === "warning"
          ? note.noteType === "warning"
          : activeNoteRecord.noteType === "comment" &&
            note.noteType !== "warning"
        : note
    );

    useEffect(() => {
      if (currentAlert) {
        setWarningWord(currentAlert.warning_word || "");
        setReason(currentAlert.reason);
        setStatus(
          getNotValidStatus || getEmptyStatus || currentAlert?.alert_type || ""
        );
      }
      currentAlert?.isOpen && openPopconfirm();
    }, [currentAlert]);

    useEffect(() => {
      const currentAlert = surveyStore.currentStepData?.popconfirms?.find(
        (v) => v.recordId === recordId && v.name === fieldName
      );
      currentAlert && setCurrentAlert(currentAlert);
      setStatus(
        getNotValidStatus || getEmptyStatus || currentAlert?.alert_type || ""
      );
    }, [fieldValue, surveyStore.currentStepData?.popconfirms]);

    useEffect(() => {
      setFieldNotes(record?.field_notes || []);
    }, [record?.field_notes]);

    useEffect(() => {
      setStatus(
        getNotValidStatus || getEmptyStatus || currentAlert?.alert_type || ""
      );
    }, [
      surveyStore.currentStepData.fieldsStatus,
      surveyStore.currentStepData?.validation?.length,
    ]);

    useEffect(() => {
      (surveyStore.surveyInfo.defaultMetaDataSheetId.length &&
        schema.metadata_entity_name) ||
      Object.keys(rowData?.placeholders || {})?.find(
        (key) =>
          schema.entity_name === key ||
          schema.entity_name === key + "_actual_value"
      ) ||
      record?.import_id ||
      surveyStore.surveyInfo.status !== "ACTIVE" ||
      record?.review_status === "APPROVED" ||
      record?.isLocked ||
      disabledFields
        ?.find((v) => v.id == recordId)
        ?.disabledFields?.some((item: any) => item == schema.entity_name)
        ? forbidEditing()
        : setEditable();
      if (schema.input_type === "date") {
        let value: string | number | Moment | any = record
          ? record?.[record?.sheet_name]?.[schema?.entity_name]
          : "";
        value = value ? moment((value as any)?.$date || value) : null;
        setFieldValue(value);
      } else if (isUnitId) {
        setFieldValue(
          record?.[record?.sheet_name]?.[
            schema?.entity_name + "_actual_unit_id"
          ] || record?.[record?.sheet_name]?.[schema?.entity_name + "_unit_id"]
        );
      } else {
        const value: string | number =
          typeof record?.[record?.sheet_name]?.[schema?.entity_name] ===
          "object"
            ? ""
            : record?.[record?.sheet_name]?.[schema?.entity_name];
        setFieldValue(value);
      }
    }, [record, disabledFields]);

    const handleSave = (value?: string | number | string[] | Moment) => {
      const newRecord = { ...record };
      const currentValue = value || fieldValue;

      !currentAlert?.alert_type && setStatus("");
      closePopconfirm();
      newRecord.alerts = newRecord.alerts?.filter(
        (alert) => alert.field !== fieldName
      );

      try {
        const targetKey =
          schema.inputType === "unit_id" || isUnitId
            ? fieldName + "_actual_unit_id"
            : schema.entity_name;

        newRecord[record.sheet_name][targetKey] = currentValue;
        if (schema.unit_id && schema.inputType !== "unit_id" && !isUnitId) {
          newRecord[record.sheet_name][
            targetKey?.replace("_actual_value", "")
          ] = currentValue;
        }
        onSave(newRecord, recordId);
      } catch (errInfo) {
        if ((errInfo as any).values) {
          newRecord[record.sheet_name][fieldName] = (errInfo as any).values[
            fieldName
          ];
        }
      }
    };

    const handleChangeSelector = (value: string) => {
      setFieldValue(value);
      handleSave(value);
    };

    const handleChangeCheckbox = (value: CheckboxValueType[]) => {
      setFieldValue(value.map((v) => v.toString()));
      handleSave();
    };

    const handleChangeInput = (value: number) => {
      setFieldValue(value);
      const newList = surveyStore.currentStepData?.popconfirms?.filter(
        (popconfirm) =>
          !(popconfirm.recordId === recordId && popconfirm.name === fieldName)
      );
      surveyStore.changeSurveyData(newList, "popconfirms");
    };

    const handleReasonConfirm = () => {
      handleSave();
      const currentAlert = surveyStore.currentStepData?.popconfirms?.findIndex(
        (v) => v.recordId === recordId && v.name === fieldName
      );
      const nextAlert = surveyStore.currentStepData?.popconfirms?.findIndex(
        (v) =>
          !v.reason?.length &&
          !(v.recordId === recordId && v.name === fieldName)
      );
      const newList = surveyStore.currentStepData?.popconfirms?.map(
        (popconfirm, index) =>
          index === currentAlert
            ? {
                ...popconfirm,
                isOpen: false,
                reason,
                warning_word: warningWord,
              }
            : index === nextAlert
            ? { ...popconfirm, isOpen: true }
            : popconfirm
      );
      surveyStore.changeSurveyData(newList, "popconfirms");
      closePopconfirm();
    };

    const handleAddFieldNote = (text: string) => {
      if (text.length) {
        const newNote = {
          key: uuidv4(),
          user: userInfoStore.userName,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          text: text,
          field: !isUnitId ? fieldName : fieldName + "_unit_id",
          username: userInfoStore.userID,
        };
        const newFieldNotes = record?.field_notes?.length
          ? [...record?.field_notes, newNote]
          : [...fieldNotes, newNote];
        setFieldNotes(newFieldNotes);
        const updatedrecord = {
          ...record,
          field_notes: newFieldNotes,
        } as DataSheetRecord;
        onSave(updatedrecord, recordId);
      }
    };

    const handleChangeNoteStatus = (checked: boolean, noteKey: string) => {
      const newFieldNotes = fieldNotes.map((item) =>
        item.key === noteKey ? { ...item, isAcknowledged: checked } : item
      );
      setFieldNotes(newFieldNotes);
      const updatedrecord = {
        ...record,
        field_notes: newFieldNotes,
      } as DataSheetRecord;
      onSave(updatedrecord, recordId);
    };

    const handleViewRichText = () => {
      setIsRichTextModalVisible(true);
    };

    const handleEditRichText = (data: string) => {
      setFieldValue(data);
      handleSave(data);
    };

    const generateCell = () => {
      if (isUnitId) {
        return (
          <Col>
            <Select
              style={{ minWidth: "150px" }}
              disabled={!isFieldEditable}
              onChange={(v) => handleChangeSelector(v as string)}
              value={fieldValue}
              dropdownMatchSelectWidth={false}
              options={schema.convertable_units?.map((item: any) => {
                return { value: item.unit, label: item.name };
              })}
            />
          </Col>
        );
      }

      switch (schema.input_type) {
        case "number":
          return (
            <Popconfirm
              visible={isPopconfirmVisible}
              title={
                <FormWrapper className="reasonPopconfirmWrapper">
                  <p className="reasonPopconfirmText">
                    Entered data is much {warningWord} than the baseline. Enter
                    a reason or correct the value to submit the survey
                  </p>
                  <Input
                    value={reason}
                    placeholder="Enter reason"
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </FormWrapper>
              }
              onConfirm={handleReasonConfirm}
              onCancel={() => {
                closePopconfirm();
                setReason(currentAlert?.reason || "");
              }}
              cancelText="Cancel"
              okText="Submit and go to the next"
              placement="bottomRight"
              icon={<WarningOutlined style={{ color: "#e9d155" }} />}
              overlayClassName={`popoverContent reasonPopover ${
                isPopconfirmVisible && "reasonPopoverActive"
              }`}
            >
              <InputNumber
                disabled={!isFieldEditable}
                lang="en"
                value={fieldValue as number}
                onChange={(e) => {
                  handleChangeInput(e);
                }}
                onBlur={() => handleSave()}
                onPressEnter={() => handleSave()}
                className={styles.columnField}
                addonAfter={
                  status === "warning" && (
                    <WarningOutlined
                      onClick={togglePopconfirm}
                      style={{ color: "orange" }}
                    />
                  )
                }
                status={status}
              />
            </Popconfirm>
          );
        case "date":
          return (
            <DatePicker
              disabled={!isFieldEditable}
              onChange={(value) => setFieldValue(value)}
              onBlur={() => handleSave()}
              value={fieldValue as Moment}
              className={styles.dateField}
              format={YearMonthDayFormat}
              status={status}
            />
          );
        case "string":
          return (
            <Input
              disabled={!isFieldEditable}
              value={fieldValue as string}
              onPressEnter={() => handleSave()}
              onBlur={() => handleSave()}
              onChange={(e) => setFieldValue(e.target.value)}
              status={status}
            />
          );
        case "dropdown":
          return (
            <Select
              disabled={!isFieldEditable}
              onChange={(v) => handleChangeSelector(v as string)}
              value={fieldValue}
              dropdownMatchSelectWidth={false}
              className={styles.columnField}
              options={schema?.options?.map((item: any) => {
                return { value: item.value, label: item.label };
              })}
              status={status}
            />
          );
        case "radio":
          return (
            <Radio.Group
              disabled={!isFieldEditable}
              onChange={(e) => handleChangeSelector(e.target.value)}
              value={fieldValue}
            >
              {schema?.options?.map((item: any) => {
                return (
                  <Radio key={item?.value} value={item?.value}>
                    {item?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          );
        case "checkbox":
          if (schema?.is_checkbox_group) {
            return (
              <Checkbox.Group
                disabled={!isFieldEditable}
                onChange={handleChangeCheckbox}
                defaultValue={fieldValue as CheckboxValueType[]}
              >
                <Row>
                  {schema?.options?.map((item: any) => {
                    return (
                      <Col key={item?.value} xs={24} sm={24} md={24} lg={24}>
                        <Checkbox value={item?.value}>{item?.label}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            );
          } else {
            return (
              <Checkbox
                disabled={!isFieldEditable}
                onChange={(v) => handleChangeCheckbox(v.target.value)}
                value={fieldValue}
              >
                {schema.display_name}{" "}
                {schema.description &&
                  schema.description != schema.display_name && (
                    <Tooltip
                      title={
                        schema.description
                          ? schema.description
                          : schema.display_name
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
              </Checkbox>
            );
          }
        case "long-answer":
          return (
            <Input.TextArea
              disabled={!isFieldEditable}
              value={fieldValue as string}
              onPressEnter={() => handleSave()}
              onBlur={() => handleSave()}
              onChange={(e) => setFieldValue(e.target.value)}
              style={{ height: 100 }}
              status={status}
            />
          );
        case "rich-text":
          if (size) {
            return (
              <Col style={{ width: "100%" }}>
                <div className="ckSmallHeight">
                  <CKEditor
                    editor={DecoupledDocumentEditor}
                    onReady={(editor: any) => {
                      if (editor?.ui) {
                        editor.ui.view.editable.element.parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.view.editable.element
                        );
                      }
                    }}
                    data={fieldValue || ""}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();
                      setFieldValue(data);
                      handleSave();
                    }}
                    disabled={!isFieldEditable}
                    style={{ height: 100 }}
                  />
                </div>
              </Col>
            );
          } else
            return (
              <StyledButton
                type="default"
                icon={<EyeOutlined />}
                onClick={() => handleViewRichText()}
              >
                View
              </StyledButton>
            );
        default:
          return <></>;
      }
    };

    if (schema?.metadata_entity_name && !rowData.hide_fields) {
      return <></>;
    }
    return (
      <>
        <Row align="middle" justify="space-between" className={styles.rowCell}>
          <Col
            span={
              rowData.show_notes || adminNotes.length
                ? rowData.show_notes && adminNotes.length
                  ? 18
                  : 20
                : 24
            }
          >
            <Popover
              overlayClassName="popoverContent"
              title={schema.description}
            >
              {size ? (
                <Form.Item>{generateCell()}</Form.Item>
              ) : (
                <Input.Group> {generateCell()}</Input.Group>
              )}
            </Popover>
          </Col>
          <Col
            span={
              rowData.show_notes || adminNotes.length
                ? rowData.show_notes && adminNotes.length
                  ? 6
                  : 4
                : 0
            }
          >
            {!!adminNotes.length && (
              <Popover
                overlayClassName="popoverContent"
                title={
                  <FormWrapper>
                    {adminNotes.map((v) => (
                      <Row
                        key={v?.key}
                        gutter={[8, 8]}
                        align="middle"
                        justify="space-between"
                      >
                        <Col>{v?.text}</Col>
                        <Col>
                          <Checkbox
                            disabled={v?.isAcknowledged}
                            checked={v?.isAcknowledged}
                            onChange={(e) =>
                              handleChangeNoteStatus(e.target.checked, v?.key!)
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </FormWrapper>
                }
              >
                <span
                  className={
                    recordId === activeNoteRecord?.recordId
                      ? activeNoteRecord.noteType === "warning"
                        ? `textOrange`
                        : `textViolet`
                      : ""
                  }
                >
                  {adminNotes.some((note) => note.noteType === "warning") ? (
                    <WarningOutlined className={styles.warning} />
                  ) : (
                    <CommentOutlined className={styles.comments} />
                  )}
                </span>
              </Popover>
            )}
            {rowData.show_notes && (
              <Row justify="end">
                <CommentOutlined
                  className={styles.fieldNotesBtn}
                  onClick={() => showNotesDrawer()}
                />
              </Row>
            )}
          </Col>
        </Row>
        {isNotesDrawerShown && (
          <SurveyNotesDrawer
            visible={isNotesDrawerShown}
            notes={
              fieldNotes?.filter((note) =>
                !isUnitId
                  ? note.field === fieldName
                  : note.field === fieldName + "_unit_id"
              ) || []
            }
            onClose={hideNotesDrawer}
            onSubmit={handleAddFieldNote}
          />
        )}
        <RichTextModal
          fieldName={schema.entity_name}
          fieldDisplayName={schema.display_name}
          data={fieldValue as string}
          visible={isRichTextModalVisible}
          onVisibleChange={setIsRichTextModalVisible}
          isViewOnly={!isFieldEditable}
          onConfirm={handleEditRichText}
        />
      </>
    );
  }
);
