import React, { useState } from "react";
import { Col, Row, message, Tabs } from "antd";
import { PageWrap, StyledTabs } from "../../../shared/commonStyles";
import ProfileDetail from "./components/ProfileDetail";
import CompanyDetail from "./components/CompanyDetail";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import PlatformSettings from "./components/PlatformSettings";
import { CarbonSettings } from "./components/CarbonSettings";

const { TabPane } = Tabs;
const UserAccountPage = () => {
  const { checkPermissions } = useCheckRolesPermissions();

  const [selectedTab, setSelectedTab] = useState("profile_info");
  const onTabChange = (selectedTabKey) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };
  return (
    <PageWrap>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledTabs activeKey={selectedTab} onChange={onTabChange}>
            <TabPane tab="Profile Information" key="profile_info" />
            {checkPermissions(["settings-staff-page"]) && (
              <>
                <TabPane tab="Company Information" key="company_info" />
                <TabPane tab="Platform settings" key="platform_settings" />
                <TabPane tab="Carbon settings" key="carbon_settings" />
              </>
            )}
          </StyledTabs>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          {selectedTab === "profile_info" && <ProfileDetail />}
          {checkPermissions(["settings-staff-page"]) &&
            selectedTab === "company_info" && <CompanyDetail />}
          {checkPermissions(["settings-staff-page"]) &&
            selectedTab === "platform_settings" && <PlatformSettings />}
          {checkPermissions(["settings-staff-page"]) &&
            selectedTab === "carbon_settings" && <CarbonSettings />}
        </Col>
      </Row>
    </PageWrap>
  );
};

export default UserAccountPage;
