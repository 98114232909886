import { GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { makeAutoObservable, runInAction } from "mobx";
import { DatalakeQuery } from "../../../graphql/API";
import { datalake } from "../../../graphql/queries";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";

class UnitsManagementStore {
  unitGroups: UnitGroup[];
  unitGroup: UnitGroup;
  units: Unit[];
  unit: Unit;
  loadingUnitGroups: boolean;
  loadingUnits: boolean;

  constructor() {
    this.unitGroups = [];
    this.unitGroup = {} as UnitGroup;
    this.units = [];
    this.unit = {} as Unit;
    this.loadingUnitGroups = false;
    this.loadingUnits = false;

    makeAutoObservable(this);
  }

  fetchUnitGroups() {
    this.loadingUnitGroups = true;
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_unit_groups",
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((data) => {
      const response = JSON.parse(data.data?.datalake || "{}");
      runInAction(() => {
        this.unitGroups = response || [];
        this.loadingUnitGroups = false;
      });
    });
  }

    // Function to find matching symbols in the same units array
  findMatchingSymbolsInsideSameUnitGroup(symbol1: string, symbol2: string): Unit[] | undefined  {
    let matchingUnits: Unit[] | undefined;

    // Iterate over unitGroups to find a match
    this.unitGroups.some((unitGroup) => {
      // Check if both symbols exist in the units array of this unitGroup
      const symbol1Exists = unitGroup.units.some((unit) => unit.symbol === symbol1);
      const symbol2Exists = unitGroup.units.some((unit) => unit.symbol === symbol2);

      // If both symbols exist in the same unitGroup, return the units
      if (symbol1Exists && symbol2Exists) {
        //matchingUnits = unitGroup.units.filter((unit) => unit.symbol === symbol1 || unit.symbol === symbol2);
        const matchingUnit1: Unit | undefined = unitGroup.units.find(unit => unit.symbol === symbol1);

        // Filter for symbol2 (excluding units already matched for symbol1)
        const matchingUnit2: Unit | undefined = unitGroup.units.find(unit => unit.symbol === symbol2);

        // Combine into an array if both units were found
        if (matchingUnit1 && matchingUnit2) {
          matchingUnits = [matchingUnit1, matchingUnit2];
          return true; // Exit the loop
        }
      }

      return false; // Continue to next unitGroup
    });

    return matchingUnits;
  };

  addUnitGroup(unitGroup: UnitGroup) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "add_unit_group",
          request_payload: JSON.stringify({
            name: unitGroup.name,
            description: unitGroup.description,
          }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchUnitGroups();
      });
    });
  }

  getUnitGroupByName(name: string) {
    this.loadingUnits = true;
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_unit_groups",
          request_payload: JSON.stringify({ filter: { name } }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((data) => {
      const response = JSON.parse(data.data?.datalake || "{}");
      const units = response[0].units;
      runInAction(() => {
        this.units = units || [];
        this.unitGroup = response[0];
        this.loadingUnits = false;
      });
    });
  }

  updateUnitGroup(group: UnitGroup) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "update_unit_group",
          request_payload: JSON.stringify(group),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.fetchUnitGroups();
      });
    });
  }

  removeUnitGroup(group: UnitGroup) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "remove_unit_group",
          request_payload: JSON.stringify({ _id: group._id }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then(() => {
      runInAction(() => {
        this.fetchUnitGroups();
      });
    });
  }

  addUnit(groupName: string, unit: Unit) {
    const unitKey = uuidv4();
    const unitData = { ...unit, key: unitKey };
    const payload = !unitData.is_base_unit
      ? unitData
      : ({ ...unitData, base_unit: unitKey, multiplication_factor: 1 } as Unit);
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "add_unit",
          request_payload: JSON.stringify({
            group_name: groupName,
            unit: payload,
          }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.getUnitGroupByName(groupName);
      });
    });
  }

  getUnitByKey(key: string) {
    this.unit = this.units.find((item) => item.key === key) || ({} as Unit);
    return this.unit;
  }

  editUnit(unit: Unit) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "update_unit",
          request_payload: JSON.stringify({
            unit_group_id: this.unitGroup._id.$oid,
            unit: unit,
          }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then((response) => {
      runInAction(() => {
        const result = JSON.parse(response?.data?.datalake as string);
        const isError = result?.statusCode >= 400;
        if (isError) {
          message.error(result?.body);
        }
        this.getUnitGroupByName(this.unitGroup.name);
      });
    });
  }

  removeUnit(unitKey: string) {
    (
      API.graphql(
        graphqlOperation(datalake, {
          request_type: "remove_unit",
          request_payload: JSON.stringify({
            unit_group_id: this.unitGroup._id.$oid,
            unit_key: unitKey,
          }),
        })
      ) as Promise<GraphQLResult<DatalakeQuery>>
    ).then(() => {
      runInAction(() => {
        this.getUnitGroupByName(this.unitGroup.name);
      });
    });
  }
}

const unitsManagementStore = new UnitsManagementStore();

export { unitsManagementStore };
