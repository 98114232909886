import React, { ChangeEvent, useEffect, useState } from "react";
import { useBoolean } from "ahooks";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Slider,
  Row,
  Col,
  InputNumber,
  Switch,
} from "antd";
import moment from "moment";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { RegionsSelectorCheckbox } from "../../../../../components/RegionsSelectorCheckbox";
import { SelectedItems } from "../../../../../components/SelectedItems";
import { carbonStore } from "../../../../Carbon";
import styles from "./EditCalculationModal.module.scss";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { EmissionsDrillDownConfigure } from "../EmissionsDrillDownConfigure/EmissionsDrillDownConfigure";
import { disallowedNameKeys } from "../../../../Carbon/CalculationUtils";

interface EditCalculationModalProps {
  data: EditCalculationData;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: EditCalculationData) => void;
}

export const EditCalculationModal = ({
  data,
  isOpen,
  onClose,
  onConfirm,
}: EditCalculationModalProps) => {
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [attributionFactor, setAttributionFactor] = useState(
    data.attribution_factor
  );
  const [isCheckedFilters, setIsCheckedFilters] = useState(
    data.isCheckedFilters
  );

  const [isEnabledDrillDown, setIsEnabledDrillDown] = useState(
    data?.isDrilldownEnabled
  );
  const [drilldownFilters, setDrilldownFilters] = useState(
    data?.drilldownFilters
  );
  const [drillDownModal, setDrillDownModal] = useState(false);
  const [date, setDate] = useState<RangeValue>([
    data.date_start
      ? moment(
          (data.date_start as { $date: string })?.$date ||
            (data.date_start as string)
        )
      : null,
    data.date_end
      ? moment(
          (data.date_end as { $date: string })?.$date ||
            (data.date_end as string)
        )
      : null,
  ]);
  const [regions, setRegions] = useState<SelectionItem[]>(
    [] as SelectionItem[]
  );

  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  useEffect(() => {
    let isMounted = true;
    carbonStore.fetchCategories({}, "region_name", () => {
      isMounted &&
        setRegions(
          carbonStore.ghgCategories.region_name?.map((item, index) => {
            return {
              key: item + index,
              name: item,
              checked: !!data.regions.find((v) => v.name === item),
            };
          })
        );
    });
    return () => {
      isMounted = false;
    };
  }, []);
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleChangeDate = (value: RangeValue) => {
    setDate(value);
  };

  const onChangeRegion = (regions: SelectionItem[]) => {
    setRegions(regions);
  };
  const onChangeSelectionRegion = (value: string) => {
    const newRegions = regions.map((item) =>
      item.key === value ? { ...item, checked: false } : item
    );
    onChangeRegion(newRegions);
  };

  const handleClose = async () => {
    hideModal();
    setDate([
      data.date_start
        ? moment(
            (data.date_start as { $date: string })?.$date ||
              (data.date_start as string)
          )
        : null,
      data.date_end
        ? moment(
            (data.date_end as { $date: string })?.$date ||
              (data.date_end as string)
          )
        : null,
    ]);
    setRegions(
      carbonStore.ghgCategories.region_name?.map((item, index) => {
        return {
          key: item + index,
          name: item,
          checked: !!data.regions.find((v) => v.name === item),
        };
      })
    );
    setDrilldownFilters(data?.drilldownFilters);
    setIsEnabledDrillDown(data?.isDrilldownEnabled);
    setName(data.name);
    setDescription(data.description);
    setAttributionFactor(data.attribution_factor);
    setIsCheckedFilters(data.isCheckedFilters);
    onClose();
  };

  const handleConfirm = async () => {
    await hideModal();
    onConfirm({
      name,
      description,
      date_start: date?.[0] || moment(),
      date_end: date?.[1] || moment(),
      regions: regions.filter((v) => v.checked),
      attribution_factor: attributionFactor,
      isCheckedFilters: isCheckedFilters,
      isDrilldownEnabled: isEnabledDrillDown,
      drilldownFilters,
    });
    setIsCheckedFilters(false);
  };

  const dateFormat = "YYYY-MM-DD";

  const getDatePanel = (panelNode: React.ReactNode) => {
    return (
      <>
        <p className={styles.dateRangeTitle}>Time period</p>
        <div className={styles.dateRangeResult}>
          <span>{date?.[0]?.format(dateFormat)}</span> -{" "}
          <span>{date?.[1]?.format(dateFormat)}</span>
        </div>
        <div className={styles.dateRangeSelector}>{panelNode}</div>
        <div className={styles.footerDateRangeButtons}>
          <StyledButton type="primary" onClick={hideModal}>
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <>
      <EmissionsDrillDownConfigure
        visible={drillDownModal}
        data={drilldownFilters}
        onClose={() => setDrillDownModal(false)}
        onConfirm={setDrilldownFilters}
      />
      <CommonModal
        onCancel={handleClose}
        onOk={handleConfirm}
        title="Edit calculation"
        visible={isOpen}
        okText="Edit Calculation"
        footer={[
          <Button type="text" key="cancel" onClick={handleClose}>
            Cancel
          </Button>,
          <Button type="primary" key="confirm" onClick={handleConfirm}>
            Edit Calculation
          </Button>,
        ]}
        width={"60%"}
      >
        <FormWrapper className={styles.inputWrap}>
          <div className={styles.inputBlock}>
            <div>Name</div>
            <Input
              disabled={data.isLocked}
              className={styles.inputSuccessStep}
              placeholder="Name your calculation"
              value={name}
              onKeyDown={(e) => {
                if (disallowedNameKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={handleChangeName}
            />
          </div>

          <div className={styles.inputBlock}>
            <div>Description</div>
            <Input
              disabled={data.isLocked}
              className={styles.inputSuccessStep}
              placeholder="Enter description"
              value={description}
              onChange={handleChangeDescription}
            />
          </div>
          <div className={styles.inputBlock}>
            <div>Time period</div>
            <DatePicker.RangePicker
              disabled={data.isLocked}
              className={`${styles.datePicker} ${styles.datePickerEdit}`}
              open={isModalVisible}
              onFocus={showModal}
              onChange={handleChangeDate}
              panelRender={getDatePanel}
              placeholder={["Date", "Date"]}
              dropdownClassName={styles.dateRangeBox}
              format={dateFormat}
              value={date}
              ranges={{
                "Date Presets": [moment(), moment()],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "Last Year": [
                  moment().startOf("year").subtract(1, "year"),
                  moment().endOf("year").subtract(1, "year"),
                ],
                Custom: [moment(), moment()],
              }}
            />
            <div className={styles.checkBox}>
              <Checkbox
                disabled={data.isLocked}
                checked={isCheckedFilters}
                onChange={(e) => setIsCheckedFilters(e.target.checked)}
              >
                Apply the date range to the filters of the automated emissions
              </Checkbox>
            </div>
          </div>
          <div className={styles.inputBlock}>
            <div>Regions</div>
            <RegionsSelectorCheckbox
              data={regions}
              onChange={onChangeRegion}
              disabled={data.isLocked}
            />
            <SelectedItems
              data={regions}
              datatype={"regions"}
              onChange={(_, _i, value) =>
                !data.isLocked && onChangeSelectionRegion(value)
              }
            />
          </div>
          <div className={styles.inputBlock}>
            <div>Attribution factor</div>
            <Row gutter={[24, 0]} justify="space-between">
              <Col span={18}>
                <Slider
                  min={0}
                  max={100}
                  marks={{
                    0: 0,
                    100: 100,
                  }}
                  className={styles.slider}
                  onChange={setAttributionFactor}
                  value={attributionFactor}
                />
              </Col>
              <Col span={6}>
                <InputNumber
                  disabled={data.isLocked}
                  min={1}
                  max={100}
                  className={styles.sliderInput}
                  value={attributionFactor}
                  onChange={setAttributionFactor}
                  addonAfter={"%"}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={10}>
              Emissions Drill Down <InfoCircleOutlined />
            </Col>
            <Col>
              <Switch
                checked={isEnabledDrillDown}
                onChange={(checked) => setIsEnabledDrillDown(checked)}
              />{" "}
              {isEnabledDrillDown ? "Active" : "Inactive"}
            </Col>
          </Row>
          {isEnabledDrillDown && (
            <StyledButton onClick={() => setDrillDownModal(true)}>
              Configure <RightOutlined />
            </StyledButton>
          )}
        </FormWrapper>
      </CommonModal>
    </>
  );
};
