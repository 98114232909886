import React, { Fragment, useEffect, useState, useRef } from "react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import { Viewer, Designer } from "@grapecity/activereports-react";
import { PageWrap } from "../../shared/commonStyles";
import { Core } from "@grapecity/activereports";
import { useParams, useLocation } from "react-router-dom";
import { getReportById, updateSavedReports } from "../../services/reports";
import { Spin, message } from "antd";

Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY);

const ReportViewerDetail = () => {
  const { report_id: reportId } = useParams();
  const [rowData, setRowData] = useState(null);
  const [loadingReports, setLoadingReports] = useState(false);
  const [designerVisible, setDesignerVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const viewerRef = useRef();
  const { state } = useLocation();
  const getReportData = async () => {
    try {
      setLoadingReports(true);
      const selectedReport = await getReportById(reportId);
      setSelectedReport(selectedReport);
      setRowData(selectedReport?.report_data?.definition);
    } catch (error) {
      message.error(`Error while getting reports : ${error}`);
    } finally {
      setLoadingReports(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      getReportData();
    }
  }, [reportId]);

  useEffect(() => {
    if (state?.action === "update") {
      setDesignerVisible(true);
    }
  }, [state]);

  const onSaveReport = async (info) => {
    console.log("info", info);
    try {
      const payload = {
        _id: { $oid: info.id },
        report_data: info,
      };
      const response = await updateSavedReports(payload);

      return {
        id: response?.$oid,
        displayName: info.displayName,
      };
    } catch (error) {
      console.log("Error while saving report !", error);
    }
  };

  function updateToolbar() {
    const designButton = {
      key: "$openDesigner",
      text: "Edit in Designer",
      iconCssClass: "mdi mdi-pencil",
      enabled: true,
      action: () => {
        setDesignerVisible(true);
      },
    };
    viewerRef.current.toolbar.addItem(designButton);
    viewerRef.current.toolbar.updateLayout({
      default: [
        "$openDesigner",
        "$split",
        "$navigation",
        "$split",
        "$refresh",
        "$split",
        "$history",
        "$split",
        "$zoom",
        "$fullscreen",
        "$split",
        "$print",
        "$split",
        "$singlepagemode",
        "$continuousmode",
        "$galleymode",
      ],
    });
  }

  function onReportPreview(report) {
    updateToolbar();
    setDesignerVisible(false);
    viewerRef.current.open(report.definition);
    return Promise.resolve();
  }

  return (
    <PageWrap>
      <Spin spinning={loadingReports}>
        <div style={{ width: "100%", height: "80vh" }}>
          <Fragment>
            <div
              id="designer-host"
              style={{
                display: designerVisible ? "block" : "none",
                height: "100%",
              }}
            >
              <Designer
                report={{
                  definition: selectedReport?.report_data?.definition,
                  displayName: selectedReport?.report_data?.displayName,
                  id: selectedReport?._id?.$oid,
                }}
                onSave={onSaveReport}
                onSaveAs={onSaveReport}
                onRender={onReportPreview}
                viewMode="Continuous"
              />
            </div>
            <div
              id="viewer-host"
              style={{
                display: designerVisible ? "none" : "block",
                height: "100%",
              }}
            >
              {rowData && <Viewer ref={viewerRef} report={{ Uri: rowData }} />}
            </div>
          </Fragment>
        </div>
      </Spin>
    </PageWrap>
  );
};
export default ReportViewerDetail;
