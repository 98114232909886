import {
  Alert,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";
import { StyledDivider } from "../../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";

interface EmailTemplateProps {
  form: FormInstance;
  onChangeEmailBody: () => void;
}

export const EmailTemplate = ({
  form,
  onChangeEmailBody,
}: EmailTemplateProps) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Typography.Title level={3}>Email Template</Typography.Title>
        <Form.Item name="email_type" label=" " labelCol={{ span: 24 }}>
          <Radio.Group
            style={{ marginTop: "5px" }}
            value={form.getFieldValue("email_type")}
          >
            <Space direction="vertical" size="middle">
              <Row>
                <Col>
                  <Radio value="Primary">Primary Email</Radio>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio value="Reminder">Reminder Email</Radio>
                </Col>
              </Row>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Typography.Title level={4}>Email Settings</Typography.Title>
        <StyledDivider />
        <Form.Item
          labelCol={{ span: 24 }}
          label="Email Subject"
          name={
            form.getFieldValue("email_type") === "Primary"
              ? "email_subject"
              : "email_reminder_subject"
          }
          rules={[
            {
              required: true,
              message: "Please enter an email subject",
            },
          ]}
        >
          <Input placeholder="Please enter an email subject" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Typography.Title level={4}>Email Body</Typography.Title>
        <StyledDivider />
        <Alert
          message="Email Placeholder Options"
          description="There are 3 placeholders currently available: {SIGNUP_LINK}, {SIGNIN_LINK} and {FULL_NAME}. {SIGNUP_LINK} will send the user a sign up link if they do not exist. {SIGNIN_LINK} takes an existing user to sign in immediately and {FULL_NAME} is the user's full display name and both should be used only when the user already exists. The placeholders will be autofilled when the emails are sent and can be used in links as well."
          type="warning"
          showIcon
        />
        <Typography.Title
          style={{ fontSize: "14px" }}
        >{`Email Data`}</Typography.Title>
        <div className="ckMediumHeight">
          <CKEditor
            editor={DecoupledDocumentEditor}
            data={
              form.getFieldValue("email_type") === "Primary"
                ? form.getFieldValue("email_body")
                : form.getFieldValue("email_reminder_body") || ""
            }
            onReady={(editor: any) => {
              if (editor?.ui) {
                editor.ui.view.editable.element.parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.view.editable.element
                );
              }
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              const typeEmail = form.getFieldValue("email_type");

              if (typeEmail === "Primary") {
                form.setFieldsValue({ email_body: data });
              }
              if (typeEmail === "Reminder") {
                form.setFieldsValue({ email_reminder_body: data });
              }
              onChangeEmailBody();
            }}
          />
        </div>
      </Col>

      {form.getFieldValue("email_type") === "Reminder" && (
        <Col span={24}>
          <Form.Item
            name="reminder_interval"
            label="Reminder Frequency"
            labelCol={{ span: 24 }}
          >
            <Radio.Group
              style={{ marginTop: "5px" }}
              value={form.getFieldValue("reminder_interval")}
            >
              <Space direction="vertical" size="middle">
                <Row>
                  <Col>
                    <Radio value="op1">No reminder</Radio>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Radio value={1000000}>One-time reminder</Radio>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Radio value="recurring_reminder">Recurring reminder</Radio>
                  </Col>
                </Row>
              </Space>
            </Radio.Group>
          </Form.Item>
          {form.getFieldValue("reminder_interval") === "recurring_reminder" && (
            <Row
              style={{
                alignItems: "center",
                color: "white",
                marginLeft: "25px",
              }}
            >
              <Space direction="horizontal" size="small">
                <Col>Remind every</Col>
                <Col>
                  <Form.Item name="recurring_reminder_val">
                    <Input
                      maxLength={10}
                      style={{ textAlign: "center", width: "100px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>days</Col>
              </Space>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};
