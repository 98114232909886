import React from "react";
import { InputNumber, Input, Form, DatePicker } from "antd";
import { YearMonthDayFormat } from "../../../../../shared/constants";

export const EditableCell = ({
  inputType,
  dataType,
  schemaField,
  required,
  editing,
  key,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = null;
  const formItemProps = {
    name: dataIndex,
    rules: [{ required: required, message: `This field is required!` }],
    style: { margin: 0 },
  };

  if (inputType === "number") {
    inputNode = <InputNumber type="number" />;
  } else if (inputType === "string") {
    inputNode = <Input />;
  } else if (inputType === "date") {
    inputNode = (
      <DatePicker format={YearMonthDayFormat} className="clearIcon" />
    );
  } else {
    inputNode = null;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item {...formItemProps}>{inputNode}</Form.Item>
      ) : (
        children
      )}
    </td>
  );
};