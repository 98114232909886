import React, { useState, useEffect, useRef } from "react";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Spin, message, Collapse, Typography } from "antd";
import { StyledButton, StyledCollapse } from "../../../shared/commonStyles";
import ContentEditable from "react-contenteditable";
import {
  getAiResult,
  getAiRecommendations,
  addAiRecommendations,
  removeAiRecommendations,
} from "../../../services/ai";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";

const { Title } = Typography;
const DevelopmentRecommendations = () => {
  const scrollRef = useRef();
  const [loader, setLoader] = useState(false);
  const [questionLoader, setQuestionLoader] = useState(false);
  const [ansLoader, setAnsLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [aiQuestion, setAiQuestion] = useState(
    "Enter your question or request"
  );
  const [aiText, setAiText] = useState("The answer will be shown here");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState(0);

  const [txt, setTxt] = useState(null);
  const [index, setIndex] = useState("");
  const [rowData, setRowData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const speed = 10;

  const fetchResults = async () => {
    try {
      setLoader(true);
      const payload = { filter: { type: "development" } };
      const response = await getAiRecommendations(payload);
      const newData = response.reverse();
      setDataSource(newData);
    } catch (e) {
      message.error(`Some problem occured ${e}`);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [submitRefresh]);

  const handleAiQuestion = (event) => {
    const value = event.target.value;
    setAiQuestion(value);
  };
  const typeWriter = () => {
    if (index <= txt.length) {
      if (index === 1) {
        setAiText((prevState) => prevState.concat("<mark>"));
      }
      setAiText((prevState) => prevState.concat(txt.charAt(index - 1)));
      scrollRef?.current.scrollTo(0, scrollRef?.current.scrollHeight);
      if (index === txt.length) {
        setAiText((prevState) =>
          prevState.concat("</mark><div ref=" + scrollRef + " />")
        );
        setIndex("");
        setTxt(null);
        setAnsLoader(false);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const getAiData = async (question) => {
    const results = await getAiResult(question);
    return results;
  };

  const submitData = async () => {
    try {
      setQuestionLoader(true);
      if (aiQuestion && aiQuestion !== "Enter your question or request") {
        const response = await getAiData(aiQuestion);
        if (response?.choices.length > 0) {
          await Promise.all(
            await response?.choices?.map(async (item) => {
              const newText = item?.text
                ?.replace(/^\n+|\n+$/g, "")
                .replace(/(?:\r\n|\r|\n)/g, "<br>");
              setTxt(newText);
            })
          );
        }
      }
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setQuestionLoader(false);
    }
  };

  useEffect(() => {
    if (index) {
      setTimeout(typeWriter, speed);
    }
  }, [index]);

  useEffect(() => {
    if (txt) {
      setAnsLoader(true);
      setAiText("");
      setIndex(1);
    }
  }, [txt]);

  const removePlaceholderText = () => {
    if (aiQuestion === "Enter your question or request") {
      setAiQuestion("");
    }
  };

  const submitAiData = async () => {
    try {
      setSubmitLoader(true);
      if (aiText && aiText !== "The answer will be shown here") {
        const payload = {
          type: "development",
          answer: aiText?.replace("<mark>", "")?.replace("</mark>", ""),
          question: aiQuestion,
        };
        await addAiRecommendations(payload);
        setSubmitRefresh(submitRefresh + 1);
        setAiText("The answer will be shown here");
        setAiQuestion("Enter your question or request");
      }
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setSubmitLoader(false);
    }
  };
  const onDelete = (record) => {
    setRowData(record);
    setShowConfirmModal(true);
  };

  const onConfirmDelete = async () => {
    if (!rowData) return;
    try {
      setLoader(true);
      const payload = {
        _id: rowData?._id,
      };
      await removeAiRecommendations(payload);
      setSubmitRefresh(submitRefresh + 1);
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setLoader(false);
      setShowConfirmModal(false);
    }
  };
  return (
    <Spin spinning={loader}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={10}>
              <Card
                style={{
                  backgroundColor: "#2D273F",
                  padding: "20px",
                }}
                bodyStyle={{ padding: "0" }}
              >
                <ContentEditable
                  html={aiQuestion} // innerHTML of the editable div
                  disabled={questionLoader || ansLoader} // use true to disable editing
                  onChange={handleAiQuestion} // handle innerHTML change
                  onFocus={removePlaceholderText}
                  //tagName='article' // Use a custom HTML tag (uses a div by default)
                  style={{
                    border: "1px solid #A68DFB",
                    background: "#87809C",
                    color: "#fff",
                    fontSize: "14px",
                    height: "150px",
                    padding: "4px 11px",
                    overflowX: "auto",
                    width: "100%",
                  }}
                />
                <StyledButton
                  type="custom"
                  style={{ marginLeft: 0 }}
                  onClick={submitData}
                  loading={questionLoader}
                  disabled={
                    aiText === "" ||
                    aiQuestion === "Enter your question or request" ||
                    ansLoader
                  }
                >
                  Submit
                </StyledButton>
              </Card>
            </Col>
            <Col span={14}>
              <Card
                style={{
                  backgroundColor: "#3C3453",
                  padding: "20px",
                }}
                bodyStyle={{ padding: "0" }}
              >
                <Row>
                  <Col span={24}>
                    <ContentEditable
                      html={aiText} // innerHTML of the editable div
                      disabled={true} // use true to disable editing
                      style={{
                        background: "#3C3453",
                        color: "#fff",
                        fontSize: "14px",
                        height: "150px",
                        padding: "0",
                        overflowX: "auto",
                        width: "100%",
                      }}
                      innerRef={scrollRef}
                    />
                  </Col>
                  <Col span={24}>
                    <StyledButton
                      type="custom"
                      onClick={submitAiData}
                      loading={
                        aiText === "" || questionLoader || ansLoader
                          ? true
                          : submitLoader
                      }
                      disabled={
                        aiText === "" ||
                        aiText === "The answer will be shown here" ||
                        questionLoader ||
                        ansLoader
                      }
                    >
                      {aiText === "" || questionLoader || ansLoader
                        ? "Waiting"
                        : "Save"}
                    </StyledButton>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        {dataSource?.length > 0 && (
          <Col span={24}>
            <Title level={3} className="color-white">
              Saved Result
            </Title>
            <StyledCollapse
              ghost
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusCircleOutlined
                    style={{ fontSize: "20px", color: "#A68DFB" }}
                  />
                ) : (
                  <PlusCircleOutlined style={{ fontSize: "20px" }} />
                )
              }
              collapsible="header"
              defaultActiveKey={0}
            >
              {dataSource.map((item, index) => {
                return (
                  <Collapse.Panel
                    header={<span>{item?.question}</span>}
                    extra={
                      <>
                        <Row
                          gutter={[8]}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Col style={{ marginTop: "-5px" }}>
                            <StyledButton
                              type="default"
                              style={{ padding: "8px" }}
                              onClick={() => onDelete(item)}
                              icon={<DeleteOutlined />}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                    className="development"
                    key={index}
                  >
                    <div className="color-white">
                      <ContentEditable
                        html={item?.answer} // innerHTML of the editable div
                        disabled={true} // use true to disable editing
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </Collapse.Panel>
                );
              })}
            </StyledCollapse>
          </Col>
        )}
      </Row>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          visible={true}
          okText="Delete"
          onConfirm={onConfirmDelete}
          onClose={() => setShowConfirmModal(false)}
          description="Are you sure you want to delete saved result?"
          ConfirmLoader={loader}
        />
      )}
    </Spin>
  );
};

export default DevelopmentRecommendations;
