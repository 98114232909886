import React, { useEffect } from 'react';

import { message } from "antd";

import { truncateString } from "../../../shared/commonFunctions";
import { 
  StyledCard, 
  StyledCardTitle, 
  StyledCardSubtitle, 
  StyledActionButton, 
  StyledCardImgWrap 
} from  './ServiceCard.style';

const ServiceCard = ({ data }) => {
  useEffect(() => {
    message.config({
        maxCount: 1,
        duration: 6,
    });
  });

  const showMessage = () => {
    message.info({
        content: (
        <span>
            Please contact your Hydrus account manager or email{' '} 
            <a href="mailto:sales@hydrus.ai">sales@hydrus.ai</a> to request this feature.
        </span>
        )
    });
  }
  return (
    <StyledCard>
      <section>
        <StyledCardImgWrap>
          <img src={data.image_url} alt={`${data.name}-img`} />
        </StyledCardImgWrap> 
        <StyledCardTitle title={data.name}>{truncateString(data.name, 10)}</StyledCardTitle>
        <StyledCardSubtitle title={data.category}>{truncateString(data.category, 10)}</StyledCardSubtitle>
      </section>
      <StyledActionButton block type='ghost' onClick={showMessage}>Connect</StyledActionButton>
    </StyledCard>
  );
}

export default ServiceCard;
