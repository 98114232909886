import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { PageWrap, StyledProgress } from "../../../../../shared/commonStyles";
import { CreateTargets } from "./CreateTargets";
import { ScienceBasedTargetsInfo } from "./ScienceBasedTargetsInfo";
import { HeaderWhatIf } from "../../HeaderWhatIf";
import { observer } from "mobx-react-lite";
import { reductionPlanStore } from "../../ReductionPlanStore";
import { companyInfoStore } from "../../../../Carbon";
import styles from "../../WhatIfOnboarding.module.scss";

interface CustomTargetsProps {
  onBack: () => void;
}

export const CustomTargets = observer(({ onBack }: CustomTargetsProps) => {
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const handleSave = () => {
    reductionPlanStore.onboardingCustomTargets.forEach((item) => {
      reductionPlanStore.saveTarget(item);
    });
    companyInfoStore.updateOnboardingCompletion();
    navigate("/carbon/reduction-plan");
  };

  const prevStep = () => {
    setCurrent(current - 1);
  };
  const stepsComponents = [
    <ScienceBasedTargetsInfo
      key="Science based targets info"
      onNext={nextStep}
      onBack={onBack}
    />,
    <CreateTargets
      data={reductionPlanStore.onboardingCustomTargets}
      key="Custom Targets"
      title="Custom Targets"
      subtitle="Enter custom company targets"
      targetType={TargetType.CUSTOM}
      isCustomInfo
      onNext={handleSave}
      onBack={prevStep}
    />,
  ];

  return (
    <>
      <HeaderWhatIf />
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={(current + 1) * 50}
        showInfo={false}
        className={styles.progress}
      />
      <PageWrap className={styles.container}>
        <Row justify="center" align="middle">
          <Col>
            <span className={styles.welcomeText}>
              Welcome to reduction plan section!
            </span>
            <div>
              <div>{stepsComponents[current]}</div>
            </div>
          </Col>
        </Row>
      </PageWrap>
    </>
  );
});
