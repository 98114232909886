import React, { useState } from "react";
import { StyledButton } from "../../../../shared/commonStyles";
import { SendEmailModal } from "./SendEmailModal";

export const PendingPageButtons = () => {
  const [showEditor, setShowEditor] = useState(false);
  return (
    <>
      <StyledButton onClick={() => setShowEditor(true)}>
        Edit denial email template
      </StyledButton>
      <SendEmailModal
        visible={showEditor}
        onClose={() => setShowEditor(false)}
      />
    </>
  );
};
