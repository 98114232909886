import React, { useState } from "react";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AddUnitModal } from "./AddUnitModal";
import styles from "./UnitsTable.module.scss";
import { unitsManagementStore } from "../UnitsManagementStore";
import { ConfirmModal } from "../../../carbonPage/Calculator/Components/ConfirmModal";

interface UnitTableButtonsProps {
  data: Unit;
  groupName: string;
}

export const UnitTableButtons = ({
  data,
  groupName,
}: UnitTableButtonsProps) => {
  const [isAddUnitModalVisible, setAddUnitModalVisible] = useState(false);
  const [isDeleteEditUnitModalVisible, setDeleteEditUnitModalVisible] =
    useState(false);
  const [confirmModalInfo, setConfirmModalInfo] = useState<"delete" | "edit">(
    "delete"
  );

  const infoConfirmDeleteModal = {
    title: "Are you sure you want to delete this unit",
    description: "Any data using this unit might be impacted.",
    confirmText: "Delete",
    cancelText: "Cancel",
  };
  const infoConfirmEditModal = {
    title: "Are you sure you want to edit this unit",
    description: "Any data using this unit might be impacted.",
    confirmText: "Edit",
    cancelText: "Cancel",
  };

  const handleOpenConfirmModal = (info: "delete" | "edit") => {
    setConfirmModalInfo(info);
    setDeleteEditUnitModalVisible(true);
  };
  const handleOpenEditModal = () => {
    setAddUnitModalVisible(true);
    setDeleteEditUnitModalVisible(false);
  };
  const handleDeleteUnit = () => {
    unitsManagementStore.removeUnit(data.key);
    setDeleteEditUnitModalVisible(false);
  };

  return (
    <div className={styles.tableButtonWrap}>
      <Button
        className={styles.tableButton}
        onClick={() => handleOpenConfirmModal("edit")}
        disabled={!data.is_custom}
      >
        <EditOutlined className={styles.tableButtonIcon} />
        Edit
      </Button>
      <Button
        className={styles.tableButton}
        disabled={!data.is_custom}
        onClick={() => handleOpenConfirmModal("delete")}
      >
        <DeleteOutlined className={styles.tableButtonIcon} /> Delete
      </Button>
      <AddUnitModal
        visible={isAddUnitModalVisible}
        data={data}
        groupName={groupName}
        onClose={() => setAddUnitModalVisible(false)}
      />
      <ConfirmModal
        onConfirm={
          confirmModalInfo === "delete" ? handleDeleteUnit : handleOpenEditModal
        }
        visible={isDeleteEditUnitModalVisible}
        setFalse={() => setDeleteEditUnitModalVisible(false)}
        infoConfirmModal={
          confirmModalInfo === "delete"
            ? infoConfirmDeleteModal
            : infoConfirmEditModal
        }
      />
    </div>
  );
};
