import React from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";

import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Radio,
  Space,
  Select,
  Divider,
} from "antd";

const { Title } = Typography;
const { Option } = Select;
const Step1 = ({
  onClickNext,
  onClickPrevious,
  initialValues,
  dataSheetsList,
  state,
}) => {
  return (
    <FormWrap
      width="453px"
      style={{
        marginTop: "50px",
        backgroundColor: "#2D273F",
        borderRadius: "4px",
      }}
    >
      <FormWrapper style={{ margin: "20px 0px" }}>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3} style={{ fontWeight: "600" }}>
              General information
            </Title>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="import_name"
              label="Import name"
              rules={[
                { required: true, message: "Please input import name !" },
              ]}
            >
              <Input
                placeholder="Enter name"
                disabled={
                  state?.action ||
                  state?.type === "dataSheet" ||
                  state?.type === "survey" ||
                  state?.type === "pendingKpi"
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <Input
                placeholder="Enter description"
                disabled={
                  initialValues.import_status === "APPROVED" ||
                  initialValues.import_status === "REJECTED" ||
                  state?.type === "dataSheet" ||
                  state?.type === "survey" ||
                  state?.type === "pendingKpi"
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: "15px" }}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title style={{ fontSize: "18px" }}>
              Select destination data sheet
            </Title>
          </Col>
          <Divider
            style={{ background: "#3A3C5A", margin: "5px 0px 5px 0px" }}
          />
        </Row>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="sheet_id"
              label="Data Sheet"
              rules={[
                { required: true, message: "Please select data sheet !" },
              ]}
            >
              <Select
                placeholder="Select data sheet"
                disabled={
                  state?.action ||
                  state?.type === "dataSheet" ||
                  state?.type === "survey" ||
                  state?.type === "pendingKpi"
                }
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={dataSheetsList?.map((item) => {
                  return {
                    label: item.sheet_name,
                    value: item["_id"]["$oid"],
                  };
                })}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="source_file_type"
              label="Select source file type"
            >
              <Radio.Group>
                <Space
                  size="small"
                  direction="vertical"
                  value={initialValues.source_file_type}
                >
                  <Radio
                    value="structured_file"
                    disabled={
                      state?.action ||
                      state?.type === "dataSheet" ||
                      state?.type === "survey" ||
                      state?.type === "pendingKpi"
                    }
                  >
                    Csv, Excel, other structured file(s)
                  </Radio>
                  <Radio
                    value="ocr_file"
                    disabled={
                      state?.action ||
                      state?.type === "dataSheet" ||
                      state?.type === "survey" ||
                      state?.type === "pendingKpi"
                    }
                  >
                    PDF’s or images with optical character recognition (OCR)
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <StyledButton
            type="custom"
            onClick={onClickPrevious}
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="white"
            style={{ padding: "11px 5px 11px 0px" }}
          >
            <span>Cancel</span>
          </StyledButton>
          <StyledButton
            className="margin-5"
            onClick={() => onClickNext()}
            type="custom"
          >
            <span>Continue to Step 2</span>
          </StyledButton>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step1;
