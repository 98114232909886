import { RightOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import UsersGroupList from "../../goalsProgressPageNew/manageGoals/UsersGroupList";
import UsersList from "../../goalsProgressPageNew/manageGoals/UsersList";
const { Title } = Typography;
const { RangePicker } = DatePicker;

const GeneralInfo = ({
  navigate,
  onClickNext,
}) => {
  return (
  
      <FormWrap width="50%">
        <FormWrapper>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={3}>General information</Title>
              <Form.Item
                labelCol={{ span: 24 }}
                name="name"
                label="Audit name"
                rules={[
                  { required: true, message: "Please input audit name!" },
                ]}
              >
                <Input placeholder={"Enter name"} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="description"
                label="Description"
              >
                <Input placeholder={"Enter Description"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateRange"
                label="Specify a timeline for the audit"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Please select timeline!" }]}
                className="clearIcon"
              >
                <RangePicker
                  placeholder={["Start date", "End date"]}
                  style={{ background: "#625D75" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Set a priority"
                name="priority"
              >
                <Select
                  placeholder="Select priority"
                  showArrow
                  style={{ width: "100%" }}
                >
                  <Select.Option key="1" value={1}>
                    High
                  </Select.Option>
                  <Select.Option key="2" value={2}>
                    Medium
                  </Select.Option>
                  <Select.Option key="3" value={3}>
                    Low
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <UsersList />
              <UsersGroupList />
            </Col>
            <Col span={24}>
              <Form.Item>
                <StyledButton
                  onClick={() => navigate('/audit/manage')}
                  type="custom"
                  hovercolor="#A68DFB"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#fff"
                >
                  <span>Back</span>
                </StyledButton>
                <StyledButton onClick={onClickNext} type="custom">
                  <span>Continue to step 2</span> <RightOutlined />
                </StyledButton>
              </Form.Item>
            </Col>
          </Row>
        </FormWrapper>
      </FormWrap>
  );
};

export default GeneralInfo;
