import { message } from "antd";

interface Response {
  messages: { messageType: string; message: string }[];
  ids: { $oid: string }[];
}

export const getMessage = (response: Response, dataSheet: DataSheet) => {
  response.messages.map((v: any) => {
    let values: string[] = [];
    if (v?.primaryKeysValues) {
      const displayInfo = Object.entries(v.primaryKeysValues).map(
        (pair: any) => {
          const displayName: string =
            dataSheet?.sheet_schema.find(
              (item) => item.entity_name && item.entity_name === pair[0]
            )?.display_name || "";

          return ` ${displayName}: ${pair[1]}`;
        }
      );

      values = displayInfo;
    }
    const messageText = !!values.length ? `${v.message}:${values}` : v.message;
    return (
      (v.messageType == "warning" && message.warning(messageText)) ||
      (v.messageType == "success" && message.success(messageText)) ||
      (v.messageType == "info" && message.info(messageText))
    );
  });
};
