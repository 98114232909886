import React from "react";
import { StyledButton, FormWrap } from "../../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Typography } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";
import { encodeUrlName } from "../../../../shared/commonFunctions";

const { Title } = Typography;

const Step5 = ({ action, document }) => {
  const navigate = useNavigate();
  return (
    <>
      <FormWrap width="590px">
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <CheckCircleOutlined style={{ fontSize: "40px" }} />
              <Title className="color-white" level={4}>
                Document Review was{" "}
                {action === "update" ? "updated" : "created"} successfully!
              </Title>
              <p>
                <span>
                  Document was {action === "update" ? "updated" : "created"}{" "}
                  successfully! It is already available in Document Review
                  section
                </span>
              </p>
              <Row justify="space-between">
                <Col span={8}>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      navigate(
                        `/reporting/document-review/${
                          document?._id?.$oid
                        }/${encodeUrlName(document?.name)}/view`
                      )
                    }
                  >
                    <span>Open Document</span>
                  </StyledButton>
                </Col>
                <Col span={8}>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => navigate("/reporting/document-review")}
                  >
                    <span>Go to All Documents</span>
                  </StyledButton>
                </Col>
                <Col span={8}>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => {
                      navigate("/reporting/document-review", {
                        state: { showModal: true },
                      });
                    }}
                  >
                    <span>Create Document</span>
                  </StyledButton>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};

export default Step5;
