import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./SelectedItems.module.scss";

interface SelectedItemsProps {
  data: {
    key: string;
    name: string;
    checked?: boolean;
  }[];
  datatype: string;
  onChange: (checked: boolean, index: number, value: string) => void;
  isHeadTitleShow?: boolean;
}

export const SelectedItems = ({
  data,
  datatype,
  onChange,
  isHeadTitleShow = true,
}: SelectedItemsProps) => {
  const getSelectedClassname = () => {
    const isChecked = data?.some((item) => item.checked);
    return isChecked ? styles.selectedBox : styles.selectedBoxHidden;
  };

  return (
    <>
      <div className={getSelectedClassname()}>
        {isHeadTitleShow ? (
          <p className={styles.selectedPlaceholder}>Selected {datatype}</p>
        ) : null}
        <div className={styles.selectedItems}>
          {data?.map((value, index) => {
            if (value.checked) {
              return (
                <div key={value.key} className={styles.selectedCheckbox}>
                  {value.name}
                  <CloseOutlined
                    className={styles.removeIcon}
                    onClick={() => {
                      onChange(false, index, value.key);
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};
