import { LeftOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Radio, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
const { Title } = Typography;
const ReminderSettings = ({
  onClickPrevious,
  initialValues,
  onSubmitHandler,
  action,
  emailBody,
  setEmailBody,
  loader,
}) => {
  
  // Default email body
  const defaultEmailBody = `
  <p>Dear Auditor,</p>
  <p>You have been requested to perform a data audit.</p>
  <p>This audit is due by the specified due date.</p>
  <p>Please contact your administrator for any questions that you may have.</p>
  <p>Thank you,<br/>Your Company Management.</p>
  `;

  // Check if the emailBody is not set and then initialize with the default message
  useEffect(() => {
    if (!emailBody) {
      setEmailBody(defaultEmailBody);
    }
  }, [emailBody, setEmailBody]);

  
  return (
    <FormWrap width="40%">
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>Reminder Settings</Title>
            <Title level={4}>Email Settings</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Email Subject"
              name="email_subject"
              rules={[
                {
                  required: true,
                  message: "Please enter an email subject",
                },
              ]}
            >
              <Input placeholder="Please enter an email subject" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>Email Body</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Title style={{ fontSize: "14px" }}>{`Email Data`}</Title>
            <div className="ckMediumHeight">
              <CKEditor
                editor={DecoupledDocumentEditor}
                data={emailBody || defaultEmailBody}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEmailBody(data);
                }}
              />
            </div>
          </Col>

          <Col span={24}>
            <Form.Item
              name="reminder_interval"
              label="Reminder Frequency"
              labelCol={{ span: 24 }}
            >
              <Radio.Group
                style={{ marginTop: "5px" }}
                value={initialValues.reminder_interval}
              >
                <Space direction="vertical" size="middle">
                  <Row>
                    <Col>
                      <Radio value="op1">No reminder</Radio>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio value={1000000}>One-time reminder</Radio>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio value="recurring_reminder">
                        Recurring reminder
                      </Radio>
                    </Col>
                  </Row>
                </Space>
              </Radio.Group>
            </Form.Item>
            {initialValues.reminder_interval === "recurring_reminder" && (
              <Form.Item name="recurring_reminder_val">
                <Row
                  style={{
                    alignItems: "center",
                    color: "white",
                    marginLeft: "25px",
                  }}
                >
                  <Space direction="horizontal" size="small">
                    <Col>Remind every</Col>
                    <Col>
                      <Input
                        maxLength={10}
                        value={initialValues.recurring_reminder_val}
                        style={{ textAlign: "center", width: "100px" }}
                      />
                    </Col>
                    <Col>days</Col>
                  </Space>
                </Row>
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item>
              <StyledButton
                className="margin-5"
                onClick={() => onClickPrevious()}
                type="custom"
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <LeftOutlined /> <span>Back</span>
              </StyledButton>
              <StyledButton loading={loader} onClick={onSubmitHandler} type="custom">
               {action === "create" ? "Finish" : "Update" }
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default ReminderSettings;
