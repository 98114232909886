import React, { useEffect, useState } from "react";
import { Typography, Row, Col, message, Spin, Card } from "antd";
import { CommonModal } from "../../../../shared/commonStyles";
import { getGoalSamples } from "../../../../services/goals";
import { sdgGoalsStaticList } from "../../../../shared/constants";

const { Title } = Typography;

const IndicatorModal = ({ visible, onClose, rowData }) => {
  const [loader, setLoader] = useState(false);
  const [sdgGoal, setSdgGoal] = useState(null);
  const [indicators, setIndicators] = useState([]);

  const loadData = async () => {
    try {
      setLoader(true);
      const selectedSdgGoal = sdgGoalsStaticList.find(
        (item) => item?.goal === rowData?.sdg_num
      );
      setSdgGoal(selectedSdgGoal);
      const goalSamples = await getGoalSamples();
      const indicatorArray = [];
      const indicators = JSON.parse(rowData?.sdg_indicators);
      indicators[0]?.subGoals?.forEach((item) => {
        const selectedGoal = goalSamples?.find(
          (goal) => goal?._id?.$oid === item
        );
        indicatorArray.push(
          `Target ${selectedGoal?.target} - ${selectedGoal?.target_description}`
        );
      });
      //if (Object.keys(indicators[1]).length > 0) {
        indicators[1]?.subGoalsInitiative?.forEach((item) => {
          const selectedGoal = goalSamples?.find(
            (goal) => goal?._id?.$oid === item
          );
          indicatorArray.push(
            `Target ${selectedGoal?.target} - ${selectedGoal?.target_description}`
          );
        });
      //}

      setIndicators(indicatorArray);
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadData();
  }, [rowData?.sdg_indicators]);

  return (
    <CommonModal
      width={500}
      title="Selected Indicators"
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
    >
      <Spin spinning={loader}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={4}>{`${sdgGoal?.goal}-${sdgGoal?.title}`}</Title>
          </Col>
          {indicators?.length > 0 && (
            <>
              {indicators.map((indicator, index) => {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#443A59",
                        color: "#fff",
                      }}
                      bodyStyle={{ padding: "15px" }}
                    >
                      {indicator}
                    </Card>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Spin>
    </CommonModal>
  );
};

export default IndicatorModal;
