import React from "react";
import {
  FormWrap,
  FormWrapper,
} from "../../../../shared/commonStyles";

import { Row, Col, Typography, Divider } from "antd";
import DocumentReviewTable from "./DocumentReviewTable";

const { Title } = Typography;

const Step2 = ({
  onClickPrevious,
  onCreateDocumentReview,
  tableData,
  action,
  loading,
}) => {
  return (
    <FormWrap width="90%">
      <FormWrapper>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3}>Table of content</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />            
            <DocumentReviewTable
              onCreateDocumentReview={onCreateDocumentReview}
              onClickPrevious={onClickPrevious}
              tableData={tableData}
              action={action}
              loading={loading}
            /> 
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step2;
