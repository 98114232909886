import React, { useState } from "react";
import { Row, Col, Tabs, Radio } from "antd";
// import styled from 'styled-components'
import ReportingPeriodDataTable from "./Components/ReportingPeriodsDataTable";
import LocationsDataTable from "./Components/LocationsDataTable";
import BusinessUnitsDataTable from "./Components/BusinessUnitsDataTable";
import {
  TabContentWrap,
  OverviewContentWrap,
  CommonRadio,  
} from "./../../../shared/commonStyles";

const SettingsPage = () => {
  const [section, setSection] = useState("business_unit");

  const onChange = (e) => {
    setSection(e.target.value);
  };

  return (
    <TabContentWrap>
      <OverviewContentWrap style={{ background: "transparent" }}>
        <Radio.Group
          value={section}
          onChange={onChange}
          style={{ marginBottom: 16 }}
          buttonStyle="solid"
          size="large"
        >
          <CommonRadio value="business_unit">Business Unit</CommonRadio>
          <CommonRadio value="location">Location</CommonRadio>
          <CommonRadio value="reporting_period">Reporting Period</CommonRadio>
        </Radio.Group>

        <Row>
          <Col lg={24} sm={24} md={24} xs={24}>
            {section === "reporting_period" && (
              <ReportingPeriodDataTable                
                section={section}
              />
            )}
            {section === "location" && (
              <LocationsDataTable               
                section={section}
              />
            )}
            {section === "business_unit" && (
              <BusinessUnitsDataTable                
                section={section}
              />
            )}
          </Col>
        </Row>
      </OverviewContentWrap>
    </TabContentWrap>
  );
};

export default SettingsPage;
