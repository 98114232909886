import React, { useState, useEffect, useRef, useCallback } from "react";
import { message, Spin, Form, Select, Col } from "antd";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";
import { datalake } from "../../graphql/queries";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { siteThemeColors } from "../../shared/colors";
import { PageWrap } from "./../../shared/commonStyles";
import { getCompanyInfo } from "../../services/companyInfo";
import {
  getAssignedIdentifiers,
  getRecordsQueryByIdentifiers,
} from "../../services/dataSheet";
import styles from "./Dashboard.module.scss";
import { getAssignedIdentifiersForDefaultMetadata } from "../../services/dataSheet";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
const DashboardChartsPage = () => {
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [selectedDashboardData, setSelectedDashboardData] = useState(null);
  const refBarChart = useRef(null);
  const { id } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  const [identifiers, setIdentifiers] = useState([]);
  const [sheetIds, setSheetIds] = useState();
  const [metaDataId, setMetadataId] = useState();
  const [chartQuery, setChartQuery] = useState({});
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [selectedValuesElevated, setSelectedValuesElevated] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isCarbon, setCarbon] = useState(false);
  const [barChart, setBarChart] = useState();
  useEffect(() => {
    try {
      const loadCompanyInfo = async () => {
        const baseUrlResponse = await getCompanyInfo();
        if (baseUrlResponse) {
          const companyDetailsData = JSON.parse(baseUrlResponse["data"]);
          setCompanyDetails(companyDetailsData);
        }
      };
      loadCompanyInfo();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sdk = new ChartsEmbedSDK({
    baseUrl: companyDetails?.chart_base_url,
  });
  const handleIdentifierChange = async (option) => {
    try {
      setSelectedValuesElevated(option);
      setSelectedValues(option);
      const request_payload = {
        metadata_sheet_id: metaDataId || "",
        sheet_ids: sheetIds || [],
        selected_identifiers: option,
      };
      if (selectedDashboardData?.is_carbon) {
        setChartQuery(
          option.length
            ? {
                "metadata_drilldown.group": { $in: option },
              }
            : {
                "metadata_drilldown.group": {
                  $in: identifiers.map((identifier) => identifier.value),
                },
              }
        );
      } else {
        const recordsQueryResponse = await getRecordsQueryByIdentifiers(
          request_payload
        );
        setChartQuery(recordsQueryResponse || {});
      }
    } catch (err) {
      console.error("error with Identifiers");
    }
  };
  const loadData = async (id) => {
    try {
      const response = await API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_dashboard_charts",
        })
      );
      const records = JSON.parse(response["data"]["datalake"]);
      if (records?.data?.length) {
        const findRecord = records.data.find((item) => item._id.$oid === id);
        console.log("findRecord", findRecord);
        setSheetIds(findRecord.datasheet_id);
        setCarbon(findRecord.is_carbon);
        findRecord && setSelectedDashboardData(findRecord);
        setMetadataId(findRecord.metadata_sheet_id || null);
        if (findRecord.is_carbon) {
          const defaultIdentifiers =
            await getAssignedIdentifiersForDefaultMetadata();
          setIdentifiers(
            defaultIdentifiers?.map((item) => ({
              value: item,
              label: item,
            }))
          );
          setSelectedValuesElevated(
            defaultIdentifiers?.map((item) => ({
              value: item,
              label: item,
            }))
          );
          if (checkRolesPermission(["role:elevateduser"])) {
            setChartQuery(
              defaultIdentifiers.length
                ? {
                    "metadata_drilldown.group": { $in: defaultIdentifiers },
                  }
                : {}
            );
          }
        } else {
          const identifiersResponse = await getAssignedIdentifiers(
            findRecord.metadata_sheet_id
          );
          const isError = identifiersResponse.statusCode >= 400;
          if (isError) {
            setIdentifiers([]);
          }
          if (checkRolesPermission(["role:elevateduser"])) {
            const request_payload = {
              metadata_sheet_id: findRecord.metadata_sheet_id || "",
              sheet_ids: findRecord.datasheet_id || [],
              selected_identifiers: identifiersResponse,
            };
            const recordsQueryResponse = await getRecordsQueryByIdentifiers(
              request_payload
            );
            setChartQuery(recordsQueryResponse);
          }
          if (checkRolesPermission(["role:elevateduser"])) {
            const request_payload = {
              metadata_sheet_id: findRecord.metadata_sheet_id || "",
              sheet_ids: findRecord.datasheet_id || [],
              selected_identifiers: [],
            };
            const recordsQueryResponse = await getRecordsQueryByIdentifiers(
              request_payload
            );

            setChartQuery(recordsQueryResponse);
          }
          setIdentifiers(
            identifiersResponse?.map((item) => ({
              value: item,
              label: item,
            }))
          );
          setSelectedValuesElevated(
            identifiersResponse?.map((item) => ({
              value: item,
              label: item,
            }))
          );
        }
      }
    } catch (error) {
      console.log("Some errors with loading data");
    }
  };
  useEffect(() => {
    async function load(id) {
      setDashboardLoading(true);
      try {
        await loadData(id);
      } catch (err) {
        message.error(err);
      } finally {
        setDashboardLoading(false);
      }
    }
    if (id) {
      load(id);
    }
  }, [id]);

  const renderBarChart = useCallback(
    async (ref) => {
      if (selectedDashboardData) {
        try {
          const barChart = sdk.createDashboard({
            dashboardId: selectedDashboardData?.chart_id,
            height: "90vh",
            widthMode: "scale",
            heightMode: "fixed",
            background: siteThemeColors.chartsDashboardBgColor,
            chartsBackground: "#2f2941",
            showAttribution: false,
            theme: "dark",
            filter: chartQuery,
            getUserToken: async () => {
              const data = await Auth.currentSession();
              const jwtToken = data["idToken"]["jwtToken"];
              return jwtToken;
            },
          });
          await barChart.render(ref);
          console.log("barChart", barChart);
        } catch (e) {
          console.error("error while render chart", e);
          message.error("Error while render chart!");
        }
      }
    },
    [selectedDashboardData, chartQuery]
  );

  const setRefBarChart = useCallback(
    (ref) => {
      if (ref) {
        renderBarChart(ref);
      }
      refBarChart.current = ref;
    },
    [renderBarChart]
  );

  return (
    <>
      <Spin spinning={dashboardLoading}>
        <PageWrap style={{ minHeight: "70vh" }}>
          <Form autoComplete="off">
            {(selectedDashboardData?.is_carbon ||
              selectedDashboardData?.metadata_sheet_id) && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <span className="color-white">
                    Identifier Name
                    <p></p>
                  </span>
                }
                name="datasheet"
              >
                <Col span={5} className={styles.select}>
                  <Select
                    value={
                      checkRolesPermission(["role:elevateduser"])
                        ? selectedValuesElevated
                        : checkRolesPermission(["role:elevateduser"]) &&
                          identifiers.length === 1
                        ? identifiers[0]
                        : selectedValues
                    }
                    disabled={
                      checkRolesPermission(["role:elevateduser"]) &&
                      identifiers.length === 1
                        ? true
                        : false
                    }
                    showSearch
                    allowClear
                    showArrow
                    onChange={handleIdentifierChange}
                    mode="multiple"
                    selectionItemContent="black"
                    style={{ width: "25%" }}
                    placeholder="Select identifier"
                    options={identifiers}
                  />
                </Col>
              </Form.Item>
            )}
          </Form>
          {checkRolesPermission(["role:elevateduser"]) &&
          identifiers.length === 0 &&
          isCarbon ? (
            <div className={styles.emptyContainer}>
              <h2 className={styles.emptyTitle}>
                No corresponding data to display for the dashboard.
              </h2>
            </div>
          ) : (
            <div
              id="dashboard-charts"
              style={{ width: "100%", height: "100%" }}
              ref={setRefBarChart}
            />
          )}
        </PageWrap>
      </Spin>
    </>
  );
};

export default DashboardChartsPage;
