import {
  Button,
  Col,
  Divider,
  Form,
  Progress,
  Row,
  Steps,
  message,
} from "antd";
import {
  FormWrapper,
  StyledButton,
  StyledProgress,
  StyledSteps,
} from "../../../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import { getSheetById, updateDataSheet } from "../../../../services/dataSheet";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { DataSheetFilters } from "../../../../components/DataSheetFilters/DataSheetFilters";
import { ScoreFormItem } from "./ScoreFormItem";
import { StepperFormWrap } from "../../../carbonPage/styles";
import { purple } from "@ant-design/colors";
import styles from "./SetupDataQualityScore.module.scss";
import { encodeUrlName } from "../../../../shared/commonFunctions";

export const SetupDataQualityScore = () => {
  const [form] = Form.useForm();
  const { data_sheet_id } = useParams();
  const navigate = useNavigate();
  const [dataSheet, setDataSheet] = useState<DataSheet>();
  const [initialFilters, setInitialFilters] = useState({
    highScore: [],
    lowScore: [],
    mediumScore: [],
  });
  useEffect(() => {
    const empty = [{ column: null, op: null }];
    getSheetById(data_sheet_id).then((res) => {
      setDataSheet(res);

      const highScore = res?.dataQualityScoring?.highScore;
      const lowScore = res?.dataQualityScoring?.lowScore;
      const mediumScore = res?.dataQualityScoring?.mediumScore;
      const initialValues = {
        highScore: highScore?.length ? highScore : empty,
        lowScore: lowScore?.length ? lowScore : empty,
        mediumScore: mediumScore?.length ? mediumScore : empty,
      };
      setInitialFilters(initialValues);
      form.setFieldsValue(initialValues);
    });
  }, []);

  const onFinishForm = async (values: any) => {
    const isValuesFilled =
      values.highScore.length !== 0 ||
      values.lowScore.length !== 0 ||
      values.mediumScore.length !== 0;

    try {
      if (dataSheet) {
        const payload = {
          _id: dataSheet._id,
          sheet_name: dataSheet.sheet_name,
          sheet_type: dataSheet.sheet_type,
          metadata_sheet_id: dataSheet.metadata_sheet_id,
          description: dataSheet.description,
          sheet_schema: dataSheet.sheet_schema,
          archive: dataSheet.archive,
          enable_comments: dataSheet.enable_comments,
          enable_change_history: dataSheet.enable_change_history,

          dataQualityScoring: isValuesFilled ? values : {},
        };

        updateDataSheet(payload);
      }
    } catch (error) {
      message.error("Error while updating data sheet!");
      console.log("Error while updating data sheet!", error);
    } finally {
      message.success("Data quality score was successfully set up!!");
      navigate(
        `/data/data-sheets/${data_sheet_id}/${encodeUrlName(
          dataSheet?.sheet_name as string
        )}/documents`
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      disabled={!dataSheet}
      onFinish={onFinishForm}
    >
      {({ lowScore, mediumScore, highScore }: any) => {
        return (
          <>
            <Row justify="space-between" className={styles.updateHeader}>
              <Col lg={1} md={4} sm={7} xs={10}>
                <StyledButton
                  style={{ zIndex: "1" }}
                  className="margin-5"
                  type="custom"
                  onClick={() => navigate(-1)}
                  hovercolor="#A68DFB"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#fff"
                >
                  {" "}
                  <span>
                    {" "}
                    <ArrowLeftOutlined /> Exit
                  </span>
                </StyledButton>
              </Col>
              <Col
                lg={22}
                md={19}
                sm={16}
                xs={13}
                className="user-flow-steps-wrap color-white"
              >
                <StyledSteps current={0}>
                  <Steps.Step
                    key="Setup data quality scoring "
                    title="Setup data quality scoring"
                  />
                </StyledSteps>
              </Col>
            </Row>
            <StyledProgress
              strokeLinecap="square"
              strokeWidth={10}
              showInfo={false}
              className={styles.progress}
            />
            <FormWrapper>
              <div className={styles.stepperWrap}>
                <StepperFormWrap style={{ width: "600px" }}>
                  <h2 className={styles.stepTitle}>
                    Setup data quality scoring{" "}
                  </h2>
                  <h4 className={styles.stepSubtitle}>
                    {"Low "}
                    <Progress
                      style={{ marginLeft: 5 }}
                      percent={30}
                      steps={3}
                      strokeColor={[purple[2], purple[3], purple[4]]}
                      trailColor="#4E4268"
                      showInfo={false}
                    />
                  </h4>
                  <Divider className={styles.divider} />
                  <DataSheetFilters
                    initialFilters={initialFilters.lowScore}
                    listName="lowScore"
                    score={lowScore}
                    formFilters={form.getFieldsValue().lowScore}
                    dataSheet={dataSheet || ({} as DataSheet)}
                    addButtonText="Add rule"
                  />{" "}
                  <h4 className={styles.stepSubtitle}>
                    {"Medium "}
                    <Progress
                      style={{ marginLeft: 5 }}
                      percent={60}
                      steps={3}
                      strokeColor={[purple[2], purple[3], purple[4]]}
                      trailColor="#4E4268"
                      showInfo={false}
                    />
                  </h4>
                  <Divider className={styles.divider} />
                  <DataSheetFilters
                    initialFilters={initialFilters.mediumScore}
                    listName="mediumScore"
                    score={mediumScore}
                    formFilters={form.getFieldsValue().mediumScore}
                    dataSheet={dataSheet || ({} as DataSheet)}
                    addButtonText="Add rule"
                  />{" "}
                  <h4 className={styles.stepSubtitle}>
                    {"High "}
                    <Progress
                      style={{ marginLeft: 5 }}
                      percent={100}
                      steps={3}
                      strokeColor={[purple[2], purple[3], purple[4]]}
                      trailColor="#4E4268"
                      showInfo={false}
                    />
                  </h4>
                  <Divider className={styles.divider} />
                  <DataSheetFilters
                    initialFilters={initialFilters.highScore}
                    listName="highScore"
                    score={highScore}
                    formFilters={form.getFieldsValue().highScore}
                    dataSheet={dataSheet || ({} as DataSheet)}
                    addButtonText=" Add rule"
                  />
                  <Row>
                    <StyledButton type="default">Cancel</StyledButton>
                    <StyledButton type="custom" htmlType="submit">
                      Setup
                    </StyledButton>
                  </Row>
                </StepperFormWrap>
              </div>
            </FormWrapper>
          </>
        );
      }}
    </Form>
  );
};
