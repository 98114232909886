import React, { useState } from "react";
import { Alert, Col, Row, Typography, Upload } from "antd";
import { InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ExcelRenderer } from "react-excel-renderer";
import {
  FormWrapper,
  StyledButton,
  StyledDivider,
} from "../../../shared/commonStyles";
import XLSX from "xlsx";
import styles from "./AddUsers.module.scss";

interface AddUsersImportProps {
  setEmails?: (data: string[]) => void;
  setImportUserData?: (data: any) => void;
  isManagement?: boolean;
}

export const AddUsersImport = ({
  setEmails,
  setImportUserData,
  isManagement,
}: AddUsersImportProps) => {
  const [files, setFiles] = useState<any[]>([]);
  const beforeUploadEmails = (file: any) => {
    setFiles([file]);
    ExcelRenderer(file, (err: any, result: any) => {
      if (err) {
        console.log("read file error : ", err);
      } else {
        const dataSource: any = [];
        result.rows.forEach((item: any, index: number) => {
          if (index !== 0) {
            let obj: any = {};
            const heads = result.rows[0];
            if (item && item?.length) {
              item.map((row: any, i: number) => {
                if (heads && heads?.length) {
                  heads.map((head: string, index: number) => {
                    if (
                      head === "Email" ||
                      head === "Email Address" ||
                      head === "email" ||
                      head === "email address"
                    ) {
                      if (i === index) {
                        obj = row || "";
                      }
                    }
                  });
                }
              });
            }

            obj?.length && dataSource.push(obj);
          }
        });

        setEmails && setEmails(dataSource);
      }
    });
  };
  const beforeUploadUserData = (file: any) => {
    setFiles((prev: any) => [...prev, file]);
    ExcelRenderer(file, (err: any, result: any) => {
      if (err) {
        console.log("read file error : ", err);
      } else {
        const usersData: any = [];

        result.rows.forEach((item: any, index: number) => {
          if (index !== 0) {
            const user: any = { fileId: file.uid };

            const heads = result.rows[0];

            if (item && item?.length) {
              item.map((row: any, i: number) => {
                if (heads && heads?.length) {
                  heads.map((head: string, index: number) => {
                    switch (head) {
                      case "name":
                        index == i && (user.name = row || "");
                      case "role":
                        index == i && (user.role = row || "");
                      case "email":
                        index == i && (user.email = row || "");
                      case "ref_data_sheet":
                        index == i && (user.ref_data_sheet = row || "");
                      case "identifier":
                        index == i && (user.identifier = row || "");
                    }
                  });
                }
              });
            }

            usersData.push(user);
          }
        });
        setImportUserData &&
          setImportUserData((prev: any[]) => [...prev, usersData].flat());
      }
    });
  };

  const handleCreateSampleExcel = () => {
    const sampleFields = [{ email: "example@mail.com" }];
    const managementSampleFields = [
      {
        name: "George",
        role: "role:admin, role:user",
        email: "example@mail.com",
        ref_data_sheet: "MetadataSheet", //id?
        identifier: "London, New York",
      },
    ];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      isManagement ? managementSampleFields : sampleFields
    );
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "SampleFile.xlsx");
  };
  const onRemoveFileFromListHandler = (file: any) => {
    isManagement &&
      setImportUserData &&
      setImportUserData((prev: any[]) =>
        prev.filter((v) => v.fileId !== file.uid)
      );

    setFiles((prev) => prev.filter((v) => v.uid !== file.uid));
  };

  return (
    <FormWrapper height={"100%"} style={isManagement && { paddingTop: "20px" }}>
      <Row justify="space-between" style={{ alignItems: "baseline" }}>
        <Col>
          <Typography.Title style={{ padding: "0px" }} level={3}>
            {" "}
            {isManagement ? "Import Users" : "Upload data"}
          </Typography.Title>
        </Col>
        <Col>
          <StyledButton
            className="margin-5"
            type="custom"
            padding={"5px 12px"}
            bgcolor={"#7F5FEE"}
            bordercolor={"#7F5FEE"}
            onClick={handleCreateSampleExcel}
          >
            Download Sample Excel
          </StyledButton>
        </Col>
      </Row>
      {isManagement && (
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Typography.Title level={4}>Upload source file</Typography.Title>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={5} className="color-grey">
            Upload file you want to add to the system
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <Alert
            message={
              isManagement
                ? "The file should contain a single column with one of the following names: Name / Role(s)(comma separated for multiple items) / Email / Ref Data Sheet(optional) / Identifier(optional)(comma separated for multiple items)."
                : "The file should contain a single column with one of the following names: Email / Email Address / email / email address"
            }
            type="warning"
            showIcon
          />
        </Col>
        <StyledDivider />
      </Row>

      <Upload.Dragger
        onRemove={onRemoveFileFromListHandler}
        multiple={isManagement}
        fileList={[]}
        customRequest={({ file, onSuccess }) => {
          onSuccess && onSuccess("ok");
        }}
        beforeUpload={isManagement ? beforeUploadUserData : beforeUploadEmails}
        maxCount={isManagement ? 10 : 1}
        className={styles.uploadBox}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined className="color-white" />
        </p>
        <p className="ant-upload-text color-white">
          Drag file to this area to upload
        </p>

        {isManagement ? (
          <p className="ant-upload-hint color-white">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        ) : (
          <p className="ant-upload-hint color-white">
            Supports a single upload. File type is restricted to the type of
            upload you have chosen
          </p>
        )}
      </Upload.Dragger>
      <Row>Or</Row>
      <Row align="top">
        <Col span={24}>
          <Upload
            multiple={isManagement}
            customRequest={({ file, onSuccess }) => {
              onSuccess && onSuccess("ok");
            }}
            fileList={files}
            beforeUpload={
              isManagement ? beforeUploadUserData : beforeUploadEmails
            }
            maxCount={isManagement ? 10 : 1}
            onRemove={onRemoveFileFromListHandler}
          >
            <StyledButton
              className="margin-5"
              type="custom"
              padding={"5px 12px"}
              bgcolor={"#7F5FEE"}
              bordercolor={"#7F5FEE"}
            >
              <PlusCircleOutlined />
              <span>Upload file</span>
            </StyledButton>
          </Upload>
        </Col>
      </Row>
    </FormWrapper>
  );
};
