import React, { useEffect, useState } from "react";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../shared/commonStyles";
import {
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { unitsManagementStore } from "../../pages/Data/UnitsManagement/UnitsManagementStore";
import { observer } from "mobx-react-lite";

interface UnitConversionsModalProps {
  data: {
    measure: string;
    customConversions?: UnitConversion[];
    index: number;
  };
  visible: boolean;
  onClose: () => void;
  onSave: (unitConversions: UnitConversion[], index: number) => void;
}
interface UnitConversion {
  unitGroup?: string;
  unit?: string;
  conversionFactor?: number;
}
interface ConversionForm {
  isEnabled?: boolean;
  unitConversions: UnitConversion[];
}

export const UnitConversionsModal = observer(
  ({ data, visible, onClose, onSave }: UnitConversionsModalProps) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<ConversionForm>();
    useEffect(() => {
      unitsManagementStore.fetchUnitGroups();
    }, []);

    useEffect(() => {
      const defaultValues = {
        isEnabled: !!data?.customConversions?.length,
        unitConversions: data?.customConversions || [],
      };
      form.setFieldsValue(defaultValues);
      setFormValues(defaultValues);
    }, [data]);

    const defaultUnitGroups = unitsManagementStore.unitGroups?.filter(
      (item) => item.name !== data.measure
    );
    const handleRemoveConversions = (
      index: number,
      callback: (index: number) => void
    ) => {
      const formValues = form.getFieldsValue();

      const newValues = formValues.unitConversions?.filter(
        (item: any, i: number) => i !== index
      );
      callback(index);

      form.setFieldsValue({ ...formValues, unitConversions: newValues });
      setFormValues({ ...formValues, unitConversions: newValues });
    };
    const handleSelectUnitGroup = (index: number) => {
      const values = form.getFieldsValue(true);
      const conversionsValues = values?.unitConversions?.map(
        (v: UnitConversion, i: number) => {
          if (index === i) {
            return {
              ...v,
              unit: null,
              conversionFactor: null,
            };
          } else {
            return v;
          }
        }
      );
      form.setFieldsValue({ ...values, unitConversions: conversionsValues });
      setFormValues({ ...values, unitConversions: conversionsValues });
    };

    const handleSaveConversions = () => {
      const isEnabled = formValues?.isEnabled;
      const conversions = isEnabled ? formValues?.unitConversions : [];
      const isValid =
        !conversions?.length ||
        conversions.every(
          (item) => item.unitGroup && item.conversionFactor && item.unit
        );
      if (isValid) {
        onSave(conversions, data.index);
        onClose();
        form.resetFields();
        setFormValues({} as ConversionForm);
      } else message.error("Please fill all fields");
    };

    return (
      <CommonModal
        centered
        visible={visible}
        onCancel={() => {
          onClose();
          form.resetFields();
        }}
        okText="Save"
        onOk={handleSaveConversions}
        title="Manage fields"
        width="50%"
      >
        <FormWrapper>
          <Form
            form={form}
            onFieldsChange={() => setFormValues(form.getFieldsValue())}
          >
            <Form.Item
              name="isEnabled"
              label="Enable multiple measures"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            {formValues?.isEnabled && (
              <Form.List name="unitConversions">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        const rowGroup = defaultUnitGroups?.find(
                          (item) =>
                            item.name ===
                            form.getFieldValue("unitConversions")?.[index]
                              ?.unitGroup
                        );
                        return (
                          <Row
                            gutter={[8, 8]}
                            justify="space-between"
                            align="bottom"
                            key={key}
                          >
                            <Col span={7}>
                              <Spin
                                spinning={
                                  unitsManagementStore.loadingUnitGroups ||
                                  !defaultUnitGroups?.length
                                }
                              >
                                <Form.Item
                                  name={[name, "unitGroup"]}
                                  label="Unit Group"
                                  labelCol={{ span: 24 }}
                                >
                                  <Select
                                    onChange={() =>
                                      handleSelectUnitGroup(index)
                                    }
                                    notFoundContent={
                                      !defaultUnitGroups?.length && (
                                        <Spin size="small" />
                                      )
                                    }
                                    options={defaultUnitGroups
                                      ?.filter(
                                        (item) =>
                                          !formValues?.unitConversions
                                            ?.filter((_, i) => i !== index)
                                            ?.find(
                                              (v) => v?.unitGroup === item?.name
                                            )
                                      )
                                      ?.map((item) => {
                                        return {
                                          label: item?.name,
                                          value: item?.name,
                                        };
                                      })}
                                  />
                                </Form.Item>
                              </Spin>
                            </Col>
                            <Col span={7}>
                              <Spin
                                spinning={
                                  unitsManagementStore.loadingUnitGroups ||
                                  !defaultUnitGroups?.length
                                }
                              >
                                <Form.Item
                                  name={[name, "unit"]}
                                  label="Unit"
                                  labelCol={{ span: 24 }}
                                >
                                  <Select
                                    disabled={
                                      !form.getFieldValue("unitConversions")?.[
                                        index
                                      ]?.unitGroup
                                    }
                                    options={rowGroup?.units?.map(
                                      (item: Unit) => {
                                        return {
                                          value: item.symbol,
                                          label: item.name,
                                        };
                                      }
                                    )}
                                  />
                                </Form.Item>
                              </Spin>
                            </Col>
                            <Col span={7}>
                              <Spin
                                spinning={
                                  unitsManagementStore.loadingUnitGroups ||
                                  !defaultUnitGroups?.length
                                }
                              >
                                <Form.Item
                                  label="Conversion factor"
                                  labelCol={{ span: 24 }}
                                  name={[name, "conversionFactor"]}
                                >
                                  <InputNumber
                                    disabled={
                                      !form.getFieldValue("unitConversions")?.[
                                        index
                                      ]?.unit
                                    }
                                  />
                                </Form.Item>
                              </Spin>
                            </Col>
                            <Col span={2}>
                              <StyledButton
                                type="default"
                                onClick={() => {
                                  handleRemoveConversions(index, () =>
                                    remove(index)
                                  );
                                }}
                                icon={<DeleteOutlined />}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <StyledButton onClick={() => add()}>
                        <PlusCircleOutlined /> Add
                      </StyledButton>
                    </>
                  );
                }}
              </Form.List>
            )}
          </Form>
        </FormWrapper>
      </CommonModal>
    );
  }
);
