import React, { useState, useEffect, useRef } from "react";
import { StyledButton } from "../../../../shared/commonStyles";
import { Spin } from "antd";
import { parseDataFromResponse } from "./util";
import { staticImagesList } from "../../../../shared/constants";
import { Auth, Storage } from "aws-amplify";
import "./style.css";
import {
  Viewer as ReportViewer,
  Designer,
} from "@grapecity/activereports-react";
import { Core } from "@grapecity/activereports";
import { fetchRecordData, makeQuery } from "../../../../services/dataSheet";
import { Width } from "devextreme-react/chart";

Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY);

const GeneratedReportUsingActiveJs = ({
  setStep,
  title,
  description,
  metricColumnTitle,
  reportingColumnTitle,
  descriptionColumnTitle,
  dataLakeMapReport,
  primary,
  secondary,
  reportTitleColor,
  reportDescriptionColor,
  tableHeaderTextColor,
  topicTextColor,
  setGeneratedReportUsingActiveJs,
  standardSheet,
  filters,
  reportColumns,
}) => {
  const [loadReportingData, setLoadingReportingData] = useState(false);
  const [reportDataForSingleTable, setReportDataForSingleTable] = useState([]);
  const [designerVisible, setDesignerVisible] = useState(true);

  const viewerRef = useRef();

  function updateToolbar() {
    const designButton = {
      key: "$openDesigner",
      text: "Edit in Designer",
      iconCssClass: "mdi mdi-pencil",
      enabled: true,
      action: () => {
        setDesignerVisible(true);
      },
    };
    viewerRef.current.toolbar.addItem(designButton);
    viewerRef.current.toolbar.updateLayout({
      default: [
        "$openDesigner",
        "$split",
        "$navigation",
        "$split",
        "$refresh",
        "$split",
        "$history",
        "$split",
        "$zoom",
        "$fullscreen",
        "$split",
        "$print",
        "$split",
        "$singlepagemode",
        "$continuousmode",
        "$galleymode",
      ],
    });
  }

  function onReportPreview(report) {
    updateToolbar();
    setDesignerVisible(false);
    viewerRef.current.open(report.definition);
    return Promise.resolve();
  }
  const [a_icon, setIcon] = useState(null);

  const getFile = async () => {
    const data = await Auth.currentSession();
    const groups = data["accessToken"]["payload"]["cognito:groups"].filter(
      (element) => element.includes("org:")
    )[0];
    const a_icon = await Storage.get(
      `logos/${groups.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
    );
    setIcon(a_icon);
  };

  const getStandardSheetRecords = async () => {
    try {
      const params = {
        pagination: {
          current: 1,
          pageSize: 10000,
        },
        conditionalFilters: filters?.conditionalFilters || [],
        sorter: { field: "key", order: "ascend" },
      };
      const recordTypes = {
        include: [],
        exclude: ["archived", "imported_pending", "pending", "denied"],
      };
      const selectedRowsFilter = filters?.selectedRows?.length
        ? {
            _id: { $in: filters?.selectedRows?.map((item) => item?._id?.$oid) },
          }
        : {};
      const payload = makeQuery(
        params,
        standardSheet,
        recordTypes,
        selectedRowsFilter
      );
      const sheetRecords = await fetchRecordData(payload);

      if (sheetRecords?.data?.length) {
        const newData = [...sheetRecords?.data];
        setLoadingReportingData(true);
        setReportDataForSingleTable([]);
        const result = newData?.map((record) => {
          const data = {
            ...record[record.sheet_name],
            specific_code: record.sheet_type,
            name: record.sheet_name,
          };
          return data;
        });
        setReportDataForSingleTable(result);
      }
      setLoadingReportingData(false);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    getFile();
    standardSheet && getStandardSheetRecords();
  }, []);

  useEffect(() => {
    if (dataLakeMapReport?.length) {
      const filteredData = dataLakeMapReport?.filter(
        (item) =>
          item.publish_status &&
          item?.user_status === "closed" &&
          item?.status === "ACTIVE"
      );
      setLoadingReportingData(true);
      setReportDataForSingleTable([]);

      filteredData.forEach((item) => {
        const data = parseDataFromResponse(item);
        if (data) {
          setReportDataForSingleTable((prev) =>
            [...prev, data].sort((a, b) => a.theme.localeCompare(b.theme))
          );
        }
      });

      setReportDataForSingleTable(filteredData);
      setLoadingReportingData(false);
    }
  }, [dataLakeMapReport]);

  const dataSource1 = () => {
    const table1 = {
      Type: "table",
      TableColumns: [
        { Type: "tablecolumn", Width: "1in" },
        { Type: "tablecolumn", Width: "1in" },
        { Type: "tablecolumn", Width: "2in" },
        { Type: "tablecolumn", Width: "4.3in" },
      ],
      Details: {
        Type: "tabledetails",
        TableRows: [
          {
            Type: "tablerow",
            Height: "0.384481in",
            TableCells: [
              {
                Item: {
                  Type: "textbox",
                  CanGrow: true,
                  Value: "=Fields!name.Value",
                  Name: "TextBox28",
                  Style: {
                    FontFamily: "Montserrat",
                    FontSize: "9pt",
                    Format: "g",
                    TextAlign: "Left",
                    VerticalAlign: "Middle",
                    PaddingLeft: "8pt",
                    PaddingRight: "4pt",
                    PaddingTop: "2pt",
                    PaddingBottom: "2pt",
                    BottomBorder: {
                      Style: "Solid",
                      Color: "Gainsboro",
                      Width: "0.25pt",
                    },
                  },
                  KeepTogether: true,
                  DataElementName: "TextBox4",
                  Width: "1in",
                  Height: "0.25in",
                },
                RowSpan: 1,
                ColSpan: 1,
                AutoMergeMode: "Never",
              },
              {
                Item: {
                  Type: "textbox",
                  CanGrow: true,
                  Value: "=Fields!description.Value",
                  Name: "TextBox29",
                  Style: {
                    FontFamily: "Montserrat",
                    FontSize: "9pt",
                    VerticalAlign: "Middle",
                    PaddingLeft: "12pt",
                    PaddingRight: "2pt",
                    PaddingTop: "2pt",
                    PaddingBottom: "2pt",
                    BottomBorder: {
                      Style: "Solid",
                      Color: "Gainsboro",
                      Width: "0.25pt",
                    },
                  },
                  KeepTogether: true,
                  DataElementName: "Start",
                  Width: "1in",
                  Height: "0.25in",
                },
                RowSpan: 1,
                ColSpan: 1,
                AutoMergeMode: "Never",
              },
              {
                Item: {
                  Type: "textbox",
                  CanGrow: true,
                  Value: "=Fields!standard_code.Value",
                  Name: "TextBox30",
                  Style: {
                    FontFamily: "Montserrat",
                    FontSize: "9pt",
                    Format: "g",
                    TextAlign: "Left",
                    VerticalAlign: "Middle",
                    PaddingLeft: "2pt",
                    PaddingRight: "2pt",
                    PaddingTop: "2pt",
                    PaddingBottom: "2pt",
                    BottomBorder: {
                      Style: "Solid",
                      Color: "Gainsboro",
                      Width: "0.25pt",
                    },
                  },
                  KeepTogether: true,
                  DataElementName: "TextBox6",
                  Width: "1in",
                  Height: "0.25in",
                },
                RowSpan: 1,
                ColSpan: 1,
                AutoMergeMode: "Never",
              },
              {
                Item: {
                  Type: "richtext",
                  MarkupType: "HTML",
                  CanGrow: true,
                  Value: "=Fields!response.Value",
                  Name: "RichText33",
                  Style: {
                    FontFamily: "Montserrat",
                    FontSize: "9pt",
                    VerticalAlign: "Middle",
                    PaddingLeft: "12pt",
                    PaddingRight: "2pt",
                    PaddingTop: "2pt",
                    PaddingBottom: "2pt",
                    BottomBorder: {
                      Style: "Solid",
                      Color: "Gainsboro",
                      Width: "0.25pt",
                    },
                  },
                  KeepTogether: true,
                  DataElementName: "Stop",
                  Width: "1in",
                  Height: "0.25in",
                },
                RowSpan: 1,
                ColSpan: 1,
                AutoMergeMode: "Never",
              },
            ],
          },
        ],
        Group: {
          PageBreakDisabled: "false",
          PrintFooterAtBottom: false,
          Name: "",
        },
        KeepTogether: false,
      },
      TableGroups: [
        {
          Type: "tablegroup",
          Group: {
            Name: "Table2_Group1",
            GroupExpressions: ["=Month(Fields!StartDate.Value)"],
            PageBreakDisabled: "false",
            PrintFooterAtBottom: false,
          },
          Header: {
            Type: "tableheader",
            TableRows: [
              {
                Type: "tablerow",
                Height: "0.488531in",
                TableCells: [
                  {
                    Item: {
                      Type: "textbox",
                      CanGrow: true,
                      Value: metricColumnTitle,
                      Name: "TextBox13",
                      Style: {
                        BackgroundColor: secondary,
                        FontFamily: "Montserrat",
                        FontSize: "9pt",
                        TextAlign: "Left",
                        VerticalAlign: "Middle",
                        Color: tableHeaderTextColor,
                        PaddingLeft: "8pt",
                        PaddingRight: "4pt",
                        PaddingTop: "2pt",
                        PaddingBottom: "2pt",
                        Border: {
                          Color: "Gainsboro",
                          Width: "0.25pt",
                        },
                        BottomBorder: { Style: "Solid" },
                      },
                      KeepTogether: true,
                      DataElementName: "TextBox24",
                      Width: "1in",
                      Height: "0.25in",
                    },
                    RowSpan: 1,
                    ColSpan: 1,
                    AutoMergeMode: "Never",
                  },
                  {
                    Item: {
                      Type: "textbox",
                      CanGrow: true,
                      Value: descriptionColumnTitle,
                      Name: "TextBox17",
                      Style: {
                        BackgroundColor: secondary,
                        FontFamily: "Montserrat",
                        FontSize: "9pt",
                        TextAlign: "Left",
                        VerticalAlign: "Middle",
                        Color: tableHeaderTextColor,
                        PaddingLeft: "12pt",
                        PaddingRight: "2pt",
                        PaddingTop: "2pt",
                        PaddingBottom: "2pt",
                        Border: {
                          Color: "Gainsboro",
                          Width: "0.25pt",
                        },
                        BottomBorder: { Style: "Solid" },
                        //"StyleName":"=\"\""
                      },
                      KeepTogether: true,
                      DataElementName: "TextBox25",
                      Width: "1.4in",
                      Height: "0.25in",
                    },
                    RowSpan: 1,
                    ColSpan: 1,
                    AutoMergeMode: "Never",
                  },
                  {
                    Item: {
                      Type: "textbox",
                      CanGrow: true,
                      Value: "CODE",
                      Name: "TextBox18",
                      Style: {
                        BackgroundColor: secondary,
                        FontFamily: "Montserrat",
                        FontSize: "9pt",
                        TextAlign: "Left",
                        VerticalAlign: "Middle",
                        Color: tableHeaderTextColor,
                        PaddingLeft: "2pt",
                        PaddingRight: "2pt",
                        PaddingTop: "2pt",
                        PaddingBottom: "2pt",
                        Border: {
                          Color: "Gainsboro",
                          Width: "0.25pt",
                        },
                        BottomBorder: { Style: "Solid" },
                        //"StyleName":"=\"\""
                      },
                      KeepTogether: true,
                      DataElementName: "TextBox26",
                      Width: "0.6in",
                      Height: "0.25in",
                    },
                    RowSpan: 1,
                    ColSpan: 1,
                    AutoMergeMode: "Never",
                  },
                  {
                    Item: {
                      Type: "textbox",
                      CanGrow: true,
                      Value: reportingColumnTitle,
                      Name: "TextBox21",
                      Style: {
                        BackgroundColor: secondary,
                        FontFamily: "Montserrat",
                        FontSize: "9pt",
                        TextAlign: "Left",
                        VerticalAlign: "Middle",
                        Color: tableHeaderTextColor,
                        PaddingLeft: "12pt",
                        PaddingRight: "2pt",
                        PaddingTop: "2pt",
                        PaddingBottom: "2pt",
                        Border: {
                          Color: "Gainsboro",
                          Width: "0.25pt",
                        },
                        BottomBorder: { Style: "Solid" },
                        //"StyleName":"=\"\""
                      },
                      KeepTogether: true,
                      DataElementName: "TextBox27",
                      Width: "1in",
                      Height: "0.25in",
                    },
                    RowSpan: 1,
                    ColSpan: 1,
                    AutoMergeMode: "Never",
                  },
                ],
              },
            ],
            RepeatOnNewPage: true,
          },
          PreventOrphanedFooter: false,
        },
      ],
      DetailDataElementOutput: "Output",
      DataSetName: "DataSet1",
      Name: "Table2",
      /*"Top":"0.9789583cm",
  "Left":"1.084792cm",*/
      Width: "2.8542in",
      Height: "1.8229in",
      Style: {
        FontFamily: "Segoe UI",
        StyleName: '=""',
      },
    };
    const tableSheetRecords = {
      Type: "table",
      Name: "TableStandardSheet",
      DetailDataElementOutput: "Output",
      TableColumns: reportColumns?.map((column, index) => ({
        Type: "tablecolumn",
        Width: `${8.3 / reportColumns?.length}in`,
      })),
      Width: "8.5in",
      DataSetName: "DataSetStandardSheetRecords",
      Style: {
        FontFamily: "Segoe UI",
        StyleName: '=""',
      },
      TableGroups: [
        {
          Type: "tablegroup",
          Group: {
            Name: "Table2_Group1",
            GroupExpressions: ["=Month(Fields!StartDate.Value)"],
            PageBreakDisabled: "false",
            PrintFooterAtBottom: false,
          },
          Header: {
            Type: "tableheader",
            TableRows: [
              {
                Type: "tablerow",
                Height: "0.488531in",
                TableCells: reportColumns?.map((column) => ({
                  Item: {
                    Type: "textbox",
                    CanGrow: true,
                    Value: column.label,
                    Name: `TextBox13${column.value}`,
                    Style: {
                      BackgroundColor: secondary,
                      FontFamily: "Montserrat",
                      FontSize: "9pt",
                      TextAlign: "Left",
                      VerticalAlign: "Middle",
                      Color: tableHeaderTextColor,
                      PaddingLeft: "8pt",
                      PaddingRight: "4pt",
                      PaddingTop: "2pt",
                      PaddingBottom: "2pt",
                      Border: {
                        Color: "Gainsboro",
                        Width: "0.25pt",
                      },
                      BottomBorder: { Style: "Solid" },
                    },
                    KeepTogether: true,
                    DataElementName: "TextBox24",
                    // Width: "1in",
                    Height: "0.25in",
                  },
                  RowSpan: 1,
                  ColSpan: 1,
                  AutoMergeMode: "Never",
                })),
              },
            ],
            RepeatOnNewPage: true,
          },
          PreventOrphanedFooter: false,
        },
      ],
      Details: {
        Type: "tabledetails",
        TableRows: [
          {
            Type: "tablerow",
            Height: "0.384481in",
            TableCells: reportColumns?.map((column) => ({
              Item: {
                Type: column.type === "rich-text" ? "richtext" : "textbox",
                ...(column.type === "rich-text" && { MarkupType: "HTML" }),
                CanGrow: true,
                Value: `=Fields!${column.value}.Value`,
                Name: `TextBox28${column.value}`,
                Style: {
                  FontFamily: "Montserrat",
                  FontSize: "9pt",
                  Format: "g",
                  TextAlign: "Left",
                  VerticalAlign: "Middle",
                  PaddingLeft: "8pt",
                  PaddingRight: "4pt",
                  PaddingTop: "2pt",
                  PaddingBottom: "2pt",
                  BottomBorder: {
                    Style: "Solid",
                    Color: "Gainsboro",
                    Width: "0.25pt",
                  },
                },
                KeepTogether: true,
                // DataElementName: "TextBox4",
                Width: "1in",
                Height: "0.25in",
              },
              RowSpan: 1,
              ColSpan: 1,
              AutoMergeMode: "Never",
            })),
          },
        ],
        Group: {
          PageBreakDisabled: "false",
          PrintFooterAtBottom: false,
          Name: "",
        },
        KeepTogether: false,
      },
    };

    const report = {
      Name: "Frontstore.rdlx",
      Type: "report",
      Body: {
        ReportItems: [standardSheet ? tableSheetRecords : table1],
        Style: { StyleName: '=""' },
      },
      PageHeader: {
        Type: "pagesection",
        Name: "PageHeader",
        Height: "3in",
        ReportItems: [
          {
            Type: "richtext",
            MarkupType: "HTML",
            Value: title,
            Name: "RichText11",
            Top: "0.7in",
            Width: "7.5in",
            Height: "0.5in",
            CanGrow: true,
            ZIndex: 22,
            Style: {
              BackgroundColor: "",
              FontFamily: "Montserrat",
              FontSize: "16pt",
              TextAlign: "Left",
              VerticalAlign: "Top",
              Color: reportTitleColor,
              PaddingLeft: "0.2in",
              StyleName: '=""',
              FontWeight: "Medium",
            },
            KeepTogether: true,
            DataElementName: "RichText1",
          },
          {
            Type: "richtext",
            MarkupType: "HTML",
            Value: description,
            Name: "RichText1",
            CanGrow: true,
            Top: "1.2in",
            Width: "7.5in",
            Height: "1.5in",
            ZIndex: 22,
            Style: {
              BackgroundColor: "",
              FontFamily: "Montserrat",
              FontSize: "12pt",
              TextAlign: "Left",
              VerticalAlign: "Top",
              Color: reportDescriptionColor,
              PaddingLeft: "0.2in",
              StyleName: '=""',
              FontWeight: "Medium",
            },
            KeepTogether: true,
            DataElementName: "RichText1",
          },
          {
            Type: "image",
            VerticalAlignment: "Middle",
            HorizontalAlignment: "Right",
            Sizing: "FitProportional",
            Source: "External",
            Value: `${a_icon || ""}`,
            Name: "Image4",
            Top: "0.27085in",
            Left: "5.822917in",
            Width: "2.4446in",
            Height: "0.375in",
            ZIndex: 30,
            Style: {
              PaddingRight: "0.2in",
              StyleName: '=""',
            },
            KeepTogether: false,
            DataElementName: "Image4",
            DataElementOutput: "Auto",
          },
        ],
        Style: {
          BackgroundColor: primary,
          StyleName: '=""',
        },
      },
      PageFooter: {
        Type: "pagesection",
        Name: "PageFooter",
        Height: "0.6in",
        ReportItems: [
          {
            Type: "rectangle",
            ReportItems: [
              {
                Type: "textbox",
                CanGrow: true,
                Value: "Powered By",
                Name: "TextBox2",
                Left: "0.2in",
                Width: "3.375in",
                Height: "0.6in",
                Style: {
                  FontFamily: "Montserrat",
                  FontWeight: "Thin",
                  TextAlign: "Left",
                  VerticalAlign: "Middle",
                  Color: "Gray",
                  StyleName: '=""',
                },
                KeepTogether: true,
                DataElementName: "TextBox70",
                Action: {
                  Type: "HyperLink",
                  HyperLink: "https://www.hydrus.ai",
                },
              },
              {
                Type: "image",
                Sizing: "FitProportional",
                Source: "External",
                Value: `${staticImagesList.hydrusGreyLogo}`,
                Name: "HydrusLogo",
                Top: "0.2in",
                Left: "1.3in",
                Width: "1.5in",
                Height: "0.6in",
                ZIndex: 1,
                Style: {
                  StyleName: '=""',
                },
                KeepTogether: false,
                DataElementName: "HydrusLogo",
                DataElementOutput: "Auto",
                Action: {
                  Type: "HyperLink",
                  HyperLink: "https://www.hydrus.ai",
                },
              },
            ],
            Name: "Container1",
            Width: "8.3in",
            Height: "0.6in",
            Style: { StyleName: '=""' },
            KeepTogether: true,
          },
        ],
        Style: {
          BackgroundColor: "#ffffff",
          StyleName: '=""',
        },
      },
      Page: {
        PageWidth: "8.3in",
        PageHeight: "11.69in",
        PageOrientation: "Portrait",
        ColumnSpacing: "0.5in",
        BottomMargin: "0in",
        LeftMargin: "0in",
        RightMargin: "0in",
        TopMargin: "0in",
        Columns: 1,
      },
      Width: "8.3in",
      DataSources: [
        {
          Name: "DataSource1",
          Transaction: false,
          DataSourceReference: "",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString:
              "jsondata=" + JSON.stringify(reportDataForSingleTable),
            IntegratedSecurity: false,
            Prompt: "",
            Collation: "",
            AccentSensivity: "Auto",
            WidthSensivity: "Auto",
          },
        },
        {
          Name: "DataSourceStandardSheetRecords",
          Transaction: false,
          DataSourceReference: "",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString:
              "jsondata=" + JSON.stringify(reportDataForSingleTable),
            IntegratedSecurity: false,
            Prompt: "",
            Collation: "",
            AccentSensivity: "Auto",
            WidthSensivity: "Auto",
          },
        },
      ],
      DocumentMap: {
        Source: "All",
      },
      DataSets: [
        {
          Name: "DataSet1",
          Fields: [
            {
              Name: "name",
              DataField: "name",
            },
            {
              Name: "description",
              DataField: "description",
            },
            {
              Name: "standard_code",
              DataField: "standard_code",
            },
            {
              Name: "response",
              DataField: "response",
            },
          ],
          Query: {
            DataSourceName: "DataSource1",
            CommandType: "Text",
            CommandText: "$.[*]",
            Timeout: 0,
          },
          Filters: [
            {
              FilterExpression: "=Fields!Purpose.Value",
              Operator: "NotEqual",
              FilterValues: ["void"],
            },
          ],
          CaseSensitivity: "Auto",
          Collation: "",
          KanatypeSensitivity: "Auto",
          AccentSensitivity: "Auto",
          WidthSensitivity: "Auto",
        },
        {
          Name: "DataSetStandardSheetRecords",
          Fields: reportColumns?.map((column) => ({
            Name: column.value,
            DataField: column.value,
          })),
          Query: {
            DataSourceName: "DataSourceStandardSheetRecords",
            CommandType: "Text",
            CommandText: "$.[*]",
            Timeout: 0,
          },
          // Filters: [
          //   {
          //     FilterExpression: "=Fields!Purpose.Value",
          //     Operator: "NotEqual",
          //     FilterValues: ["void"],
          //   },
          // ],
          CaseSensitivity: "Auto",
          Collation: "",
          KanatypeSensitivity: "Auto",
          AccentSensitivity: "Auto",
          WidthSensitivity: "Auto",
        },
      ],
      EmbeddedImages: [
        {
          MIMEType: "image/png",
          ImageData: "",
          Name: "treadstone-logo-full-color-rgb",
        },
      ],
      Layers: [
        {
          Type: "layer",
          Name: "default",
        },
      ],
      CustomProperties: [
        {
          Name: "DisplayType",
          Value: "Page",
        },
        {
          Name: "SizeType",
          Value: "Default",
        },
      ],
    };
    return report;
  };

  return (
    <>
      <div className="action-buttons">
        <StyledButton
          type="custom"
          onClick={() => {
            setGeneratedReportUsingActiveJs(false);
            setStep((prev) => prev - 1);
          }}
        >
          Edit Report
        </StyledButton>
      </div>
      <Spin spinning={loadReportingData}>
        <div
          id="viewer-host"
          style={{
            display: designerVisible ? "none" : "block",
            height: "80vh",
          }}
        >
          <ReportViewer ref={viewerRef} />
        </div>
        <div
          id="designer-host"
          style={{
            display: designerVisible ? "block" : "none",
            height: "80vh",
          }}
        >
          <Designer
            report={{ definition: dataSource1(), displayName: "Custom Report" }}
            onRender={onReportPreview}
          />
        </div>
      </Spin>
    </>
  );
};

export default GeneratedReportUsingActiveJs;
