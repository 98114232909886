import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Form, message, Progress, Row, Space, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";
import { YearMonthDayFormat } from "../../shared/constants";
import GeneralInfo from "./components/Generalinfo";
import DataSheetFilter from "./components/DataSheetFilter";
import ReminderSettings from "./components/ReminderSettings";
import Completed from "./components/Completed";
import { v4 as uuidv4 } from "uuid";
import {
  createAuditManagement,
  getAuditManagements,
  updateAuditManagement,
} from "../../services/audits";
import moment from "moment";

const { Step } = Steps;

const CreateAudit = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [action, setAction] = useState("create");
  const [emailBody, setEmailBody] = useState(null);
  const [rowData, setRowData] = useState(null);
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    dateRange: "",
    priority: 1,
    userIds: [],
    groupIds: [],
    email_subject: "",
    reminder_interval: "op1",
    type: "",
  });
  const { audit_id } = useParams();
  const [form] = Form.useForm();
  useEffect(() => {
    if (state?.id) {
      const split_ids = state?.id.split(",");
      setInitialValues({
        ...initialValues,
        ["audit_items"]: split_ids,
        ["audit_type"]: state?.type,
      });
    }
  }, [state]);

  const getAuditById = async () => {
    const response = await getAuditManagements(audit_id);
    if (response) {
      setRowData(response);
      setAction("update");
    }
  };

  useEffect(() => {
    if (audit_id) {
      getAuditById();
    }
  }, [audit_id]);

  useEffect(() => {
    if (rowData) {
      try {
        const dateFormat = "YYYY/MM/DD";
        const today = moment();
        const dateStart = rowData?.start_date
          ? moment(rowData.start_date, dateFormat)
          : moment(today, dateFormat);
        const dateEnd = rowData?.end_date
          ? moment(rowData.end_date, dateFormat)
          : moment(today, dateFormat);
        const dateRange = [dateStart, dateEnd];
        const data = {
          name: rowData?.name ? rowData?.name : "",
          description: rowData?.description ? rowData?.description : "",
          email_subject: rowData?.email_subject ? rowData?.email_subject : "",
          dateRange: dateRange,
          priority: rowData?.priority,
          userIds: rowData?.assigned_users
            ? JSON.parse(rowData?.assigned_users)
            : [],
          groupIds: rowData?.assigned_groups
            ? JSON.parse(rowData?.assigned_groups)
            : [],
          reminder_interval:
            rowData.reminder_interval === null
              ? "op1"
              : rowData.reminder_interval === 1000000
              ? rowData.reminder_interval
              : "recurring_reminder",
          recurring_reminder_val:
            rowData.reminder_interval != 1000000
              ? rowData.reminder_interval
              : "",
          audit_items: JSON.parse(rowData?.audit_items),
          audit_type: rowData?.audit_type,
          conditional_filters:
            rowData?.audit_type === "DATA_SHEETS" && rowData.audit_settings
              ? JSON.parse(rowData.audit_settings)?.conditional_filters?.length
                ? JSON.parse(rowData.audit_settings)
                : JSON.parse(rowData.audit_settings)
              : [],
        };
        setInitialValues(data);
        form.setFieldsValue(data);
        setEmailBody(rowData?.email_body ? rowData?.email_body : "");
      } catch (error) {
        console.log("Error while pre fill form values", error);
      }
    }
  }, [rowData]);

  const onChangeFieldsData = (data) => {
    let newInitialValues = { ...initialValues };
    const formValues = form.getFieldsValue(true);
    Object.keys(formValues)?.forEach((key, index) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = formValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem = { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };
            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          newInitialValues = { ...newInitialValues, [key]: formValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      if (initialValues?.audit_type === "DATA_SHEETS" || step > selectedStep) {
        setStep(selectedStep + 1);
      } else {
        setStep(selectedStep + 2);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const onClickPrevious = () => {
    setStep(step - 1);
  };
  const onClickNext = async () => {
    try {
      await form.validateFields();
      if (initialValues?.audit_type === "DATA_SHEETS") {
        setStep(step + 1);
      } else {
        setStep(step + 2);
      }
    } catch (error) {
      console.log("Failed", error);
    }
  };

  const onSubmitHandler = async () => {
    form.validateFields().then(async () => {
      try {
        setLoader(true);
        const {
          name,
          description,
          userIds,
          groupIds,
          dateRange,
          priority,
          email_subject,
          audit_items,
          audit_type,
        } = initialValues;
        const conditional_filters = initialValues.conditional_filters?.map(
          (item) =>
            (item.op === "isEmpty" && { ...item, column_value: true }) ||
            (item.op === "isNotEmpty" && {
              ...item,
              op: "isEmpty",
              column_value: false,
            }) ||
            item
        );
        const reminder_interval_final =
          initialValues.reminder_interval === "recurring_reminder"
            ? initialValues.recurring_reminder_val
            : initialValues.reminder_interval === "op1"
            ? null
            : 1000000;
        const startdate = dateRange
          ? dateRange[0].format(YearMonthDayFormat)
          : null;
        const enddate = dateRange
          ? dateRange[1].format(YearMonthDayFormat)
          : null;

        let payload = null;
        let response = null;

        if (action === "create") {
          payload = {
            id: uuidv4(),
            name,
            description,
            priority: priority,
            status: "IN_PROGRESS",
            start_date: startdate,
            end_date: enddate,
            audit_type: audit_type,
            assigned_groups: JSON.stringify(groupIds),
            assigned_users: JSON.stringify(userIds),
            audit_items: JSON.stringify(audit_items),
            audit_settings: JSON.stringify(
              conditional_filters ? { conditional_filters } : {}
            ),
            email_subject,
            email_body: emailBody ? emailBody : "",
            reminder_interval: parseInt(reminder_interval_final),
          };
          response = await createAuditManagement(payload);
        } else {
          payload = {
            id: rowData?.id,
            name,
            description,
            priority: priority,
            status: "IN_PROGRESS",
            start_date: startdate,
            end_date: enddate,
            audit_type: audit_type,
            assigned_groups: JSON.stringify(groupIds),
            assigned_users: JSON.stringify(userIds),
            audit_items: JSON.stringify(audit_items),
            audit_settings: JSON.stringify(
              conditional_filters ? { conditional_filters } : []
            ),
            email_subject,
            email_body: emailBody ? emailBody : "",
            reminder_interval: parseInt(reminder_interval_final),
          };
          response = await updateAuditManagement(payload);
        }
        if (response) {
          message.success("Audit created successfully.");
          setStep(step + 1);
        } else {
          message.error("Error while saving audit");
        }
      } catch (error) {
        message.error("Something went wrong while creating audit");
        console.log("Something went wrong while creating audit", error);
      } finally {
        setLoader(false);
      }
    });
  };

  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return (
        <GeneralInfo
          navigate={navigate}
          onClickNext={onClickNext}
          initialValues={initialValues}
        />
      );
    } else if (step === 2 && initialValues?.audit_type === "DATA_SHEETS") {
      return (
        <DataSheetFilter
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          form={form}
        />
      );
    } else if (step === 3) {
      return (
        <ReminderSettings
          onClickPrevious={onClickPrevious}
          initialValues={initialValues}
          onSubmitHandler={onSubmitHandler}
          action={action}
          form={form}
          setEmailBody={setEmailBody}
          emailBody={emailBody}
          loader={loader}
        />
      );
    } else if (step === 4) {
      return <Completed action={action} />;
    }
  };
  return (
    <>
      <PageWrap>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                className="margin-5"
                type="custom"
                onClick={() => navigate("/audit/manage")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <span>
                  {" "}
                  <ArrowLeftOutlined /> <span>Exit</span>
                </span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                <Step
                  className="stepper-wrapper "
                  key={1}
                  title={<span>General Information</span>}
                />
                {initialValues?.audit_type === "DATA_SHEETS" && (
                  <Step
                    className="stepper-wrapper"
                    key={2}
                    title={<span>Data filtering</span>}
                  />
                )}
                <Step
                  className="stepper-wrapper"
                  key={3}
                  title={<span>Reminder Settings</span>}
                />
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={
              initialValues?.audit_type === "DATA_SHEETS"
                ? step * 33.33
                : step * 50
            }
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Space>
      </PageWrap>
    </>
  );
};

export default CreateAudit;
