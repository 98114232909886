import React, { useState, useEffect } from "react";
import { FormWrapper, PageWrap, StyledButton } from "../../shared/commonStyles";
import { message, Row, Col} from "antd";
import ContentEditable from 'react-contenteditable'
import {getAiResult} from "../../services/ai"

const Ai = () => {
    const [aiText, setAiText] = useState('The Hydrus AI tool can help answer questions you might have and even autocomplete various repetitive tasks. Give it a try and type a sentence.');
    const [oldText, setOldText] = useState('');
    const [txt, setTxt] = useState(null);
    const [index, setIndex] = useState('');
    const [loader, setLoader] = useState(false);
  
    const speed = 10
    
    const handleAiText = (event) => {        
        const value = event.target.value
        setAiText(value)
    }
    const typeWriter = () => {        
        if (index <= txt.length) {
            
            if(index === 1){
                setAiText((prevState) => prevState.concat('<div><br></div><mark>'))
            }
            setAiText((prevState) => prevState.concat(txt.charAt(index-1)))
            if(index === txt.length){
                setAiText((prevState) => prevState.concat('</mark><div><br></div>'))
                setOldText(aiText)
                setIndex('')
                setTxt(null)
            }else{
                setIndex(index+1)
            }
        }
      }

    const getAiData = async(question) => {    
        const results = await getAiResult(question);
        return results
    }
    
    const submitData = async() => {
        try{
            setLoader(true)            
            const question = oldText ? aiText?.substring(oldText?.length) : aiText
            if(question){
                const response = await getAiData(question)
                if(response?.choices.length > 0){
                    await Promise.all(
                        await  response?.choices?.map(async(item) => {                            
                            const newText = item?.text?.replace(/^\n+|\n+$/g, '').replace(/(?:\r\n|\r|\n)/g, "<br>")
                            setTxt(newText)  
                        })
                    )                     
                }
            }
        }catch(e){
            message.error(`Some problem occured while fetching data ${e}`)
        }finally{
            setLoader(false)
        }        
    }

    useEffect(() => {
        
        if (index) {            
            setTimeout(typeWriter, speed);            
        }
    },[index])

    useEffect(() => {
        if(txt){
            setIndex(1)
        }
    },[txt])

    const removePlaceholderText = () => {
        if(aiText === 'The Hydrus AI tool can help answer questions you might have and even autocomplete various repetitive tasks. Give it a try and type a sentence.'){
            setAiText('')
        }
    }
    return (
        <PageWrap>
            <FormWrapper>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <ContentEditable                            
                            html={aiText} // innerHTML of the editable div
                            disabled={loader}       // use true to disable editing
                            onChange={handleAiText} // handle innerHTML change
                            onFocus={removePlaceholderText}
                            //tagName='article' // Use a custom HTML tag (uses a div by default)
                            style={{
                                border: '1px solid #A68DFB',
                                background: '#625D75',
                                color: '#fff',
                                fontSize: '20px',
                                height: '80vh',
                                padding: '4px 11px',
                                overflowX: 'auto', 
                                width:'100%'
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <StyledButton onClick={submitData} loading={loader}>Submit</StyledButton>
                    </Col>
                </Row>
            </FormWrapper>            
        </PageWrap>
    )
}
export default Ai