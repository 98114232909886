import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  InputNumber,
  Popover,
  Row,
  Slider,
} from "antd";
import styles from "../DataSheetDocuments/DataSheetDocuments.module.scss";
import {
  FormWrapper,
  TableHeaderRightButtonWrap,
} from "../../../../shared/commonStyles";
import {
  CheckOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  ColumnHeightOutlined,
  FilterOutlined,
  LineChartOutlined,
  LockOutlined,
  PlusOutlined,
  SettingOutlined,
  SwapOutlined,
  SyncOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { DenyReasonModal } from "./DenyReasonModal";
import { useNavigate } from "react-router";
import { useBoolean } from "ahooks";
import { CreateExpressionModal } from "./CreateExpressionModal";
import { SortDropdown } from "../../../../components/SortDropdown";
import { FiltersModal } from "./FiltersModal/FiltersModal";
import { ManageDataSheetColumns } from "./ManageDataSheetColumns";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import { encodeUrlName } from "../../../../shared/commonFunctions";

interface ManageColumnData {
  key?: string;
  name: string;
  dataIndex: string;
  hide: boolean;
  index?: number;
}

interface TableHeaderProps {
  dataSheet?: DataSheet;
  isDataQualityScoreShown: boolean;
  toggleDataQualityScore: () => void;
  pagination: Pagination;
  rowSize: number;
  handleChangeSize: (size: number) => void;
  isPending: boolean;
  isDenied: boolean;
  isGeneral: boolean;
  isDeleted: boolean;
  selectedRows: DataSheetRecord[];
  handleMassChangeStatus: (
    status: RecordStatus,
    reason?: string,
    files?: File[]
  ) => void;
  handleChangeAllStatus: () => void;
  handleChangeLockRecords: (isLocked: boolean) => void;
  onConfirmCreateExpression: () => void;
  sortRecords: SortRecords;
  setSortRecords: (sortRecords: SortRecords) => void;
  onSort: () => void;
  search: Search;
  onApplyFilters: (params: any) => void;
  onResetFilters: () => void;
  onSortEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  manageColumnData: ManageColumnData[];
  handleSwitch: (checked: boolean) => void;
  handleMassCheck: (checked: boolean) => void;
  onCheckedItem: (value: any) => void;
}

export const TableHeader = ({
  dataSheet,
  isDataQualityScoreShown,
  toggleDataQualityScore,
  rowSize,
  handleChangeSize,
  isPending,
  isDenied,
  isDeleted,
  isGeneral,
  selectedRows,
  handleMassChangeStatus,
  handleChangeAllStatus,
  handleChangeLockRecords,
  onConfirmCreateExpression,
  sortRecords,
  setSortRecords,
  onSort,
  search,
  onApplyFilters,
  onResetFilters,
  manageColumnData,
  handleSwitch,
  onSortEnd,
  handleMassCheck,
  onCheckedItem,
}: TableHeaderProps) => {
  const navigate = useNavigate();
  const [isManageColumnsVisible, setManageColumnsVisible] = useState(false);
  const [confirmApproveModal, setConfirmApproveModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [
    isCreateExpressionModalOpen,
    { setTrue: openCreateExpressionModal, setFalse: hideCreateExpressionModal },
  ] = useBoolean(false);
  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);

  const sortOptions = () =>
    [...dataSheet!.sheet_schema]!.map((option) => ({
      name: option.display_name,
      value: option.entity_name,
    }));
  const handleChangeDataQualityShown = () => {
    toggleDataQualityScore();
    localStorage.setItem(
      "isDataQualityScoreShown",
      JSON.stringify(!isDataQualityScoreShown)
    );
  };
  const handleConfirmCreateExpression = () => {
    hideCreateExpressionModal();
    onConfirmCreateExpression();
  };
  const { checkRolesPermission } = useCheckRolesPermissions();

  const handleConfirmApprove = async () => {
    setConfirmApproveModal(false);
    await handleChangeAllStatus();
  };

  return (
    <>
      {dataSheet && (
        <>
          <Row justify="space-between">
            <TableHeaderRightButtonWrap>
              <Popover
                overlayClassName="popoverContent"
                content={
                  <FormWrapper>
                    <Row justify="space-between">
                      <Col span={14}>
                        <Slider
                          min={54}
                          max={200}
                          tooltipVisible={false}
                          value={rowSize}
                          onChange={handleChangeSize}
                        />
                      </Col>
                      <Col span={9}>
                        <InputNumber
                          min={54}
                          max={200}
                          value={rowSize}
                          onChange={handleChangeSize}
                        />
                      </Col>
                    </Row>
                  </FormWrapper>
                }
                trigger={"click"}
                placement="bottomLeft"
              >
                <Button className="sortButton" type="text">
                  <ColumnHeightOutlined /> Change row height
                </Button>
              </Popover>
              {isPending && (
                <>
                  <Button
                    className="sortButton"
                    type="text"
                    onClick={() => setConfirmApproveModal(true)}
                  >
                    <CheckOutlined /> Approve all records
                  </Button>
                </>
              )}
              {isPending && !!selectedRows.length && (
                <>
                  <Button
                    className="sortButton"
                    type="text"
                    onClick={() =>
                      handleMassChangeStatus(RecordStatus.APPROVED)
                    }
                  >
                    <CheckOutlined /> Approve records ({selectedRows.length})
                  </Button>
                  <DenyReasonModal
                    onConfirm={(reason, files) =>
                      handleMassChangeStatus(RecordStatus.DENIED, reason, files)
                    }
                    button={{
                      icon: <CloseOutlined />,
                      text: `Deny records(${selectedRows.length})`,
                      className: "sortButton",
                      type: "text",
                    }}
                  />
                </>
              )}
              {!isPending &&
                !isDenied &&
                !!selectedRows.length &&
                !checkRolesPermission(["role:auditor"]) && (
                  <Button
                    className="sortButton"
                    type="text"
                    onClick={() =>
                      handleChangeLockRecords(
                        !selectedRows.every((item) => item.isLocked)
                      )
                    }
                  >
                    {selectedRows.every((item) => item.isLocked) ? (
                      <>
                        <UnlockOutlined /> Unlock records ({selectedRows.length}
                        )
                      </>
                    ) : (
                      <>
                        <LockOutlined /> Lock records ({selectedRows.length})
                      </>
                    )}
                  </Button>
                )}
              {!checkRolesPermission(["role:elevateduser", "role:auditor"]) &&
                !isPending &&
                !isDenied && (
                  <Button
                    className="sortButton"
                    type="text"
                    icon={<SyncOutlined />}
                    onClick={() =>
                      navigate(
                        `/data/data-sheets/${
                          dataSheet?._id?.$oid
                        }/${encodeUrlName(
                          dataSheet?.sheet_name.replace("/", "")
                        )}/documents/update-records`,
                        {
                          state: {
                            selectedRows: selectedRows.map(
                              (item) => item?._id?.$oid
                            ),
                            dataSheet: dataSheet,
                          },
                        }
                      )
                    }
                  >
                    {!selectedRows.length
                      ? "Update all records"
                      : `Update records(${selectedRows.length})`}
                  </Button>
                )}
              <Button
                className="sortButton"
                type="text"
                icon={<LineChartOutlined />}
                onClick={handleChangeDataQualityShown}
              >
                {isDataQualityScoreShown ? "Hide" : "Show"} Data Quality
              </Button>
              {!isGeneral &&
                !checkRolesPermission([
                  "role:elevateduser",
                  "role:auditor",
                ]) && (
                  <>
                    <Button
                      className="sortButton"
                      type="text"
                      icon={<SettingOutlined />}
                      onClick={() =>
                        navigate(
                          `/data/data-sheets/${
                            dataSheet?._id?.$oid
                          }/${encodeUrlName(
                            dataSheet?.sheet_name.replace("/", "")
                          )}/documents/data-quality-score-setup`
                        )
                      }
                    >
                      Setup data quality scoring
                    </Button>
                    <Button
                      className="sortButton"
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={openCreateExpressionModal}
                    >
                      Create an expression
                    </Button>
                  </>
                )}

              {
                <Button type="text" className={`sortButton ${styles.sort}`}>
                  <Dropdown
                    overlay={
                      <SortDropdown
                        sorting={sortRecords}
                        changeSorting={setSortRecords}
                        options={sortOptions()}
                        onConfirm={() => {
                          onSort();
                          hideDropdown();
                        }}
                      />
                    }
                    trigger={["click"]}
                    onVisibleChange={toggle}
                    visible={isDropdownShown}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <SwapOutlined rotate={90} />
                      <span className={styles.sortSpan}>Sort</span>
                    </a>
                  </Dropdown>
                </Button>
              }
              <Button
                className={`sortButton ${styles.filter}`}
                type="text"
                icon={<FilterOutlined />}
                onClick={() => setShowFiltersModal(true)}
              >
                <span className="expandText">Filters</span>{" "}
                <Badge
                  dot={
                    !!search.conditionalFilters?.filter(
                      (v) => v.type === "condition"
                    )?.length
                  }
                  style={{
                    backgroundColor: "#A68DFB",
                  }}
                />
              </Button>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <ManageDataSheetColumns
                    dataSheet={dataSheet}
                    onSortEnd={onSortEnd}
                    setManageColumnsVisible={setManageColumnsVisible}
                    manageColumnData={manageColumnData}
                    handleSwitch={handleSwitch}
                    handleMassCheck={handleMassCheck}
                    onCheckedItem={onCheckedItem}
                  />
                }
                visible={isManageColumnsVisible}
              >
                <Button
                  type="text"
                  className="sortButton"
                  icon={<CheckSquareOutlined />}
                  onClick={() => setManageColumnsVisible((prev) => !prev)}
                >
                  Manage columns
                </Button>
              </Dropdown>
            </TableHeaderRightButtonWrap>
          </Row>
          {confirmApproveModal && (
            <ConfirmModal
              title={"Confirmation"}
              visible={confirmApproveModal}
              okText={"Approve"}
              onConfirm={handleConfirmApprove}
              onClose={() => setConfirmApproveModal(false)}
              ConfirmLoader={false}
              description={
                "Are you sure you want to approve all records? This is irreversible"
              }
            />
          )}
          {isCreateExpressionModalOpen && (
            <CreateExpressionModal
              visible={isCreateExpressionModalOpen}
              dataSheet={dataSheet!}
              onClose={hideCreateExpressionModal}
              onConfirm={handleConfirmCreateExpression}
              isRecordsPage
            />
          )}
          {showFiltersModal && (
            <FiltersModal
              initialValues={search}
              visible={showFiltersModal}
              onClose={() => setShowFiltersModal(false)}
              dataSheet={dataSheet}
              onFinish={onApplyFilters}
              onReset={() => {
                onResetFilters();
                setShowFiltersModal(false);
              }}
              pageType={
                (isDeleted && "deleted") ||
                (isPending && "pending") ||
                (isDenied && "denied") ||
                "main"
              }
            />
          )}
        </>
      )}
    </>
  );
};
