import React, { useEffect, useRef, useState } from "react";
import { Viewer } from "@grapecity/activereports-react";
import { Rdl as ARJS } from "@grapecity/activereports/core";
import { observer } from "mobx-react-lite";
import { calculationStore } from "../../../../Carbon/CalculationsStore";
import { CommonModal } from "../../../../../shared/commonStyles";
import moment from "moment";
import { staticImagesList } from "../../../../../shared/constants";
import { getCompanyInfo } from "../../../../../services/companyInfo";
import { doConversion } from "../../../../../services/dataSheet";
import { userInfoStore } from "../../../../Carbon/UserInfoStore";
import { getKpiMetric } from "../../../../../services/kpi";
import {
  fractionalNumber,
  getEmissionsUnit,
} from "../../../../Carbon/CalculationUtils";
import { calculationGroupsStore } from "../../../../Carbon/CalculationGroupsStore";

const numeral = require("numeral");

interface CarbonSettings {
  revenue_total: number;
  total_floor_space: number;
  full_time_employees: number;
}
interface ExportCalculations {
  visible: boolean;
  onClose: () => void;
}
export const ExportCalculations = observer(
  ({ visible, onClose }: ExportCalculations) => {
    const viewerRef = useRef<Viewer>(null);
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );
    const [carbonSettings, setCarbonSettings] = useState({} as CarbonSettings);
    const [conversionFactor, setConversionFactor] = useState(1);
    const period = calculationStore.calculationsPeriod?.map((item) =>
      moment(item?.$date || item)
    );
    const [calculationsPeriod, setCalculationsPeriod] = useState(period);
    useEffect(() => {
      setCalculationsPeriod(period);
    }, [calculationStore.calculationsPeriod]);

    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";
          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
      }
    };

    useEffect(() => {
      calculationStore.fetchCalculations();
      calculationGroupsStore.fetchCalculationGroups();
      userInfoStore.fetchUserInfo();
      loadCompanyInfo();
    }, []);

    const getConversionFactor = async () => {
      const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
      setConversionFactor(factor);
    };

    const getLastDateKpi = async (
      name: "revenue_total" | "full_time_employees" | "total_floor_space"
    ) => {
      const lastKpi = companyDetails?.[name]?.sort((a, b) =>
        a.end_date < b.end_date ? 1 : -1
      )[0].kpi;
      const kpiData = await getKpiMetric({
        filter: { _id: { $oid: lastKpi } },
      });
      if (kpiData.length) {
        const result =
          kpiData[0]?.analyticResult[
            `${kpiData[0]?.aggregateColumn}_${kpiData[0]?.aggregateOp}`
          ];
        setCarbonSettings((prev) => {
          return { ...prev, [name]: result };
        });
      }
    };
    useEffect(() => {
      getLastDateKpi("revenue_total");
      getLastDateKpi("full_time_employees");
      getLastDateKpi("total_floor_space");
      companyDetails.emissions_unit && getConversionFactor();
    }, [companyDetails]);

    const convertedNumber = fractionalNumber(
      companyDetails.decimal_places_number
    );

    const getCurrencyType = () => {
      switch (companyDetails.currency_type) {
        case "USD":
          return "$";
        case "EUR":
          return "€";
        case "GBP":
          return "£";
        case "AUD":
          return "A$";
        default:
          return "$";
      }
    };

    const calculations = calculationStore.savedCalculations.map((item: any) => {
      return {
        ...item,
        period: `${moment(
          (item.date_start as { $date: string })?.$date ||
            (item.date_start as string)
        ).format("YYYY-DD-MM")} - ${moment(
          (item.date_end as { $date: string })?.$date ||
            (item.date_end as string)
        ).format("YYYY-DD-MM")}`,
        total_kg_CO2e: convertedNumber(
          +item.data?.total_calculate?.all_total! * conversionFactor
        ),
      };
    });

    const filteredCalculations = calculations
      .filter(
        (v) =>
          calculationGroupsStore.calculationGroups &&
          !calculationGroupsStore.calculationGroups
            ?.map((v) => v.calculationsList)
            .flat()
            ?.find((item) => v._id?.$oid === item)
      )
      .filter((item) =>
        calculationsPeriod
          ? moment(
              (item.date_start as { $date: string })?.$date ||
                (item.date_start as string)
            ) >= moment(calculationsPeriod[0]) &&
            moment(
              (item.date_end as { $date: string })?.$date ||
                (item.date_end as string)
            ) <= moment(calculationsPeriod[1])
          : item
      );

    const calculationsList = filteredCalculations.map((item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });

    const newGroups = calculationGroupsStore.calculationGroups?.map(
      (group, index) => {
        const calculationsData = calculations?.filter((item) =>
          group?.calculationsList?.find((v) => item._id?.$oid === v)
        );
        const dateStart = calculationsData?.sort((a, b) =>
          moment(
            (a?.date_start as { $date: string })?.$date ||
              (a?.date_start as string)
          ) >
          moment(
            (b?.date_start as { $date: string })?.$date ||
              (b?.date_start as string)
          )
            ? 1
            : -1
        )[0]?.date_start;
        const dateEnd = calculationsData?.sort((a, b) =>
          moment(
            (a?.date_end as { $date: string })?.$date || (a?.date_end as string)
          ) <
          moment(
            (b?.date_end as { $date: string })?.$date || (b?.date_end as string)
          )
            ? 1
            : -1
        )[0]?.date_end;
        const indicatorType =
          !!calculationsData?.length &&
          (calculationsData?.every((item) => item.indicatorType === "l")
            ? "l"
            : calculationsData?.every((item) => item.indicatorType === "m")
            ? "m"
            : "default");
        const total = calculationsData.reduce((acc, item) => {
          return acc + +item.total_calculate?.all_total!;
        }, 0);
        return {
          ...group,
          index: index + 1,
          period: `${moment(
            (dateStart as { $date: string })?.$date || (dateStart as string)
          ).format("YYYY-DD-MM")} - ${moment(
            (dateEnd as { $date: string })?.$date || (dateEnd as string)
          ).format("YYYY-DD-MM")}`,
          calculations: calculationsData.map((v, i) => ({
            ...v,
            index: i + 1,
          })),
          indicatorType: indicatorType,
          date_start: dateStart,
          date_end: dateEnd,
          total_kg_CO2e: convertedNumber(total * conversionFactor),
          isGroup: true,
        };
      }
    );

    const groupFilterData = newGroups?.filter((item) =>
      calculationsPeriod
        ? moment(
            (item.date_start as { $date: string })?.$date ||
              (item.date_start as string)
          ) >= moment(calculationsPeriod[0]) &&
          moment(
            (item.date_end as { $date: string })?.$date ||
              (item.date_end as string)
          ) <= moment(calculationsPeriod[1])
        : item
    );

    const calculationsTotal = convertedNumber(
      calculationStore.savedCalculations
        ?.filter(
          (item) =>
            groupFilterData
              .map((v) => v.calculationsList)
              .flat()
              .find((value) => item._id?.$oid === value) ||
            filteredCalculations?.find(
              (value) => value._id?.$oid === item._id?.$oid
            )
        )
        .reduce((acc, item) => {
          return (
            acc + +(item.total_calculate?.all_total || 0) * conversionFactor
          );
        }, 0)
    );

    const countPercent = (number: string | number): number => {
      const numericValue =
        typeof number === "string" ? parseFloat(number) : number;

      if (!isNaN(numericValue)) {
        const total = numeral(number);

        if (calculationsTotal) {
          return total?.divide(calculationsTotal).multiply(100).format("0.00");
        }
      }
      return 0;
    };

    const otherEmissionPercentage = convertedNumber(
      calculationsList
        .filter((item) => countPercent(item.total_kg_CO2e || 0) <= 4)
        .map((item) => item.total_kg_CO2e)
        .reduce((acc, v) => {
          return acc + +v;
        }, 0)
    );

    const emissionPercentage = calculationsList.filter(
      (item) => countPercent(item.total_kg_CO2e || 0) > 4
    );

    const totalCalculationsInfo = emissionPercentage.map((item) => {
      return {
        name: item.name,
        value: Number(item.total_kg_CO2e),
        percentValue: countPercent(item.total_kg_CO2e!),
      };
    });
    totalCalculationsInfo.push({
      name: "Other",
      value: +otherEmissionPercentage,
      percentValue: countPercent(+otherEmissionPercentage),
    });

    const totalTableData = [
      {
        nameTotal: "Per dollar of revenue",
        valueTotal: `${
          Object.keys(companyDetails)?.length && carbonSettings.revenue_total
            ? convertedNumber(+calculationsTotal / carbonSettings.revenue_total)
            : "N/A"
        } ${getEmissionsUnit(
          companyDetails.emissions_unit
        )} CO2e/1${getCurrencyType()}`,
      },
      {
        nameTotal: "Per Full time employee",
        valueTotal: `${
          Object.keys(companyDetails)?.length &&
          carbonSettings.full_time_employees
            ? convertedNumber(
                +calculationsTotal / carbonSettings.full_time_employees
              )
            : "N/A"
        } ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e/FTE`,
      },
      {
        nameTotal: "Per cubic foot",
        valueTotal: `${
          Object.keys(companyDetails)?.length &&
          carbonSettings.total_floor_space
            ? convertedNumber(
                +calculationsTotal / carbonSettings.total_floor_space
              )
            : "N/A"
        } ${getEmissionsUnit(companyDetails.emissions_unit)} CO2e/${
          companyDetails.floor_space_unit || "m3"
        }`,
      },
    ];
    const calculationsStructure = [
      {
        name: "#",
        value: "index",
        length: 4,
      },
      {
        name: "Name",
        value: "name",
        length: 18,
      },
      {
        name: "Description",
        value: "description",
        length: 32,
      },
      {
        name: "Period",
        value: "period",
        length: 24,
      },
      {
        name: `Total Emission (${getEmissionsUnit(
          companyDetails.emissions_unit
        )} C02e)`,
        value: "total_kg_CO2e",
        length: 22,
      },
    ];

    const totalCalculationsStructure = [
      {
        name: "nameTotal",
        value: "nameTotal",
        type: "text",
        length: 50,
      },
      {
        name: "valueTotal",
        value: "valueTotal",
        type: "number",
        length: 50,
      },
    ];

    const getFieldValue = (fieldName: string) => {
      const value = `=Fields!${fieldName}.Value`;
      return value;
    };

    const generateReport = async () => {
      const calculationsTitle: ARJS.Table = {
        Name: "CalculationsTitle",
        Type: "table",
        DataSetName: "DataSetTotalTable",
        PageBreak: "End",
        TableColumns: [{ Width: "7.5in" }],
        Left: "0.5in",
        Style: {
          VerticalAlign: "middle",
        },
        Header: {
          TableRows: [
            {
              Height: "15pt",
              TableCells: [
                {
                  Item: {
                    Type: "textbox",
                    Name: `reportName`,
                    Value: "Greenhouse Gas Emissions",
                    CanGrow: true,
                    Style: {
                      Color: "#fff",
                      FontWeight: "Bold",
                      PaddingTop: "3in",
                      FontSize: "20pt",
                      TextAlign: "Center",
                    },
                  },
                },
              ],
            },
            {
              Height: "15pt",
              TableCells: [
                {
                  Item: {
                    Type: "textbox",
                    Name: `reportOverview`,
                    Value: "Calculations Overview",
                    CanGrow: true,
                    Style: {
                      Color: "#fff",
                      FontWeight: "Bold",
                      FontSize: "20pt",
                      TextAlign: "Center",
                    },
                  },
                },
              ],
            },
            {
              Height: "15pt",
              TableCells: [
                {
                  Item: {
                    Type: "textbox",
                    Name: `calculationPeriod`,
                    Value: `${moment(calculationsPeriod?.[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(calculationsPeriod?.[1]).format(
                      "YYYY-MM-DD"
                    )}`,
                    CanGrow: true,
                    Style: {
                      Color: "#fff",
                      FontWeight: "SemiBold",
                      FontSize: "16pt",
                      TextAlign: "Center",
                    },
                  },
                },
              ],
            },
          ],
        },
      };
      const getChart = (dataSet: string, index: number) => {
        const chart: ARJS.DvChart = {
          Name: `Chart_Calculation_${index}`,
          Type: "dvchart",
          DataSetName: dataSet,
          Palette: "Custom",
          Legend: {
            Hidden: true,
          },
          CustomPalette: ["#A68DFB"],
          Style: {
            VerticalAlign: "middle",
            FontFamily: "Inter, sans-serif",
            BackgroundColor: "#716A88",
            Border: {
              Width: "1pt",
              Style: "Solid",
              Color: "#716A88",
            },
          },
          PlotArea: {
            Legends: [
              {
                Hidden: true,
              },
            ],
            Axes: [
              {
                AxisType: "X",
                Plots: ["Plot"],
                Labels: false,
                LineStyle: {
                  Border: {
                    Width: "0pt",
                    Style: "Solid",
                  },
                },
              },
              {
                AxisType: "Y",
                Plots: ["Plot"],
                Max: `${calculationsTotal}`,
                Labels: false,
              },
            ],
          },
          Plots: [
            {
              PlotName: "Plot",
              PlotChartType: "Bar",
              Config: {
                Bar: {
                  Width: 1,
                },
                SwapAxes: true,
                LineAspect: "Spline",
                LineStyle: {
                  Style: "Solid",
                  Width: "0pt",
                },
              },
              Encodings: {
                Values: [
                  {
                    Field: {
                      Value: [getFieldValue("value")],
                    },
                    Aggregate: "Sum",
                  },
                ],
                Category: {
                  Field: {
                    Value: [getFieldValue("name")],
                  },
                },
                Color: {
                  Field: {
                    Value: [getFieldValue("percentValue")],
                  },
                },
              },
            },
          ],
        };
        return chart;
      };

      const totalTable: ARJS.Table = {
        Name: "TotalOverview",
        Type: "table",
        DataSetName: "DataSetTotalTable",
        Left: "0.5in",
        Width: "7.5in",
        TableColumns: totalCalculationsStructure.map((f) => ({
          Width: `${(7.5 * f.length) / 100}in`,
          AutoWidth: "Proportional",
        })),
        Style: {
          VerticalAlign: "bottom",
          FontFamily: "Inter, sans-serif",
        },
        Header: {
          TableRows: [
            {
              Height: "15pt",
              TableCells: totalCalculationsStructure.map((f) => ({
                Item: {
                  Type: "textbox",
                  Name: `calculation_header_${f.value}`,
                  Value: `${
                    f.value === "nameTotal"
                      ? "Total"
                      : calculationsTotal +
                        " " +
                        getEmissionsUnit(companyDetails.emissions_unit) +
                        " CO2e"
                  }`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "SemiBold",
                    FontSize: "14pt",
                    VerticalAlign: "middle",
                    TextAlign: f.type === "text" ? "left" : "right",
                    Color: "#fff",
                    BackgroundColor: "#42385F",
                    PaddingLeft: "24pt",
                    PaddingRight: "12pt",
                    PaddingTop: "15pt",
                    PaddingBottom: "10pt",
                    BottomBorder: {
                      Color: "#504769",
                      Style: "solid",
                      Width: "1pt",
                    },
                  },
                },
              })),
            },
          ],
        },
        Details: {
          TableRows: [
            {
              Height: "15pt",
              TableCells: totalCalculationsStructure.map((f) => ({
                Item: {
                  Type: "textbox",
                  Name: `calculation_value_${f.value}`,
                  Value: `${getFieldValue(f.value)}`,
                  CanGrow: true,
                  KeepTogether: true,
                  Style: {
                    FontWeight: "SemiBold",
                    FontSize: "12pt",
                    VerticalAlign: "middle",
                    TextAlign: f.type === "text" ? "left" : "right",
                    Color: "#fff",
                    BackgroundColor: "#42385F",
                    PaddingLeft: "20pt",
                    PaddingRight: "12pt",
                    PaddingTop: "5pt",
                    PaddingBottom: "5pt",
                  },
                },
              })),
            },
          ],
        },
      };

      const getOverviewTable = (dataSet: string, item: any, index: number) => {
        const lastIndex = totalCalculationsInfo.length - 1;
        const headerRows: ARJS.TableRow[] = [];
        const headerRow: ARJS.TableRow = {
          Height: "15pt",
          TableCells: [
            {
              ColSpan: 2,
              Item: {
                Type: "textbox",
                Name: `totalOverview`,
                Value: `Short overview`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  FontWeight: "Bold",
                  FontSize: "16pt",
                  VerticalAlign: "middle",
                  TextAlign: "center",
                  Color: "#fff",
                  PaddingTop: "13pt",
                  PaddingBottom: "30pt",
                },
              },
            },
          ],
        };
        index === 0 && headerRows.push(headerRow);
        const chartRowTitle: ARJS.TableRow = {
          TableCells: [
            {
              Item: {
                Type: "textbox",
                Name: `infoTotal`,
                Value: `${item.name} - ${item.percentValue} %`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  FontWeight: "Bold",
                  FontSize: "14pt",
                  VerticalAlign: "middle",
                  TextAlign: "left",
                  Color: "#fff",
                  BackgroundColor: "#342A48",
                  PaddingBottom: "10pt",
                  PaddingTop: "25pt",
                },
              },
            },
            {
              Item: {
                Type: "textbox",
                Name: `infoTotal`,
                Value: `${item.value} ${getEmissionsUnit(
                  companyDetails.emissions_unit
                )} C02e`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  FontWeight: "Bold",
                  FontSize: "14pt",
                  VerticalAlign: "middle",
                  TextAlign: "right",
                  Color: "#fff",
                  BackgroundColor: "#342A48",
                  PaddingBottom: "10pt",
                  PaddingTop: "25pt",
                },
              },
            },
          ],
        };
        headerRows.push(chartRowTitle);
        const chartData: ARJS.TableRow = {
          Height: "20pt",
          TableCells: [
            {
              ColSpan: 2,
              Item: getChart(dataSet, index),
            },
          ],
        };
        headerRows.push(chartData);
        const overviewTable: ARJS.Table = {
          Name: "TotalOverview",
          Type: "table",
          DataSetName: "DataSetTotalTable",
          Left: "0.5in",
          Width: "7.5in",
          TableColumns: totalCalculationsStructure.map((f) => ({
            Width: `${(7.5 * f.length) / 100}in`,
            AutoWidth: "Proportional",
          })),
          Style: {
            VerticalAlign: "middle",
            FontFamily: "Inter, sans-serif",
          },
          Header: {
            TableRows: headerRows,
          },
        };
        if (index === lastIndex) {
          overviewTable.Footer = {
            TableRows: [
              {
                Height: "90pt",
                TableCells: [
                  {
                    ColSpan: 2,
                    Item: {
                      Type: "textbox",
                      Name: "",
                      Value: "",
                    },
                  },
                ],
              },
              {
                Height: "15pt",
                TableCells: [
                  {
                    ColSpan: 2,
                    Item: totalTable,
                  },
                ],
              },
            ],
          };
          overviewTable.PageBreak = "End";
        }
        return overviewTable;
      };

      const getCalculationsTable = (dataSet: string, showHeader?: boolean) => {
        const tableCells: ARJS.TableCell[] = [
          {
            Item: {
              Type: "textbox",
              Name: `empty`,
              Value: " ",
            },
          },
        ];
        calculationsStructure.map((f) =>
          tableCells.push({
            Item: {
              Type: "textbox",
              Name: `emissions_value_${f.value}`,
              Value: getFieldValue(f.value),
              CanGrow: true,
              KeepTogether: true,
              Style: {
                VerticalAlign: "middle",
                TextAlign: "left",
                PaddingTop: "6pt",
                PaddingLeft: "6pt",
                PaddingRight: "6pt",
                PaddingBottom: "6pt",
                FontSize: "8pt",
                FontWeight: f.value === "name" ? "Medium" : "Normal",
                Color: "#fff",
                BackgroundColor: "#42385F",
                BottomBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: "1pt",
                },
                TopBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: "1pt",
                },
                LeftBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: f.value === "index" ? "1pt" : "0pt",
                },
                RightBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: f.value === "total_kg_CO2e" ? "1pt" : "0pt",
                },
              },
            },
          })
        );
        const calculationsTable: ARJS.Table = {
          Name: `Table_Overview_${dataSet}`,
          Type: "table",
          DataSetName: dataSet,
          TableColumns: [
            { Width: showHeader ? "0.2in" : "0in" },
            ...calculationsStructure.map((f) => ({
              Width: `${((showHeader ? 7.3 : 7.5) * f.length) / 100}in`,
            })),
          ],
          Left: "1in",
          Details: {
            TableRows: [
              {
                Height: "15pt",
                TableCells: tableCells,
              },
            ],
          },
        };
        if (showHeader) {
          const headerCells: ARJS.TableCell[] = [
            {
              Item: {
                Type: "textbox",
                Name: `empty`,
                Value: " ",
              },
            },
          ];
          calculationsStructure.map((f) =>
            headerCells.push({
              Item: {
                Type: "textbox",
                Name: `emissions_header_${f.value}`,
                Value: f.name,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  Color: "#fff",
                  VerticalAlign: "middle",
                  FontWeight: "Bold",
                  FontSize: "8pt",
                  TextAlign: "left",
                  PaddingTop: "6pt",
                  PaddingLeft: "6pt",
                  PaddingRight: "6pt",
                  PaddingBottom: "6pt",
                  BackgroundColor: "#35294A",
                  BottomBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  TopBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  LeftBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "index" ? "1pt" : "0pt",
                  },
                  RightBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "total_kg_CO2e" ? "1pt" : "0pt",
                  },
                },
              },
            })
          ),
            (calculationsTable.Header = {
              RepeatOnNewPage: true,
              TableRows: [
                {
                  Height: "15pt",
                  TableCells: headerCells,
                },
              ],
            });
        }
        return calculationsTable;
      };

      const tableGroups: ARJS.Table = {
        Name: "test",
        Type: "table",
        Left: "0.5in",
        DataSetName: "DataSetCalculationsTable",
        TableColumns: calculationsStructure.map((f) => ({
          Width: `${(7.5 * f.length) / 100}in`,
        })),
      };
      const groupRows: ARJS.TableRow[] = [
        {
          Height: "15pt",
          TableCells: [
            {
              ColSpan: calculationsStructure.length,
              Item: {
                Type: "textbox",
                Name: "calculationsOverview",
                Value: "Detailed Overview",
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  Color: "#fff",
                  VerticalAlign: "middle",
                  FontWeight: "SemiBold",
                  FontSize: "14pt",
                  TextAlign: "center",
                  PaddingTop: "6pt",
                  PaddingBottom: "26pt",
                },
              },
            },
          ],
        },
        {
          Height: "15pt",
          TableCells: calculationsStructure.map((f) => ({
            Item: {
              Type: "textbox",
              Name: `emissions_header_${f.value}`,
              Value: f.name,
              CanGrow: true,
              KeepTogether: true,
              Style: {
                Color: "#fff",
                VerticalAlign: "middle",
                FontWeight: "Bold",
                FontSize: "8pt",
                TextAlign: "left",
                PaddingTop: "6pt",
                PaddingLeft: "6pt",
                PaddingRight: "6pt",
                PaddingBottom: "6pt",
                BackgroundColor: "#35294A",
                BottomBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: "1pt",
                },
                TopBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: "1pt",
                },
                LeftBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: f.value === "index" ? "1pt" : "0pt",
                },
                RightBorder: {
                  Color: "#504769",
                  Style: "solid",
                  Width: f.value === "total_kg_CO2e" ? "1pt" : "0pt",
                },
              },
            },
          })),
        },
      ];

      groupFilterData?.map((_, index) => {
        groupRows.push({
          Height: "15pt",
          TableCells: [
            {
              ColSpan: calculationsStructure.length,
              Item: getCalculationsTable(`dataSetCalculationGroup_${index}`),
            },
          ],
        });
        groupRows.push({
          Height: "15pt",
          TableCells: [
            {
              ColSpan: calculationsStructure.length,
              Item: getCalculationsTable(
                `dataSetGroupCalculations_${index}`,
                true
              ),
            },
          ],
        });
      });
      groupRows.push({
        Height: "15pt",
        TableCells: [
          {
            ColSpan: calculationsStructure.length,
            Item: getCalculationsTable("DataSetCalculationsTable"),
          },
        ],
      });
      tableGroups.Header = {
        TableRows: groupRows,
      };
      const report: ARJS.Report = {
        Name: "report.rdlx",
        Width: "8.5in",
        DataSources: [
          {
            Name: "DataSourceCalculationsTable",
            ConnectionProperties: {
              DataProvider: "JSONEMBED",
              ConnectString: "jsondata=" + JSON.stringify(calculationsList),
            },
          },
          {
            Name: "DataSourceTotalTable",
            ConnectionProperties: {
              DataProvider: "JSONEMBED",
              ConnectString: "jsondata=" + JSON.stringify(totalTableData),
            },
          },
          ...groupFilterData.map((item, index) => {
            return {
              Name: `DataSourceCalculationGroup_${index}`,
              ConnectionProperties: {
                DataProvider: "JSONEMBED",
                ConnectString: "jsondata=" + JSON.stringify([item]),
              },
            };
          }),
          ...groupFilterData.map((item, index) => {
            return {
              Name: `DataSourceGroupCalculations_${index}`,
              ConnectionProperties: {
                DataProvider: "JSONEMBED",
                ConnectString: "jsondata=" + JSON.stringify(item.calculations),
              },
            };
          }),
          ...totalCalculationsInfo.map((item, index) => {
            return {
              Name: `DataSourceCalculation_${index}`,
              ConnectionProperties: {
                DataProvider: "JSONEMBED",
                ConnectString: "jsondata=" + JSON.stringify([item]),
              },
            };
          }),
        ],
        DataSets: [
          {
            Name: "DataSetCalculationsTable",
            Query: {
              CommandText: "$.*",
              DataSourceName: "DataSourceCalculationsTable",
            },
            Fields: calculationsStructure.map((v) => {
              return {
                Name: v.value,
                DataField: v.value,
              };
            }),
          },
          {
            Name: "DataSetTotalTable",
            Query: {
              CommandText: "$.*",
              DataSourceName: "DataSourceTotalTable",
            },
            Fields: totalCalculationsStructure.map((v) => {
              return {
                Name: v.value,
                DataField: v.value,
              };
            }),
          },
          ...groupFilterData.map((item, index) => {
            return {
              Name: `dataSetCalculationGroup_${index}`,
              Query: {
                CommandText: "$.*",
                DataSourceName: `DataSourceCalculationGroup_${index}`,
              },
              Fields: calculationsStructure.map((f) => ({
                Name: f.value,
                DataField: f.value,
              })),
            };
          }),
          ...groupFilterData.map((item, index) => {
            return {
              Name: `dataSetGroupCalculations_${index}`,
              Query: {
                CommandText: "$.*",
                DataSourceName: `DataSourceGroupCalculations_${index}`,
              },
              Fields: calculationsStructure.map((f) => ({
                Name: f.value,
                DataField: f.value,
              })),
            };
          }),

          ...totalCalculationsInfo.map((item, index) => {
            return {
              Name: `DataSetCalculation_${index}`,
              Query: {
                CommandText: "$.*",
                DataSourceName: `DataSourceCalculation_${index}`,
              },
              Fields: ["name", "value", "percentValue"].map((f) => ({
                Name: f,
                DataField: f,
              })),
            };
          }),
        ],
        Page: {
          PageWidth: "8.5in",
          PageHeight: "11.7in",
        },
        Body: {
          Style: {
            BackgroundColor: "#342A48",
          },
          ReportItems: [
            calculationsTitle,
            ...totalCalculationsInfo.map((item, index) =>
              getOverviewTable(`DataSetCalculation_${index}`, item, index)
            ),
            tableGroups,
          ],
        },
        PageHeader: {
          Height: "25pt",
          Style: {
            BackgroundColor: "#342A48",
          },
        },
        PageFooter: {
          Height: "0.6in",
          ReportItems: [
            {
              Type: "rectangle",
              ReportItems: [
                {
                  Type: "image",
                  Sizing: "FitProportional",
                  Source: "External",
                  Value: staticImagesList.hydrusIconWithLetterLogo,
                  Name: "Image2",
                  Top: "0in",
                  Left: "6.5in",
                  Width: "1.5in",
                  Height: "0.6in",
                },
              ],
              Name: "Container1",
              Width: "8.3in",
              Height: "0.6in",
              KeepTogether: true,
            },
          ],
          Style: {
            BackgroundColor: "#342A48",
          },
        },
      };
      return report;
    };
    const loadReport = () => {
      viewerRef.current?.open("report", {
        ResourceLocator: {
          getResource: () => {
            return generateReport() as any;
          },
        },
      });
    };

    useEffect(() => {
      loadReport();
    }, [visible]);

    const exportsSettings = {
      pdf: {
        title: "Greenhouse Gas Emissions",
        author: userInfoStore.userName,
        filename: "Greenhouse Gas Emissions",
        printing: "none",
        pdfVersion: "1.7",
        autoPrint: true,
      },
      xlsx: {
        title: "Greenhouse Gas Emissions",
        author: userInfoStore.userName,
        filename: "Greenhouse Gas Emissions",
        autoPrint: true,
        multiPage: true,
        embedImages: "external",
        outputType: "xlsx",
      },
      "tabular-data": {
        title: "Greenhouse Gas Emissions",
        author: userInfoStore.userName,
        filename: "Greenhouse Gas Emissions",
      },
    };

    return (
      <>
        {/* <StyledButton
        onClick={() => setModalVisible(true)}
        disabled={!calculationStore?.savedCalculations?.length}
        type="primary"
        icon={<ExportOutlined />}
      >
        Export Report
      </StyledButton> */}
        <CommonModal
          centered
          title={"Export"}
          visible={visible}
          onCancel={() => onClose()}
          footer={null}
          width={"70%"}
        >
          <div id="viewer-host">
            <Viewer
              ref={viewerRef}
              exportsSettings={exportsSettings}
              availableExports={["pdf", "xlsx", "tabular-data"]}
            />
          </div>
        </CommonModal>
      </>
    );
  }
);
