import React from "react";
import { useBoolean } from "ahooks";
import { Link } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";

import { StyledButton } from "../../../../shared/commonStyles";
import { InputReasonsModal } from "../Components/InputReasonsModal";
import { userInfoStore } from "../../../Carbon/UserInfoStore";
import { HistoryDrawer } from "../Components/HistoryDrawer";

import styles from "./CalculationsActions.module.scss";

interface CalculationsActionsProps {
  data: ICalculation;
  onConfirm: (reason: string, audit_state: AuditState) => void;
}

export const CalculationsActions = observer(function CalculationsActions({
  data,
  onConfirm,
}: CalculationsActionsProps) {
  const [
    isApproveModalVisible,
    {
      setTrue: setApproveModalTrue,
      setFalse: setApproveModalFalse,
      set: setApprove,
    },
  ] = useBoolean(false);
  const [
    isDeclineModalVisible,
    {
      setTrue: setDeclineModalTrue,
      setFalse: setDeclineModalFalse,
      set: setDecline,
    },
  ] = useBoolean(false);

  const [
    isHistoryDrawerVisible,
    { setTrue: showHistoryDrawer, setFalse: hideHistoryDrawer },
  ] = useBoolean(false);

  return (
    <div className={styles.buttonsAction}>
      <StyledButton type="primary">
        <Link
          to={`/audit/calculations-audit/${data.name}`}
          state={data._id?.$oid}
        >
          Open
        </Link>
      </StyledButton>
      <Button
        type="primary"
        className={styles.buttonTable}
        onClick={showHistoryDrawer}
      >
        History
      </Button>

      <div>
        <Dropdown
          overlay={() => (
            <InputReasonsModal
              onConfirm={(reason) => onConfirm(reason, AuditState.APPROVED)}
              onClose={setApproveModalFalse}
            />
          )}
          trigger={["click"]}
          placement="bottomLeft"
          disabled={
            data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.APPROVED
          }
          visible={isApproveModalVisible}
          onVisibleChange={(open) => setApprove(open)}
        >
          <Button
            type="primary"
            className={styles.buttonTable}
            onClick={setApproveModalTrue}
          >
            {data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.APPROVED ? (
              <>
                <CheckOutlined className={styles.styleOutlined} />
                Approved
              </>
            ) : (
              "Mark as approved"
            )}
          </Button>
        </Dropdown>
      </div>

      <div>
        <Dropdown
          overlay={() => (
            <InputReasonsModal
              onConfirm={(reason) => onConfirm(reason, AuditState.DECLINED)}
              onClose={setDeclineModalFalse}
              isRequired
            />
          )}
          trigger={["click"]}
          disabled={
            data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.DECLINED
          }
          visible={isDeclineModalVisible}
          onVisibleChange={(open) => setDecline(open)}
        >
          <Button
            type="primary"
            className={styles.buttonTable}
            onClick={setDeclineModalTrue}
          >
            {data.audits?.find((item) => item.user_id === userInfoStore.userID)
              ?.status === AuditState.DECLINED ? (
              <>
                <CloseOutlined className={styles.styleOutlined} />
                Declined
              </>
            ) : (
              "Decline"
            )}
          </Button>
        </Dropdown>
      </div>

      <HistoryDrawer
        data={data}
        visible={isHistoryDrawerVisible}
        onClose={hideHistoryDrawer}
      />
    </div>
  );
});
