import React from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
  CommonSelect
} from "../../../shared/commonStyles";

import { Form, Input, Row, Col, Typography, Divider, Select } from "antd";

import {
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const step2 = ({
  disable,
  onClickNext,
  onClickPrevious,
  loadingCreateForm,
  standardDataList,
  onChangeStandards,
  selectedStandard
}) => {
  return (
    <FormWrap width="45%">
      <FormWrapper>
        <Title level={3}>Standard Description</Title>
        <Divider
          style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
        />
        <Row justify="space-between" gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Standard Code #"
              name="standard_code"
            >
              <Input placeholder="Enter code" />
            </Form.Item>
          </Col>
          <Col style={{
            display: "flex",
            marginRight: "10px",
            flex: 1,
            minWidth: "100px",
            marginBottom: "10px",
            flexDirection: 'column'
          }}>
            <Title level={3} style={{ fontSize: "18px" }}>Frameworks <span style={{ color: '#B1AFBC' }}> (Optional)</span></Title>
            <CommonSelect
              mode="multiple"
              showArrow
              showSearch
              style={{ width: "100%" }}
              value={selectedStandard}
              placeholder="All Standards"
              onChange={(value) => onChangeStandards(value)}
            >
              {standardDataList?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </CommonSelect>
          </Col>
        </Row>
        <Title level={3} style={{ fontSize: "18px" }}>
          Custom attributes
          <span style={{ color: '#B1AFBC' }}> (Optional)</span>
        </Title>
        <Divider
          style={{
            backgroundColor: "#3A3C5A",
            marginTop: "3px",
            marginBottom: "3px",
          }}
        />

        <Form.List name="standardsList">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row justify="space-between" style={{ alignItems: "center" }} gutter={[8, 8]} key={field.key}>
                  <Col style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'standardName']}
                      fieldKey={[field.fieldKey, 'standardName']}
                      style={{ display: 'flex', flex: 1, marginRight: '15px' }}
                    >
                      <Input placeholder="Enter attribute name" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'standardValue']}
                      fieldKey={[field.fieldKey, 'standardValue']}
                      style={{ display: 'flex', flex: 1 }}
                    >
                      <Input placeholder="Enter attribute value" />
                    </Form.Item>
                  </Col>
                  <Form.Item>
                    <StyledButton
                      type="default"
                      onClick={() => remove(field.name)}
                      style={{ padding: '8px', marginTop: '0px' }}
                    >
                      <DeleteOutlined />
                    </StyledButton>
                  </Form.Item>
                </Row>
              ))}
              <Form.Item>
                <StyledButton className="margin-5" type="custom" onClick={() => add()} icon={<PlusOutlined />}>
                  Add One More Standard
                </StyledButton>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Title level={3} style={{ fontSize: "20px" }}>
          Add standard Information
        </Title>
        <Divider
          style={{
            backgroundColor: "#3A3C5A",
            marginTop: "3px",
            marginBottom: "4px",
          }}
        />
        <Input.Group>
          <Form.List name="standardInfo">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row
                    gutter={[16, 16]}
                    key={index}>
                    <Col lg={7} md={7} sm={24} xs={24}>
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        fieldKey={[field.key, "name"]}
                      >
                        <Input placeholder="Enter Standard Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={7} md={7} sm={24} xs={24}>
                      <Form.Item
                        {...field}
                        name={[field.name, "standardUrl"]}
                        fieldKey={[field.key, "standardUrl"]}
                        rules={[
                          {
                            type: "url",
                            message: "Please input standard url!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Url" />
                      </Form.Item>
                    </Col>
                    <Col lg={7} md={7} sm={24} xs={24}>
                      <Form.Item
                        {...field}
                        name={[field.name, "pageNumber"]}
                        fieldKey={[field.key, "pageNumber"]}
                      >
                        <Input placeholder="Enter Page Number" />
                      </Form.Item>
                    </Col>
                    <StyledButton
                      type="default" style={{ padding: '8px', marginTop: '0px' }}
                      onClick={() => remove(field.name)}
                      icon={<DeleteOutlined />}
                    />
                  </Row>
                ))}
                <Form.Item>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add more standard information
                  </StyledButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Input.Group>
        <Form.Item>
          <Row justify="flex-start">
            <Col lg={24} md={24} sm={24} xs={24}>
              <StyledButton
                className="margin-5"
                type="custom"
                onClick={() => onClickPrevious()}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <span>Back</span>
              </StyledButton>
              <StyledButton
                disabled={disable}
                className="margin-5"
                //htmlType="submit"
                onClick={onClickNext}
                type="custom"
                loading={loadingCreateForm}
              >
                <span>Add New Standard</span>
              </StyledButton>
            </Col>
          </Row>
        </Form.Item>
      </FormWrapper>
    </FormWrap >
  );
};

export default step2;
