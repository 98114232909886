import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { CommonModal } from "../../../../shared/commonStyles";
import styles from "./EmissionsLibraryModal.module.scss";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { EmissionLibrary } from "../../EmissionLibrary";
import { SelectedItems } from "../../../../components/SelectedItems";
import { AutomatedEmissionsCalculation } from "./AutomatedEmissionsCalculation";
import CarbonService from "../../../../services/CarbonService";
import { getCompanyInfo } from "../../../../services/companyInfo";

interface EmissionsLibraryModalProps {
  isMultipleAllowed?: boolean;
  data?: Emission[];
  onAddEmission?: (dataList: Array<{ key: string } | null>) => void;
}

export interface FormattedCategories {
  sector: FormattedCategory[];
  region_name: FormattedCategory[];
  unit_type: FormattedCategory[];
  year: FormattedCategory[];
  source: FormattedCategory[];
  category: FormattedCategory[];
}

export interface FormattedCategory {
  name: string;
  checked?: boolean;
}

interface SelectedItem extends Factor {
  key: string;
  name: string;
  checked?: boolean | undefined;
}

export const EmissionsLibraryModal = observer(
  ({ isMultipleAllowed, data, onAddEmission }: EmissionsLibraryModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState<SelectedItem[]>([]);
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );
    const [factorsType, setFactorsType] = useState<
      "emissionsLibrary" | "customFactors"
    >("emissionsLibrary");
    const handleCancel = () => {
      setIsModalVisible(false);
      clearSelection();
    };
    const clearSelection = () => {
      setSelectedRows([]);
    };
    useEffect(() => {
      if (data?.length) {
        const defData = data.map((v) => ({
          ...v.factor_data,
          key: v?.key || "",
          checked: true,
          name: v?.factor_data?.name,
        }));

        setSelectedRows(defData as SelectedItem[]);
      } else setSelectedRows([] as SelectedItem[]);

      loadCompanyInfo();
    }, [isModalVisible]);

    const handleChangeCheckbox = (value: string) => {
      setSelectedRows &&
        setSelectedRows((prevValue) => {
          return prevValue.filter((key) => key?._id?.$oid !== value);
        });
    };
    const getIdsFromSelectedData = (selectedData: SelectedItem[]) => {
      return selectedData?.map((item) => {
        return item?._id?.$oid || "";
      });
    };
    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";

          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
        message.error("Error while load company details!");
      }
    };
    const rowSelection = (expandedData: Factor[]) => {
      return {
        type: "checkbox",
        selectedRowKeys: getIdsFromSelectedData(
          selectedRows || ([] as SelectedItem[])
        ),
        hideSelectAll: !isMultipleAllowed,
        onChange: (_selectedRowKeys: any, selectedItems: Array<any>) => {
          setSelectedRows &&
            setSelectedRows((prev) => {
              if (isMultipleAllowed) {
                const uniqueItems = new Set([
                  ...prev?.filter(
                    (item) =>
                      !expandedData?.find(
                        (v) => v?._id?.$oid === item?._id?.$oid
                      )
                  ),
                  ...selectedItems?.filter((item) => item),
                ]);

                return [...uniqueItems];
              }
              return !!selectedItems.length ? [selectedItems.pop()] : [];
            });
        },
      };
    };
    const onClickAddCalculation = async () => {
      const promises = selectedRows.map(async (v, index) => {
        if (
          !v.factor &&
          !v.factorCalculatedValue &&
          factorsType === "emissionsLibrary"
        ) {
          const factor = await CarbonService.getEmissionsFactor(v.id);
          const toFixedFactor = factor.toFixed(
            companyDetails.decimal_places_number
          );
          return {
            ...v,
            factorCalculatedValue: Number(toFixedFactor),
            isCalculatedFactor: true,
          };
        } else {
          if (v.factor) return { ...v };
          else {
            // message.error("This custom factor has no factor value");
            return null;
          }
        }
      });

      const rowsWithFactor = await Promise.all(promises);
      if (rowsWithFactor.includes(null)) {
        message.error("One of chosen custom factors has no factor value");
        
      } else {
        !!onAddEmission && onAddEmission(rowsWithFactor);
        setSelectedRows([]);
        setIsModalVisible(false);
      }
    };
    const selectedData = selectedRows.map((v) => ({
      ...v,
      key: v?._id?.$oid,
      checked: true,
    }));
    const isDisabled = !selectedData.filter((item) => item.checked).length;
    return (
      <>
        <Button
          className={styles.buttonOpenLibrary}
          onClick={() => setIsModalVisible(true)}
        >
          Open emissions library
        </Button>
        <CommonModal
          centered
          title="Library"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={handleCancel}
          footer={[
            <Button type="text" key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              key="confirm"
              onClick={onClickAddCalculation}
              disabled={isDisabled}
            >
              Select Emissions
            </Button>,
          ]}
          width="90%"
          className={styles.libraryModal}
        >
          <EmissionLibrary
            rowSelection={rowSelection}
            factorsType={factorsType}
            setFactorsType={setFactorsType}
          />
          <SelectedItems
            data={selectedData}
            onChange={(_checked, _index, value) => {
              handleChangeCheckbox(value);
            }}
            datatype="emissions"
          />
        </CommonModal>
      </>
    );
  }
);
