import React, { useState, useEffect, useRef } from "react";
import { Spin, Table } from "antd";
import { Auth, Storage } from "aws-amplify";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import logo from "../../../components/hydrus_app_logo.png";
import { Report } from "./styles";
import {StyledButton} from "./../../../shared/commonStyles";
import { columnsB, parseDataFromResponse} from "./util";
import { staticImagesList } from "../../../shared/constants";
import "./ReportCenterPage.css";

const ReportCenterPage = ({  
  dataLakeMapReport,
  setShowReportCenterPage,
  title,
  subtitle,
  primary,
  secondary,
  reportingColumnTitle,
  metricColumnTitle,
  reportTitleColor,
  reportDescriptionColor,
  tableHeaderTextColor,
  topicTextColor,
}) => {
  const [loadReportingData, setLoadingReportingData] = useState(false);
  const [reportDataForSingleTable, setReportDataForSingleTable] = useState([]);
  const [c_logo, setLogo] = useState(logo);
  const exportHtmlRef = useRef();

  const getFile = async () => {
    const data = await Auth.currentSession()
    const groups = data['accessToken']['payload']['cognito:groups'].filter(element => element.includes("org:"))[0];
    const a_logo = await Storage.get(`logos/${groups.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`)
    setLogo(a_logo)
  }  

  useEffect(() => {    
    setReportDataForSingleTable([]);
    if (dataLakeMapReport?.length) {
      setLoadingReportingData(true);
      dataLakeMapReport.forEach((item) => {        
        //const foundRecord = formItems?.find((d) => d?.data_id === item?._id.$oid);
        const data = parseDataFromResponse(item);        
        if (data) {
          setReportDataForSingleTable((prev) =>
            [...prev, data].sort((a, b) => a.theme.localeCompare(b.theme))
          );
        }
      });
      setLoadingReportingData(false);      
    }
  }, [dataLakeMapReport]);

  useEffect(() => {
    getFile();
  }, []);

  useEffect(() => {
    const getFile = async () => {
      const data = await Auth.currentSession();
      const groups = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      try {
        const a_logo = await Storage.get(
          `logos/${groups.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
        );
        setLogo(a_logo);
      } catch (e) {
        console.error("Unable to fetch logo", e);
      }
    };
    getFile();
  }, []);

  const onClickBackButton = () => {
    setShowReportCenterPage(false);
  };

  const logoErrorHandle = () => {
    setLogo(logo);
  };

  //const pageStyle = `} `;
  return (
    <div className="tableContainer reportTableContainer">
      <div className="action-buttons">
        <ReactToPrint
          trigger={() => (
            <StyledButton type="custom" disabled={loadReportingData}>
              Export Pdf{" "}
            </StyledButton>
          )}
          content={() => exportHtmlRef.current}
          // pageStyle={pageStyle}
        />
        {loadReportingData ? (
          <StyledButton type="custom" disabled={true}>
            Export Excel
          </StyledButton>
        ) : (
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="custom-primary-button"
            table="table-to-xls"
            filename="Report"
            sheet="Report"
            buttonText="Export Excel"
          />
        )}
        <StyledButton type="custom" onClick={() => onClickBackButton()}>
          Back
        </StyledButton>
      </div>
      <Spin spinning={loadReportingData}>
        <div>
          <table id="table-to-xls" style={{ display: "none" }}>
            <thead>
              <tr>
                <th>STANDARDS</th>
                <th>CATEGORY</th>
                <th>METRIC</th>
                <th>THEME</th>
                <th>SUMMARY</th>
                <th>CODE</th>
                <th>DATA</th>
              </tr>
            </thead>
            <tbody>
              {reportDataForSingleTable?.length
                ? reportDataForSingleTable.map((item) => {
                    return (
                      <tr key={item._id.$oid}>
                        <td>{item.standards}</td>
                        <td>{item.category}</td>
                        <td>{item.metric}</td>
                        <td>{item.theme}</td>
                        <td>{item.summary}</td>
                        <td>{item.specific_standard}</td>
                        <td>
                          {item?.form_data_details &&
                            item?.form_data_details?.map((data, index) => {
                              return (
                                <div key={item._id.$oid + "-" + index}>
                                  <p>
                                    {data.title}: {data.value}
                                  </p>
                                </div>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <Report
          ref={exportHtmlRef}
          primary={primary}
          secondary={secondary}
          id="standardsReport"
          reportTitleColor={reportTitleColor}
          reportDescriptionColor={reportDescriptionColor}
          tableHeaderTextColor={tableHeaderTextColor}
          topicTextColor={topicTextColor}
        >
          <div className="report-header">
            <div className="header-logo-wrap">
              <img src={c_logo} alt="hydrus logo" onError={logoErrorHandle} />
            </div>
            <div className="header-content-wrap">
              <p
                className="report-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            </div>
          </div>
          {reportDataForSingleTable?.length ? (
            <Table
            rowKey={(data) => {
              return data?.["_id"]?.["$oid"];
            }}
              columns={columnsB(metricColumnTitle, reportingColumnTitle)}
              dataSource={reportDataForSingleTable}
              pagination={false}
            />
          ) : null}

          <div
            className="report-footer"
            style={{ background: "white", borderTop: "1px solid #F0F0F0" }}
          >
            <a
              href="https://hydrus.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ color: "#929292" }}>powered by</span>
              <img
                src={staticImagesList.hydrusGreyLogo}
                alt="hydrus logo"
                onError={logoErrorHandle}
              />
            </a>
          </div>
        </Report>
      </Spin>
    </div>
  );
};

export default ReportCenterPage;
