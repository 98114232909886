import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoCircleOutlined,
  SwapOutlined,
  UndoOutlined,
  CloseOutlined,
  DownOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useBoolean, useSetState, useSize } from "ahooks";
import {
  Button,
  Dropdown,
  Radio,
  RadioChangeEvent,
  Space,
  Row,
  Col,
  Spin,
  message,
} from "antd";

import { ColumnsType } from "antd/lib/table";
import { toJS } from "mobx";
import { DateSelector } from "../../../components/DateSelector";
import { EmissionsFilter } from "../../../components/EmissionsFIlter";
import { SortEmissionsLibrary } from "../../../components/SortEmissionsLibrary/SortEmissionsLibrary";
import { SuggestedSearch } from "../../../components/SuggestedSearch";
import { CommonTable, FormWrapper } from "../../../shared/commonStyles";
import { calculatorStore, carbonStore } from "../../Carbon";
import { EmissionInfoPopover } from "../Calculator/Components/EmissionInfoPopover";
import {
  FormattedCategories,
  FormattedCategory,
} from "../Calculator/Components/EmissionsLibraryModal";
import styles from "./EmissionsLibrary.module.scss";
import { observer } from "mobx-react-lite";
import { getCompanyInfo } from "../../../services/companyInfo";
import carbonService from "../../../services/CarbonService";
type Search = string | RegExp;

interface FetchParams {
  pagination: Pagination;
  filter?: Array<Search>;
  sortEmission?: SortEmission;
}
interface EmissionLibraryProps {
  rowSelection?: (data: Factor[]) => void;
  factorsType?: string;
  setFactorsType: Dispatch<
    SetStateAction<"customFactors" | "emissionsLibrary">
  >;
}
export const EmissionLibrary = observer(
  ({ rowSelection, factorsType, setFactorsType }: EmissionLibraryProps) => {
    const [tablePagination, setTablePagination] = useState<Pagination>({
      current: 1,
      pageSize: 10,
    });

    const size = useSize(document.querySelector("body"));
    const [tableData, setTableData] = useState<AggregatedFactor[]>([]);
    const [expandedData, setExpandedData] = useState<any[]>([]);
    const [filterName, setFilterName] = useState("");
    const [filters, setFilters] = useState<IGHGCategories>(
      !!rowSelection
        ? ({
            region_name: calculatorStore.regions,
          } as IGHGCategories)
        : ({} as IGHGCategories)
    );
    const [sortEmission, setSortEmission] = useState<SortEmission>();
    const [sortExpanded, setSortExpanded] = useState<SortEmission>();
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );
    const [spinner, setSpinner] = useState<string>("");
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
      useBoolean(false);
    const getFormattedGHGCategories = (): FormattedCategories => {
      const newCategories: FormattedCategories = structuredClone(
        toJS(carbonStore.ghgCategories)
      ) as unknown as FormattedCategories;
      let key: keyof FormattedCategories;
      for (key in newCategories) {
        toJS(carbonStore.ghgCategories)?.[key]?.forEach((item, index) => {
          newCategories[key][index] = {
            name: item,
            checked: false,
          };
        });
      }
      return newCategories;
    };
    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";

          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
        message.error("Error while load company details!");
      }
    };
    const handleGetFactor = async (record: Factor, index: number) => {
      try {
        setSpinner(record?._id?.$oid);
        const factor = await carbonService.getEmissionsFactor(record.id);
        const toFixedFactor = factor.toFixed(
          companyDetails.decimal_places_number
        );
        const newexpandedData = expandedData.map((v, i) => {
          if (i == index) {
            return { ...v, factorCalculatedValue: toFixedFactor };
          } else {
            return { ...v };
          }
        });
        setExpandedData(newexpandedData);
      } catch (error) {
        console.log("Error while load emission factor", error);
        message.error("Error while load emission factor!");
      } finally {
        setSpinner("");
      }
    };
    const getExpandedFactors = (
      record: AggregatedFactor,
      sortEmission?: any,
      pagination?: Pagination
    ) => {
      const aggregationFilter = {
        category: record.category,
        name: record.name,
        sector: record.sector,
        unit_type: record.unit_type,
      };
      const newFilters = {
        region_name: filters.region_name,
        source: filters.source,
        year: filters.year,
        ...(sortEmission &&
          Object.keys(sortEmission)?.length && { sortEmission }),
        ...(pagination && { pagination }),
      };

      carbonStore.fetchFactors(
        factorsType === "customFactors",
        aggregationFilter,
        newFilters
      );
      setExpandedRows([record.key]);
    };
    const [formattedCategories, setFormattedCategories] =
      useSetState<FormattedCategories>(getFormattedGHGCategories());

    useEffect(() => {
      loadCompanyInfo();
      setExpandedData(toJS(carbonStore.factors));
    }, [carbonStore.factors]);

    useEffect(() => {
      setTableData(
        factorsType === "customFactors"
          ? carbonStore.aggregatedCustomFactors
          : carbonStore.aggregatedEmissionFactors
      );
    }, [
      carbonStore.aggregatedEmissionFactors,
      carbonStore.aggregatedCustomFactors,
    ]);

    useEffect(() => {
      setTablePagination(
        factorsType === "customFactors"
          ? carbonStore.aggregatedCustomFactorsPagination
          : carbonStore.aggregatedEmissionFactorsPagination
      );
    }, [
      carbonStore.aggregatedCustomFactorsPagination,
      carbonStore.aggregatedEmissionFactorsPagination,
    ]);

    const fetch = (params: FetchParams) => {
      carbonStore.fetchAggregatedFactors(
        params,
        factorsType === "customFactors"
      );
    };

    const getRefreshedFormattedGHGCategories = (
      initialCategories?: FormattedCategories
    ): FormattedCategories => {
      const baseCategories = initialCategories || formattedCategories;
      if (Object.keys(baseCategories).length === 0) {
        return baseCategories;
      }
      const newCategories: FormattedCategories = structuredClone(
        toJS(
          factorsType === "customFactors"
            ? carbonStore.customGhgCategories
            : carbonStore.ghgCategories
        )
      ) as unknown as FormattedCategories;
      let key: keyof FormattedCategories;
      for (key in newCategories) {
        newCategories[key] = toJS(
          factorsType === "customFactors"
            ? carbonStore.customGhgCategories
            : carbonStore.ghgCategories
        )[key].map((item) => {
          let isChecked = baseCategories[key].find(
            (element) => item === element.name
          )?.checked;
          if (key == "region_name" && filters.region_name) {
            isChecked = !!filters.region_name.find((region) => item === region);
          }
          return {
            name: item,
            checked: isChecked || false,
          };
        });
      }
      return newCategories;
    };

    useEffect(() => {
      fetch({ pagination: { ...tablePagination, current: 1 }, ...filters });
      carbonStore.fetchCategories(filters, "region_name", () => {
        setFormattedCategories(
          getRefreshedFormattedGHGCategories(getFormattedGHGCategories())
        );
      });
    }, [factorsType]);

    const handleChangeExpandedTable = (
      record: AggregatedFactor,
      pagination: Pagination,
      sorter?: any,
      extra?: any
    ) => {
      const newSorter =
        extra?.action === "sort"
          ? sorter?.column
            ? {
                sortAlphabetically: sorter?.order === "ascend",
                type: sorter?.field,
              }
            : ({} as SortEmission)
          : sortExpanded;

      extra?.action === "sort" && !!newSorter && setSortExpanded(newSorter);
      getExpandedFactors(record, newSorter, pagination);
    };

    const handleTableChange = (
      pagination: Pagination,
      _filters: any,
      sorter?: any,
      extra?: any
    ) => {
      const newSorter =
        extra?.action === "sort"
          ? sorter?.column
            ? {
                sortAlphabetically: sorter?.order === "ascend",
                type: sorter?.field,
              }
            : ({} as SortEmission)
          : sortEmission;
      extra?.action === "sort" && !!newSorter && setSortEmission(newSorter);
      fetch({
        pagination,
        sortEmission: newSorter,
        ...{ ...filters, name: filterName },
      });
    };

    const columns = (isExpanded: boolean) => {
      const columns: ColumnsType<Factor | AggregatedFactor> = [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: 300,
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: "Region",
          dataIndex: isExpanded ? "region" : "regions_count",
          key: isExpanded ? "region_name" : "regions_count",
          sorter: (a, b) =>
            isExpanded
              ? (a as Factor).region_name.localeCompare(
                  (b as Factor).region_name
                )
              : +(a as AggregatedFactor).regions_count -
                +(b as AggregatedFactor).regions_count,
        },
        {
          title: "Sector",
          dataIndex: "sector",
          key: "sector",
          sorter: (a, b) => a.sector.localeCompare(b.sector),
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          sorter: (a, b) => +a.source - +b.source,
        },
        {
          title: isExpanded ? "Year" : "Years",
          dataIndex: isExpanded ? "year" : "years",
          key: isExpanded ? "year" : "years",
          sorter: (a, b) =>
            isExpanded
              ? +(a as Factor).year - +(b as Factor).year
              : (a as AggregatedFactor).years.localeCompare(
                  (b as AggregatedFactor).years
                ),
        },
        {
          title: isExpanded ? "Unit" : "Unit Type",
          dataIndex: isExpanded ? "unit" : "unit_type",
          key: isExpanded ? "unit" : "unit_type",
          sorter: (a, b) =>
            isExpanded
              ? (a as Factor).unit.localeCompare((b as Factor).unit)
              : (a as AggregatedFactor).unit_type.localeCompare(
                  (b as AggregatedFactor).unit_type
                ),
        },
      ];
      if (isExpanded) {
        columns.splice(1, 0, {
          title: "",
          dataIndex: "",
          key: "",
          width: 100,
        });
        columns.push({
          title: "CO2e Factor",
          dataIndex: "factor",
          key: "factor",
          sorter: (a, b) => +(a as Factor).factor - +(b as Factor).factor,
          render: (_, record, index) => {
            return (
              <>
                {(record as Factor)?.factor ??
                  (record as Factor)?.factorCalculatedValue ?? (
                    <Spin spinning={spinner === (record as Factor)._id.$oid}>
                      <Button
                        onClick={() => handleGetFactor(record as Factor, index)}
                      >
                        <SearchOutlined />
                      </Button>{" "}
                    </Spin>
                  )}
              </>
            );
          },
        });
        columns.push({
          title: <InfoCircleOutlined />,
          dataIndex: "info",
          key: "info",
          render: (_: any, emission: Factor | AggregatedFactor) => {
            return <EmissionInfoPopover data={emission as Factor} />;
          },
        });
      } else {
        columns.splice(1, 0, {
          title: "Factors",
          dataIndex: "factors_count",
          key: "factors_count",
          sorter: (a, b) =>
            +(a as AggregatedFactor).factors_count -
            +(b as AggregatedFactor).factors_count,
        });
      }
      return columns;
    };

    const handleChangeFilterName = (name: string) => {
      setFilterName(name);

      fetch({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        ...{ ...filters, name: [name] },
      });

      carbonStore.fetchCategories({ ...filters, name: [name] }, "name", () => {
        setFormattedCategories(
          getRefreshedFormattedGHGCategories(getFormattedGHGCategories())
        );
      });
    };

    const handleChangeFilterItem = (
      data: FormattedCategory[],
      type: string
    ) => {
      const newFilters = filters;
      const newCategories = {
        ...formattedCategories,
        [type]: data,
      };
      const newData = data.filter((v) => v.checked).map((v) => v.name);
      newFilters[type] = newData;

      carbonStore.fetchCategories(
        { ...newFilters, ...(filterName.length && { name: [filterName] }) },
        filterName.length ? "name" : "region_name",
        () => {
          setFormattedCategories(
            getRefreshedFormattedGHGCategories(newCategories)
          );
        }
      );

      fetch({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        ...{ ...newFilters, ...(filterName.length && { name: filterName }) },
      });

      setFilters(newFilters);
    };

    const handleSort = () => {
      fetch({
        pagination: tablePagination,
        ...filters,
        sortEmission,
      });
    };

    const firstRegionName = Object.keys(formattedCategories).sort((x, y) =>
      x == "region_name" ? -1 : y == "region_name" ? 1 : 0
    );

    const clearFilters = () => {
      carbonStore.fetchCategories({}, "region_name", () => {
        setFormattedCategories(getFormattedGHGCategories());
      });
      setFilters({});
      setFilterName("");
    };

    const clearFitersTable = () => {
      clearFilters();

      fetch({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    };

    const handleChangeFactorsType = (e: RadioChangeEvent) => {
      setFactorsType(e.target.value);
      clearFilters();
    };

    return (
      <>
        <div>
          <Space>
            <FormWrapper>
              <Radio.Group
                onChange={handleChangeFactorsType}
                value={factorsType}
                className={styles.librarySelector}
              >
                <Radio.Button value="emissionsLibrary">
                  Emissions Library
                </Radio.Button>
                <Radio.Button value="customFactors">
                  Custom Factors
                </Radio.Button>
              </Radio.Group>
            </FormWrapper>
          </Space>
          <Space>
            <SuggestedSearch
              filterName={filterName}
              tableData={tableData}
              handleChangeFilterName={handleChangeFilterName}
              key={filterName} // will allow re-render if filtername changed on clearall
            />
            {firstRegionName.map((category, index) => (
              <div key={category}>
                {category === "year" ? (
                  <DateSelector
                    data={
                      formattedCategories[category as keyof FormattedCategories]
                    }
                    datatype={category.split("_")[0]}
                    onChange={handleChangeFilterItem}
                  />
                ) : (
                  <EmissionsFilter
                    data={
                      formattedCategories[category as keyof FormattedCategories]
                    }
                    datatype={category}
                    onChange={handleChangeFilterItem}
                  />
                )}
              </div>
            ))}
            <Button
              type="text"
              onClick={clearFitersTable}
              className={styles.clearSelectionsButton}
            >
              <UndoOutlined />
              <span>Clear all</span>
            </Button>
            <Button type="text" className={styles.sortButton}>
              <Dropdown
                overlay={
                  <SortEmissionsLibrary
                    sorting={sortEmission}
                    changeSorting={setSortEmission}
                    onConfirm={() => {
                      handleSort();
                      hideDropdown();
                    }}
                  />
                }
                trigger={["click"]}
                onVisibleChange={toggle}
                visible={isDropdownShown}
                placement="bottomRight"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <SwapOutlined rotate={90} className={styles.icon} />
                  <span>Sort</span>
                </a>
              </Dropdown>
            </Button>
          </Space>
          <FormWrapper>
            <CommonTable
              loading={
                carbonStore.loadingAggregatedCustomFactors ||
                carbonStore.loadingAggregatedEmissionsFactors
              }
              columns={columns(false)}
              dataSource={tableData}
              pagination={tablePagination}
              scroll={{ y: 400 }}
              tableLayout="fixed"
              rowKey={(data: any) => data.key}
              expandable={{
                showExpandColumn: true,
                expandedRowKeys: expandedRows,
                expandedRowRender: (record: any) => (
                  <CommonTable
                    rowSelection={rowSelection && rowSelection(expandedData)}
                    loading={carbonStore.loadingFactors}
                    pagination={carbonStore.factorsPagination}
                    rowKey={(data: any) => data?._id?.$oid}
                    columns={columns(true)}
                    dataSource={expandedData}
                    onChange={(
                      pagination: Pagination,
                      _filters: any,
                      sorter?: any,
                      extra?: any
                    ) =>
                      handleChangeExpandedTable(
                        record,
                        pagination,
                        sorter,
                        extra
                      )
                    }
                  />
                ),

                expandIcon: ({
                  expanded,
                  onExpand,
                  record,
                }: {
                  expanded: boolean;
                  onExpand: (
                    record: any,
                    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                  ) => void;
                  record: any;
                }) =>
                  expanded ? (
                    <DownOutlined
                      onClick={(e) => {
                        onExpand(record, e);
                        setExpandedRows([]);
                      }}
                    />
                  ) : (
                    <RightOutlined
                      onClick={(e) => {
                        onExpand(record, e);
                        getExpandedFactors(record);
                      }}
                    />
                  ),
                rowExpandable: (record: any) => record.key === expandedRows[0],
              }}
              className={styles.emissionsLibraryTable}
              onChange={handleTableChange}
            />
          </FormWrapper>
        </div>
      </>
    );
  }
);
