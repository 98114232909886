import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { useBoolean, useDynamicList } from "ahooks";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Margin,
  Tooltip,
  ArgumentAxis,
} from "devextreme-react/chart";
import _ from "lodash";
import moment from "moment";
import {
  FormWrapper,
  StyledButton,
  StyledSegmented,
} from "../../../../../shared/commonStyles";
import styles from "./SingleTarget.module.scss";

interface ChartType {
  key: string;
  period: Date;
  actual?: number;
  plan?: number;
  prognosis?: number;
}

interface ProgressChartProps {
  data: Target;
  reductionPlan: Plan;
}

export const ProgressChart = ({ data, reductionPlan }: ProgressChartProps) => {
  const { list, resetList } = useDynamicList<ChartType>([]);
  const [period, setPeriod] = useState("All");
  const [customDate, setCustomDate] = useState<RangeValue>([
    moment().startOf("year"),
    moment().endOf("year"),
  ]);
  const [
    isDateModalVisible,
    { setTrue: showDateModal, setFalse: hideDateModal },
  ] = useBoolean(false);

  const now = moment();
  const year = moment().subtract(1, "years");
  const sixMonth = moment().subtract(6, "months");
  const threeMonth = moment().subtract(3, "months");
  const month = moment().subtract(1, "months");

  useEffect(() => {
    const newList: ChartType[] =
      data.actualConsumptionEntries?.map((v) => {
        return {
          key: v.date,
          period: new Date(v.date),
          actual: +v.consumptionAmount,
        };
      }) || [];

    const prognosisList = [
      {
        key: data.baseYear,
        period: new Date(data.baseYear),
        prognosis: +data.baseYearConsumptionAmount,
      },
      {
        key: data.targetYear,
        period: new Date(data.targetYear),
        prognosis: +data.goal,
      },
    ];

    prognosisList.forEach((v) => {
      const samePeriod = newList.find(
        (item) => item.period === new Date(v.period)
      );
      if (samePeriod) {
        newList[newList.indexOf(samePeriod)] = {
          ...samePeriod,
          prognosis: v.prognosis,
        };
      } else {
        newList.push(v);
      }
    });

    resetList(newList);
  }, [data]);

  useEffect(() => {
    const newList = list.filter(
      (v) => v.key !== "planStart" && v.key !== "planEnd" && v.key !== "current"
    );

    newList.push({
      key: "planStart",
      period: new Date(reductionPlan.startDate),
      plan: +data.baseYearConsumptionAmount,
    });
    newList.push({
      key: "planEnd",
      period: new Date(reductionPlan.endDate),
      plan: +reductionPlan.goal,
    });
    resetList(newList);
  }, [reductionPlan]);

  const getVisualRange = () => {
    switch (period) {
      case "All":
        return { startValue: null, endValue: null };
      case "12 month":
        return {
          startValue: year.toDate(),
          endValue: now.toDate(),
        };
      case "6 month":
        return {
          startValue: sixMonth.toDate(),
          endValue: now.toDate(),
        };
      case "3 month":
        return {
          startValue: threeMonth.toDate(),
          endValue: now.toDate(),
        };
      case "30 days":
        return {
          startValue: month.toDate(),
          endValue: now.toDate(),
        };
      case "Custom period":
        return {
          startValue: customDate?.[0].toDate(),
          endValue: customDate?.[1].toDate(),
        };
      default:
        return { startValue: null, endValue: null };
    }
  };

  const handleChangePeriod = (value: string) => {
    setPeriod(value);
  };

  const handleChangeCustom = () => {
    showDateModal();
  };

  const handleChangeDate = (value: RangeValue) => {
    setPeriod("Custom period");
    setCustomDate(value);
  };

  const dateFormat = "YYYY-MM-DD";

  const getDatePanel = (panelNode: React.ReactNode) => {
    return (
      <>
        <p className={styles.dateRangeTitle}>Time period</p>
        <div className={styles.dateRangeResult}>
          <span>{customDate?.[0]?.format(dateFormat)}</span> -{" "}
          <span>{customDate?.[1]?.format(dateFormat)}</span>
        </div>
        <div className={styles.dateRangeSelector}>{panelNode}</div>
        <div className={styles.dateRangeButtons}>
          <StyledButton type="primary" onClick={hideDateModal}>
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <div className={styles.generalInfoBlock}>
      <Row className={styles.title} justify="space-between">
        <Col>Progress Chart</Col>
        <Col>
          <StyledSegmented
            options={[
              "All",
              "12 month",
              "6 month",
              "3 month",
              "30 days",
              {
                value: "Custom period",
                label: <div onClick={handleChangeCustom}>Custom period</div>,
              },
            ]}
            value={period}
            onChange={handleChangePeriod}
          />
        </Col>
      </Row>
      <Row>
        <Col span={18} />
        <Col span={6}>
          <FormWrapper>
            <DatePicker.RangePicker
              className={
                period === "Custom period" && isDateModalVisible
                  ? styles.datePicker
                  : styles.datePickerHidden
              }
              open={period === "Custom period" && isDateModalVisible}
              onChange={handleChangeDate}
              panelRender={getDatePanel}
              placeholder={["Date", "Date"]}
              dropdownClassName={styles.dateRangeBox}
              format={dateFormat}
              value={customDate}
              ranges={{
                "Date Presets": [moment(), moment()],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "Last Year": [
                  moment().startOf("year").subtract(1, "year"),
                  moment().endOf("year").subtract(1, "year"),
                ],
                Custom: [moment(), moment()],
              }}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Chart dataSource={list} className={styles.chart}>
            <CommonSeriesSettings argumentField="period" barWidth={3} />
            <Series
              type="line"
              valueField="actual"
              name="Actual"
              color="#fff"
            />
            <Series
              type="spline"
              valueField="prognosis"
              name="Prognosis"
              color="#A68DFB"
            />
            <Series
              type="spline"
              valueField="plan"
              name="Plan"
              color="#F5222D"
            />
            <ArgumentAxis visualRange={getVisualRange()} />
            <Margin bottom={20} />
            <Legend
              verticalAlignment="center"
              horizontalAlignment="right"
              itemTextPosition="right"
            />
            <Tooltip enabled={true} />
          </Chart>
        </Col>
      </Row>
    </div>
  );
};
