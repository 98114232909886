import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  AuditOutlined,
  BellOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  RollbackOutlined,
  SwapOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Space,
  Tag,
  Typography,
  Select,
  Spin,
  message,
  Form,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  listAuditManagements,
  sendTaskReminder,
  sendUserReminderEmail,
  updateAuditManagement,
  getAssignedAuditorList,
} from "../../../services/audits";
import {
  CommonSelect,
  CommonTable,
  FiltersContainer,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { Auth } from "aws-amplify";
import Assignees from "./Assignees";
import AuditDetailCard from "./AuditDetailCard";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import ConfirmRestoreModal from "./ConfirmRestoreModal";
import ChangeStatusModal from "./ChangeStatusModal";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { getUserGroupById } from "./../../../services/users";
import { encodeUrlName } from "../../../shared/commonFunctions";
const { Title } = Typography;
const { Option } = Select;

const AuditDataTable = ({ isDeleted }) => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [rowDataView, setRowDataView] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [hideColumn, setHideColumn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filteredAuditData, setFilteredAuditData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [metricData, setMetricData] = useState(null);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [archive, setArchive] = useState("");
  const [externalTableFilters, setExternalTableFilters] = useState({
    audit_type: null,
    priority: null,
    status: null,
  });
  const [initialValues, setInitialValues] = useState({
    state: "",
  });
  const [showUserReminderConfirmModal, setShowUserReminderConfirmModal] =
    useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const renderStatusTag = (key) => {
    if (key === "COMPLETED") {
      return (
        <Tag color="#5BA85A" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "IN_PROGRESS") {
      return (
        <Tag color="#1890FF" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "DELETED") {
      return (
        <Tag color="#807C8B" className="tag-rounded">
          {key}
        </Tag>
      );
    } else {
      return (
        <Tag color="#FF0101" className="tag-rounded">
          {key}
        </Tag>
      );
    }
  };
  const iconTOuse = (val) => {
    if (val === 1) {
      return (
        <ArrowUpOutlined style={{ marginRight: "10px", color: "#EB5757" }} />
      );
    }
    if (val === 2) {
      return <SwapOutlined style={{ marginRight: "10px", color: "#F2994A" }} />;
    }
    if (val === 3) {
      return (
        <ArrowDownOutlined style={{ marginRight: "10px", color: "#F2C94C" }} />
      );
    }
  };
  const priorityTag = (priority) => {
    const prio = {
      1: "High",
      2: "Medium",
      3: "Low",
    };
    const intPriority = parseInt(priority);
    return (
      <span priority={intPriority}>
        {iconTOuse(intPriority)}
        {prio[intPriority]}
      </span>
    );
  };

  const loadAuditdata = async () => {
    try {
      setLoading(true);
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      const auditResponse = await listAuditManagements();
      if (auditResponse) {
        let audits = null;
        if (isDeleted) {
          audits = auditResponse.filter((item) => item.status === "DELETED");
        } else {
          const filteredAudit = auditResponse.filter(
            (item) => item.status != "DELETED"
          );
          if (checkRolesPermission(["role:auditor"])) {
            const assignedaudits = await Promise.all(
              filteredAudit?.map(async (item) => {
                const assignedUsers = await getAssignedAuditorList(item);
                const findUser = assignedUsers?.filter(
                  (item) => item === username
                );
                return {
                  ...item,
                  key: item.id,
                  isAuditAccessible: findUser?.length > 0,
                };
              })
            );
            audits = assignedaudits.filter((item) => item.isAuditAccessible);
          } else {
            audits = filteredAudit;
          }
        }
        setDataSource(audits);
        setFilteredAuditData(audits);
      } else {
        message.error("Failed to load audit list");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Error while listing audit records");
    } finally {
      setLoading(false);
    }
  };

  const loadMetricData = async () => {
    try {
      setLoadingMetrics(true);
      const { audit_type, priority, status } = externalTableFilters;
      const data = await sendTaskReminder(
        "AUDIT_METRICS",
        audit_type,
        priority,
        status
      );
      if (data) {
        setMetricData(data ? JSON.parse(data) : "");
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoadingMetrics(false);
    }
  };
  useEffect(() => {
    loadAuditdata();
  }, [isDeleted]);

  useEffect(() => {
    loadMetricData();
  }, [externalTableFilters]);

  const onTableRowClick = (record) => {
    setHideColumn(true);
    setRowDataView(record);
  };

  const onTableRowClose = () => {
    setHideColumn(false);
  };

  const onClickRestore = async (data, type) => {
    setRowData(data);
    setShowRestoreModal(true);
    setArchive(type);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Assignee",
      dataIndex: "assignees",
      key: "assignees",
      render: (_, record) => {
        return <Assignees record={record} />;
      },
    },
    {
      title: "Type",
      dataIndex: "audit_type",
      key: "audit_type",
      hidden: hideColumn === true ? true : false,
      render: (_, record) => {
        return record?.audit_type === "DATA_SHEETS"
          ? "Data Sheets"
          : record?.audit_type === "GHG_EMISSIONS"
          ? "GHG Emissions"
          : "Sustainability Standards";
      },
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      key: "deadline",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      hidden: hideColumn === true ? true : false,
      render: (record, data) => {
        return <span>{priorityTag(record)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => {
        const message = data?.status ? data.status : "";
        const messageColor = renderStatusTag(message);

        return <>{messageColor}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, data) => {
        const isCompleted = data.status === "COMPLETED";
        return (
          <Space direction="horizontal">
            {isDeleted ? (
              <StyledButton
                type="default"
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "11px 18px",
                  textAlign: "left",
                }}
                icon={<RollbackOutlined />}
                onClick={() => onClickRestore(data, "restore")}
              >
                Restore
              </StyledButton>
            ) : (
              <>
                <Tooltip title="Open & Perform Audit">
                  <StyledButton
                    type="default"
                    onClick={() =>
                      navigate(
                        `/audit/manage/${data?.id}/${encodeUrlName(
                          data.name?.replace("/", " ")
                        )}/view`
                      )
                    }
                  >
                    <AuditOutlined />
                  </StyledButton>
                </Tooltip>
                <Tooltip title="Audit Details">
                  <StyledButton
                    type="default"
                    onClick={() => onTableRowClick(data)}
                  >
                    <InfoCircleOutlined />
                  </StyledButton>
                </Tooltip>
                <Tooltip title="Modify Audit Settings">
                  <StyledButton
                    type="default"
                    disabled={isCompleted}
                    onClick={() => {
                      navigate(`/audit/manage/${data?.id}/update-audit`);
                    }}
                  >
                    <EditOutlined />
                  </StyledButton>
                </Tooltip>
                <Tooltip title="Send Reminder Email">
                  <StyledButton
                    type="default"
                    disabled={isCompleted}
                    onClick={() => sendUserReminder(data)}
                  >
                    <BellOutlined />
                  </StyledButton>
                </Tooltip>
                <Tooltip title="Delete Audit">
                  <StyledButton
                    type="default"
                    onClick={() => onDeleteRowClick(data, "delete")}
                  >
                    <DeleteOutlined />
                  </StyledButton>
                </Tooltip>
              </>
            )}
          </Space>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const onChangeExternalFilters = (data, filterName) => {
    try {
      setHideColumn(false);
      setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
    } catch (error) {
      console.log("Error while filtering table");
    }
  };

  useEffect(() => {
    setRowDataView(null);
    let filterAuditDataList = dataSource ? [...dataSource] : [];
    const { status, audit_type, priority } = externalTableFilters;
    if (status?.length) {
      filterAuditDataList = filterAuditDataList.filter((item) =>
        status.includes(item.status)
      );
    }
    if (audit_type?.length) {
      filterAuditDataList = filterAuditDataList.filter((item) =>
        audit_type.includes(item.audit_type)
      );
    }
    if (priority?.length) {
      filterAuditDataList = filterAuditDataList.filter((item) =>
        priority.includes(parseInt(item.priority))
      );
    }
    setFilteredAuditData(filterAuditDataList);
  }, [externalTableFilters, dataSource]);

  const { audit_type, priority, status } = externalTableFilters;

  const onDeleteRowClick = (record, type) => {
    setShowConfirmModal(true);
    setRowData(record);
    setArchive(type);
  };

  const onConfirmAuditDelete = async () => {
    if (!rowData) return;
    try {
      setLoading(true);
      delete rowData.createdAt;
      delete rowData.updatedAt;

      if (archive === "delete") {
        rowData.status = "DELETED";
      } else {
        rowData.status = "IN_PROGRESS";
      }

      const { __typename, ...payload } = rowData;

      console.log("DELETE", payload); // Use payload here for logging
      const response = await updateAuditManagement(payload); // Pass payload instead of rowData

      if (response) {
        message.success(
          `Audit ${archive === "delete" ? "deleted" : "restored"} successfully`
        );
        loadAuditdata();
        loadMetricData();
        setShowConfirmModal(false);
        setShowRestoreModal(false);
      }
    } catch (error) {
      message.error("Error while deleting audit");
      console.log("Error while deleting audit", error);
    } finally {
      setLoading(false);
    }
  };

  const sendUserReminder = async (record) => {
    setRowData(record);
    setShowUserReminderConfirmModal(true);
  };
  const onUserReminderConfirmed = async () => {
    try {
      setLoading(true);
      const response = await sendUserReminderEmail("AUDIT", rowData?.id);
      if (response) {
        message.success("Reminder notification sent successfully!");
        setShowUserReminderConfirmModal(false);
      }
    } catch (error) {
      message.error("Something went wrong  while sending task notification!");
      console.log(
        "Something went wrong  while sending task notification!",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatus = async () => {
    setShowChangeStatusModal(true);
  };
  const onClickUpdateStatus = async () => {
    form.validateFields().then(async () => {
      const { status } = initialValues;
      try {
        setLoading(true);
        const payload = rowDataView;
        delete payload.createdAt;
        delete payload.updatedAt;
        payload.status = status;
        const response = await updateAuditManagement(payload);
        if (response) {
          message.success("Status changed successfully");
          loadMetricData();
          setShowChangeStatusModal(false);
        }
      } catch (error) {
        message.error("Error while updating audit status");
        console.log("Error while updating audit status", error);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <FormWrapper>
      <FiltersContainer>
        <FormWrapper>
          <Row gutter={[16]}>
            <Col lg={4} md={6} sm={12} xs={24}>
              <CommonSelect
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="All Types"
                value={audit_type}
                onChange={(data) => onChangeExternalFilters(data, "audit_type")}
              >
                <Option key="1" value="DATA_SHEETS">
                  DATA SHEETS
                </Option>
                <Option key="2" value="GHG_EMISSIONS">
                  GHG EMISSIONS
                </Option>
                <Option key="3" value="SUSTAINABILITY_STANDARDS">
                  SUSTAINABILITY STANDARDS
                </Option>
              </CommonSelect>
            </Col>
            <Col lg={4} md={6} sm={12} xs={24}>
              <CommonSelect
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="All Priorities"
                value={priority}
                onChange={(data) => onChangeExternalFilters(data, "priority")}
              >
                <Option key="1" value="1">
                  High
                </Option>
                <Option key="2" value="2">
                  Medium
                </Option>
                <Option key="3" value="3">
                  Low
                </Option>
              </CommonSelect>
            </Col>
            {!isDeleted && (
              <Col lg={4} md={6} sm={12} xs={24}>
                <CommonSelect
                  allowClear
                  showArrow
                  style={{ width: "100%" }}
                  placeholder="All Statuses"
                  value={status}
                  onChange={(data) => onChangeExternalFilters(data, "status")}
                >
                  <Select.Option key="1" value="IN_PROGRESS">
                    In Progress
                  </Select.Option>
                  <Select.Option key="2" value="COMPLETED">
                    Completed
                  </Select.Option>
                </CommonSelect>
              </Col>
            )}
          </Row>
        </FormWrapper>
      </FiltersContainer>
      {!isDeleted && (
        <Spin spinning={loadingMetrics}>
          <Row gutter={[16, 18]} style={{ marginTop: 20, marginBottom: 20 }}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #77f4fc",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Total Audits</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.total_audits}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #77FCA4",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Open</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.total_open}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #7794fc",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Overdue</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.total_overdue}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #BB8CEA",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Closed</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.total_closed}
                </Title>
              </Card>
            </Col>
          </Row>
        </Spin>
      )}
      <Row gutter={16}>
        <Col
          xs={24}
          sm={24}
          md={hideColumn ? 16 : 24}
          lg={hideColumn ? 16 : 24}
        >
          <CommonTable
            rowKey={(data) => data?.id}
            loading={loading}
            dataSource={filteredAuditData}
            columns={columns}
            scroll={{ x: true }}
          />
        </Col>
        {hideColumn ? (
          <Col xs={24} sm={24} md={8} lg={8}>
            <AuditDetailCard
              onTableRowClose={onTableRowClose}
              rowDataView={rowDataView}
              renderStatusTag={renderStatusTag}
              priorityTag={priorityTag}
              navigate={navigate}
              sendUserReminder={sendUserReminder}
              onChangeStatus={onChangeStatus}
              loading={loading}
            />
          </Col>
        ) : null}
      </Row>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          description="Are you sure you want to delete this audit?"
          okText="Delete"
          visible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmAuditDelete}
          ConfirmLoader={loading}
        />
      )}
      <ConfirmRestoreModal
        isVisible={showRestoreModal}
        onClose={() => setShowRestoreModal(false)}
        onConfirm={onConfirmAuditDelete}
        confirmLoading={loading}
      />
      <ConfirmModal
        title="Confirmation"
        description="Are you sure you want to remind the user?"
        visible={showUserReminderConfirmModal}
        onClose={() => setShowUserReminderConfirmModal(false)}
        onConfirm={() => onUserReminderConfirmed()}
        ConfirmLoader={loading}
      />
      <ChangeStatusModal
        visible={showChangeStatusModal}
        onClose={() => setShowChangeStatusModal(false)}
        rowData={rowDataView}
        onClickUpdateStatus={onClickUpdateStatus}
        loading={loading}
        setInitialValues={setInitialValues}
        initialValues={initialValues}
        form={form}
        setShowChangeStatusModal={setShowChangeStatusModal}
      />
    </FormWrapper>
  );
};

export default AuditDataTable;
