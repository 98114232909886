import React, { useEffect, useState } from "react";
import { Button, Badge, Row, Form } from "antd";
import { useBoolean } from "ahooks";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import _ from "lodash";
import { DataSheetFilters } from "../../../../components/DataSheetFilters";
import styles from "./AdvancedDataFilters.module.scss";

interface AdvancedDataFiltersProps {
  selectedActivityFields: string;
  activityFieldsVisible: boolean;
  filters?: AdvancedDataFilter[];
  onConfirm: (data: AdvancedDataFilter[]) => void;
  selectedDataSheet?: DataSheet;
}

export const AdvancedDataFilters = ({
  selectedActivityFields,
  activityFieldsVisible,
  filters,
  onConfirm,
  selectedDataSheet,
}: AdvancedDataFiltersProps) => {
  const [form] = Form.useForm();
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const mapFilters = (filters?: AdvancedDataFilter[]) =>
    filters?.map((item: AdvancedDataFilter) => {
      if (item.op === "isEmpty" && item.column_value === true) {
        return { ...item, column_value: "" };
      } else if (item.op === "isEmpty" && item.column_value === false) {
        return {
          ...item,
          op: "isNotEmpty",
          column_value: "",
        };
      } else {
        return item;
      }
    }) || [];
  const [formInitialValue, setFormInitialValue] = useState({
    filters: mapFilters(filters),
  });
  const [initialValues, setInitialValues] = useState({
    filters: mapFilters(filters),
  });
  useEffect(() => {
    setInitialValues({ filters: mapFilters(filters) });
    setFormInitialValue({ filters: mapFilters(filters) });
    form.setFieldsValue({ filters: mapFilters(filters) });
  }, [filters]);

  const badgeCount = formInitialValue.filters?.find(
    (v) => Object.keys(v).length === 0
  )
    ? formInitialValue.filters?.length - 1
    : formInitialValue.filters?.length || 0;

  const handleClearFilters = () => {
    setFormInitialValue({ filters: [] });
    form.setFieldsValue({ filters: [] });
  };

  const onCancel = () => {
    setFormInitialValue(initialValues);
    form.setFieldsValue(initialValues);
    hideModal();
  };

  const handleConfirm = (data: any) => {
    const newFilters = data.filters?.map(
      (item: AdvancedDataFilter) =>
        (item.op === "isEmpty" && { ...item, column_value: true }) ||
        (item.op === "isNotEmpty" && {
          ...item,
          op: "isEmpty",
          column_value: false,
        }) ||
        item
    );
    onConfirm(newFilters);
    hideModal();
  };

  const resetInput = (key: number) => {
    const formValues = form.getFieldsValue(true);
    const filtersValues = formValues.filters?.map(
      (item: AdvancedDataFilter, index: number) => {
        if (!item) {
          return {} as AdvancedDataFilter;
        } else if (index === key) {
          return { ...item, op: null, column_value: "" };
        } else {
          return item;
        }
      }
    );

    form.setFieldsValue({ ...formValues, filters: filtersValues });
    setFormInitialValue({ ...formValues, filters: filtersValues });
  };

  const handleChangeFilters = () => {
    const formValues = form.getFieldsValue(true);

    const filters = formValues.filters.map((item: AdvancedDataFilter) =>
      !item ? ({} as AdvancedDataFilter) : item
    );
    setFormInitialValue({ ...formValues, filters: filters });
  };

  return (
    <>
      <Button
        type="primary"
        className={
          activityFieldsVisible
            ? styles.buttonOpenAdvancedDataFilters
            : styles.buttonOpenAdvancedDataFiltersHidden
        }
        onClick={() => {
          showModal();
        }}
        disabled={!selectedActivityFields}
      >
        <span className={styles.buttonOpenAdvancedDataFiltersText}>
          Advanced data filters
        </span>
        <Badge
          className={
            badgeCount > 0 && styles.buttonOpenAdvancedDataFiltersBadge
          }
          count={badgeCount}
          size="small"
        />
      </Button>
      <FormWrapper
        className={
          isModalVisible
            ? styles.selectionDataFiltersBox
            : styles.selectionDataFiltersBoxHidden
        }
      >
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleConfirm}
          autoComplete="off"
        >
          <Form.Item label="">
            <DataSheetFilters
              initialFilters={initialValues?.filters as AdvancedDataFilter[]}
              formFilters={formInitialValue?.filters as AdvancedDataFilter[]}
              onChangeFilters={handleChangeFilters}
              dataSheet={selectedDataSheet as DataSheet}
              resetInput={resetInput}
              addButtonText="Add field"
              hideOwnersField
            />
          </Form.Item>
          <Form.Item>
            <Row justify="space-between" className={styles.footerButtons}>
              <div>
                <Button type="text" onClick={onCancel}>
                  Cancel
                </Button>
                <StyledButton type="primary" htmlType="submit">
                  Confirm
                </StyledButton>
              </div>
              <Button type="text" onClick={handleClearFilters}>
                Clear All
              </Button>
            </Row>
          </Form.Item>{" "}
        </Form>
      </FormWrapper>
    </>
  );
};
