import {
  Button,
  Col,
  Divider,
  Row,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import {
  LinkOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { fileUploadSize, fileUploadTypes } from "../../../shared/constants";

import { Theme as AntDTheme } from "@rjsf/antd";
import CkFormData from "../Components/CkFormData";
import { StyledButton } from "../../../shared/commonStyles";
import { downloadDataLakeFileHandler } from "../../../shared/commonFunctions";
import { getDataLakeFiles } from "../../../services/standards";
import { observer } from "mobx-react-lite";
import styles from "./SurveyView.module.scss";
import { surveyStore } from "../../../stores/SurveyStore";
import { useSize } from "ahooks";
import { withTheme } from "@rjsf/core";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";

const { Title } = Typography;
const Form = withTheme(AntDTheme);

const StandardsForm = observer(function StandardsForm({
  onClickPrevious,
  onClickNext,
  completeSurvey,
  openDrawer,
}) {
  const [formData, setFormData] = useState({});
  const [clickedButton, setClickedButton] = useState("");
  // Set default states for the two statuses, status and publish_status (bool)
  const [status, setStatus] = useState("pending");
  const [publishStatus, setpublishStatus] = useState(false);
  const [formDisable] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [ckFormData, setCkFormData] = useState(
    surveyStore.standardData?.response
  );
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const size = useSize(document.querySelector("body"));
  const [isSubmitModal, setSubmitModal] = useState(false);

  useEffect(() => {
    if (
      surveyStore.standardData &&
      Object.keys(surveyStore.standardData)?.length !== 0
    ) {
      const newData = {
        ...surveyStore.standardData,
        response: ckFormData ? ckFormData : "",
        publish_status: publishStatus,
        user_status: status,
      };
      surveyStore.changeSurveyData(newData, "dataToSave");
    }
  }, [ckFormData, formData, publishStatus, status]);

  useEffect(() => {
    surveyStore.changeSurveyData(files, "files");
  }, [files]);

  const loadDataLakeFiles = async (dataId) => {
    setFiles([]);
    setUploadedFilesList([]);
    setLoaderUploadedFilesList(true);
    // load uploaded files from s3
    try {
      const filesList = await getDataLakeFiles(dataId);
      if (filesList && filesList !== null) {
        const list = filesList
          .replace(/['"]+/g, "")
          .replace(/^\[(.+)\]$/, "$1")
          .split(",");
        setUploadedFilesList(list);
      }
    } catch (error) {
      console.log("Error while fetching files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  useEffect(() => {
    if (
      surveyStore.standardData &&
      Object.keys(surveyStore.standardData)?.length !== 0
    ) {
      setFormData(
        surveyStore.standardData?.form_data
          ? surveyStore.standardData?.form_data
          : ""
      );
      setCkFormData(
        surveyStore.standardData?.response
          ? surveyStore.standardData?.response
          : ""
      );
      setStatus(surveyStore.standardData.user_status);
      setpublishStatus(
        surveyStore.standardData?.publish_status
          ? surveyStore.standardData?.publish_status
          : false
      );
      loadDataLakeFiles(surveyStore.standardData?._id.$oid);
    }
  }, [surveyStore.standardData, refreshUploadedFiles]);
  const onFormSubmit = ({ errors }) => {
    if (errors.length === 0) {
      onClickSubmit();
    }
  };

  const onClickSubmit = async () => {
    try {
      surveyStore.standardData && (await surveyStore.updateStandardData());
      if (surveyStore.currentStep === surveyStore.surveySteps?.length - 1) {
        clickedButton === "submit" && (await completeSurvey());
      } else {
        message.success("Form data was saved successfully!");

        onClickNext();
      }
      setFormData({});
    } catch (error) {
      message.error("Error while saving form data. Please contact support.");
      console.log(error);
    }
  };

  const beforeUpload = async (file) => {
    const filename = file?.name;
    const fileSize = file?.size;
    const ext = filename && filename?.split(".")?.pop()?.toLowerCase();

    if (!fileUploadTypes.includes(ext)) {
      message.error(`${file?.name} is not a valid file type`);
      return;
    }

    if (fileSize > fileUploadSize) {
      message.error(`File upload size is larger than 25MB`);
      return;
    }

    setFiles([...files, file]);
    return false;
  };

  const onRemoveFileFromListHandler = (file) => {
    const filesList = files.filter((v) => v.url !== file.url);
    setFiles(filesList);
  };

  const downloadFileHandler = async (key) => {
    try {
      const dataId = surveyStore.standardData?._id.$oid;
      if (dataId) {
        setLoaderUploadedFilesList(true);
        await downloadDataLakeFileHandler(key, dataId);
      }
    } catch (error) {
      console.log("Error while upload file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };
  const uiSchema = useMemo(
    () => surveyStore.standardData?.form_ui_code,
    [surveyStore.standardData]
  );
  const clearData = () => {
    surveyStore.changeSurveyData(surveyStore.standardData, "dataToSave");
  };

  const viewInfoButton = size && size?.width <= 900 && (
    <Col span={24}>
      <Button
        style={{
          color: " #fff",
          borderColor: "#fff",
          width: "100%",
          height: "40px",
        }}
        type="ghost"
        onClick={openDrawer}
        className="margin-5"
      >
        <QuestionCircleOutlined /> View Information & Progress
      </Button>
    </Col>
  );
  const footerButtons = (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Row
        justify={size && size?.width <= 900 ? "center" : "space-between"}
        style={{ flexFlow: "nowrap" }}
      >
        {surveyStore.currentStep >= 0 && (
          <Col>
            <StyledButton
              type="custom"
              onClick={() => {
                onClickPrevious();
                clearData();
              }}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              className="PrevButton"
              disabled={surveyStore.currentStep == 0 ? true : false}
            >
              {size && size?.width <= 767 ? <>Back</> : "Previous Step"}
            </StyledButton>
          </Col>
        )}
        {(surveyStore.currentStep === surveyStore.surveySteps?.length - 1 ||
          surveyStore.currentStepData.standardData) && (
          <Col>
            <StyledButton
              type="custom"
              htmlType="submit"
              onClick={() =>
                surveyStore.currentStep === surveyStore.surveySteps?.length - 1
                  ? setSubmitModal(true)
                  : setClickedButton("submit")
              }
              submitting={surveyStore.surveySubmitting}
            >
              {surveyStore.surveySteps?.length - 1 !== surveyStore.currentStep
                ? "Save & Go to the next step"
                : "Submit and Complete Survey"}
            </StyledButton>
          </Col>
        )}
        {surveyStore.surveySteps?.length - 1 !== surveyStore.currentStep ? (
          <Col>
            {" "}
            <StyledButton
              type="custom"
              onClick={() => {
                onClickNext();
                clearData();
              }}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
            >
              {size && size?.width <= 767
                ? "Skip"
                : "Skip and Go to the next Step"}
            </StyledButton>
          </Col>
        ) : (
          surveyStore.currentStepData.standardData && (
            <StyledButton
              disabled={surveyStore.surveyInfo.status === "INACTIVE"}
              type={size && size?.width > 767 ? "text" : "link"}
              loading={surveyStore.surveySubmitting}
              onClick={() => setClickedButton("save")}
              htmlType="submit"
            >
              Save
            </StyledButton>
          )
        )}
      </Row>
    </div>
  );
  return (
    <>
      <SubmitConfirmationModal
        visible={isSubmitModal}
        onClose={() => setSubmitModal(false)}
        onExit={() => {
          surveyStore.changeSurveyAutosaving(true);
        }}
        onComplete={() => setClickedButton("submit")}
      />
      <Spin
        spinning={surveyStore.surveyLoading || surveyStore.surveySubmitting}
      >
        <div className={styles.formWrap}>
          <>
            <Title
              className="color-white"
              style={{ textAlign: "center" }}
              level={3}
            >
              {surveyStore.standardData?.modalTitle ||
                "Sorry, data for this step was deleted"}
            </Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
          </>

          {surveyStore.standardData && (
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ textAlign: "center" }}>
                <Title className="color-white" level={3}>
                  {surveyStore.standardData?.metric}
                </Title>
                <Title className="color-white" level={5}>
                  {surveyStore.standardData?.theme}
                </Title>
              </Col>
              <Col span={24}>
                <Divider className={styles.divider} />
              </Col>
            </Row>
          )}
          {viewInfoButton}
          <Form
            className="json-form-container"
            formData={formData}
            uiSchema={uiSchema}
            schema={{}}
            onChange={({ formData }) => setFormData(formData)}
            onSubmit={onFormSubmit}
            disabled={formDisable}
          >
            {surveyStore.standardData && (
              <>
                <Row gutter={[16, 16]}>
                  {/* {Object.keys(surveyStore.standardData?.form_json_code)
                    .length === 0 && ( */}
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="ckSmallHeight">
                      <CkFormData
                        parsedData={ckFormData}
                        setCkFormData={(data) => setCkFormData(data)}
                      />
                    </div>
                  </Col>
                  {/* )} */}
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col span={24}>
                    <Spin spinning={loaderUploadedFilesList}>
                      <Title className="color-white" level={4}>
                        Attach related files
                      </Title>
                      <Title className="color-white" level={5}>
                        Attach files that are related to this field
                      </Title>
                      <Divider className={styles.divider} />

                      <Upload
                        customRequest={({ file, onSuccess }) => {
                          onSuccess("ok");
                        }}
                        beforeUpload={beforeUpload}
                        fileList={files}
                        className={`upload-list-inline ${
                          size && size?.width <= 900 && styles.mobileView
                        }`}
                        listType={
                          size && size?.width <= 900 ? "picture" : "text"
                        }
                        iconRender={() => <PaperClipOutlined />}
                        multiple={false}
                        onRemove={onRemoveFileFromListHandler}
                      >
                        <StyledButton type="custom" icon={<UploadOutlined />}>
                          Upload
                        </StyledButton>
                      </Upload>
                      <div className="data-lake-files-wrap">
                        {uploadedFilesList &&
                          uploadedFilesList?.map((item, index) => {
                            const fileName = item && item?.split("/").pop();
                            return (
                              <StyledButton
                                type="custom"
                                hoverbgcolor="transparent"
                                bgcolor="transparent"
                                bordercolor="transparent"
                                color="#A68DFB"
                                key={index}
                                className="margin-2"
                                icon={<LinkOutlined />}
                                onClick={() => downloadFileHandler(item)}
                              >
                                {fileName}
                              </StyledButton>
                            );
                          })}
                      </div>
                    </Spin>
                  </Col>
                </Row>
              </>
            )}

            {footerButtons}
          </Form>
        </div>
      </Spin>
    </>
  );
});

export default StandardsForm;
