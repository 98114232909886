import { Col, Input, Row, Typography } from "antd";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";

import React from "react";

const { Title } = Typography;

type ColumnPromptMappings = {
  id: number;
  key: string;
  value: string;
};

export type Step3PDFProps = {
  promptData: ColumnPromptMappings[];
  onPromptValueChange(id: number, value: string): void;
  onClickPrevious(): void;
  onClickNext(): void;
};

export const Step3PDF: React.FC<Step3PDFProps> = ({
  promptData,
  onPromptValueChange,
  onClickPrevious,
  onClickNext,
}) => {
  console.log(promptData);

  return (
    <>
      <FormWrap
        width="874px"
        style={{
          backgroundColor: "#2D273F",
          borderRadius: "4px",
          marginTop: "50px",
        }}
      >
        <FormWrapper style={{ marginTop: "20px" }}>
          <Title level={3} style={{ fontWeight: "600" }}>
            Data mapping
          </Title>
          <p className="color-grey font-16">
            Enter a prompt for each data sheet column below that will be used by
            the AI to extract the relevant information
          </p>
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <p>Data Sheet column name</p>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "250px" }}>
                  {" "}
                  <p>Prompt</p>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{}}>
            {promptData.map((field, index) => (
              <Row key={index} style={{ height: "100px" }}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div>
                    <Input
                      style={{ width: "350px" }}
                      value={field.key}
                      disabled={true}
                    />
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      style={{
                        width: "380px",
                        height: "60px",
                        borderRadius: "2px",
                        fontSize: "14px",
                        color: "white",
                        padding: "5px",
                      }}
                      value={promptData[index].value}
                      onChange={(e) =>
                        onPromptValueChange(field.id, e.target.value)
                      }
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </FormWrapper>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <StyledButton
              type="custom"
              onClick={onClickPrevious}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              style={{ padding: "11px 11px 11px 0px" }}
            >
              <span>Go back</span>
            </StyledButton>
            <StyledButton
              className="margin-5"
              onClick={onClickNext}
              type="custom"
            >
              <span>Continue to next step</span>
            </StyledButton>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};
