import React, { ChangeEvent } from "react";
import { useSetState } from "ahooks";
import { Button, DatePicker, Input, Slider } from "antd";
import moment, { Moment } from "moment";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import { v4 as uuidv4 } from "uuid";
import styles from "./UpdateCurrentDataModal.module.scss";

interface AddNewActionModalProps {
  modalTitle: string;
  data: Action;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (action: Action) => void;
}

export const AddNewActionModal = ({
  modalTitle,
  data,
  isOpen,
  onClose,
  onConfirm,
}: AddNewActionModalProps) => {
  const dateFormat = "YYYY-MM-DD";
  const generateAction = () => {
    return {
      key: data.key || uuidv4(),
      name: data.name || "",
      dueDate:
        moment(data.dueDate).format(dateFormat) || moment().format(dateFormat),
      progress: data.progress || 0,
    };
  };
  const [action, setAction] = useSetState<Action>(generateAction() as Action);

  const handleChangeActionName = (e: ChangeEvent<HTMLInputElement>) => {
    setAction({ name: e.target.value });
  };

  const handleChangeDate = (dateString: Moment) => {
    setAction({ dueDate: dateString.format(dateFormat) });
  };

  const handleChangeProgress = (newValue: number) => {
    setAction({ progress: newValue });
  };

  const handleConfirm = () => {
    onConfirm(action);
    onClose();
  };
  const handleMarkAsDone = () => {
    onConfirm({ ...action, progress: 100 });
    onClose();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <CommonModal
        title={modalTitle}
        visible={isOpen}
        footer={
          <>
            <Button type="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleConfirm}>
              {modalTitle === "Add new action" ? "Add action" : "Edit action"}
            </Button>
            {modalTitle === "Edit action" && (
              <Button type="text" onClick={handleMarkAsDone}>
                Mark as Done and save
              </Button>
            )}
          </>
        }
        onCancel={handleClose}
        maskClosable={false}
      >
        <FormWrapper>
          <div>Action name</div>
          <div>
            <Input
              className={styles.inputSuccessStep}
              placeholder="Enter action name"
              value={action.name}
              onChange={handleChangeActionName}
            />
          </div>
          <div>Due date</div>
          <DatePicker
            className={styles.datePicker}
            value={moment(action.dueDate)}
            format={dateFormat}
            onChange={(value) => handleChangeDate(value || moment())}
          />
          <div>Progress</div>
          <h4 className={styles.modalText}>
            {" "}
            Drag the slider to mark the progress of this action
          </h4>

          <Slider
            className={styles.rowSlider}
            min={1}
            max={100}
            onChange={handleChangeProgress}
            value={action.progress}
          />

          <div>{action.progress}% </div>
        </FormWrapper>
      </CommonModal>
    </>
  );
};
