import React, { useState } from "react";
import { Form, Row, Typography, Divider } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { ConditionalDataSheetFilters } from "../../../../../components/ConditionalDataSheetFilters/ConditionalDataSheetFilters";
import { convertFiltersList } from "../../../../../services/dataSheet";

const { Title } = Typography;

export const FiltersModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
  initialValues,
  onReset,
  pageType,
}) => {
  const [submitting] = useState(false);
  const defaultFilters = initialValues.conditionalFilters?.length
    ? initialValues.conditionalFilters
    : initialValues?.filters?.length
    ? convertFiltersList(
        initialValues?.filters,
        dataSheet,
        initialValues?.operatorType || "$and"
      )
    : [];
  const [formInitialValue, setFormInitialValue] = useState(defaultFilters);
  const [form] = Form.useForm();
  const onFinishHandler = () => {
    onFinish({ conditionalFilters: formInitialValue });
    onClose();
  };

  const onClickReset = () => {
    onReset();
  };
  const onChangeFilters = (filters) => {
    setFormInitialValue(filters);
  };
  return (
    <CommonModal
      title={"Filter Records"}
      visible={visible}
      onCancel={onClose}
      width={800}
      okText="Apply"
      onOk={onFinishHandler}
      footer={null}
      maskClosable={false}
      confirmLoading={submitting}
      destroyOnClose
      centered
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Title level={4}>Filter Datasheet fields</Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
          <Form.Item>
            <Title level={5}>Datasheet filters</Title>
            <ConditionalDataSheetFilters
              dataSheet={dataSheet}
              initialFilters={defaultFilters}
              onChangeFilters={onChangeFilters}
              addButtonText="Add"
              pageType={pageType}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <StyledButton
                type="default"
                style={{ padding: "4px 15px" }}
                onClick={onClickReset}
              >
                Reset
              </StyledButton>
              <StyledButton type="custom" htmlType="submit">
                Apply
              </StyledButton>
            </Row>
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
