import {
  Form,
  message,
  Switch,
  Select,
  Popconfirm,
  Input,
  Checkbox,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import { rolesList } from "../../../../shared/constants";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import styles from "./CreateUserModal.module.scss";
import {
  listPermissions,
  updatePermissionByUser,
} from "../../../../services/permissions";
import { v4 as uuidv4 } from "uuid";
import { GroupsAndEntitiesTable } from "./GroupsAndEntitiesTable";
import { getUserGroups, updateUserGroups } from "../../../../services/users";
import { PlusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

interface UpdateUserModalProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  rowData: any;
  updateUserDataInList: (updateFn: (prev: any[]) => any[]) => void;
  onDeleteUser: () => void;
  refDataSheets: any;
  loadingRefDataSheetsData: boolean;
}
interface Item {
  key: string;
  group?: { name: string; id: string };
  groupsEntities?: any[] | undefined;
  entity?: any;
  isNewGroup?: boolean;
  isGroup?: boolean;
  action?: any;
  dataType?: "delete" | "add" | "initial";
}

const UpdateUserModal = ({
  visible,
  onClose,
  rowData,
  updateUserDataInList,
  onDeleteUser,
  refDataSheets,
  loadingRefDataSheetsData,
}: UpdateUserModalProps) => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [getDataLoader, setGetDataLoader] = useState(false);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [dataSource, setDataSource] = useState<Item[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (rowData) {
      getPermissionsData();
      form.setFieldsValue({
        status: rowData?.Enabled ? rowData?.Enabled : false,
        roles: rowData?.roles ? rowData?.roles : [],
        newPassword: "",
        email: rowData?.email ? rowData?.email : "",
        requireChange: false,
      });
    }
  }, [rowData, form, refDataSheets]);

  const getPermissionsData = async () => {
    try {
      setGetDataLoader(true);

      const payload = {
        permissionType: "REFERENCE_DATA",
        username: rowData.Username,
      };
      const permissionsData = await listPermissions(payload);

      const listGroupNames = await getUserGroups();
      setGroups(listGroupNames);

      const userGroups = listGroupNames?.filter((v: any) =>
        v?.group_users?.some((item: any) => item == rowData?.Username)
      );
      const modifiedData = await Promise.all(
        userGroups.map(async (item: any) => {
          const payload = {
            permissionType: "REFERENCE_DATA",
            groupId: item._id.$oid,
          };
          const permissionsData = await listPermissions(payload);

          const metaDataRecords = refDataSheets.reduce(
            (result: any[], obj: any) => {
              const matchingChildren = obj.children.filter((ite: any) => {
                const foundPermission = permissionsData
                  .flatMap((v: any) => v.assignedItems)
                  .includes(ite.key);

                return foundPermission;
              });

              return result.concat(matchingChildren);
            },
            []
          );
          return {
            key: uuidv4(),
            group: { name: item.name, id: item._id.$oid },
            groupsEntities: metaDataRecords,
            isGroup: true,
            dataType: "initial",
          };
        })
      );

      const filteredPermissionsData = permissionsData?.flatMap((v: any) =>
        v.assignedItems.map((item: any) => ({ value: item, parent: v.itemId }))
      );
      const values = refDataSheets
        ?.flatMap((item: any) => item.children)
        ?.filter((item: any) =>
          filteredPermissionsData?.find((v: any) => v.value === item.value)
        );
      const allItems = refDataSheets?.flatMap((item: any) => item.children);
      const selectedValues = filteredPermissionsData?.map(
        (v: any) =>
          allItems?.find((item: any) => item.key === v.value) || {
            label: v.value,
            value: v.value,
            key: v.value,
            parent: v.parent,
          }
      );
      const userEntities = selectedValues.map((v: any) => ({
        key: uuidv4(),
        entity: { identifierId: v.key, sheetId: v.parent, title: v.title },
        isGroup: false,
        dataType: "initial",
      }));
      setDataSource([...modifiedData, ...userEntities]);

      form.setFieldsValue({ facility: values });
    } catch (error) {
      console.error("Error while get data:", error);
    } finally {
      setGetDataLoader(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteLoader(true);
      const parameters = {
        username: rowData.Username,
      };
      const params = JSON.stringify(parameters);
      await API.graphql({
        query: queries["manageUserAccess"],
        variables: { request_type: "delete-user", parameters: params },
      });
      setDeleteLoader(false);
      message.success("User has been deleted successfully.");
      onClose(!visible);
      onDeleteUser(); // Trigger user list refresh in UserManagementPage
    } catch (error) {
      console.log("Error while deleting user!", error);
      message.error("Error while deleting user!");
      setDeleteLoader(false);
    }
  };

  const handleAdd = (type: "G" | "E") => {
    const newData: Item =
      type === "G"
        ? {
            key: uuidv4(),
            groupsEntities: [],
            entity: [],
            isNewGroup: true,
            isGroup: false,
            dataType: "add",
          }
        : {
            key: uuidv4(),
            groupsEntities: [],
            entity: [],
            isNewGroup: false,
            isGroup: false,
            dataType: "add",
          };
    setDataSource([...dataSource, newData]);
    edit(newData);
  };
  const edit = (record: Partial<Item> & { key: string }) => {
    setEditingKey(record.key);
  };
  const onClickUpdate = () => {
    form
      .validateFields()
      .then(async (val) => {
        try {
          setUpdateLoader(true);
          const { status, roles, newPassword, email, requireChange } = val;
          const stausParameters = {
            username: rowData.Username,
            status: status ? "Enabled" : "Disabled",
          };
          const statusParms = JSON.stringify(stausParameters);
          const statusResponse: any = await API.graphql({
            query: queries["manageUserAccess"],
            variables: { request_type: "edit-status", parameters: statusParms },
          });
          const editStatusResponse = JSON.parse(
            statusResponse?.data?.manageUserAccess
          );
          // update data after api success otherwise it goes in catch block.
          const dataUpdate = { Enabled: status };
          updateUserDataInList((prev) =>
            prev.map((item) =>
              item["Username"] === rowData["Username"]
                ? { ...item, ...dataUpdate }
                : item
            )
          );

          // code for update user roles.
          // make a Set to hold values from roles
          const roleSet = new Set(roles);
          const removedRoles = rowData.roles.filter((role: any) => {
            // return those elements not in the namesToDeleteSet
            return !roleSet.has(role);
          });

          const rolesParameters = {
            username: rowData.Username,
            add_groups: roles,
            remove_groups: removedRoles,
          };

          const rolesParms = JSON.stringify(rolesParameters);
          const rolesResponse: any = await API.graphql({
            query: queries["manageUserAccess"],
            variables: { request_type: "edit-role", parameters: rolesParms },
          });
          const editrRolesResponse = JSON.parse(
            rolesResponse?.data?.manageUserAccess
          );

          // update data after api success otherwise it goes in catch block.
          const dataRolesUpdate = { roles: roles };
          updateUserDataInList((prev) =>
            prev.map((item) =>
              item["Username"] === rowData["Username"]
                ? { ...item, ...dataRolesUpdate }
                : item
            )
          );

          // Check if newPassword is provided to determine if password reset is required
          const isPasswordResetRequired = newPassword !== "";

          if (isPasswordResetRequired) {
            // Only update password if a new password is provided
            const parameters = {
              username: rowData.Username,
              new_password: newPassword,
              permanent: !requireChange,
            };
            const params = JSON.stringify(parameters);
            await API.graphql({
              query: queries["manageUserAccess"],
              variables: {
                request_type: "change-password",
                parameters: params,
              },
            });
          }

          // Check if newEmail is provided to determine if email change is required
          const isEmailChangeRequired =
            email !== "" && email !== rowData?.email;

          if (isEmailChangeRequired) {
            // Only update email if a new email is provided
            const parameters = {
              username: rowData.Username,
              email: email,
            };
            const params = JSON.stringify(parameters);
            await API.graphql({
              query: queries["manageUserAccess"],
              variables: {
                request_type: "edit-email",
                parameters: params,
              },
            });
          }
          const groupsData = dataSource.filter((v) => v.isGroup);
          const entitiesData: Item[] = dataSource.filter(
            (v) => !v.isGroup && v.dataType !== "delete"
          );
          groupsData.forEach(async (item) => {
            const findData = groups.find((v) => v._id.$oid == item.group?.id);
            if (item.dataType === "add") {
              const payload = {
                ...findData,
                group_users: findData?.group_users?.length
                  ? [...new Set([...findData.group_users, rowData.Username])]
                  : [rowData.Username],
              };

              await updateUserGroups(payload);
            } else if (item.dataType === "delete") {
              const filteredUsers = findData?.group_users.filter(
                (user) => user !== rowData.Username
              );
              const payload = {
                ...findData,
                group_users: filteredUsers,
              };

              await updateUserGroups(payload);
            }
          });
          const groupedBySheetId = entitiesData.reduce((acc: any, el: any) => {
            const sheetId = el.entity.sheetId;
            if (!acc[sheetId]) {
              acc[sheetId] = [];
            }
            acc[sheetId].push(el);
            return acc;
          }, {});

          const resultList = Object.entries<any[]>(groupedBySheetId).map(
            ([sheetId, items]: [string, any[]]) => {
              return {
                itemId: sheetId,
                assignedItems: items.map((item) => item.entity.identifierId),
              };
            }
          );

          const payload = {
            permissionType: "REFERENCE_DATA",
            username: rowData.Username,
            items: resultList || [],
          };
          updatePermissionByUser(payload);

          setUpdateLoader(false);
          message.success("Successfully updated user details.");
          form.resetFields();
          onClose(!visible);
        } catch (error) {
          console.log("Error while updating user information!", error);
          message.error("Error while updating user information!");
          setUpdateLoader(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong while updating user information.");
        console.log("Something went wrong while updating user information", e);
        setUpdateLoader(false);
      });
  };

  return (
    <>
      <CommonModal
        width="730px"
        title="Update User"
        visible={visible}
        okText="Update"
        onOk={onClickUpdate}
        confirmLoading={updateLoader}
        onCancel={() => onClose(!visible)}
      >
        <FormWrapper>
          <Spin spinning={false}>
            <Form form={form}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="status"
                label="Active"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="roles"
                label="User Roles"
                rules={[
                  { required: true, message: "Please select user role!" },
                ]}
              >
                <Select mode="multiple" showArrow>
                  {rolesList &&
                    rolesList.map((role, index) => {
                      return (
                        <Option key={index} value={role}>
                          {role}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="newPassword"
                label="New Password"
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 20 }}
                name="requireChange"
                label=""
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>
                  Require user to change password upon sign in
                </Checkbox>
              </Form.Item>
              <Form.Item labelCol={{ span: 24 }} name="email" label="Email">
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="groups&entities"
                label="Groups and Entities"
              >
                <GroupsAndEntitiesTable
                  dataSource={dataSource}
                  refDataSheets={refDataSheets}
                  setDataSource={setDataSource}
                  form={form}
                  editingKey={editingKey}
                  groups={groups}
                  dataLoading={getDataLoader}
                  loadingRefDataSheetsData={loadingRefDataSheetsData}
                  setEditingKey={setEditingKey}
                />
              </Form.Item>
              <StyledButton
                disabled={editingKey.length || getDataLoader}
                onClick={() => handleAdd("G")}
                type="custom"
              >
                {" "}
                <PlusCircleOutlined /> Add group
              </StyledButton>{" "}
              <StyledButton
                disabled={editingKey.length || getDataLoader}
                onClick={() => handleAdd("E")}
                type="custom"
              >
                {" "}
                <PlusCircleOutlined /> Add entity
              </StyledButton>{" "}
              <Row justify="end">
                <Form.Item labelCol={{ span: 24 }} name="deleteUser" label="">
                  <Popconfirm
                    title={
                      <div style={{ width: "300px" }}>
                        WARNING: This action is irreversible and the user will
                        be deleted from the system. Are you sure you want to
                        delete the user?
                      </div>
                    }
                    okText="Delete User"
                    cancelText="Cancel"
                    onConfirm={handleDeleteUser}
                    disabled={deleteLoader}
                    overlayClassName="popoverContent"
                  >
                    <StyledButton
                      type="custom"
                      bordercolor={"#EC5B56"}
                      bgcolor={"#EC5B56"}
                      hoverbgcolor={"#EC5B56"}
                    >
                      Delete User
                    </StyledButton>
                  </Popconfirm>
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default UpdateUserModal;
