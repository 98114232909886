import React, { useEffect, useState } from "react";
import {
  InputNumber,
  Input,
  Form,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Row,
  Col,
  Spin,
  Tooltip,
  Dropdown,
} from "antd";
import { StyledButton } from "../../../../shared/commonStyles";
import { EyeOutlined } from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../../shared/constants";
import moment from "moment";

export const EditableCell = ({
  inputType,
  dataType,
  schemaField,
  required,
  editing,
  key,
  dataIndex,
  title,
  record,
  index,
  children,
  loading,
  disabledFields,
  lastRevisionData,
  identifierConnectedValues,
  isIdentifierConnected,
  handleChangeIdentifierField,
  handleViewRichText,
  ...restProps
}) => {
  const [isRecordMatched, setIsRecordMatched] = useState(false);
  const [yourValue, setYourValue] = useState(null);
  const [revisionValue, setRevisionValue] = useState(null);

  useEffect(() => {
    if (lastRevisionData) {
      if (record?._id.$oid === lastRevisionData?.record_id?.$oid) {
        setIsRecordMatched(true);
        let yourvalue = record ? record[record?.sheet_name][dataIndex] : "";
        let revisionvalue = lastRevisionData
          ? lastRevisionData[lastRevisionData?.sheet_name][dataIndex]
          : "";

        if (inputType === "date") {
          if (typeof yourvalue === "object" && yourvalue !== null) {
            yourvalue =
              yourvalue === "" || yourvalue === undefined || yourvalue === null
                ? ""
                : moment.utc(yourvalue.$date).format(YearMonthDayFormat);
          } else {
            yourvalue =
              yourvalue === "" || yourvalue === undefined || yourvalue === null
                ? ""
                : moment.utc(yourvalue).format(YearMonthDayFormat);
          }

          if (typeof revisionvalue === "object" && revisionvalue !== null) {
            revisionvalue =
              revisionvalue === "" ||
              revisionvalue === undefined ||
              revisionvalue === null
                ? ""
                : moment.utc(revisionvalue.$date).format(YearMonthDayFormat);
          } else {
            revisionvalue =
              revisionvalue === "" ||
              revisionvalue === undefined ||
              revisionvalue === null
                ? ""
                : moment.utc(revisionvalue).format(YearMonthDayFormat);
          }
        }

        setYourValue(yourvalue);
        setRevisionValue(revisionvalue);
      }
    }
  }, [lastRevisionData]);

  let inputNode = null;

  let formItemProps = {
    name: dataIndex,
    rules: [{ required: required, message: `This field is required!` }],
    style: { margin: 0 },
  };

  if (inputType === "number") {
    inputNode = (
      <InputNumber
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        lang="en"
      />
    );
  } else if (inputType === "date") {
    inputNode = (
      <DatePicker
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        format={YearMonthDayFormat}
        className="clearIcon"
      />
    );
  } else if (inputType === "string") {
    inputNode = (
      <Input
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
      />
    );
  } else if (inputType === "dropdown") {
    inputNode = (
      <Select
        defaultValue={record[record.sheet_name][dataIndex]}
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        style={{ minWidth: "150px", width: "fit-content" }}
      >
        {schemaField?.options?.map((item, i) => {
          return (
            <Select.Option key={item?.value + i} value={item?.value}>
              {item?.label}
            </Select.Option>
          );
        })}
      </Select>
    );
  } else if (inputType === "radio") {
    inputNode = (
      <Radio.Group
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
      >
        {schemaField?.options?.map((item) => {
          return (
            <Radio key={item?.value} value={item?.value}>
              {item?.label}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  } else if (inputType === "checkbox") {
    if (schemaField?.is_checkbox_group) {
      inputNode = (
        <Checkbox.Group
          disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        >
          <Row>
            {schemaField?.options?.map((item) => {
              return (
                <Col key={item?.value} xs={24} sm={24} md={24} lg={24}>
                  <Checkbox value={item?.value}>{item?.label}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      );
    } else {
      formItemProps = { ...formItemProps, valuePropName: "checked" };
      inputNode = (
        <Checkbox
          disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        >
          {title}
        </Checkbox>
      );
    }
  } else if (inputType === "long-answer") {
    inputNode = (
      <Input.TextArea
        disabled={!!disabledFields?.some((v) => v == schemaField.entity_name)}
        style={{ height: 100 }}
      />
    );
  } else if (inputType === "rich-text") {
    inputNode = (
      <StyledButton
        type="default"
        icon={<EyeOutlined />}
        onClick={() => handleViewRichText(record, schemaField.entity_name)}
      >
        View
      </StyledButton>
    );
  } else {
    //string
    inputNode = null;
  }

  if (isIdentifierConnected) {
    inputNode = (
      <Select
        options={identifierConnectedValues
          ?.filter((v) => v)
          .map((item) => ({
            value: item,
            label: item,
          }))}
        onChange={(value) => handleChangeIdentifierField(value, dataIndex)}
        style={{ minWidth: "150px", width: "fit-content" }}
      />
    );
  }

  return (
    <>
      {editing ? (
        <>
          {lastRevisionData && isRecordMatched ? (
            <Tooltip
              placement="bottomLeft"
              title={
                <>
                  <b>Revision {lastRevisionData?.revision} Value</b>
                  <br />
                  {revisionValue}
                  <br />
                  {yourValue && (
                    <>
                      <b>Your Value</b>
                      <br />
                      {yourValue}
                    </>
                  )}
                </>
              }
            >
              <td
                {...restProps}
                className={
                  editing &&
                  lastRevisionData &&
                  isRecordMatched &&
                  yourValue &&
                  revisionValue !== yourValue
                    ? "dataSheetBorder"
                    : ""
                }
              >
                {revisionValue}
              </td>
            </Tooltip>
          ) : (
            <td
              {...restProps}
              className={
                editing &&
                lastRevisionData &&
                isRecordMatched &&
                yourValue &&
                revisionValue !== yourValue
                  ? "dataSheetBorder"
                  : ""
              }
            >
              <Spin
                spinning={!!loading?.some((v) => v == schemaField.entity_name)}
              >
                <Form.Item {...formItemProps}>{inputNode}</Form.Item>
              </Spin>
            </td>
          )}
        </>
      ) : (
        <td
          {...restProps}
          className={
            editing &&
            lastRevisionData &&
            isRecordMatched &&
            yourValue &&
            revisionValue !== yourValue
              ? "dataSheetBorder"
              : ""
          }
        >
          {children}
        </td>
      )}
    </>
  );
};
