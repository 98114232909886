import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  DatePicker,
  Button,
  message,
  Typography,
  Divider,
  Input,
  Checkbox,
} from "antd";
import {
  CommonModal,
  CommonTable,
  FormWrapper,
  StyledButton,
  TranserWrapper,
} from "../../../../shared/commonStyles";
import { SearchOutlined } from "@ant-design/icons";
import { getUsersGroupList, getUsersList } from "../../../../services/users";
import { AvatarImage } from "../../../../pages/settingsPage/userGroupsPage/Components/AvatarImage";
const { Text, Title } = Typography;
const DashboardButtonModal = ({ isVisible, onClose, chartId }) => {
  const [loader, setLoader] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const currentUrl = window.location.href;
  const [toolTipMsg, setToolTipMsg] = useState(false);
  const [searchUsers, setSearchUsers] = useState(null);
  const [initialValues, setInitialValues] = useState({
    external_email: "",
    expire_date: "",
  });
  const loadUserData = async () => {
    try {
      setLoader(true);
      const usersList = await getUsersList();
      let newUserList;
      let newGroupList;
      if (usersList) {
        newUserList = usersList.map((item) => {
          return {
            ...item,
            type: "user",
            id: item.value,
            check: false,
          };
        });
      }
      const usersGroupList = await getUsersGroupList();
      if (usersGroupList) {
        newGroupList = usersGroupList.map((item) => {
          return {
            ...item,
            type: "group",
            id: item.value,
            check: false,
          };
        });
      }

      if (newUserList && newGroupList) {
        const newArr = newUserList.concat(newGroupList);
        setNewArr(newArr);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const onCheckedItem = (record) => {
    let rowKeys = [...selectedRowKeys];
    if (record.target.checked) {
      rowKeys = [...selectedRowKeys, { ...record.target.value }];
    } else {
      rowKeys.splice(selectedRowKeys.indexOf(record.target.value.id), 1);
    }
    setSelectedRowKeys(rowKeys);

    const newArrData = newArr.map((item) => {
      if (item.id === record.target.value.id) {
        return { ...item, check: record.target.checked };
      }
      return item;
    });
    setNewArr(newArrData);
    if (searchUsers !== null && searchUsers.length > 0) {
      const newSearchData = searchUsers.map((item) => {
        if (item.id === record.target.value.id) {
          return { ...item, check: record.target.checked };
        }
        return item;
      });
      setSearchUsers(newSearchData);
    }
  };

  const columns = [
    {
      key: "key",
      title: "",
      width: "100%",

      render: (_, record) => (
        <Checkbox
          value={record}
          onChange={onCheckedItem}
          style={{ color: "white" }}
          className="rowCheckColor"
          checked={record.check}
        >
          {" "}
          <AvatarImage url={record.profieImgUrl} size={35} /> {record.name}
        </Checkbox>
      ),
    },
  ];

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };
  const onShareSubmit = async () => {
    console.log("on submit button", selectedRowKeys);
  };
  const onSendButton = async () => {
    const { external_email } = initialValues;
    console.log("on send button", external_email);
  };
  const onCopyButton = async (url) => {
    const { expire_date } = initialValues;
    if (expire_date && expire_date != undefined) {
      console.log("on copy button", expire_date._d);
    }
    if (url) {
      const copied = navigator.clipboard.writeText(url);
      if (copied) {
        console.log("URL copied");
        setToolTipMsg(true);
      } else {
        setToolTipMsg(false);
      }
    }
  };

  return (
    <CommonModal
      title={"Share Dashboard"}
      visible={isVisible}
      width={600}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      centered
      footer={[
        <Button key="close" ghost onClick={(_) => onClose()}>
          Close
        </Button>,
      ]}
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialValues}
          onFieldsChange={(data) => onChangeFieldsData(data)}
        >
          <div className="groupTable">
            <Title level={5}>Select user or group from the list</Title>
            <Divider style={{ backgroundColor: "#3A3C5A", margin: "12px 0" }} />
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search for user or group"
              onChange={(e) => {
                const filterData = newArr.filter((o) =>
                  o.name.toLowerCase().includes(e.target.value.toLowerCase())
                );
                if (filterData) {
                  setSearchUsers(filterData);
                }
                // setSearchUsers(filterData);
              }}
            />
            <TranserWrapper>
              <CommonTable
                loading={loader}
                className="assignUserTable"
                columns={columns}
                dataSource={searchUsers === null ? newArr : searchUsers}
                pagination={false}
                style={{ width: "100%" }}
                scroll={{
                  y: 200,
                }}
              />
            </TranserWrapper>
            <Row justify="end">
              <StyledButton
                type="primary"
                style={{ marginTop: "5px" }}
                onClick={() => onShareSubmit()}
                disabled={selectedRowKeys.length === 0}
              >
                Share
              </StyledButton>
            </Row>
          </div>
          <Divider style={{ backgroundColor: "#3A3C5A", margin: "12px 0" }} />
          <Text>Need to send to external user? Type the email below</Text>
          <Row gutter={[16, 16]} style={{ marginTop: "5px" }}>
            <Col span={20}>
              <Form.Item
                name="external_email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input Email Id",
                  },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <StyledButton
                type="default"
                style={{ marginTop: "-4px", padding: "9px 15px 9px 15px" }}
                onClick={() => onSendButton()}
              >
                Send
              </StyledButton>
            </Col>
          </Row>

          <Title level={5}>Or copy the link</Title>
          <Divider style={{ backgroundColor: "#3A3C5A", margin: "12px 0" }} />
          <Text>
            Set up expiration date
            <span className="color-grey"> (optional)</span>
          </Text>
          <Row gutter={16} style={{ marginTop: "5px" }}>
            <Col span={24}>
              <Form.Item name="expire_date">
                <DatePicker
                  placeholder="Expiration date"
                  style={{ background: "#625D75", width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Text>Copy web link</Text>
          <Row gutter={[16, 16]} style={{ marginTop: "5px" }}>
            <Col span={24}>
              <Input.Group compact>
                <Input
                  style={{
                    width: "calc(100% - 13%)",
                  }}
                  defaultValue={currentUrl ? currentUrl : ""}
                />
                <Button
                  type="primary"
                  style={{ padding: "6px 18px" }}
                  onClick={() => onCopyButton(currentUrl)}
                >
                  Copy
                </Button>
              </Input.Group>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default DashboardButtonModal;
