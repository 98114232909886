import {
  AntDesignOutlined,
  GroupOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, message, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AvatarUsername } from "../../../components/Avatar/AvatarUsername";
import { getUserGroups } from "../../../services/users";
import { getFirstAndLastNameByUsername } from "../../../shared/commonFunctions";

const Assignees = ({ record }) => {
  const [usersGroupList, setUsersGroupList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(false);

  const loadUsersGroupData = async () => {
    try {
      setLoader(true);
      const userGroups = JSON.parse(record?.assigned_groups);
      if (userGroups?.length > 0) {
        const payload = {
          filter: { _id: { $oid: userGroups } },
        };
        const groups = await getUserGroups(payload);
        const data = groups.map((item) => {
          return { name: item.name || "" };
        });
        setUsersGroupList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  const userData = async () => {
    try {
      setLoader(true);

      const assignedUsers = JSON.parse(record?.assigned_users);
      if (assignedUsers?.length > 0) {
        const data = await Promise.all(
          assignedUsers?.map(async (item) => {
            const usersList = await getFirstAndLastNameByUsername(item);
            return {
              name: usersList ? usersList : "",
            };
          })
        );
        setUsersList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadUsersGroupData();
    userData();
  }, [record]);

  return (
    <Spin spinning={loader}>
      <Avatar.Group>
        {usersList?.map((item, index) => {
          return (
            <AvatarUsername
              key={index}
              size="medium"
              username={item?.name}
              customTooltipTitle={item?.name}
            />
          );
        })}

        {usersGroupList?.map((item, index) => {
          return (
            <Tooltip
              placement="top"
              overlayInnerStyle={{ width: "auto" }}
              title={item?.name}
              key={index}
            >
              <Avatar
                style={{
                  backgroundColor: "#1890ff",
                }}
                icon={<GroupOutlined />}
              />
            </Tooltip>
          );
        })}
      </Avatar.Group>
    </Spin>
  );
};
export default Assignees;
