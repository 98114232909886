// Helper Functions for EmissionsModal

import React, { Dispatch, SetStateAction } from 'react';

import { getSheetById  } from "../../../services/dataSheet";
import { unitsManagementStore } from "../../Data/UnitsManagement/UnitsManagementStore";


// Define the type of the object
type ConversionMetaDataType = {
  // Define the properties of the object here
  // For example:
  emissions_library_unit: string;
  datasheet_unit: string;
  datasheet_unit_conversion_factor: number;
  // Add more properties as needed
};


export const handleAddEditEmission = (data: any, autoEmission: AutomatedEmission, setAutoEmission: Dispatch<SetStateAction<AutomatedEmission>>, setIsShowCustomConversionFactor: Dispatch<SetStateAction<boolean>>): void => {
  const conversionMetaData: ConversionMetaDataType = {
    emissions_library_unit: '',
    datasheet_unit_conversion_factor: 1,
    datasheet_unit: ''
  };
  const { checked, ...rest } = data;
  const factorValue = rest.factor ?? rest.factorCalculatedValue;

  let is_have_custom_unit_conversion_factor = false;

  conversionMetaData.emissions_library_unit = rest.unit;

  // probably should call this earlier once we select a sheet id
  const datasheetPromise = getSheetById(autoEmission?.activity_sheet);

  const new_factor_data: any = {};

  datasheetPromise.then((datasheet) => {
    // Use datasheet here
    const schema: any = datasheet.sheet_schema.find((schema: any) => schema.entity_name === autoEmission.activity_field);


    if (schema) {
      // we found it

      if (schema.unit_id !== undefined) {
        conversionMetaData.datasheet_unit = schema.unit_id;
        if (conversionMetaData.emissions_library_unit !== undefined) {
          const parts = conversionMetaData.emissions_library_unit.split('/');

          // Check if split worked and we have a denominator value
          let denominatorValue = '';
          if (parts.length > 1) {
            denominatorValue = parts[1].trim(); // Trim any extra whitespace
          }

          if (denominatorValue) {
            if (conversionMetaData.datasheet_unit == denominatorValue) {
              // we have match and don't need to do any conversions

            } else {
              // need to check if in same unit group
              const symbol1 = conversionMetaData.datasheet_unit;
              const symbol2 = denominatorValue;
              const result = unitsManagementStore.findMatchingSymbolsInsideSameUnitGroup(conversionMetaData.datasheet_unit,denominatorValue);
              if (result) {
                // found in same group
                //console.log(`Units with symbols '${symbol1}' and '${symbol2}' found:`);
                //console.log(result)
                // need to set conversion factor here
                conversionMetaData.datasheet_unit_conversion_factor = result[0].multiplication_factor/result[1].multiplication_factor;
              } else {
                // if not in same size unit group then we need to ask for a conversion factor
                // need to ask for conversion factor
                //console.log(`No units found with symbols '${symbol1}' and '${symbol2}' in the same unit group.`);
                is_have_custom_unit_conversion_factor = true;
              }
            }
          } else {
            is_have_custom_unit_conversion_factor = true;
            // no denominator
          }
        } else {
            //if (conversionMetaData.emissions_library_unit is not defined
            is_have_custom_unit_conversion_factor = true;
        }
      } else {
        // if datasheet doesn't have unit it then don't ask for conversion factor.
        is_have_custom_unit_conversion_factor = false;
      }
    } else {
    }
    setIsShowCustomConversionFactor(is_have_custom_unit_conversion_factor);

    if (is_have_custom_unit_conversion_factor) {
      // need to focus on custom field
      const inputElement = document.getElementById('custom_unit_conversion_factor_input') as HTMLInputElement | null;
      if (inputElement) {
        inputElement.focus();
      }
    } else {
      // we get in here and we aren't using a custom conversion factor anymore so we need to set it to 1 if it exists
      // we are changing
      if (autoEmission?.factor_data !== undefined && autoEmission?.factor_data?.custom_unit_conversion_factor !== undefined) {
        new_factor_data.custom_unit_conversion_factor = 1;
      }

    }



    delete rest.factorCalculatedValue;
    setAutoEmission({
      ...autoEmission,
      factor_data: { ...rest, ...new_factor_data, factor: factorValue },
      activity_field: autoEmission?.activity_field,
      automatic: autoEmission?.automatic,
      filters: autoEmission.filters,
      activity_sheet: autoEmission?.activity_sheet,
      datasheet_unit: conversionMetaData.datasheet_unit,
      datasheet_unit_conversion_factor: conversionMetaData.datasheet_unit_conversion_factor,
    } as AutomatedEmission);

  }).catch((error) => {
    // Handle any errors that occur during fetching the datasheet
    console.error('Error fetching datasheet:', error);
  });
};