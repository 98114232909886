import React, { Children, useEffect, useState } from "react";
import { Form, Input, Select, Spin, TreeSelect, message, Alert } from "antd";

import { v4 as uuidv4 } from "uuid";

import {
  FormWrapper,
  CommonModal,
  StyledButton,
} from "../../../../shared/commonStyles";
import { rolesList } from "../../../../shared/constants";
import { addPermissions } from "../../../../services/permissions";
import {
  createUser,
  getUserGroups,
  updateUserGroups,
} from "../../../../services/users";
import { PlusCircleOutlined } from "@ant-design/icons";

import { GroupsAndEntitiesTable } from "./GroupsAndEntitiesTable";

interface CreateUserModalProps {
  visible: boolean;
  loadingRefDataSheetsData: boolean;
  refDataSheets: any[];
  refresh: () => void;
  onClose: (visible: boolean) => void;
}

interface Item {
  key: string;
  group?: { name: string; id: string };
  groupsEntities?: any[] | undefined;

  entity?: any;
  isNewGroup?: boolean;
  isGroup?: boolean;
  action?: any;
  dataType?: "delete" | "add" | "initial";
}

const CreateUserModal = ({
  visible,
  refDataSheets,
  loadingRefDataSheetsData,
  onClose,
  refresh,
}: CreateUserModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<Item[]>([]);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    const listGroupNames = await getUserGroups();

    setGroups(listGroupNames);
  };

  const edit = (record: Partial<Item> & { key: string }) => {
    setEditingKey(record.key);
  };

  const handleAdd = (type: "G" | "E") => {
    const newData: Item =
      type === "G"
        ? {
            key: uuidv4(),
            groupsEntities: [],

            isNewGroup: true,
            isGroup: false,
            dataType: "add",
          }
        : {
            key: uuidv4(),
            groupsEntities: [],
            entity: [],
            isNewGroup: false,
            isGroup: false,
            dataType: "add",
          };
    setDataSource([...dataSource, newData]);
    edit(newData);
  };

  const onClickSubmit = async () => {
    form.validateFields().then(async (val) => {
      try {
        setIsSubmitting(true);
        const { name, email, role_groups } = val;
        const parameters = {
          user_attributes: {
            name,
            email,
          },
          role_groups,
        };
        const response = await createUser(parameters);

        if (response.data?.manageUserAccess.statusCode) {
          message.error(
            response.data?.manageUserAccess.body ?? "Error while adding user!"
          );
        } else {
          message.success(`Successfully added user (${name}).`);
          form.resetFields();
          onClose(!visible);
          refresh();
        }
        const identifiers: any = [];
        dataSource.forEach(async (el) => {
          if (el.isGroup && el.group?.id) {
            const group = groups.find((v) => v?._id?.$oid == el?.group?.id);

            const payload = {
              ...group,
              group_users: group?.group_users?.length
                ? [group.group_users, response.data?.manageUserAccess]
                : [response.data?.manageUserAccess],
            };

            await updateUserGroups(payload);
          } else if (!el.isGroup && el.entity) {
            identifiers.push(el);
          }
        });
        const identifiersBySheet = identifiers.reduce((acc: any, curr: any) => {
          const existingItem = acc.find(
            (item: any) => item.sheetId === curr.entity.sheetId
          );
          if (existingItem) {
            existingItem.identifiers.push(curr.entity.identifierId);
          } else {
            acc.push({
              sheetId: curr?.entity?.sheetId,
              identifiers: [curr.entity.identifierId],
            });
          }
          return acc;
        }, []);

        identifiersBySheet.forEach(async (el: any) => {
          const payload = {
            permissionType: "REFERENCE_DATA",
            itemId: el?.sheetId,
            assignedUsers: [response.data?.manageUserAccess],
            assignedItems: el?.identifiers,
          };
          await addPermissions(payload);
        });
      } catch (error) {
        message.error("Error while adding user!");
        console.log("Error while adding user!", error);
      } finally {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <>
      <CommonModal
        width="730px"
        title="Add User"
        visible={visible}
        okText="Save"
        onOk={onClickSubmit}
        confirmLoading={isSubmitting}
        onCancel={(_: any) => onClose(!visible)}
      >
        <Alert
          message="Warning"
          description="The email you enter is case sensitive. If you are using Single Sign On (SSO), you must match the case of the email from your organization's system, or account linking will not occur. For example, john.doe@example.com is not the same as John.Doe@example.com."
          type="warning"
          showIcon
        />
        <FormWrapper>
          <Form form={form}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input user name!" }]}
            >
              <Input placeholder={""} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input user email!",
                },
              ]}
            >
              <Input placeholder={""} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="role_groups"
              label="User Roles"
              rules={[{ required: true, message: "Please select user role!" }]}
            >
              <Select mode="multiple" showArrow>
                {rolesList &&
                  rolesList.map((role, index) => {
                    return (
                      <Select.Option key={index} value={role}>
                        {role}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="groups&entities"
              label="Groups and Entities"
            >
              <GroupsAndEntitiesTable
                dataSource={dataSource}
                refDataSheets={refDataSheets}
                setDataSource={setDataSource}
                form={form}
                editingKey={editingKey}
                groups={groups}
                loadingRefDataSheetsData={loadingRefDataSheetsData}
                setEditingKey={setEditingKey}
              />
            </Form.Item>
            <StyledButton
              disabled={editingKey.length}
              onClick={() => handleAdd("G")}
              type="custom"
            >
              {" "}
              <PlusCircleOutlined /> Add group
            </StyledButton>{" "}
            <StyledButton
              disabled={editingKey.length}
              onClick={() => handleAdd("E")}
              type="custom"
            >
              {" "}
              <PlusCircleOutlined /> Add entity
            </StyledButton>{" "}
          </Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default CreateUserModal;
