import React, { useState } from "react";
import { message, Switch, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CommonTable, StyledButton } from "../../../shared/commonStyles";
import DataSheetFormViewModal from "./../../../components/Modals/DataSheetFormViewModal/DataSheetFormViewModal";
import InstructionsModal from "./InstructionsModal";
import { DisplayFieldsModal } from "./DisplayFieldsModal";

const DataSheetSetupTable = ({
  dataSheetsList,
  loadDataSheets,
  handleMultilineFormFlag,
  handleHideFields,
  handleShowNotes,
  handleInstructionsForm,
  handleMassUploadToggle,
  handleChangeDisplayFields,
  initialSurveyValues,
}) => {
  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const [rowDataView, setRowDataView] = useState(null);
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [isDisplayFieldsModal, setDisplayFieldsModal] = useState(false);
  const [dataSheet, setDataSheet] = useState();

  const handleDisplayFieldsModal = (data) => {
    setDataSheet(data);
    setDisplayFieldsModal(true);
  };

  const onShowInstructionsModal = (record) => {
    setInstructionsModal(true);
    setRowDataView(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "sheet_name",
      width: "120px",
    },
    {
      title: "Multiline Form",
      dataIndex: "multiline_form_flag",
      width: "10%",
      render: (status, data) => {
        return (
          <>
            <Switch
              onChange={(checked) => handleMultilineFormFlag(checked, data)}
              checked={status}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </>
        );
      },
    },

    {
      title: "Enable Mass Upload",
      dataIndex: "mass_upload_status",
      width: "10%",
      render: (status, data) => {
        return (
          <>
            {data.multiline_form_flag && (
              <Switch
                onChange={(checked) => handleMassUploadToggle(checked, data)}
                checked={status}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Custom Instructions",
      dataIndex: "instructions",
      width: "10%",
      render: (_, data) => {
        return (
          <>
            <StyledButton onClick={() => onShowInstructionsModal(data)}>
              Add
            </StyledButton>
          </>
        );
      },
    },
    {
      title: "Metadata Fields",
      dataIndex: "hide_fields",
      width: "10%",
      render: (status, data) => {
        return (
          <>
            {data?.metadata_sheet_id ? (
              <Switch
                onChange={(checked) => handleHideFields(checked, data)}
                checked={status}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            Notes
            <Popover
              overlayClassName="popoverContent"
              content={
                <div style={{ width: "320px" }}>
                  To allow users to add notes for a data sheet on this survey,
                  please enable comments from that data sheet’s settings page.
                </div>
              }
              autoAdjustOverflow
              arrowPointAtCenter
            >
              {" "}
              <InfoCircleOutlined style={{ marginLeft: "5px" }} />
            </Popover>
          </div>
        );
      },

      dataIndex: "show_notes",
      width: "10%",
      render: (status, data) => {
        return (
          <>
            {data?.enable_comments && (
              <Switch
                onChange={(checked) => handleShowNotes(checked, data)}
                checked={status}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Display fields",
      dataIndex: "display_fields",
      width: "10%",
      render: (_, data) => {
        return (
          <StyledButton onClick={() => handleDisplayFieldsModal(data)}>
            Manage fields
          </StyledButton>
        );
      },
    },
  ];

  const submitInstruction = () => {
    if (instructions) {
      const response = handleInstructionsForm(instructions, rowDataView);
      if (response === "success") {
        setInstructionsModal(false);
      }
    } else {
      message.error("Please enter some instruction");
      return;
    }
  };

  return (
    <>
      <InstructionsModal
        visible={instructionsModal}
        onClose={() => setInstructionsModal(false)}
        submitInstruction={submitInstruction}
        rowData={rowDataView}
        setInstructions={setInstructions}
        instructions={instructions}
      />

      {showFormViewModal && (
        <DataSheetFormViewModal
          visible={showFormViewModal}
          onClose={() => setShowFormViewModal(false)}
          rowData={rowDataView}
        />
      )}
      {isDisplayFieldsModal && (
        <DisplayFieldsModal
          visible={isDisplayFieldsModal}
          onClose={() => setDisplayFieldsModal(false)}
          onConfirm={(fields, placeholders, isAppliedToAll) =>
            handleChangeDisplayFields(
              fields,
              placeholders,
              dataSheet,
              isAppliedToAll
            )
          }
          dataSheet={dataSheet}
          initialSurveyValues={initialSurveyValues}
        />
      )}

      <CommonTable
        loading={loadDataSheets}
        rowKey={(data) => data?.["_id"]?.["$oid"]}
        dataSource={dataSheetsList}
        columns={columns}
        scroll={{ x: true }}
      />
    </>
  );
};

export default DataSheetSetupTable;
