import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  message,
  Spin,
  Typography,
  Input,
  InputNumber,
  Divider,
  Row,
  Col,
  Card,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import moment from "moment";
import * as mutations from "../../../../graphql/mutations";
import { getCompanyInfo } from "../../../../services/companyInfo";
import UpdateLogo from "./UpdateLogo";
import UpdateIcon from "./UploadIcon";
const { Title } = Typography;

const CompanyDetail = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [refreshData, setRefreshData] = useState(0);

  const staticCompanyStages = [
    {
      label: "Startup",
      value: "startup",
    },
    {
      label: "Small-medium sized",
      value: "small-medium-sized",
    },
    {
      label: "Enterprise",
      value: "enterprise",
    },
  ];

  const staticIndustries = [
    {
      label: "CONSUMER GOODS",
      value: "CONSUMER GOODS",
    },
    {
      label: "FOOD AND BEVERAGE",
      value: "FOOD AND BEVERAGE",
    },
    {
      label: "RESOURCE TRANSFORMATION",
      value: "RESOURCE TRANSFORMATION",
    },
    {
      label: "EXTRACTIVES AND MINERALS PROCESSING",
      value: "EXTRACTIVES AND MINERALS PROCESSING",
    },
    {
      label: "HEALTH CARE",
      value: "HEALTH CARE",
    },
    {
      label: "SERVICES",
      value: "SERVICES",
    },
    {
      label: "FINANCIALS",
      value: "FINANCIALS",
    },
    {
      label: "INFRASTRUCTURE",
      value: "INFRASTRUCTURE",
    },
    {
      label: "TECHNOLOGY AND COMMUNICATIONS",
      value: "TECHNOLOGY AND COMMUNICATIONS",
    },
    {
      label: "RENEWABLE RESOURCES AND ALTERNATIVE ENERGY",
      value: "RENEWABLE RESOURCES AND ALTERNATIVE ENERGY",
    },
    {
      label: "TRANSPORTATION",
      value: "TRANSPORTATION",
    },
  ];

  const [form] = Form.useForm();
  const { Option } = Select;

  const loadCompanyInfo = async () => {
    try {
      setLoader(true);
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";
        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, [refreshData]);

  useEffect(() => {
    try {
      const reportPeriod = `FY ${moment(new Date(), "YY").format("YY")}`;
      if (companyDetails) {
        form.setFieldsValue({
          ...companyDetails,
          period_name: reportPeriod,
        });
      } else {
        form.setFieldsValue({
          period_name: reportPeriod,
        });
      }
    } catch (error) {
      console.log("Error while set form values!");
      message.error("Something went wrong!");
    }
  }, [form, companyDetails]);

  const onSelectChange = (name, value) => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  const onFormSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setSubmitting(true);
          const data = await Auth.currentSession();
          const groupId = data["accessToken"]["payload"][
            "cognito:groups"
          ].filter((element) => element.includes("org:"))[0];
          if (companyDetails) {
            const payload = {
              data: `${JSON.stringify({ ...companyDetails, ...values })}`,
              group: groupId,
            };
            const response = await API.graphql({
              query: mutations.updateCompanyInfo,
              variables: { input: payload },
            });

            const updatedCompanyInfo = response["data"]["updateCompanyInfo"];
            if (updatedCompanyInfo) {
              message.success("Data updated Successfully!");
            }
          } else {
            const payload = {
              data: `${JSON.stringify(values)}`,
              group: groupId,
            };
            const response = await API.graphql({
              query: mutations.createCompanyInfo,
              variables: { input: payload },
            });
            const createdCompanyInfo = response["data"]["createCompanyInfo"];
            if (createdCompanyInfo) {
              message.success("Data saved Successfully!");
              setRefreshData(refreshData + 1);
            }
          }
        } catch (error) {
          message.error(`Error while submitting data ${error}`);
        } finally {
          setSubmitting(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong.");
        console.log(e);
      });
  };

  return (
    <Spin spinning={loader}>
      <FormWrapper>
        <Form form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Title level={4} className="color-white">
                  Company Details
                </Title>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <small className="color-white">Company Logo</small>
                    <UpdateLogo />
                  </Col>
                  <Col span={6}>
                    <small className="color-white">Company Icon</small>
                    <UpdateIcon />
                  </Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Form.Item
                      name="business_unit_name"
                      label="Legal Name"
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder={""} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="location_name"
                      label="Location"
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder={"San Francisco"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="industry"
                      label="Industry"
                      labelCol={{ span: 24 }}
                    >
                      <Select>
                        {staticIndustries &&
                          staticIndustries.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="company_stage"
                      label="Stage"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        onChange={(value) =>
                          onSelectChange("company_stage", value)
                        }
                      >
                        {staticCompanyStages &&
                          staticCompanyStages.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Default Welcome Message Title"
                      name="default_welcome_message_title"
                      labelCol={{ span: 24 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Default Welcome Message"
                      name="default_welcome_message"
                      labelCol={{ span: 24 }}
                      className="ckMediumHeight"
                    >
                      <CKEditor
                        editor={DecoupledDocumentEditor}
                        data={form.getFieldValue("default_welcome_message")}
                        onReady={(editor) => {
                          if (editor?.ui) {
                            editor.ui.view.editable.element.parentElement.insertBefore(
                              editor.ui.view.toolbar.element,
                              editor.ui.view.editable.element
                            );
                          }
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          form.setFieldsValue({
                            default_welcome_message: data,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Divider
                      style={{ backgroundColor: "#4F4669", margin: "8px 0" }}
                    />
                    <Form.Item>
                      <StyledButton
                        type="custom"
                        onClick={() => navigate("/management")}
                        hovercolor="#A68DFB"
                        hoverbgcolor="transparent"
                        bgcolor="transparent"
                        bordercolor="transparent"
                        color="#fff"
                      >
                        <span>Cancel</span>
                      </StyledButton>
                      <StyledButton
                        loading={submitting}
                        type="custom"
                        onClick={() => onFormSubmit()}
                      >
                        Submit
                      </StyledButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Spin>
  );
};

export default CompanyDetail;
