import React, { useState } from "react";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { StyledButton } from "../../../shared/commonStyles";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DataSheetsTypeModal } from "./DataSheetsTypeModal";

export const DataSheetsButtons = () => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  return (
    <div>
      <DataSheetsTypeModal
        isVisible={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      />
      {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
        <StyledButton
          type="primary"
          onClick={() => setCreateModalVisible(true)}
        >
          <PlusCircleOutlined /> <span>Create Data Sheet</span>
        </StyledButton>
      )}
      <StyledButton type="primary">
        <Link to="/data/data-sheets/deleted">View deleted datasheets</Link>
      </StyledButton>
    </div>
  );
};
