import { GroupOutlined } from "@ant-design/icons";
import { Avatar, message, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AvatarUsername } from "../../../../components/Avatar/AvatarUsername";
import { getUserGroups } from "../../../../services/users";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import { StyledButton } from "../../../../shared/commonStyles";
const Assignees = ({ record, onAddEditors, type }) => {
  const [usersGroupList, setUsersGroupList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [groupLoader, setGroupLoader] = useState(false);
  const loadUsersGroupData = async () => {
    try {
      setGroupLoader(true);
      const userGroups =
        type === "auditor"
          ? record?.auditors?.groupIds
          : record?.editors?.groupIds;
      if (userGroups?.length > 0) {
        const payload = {
          filter: { _id: { $oid: userGroups } },
        };
        const groups = await getUserGroups(payload);
        const data = groups.map((item) => {
          return { name: item.name || "" };
        });
        setUsersGroupList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setGroupLoader(false);
    }
  };

  const userData = async () => {
    try {
      setLoader(true);

      const assignedUsers =
        type === "auditor"
          ? record?.auditors?.userIds
          : record?.editors?.userIds;
      if (assignedUsers?.length > 0) {
        const data = await Promise.all(
          assignedUsers?.map(async (item) => {
            const usersList = await getFirstAndLastNameByUsername(item);
            return {
              name: usersList ? usersList : "",
            };
          })
        );
        setUsersList(data);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadUsersGroupData();
    userData();
  }, [record]);

  return (
    <Spin spinning={loader || groupLoader}>
      {usersList.length > 0 || usersGroupList.length > 0 ? (
        <Avatar.Group>
          {usersList?.map((item, index) => {
            return (
              <AvatarUsername
                key={index}
                size="medium"
                username={item?.name}
                customTooltipTitle={item?.name}
              />
            );
          })}

          {usersGroupList?.map((item, index) => {
            return (
              <Tooltip
                placement="top"
                overlayInnerStyle={{ width: "auto" }}
                title={item?.name}
                key={index}
              >
                <Avatar
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                  icon={<GroupOutlined />}
                />
              </Tooltip>
            );
          })}
        </Avatar.Group>
      ) : (
        <StyledButton
          type="custom"
          onClick={() => onAddEditors(record, type)}
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#A68DFB"
        >
          Add {type === "auditor" ? "Auditors" : "Editors"}
        </StyledButton>
      )}
    </Spin>
  );
};
export default Assignees;
