import React from "react";
import { FormWrapper } from "../../../shared/commonStyles";
import GoalsTable from "./components/GoalsTable";
const ManageDeletedGoals = () => {
  return (
    <FormWrapper>
      <GoalsTable />
    </FormWrapper>
  );
};

export default ManageDeletedGoals;
