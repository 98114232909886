import { Button, Row } from "antd";
import React from "react";
import { StyledButton } from "../../../shared/commonStyles";

interface FooterButtonsProps {
  step: number;
  surveyId?: string;
  onClickNext: () => void;
  onClickBack: () => void;
  disabled?: boolean;
}

export const FooterButtons = ({
  step,
  surveyId,
  onClickBack,
  onClickNext,
  disabled,
}: FooterButtonsProps) => {
  const getOkButtonName = () => {
    switch (step) {
      case 1:
        return "Continue to Step 2";
      case 2:
        return "Continue";
      case 3:
        return "Continue";
      case 4:
        return surveyId ? "Update Survey" : "Create Survey";
      default:
        return "Continue";
    }
  };
  return (
    <Row justify="start" align="middle" style={{ marginTop: "20px" }}>
      <Button className="margin-5" onClick={() => onClickBack()} type="text">
        {step === 1 ? "Cancel" : "Back"}
      </Button>
      <StyledButton
        className="margin-5"
        onClick={() => onClickNext()}
        type="custom"
        disabled={disabled}
      >
        {getOkButtonName()}
      </StyledButton>
    </Row>
  );
};
