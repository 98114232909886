import React from 'react';
import { Menu,Typography} from 'antd';
import ReactPlayer from 'react-player/lazy';
import {
   BookOutlined
  } from '@ant-design/icons';

const { SubMenu } = Menu;
const {Title} = Typography;
export const  LeftSideMenu =({educationData,handleClick}) =>{
    return (
      <>
        <div style={{margin: '30px 24px'}}>
          <Title level={4} className = "color-white">Table of Contents</Title>
        </div>
      <Menu
        // onClick={handleClick}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme='dark'
        style={{
          width : '100%',
          background: '#2C263F',
        }}
      >
        <SubMenu key="sub1" 
          icon={<BookOutlined />} 
          title="Education" 
          className = "tableContentSubMenu"
          >
            {educationData && educationData?.map((data)=>{
              return (
                      <Menu.ItemGroup key={data.type} title={data.type}>
                      {data.data && data.data.map((element)=>{
                        return <Menu.Item className='menu_item' title={element.name} onClick={()=>handleClick(element)} key={element._id.$oid}>
                          <div>
                          <ReactPlayer 
                            url={element.content_url} 
                            controls={false}
                            light = {true}
                          />
                          </div>
                          <div style={{paddingLeft : '25px'}}>
                            {element.name}
                          </div>
                        </Menu.Item>
                        })}
                      </Menu.ItemGroup>
                    );
            })}
          </SubMenu>
      </Menu> 
      </>     
    );
  }