import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Typography,
  Divider,
  Radio,
  Col,
  Select,
  InputNumber,
  Input,
  Spin,
} from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import {
  additionalQueryOperators,
  queryOperators,
} from "../../../services/mongoOperators";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import SurveyKpiService from "../../../services/SurveyKpiService";
import { getUsersList } from "../../../services/users";
import { getSurveys } from "../../../services/surveys";

const { Title } = Typography;
interface KpiFiltersProps {
  initialFilters: KpiFormFilters;
  dataSheet?: DatasheetWithKpi;
  visible: boolean;
  onClose: () => void;
  onFinish: (data: KpiFormFilters) => void;
  columns: KpiColumn[];
  onReset: () => void;
}
interface DatasheetWithKpi extends DataSheet {
  key: string;
  kpis: SurveyKPI[];
}
interface Option {
  label?: string;
  value?: string;
}
interface KpiFormFilters {
  filters: AdvancedDataFilter[];
  operatorType?: string;
}
type InputType = {
  index?: number;
  inputType?: string;
  value?: string;
};
interface KpiColumn extends ColumnType<SurveyKPI> {
  hide?: boolean;
  name?: string;
  label?: string;
  inputType?: string;
  value?: string;
}

export const KpiFilters = ({
  initialFilters,
  dataSheet,
  visible,
  onClose,
  onFinish,
  columns,
  onReset,
}: KpiFiltersProps) => {
  const [typesInput, setTypesInput] = useState<InputType[]>([]);
  const [optionsSurvey, setOptionsSurvey] = useState<Option[]>([]);
  const [optionsUsers, setOptionsUsers] = useState<Option[]>([]);
  const [submitting] = useState(false);
  const [formInitialValue, setFormInitialValue] = useState<KpiFormFilters>(
    {} as KpiFormFilters
  );
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const mongoOperations = [...queryOperators, ...additionalQueryOperators];

  const getFilters = async () => {
    try {
      setLoader(true);
      if (dataSheet?._id?.$oid) {
        const filtersOptions = await SurveyKpiService.getSurveyKpiFilters(
          dataSheet?._id?.$oid
        );
        if (filtersOptions.survey_id?.length) {
          const payload = {
            filter: {
              or: filtersOptions.survey_id.map((id: string) => ({
                id: { eq: id },
              })),
            },
          };
          const surveys = await getSurveys(payload);
          const filterSurveys = surveys.filter((v: SurveyInfo) =>
            filtersOptions.survey_id?.includes(v.id)
          );
          const optionsSurveys = filterSurveys.map((v: SurveyInfo) => ({
            ...v,
            value: v.id,
            label: v.name,
          }));
          setOptionsSurvey(optionsSurveys);
        }
        if (filtersOptions.username?.length) {
          const users = await getUsersList();
          const filterUsers = users.filter((v: User) =>
            filtersOptions.username?.includes(v.key)
          );
          setOptionsUsers(filterUsers);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  const getSelectOptions = (key: number) => {
    let options: Option[] = [];
    const formValues = form.getFieldsValue();

    if (formValues?.filters?.[key]?.column === "surveyId") {
      options = optionsSurvey;
    }
    if (formValues?.filters?.[key]?.column === "username") {
      options = optionsUsers;
    }
    return options;
  };
  useEffect(() => {
    form.setFieldsValue(initialFilters);
    setFormInitialValue(initialFilters);
    getFilters();
    if (initialFilters.filters?.length) {
      const newTypesInput: any[] = [];
      initialFilters?.filters?.forEach((v, index) => {
        columns?.forEach((item) => {
          if (v?.column && v?.column === item.value) {
            newTypesInput.push({
              index,
              value: v.column,
              inputType: item.inputType,
            });
          }
        });
      });
      setTypesInput(newTypesInput);
    }
  }, [initialFilters, dataSheet]);

  const handleChangeColumn = (value: any, item: any, key: number) => {
    const newItem = {
      inputType: item.inputType,
      value: item.value,
      index: key,
    };

    if (!typesInput?.find((v) => v.index === key)) {
      setTypesInput([...typesInput, newItem]);
    } else {
      const data = typesInput.map((v) => {
        if (v.index === key) {
          return newItem;
        } else {
          return v;
        }
      });
      setTypesInput(data);
    }
    resetInput && resetInput(key);
  };

  const handleRemoveFilter = (key: number) => {
    const newTypes = typesInput
      .filter((v) => v.index !== key)
      .map((v, index) => {
        return { ...v, index: index };
      });
    setTypesInput(newTypes);
    handleChangeFilters();
  };

  const getOperations = (key: number) => {
    const inputType = typesInput[key]?.inputType;
    switch (inputType) {
      case "select":
        return mongoOperations.filter(
          (v) => v.value === "$in" || v.value === "$eq"
        );
      case "string":
        return mongoOperations.filter(
          (v) => v.value !== "$in" && v.value !== "$all"
        );
      case "dropdown":
        return mongoOperations.filter(
          (v) => v.value !== "$in" && v.value !== "$all"
        );
      default:
        return mongoOperations.filter(
          (v) =>
            v.value !== "$in" &&
            v.value !== "$all" &&
            v.value !== "$regex" &&
            v.value !== "doesNotContain"
        );
    }
  };

  const isDisabled = (index: number) => {
    const formFilters = form.getFieldsValue();
    return (
      formFilters &&
      (formFilters?.filters?.[index]?.op === "isEmpty" ||
        formFilters?.filters?.[index]?.op === "isNotEmpty")
    );
  };

  const onFinishHandler = (data: KpiFormFilters) => {
    onFinish(data);
    onClose();
  };

  const onClickReset = () => {
    onReset();
  };
  const resetInput = (key: number, isOperator?: boolean) => {
    const formValues = form.getFieldsValue(true);
    const filtersValues = formValues.filters.map(
      (item: AdvancedDataFilter, index: number) => {
        if (index === key) {
          return {
            ...item,
            op: isOperator ? item.op : null,
            column_value:
              item.column === "username" || item.column === "surveyId"
                ? isOperator && item.op === "$eq"
                  ? null
                  : []
                : "",
          };
        } else {
          return item;
        }
      }
    );
    form.setFieldsValue({ ...formValues, filters: filtersValues });
    setFormInitialValue({ ...formValues, filters: filtersValues });
  };

  const handleChangeFilters = (key?: number) => {
    const formValues = form.getFieldsValue(true);
    setFormInitialValue(formValues);
    (key || key === 0) && resetInput(key, true);
  };

  return (
    <CommonModal
      title={"Filter Records"}
      visible={visible}
      onCancel={onClose}
      width={800}
      okText="Apply"
      onOk={onFinishHandler}
      footer={null}
      maskClosable={false}
      confirmLoading={submitting}
      destroyOnClose
      centered
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialFilters}
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Title level={4}>Filter Kpi fields</Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
          {formInitialValue?.filters?.length > 1 && (
            <>
              <Title level={5}>Filter Operator Type</Title>
              <Form.Item
                name="operatorType"
                key={"operatorType"}
                rules={[
                  {
                    required: true,
                    message: "Select operator type",
                  },
                ]}
              >
                <Radio.Group
                  style={{ marginBottom: 20 }}
                  onChange={() => handleChangeFilters()}
                >
                  <Radio value="$and">AND</Radio>
                  <Radio value="$or">OR</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Title level={5}>Kpi filters</Title>

            <Form.List name={"filters"} key={"filters"}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      const checkInputType = typesInput.find(
                        (field) => field?.index === index
                      )?.inputType;
                      return (
                        <Row
                          key={key}
                          align={"top"}
                          justify="space-between"
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <Col span={8}>
                            <Form.Item
                              name={[name, "column"]}
                              label={""}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required.",
                                },
                              ]}
                              style={{ width: "100%" }}
                            >
                              <Select
                                onChange={(value, item) => {
                                  handleChangeColumn(value, item, index);
                                }}
                                placeholder="Field name"
                                options={columns}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              label={""}
                              name={[name, "op"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required.",
                                },
                              ]}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Equality operator"
                                onChange={() => handleChangeFilters(index)}
                                options={getOperations(index)}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            {checkInputType == "number" ? (
                              <Form.Item
                                label={""}
                                name={[name, "column_value"]}
                                rules={[
                                  {
                                    required: !isDisabled(index),
                                    message: "This field is required.",
                                  },
                                ]}
                                style={{ width: "100%" }}
                              >
                                <InputNumber
                                  disabled={isDisabled(index)}
                                  placeholder="Input variable"
                                />
                              </Form.Item>
                            ) : checkInputType == "select" ? (
                              <Form.Item
                                valuePropName="value"
                                label={""}
                                name={[name, "column_value"]}
                                rules={[
                                  {
                                    required: !isDisabled(index),
                                    message: "This field is required.",
                                  },
                                ]}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  placeholder="Input variable"
                                  disabled={isDisabled(index)}
                                  style={{ width: "100%" }}
                                  notFoundContent={
                                    loader && <Spin size="small" />
                                  }
                                  mode={
                                    formInitialValue?.filters?.[index]?.op ===
                                    "$eq"
                                      ? undefined
                                      : "multiple"
                                  }
                                  allowClear
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={getSelectOptions(index)}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                label={""}
                                name={[name, "column_value"]}
                                rules={[
                                  {
                                    required: !isDisabled(index),
                                    message: "This field is required.",
                                  },
                                ]}
                                style={{ width: "100%" }}
                              >
                                <Input
                                  disabled={isDisabled(index)}
                                  placeholder="Input variable"
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <Form.Item label={""} labelCol={{ span: 24 }}>
                            <Col span={2}>
                              <StyledButton
                                type="default"
                                style={{
                                  padding: "8px",
                                  marginTop: "0px",
                                  high: "30px",
                                }}
                                disabled={columns?.length === 0}
                                onClick={() => {
                                  remove(index);
                                  handleRemoveFilter(index);
                                }}
                                icon={<DeleteOutlined />}
                              />
                            </Col>
                          </Form.Item>
                        </Row>
                      );
                    })}
                    <Form.Item
                      name={""}
                      rules={[
                        {
                          required: fields.length === 0,
                          message: "Please add filters.",
                        },
                      ]}
                    >
                      <StyledButton
                        type="custom"
                        disabled={columns?.length === 0}
                        onClick={() => {
                          add();
                          handleChangeFilters();
                        }}
                      >
                        <PlusCircleOutlined /> {"Add filter"}
                      </StyledButton>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <StyledButton
                type="custom"
                bgcolor="#443A59"
                bordercolor="#443A59"
                padding="4px 15px"
                onClick={onClickReset}
              >
                Reset
              </StyledButton>
              <StyledButton type="custom" htmlType="submit">
                Apply
              </StyledButton>
            </Row>
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
