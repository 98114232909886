import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const addPermissions = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "add_permissions",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return response;
};

export const updatePermission = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "update_permission",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const updatePermissionByUser = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "update_permissions_by_user",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};
export const updatePermissionByGroup = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "update_permissions_by_group",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};
export const listPermissions = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "list_permissions",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};
export const checkPermission = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "check_permission",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};
