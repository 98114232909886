import React from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { Col, Row, Switch, Typography } from "antd";
import { withTheme } from "@rjsf/core";
import { Theme as AntDTheme } from "@rjsf/antd";
const { Title } = Typography;

import { FormBuilder } from "@ginkgo-bioworks/react-json-schema-form-builder";
import "./FormBuilder.css";
const FormSystem = withTheme(AntDTheme);

const step3 = ({
  onClickPrevious,
  onSubmitHandler,
  setFormOption,
  formOption,
  loadingCreateForm,
  schema,
  setSchema,
  UISchema,
  setUISchema,
}) => {
  const handleSwitch = (checked) => {
    setFormOption(checked);
  };

  return (
    <>
      {!formOption ? (
        <FormWrap width="60%">
          <FormWrapper>
            <Row gutter={[16, 16]}>
              <Col span={18} justify="flex-start">
                <Title level={3}>
                  Custom Data Model{" "}
                  <span className="color-grey">(optional)</span>
                </Title>{" "}
              </Col>
              <Col span={6} justify="flex-end">
                <Switch
                  checked={formOption}
                  onChange={(checked) => handleSwitch(checked)}
                />
                {formOption ? " Enabled" : " Disabled"}
              </Col>

              <Col span={24}>
                <p className="color-grey">
                  {`Create a custom data model using the builder. The form you create will
                  be tagged to your orgnization's profile and available to other users.`}
                </p>
              </Col>
              <Col span={24}>
                <StyledButton
                  className="margin-5"
                  type="custom"
                  onClick={() => onClickPrevious()}
                  hovercolor="#A68DFB"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#fff"
                >
                  Back
                </StyledButton>

                <StyledButton
                  className="margin-5"
                  onClick={onSubmitHandler}
                  type="custom"
                  loading={loadingCreateForm}
                >
                  <span>Finish</span>
                </StyledButton>
              </Col>
            </Row>
          </FormWrapper>
        </FormWrap>
      ) : (
        <>
          {formOption && (
            <Row>
              <Col span={12} offset={2}>
                <FormWrap width="100%" style={{ padding: "35px 40px" }}>
                  <FormWrapper>
                    <Row gutter={[16, 16]}>
                      <Col span={18} justify="flex-start">
                        <Title level={3}>
                          Custom Data Model{" "}
                          <span className="color-grey">(optional)</span>
                        </Title>{" "}
                      </Col>
                      <Col span={6} justify="flex-end">
                        <Switch
                          checked={formOption}
                          onChange={(checked) => handleSwitch(checked)}
                        />
                        {formOption ? " Enabled" : " Disabled"}
                      </Col>

                      <Col span={24}>
                        <p className="color-grey">
                          {`Create a custom data model using the builder. The form you create will
                            be tagged to your orgnization's profile and available to other users.`}
                        </p>
                      </Col>
                      <Col span={24}>
                        <FormBuilder
                          schema={schema}
                          uischema={UISchema}
                          onChange={(newSchema, newUiSchema) => {
                            setSchema(newSchema);
                            setUISchema(newUiSchema);
                          }}
                          mods={{
                            deactivatedFormInputs: [
                              "time",
                              "array",
                              "datetime",
                              "password",
                            ],
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <StyledButton
                          className="margin-5"
                          type="custom"
                          onClick={() => onClickPrevious()}
                          hovercolor="#A68DFB"
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="#fff"
                        >
                          Back
                        </StyledButton>

                        <StyledButton
                          className="margin-5"
                          onClick={onSubmitHandler}
                          //htmlType="submit"
                          type="custom"
                          loading={loadingCreateForm}
                        >
                          <span>Finish</span>
                        </StyledButton>
                      </Col>
                    </Row>
                  </FormWrapper>
                </FormWrap>
              </Col>
              <Col
                span={8}
                style={{
                  background: "#3C3453",
                  border: "1px solid #3A3C5A",
                  boxShadow: "8px 7px 19px 3px rgba(0, 0, 0, 0.08)",
                  padding: "35px 40px",
                }}
              >
                <FormWrapper className="customBuilder">
                  <Title className="color-white" level={3}>
                    Preview <span className="color-grey">(View Only)</span>
                  </Title>

                  <FormSystem
                    schema={JSON.parse(schema)}
                    uiSchema={JSON.parse(UISchema)}
                    formData={{}}
                  />
                </FormWrapper>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default step3;
