import { Typography } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import styles from "./CreateTaskDeploymentModal.module.scss";
import { encodeUrlName } from "../../../../shared/commonFunctions";

interface CreateTaskDeploymentModalProps {
  isVisible: boolean;
  taskDeployments: SurveyDeployment[];
  onClose: () => void;
}

interface SurveyInfo {
  surveyId: string;
  entityIds?: string[];
  defaultMetadataSheetId: string;
  surveyName: string;
}

export const CreateTaskDeploymentModal = ({
  isVisible,
  taskDeployments,
  onClose,
}: CreateTaskDeploymentModalProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleGoToImport = () => {
    onClose();
    navigate(
      `/management/collect-data/${
        (state as SurveyInfo).surveyId
      }/${encodeUrlName(
        (state as SurveyInfo).surveyName
      )}/deploy-survey/import-users`,
      {
        state: {
          type: "importUsers",
          surveyId: (state as SurveyInfo).surveyId,
          entityIds: (state as SurveyInfo).entityIds,
          surveyName: (state as SurveyInfo).surveyName,
          defaultMetadataSheetId: (state as SurveyInfo).defaultMetadataSheetId,
          taskDeployments: taskDeployments,
        },
      }
    );
  };
  const handleGoToAddManually = () => {
    onClose();
    navigate(
      `/management/collect-data/${
        (state as SurveyInfo).surveyId
      }/${encodeUrlName(
        (state as SurveyInfo).surveyName
      )}/deploy-survey/add-users-manually`,
      {
        state: {
          type: "addManuallyUsers",
          surveyName: (state as SurveyInfo).surveyName,
          surveyId: (state as SurveyInfo).surveyId,
          entityIds: (state as SurveyInfo).entityIds,
          defaultMetadataSheetId: (state as SurveyInfo).defaultMetadataSheetId,
          taskDeployments: taskDeployments,
        },
      }
    );
  };

  return (
    <CommonModal
      width={500}
      title="Create New Deployment"
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      centered
    >
      <FormWrapper className={styles.buttonWrap}>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          How would you like to assign users?
        </Typography.Title>
        <StyledButton
          type="default"
          className={styles.button}
          onClick={handleGoToAddManually}
        >
          Add users manually
        </StyledButton>
        <StyledButton
          type="default"
          className={styles.button}
          onClick={handleGoToImport}
        >
          Import a list of users
        </StyledButton>
      </FormWrapper>
    </CommonModal>
  );
};
