import { message } from "antd";
import {
  getFilesById,
  getFilesByIdNew,
  insertImportData,
  modifyStatus,
  processFileMultipleSheet,
  updateImportData,
  uploadFile,
  uploadFileNew,
  fetchDataWithRetry,
} from "../../services/importData";
import * as path from "path";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  fetchRecordData,
  massApproveWithDeduplication,
} from "../../services/dataSheet";

export const getImportedFiles = async (files?: string[], dataId?: string) => {
  const newFiles: ImportedFile[] = [];
  if (files?.length) {
    // for (const fileId of fileIds || []) {
    const filesList = await getFilesByIdNew(files);
    // if (filesList) {

    const list = filesList
      .replace(/['"]+/g, "")
      .replace(/^\[(.+)\]$/, "$1")
      .split(",")
      .map((v: string) => ({ name: path.basename(v), url: v }));
    list.forEach((v: { name: string; url: string }, i: number) =>
      newFiles.push({ name: v.name, dataId: files[i], url: v.url })
    );
  } else {
    const filesList = await getFilesByIdNew(files);
    if (filesList) {
      const list = filesList
        .replace(/['"]+/g, "")
        .replace(/^\[(.+)\]$/, "$1")
        .split(",")
        .map((v: string) => ({ name: path.basename(v), url: v }));
      list.forEach((v: { name: string; url: string }, i: number) =>
        newFiles.push({
          name: v.name,
          dataId: files?.[i],
          url: v.url,
          isBaseFile: true,
        })
      );
    }
  }
  // if (dataId) {
  //   const filesList = await getFilesByIdNew(files);
  //   if (filesList) {
  //     const list = filesList
  //       .replace(/['"]+/g, "")
  //       .replace(/^\[(.+)\]$/, "$1")
  //       .split(",")
  //       .map((v: string) => ({ name: path.basename(v), url: v }));
  //     list.forEach((v: { name: string; url: string }) =>
  //       newFiles.push({ name: v.name, dataId, url: v.url, isBaseFile: true })
  //     );
  //   }
  // }
  return newFiles;
};
export const processBaseFile = async (
  importId: string,
  signedURL: string,
  fileName: string,
  file: File
) => {
  const arrayBufferData = await file.arrayBuffer();
  if (arrayBufferData) {
    const blob = new Blob([new Uint8Array(arrayBufferData)], {
      type: file.type,
    });
    const result = await fetch(signedURL, {
      method: "PUT",
      body: blob,
    });
    if (result?.status === 200) {
      try {
        const variableProcess = {
          import_id: importId,
          import_status: "PENDING_REVIEW",
          version: 1,
          file_name: fileName,
        };
        const job_id = await processFileMultipleSheet(variableProcess);
        const result = await fetchDataWithRetry(job_id);
        const resultSummary = JSON.parse(result?.resultSummary || "");
        if (resultSummary) {
          const isError = resultSummary?.statusCode >= 400;
          if (isError) {
            message.error(resultSummary?.body);
            return Promise.reject();
          }
          resultSummary?.message && message.success(resultSummary?.message);
        } else {
          return Promise.reject();
        }
      } catch (error: any) {
        message.error("Some problems with processing the file");
        const errorTimeout =
          error?.errors?.[0].errorType === "ExecutionTimeout";
        if (errorTimeout) {
          message.error(
            "The file is too large. Some records may be unprocessed."
          );
        } else {
          return Promise.reject();
        }
      }
    }
  }
};

export const createImport = async (
  file: ImportedFile,
  secondaryFileIds: string[]
) => {
  try {
    const currentDate = moment();
    const payloadImport = {
      import_name: `Multiple sheets ${currentDate.format(
        "YYYY-MM-DD HH:mm:ss A"
      )}`,
      file_id: "",
      files_list: secondaryFileIds,
      import_type: file?.type,
      import_status: "PENDING_REVIEW",
      description: "",
      data_id: uuidv4(),
      createDate: currentDate,
      importCategory: ImportCategory.MULTIPLE_DATASHEETS,
    };
    const CurrentDate = moment().unix();
    const fileName =
      CurrentDate + "-" + file?.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
    const payload = {
      file_category: "DATASHEET",
      version: 1,
      file_name: fileName,
      data_id: payloadImport.data_id,
    };
    const signedURL = await uploadFileNew(
      payloadImport.data_id,
      "",
      payload,
      "dataSheetFiles",
      "UPLOAD_FILE"
    );
    const parsedURL = JSON.parse(signedURL);
    payloadImport.file_id = parsedURL.file_id;
    const importId = await insertImportData(payloadImport);
    if (signedURL) {
      await processBaseFile(
        importId.$oid,
        parsedURL.url,
        fileName,
        file as File
      );
    }
    return importId.$oid;
  } catch (e) {
    return Promise.reject();
  }
};

export const updateImportStatus = async (importData?: DataImport) => {
  try {
    const payload = {
      ...importData,
      import_status: "APPROVED",
    };
    const response = await updateImportData(payload);
    const massApprovePayload = {
      filters: { import_id: importData?._id.$oid },
      column: "import_status",
      value: "APPROVED",
    };
    if (response) {
      await massApproveWithDeduplication(massApprovePayload);
    }
  } catch (e) {
    return Promise.reject();
  }
};

export const loadDataSheetRecords = async (
  sheetId: string,
  importId: string,
  pagination: Pagination
) => {
  try {
    const payload = {
      sheet_id: sheetId,
      filter: { import_id: importId },
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };
    const sheetRecords = await fetchRecordData(payload);
    return sheetRecords;
  } catch (e) {
    return Promise.reject();
  }
};

export const isLessSevenDays = (createDate: string) => {
  const sevenDaysInMilliseconds = 604800000;
  const startDate = new Date(createDate).getTime();
  const currentDate = new Date().getTime();
  const timeDiff = currentDate - startDate;
  return timeDiff <= sevenDaysInMilliseconds;
};
