import React from 'react';
import { Input, Form, Row, Col } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
import {FormWrap,FormWrapper,StyledTitle,StyledButton,} from "../../../../shared/commonStyles";

const step1 = ({
  onClickNext,
  navigate,
  title,
  setTitle,
  description,
  setDescription,
  metricColumnTitle = "Name", // Default set to "Name"
  setMetricColumnTitle,
  reportingColumnTitle = "Reporting Information", // Default set to "Reporting Information"
  descriptionColumnTitle = "Description", // Default set to "Description"
  setDescriptionColumnTitle,
  setReportingColumnTitle,
  standardSheet,
}) => {
  return (
    <FormWrap
      width="600px"
      style={{ marginTop: "50px", backgroundColor: "#2D273F" }}
    >
      <style jsx="true">
        {`
          .ck.ck-placeholder:before,
          .ck .ck-placeholder:before {
            color: #f5f5f5b8;
          }
        `}
      </style>
      <FormWrapper style={{ margin: "20px 0px" }}>
        <Row>
          <div>
            <StyledTitle level={3}>
              Fill in information about the report
            </StyledTitle>
          </div>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <Form.Item labelCol={{ span: 24 }} name="title" label="Title">
              <div className="ckSmallHeight">
                <CKEditor
                  style={{ width: "100%" }}
                  editor={DecoupledDocumentEditor}
                  data={title}
                  config={{
                    fontSize: {
                      options: [
                        11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
                        38, 40,
                      ],
                      supportAllValues: true,
                    },
                    placeholder: "Enter title",
                  }}
                  onReady={(editor) => {
                    if (editor?.ui) {
                      editor.ui.view.editable.element.parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.view.editable.element
                      );
                    }
                  }}
                  onChange={(_, editor) => {
                    const data = editor.getData();
                    setTitle(data);
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <Form.Item labelCol={{ span: 24 }} label="Description">
              <div className="ckSmallHeight">
                <CKEditor
                  style={{ width: "100%" }}
                  editor={DecoupledDocumentEditor}
                  data={description}
                  config={{
                    fontSize: {
                      options: [
                        11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
                        38, 40,
                      ],
                      supportAllValues: true,
                    },
                    placeholder: "Enter description",
                  }}
                  onReady={(editor) => {
                    if (editor?.ui) {
                      editor.ui.view.editable.element.parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.view.editable.element
                      );
                    }
                  }}
                  onChange={(_, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        {!standardSheet && (
          <>
            <Row justify="space-between">
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Reporting Item Column Name"
                >
                  <Input
                    placeholder="Enter name"
                    style={{ width: "100%" }}
                    value={metricColumnTitle}
                    onChange={(e) => {
                      setMetricColumnTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Description Column Name"
                >
                  <Input
                    placeholder="Enter description"
                    style={{ width: "100%" }}
                    value={descriptionColumnTitle}
                    onChange={(e) => {
                      setDescriptionColumnTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Reporting Response Column Name"
                >
                  <Input
                    placeholder="Enter reporting information"
                    style={{ width: "100%" }}
                    value={reportingColumnTitle}
                    onChange={(e) => {
                      setReportingColumnTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row style={{ marginTop: "10px" }}>
          <StyledButton
            type="custom"
            onClick={() => navigate(-1)}
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="white"
            style={{ padding: "11px 5px 11px 0px" }}
          >
            <span>Cancel</span>
          </StyledButton>
          <StyledButton
            className="margin-5"
            onClick={() => onClickNext()}
            type="custom"
          >
            <span>Continue to step 2</span>
          </StyledButton>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default step1
