import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import DataLakeMapDataForm from "./components/DataLakeMapDataForm";
import { useParams } from "react-router-dom";
import { PageWrap, FormWrapper, FormWrap } from "./../../shared/commonStyles";

import {
  getSelectedStandardById,
  parsedStandardData,
} from "../../services/standards";

const UpdateStandard = () => {
  const { standard_id: standardId } = useParams();
  const [loadStandardData, setLoadStandardData] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [onRefreshData, setOnRefreshData] = useState(0);

  const refreshOnSubmit = () => {
    setOnRefreshData(onRefreshData + 1);
  };
  const loadData = async (rowDataId: string) => {
    try {
      setLoadStandardData(true);
      const reportingDataItem = await getSelectedStandardById(rowDataId);
      const parsedData = await parsedStandardData(
        reportingDataItem,
        "reporting"
      );
      setRowData(parsedData);
    } catch (error) {
      message.error("Error while loading Standard Form!");
    } finally {
      setLoadStandardData(false);
    }
  };

  useEffect(() => {
    standardId && loadData(standardId);
  }, [standardId, onRefreshData]);

  return (
    <PageWrap>
      <Spin spinning={loadStandardData}>
        {rowData && (
          <FormWrapper>
            <FormWrap width="100%" style={{ padding: 0 }}>
              <DataLakeMapDataForm
                rowData={rowData}
                refreshOnSubmit={refreshOnSubmit}
                pageType="standard"
              />
            </FormWrap>
          </FormWrapper>
        )}
      </Spin>
    </PageWrap>
  );
};

export default UpdateStandard;
