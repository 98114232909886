import React from "react";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../../shared/commonStyles";

import { Form, Input, Row, Col, Typography, Divider, Select } from "antd";

import { RightOutlined } from "@ant-design/icons";

const { Title } = Typography;
const Step1 = ({ onClickNext }) => {
  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>General information</Title>

            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Name of the document"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                },
              ]}
            >
              <Input placeholder="Type in a name of the document" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
              rules={[
                { required: false, message: "Please input description!" },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Provide a short description of what kind of data will be collected "
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <StyledButton
                className="margin-5"
                onClick={() => onClickNext()}
                type="custom"
              >
                <span>Continue to step 2</span> <RightOutlined />
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default Step1;
