import React, { useState } from "react";
import { Form, Input, message, InputNumber, Button, DatePicker } from "antd";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import {
  CommonModal,
  FormWrapper,
} from "../../../../../../shared/commonStyles";
import { insertMetaDataRecord } from "../../../../../../services/dataSheet";
import { YearMonthDayFormat } from "../../../../../../shared/constants";

const CreateMetaDataRecordModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const onCloseModal = () => {
    onClose();
  };

  const onFinishHandler = (data) => {
    onFinish(data);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onClickSaveRecord = async () => {
    try {
      setSubmiting(true);
      const data = await Auth.currentSession();
      const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const username = data["accessToken"]["payload"]["username"];
      const values = await form.validateFields();
      values["group_id"] = group_id;
      values["username"] = username;
      values["id"] = uuidv4();
      values["archive"] = false;
      const sheetName = dataSheet["sheet_name"];
      const sheetId = dataSheet["_id"]["$oid"];
      const payload = {
        group_id: group_id,
        sheet_id: sheetId,
        sheet_name: sheetName,
        username: username,
        [sheetName]: [values],
      };

      const responseData = await insertMetaDataRecord(payload);
      if (responseData) {
        message.success("Record saved successfully!");
        onFinishHandler(responseData);
        onCloseModal();
      }
    } catch (error) {
      message.error("Error while saving record!");
      console.log("Error while saving record!", error);
    } finally {
      setSubmiting(false);
    }
  };

  const dynamicFormInputs = (dataSheet) => {
    try {
      return dataSheet.sheet_schema.map((item, index) => {
        let inputNode = null;
        const formItemProps = {
          name: item.entity_name,
          rules: [
            { required: item.required, message: `This field is required!` },
          ],
          style: { width: "100%" },
        };

        const inputType = item?.input_type;
        if (inputType === "number") {
          inputNode = <InputNumber type="number" style={{ width: 150 }} />;
        } else if (inputType === "string") {
          inputNode = <Input />;
        } else if (inputType === "date") {
          inputNode = <DatePicker format={YearMonthDayFormat} />;
        } else {
          //string
          inputNode = null;
        }

        return (
          <Form.Item
            key={index}
            labelCol={{ span: 24 }}
            label={item.display_name}
          >
            <Input.Group compact>
              <Form.Item {...formItemProps}>{inputNode}</Form.Item>
            </Input.Group>
          </Form.Item>
        );
      });
    } catch (error) {
      return null;
    }
  };

  return (
    <CommonModal
      title={"Add New Record"}
      visible={visible}
      onCancel={onClose}
      width={600}
      maskClosable={false}
      onOk={onClickSaveRecord}
      okText="Save"
      confirmLoading={submiting}
      destroyOnClose
      footer={[
        <Button key="Cancel" ghost onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="Add record"
          type="primary"
          loading={submiting}
          onClick={onClickSaveRecord}
        >
          Add record
        </Button>,
      ]}
    >
      <FormWrapper>
        <Form form={form}>{dynamicFormInputs(dataSheet)}</Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default CreateMetaDataRecordModal;
