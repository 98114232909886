import React, { useState, useEffect } from "react";
import {
  message,
  Space,
  Menu,
  Dropdown,
  Spin,
  Row,
  Col,
  Checkbox,
  Pagination,
  Select,
} from "antd";
import { useNavigate, Link } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  RightSquareOutlined,
  RollbackOutlined,
  CopyOutlined,
  UsergroupDeleteOutlined,
  FileTextOutlined,
  MoreOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { useBoolean, useSetState } from "ahooks";
import {
  listSelectedStandards,
  parsedStandardData,
} from "../../../../services/standards";
import {
  StyledButton,
  CommonTable,
  StyledTag,
  TableStyledMenu,
  TableHeaderRightTabWrap,
  TableCommonMenu,
  CardsWrap,
  FormWrapper,
} from "./../../../../shared/commonStyles";
import ConfirmArchiveModal from "../components/ConfirmArchiveModal";
import {
  updateSelectedStandards,
  getStandardsFilters,
} from "../../../../services/standards";
import AssignPermissionsModal from "./AssignPermissionsModal";
import { COLORS } from "../../../../shared/colors";
import { SortDropdown } from "../../../../components/SortDropdown";

const DataLakeMapTable = ({
  onRowEdit,
  selectedListReportDataLake,
  setSelectedListReportDataLake,
  report_period,
  location_id,
  business_unit_id,
  refreshListReportDataLake,
  onClickRecategorizeStandard,
  refreshReportDataLake,
  isDeleted,
  selectedListReportIds,
  onClickRecategorizeStandards,
  onClickCloneStandards,
  onReportBtnClick,
}) => {
  const [dataSource, setDatasource] = useState([]);
  const [toggleListCardView, setToggleListCardView] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });

  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [archiveData, setArchiveData] = useState([]);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [status, setStatus] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [showAssignPermissionsModal, setShowAssignPermissionsModal] =
    useState(false);
  const [filters, setFilters] = useSetState({});
  const [standardFilter, setStandardFilter] = useState([]);
  
  const sortData = {
    sortAlphabetically: tableFilters.sortedInfo
      ? tableFilters.sortedInfo?.order === "ascend"
      : true,
    type: tableFilters.sortedInfo?.field || "key",
  };
  const getFilterDataFromLocalStorage = () => {
    setTableFilters({
      filteredInfo: JSON.parse(localStorage.getItem("filteredInfo")),
      sortedInfo: JSON.parse(localStorage.getItem("sortedInfo")),
    });
    refreshReportDataLake();
  };

  useEffect(() => {
    getFilterDataFromLocalStorage();
  }, []);

  useEffect(() => {
    if (dataSource) {
      const standardSet = new Set();
      const codeSet = new Set();

      dataSource.forEach((val) => {
        (val["frameworks"] ?? []).forEach((stan) => standardSet.add(stan));
        // if ("standard_code" in val) codeSet.add(val["standard_code"]);
      });
      setStandardFilter(Array.from(standardSet));
      // setCodeFilter(Array.from(codeSet));
    }
  }, [dataSource])

  const setFilterDataToLocalStorage = (filters, sorter) => {
    localStorage.setItem("filteredInfo", JSON.stringify(filters));
    localStorage.setItem("sortedInfo", JSON.stringify(sorter));
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilterDataToLocalStorage(filters, sorter);
    setPagination(pagination);

    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    refreshReportDataLake();
  };

  const handleChangePage = (current, pageSize) => {
    const newPagination = { ...pagination, current, pageSize };
    setPagination(newPagination);
    refreshReportDataLake();
  };

  const clearTableFilters = () => {
    localStorage.setItem("filteredInfo", null);
    setTableFilters({
      filteredInfo: null,
      sortedInfo: null,
    });
    refreshReportDataLake();
  };

  const loadStandardsFilters = async () => {
    setLoading(true);
    try {
      const data = await Auth.currentSession();
      const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const filter = {
        group_id,
        ...(business_unit_id && { business_unit: business_unit_id }),
        ...(location_id && { location_id }),
        ...(report_period && { report_period }),
        status: isDeleted ? "INACTIVE" : "ACTIVE",
      };
      const standardsFilters = await getStandardsFilters({ filter });
      Object.keys(standardsFilters).forEach((column) => {
        const columnValues = standardsFilters[column].map((val) => {
          if (column === "frameworks") {
            return val
              ? { text: val, value: val }
              : { text: "None", value: "" };
          }
          return { text: val, value: val };
        });
        setFilters({ [column]: columnValues });
      });
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadStandardsFilters();
  }, [business_unit_id, location_id, report_period]);

  const loadData = async () => {
    try {
      setLoading(true);
      const data = await Auth.currentSession();
      const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];

      let filter = {
        group_id,
        ...(business_unit_id && { business_unit: business_unit_id }),
        ...(location_id && { location_id }),
        ...(report_period && { report_period }),
        status: isDeleted ? "INACTIVE" : "ACTIVE",
      };

      if (tableFilters?.filteredInfo) {
        Object.keys(tableFilters?.filteredInfo)?.forEach((item) => {
          if (tableFilters?.filteredInfo[item]) {
            filter = {
              ...filter,
              [item]: { $in: tableFilters?.filteredInfo[item] },
            };
          }
        });
      }
      const sorter = tableFilters.sortedInfo && {
        [tableFilters.sortedInfo?.field]:
          tableFilters.sortedInfo?.order === "ascend" ? 1 : -1,
      };
      const payload = {
        filter,
        skip: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
        ...(tableFilters.sortedInfo && { sort: sorter }),
      };
      const standardsResponse = await listSelectedStandards(payload);
      const parsedData = await Promise.all(
        standardsResponse?.data?.map(async (item) => {
          return await parsedStandardData(item, "reporting");
        })
      );
      setPagination({
        ...pagination,
        total: standardsResponse?.filteredCount,
      });
      setDatasource(parsedData);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [
    business_unit_id,
    location_id,
    report_period,
    refreshListReportDataLake,
    isDeleted,
  ]);

  const onClickArchive = (data, status) => {
    setStatus(status);
    setArchiveData(data);
    setShowConfirmModal(true);
  };
  const onConfirmArchive = async () => {
    setConfirmLoading(true);
    try {
      const formJson = JSON.stringify(archiveData.form_json_code);
      const formUi = JSON.stringify(archiveData.form_ui_code);
      const dataToSubmit = {
        ...archiveData,
        form_json_code: formJson,
        form_ui_code: formUi,
        status: status,
      };
      const response = await updateSelectedStandards(dataToSubmit);
      const customMsg =
        status === "INACTIVE"
          ? "Standard archived successfully!"
          : "Standard restored successfully!";
      if (response) {
        message.success(customMsg);
        setConfirmLoading(false);
        refreshReportDataLake();
      } else {
        message.error("Something went wrong while archiving the standard!");
      }
    } catch (error) {
      console.log("found error", error);
      message.error("Something went wrong while archiving the standard!");
    } finally {
      setShowConfirmModal(false);
    }
  };

  const onClickAssignPermissionsData = (record) => {
    setRowData(record);
    setShowAssignPermissionsModal(true);
  };
  const onCloseAssignPermissionsModal = async () => {
    loadData();
  };
  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        onClick={() =>
          navigate("/reporting/sustainability-standards/create-audit", {
            state: { id: record?._id?.$oid, type: "SUSTAINABILITY_STANDARDS" },
          })
        }
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<RightSquareOutlined />}
        >
          Assign Auditors
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => onClickAssignPermissionsData(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<UsergroupDeleteOutlined />}
        >
          Permissions
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => onClickRecategorizeStandard(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<CopyOutlined />}
        >
          Recategorize
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => onClickArchive(record, "INACTIVE")}
        style={{ background: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Archive
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  const sortOptions = [
    { name: "Name", value: "name" },
    { name: "Code", value: "standard_code" },
    { name: "Industry", value: "industry" },
  ];

  const handleSort = () => {
    loadData();
  };
  const handleChangeSorting = (sorting) => {
    const newSorting = {
      order: sorting.sortAlphabetically ? "ascend" : "descend",
      field: sorting.type,
    };
    setTableFilters((prev) => ({ ...prev, sortedInfo: newSorting }));
    localStorage.setItem("sortedInfo", JSON.stringify(newSorting));
    refreshReportDataLake();
  };

  const columns = [
    {
      filteredValue: tableFilters.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
      title: "Name",
      dataIndex: "name",
      width: "20%",
      filters: filters.name,
      sorter: (a, b) => b?.name?.localeCompare(a?.name),
      sortDirections: ["descend", "ascend"],
      sortOrder:
        tableFilters.sortedInfo?.field === "name" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
      searchPlaceHolder: "Search description",
      filteredValue: tableFilters.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
    },
    {
      filteredValue: tableFilters?.filteredInfo?.frameworks
        ? tableFilters.filteredInfo.frameworks
        : null,
      title: "Standard",
      dataIndex: "frameworks",
      width: "15%",
      render: (record, data) =>
        record?.map((a, i) => (
          <StyledTag className="margin-2" key={data?._id?.$oid + i}>
            {a}
          </StyledTag>
        )),
      sorter: standardSorter,
      sortDirections: ["descend", "ascend"],
      filters: standardFilter.map((fil) =>
        fil !== "" ? { text: fil, value: fil } : { text: "None", value: "" }
      ),
      onFilter: (value, record) => record?.frameworks?.includes(value),
    },
    {
      filteredValue: tableFilters.filteredInfo?.standard_code
        ? tableFilters.filteredInfo.standard_code
        : null,
      title: "Code",
      dataIndex: "standard_code",
      width: "20%",
      sorter: (a, b) => b?.name?.localeCompare(a?.name),
      sortDirections: ["descend", "ascend"],
      filters: filters.standard_code,
      sortOrder:
        tableFilters.sortedInfo?.field === "standard_code" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: "10%",
      render: (_, record) => {
        return isDeleted ? (
          <StyledButton
            onClick={() => onClickArchive(record, "ACTIVE")}
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<RollbackOutlined />}
          >
            Restore
          </StyledButton>
        ) : (
          <Space direction="horizontal">
            <StyledButton type="default" onClick={() => onRowEdit(record)}>
              <EditOutlined />
            </StyledButton>
            <Dropdown overlay={() => menuButtons(record)} trigger={["click"]}>
              <StyledButton type="default">
                <EllipsisOutlined />
              </StyledButton>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onSelectRowChange = (_, data) => {
    // save selected rows in state
    setSelectedListReportDataLake(data);
  };

  const getDataIdsFromSelectedData = (selectedListReportDataLake) => {
    return selectedListReportDataLake.map((item) => {
      return item?._id?.$oid;
    });
  };

  const rowSelection = {
    selectedRowKeys: getDataIdsFromSelectedData(selectedListReportDataLake),
    preserveSelectedRowKeys: true,
    onChange: onSelectRowChange,
  };

  const hasSelected = selectedListReportDataLake.length > 0;

  const handleChangeChecked = (checked, standard) => {
    !checked
      ? setSelectedListReportDataLake((prev) =>
        prev.filter((item) => item?._id?.$oid !== standard?._id?.$oid)
      )
      : setSelectedListReportDataLake((prev) => [...prev, standard]);
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="recategorize">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickRecategorizeStandards()}
          type="custom"
          disabled={!selectedListReportDataLake?.length}
        >
          <CopyOutlined /> <span>Recategorize</span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="clone_standards">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickCloneStandards()}
          type="custom"
          disabled={!selectedListReportDataLake?.length}
        >
          <CopyOutlined /> <span>Clone Standards</span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="assign_auditors">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() =>
            navigate("/reporting/sustainability-standards/create-audit", {
              state: {
                id: selectedListReportIds.toString(),
                type: "SUSTAINABILITY_STANDARDS",
              },
            })
          }
          type="custom"
          disabled={!selectedListReportDataLake?.length}
        >
          <RightSquareOutlined /> <span>Assign Auditors </span>
        </StyledButton>
      </Menu.Item>
    </TableCommonMenu>
  );

  return (
    <>
      <TableHeaderRightTabWrap
        style={{ justifyContent: "end", marginBottom: 16 }}
      >
        {!isDeleted && (
          <Space direction="horizontal" size="middle">
            <span style={{ marginLeft: 8 }}>
              {hasSelected
                ? `Selected ${selectedListReportDataLake.length} items`
                : ""}
            </span>
            <StyledButton
              bgcolor={COLORS.raisinBlack}
              bordercolor={COLORS.white}
              type="custom"
              onClick={clearTableFilters}
            >
              Clear Filters
            </StyledButton>
            <StyledButton
              bgcolor={COLORS.raisinBlack}
              bordercolor={COLORS.white}
              onClick={() => onReportBtnClick()}
              type="custom"
              disabled={!selectedListReportDataLake?.length}
            >
              <Link
                to="/reporting/sustainability-standards/generate-report"
                state={{
                  dataLakeMapReport: selectedListReportDataLake
                    ? selectedListReportDataLake
                    : null,
                  report_period: report_period ? report_period : null,
                  location_id: location_id ? location_id : null,
                  business_unit_id: business_unit_id ? business_unit_id : null,
                }}
              >
                <FileTextOutlined /> <span>Generate Report</span>
              </Link>
            </StyledButton>
            <Dropdown overlay={moreButtons} trigger={["click"]}>
              <StyledButton
                bgcolor={COLORS.raisinBlack}
                bordercolor={COLORS.white}
                type="custom"
                className="margin-5"
                icon={<MoreOutlined />}
              >
                More Actions
              </StyledButton>
            </Dropdown>
          </Space>
        )}
        {!toggleListCardView && (
          <StyledButton
            type="default"
            style={{
              borderRadius: "0",
              marginRight: "10px",
              padding: "5px 18px",
            }}
          >
            <Dropdown
              overlay={
                <SortDropdown
                  sorting={sortData}
                  changeSorting={handleChangeSorting}
                  options={sortOptions}
                  onConfirm={() => {
                    handleSort();
                    hideDropdown();
                  }}
                />
              }
              trigger={["click"]}
              onVisibleChange={toggle}
              visible={isDropdownShown}
              placement="bottomRight"
            >
              <a onClick={(e) => e.preventDefault()}>
                <SwapOutlined rotate={90} />
                <span style={{ paddingLeft: "5px" }}>Sort</span>
              </a>
            </Dropdown>
          </StyledButton>
        )}
        {/* <StyledButton
          type="default"
          bgcolor={toggleListCardView && "#7F5FEE"}
          style={{
            borderRadius: "0",
            marginRight: "0",
            padding: "5px 18px",
          }}
          icon={<UnorderedListOutlined />}
          onClick={() => setToggleListCardView(true)}
        >
          List View
        </StyledButton> */}
        {/* <StyledButton
          type="default"
          bgcolor={!toggleListCardView && "#7F5FEE"}
          style={{
            borderRadius: "0",
            marginLeft: "0",
            padding: "5px 18px",
          }}
          icon={<AppstoreOutlined />}
          onClick={() => setToggleListCardView(false)}
        >
          Card View
        </StyledButton> */}
      </TableHeaderRightTabWrap>
      {toggleListCardView ? (
        <CommonTable
          loading={loading}
          rowKey={(data) => {
            return data?._id?.$oid;
          }}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          rowSelection={rowSelection}
          scroll={{ x: true }}
          onChange={handleChange}
        />
      ) : (
        <FormWrapper>
          <Spin spinning={loading}>
            <CardsWrap style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Row
                gutter={[16, 16]}
                style={{
                  width: "100%",
                }}
              >
                {dataSource?.map((standard) => (
                  <Col key={standard?._id?.$oid} xs={24} sm={24} md={12} lg={6}>
                    <div
                      style={{
                        width: "100%",
                        padding: "24px 12px",
                        backgroundColor: "#2D273F",
                        borderRadius: "4px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Row
                        justify="space-between"
                        align="middle"
                        className="margin-5"
                      >
                        <Col>
                          <Checkbox
                            checked={selectedListReportDataLake.find(
                              (item) => item._id.$oid === standard?._id?.$oid
                            )}
                            onChange={(e) =>
                              handleChangeChecked(e.target.checked, standard)
                            }
                          />
                        </Col>
                        <Col>
                          <StyledButton
                            type="default"
                            padding={0}
                            onClick={() => onRowEdit(standard)}
                            icon={<EditOutlined />}
                          />
                          <Dropdown
                            overlay={() => menuButtons(standard)}
                            trigger={["click"]}
                          >
                            <StyledButton
                              type="default"
                              padding={0}
                              icon={<EllipsisOutlined />}
                            />
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row className="margin-5">{standard.name}</Row>
                      <Row className="margin-5 color-grey font-14">
                        {standard.standard_code}
                      </Row>
                      <Row className="margin-5">
                        <StyledTag bgcolor={"#4F485E"} className="margin-2">
                          {standard.category}
                        </StyledTag>
                      </Row>
                      <Row className="margin-5">
                        {standard.frameworks?.map((a, i) => (
                          <StyledTag
                            className="margin-2"
                            key={standard?._id?.$oid + i}
                          >
                            {a}
                          </StyledTag>
                        ))}
                      </Row>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardsWrap>
            <Row justify="center">
              <Pagination
                current={pagination.current}
                total={pagination.total}
                pageSize={pagination.pageSize}
                showSizeChanger
                onChange={handleChangePage}
              />
            </Row>
          </Spin>
        </FormWrapper>
      )}
      {showConfirmModal && (
        <ConfirmArchiveModal
          description={
            status === "INACTIVE"
              ? "Are you sure you want to archive this data?"
              : "Are you sure you want to restore this data?"
          }
          isVisible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmArchive}
          confirmLoading={confirmLoading}
          status={status}
        />
      )}
      {showAssignPermissionsModal && (
        <AssignPermissionsModal
          visible={showAssignPermissionsModal}
          onClose={() => setShowAssignPermissionsModal(false)}
          rowData={rowData}
          refresh={onCloseAssignPermissionsModal}
        />
      )}
    </>
  );
};

export default DataLakeMapTable;

const standardSorter = (a, b) => {
  a = a.frameworks;
  b = b.frameworks;
  const a_length = a.length;
  const b_length = b.length;
  const diff = a_length - b_length;
  if (diff > 0) {
    return 1;
  }
  if (diff < 0) {
    return -1;
  } else {
    let i = 0;
    while (i < a_length) {
      const comp = a[i].localeCompare(b[i]);

      if (comp === 0) {
        i++;
      } else {
        return comp;
      }
    }
  }

  return 0;
};