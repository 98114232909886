import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { StyledButton } from "../../../shared/commonStyles";
import CreateNewDashboardModal from "./CreateNewDashboardModal";
import { getCompanyInfo } from "../../../services/companyInfo";

export const AnalyticsChartModal = () => {
  const [showCreateDashboardModal, setShowCreateDashboardModal] =
    useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [btnHide, setBtnHide] = useState(false);

  const loadBaseUrl = async () => {
    const baseUrlResponse = await getCompanyInfo();
    if (baseUrlResponse) {
      const companyDetailsData = JSON.parse(baseUrlResponse["data"]);
      setCompanyDetails(companyDetailsData);
    }
  };

  useEffect(() => {
    loadBaseUrl();
  }, []);

  useEffect(() => {
    if (!companyDetails?.chart_base_url) {
      setBtnHide(true);
    } else {
      setBtnHide(false);
    }
  }, [companyDetails]);

  return (
    <>
      <CreateNewDashboardModal
        isVisible={showCreateDashboardModal}
        onClose={() => setShowCreateDashboardModal(false)}
      />

      {btnHide ? null : (
        <StyledButton
          type="primary"
          onClick={() => setShowCreateDashboardModal(true)}
        >
          <PlusCircleOutlined /> <span>Add New Dashboard</span>
        </StyledButton>
      )}
    </>
  );
};
