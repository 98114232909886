import React, { useState } from "react";
import {
  DatePicker,
  Space,
  Tooltip,
  Input,
  Form,
  message,
  Select,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Styled from "styled-components";
import {
  YearMonthDay24HourFormat,
  YearMonthDayFormat,
} from "../../../shared/constants";
import {
  CommonModal,
  StyledButton,
  CommonSelect,
  FormWrapper,
} from "../../../shared/commonStyles";
import moment from "moment";
import { addReportFilters } from "../../../services/filter";

const { Option } = Select;
const { RangePicker } = DatePicker;

const FloatRight = Styled.div`
// float: right; 
// margin: 0px 5px;
`;

export const DateSelect = ({
  setRange,
  value,
  setTimePeriod,
  timeperiods,
  visibleAddBtn = true,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {
  const [visible, setModalVis] = useState(false);
  const [form] = Form.useForm();

  const createTimePeriod = async (val) => {
    const payload = formatFormValues(val);
    const resp = await addReportFilters(payload);

    return resp;
  };  

  const formatFormValues = ({ name, description, period_range }, group) => {
    const payload = {
      name,
      description,
      filter_type: "reporting_period",
      status: "ACTIVE",
      date_start: period_range
        ? period_range[0].format(YearMonthDayFormat)
        : "",
      date_end: period_range ? period_range[1].format(YearMonthDayFormat) : "",
      modify_timestamp: moment().format(YearMonthDay24HourFormat),
      create_timestamp: moment().format(YearMonthDay24HourFormat),
    };
    return payload;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((val) =>
        createTimePeriod(val).then(() => {
          message.success("Successfully created reporting period!");
        })
      )
      .catch((e) => {
        message.error("Something went wrong creating the reporting period.");
      });

    setModalVis(!visible);
  };

  return (
    <FloatRight>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <DateSelectFilter
          value={value}
          options={timeperiods}
          setRange={setRange}
          extraOptions={extraOptions}
          selectWidth={selectWidth}
          changeFilter={changeFilter}
          {...rest}
        />

        {visibleAddBtn && (
          <AddPeriodButton onclick={(_) => setModalVis(!visible)} />
        )}

        <CommonModal
          visible={visible}
          title="Create New Reporting Period"
          onCancel={(_) => setModalVis(!visible)}
          onOk={submitForm}
          centered
          footer={[
            <Button ghost key={3} onClick={(_) => setModalVis(!visible)}>
              Cancel
            </Button>,
            <Button key={4} type="primary" onClick={submitForm}>
              Create
            </Button>,
          ]}
        >
          <FormWrapper>
            <AddPeriodModal form={form} />
          </FormWrapper>
        </CommonModal>
      </Space>
    </FloatRight>
  );
};

const AddPeriodButton = ({ onclick }) => (
  <Tooltip title="Add Reporting Period">
    <StyledButton
      onClick={onclick}
      type="custom"
      shape="circle"
      icon={<PlusOutlined />}
    />
  </Tooltip>
);

const AddPeriodModal = ({ form }) => {
  return (
    <Form form={form}>
      <Form.Item labelCol={{ span: 24 }} name="period_name" label="Period Name">
        <Input placeholder={"FY 21"} />
      </Form.Item>

      <Form.Item labelCol={{ span: 24 }} name="period_desc" label="Description">
        <Input placeholder={"Full fiscal year report"} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        name="period_range"
        label="Date Range:"
      >
        <RangePicker style={{ width: "100%", background: "#625D75" }} />
      </Form.Item>
    </Form>
  );
};

const DateSelectFilter = ({
  value,
  options,
  setRange,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {
  const onChange = (val) => {
    localStorage.setItem("isReportFilterChanged", true);

    if (changeFilter === "no") {
      setRange(val);
    } else {
      localStorage.setItem("selectedReportPeriod", val);
      setRange(val);
    }
  };
  return (
    <CommonSelect
      {...rest}
      showSearch
      style={{ width: selectWidth ? selectWidth : 180 }}
      onChange={onChange}
      value={value}
      filterOption={(input, option) => {
        return (
          option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      placeholder="Select a Time Period"
    >
      {extraOptions}
      {options &&        
        options.map((item, index) => {
          if (item.status === "ACTIVE") {
            return (
              <Option value={item._id["$oid"]} key={index}>
                {item.name}
              </Option>
            );
          }
        })}
    </CommonSelect>
  );
};

export const LocationSelect = ({
  setRange,
  value,
  setLocation,
  locations,
  visibleAddBtn = true,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {  
  const [visible, setModalVis] = useState(false);
  const [form] = Form.useForm();

  const createLocationOption = async (val) => {
    const payload = formatFormValues(val);   
  };

  const formatFormValues = ({ name, description }) => {
    const payload = {
      name,
      description,
      filter_type: "",
      status: "ACTIVE",
      date_start: "",
      date_end: "",
      modify_timestamp: moment().format(YearMonthDay24HourFormat),
      create_timestamp: moment().format(YearMonthDay24HourFormat),
    };
    return payload;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((val) =>
        createLocationOption(val).then(({ data }) => {
          message.success("Successfully created new location!");
          const responseData = data;
          if (responseData) {
            setLocation([...locations, responseData]);
            form.resetFields();
          }
        })
      )
      .catch((e) => {
        message.error("Something went wrong creating the new location.");
        console.log(e);
      });

    setModalVis(!visible);
  };

  return (
    <FloatRight>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <LocationSelectFilter
          value={value}
          options={locations}
          setRange={setRange}
          extraOptions={extraOptions}
          selectWidth={selectWidth}
          changeFilter={changeFilter}
          {...rest}
        />

        {visibleAddBtn && (
          <AddLocationButton onclick={(_) => setModalVis(!visible)} />
        )}

        <CommonModal
          visible={visible}
          title="Create New Location"
          onCancel={(_) => setModalVis(!visible)}
          onOk={submitForm}
          centered
          footer={[
            <Button ghost key={1} onClick={(_) => setModalVis(!visible)}>
              Cancel
            </Button>,
            <Button key={2} type="primary" onClick={submitForm}>
              Create
            </Button>,
          ]}
        >
          <FormWrapper>
            <AddLocationModal form={form} />
          </FormWrapper>
        </CommonModal>
      </Space>
    </FloatRight>
  );
};

const AddLocationButton = ({ onclick }) => (
  <Tooltip title="Add Location">
    <StyledButton
      onClick={onclick}
      type="custom"
      shape="circle"
      icon={<PlusOutlined />}
    />
  </Tooltip>
);

const AddLocationModal = ({ form }) => {
  return (
    <Form form={form}>
      <Form.Item
        labelCol={{ span: 24 }}
        name="location_name"
        label="Location Name"
      >
        <Input placeholder={"San Francisco"} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        name="location_desc"
        label="Description"
      >
        <Input placeholder={"Headquarters location"} />
      </Form.Item>
    </Form>
  );
};

const LocationSelectFilter = ({
  value,
  options,
  setRange,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {
  const onChange = (val) => {
    localStorage.setItem("isReportFilterChanged", true);

    if (changeFilter === "no") {
      setRange(val);
    } else {
      localStorage.setItem("selectedLocation", val);
      setRange(val);
    }
  };

  return (
    <CommonSelect
      {...rest}
      showSearch
      style={{ width: selectWidth ? selectWidth : 180 }}
      onChange={onChange}
      value={value}
      filterOption={(input, option) => {
        return (
          option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );        
      }}
      placeholder="Select a Location"
    >
      {extraOptions}
      {options &&        
        options.map((item, index) => {
          if (item.status === "ACTIVE") {
            return (
              <Option value={item._id["$oid"]} key={index}>
                {item.name}
              </Option>
            );
          }
        })}
    </CommonSelect>
  );
};
