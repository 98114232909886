import React, { useEffect, useState } from "react";
import { Select, Input, Col, Row, Form } from "antd";
import { CheckboxSelector } from "../../../../../components/CheckboxSelector";
import { SelectedItems } from "../../../../../components/SelectedItems";
import { EmissionsLibraryModal } from "../EmissionsLibraryModal";

import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import styles from "./AddManualEmissionsModal.module.scss";
import { getScopeNumber } from "../../../../Carbon/CalculationUtils";
import { CloseOutlined } from "@ant-design/icons";

interface SelectedEmissions extends ManualEmission {
  key: string;
  name: string;
  checked?: boolean;
}
interface Step1AddManualEmissionsProps {
  data?: ManualEmission;
  scopeName: ScopeName;
  selectedEmissions: ManualEmission[];
  removeEmission: (index: number) => void;
  resetSelectedEmissions: (newList: ManualEmission[]) => void;
}

export const Step1AddManualEmissions = observer(
  ({
    data,
    scopeName,
    selectedEmissions,
    removeEmission,
    resetSelectedEmissions,
  }: Step1AddManualEmissionsProps) => {
    const handleAddEmission = (factorsData: any[]) => {
      resetSelectedEmissions(
        factorsData.map((item) => {
          const { checked, ...rest } = item;
          const factorValue = rest.factor ?? rest.factorCalculatedValue;
          delete rest.factorCalculatedValue;
          return {
            ...data,
            factor_data: { ...rest, factor: factorValue } as Factor,
            key: uuidv4(),
            manual: true,
            consumption_amount: 0,
            scope: scopeName,
          } as ManualEmission;
        })
      );
    };

    const handleRemoveEmission = (index: number) => {
      removeEmission(index);
    };

    return (
      <>
        <p className={styles.selectEmissionHeader}>
          {data ? (
            <>Select emission manually</>
          ) : (
            <>
              Select emission you want to add to Scope{" "}
              {getScopeNumber(scopeName)}
            </>
          )}
        </p>

        <div>
          <EmissionsLibraryModal
            isMultipleAllowed={!data}
            data={data ? [data] : selectedEmissions}
            onAddEmission={handleAddEmission}
          />
        </div>

        {selectedEmissions.length > 0 &&
          selectedEmissions.map((v, index) => {
            return (
              <div className={styles.selectedItems}>
                <div className={styles.selectedCheckbox}>
                  {v?.factor_data?.name}
                  <CloseOutlined
                    className={styles.removeIcon}
                    onClick={() => {
                      handleRemoveEmission(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </>
    );
  }
);
