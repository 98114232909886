import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import {
  CommonRadio,
  StyledButton,
  StyledTabs,
} from "./../../../shared/commonStyles";
import {
  DateSelect,
  LocationSelect,
} from "../../../pages/reportingPage/Components";
import {
  EllipsisOutlined,
  LinkOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  YearMonthDay24HourFormat,
  fileUploadSize,
  fileUploadTypes,
} from "../../../shared/constants";
import {
  createStandardComments,
  getDataLakeFiles,
  parsedStandardData,
  updateSelectedStandards,
  updateStandardComments,
  uploadFile,
} from "../../../services/standards";
import {
  downloadDataLakeFileHandler,
  handleLongString,
} from "../../../shared/commonFunctions";

import { Theme as AntDTheme } from "@rjsf/antd";
import { Auth } from "aws-amplify";
import { BusinessUnitSelect } from "../../../pages/reportingPage/Components/BusinessUnitSelect/BusinessUnitSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { COLORS } from "../../../shared/colors";
import { CommentsList } from "../../../components/ReportingData/CommentsList/CommentsList";
import ConfirmModal from "./ConfirmModal";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { HistoryDataSectionNew } from "../../../components/ReportingData/HistoryDataSection/HistoryDataSectionNew";
import { SignOffSection } from "../../../components/ReportingData/SignOffSection/SignOffSection";
import { getAuthData } from "../../../pages/dataLakeReportPage/util/index";
import { getDataSheets } from "../../../services/dataSheet";
import { getKpiMetric } from "../../../services/kpi";
import { getReportFilters } from "../../../services/filter";
import { listSelectedStandards } from "../../../services/standards";
import moment from "moment";
import { useBusinessUnitLocationPeriodFilter } from "../../../hooks/useBusinessUnitLocationPeriodFilter";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { withTheme } from "@rjsf/core";
import { SustainabilityStandardHistoryComponent } from "../../Reporting/SustainabilityStandards/Component/SustainabilityStandardHistoryComponent";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const Form = withTheme(AntDTheme);

const DataLakeMapDataForm = ({
  rowData,
  refreshOnSubmit,
  pageType,
  auditData = null,
}) => {
  const uiSchema = useMemo(() => rowData?.form_ui_code, [rowData]);

  const [confirmModalShowing, setConfirmModalShowing] = useState(false);
  // const [formData, setFormData] = useState({});
  // TODO: WIP -> use response from the api call
  const [ckFormData, setCkFormData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [lastDataLoading, setLastDataLoading] = useState(false);
  const [autofillKpiDataLoading, setAutofillKpiDataLoadingLoading] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Set default states for the two statuses, status and publish_status (bool)
  const [status, setStatus] = useState("open");
  const [publishStatus, setPublishStatus] = useState(false);
  const [commentData, setCommentData] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const [ckNotes, setCkNotes] = useState(rowData?.notes ? rowData?.notes : "");
  const [selectedTab, setSelectedTab] = useState(
    pageType === "standard" ? "information-actions" : "sign-off"
  );
  const [sheets, setSheets] = useState([]);
  const [kpiList, setKpiList] = useState([]);
  const [numericFields, setNumericFields] = useState([]);
  const [lastSelectedStandard, setLastSelectedStandard] = useState();
  const [versionInfo, setVersionInfo] = useState("");
  const [latestVersion, setLatestVersion] = useState("");
  const [currentVersion, setCurrentVersion] = useState(
    rowData?.history?.length
  );
  const [responseValue, setResponseValue] = useState();

  const navigate = useNavigate();
  const {
    locations,
    businessUnits,
    reportPeriods,
    locationId,
    setLocationId,
    businessUnitId,
    setBusinessUnitId,
    reportPeriodId,
    setReportPeriodId,
  } = useBusinessUnitLocationPeriodFilter();

  const onTabChange = (selectedTabKey) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };

  useEffect(() => {
    if (rowData?._id?.$oid) {
      setCurrentVersion(rowData?.history?.length);
      setDataLoading(true);
      setResponseValue(rowData?.response ? rowData?.response : "");

      setStatus(rowData.user_status);

      setCkNotes(rowData?.notes || "");
      setPublishStatus(rowData?.publish_status || false);
      setDataLoading(false);
    }
  }, [rowData?._id?.$oid]);

  const dropdownMenuItems = (
    <Menu>
      <Menu.Item key="autofill" onClick={() => onTabChange("autofill")}>
        Autofill from KPIs
      </Menu.Item>
      <Menu.Item key="lastYearData" onClick={() => onTabChange("lastYearData")}>
        Last year data
      </Menu.Item>
    </Menu>
  );

  const loadDataLakeFiles = async (standardId) => {
    setLoaderUploadedFilesList(true);
    // load uploaded files from s3
    try {
      const filesList = await getDataLakeFiles(standardId);

      const list = filesList
        ?.replace(/['"]+/g, "")
        .replace(/^\[(.+)\]$/, "$1")
        .split(",");
      setUploadedFilesList(list);
    } catch (error) {
      console.log("Error while fetching data lake files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  useEffect(() => {
    if (rowData?._id?.$oid) {
      findNumericFields();
      setCurrentVersion(rowData?.history?.length);
      loadDataKpis();
      setLocationId(rowData.location_id);
      setBusinessUnitId(rowData.business_unit);
      setReportPeriodId(rowData.report_period);
    }
  }, []);

  useEffect(() => {
    if (rowData && rowData?._id.$oid) {
      loadDataLakeFiles(rowData?._id.$oid);
    }
  }, [rowData, refreshUploadedFiles]);

  const onFormSubmit = ({ errors }) => {
    if (errors.length === 0) {
      if (rowData?._id.$oid) {
        setConfirmModalShowing(true);
      } else {
        onClickSubmit();
      }
    }
  };

  const formDataSubmit = async (editReason) => {
    try {
      setSubmitting(true);
      // const formJson = JSON.stringify(rowData?.form_json_code);
      // const formUi = JSON.stringify(rowData?.form_ui_code);
      const formJson = "";
      const formUi = "";
      const dataToSubmit = {
        ...rowData,
        form_json_code: formJson,
        form_ui_code: formUi,
        user_status: status,
        // TODO: fix this with the correct property according to the latest schema change
        // form_data:
        //   Object.keys(rowData?.form_json_code).length === 0
        //     ? ckFormData
        //     : formData,
        modify_reason: editReason,
        publish_status: publishStatus,
        response: responseValue,
      };

      const response = await updateSelectedStandards(dataToSubmit);

      if (response && files.length) {
        //files upload on save new record not on update time.
        await uploadFileForNewRecord(files, rowData?._id.$oid);
      }
      message.success("Successfully saved data form.");
      setConfirmModalShowing(false);
      refreshOnSubmit();
    } catch (error) {
      message.error("Error while saving data. Please contact support.");
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onClickSubmit = async () => {
    formDataSubmit("");
  };

  const onConfirmSubmit = async ({ editReason }) => {
    formDataSubmit(editReason);
  };

  const onChangeCommentHandler = (e) => {
    setCommentData(e.target.value);
  };

  const onPressEnterCommentHandler = async () => {
    const comment = commentData ? commentData?.trim() : null;
    if (comment && rowData?._id.$oid) {
      await onCreateLakeComments(rowData?._id, comment);
    }
  };

  const onCreateLakeComments = async (data_id, comment_data) => {
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      const payload = {
        _id: data_id,
        comment: {
          comment_id: uuidv4(),
          username,
          comment_data,
          status: "ACTIVE",
          create_timestamp: moment().format(YearMonthDay24HourFormat),
          modify_timestamp: moment().format(YearMonthDay24HourFormat),
        },
      };
      const createdLakeComment = await createStandardComments(payload);
      if (createdLakeComment) {
        setCommentData("");
        refreshOnSubmit();
      }
    } catch (error) {
      console.log("error while saving comment", error);
      message.error("error while saving comment");
    }
  };

  const onUpdateLakeComments = async (data_id, comment_data, commentRow) => {
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      delete commentRow?.first_last_name;
      const payload = {
        _id: data_id,
        previous_comment: commentRow,
        comment: {
          comment_id: uuidv4(),
          username,
          comment_data,
          status: "ACTIVE",
          create_timestamp: commentRow.create_timestamp,
          modify_timestamp: moment().format(YearMonthDay24HourFormat),
        },
      };

      const updatedData = await updateStandardComments(payload);
      if (updatedData) {
        message.success("Comment updated successfully!");
        refreshOnSubmit();
      }
    } catch (error) {
      console.log("error while updating data comment", error);
      message.error("error while updating data comment");
    }
  };

  const editLakeComment = (commentData, data) => {
    if (commentData?.comment_data !== data) {
      commentData?.comment_id &&
        onUpdateLakeComments(rowData?._id, data, commentData);
    }
  };

  const deleteLakeComment = async (commentRow) => {
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      delete commentRow?.first_last_name;
      const payload = {
        _id: rowData?._id,
        previous_comment: commentRow,
        comment: {
          comment_id: uuidv4(),
          username,
          comment_data: commentRow.comment_data,
          status: "INACTIVE",
          create_timestamp: commentRow.create_timestamp,
          modify_timestamp: moment().format(YearMonthDay24HourFormat),
        },
      };

      const updatedData = await updateStandardComments(payload);
      if (updatedData) {
        message.success("Comment deleted successfully!");
        refreshOnSubmit();
      }
    } catch (error) {
      console.log("error while updating data comment", error);
      message.error("error while updating data comment");
    }
  };

  const onChangeHistoryVersion = (historyVersionData) => {
    historyVersionData?.response &&
      setResponseValue(historyVersionData?.response);
    setCkNotes(historyVersionData?.response ? historyVersionData.response : "");
    setVersionInfo("");
    setCurrentVersion(historyVersionData);
  };

  const uploadFileForNewRecord = async (files, data_id) => {
    if (files && files.length) {
      try {
        for (const file of files) {
          await uploadStandardFile(file, data_id);
        }
        setRefreshUploadedFiles((prev) => prev + 1);
      } catch (error) {
        console.log("Something went wrong while uploading files!", error);
      }
    }
  };

  const uploadStandardFile = async (file, dataId) => {
    //fileUploadState is for create or update
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      setLoaderUploadedFilesList(true);

      const signedURL = await uploadFile(dataId, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
            setRefreshUploadedFiles((prev) => prev + 1);
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const beforeUpload = async (file) => {
    const filename = file?.name;
    const fileSize = file?.size;
    const ext = filename && filename?.split(".")?.pop();

    if (!fileUploadTypes.includes(ext)) {
      message.error(`${file?.name} is not a valid file type`);
      return;
    }

    if (fileSize > fileUploadSize) {
      message.error(`File upload size is larger than 25MB`);
      return;
    }

    if (rowData && rowData?._id.$oid) {
      // when record edit.
      uploadStandardFile(file, rowData?._id.$oid);
    } else {
      // when new record added.
      setFiles([...files, file]);
    }
    return false;
  };

  const downloadFileHandler = async (key) => {
    try {
      const dataId = rowData?._id.$oid;
      if (dataId) {
        setLoaderUploadedFilesList(true);
        await downloadDataLakeFileHandler(key, dataId);
      }
    } catch (error) {
      console.log("Error while upload file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const onRemoveFileFromListHandler = (file) => {
    const filesList = files.filter((v) => v.url !== file.url);
    setFiles(filesList);
  };

  const findNumericFields = () => {
    // if (Object.keys(rowData?.form_json_code).length) {
    //   const jsonDataArray = Object.entries(
    //     rowData.form_json_code.properties
    //   ).map(([key, value]) => {
    //     return { key: key, value: value };
    //   });
    //   const numericFieldsData = jsonDataArray
    //     .filter((item) => item.key !== "items" && item.value.type === "number")
    //     .map((item) => {
    //       return {
    //         ...item,
    //         checked: false,
    //         field: item.key,
    //         dataSheetId: "",
    //         kpis: [],
    //         selectedKpi: {},
    //       };
    //     });
    //   setNumericFields(numericFieldsData);
    // }
  };

  const loadDataKpis = async () => {
    setAutofillKpiDataLoadingLoading(true);
    const kpiData = await getKpiMetric();
    const kpiList = kpiData.map((v) => {
      return {
        name: v.name,
        key: v._id.$oid,
        sheetId: v.sheetId,
        analyticResult: v.analyticResult,
      };
    });

    const sheetsData = await getDataSheets({ filter: { archive: false } });
    const sheetsList = sheetsData.map((v) => {
      return { name: v.sheet_name, key: v._id.$oid };
    });
    const sheetsForKpi = sheetsList.filter((item) =>
      kpiList.find((v) => v.sheetId === item.key)
    );
    setSheets(sheetsForKpi);
    setKpiList(kpiList);
    setAutofillKpiDataLoadingLoading(false);
  };

  const handleChangeDataSheet = (sheetId, fieldName) => {
    const kpiForSheet = kpiList.filter((v) => v.sheetId === sheetId);
    const addKpis = numericFields.map((v) => {
      if (v.key === fieldName) {
        return { ...v, dataSheetId: sheetId, kpis: kpiForSheet };
      } else {
        return v;
      }
    });
    setNumericFields(addKpis);
  };

  const handleChangeKpi = (kpiKey, fieldName) => {
    const selectedKpi = kpiList.find((v) => v.key === kpiKey);
    const addSelectedKpi = numericFields.map((v) => {
      if (v.key === fieldName) {
        return { ...v, selectedKpi: selectedKpi };
      } else {
        return v;
      }
    });
    setNumericFields(addSelectedKpi);
  };

  const handleChangeCheckboxKpi = (checked, item) => {
    const checkNumericFields = numericFields.map((v) => {
      if (v.key === item.key) {
        return { ...v, checked };
      } else {
        return v;
      }
    });
    setNumericFields(checkNumericFields);
  };

  const handleAutofillKpi = () => {
    const checkedNumericFields = numericFields.filter((item) => item.checked);

    setResponseValue((prevValue) => {
      const autofillData = { ...prevValue } || {};
      checkedNumericFields.forEach((element) => {
        if (element.selectedKpi.analyticResult) {
          autofillData[element.key] =
            Object.values(element.selectedKpi.analyticResult)[0] || 0;
        }
      });
      return autofillData;
    });
  };

  const findLastYearData = async () => {
    setLastDataLoading(true);
    const { group_id } = await getAuthData();
    const payload = {
      filter: {
        group_id,
        business_unit: businessUnitId,
        location_id: locationId,
        report_period: reportPeriodId,
      },
    };
    const selectedStandards = await listSelectedStandards(payload);

    const selectedStandardsForStandards = selectedStandards.data.filter(
      (item) =>
        item.standard_id === rowData.standard_id &&
        item._id.$oid !== rowData._id.$oid
    );
    const parsedData = await Promise.all(
      selectedStandardsForStandards?.map(async (item) => {
        return await parsedStandardData(item);
      })
    );
    const payloadFilter = { filter: { filter_type: "reporting_period" } };
    const response = await getReportFilters(payloadFilter);
    const lastStandards = parsedData.map((item) => {
      const displayedData = item.form_json_code
        ? Object.entries(item.form_json_code.properties).map(([key, value]) => {
            const data = item.form_data ? item.form_data[key] : "NA";

            return {
              key,
              value,
              data: data,
              checked: false,
              type: item.form_json_code.properties[key].type,
            };
          })
        : {};

      const displayedPeriod = response.find(
        (v) => v._id.$oid === item.report_period
      )?.name;
      return {
        ...item,
        displayedData,
        displayedPeriod,
      };
    });
    setLastSelectedStandard(lastStandards[0]);
    setLastDataLoading(false);
  };

  const handleChangeCheckboxLastData = (checked, v) => {
    setLastSelectedStandard((prevValue) => {
      const newDisplayedData = lastSelectedStandard.displayedData.map(
        (value) => {
          if (value.key === v.key) {
            return { ...value, checked };
          } else return value;
        }
      );
      return { ...prevValue, displayedData: newDisplayedData };
    });
  };

  const handleAutofillLastData = () => {
    const checkedFields = lastSelectedStandard.displayedData.filter(
      (item) => item.checked
    );

    setResponseValue((prevValue) => {
      const autofillData = { ...prevValue } || {};
      checkedFields.forEach((element) => {
        if (element.data) {
          autofillData[element.key] = element.data;
        }
      });
      return autofillData;
    });
  };

  const checkVariable = (variable) => {
    if (typeof variable === "string") {
      return variable;
    } else {
      return "";
    }
  };

  return (
    <>
      {rowData?.status == "INACTIVE" ? (
        <Alert
          message="Sorry, you cannot access this archive standard!"
          type="info"
          showIcon
        />
      ) : (
        <Row>
          <Col xs={24} sm={24} md={14} lg={14} className="preview-left-part">
            <Col
              lg={24}
              md={24}
              sm={12}
              xs={12}
              style={{ padding: "40px 40px" }}
            >
              <Spin spinning={dataLoading || submitting}>
                {versionInfo ? (
                  <div
                    style={{
                      padding: "10px",
                      background: "#302A43",
                      borderRadius: "2px",
                      borderLeft: "6px solid #A68DFB",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Row>
                      <Col span={20}>
                        <Title
                          level={5}
                          style={{ marginLeft: "5px", marginTop: "7px" }}
                        >
                          You are currently viewing Version {versionInfo}
                        </Title>
                      </Col>
                      <Col
                        span={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <StyledButton
                          bgcolor={COLORS.martinique}
                          bordercolor={COLORS.white}
                          type="custom"
                          size="small"
                          onClick={() => onChangeHistoryVersion(latestVersion)}
                          style={{ padding: "6px 16px", margin: "0 5px" }}
                        >
                          Return back
                        </StyledButton>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  " "
                )}{" "}
                {rowData?.name && (
                  <>
                    <Title
                      className="color-white"
                      style={{ textAlign: "center" }}
                      level={3}
                    >
                      {rowData?.name}
                    </Title>
                  </>
                )}
                <Title level={4}>Data</Title>
                <Divider
                  style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                />
                <Row gutter={[16, 16]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    style={{ marginTop: 15 }}
                  >
                    <div className="ckSmallHeight1">
                      <CKEditor
                        editor={DecoupledDocumentEditor}
                        disabled={pageType === "audit"}
                        data={checkVariable(responseValue)}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          if (editor?.ui) {
                            editor.ui.view.editable.element.parentElement.insertBefore(
                              editor.ui.view.toolbar.element,
                              editor.ui.view.editable.element
                            );
                          }
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setResponseValue(data);
                        }}
                      />
                    </div>
                  </Col>

                  <Col span={24} style={{ marginTop: "15px" }}>
                    <Spin spinning={loaderUploadedFilesList}>
                      <Title className="color-white" level={4}>
                        Attach related files
                      </Title>
                      <p className="color-grey">
                        Attach files that are related to this field
                      </p>
                      <Divider
                        style={{
                          backgroundColor: "#3A3C5A",
                          marginTop: "15px",
                        }}
                      />

                      <Upload
                        customRequest={({ file, onSuccess }) => {
                          onSuccess("ok");
                        }}
                        beforeUpload={beforeUpload}
                        fileList={files}
                        className="upload-list-inline"
                        multiple={false}
                        onRemove={onRemoveFileFromListHandler}
                      >
                        <StyledButton
                          type="custom"
                          icon={<UploadOutlined />}
                          disabled={pageType === "audit"}
                        >
                          Uploads
                        </StyledButton>
                      </Upload>

                      <div className="data-lake-files-wrap">
                        {uploadedFilesList &&
                          uploadedFilesList?.map((item, index) => {
                            const fileName = item && item?.split("/").pop();
                            return (
                              <Row key={index}>
                                <Col span={24}>
                                  <StyledButton
                                    type="custom"
                                    hoverbgcolor="transparent"
                                    bgcolor="transparent"
                                    bordercolor="transparent"
                                    color="#A68DFB"
                                    className="margin-2"
                                    icon={<LinkOutlined />}
                                    onClick={() => downloadFileHandler(item)}
                                    style={{ padding: "0" }}
                                  >
                                    {fileName}
                                  </StyledButton>
                                </Col>
                              </Row>
                            );
                          })}
                      </div>
                    </Spin>
                  </Col>
                </Row>
                {confirmModalShowing && (
                  <ConfirmModal
                    visible={confirmModalShowing}
                    onSubmit={(e) => onConfirmSubmit(e)}
                    onCancel={() => setConfirmModalShowing(false)}
                    submitting={submitting}
                  />
                )}
              </Spin>
            </Col>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            className="preview-right-part"
            style={{ padding: "35px 40px", position: "relative" }}
          >
            <Row style={{ marginBottom: "61px" }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row justify="space-between">
                  <StyledTabs activeKey={selectedTab} onChange={onTabChange}>
                    {pageType === "audit" && (
                      <TabPane tab="Sign Off" key="sign-off" />
                    )}
                    <TabPane
                      tab="Information & Actions"
                      key="information-actions"
                    />
                    <TabPane tab="History" key="history" />
                    <TabPane tab="Comments" key="comments" />
                  </StyledTabs>
                  <Dropdown overlay={dropdownMenuItems} placement="bottom">
                    <div>
                      <EllipsisOutlined
                        style={{ fontSize: "25px", margin: "12px 0 0 10px" }}
                      />
                    </div>
                  </Dropdown>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                {selectedTab === "sign-off" && pageType === "audit" && (
                  <SignOffSection
                    rowData={rowData}
                    auditRecord={auditData}
                    refreshOnSubmit={refreshOnSubmit}
                  />
                )}
                {selectedTab === "autofill" &&
                  (!numericFields.length ? (
                    <div>
                      {" "}
                      <Title level={4}>No numeric fields to autofill</Title>
                    </div>
                  ) : (
                    <Spin spinning={autofillKpiDataLoading}>
                      {" "}
                      <Title level={4}>
                        Autofill standards data from KPIs
                      </Title>{" "}
                      {numericFields.map((item) => {
                        return (
                          <Row key={item.key}>
                            <Checkbox
                              disabled={pageType === "audit"}
                              value={item.key}
                              checked={item.checked}
                              onChange={(e) =>
                                handleChangeCheckboxKpi(e.target.checked, item)
                              }
                            >
                              <Text>{item?.value.title}</Text>
                            </Checkbox>
                            {item.checked && (
                              <>
                                <Select
                                  placeholder="Please select a datasheet!"
                                  showArrow
                                  style={{ width: "100%", margin: "10px" }}
                                  onChange={(value) =>
                                    handleChangeDataSheet(value, item.key)
                                  }
                                >
                                  {sheets.map((v) => (
                                    <Select.Option key={v.key} value={v.key}>
                                      {v.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                                <Select
                                  placeholder="Please select a KPI!"
                                  showArrow
                                  style={{ width: "100%", margin: "10px" }}
                                  onChange={(value) =>
                                    handleChangeKpi(value, item.key)
                                  }
                                >
                                  {item.kpis.map((v) => (
                                    <Select.Option key={v.key} value={v.key}>
                                      {v.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </>
                            )}
                          </Row>
                        );
                      })}
                      <StyledButton
                        type="custom"
                        disabled={
                          !numericFields.find(
                            (v) => v.selectedKpi.analyticResult
                          ) || pageType === "audit"
                        }
                        style={{ marginTop: "10px", marginLeft: "0" }}
                        onClick={handleAutofillKpi}
                      >
                        Autofill from KPIs
                      </StyledButton>
                    </Spin>
                  ))}
                {selectedTab === "information-actions" && (
                  <div>
                    {/*Information Section */}
                    <Title
                      level={3}
                      style={{ fontWeight: "500px", lineHeight: "45px" }}
                    >
                      Information
                    </Title>
                    <Space direction="vertical">
                      {rowData?.description && (
                        <p className="color-grey"> {rowData.description}</p>
                      )}
                      <div>
                        {(rowData?.standardLink || []).map((val, index) => (
                          <Tooltip key={index} title={val.name}>
                            <Row>
                              <Col span={24}>
                                <a
                                  className="padding-0"
                                  href={val.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#7F5FEE" }}
                                >
                                  Link to
                                  {` " ${handleLongString(val.name, 10)}"`}
                                  {val.page_num
                                    ? `,  Page No : ${val.page_num}`
                                    : " "}
                                </a>
                              </Col>
                            </Row>
                          </Tooltip>
                        ))}
                      </div>
                    </Space>
                    <Title
                      level={3}
                      style={{
                        marginTop: 25,
                        fontWeight: "500px",
                        lineHeight: "45px",
                      }}
                    >
                      Actions
                    </Title>
                    <Title level={5}>Status</Title>
                    <Space direction="vertical" style={{ paddingBottom: 10 }}>
                      <p className="color-grey">
                        If data collection is completed, set the status to{" "}
                        {`"Closed"`} in order to prepare the data for
                        distribution.
                      </p>

                      <Divider
                        style={{ backgroundColor: "#3A3C5A", marginTop: "0" }}
                      />

                      <Radio.Group
                        style={{ background: "transparent" }}
                        disabled={pageType === "audit"}
                        onChange={(e) => setStatus(e.target.value)}
                        value={status ?? "open"}
                        buttonStyle="solid"
                        className="radioGroup"
                      >
                        <CommonRadio bgColor="#7F5FEE" value="open">
                          Open
                        </CommonRadio>
                        <CommonRadio bgColor="#7F5FEE" value="pending">
                          Pending
                        </CommonRadio>
                        <CommonRadio bgColor="#7F5FEE" value="escalated">
                          Escalated
                        </CommonRadio>
                        <CommonRadio bgColor="#7F5FEE" value="closed">
                          Closed
                        </CommonRadio>
                      </Radio.Group>

                      <Title level={5} style={{ marginTop: "20px" }}>
                        Publishing
                      </Title>
                      <p className="color-grey">
                        If you are ready to generate report with the data and
                        the data has passed your audit, please toggle the switch
                        to {`"Published"`} to include data in the report
                        generation.
                      </p>

                      <Divider
                        style={{ backgroundColor: "#3A3C5A", marginTop: "0" }}
                      />
                      <Switch
                        disabled={pageType === "audit"}
                        checkedChildren="Published"
                        unCheckedChildren="Unpublished"
                        onChange={(checked) => setPublishStatus(checked)}
                        checked={!!publishStatus}
                      />
                    </Space>
                  </div>
                )}
                {selectedTab === "history" && (
                  <div>
                    {/* History Section  */}
                    {rowData && (
                      <SustainabilityStandardHistoryComponent
                        rowData={rowData}
                        onChangeHistoryHandler={onChangeHistoryVersion}
                        setVersionInfo={setVersionInfo}
                        setLatestVersion={setLatestVersion}
                        setCurrentVersion={setCurrentVersion}
                        currentVersion={currentVersion}
                      />
                    )}
                    {/* History Section End*/}
                  </div>
                )}
                {selectedTab === "lastYearData" && (
                  <>
                    <Row justify="space-between">
                      <Col span={7}>
                        <BusinessUnitSelect
                          key={1}
                          selectedBusinessUnit={businessUnitId}
                          businessUnits={businessUnits}
                          setSelectedBusinessUnit={setBusinessUnitId}
                          visibleAddBtn={false}
                          selectWidth={"100%"}
                          className="standardsLastDataSelector"
                        />
                      </Col>
                      <Col span={7}>
                        <LocationSelect
                          key={2}
                          setRange={setLocationId}
                          value={locationId}
                          visibleAddBtn={false}
                          locations={locations}
                          selectWidth={"100%"}
                          className="standardsLastDataSelector"
                        />
                      </Col>
                      <Col span={7}>
                        <DateSelect
                          key={3}
                          setRange={setReportPeriodId}
                          value={reportPeriodId}
                          timeperiods={reportPeriods}
                          visibleAddBtn={false}
                          selectWidth={"100%"}
                          className="standardsLastDataSelector"
                        />
                      </Col>
                    </Row>
                    <Spin spinning={lastDataLoading}>
                      {!lastSelectedStandard ? (
                        <div style={{ marginTop: "20px" }}>
                          No data found for the previous period, location, or
                          business unit
                        </div>
                      ) : (
                        <Space
                          direction="vertical"
                          style={{ marginTop: "20px" }}
                        >
                          <div key={lastSelectedStandard._id.$oid}>
                            <Title
                              level={3}
                              style={{
                                fontWeight: "500px",
                                lineHeight: "45px",
                              }}
                            >
                              {lastSelectedStandard.theme} -{" "}
                              {lastSelectedStandard.displayedPeriod}
                            </Title>
                            <div
                              style={{
                                margin: "10px 0px",
                              }}
                            >
                              <div>
                                {" "}
                                Category: {lastSelectedStandard.category}
                              </div>
                              <div> Metric: {lastSelectedStandard.metric}</div>
                              <div>
                                {" "}
                                Standard:{" "}
                                {lastSelectedStandard.standard_name[0]}
                              </div>
                              <div>
                                {" "}
                                Code: {lastSelectedStandard.specific_standard}
                              </div>
                            </div>
                            {lastSelectedStandard.displayedData.map((v) => {
                              return v.type !== "array" ? (
                                <Col>
                                  <Checkbox
                                    disabled={
                                      v.data === "NA" || pageType === "audit"
                                    }
                                    key={v.key}
                                    value={v.key}
                                    checked={v.checked}
                                    onChange={(e) =>
                                      handleChangeCheckboxLastData(
                                        e.target.checked,
                                        v
                                      )
                                    }
                                  >
                                    <Title level={4}>{v.value?.title}</Title>
                                  </Checkbox>
                                  <div style={{ marginLeft: 24 }}>
                                    <Title level={4}>{v?.data}</Title>
                                    <p>{v.value?.description}</p>
                                  </div>
                                </Col>
                              ) : (
                                <></>
                              );
                            })}{" "}
                            <StyledButton
                              type="custom"
                              style={{
                                marginTop: "10px",
                                marginLeft: "0",
                              }}
                              onClick={handleAutofillLastData}
                              disabled={
                                !lastSelectedStandard?.displayedData.find(
                                  (v) => v.checked === true
                                ) || pageType === "audit"
                              }
                            >
                              Autofill last Data
                            </StyledButton>
                          </div>
                        </Space>
                      )}{" "}
                    </Spin>
                  </>
                )}
                {selectedTab === "comments" && (
                  <div>
                    {/* comment section */}
                    <Title level={4}>Comments</Title>
                    <CommentsList
                      rowData={rowData}
                      editLakeComment={editLakeComment}
                      deleteLakeComment={deleteLakeComment}
                    />
                    <Input.TextArea
                      value={commentData}
                      rows={4}
                      onChange={onChangeCommentHandler}
                      name="comments_data"
                      placeholder="Add a comment"
                    />
                    <StyledButton
                      type="custom"
                      style={{ marginTop: "10px", marginLeft: "0" }}
                      onClick={onPressEnterCommentHandler}
                    >
                      Add Comment
                    </StyledButton>
                    {/* comment section end */}
                  </div>
                )}
              </Col>
            </Row>

            <Row style={{ position: "absolute", bottom: "35px", left: "40px" }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ textAlign: "left", marginTop: "20px" }}>
                  {pageType === "standard" && (
                    <StyledButton
                      form="updateFormId"
                      type="custom"
                      loading={submitting}
                      htmlType="submit"
                    >
                      Update
                    </StyledButton>
                  )}
                  <StyledButton
                    type="custom"
                    onClick={() =>
                      navigate(
                        pageType === "standard"
                          ? "/reporting/sustainability-standards"
                          : "/audit/manage"
                      )
                    }
                    hovercolor="#A68DFB"
                    hoverbgcolor="transparent"
                    bgcolor="transparent"
                    bordercolor="transparent"
                    color="#fff"
                  >
                    Cancel
                  </StyledButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DataLakeMapDataForm;

// TODO: replace with below code later , follow this and replace with the editor

{
  /* <Form
className="json-form-container"
formData={formData}
uiSchema={uiSchema}
schema={schema}
onChange={({ formData }) => setFormData(formData)}
onSubmit={rowData?._id.$oid ? onFormSubmit : onClickSubmit}
id="updateFormId"
disabled={pageType === "audit"}
>
<Row gutter={[16, 16]}>
  {Object.keys(rowData?.form_json_code).length === 0 && (
    <Col xs={24} sm={24} md={24} lg={24}>
      <div className="ckSmallHeight1">
        <CKEditor
          editor={DecoupledDocumentEditor}
          data={ckFormData}
          disabled={pageType === "audit"}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            if (editor?.ui) {
              editor.ui.view.editable.element.parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.view.editable.element
              );
            }
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setCkFormData(data);
          }}
        />
      </div>
    </Col>
  )}
  <Col
    xs={24}
    sm={24}
    md={24}
    lg={24}
    style={{ marginTop: 15 }}
  >

    <Title level={4}>{`Footnote`}</Title>
    <Divider
      style={{
        backgroundColor: "#3A3C5A",
        marginTop: "15px",
      }}
    />
    <div className="ckSmallHeight1">
      <CKEditor
        editor={DecoupledDocumentEditor}
        disabled={pageType === "audit"}
        data={ckNotes}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          if (editor?.ui) {
            editor.ui.view.editable.element.parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.view.editable.element
            );
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setCkNotes(data);
        }}
      />
    </div>
  </Col>

  <Col span={24} style={{ marginTop: "15px" }}>
    <Spin spinning={loaderUploadedFilesList}>
      <Title className="color-white" level={4}>
        Attach related files
      </Title>
      <p className="color-grey">
        Attach files that are related to this field
      </p>
      <Divider
        style={{
          backgroundColor: "#3A3C5A",
          marginTop: "15px",
        }}
      />

      <Upload
        customRequest={({ file, onSuccess }) => {
          onSuccess("ok");
        }}
        beforeUpload={beforeUpload}
        fileList={files}
        className="upload-list-inline"
        multiple={false}
        onRemove={onRemoveFileFromListHandler}
      >
        <StyledButton
          type="custom"
          icon={<UploadOutlined />}
          disabled={pageType === "audit"}
        >
          Uploads
        </StyledButton>
      </Upload>

      <div className="data-lake-files-wrap">
        {uploadedFilesList &&
          uploadedFilesList?.map((item, index) => {
            const fileName = item && item?.split("/").pop();
            return (
              <Row key={index}>
                <Col span={24}>
                  <StyledButton
                    type="custom"
                    hoverbgcolor="transparent"
                    bgcolor="transparent"
                    bordercolor="transparent"
                    color="#A68DFB"
                    className="margin-2"
                    icon={<LinkOutlined />}
                    onClick={() => downloadFileHandler(item)}
                    style={{ padding: "0" }}
                  >
                    {fileName}
                  </StyledButton>
                </Col>
              </Row>
            );
          })}
      </div>
    </Spin>
  </Col>
</Row>
</Form> */
}
