import {
  CommonTable,
  StyledButton,
  StyledTag,
} from "../../../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import { Space, message } from "antd";
import {
  addSelectedStandards,
  getReportingStandards,
  parsedStandardData,
} from "../../../../services/standards";

import ESGTemplateFormModal from "./ESGTemplateFormModal";
import { EyeOutlined } from "@ant-design/icons";
import SearchableColumn from "../../../../components/SearchableColumn";
import styled from "styled-components";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchInput = styled(Input)`
  height: 40px;
  background-color: #625d75;
  border-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
    border-right-width: 2px;
    z-index: 1;
  }

  .ant-input {
    background-color: transparent;
    margin-left: 5px;
    font-size: 16px;
    color: #b1afbc;
  }

  &.customInput {
    background-color: #625d75;
    font-size: 16px;
    border-color: transparent;

    &::placeholder {
      background-color: #625d75;
      font-size: 16px;
      border-color: transparent;
    }
  }
`;

const ESGTemplateDataTable = ({
  report_period,
  location_id,
  onESGTemplateModalClose,
  refreshReportDataLake,
  business_unit_id,
}) => {
  const [dataSource, setDatasource] = useState([]);
  const [standardFilter, setStandardFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [codeFilter, setCodeFilter] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showESGTemplateFormModal, setShowESGTemplateFormModal] =
    useState(false);
  const [loadingAddToLake, setLoadingAddToLake] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [searchValue, setSearchValue] = useState("");
  //const Add

  const handleChange = (pagination, filters, sorter) => {
    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearTableFilters = () => {
    // for clear table filters and sort
    setTableFilters({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  useEffect(() => {
    if (!loading) return;
    const loadData = async () => {
      try {
        const formItems = await getReportingStandards();
        const parsedData = await Promise.all(
          formItems?.map(async (item) => {
            return await parsedStandardData(item, "reporting");
          })
        );
        setDatasource(parsedData);

        const frameworksSet = new Set();

        parsedData.forEach((item) => {
          if (item && item.frameworks && typeof item.frameworks === "string") {
            try {
              const frameworksArray = JSON.parse(item.frameworks);
              if (Array.isArray(frameworksArray)) {
                frameworksArray.forEach((framework) => {
                  frameworksSet.add(framework.trim());
                });
              }
            } catch (error) {
              console.error("Error parsing frameworks:", error);
            }
          }
        });

        setStandardFilter(Array.from(frameworksSet));
      } catch (e) {
        console.log(e);
        message.error("Failed to load ffdd data");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [loading, report_period, location_id]);

  useEffect(() => {
    // Reload data when network recovers
    window.addEventListener("online", () => {
      setLoading(true);
    });
    message.config({
      maxCount: 1,
    });
  }, []);

  const columns = [
    SearchableColumn({
      title: "Name",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend"],
      searchPlaceHolder: "Search Name",
      filteredValue: tableFilters.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
      render: (_, data) => {
        if (data) {
          return <p> {data.name}</p>;
        }
      },
    }),
    SearchableColumn({
      title: "Description",
      dataIndex: "description",
      width: "30%",
      searchPlaceHolder: "Search Description",
      filteredValue: tableFilters.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
      render: (_, data) => {
        if (data) {
          return <p> {data.description}</p>;
        }
      },
    }),

    {
      title: "Standard",
      dataIndex: "frameworks",
      width: "20%",
      render: (record, data) => {
        let value;

        if (typeof record === "string") {
          try {
            value = JSON.parse(record);
          } catch (error) {
            console.error("Invalid JSON string:", error);
            return null;
          }
        } else if (typeof record === "object" && record !== null) {
          value = record;
        } else {
          return null;
        }

        if (Array.isArray(value)) {
          return value?.map((a, i) => (
            <StyledTag className="margin-2" key={data._id.$oid + i}>
              {a}
            </StyledTag>
          ));
        } else {
          return null;
        }
      },
      sorter: standardSorter,
      sortDirections: ["descend", "ascend"],
      filters: standardFilter.map((fil) =>
        fil !== "" ? { text: fil, value: fil } : { text: "None", value: "" }
      ),
      onFilter: (value, record) => {
        return record?.frameworks?.includes(value);
      },
      filteredValue: tableFilters.filteredInfo?.frameworks
        ? tableFilters.filteredInfo.frameworks
        : null,
      sortOrder:
        tableFilters.sortedInfo?.field === "frameworks" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Code",
      dataIndex: "standard_code",
      width: "20%",
      sorter: (a, b) => b.standard_code.localeCompare(a.standard_code),
      sortDirections: ["descend", "ascend"],
      filters: codeFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) =>
        record?.specific_standard?.indexOf(value) === 0,
      filteredValue: tableFilters.filteredInfo?.standard_code
        ? tableFilters.filteredInfo.standard_code
        : null,
      sortOrder:
        tableFilters.sortedInfo?.field === "standard_code" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "View",
      key: "operation",
      fixed: "right",
      width: "10%",
      render: (_, record) => (
        <StyledButton type="custom" onClick={() => onRowFormView(record)}>
          <EyeOutlined />
        </StyledButton>
      ),
    },
  ];

  const onSelectRowChange = (selectedRowKeys, record) => {
    setSelectedRowKeys(record);
  };

  const getIdsFromSelectedData = (selectedRowKeys) => {
    return selectedRowKeys.map((item) => {
      return item?._id?.$oid;
    });
  };

  const rowSelection = {
    selectedRowKeys: getIdsFromSelectedData(selectedRowKeys),
    onChange: onSelectRowChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const resetSelectedRows = () => {
    // Reset state for the selected rows forms id's to empty array.
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onClickAddToDataLake = async () => {
    // Promise fuction used to wait for forEach loop complete.

    if (selectedRowKeys.length < 1) return;
    setLoadingAddToLake(true);
    const checkAddDataLoopEnd = new Promise((resolve) => {
      const faildedRecords = [];
      const alreadyAddedRecords = [];
      const successRecords = [];
      let index = 0;
      for (const item of selectedRowKeys) {
        try {
          const formJson = JSON.stringify(item.form_json_code);
          const formUi = JSON.stringify(item.form_ui_code);

          const dataToSubmit = {
            standard_id: item._id.$oid,
            status: "ACTIVE",
            user_status: "open",
            publish_status: false,
            data_type: item.data_type,
            name: item.name || "",
            description: item.description || "",
            resource_urls: item.standardLink,
            standard_code: item.standard_code || "",
            frameworks: JSON.parse(item.frameworks),
            summary: item.summary || "",
            report_period: report_period ?? "",
            location_id: location_id ?? "",
            business_unit: business_unit_id ?? "",
            tags: {
              industry: item.tags.industry || "",
              category: item.tags.category || "",
              theme: item.tags.theme || "",
            },
          };

          const responseData = addSelectedStandards(dataToSubmit);
          if (responseData) {
            successRecords.push(item);
          } else {
            alreadyAddedRecords.push(item);
          }
        } catch (error) {
          faildedRecords.push(item);
          console.log("error while creating esg for user", error);
        }
        index = index + 1;
      }
      resolve({ alreadyAddedRecords, successRecords, faildedRecords });
    });

    // after for each loop complete call hide ESG templates modal.
    checkAddDataLoopEnd.then((result) => {
      message.info(
        `${
          result?.alreadyAddedRecords?.length
            ? result?.alreadyAddedRecords?.length +
              " standard(s) already in the data lake. "
            : ""
        }  ${
          result?.successRecords?.length
            ? result?.successRecords?.length +
              " standard(s) successfully added to the data lake."
            : ""
        }`
      );

      if (result?.faildedRecords?.length) {
        message.error(
          `(${result?.faildedRecords?.length}) standards are failed to add in data lake`
        );
      }
      setLoadingAddToLake(false);
      refreshReportDataLake && refreshReportDataLake();
      onESGTemplateModalClose();
      // window.location.reload();
    });
  };

  const onRowFormView = (rowData) => {
    // view modal
    setRowData(rowData);
    setShowESGTemplateFormModal(true);
  };

  const onResetFiltersClick = () => {
    // Reset Table Filters.
    clearTableFilters();
  };

  const handleSearchValueChange = (value) => {
    if (value === "") {
      setLoading(true);
    }
    setSearchValue(value);
  };

  const handleKeyUp = (e, datavalue) => {
    if (e.key === "Enter") {
      handleSearchClick(datavalue);
    }
  };

  const handleSearchClick = (dataValue) => {
    if (searchValue && searchValue !== "") {
      const value = dataValue;
      const filteredData = searchStandard(value, searchValue);
      if (filteredData) {
        setDatasource(filteredData);
      }
    }
  };

  function searchStandard(data, searchTerm) {
    searchTerm = searchTerm.toLowerCase();

    return data?.filter((obj) => {
      const nameMatch = obj.name
        ? obj.name.toLowerCase().includes(searchTerm)
        : false;
      const descriptionMatch = obj.description
        ? obj.description.toLowerCase().includes(searchTerm)
        : false;
      const frameworksMatch = obj.frameworks
        ? obj.frameworks.includes(searchTerm)
        : false;

      const standardCodeMatch = obj.standard_code
        ? obj.standard_code.toLowerCase().includes(searchTerm)
        : false;

      if (
        nameMatch ||
        descriptionMatch ||
        standardCodeMatch ||
        frameworksMatch
      ) {
        return true;
      }

      return false;
    });
  }

  return (
    <>
      {showESGTemplateFormModal && (
        <ESGTemplateFormModal
          visible={showESGTemplateFormModal}
          onClose={() => setShowESGTemplateFormModal(false)}
          rowData={rowData}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              alignSelf: "center",
            }}
          >
            <SearchInput
              className="customInput"
              placeholder="Search"
              value={searchValue}
              prefix={<SearchOutlined style={{ color: "white" }} />}
              onKeyUp={(e) => handleKeyUp(e, dataSource)}
              onChange={(e) => handleSearchValueChange(e.target.value)}
            />
          </div>
          <StyledButton
            type="primary"
            onClick={() => handleSearchClick(dataSource)}
          >
            Search
          </StyledButton>
          <Space direction="horizontal">
            <StyledButton
              type="custom"
              onClick={resetSelectedRows}
              disabled={!hasSelected}
              loading={loading}
            >
              Reload
            </StyledButton>
            {/*<StyledButton type="custom"
            onClick={onResetFiltersClick} 
          >
            Reset
    </StyledButton>*/}
          </Space>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>
      </div>
      <CommonTable
        loading={loading}
        rowKey={(data) => {
          return data?._id?.$oid;
        }}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: true }}
        rowSelection={rowSelection}
        onChange={handleChange}
      />
      <div className="align-items-right-wrapper">
        <StyledButton
          type="custom"
          onClick={onClickAddToDataLake}
          disabled={!hasSelected}
          loading={loadingAddToLake}
        >
          Add to Data Lake
        </StyledButton>
      </div>
    </>
  );
};

export default ESGTemplateDataTable;

const standardSorter = (a, b) => {
  a = a.standards;
  b = b.standards;
  const a_length = a.length;
  const b_length = b.length;
  const diff = a_length - b_length;
  if (diff > 0) {
    return 1;
  }
  if (diff < 0) {
    return -1;
  } else {
    let i = 0;
    while (i < a_length) {
      const comp = a[i].localeCompare(b[i]);

      if (comp === 0) {
        i++;
      } else {
        return comp;
      }
    }
  }

  return 0;
};
