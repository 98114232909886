import React from "react";
import { useBoolean } from "ahooks";
import { Col, Row } from "antd";
import {
  CommonDarkTransparentTable,
  StyledButton,
  StyledProgress,
  StyledTabs,
} from "../../../../../shared/commonStyles";
import { PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { TargetActionButtons } from "./TargetActionButtons";
import { AddNewActionModal } from "./AddNewActionModal";
import { observer } from "mobx-react-lite";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import styles from "./SingleTarget.module.scss";
import moment from "moment";

interface ActionsProps {
  targetId: string;
}

export const Actions = observer(({ targetId }: ActionsProps) => {
  const [
    isAddNewActionModalVisible,
    { setTrue: showAddNewActionModal, setFalse: hideAddNewActionModal },
  ] = useBoolean(false);

  const handleMarkDone = (index: number, data: Action) => {
    reductionPlanStore.updateTargetAction(index, data);
  };

  const handleAddNewAction = (action: Action) => {
    reductionPlanStore.addTargetAction(action);
    hideAddNewActionModal();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      align: "left",
    },
    {
      title: "Date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: "20%",
      align: "center",
      render: (data: string) => (
        <span>
          <ReloadOutlined className={styles.dateIcon} />
          {moment(data).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: "25%",
      align: "center",
      render: (data: number) => {
        return (
          <StyledProgress
            percent={data}
            format={(percent: number) => percent + "%"}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "_id",
      render: (_: any, action: Action, index: number) => (
        <TargetActionButtons
          data={action}
          index={index}
          firstBtnAction={(data) => handleMarkDone(index, data)}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" className={styles.title}>
        <span>Actions</span>
        <StyledButton onClick={showAddNewActionModal}>
          <PlusCircleOutlined />
          Add new action
        </StyledButton>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <StyledTabs defaultActiveKey="1">
            <StyledTabs.TabPane tab="In progress" key="1">
              <CommonDarkTransparentTable
                columns={columns}
                loading={reductionPlanStore.loadingTargets}
                pagination={false}
                dataSource={reductionPlanStore.currentTarget.actions?.filter(
                  (item) => item.progress < 100
                )}
                showHeader={false}
              />
            </StyledTabs.TabPane>
            <StyledTabs.TabPane tab="Done" key="2">
              <CommonDarkTransparentTable
                columns={columns}
                pagination={false}
                dataSource={reductionPlanStore.currentTarget.actions?.filter(
                  (item) => item.progress === 100
                )}
                showHeader={false}
              />
            </StyledTabs.TabPane>
          </StyledTabs>
        </Col>
      </Row>
      <AddNewActionModal
        modalTitle="Add new action"
        data={{} as Action}
        isOpen={isAddNewActionModalVisible}
        onClose={hideAddNewActionModal}
        onConfirm={handleAddNewAction}
      />
    </>
  );
});
