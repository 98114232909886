import {
    API, Auth
} from 'aws-amplify';
import * as queries from '../../../../graphql/queries';

export const getConnectToken = async () => {
    const data = await Auth.currentSession();
    const ClientUserId = data['accessToken']['payload']['username'];

    const requestPayload = {
        client_user_id: ClientUserId
    };

    const responseGetUtilityBills = await API.graphql({
        query: queries["getUtilityBills"],
        variables: {
            request_type: "connect_token",
            request_payload: JSON.stringify(requestPayload)
        }
    });

    if (responseGetUtilityBills) {
        return responseGetUtilityBills['data']['getUtilityBills']
    }
};