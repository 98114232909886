import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { DatalakeQuery } from "../../../graphql/API";
import { datalake } from "../../../graphql/queries";

export const fetchChartData = (payload: any) => {
  const newFilters = { ...payload?.filters };
  if (payload?.filters?.name?.length) {
    newFilters.name = newFilters.name.replace(/"/g, '\\"');
  }

  const chartPayload = {
    filters: newFilters,
    identifiers: payload?.identifiers,
    chartTypes: [
      "totalCarbonEmissions",
      "emissionsByScope",
      "targetsProgress",
      "countGHGFactors",
      "offsettedEmissions",
      "emissionsLocationHotspots",
      "factorsFootprint",
    ],
  };
  return (
    API.graphql(
      graphqlOperation(datalake, {
        request_type: "get_carbon_charts",
        request_payload: JSON.stringify(chartPayload),
      })
    ) as Promise<GraphQLResult<DatalakeQuery>>
  ).then((response) => JSON.parse(response.data?.datalake || "{}"));
  
};
