import {
  BellOutlined,
  ApiOutlined,
  BookOutlined,
  CheckCircleOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  FormOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CardsWrap, ContentSection } from "../../shared/commonStyles";
import React, { useContext, useEffect, useState } from "react";
import { Result, Spin, Typography } from "antd";

import { AuthContext } from "../../contexts/AuthContext";
import Banner from "../../shared/banner";
import { COLORS } from "../../shared/colors";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { openInNewTab } from "../../shared/commonFunctions";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { useNavigate } from "react-router-dom";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

const cards = [
  {
    permission: "management-page/news-feed-view",
    cardTitle: "News Feed",
    cardIcon: <ContainerOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/news-feed",
    type: "additional-tools",
  },
  {
    permission: "learn-page",
    cardTitle: "Resources",
    cardIcon: <BookOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/learning-center",
    type: "additional-tools",
  },
  {
    permission: "settings-page",
    cardTitle: "Your Account",
    cardIcon: <UserOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/user-account",
    type: "user-settings",
  },
  {
    permission: "settings-staff-page",
    cardTitle: "User management",
    cardIcon: <UserOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/user-management",
    type: "user-settings",
  },
  {
    permission: "settings-staff-page",
    cardTitle: "User Groups",
    cardIcon: <UserOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/user-groups",
    type: "user-settings",
  },
  /*{
    permission: "settings-page",
    cardTitle: "Help Center",
    cardIcon: <QuestionCircleOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/help-center",
    type: "user-settings",
  },
  {
    permission: "settings-page",
    cardTitle: "Contact support",
    cardIcon: <PhoneOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/contact-support",
    type: "user-settings",
  },*/
  {
    permission: "management-page/releaseNotes-page",
    cardTitle: "Release Notes",
    cardIcon: <DatabaseOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/releaseNotes",
    type: "additional-tools",
  },
  {
    // TODO: QUERY: check if we need to make some additional config
    permission: "management-page/notifications-page",
    cardTitle: "Notification Center",
    cardIcon: <BellOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/notifications",
    type: "additional-tools",
  },
  {
    permission: "apps-page",
    cardTitle: "API Documentation",
    cardIcon: <ApiOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/management/api-documentation",
    type: "additional-tools",
  },
];

const Management = () => {
  const navigate = useNavigate();
  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();

  const { loadingAuth, auth } = useContext(AuthContext);
  const checkNoPermissions = () => {
    // 1st condition run if user have role:unauth.
    // 2nd condition run if user not have any role and org:group.
    if (
      checkRolesPermission(["role:unauth"]) &&
      !checkRolesPermission([
        "role:admin",
        "role:auditor",
        "role:manager",
        "role:user",
      ])
    ) {
      return true;
    } else if (!auth?.groups?.length || !auth?.roles?.length) {
      return true;
    } else {
      return false;
    }
  };

  const isPermissionError = checkNoPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);

  const allowedAdditionalToolsCards = cards.filter(
    (card) =>
      card.type == "additional-tools" && checkPermissions([card.permission])
  );

  const allowedUserSettingsCards = cards.filter(
    (card) =>
      card.type == "user-settings" && checkPermissions([card.permission])
  );

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/management/goals-and-progress":
        navigate(section);
        break;
      case "/management/news-feed":
        navigate(section);
        break;
      case "/management/collect-data":
        navigate(section);
        break;

      case "mass-upload-data":
        navigate(section);
        break;
      case "/management/learning-center":
        navigate(section);
        break;

      case "/management/standards-audits":
        navigate(section);
        break;

      case "/management/user-account":
        navigate(section);
        break;
      case "/management/user-management":
        navigate(section);
        break;
      case "/management/user-groups":
        navigate(section);
        break;
      case "/management/help-center":
        openInNewTab("https://support.hydrus.ai/");
        break;
      case "/management/api-documentation":
        openInNewTab("https://api-docs.hydrus.ai/");
        break;
      case "/management/releaseNotes":
        navigate(section);
        break;
      case "/management/notifications":
        navigate(section);
        break;
      case "/management/contact-support":
        window.location.href = "mailto: support@hydrus.ai";
        break;
      default:
    }
  };
  const popularActions = [
    {
      permission: "goals-and-progress-page",
      cardTitle: "Goals & Progress",
      cardIcon: <CheckCircleOutlined />,
      iconColor: "linear-gradient(180deg, #FC7786 0%, #E66270 100%)",
      cardLink: "/management/goals-and-progress",
      type: "tasks",
    },
    {
      permission: "management-page/flows-view",
      cardTitle: "Surveys",
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardIcon: <FormOutlined />,
      cardLink: "/management/collect-data",
      type: "data-collection",
    },
  ];
  useEffect(() => {
    popularActions.map((card, index) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);

  return (
    <Spin spinning={loadingAuth}>
      {isPermissionError ? (
        <Result
          status="403"
          subTitle="Error! Your account does not have appropriate permissions. Please contact your systems administrator"
        />
      ) : (
        !loadingAuth && (
          <ContentSection>
            <Banner />
            {popularActions.length > 0 && (
              <>
                {showPopluarActions && (
                  <Typography.Title
                    level={2}
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      marginTop: "20px",
                    }}
                  >
                    Popular Actions
                  </Typography.Title>
                )}
                <CardsWrap>
                  {popularActions.map(
                    (card, index) =>
                      checkPermissions([card.permission]) && (
                        <PopularActionCard
                          title={card.cardTitle}
                          iconColor={card.iconColor}
                          customCardStyle={customIconCardStyle.cardStyle}
                          titleStyle={customIconCardStyle.titleStyle}
                          onClick={onClickCardsSelectSection}
                          iconComponent={card.cardIcon}
                          section={card.cardLink}
                          key={index}
                          isLockModule={
                            card.moduleName ? card.moduleName : false
                          }
                        />
                      )
                  )}
                </CardsWrap>
              </>
            )}
            {/* Addinf settings page here */}
            {allowedUserSettingsCards.length > 0 && (
              <>
                {" "}
                <Typography.Title
                  level={2}
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                >
                  Settings
                </Typography.Title>
                <CardsWrap>
                  {allowedUserSettingsCards.map((card, index) => (
                    <CustomIconCard
                      title={card.cardTitle}
                      customCardStyle={customIconCardStyle.cardStyle}
                      titleStyle={customIconCardStyle.titleStyle}
                      onClick={onClickCardsSelectSection}
                      iconComponent={card.cardIcon}
                      section={card.cardLink}
                      key={index}
                      isLockModule={card.moduleName ? card.moduleName : false}
                    />
                  ))}
                </CardsWrap>
              </>
            )}
            {allowedAdditionalToolsCards.length > 0 && (
              <>
                <Typography.Title
                  level={2}
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                >
                  Additional Tools
                </Typography.Title>
                <CardsWrap>
                  {allowedAdditionalToolsCards.map((card, index) => {
                    return (
                      <CustomIconCard
                        title={card.cardTitle}
                        customCardStyle={customIconCardStyle.cardStyle}
                        titleStyle={customIconCardStyle.titleStyle}
                        onClick={onClickCardsSelectSection}
                        iconComponent={card.cardIcon}
                        section={card.cardLink}
                        key={index}
                        isLockModule={card.moduleName ? card.moduleName : false}
                      />
                    );
                  })}
                </CardsWrap>
              </>
            )}
          </ContentSection>
        )
      )}
    </Spin>
  );
};

export default Management;
