import { Col, Form, Progress, Row, Space, Spin, Steps, message } from "antd";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import {
  addSurveys,
  getSurveyById,
  getSurveysList,
  updateSurveys,
} from "../../services/surveys";
import {
  listSelectedStandards,
  parsedStandardData,
} from "../../services/standards";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import EmailTemplate from "../../components/EmailTemplate/EmailTemplate";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import Step5 from "./components/step5";
import Success from "./components/Success";
import { arrayMoveImmutable } from "array-move";
import { useBusinessUnitLocationPeriodFilter } from "../../hooks/useBusinessUnitLocationPeriodFilter";
import { v4 as uuidv4 } from "uuid";

const { Step } = Steps;

const CreateStandardsSurvey = () => {
  const {
    locations,
    businessUnits,
    reportPeriods,
    locationId,
    setLocationId,
    businessUnitId,
    setBusinessUnitId,
    reportPeriodId,
    setReportPeriodId,
  } = useBusinessUnitLocationPeriodFilter();
  const { survey_id: surveyId } = useParams();
  const [rowData, setRowData] = useState(null);
  const [step, setStep] = useState(1);

  const [sortedDataLakeList, setSortedDataLakeList] = useState([]);

  const [loadingCreateFlow, setLoadingCreateFlow] = useState(false);
  const [selectedListDataLake, setSelectedListDataLake] = useState([]);
  const [loadingFlowItems, setLoadingFlowItems] = useState(false);

  const [loadingDataLakeMap, setLoadingDataLakeMap] = useState([]);
  const [dataLakeMapsList, setDataLakeMapsList] = useState([]);
  const [dataLakeMapsListForTable, setDataLakeMapsListForTable] = useState([]);
  const [listAllSurveys, setListAllSurveys] = useState([]);
  const [action, setAction] = useState("create");
  const sampleEmailBody = `Hello {FULL_NAME},<br><br>We are conducting the <b>{SURVEY_NAME}</b> survey and would like your input. Please follow {SIGNUP_LINK} to our data management platform, <a href="http://Hydrus.ai target=__blank">Hydrus.ai</a>, where you can complete the survey. If you have any questions about this process, please don’t hesitate to reach out to your administrator.<br><br>
  Thanks,<br><br>Administrator`;

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    status: true,
    selectedStandards: [],
    email_subject: "",
    reminder_interval: "op1",
    email_type: "Primary",
    email_reminder_subject: "",
    email_body: sampleEmailBody,
    email_reminder_body: "",
  });

  const [form] = Form.useForm();

  const loadDataLake = async () => {
    try {
      setLoadingDataLakeMap(true);
      const data = await Auth.currentSession();
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      // for filtering data
      const businessUnit = businessUnitId ? businessUnitId : undefined;
      const reportPeriod = reportPeriodId ? reportPeriodId : undefined;
      const newLocationId = locationId ? locationId : undefined;
      const payload = {
        filter: {
          group_id: group,
          business_unit: businessUnit,
          location_id: newLocationId,
          report_period: reportPeriod,
          status: 'ACTIVE'
        },
      };
      const standardsResponse = await listSelectedStandards(payload);
      const formItems = standardsResponse?.data || [];
      const parsedData = await Promise.all(
        formItems?.map(async (item) => {
          return await parsedStandardData(item, "reporting");
        })
      );
      setDataLakeMapsList(parsedData);
      setDataLakeMapsListForTable(parsedData);
      // List All Surveys
      const ListSurveys = await getSurveysList();
      setListAllSurveys(ListSurveys["items"]);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoadingDataLakeMap(false);
    }
  };
  useEffect(() => {
    loadDataLake();
  }, [locationId, reportPeriodId, businessUnitId]);
  useEffect(() => {
    if (surveyId) {
      setAction("update");
      setLoadingFlowItems(true);
      const loadSurveyRowData = async () => {
        try {
          const surveyData = await getSurveyById(surveyId);

          if (surveyData) {
            setRowData(surveyData);
          } else {
            message.error("Survey not found");
            navigate(`/management/collect-data`);
          }
        } catch (error) {
          console.log("Something went wrong while load flow items!", error);
          message.error("Something went wrong while load flow items!");
        } finally {
          setLoadingFlowItems(false);
        }
      };
      loadSurveyRowData();
    }
  }, [surveyId]);

  const loadData = () => {
    setLoadingFlowItems(true);
    try {
      const initData = {
        status: rowData?.status === "ACTIVE" ? true : false,
        name: rowData.name,
        description: rowData.description,
        email_subject: rowData?.email_subject,
        email_reminder_subject: rowData?.email_reminder_subject,
        email_reminder_body: rowData?.email_reminder_body,
        email_body: rowData.email_body,
        email_type: "Primary",
        reminder_interval:
          rowData.reminder_interval === null
            ? "op1"
            : rowData.reminder_interval === 1000000
            ? rowData.reminder_interval
            : "recurring_reminder",
        recurring_reminder_val:
          rowData?.reminder_interval != 1000000
            ? rowData?.reminder_interval
            : "",
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    } catch (error) {
      console.log("Something went wrong while load flow items!", error);
      message.error("Something went wrong while load flow items!");
    } finally {
      setLoadingFlowItems(false);
    }
  };

  const loadSortedData = () => {
    setLoadingFlowItems(true);
    try {
      const surveyOrders = JSON.parse(rowData?.survey_order);
      const selectedRecordsIds = surveyOrders.map((item) => {
        return item.id;
      });
      const dataMapList = dataLakeMapsList?.filter((sItem) =>
        selectedRecordsIds?.includes(sItem._id["$oid"])
      );
      const dataMapListNotIncluded = dataLakeMapsList?.filter(
        (sItem) => !selectedRecordsIds?.includes(sItem?._id["$oid"])
      );
      const newArray = [...dataMapList, ...dataMapListNotIncluded];
      setDataLakeMapsListForTable(newArray);
      const dataLakeMapSortable = dataMapList?.map((item, index) => {
        // add index for sortable table otherwise it hide rows when we try to sort.
        return {
          ...item,
          index: index,
        };
      });
      const welcomePageData = surveyOrders
        ?.filter((item) => item?.survey_type === "welcome_page")
        .map((item) => ({ ...item, _id: { $oid: item.id } }));
      const newData = dataLakeMapSortable;
      if (welcomePageData?.length) {
        let newIndex = dataLakeMapSortable.length;
        welcomePageData?.map((item, index) => {
          newIndex = newIndex + 1;
          const dataObj = {
            ...item,
            type: "welcome_page",
            index: newIndex,
          };
          newData.push(dataObj);
        });
      }
      const sorter = (a, b) => {
        const aData = a?._id?.["$oid"] || a?.id;
        const bData = b?._id?.["$oid"] || b?.id;
        let positionA = 0;
        let positionB = 0;
        surveyOrders.forEach((value, index) => {
          if (aData.indexOf(value.id) !== -1) {
            positionA = index;
          }
          if (bData.indexOf(value.id) !== -1) {
            positionB = index;
          }
        });
        return positionA - positionB;
      };

      newData.sort(sorter);
      setSelectedListDataLake(newData);
      // set initial data in form when update data
      const initData = {
        selectedStandards: dataMapList,
      };
      form.setFieldsValue(initData);
    } catch (error) {
      console.log("Something went wrong while load flow items!", error);
      message.error("Something went wrong while load flow items!");
    } finally {
      setLoadingFlowItems(false);
    }
  };

  useEffect(() => {
    if (rowData) {
      loadData();
    }
    if (dataLakeMapsList.length && rowData) {
      loadSortedData();
    }
  }, [dataLakeMapsList, rowData]);

  useEffect(() => {
    setDataForSortableTable();
  }, [selectedListDataLake]);

  const setDataForSortableTable = () => {
    if (selectedListDataLake && selectedListDataLake.length) {
      const data = selectedListDataLake.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setSortedDataLakeList(data);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(sortedDataLakeList),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const indexKeyUpdate = newData.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setSortedDataLakeList(indexKeyUpdate);
    }
  };

  const onSelectRowChange = (record, selected, selectedRows) => {
    if (selected) {
      const filterNewRecords = selectedRows.filter(
        (item) =>
          !selectedListDataLake.find(
            (bItem) => bItem?._id["$oid"] === item?._id["$oid"]
          )
      );
      const allSelectedRecords = [...selectedListDataLake, ...filterNewRecords];
      setSelectedListDataLake(allSelectedRecords);

      form.setFieldsValue({
        selectedStandards: allSelectedRecords,
      });
    } else {
      const filterNewRecords = selectedRows.filter(
        (item) =>
          !selectedListDataLake.find(
            (bItem) => bItem?._id["$oid"] === item?._id["$oid"]
          )
      );
      const allSelectedRecords = [...selectedListDataLake, ...filterNewRecords];
      const filteredRecords = allSelectedRecords.filter(
        (item) => item?._id["$oid"] !== record?._id["$oid"]
      );
      setSelectedListDataLake(filteredRecords);
      // save selected rows in state
      form.setFieldsValue({
        selectedStandards: filteredRecords,
      });
    }
  };

  const getDataIdsFromSelectedData = (selectedList) => {
    try {
      const selectedRecordsIds = selectedList.map((item) => {
        return item._id["$oid"];
      });
      const filterdData = dataLakeMapsList.filter((item) =>
        selectedRecordsIds.includes(item._id["$oid"])
      );
      return filterdData.map((item) => {
        return item._id["$oid"];
      });
    } catch (e) {
      return [];
    }
  };

  const rowSelection = {
    selectedRowKeys: getDataIdsFromSelectedData(selectedListDataLake),
    onSelect: onSelectRowChange,
    hideSelectAll: true,
  };

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onClickUpdateSurvey = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateFlow(true);
          const { name, description, status } = initialValues;
          const statusText = status ? "ACTIVE" : "INACTIVE";

          const dataIds = [];
          for (const item of sortedDataLakeList) {
            if (item.type === "welcome_page") {
              dataIds.push({
                id: item.id || uuidv4(),
                survey_type: "welcome_page",
                body: item.body,
                subject: item.subject,
              });
            } else {
              dataIds.push({ id: item._id["$oid"], survey_type: "standards" });
            }
          }
          const emailData = initialValues?.email_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminderEmailData = initialValues?.email_reminder_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminder_interval_final =
            initialValues.reminder_interval === "recurring_reminder"
              ? initialValues.recurring_reminder_val
              : initialValues.reminder_interval === "op1"
              ? null
              : 1000000;
          const payload = {};

          payload.id = rowData.id;
          payload.survey_type = "standards";
          payload.status = statusText;
          payload.name = name;
          payload.survey_order = JSON.stringify(dataIds);
          payload.description = description;
          payload.email_subject = initialValues?.email_subject
            ? initialValues?.email_subject
            : "";
          payload.email_reminder_subject = initialValues?.email_reminder_subject
            ? initialValues?.email_reminder_subject
            : "";
          payload.email_body = emailData;
          payload.email_reminder_body = reminderEmailData;
          payload.reminder_interval = reminder_interval_final
            ? Number(reminder_interval_final)
            : null;
          const response = await updateSurveys(payload);
          if (response) {
            setLoadingCreateFlow(false);
            form.resetFields();
            setStep(step + 1);
          }
        } catch (error) {
          message.error(`Error while creating survey. ${error}`);
        } finally {
          setLoadingCreateFlow(false);
        }
      })
      .catch((error) => message.error(`Error in survey flow. ${error}`));
  };
  const onClickCreateSurvey = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateFlow(true);
          const { name, description, status } = initialValues;
          const statusText = status ? "ACTIVE" : "INACTIVE";

          const dataIds = [];
          for (const item of sortedDataLakeList) {
            if (item.type === "welcome_page") {
              dataIds.push({
                id: uuidv4(),
                survey_type: "welcome_page",
                body: item.body,
                subject: item.subject,
              });
            } else {
              dataIds.push({ id: item._id.$oid, survey_type: "standards" });
            }
          }
          const emailData = initialValues?.email_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminderEmailData = initialValues?.email_reminder_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminder_interval_final =
            initialValues.reminder_interval === "recurring_reminder"
              ? initialValues.recurring_reminder_val
              : initialValues.reminder_interval === "op1"
              ? null
              : 1000000;
          const payload = {};

          payload.survey_type = "standards";
          payload.status = statusText;
          payload.name = name;
          payload.survey_order = JSON.stringify(dataIds);
          payload.description = description;
          payload.email_subject = initialValues?.email_subject
            ? initialValues?.email_subject
            : "";
          payload.email_body = emailData;
          payload.email_reminder_subject = initialValues?.email_reminder_subject
            ? initialValues?.email_reminder_subject
            : "";
          payload.email_reminder_body = reminderEmailData;
          payload.reminder_interval = reminder_interval_final
            ? Number(reminder_interval_final)
            : null;
          const response = await addSurveys(payload);
          if (response) {
            setLoadingCreateFlow(false);
            form.resetFields();
            setRowData(response.data.createSurveyManagement);
            setStep(step + 1);
          }
        } catch (error) {
          message.error("Error while creating flow.");
          console.log("error while creating flow", error);
        } finally {
          setLoadingCreateFlow(false);
        }
      })
      .catch((error) => console.log("error form in flow", error));
  };

  const onSubmitHandler = () => {
    if (action === "update") {
      onClickUpdateSurvey();
    } else {
      onClickCreateSurvey();
    }
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return <Step1 onClickNext={onClickNext} />;
    } else if (step === 2) {
      return (
        <Step2
          locations={locations}
          businessUnits={businessUnits}
          reportPeriods={reportPeriods}
          locationId={locationId}
          setLocationId={setLocationId}
          businessUnitId={businessUnitId}
          setBusinessUnitId={setBusinessUnitId}
          reportPeriodId={reportPeriodId}
          setReportPeriodId={setReportPeriodId}
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
        />
      );
    } else if (step === 3) {
      return (
        <Step3
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          rowSelection={rowSelection}
          loadingDataLakeMap={loadingDataLakeMap}
          dataLakeMapsList={dataLakeMapsListForTable}
          listAllSurveys={listAllSurveys}
        />
      );
    } else if (step === 4) {
      return (
        <EmailTemplate
          form={form}
          key="emailTemplate"
          onClickPrevious={onClickPrevious}
          setInitialValues={setInitialValues}
          initialValues={initialValues}
          onClickNext={onClickNext}
        />
      );
    } else if (step === 5) {
      return (
        <Step5
          action={action}
          onSortEnd={onSortEnd}
          onSubmitHandler={onSubmitHandler}
          sortedDataLakeList={sortedDataLakeList}
          setSortedDataLakeList={setSortedDataLakeList}
          onClickPrevious={onClickPrevious}
          loadingCreateFlow={loadingCreateFlow}
        />
      );
    } else if (step === 6) {
      return <Success action={action} survey={rowData} />;
    } else {
      console.log("completed");
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadingFlowItems}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                className="margin-5"
                type="custom"
                onClick={() => navigate("/management/collect-data")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                <Step
                  className="stepper-wrapper "
                  key={1}
                  title={<span>General Information</span>}
                />
                <Step
                  className="stepper-wrapper "
                  key={2}
                  title={<span>Business Information</span>}
                />
                <Step
                  className="stepper-wrapper"
                  key={3}
                  title={<span>Standards selection</span>}
                />
                <Step
                  className="stepper-wrapper"
                  key={4}
                  title={<span>Email</span>}
                />
                <Step
                  className="stepper-wrapper"
                  key={5}
                  title={<span>Flow order</span>}
                />
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 20}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default CreateStandardsSurvey;
