import { EditOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CommonModal } from "../../../../../shared/commonStyles";
import { Step1AddManualEmissions } from "../AddManualEmissionsModal/Step1AddManualEmissions";
import { useDynamicList } from "ahooks";

interface EditEmissionFactorModalProps {
  disabled?: boolean;
  data: Emission;
  editEmission: (data: Emission) => void;
}

export const EditEmissionFactorModal = ({
  disabled,
  data,
  editEmission,
}: EditEmissionFactorModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    list: selectedEmissions,
    remove: removeEmission,
    resetList: resetSelectedEmissions,
  } = useDynamicList<Emission>([data]);

  const handleOk = () => {
    const {
      key,
      consumption_amount,
      scope,
      history,
      estimated,
      notes,
      custom_name,
      indicatorType,
      recAmount,
      recPercent,
    } = data;
    const newData: ManualEmission = {
      ...selectedEmissions[0],
      key,
      consumption_amount,
      manual: true,
      scope,
      estimated,
      notes,
      history,
      custom_name,
      indicatorType,
      recAmount,
      recPercent,
    };
    editEmission(newData);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button onClick={() => setIsModalVisible(true)} disabled={disabled}>
        <EditOutlined />
        Edit
      </Button>
      <CommonModal
        centered
        title="Edit emission factor"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        width={758}
      >
        <Step1AddManualEmissions
          data={data}
          scopeName={data.scope}
          selectedEmissions={selectedEmissions}
          removeEmission={removeEmission}
          resetSelectedEmissions={resetSelectedEmissions}
        />
      </CommonModal>
    </>
  );
};
