import { DeleteOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import { CommonTable, StyledButton, TableStyledMenu } from "../../../../shared/commonStyles";
import Assignees from "./Assignees";
import RelatedItems from "./RelatedItems";
import { subGoals } from "../../../../services/goals";

const SubGoalsTable = ({goalData, markAsCompleted, editGoals, handleOnDelete}) => { 
  const [loader, setLoader] =  useState(false)
  const [dataSource, setDataSource] =  useState(null) 
  
  const loadGoals = async() => {
    try{
      setLoader(true)
      const goals = await subGoals(goalData?.id) 
      setDataSource(goals)
    }catch(e){
      message.error(e)
    }finally{
      setLoader(false)
    }
  }
  useEffect(()=> {
    if(goalData){
      loadGoals()
    }
  },[goalData])

  
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "10%",
      render:(_,record) => {
        return <Assignees record={record} />
      }
    },
    {
      title: "Related Items",
      dataIndex: "relatedItems",
      width: "20%",
      render:(_,record) => {
        return <RelatedItems record={record} />
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (_,record) => {
        return (
          <Space direction="horizontal">
            <StyledButton
              type="default"              
              onClick={() => markAsCompleted(record)}
              //disabled={record?.status !== 'IN_PROGRESS' || goalData?.status !== 'IN_PROGRESS'}
            >
              Mark as {record?.status === 'COMPLETED' ? 'In Progress' : 'Completed'}
            </StyledButton>
            <Dropdown overlay={() => menu(record)} trigger={["click"]}>
              <StyledButton type="default" icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        )
      },
    },
  ];
  const menu = (record) => {
    return (
      <TableStyledMenu style={{padding:'0'}}>        
        <Menu.Item
          key={record?.id+3} 
          onClick={() => editGoals(record,'GEN_SHORT')}
          style={{backgroundColor:'transparent',padding:'0'}}       
        >
          <StyledButton type="default" style={{width:'100%', margin:'0',padding: "11px 18px",textAlign: "left"}}  icon={<EditOutlined />}>Edit</StyledButton>        
        </Menu.Item>            
        <Menu.Item 
          key={record?.id+4}  
          style={{backgroundColor:'transparent',padding:'0'}}
          onClick={() => handleOnDelete(record)}  
        >
          <StyledButton type="default" style={{width:'100%', margin:'0',padding: "11px 18px", textAlign: "left"}} icon={<DeleteOutlined />}>Delete</StyledButton>
        </Menu.Item>
      </TableStyledMenu>
    )
  }

  return ( 
      <div className="subgoals">
        {dataSource && 
          <CommonTable
            showHeader={false}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
            pagination={false}
            rowKey="id" 
            rowClassName={(record) => {          
              return record?.status === 'DELETED' ? 'rowDenied' : ''
            }}        
          />
        }
        </div>
  )
  
}
export default SubGoalsTable;
