import { Input, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../../shared/commonStyles";

const InputData = ({ rowData, handleSave, type }) => {
  const [inputValue, setInputValue] = useState(null)
  useEffect(() => {
    setInputValue(rowData?.sectionName)
  },[rowData])
  
  const save = (event) => {
    handleSave(event.target.value,rowData)
  }
  return (
    <FormWrapper>  
      <Row gutter={[16,16]}>
        {type === 'child' && <Col span={2}><img src="/enter-outlined.svg" /></Col>}
        <Col>
          <Input
            value={inputValue}
            placeholder="Enter section name"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onBlur={e => save(e)}
          />
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default InputData;
