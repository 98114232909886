import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getSavedReports = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_saved_reports",
    },
  });

  return JSON.parse(response.data.datalake);
};


export const getReportById = async (reportID) => {
  const allReports = await getSavedReports();
  return allReports?.find((item) => item._id.$oid === reportID);
};

export const addSavedReports = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const payload = {...requestPayload,'group':group,username}
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_saved_reports",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};
export const updateSavedReports = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const payload = {...requestPayload,'group':group,username}
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_saved_reports",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const removeSavedReports = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_saved_reports",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};