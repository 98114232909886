import React from 'react';
import CategoryTabItem from './CategoryTabItem';

import { StyledTabsContainer } from './CategoryTabs.style'

const CategoryTabs = ({ categories, activeCategory, onClick }) => {
  return (
    <StyledTabsContainer>
      {categories?.map(category => 
        <CategoryTabItem 
          key={category} 
          label={category} 
          isActive={activeCategory === category} 
          onClick={() => onClick(category)}
        />
      )}
    </StyledTabsContainer>
  );
}

export default CategoryTabs;
