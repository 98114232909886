import React from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";
import { encodeUrlName } from "../../../shared/commonFunctions";

const { Title } = Typography;

const Success = ({ action, survey }) => {
  const navigate = useNavigate();
  return (
    <>
      <FormWrap width="530px">
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <CheckCircleOutlined style={{ fontSize: "40px" }} />
              <Title className="color-white" level={4}>
                Survey was {action === "update" ? "updated" : "created"}{" "}
                successfully!
              </Title>
              <p>
                <span>
                  {" "}
                  Survey was {action === "update" ? "updated" : "created"}{" "}
                  successfully! It is already available in Collect Data section
                </span>
              </p>
              <Row justify="space-between">
                <Col>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      navigate(
                        `/management/collect-data/${survey.id}/${encodeUrlName(
                          survey.name
                        )}/view-survey`
                      )
                    }
                  >
                    <span>Open Survey</span>
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => navigate("/management/collect-data")}
                  >
                    <span>Go to All Surveys</span>
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => {
                      navigate("/management/collect-data", {
                        state: { showModal: true },
                      });
                    }}
                  >
                    <span>Create Another Survey</span>
                  </StyledButton>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </FormWrap>
    </>
  );
};

export default Success;
