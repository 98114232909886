import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Auth, Storage } from "aws-amplify";
import logo from "./hydrus_app_logo_new.png";
import { LogoWrapper, StyledSider } from "../shared/commonStyles";
import { useSize } from "ahooks";

const LogoSideBar = () => {
  const [c_logo, setLogo] = useState(logo);
  const size = useSize(document.querySelector("body"));

  useEffect(() => {
    const getFile = async () => {
      try {
        const data: any = await Auth.currentSession();
        const groups = data.accessToken.payload["cognito:groups"]?.filter(
          (element: any) => element.includes("org:")
        )[0];
        const a_logo = await Storage.get(
          `icons/${groups?.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
        );
        setLogo(a_logo);
      } catch (error) {
        console.log("Error while load header logo", error);
      }
    };
    getFile();
  }, []);

  const headerLogoErrorHandle = () => {
    setLogo(logo);
  };

  return (
    <>
      {size && size?.width > 600 ? (
        <StyledSider>
          <LogoWrapper>
            <Link to="/">
              <img
                onError={() => headerLogoErrorHandle()}
                src={c_logo}
                alt="hydrus Icon"
              />
            </Link>
          </LogoWrapper>
        </StyledSider>
      ) : (
        <LogoWrapper style={{ position: "absolute", zIndex: 0 }}>
          <Link to="/">
            <img
              onError={() => headerLogoErrorHandle()}
              src={c_logo}
              alt="hydrus Icon"
            />
          </Link>
        </LogoWrapper>
      )}
    </>
  );
};
export default LogoSideBar;
