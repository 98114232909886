import { message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { subSdgGoals } from "../../../../services/goals";

const SubgoalCount = ({ record }) => {
  const [subGoalsData, setSubGoalsData] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchShortGoal = async () => {
    try {
      setLoader(true);
      const subGoalsData = await subSdgGoals(record?.id);
      setSubGoalsData(subGoalsData);
      if (subGoalsData?.length) {
        return subGoalsData.length;
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchShortGoal();
  }, [record]);

  return (
    <>
      <Spin spinning={loader}>{subGoalsData?.length > 0 ? subGoalsData?.length : ''}</Spin>
    </>
  );
};

export default SubgoalCount;
