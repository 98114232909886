import React from "react";
import { Link } from "react-router-dom";
import {   
  Typography
} from "antd";
import {
  StyledProgress,
} from "../../../shared/commonStyles";
const { Title } = Typography;
const ProgressTracker = ({metricData, type}) => {  

  const ProgressData = (percentage, name,index) => {
    return (
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          width: 110,
          justifyContent: "center",
          alignItems: "center",
          margin: 20,
        }}
        key={index}
      >
        <Link to=''>
          <StyledProgress
            tickcolor="#6affdb"
            width={90}
            percent={percentage}
            type="circle"
            trailColor="#403655"
            strokeColor={{
              "0%": "#6AFFDB",
              "100%": "#6AFFDB",
            }}
            strokeWidth={10}
          />
          <Title
            level={5}
            style={{
              color: "#fff",
              textAlign: "center",
            }}
          >
            {name}
          </Title>
        </Link>
      </div>
    )
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      > 
        {type ?
          <>
            {Object.keys(metricData)?.filter(key => metricData[key]?.category === type)?.map((key, index) => {              
              return ProgressData(metricData[key]?.progress,key, index)
            })}
          </>
          :
            <>
              {Object.keys(metricData).map((key, index) => {            
                return ProgressData(metricData[key]?.progress,key, index)
              })}
            </>
        }
        
      </div>
    </>
  );
};

export default ProgressTracker