import React, { useState } from "react";
import { UndoOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { ConfirmModal } from "./ConfirmModal/ConfirmModal";
import styles from "./CalculatorEntryHistoryButtons.module.scss";

interface CalculatorEntryHistoryButtonsProps {
  isDeleted?: boolean;
  popoverData: CalculatorEntryHistory;
  onRestore: () => void;
  onCurrent: () => void;
}

export const CalculatorEntryHistoryButtons = ({
  isDeleted = false,
  popoverData,
  onRestore,
  onCurrent,
}: CalculatorEntryHistoryButtonsProps) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const infoConfirmModal = {
    title: `Are you sure you want to restore Version ${popoverData?.version}: ${popoverData?.timestamp?.$date}?`,
    description: "The later versions may be lost",
    confirmText: "Restore",
    cancelText: "Cancel",
  };

  const historyPopover = () => {
    return (
      <div className={styles.historyPopover}>
        <div className={styles.historyPopoverTitle}>
          You are viewing Version {popoverData?.version}:{" "}
          {popoverData?.timestamp?.$date}
        </div>
        {isDeleted ? (
          <>
            {" "}
            <div className={styles.historyPopoverText}>
              <span>
                You can return to the current one by clicking "Go to current
                version"{" "}
              </span>
              <Button className={styles.historyPopoverBtn}>
                <UploadOutlined rotate={90} />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.historyPopoverText}>
              <span>
                You can restore this version by clicking "Restore this version"{" "}
              </span>
              <Button className={styles.historyPopoverBtn}>
                <UndoOutlined />
              </Button>{" "}
            </div>
            <div className={styles.historyPopoverText}>
              <span>
                or return to the current one by clicking "Go to current version"{" "}
              </span>
              <Button className={styles.historyPopoverBtn}>
                <UploadOutlined rotate={90} />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleRestore = () => {
    onRestore();
    setIsConfirmVisible(false);
  };
  return (
    <div className={styles.historyBtnWrap}>
      {!isDeleted && (
        <Popover
          autoAdjustOverflow
          overlayClassName="popoverContent"
          content={historyPopover}
          placement="topLeft"
        >
          <Button
            className={styles.btnIcon}
            onClick={() => setIsConfirmVisible(true)}
          >
            <UndoOutlined />
          </Button>
        </Popover>
      )}{" "}
      <Popover
        autoAdjustOverflow
        placement="topLeft"
        overlayClassName="popoverContent"
        content={historyPopover}
      >
        <Button className={styles.btnIcon} onClick={onCurrent}>
          <UploadOutlined rotate={90} />
        </Button>
      </Popover>
      <ConfirmModal
        onConfirm={handleRestore}
        visible={isConfirmVisible}
        setFalse={() => setIsConfirmVisible(false)}
        infoConfirmModal={infoConfirmModal}
      />
    </div>
  );
};
