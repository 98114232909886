import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, message, Row } from 'antd';
import moment from 'moment';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';

export const BasicInformation = ({ companySave, company, setLoading }) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState('');

  const handleSubmit = (values) => {
    companySave({
      ...values,
      founded_year: moment(values.founded_year).format('YYYY'),
      logo,
    });
  };

  const getCompanyPortfolio = async () => {
    try {
      setLoading(true);
      const domain_name = await form.getFieldValue('website_domain');
      if (domain_name) {
        const response = await API.graphql({
          query: queries['portfolioCrawler'],
          variables: {
            domain_name,
          },
        });
        const portfolio = JSON.parse(response['data']['portfolioCrawler']);
        if (portfolio?.error) {
          message.error('Error while getting company portfolio!');
          return {};
        }
        return portfolio;
      }
    } catch (error) {
      message.error('Error while getting company portfolio!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (company) {
      const toState = {
        ...company,
        founded_year: company.founded_year ? moment(`${company.founded_year}`) : moment(),
      };
      form.setFieldsValue(toState);
      setLogo(company.logo);
    }
  }, [company]);

  const handlePrefil = async () => {
    const companyPortfolio = await getCompanyPortfolio();
    setLogo(companyPortfolio?.logo || '');

    const prefillData = {
      company_name: companyPortfolio?.name || '',
      tagline: companyPortfolio?.tags?.length ? companyPortfolio?.tags[0] : '',
      description: companyPortfolio?.description || '',
      ceo_name: '',
      location: companyPortfolio?.location ? companyPortfolio?.location : '',
      founded_year: companyPortfolio?.foundedYear
        ? moment(`${companyPortfolio?.foundedYear}`)
        : moment(),
      industry: companyPortfolio?.category?.industry || '',
    };
    form.setFieldsValue(prefillData);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      autoComplete="off"
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
    >
      <Row>
        <Col span={9}>
          <Form.Item
            label="Website"
            name="website_domain"
            rules={[{ required: true, message: 'Please input a website domain!' }]}
          >
            <Input addonBefore="https://" onBlur={handlePrefil} />
          </Form.Item>
        </Col>
        <Col span={9} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <img src={logo} alt="" style={{ maxHeight: '100px' }} />
        </Col>
        <Col span={9}>
          <Form.Item
            label="Name"
            name="company_name"
            rules={[{ required: true, message: 'Please input company name!' }]}
          >
            <Input placeholder="Name of the portfolio company" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label="Tagline (max. 35 characters)"
            name="tagline"
            rules={[{ required: true, message: 'Please input tagline!' }]}
          >
            <Input placeholder="Enter the company slogan or catchphrase" maxLength={35} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Description (max. 555 characters)"
            name="description"
            rules={[{ required: true, message: 'Please input a description!' }]}
          >
            <Input.TextArea
              placeholder="Provide a short description about what the company does"
              maxLength={555}
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="Founding year" name="founded_year">
            <DatePicker placeholder="Select year" picker="year" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label="CEO"
            name="ceo_name"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input placeholder="Enter the name of the CEO" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="Industry" name="industry">
            <Input placeholder="Enter an industry" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input placeholder="Enter where the company HQ is" />
          </Form.Item>
        </Col>
      </Row>
      <div className="report-form-controls">
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </div>
    </Form>
  );
};
