export const getProgress = (target: Target) => {
  if (
    !target.baseYearConsumptionAmount &&
    !target.currentConsumptionAmount &&
    !target.goal
  ) {
    return 0;
  }
  const result =
    ((+target.baseYearConsumptionAmount - +target.currentConsumptionAmount) /
      (+target.baseYearConsumptionAmount - +target.goal)) *
    100;
  if (result < 0) {
    return 0;
  } else if (result > 100) {
    return 100;
  }
  return result;
};
