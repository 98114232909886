import React, { useState, useEffect } from "react";
import moment from "moment";
import { message, Timeline, Spin, Alert, Divider } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { HistoryDataSectionProps } from "../Container/SustainabilityStandardContainer";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import { YearMonthDay24HourFormat } from "../../../../shared/constants";
import { StyledButton, StyledTitle } from "../../../../shared/commonStyles";
import { COLORS } from "../../../../shared/colors";
import styles from "./SustainabilityStandardsComponent.styles.scss";

export const SustainabilityStandardHistoryComponent: React.FC<
  HistoryDataSectionProps
> = ({
  onChangeHistoryHandler,
  rowData,
  setVersionInfo,
  setLatestVersion,
  currentVersion,
}) => {
  const [historyData, setHistoryData] = useState<any[]>([] || null);
  const [loadingHistoryData, setLoadingHistoryData] = useState(false);

  useEffect(() => {
    onClickRequestHistory(rowData?.history);
  }, [rowData]);

  const onClickRequestHistory = async (historyData: any) => {
    setLoadingHistoryData(true);
    try {
      if (historyData?.length > 0) {
        const versionIncreasedHistoryData = await Promise.all(
          historyData
            .map(async (item: any) => {
              const firstLastName = await getFirstAndLastNameByUsername(
                item.username
              );
              return {
                ...item,
                first_last_name: firstLastName,
              };
            })
            .reverse()
        );

        const latestHistorySavedData =
          currentVersion > 0
            ? versionIncreasedHistoryData.find(
                (item) => item.version_number == currentVersion
              )
            : versionIncreasedHistoryData.slice(0)[0];

        setHistoryData(versionIncreasedHistoryData);
        setLatestVersion(latestHistorySavedData);
      }
    } catch (error) {
      message.error("Error while load history data");
    } finally {
      setLoadingHistoryData(false);
    }
  };

  const getHistoryDataByVersionId = (versionId: any) => {
    return (
      historyData &&
      historyData.find((item) => item.version_number === versionId)
    );
  };

  const onChangeHistoryVersion = (version: any) => {
    const historyVersionData = getHistoryDataByVersionId(version);

    onChangeHistoryHandler(historyVersionData);

    const createDate = historyVersionData?.create_timestamp?.$date
      ? moment(historyVersionData?.create_timestamp?.$date).format(
          YearMonthDay24HourFormat
        )
      : null;
    setVersionInfo(historyVersionData.version_number + " :" + createDate);
  };

  return (
    <div className="history-section">
      <StyledTitle level={3}>History</StyledTitle>
      <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
      <Spin spinning={loadingHistoryData}>
        {historyData ? (
          <>
            <div className="data-form-history-timeline-section">
              <Timeline>
                {historyData.map((item, index) => {
                  const createDate = item?.create_timestamp?.$date
                    ? moment(item?.create_timestamp?.$date).format(
                        YearMonthDay24HourFormat
                      )
                    : null;

                  return (
                    <>
                      <Timeline.Item key={index} color={"#A68DFB"}>
                        <p>
                          <b>
                            {index == 0 ? (
                              <>Current Version: {createDate}</>
                            ) : (
                              <>
                                Version {item.version_number} : {createDate}
                              </>
                            )}{" "}
                            <StyledButton
                              bgcolor={COLORS.martinique}
                              bordercolor={COLORS.white}
                              type="custom"
                              className="HistoryButton"
                              icon={
                                currentVersion === item.version_number ? (
                                  ""
                                ) : (
                                  <UndoOutlined />
                                )
                              }
                              disabled={
                                currentVersion === item.version_number
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                onChangeHistoryVersion(item.version_number)
                              }
                              style={{ float: "right", padding: "5px 10px" }}
                            >
                              {currentVersion === item.version_number
                                ? "Currently Open"
                                : "Open Version"}
                            </StyledButton>
                          </b>
                        </p>
                        <p>
                          {" "}
                          {item.first_last_name}
                          <span style={{ fontWeight: "300" }}>
                            {" "}
                            created this data
                          </span>
                        </p>
                        <p>
                          {item.first_last_name}
                          <span style={{ fontWeight: "300" }}>
                            {" "}
                            set status <b>{item.user_status}</b>
                          </span>
                        </p>
                      </Timeline.Item>
                      <Timeline.Item
                        pending={item.create_timestamp}
                        color={"#A68DFB"}
                      >
                        <p>
                          {item.first_last_name}{" "}
                          {item.publish_status ? (
                            <span style={{ fontWeight: "300" }}>
                              published{" "}
                            </span>
                          ) : (
                            <span style={{ fontWeight: "300" }}>
                              unpublished
                            </span>
                          )}{" "}
                          data
                        </p>
                      </Timeline.Item>
                    </>
                  );
                })}
              </Timeline>
            </div>
          </>
        ) : (
          <>
            {!loadingHistoryData && (
              <Alert
                className="customInfoAlert"
                message="No modification history found."
                type="info"
                showIcon
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};
