import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";

const Ckformdata = ({ setCkFormData, parsedData }) => {
  const checkVariable = (variable) => {
    if (typeof variable === "string") {
      return variable;
    } else {
      return "";
    }
  };

  return (
    <div>
      <CKEditor
        editor={DecoupledDocumentEditor}
        data={checkVariable(parsedData)}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          if (editor?.ui) {
            editor.ui.view.editable.element.parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.view.editable.element
            );
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setCkFormData(data);
        }}
      />
    </div>
  );
};

export default Ckformdata;
