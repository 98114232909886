import React, { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import { CommonModal } from "../../../../../shared/commonStyles";
import styles from "./EditAddEditEntityModal.module.scss";
const { Option } = Select;

interface EditAddEditEntityModalProps {
  visible: boolean;
  onCancel: () => void;
  handleAssignEntity: (entity: string) => void;
  entities?: string[];
  selectedEntity?: string;
}

const EditAddEditEntityModal: React.FC<EditAddEditEntityModalProps> = ({
  visible,
  onCancel,
  entities,
  selectedEntity,
  handleAssignEntity,
}) => {
  const [currentSelectedEntity, setCurrentSelectedEntity] =
    useState(selectedEntity);

  return (
    <CommonModal
      title="Add/Edit Entity"
      visible={visible}
      onCancel={() => {
        onCancel();
        setCurrentSelectedEntity(selectedEntity);
      }}
      footer={[
        <Button
          type="text"
          key="cancel"
          onClick={() => {
            onCancel();
            setCurrentSelectedEntity(selectedEntity);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="add"
          type="primary"
          disabled={!Boolean(currentSelectedEntity)}
          onClick={() => {
            if (currentSelectedEntity) {
              handleAssignEntity(currentSelectedEntity);
            }
          }}
          style={{ backgroundColor: "#7F5FEE" }}
        >
          Add Entity
        </Button>,
      ]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select entity"
        className={styles.select}
        showSearch
        showArrow
        value={currentSelectedEntity}
        options={entities?.map((item: any) => ({
          value: item,
          label: item?.toString(),
        }))}
        filterOption={(input: string, option: any) =>
          ((option?.label as string) ?? "")
            .toLowerCase()
            .includes(input?.toLowerCase())
        }
        onChange={(value) => {
          setCurrentSelectedEntity(value);
        }}
      >
        {entities?.map((entity) => (
          <Option key={entity}>{entity}</Option>
        ))}
      </Select>
    </CommonModal>
  );
};

export default EditAddEditEntityModal;
