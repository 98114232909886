import React, { useMemo } from "react";
import { Alert, Divider, Space, Tooltip } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
import { withTheme } from "@rjsf/core";
import { Theme as AntDTheme } from "@rjsf/antd";
import {
  handleLongString,
  openInNewTab,
} from "../../../../shared/commonFunctions";
import {
  StyledButton,
  CommonModal,
  StyledTag,
  StyledTitle,
  FormWrapper,
} from "../../../../shared/commonStyles";

const Form = withTheme(AntDTheme);

const ESGTemplateFormModal = ({ visible, rowData, onClose }) => {
  const schema = useMemo(() => rowData?.form_json_code, [rowData]);
  const uiSchema = useMemo(() => rowData?.form_ui_code, [rowData]);

  if (!rowData) return <></>;
  return (
    <CommonModal
      title={rowData?.name}
      visible={visible}
      onCancel={onClose}
      width={800}
      maskClosable={false}
      destroyOnClose
      centered
      footer={
        <div className="flex-content-justify-center">
          <StyledButton
            key="1"
            className="width-60"
            type="custom"
            onClick={() => onClose()}
          >
            Back
          </StyledButton>
        </div>
      }
    >
      <Divider>
        <StyledTitle level={4}>Information</StyledTitle>
      </Divider>
      <Space direction="vertical">
        <Alert message={rowData.description} type="info" showIcon />
        <div>
          {rowData.standardLink.map((val, index) => (
            <Tooltip title={val.name} key={index}>
              <StyledTag
                onClick={() => openInNewTab(val.url)}
              >
                Link to {`"${handleLongString(val.name, 20)}"`}
                {val.page_num ? `, Page No: ${val.page_num}` : ""}
              </StyledTag>
            </Tooltip>
          ))}
        </div>
      </Space>
      <Divider>
        <StyledTitle level={4}>Data</StyledTitle>
      </Divider>
      <Space direction="vertical" style={{ width: "100%" }}>
        <FormWrapper>
          {/* {Object.keys(rowData?.form_json_code).length > 0 ? (
            <Form formData={{}} uiSchema={uiSchema} schema={schema} />
          ) : ( */}
            <div className="ckSmallHeight">
              <CKEditor
                editor={DecoupledDocumentEditor}
                data=""
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  if (editor?.ui) {
                    editor.ui.view.editable.element.parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.view.editable.element
                    );
                  }
                }}
              />
            </div>
          {/* )} */}
        </FormWrapper>
      </Space>
    </CommonModal>
  );
};

export default ESGTemplateFormModal;
