import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Card, Spin, message, InputNumber, Divider, Space } from "antd";
import { useParams, } from "react-router-dom";
import * as queries from '../../../../graphql/queries';
import { API } from 'aws-amplify';
import { PageWrap, StyledButton } from "./../../../../shared/commonStyles";
import { getToFixedNumber, numberWithCommas } from '../../../../shared/commonFunctions';
import { RetweetOutlined } from "@ant-design/icons"
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';

const { Title, Text } = Typography;


const ProductOrderPage = () => {
    const [projectDetails, setProjectDetails] = useState(null);
    const [loadingProjectDetails, setLoadingProjectDetails] = useState(false);
    const [calculations, setCalculations] = useState({
        totalAmount: 0,
        dollarsInput: 0,
        tonnesInput: 0
    });
    const [submittingOrder, setSubmittingOrder] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { id } = useParams();

    const loadProductDetails = async (id) => {
        try {
            setLoadingProjectDetails(true);
            const response = await API.graphql({
                query: queries["decarbonization"], variables: {
                    request_type: "project_details",
                    request_payload: JSON.stringify({ project_id: id })
                }
            });
            const details = JSON.parse(response['data']['decarbonization']);
            if (details) {
                setProjectDetails(details);
            }
        }
        catch (error) {
            console.log("error", error);
            message.error("Error while load details!");
        } finally {
            setLoadingProjectDetails(false);
        }
    }

    useEffect(() => {
        if (id) {
            loadProductDetails(id);
        }
    }, [id])


    const onConfirmCreateOrder = async () => {
        try {
            setSubmittingOrder(true);
            const response = await API.graphql({ query: queries["decarbonization"], variables: { request_type: "create_order", request_payload: JSON.stringify({ project_id: id, total_amount: totalAmount }) } });
            const createdOrder = JSON.parse(response['data']['decarbonization']);
            if (createdOrder) {
                message.success("Order created Successfully!");

                setShowConfirmModal(false);
            }
        }
        catch (error) {
            console.log("Error while creating order!", error);
            message.error("Error while creating order!");
        } finally {
            setSubmittingOrder(false);
        }
    }


    const onChangeInputs = (value, name) => {
        try {
            if (name === "dollarsInput") {
                let dollarInputValue = value > 0 ? getToFixedNumber(value) : 0;
                dollarInputValue = parseFloat(dollarInputValue) > parseFloat(maxDollars) ? parseFloat(maxDollars) : parseFloat(dollarInputValue);                
                const calculatedTonnes = ((1 / price) * dollarInputValue)?.toFixed(2);
                const data = {
                    [name]: parseFloat(dollarInputValue),
                    tonnesInput: parseFloat(calculatedTonnes),
                    totalAmount: parseFloat(dollarInputValue)
                }
                setCalculations(data);
            } else if (name === "tonnesInput") {
                let tonnesInputValue = value > 0 ? getToFixedNumber(value) : 0;                
                tonnesInputValue = parseFloat(tonnesInputValue) > parseFloat(tonnes) ? parseFloat(tonnes) : parseFloat(tonnesInputValue);                
                const dollars = (tonnesInputValue * price)?.toFixed(2);
                const total = dollars;
                const data = {
                    [name]: parseFloat(tonnesInputValue),
                    dollarsInput: parseFloat(dollars),
                    totalAmount: parseFloat(total)
                }
                setCalculations(data);
            } else {

            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const productName = projectDetails?.name ? projectDetails?.name : "";
    const remainingMassG = projectDetails?.remaining_mass_g ? projectDetails?.remaining_mass_g : 0;
    const price = projectDetails?.average_price_per_tonne_cents_usd ? projectDetails?.average_price_per_tonne_cents_usd / 100 : 0;
    const tonnes = getToFixedNumber((remainingMassG / 1000000));
    const maxDollars = getToFixedNumber((tonnes * price));
    
    const { tonnesInput, totalAmount, dollarsInput } = calculations;    

    return (
        <PageWrap>
            {showConfirmModal && <ConfirmModal
                title="Confirmation"
                description="Are you sure you want to place the order? This is irreversible and your company will be billed for the order placed."
                visible={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={() => onConfirmCreateOrder()}
                ConfirmLoader={submittingOrder}
            />}
            <Spin spinning={loadingProjectDetails}>
               
                
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title={productName}
                            actions={[
                                <div key="Create order" style={{ padding: 10 }}>
                                    <StyledButton style={{ width: "100%" }} type="custom" onClick={() => setShowConfirmModal(true)}>
                                        Create order
                                    </StyledButton>
                                </div>
                            ]}
                        >
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Row>
                                    <Col span={24}>
                                        <Title level={5}>Vintage</Title>
                                        <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#f4f3f3", padding: 15, border: "1px solid lightgrey" }}>
                                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                                <span style={{ fontWeight: "600" }} className='margin-right-5'>{numberWithCommas(tonnes)}</span>
                                                <span style={{ fontSize: 10, color: "grey" }}>TOTAL OFFSETS AVAILABLE</span>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                                <span style={{ fontWeight: "600" }} className='margin-right-5'>${numberWithCommas(price)}</span>
                                                <span style={{ fontSize: 10, color: "grey" }}>PER TONNE</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Title level={5}>Amount</Title>
                                        <Text type="default">{`The amount of carbon you'd like to offset.`}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div>
                                            <p>Enter Dollar Amount:</p>
                                            <InputNumber
                                                type="number"
                                                value={dollarsInput}
                                                style={{ width: "100%", backgroundColor: "#f4f3f3", padding: 8, border: "1px solid lightgrey" }}
                                                size="large"
                                                placeholder=""
                                                prefix={"$"}
                                                onChange={(value) => onChangeInputs(value, "dollarsInput")}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <RetweetOutlined style={{ fontSize: 30, marginTop: 30 }} />
                                    </Col>
                                    <Col span={11}>
                                        <div >
                                            <p>Select Offset Amount (Tonnes)</p>
                                            <InputNumber
                                                type="number"
                                                style={{ width: "100%", backgroundColor: "#f4f3f3", padding: 8, border: "1px solid lightgrey" }}
                                                size="large"
                                                placeholder=""
                                                suffix="Tonnes"
                                                value={tonnesInput}
                                                onChange={(value) => onChangeInputs(value, "tonnesInput")}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Space>
                            <Divider style={{ margin: 0 }} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </PageWrap>
    )
}
export default ProductOrderPage;
