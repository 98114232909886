import React from "react";
import {CommonModal} from "../../../../shared/commonStyles";
import { Typography } from "antd";
const Text = Typography;
const ConfirmArchiveModal = ({isVisible,onClose,onConfirm,description,confirmLoading,status}) => {
  return (
    <>
      <CommonModal
        title="Confirmation"
        visible={isVisible}
        okText= {status==="INACTIVE" ? "Archive" : "Restore"}
        onOk={onConfirm}
        onCancel={onClose}
        confirmLoading={confirmLoading}
      >
        <Text> {description}</Text>
      </CommonModal>
    </>
  );
};

export default ConfirmArchiveModal;
