import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { API } from 'aws-amplify';
import { useReactToPrint } from 'react-to-print';
import * as queries from '../../../graphql/queries';
import { sdgGoalsStaticList } from '../../../shared/constants';
import { NewCompany } from './NewCompany';
import { ReportTemplate } from './ReportTemplate';
import { ModalWithStyles, TableWithStyles } from '../../../shared/commonStyles';
import { ReportTemplateModal, StyledReportCompanies } from './styles';

const { Column } = Table;

export const NewReport = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [toPrint, setToPrint] = useState(null);
  const [editCompany, setEditCompany] = useState(null);
  const exportHtmlRef = useRef();

  const printPdf = useReactToPrint({
    content: () => exportHtmlRef.current,
  });

  const loadData = async () => {
    try {
      const response = await API.graphql({
        query: queries['datalake'],
        variables: {
          request_type: 'list_portfolio_companies',
        },
      });

      const data = await JSON.parse(response['data']['datalake']);
      setCompanies(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handlePreview = (company) => () => {
    setModalVisible2(true);
    setSelectedCompany(company);
  };

  const handlePrint =
    (content = [selectedCompany]) =>
    async () => {
      await setToPrint(content);
      await printPdf();
    };

  const handleEdit = (id) => async () => {
    await setEditCompany(companies.find((el) => el._id?.$oid == id));
    await setModalVisible(true);
  };

  const afterAdding = () => {
    setModalVisible(false);
    setEditCompany(null);
    loadData();
  };
  const modal = useMemo(
    () => (
      <ModalWithStyles
        centered
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditCompany(null);
        }}
        footer={null}
        destroyOnClose={true}
      >
        <NewCompany initValue={editCompany} afterAdding={afterAdding} />
      </ModalWithStyles>
    ),
    [modalVisible],
  );

  const reportPdfModal = useMemo(() => {
    return (
      <ReportTemplateModal
        centered
        visible={modalVisible2}
        onOk={() => setModalVisible2(false)}
        onCancel={() => setModalVisible2(false)}
        footer={null}
        destroyOnClose={true}
      >
        <>
          <div className="report-template-header">
            <span>Preview</span>
            <div>
              <Button
                onClick={() => {
                  setModalVisible2(false);
                  handleEdit(selectedCompany?._id?.$oid)();
                }}
              >
                Edit
              </Button>
              <Button onClick={handlePrint([selectedCompany])}>Download</Button>
            </div>
          </div>
          <div>
            <ReportTemplate rowData={selectedCompany} />
          </div>
        </>
      </ReportTemplateModal>
    );
  }, [modalVisible2, selectedCompany]);

  const toTableStructure = useCallback(() => {
    if (!companies) return null;

    return companies.map(({ company_name, founded_year, goals, ...rest }) => {
      if (!goals) return [];

      const newGoals = goals.map((item) => {
        const fullGoal = sdgGoalsStaticList.find((element) => element.goal == item.goal);
        return {
          ...item,
          image_url: fullGoal.image_url,
        };
      });

      return {
        ...rest,
        goals: newGoals,
        name: company_name,
        year: founded_year,
      };
    });
  }, [companies]);

  const tableCompanies = toTableStructure();
  return (
    <StyledReportCompanies>
      <Row justify="end" gutter={20}>
        {/* <Col>
          <Button onClick={() => setModalVisible(true)}>Add Company</Button>
        </Col> */}
        <Col>
          <Button onClick={handlePrint(tableCompanies)}>Download Portfolio</Button>
        </Col>
      </Row>
      {modal}
      {reportPdfModal}
      {companies && (
        <TableWithStyles dataSource={tableCompanies} pagination={false}>
          <Column title="Name" dataIndex="name" />
          <Column title="Year" dataIndex="year" />
          <Column
            title="SDGs"
            dataIndex="goals"
            render={(_, record) => {
              return (
                <div className="sdgs-row">
                  {record.goals?.map((el,index) => (
                    <img src={el.image_url} key={index} />
                  ))}
                </div>
              );
            }}
          />
          <Column
            title=""
            key="action"
            render={(_, record) => (
              <div className="report-table__controls">
                <Button type="primary" onClick={handlePrint([record])}>
                  Download
                </Button>
                <Button onClick={handlePreview(record)}>Preview</Button>
                <Button onClick={handleEdit(record?._id?.$oid)}>Edit</Button>
              </div>
            )}
          />
        </TableWithStyles>
      )}
      <div style={{ display: 'none' }}>
        <div ref={exportHtmlRef}>
          {toPrint && toPrint.map((el) => <ReportTemplate key="Report Template" rowData={el} />)}
        </div>
      </div>
    </StyledReportCompanies>
  );
};
