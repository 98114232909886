import React, { useState } from "react";
import { Col, Radio, Row } from "antd";
import {
  CommonRadio,
  FormWrapper,
  PageWrap,
  TabContentWrap,
} from "../../shared/commonStyles";
import OverviewGoals from "./components/overviewGoals";
import GeneralGoals from "./components/generalGoals";
import SustainableGoals from "./components/sustainableGoals";
import DevelopmentRecommendations from "./components/developmentRecommendations";

const GoalsAndProgressPageNew = () => {
  const [section, setSection] = useState("overview");
  const onChange = (e) => {
    setSection(e.target.value);
  };
  return (
    <>
      <PageWrap>
        <FormWrapper>
          <Row gutter={[16, 18]} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={24} md={24} lg={17} style={{ marginTop: "5px" }}>
              <TabContentWrap>
                
                <Radio.Group
                  value={section}
                  onChange={onChange}
                  style={{ marginBottom: 16 }}
                  buttonStyle="solid"
                  size="large"
                >
                  <CommonRadio value="overview">Overview</CommonRadio>
                  <CommonRadio value="general_goals">
                    General Goals
                  </CommonRadio>
                  <CommonRadio value="sustainable_goals">
                    Sustainable Development Goals
                  </CommonRadio>
                  <CommonRadio value="development_recommendations">
                    Development Recommendations
                  </CommonRadio>
                </Radio.Group>
              </TabContentWrap>
            </Col>
          </Row>
        <Row>
          <Col lg={24} sm={24} md={24} xs={24}>
            {section === "overview" && <OverviewGoals />}
            {section === "general_goals" && <GeneralGoals />}
            {section === "sustainable_goals" && <SustainableGoals />}
            {section === "development_recommendations" && <DevelopmentRecommendations />}
          </Col>
        </Row>
        </FormWrapper>
      </PageWrap>
    </>
  );
};

export default GoalsAndProgressPageNew;
