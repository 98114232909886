import React, { useState } from "react";
import { Button, Drawer, Row, Space, Typography, Col } from "antd";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../shared/commonStyles";
import KpiFilesModal from "../PendingKPIs/KpiFilesModal";
import { NotesDrawer } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/NotesDrawer";
import { useBoolean } from "ahooks";
import { encodeUrlName } from "../../../shared/commonFunctions";
import { convertFiltersList } from "../../../services/dataSheet";
const { Title } = Typography;
const KpiDetailCard = ({
  visible,
  onClose,
  rowData,
  handleAddNote,
  handleUpdateNote,
}) => {
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [
    isFieldNotesDrawerShown,
    { setTrue: showFieldNotesDrawer, setFalse: hideFieldNotesDrawer },
  ] = useBoolean(false);
  return (
    <>
      {showFilesModal && (
        <KpiFilesModal
          visible={showFilesModal}
          rowData={rowData}
          onClose={() => setShowFilesModal(false)}
          isViewOnly
        />
      )}
      <NotesDrawer
        visible={isFieldNotesDrawerShown}
        data={[]}
        notes={rowData?.notes || []}
        loading={false}
        onClose={() => {
          hideFieldNotesDrawer();
        }}
        onSubmit={(text) => handleAddNote(text, rowData)}
        onUpdateNotes={(notes) => handleUpdateNote(notes, rowData)}
        isFieldNote={false}
        isViewOnly={false}
      />
      <Drawer
        className="drawerCustom"
        title={rowData ? rowData.name : ""}
        width={400}
        onClose={onClose}
        visible={visible}
        placement="right"
        footerStyle={{ background: "#2D273F", borderTop: "none" }}
        footer={
          <Space>
            <StyledButton
              className="margin-5"
              type="custom"
              hovercolor="#A68DFB"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#fff"
              onClick={onClose}
            >
              Close
            </StyledButton>
            <StyledButton
              type="custom"
              className="color-white"
              onClick={onClose}
            >
              OK
            </StyledButton>
            {rowData && (
              <Button style={{ color: "#A68DFB" }} type="link" block>
                <Link
                  to={`/data/data-sheets/${rowData.sheetId}/${encodeUrlName(
                    rowData.sheetData.sheet_name
                  )}/documents?kpi=${rowData._id.$oid}`}
                  target="_blank"
                  onClick={() => {
                    (rowData.conditional_filters?.length ||
                      rowData.filters?.length) &&
                      localStorage.setItem(
                        "conditional_filters",
                        JSON.stringify(
                          rowData.conditional_filters?.length
                            ? rowData.conditional_filters
                            : convertFiltersList(
                                rowData.filters,
                                rowData.sheetData
                              )
                        )
                      );
                  }}
                >
                  View Data Sheet
                </Link>
              </Button>
            )}
          </Space>
        }
      >
        {rowData && (
          <Row justify="space-between">
            <Col span={24}>
              <Title className="color-white" level={3}>
                {rowData.name}
              </Title>
            </Col>
            <Col span={24}>
              <Title className="color-white" level={5}>
                Description
              </Title>
              <p className="color-white">{rowData.description}</p>

              <Title className="color-white" level={5}>
                Datasheet field
              </Title>
              <p className="color-white">{rowData.aggregateColumnLabel}</p>

              <Title className="color-white" level={5}>
                Aggregation operator
              </Title>
              <p className="color-white">{rowData.aggregateOpLabel}</p>

              <Title className="color-white" level={5}>
                Datasheet name
              </Title>
              <p className="color-white">{rowData.sheetData.sheet_name}</p>
              <Title className="color-white" level={5}>
                Datasheet type
              </Title>
              <p className="color-white">{rowData.sheetData.sheet_name}</p>
              {!!rowData?.technicalDetails?.length && (
                <>
                  <Title className="color-white" level={5}>
                    Technical Details
                  </Title>
                  <p className="color-white">{rowData?.technicalDetails}</p>
                </>
              )}

              <div>
                {rowData?.files?.length && (
                  <StyledButton onClick={() => setShowFilesModal(true)}>
                    Files
                  </StyledButton>
                )}

                <StyledButton onClick={() => showFieldNotesDrawer()}>
                  Notes
                </StyledButton>
              </div>
            </Col>
          </Row>
        )}
      </Drawer>
    </>
  );
};

export default KpiDetailCard;
