import { Button, Row } from "antd";
import React from "react";
import { StyledButton } from "../../../shared/commonStyles";
import { isLessSevenDays } from "../MassImportFunctions";

interface ImportState {
  importId: string;
  action: string;
}
interface ImportFooterProps {
  currentStep: number;
  goBack: () => void;
  goNext: () => void;
  onUndo: () => void;
  isBaseFile: boolean;
  state?: ImportState;
  importData?: DataImport;
}

export const ImportFooter = ({
  currentStep,
  goBack,
  goNext,
  onUndo,
  isBaseFile,
  state,
  importData,
}: ImportFooterProps) => {
  return (
    <Row
      justify="start"
      align="middle"
      gutter={[8, 8]}
      style={{ marginTop: "20px" }}
    >
      <Button type="text" onClick={goBack}>
        Go back
      </Button>
      <StyledButton
        type="custom"
        onClick={goNext}
        disabled={
          (currentStep === 0 && !isBaseFile) ||
          (currentStep === 1 && state?.action === "update")
        }
      >
        {currentStep === 0 ? "Upload" : "Confirm"}
      </StyledButton>
      {currentStep === 1 &&
        importData?.import_status === "APPROVED" &&
        isLessSevenDays(importData?.createDate?.$date!) && (
          <StyledButton type="custom" onClick={onUndo}>
            Undo
          </StyledButton>
        )}
    </Row>
  );
};
