import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message, Spin, Row } from "antd";
import { DataSheetDocuments } from "./DataSheetDocuments";
import { PageWrap } from "../../../shared/commonStyles";
import {
  getDataSheetsWithPagination,
  getSheetById,
  metadataSheetTypesList,
  is_reference_sheet,
} from "./../../../services/dataSheet";
import { Auth } from "aws-amplify";
import { MetaDataDocuments } from "./MetaDataDocuments";

const DataSheetDocumentsPage = ({
  isDeleted,
  isPending,
  isDenied,
  isGeneral,
}) => {
  const [dataSheet, setDataSheet] = useState();
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  const [loadingDataSheetsList, setLoadingDataSheetsList] = useState(false);
  const [dataSheetsList, setDataSheetsList] = useState();
  const { data_sheet_id } = useParams();
  const navigate = useNavigate();
  const makeQuery = (group) => {
    return {
      filter: {
        archive: false,
        group: group,
        is_reference_data: false,
      },
      withPending: true,
    };
  };
  const loadDataSheets = async () => {
    setLoadingDataSheetsList(true);
    try {
      const data = await Auth.currentSession();
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const requestPayload = makeQuery(group);
      const sheetRecords = await getDataSheetsWithPagination(requestPayload);
      const dataSheets = sheetRecords.data;
      setDataSheetsList(dataSheets);
    } catch (error) {
      message.error("Something went wrong while loading data sheets!");
    } finally {
      setLoadingDataSheetsList(false);
    }
  };

  useEffect(() => {
    !!isGeneral && loadDataSheets();
  }, []);

  const loadDataSheet = async (dataSheetid, filterField) => {
    try {
      setLoadingDataSheet(true);
      const _dataSheet = await getSheetById(dataSheetid);
      setDataSheet(_dataSheet);
      !isGeneral && data_sheet_id && setDataSheetsList([_dataSheet]);

      _dataSheet?.statusCode &&
        message.error(_dataSheet?.body) &&
        navigate("/data/data-sheets");
    } catch (error) {
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };

  useEffect(() => {
    if (data_sheet_id) {
      const dataID = data_sheet_id.endsWith("}")
        ? data_sheet_id.slice(0, -1)
        : data_sheet_id;
      loadDataSheet(dataID);
    }
  }, [data_sheet_id]);

  if (loadingDataSheet) {
    return (
      <PageWrap>
        <Row justify="center">
          <Spin spinning={loadingDataSheet} />
        </Row>
      </PageWrap>
    );
  }

  if (!dataSheet && !isGeneral) {
    return (
      <PageWrap>
        <Row justify="center">
          <span>No record found!</span>
        </Row>
      </PageWrap>
    );
  }

  if (
    !isGeneral &&
    dataSheet &&
    is_reference_sheet(dataSheet)
  ) {
    return (
      <MetaDataDocuments
        dataSheet={dataSheet}
        loadingDataSheet={loadingDataSheet}
        isDeleted={isDeleted}
      />
    );
  } else {
    return (
      <DataSheetDocuments
        dataSheet={dataSheet}
        loadingDataSheet={loadingDataSheet}
        loadingDataSheetsList={loadingDataSheetsList}
        loadDataSheet={loadDataSheet}
        setDataSheet={setDataSheet}
        isDeleted={isDeleted}
        isPending={isPending}
        isDenied={isDenied}
        isGeneral={isGeneral}
        dataSheetsList={dataSheetsList}
      />
    );
  }
};

export default DataSheetDocumentsPage;
