import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import { CommonModal, FormWrapper } from "../../../shared/commonStyles";

import { YearMonthDayFormat } from "../../../shared/constants";
import moment from "moment";

interface InputProps {
  name: string;
  rules: {
    required: boolean;
    message: string;
  }[];
  style: {
    width: string;
  };
  valuePropName?: string;
}

interface InputPlaceholderModalProps {
  visible: boolean;
  dataSheet: any;
  onClose: () => void;
  onConfirm: (fields: any) => void;
}

export const InputPlaceholderModal = ({
  visible,
  dataSheet,
  onClose,
  onConfirm,
}: InputPlaceholderModalProps) => {
  const [form] = Form.useForm();

  const handleConfirm = () => {
    const formValue = form.getFieldsValue();
    const datevalue = Object.entries(formValue).map(([key, value]) => ({
      [key]: { $date: moment(value as Date) },
    }));
    dataSheet.input_type == "date"
      ? onConfirm(datevalue[0])
      : onConfirm(formValue);

    onClose();
  };

  const dynamicFormInputs = (item: any) => {
    let inputNode = null;
    let inputProps: InputProps = {
      name: item.entity_name,
      rules: [{ required: item.required, message: `This field is required!` }],
      style: { width: "100%" },
    };
    switch (item?.input_type) {
      case "number":
        inputNode = <InputNumber lang="en" style={{ width: 150 }} />;
        break;
      case "date":
        inputNode = (
          <DatePicker style={{ width: 150 }} format={YearMonthDayFormat} />
        );
        break;
      case "string":
        inputNode = <Input />;
        break;
      case "dropdown":
        inputNode = (
          <Select style={{ width: 150 }}>
            {item?.options?.map((item: any) => (
              <Select.Option key={item?.value} value={item?.value}>
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        );
        break;
      case "radio":
        inputNode = (
          <Radio.Group>
            {item?.options?.map((item: any) => {
              return (
                <Radio key={item?.value} value={item?.value}>
                  {item?.label}
                </Radio>
              );
            })}
          </Radio.Group>
        );
        break;
      case "checkbox":
        if (item?.is_checkbox_group) {
          inputNode = (
            <Checkbox.Group>
              <Row>
                {item?.options?.map((item: any, index: number) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={24} key={index}>
                      <Checkbox key={item?.value} value={item?.value}>
                        {item?.label}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          );
        } else {
          inputProps = { ...inputProps, valuePropName: "checked" };
          inputNode = <Checkbox>{item.display_name}</Checkbox>;
        }
        break;
      case "long-answer":
        inputNode = <Input.TextArea style={{ height: 100 }} />;
        break;
      default:
        inputNode = null;
        break;
    }

    return (
      <Row gutter={[16, 16]}>
        <Col span={item.unit_id ? 14 : 24}>
          <Form.Item
            labelCol={{ span: 24 }}
            label={item.display_name}
            {...inputProps}
          >
            {inputNode}
          </Form.Item>
        </Col>
        {item.unit_id && (
          <Col span={10}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={item.display_name + " Unit"}
              name={item.entity_name + "_unit_id"}
              initialValue={item.unit_id}
              rules={[{ required: true, message: "Please select a Unit!" }]}
            >
              <Select placeholder="Unit">
                {item.convertable_units?.map(
                  (unit: { unit: string; name: string }, i: number) => {
                    return (
                      <Select.Option key={unit.unit + i} value={unit.unit}>
                        {unit.name}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <CommonModal
        visible={visible}
        title={dataSheet.display_name}
        onOk={() => handleConfirm()}
        onCancel={onClose}
      >
        <FormWrapper>
          <Form form={form}>{dynamicFormInputs(dataSheet)}</Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};
