import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Spin, message, Row, Typography, Space, Tooltip, Button } from "antd";
import { Auth } from "aws-amplify";
import {
  FileAddOutlined,
  FileSearchOutlined,
  FilterOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  StyledButton,
  TableWrap,
  CommonTable,
  TableHeaderRightButtonWrap,
} from "../../../../shared/commonStyles";
import _ from "lodash";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import SheetFilesModal from "../../../../components/DataSheetDocuments/SheetFilesModal/SheetFilesModal";

import { HistoryDataSection } from "../../../dataSheetsPage/dataSheetDocumentsPage/Components/HistoryDataSection";

import {
  getSheetById,
  fetchRecordData,
  makeQuery,
  convertFiltersList,
} from "./../../../../services/dataSheet";
import { updateApprovedData } from "./../../../../services/dataSheetAudit";
import ApproveStatus from "./Components/ApproveStatus";
import { uploadDataSheetAuditFile } from "./../../../../services/dataSheet";
import { FiltersModal } from "./Components/FiltersModal";
import AuditDetail from "../../../auditDataSheetsPage/auditDataSheetDocuments/Components/AuditDetail";

const { Title } = Typography;

const DataSheetAudit = ({ auditRecord }) => {
  if (!auditRecord) return;
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [dataSheet, setDataSheet] = useState();
  const [search, setSearch] = useState({});
  const [loadingDataSheet, setLoadingDataSheet] = useState(false);
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const [orgDataSheetRecords, setOrgDataSheetRecords] = useState([]);
  const [dataSheetRecords, setDataSheetRecords] = useState([]);
  const [sorter, setSorter] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [auditData, setAuditData] = useState(null);
  const [showDataSheetFilesModal, setShowDataSheetFilesModal] = useState(false);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [latestRecord, setLatestRecord] = useState(null);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [isOtherButtonsDisabled, setIsOtherButtonsDisabled] = useState(false);
  const onClickDataSheetFileBtn = (record) => {
    setRowData(record);
    setShowDataSheetFilesModal(true);
  };

  const loadDataSheet = async (dataSheetid) => {
    setLoadingDataSheet(true);
    try {
      const _dataSheet = await getSheetById(dataSheetid);
      if (_dataSheet) {
        setDataSheet(_dataSheet);
      }
    } catch (error) {
      message.error("Something went wrong while loading data sheet!");
    } finally {
      setLoadingDataSheet(false);
    }
  };

  const recordTypes = {
    include: [],
    exclude: ["archived", "imported_pending"],
  };

  const loadDataSheetRecords = async (params) => {
    try {
      setLoadingSheetRecords(true);
      const { pagination, ...search } = params;
      const requestPayload = makeQuery(
        { ...params, conditionalFilters: params.conditional_filters },
        dataSheet,
        recordTypes
      );

      const sheetRecords = await fetchRecordData(requestPayload);
      const _dataSheetRecords = await Promise.all(
        await sheetRecords.data.map(async (record) => {
          const first_last_name = await getFirstAndLastNameByUsername(
            record.username
          );
          return {
            ...record,
            key: record._id.$oid,
            first_last_name: first_last_name,
          };
        })
      );
      setOrgDataSheetRecords(_dataSheetRecords);
      setPagination({ ...pagination, total: sheetRecords.filteredCount });
      setSearch(search);
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading data sheet records!");
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  const updateDataSheetRecords = () => {
    if (!dataSheet) {
      return;
    }

    const schemas = {};
    dataSheet.sheet_schema.forEach((schema) => {
      schemas[schema.entity_name] = schema.unit_id;
    });

    const tempRecords = orgDataSheetRecords.map((record) => {
      const records = record[record.sheet_name];
      let temp = {};

      Object.keys(records || {}).forEach((key) => {
        if (schemas[key]) {
          temp[`${key}_unit_id`] = schemas[key];
        }
      });

      temp = {
        ...temp,
        ...records,
      };

      return {
        ...record,
        [record.sheet_name]: temp,
      };
    });
    setDataSheetRecords(tempRecords);
  };
  useEffect(() => {
    if (auditRecord?.audit_items) {
      const sheetId = JSON.parse(auditRecord?.audit_items)[0];
      loadDataSheet(sheetId);
    }
  }, [auditRecord]);

  useEffect(() => {
    if (dataSheet) {
      if (auditRecord?.audit_settings) {
        const filters = JSON.parse(auditRecord?.audit_settings)
          ?.conditional_filters?.length
          ? JSON.parse(auditRecord?.audit_settings).conditional_filters
          : convertFiltersList(
              JSON.parse(auditRecord?.audit_settings),
              dataSheet
            );
        if (filters?.length) {
          setSearch({ conditional_filters: filters });
          loadDataSheetRecords({
            pagination,
            conditional_filters: filters,
            sorter,
          });
        }
      } else loadDataSheetRecords({ pagination, ...search, sorter });
    }
  }, [dataSheet]);

  useEffect(() => {
    if (dataSheet) {
      updateDataSheetRecords();
    }
  }, [dataSheet, orgDataSheetRecords]);

  const onClickAuditDetail = (record) => {
    setAuditData(record);
    setShowDetailsDrawer(true);
  };

  const onCancelHandler = (record) => {
    setDataSheetRecords((prevItems) =>
      prevItems.map((item) => {
        if (item?._id?.$oid === record?._id?.$oid) {
          return latestRecord;
        }
        return item;
      })
    );
    setLatestRecord(null);
  };

  const onChangeHistoryHandler = (record, historyData) => {
    try {
      const payload = { ...record };

      payload[historyData?.sheet_name] = historyData[historyData?.sheet_name];
      payload[historyData?.sheet_name + " Audit"] =
        historyData[historyData?.sheet_name + " Audit"];
      payload["revision"] = historyData?.revision;

      setDataSheetRecords((prevItems) =>
        prevItems.map((item) => {
          if (item?._id?.$oid === record?._id?.$oid) {
            return payload;
          }
          return item;
        })
      );
      setLatestRecord(record);
    } catch (error) {
      console.log("Error when request for edit", error);
    }
  };

  const generateDynamicColumns = (dataSheetSchema) => {
    if (dataSheetSchema?.length) {
      const columns = dataSheetSchema?.reduce((acc, item) => {
        const column = [];

        column.push({
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: 250,
          ellipsis: false,
          editable: false,
          sorter: (a, b) => {},
          onCell: (data, rowIndex) => {
            let yourValue = null;
            let revisionValue = null;
            let isRecordMatched = false;
            if (latestRecord) {
              if (data?._id.$oid === latestRecord?._id?.$oid) {
                isRecordMatched = true;
                yourValue = latestRecord
                  ? latestRecord[latestRecord?.sheet_name][item?.entity_name]
                  : "";
                revisionValue = data
                  ? data[data?.sheet_name][item?.entity_name]
                  : "";
              }
            }
            return {
              className:
                isRecordMatched && yourValue !== revisionValue
                  ? "dataSheetBorder"
                  : "",
            };
          },
          render: (_, data) => {
            let yourValue = null;
            let revisionValue = null;
            let isRecordMatched = false;
            if (latestRecord) {
              if (data?._id.$oid === latestRecord?._id?.$oid) {
                isRecordMatched = true;
                yourValue = latestRecord
                  ? latestRecord[latestRecord?.sheet_name][item?.entity_name]
                  : "";
                revisionValue = data
                  ? data[data?.sheet_name][item?.entity_name]
                  : "";
              }
            }
            try {
              let newValue = null;
              if (item?.input_type === "date") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                newValue =
                  value === "" || value === undefined || value === null
                    ? ""
                    : moment.utc(value).format(YearMonthDayFormat);
              } else if (item?.input_type === "checkbox") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                if (Array.isArray(value)) {
                  newValue = value?.toString();
                } else {
                  newValue = value ? "TRUE" : "FALSE";
                }
              } else {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                newValue = value ? value : null;
              }
              return (
                <>
                  {isRecordMatched ? (
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <>
                          {revisionValue && (
                            <>
                              <b>Revision {data?.revision} Value</b>
                              <br />
                            </>
                          )}
                          {revisionValue}
                          <br />
                          {yourValue && (
                            <>
                              <b>Your Value</b>
                              <br />
                              {yourValue}
                            </>
                          )}
                        </>
                      }
                    >
                      {newValue}
                    </Tooltip>
                  ) : (
                    <>{newValue}</>
                  )}
                </>
              );
            } catch (error) {
              console.log("error", error);
              return "";
            }
          },
        });

        if (item.unit_id) {
          column.push({
            title: item.display_name + " Unit",
            dataIndex: item.entity_name + "_unit_id",
            width: "100px",
            editable: false,
            render: (_, data) => {
              try {
                const value =
                  data?.[data?.sheet_name]?.[item.entity_name + "_unit_id"];
                return value ? value : null;
              } catch (error) {
                console.log("error", error);

                return "";
              }
            },
          });
        }
        return acc.concat(column);
      }, []);

      columns.push({
        title: "Action",
        dataIndex: "action",
        fixed: "right",
        width: "50px",
        render: (_, record) => {
          return latestRecord &&
            record?.["_id"]?.["$oid"] === latestRecord?.["_id"]["$oid"] ? (
            <>
              <StyledButton
                type="default"
                onClick={() => onCancelHandler(record)}
                icon={<LoginOutlined />}
              >
                Go to current version
              </StyledButton>
            </>
          ) : (
            <>
              <Space direction="horizontal">
                <StyledButton
                  disabled={isOtherButtonsDisabled}
                  type="default"
                  onClick={() => onClickDataSheetFileBtn(record)}
                  icon={<FileAddOutlined />}
                >
                  <span>Files</span>
                </StyledButton>
                <HistoryDataSection
                  onChangeHistoryHandler={onChangeHistoryHandler}
                  rowData={record}
                  buttonText="History"
                  setIsOtherButtonsDisabled={setIsOtherButtonsDisabled}
                />
                <StyledButton
                  type="default"
                  onClick={() => onClickAuditDetail(record)}
                  icon={<FileSearchOutlined />}
                  disabled={isOtherButtonsDisabled}
                >
                  <span>Audit details</span>
                </StyledButton>
                <ApproveStatus
                  isOtherButtonsDisabled={isOtherButtonsDisabled}
                  rowData={record}
                  submitting={submitting}
                  submitSignoffStatus={submitSignoffStatus}
                  refreshUploadedFiles={refreshUploadedFiles}
                />
              </Space>
            </>
          );
        },
      });

      return columns;
    }
    return [];
  };

  const generateColumns = generateDynamicColumns(dataSheet?.sheet_schema);

  const handleTableChange = (pagination, filters, sorter) => {
    setSorter(sorter);
    loadDataSheetRecords({ pagination, ...search, sorter });
  };

  const uploadFile = async (file, dataId) => {
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      const signedURL = await uploadDataSheetAuditFile(dataId, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
            setRefreshUploadedFiles((prev) => prev + 1);
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    }
  };

  const saveFiles = async (files, auditData) => {
    if (files && files.length && auditData?.id) {
      try {
        await Promise.all(
          await files.map(async (file) => {
            await uploadFile(file, auditData.id);
          })
        );
      } catch (error) {
        console.log("Something went wrong while uploading files!", error);
      }
    }
  };

  const submitSignoffStatus = async (
    record,
    comment,
    status,
    files,
    auditData
  ) => {
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      setSubmitting(true);

      saveFiles(files, auditData);
      const sheetName = record["sheet_name"];
      const dataSheetAudit = record[sheetName + " Audit"];

      const payload = {
        ...record,
        username: username,
      };
      let updatedAuditsList = null;
      if (dataSheetAudit) {
        const findIfAlreadyExist = dataSheetAudit.find(
          (item) => item.username === username
        );
        if (findIfAlreadyExist && findIfAlreadyExist?.status === status) {
          message.info(`You have already ${status} this record!`);
          setDataSheetRecords((prev) =>
            prev.map((rec) =>
              rec["_id"]["$oid"] === record["_id"]["$oid"]
                ? { ...rec, ...payload }
                : rec
            )
          );
          return "success";
        } else if (
          findIfAlreadyExist &&
          findIfAlreadyExist?.status !== status
        ) {
          updatedAuditsList = dataSheetAudit.map((item) => {
            if (item.username === findIfAlreadyExist.username) {
              return {
                ...item,
                audit_id: auditRecord?.id,
                status: status,
                comment: comment,
                last_modified: moment(new Date(), YearMonthDayFormat),
                id: item?.id ? item.id : uuidv4(),
              };
            } else {
              return item;
            }
          });
        } else {
          updatedAuditsList = [
            ...dataSheetAudit,
            {
              audit_id: auditRecord?.id,
              record_id: record["_id"]["$oid"],
              username: username,
              status: status,
              comment: comment,
              last_modified: moment(new Date(), YearMonthDayFormat),
              id: uuidv4(),
            },
          ];
        }
      } else {
        updatedAuditsList = [
          {
            audit_id: auditRecord?.id,
            record_id: record["_id"]["$oid"],
            username: username,
            status: status,
            comment: comment,
            last_modified: moment(new Date(), YearMonthDayFormat),
            audit_revision: 1,
            id: uuidv4(),
          },
        ];
      }
      payload[sheetName + " Audit"] = updatedAuditsList;

      const updatedSheet = await updateApprovedData(payload);

      if (updatedSheet["sheet_id"]) {
        setAuditData(payload);
        setDataSheetRecords((prev) =>
          prev.map((rec) =>
            rec["_id"]["$oid"] === record["_id"]["$oid"]
              ? { ...rec, ...updatedSheet }
              : rec
          )
        );
        message.success("Record updated successfully!");
        return "success";
      } else {
        message.error("Error while updating record!");
      }
    } catch (error) {
      console.log("Error while approving data sheet record!", error);
      message.error("Error while approving data sheet record!");
    } finally {
      setSubmitting(false);
    }
    return;
  };

  const onApplyFilters = (params) => {
    const newPagination = { ...pagination, current: 1 };
    setPagination(newPagination);
    loadDataSheetRecords({ pagination: newPagination, ...params, sorter });
    setSearch(params);
  };

  const onResetFilters = () => {
    const newPagination = { ...pagination, current: 1 };
    setPagination(newPagination);
    loadDataSheetRecords({ pagination: newPagination, sorter });
    setSearch(null);
    setShowFiltersModal(false);
  };

  const onCloseAudit = () => {
    setShowDetailsDrawer(false);
    setAuditData(null);
  };

  return (
    <>
      {showDataSheetFilesModal && (
        <SheetFilesModal
          visible={showDataSheetFilesModal}
          rowData={rowData}
          onClose={() => setShowDataSheetFilesModal(false)}
          uploadEnabled={false}
        />
      )}

      <AuditDetail
        auditRecord={auditRecord}
        showDetailsDrawer={showDetailsDrawer}
        setShowDetailsDrawer={setShowDetailsDrawer}
        onClose={onCloseAudit}
        rowData={auditData}
        submitSignoffStatus={submitSignoffStatus}
        submitting={submitting}
        refreshUploadedFiles={refreshUploadedFiles}
      />

      <Spin spinning={loadingDataSheet}>
        {showFiltersModal && (
          <FiltersModal
            initialValues={search}
            visible={showFiltersModal}
            onClose={() => setShowFiltersModal(false)}
            dataSheet={dataSheet}
            onFinish={(data) => onApplyFilters(data)}
            onReset={onResetFilters}
          />
        )}
        <>
          {dataSheet ? (
            <TableWrap className="dataSheet">
              <CommonTable
                title={() => (
                  <Row justify="space-between">
                    <div style={{ display: "flex" }}>
                      <Title className="color-white" level={4}>
                        {dataSheet?.sheet_name}
                      </Title>
                      <span
                        style={{
                          color: "#C1BFCD",
                          alignItems: "baseline",
                          padding: "5px 16px",
                        }}
                      >
                        ({pagination.total})
                      </span>
                    </div>
                    <TableHeaderRightButtonWrap>
                      <Button
                        style={{ background: "none", border: "none" }}
                        type="text"
                        className="sortButton"
                        icon={<FilterOutlined />}
                        onClick={() => setShowFiltersModal(true)}
                      >
                        <span className="expandText">Filters</span>
                      </Button>
                    </TableHeaderRightButtonWrap>
                  </Row>
                )}
                loading={loadingSheetRecords}
                rowKey={(data) => {
                  const id = data?.["_id"]?.["$oid"];
                  return id;
                }}
                dataSource={dataSheetRecords}
                columns={generateColumns}
                rowClassName="dataSheetsTable"
                scroll={{ x: "max-content" }}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </TableWrap>
          ) : null}
        </>
      </Spin>
    </>
  );
};

export default DataSheetAudit;
