import { observer } from "mobx-react-lite";
import { StyledButton } from "../../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import { ExportOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { EditCustomFactor } from "./EditCustomFactor";
import { carbonStore } from "../../Carbon/CarbonStore";
import XLSX from "xlsx";
import { Row, message } from "antd";
import { excelFields } from "./CustomFactorsUtils";

export const ExportAndEditCustomFactor = observer(() => {
  const [customFactorModal, setCustomFactorModal] = useState(false);

  const getCustomList = async () => {
    carbonStore.fetchFactors(true, {}, {}, false);
  };
  useEffect(() => {
    !!carbonStore.customFactorsList?.length && onClickExportExcel();
  }, [carbonStore.customFactorsList]);

  const onClickExportExcel = async () => {
    try {
      const factorData: Partial<Record<ExcelFieldsKey, any>>[] =
        carbonStore.customFactorsList.map((item: Factor) => {
          const orderedItem: Partial<Record<ExcelFieldsKey, any>> = {};

          Object.entries(excelFields).forEach(([key, value]) => {
            if (item[value as keyof Factor] !== undefined) {
              orderedItem[key as ExcelFieldsKey] = item[value as keyof Factor];
            } else if (
              item.constituent_gases &&
              item.constituent_gases[
                value as keyof Factor["constituent_gases"]
              ] !== undefined
            ) {
              orderedItem[key as ExcelFieldsKey] =
                item.constituent_gases[
                  value as keyof Factor["constituent_gases"]
                ];
            } else {
              orderedItem[key as ExcelFieldsKey] = "";
            }
          });

          return orderedItem;
        });

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(factorData, {
        header: Object.keys(excelFields),
      });

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
    } catch (error: any) {
      console.log("Found Errors", error);
    }
  };

  return (
    <Row justify="end">
      <StyledButton
        type="primary"
        icon={<ExportOutlined />}
        onClick={getCustomList}
      >
        <span>Export Excel</span>
      </StyledButton>

      <StyledButton
        type="primary"
        onClick={() => {
          setCustomFactorModal(true);
        }}
      >
        <PlusCircleOutlined />
        Create Custom Factor
      </StyledButton>
      {customFactorModal && (
        <EditCustomFactor
          title="Create custom emissions factor"
          initialData={{} as any}
          onSave={() => {
            setCustomFactorModal(false);
            message.success("Custom Factor successfully created");
          }}
          onCancel={() => setCustomFactorModal(false)}
        />
      )}
    </Row>
  );
});
