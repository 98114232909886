import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
  CheckOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {  
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Spin,
  message,
  Tag
} from "antd";
import React, { useState, useEffect } from "react";
import {
  CommonTable,
  StyledButton,
  TableStyledMenu,
} from "../../../../shared/commonStyles";
import Assignees from "./Assignees";
import RelatedItems from "./RelatedItems";
import ProgressBar from "./ProgressBar";
import SubGoalsTable from "./SubGoalsTable";
import MetricsData from "./MetricsData";
import SubgoalCount from "./SubgoalCount";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import {  
  parentGoals,
} from "../../../../services/goals";
import LongTermGoalModal from "../../manageGoals/LongTermGoalModal";
import { updateGolasAndProgress } from "../../../../services/goals";

const GeneralGoalsDetails = ({ category }) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);  
  const [rowData, setRowData] = useState(null);
  const [refreshData, setRefreshData] = useState(0);
  const [type, setType] = useState("GEN_LONG");
  const [action, setAction] = useState("add");
  const [showLongTermModal, setShowLongTermModal] = useState(false);
  const [expended, setExpended] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [status, setStatus] = useState();
  const [confirmLoader, setConfirmLoader] = useState(false);
  
  const renderStatusTag = (key) => {
    //const status = key ? key?.toLowerCase() : "";
    if (key === "COMPLETED") {
      return (
        <Tag color="#5BA85A" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "IN_PROGRESS") {
      return (
        <Tag color="#1890FF" className="tag-rounded">
          {key}
        </Tag>
      );
    } else if (key === "DELETED") {
      return (
        <Tag color="#807C8B" className="tag-rounded">
          {key}
        </Tag>
      );
    }else {
      return (
        <Tag color="#FF0101" className="tag-rounded">
          {key}
        </Tag>
      );
    }
  };
  const expend = (index) => {
    if (expended === index) setExpended(undefined);
    else setExpended(index);
  };
  const loadGoals = async () => {
    try {
      setLoader(true);
      const goals = await parentGoals(category);
      const filteredGoals = goals?.filter(item => item.status !== 'DELETED')
      setDataSource(filteredGoals);
      
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
    
  useEffect(() => {
    loadGoals();
  }, [refreshData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "10%",
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {       
        const messageColor = renderStatusTag(status);
        return <>{messageColor}</>;
      },
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "10%",
      render: (_, record) => {
        return <Assignees record={record} />;
      },
    },
    {
      title: "Related Items",
      dataIndex: "relatedItems",
      width: "10%",
      render: (_, record) => {
        return <RelatedItems record={record} />;
      },
    },
    {
      title: "Subgoals",
      dataIndex: "shortTermGoals",
      width: "10%",
      render: (_, record) => {
        return <SubgoalCount record={record} />
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      width: "10%",
      render: (_, record) => {
        return <ProgressBar record={record} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "30%",
      render: (_, record) => {
        return (
          <Space direction="horizontal">
            <StyledButton
              type="default"
              icon={
                record.id === expended ? (
                  <MinusSquareOutlined />
                ) : (
                  <PlusSquareOutlined />
                )
              }
              onClick={() => expend(record.id)}
            >
              {record.id === expended ? "Hide Subgoals" : "View Subgoals"}
            </StyledButton>
            <Dropdown overlay={() => menu(record)} trigger={["click"]}>
              <StyledButton type="default" icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const menu = (record) => {
    return (
      <TableStyledMenu style={{ padding: "0" }}>
        <Menu.Item
          key={record.id + 1}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={record?.status === 'COMPLETED' ? <UndoOutlined /> : <CheckOutlined />}
            onClick={() => markAsCompleted(record)}
          >
            Mark as {record?.status === 'COMPLETED' ? 'In Progress' : 'Completed'}
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 2}
          onClick={() => addSubGoals(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<PlusCircleOutlined />}
          >
            Add Sub Goals
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 3}
          onClick={() => editGoals(record,'GEN_LONG')}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<EditOutlined />}
          >
            Edit
          </StyledButton>
        </Menu.Item>
        <Menu.Item
          key={record.id + 4}
          onClick={() => handleOnDelete(record)}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<DeleteOutlined />}
          >
            Delete
          </StyledButton>
        </Menu.Item>
      </TableStyledMenu>
    );
  };

  const addSubGoals = (data) => {
    setType("GEN_SHORT");
    setAction("create");
    setRowData(data);
    setShowLongTermModal(true);
  };

  const editGoals = (data, type) => {
    setType(type);
    setAction("edit");
    setRowData(data);
    setShowLongTermModal(true);
  };

  const onCloseTermModal = () => {
    setShowLongTermModal(false);
    setRefreshData(refreshData + 1);
  };  

  const markAsCompleted = (data) => {
    setStatus(data.status === 'COMPLETED' ? 'IN_PROGRESS' : 'COMPLETED')
    setRowData(data);
    setShowConfirmModal(true)
  }

  const handleOnDelete = (data) => {
    setStatus('DELETED')
    setRowData(data);
    setShowConfirmModal(true)
  }

  const onConfirmSubmit = async() => {
    
    if(!rowData) return;
    try{
      setConfirmLoader(true);
      delete rowData.createdAt
      delete rowData.updatedAt
      const response = await updateGolasAndProgress({...rowData, status});
      if(response){
        message.success((status === 'COMPLETED' || status === 'IN_PROGRESS')  ? `Goal status changed to ${status === 'COMPLETED' ? 'completed' : 'in progress'} successfully` : 'Goal deleted successfully');
        setRefreshData(refreshData + 1);
        setRowData(null)
        setShowConfirmModal(false)
        
      }
    }
    catch(error){
      message.error(`error while confirmimg goal ${error}`);
    }finally{
      setConfirmLoader(false);
    }
  }
  
  return (
    <Spin spinning={loader}>
      {showLongTermModal && (
        <LongTermGoalModal
          visible={showLongTermModal}
          action={action}
          type={type}
          rowData={rowData}
          onClose={onCloseTermModal}
        />
      )}
      
      {showConfirmModal && 
        <ConfirmModal
          title="Confirmation"
          description={(status === 'COMPLETED' || status === 'IN_PROGRESS') ? `Are you sure you want to mark it as ${status === 'COMPLETED' ? 'completed' : 'in progress'}?` : 'Are you sure want to delete the goal?'}
          visible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmSubmit}
          ConfirmLoader={confirmLoader}
        />
      }
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <MetricsData refreshData={refreshData} category={category} />
        </Col>
        <Col span={24}>
          <CommonTable
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
            rowKey="id"
            rowClassName={(record) => {          
              return record?.status === 'DELETED' ? 'rowDenied' : ''
            }}
            expandable={{
              expandedRowRender: (record) => <SubGoalsTable goalData={record} markAsCompleted={markAsCompleted} editGoals={editGoals} handleOnDelete={handleOnDelete} refreshData={refreshData} />,
              //rowExpandable: (record) => record.key % 2,
              expandIcon: () => <></>,
            }}
            expandedRowKeys={[expended]}
          />
        </Col>
      </Row>
    </Spin>
  );
};
export default GeneralGoalsDetails;
