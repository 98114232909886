import React from "react";
import DeploySurveyDataTable from "./Components/DeploySurveyDataTable";
import { PageWrap } from "../../shared/commonStyles";


const DeploySurvey = () => {  

  return (    
    <PageWrap> 
      <DeploySurveyDataTable />
    </PageWrap>
  );
};

export default DeploySurvey;
