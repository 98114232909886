import Styled from 'styled-components';

export const NewsWrapScroll = Styled.div`
    max-height : 400px;
    overflow-y : auto;
    margin : 10px;
    border : 1px solid #f0f0f0;
    padding : 10px;
`;

export const CustomLink = Styled.a`
        text-decoration: none;
        color: inherit;

        &:hover {
            color: #096dd9;;
        }
`;
