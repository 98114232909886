import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Row,
  Col,
  Spin,
  Input,
  DatePicker,
  Space,
  Select,
  message,
} from "antd";
import { ChartProgress, TabContentWrap } from "../../../shared/commonStyles";
import { SearchOutlined } from "@ant-design/icons";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Tooltip,
  ArgumentAxis,
  Label,
  ValueAxis,
  BreakStyle,
} from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import { fetchChartData } from "./OverviewApi";
import styles from "./Overview.module.scss";
import moment, { Moment } from "moment";
import { getCompanyInfo } from "../../../services/companyInfo";
import { doConversion } from "../../../services/dataSheet";
import { getEmissionsUnit } from "../../Carbon/CalculationUtils";
import { getAssignedIdentifiersForDefaultMetadata } from "../../../services/dataSheet";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { Auth } from "aws-amplify";

type ChartsFilter = {
  name?: string;
  date_start?: { $gte: Moment };
  date_end?: { $lte: Moment };
  indicatorType?: { $in: string[] };
};

type ChartData = {
  totalCarbonEmissions?: TotalCarbonEmissions[];
  emissionsByScope?: EmissionsByScope[];
  targetsProgress?: TargetsProgress[];
  countGHGFactors?: CountGHGFactors[];
  offsettedEmissions?: OffsettedEmissions[];
  emissionsLocationHotspots?: EmissionsLocationHotspots[];
  factorsFootprint?: FactorsFootprint[];
};

const Overview = () => {
  const [chartData, setChartData] = useState<ChartData>({} as ChartData);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<ChartsFilter>({} as ChartsFilter);
  const [searchName, setSearchName] = useState("");
  const [assignedIdentifiers, setAssignedIdentifiers] = useState([]);
  const [selectedIdentifiers, setSelectedIdentifiers] = useState<string[]>([]);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [conversionFactor, setConversionFactor] = useState(1);
  const [groupId, setGroupId] = useState("");
  const { checkRolesPermission } = useCheckRolesPermissions();

  useEffect(() => {
    // Fetch group ID from user session
    const fetchGroupId = async () => {
      try {
        const session = await Auth.currentSession();
        const group_id = session.getAccessToken().payload["cognito:groups"].filter(
          (element: string) => element.includes("org:")
        )[0];
        setGroupId(group_id);
      } catch (error) {
        console.error("Error fetching group ID:", error);
      }
    };
  
    fetchGroupId();
    getIdentifiers();
    loadCompanyInfo();
  }, []);

  useEffect(() => {
    loadData();
  }, [filter, selectedIdentifiers]);

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const onChangeIdentifiers = (identifiers: string[]) => {
    setSelectedIdentifiers(identifiers);
  };

  const getIdentifiers = async () => {
    try {
      const identifiers = await getAssignedIdentifiersForDefaultMetadata();
      const isError = identifiers?.statusCode >= 400;
      if (isError) {
        setAssignedIdentifiers([]);
        return Promise.reject();
      }
      setAssignedIdentifiers(identifiers);
      if (
        checkRolesPermission(["role:elevateduser"]) &&
        identifiers.length === 1
      ) {
        setSelectedIdentifiers(identifiers);
      }
    } catch {
      message.error("Error while getting identifiers!");
    }
  };

  const loadData = () => {
    setLoading(true);

    let payload: any = { identifiers: selectedIdentifiers };
    if (Object.keys(filter).length) {
      const { indicatorType, ...rest } = filter;
      const newFilter = indicatorType?.$in.includes("all") ? rest : filter;
      payload = {
        ...payload,
        filters: newFilter,
      };
    }
    fetchChartData(payload).then((result) => {
      setChartData(result);
      setLoading(false);
    });
  };

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
    }
  };

  const makeConversion = (chartType: string) => {
    switch (chartType) {
      case "emissionsByScope":
        return (chartData.emissionsByScope?.map((item) => {
          return {
            ...item,
            scope1:
              item.scope1 === 0
                ? null
                : +(item.scope1 * conversionFactor).toFixed(
                    companyDetails.decimal_places_number
                  ),
            scope2:
              item.scope2 === 0
                ? null
                : +(item.scope2 * conversionFactor).toFixed(
                    companyDetails.decimal_places_number
                  ),
            scope3:
              item.scope3 === 0
                ? null
                : +(item.scope3 * conversionFactor).toFixed(
                    companyDetails.decimal_places_number
                  ),
          };
        }) || []) as EmissionsByScope[];
      case "totalCarbonEmissions":
        return (chartData.totalCarbonEmissions?.map((item) => {
          return {
            ...item,
            totalEmission: +(item.totalEmission * conversionFactor).toFixed(
              companyDetails.decimal_places_number
            ),
          };
        }) || []) as TotalCarbonEmissions[];
      case "offsettedEmissions":
        return (chartData.offsettedEmissions?.map((item) => {
          return {
            ...item,
            emissions: +(item.emissions * conversionFactor).toFixed(
              companyDetails.decimal_places_number
            ),
          };
        }) || []) as OffsettedEmissions[];
      case "emissionsLocationHotspots":
        return (chartData.emissionsLocationHotspots?.map((item) => {
          return {
            ...item,
            emission: +(item.emission * conversionFactor).toFixed(
              companyDetails.decimal_places_number
            ),
          };
        }) || []) as EmissionsLocationHotspots[];
      case "factorsFootprint":
        return (chartData.factorsFootprint?.map((item) => {
          return {
            ...item,
            emission: +(item.emission * conversionFactor).toFixed(
              companyDetails.decimal_places_number
            ),
          };
        }) || []) as FactorsFootprint[];
    }
  };

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  const isChartDataVisible =
    Object.values(chartData).filter((v) => v.length > 0).length > 0;

  const chartsInfoDate = (data: any) => {
    return {
      html: `
        <div>
          <div>${data.argumentText}</div>
          <div>
            <span>${data.seriesName}: </span>
            <span>${data.value}</span>
          </div>
        </div>
      `,
    };
  };

  const chartsInfo = (data: any) => {
    return {
      html: `
        <div>
            <div>${data.argumentText}</div>
            <div>${Number(data.value).toFixed(2)}</div>
        </div>
      `,
    };
  };

  const factorCountInfo = (data: any) => {
    return {
      html: `
        <div>
          <span>${data.argumentText}: </span>
          <span>${Number(data.value)}</span>
        </div>
      `,
    };
  };

  const offsettedEmissionsPalette = ["#B0DBA9", "#629A64"];
  const emissionsByScopeSeries = ["#7095F4", "#4967B8", "#303779"];

  const offsettedEmissionsSum =
    Array.isArray(chartData.offsettedEmissions) &&
    (makeConversion("offsettedEmissions") as OffsettedEmissions[])
      .map((item) => item.emissions)
      .reduce((prev, next) => prev + next)
      .toFixed(2);

  const offsettedLegend = (data: any) => {
    const name = (
      makeConversion("offsettedEmissions") as OffsettedEmissions[]
    )?.find((item) => item.name === data.pointName)?.name;
    const emissions = (
      makeConversion("offsettedEmissions") as OffsettedEmissions[]
    )?.find((item) => item.name === data.pointName)?.emissions;
    return `${name}: ${emissions?.toFixed(2)} / ${offsettedEmissionsSum}`;
  };

  const offsettedMarker = (item: any) => {
    return (
      <>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 13.5C10.3137 13.5 13 10.8137 13 7.5C13 4.18629 10.3137 1.5 7 1.5C3.68629 1.5 1 4.18629 1 7.5C1 10.8137 3.68629 13.5 7 13.5Z"
            stroke={offsettedEmissionsPalette[item.id]}
            strokeWidth="2"
          />
        </svg>
      </>
    );
  };

  const emissionsByScopeMarker = (item: any) => {
    return (
      <>
        <svg
          width="29"
          height="22"
          viewBox="0 0 29 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.206543"
            width="28.023"
            height="22"
            rx="4"
            fill={emissionsByScopeSeries[item.id]}
          />
        </svg>
      </>
    );
  };

  const legendMarker = (color: string) => {
    return (
      <>
        <svg
          width="29"
          height="22"
          viewBox="0 0 29 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.206543" width="28.023" height="22" rx="4" fill={color} />
        </svg>
      </>
    );
  };

  const handleChangeSearchName = () => {
    searchName
      ? setFilter((prev) => ({
          ...prev,
          name: searchName,
        }))
      : setFilter((prev) => {
          const newFilters = { ...prev };
          delete newFilters.name;
          return newFilters;
        });
  };

  const onChangeDateFilters = (value: RangeValue) => {
    value
      ? setFilter((prev) => ({
          ...prev,
          date_start: { $gte: value?.[0] },
          date_end: { $lte: value?.[1] },
        }))
      : setFilter((prev) => {
          const newFilters = { ...prev };
          delete newFilters.date_start;
          delete newFilters.date_end;
          return newFilters;
        });
  };

  const onChangeBaseFilters = (value: string[]) => {
    value.length
      ? setFilter((prev) => ({
          ...prev,
          indicatorType: { $in: value.includes("all") ? ["all"] : value },
        }))
      : setFilter((prev) => {
          const newFilters = { ...prev };
          delete newFilters.indicatorType;
          return newFilters;
        });
  };

  const basedOptions = [
    { value: "all", label: "All" },
    { value: "default", label: "Default" },
    { value: "m", label: "Market-based" },
    { value: "l", label: "Location-based" },
  ];

  return (
    <>
      {" "}
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Row>
          <Col span={5}>
            <Input
              addonAfter={<SearchOutlined onClick={handleChangeSearchName} />}
              placeholder="Search by name"
              className={styles.search}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onBlur={handleChangeSearchName}
              onPressEnter={handleChangeSearchName}
            />
          </Col>
          <Col span={5} className={styles.dateFilter}>
            <DatePicker.RangePicker
              className={styles.filterDatepicker}
              format="YYYY-MM-DD"
              value={[
                filter?.date_start?.$gte
                  ? moment(filter?.date_start?.$gte)
                  : null,
                filter?.date_end?.$lte ? moment(filter?.date_end?.$lte) : null,
              ]}
              onChange={(value, string) => onChangeDateFilters(value)}
            />{" "}
          </Col>
          <Col span={5} className={styles.select}>
            <Select
              showArrow
              showSearch={false}
              placeholder="Select an emission type"
              mode="multiple"
              value={filter.indicatorType?.$in}
              options={basedOptions}
              onChange={(value) => onChangeBaseFilters(value)}
            />{" "}
          </Col>
          {!!assignedIdentifiers.length && (
            <Col span={5} className={styles.select}>
              <Select
                defaultValue={
                  checkRolesPermission(["role:elevateduser"]) &&
                  assignedIdentifiers.length === 1
                    ? assignedIdentifiers[0]
                    : []
                }
                disabled={
                  checkRolesPermission(["role:elevateduser"]) &&
                  assignedIdentifiers.length === 1
                    ? true
                    : false
                }
                showSearch
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Select Identifier"
                filterOption={(input: string, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={assignedIdentifiers.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value: string[]) => onChangeIdentifiers(value)}
                mode="multiple"
              />
            </Col>
          )}
        </Row>
      </Space>
      {!isChartDataVisible && !loading ? (
        <div className={styles.emptyContainer}>
          <h2 className={styles.emptyTitle}>
            No corresponding data to display for the dashboard.
          </h2>
          <div className={styles.emptyText}>
            Please, check your calculations and/or reduction targets.
          </div>
        </div>
      ) : (
      <Spin spinning={loading}>
        <TabContentWrap>
          <Row justify="space-between">
            {chartData.totalCarbonEmissions?.length && (
              <Col span={11}>
                <Row className={styles.chartTitle}>Total carbon emissions</Row>
                <Row>
                  <Col span={24}>
                    <Chart
                      dataSource={(
                        makeConversion(
                          "totalCarbonEmissions"
                        ) as TotalCarbonEmissions[]
                      )?.map((v) => ({
                        ...v,
                        date: v.date ? v.date?.toString() : "Without Date",
                      }))}
                      className={styles.chart}
                    >
                      <ArgumentAxis>
                        <Label
                          wordWrap="nowrap"
                          overlappingBehavior="rotate"
                          rotationAngle={-90}
                        />
                      </ArgumentAxis>
                      <CommonSeriesSettings argumentField="date" />
                      <Series
                        valueField="totalEmission"
                        name={`Total (${getEmissionsUnit(
                          companyDetails.emissions_unit
                        )} CO2e)`}
                        color="#FBE38E"
                      />
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        itemTextPosition="right"
                        markerRender={() => legendMarker("#FBE38E")}
                      />
                      <Tooltip
                        enabled={true}
                        customizeTooltip={chartsInfoDate}
                        font={{
                          weight: 600,
                          size: "12px",
                        }}
                        border={{ color: "#7E768E" }}
                      />
                    </Chart>
                  </Col>
                </Row>
              </Col>
            )}
            {chartData.emissionsByScope?.length && (
              <Col span={11}>
                <Row className={styles.chartTitle}>Carbon emissions by scope</Row>
                <Row className={styles.chartSubTitle}>
                  Figure may not be to scale. Hover over chart to see values.
                </Row>
                <Row>
                  <Col span={24}>
                    <Chart
                      dataSource={(
                        makeConversion(
                          "emissionsByScope"
                        ) as EmissionsByScope[]
                      )?.map((v) => ({
                        ...v,
                        date: v.date ? v.date?.toString() : "Without Date",
                      }))}
                      className={styles.chart}
                    >
                      <ArgumentAxis>
                        <Label
                          wordWrap="nowrap"
                          overlappingBehavior="rotate"
                          rotationAngle={-90}
                        />
                      </ArgumentAxis>
                      <CommonSeriesSettings
                        argumentField="date"
                        type="stackedBar"
                        barPadding={0}
                      />
                      <Series
                        valueField="scope1"
                        name={`Scope 1 (${getEmissionsUnit(
                          companyDetails.emissions_unit
                        )} CO2e)`}
                        color="#7095F4"
                        minBarSize={10}
                        ignoreEmptyPoints
                      />
                      <Series
                        valueField="scope2"
                        name={`Scope 2 (${getEmissionsUnit(
                          companyDetails.emissions_unit
                        )} CO2e)`}
                        color="#4967B8"
                        minBarSize={10}
                        ignoreEmptyPoints
                      />
                      <Series
                        valueField="scope3"
                        name={`Scope 3 (${getEmissionsUnit(
                          companyDetails.emissions_unit
                        )} CO2e)`}
                        color="#303779"
                        minBarSize={10}
                        ignoreEmptyPoints
                      />
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        itemTextPosition="right"
                        markerRender={emissionsByScopeMarker}
                      />
                      <Tooltip
                        enabled={true}
                        customizeTooltip={chartsInfoDate}
                        font={{
                          weight: 600,
                          size: "12px",
                        }}
                        border={{ color: "#7E768E" }}
                      />
                    </Chart>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          {/* Conditionally render other charts only if groupId is NOT "org:amg-nv.com" */}
          {groupId !== "org:amg-nv.com" && (
            <>
              <Row justify="space-between">
                {chartData?.targetsProgress?.length && (
                  <Col span={11}>
                    <Row className={styles.chartTitle}>
                      Active reduction plans
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          {!loading &&
                            chartData?.targetsProgress.map((v) => (
                              <Col
                                key={uuidv4()}
                                className={styles.reductionPlan}
                              >
                                <Row>
                                  <ChartProgress
                                    strokeLinecap="square"
                                    strokeWidth={10}
                                    type="circle"
                                    percent={Math.round(v.progress || 0)}
                                  />
                                </Row>
                                <Row
                                  justify="center"
                                  className={styles.reductionPlanName}
                                >
                                  {v.target}
                                </Row>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {chartData.offsettedEmissions?.length && (
                  <Col span={11}>
                    <Row className={styles.chartTitle}>Offsetted emissions</Row>
                    <Row>
                      <Col span={24}>
                        <PieChart
                          id="pie"
                          dataSource={makeConversion("offsettedEmissions")}
                          palette={offsettedEmissionsPalette}
                          className={`${styles.chart} ${styles.chartOffsetted}`}
                          size={{ height: 150, width: 500 }}
                          startAngle={90}
                        >
                          <Series argumentField="name" valueField="emissions" />
                          <Legend
                            verticalAlignment="top"
                            horizontalAlignment="left"
                            itemTextPosition="right"
                            itemsAlignment="left"
                            customizeText={offsettedLegend}
                            markerRender={offsettedMarker}
                            visible
                          />
                          <Tooltip
                            enabled={true}
                            customizeTooltip={chartsInfo}
                            font={{
                              weight: 600,
                              size: "12px",
                            }}
                            border={{ color: "#7E768E" }}
                          />
                        </PieChart>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {/* Other charts */}
              <Row justify="space-between">
                {chartData.emissionsLocationHotspots?.length && (
                  <Col span={11}>
                    <Row className={styles.chartTitle}>
                      Emissions location hotspots
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Chart
                          id="chart"
                          dataSource={makeConversion("emissionsLocationHotspots")}
                          className={styles.chart}
                        >
                          <ArgumentAxis>
                            <Label
                              wordWrap="nowrap"
                              overlappingBehavior="none"
                            />
                          </ArgumentAxis>
                          <Series
                            argumentField="location"
                            valueField="emission"
                            name="Emissions hotspots"
                            type="bar"
                            ignoreEmptyPoints={true}
                            color="#8CEAB2"
                          />
                          <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="right"
                            markerRender={() => legendMarker("#8CEAB2")}
                          />
                          <Tooltip
                            enabled={true}
                            customizeTooltip={chartsInfo}
                            font={{
                              weight: 600,
                              size: "12px",
                            }}
                            border={{ color: "#7E768E" }}
                          />
                        </Chart>
                      </Col>
                    </Row>
                  </Col>
                )}
                {chartData.countGHGFactors?.length && (
                  <Col span={11}>
                    <Row className={styles.chartTitle}>GHG Factors count</Row>
                    <Row>
                      <Col span={24}>
                        <Chart
                          dataSource={chartData.countGHGFactors}
                          className={styles.chart}
                        >
                          <CommonSeriesSettings argumentField="factor" />
                          <ArgumentAxis>
                            <Label
                              wordWrap="nowrap"
                              overlappingBehavior="none"
                            />
                          </ArgumentAxis>
                          <Series
                            argumentField="factor"
                            valueField="count"
                            name="Factors count"
                            type="bar"
                            ignoreEmptyPoints={true}
                            color="#EAA88C"
                          />
                          <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="right"
                            markerRender={() => legendMarker("#EAA88C")}
                          />
                          <Tooltip
                            enabled={true}
                            customizeTooltip={factorCountInfo}
                            font={{
                              weight: 600,
                              size: "12px",
                            }}
                            border={{ color: "#7E768E" }}
                          />
                        </Chart>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row justify="space-between">
                {chartData.factorsFootprint?.length && (
                  <Col span={24}>
                    <Row className={styles.chartTitle}>Factors footprint</Row>
                    <Row>
                      <Col span={24}>
                        <Chart
                          id="chart"
                          dataSource={makeConversion("factorsFootprint")}
                          palette="Soft"
                          className={styles.chart}
                        >
                          <ArgumentAxis>
                            <Label
                              wordWrap="nowrap"
                              overlappingBehavior="none"
                            />
                          </ArgumentAxis>
                          <Series
                            argumentField="factor"
                            valueField="emission"
                            name="Factors footprint"
                            type="bar"
                            ignoreEmptyPoints={true}
                            color="#8CB7EA"
                          />
                          <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="right"
                            markerRender={() => legendMarker("#8CB7EA")}
                          />
                          <Tooltip
                            enabled={true}
                            customizeTooltip={chartsInfo}
                            font={{
                              weight: 600,
                              size: "12px",
                            }}
                            border={{ color: "#7E768E" }}
                          />
                        </Chart>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </>
          )}
        </TabContentWrap>
      </Spin>
      )}
    </>
  );
};

export default Overview;
