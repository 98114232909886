import React, { useState, useEffect, useCallback } from "react";
  useCallback
import { Col, message, Row, Input, Typography, Select, Switch, Dropdown, Menu, Checkbox, Spin } from "antd";
import { API } from "aws-amplify";
import * as queries from "../../../../../graphql/queries";
import OverviewDataTable from "./Components/OverviewDataTable";
import {
  FiltersContainer,
  FiltersWrap,
  PageWrap,
  FilterButtonsWrap,
  StyledButton,
  FormWrapper,
  CommonSelect,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";

import XLSX from "xlsx";

import { MenuOutlined, PlusSquareOutlined, ExportOutlined} from '@ant-design/icons';



import { getCompanyInfo } from "../../../../../services/companyInfo";


import styles from "./../../Overview.module.scss";
import { round } from "lodash";
import { count } from "mathjs";

const { Text } = Typography;
const { Option } = Select;

const BreakdownPivotTable = () => {
  const [pivotDataList, setPivotDataList] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [manageLevels, setManageLevels] = useState([]);
  const [manageColumns, setManageColumns] = useState([]);
  const [manageYears, setManageYears] = useState([]);

  const [manageLevelsState, setManageLevelsState] = useState([]);
  // stores original data returned from api
  const [pivotTableData, setPivotTableData] = useState([]);

  const [filter, setFilter] = useState({
    title: "",
    category: "",
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [columnVisibility, setColumnVisibility] = useState({
    level: true,
    row_labels: true,
    sum_of_consumption: true,
    sum_of_total_location: true,
    scope_1_location: false,
    scope_2_location: false,
    scope_3_location: false,
    sum_of_total_market: true,
    scope_1_market: false,
    scope_2_market: false,
    scope_3_market: false,
    sum_of_total_uncategorized: true,
    scope_1_uncategorized: false,
    scope_2_uncategorized: false,
    scope_3_uncategorized: false,
  });

  const menuItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'nowrap'
  };

  let round_to_decimal_places = 2;

  const generateExportReport = () => {
    try {
      /*
      const dataList = taskDeployments.map((item, index) => {
        const data = {};
        for (const [key, value] of Object.entries(item)) {
          for (const [k, v] of Object.entries(ExcelFields)) {
            if (key === v) {
              data[k] = value;
            }
          }
        }
        return data;
      });
      */

      const arr_data = [];
      const wb = XLSX.utils.book_new();
      pivotDataList.forEach(item => {
          // Add the value of the specified field to the Set
          delete item['current_combinations'];
          delete item['row_labels'];
          arr_data.push(item);
          //item.current_combinations.foreach(combinat => {
          //});
          //item.row_labels = 
      });
      const ws = XLSX.utils.json_to_sheet(arr_data);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "pivot-table-breakdown.xlsx");
    } catch (error) {
      console.log("Error while generating Excel Report!", error);
    }
  };


  function getUniqueValues(data, field) {

    if (!Array.isArray(data)) {
        return [];
    }

    // Create a Set to store unique values
    const uniqueValues = new Set();

    // Loop through the data array
    data.forEach(item => {
        // Add the value of the specified field to the Set
        uniqueValues.add(item[field]);
    });

    // Convert the Set back to an array, sort it in ascending order, and return it
    return Array.from(uniqueValues).sort((a, b) => (a > b ? 1 : -1));
  }

  function filterDataByCriteria(data, criteria) {
    // Filter the data array based on the criteria
    return data.filter(item => {
        // Check if all key-value pairs in the criteria match the current item
        return Object.keys(criteria).every(key => item[key] === criteria[key]);
    });
  }







// Helper function to generate combinations of array elements
  const getCombinations = (array) => {
    const results = [[]];
    for (const value of array) {
      const copy = [...results]; // See note below.
      for (const prefix of copy) {
        results.push(prefix.concat(value));
      }
    }
    return results.slice(1); // Remove the empty set
  };

  // Function to generate all combinations of fields with unique values in a flat array
  const generateFieldCombinations = (data, excludeFields) => {
    if (data.length === 0) return [];

    const keys = Object.keys(data[0]).filter(key => !excludeFields.includes(key));
    let allCombinations = [];

    const keyCombinations = getCombinations(keys);
    
    keyCombinations.forEach(combination => {
      const combinationResult = data.map(item => {
        const obj = {};
        combination.forEach(key => {
          obj[key] = item[key];
        });
        return obj;
      });

      // Filter out duplicates by converting each combinationResult to a set of JSON strings
      const uniqueResults = Array.from(new Set(combinationResult.map(item => JSON.stringify(item))))
                                 .map(item => JSON.parse(item));
      
      allCombinations = [...allCombinations, ...uniqueResults];
    });

    return allCombinations;
  };

  // Function to match combinations with original data and aggregate results
  const aggregateData = (combinations, data) => {
    const aggregatedResults = combinations.map(combination => {
      let totalConsumptionAmount = 0;
      let totalEmission = 0;

      data.forEach(item => {
        const isMatch = Object.keys(combination).every(key => combination[key] === item[key]);

        if (isMatch) {
          totalConsumptionAmount += item.total_consumption_amount;
          totalEmission += item.total_emission;
        }
      });

      return {
        combination,
        totalConsumptionAmount,
        totalEmission,
      };
    });

    return aggregatedResults;
  };


  const sortCombinations = (combinations) => {
    // Step 1: Sort combinations by level (number of fields)
    combinations.sort((a, b) => Object.keys(a).length - Object.keys(b).length);

    // Step 2: Create a map to store children under their parent combinations
    const combinationMap = {};

    combinations.forEach(combinationObj => {
      const combinationKey = JSON.stringify(combinationObj);
      combinationMap[combinationKey] = combinationObj;
    });

    // Step 3: Create a list to hold sorted combinations
    const orderedCombinations = [];
    const visited = new Set();

    const isParentOf = (parent, child) => {
      return Object.keys(parent).every(key => parent[key] === child[key]);
    };

    const addToOrderedList = (combinationObj) => {
      const combinationKey = JSON.stringify(combinationObj);

      if (!visited.has(combinationKey)) {
        visited.add(combinationKey);

        // Add the current combination to the ordered list
        orderedCombinations.push(combinationObj);

        // Recursively add child combinations
        combinations.forEach(childCombinationObj => {
          if (
            childCombinationObj !== combinationObj &&
            isParentOf(combinationObj, childCombinationObj)
          ) {
            addToOrderedList(childCombinationObj);
          }
        });
      }
    };

    combinations.forEach(combinationObj => {
      addToOrderedList(combinationObj);
    });

    return orderedCombinations;
  };


  const onChangeLevel = (option) => {
    console.log(option)
    /*
    setInitialValues((oldState) => ({
      ...oldState,
      is_carbon: option.target.checked,
    }));
    */
  };


  const handleLevelVisibility = (checked, event) => {
    event.stopPropagation();
    console.log('handle level visibility');
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      ['level']: !prevVisibility['level'], // Toggle the current valu
    }));
    
  };
  const handleLevelVisibilityClick = (event) => {
    event.stopPropagation();
    console.log('handle level visibility click');
  };

  //const handle_pivot_table_row_click = (n, pivotDataList, setPivotDataList) => {
  //const handle_pivot_table_row_click = useCallback((n) => {
  //const handle_pivot_table_row_click = (n, pivotDataList, setPivotDataList) => {
  const handle_pivot_table_row_click = (record) => {
    console.log('pivot table row click')
    let n = record.id;
    //console.log('n is ' + n)
    if (!record.is_expanded) {
      handle_pivot_table_row_expand(record);
    } else {
      handle_pivot_table_row_collapse(n, record.level);
    }
    /*
    console.log(record);
    console.log(pivotDataList);
    console.log(pivotDataList);
    const newPivotDataList = [...pivotDataList];
    console.log(newPivotDataList[n])
    newPivotDataList.push(newPivotDataList[n]);
    setPivotDataList(newPivotDataList);
    */
  };


  //function handle_pivot_table_row_collapse(n, current_level, pivotDataList, setPivotDataList) {
  function handle_pivot_table_row_collapse(n, current_level) {
      // Create a copy of the pivotDataList to avoid direct mutation
      let updatedPivotDataList = [...pivotDataList];

      // Start at index n+1
      let i = n + 1;

      // Loop through the list starting from index n+1
      while (i < updatedPivotDataList.length) {
          const row = updatedPivotDataList[i];

          // If row.level is greater than current_level, delete it
          if (row.level > current_level) {
              updatedPivotDataList.splice(i, 1); // Remove the item at index i
          } else if (row.level === current_level) {
              // Stop if we encounter a row with the same level
              break;
          } else {
              // If the level is lower, just move to the next item
              i++;
          }
      }

      // Set is_expanded to false for the row at index n
      updatedPivotDataList[n].is_expanded = false;

      // Reindex the array so that all ids are sequential
      const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
          ...item,
          id: index, // Sequentially reindex ids
      }));

      // Update the state with the new pivotDataList
      setPivotDataList(reindexedPivotDataList);
  }

  const pivot_table_row_expand_recursive = (record, new_level_index = -1, passed_pivot_table_data = null, passed_manage_levels = null) => {
    console.log('handle pivot table row expand');
    let column_display_name = '';

    let manage_levels = [];
    if (passed_manage_levels !== null) {
      manage_levels = passed_manage_levels;
    } else {
      manage_levels = manageLevelsState;
    }

    console.log('manageLevelsState')
    console.log(manageLevelsState);
    console.log('manage_levels')
    console.log(manage_levels);
    let current_level = null;
    if (new_level_index != -1) {
      current_level = manage_levels[new_level_index];

    } else {
      current_level = manage_levels[record.level];
    }

    const newPivotDataList = [];
    let pivot_table_data = [];
    if (passed_pivot_table_data !== null) {
      pivot_table_data = passed_pivot_table_data;
    } else {
      pivot_table_data = pivotTableData;
    }

    if (current_level) {
      console.log('inside current level');
      // we have root level get unique fields
      let entity_name = "";
      if (current_level.isIdentifier) {
        entity_name = "group";
      } else {
        entity_name = current_level.entity_name;
      }
      column_display_name = entity_name;

      // we probably can make this more efficient by only grabbing values with parent combination
      const arr_unique_values = getUniqueValues(pivot_table_data, entity_name);


      let n = 0;
      if (record.level > 1) {
        n = record.id+1;
      }

      // Loop through arr_root_unique_values
      arr_unique_values.forEach(uniqueValue => {
        // Prepare the criteria object
        const criteria = {};
        // need to add all parent combinations

        // Loop through current_combinations and add each to criteria
        for (const key in record.current_combinations) {
            if (record.current_combinations.hasOwnProperty(key)) {
                criteria[key] = record.current_combinations[key];
            }
        }
        criteria[entity_name] = uniqueValue;

        // Call filterDataByCriteria with the criteria
        const filteredResults = filterDataByCriteria(pivot_table_data, criteria);
        if (entity_name == "scope") {
          if (uniqueValue == "scope_1") {
            uniqueValue = "Scope 1";
            column_display_name = uniqueValue;
          } else if (uniqueValue == "scope_2") {
            uniqueValue = "Scope 2";
            column_display_name = uniqueValue;
          } else if (uniqueValue == "scope_3") {
            uniqueValue = "Scope 3";
            column_display_name = uniqueValue;
          }
        }

        // Initialize the total_consumption_amount
        let totalConsumptionAmount = 0;
        let sum_of_total_all_scopes_location = 0;
        let scope_1_emission_amount_location = 0;
        let scope_2_emission_amount_location = 0;
        let scope_3_emission_amount_location = 0;
        let sum_of_total_all_scopes_market = 0;
        let scope_1_emission_amount_market = 0;
        let scope_2_emission_amount_market = 0;
        let scope_3_emission_amount_market = 0;
        let sum_of_total_all_scopes_uncategorized = 0;
        let scope_1_emission_amount_uncategorized = 0;
        let scope_2_emission_amount_uncategorized = 0;
        let scope_3_emission_amount_uncategorized = 0;

        // Loop through the filtered results to sum up total_consumption_amount
        filteredResults.forEach(item => {
            if (item.indicatorType !== undefined && item.indicatorType === "l") {
              sum_of_total_all_scopes_location += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_location += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_location += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_location += item.total_emission;
              }
            } else if (item.indicatorType !== undefined && item.indicatorType === "m") {
              sum_of_total_all_scopes_market += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_market += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_market += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_market += item.total_emission;
              }
            } else {
              sum_of_total_all_scopes_uncategorized += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_uncategorized += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_uncategorized += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_uncategorized += item.total_emission;
              }

            }
            totalConsumptionAmount += item.total_consumption_amount;
        });
        const level_padding_left = record.level*20;
        const pivot_table_row = {
          id: n,
          level: record.level+1,
          entity_name: column_display_name,
          current_combinations: criteria,
          row_labels: (
            <>
              <span
                style={{ 
                  cursor: 'pointer', 
                  color: '#ffffff', 
                  paddingLeft: `${level_padding_left}px` // Assuming you want to add 'px' as the unit
                }}
                >
                  <PlusSquareOutlined /> {uniqueValue}
              </span>
            </>
          ),
          sum_of_total_all_scopes_location: sum_of_total_all_scopes_location.toFixed(round_to_decimal_places),
          scope_1_emission_amount_location: scope_1_emission_amount_location.toFixed(round_to_decimal_places),
          scope_2_emission_amount_location: scope_2_emission_amount_location.toFixed(round_to_decimal_places),
          scope_3_emission_amount_location: scope_3_emission_amount_location.toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_market: sum_of_total_all_scopes_market.toFixed(round_to_decimal_places),
          scope_1_emission_amount_market: scope_1_emission_amount_market.toFixed(round_to_decimal_places),
          scope_2_emission_amount_market: scope_2_emission_amount_market.toFixed(round_to_decimal_places),
          scope_3_emission_amount_market: scope_3_emission_amount_market.toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_uncategorized: sum_of_total_all_scopes_uncategorized.toFixed(round_to_decimal_places),
          scope_1_emission_amount_uncategorized: scope_1_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          scope_2_emission_amount_uncategorized: scope_2_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          scope_3_emission_amount_uncategorized: scope_3_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          sum_of_consumption: totalConsumptionAmount,
          is_expanded: false,
        }

        // if all 0 then don't add
        if (
          sum_of_total_all_scopes_location == 0 &&
          scope_1_emission_amount_location == 0 &&
          scope_2_emission_amount_location == 0 &&
          scope_3_emission_amount_location == 0 &&
          sum_of_total_all_scopes_market == 0 &&
          scope_1_emission_amount_market == 0 &&
          scope_2_emission_amount_market == 0 &&
          scope_3_emission_amount_market == 0 &&
          sum_of_total_all_scopes_uncategorized == 0 &&
          scope_1_emission_amount_uncategorized == 0 &&
          scope_2_emission_amount_uncategorized == 0 &&
          scope_3_emission_amount_uncategorized == 0 &&
          totalConsumptionAmount == 0
        ) {
        } else {
          // Push the new row into the newPivotDataList array
          newPivotDataList.push(pivot_table_row);
        }
        n++;
      });
    }

    const children = [];
    const merged_rows = [];
    // loop through newpivotdatalist these will be all the children
    newPivotDataList.forEach((pivot_table_row) => {
      const child_rows = (pivot_table_row_expand_recursive(pivot_table_row, pivot_table_row.level, passed_pivot_table_data, passed_manage_levels));
      pivot_table_row.is_expanded = true;
      merged_rows.push(pivot_table_row);
      child_rows.forEach((child_row) => {
        child_row.is_expanded = true;
        merged_rows.push(child_row);
      });
    });



    // then once grabbed datalist for each child then merge them all together and then return that
    //return newPivotDataList;
    return merged_rows;
  }

  // expands a row in a pivot table
  const handle_pivot_table_row_expand = (record) => {

    const newPivotDataList = pivot_table_row_expand_recursive(record);
    const insertIndex = record.id;
    //newPivotDataList[record.id].is_expanded = true;

    if (insertIndex !== -1) {
      // Insert the newPivotDataList after the found index
      const updatedPivotDataList = [
        ...pivotDataList.slice(0, insertIndex + 1),
        ...newPivotDataList,
        ...pivotDataList.slice(insertIndex + 1),
      ];

      updatedPivotDataList[record.id].is_expanded = true;

      // Reindex all ids sequentially
      const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
        ...item,
        id: index, // Reindex id to be sequential starting from 0
      }));
      console.log('reindex data')
      console.log(reindexedPivotDataList)

      // Update the state with the new reindexed list
      setPivotDataList(reindexedPivotDataList);
    }
  }


  const load_data = async () => {
    const companyInfo = await getCompanyInfo();
    const obj = JSON.parse(companyInfo.data);
    const default_metadata_sheet_id = obj.default_metadata_sheet_id;

    console.log('company info');
    console.log(companyInfo)
    console.log('obj');
    console.log(obj)
    round_to_decimal_places = obj.decimal_places_number;

    const manage_levels = await get_manage_levels(default_metadata_sheet_id);

    const year_level = {
      "display_name": "Reporting Year",
      "entity_name": "year",
      "level": 1
    }

    manage_levels.unshift(year_level);

    const scope_level = {
      "display_name": "Scope",
      "entity_name": "scope",
      "level": 1
    }

    manage_levels.push(scope_level);
    // add year to manage levels
    console.log('manage levels');
    console.log(manage_levels)


    setManageLevelsState(manage_levels);

    const manage_level_options = manage_levels.map(level => ({
      label: level.display_name,
      value: level.entity_name,
    }));



    // loop through manage levels
    const TempManageLevels = (
      <TableCommonMenu>
        {manage_level_options.map(level => (
          <Menu.Item key={level.value} onClick={handleMenuItemClick}>
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={handleCheckBoxClick}
                  checked={false}
                  onChange={onChangeLevel}
                >
                  <span style={{ color: 'white' }}>{level.label}</span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
          </Menu.Item>
        ))}
      </TableCommonMenu>
    );

    console.log('temp manage Levels')
    console.log(TempManageLevels)
    setManageLevels(TempManageLevels)


    const TempManageColumns = (
      <TableCommonMenu>
        <Menu.Item key="levels_column_is_hidden" onClick={handleMenuItemClick}>
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap' }}>
                <Switch
                  onChange={handleLevelVisibility}
                  onClick={handleLevelVisibilityClick}
                  checked={columnVisibility['level']}
                  /*onChange={(checked) => handleChartStatus(checked, rowDataSwitch)}
                  checked={status === "ACTIVE" ? true : false}
                  */
                >
                </Switch>
                <span style={{ color: 'white', paddingLeft: '10px' }}>Levels Column is Hidden</span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="row_labels" onClick={handleMenuItemClick}>
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'row_labels')}
                  checked={columnVisibility['row_labels']}
                >
                  <span style={{ color: 'white' }}>Row Labels
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="sum_of_consumption">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_consumption')}
                  checked={columnVisibility['sum_of_consumption']}
                >
                  <span style={{ color: 'white' }}>Sum Of Consumption
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="sum_of_total_location">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_location')}
                  checked={columnVisibility['sum_of_total_location']}
                >
                  <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Location
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_1_location">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_location')}
                  checked={columnVisibility['scope_1_location']}
                >
                  <span style={{ color: 'white' }}>Scope 1
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_2_location">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_location')}
                  checked={columnVisibility['scope_2_location']}
                >
                  <span style={{ color: 'white' }}>Scope 2
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_3_location">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_location')}
                  checked={columnVisibility['scope_3_location']}
                >
                  <span style={{ color: 'white' }}>Scope 3
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="sum_of_total_market">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_market')}
                  checked={columnVisibility['sum_of_total_market']}
                >
                  <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Markets
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_1_market">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_market')}
                  checked={columnVisibility['scope_1_market']}
                >
                  <span style={{ color: 'white' }}>Scope 1
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_2_market">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_market')}
                  checked={columnVisibility['scope_2_market']}
                >
                  <span style={{ color: 'white' }}>Scope 2
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_3_market">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_market')}
                  checked={columnVisibility['scope_3_market']}
                >
                  <span style={{ color: 'white' }}>Scope 3
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="sum_of_total_uncategorized">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={menuItemStyle}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_uncategorized')}
                  checked={columnVisibility['sum_of_total_uncategorized']}
                >
                  <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Uncategorized
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  <MenuOutlined />
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_1_uncategorized">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_uncategorized')}
                  checked={columnVisibility['scope_1_uncategorized']}
                >
                  <span style={{ color: 'white' }}>Scope 1
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_2_uncategorized">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_uncategorized')}
                  checked={columnVisibility['scope_2_uncategorized']}
                >
                  <span style={{ color: 'white' }}>Scope 2
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
        <Menu.Item key="scope_3_uncategorized">
            <StyledButton
              onClick={handleCheckBoxClick}
              type="custom"
              className="margin-5"
            >
              <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                <Checkbox
                  onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_uncategorized')}
                  checked={columnVisibility['scope_3_uncategorized']}
                >
                  <span style={{ color: 'white' }}>Scope 3
                  </span>
                </Checkbox>
                <span style={{ color: 'white' }}>
                  &nbsp;
                </span>
              </div>
            </StyledButton>
        </Menu.Item>
      </TableCommonMenu>
    );

    console.log('temp manage columns')
    console.log(TempManageColumns)
    setManageColumns(TempManageColumns)


    // Get the current year
    const currentYear = new Date().getFullYear();

    // Generate an array of the past 7 years
    const pastYears = Array.from({ length: 7 }, (_, i) => currentYear - i);

    const TempYearColumns = (
      <TableCommonMenu>
        {pastYears.map(year => (
          <Menu.Item key={year}>
            <StyledButton
              type="custom"
              className="margin-5"
            >
              <Checkbox
                onClick={handleCheckBoxClick}
                checked={false}
                onChange={onChangeLevel}
              >
                <span style={{ color: 'white' }}>{year}</span>
              </Checkbox>
            </StyledButton>
          </Menu.Item>
        ))}
      </TableCommonMenu>
    );

    setManageYears(TempYearColumns)

    const pivot_table_data = await get_pivot_table_data(default_metadata_sheet_id);

    setPivotTableData(pivot_table_data)

    console.log('pivot table data');
    console.log(pivot_table_data)


    const root_level = manage_levels[0];

    const newPivotDataList = [...pivotDataList];

    let column_display_name = "";
    if (root_level) {
      // we have root level get unique fields
      let entity_name = "";
      if (root_level.isIdentifier) {
        entity_name = "group";
      } else {
        entity_name = root_level.entity_name;
      }
      column_display_name = entity_name;

      const arr_root_unique_values = getUniqueValues(pivot_table_data, entity_name);


      let n = 0;
      // Loop through arr_root_unique_values
      arr_root_unique_values.forEach(uniqueValue => {
        // Prepare the criteria object
        const criteria = {};
        criteria[entity_name] = uniqueValue;

        // Call filterDataByCriteria with the criteria
        const filteredResults = filterDataByCriteria(pivot_table_data, criteria);
        if (entity_name == "scope") {
          if (uniqueValue == "scope_1") {
            uniqueValue = "Scope 1";
            column_display_name = uniqueValue;
          } else if (uniqueValue == "scope_2") {
            uniqueValue = "Scope 2";
            column_display_name = uniqueValue;
          } else if (uniqueValue == "scope_3") {
            uniqueValue = "Scope 3";
            column_display_name = uniqueValue;
          }
        }

        // Initialize the total_consumption_amount
        let totalConsumptionAmount = 0;
        let sum_of_total_all_scopes_location = 0;
        let scope_1_emission_amount_location = 0;
        let scope_2_emission_amount_location = 0;
        let scope_3_emission_amount_location = 0;
        let sum_of_total_all_scopes_market = 0;
        let scope_1_emission_amount_market = 0;
        let scope_2_emission_amount_market = 0;
        let scope_3_emission_amount_market = 0;
        let sum_of_total_all_scopes_uncategorized = 0;
        let scope_1_emission_amount_uncategorized = 0;
        let scope_2_emission_amount_uncategorized = 0;
        let scope_3_emission_amount_uncategorized = 0;

        // Loop through the filtered results to sum up total_consumption_amount
        filteredResults.forEach(item => {
            if (item.indicatorType !== undefined && item.indicatorType === "l") {
              sum_of_total_all_scopes_location += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_location += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_location += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_location += item.total_emission;
              }
            } else if (item.indicatorType !== undefined && item.indicatorType === "m") {
              sum_of_total_all_scopes_market += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_market += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_market += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_market += item.total_emission;
              }
            } else {
              sum_of_total_all_scopes_uncategorized += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_uncategorized += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_uncategorized += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_uncategorized += item.total_emission;
              }

            }
            totalConsumptionAmount += item.total_consumption_amount;
        });
        const pivot_table_row = {
          id: n,
          level: 1,
          entity_name: entity_name,
          current_combinations: { [entity_name]: uniqueValue  },
          row_labels: (
            <>
              <span
                style={{ cursor: 'pointer', color: '#ffffff' }}
                /*
                onClick={(function(currentN) {
                    return () => handle_pivot_table_row_click(currentN, pivotDataList, setPivotDataList);
                })(n)}
                */
              >
                <PlusSquareOutlined /> {uniqueValue}
              </span>
            </>
          ),
          sum_of_total_all_scopes_location: sum_of_total_all_scopes_location.toFixed(round_to_decimal_places),
          scope_1_emission_amount_location: scope_1_emission_amount_location.toFixed(round_to_decimal_places),
          scope_2_emission_amount_location: scope_2_emission_amount_location.toFixed(round_to_decimal_places),
          scope_3_emission_amount_location: scope_3_emission_amount_location.toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_market: sum_of_total_all_scopes_market.toFixed(round_to_decimal_places),
          scope_1_emission_amount_market: scope_1_emission_amount_market.toFixed(round_to_decimal_places),
          scope_2_emission_amount_market: scope_2_emission_amount_market.toFixed(round_to_decimal_places),
          scope_3_emission_amount_market: scope_3_emission_amount_market.toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_uncategorized: sum_of_total_all_scopes_uncategorized.toFixed(round_to_decimal_places),
          scope_1_emission_amount_uncategorized: scope_1_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          scope_2_emission_amount_uncategorized: scope_2_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          scope_3_emission_amount_uncategorized: scope_3_emission_amount_uncategorized.toFixed(round_to_decimal_places),
          sum_of_consumption: totalConsumptionAmount.toFixed(round_to_decimal_places),
          is_expanded: false,
        }
        // Push the new row into the newPivotDataList array
        newPivotDataList.push(pivot_table_row);
        n++;
      });
    }

    // start of code to recursively call all rows and auto expand all rows
    const merged_rows = [];

    newPivotDataList.forEach((pivot_table_row) => {
      console.log('auto expanding item')
      console.log(pivot_table_row)
      const child_rows = (pivot_table_row_expand_recursive(pivot_table_row, 1, pivot_table_data, manage_levels));
      console.log('found ' + count(child_rows) + ' child rows');
      pivot_table_row.is_expanded = true;
      merged_rows.push(pivot_table_row);
      child_rows.forEach((child_row) => {
        merged_rows.push(child_row);
      });
    });

    const updatedPivotDataList = merged_rows;
    
    const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
      ...item,
      id: index, // Reindex id to be sequential starting from 0
    }));
    console.log('reindex data root level')
    console.log(reindexedPivotDataList)


    // Update the state with the new reindexed list
    setPivotDataList(reindexedPivotDataList);
    // end of code to recursively call all rows and auto expand all rows



    /*
    // previous code which did not auto expand all rows
    // Finally, update the state with the new array of rows
    setPivotDataList(newPivotDataList);
    */

    setLoadingTableData(false);



    // get all combinations
    /*
    const excludeFields = ["total_emission", "total_consumption_amount", "shop_name"];
    
    // Generate combinations
    const combinations = generateFieldCombinations(pivot_table_data, excludeFields);
    console.log('combination result')
    console.log(combinations)

    const aggregatedResults = aggregateData(combinations, pivot_table_data);
    console.log('aggregatedResults')
    console.log(aggregatedResults)



    // Order the aggregated results
    const orderedResults = sortCombinations(aggregatedResults);
    console.log('orderedResults')
    console.log(orderedResults)
    console.log('manage level optiosn')
    console.log(manage_level_options)
    */

    /*
    let n = 0;
    const updatedPivotDataList = aggregatedResults.map(result => {
      const lastCombinationKey = Object.keys(result.combination).pop(); // Get the last key of the combination
      const rowLabel = result.combination[lastCombinationKey]; // Use the last combination value as row_label
      const sumOfConsumption = result.totalConsumptionAmount; // Directly use total_consumption
      n++;

      return {
        id: n-1,
        row_labels: rowLabel,
        sum_of_consumption: sumOfConsumption
      };
    });

    console.log('updated pivot data ilst');
    console.log(updatedPivotDataList);
    // Assuming setPivotDataList is the useState setter function
    setPivotDataList(updatedPivotDataList);
    */

  };



  // get all the entities
  const get_manage_levels = async (default_metadata_sheet_id) => {
    let payload = {};
    payload.sheet_id = default_metadata_sheet_id;

    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_default_schema_entities",
        request_payload: JSON.stringify(payload),
      },
    });
    console.log('this is manage level response')
    console.log(response)
    return JSON.parse(response.data.datalake);
  };

  // get pivot table data
  const get_pivot_table_data = async (default_metadata_sheet_id) => {
    let payload = {};
    payload.sheet_id = default_metadata_sheet_id;
    //payload.sheet_id = sheet_id;

    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_pivot_table_data",
        request_payload: JSON.stringify(payload),
      },
    });

    // if datalake null
    if (!response.data.datalake) {
      return [];
    }
    //console.log('this is get pivot table data')
    //console.log(response)
    const parsedData = JSON.parse(response.data.datalake);
    // Check if parsedData is not null or undefined
    if (parsedData) {
      console.log('parsedData is not null');

      // Check if the pipeline field exists and is not null
      if (parsedData.pipeline) {
        console.log('pipeline exists');
        
        // Check if the results field exists and is not null
        if (parsedData.results) {
          console.log('results exist');
          return parsedData.results;
        }
      } else {
        console.log('pipeline does not exist');

        // Since parsedData is already checked as not null, you can directly return it
        return parsedData;
      }
    }

    return [];


    //return JSON.parse(response.data.datalake);
  };

  // not used need to delete
  const getNewsList = async (payload) => {
    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_news_content",
        request_payload: JSON.stringify(payload),
      },
    });
    console.log('this is response')
    //console.log(response["data"]["datalake"])
    const breakdown_data = JSON.parse(response["data"]["datalake"]);
    console.log(breakdown_data);
    if (breakdown_data && breakdown_data.data && Array.isArray(breakdown_data.data)) {
      // Add an additional column with an empty string to each item
      const updatedBreakdownData = breakdown_data.data.map(item => ({
        ...item,
        year: "2024",
        region: "",
        entity_name: "",
        scope_1_consumption_amount: 0,
        scope_2_consumption_amount: 0, // Add the new property with an empty string
        scope_3_consumption_amount: 0, // Add the new property with an empty string
      }));

      // Replace the original data with the updated data
      breakdown_data.data = updatedBreakdownData;
    }
    return breakdown_data;
  };

  const generatePayload = (params) => {
    const { filter, pagination } = params;
    const query = {};
    if (filter?.category) {
      query.category = { $regex: filter.category, $options: "i" };
    }
    if (filter?.title) {
      query.title = { $regex: filter.title, $options: "i" };
    }
    const sortingResult = {};
    sortingResult[`${"published_date"}`] = -1;

    const payload = {
      filter: query,
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      sort: sortingResult,
    };
    return payload;
  };

  const loadNewsContent = async (payload) => {
    setLoadingNews(true);
    try {
      const requestPayload = generatePayload(payload);
      const { pagination } = payload;
      const dataList = await getNewsList(requestPayload);
      setPagination({ ...pagination, total: dataList.filteredCount });
      if (dataList.data?.length > 0) {
        setNewsList(dataList.data);
        return dataList;
      }
    } catch (e) {
      console.log("Error while loading dashboard news list", e);
      message.error("Error while loading dashboard news list", e);
      return null;
    } finally {
      setLoadingNews(false);
    }
  };

  const handleMenuItemClick = (e) => {
    e.stopPropagation();
    //e.domEvent.stopPropagation(); // Prevent the click event from closing the menu
  };

  const handleCheckBoxClick = (e) => {
    console.log('checkbox click')
    e.stopPropagation();
  };
  const handleManageColumnsCheckBoxClick = (e, name) => {
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      [name]: !prevVisibility[name], // Toggle the current valu
      //[name]: !prevVisibility[name], // Toggle the current valu
    }));
    console.log('checkbox click')

    e.stopPropagation();
  };

  useEffect(() => {
    load_data();
  }, []);

  const handleChangePagination = (pagination) => {
    //const payload = { filter: filter, pagination: pagination };
    //loadNewsContent(payload);
  };
  const filter_width = "200px";



  const options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];

  const handleCheckboxChange = (checked, value) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((item) => item !== value);

    setSelectedValues(newSelectedValues);
  };



  const handleSelect = () => {
    // This function intentionally does nothing
  };


  return (
    <PageWrap padding="0px 0px 0px 0px">
      <FiltersContainer>
        <FormWrapper>
              <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <div  className={`margin-5 ${styles.select}`} style={{ }}>
                  <StyledButton
                    type="custom"
                    //disabled={!taskDeployments?.length}
                    onClick={() => generateExportReport()}
                    style={{ display: "inline", borderRadius: "4px" }}
                  >
                    <ExportOutlined /> <span>Export Report</span>
                  </StyledButton>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <div  className={`margin-5 ${styles.select}`} style={{ width: '200px' }}>
                   <Dropdown overlay={manageYears} trigger={["click"]}>
                    <Select
                      showArrow
                      showSearch={false}
                      placeholder="Year"
                      mode="single"
                      open={false}
                    />
                    </Dropdown>
                </div>
                <div  className={`${styles.select}`} style={{ width: '200px', marginTop: '5px', marginBottom: '5px', marginLeft: '5px', marginRight: '5px'}}>
                  <Dropdown overlay={manageLevels} trigger={["click"]}>
                    <Select
                      showArrow
                      showSearch={false}
                      placeholder="Manage Levels"
                      open={false}
                      mode="single"
                    />
                  </Dropdown>
                </div>
                <div  className={`margin-5 ${styles.select}`} style={{ width: '200px'}}>
                    <Dropdown overlay={manageColumns} trigger={["click"]}>
                      <Select
                        showArrow
                        onSelect={handleSelect} // This will do nothing on select
                        showSearch={false}
                        placeholder="Manage Columns"
                        mode="single"
                        open={false}
                        dropdownStyle={{ display: 'none' }} // Ensure the dropdown is hidden
                      />
                    </Dropdown>
                </div>
              </div>
        </FormWrapper>
      </FiltersContainer>
      <OverviewDataTable
        loadingNewslist={loadingNews}
        loadingTableData={loadingTableData}
        pivotDataList={pivotDataList}
        pagination={pagination}
        columnVisibility={columnVisibility}
        handle_pivot_table_row_click={handle_pivot_table_row_click}
        onChangePagination={handleChangePagination}
      />
    </PageWrap>
  );
};

export default BreakdownPivotTable;
