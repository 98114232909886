import React from 'react';
import { Form, Input } from 'antd';

const ChangePasswordForm = ({
  form, onChange, fields, error,
}) => {
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Form
      labelCol={layout.labelCol}
      wrapperCol={layout.wrapperCol}
      form={form}
      name="change_password"
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name="old_password"
        label="Current Password"
        rules={[
          { required: true, message: 'Current Password is required.' },
          { min: 8, message: 'Password needs to be at least 8 characters long' },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        name="new_password"
        label="New Password"
        rules={[
          { required: true, message: 'New Password is required' },
          { min: 8, message: 'Password needs to be at least 8 characters long' },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        name="new_password_confirm"
        label="Confirm Password"
        rules={[
          { required: true, message: 'Please confirm your new password' },
          { min: 8, message: 'Password needs to be at least 8 characters long' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input type="password" />
      </Form.Item>
      {(error && error.message)
        ? (
          <p style={{ color: 'red' }}>
            {error.message}
          </p>
        )
        : ''}
    </Form>
  );
};

export default ChangePasswordForm;
