import React from "react";
import { Form, Row, Col, Typography, Divider } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {
  StyledButton,
  FormWrap,
  FormWrapper,
} from "../../../shared/commonStyles";
import DataSheetSetupTable from "../components/DataSheetSetupTable";
import { useLocation } from 'react-router-dom'

const { Title } = Typography;

interface DataSheetsSetupProps {
  loadDataSheets: boolean;
  dataSheetsList: DataSheet[];
  onClickNext: () => void;
  onClickPrevious: () => void;
  handleMultilineFormFlag: () => void;
  handleHideFields: () => void;
  handleShowNotes: () => void;
  handleInstructionsForm: () => void;
  handleMassUploadToggle: () => void;
  handleChangeDisplayFields: () => void;
  initialSurveyValues: any;
}

const DataSheetsSetup = ({
  loadDataSheets,
  dataSheetsList,
  onClickNext,
  onClickPrevious,
  handleMultilineFormFlag,
  handleHideFields,
  handleShowNotes,
  handleInstructionsForm,
  handleMassUploadToggle,
  handleChangeDisplayFields,
  initialSurveyValues,
}: DataSheetsSetupProps) => {
  const location = useLocation();
  const routeName = location.pathname;
  const routeRegex = /^\/management\/collect-data\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/update-mixed-survey$/;

  return (
    <FormWrap width="90%">
      <FormWrapper>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3}>Data Sheets Setup</Title>
            <Title level={4}>
              Select the data sheets required for the current data collection
              survey
            </Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              name="selectedDataSheets"
              label="Data Sheets"
              rules={[{ required: true, message: "Please select Data Sheet!" }]}
            >
              <DataSheetSetupTable
                loadDataSheets={loadDataSheets}
                dataSheetsList={dataSheetsList}
                handleMultilineFormFlag={handleMultilineFormFlag}
                handleHideFields={handleHideFields}
                handleShowNotes={handleShowNotes}
                handleInstructionsForm={handleInstructionsForm}
                handleMassUploadToggle={handleMassUploadToggle}
                handleChangeDisplayFields={handleChangeDisplayFields}
                initialSurveyValues={initialSurveyValues}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="start">
                <StyledButton
                  className="margin-5"
                  onClick={onClickPrevious}
                  type="custom"
                >
                  <LeftOutlined /> <span>Previous</span>
                </StyledButton>

                <StyledButton
                  className="margin-5"
                  onClick={onClickNext}
                  type="custom"
                >
                  <span>{routeName === '/management/collect-data/create-mixed-survey' || routeRegex.test(routeName) ? 'Continue to step 6' : 'Continue to step 4'}</span> <RightOutlined />
                </StyledButton>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default DataSheetsSetup;
