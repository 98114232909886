import React,{ useState, useEffect} from "react";
import { 
  Typography,
  Spin    
} from "antd";
import { Auth} from "aws-amplify";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export const PasswordlessVerify = ({code_answer, awsmobile}) => { 
    const [isSignInError, setIsSignInError] = useState(false);
    const [loadingVerify, setLoadingVerifyEmail] = useState(false);
    
    async function answerCustomChallenge(code) {
      
        try {
          setLoadingVerifyEmail(true)           
          // Send the answer to the User Pool
          // This will throw an error if it’s the 3rd wrong answer
      
          const user = JSON.parse(localStorage.getItem("cognitoUser"));
          // rehydrate the CognitoUser
          const authenticationData = {
            Username: user.username,
          };
          const authenticationDetails =
            new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
          const poolData = {
            UserPoolId: awsmobile.aws_user_pools_id,
            ClientId: awsmobile.aws_user_pools_web_client_id,
          };
          const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          const userData = {
            Username: user.username,
            Pool: userPool,
          };
          const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
          // After this set the session to the previously stored user session
          cognitoUser.Session = user.session;
          // rehydrating the user and sending the auth challenge answer directly will not
          // trigger a new email
          cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");        
          
          await Auth.sendCustomChallengeAnswer(cognitoUser, code);            
          // It we get here, the answer was sent successfully,
          // but it might have been wrong (1st or 2nd time)
          // So we should test if the user is authenticated now
      
          // This will throw an error if the user is not yet authenticated:
          await Auth.currentSession();
          localStorage.setItem(
              "loggedIn",
              true
          );           
          window.location.href = window.location.origin;            
          
        } catch (_a) {
          setIsSignInError(true)
          console.log("Apparently the user did not enter the right code", _a);
        }finally {
          setLoadingVerifyEmail(false);
      }
    }

    useEffect(() => {
      answerCustomChallenge(code_answer)
    }, [code_answer]);
    
    return(
      <>
       
        {isSignInError ?    
          <>
            <Typography.Title level={2} style={{color:'#f00',marginTop:'10px'}}>Warning!</Typography.Title>
            <p className="color-grey">Verify login request is not successful. Please re-check your email for a secure verify link.</p>
          </>
          :
          <>
            <Typography.Title level={2} style={{color:'#fff',marginTop:'10px'}}>Processing your request...</Typography.Title>
            <p className="color-grey">Please wait while we are processing your request.....</p>
            <Spin spinning={loadingVerify}></Spin>
          </>
        }        
      </>
    );
  }