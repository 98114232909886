import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import {
  CommonRadio,
  CommonSelect,
  FiltersContainer,
  FormWrapper,
  PageWrap,
  StyledButton,
  StyledTabs,
} from "../../../../shared/commonStyles";
import { DateSelect, LocationSelect } from "../../../reportingPage/Components";
import {
  FileUploadProps,
  Filter,
  HistoryItem,
  ListItems,
  SelectedStandardsItem,
} from "../Container/SustainabilityStandardContainer";
import {
  LinkOutlined,
  MoreOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  YearMonthDay24HourFormat,
  fileUploadSize,
  fileUploadTypes,
  staticImagesList,
} from "../../../../shared/constants";
import {
  getDataLakeFiles,
  updateStandardComments,
} from "../../../../services/standards";

import { BusinessUnitSelect } from "../../../reportingPage/Components/BusinessUnitSelect/BusinessUnitSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { COLORS } from "../../../../shared/colors";
import { CommentsList } from "../../../../components/ReportingData/CommentsList/CommentsList";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { SustainabilityStandardHistoryComponent } from "./SustainabilityStandardHistoryComponent";
import { downloadDataLakeFileHandler } from "../../../../shared/commonFunctions";
// @ts-ignore
import styled from "styled-components";
import styles from "./SustainabilityStandardsComponent.styles.scss";
import { useSize } from "ahooks";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../UpdateStandard/components/ConfirmModal";
import { Comment } from "../Container/SustainabilityStandardContainer";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 20px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: ${({ isSelected }: any) =>
    isSelected ? "#a68dfb" : "#716A88"};
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
`;

const SearchInput = styled(Input)`
  height: 40px;
  background-color: #625d75;
  border-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
    border-right-width: 2px;
    z-index: 1;
  }

  .ant-input {
    background-color: transparent;
    margin-left: 5px;
    font-size: 16px;
    color: #b1afbc;
  }

  &.customInput {
    background-color: #625d75;
    font-size: 16px;
    border-color: transparent;

    &::placeholder {
      background-color: #625d75;
      font-size: 16px;
      border-color: transparent;
    }
  }
`;

const layoutStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  marginTop: "10px",
  marginBottom: "10px",
  marginLeft: "10px",
  marginRight: "10px",
  minWidth: "768px",
};

const emptyLayoutStyle: React.CSSProperties = {
  backgroundColor: "#3A3C5A",
  marginTop: "10px",
  marginBottom: "10px",
  marginLeft: "10px",
  marginRight: "10px",
  minWidth: "768px",
};

const contentStyle: React.CSSProperties = {
  display: "flex",
  backgroundColor: "#3A3C5A",
  width: "100%",
  height: "100%",
};

const bodyContainer: React.CSSProperties = {
  flex: 1,
  backgroundColor: "transparent",
  marginTop: "15px",
  display: "flex",
  fontFamily: "Roboto",
};

const emptyBodyContainer: React.CSSProperties = {
  flex: 1,
  backgroundColor: "#3A3C5A",
  justifyContent: "center",
  marginTop: "15px",
  display: "flex",
  fontFamily: "Roboto",
};

const moreButton: React.CSSProperties = {
  display: "flex",
  fontSize: "32px",
  lineHeight: "1px",
};

const checkboxContainer: React.CSSProperties = {
  width: "400px",
  minWidth: "250px",
  backgroundColor: "#423A5A",
  display: "flex",
  flexDirection: "column",
};

const loaderBox: React.CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "30px",
};

const itemsContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
};

const selectedDataContainer: React.CSSProperties = {
  backgroundColor: "#A68DFB",
  margin: "6px",
  borderRadius: "4px",
  minHeight: "106px",
  color: "#FFFFFF",
  padding: "12px",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
};

const dataContainer: React.CSSProperties = {
  backgroundColor: "#2D273F",
  margin: "6px",
  borderRadius: "4px",
  minHeight: "106px",
  color: "#FFFFFF",
  padding: "12px",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
};

const checkbox: React.CSSProperties = {
  flexDirection: "row",
  display: "flex",
  flex: 1,
  fontSize: "14px",
  justifyContent: "space-between",
};

const selectAllCheckbox: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  color: "white",
  marginRight: "17px",
  marginTop: "5px",
  marginBottom: "3px",
};

const selectAllBox: React.CSSProperties = {
  display: "flex",
  marginLeft: "8px",
};

const checkboxGeneral: React.CSSProperties = {
  flexDirection: "row",
  display: "flex",
  fontSize: "14px",
  justifyContent: "space-between",
};

const dataName: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "7px",
  cursor: "pointer",
};

const getCheckBoxValueStyle = (isSelected: boolean): React.CSSProperties => {
  return {
    fontWeight: "normal",
    flex: 1,
    display: "flex",
    flexDirection: "row-reverse",
    color: isSelected ? "#FFFFFF" : "#B1AFBC",
  };
};

const paginationBox: React.CSSProperties = {
  justifyContent: "center",
  display: "flex",
  color: "#FFFFFF",
  fontSize: "14px",
  fontFamily: "Roboto",
  fontWeight: "lighter",
  marginRight: "10px",
  marginLeft: "10px",
  minHeight: "60px",
  cursor: "pointer",
};

const paginationText: React.CSSProperties = {
  display: "flex",
  marginRight: "10px",
  marginLeft: "10px",
};

const contentContainer: React.CSSProperties = {
  minWidth: "550px",
  marginLeft: "5px",
  backgroundColor: "#3A3C5A",
  display: "flex",
  justifyContent: "center",
  padding: "30px",
  flexDirection: "column",
};

const contentHeader: React.CSSProperties = {
  fontSize: "20px",
  fontFamily: "Roboto",
  lineHeight: "30px",
  color: "#FFFFFF",
  textAlign: "center",
};

const aiButtonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};

const saveButton: React.CSSProperties = {
  marginTop: "15px",
  paddingRight: "16px",
  paddingLeft: "16px",
};

const uploadButton: React.CSSProperties = {
  paddingRight: "16px",
  paddingLeft: "16px",
};

const uploadIcon: React.CSSProperties = {
  marginRight: "10px",
  height: "13px",
  width: "12px",
};

const innerContentContainer: React.CSSProperties = {
  flex: 1,
  width: "500px",
  padding: "10px",
};

const aiButton: React.CSSProperties = {
  display: "flex",
  backgroundColor: "transparent",
  borderColor: "#FFFFFF",
  borderWidth: "1px",
  fontFamily: "Roboto",
  fontWeight: "lighter",
  lineHeight: "10px",
  borderRadius: "2px",
  position: "relative",
};

const attachFileHeader: React.CSSProperties = {
  fontFamily: "Roboto",
  fontSize: "16px",
  color: "#FFFFFF",
  marginTop: "15px",
};

const attachFileSubHeader: React.CSSProperties = {
  fontSize: "14px",
  color: "#B1AFBC",
  fontWeight: "300",
  paddingBottom: "10px",
  borderBottom: "2px solid #4F4669",
};

const tabContainer: React.CSSProperties = {
  backgroundColor: "#2D273F",
  width: "550px",
  minWidth: "375px",
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  alignItems: "center",
};

const loaderErrorBox: React.CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  marginTop: "20px",
};

const tabStyle: React.CSSProperties = {
  display: "flex",
  alignContent: "space-between",
  width: "100%",
  color: "white",
};

const updateContainer: React.CSSProperties = {
  alignSelf: "flex-start",
  justifySelf: "flex-end",
};

const dataType: React.CSSProperties = {
  alignSelf: "flex-start",
  justifySelf: "flex-end",
  fontSize: "12px",
  backgroundColor: "#569CE6",
  borderRadius: "100px",
  paddingRight: "8px",
  paddingLeft: "8px",
  fontWeight: "lighter",
  marginBottom: "5px",
  marginRight: "2px",
};

const selectedDataType: React.CSSProperties = {
  alignSelf: "flex-start",
  justifySelf: "flex-end",
  fontSize: "12px",
  backgroundColor: "#FFFFFF",
  borderRadius: "100px",
  paddingRight: "8px",
  paddingLeft: "8px",
  fontWeight: "lighter",
  marginBottom: "5px",
  marginRight: "2px",
  color: "#A68DFB",
};

const tabContentContainer: React.CSSProperties = {
  flex: 1,
  display: "flex",
  width: "100%",
};

const commentBox: React.CSSProperties = {
  width: "100%",
};

const headerStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  height: "70px",
  justifyContent: "space-between",
  justifyItems: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const dropdownStyle: React.CSSProperties = {
  display: "flex",
  marginRight: "10px",
  flex: 1,
  minWidth: "100px",
};

const searchInputContainerStyle: React.CSSProperties = {
  flex: 1,
  alignSelf: "center",
};

const searchBox: React.CSSProperties = {
  display: "flex",
  flex: 1,
  marginRight: "35px",
};

const subHeader: React.CSSProperties = {
  height: "53px",
  width: "100%",
  display: "flex",
  alignContent: "center",
  marginTop: "5px",
};

const filtersContainer: React.CSSProperties = {
  height: "100%",
  width: "80%",
  display: "flex",
  minWidth: "600px",
  marginTop: "8px",
};

const generateReportContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
};

const downArrowIcon: React.CSSProperties = {
  marginLeft: "7px",
  height: "7px",
  width: "12px",
};

const editorStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  margin: "0 auto",
};

const loadingIndicator: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "10px",
  transform: "translateY(-50%)",
};

export type Item = {
  _id: {
    $oid: string;
  };
  standard_id: string;
  name: string;
  type: string[];
  isSelected: boolean;
  keyValue: string;
  response: any;
  isSelectedCheckbox: boolean;
  onClick(value: any, data: any[]): void;
  onCheckboxClick(value: any, data: any[]): void;
};

export type Pagination = {
  currentPage: number;
  totalPage: number;
  onPreviousClick(): void;
  onNextClick(): void;
};

export type SustainabilityStandardComponentProps = {
  isLoading: boolean;
  isLoadingStandardData: boolean;
  hasError: boolean;
  hasSelectedStandards: boolean;
  searchString: string;
  tab: string;
  data: Item[];
  totalItems: Item[];
  selectedItems: SelectedStandardsItem[];
  pagination: Pagination;
  moreButtons: any;
  businessUnits?: ListItems;
  orgLocations?: ListItems;
  reportPeriods?: ListItems;
  standards?: string[];
  users?: string;
  statuses?: string;
  selectedData?: string;
  rowData: any;
  formData: any;
  businessUnitsList: any[];
  orgLocationsList: any[];
  reportPeriodsList: any[];
  standardsList: any[];
  usersList: any[];
  statusesList: any[];
  actionStatus: string;
  publishedStatus: boolean;
  selectAll: boolean;
  fileProps: FileUploadProps;
  confirmModalShowing: boolean;

  isLoadingAI?: boolean;
  isUpdateLoading: boolean;

  onStatusChanged(status: string): void;
  onChangeBusinessUnits(businessUnits: ListItems): void;
  onChangeOrgLocations(orgLocations: ListItems): void;
  onChangeReportPeriods(reportPeriods: ListItems): void;
  onChangeStandards(standards: string[]): void;
  onChangeUsers(users: string): void;
  onChangeStatuses(statuses: string): void;
  onTabChange(tab: string): void;
  onSearchValueChange(value: string): void;

  onCommentsUpdate(values: any): void;
  onFormDataChange(formData: string): void;
  onSearchClick(): void;
  onSelectAllClick(): void;
  onUpdateClick(): void;
  onConfirmUpdate(values: any): void;
  onConfirmModalClose(): void;
  onPublishClick(): void;

  onAddNewStandardClick(): void;
  onGenerateAI(): void;
  onDeleteLakeComment(comment: Comment): void;
  onEditLakeComment(comment: Comment, data: string): void;
};

export const SustainabilityStandardComponent: React.FC<
  SustainabilityStandardComponentProps
> = ({
  searchString,
  tab,
  data,
  totalItems,
  selectedData,
  selectedItems,
  businessUnits,
  orgLocations,
  reportPeriods,
  standards,
  users,
  statuses,
  pagination,
  rowData,
  formData,

  selectAll,
  moreButtons,
  actionStatus,
  publishedStatus,
  fileProps,
  isLoading,
  isLoadingStandardData,
  hasError,
  hasSelectedStandards,
  businessUnitsList,
  orgLocationsList,
  reportPeriodsList,
  standardsList,
  usersList,
  isUpdateLoading,
  statusesList,
  isLoadingAI,
  confirmModalShowing,
  onCommentsUpdate,

  onTabChange,
  onSearchValueChange,
  onSearchClick,

  onChangeBusinessUnits,
  onChangeStandards,
  onStatusChanged,
  onChangeStatuses,
  onChangeUsers,
  onChangeOrgLocations,
  onChangeReportPeriods,

  onSelectAllClick,
  onUpdateClick,
  onConfirmUpdate,
  onConfirmModalClose,
  onFormDataChange,
  onPublishClick,
  onAddNewStandardClick,
  onGenerateAI,
  onEditLakeComment,
  onDeleteLakeComment,
}) => {
  const [commentText, setCommentText] = useState<string>("");

  const [versionInfo, setVersionInfo] = useState("");
  const [currentVersion, setCurrentVersion] = useState(
    rowData?.history?.length
  );
  useEffect(() => {
    setCurrentVersion(rowData?.history?.length);
  }, [rowData?.history?.length]);

  const [latestVersion, setLatestVersion] = useState<HistoryItem>();
  const handleCommentChange = (value: any) => {
    setCommentText(value.target.value);
  };

  const handleCommentSubmit = (commentText: any) => {
    onCommentsUpdate(commentText);
    setCommentText("");
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      onSearchClick();
    }
  };
  const onChangeHistoryVersion = (historyVersionData: any) => {
    historyVersionData?.response
      ? onFormDataChange(historyVersionData?.response)
      : historyVersionData?.notes &&
        onFormDataChange(historyVersionData?.notes);

    setVersionInfo("");
    setCurrentVersion(historyVersionData.version_number);
  };
  const pageType = "audit";
  const numericFields: any = [];
  const sheets: any = [];
  const size = useSize(document.querySelector("body"));

  const dropdownMenuItems = (
    <Menu>
      <Menu.Item key="autofill" onClick={() => onTabChange("autofill")}>
        Autofill from KPIs
      </Menu.Item>
      <Menu.Item key="lastYearData" onClick={() => onTabChange("lastYearData")}>
        Last year data
      </Menu.Item>
    </Menu>
  );

  const checkVariable = (variable: any): string => {
    if (typeof variable === "string") {
      return variable;
    } else {
      return "";
    }
  };
  const conditionalStyles = isLoadingAI ? { marginRight: "20px" } : {};
  return (
    <PageWrap>
      <Layout style={layoutStyle}>
        <FiltersContainer>
          <FormWrapper>
            <Form>
              {" "}
              <Row>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    mode="multiple"
                    showArrow
                    showSearch
                    style={{ width: "100%" }}
                    value={standards}
                    placeholder="All Standards"
                    onChange={(value: any) => onChangeStandards(value)}
                  >
                    {standardsList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    showSearch
                    style={{ width: "100%" }}
                    value={businessUnits?.name}
                    onChange={(value: string) => {
                      const selectedItem = businessUnitsList?.find(
                        (item) => item.name === value
                      );
                      if (selectedItem) {
                        onChangeBusinessUnits(selectedItem);
                      }
                    }}
                  >
                    {businessUnitsList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    showSearch
                    style={{ width: "100%" }}
                    value={orgLocations?.name}
                    onChange={(value: string) => {
                      const selectedItem = orgLocationsList?.find(
                        (item) => item.name === value
                      );
                      if (selectedItem) {
                        onChangeOrgLocations(selectedItem);
                      }
                    }}
                  >
                    {orgLocationsList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    showSearch
                    style={{ width: "100%" }}
                    value={reportPeriods?.name}
                    onChange={(value: string) => {
                      const selectedItem = reportPeriodsList?.find(
                        (item) => item.name === value
                      );
                      if (selectedItem) {
                        onChangeReportPeriods(selectedItem);
                      }
                    }}
                  >
                    {reportPeriodsList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    showSearch
                    style={{ width: "100%" }}
                    value={users}
                    onChange={(value: string) => {
                      onChangeUsers(value);
                    }}
                  >
                    {usersList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
                <Col style={dropdownStyle}>
                  <CommonSelect
                    showSearch
                    style={{ width: "100%" }}
                    value={statuses}
                    onChange={(value: string) => {
                      onChangeStatuses(value);
                    }}
                  >
                    {statusesList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </CommonSelect>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </FiltersContainer>
        <div style={subHeader}>
          <div style={searchBox}>
            <div style={searchInputContainerStyle}>
              <SearchInput
                className="customInput"
                placeholder="Search for sustainability standards"
                value={searchString}
                prefix={<SearchOutlined style={{ color: "white" }} />}
                onKeyUp={handleKeyUp}
                onChange={(e: any) => onSearchValueChange(e.target.value)}
              />
            </div>
            <StyledButton type="primary" onClick={onSearchClick}>
              Search
            </StyledButton>
          </div>

          <div style={generateReportContainer}>
            <Tooltip
              title={"Select items from the list to generate the report"}
            >
              <StyledButton
                onClick={() => {}}
                type="custom"
                disabled={!selectedItems.length}
              >
                <Link
                  to="/reporting/sustainability-standards/generate-report"
                  state={{
                    dataLakeMapReport: selectedItems,
                    report_period: null,
                    location_id: null,
                    business_unit_id: null,
                  }}
                >
                  Generate Report
                </Link>
              </StyledButton>
            </Tooltip>
            <Dropdown overlay={moreButtons} trigger={["click"]}>
              <StyledButton type="primary" style={moreButton}>
                ...
              </StyledButton>
            </Dropdown>
          </div>
        </div>{" "}
        {data.length || isLoading ? (
          <>
            <div style={bodyContainer}>
              <div style={checkboxContainer}>
                <div style={itemsContainer}>
                  {isLoading ? (
                    <div style={loaderBox}>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      {" "}
                      {data.length > 0 ? (
                        <>
                          <div style={selectAllCheckbox}>
                            {" "}
                            Select All{" "}
                            <span style={selectAllBox}>
                              <Checkbox
                                checked={selectAll}
                                onClick={onSelectAllClick}
                              />
                            </span>
                          </div>
                          {data.map((item, index) => (
                            <div
                              style={
                                item.isSelected
                                  ? selectedDataContainer
                                  : dataContainer
                              }
                              key={index}
                              onClick={() => item.onClick(item, totalItems)}
                            >
                              <div style={checkbox}>
                                <Tooltip title={item.name}>
                                  <p style={dataName}>{item.name}</p>
                                </Tooltip>
                                <Checkbox
                                  checked={item.isSelectedCheckbox}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    item.onCheckboxClick(item, totalItems);
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {item.type &&
                                  item.type.map((framework, frameIndex) => (
                                    <div
                                      style={
                                        item.isSelected
                                          ? selectedDataType
                                          : dataType
                                      }
                                      key={frameIndex}
                                    >
                                      {framework}
                                    </div>
                                  ))}
                              </div>
                              <div style={checkboxGeneral}>
                                <Tooltip title={item.keyValue}>
                                  <p style={dataName}>{item.keyValue}</p>
                                </Tooltip>
                                <p
                                  style={getCheckBoxValueStyle(item.isSelected)}
                                >
                                  {" "}
                                  General
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div style={loaderBox}>
                          {hasError && (
                            <h1 style={attachFileHeader}>
                              Unable to fetch standards
                            </h1>
                          )}
                          {/* {data && data.length === 0 && <h1 style={attachFileHeader}>
                          No data
                        </h1>} */}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {pagination.totalPage !== 0 && !isLoading && !hasError && (
                  <p style={paginationBox}>
                    <span onClick={pagination.onPreviousClick}>
                      {pagination.currentPage === 1 ? (
                        <img src={staticImagesList.arrowLeftDisable} />
                      ) : (
                        <img src={staticImagesList.arrowLeft} />
                      )}
                    </span>
                    <span style={paginationText}>
                      {pagination.currentPage} / {pagination.totalPage}
                    </span>
                    <span onClick={pagination.onNextClick}>
                      {pagination.currentPage === pagination.totalPage ? (
                        <img src={staticImagesList.arrowRightDisable} />
                      ) : (
                        <img src={staticImagesList.arrowRight} />
                      )}
                    </span>
                  </p>
                )}
              </div>
              <div style={contentContainer}>
                {isLoading && (
                  <div style={loaderErrorBox}>
                    <Spin />
                  </div>
                )}
                {hasError && (
                  <div style={loaderErrorBox}>
                    <h1 style={attachFileHeader}>Unable to fetch data</h1>
                  </div>
                )}
                {!isLoading && !hasError && (
                  <>
                    {!isLoadingStandardData && (
                      <>
                        {hasSelectedStandards ? (
                          <>
                            {" "}
                            <Row>
                              <Col
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                // style={{ padding: "40px 40px" }}
                              >
                                <Spin spinning={isLoadingStandardData}>
                                  {versionInfo && (
                                    <div
                                      style={{
                                        padding: "10px",
                                        background: "#302A43",
                                        borderRadius: "2px",
                                        borderLeft: "6px solid #A68DFB",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <Row justify="space-between">
                                        <Col span={18}>
                                          <Title
                                            level={5}
                                            style={{
                                              marginLeft: "5px",
                                              marginTop: "7px",
                                              color: "#fff",
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            You are currently viewing Version{" "}
                                            {versionInfo}
                                          </Title>
                                        </Col>
                                        <Col
                                          span={6}
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <StyledButton
                                            bgcolor={COLORS.martinique}
                                            bordercolor={COLORS.white}
                                            type="custom"
                                            size="small"
                                            onClick={() =>
                                              onChangeHistoryVersion(
                                                latestVersion
                                              )
                                            }
                                          >
                                            Return back
                                          </StyledButton>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Spin>
                              </Col>
                            </Row>
                            <div style={innerContentContainer}>
                              <p style={contentHeader}>{selectedData}</p>
                              <div style={aiButtonContainer}>
                                <Button
                                  type="primary"
                                  onClick={() => onGenerateAI()}
                                  style={aiButton}
                                  disabled={isLoadingAI}
                                >
                                  <span style={conditionalStyles}>
                                    Generate with AI
                                  </span>
                                  {isLoadingAI && (
                                    <Spin style={loadingIndicator} />
                                  )}
                                </Button>
                              </div>
                              {/* <Col span={23}> */}
                              <div style={editorStyle}>
                                <CKEditor
                                  editor={DecoupledDocumentEditor}
                                  data={checkVariable(formData)}
                                  onReady={(editor: any) => {
                                    if (editor?.ui) {
                                      editor.ui.view.editable.element.parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.view.editable.element
                                      );
                                    }
                                  }}
                                  onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    onFormDataChange(data);
                                  }}
                                  onBlur={() => {
                                    onUpdateClick();
                                  }}
                                />
                              </div>
                            </div>
                            <Row style={{ marginTop: "15px" }}>
                              <Col span={24}>
                                <Spin spinning={fileProps.isLoading}>
                                  <Title className="color-white" level={4}>
                                    Attach related files
                                  </Title>
                                  <Title className="color-white" level={5}>
                                    Attach files that are related to this field
                                  </Title>
                                  <Divider className={styles.divider} />
                                  <Upload
                                    customRequest={({ file, onSuccess }) => {
                                      if (onSuccess) {
                                        onSuccess("ok");
                                      }
                                    }}
                                    beforeUpload={fileProps.onBeforeUpload}
                                    fileList={fileProps.files}
                                    className={`upload-list-inline ${
                                      size &&
                                      size?.width <= 900 &&
                                      styles.mobileView
                                    }`}
                                    listType={
                                      size && size?.width <= 900
                                        ? "picture"
                                        : "text"
                                    }
                                    iconRender={() => <PaperClipOutlined />}
                                    multiple={false}
                                    onRemove={
                                      fileProps.onRemoveFileFromListHandler
                                    }
                                  >
                                    <StyledButton
                                      type="custom"
                                      icon={<UploadOutlined />}
                                    >
                                      Uploads
                                    </StyledButton>
                                  </Upload>
                                  <div className="data-lake-files-wrap">
                                    {fileProps.uploadedFilesList &&
                                      fileProps.uploadedFilesList?.map(
                                        (item: any, index: any) => {
                                          const fileName =
                                            item && item?.split("/").pop();
                                          return (
                                            <Row key={index}>
                                              <Col span={24}>
                                                <StyledButton
                                                  type="custom"
                                                  hoverbgcolor="transparent"
                                                  bgcolor="transparent"
                                                  bordercolor="transparent"
                                                  color="#A68DFB"
                                                  className="margin-2"
                                                  icon={<LinkOutlined />}
                                                  onClick={() =>
                                                    fileProps.onDownloadFileHandler(
                                                      item
                                                    )
                                                  }
                                                  style={{ padding: "0" }}
                                                >
                                                  {fileName}
                                                </StyledButton>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                  </div>
                                </Spin>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            {/* <div style={{display: "flex", flexDirection: "column", alignSelf: "center", alignItems: "center"}}>
                        <Text style={{color: "white", fontSize: "14px"}}>
                          There are no frameworks added to your account
                        </Text>
                        <StyledButton style={{width: "180px"}} type="primary" onClick={() => {onAddNewStandardClick()}}>
                          <PlusCircleOutlined /> <span>Add New Standard</span>
                        </StyledButton>
                  </div> */}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div style={tabContainer}>
                {isLoading && (
                  <div style={loaderErrorBox}>
                    <Spin />
                  </div>
                )}
                {hasError && (
                  <div style={loaderErrorBox}>
                    <h1 style={attachFileHeader}>Unable to fetch data</h1>
                  </div>
                )}
                {!isLoading && !hasError && hasSelectedStandards && (
                  <>
                    <div style={tabStyle}>
                      <StyledTabs
                        activeKey={tab}
                        onChange={onTabChange}
                        style={tabStyle}
                      >
                        <TabPane
                          tab="Information & Actions"
                          key="information-actions"
                        />
                        <TabPane tab="History" key="history" />
                        <TabPane tab="Comments" key="comments" />
                      </StyledTabs>
                      <Dropdown overlay={dropdownMenuItems} placement="bottom">
                        <div>
                          <MoreOutlined
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              marginTop: "14px",
                              color: "white",
                            }}
                          />
                        </div>
                      </Dropdown>
                    </div>
                    <div style={tabContentContainer}>
                      {tab === "information-actions" && (
                        <div>
                          <Title
                            level={3}
                            style={{ fontWeight: "500px", color: "white" }}
                          >
                            Information
                          </Title>
                          <Space direction="vertical">
                            {rowData?.description && (
                              <p className="color-grey">
                                {" "}
                                {rowData.description}
                              </p>
                            )}
                            <div>
                              {rowData?.resource_urls?.map(
                                (val: any, index: any) => (
                                  <Tooltip key={index} title={val.name}>
                                    <Row>
                                      <Col span={24}>
                                        <a
                                          className="padding-0"
                                          href={val.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "#7F5FEE",
                                            width: "300px",
                                          }}
                                        >
                                          Link to
                                          {val.name ? ` "${val.name}" ` : ""}
                                          {val.page_num
                                            ? `,  Page No : ${val.page_num}`
                                            : " "}
                                        </a>
                                      </Col>
                                    </Row>
                                  </Tooltip>
                                )
                              )}
                            </div>
                          </Space>
                          <Title
                            level={4}
                            style={{
                              marginTop: 25,
                              fontWeight: "500",
                              lineHeight: "45px",
                              color: "white",
                            }}
                          >
                            Actions
                          </Title>
                          <Title level={5} style={{ color: "white" }}>
                            Status
                          </Title>
                          <Space
                            direction="vertical"
                            style={{ paddingBottom: 10 }}
                          >
                            <p className="color-grey">
                              If data collection is completed, set the status to{" "}
                              {`"Closed"`} in order to prepare the data for
                              distribution.
                            </p>

                            <Divider
                              style={{
                                backgroundColor: "#3A3C5A",
                                marginTop: "0",
                              }}
                            />

                            <Radio.Group
                              style={{ background: "transparent" }}
                              onChange={(e: any) => {
                                onStatusChanged(e.target.value);
                              }}
                              value={actionStatus}
                              buttonStyle="solid"
                              className="radioGroup"
                            >
                              <CommonRadio bgColor="#7F5FEE" value="open">
                                Open
                              </CommonRadio>
                              <CommonRadio bgColor="#7F5FEE" value="pending">
                                Pending
                              </CommonRadio>
                              <CommonRadio bgColor="#7F5FEE" value="escalated">
                                Escalated
                              </CommonRadio>
                              <CommonRadio bgColor="#7F5FEE" value="closed">
                                Closed
                              </CommonRadio>
                            </Radio.Group>

                            <Title
                              level={5}
                              style={{ marginTop: "20px", color: "white" }}
                            >
                              Publishing
                            </Title>
                            <p className="color-grey">
                              If you are ready to generate report with the data
                              and the data has passed your audit, please toggle
                              the switch to {`"Published"`} to include data in
                              the report generation.
                            </p>

                            <Divider
                              style={{
                                backgroundColor: "#3A3C5A",
                                marginTop: "0",
                              }}
                            />
                            <Switch
                              checkedChildren="Published"
                              unCheckedChildren="Unpublished"
                              onChange={onPublishClick}
                              checked={publishedStatus}
                            />
                          </Space>
                        </div>
                      )}
                      {tab === "history" && (
                        <div style={{ width: "100%" }}>
                          {rowData && rowData?.history?.length > 0 && (
                            <SustainabilityStandardHistoryComponent
                              rowData={rowData}
                              currentVersion={currentVersion}
                              onChangeHistoryHandler={onChangeHistoryVersion}
                              setVersionInfo={setVersionInfo}
                              setLatestVersion={setLatestVersion}
                            />
                          )}
                        </div>
                      )}
                      {tab === "comments" && (
                        <div style={commentBox}>
                          <CommentsList
                            rowData={rowData}
                            addComment={handleCommentSubmit}
                            editLakeComment={onEditLakeComment}
                            deleteLakeComment={onDeleteLakeComment}
                          />
                        </div>
                      )}
                      {tab === "autofill" &&
                        (!numericFields.length ? (
                          <div>
                            {" "}
                            <Title
                              style={{ display: "flex", color: "white" }}
                              level={4}
                            >
                              No numeric fields to autofill
                            </Title>
                          </div>
                        ) : (
                          <Spin>
                            {" "}
                            <Title level={4}>
                              Autofill standards data from KPIs
                            </Title>{" "}
                            {numericFields.map((item: any) => {
                              return (
                                <Row key={item.key}>
                                  <Checkbox
                                    disabled={pageType === "audit"}
                                    value={item.key}
                                    checked={item.checked}
                                  >
                                    <Text>{item?.value.title}</Text>
                                  </Checkbox>
                                  {item.checked && (
                                    <>
                                      <Select
                                        placeholder="Please select a datasheet!"
                                        showArrow
                                        style={{
                                          width: "100%",
                                          margin: "10px",
                                        }}
                                      >
                                        {sheets.map((v: any) => (
                                          <Select.Option
                                            key={v.key}
                                            value={v.key}
                                          >
                                            {v.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                      <Select
                                        placeholder="Please select a KPI!"
                                        showArrow
                                        style={{
                                          width: "100%",
                                          margin: "10px",
                                        }}
                                      >
                                        {item.kpis.map((v: any) => (
                                          <Select.Option
                                            key={v.key}
                                            value={v.key}
                                          >
                                            {v.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </>
                                  )}
                                </Row>
                              );
                            })}
                            <StyledButton
                              type="custom"
                              disabled={
                                !numericFields.find(
                                  (v: any) => v.selectedKpi.analyticResult
                                ) || pageType === "audit"
                              }
                              style={{ marginTop: "10px", marginLeft: "0" }}
                            >
                              Autofill from KPIs
                            </StyledButton>
                          </Spin>
                        ))}
                      {tab === "lastYearData" && (
                        <>
                          {/* <Row justify="space-between">
                    <Col span={7}>
                      <BusinessUnitSelect
                        key={1}
                        selectedBusinessUnit={''}
                        businessUnits={businessUnits}
                        setSelectedBusinessUnit={''}
                        visibleAddBtn={false}
                        selectWidth={"100%"}
                        className="standardsLastDataSelector"
                        setBusinessUnits={undefined}
                        extraOptions={undefined}
                        changeFilter={undefined} />
                    </Col>
                    <Col span={7}>
                      <LocationSelect
                        key={2}
                        setRange={''}
                        value={''}
                        visibleAddBtn={false}
                        locations={''}
                        selectWidth={"100%"}
                        className="standardsLastDataSelector"
                        setLocation={undefined}
                        extraOptions={undefined}
                        changeFilter={undefined} />
                    </Col>
                    <Col span={7}>
                      <DateSelect
                        key={3}
                        setRange={''}
                        value={''}
                        timeperiods={reportPeriods}
                        visibleAddBtn={false}
                        selectWidth={"100%"}
                        className="standardsLastDataSelector"
                        setTimePeriod={undefined}
                        extraOptions={undefined}
                        changeFilter={undefined} />
                    </Col>
                  </Row>
                  <Spin >
                    {!lastSelectedStandard ? (
                      <div style={{ marginTop: "20px" }}>
                        No data found for the previous period, location, or
                        business unit
                      </div>
                    ) : (
                      <Space
                        direction="vertical"
                        style={{ marginTop: "20px" }}
                      >
                        <div key={lastSelectedStandard._id.$oid}>
                          <Title
                            level={3}
                            style={{
                              fontWeight: "500px",
                              lineHeight: "45px",
                            }}
                          >
                            {lastSelectedStandard.theme} -{" "}
                            {lastSelectedStandard.displayedPeriod}
                          </Title>
                          <div
                            style={{
                              margin: "10px 0px",
                            }}
                          >
                            <div>
                              {" "}
                              Category: {lastSelectedStandard.category}
                            </div>
                            <div> Metric: {lastSelectedStandard.metric}</div>
                            <div>
                              {" "}
                              Standard:{" "}
                              {lastSelectedStandard.standard_name[0]}
                            </div>
                            <div>
                              {" "}
                              Code: {lastSelectedStandard.specific_standard}
                            </div>
                          </div>
                          {lastSelectedStandard.displayedData.map((v: any) => {
                            return v.type !== "array" ? (
                              <Col>
                                <Checkbox
                                  disabled={
                                    v.data === "NA" || pageType === "audit"
                                  }
                                  key={v.key}
                                  value={v.key}
                                  checked={v.checked}
                                >
                                  <Title level={4}>{v.value?.title}</Title>
                                </Checkbox>
                                <div style={{ marginLeft: 24 }}>
                                  <Title level={4}>{v?.data}</Title>
                                  <p>{v.value?.description}</p>
                                </div>
                              </Col>
                            ) : (
                              <></>
                            );
                          })}{" "}
                          <StyledButton
                            type="custom"
                            style={{
                              marginTop: "10px",
                              marginLeft: "0",
                            }}
                            disabled={
                              !lastSelectedStandard?.displayedData.find(
                                (v: any) => v.checked === true
                              ) || pageType === "audit"
                            }
                          >
                            Autofill last Data
                          </StyledButton>
                        </div>
                      </Space>
                    )}{" "}
                  </Spin> */}
                        </>
                      )}
                    </div>
                    <div style={updateContainer}>
                      <StyledButton type="primary" onClick={onUpdateClick}>
                        Update
                      </StyledButton>
                    </div>
                    {confirmModalShowing && (
                      <ConfirmModal
                        visible={confirmModalShowing}
                        onSubmit={onConfirmUpdate}
                        onCancel={onConfirmModalClose}
                        submitting={isUpdateLoading}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Col
            span={24}
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
          >
            <Row justify="center">
              <Title
                level={3}
                style={{ color: "#B1AFBC", fontWeight: "normal" }}
              >
                There are no frameworks added to your account.
              </Title>
            </Row>

            <>
              <Row justify="center">
                <Title
                  level={4}
                  style={{ color: "#B1AFBC", fontWeight: "normal" }}
                >
                  Click on the button below to create your first standard or
                  refine your search criteria.
                </Title>
              </Row>
              <Row justify="center">
                <StyledButton
                  type="primary"
                  onClick={() => {
                    onAddNewStandardClick();
                  }}
                >
                  <PlusCircleOutlined /> <span>Add New Standard</span>
                </StyledButton>
              </Row>
            </>
          </Col>
        )}
      </Layout>
    </PageWrap>
  );
};
