import React, { useEffect, useState } from "react";
import { Switch, Spin, message} from "antd";
import { FormWrapper } from "../../../../shared/commonStyles";

const ChangeStatus = ({
  rowData,
  onChangeStatus
}) => {
  const [status, setStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    try{
      setLoader(true)
      setStatus(rowData?.status === 'ACTIVE' ? true : false)
    }catch(e){
      message.error('Some problem occured when changing status')
    }finally{
      setLoader(false)
    }
    
  }, [rowData]);
  return (
    <Spin spinning={loader}>
      <FormWrapper>
        <Switch
          checked={status}
          onChange={(value) => {
            setStatus(value) 
            onChangeStatus(value, rowData)
          }}
        />
        {status ? " Active" : " Paused"}
      </FormWrapper>
    </Spin>
  );
};

export default ChangeStatus;
