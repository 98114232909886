import React from 'react'
import {CommonModal } from "../../../shared/commonStyles";
import { Typography } from 'antd';
const Text =Typography
const ConfirmModal = ({isVisible,onConfirm,onClose,confirmLoading, description}) => {
  return (
    <>
    <CommonModal
        title="Confirmation"
        visible={isVisible}
        onOk={onConfirm}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okText='Delete'  
      >
           <Text>{description}</Text>

      </CommonModal>
    </>
  )
}

export default ConfirmModal