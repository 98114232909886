import React, { useState, useEffect } from "react";
import { message, Select, Row, Col, Spin, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import * as queries from "../../../graphql/queries";
import { API } from "aws-amplify";
import ProductCardB from "../CarbonOffsets/Components/ProductCard/ProductCardB";
import {
  PageWrap,
  StyledSelect,
  FiltersWrap,
  FiltersContainer,
  TableWrap,
  StyledButton,
  CommonSelect,
} from "./../../../shared/commonStyles";

const CountriesList = require("../../../shared/Json/Countries.json");

const CarbonOffsetsProducts = () => {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [oldProducts, setOldProducts] = useState([]);
  const [filters, setFilters] = useState({
    country: "",
    technology_type: "",
    page: 1,
  });
  const [noMoreRecord, setNoMoreRecords] = useState(false);
  const [loadingMoreRecords, setLoadingMoreRecords] = useState(false);
  const [loadingTechnologyTypes, setLoadingTechnologyTypes] = useState(false);
  const [uniqueTechnolgies, setUniqueTechnologies] = useState([]);
  const [mechanism, setMechanism] = useState(null);
  const projectTypes = [
    { value: "removal", name: "Removal" },
    { value: "avoidance", name: "Avoidance" },
  ];

  const navigate = useNavigate();
  const loadProductsList = async (requestPayload) => {
    try {
      setNoMoreRecords(null);
      if (requestPayload?.page === 1) {
        setLoadingProducts(true);
      } else {
        setLoadingMoreRecords(true);
      }

      const response = await API.graphql({
        query: queries["decarbonization"],
        variables: {
          request_type: "fetch_projects",
          request_payload: JSON.stringify(requestPayload),
        },
      });
      const list = JSON.parse(response["data"]["decarbonization"]);
      setOldProducts(list);
      if (list) {
        if (requestPayload?.page === 1) {
          setProducts(list);
        } else {
          list?.length < 1 && setNoMoreRecords(true);
          setProducts((prev) => [...prev, ...list]);
        }
      }
    } catch (error) {
      console.log("Error while load products!", error);
      message.error("Error while load products!");
    } finally {
      setLoadingProducts(false);
      setLoadingMoreRecords(false);
    }
  };

  const loadTechnologyTypesList = async () => {
    try {
      setLoadingTechnologyTypes(true);
      const response = await API.graphql({
        query: queries["decarbonization"],
        variables: { request_type: "technology_types" },
      });
      const list = response["data"]["decarbonization"];
      const technologies = list && JSON.parse(list);
      const uniqueTechnologies = technologies.reduce((arr, val) => {
        if (
          "parent_technology_type" in val &&
          !arr.find((item) => item?.name === val?.parent_technology_type?.name)
        ) {
          arr.push(val["parent_technology_type"]);
        }
        return arr;
      }, []);
      setUniqueTechnologies(uniqueTechnologies);
      //    setTechnologiesTypes(technologies);
    } catch (error) {
      console.log("Error while load technologies types!", error);
      message.error("Error while load technologies types!");
    } finally {
      setLoadingTechnologyTypes(false);
    }
  };

  useEffect(() => {
    loadProductsList(filters);
  }, [filters]);

  useEffect(() => {
    if (mechanism) {
      const newProducts = oldProducts?.filter(
        (item) => item.mechanism === mechanism
      );
      setProducts(newProducts);
    } else {
      setProducts(oldProducts);
    }
  }, [mechanism]);

  useEffect(() => {
    if (oldProducts.length > 0 && mechanism) {
      const newProducts = oldProducts?.filter(
        (item) => item.mechanism === mechanism
      );
      setProducts(newProducts);
    }
  }, [oldProducts]);
  useEffect(() => {
    loadTechnologyTypesList();
  }, []);

  const onClickLoadMore = () => {
    setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const onClickProductCard = (id) => {
    navigate(`/carbon/carbon-offsets/${id}/details`);
  };

  const onChangeFilters = (value, filterName) => {
    if (filterName === "mechanism") {
      setMechanism(value);
    } else {
      const filterValue = value ? value : "";
      setFilters((prev) => ({ ...prev, [filterName]: filterValue, page: 1 }));
    }
  };

  const resetFiltering = () => {
    setMechanism(null);
    setFilters({
      country: "",
      technology_type: "",
      page: 1,
    });
  };

  return (
    <>
      <PageWrap>
        <FiltersContainer>
          <Row className="margin-5">
            <Col xs={24} sm={24} md={24} lg={24}>
              <FiltersWrap>
                <CommonSelect
                  style={{ width: 250, marginRight: 10, marginBottom: 10 }}
                  placeholder={`Select Technology`}
                  onChange={(value) =>
                    onChangeFilters(value, "technology_type")
                  }
                  allowClear={true}
                  value={
                    filters.technology_type === ""
                      ? null
                      : filters.technology_type
                  }
                  loading={loadingTechnologyTypes}
                >
                  {uniqueTechnolgies?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item?.slug}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </CommonSelect>
                <CommonSelect
                  style={{ width: 250, marginRight: 10, marginBottom: 10 }}
                  placeholder={`Select Country`}
                  onChange={(value) => onChangeFilters(value, "country")}
                  value={filters.country === "" ? null : filters.country}
                  allowClear={true}
                >
                  {CountriesList?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item?.code}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </CommonSelect>
                <CommonSelect
                  style={{ width: 250, marginRight: 10, marginBottom: 10 }}
                  placeholder={`Select Project Types`}
                  onChange={(value) => onChangeFilters(value, "mechanism")}
                  value={mechanism === "" ? null : mechanism}
                  allowClear={true}
                >
                  {projectTypes?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </CommonSelect>
                <StyledButton type="custom" onClick={() => resetFiltering()}>
                  Reset
                </StyledButton>
              </FiltersWrap>
            </Col>
          </Row>
        </FiltersContainer>
        <Spin spinning={loadingProducts}>
          <TableWrap>
            <Row gutter={[16, 16]}>
              {products?.length ? (
                products?.map((item, index) => {
                  return (
                    <Col key={index} xs={24} sm={12} md={8} lg={8}>
                      <ProductCardB
                        data={item}
                        onClickHandler={onClickProductCard}
                      />
                    </Col>
                  );
                })
              ) : !loadingProducts && !loadingMoreRecords ? (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row justify="center">
                    <Alert type="error" message="No record found!" banner />
                  </Row>
                </Col>
              ) : null}
            </Row>
            {noMoreRecord ? (
              <Row justify="center">
                <Alert type="error" message="No more records!" banner />
              </Row>
            ) : products?.length >= 10 ? (
              <Row justify="center">
                <StyledButton
                  type="custom"
                  loading={loadingMoreRecords}
                  onClick={() => onClickLoadMore()}
                >
                  Load More
                </StyledButton>
              </Row>
            ) : null}
          </TableWrap>
        </Spin>
      </PageWrap>
    </>
  );
};

export default CarbonOffsetsProducts;
