import React, { useEffect, useState } from "react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import { PageWrap, StyledButton } from "../../shared/commonStyles";
import { Core } from "@grapecity/activereports";
import { getSavedReports, removeSavedReports } from "../../services/reports";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Row, Space, Spin, Card, Col, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import { encodeUrlName } from "../../shared/commonFunctions";

Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY);

const { Title } = Typography;
const ReportViewer = () => {
  const [reports, setReports] = useState(null);
  const [loadingReports, setLoadingReports] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState(0);

  const navigate = useNavigate();
  const getAllReports = async () => {
    try {
      setLoadingReports(true);

      const _reports = await getSavedReports();
      setReports(_reports.reverse());
    } catch (error) {
      message.error(`Error while getting reports : ${error}`);
    } finally {
      setLoadingReports(false);
    }
  };

  useEffect(() => {
    getAllReports();
  }, [submitRefresh]);

  const handleDelete = (data) => {
    setRowData(data);
    setShowConfirmModal(true);
  };

  const onConfirmDelete = async () => {
    if (!rowData) return;
    try {
      setDeleteLoader(true);
      const payload = {
        _id: rowData?._id,
      };
      await removeSavedReports(payload);
      setSubmitRefresh(submitRefresh + 1);
    } catch (e) {
      message.error(`Some problem occured while fetching data ${e}`);
    } finally {
      setDeleteLoader(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Spin spinning={loadingReports}>
          {showConfirmModal && (
            <ConfirmModal
              title="Confirmation"
              visible={showConfirmModal}
              okText="Delete"
              onConfirm={onConfirmDelete}
              onClose={() => setShowConfirmModal(false)}
              description="Are you sure you want to delete this report?"
              ConfirmLoader={deleteLoader}
            />
          )}
          <>
            {reports?.length && (
              <Row gutter={[16, 16]}>
                {reports.map((item, index) => {
                  return (
                    <Col span={6} key={index}>
                      <Card
                        style={{
                          backgroundColor: "#2d273f",
                          padding: "20px",
                          height: "100%",
                        }}
                        bodyStyle={{ padding: "0" }}
                      >
                        <Title level={4} className="color-white">
                          {item?.report_data?.displayName}
                        </Title>
                        <div
                          style={{
                            backgroundColor: "#403950",
                            padding: "56px",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <img src="/Icon.png" />
                        </div>
                        <p className="color-grey">
                          {item?.report_data?.description}
                        </p>
                        <StyledButton
                          type="custom"
                          bgcolor="#443A59"
                          bordercolor="#443A59"
                          style={{ marginLeft: 0 }}
                          onClick={() =>
                            navigate(
                              `/reporting/saved-reports/${
                                item?._id?.$oid
                              }/${encodeUrlName(
                                item?.report_data?.displayName?.replace(
                                  "/",
                                  " "
                                )
                              )}/view`
                            )
                          }
                        >
                          <EyeOutlined /> View
                        </StyledButton>
                        <StyledButton
                          type="custom"
                          bgcolor="#443A59"
                          bordercolor="#443A59"
                          onClick={() =>
                            navigate(
                              `/reporting/saved-reports/${
                                item?._id?.$oid
                              }/${encodeUrlName(
                                item?.report_data?.displayName?.replace(
                                  "/",
                                  " "
                                )
                              )}/update`,
                              {
                                state: { action: "update" },
                              }
                            )
                          }
                        >
                          <EditOutlined /> Edit
                        </StyledButton>
                        <StyledButton
                          type="custom"
                          bgcolor="#443A59"
                          bordercolor="#443A59"
                          onClick={() => handleDelete(item)}
                        >
                          <DeleteOutlined /> Delete
                        </StyledButton>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default ReportViewer;
