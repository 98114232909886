import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../../shared/commonStyles";
import { Space, Form, Row, Col, message, Steps, Progress, Spin } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import {
  addDocumentReview,
  updateDocumentReview,
  getDocumentByid,
} from "../../../services/documentReview";
const { Step } = Steps;

const ManageDocumentReview = () => {
  const [step, setStep] = useState(1);
  const [loading, setloading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [action, setAction] = useState("create");
  const [btnLoading, setBtnLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });
  const { document_id: document_id } = useParams();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const loadDocumentById = async () => {
    try {
      if (!document_id) return;
      setloading(true);

      const data = await getDocumentByid(document_id);
      if (data) {
        setRowData(data);
        setTableData(data?.sections);
        setAction("update");
      }
    } catch (error) {
      console.log("Error while fetching document", error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (!document_id) return;
    loadDocumentById();
  }, [document_id]);

  useEffect(() => {
    if (!rowData) return;
    if (rowData) {
      const { name, description } = rowData;
      const initialData = {
        name: name,
        description,
      };
      setInitialValues(initialData);
      form.setFieldsValue(initialData);
    }
  }, [rowData]);

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const onCreateDocumentReview = async (tableData) => {
    if (!tableData) return;

    try {
      setBtnLoading(true);
      const { name, description } = initialValues;
      const payload = {
        name: name,
        description,
        sections: tableData,
      };
      let response = null;
      if (action === "create") {
        response = await addDocumentReview(payload);
        payload._id = { $oid: response?.$oid };
        console.log("response payload", payload);
        setRowData(payload);
      } else {
        payload._id = rowData?._id;
        response = await updateDocumentReview(payload);
      }
      if (response) {
        setStep(step + 1);
      } else {
        message.error("Error while saving document");
      }
    } catch (error) {
      message.error(`Error while creating documment ${error}`);
    } finally {
      setBtnLoading(false);
    }
  };
  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return <Step1 onClickNext={onClickNext} initialValues={initialValues} />;
    } else if (step === 2) {
      return (
        <Step2
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          onCreateDocumentReview={onCreateDocumentReview}
          tableData={tableData}
          action={action}
          loading={btnLoading}
        />
      );
    } else if (step === 3) {
      return <Step3 action={action} document={rowData} />;
    } else {
      console.log("completed");
    }
  };

  return (
    <>
      <PageWrap>
        <Spin spinning={loading}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row gutter={[16, 16]} className="exitAlign">
              <Col lg={1} md={24} sm={24} xs={24}>
                <StyledButton
                  style={{ zIndex: "1" }}
                  className="margin-5"
                  type="custom"
                  onClick={() => navigate("/reporting/document-review")}
                  hovercolor="#A68DFB"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#fff"
                >
                  <ArrowLeftOutlined /> <span>Exit</span>
                </StyledButton>
              </Col>
              <Col
                className="user-flow-steps-wrap color-white"
                lg={23}
                md={24}
                sm={24}
                xs={24}
              >
                <StyledSteps current={step - 1} onChange={onStepChange}>
                  <Step
                    className="stepper-wrapper "
                    key={1}
                    title={<span>General Information</span>}
                  />
                  <Step
                    className="stepper-wrapper "
                    key={2}
                    title={<span>Table of Content</span>}
                  />
                </StyledSteps>
              </Col>
            </Row>
            <Progress
              percent={step * 50}
              strokeColor={"#A68DFB"}
              showInfo={false}
            />
            <Row>
              <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
                <div className="form-wrap">
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={(data) => onChangeFieldsData(data)}
                  >
                    {showSectionsAccordingSteps(step)}
                  </Form>
                </div>
              </Col>
            </Row>
          </Space>
        </Spin>
      </PageWrap>
    </>
  );
};
export default ManageDocumentReview;
