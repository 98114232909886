import React, { useEffect, useState } from "react";
import {
  addSurveys,
  removeSurveys,
  updateSurveys,
} from "./../../services/surveys";
import { useLocation, useNavigate } from "react-router-dom";

import Banner from "../../shared/banner";
import ConfirmModal from "./../../components/ConfirmModal/ConfirmModal";
import { PageWrap } from "../../shared/commonStyles";
import { ReportingPageProvider } from "../reportingPage/context/ReportingPageContext";
import SearchMetaEntityModal from "./Components/SearchMetaEntityModal";
import SurveysDataTable from "./Components/SurveysDataTable";
import { checkPermission } from "./../../services/permissions";
import { message } from "antd";
import { useChangeMetadataSettings } from "../../hooks/useChangeMetadataSettings";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { useGetMetadataUserSettings } from "../../hooks/useGetMetadataUserSettings";
import { useSize } from "ahooks";
import {
  addTaskDeployment,
  getTaskDeploymentsBySurveyId,
} from "../../services/taskDeployment";
import RemindAllUsers from "../deploySurvey/Components/RemindAllUsers";
import { encodeUrlName } from "../../shared/commonFunctions";

const CollectData = () => {
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshAllSurveyData, setRefreshAllSurveyData] = useState(1);
  const [loaderDeletingSurvey, setLoaderDeletingSurvey] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMetaDataUserInfoModal, setShowMetaDataUserInfoModal] =
    useState(false);
  const size = useSize(document.querySelector("body"));
  const [permissionsData, setPermissionsData] = useState([]);
  const [remindUserModal, setRemindUserModal] = useState(false);
  const navigate = useNavigate();
  const setParams = useNavigate();
  const { state } = useLocation();
  const { checkRolesPermission } = useCheckRolesPermissions();
  const userSettings = useGetMetadataUserSettings();
  const changeMetadataSettings = useChangeMetadataSettings();
  const isUser =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:elevateduser"]);
  if (state) {
    if (state.showModal === true) {
      const btn = document.getElementById("createNewDataCollectionBtn");
      if (btn) {
        btn.click();
      }
      setParams({
        state: {
          showModal: false,
        },
      });
    }
  }

  const handleViewUserFlow = async (rowData) => {
    setRowData(rowData);
    if (isUser) {
      if (rowData.defaultMetaDataSheetId) {
        if (rowData.entityId) {
          navigate(
            `/management/collect-data/${
              rowData?.surveyManagementID
            }/${encodeUrlName(rowData?.name.replace("/", " "))}/view-survey`,
            {
              state: {
                taskDeploymentId: rowData.id,
                metadataRecordId: rowData.entityId,
              },
            }
          );
        } else {
          setShowMetaDataUserInfoModal(true);
        }
      } else {
        navigate(
          `/management/collect-data/${
            rowData?.surveyManagementID
          }/${encodeUrlName(rowData?.name.replace("/", " "))}/view-survey`,
          {
            state: {
              taskDeploymentId: rowData.id,
            },
          }
        );
      }
    } else {
      const payload = {
        permissionType: "REFERENCE_DATA",
        itemId: rowData?.defaultMetaDataSheetId,
      };
      const permissions = await checkPermission(payload);

      setPermissionsData(permissions);

      if (
        (rowData.survey_type === "data_sheets" ||
          rowData.survey_type === "mixed") &&
        rowData?.defaultMetaDataSheetId
      ) {
        if (permissions.length === 1) {
          handleChangeSettings(permissions[0]);
          navigate(
            `/management/collect-data/${rowData?.id}/${encodeUrlName(
              rowData?.name.replace("/", " ")
            )}/view-survey`
          );
        } else {
          setShowMetaDataUserInfoModal(true);
        }
      } else {
        navigate(
          `/management/collect-data/${rowData?.id}/${encodeUrlName(
            rowData?.name.replace("/", " ")
          )}/view-survey`
        );
      }
    }
  };
  const handleChangeSettings = async (value, searchEntityName) => {
    try {
      if (rowData) {
        await changeMetadataSettings(
          rowData.defaultMetaDataSheetId,
          value,
          searchEntityName || ""
        ).then((res) => {
          res.length && onFinishMateDataInfo();
        });
      }
    } catch (error) {
      console.log("Something went wrong!", error);
    }
  };

  const onFinishMateDataInfo = () => {
    setShowMetaDataUserInfoModal(false);
    const surveyId = rowData?.surveyManagementID || rowData?.id;
    if (surveyId) {
      isUser
        ? navigate(
            `/management/collect-data/${surveyId}/${encodeUrlName(
              rowData?.name.replace("/", " ")
            )}/view-survey`,
            {
              state: {
                taskDeploymentId: rowData.id,
              },
            }
          )
        : navigate(
            `/management/collect-data/${surveyId}/${encodeUrlName(
              rowData?.name.replace("/", " ")
            )}/view-survey`
          );
    }
  };

  const onRefreshAllSurveyData = () => {
    setRefreshAllSurveyData(refreshAllSurveyData + 1);
  };

  const handleEditSurvey = (rowData) => {
    const surveyId = rowData?.id;
    if (surveyId) {
      if (rowData.survey_type === "standards") {
        navigate(
          `/management/collect-data/${surveyId}/update-standards-survey`
        );
      } else if (rowData.survey_type === "data_sheets") {
        navigate(
          `/management/collect-data/${surveyId}/update-datasheet-survey`
        );
      } else if (rowData.survey_type === "kpi") {
        navigate(`/management/collect-data/${surveyId}/update-kpi-survey`);
      } else {
        navigate(`/management/collect-data/${surveyId}/update-mixed-survey`);
      }
    }
  };

  const handleRemindUsers = async (rowData) => {
    setRowData(rowData);
    setRemindUserModal(true);
  };

  const handleCheckName = (surveyName, surveysList) => {
    const escapedName = surveyName.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const matchRegex = new RegExp(
      `^(${escapedName})$|^(${escapedName}\\s?\\(\\d\\))$`
    );
    const newNumber = surveysList.filter((record) =>
      matchRegex.test(record.name)
    ).length;
    return newNumber;
  };

  const handleDuplicateSurvey = async (rowData, surveysList) => {
    try {
    const {
      survey_type,
      status,
      name,
      survey_order,
      description,
      popup_title,
      info_message,
      search_field_label,
      search_field_label_value,
      defaultMetaDataSheetId,
      email_subject,
      email_body,
      email_reminder_subject,
      email_reminder_body,
      reminder_interval,
      entityIds,
    } = rowData;
    const nameNumber = handleCheckName(name, surveysList);
    const newEmailBody = email_body.replace(name, `${name} (${nameNumber})`);

    const duplicatedData = {
      survey_type,
      status,
      name: `${name} (${nameNumber})`,
      survey_order,
      description,
      popup_title,
      info_message,
      search_field_label,
      search_field_label_value,
      defaultMetaDataSheetId,
      email_subject,
      email_body: newEmailBody,
      email_reminder_subject,
      email_reminder_body,
      reminder_interval,
      ...(entityIds && { entityIds }),
    };
      const surveyResponse = await addSurveys(duplicatedData);
      const newSurveyData = surveyResponse.data.createSurveyManagement;
      const taskDeploymentsResponse = await getTaskDeploymentsBySurveyId(
        rowData.id
      );
      if (taskDeploymentsResponse?.items?.length) {
        await Promise.all(
          taskDeploymentsResponse?.items?.map(async (item) => {
            const { email, entityId, description, assignedGroup } = item;
            const newItem = {
              email,
              ...(entityId && { entityId }),
              ...(description && { description }),
              ...(assignedGroup && { assignedGroup }),
              surveyManagementID: newSurveyData.id,
              completion_date: null,
              status: "Not contacted",
            };
            await addTaskDeployment(newItem);
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      onRefreshAllSurveyData();
    }
  };

  const handleDeleteSurvey = (rowData) => {
    setRowData(rowData);
    setShowConfirmModal(true);
  };

  const handleSurveyStatus = async (status, rowData) => {
    setLoading(true);
    const payload = { id: rowData.id, status: status ? "ACTIVE" : "INACTIVE" };
    const response = await updateSurveys(payload);
    if (response) {
      setLoading(false);
      onRefreshAllSurveyData();
      message.success("Status changed successfully!");
    }
  };

  const onConfirmSurveyDelete = async () => {
    if (!rowData) return;
    try {
      setLoaderDeletingSurvey(true);
      const payload = {};

      payload.id = rowData.id;
      const response = await removeSurveys(payload);
      onRefreshAllSurveyData();
      message.success("Removed successfully!");
      setShowConfirmModal(false);
    } catch (error) {
      console.log("error while deleting document.", error);
      message.error("error while deleting document.");
    } finally {
      setLoaderDeletingSurvey(false);
    }
  };

  const handleDeploySurvey = (rowData) => {
    const surveyId = rowData?.id;
    if (surveyId) {
      navigate(
        `/management/collect-data/${surveyId}/${encodeUrlName(
          rowData?.name.replace("/", " ")
        )}/deploy-survey`,
        {
          state: {
            surveyStatus: rowData.status,
            surveyName: rowData?.name.replace("/", " "),
            surveyId: surveyId,
            defaultMetadataSheetId: rowData.defaultMetaDataSheetId,
            entityIds: rowData.entityIds,
          },
        }
      );
    }
  };

  return (
    <ReportingPageProvider>
      <PageWrap style={{ paddingLeft: size && size?.width <= 600 && 32 }}>
        <Banner />
        {showConfirmModal && (
          <ConfirmModal
            title="Confirmation"
            description="Are you sure you want to delete this survey?"
            okText="Delete"
            visible={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={onConfirmSurveyDelete}
            ConfirmLoader={loaderDeletingSurvey}
          />
        )}
        {showMetaDataUserInfoModal && (
          <SearchMetaEntityModal
            userSettings={userSettings}
            permissions={permissionsData}
            rowData={rowData}
            visible={showMetaDataUserInfoModal}
            onChangeSettings={handleChangeSettings}
            onClose={() => setShowMetaDataUserInfoModal(false)}
          />
        )}
        {remindUserModal && (
          <RemindAllUsers
            title="Confirmation"
            visible={remindUserModal}
            description="Are you sure you want to remind all non complete users?"
            okText="Remind Users"
            onClose={() => setRemindUserModal(false)}
            surveyId={rowData?.id}
            setRemindUserModal={setRemindUserModal}
          />
        )}
        <SurveysDataTable
          handleViewUserFlow={handleViewUserFlow}
          refreshAllSurveyData={refreshAllSurveyData}
          handleDuplicateSurvey={handleDuplicateSurvey}
          handleRemindUsers={handleRemindUsers}
          handleEditSurvey={handleEditSurvey}
          handleDeleteSurvey={handleDeleteSurvey}
          handleSurveyStatus={handleSurveyStatus}
          handleDeploySurvey={handleDeploySurvey}
          setLoading={setLoading}
          loading={loading}
        />
      </PageWrap>
    </ReportingPageProvider>
  );
};

export default CollectData;
