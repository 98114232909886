import React, { useState } from "react";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./EmissionInfoPopover.module.scss";

interface EmissionInfoPopoverProps {
  data: FactorExtended;
}

export const EmissionInfoPopover = ({ data }: EmissionInfoPopoverProps) => {
  const emissionInfoContent = (
    <div className={styles.emissionInfoBox}>
      <h4 className={styles.emissionTitle}>Description</h4>
      <p>{data.description}</p>
      <h4 className={styles.emissionTitle}>Source</h4>
      <a href={data.source_link} target="_blank">
        {data.source}
      </a>
      <h4 className={styles.emissionTitle}>Region</h4>
      <p>{data.region}</p>
      <h4 className={styles.emissionTitle}>Unit</h4>
      <p>{data.unit}</p>
    </div>
  );

  return (
    <>
      <Popover
        overlayClassName="popoverContent"
        autoAdjustOverflow
        className={styles.infoIcon}
        placement="bottomRight"
        content={emissionInfoContent}
        trigger="hover"
        arrowPointAtCenter
      >
        <InfoCircleOutlined />
      </Popover>
    </>
  );
};
