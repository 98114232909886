import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { getUserGroups } from "./users";

export const listAuditManagements = async () => {
  const response = await API.graphql({
    query: queries["listAuditManagements"],
  });
  return response["data"]["listAuditManagements"]["items"];
};

export const getAuditManagements = async (id) => {
  const response = await API.graphql({
    query: queries["getAuditManagement"],
    variables: { id: id },
  });
  return response["data"]["getAuditManagement"];
};

export const createAuditManagement = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const owner = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group, owner };

  const response = await API.graphql({
    query: mutations.createAuditManagement,
    variables: { input: newPayload },
  });
  return response;
};

export const updateAuditManagement = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const owner = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group, owner };

  const response = await API.graphql({
    query: mutations.updateAuditManagement,
    variables: { input: newPayload },
  });
  return response;
};

export const sendTaskReminder = async (type, audit_type, priority, status) => {
  const requestPayload = { request_type: type };
  if (audit_type) {
    requestPayload.audit_type = audit_type;
  }
  if (priority) {
    requestPayload.priority = priority;
  }
  if (status) {
    requestPayload.status = status;
  }

  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: requestPayload,
  });
  return response["data"]["sendTaskReminder"];
};
export const sendUserReminderEmail = async (type, id) => {
  const requestPayload = { request_type: type, audit_id: id };

  console.log("requestPayload", requestPayload);

  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: requestPayload,
  });
  return response["data"]["sendTaskReminder"];
};

export const listStandardAudits = async (payload) => {
  let response = null;
  if (payload) {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_standards_audit",
        request_payload: JSON.stringify(payload),
      },
    });
  } else {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_standards_audit",
        request_payload: JSON.stringify(payload),
      },
    });
  }

  return JSON.parse(response.data.datalake);
};

export const getStandardAuditById = async (auditId) => {
  const allAudits = await listStandardAudits();
  return allAudits?.find((item) => item._id.$oid === auditId);
};

export const listDataLakeAuditFiles = async (dataId) => {
  const response = await API.graphql({
    query: queries["dataLakeAuditFiles"],
    variables: { request_type: "AUDIT_LIST", data_id: dataId },
  });
  return response["data"]["dataLakeAuditFiles"];
};

export const uploadDataLakeAuditFile = async (dataId, fileName) => {
  const response = await API.graphql({
    query: queries["dataLakeAuditFiles"],
    variables: {
      request_type: "AUDIT_UPLOAD",
      data_id: dataId,
      version: 1,
      file_name: fileName,
    },
  });
  return response["data"]["dataLakeAuditFiles"];
};

export const getAssignedAuditorList = async (item) => {
  const assignedGroups = JSON.parse(item?.assigned_groups);
  let assignedUsers = JSON.parse(item?.assigned_users);
  if (assignedGroups?.length > 0) {
    const payload = {
      filter: { _id: { $oid: assignedGroups }, status: "ACTIVE" },
    };
    const groups = await getUserGroups(payload);
    const usersList = groups?.map((item) => item?.group_users)?.flat();
    assignedUsers = [...assignedUsers, ...usersList];
  }
  return assignedUsers;
};
