import React, { useState } from "react";
import { Input, Form, Row,Col } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
import {
  FormWrapper,
  StyledButton,
  CommonModal,
  StyledTitle,
} from "./../../../../shared/commonStyles";
const configEditor =  {
  fontSize: {
      options: [ 11, 12, 14, 16,18,20,22,24,26,28,30,32,34,36,38,40],
      supportAllValues: true
  }
};

const ReportTitleModal = ({ visible, onSubmit, onCancel, submitting }) => {
  const [form] = Form.useForm();
  const [title,setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [primary, setPrimary] = useState("#3E2982");
  const [secondary, setSecondary] = useState("#27132F");
  const [reportTitleColor, setReportTitleColor] = useState("#3E2982");
  const [reportDescriptionColor, setReportDescriptionColor] = useState("#3E2982");
  const [tableHeaderTextColor,setTableHeaderTextColor] = useState("#3E2982");
  const [topicTextColor,setTopicTextColor] = useState("#3E2982");

  const onOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ ...values, title,subtitle, primary, secondary,reportTitleColor,reportDescriptionColor,tableHeaderTextColor,topicTextColor });
    } catch (e) {
      console.error("Validation failed.", e);
    }
  };

  const initialValues = {
    metricColumnTitle: "SASB Metric",
    reportingColumnTitle: "2021 Reporting",
  };

  return (
    <CommonModal
      width={1000}
      title="GENERATE A REPORT"
      footer={null}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={submitting}
      centered
    >
      <FormWrapper>
        <div>
          <StyledTitle level={5}>Fill in information about the report</StyledTitle>
        </div>
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ message: "Please enter a title for your report" }]}
          >
            <div className="ckSmallHeight">
            <CKEditor
              editor={DecoupledDocumentEditor}
              data={title}
              config={configEditor}
              onReady={(editor) => {
              if (editor?.ui) {
                editor.ui.view.editable.element.parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.view.editable.element
                );
              }
            }}
            onChange={(_, editor) => {
              const data = editor.getData();
              setTitle(data);
              const values = form.getFieldsValue();
              form.setFieldsValue({...values,title : data});
            }}
          />
          </div>
          </Form.Item>
          Description of report
          <div  className="ckMediumHeight">
          <CKEditor
            editor={DecoupledDocumentEditor}
            config={configEditor}
            data={subtitle}
            onReady={(editor) => {
              if (editor?.ui) {
                editor.ui.view.editable.element.parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.view.editable.element
                );
              }
            }}
            onChange={(_, editor) => {
              const data = editor.getData();
              setSubtitle(data);
            }}
          />
          </div>
          <Form.Item
            style={{ paddingTop: 20 }}
            name="metricColumnTitle"
            label="Name of Column #1 (Metrics)"
            rules={[
              {
                required: true,
                message: "Please enter a metric column title for your report",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reportingColumnTitle"
            label="Name of Column #2 (reporting information)"
            rules={[
              {
                required: true,
                message:
                  "Please enter a reporting column title for your report",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div>
            <StyledTitle level={5}>Select a color</StyledTitle>
          </div>
          <Row gutter={[16,16]}>
             <Col span={6}>
                    <label htmlFor="primary">Main heading:</label>
                    <div style={{ width: 150 }}>
                      <input
                      
                        style={{ width: 110 }}
                        type="color"
                        id="primary"
                        name="primary"
                        value={primary}
                        onChange={(e) => setPrimary(e.target.value)}
                      />
                    </div>
              </Col>
              <Col span={6}>
                  <label htmlFor="secondary">Table heading:</label>
                  <div style={{ width: 150 }}>
                    <input
                      style={{ width: 110 }}
                      type="color"
                      id="secondary"
                      name="secondary"
                      value={secondary}
                      onChange={(e) => setSecondary(e.target.value)}/>
                  </div>
              </Col>
              <Col span={6}>
                  <label htmlFor="primary">Table heading text:</label>
                  <div style={{ width: 150 }}>
                    <input
                      style={{ width: 110 }}
                      type="color"
                      id="primary"
                      name="primary"
                      value={tableHeaderTextColor}
                      onChange={(e) => setTableHeaderTextColor(e.target.value)}
                    />
                  </div>
             </Col>
             <Col span={6}>
                  <label htmlFor="primary">Report topics text:</label>
                  <div style={{ width: 150 }}>
                    <input
                      style={{ width: 110 }}
                      type="color"
                      id="primary"
                      name="primary"
                      value={topicTextColor}
                      onChange={(e) => setTopicTextColor(e.target.value)}
                    />
                  </div>
             </Col>
              <Col span={6}> 
                    <label htmlFor="primary">Report title:</label>
                    <div style={{ width: 150 }}>
                      <input
                      
                        style={{ width: 110 }}
                        type="color"
                        id="primary"
                        name="primary"
                        value={reportTitleColor}
                        onChange={(e) => setReportTitleColor(e.target.value)}
                      />
                    </div>
              </Col>
              <Col span={6}>
                    <label htmlFor="primary">Report description:</label>
                    <div style={{ width: 150 }}>
                      <input
                        style={{ width: 110 }}
                        type="color"
                        id="primary"
                        name="primary"
                        value={reportDescriptionColor}
                        onChange={(e) => setReportDescriptionColor(e.target.value)}
                      />
                    </div>
              </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <StyledButton type="custom"  onClick={onOk} style={{ margin: 0, width: 130,marginTop:30 }}>
              Submit
            </StyledButton>
          </Row>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default ReportTitleModal;
