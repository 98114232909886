import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

export const getTaskDeployments = async () => {
  const response = await API.graphql({
    query: queries["listTaskDeployments"],
  });

  return response.data.listTaskDeployments;
};

export const getFilteredTaskDeployments = async (email, userGroups) => {
  const groupsFilter = userGroups?.map((group) => ({
    assignedGroup: { eq: group._id?.$oid },
  }));
  const payload = {
    filter: {
      or: [{ email: { eq: email } }, ...groupsFilter],
      not: { status: { eq: "Not contacted" } },
    },
  };
  const response = await API.graphql({
    query: queries["listTaskDeployments"],
    variables: payload,
  });
  let items = response.data.listTaskDeployments.items;
  let nextToken = response.data.listTaskDeployments.nextToken;

  while (nextToken) {
    const nextPage = await API.graphql({
      query: queries["listTaskDeployments"],
      variables: {
        filter: payload?.filter,
        nextToken: nextToken,
      },
    });

    items = items.concat(nextPage.data.listTaskDeployments.items);
    nextToken = nextPage.data.listTaskDeployments.nextToken;
  }
  return items;
};

export const getTaskDeploymentsBySurveyId = async (Id) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const response = await API.graphql({
    query: queries["bySurveyManagementIdGroup"],
    variables: {
      surveyManagementID: Id,
      group: { eq: group },
    },
  });

  return response.data.bySurveyManagementIdGroup;
};

export const getTaskDeploymentsByEmail = async (email, Id) => {
  const response = await API.graphql({
    query: queries["bySurveyManagementIdEmail"],
    variables: {
      email: { eq: email },
      surveyManagementID: Id,
    },
  });

  return response.data.bySurveyManagementIdEmail;
};

export const getDeploymentMetrics = async (surveyId) => {
  const response = await API.graphql({
    query: queries["getDeploymentMetrics"],
    variables: {
      request_type: "DEPLOYMENT_METRICS",
      survey_id: surveyId,
    },
  });

  return response["data"]["getDeploymentMetrics"];
};

export const getDeployStatusBySurveyId = async (surveyId) => {
  const response = await API.graphql({
    query: queries["bySurveyManagementIdStatus"],
    variables: {
      surveyManagementID: surveyId,
      status: { eq: "Completed" },
    },
  });
  return response["data"]["bySurveyManagementIdStatus"];
};

export const getTaskDeploymentById = async (surveyId) => {
  const response = await API.graphql({
    query: queries["getTaskDeployment"],
    variables: {
      id: surveyId,
    },
  });

  return response["data"]["getTaskDeployment"];
};

export const addTaskDeployment = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group: group, owner: username };

  const response = await API.graphql({
    query: mutations["createTaskDeployment"],
    variables: {
      input: newPayload,
    },
  });
  return response;
};

export const updateTaskDeployment = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group: group, owner: username };

  const response = await API.graphql({
    query: mutations["updateTaskDeployment"],
    variables: {
      input: newPayload,
    },
  });

  return response;
};

export const removeTaskDeployment = async (payload) => {
  const data = await Auth.currentSession();
  const response = await API.graphql({
    query: mutations["deleteTaskDeployment"],
    variables: {
      input: payload,
    },
  });

  return response;
};

export const sendEmailReminderToUser = async (type, taskDeploymentId) => {
  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: {
      request_type: type,
      task_deployment_id: taskDeploymentId,
    },
  });
  return response["data"]["sendTaskReminder"];
};
export const sendReminderToAllUser = async (type, surveyId) => {
  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: { request_type: type, survey_id: surveyId },
  });
  return response["data"]["sendTaskReminder"];
};
