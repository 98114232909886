import { Auth, API } from 'aws-amplify';
import * as queries from '../graphql/queries';  
import * as mutations from '../graphql/mutations'; 

export const getGoalSamples = async() => {  
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "get_goal_samples"
  }});
  return JSON.parse(response['data']['datalake']);
};

export const getGolasProgress = async(payload) => {  
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "get_goals_progress",
    'request_payload': JSON.stringify(payload)
  }});
  return JSON.parse(response['data']['datalake']);
  
};

export const addGolasProgress = async(payload) => {  
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = {...payload,group_id,username}
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "add_goals_progress",
    'request_payload': JSON.stringify(newPayload)
  }});
  return response; 
};

export const updateGolasProgress = async(payload) => {  
  const data = await Auth.currentSession();
  const group_id = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = {...payload,group_id,username}
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "update_goals_progress",
    'request_payload': JSON.stringify(newPayload)
  }});
  return response;
};

export const removeGolasProgress = async(payload) => {  
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "remove_goals_progress",
    'request_payload': JSON.stringify(payload)
  }});
  return response;
};

export const getGolasAndProgress = async() => {  
  
  const response = await API.graphql({
    query: queries["listGoalsAndProgresss"],    
  });

  return response['data']['listGoalsAndProgresss'];
  
};

export const getGolasAndProgressById = async(sdgId) => {  
  
  const allGoals = await getGolasAndProgress()
  return allGoals?.items?.find(item => item.id === sdgId)
  
};

export const parentGoals = async(category = null) => {  
  
  const allGoals = await getGolasAndProgress();
  let parentGoals = allGoals?.items?.filter(item=> item.goal_type === 'GEN_LONG')
  if(category){
    parentGoals = parentGoals?.filter(item=> item.category === category)
  }
  return parentGoals;
  
};

export const subGoals = async(parentId) => {  
  
  const allGoals = await getGolasAndProgress();
  const subGoals = allGoals?.items?.filter(item=> item?.goal_type === 'GEN_SHORT' && item?.parent_id === parentId)
  return subGoals;
  
};

export const parentSdgGoals = async() => {    
  const allGoals = await getGolasAndProgress();
  return allGoals?.items?.filter(item=> item.goal_type === 'SDG_LONG')  
};

export const subSdgGoals = async(parentId) => {  
  
  const allGoals = await getGolasAndProgress();
  return allGoals?.items?.filter(item=> item?.goal_type === 'SDG_SHORT' && item?.parent_id === parentId)  
};

export const addGolasAndProgress = async(payload) => {  
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const owner = data["accessToken"]["payload"]["username"];
  const newPayload = {...payload,group,owner}
  
  const response = await API.graphql({ query: mutations["createGoalsAndProgress"], variables:{
    input : newPayload
  }});
  return response 
};

export const updateGolasAndProgress = async(payload) => {  
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const owner = data["accessToken"]["payload"]["username"];
  const newPayload = {...payload,group,owner}
  
  const response = await API.graphql({ query: mutations["updateGoalsAndProgress"], variables:{
    input : newPayload
  }});
  return response 
};

export const removeGolasAndProgress = async(payload) => {  
  const response = await API.graphql({ query: mutations["deleteGoalsAndProgress"], variables:{
    input : payload
  }});

  return response 
};

export const sendTaskReminder = async(type, category = null) => {
  const requestPayload = { 'request_type':type }
  if(category){
    requestPayload.category = category
  }
  const response = await API.graphql({ query: queries["sendTaskReminder"], variables: requestPayload })
 return response['data']['sendTaskReminder'];
}