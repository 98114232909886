import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { CommonModal } from "../../../../shared/commonStyles";

interface RichTextModalProps {
  data: string;
  fieldName: string;
  fieldDisplayName: string;
  visible: boolean;
  isViewOnly?: boolean;
  onVisibleChange: (isVisible: boolean) => void;
  onConfirm: (newValue: string) => void;
}

export const RichTextModal = ({
  data,
  fieldName,
  fieldDisplayName,
  visible,
  isViewOnly,
  onVisibleChange,
  onConfirm,
}: RichTextModalProps) => {
  const [value, setValue] = useState(data || "");

  useEffect(() => {
    setValue(data || "");
  }, [visible]);

  const handleCancel = () => {
    setValue(data || "");
    onVisibleChange(false);
  };
  const handleConfirm = () => {
    !isViewOnly && onConfirm(value);
    onVisibleChange(false);
  };

  return (
    <CommonModal
      width={"65%"}
      title={fieldDisplayName}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleConfirm}
    >
      <div className="ckSmallHeight">
        <CKEditor
          editor={DecoupledDocumentEditor}
          onReady={(editor: any) => {
            if (editor?.ui) {
              editor.ui.view.editable.element.parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.view.editable.element
              );
            }
          }}
          data={value}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            setValue(data);
          }}
          disabled={isViewOnly}
          style={{ height: 100 }}
        />
      </div>
    </CommonModal>
  );
};
