import React,{useState,useEffect} from 'react';
import {Avatar,Tooltip} from 'antd';

export const AvatarUsername = ({username,size='large',gap='2',customTooltipTitle=null}) =>{
    const [userName,setUserName] = useState("Not Available");
   
   useEffect(()=>{
    if(username){
      setUserName(username)
    }
   },[username])
   
  const getWordsFirstLetter = (string) => {
    if(!string) return "";
    try{
      const str = string;
      const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join(''); // JSON
      return acronym;
    }catch(error){
      console.log("error in getWordsFirstLetter function",error);
    return '';
    }
  }
   
  return(
    <Tooltip title={customTooltipTitle ? customTooltipTitle : userName}>
      <Avatar style={{ backgroundColor: '#7265e6', verticalAlign: 'middle', marginRight:'5px' }} size={size} gap={gap}>
          {getWordsFirstLetter(userName)}
      </Avatar>
    </Tooltip>
  )
}
   