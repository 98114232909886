import React from 'react';
import {
  CardDetails,
  CardWrapper,
  CompanyInfoCard,
  CompanyLogo,
  Paragraph,
  ReportTemplateStyle,
  SdgCardWrap,
  TopCompanyInfoWrap,
} from './styles';
import { Col, Row, Typography } from 'antd';
import logo from '../../../shared/Images/hydrus_log.svg';

const { Title } = Typography;
export const ReportTemplate = ({ rowData }) => {
  return (
    <ReportTemplateStyle>
      <CardWrapper>
        <CardDetails>
          <TopCompanyInfoWrap>
            <div>
              <Title level={3}>{rowData?.name}</Title>
              <Paragraph
                style={{
                  fontWeight: 300,
                  fontSize: '20px',
                  textTransform: 'uppercase',
                }}
              >
                {rowData?.tagline}
              </Paragraph>
              <Paragraph>{rowData?.website_domain}</Paragraph>
            </div>

            <CompanyLogo src={rowData?.logo} />
          </TopCompanyInfoWrap>

          <Row style={{ marginBottom: 30 }}>
            <Col xs={6} sm={6} md={6} lg={6} style={{ borderRight: '2px solid black' }}>
              <CompanyInfoCard>
                <Title level={4} style={{ textAlign: 'center' }}>
                  CEO
                </Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
                  {rowData?.ceo_name}
                </Paragraph>
              </CompanyInfoCard>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} style={{ borderRight: '2px solid black' }}>
              <CompanyInfoCard>
                <Title level={4} style={{ textAlign: 'center' }}>
                  LOCATION
                </Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
                  {rowData?.location}
                </Paragraph>
              </CompanyInfoCard>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} style={{ borderRight: '2px solid black' }}>
              <CompanyInfoCard>
                <Title style={{ textAlign: 'center' }} level={4}>
                  FOUNDED
                </Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>{rowData?.year}</Paragraph>
              </CompanyInfoCard>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <CompanyInfoCard>
                <Title style={{ textAlign: 'center' }} level={4}>
                  INDUSTRY
                </Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
                  {rowData?.industry}
                </Paragraph>
              </CompanyInfoCard>
            </Col>
          </Row>

          <Row style={{ marginBottom: 5 }}>
            <Col span={24}>
              <Paragraph>{rowData?.description}</Paragraph>
            </Col>
          </Row>

          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Title level={4} style={{ fontWeight: '800' }}>
                SDGS ADDRESSED
              </Title>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {rowData?.goals?.map((item, index) => {
                return (
                  <SdgCardWrap key={index}>
                    <img alt={item.title} src={item.image_url} />
                    <div>
                      <Title className="title" level={5} style={{ margin: 0 }}>
                        {item.title}
                      </Title>
                      <Paragraph className="descriptiom">{item.description}</Paragraph>
                    </div>
                  </SdgCardWrap>
                );
              })}
            </Col>
          </Row>
        </CardDetails>
        <div className="bottom_logo">
          <img src={logo} alt="" />
        </div>
      </CardWrapper>
    </ReportTemplateStyle>
  );
};
