import Styled from "styled-components";
import { Button } from 'antd';

import { siteThemeColors } from "../../../shared/colors";

export const StyledCard = Styled.div`
  padding: 24px;
  background-color: ${siteThemeColors.commonColor.bgColor};
  border-radius: 4px;
  margin: 10px 20px 10px 0px;
  width: 268px;
  height: 220px;
`;

export const StyledCardContent = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCardTitle = Styled.h4`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${siteThemeColors.commonColor.color};
`;

export const StyledCardSubtitle = Styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${siteThemeColors.FontColor.secondary};

  :before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    margin: 0px 10px 4px 10px;
    background-color: ${siteThemeColors.StepColor.borderColor}
  }
`;

export const StyledActionButton = Styled(Button)`
  border-radius: 4px;
  height: 38px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${siteThemeColors.commonColor.color};
  margin-top: 12px;

  :hover {
    color: ${siteThemeColors.ButtonColor.hoverBgColor};
    border-color: ${siteThemeColors.ButtonColor.hoverBgColor};
  }
`;

export const StyledCardImgWrap = Styled.div`
  flex: 1;
  padding-bottom: 16px;
  width: 100%;
  height: 85px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
