import React from 'react';
import MixedSurveyFormViewModal from "../../../components/Modals/MixedSurveyFormViewModal/MixedSurveyFormViewModal";

const ShowMixedSurveyFormViewModal = ({
  rowDataView,
  showFormViewModal,
  setShowFormViewModal,
  sortedSurveyList,
  setRowDataView,
}) => {
  return (
    <>
      {showFormViewModal && (
        <MixedSurveyFormViewModal
          visible
          onClose={() => setShowFormViewModal(false)}
          rowData={rowDataView}
          StandardFormViewModal={true}
          isShowSteps={true}
          sortedSurveyList={sortedSurveyList}
          setRowDataView={setRowDataView}
        />
      )}
    </>
  );
};

export default ShowMixedSurveyFormViewModal;