import React, { useEffect, useState } from "react";
import { StyledButton } from "../../../shared/commonStyles";
import { notification } from "antd";
import { PlusCircleOutlined, } from "@ant-design/icons";

import { getConnectToken } from "./components/session";
import { useConnect } from "@arcadia-eng/connect-react";

export const AddNewUtilityAccount = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [config, setConfig] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [error, setError] = useState(null);
    const [timedOut, setTimedOut] = useState(false);
    const [utilityCredentialId, setUtilityCredentialId] = useState(null);

    const [{ loading, connectSetupError }, open] = useConnect();

    if (connectSetupError) {
        setError(connectSetupError.message);
    }

    useEffect(() => {
        getConnectToken()
            .then((connectToken) => {
                setConfig(generateConfig(connectToken));
            })
            .catch((e) => {
                setError(e.message);
            });
    }, []);

    const generateConfig = (connectToken) => {
        return {
            connectToken,
            callbacks: {
                onOpen: () => {
                    setModalOpen(true);
                },
                onCredentialsSubmitted: ({ utilityCredentialId }) => {
                    setUtilityCredentialId(utilityCredentialId);
                },
                onClose: ({ status }) => {
                    switch (status) {
                        case "verified":
                            setSuccessful(true);
                            break;
                        case "timed_out":
                            setTimedOut(true);
                            break;
                        default:
                            break;
                    }
                    setModalOpen(false);
                },
                onError: ({ error }) => {
                    setError(error);
                },
            },
        };
    };

    
    const openNotificationWithIcon = (type, title, content) => {
        notification[type]({
            message:  `${title}`,
            description: `${content}`,
            placement: 'bottomRight',
            maxCount : 1,
        });
        //triggerChange((Math.random() + 1).toString(36).substring(7));
    }

    if (modalOpen) return null;

    if (error) {
        openNotificationWithIcon('error', `Connect Utility Failed. Uh oh! We hit a problem !!`)
    }

    if (timedOut) {
        openNotificationWithIcon('info', `Utility Credential was created but the credentials weren't verified before the Component timed out and closed. The credentials will be verified in the background. If you've configured a webhook, check your console for incoming webhooks about verification.`);
    }

    if (successful) {
        openNotificationWithIcon(
            'success', 
            'Account Successfully Added', 
            `You have connected Utility Credentials! 
            If you've configured a webhook, check your console for incoming data.In order to try connecting these same credentials`,
        );
    }

    return (
        <>
            <StyledButton
                type="button"
                disabled={loading || !config}
                onClick={() => open(config)}
            >
                <PlusCircleOutlined /> <span>Add New Utility Account</span>
            </StyledButton>
        </>
    );
};
