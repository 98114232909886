import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Spin,
  Space,
  Col,
  Tag,
  Dropdown,
  Menu,
  Tooltip,
  Select,
} from "antd";
import {
  TableWrap,
  PageWrap,
  StyledButton,
  CommonTable,
  TableStyledMenu,
  FormWrapper,
  FiltersContainer,
  CommonSelect,
} from "../../shared/commonStyles";
import {
  EyeOutlined,
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  SettingOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { getImportData, removeImportData } from "../../services/importData";
import { encodeUrlName } from "../../shared/commonFunctions";
const { Option } = Select;
const ImportDataPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [listImportData, setListImportData] = useState([]);
  const [loadigData, setLoadingData] = useState(false);
  const [externalTableFilters, setExternalTableFilters] = useState({});
  const navigate = useNavigate();
  const loadImportData = async () => {
    try {
      setLoadingData(true);
      const data = await Auth.currentSession();
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const username = data["accessToken"]["payload"]["username"];
      const filter = { ...externalTableFilters };
      const payload = {
        filter,
        group,
        username,
      };

      const import_data = await getImportData(payload);
      const importData = import_data?.map((element) => ({
        ...element,
        key: element["_id"]["$oid"],
      }));
      setDataSource(
        importData.sort((a, b) =>
          b.import_status.localeCompare(a.import_status === "PENDING_REVIEW")
        )
      );
    } catch (error) {
      console.log("load error : ", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    loadImportData();
  }, [externalTableFilters]);

  const loadListImportData = async () => {
    const data = await Auth.currentSession();
    const group = data["accessToken"]["payload"]["cognito:groups"].filter(
      (element) => element.includes("org:")
    )[0];
    const username = data["accessToken"]["payload"]["username"];
    const payload = {
      group,
      username,
    };

    const _import_data = await getImportData(payload);
    setListImportData(_import_data?.reverse());
  };

  useEffect(() => {
    loadListImportData();
  }, []);

  const handleDeleteImportData = async (record) => {
    try {
      const payload = {
        _id: record["_id"],
      };
      await removeImportData(payload);
      /*const response = await API.graphql({ query: queries["datalake"], variables:{
          request_type: "remove_data_import",
          request_payload: JSON.stringify(payload)
        }});*/
    } catch (error) {
      console.log("delete import error : ", error);
    } finally {
      loadImportData();
    }
  };

  const handleViewData = (data) => {
    const importId = data["_id"]["$oid"];
    const importName = data["import_name"];
    if (importId && importName) {
      data.importCategory === "MULTIPLE_DATASHEETS"
        ? navigate(`/data/import-data/${encodeUrlName(importName)}/update`, {
            state: { action: "update", importId },
          })
        : navigate(
            `/data/import-data/${importId}/${encodeUrlName(importName)}/update`,
            {
              state: { action: "update", sheet_id: data["sheet_id"] },
            }
          );
    }
  };

  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <>
        <Menu.Item
          key="1"
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <StyledButton
            type="default"
            style={{
              width: "100%",
              margin: "0",
              padding: "11px 18px",
              textAlign: "left",
            }}
            icon={<FolderViewOutlined />}
            onClick={() => handleViewData(record)}
          >
            View Details
          </StyledButton>
        </Menu.Item>
        {record["import_status"] === "REJECTED" ? (
          <Menu.Item
            key="2"
            style={{ backgroundColor: "transparent", padding: "0" }}
          >
            <StyledButton
              type="default"
              style={{
                width: "100%",
                margin: "0",
                padding: "11px 18px",
                textAlign: "left",
              }}
              onClick={() => {
                handleDeleteImportData(record);
              }}
              icon={<DeleteOutlined />}
            >
              Delete
            </StyledButton>
          </Menu.Item>
        ) : null}
      </>
    </TableStyledMenu>
  );

  const fillColor = (record) => {
    let tagColor = "#E69B56";
    let tagText = "REVIEW REQUIRED";
    if (record === "APPROVED") {
      tagColor = "#3DA48B";
      tagText = "APPROVED";
    }
    if (record === "REJECTED") {
      tagColor = "#FF0000";
      tagText = "REJECTED";
    }

    return (
      <Tag color={tagColor} className="tag-rounded">
        {tagText}
      </Tag>
    );
  };

  const import_type_list = {
    "image/png": "Img",
    "image/jpg": "Img",
    "image/jpeg": "Img",
    "image/JPG": "Img",
    "application/pdf": "Pdf",
    "text/csv": "Csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "Excel",
  };

  const handleReviewData = (data) => {
    const importId = data["_id"]["$oid"];
    const importName = data["import_name"];
    if (importId && importName) {
      data.importCategory === "MULTIPLE_DATASHEETS"
        ? navigate(`/data/import-data/${encodeUrlName(importName)}/review`, {
            state: { action: "review", importId },
          })
        : navigate(
            `/data/import-data/${importId}/${encodeUrlName(importName)}/review`,
            {
              state: { action: "review", sheet_id: data["sheet_id"] },
            }
          );
    }
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Import name",
      dataIndex: "import_name",
      width: 300,
      sorter: (a, b) => {
        return a?.import_name?.localeCompare(b?.import_name);
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 400,
      render: (record) => {
        return record;
      },
    },
    {
      title: "Import Type",
      dataIndex: "import_type",
      width: 300,
      render: (record) => {
        let import_type = "";
        Object.keys(import_type_list).forEach((key, indx) => {
          if (key === record) {
            import_type = import_type_list[key];
          }
        });
        return import_type;
      },
    },
    {
      title: "Data Sheet Name",
      dataIndex: "data_sheet",
      width: 300,
      render: (record) => {
        return record;
      },
    },
    {
      title: "Status",
      dataIndex: "import_status",
      width: 300,
      render: (record) => {
        return fillColor(record);
      },
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: 300,
      className: "Fixed_Actions_Right_Side",
      render: (_, data) => (
        <Space direction="horizontal">
          <Tooltip
            title={
              data["import_status"] === "REJECTED"
                ? "This import has been rejected, Please create new one!"
                : data["import_status"] === "APPROVED"
                ? "This import has been approved, Please create new one!"
                : null
            }
          >
            <StyledButton
              type="default"
              //disabled = {data['import_status'] === 'REJECTED'}
              onClick={() => handleReviewData(data)}
              disabled={data["import_status"] !== "PENDING_REVIEW"}
            >
              <EyeOutlined />
              <span>Review</span>
            </StyledButton>
          </Tooltip>
          <Dropdown overlay={() => menuButtons(data)} trigger={["click"]}>
            <StyledButton
              type="default"
              style={{
                marginLeft: "10",
                padding: "10px 18px",
              }}
            >
              <EllipsisOutlined />
            </StyledButton>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onChangeExternalFilters = (data, filterName) => {
    try {
      setExternalTableFilters({ ...externalTableFilters, [filterName]: data });
    } catch (error) {
      console.log("Error while filter import table", error);
    }
  };

  const uniqueDataSheet = () => {
    const unique_data_sheet_list = [];
    const seenValues = new Set();

    listImportData.forEach((item) => {
      if (!seenValues.has(item.data_sheet) && item.data_sheet) {
        seenValues.add(item.data_sheet);
        unique_data_sheet_list.push(item.data_sheet);
      }
    });
    return (
      <>
        {unique_data_sheet_list?.map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))}
      </>
    );
  };

  return (
    <>
      <Spin spinning={false}>
        <PageWrap>
          <FiltersContainer>
            <FormWrapper>
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={17}
                  style={{ marginTop: "5px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={4} lg={4}>
                      <CommonSelect
                        allowClear
                        showArrow
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Import Name"
                        onChange={(data) =>
                          onChangeExternalFilters(data, "import_name")
                        }
                      >
                        {listImportData?.map((item, index) => {
                          return (
                            <Option key={index} value={item.import_name}>
                              {item.import_name}
                            </Option>
                          );
                        })}
                      </CommonSelect>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4}>
                      <CommonSelect
                        allowClear
                        showArrow
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Description"
                        onChange={(data) =>
                          onChangeExternalFilters(data, "description")
                        }
                      >
                        {listImportData?.map((item, index) => {
                          return (
                            <Option key={index} value={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </CommonSelect>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4}>
                      <CommonSelect
                        allowClear
                        showArrow
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Import Type"
                        onChange={(data) =>
                          onChangeExternalFilters(data, "import_type")
                        }
                      >
                        <Option
                          key={1}
                          value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        >
                          Excel
                        </Option>
                        <Option key={2} value="text/csv">
                          Csv
                        </Option>
                        <Option key={3} value="application/pdf">
                          Pdf
                        </Option>
                        <Option key={4} value="image/png">
                          Img
                        </Option>
                      </CommonSelect>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4}>
                      <CommonSelect
                        allowClear
                        showArrow
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Data Sheet Name"
                        onChange={(data) =>
                          onChangeExternalFilters(data, "data_sheet")
                        }
                      >
                        {uniqueDataSheet()}
                      </CommonSelect>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4}>
                      <CommonSelect
                        allowClear
                        showArrow
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Status"
                        onChange={(data) =>
                          onChangeExternalFilters(data, "import_status")
                        }
                      >
                        <Option key={1} value="APPROVED">
                          Approved
                        </Option>
                        <Option key={2} value="REJECTED">
                          Rejected
                        </Option>
                        <Option key={3} value="PENDING_REVIEW">
                          Review required
                        </Option>
                      </CommonSelect>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormWrapper>
          </FiltersContainer>
          <Row gutter={[16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <TableWrap>
                <Spin spinning={loadigData}>
                  <CommonTable
                    className="importDataTable"
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ x: true }}
                  />
                </Spin>
              </TableWrap>
            </Col>
          </Row>
        </PageWrap>
      </Spin>
    </>
  );
};

export default ImportDataPage;
