import React from "react";
import { useBoolean } from "ahooks";
import { useLocation, useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import { Row } from "antd";
import { StyledButton } from "../../../../../shared/commonStyles";
import { PlusCircleOutlined } from "@ant-design/icons";
import { UpdateCurrentDataModal } from "./UpdateCurrentDataModal";
import { CreateNewPlanModal } from "./CreateNewPlanModal";
import { AddNewActionModal } from "./AddNewActionModal";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";

export const HeaderBtnSingle = observer(() => {
  const { state } = useLocation();
  const navigate = useNavigate();
  if (state === null) navigate("/carbon/reduction-plan");

  const [
    isUpdateCurrentDataModalOpen,
    {
      setTrue: openUpdateCurrentDataModal,
      setFalse: hideUpdateCurrentDataModal,
    },
  ] = useBoolean(false);

  const [
    isAddNewActionModalOpen,
    { setTrue: openAddNewActionModal, setFalse: hideAddNewActionModal },
  ] = useBoolean(false);
  const [
    isCreateNewPlanModalOpen,
    { setTrue: openCreateNewPlanModal, setFalse: hideCreateNewPlanModal },
  ] = useBoolean(false);

  const handleConfirmUpdateCurrentData = () => {
    hideUpdateCurrentDataModal();
  };
  const handleConfirmAddNewAction = (action: Action) => {
    reductionPlanStore.addTargetAction(action);
    hideAddNewActionModal();
  };

  const handleCreateNewPlan = (plan: Plan) => {
    reductionPlanStore.addTargetPlan(plan);
    hideCreateNewPlanModal();
  };

  return (
    <div>
      <Row justify="end">
        <StyledButton onClick={openUpdateCurrentDataModal}>
          <PlusCircleOutlined />
          Update current data
        </StyledButton>
        <UpdateCurrentDataModal
          isOpen={isUpdateCurrentDataModalOpen}
          onClose={hideUpdateCurrentDataModal}
          onConfirm={handleConfirmUpdateCurrentData}
        />

        <StyledButton onClick={openCreateNewPlanModal}>
          <PlusCircleOutlined /> Create reduction plan
        </StyledButton>
        <CreateNewPlanModal
          modalTitle="Create new reduction plan"
          data={{} as Plan}
          isOpen={isCreateNewPlanModalOpen}
          onClose={hideCreateNewPlanModal}
          onConfirm={handleCreateNewPlan}
        />
        <StyledButton onClick={openAddNewActionModal}>
          <PlusCircleOutlined />
          Add new action
        </StyledButton>

        <AddNewActionModal
          modalTitle="Add new action"
          data={{} as Action}
          isOpen={isAddNewActionModalOpen}
          onClose={hideAddNewActionModal}
          onConfirm={handleConfirmAddNewAction}
        />
      </Row>
    </div>
  );
});
