import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { PageWrap, StyledProgress } from "../../../../../shared/commonStyles";
import { CreateTargets } from "../CustomTargets/CreateTargets";
import { ApprovedScienceBasedTargets } from "./ApprovedScienceBasedTargets";
import { HeaderWhatIf } from "../../HeaderWhatIf";
import { observer } from "mobx-react-lite";
import { reductionPlanStore } from "../../ReductionPlanStore";
import { companyInfoStore } from "../../../../Carbon/CompanyInfoStore";
import styles from "../../WhatIfOnboarding.module.scss";

interface ScienceBasedTargetsProps {
  onBack: () => void;
}

export const ScienceBasedTargets = observer(
  ({ onBack }: ScienceBasedTargetsProps) => {
    const [current, setCurrent] = useState(0);
    const navigate = useNavigate();
    const nextStep = () => {
      setCurrent(current + 1);
    };
    const handleCustomStep = () => {
      setCurrent(2);
    };

    const handleSave = () => {
      reductionPlanStore.saveTargetsList(reductionPlanStore.newTargets);
      companyInfoStore.updateOnboardingCompletion();
      navigate("/carbon/reduction-plan");
    };

    const prevStep = () => {
      setCurrent(current - 1);
    };
    const stepsComponents = [
      <ApprovedScienceBasedTargets
        key="approved-targets"
        onNext={nextStep}
        onCustomStep={handleCustomStep}
      />,
      <CreateTargets
        data={reductionPlanStore.onboardingScienceBasedTargets}
        key="science-based-targets"
        title="Science based targets"
        subtitle="Enter approved science based targets"
        targetType={TargetType.SCIENCE_BASED}
        onNext={nextStep}
        onBack={prevStep}
      />,
      <CreateTargets
        data={reductionPlanStore.onboardingCustomTargets}
        key="custom-targets"
        title="Custom targets (optional)"
        subtitle="Enter custom company targets"
        targetType={TargetType.CUSTOM}
        onNext={handleSave}
        onBack={prevStep}
      />,
    ];

    return (
      <>
        <HeaderWhatIf />
        <StyledProgress
          strokeLinecap="square"
          strokeWidth={10}
          percent={(current + 1) * 34}
          showInfo={false}
          className={styles.progress}
        />
        <PageWrap className={styles.container}>
          <Row justify="center" align="middle">
            <Col>
              <span className={styles.welcomeText}>
                Welcome to reduction plan section!
              </span>
              <div>
                <div>{stepsComponents[current]}</div>
              </div>
            </Col>
          </Row>
        </PageWrap>
      </>
    );
  }
);
