import React,{useState, useEffect} from "react";
import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
  Tooltip as Tooltips,
} from "devextreme-react/pie-chart";
import { Spin, message } from "antd";
import { getDeploymentMetrics } from "./../../../services/taskDeployment";

export const MyResponsivePieDeploy = ({surveyId}) => {
  const [loader, setLoader] = useState(false);
  const [deploySurveyMetrics, setDeploySurveyMetrics] = useState(null);
  const [chartData, setChartData] = useState(null);

  const getDeploySurveyMetrics = async() => {
    try {
      setLoader(true)
      const deploysurveyMetrics = await getDeploymentMetrics(surveyId)
      const metricsData = JSON.parse(deploysurveyMetrics)
      setDeploySurveyMetrics(metricsData)
    } catch (e) {
      message.error("Failed to load progress  data");
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {  
    if(surveyId){
      getDeploySurveyMetrics()
      
    }
  }, [surveyId])

  useEffect(() => {  
    if(deploySurveyMetrics){
      const notCompletedUsers = deploySurveyMetrics?.total_users - deploySurveyMetrics?.completed_users
      const dataExport = [
        {
            name: `<span style="font-size:16px; color:white">Completed ${deploySurveyMetrics?.completed_users}/${deploySurveyMetrics?.total_users}</span>`,
            color: "#5ec79b",
            value: deploySurveyMetrics?.completed_users,
            tooltip: `Completed<br /> ${deploySurveyMetrics?.completed_users}/${deploySurveyMetrics?.total_users}`
        },
        {
            name: `<span style="font-size:16px; color:white">Not Completed ${notCompletedUsers}/${deploySurveyMetrics?.total_users}</span>`,
            color: "#6feeb8",
            value: notCompletedUsers,
            tooltip: `Not Completed<br /> ${notCompletedUsers}/${deploySurveyMetrics?.total_users}`
        }
      ];
      setChartData(dataExport)
    }
  }, [deploySurveyMetrics])
  
  const customizePoint = (pointInfo) => {
    return pointInfo.value === pointInfo.data.value
      ? { color: pointInfo.data.color }
      : {};
  };
  function customizeTooltip(e) {
    const selectedData = chartData?.find((item) => item.name === e.argument);
    return {
      text: selectedData
        ? selectedData.tooltip
        : `${e.argumentText}  ${e.valueText} `,
    };
  }
  return (
    <Spin spinning={loader}>
      {chartData && 
        <div className="chart-inner-box">
          <PieChart 
            id="pie"
            dataSource={chartData}
            palette="Dark Violet"
            customizePoint={customizePoint}
          >
            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            <Tooltips enabled={true} customizeTooltip={customizeTooltip} />

            <Series
              argumentField="name"
              valueField="value"
              tooltipField="tooltip"
            >
              <Label visible={true}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>
            
          </PieChart>
        </div>
      }
    </Spin>
  );
};
