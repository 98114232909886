import React from "react";
import { CommonModal } from "../../../shared/commonStyles";
import { Typography } from "antd";
const Text = Typography;
const ConfirmModal = ({
  description,
  visible,
  onConfirm,
  onClose,
  confirmLoading,
}) => {
  return (
    <>
      <CommonModal
        title="Confirmation"
        visible={visible}
        okText="Delete"
        onOk={onConfirm}
        onCancel={onClose}
        confirmLoading={confirmLoading}
      >
        <Text>{description}</Text>
      </CommonModal>
    </>
  );
};

export default ConfirmModal;
