import React, { useEffect, useState } from "react";
import { Row, Select, Spin } from "antd";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";

interface AssignOwnerModalProps {
  visible: boolean;
  usersData: string[];
  groupsData: string[];
  onCancel: () => void;
  onConfirm: (users: string[], groups: string[]) => void;
  usersList: User[];
  groupsList: UserGroup[];
}

export const AssignOwnerModal = ({
  visible,
  usersData,
  groupsData,
  onCancel,
  onConfirm,
  usersList,
  groupsList,
}: AssignOwnerModalProps) => {
  const [selectedUsersList, setSelectedUsersList] = useState<string[]>([]);
  const [filteredSelectedUsers, setFilteredSelectedUsers] = useState<string[]>(
    []
  );
  const [selectedGroupsList, setSelectedGroupsList] = useState<string[]>([]);

  useEffect(() => {
    setSelectedUsersList(usersData);
    setSelectedGroupsList(groupsData);
  }, []);

  useEffect(() => {
    selectedUsersList &&
      setFilteredSelectedUsers(selectedUsersList.filter((v) => v !== "SYSTEM"));
  }, [selectedUsersList]);

  const handleSelectUsers = (value: string[]) => {
    setSelectedUsersList(value);
  };
  const handleSelectGroups = (value: string[]) => {
    setSelectedGroupsList(value);
  };
  const handleConfirm = () => {
    const users = usersData.some((v) => v === "SYSTEM")
      ? selectedUsersList.concat("SYSTEM")
      : selectedUsersList;
    onConfirm(users, selectedGroupsList);
  };

  return (
    <CommonModal
      visible={visible}
      onCancel={onCancel}
      onOk={handleConfirm}
      centered
      okText="Grant access"
      title="Assign owner"
    >
      <FormWrapper>
        <Row style={{ marginBottom: 10 }}>Assign owners</Row>
        <Select
          mode="multiple"
          placeholder="Select users"
          showArrow
          onChange={handleSelectUsers}
          style={{ width: "100%" }}
          value={filteredSelectedUsers}
          notFoundContent={!usersList.length && <Spin size="small" />}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={usersList}
        />
        <Row style={{ marginBottom: 10, marginTop: 30 }}>
          Assign owner groups
        </Row>
        <Select
          mode="multiple"
          placeholder="Select groups"
          showArrow
          showSearch
          onChange={handleSelectGroups}
          style={{ width: "100%" }}
          value={selectedGroupsList}
          notFoundContent={!groupsList.length && <Spin size="small" />}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={groupsList}
        />
      </FormWrapper>
    </CommonModal>
  );
};
