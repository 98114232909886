import React, { useEffect, useState } from "react";
import { useSetState } from "ahooks";
import { Button } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { AutomatedEmissionsCalculation } from "../AutomatedEmissionsCalculation";
import { CommonModal } from "../../../../../shared/commonStyles";
import { calculatorStore } from "../../../../Carbon";
import { observer } from "mobx-react-lite";
import { EmissionsLibraryModal } from "../EmissionsLibraryModal";
import styles from "../AddAutomatedEmissionsModal/AddAutomatedEmissionsModal.module.scss";

import { handleAddEditEmission } from "../../EmissionsModalHelper";
import { CustomConversionFactorComponent } from "../CustomConversionFactorComponent";

interface EditEmissionsModalProps {
  disabled?: boolean;
  data: AutomatedEmission;
  editEmission?: (data: AutomatedEmission) => void;
}

interface AutoEmissionData {
  activity_sheet: string;
  activity_field: string;
  activityFields: ActivityField[];
  filters: AdvancedDataFilter[];
  automatic: boolean;
}

export const EditAutomatedEmissionsModal = observer(
  ({ data, editEmission, disabled }: EditEmissionsModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [autoEmissionData, setAutoEmissionData] =
      useState<AutomatedEmission>(data);

    const [activityField, setActivityField] = useState(data.activity_field);
    const [customConversionFactor, setCustomConversionFactor] = useState('');
    const [isShowCustomConversionFactor, setIsShowCustomConversionFactor] = useState(false);

    useEffect(() => {
      setAutoEmissionData(data);

      // set to blank if we have some value in custom unit conversion factor and we aren't using it
      if (data?.factor_data?.custom_unit_conversion_factor !== undefined && isShowCustomConversionFactor === false) {
        //&& data?.factor_data?.custom_unit_conversion_factor == 1) {
        setCustomConversionFactor('');
      } else if (data?.factor_data?.custom_unit_conversion_factor !== undefined) {
        setCustomConversionFactor(String((data?.factor_data?.custom_unit_conversion_factor).valueOf()));
      }
      // we do an api call here to getSheetById, maybe need to cache it so we aren't doing multiple calls for the same Sheet in the same session
      handleAddEditEmission(data?.factor_data, autoEmissionData, setAutoEmissionData, setIsShowCustomConversionFactor);
      if (data?.factor_data?.custom_unit_conversion_factor) {
        setIsShowCustomConversionFactor(true);
      }
    }, [isModalVisible, activityField]);

    const showModal = () => {
      setIsModalVisible(true);
    };


    const handleChangeActivitySheets = (activity_sheet: string) => {
      setAutoEmissionData((prev) => ({ ...prev, activity_sheet }));
    };

    // on change of activity field
    const handleChangeValueActivityFields = (activity_field: string) => {
      setActivityField(activity_field);
      setAutoEmissionData((prev) => ({ ...prev, activity_field }));
    };

    const handleChangeFilters = (filters: AdvancedDataFilter[]) => {
      setAutoEmissionData((prev) => ({ ...prev, filters: filters }));
    };

    const handleChangeAutomatic = (automatic: boolean) => {
      setAutoEmissionData((prev) => ({ ...prev, automatic: automatic }));
    };

    // here we save to db and close pop up
    const handleOk = async () => {
      const emission = await calculatorStore.getAutomatedConsumptionAmount(
        autoEmissionData
      );
      const factorValue =
        emission.factor_data.factor ??
        emission.factor_data.factorCalculatedValue;
      delete emission.factor_data.factorCalculatedValue;

      // if custom unit conversion factor exists and isShowCustomFactor is set to false then make this value 1
      // we want to make sure any change custom factor when it is set and  we are not using it this is set correctly in db
      // we cannot set to undefined since we don't know if it was previously defined in db or not
      // this can occur if switching back and forth between different activity fields in pop up
      const new_factor_data: any = {};
      if (emission?.factor_data?.custom_unit_conversion_factor !== undefined &&  isShowCustomConversionFactor === false) {
        //console.log('cleaning up and setting custom unit conversion factor to 1')
        new_factor_data.custom_unit_conversion_factor = 1;
      }

      const new_emission_data: any = {};
      if (emission?.datasheet_unit_conversion_factor !== undefined && isShowCustomConversionFactor === true) {
        // if we are showing custom conversion factor then that means we are not in the same unit group and so no unit conversion factor
        // and also weh ave a value in datasheet_unit_conversion_factor
        // this can occur if switching back and forth between different activity fields in pop up and going between using a multiplier and not
        // we cannot set to undefined since we don't know if it was previously defined in db or not
        // we set to 1 meaning no conversion
        new_emission_data.datasheet_unit_conversion_factor = 1;
      }



      editEmission &&
        editEmission({
          ...emission,
          ...new_emission_data,
          factor_data: { ...emission.factor_data, ...new_factor_data, factor: factorValue },
        });
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setAutoEmissionData({ ...data });
      setIsModalVisible(false);
    };

    const handleSelectEmission = (factor: any[]) => {
      handleAddEditEmission(factor[0], autoEmissionData, setAutoEmissionData, setIsShowCustomConversionFactor);
    };

    const handleRemoveEmission = () => {
      setAutoEmissionData(
        (prev) =>
          ({
            activity_field: prev?.activity_field,
            automatic: prev?.automatic,
            filters: prev?.filters,
            activity_sheet: prev?.activity_sheet,
          } as AutomatedEmission)
      );
    };

    return (
      <>
        <Button onClick={showModal} disabled={disabled}>
          <EditOutlined />
          Edit
        </Button>
        <CommonModal
          centered
          title="Edit emission"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type="text" key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              key="confirm"
              onClick={handleOk}
              disabled={!autoEmissionData?.activity_field || (isShowCustomConversionFactor && (customConversionFactor.trim() === "" || isNaN(Number(customConversionFactor).valueOf())))}
            >
              Edit Emission
            </Button>,
          ]}
          width={758}
        >
          <AutomatedEmissionsCalculation
            autoEmissionData={autoEmissionData}
            onChangeActivitySheet={handleChangeActivitySheets}
            onChangeValueActivityField={handleChangeValueActivityFields}
            onChangeFilters={handleChangeFilters}
            onChangeAutomatic={handleChangeAutomatic}
          />
          { isShowCustomConversionFactor ? (
                  <CustomConversionFactorComponent
                    customConversionFactor={customConversionFactor}
                    setCustomConversionFactor={setCustomConversionFactor}
                    autoEmission={autoEmissionData}
                    setAutoEmission={setAutoEmissionData}
                  />
          ) : null }
          <EmissionsLibraryModal
            onAddEmission={handleSelectEmission}
            data={[autoEmissionData]}
          />
          {autoEmissionData?.factor_data?.name && (
            <div className={styles.selectedItems}>
              <div className={styles.selectedCheckbox}>
                {autoEmissionData?.factor_data?.name}
                <CloseOutlined
                  className={styles.removeIcon}
                  onClick={() => {
                    handleRemoveEmission();
                  }}
                />
              </div>
            </div>
          )}
        </CommonModal>
      </>
    );
  }
);
