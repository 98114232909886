import React, { useEffect, useState } from "react";
import { CommonModal, FormWrapper } from "../../../../shared/commonStyles";
import { Col, Row, Select, Spin } from "antd";
import { getDataSheetsWithPagination } from "../../../../services/dataSheet";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";

interface StandardSheetTemplateModalProps {
  visible: boolean;
  onClose: () => void;
  standardCard: any;
}

export const StandardSheetTemplateModal = ({
  visible,
  onClose,
  standardCard,
}: StandardSheetTemplateModalProps) => {
  const navigate = useNavigate();
  const [standardSheets, setStandardSheets] = useState([]);
  const [loadingSheets, setLoadingSheets] = useState(false);
  const [currentSheet, setCurrentSheet] = useState<DataSheet | null>(null);

  const getStandardSheets = async () => {
    try {
      setLoadingSheets(true);
      const data = await Auth.currentSession();
      const accessToken = data.getAccessToken();
      const groups = accessToken.payload["cognito:groups"] || [];
      const group = groups?.filter((element: any) =>
        element.includes("org:")
      )[0];
      const payloadSheets = {
        filter: {
          archive: false,
          group: group,
          is_standard_data: true,
          sheet_type: [standardCard.type.replace("standard_", "")],
        },
      };
      const sheets = await getDataSheetsWithPagination(payloadSheets);
      setStandardSheets(sheets?.data);
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoadingSheets(false);
    }
  };
  useEffect(() => {
    if (standardCard) {
      getStandardSheets();
    }
  }, [standardCard]);

  const handleChangeSelector = (value: string) => {
    const selectedRecord =
      standardSheets?.find((item: DataSheet) => item._id.$oid === value) ||
      ({} as DataSheet);
    setCurrentSheet(selectedRecord);
  };

  const onConfirm = () => {
    navigate(standardCard.link, {
      state: {
        type: standardCard.type,
        standardSheet: currentSheet,
        dataLakeMapReport: [],
        report_period: null,
        location_id: null,
        business_unit_id: null,
      },
    });
    onClose();
  };

  return (
    <CommonModal
      visible={visible}
      onCancel={onClose}
      title={"Select Data Sheet"}
      width={"60%"}
      centered
      onOk={onConfirm}
      okText="Select"
      okButtonProps={{ disabled: !currentSheet }}
    >
      <Spin spinning={loadingSheets}>
        <FormWrapper>
          <Row className="margin-5">
            Please specify the data sheet that you want to use for this GRI
            report
          </Row>
          <Row className="margin-5" gutter={[8, 8]} align="middle">
            <Col flex="none">Data sheet name: </Col>
            <Col flex="auto">
              <Select
                style={{ width: "100%" }}
                options={standardSheets.map((item: DataSheet) => ({
                  label: item.sheet_name,
                  value: item._id.$oid,
                }))}
                onChange={handleChangeSelector}
                value={currentSheet?._id?.$oid}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};
