import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getAiResult = async (question) => {
  const response = await API.graphql({ 
    query: queries["artificialIntelligence"], 
    variables:{
      model:'text-davinci-003',prompt: question, max_tokens: 256, temperature: 0.7
    }
  })

  return JSON.parse(response['data']['artificialIntelligence']);
};

export const getAiRecommendations = async (payload) => {

  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_ai_recommendation",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const addAiRecommendations = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_ai_recommendation",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};


export const removeAiRecommendations = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_ai_recommendation",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const getGeneratedAIContent = async(requestPayload)=>{
  const response = await API.graphql({
    query:queries["aiFunction"],
    variables:{
      request_type:"GENERATE_FRAMEWORK_RESPONSE",
      event : JSON.stringify(requestPayload)
    }
  })
  return JSON.parse(response['data']['aiFunction']);

}