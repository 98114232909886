import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUsersGroupList, getUsersList } from "../../services/users";
import { getSheetRecordsFilters } from "../../services/dataSheet";
import { StyledButton } from "../../shared/commonStyles";
import {
  additionalQueryOperators,
  queryOperators,
} from "../../services/mongoOperators";

interface DataSheetFiltersProps {
  initialFilters?: AdvancedDataFilter[];
  score?: any;
  formFilters?: AdvancedDataFilter[];
  onChangeFilters?: () => void;
  onAddFilters?: () => void;
  dataSheet?: DataSheet;
  schema?: any;
  resetInput?: (key: number) => void;
  addButtonText: string;
  hideOwnersField?: boolean;
  listName?: any;
  isLogicalExpression?: boolean;
}

type InputType = {
  index: number;
  inputType: string;
  value: string;
};
type ActivityField = {
  label: string;
  inputType: string;
  value: string;
};

export const DataSheetFilters = ({
  initialFilters,
  formFilters,
  listName,
  score,
  onChangeFilters,
  onAddFilters,
  dataSheet,
  schema,
  resetInput,
  addButtonText,
  hideOwnersField = false,
  isLogicalExpression,
}: DataSheetFiltersProps) => {
  const [activityFields, setActivityFields] = useState<ActivityField[]>([]);
  const [typesInput, setTypesInput] = useState<InputType[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [groupsList, setGroupsList] = useState<UserGroup[]>([]);
  const [loader, setLoader] = useState(false);
  const mongoOperations = [...queryOperators, ...additionalQueryOperators];

  const getFilters = async () => {
    try {
      setLoader(true);
      if (dataSheet?._id?.$oid) {
        const result = await getSheetRecordsFilters(dataSheet?._id?.$oid);
        if (result) {
          if (result.owner_groups?.length) {
            const groups: UserGroup[] = await getUsersGroupList();
            const sheetGroups = groups.filter((v) =>
              result.owner_groups?.includes(v._id.$oid)
            );
            setGroupsList(sheetGroups);
          }
          if (result.owner_users?.length) {
            const users: User[] = await getUsersList();
            const sheetUsers: User[] = users.filter((v: User) =>
              result.owner_users?.includes(v.key)
            );

            result.owner_users.some((v: string) => v === "SYSTEM") &&
              sheetUsers.push({
                label: "SYSTEM",
                value: "SYSTEM",
              } as User);
            setUsersList(sheetUsers);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    try {
      setGroupsList([]);
      setUsersList([]);
      if (dataSheet?.sheet_schema?.length && !schema?.length) {
        const schemas: any[] = dataSheet.sheet_schema.map((schema) => {
          return {
            value: schema.entity_name,
            label: schema.display_name,
            inputType: schema.data_type || schema.input_type,
          };
        });
        {
          !hideOwnersField &&
            !!dataSheet &&
            schemas.push({
              value: "owner_users",
              label: "Owner Users",
              inputType: "select",
            });
          !hideOwnersField &&
            !!dataSheet &&
            schemas.push({
              value: "owner_groups",
              label: "Owner Groups",
              inputType: "select",
            });
        }

        setActivityFields(schemas);
        !hideOwnersField && getFilters();
      } else {
        const newSchemas = schema?.map((schema: SheetSchema) => {
          return {
            value: schema.entity_name,
            label: schema.display_name,
            inputType: schema.data_type || schema.input_type,
          };
        });
        setActivityFields(newSchemas);
      }
    } catch (error) {
      console.log("Something went wrong!", error);
      message.error("Something went wrong!");
    }
  }, [dataSheet]);

  useEffect(() => {
    if (initialFilters?.length) {
      const newTypesInput: any[] = [];
      initialFilters?.forEach((v, index) => {
        activityFields?.forEach((item) => {
          if (v?.column && v?.column === item.value) {
            newTypesInput.push({
              index,
              value: v.column,
              inputType: item.inputType,
            });
          }
        });
      });
      setTypesInput(newTypesInput);
    }
    !hideOwnersField && getFilters();
  }, [initialFilters, activityFields?.length]);

  const getSelectOptions = (key: number) => {
    let options: any[] = [];
    if (formFilters?.[key]?.column === "owner_users") {
      options = usersList;
    }
    if (formFilters?.[key]?.column === "owner_groups") {
      options = groupsList;
    }
    return options;
  };

  const handleChangeColumn = (value: any, item: any, key: number) => {
    const newItem = { ...item, index: key };

    if (!typesInput?.find((v) => v.index === key)) {
      setTypesInput([...typesInput, newItem]);
    } else {
      const data = typesInput.map((v) => {
        if (v.index === key) {
          return newItem;
        } else {
          return v;
        }
      });
      setTypesInput(data);
    }
    resetInput && resetInput(key);
  };

  const handleRemoveFilter = (key: number) => {
    const newTypes = typesInput
      .filter((v) => v.index !== key)
      .map((v, index) => {
        return { ...v, index: index };
      });
    setTypesInput(newTypes);
    onChangeFilters && onChangeFilters();
  };

  const getOperations = (key: number) => {
    const inputType = typesInput[key]?.inputType;
    switch (inputType) {
      case "select":
        return mongoOperations.filter(
          (v) => v.value === "$in" || v.value === "$all" || v.value === "$eq"
        );
      case "string":
        return mongoOperations.filter(
          (v) =>
            v.value !== "$in" &&
            v.value !== "$all" &&
            v.value !== "$lt" &&
            v.value !== "$gt" &&
            v.value !== "$lte" &&
            v.value !== "$gte"
        );
      case "dropdown":
        return mongoOperations.filter(
          (v) => v.value !== "$in" && v.value !== "$all"
        );
      default:
        return isLogicalExpression
          ? queryOperators
          : mongoOperations.filter(
              (v) =>
                v.value !== "$in" &&
                v.value !== "$all" &&
                v.value !== "$regex" &&
                v.value !== "doesNotContain"
            );
    }
  };

  const isDisabled = (index: number) => {
    return (
      formFilters &&
      (formFilters[index]?.op === "isEmpty" ||
        formFilters[index]?.op === "isNotEmpty")
    );
  };

  return (
    <Form.List name={listName || "filters"} key={"filters"}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const checkInputType = typesInput.find(
                (field) => field?.index === index
              )?.inputType;
              return (
                <Row
                  key={key}
                  align={"top"}
                  justify="space-between"
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <Col span={8}>
                    <Form.Item
                      name={[name, "column"]}
                      label={score ? "Datasheet field" : ""}
                      rules={[
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        disabled={isLogicalExpression}
                        onChange={(value, item) => {
                          handleChangeColumn(value, item, index);
                        }}
                        placeholder="Datasheet field name"
                        options={activityFields}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      label={score ? "Equality operator" : ""}
                      name={[name, "op"]}
                      rules={[
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Equality operator"
                        onChange={() => onChangeFilters && onChangeFilters()}
                        options={getOperations(index)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    {checkInputType == "date" ? (
                      <Form.Item
                        label={score ? "Input variable" : ""}
                        valuePropName="date"
                        name={[name, "column_value"]}
                        rules={[
                          {
                            required: !isDisabled(index),
                            message: "This field is required.",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabled={isDisabled(index)}
                          format="YYYY-MM-DD"
                          value={
                            formFilters?.[index]?.column_value?.length ||
                            formFilters?.[index]?.column_value instanceof moment
                              ? moment(formFilters?.[index]?.column_value)
                              : undefined
                          }
                          onChange={() => onChangeFilters && onChangeFilters()}
                          placeholder="Input variable"
                        />
                      </Form.Item>
                    ) : checkInputType == "number" ? (
                      <Form.Item
                        label={score ? "Input variable" : ""}
                        name={[name, "column_value"]}
                        rules={[
                          {
                            required: !isDisabled(index),
                            message: "This field is required.",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <InputNumber
                          disabled={isDisabled(index)}
                          placeholder="Input variable"
                        />
                      </Form.Item>
                    ) : checkInputType == "select" ? (
                      <Form.Item
                        label={score ? "Input variable" : ""}
                        name={[name, "column_value"]}
                        rules={[
                          {
                            required: !isDisabled(index),
                            message: "This field is required.",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <Select
                          placeholder="Input variable"
                          disabled={isDisabled(index)}
                          style={{ width: "100%" }}
                          notFoundContent={loader && <Spin size="small" />}
                          mode="multiple"
                          allowClear
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={getSelectOptions(index)}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label={score ? "Input variable" : ""}
                        name={[name, "column_value"]}
                        rules={[
                          {
                            required: !isDisabled(index),
                            message: "This field is required.",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <Input
                          disabled={isDisabled(index)}
                          placeholder="Input variable"
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Form.Item label={score ? " " : ""} labelCol={{ span: 24 }}>
                    <Col span={2}>
                      <StyledButton
                        type="default"
                        style={{
                          padding: "8px",
                          marginTop: "0px",
                          high: "30px",
                        }}
                        disabled={activityFields?.length === 0}
                        onClick={() => {
                          remove(index);
                          handleRemoveFilter(index);
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </Col>
                  </Form.Item>
                </Row>
              );
            })}
            <Form.Item
              name={""}
              rules={[
                {
                  required: fields.length === 0 && isLogicalExpression,
                  message: "Please add filters.",
                },
              ]}
            >
              <StyledButton
                type="custom"
                disabled={activityFields?.length === 0}
                onClick={() => {
                  isLogicalExpression
                    ? add({ column: activityFields[0].value })
                    : add();
                  onAddFilters && onAddFilters();
                }}
              >
                <PlusCircleOutlined /> {addButtonText}
              </StyledButton>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};
