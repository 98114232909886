import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getImportData = async (payload) => {
  let response = null;
  if (payload) {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_data_import",
        request_payload: JSON.stringify(payload),
      },
    });
  } else {
    response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_data_import",
      },
    });
  }
  return JSON.parse(response.data.datalake);
};

export const getImportDataById = async (importId) => {
  const payload = { filter: { _id: { $oid: importId } } };
  const data = await getImportData(payload);
  return data?.[0];
};

export const insertImportData = async (payload) => {
  const data = await Auth.currentSession();
  const username = data["accessToken"]["payload"]["username"];
  payload.username = username;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_data_import",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const updateImportData = async (payload) => {
  const data = await Auth.currentSession();
  const username = data["accessToken"]["payload"]["username"];
  payload.username = username;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_data_import",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const removeImportData = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_data_import",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const downloadTemplate = async () => {
  const response = await API.graphql({
    query: queries["datalakeImportData"],
    variables: {
      request_type: "GET_MULTIPLE_DATASHEETS_TEMPLATE",
    },
  });
  return JSON.parse(response.data.datalakeImportData);
};

export const downloadSingleSheetTemplate = async (
  sheet_id,
  type,
  display_fields
) => {
  const response = await API.graphql({
    query: queries["datalakeImportData"],
    variables: {
      request_type: "GET_SINGLE_DATASHEET_TEMPLATE",
      request_payload: JSON.stringify({ sheet_id, type, display_fields }),
    },
  });
  return JSON.parse(response.data.datalakeImportData);
};

export const modifyStatus = async (importId, importStatus) => {
  const response = await API.graphql({
    query: queries["datalakeImportData"],
    variables: {
      request_type: "MODIFY_STATUS",
      import_id: importId,
      import_status: importStatus,
    },
  });
  return JSON.parse(response.data.datalakeImportData);
};

export const getFilesById = async (dataId, query, requestType) => {
  const response = await API.graphql({
    query: queries[query],
    variables: { request_type: requestType, data_id: dataId },
  });
  const filesList = response["data"][query];
  return filesList;
};
export const getFilesByIdNew = async (filesList) => {
  const requestPayload = {
    filter: {
      _id: { $oid: filesList },
    },
  };
  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: {
      request_type: "LIST_FILES",
      data_id: "",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return response["data"]["dataSheetFiles"];
};
export const uploadFile = async (payload, query, requestType) => {
  const response = await API.graphql({
    query: queries[query],
    variables: { request_type: requestType, ...payload },
  });
  return response["data"][query];
};
export const uploadFileNew = async (
  dataId,
  importId,
  payload,
  query,
  requestType
) => {
  const response = await API.graphql({
    query: queries[query],
    variables: {
      request_type: requestType,
      data_id: dataId,
      import_id: importId,
      request_payload: JSON.stringify(payload),
    },
  });
  return response["data"][query];
};

export const processFileMultipleSheet = async (payload) => {
  const response = await API.graphql({
    query: queries["datalakeImportData_job"],
    variables: {
      request_type: "PROCESS_FILE_MULTIPLE_SHEETS",
      ...payload,
    },
  });
  const responseFile = response?.["data"]["datalakeImportData_job"];
  const filenameMatch = responseFile.match(/job_id=([^;\n]+)/);
  const fileName = filenameMatch[1].replace(/}/g, "");
  return fileName;
};
export const getJobExecutionStatus = async (payload) => {
  const response = await API.graphql({
    query: queries["getJobExecutionStatus"],
    variables: {
      ...payload,
    },
  });
  return response["data"]["getJobExecutionStatus"];
};
export const fetchDataWithRetry = async (job_id) => {
  const maxRetries = 100;
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      const payload = {
        id: job_id,
      };
      const response = await getJobExecutionStatus(payload);

      if (!response.status) {
        throw new Error("Failed to fetch data");
      }

      if (response.status != "IN_PROGRESS") {
        return response; // Return the result upon successful fetch
      }
      retryCount++;
      if (retryCount < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    } catch (error) {
      console.error("Error fetching data:");
      retryCount++;
      if (retryCount < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } else {
        throw new Error("Max retries reached");
      }
    }
  }
};
