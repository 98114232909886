import React, { useState, useEffect, createContext } from "react";
import { Select, Row, Col, message } from "antd";
import { ContentSection } from "../../shared/commonStyles";
import { BusinessUnitSelect } from "../reportingPage/Components/BusinessUnitSelect/BusinessUnitSelect";
import { DateSelect, LocationSelect } from "../reportingPage/Components";
import { getReportFilters, getReportingStatus } from "../../services/filter";

export const ReportingContext = createContext();

export const ReportingProvider = ({
  children = <></>,
  isFiltersShow = true,
  isAuditPage = false,
}) => {
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportingStatus, setReportingStatus] = useState({});
  const [businessUnits, setBusinessUnits] = useState([]);
  const [orgLocations, setOrgLocations] = useState([]);
  const [reportPeriods, setReportPeriods] = useState([]);
  const [businessUnitsId, setBusinessUnitsId] = useState("");
  const [orgLocationId, setOrgLocationId] = useState("");
  const [reportPeriodId, setReportPeriodId] = useState("");

  const getReportingData = async (
    reportPeriodId,
    orgLocationId,
    businessUnitsId
  ) => {
    let unMounted = false;
    setLoading(true);
    const payload = {
      report_period: reportPeriodId ? reportPeriodId : null,
      location_id: orgLocationId ? orgLocationId : null,
      business_unit: businessUnitsId ? businessUnitsId : null,
    };
    await getReportingStatus(payload).then((response) => {
      if (unMounted) return;
      if (response?.ERORR) {
      } else {
        setReportingStatus(response);
        setLoading(false);
      }
    });

    return () => (unMounted = true);
  };

  useEffect(() => {
    try {
      getReportingData(reportPeriodId, orgLocationId, businessUnitsId);
    } catch (e) {
      message.error(
        "Some Problem occured which fetching reporting status data!!!"
      );
    } finally {
      setLoading(false);
    }
  }, [businessUnitsId, orgLocationId, reportPeriodId]);

  /* New Api's Being Called */
  const isStorageSet = (type) => {
    let isStorageSet = false;
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i) === type) {
        isStorageSet = true;
      }
    }
    return isStorageSet;
  };
  const getFilters = async () => {
    const payload = {
      filter_types: ["location", "business_unit", "reporting_period"],
    };
    const response = await getReportFilters(payload);
    if (response) {
      const businessUnit = response?.filter(
        (item) => item.filter_type === "business_unit"
      );
      const location = response?.filter(
        (item) => item.filter_type === "location"
      );
      const reportingPeriod = response?.filter(
        (item) => item.filter_type === "reporting_period"
      );
      getBusinessUnit(businessUnit);
      getLocation(location);
      getReportingPeriod(reportingPeriod);
    }
  };

  const getBusinessUnit = async (response) => {
    if (response) {
      const defaultBusinessUnitId = response?.find(
        ({ status }) => status === "ACTIVE"
      )?._id["$oid"];
      if (!isAuditPage) {
        if (isStorageSet("selectedBusinessUnit")) {
          setBusinessUnitsId(localStorage.getItem("selectedBusinessUnit"));
        } else {
          localStorage.setItem("selectedBusinessUnit", defaultBusinessUnitId);
          setBusinessUnitsId(defaultBusinessUnitId);
        }
      }
      setBusinessUnits(response);
    }
  };

  const getLocation = async (response) => {
    if (response) {
      const defaultLocationId = response?.find(
        ({ status }) => status === "ACTIVE"
      )?._id["$oid"];
      if (!isAuditPage) {
        if (isStorageSet("selectedLocation")) {
          setOrgLocationId(localStorage.getItem("selectedLocation"));
        } else {
          localStorage.setItem("selectedLocation", defaultLocationId);
          setOrgLocationId(defaultLocationId);
        }
      }
      setOrgLocations(response);
    }
  };

  const getReportingPeriod = async (response) => {
    if (response) {
      const defaultPeriodId = response?.find(
        ({ status }) => status === "ACTIVE"
      )?._id["$oid"];
      if (!isAuditPage) {
        if (isStorageSet("selectedReportPeriod")) {
          setReportPeriodId(localStorage.getItem("selectedReportPeriod"));
        } else {
          localStorage.setItem("selectedReportPeriod", defaultPeriodId);
          setReportPeriodId(defaultPeriodId);
        }
      }
      setReportPeriods(response);
    }
  };

  useEffect(() => {
    !isAuditPage && localStorage.removeItem("isReportFilterChanged");
    getFilters();
  }, []);

  const data = {
    loading,
    loadingUnits,
    reportingStatus,
    businessUnits,
    orgLocations,
    reportPeriods,
    businessUnitsId,
    orgLocationId,
    reportPeriodId,
    setLocations: setOrgLocations,
    setTimePeriods: setReportPeriods,
    setBusinessUnits,
  };

  return (
    <ContentSection>
      {isFiltersShow && (
        <Row gutter={[16, 16]}>
          <Col>
            <BusinessUnitSelect
              key={1}
              selectedBusinessUnit={businessUnitsId}
              businessUnits={businessUnits}
              setSelectedBusinessUnit={(value) => setBusinessUnitsId(value)}
              setBusinessUnits={setBusinessUnits}
              visibleAddBtn={false}
              extraOptions={
                <Select.Option value="" label={"All"}>
                  All
                </Select.Option>
              }
            />
          </Col>
          <Col>
            <LocationSelect
              key={2}
              setRange={(value) => setOrgLocationId(value)}
              value={orgLocationId}
              locations={orgLocations}
              setLocation={setOrgLocations}
              visibleAddBtn={false}
              extraOptions={
                <Select.Option value="" label={"All"}>
                  All
                </Select.Option>
              }
            />
          </Col>
          <Col>
            <DateSelect
              key={3}
              setRange={(value) => setReportPeriodId(value)}
              value={reportPeriodId}
              timeperiods={reportPeriods}
              setTimePeriod={setReportPeriods}
              visibleAddBtn={false}
              extraOptions={
                <Select.Option value="" label={"All"}>
                  All
                </Select.Option>
              }
            />
          </Col>
        </Row>
      )}
      <ReportingContext.Provider value={data}>
        {children}
      </ReportingContext.Provider>
    </ContentSection>
  );
};
