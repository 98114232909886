import React, { useState } from "react";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AddUnitConversionModal } from "./AddUnitConversionModal";
import styles from "../UnitsTable.module.scss";

interface TableConversionsButtonsProps {
  data: UnitConversion;
}

export const TableConversionsButtons = ({
  data,
}: TableConversionsButtonsProps) => {
  const [isAddConversionModalVisible, setAddConversionModalVisible] =
    useState(false);
  return (
    <div className={styles.tableButtonWrap}>
      <Button
        className={styles.tableButton}
        onClick={() => setAddConversionModalVisible(true)}
      >
        <EditOutlined className={styles.tableButtonIcon} />
        Edit
      </Button>
      <Button className={styles.tableButton}>
        <DeleteOutlined className={styles.tableButtonIcon} /> Delete
      </Button>
      <AddUnitConversionModal
        visible={isAddConversionModalVisible}
        onClose={() => setAddConversionModalVisible(false)}
        data={data}
      />
    </div>
  );
};
