import { Col, Collapse, Form, Row, Checkbox, Card, Tag, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
  StyledCollapseForGoals,
} from "../../../../../shared/commonStyles";
import { RightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { sdgGoalsStaticList } from "../../../../../shared/constants";
const { Title } = Typography;
const { Panel } = Collapse;

const Step2 = ({
  onClickNext,
  onClickPrevious,
  initialValues,
  allGoalSamples,
}) => {
  const [loader, setLoader] = useState(false);
  const [subGoals, setSubGoals] = useState([]);
  const [subGoalsInitiative, setSubGoalsInitiative] = useState([]);
  const [selectedStaticGoal, setSelectedStaticGoal] = useState(null);

  const mapGoals = () => {
    if (initialValues?.selectedGoals > 0) {
      setLoader(true);
      //const mappedGoals = initialValues?.selectedGoals?.map(item => {
      const selectedStaticGoal = sdgGoalsStaticList.find(
        (staticGoals) => staticGoals.goal === initialValues?.selectedGoals
      );
      setSelectedStaticGoal(selectedStaticGoal);
      const filteredSubGoals = allGoalSamples?.filter((goalItem) => {
        return (
          parseInt(goalItem.goal) === parseInt(initialValues?.selectedGoals) &&
          /^[0-9.]+$/.test(goalItem.target)
        );
      });

      const filteredSubGoalsInitiative = allGoalSamples?.filter((goalItem) => {
        return (
          parseInt(goalItem.goal) === parseInt(initialValues?.selectedGoals) &&
          !/^[0-9.]+$/.test(goalItem.target)
        );
      });
      setSubGoals(filteredSubGoals);
      setSubGoalsInitiative(filteredSubGoalsInitiative);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (initialValues?.selectedGoals) {
      mapGoals();
    }
  }, [initialValues]);
  return (
    <FormWrap style={{ width: "80%" }}>
      <Spin spinning={loader}>
        {selectedStaticGoal && (
          <FormWrapper>
            <Title level={4}> Select indicators for the selected goal</Title>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Tag color="#569CE6" className="tag-rounded">
                  {selectedStaticGoal.title}
                </Tag>
              </Col>
              {subGoals.length > 0 && (
                <Col span={24}>
                  <Form.Item name={`subGoals`} label="">
                    <Checkbox.Group
                      style={{
                        width: "100%",
                      }}
                    >
                      <StyledCollapseForGoals
                        collapsible="header"
                        defaultActiveKey="subGoals"
                      >
                        <Panel header={`Sub-Goals`} key="subGoals">
                          <Row gutter={[4, 4]}>
                            {subGoals?.map((data, index) => (
                              <Col span={24} key={index}>
                                <Card
                                  style={{
                                    backgroundColor: "#37304B",
                                  }}
                                  bodyStyle={{ padding: "15px" }}
                                >
                                  <Checkbox value={data._id.$oid}>
                                    <Row
                                      gutter={[16, 16]}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Col span={24}>
                                        Target {data?.target} :{" "}
                                        {data?.target_description}
                                      </Col>
                                    </Row>
                                  </Checkbox>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Panel>
                      </StyledCollapseForGoals>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              )}
              {subGoalsInitiative.length > 0 && (
                <Col span={24}>
                  <Form.Item name={`subGoalsInitiative`} label="">
                    <Checkbox.Group
                      style={{
                        width: "100%",
                      }}
                    >
                      <StyledCollapseForGoals
                        collapsible="header"
                        defaultActiveKey="subGoalsInitiatives"
                      >
                        <Panel
                          header={`Sub-Goal Initiatives	`}
                          key="subGoalsInitiatives"
                        >
                          <Row gutter={[16, 16]}>
                            {subGoalsInitiative?.map((data, index) => (
                              <Col span={24} key={index}>
                                <Card
                                  style={{
                                    backgroundColor: "#37304B",
                                  }}
                                  bodyStyle={{ padding: "15px" }}
                                >
                                  <Checkbox value={data._id.$oid}>
                                    <Row
                                      gutter={[16, 16]}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Col span={24}>
                                        Target {data?.target} :{" "}
                                        {data?.target_description}
                                      </Col>
                                    </Row>
                                  </Checkbox>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Panel>
                      </StyledCollapseForGoals>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col span={24}>
                <Form.Item>
                  <StyledButton
                    onClick={() => onClickPrevious()}
                    type="default"
                    ghost
                  >
                    <span>Back</span>
                  </StyledButton>
                  <StyledButton onClick={onClickNext} type="custom">
                    <span>Continue to step 3</span> <RightOutlined />
                  </StyledButton>
                </Form.Item>
              </Col>
            </Row>
          </FormWrapper>
        )}
      </Spin>
    </FormWrap>
  );
};

export default Step2;
