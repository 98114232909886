import {
  Button,
  Dropdown,
  Form,
  Input,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import { UploadOutlined } from "@ant-design/icons";
import { fileUploadSize, fileUploadTypes } from "../../../../shared/constants";
import { ButtonType } from "antd/lib/button";
import styles from "./DenyReasonModal.module.scss";

interface DenyReasonModalProps {
  onConfirm: (reason: string, files?: File[]) => void;
  button: {
    icon: React.ReactChild;
    text: string;
    className?: string;
    type: ButtonType;
    disabled?: boolean;
    submitting?: boolean;
  };
}
export const DenyReasonModal = ({
  button,
  onConfirm,
}: DenyReasonModalProps) => {
  const [statusReason, setStatusReason] = useState("");
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const handleClose = () => {
    setFiles([]);
    setStatusReason("");
    setVisible(false);
  };

  const handleConfirm = () => {
    onConfirm(statusReason, files);
    handleClose();
  };

  const beforeUpload = async (file: File) => {
    const filename = file?.name;
    const fileSize = file?.size || 0;
    const ext = (filename && filename?.split(".")?.pop()?.toLowerCase()) || "";

    if (!fileUploadTypes.includes(ext)) {
      message.error(`${file?.name} is not a valid file type`);
      return;
    }
    if (fileSize > fileUploadSize) {
      message.error("File Upload size is larger than 25MB");
      return;
    }
    setFiles([...files, file]);
    return false;
  };

  const onRemoveFileFromListHandler = (file: any) => {
    const filesList = files.filter((v) => v.uid !== file.uid);
    setFiles(filesList);
  };

  const reasonContent = (
    <FormWrapper>
      <Typography.Title level={5}>Summarize your feedback</Typography.Title>
      <Row className={styles.row}>
        <div>
          <Typography.Text>Note/Reason</Typography.Text>{" "}
          <span className="color-grey font-14">(Optional)</span>
        </div>
      </Row>
      <Form.Item required>
        <Input.TextArea
          value={statusReason}
          onChange={(e) => setStatusReason(e.target.value)}
        />
      </Form.Item>
      <Row className={styles.row}>
        <div>
          <Typography.Text>Attach related files</Typography.Text>{" "}
          <span className="color-grey font-14">(Optional)</span>
        </div>
      </Row>
      <Upload
        customRequest={({ file, onSuccess }) => {
          onSuccess && onSuccess("ok");
        }}
        beforeUpload={beforeUpload}
        fileList={files}
        className={`upload-list-inline ${styles.uploader}`}
        listType="text"
        multiple={false}
        onRemove={onRemoveFileFromListHandler}
      >
        <StyledButton padding="5px 10px" icon={<UploadOutlined />}>
          Upload
        </StyledButton>
      </Upload>
      <Row className={styles.row} align="middle">
        <Button type="text" onClick={handleClose}>
          Cancel
        </Button>
        <StyledButton onClick={handleConfirm}>Confirm & Send</StyledButton>
      </Row>
    </FormWrapper>
  );
  return (
    <Dropdown
      overlayClassName={styles.dropdownContent}
      overlay={reasonContent}
      visible={visible}
      onVisibleChange={(open) => setVisible(open)}
      trigger={["click"]}
      disabled={button.disabled}
    >
      {button.type === "text" ? (
        <Button
          type={button.type}
          onClick={() => setVisible(true)}
          icon={button.icon}
          disabled={button.disabled}
          loading={button.submitting}
          className={button.className || ""}
        >
          {button.text}
        </Button>
      ) : (
        <StyledButton
          type={button.type}
          onClick={() => setVisible(true)}
          icon={button.icon}
          disabled={button.disabled}
          loading={button.submitting}
          className={button.className || ""}
        >
          {button.text}
        </StyledButton>
      )}
    </Dropdown>
  );
};
