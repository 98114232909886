import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Card, Spin, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import * as queries from "../../../../graphql/queries";
import { API } from "aws-amplify";
import {
  PageWrap,
  StyledTitle,
  StyledTag,
  StyledButton,
} from "./../../../../shared/commonStyles";
import { getToFixedNumber } from "../../../../shared/commonFunctions";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ProductDetailsPage = () => {
  const [projectDetails, setProjectDetails] = useState(null);
  const [loadingProjectDetails, setLoadingProjectDetails] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const loadProductDetails = async (id) => {
    try {
      setLoadingProjectDetails(true);
      const response = await API.graphql({
        query: queries["decarbonization"],
        variables: {
          request_type: "project_details",
          request_payload: JSON.stringify({ project_id: id }),
        },
      });
      const details = JSON.parse(response["data"]["decarbonization"]);
      if (details) {
        setProjectDetails(details);
      }
    } catch (error) {
      console.log("error", error);
      message.error("Error while load details!");
    } finally {
      setLoadingProjectDetails(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadProductDetails(id);
    }
  }, [id]);

  const getStandard = (standard) => {
    try {
      return !standard.includes("{") ? standard : "NA";
    } catch (e) {
      return "NA";
    }
  };

  const onClickPurchase = (id) => {
    navigate(`/carbon/carbon-offsets/${id}/new-order`);
  };
  const firstImage = projectDetails?.["photos"][0]?.url;
  const secordImage = projectDetails?.["photos"][1]?.url;
  const thirdImage = projectDetails?.["photos"][2]?.url;
  const productName = projectDetails?.name ? projectDetails?.name : "";
  const productDescription = projectDetails?.description
    ? projectDetails?.description
    : "";
  const price = projectDetails?.average_price_per_tonne_cents_usd
    ? projectDetails?.average_price_per_tonne_cents_usd / 100
    : 0;
  const remainingMassG = projectDetails?.remaining_mass_g
    ? projectDetails?.remaining_mass_g
    : 0;
  const tonnes = getToFixedNumber(remainingMassG / 1000000);
  const mechanism = projectDetails?.mechanism
    ? projectDetails?.mechanism
    : "NA";
  const standard = getStandard(projectDetails?.standard);
  const developer = projectDetails?.developer
    ? projectDetails?.developer
    : "NA";
  return (
    <PageWrap>
      <Spin spinning={loadingProjectDetails}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col>
                <StyledTitle level={3}>{productName}</StyledTitle>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={14} lg={14}>
                <div>
                  <img
                    style={{ borderRadius: 10 }}
                    alt="example"
                    src={firstImage}
                    width={"100%"}
                    height={380}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={10} lg={10}>
                {secordImage && (
                  <div style={{ padding: "0 0 10px 0" }}>
                    <img
                      style={{ borderRadius: 10 }}
                      alt="example"
                      src={secordImage}
                      width={"100%"}
                      height={180}
                    />
                  </div>
                )}
                {thirdImage && (
                  <div style={{ padding: "10px 0 0 0" }}>
                    <img
                      style={{ borderRadius: 10 }}
                      alt="example"
                      src={thirdImage}
                      width={"100%"}
                      height={180}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={16} lg={16}>
                <Card title="Story" bordered={true} style={{ minHeight: 200 }}>
                  {productDescription}
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Card
                  title="Inventory"
                  bordered={true}
                  style={{ minHeight: 150 }}
                  actions={[
                    <StyledButton
                      key="purchaseButton"
                      onClick={() => onClickPurchase(id)}
                      style={{ width: "90%" }}
                      type="primary"
                    >
                      Purchase
                    </StyledButton>,
                  ]}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <p
                        style={{ fontSize: 14, fontWeight: 600 }}
                      >{`${tonnes}t`}</p>
                      <p style={{ fontSize: 10, color: "grey" }}>AVAILABLE</p>
                    </div>
                    <div>
                      <p
                        style={{ fontSize: 14, fontWeight: 600 }}
                      >{`$${price}`}</p>
                      <p style={{ fontSize: 10, color: "grey" }}>PER TONNE</p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Card
                  title="Technology"
                  bordered={true}
                  style={{ minHeight: 200 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 10,
                    }}
                  >
                    <Text type="secondary">Mechanism</Text>
                    <span style={{ textTransform: "capitalize" }}>
                      <StyledTag
                        style={{ margin: 0 }}
                        icon={<VerticalAlignBottomOutlined />}
                      >
                        {mechanism}
                      </StyledTag>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 10,
                    }}
                  >
                    <Text type="secondary">Standard</Text>
                    <span>{standard}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 10,
                    }}
                  >
                    <Text type="secondary">Developer</Text>
                    <span>{developer}</span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                {/* <Card title="Location" bordered={true} style={{ minHeight: 200 }}>
                                    
                                </Card> */}
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}></Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={16} lg={16}>
                <Card
                  title="Highlights"
                  bordered={true}
                  style={{ minHeight: 150 }}
                >
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {(projectDetails?.highlights || []).map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            padding: 10,
                            backgroundColor: "#f4f3f3",
                            display: "flex",
                            width: 320,
                            margin: 10,
                            borderRadius: 10,
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="margin-right-5"
                            src={`https:${item?.icon_url}`}
                            width={30}
                            height={30}
                          />
                          <span>{item?.title}</span>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </PageWrap>
  );
};
export default ProductDetailsPage;
