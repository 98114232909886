import React,{useState, useEffect} from "react";
import {
  Chart,
  Series,
  ValueAxis,
  Tooltip,
  Legend,
  ArgumentAxis,
} from "devextreme-react/chart";
import moment from 'moment'
import { Spin, message } from "antd";
  import { getDeployStatusBySurveyId } from "./../../../services/taskDeployment";

export const MyCustomLineChart = ({ surveyId }) => {    
  
  const [loader, setLoader] = useState(false);
  const [deploySurveyMetrics, setDeploySurveyMetrics] = useState(null);
  const [chartData, setChartData] = useState(null);
  const currentMonth = moment().format("MM")
  const currentMonthName = moment().format("MMM")
  
  useEffect(() => {  
    if(surveyId){
      getDeploySurveyMetrics()
      
    }
  }, [surveyId])

  const medalSources = [
    { value: 'value', name: currentMonthName, color: '#A68DFB' },
  ];
  const getDeploySurveyMetrics = async() => {
    try {
      setLoader(true)
      const deployStatusResonse = await getDeployStatusBySurveyId(surveyId)
      setDeploySurveyMetrics(deployStatusResonse?.items)
    } catch (e) {
      message.error("Failed to load progress  data");
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {  
    if(surveyId){
      getDeploySurveyMetrics()
      
    }
  }, [surveyId])

  useEffect(() => {  
    if(deploySurveyMetrics){
      const numberDays = moment().daysInMonth();
      const result = deploySurveyMetrics.reduce(function (r, a) {
        const completionMonth = moment(a?.completion_date).format("MM")
        if(parseInt(currentMonth) === parseInt(completionMonth)){
          const completionDate = moment(a?.completion_date).format("D")
          if(r){
            r[completionDate] = r[completionDate] || [];
            r[completionDate].push(a);
          }
          return r;
        }
      }, Object.create(null));        
      const chartData = []
      for(let i =1; i <= numberDays; i++){
        let data = null
        if(result && result[i] && result[i] !== undefined){
          data = {date:parseInt(i),value:result[i].length}
        }else{
          data = {date:i,value:0}
        }
        chartData.push(data)
      }    
      setChartData(chartData)
    }
  }, [deploySurveyMetrics])
  return (
    <Spin spinning={loader}>
      {chartData && 
        <Chart          
          id="chart"
          dataSource={chartData}
        >
          {
            medalSources.map((item) => 
            <Series
              key={item.value}
              valueField={item.value}
              argumentField="date"
              name={item.name}
              color={item.color}
              type="bar" 
            />
          )}
          
          <ValueAxis name="value" title="Completions of the Survey" position="left" tickInterval={2} min={0} />
          <ArgumentAxis allowDecimals={false} argumentType='numeric'></ArgumentAxis>
          <Tooltip enabled={true} />          
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
          />       

        </Chart>
      }
    </Spin>
  );
};
