import {
  API, Auth
} from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import React, { useState, useEffect } from "react";
import { message, Space, Dropdown, Menu } from "antd";
import {
  EllipsisOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { CommonTable, StyledButton, TableStyledMenu, FormWrapper } from "../../../shared/commonStyles";

const AccountsDataTable = ({
  handleViewUserFlow,
  handleAccountUpdate,
  loadingTable,
  setLoadingTable,
}) => {
  const [dataSource, setDataSource] = useState();
  const [refreshTable, setRefreshTable] = useState({value: '0'});

  const loadData = async () => {
    try {
      setLoadingTable(true);
      const data = await Auth.currentSession();
      const ClientUserId = data['accessToken']['payload']['username'];

      const requestPayload = {
        client_user_id: ClientUserId
      };

      const responseGetUtilityBills = await API.graphql({
        query: queries["getUtilityBills"],
        variables: {
          request_type: "list_utility_accounts",
          request_payload: JSON.stringify(requestPayload)
        }
      });

      const response = JSON.parse(responseGetUtilityBills.data.getUtilityBills);

      if (response) {
        setDataSource(response);
      }

    } catch (error) {
      console.log('error occured : ', error);
      message.error("Failed to load data");
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [refreshTable]);

  const menu = (utility_credential_id) => (
    <TableStyledMenu style={{ padding: '0' }}>
      <Menu.Item
        key="1"
        style={{ backgroundColor: 'transparent', padding: '0' }}
      >
        <StyledButton
          type="default"
          style={{ width: '100%', margin: '0' }}
          icon={<EditOutlined />}
          onClick={() => { handleAccountUpdate(utility_credential_id) }}
        >Edit</StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );


  const columns = [
    {
      title: "Utility Name",
      dataIndex: "utility_name",
      width: "20%",
    },
    {
      title: "Service Address",
      dataIndex: "address",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.service_address_street_one ? record.service_address_street_one + ', ' : ''}
          {record.service_address_street_two ? record.service_address_street_two + ', ' : ''}
          {record.service_address_city ? record.service_address_city + ', ' : ''}
          {record.service_address_state ? record.service_address_state + ', ' : ''}
          {record.service_address_zip ? record.service_address_zip : ''}
        </span>
      )
    },
    {
      title: "Service Status",
      dataIndex: "status",
      width: "10%",
      render: (status, rowData) => {
        return (
          <p style={{ margin: '0px' }}>{status === 'active' ? 'ACTIVE' : 'INACTIVE'}</p>
        )
      }
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: "6%",
      render: (rowData) => (
        <Space direction="horizontal">
          <StyledButton
            type="default"
            onClick={() => { handleViewUserFlow(rowData) }}
            icon={<EyeOutlined />}
          />
          <Dropdown overlay={() => menu(rowData.utility_credential_id)} trigger={["click"]}>
            <StyledButton type="default" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <FormWrapper>
        <CommonTable
          loading={loadingTable}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: true }}
        />
      </FormWrapper>
      <textarea 
        value={refreshTable.value} 
        onChange={({ target: { value } }) => setRefreshTable({value})} 
        style = {{display : 'none'}}
      />
    </>
  )
}

export default AccountsDataTable


window.triggerChange = function triggerChange(nextValue) {
  const element = document.querySelector('textarea')
  const event = new Event('input', { bubbles: true })
  const previousValue = element.value

  element.value = nextValue
  element._valueTracker.setValue(previousValue)
  element.dispatchEvent(event)
}