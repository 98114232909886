import React, { useEffect, useState } from "react";
import { Button, Radio, Space, Typography } from "antd";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";

interface IndicatorModalProps {
  visible: boolean;
  defaultValue?: string;
  emissionName: string;
  onConfirm: (value: string) => void;
  onCancel: () => void;
}

export const CategoryModal = ({
  visible,
  defaultValue,
  emissionName,
  onConfirm,
  onCancel,
}: IndicatorModalProps) => {
  const [category, setCategory] = useState(defaultValue || "default");
  const categories = [
    { value: "1", text: "1: Purchased Goods & Services" },
    { value: "2", text: "2: Capital Goods" },
    {
      value: "3",
      text: " 3: Fuel- and Energy-Related Activities Not Included in Scope 1 or Scope 2",
    },
    { value: "4", text: "4: Upstream Transportation and Distribution" },
    { value: "5", text: "5: Waste Generated in Operations" },
    { value: "6", text: "6: Business Travel" },
    { value: "7", text: "7: Employee Commuting" },
    { value: "8", text: "8: Upstream Leased Assets" },
    { value: "9", text: "9: Downstream Transportation and Distribution" },
    { value: "10", text: "10: Processing of Sold Products" },
    { value: "11", text: "11: Use of Sold Products" },
    { value: "12", text: "12: End-of-Life Treatment of Sold Products" },
    { value: "13", text: "13: Downstream Leased Assets" },
    { value: "14", text: "14: Franchises" },
    { value: "15", text: "15: Investments" },
  ];
  useEffect(() => {
    setCategory(defaultValue || "default");
  }, [defaultValue]);

  return (
    <CommonModal
      visible={visible}
      title="Category"
      onCancel={onCancel}
      onOk={onConfirm}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => onConfirm(category)}
        >
          Submit
        </Button>,
      ]}
      centered
    >
      <div>
        <Typography.Text>
          Select Category for <b>{emissionName}</b>
        </Typography.Text>
      </div>
      <FormWrapper>
        <Radio.Group
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <Space direction="vertical">
            {categories.map((category) => {
              return (
                <Radio key={category.value} value={category.value}>
                  {category.text}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </FormWrapper>
    </CommonModal>
  );
};
