import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';  

export const getEsgImprovementIndicators = async (requestPayload) => {  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "get_esg_improvement",
    'request_payload': JSON.stringify(requestPayload)
  }});
  return JSON.parse(response.data.datalake);
};
