import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd'
import { BookOutlined } from '@ant-design/icons'
import { CardsWrap } from '../../shared/commonStyles'
import { ReportingContext } from './ReportingProvider'
import { COLORS } from '../../shared/colors'
import { CustomIconCard } from '../../components/CustomCard/CustomIconCard';

const customIconCardStyle = {
  cardStyle: { margin: 10 },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white }
}


export const StandardOverview = () => {
  const navigate = useNavigate();
  const { loading, reportingStatus } = useContext(ReportingContext)

  return (
    <>
      { loading && <Spin></Spin> }
      { !loading && <CardsWrap>
          {reportingStatus.results_by_standard?.map((item, index) => (            
            <CustomIconCard
                key={index}
                title={Object.keys(item)[0]}
                customCardStyle={customIconCardStyle.cardStyle}
                titleStyle={customIconCardStyle.titleStyle}
                onClick={()=>navigate(`/reporting/sustainability-standards?standard=${Object.keys(item)[0]}`)}
                iconComponent={<BookOutlined  style={customIconCardStyle.iconStyle}/>}
              />
          ))}
        </CardsWrap>
      }
    </>
  )
}