import React from "react";
import styles from "./ImportData.module.scss";
import { Col, Row } from "antd";
import {
  StyledButton,
  StyledProgress,
  StyledSteps,
} from "../../../shared/commonStyles";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface ImportHeaderProps {
  steps: any[];
  currentStep: number;
  onExit: () => void;
  onChangeStep: (current: number) => void;
}

export const ImportHeader = ({
  steps,
  currentStep,
  onExit,
  onChangeStep,
}: ImportHeaderProps) => {
  return (
    <>
      <Row className={styles.header} wrap={false}>
        <Col flex={1}>
          <StyledButton
            type="custom"
            onClick={onExit}
            hovercolor="#A68DFB"
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="#fff"
          >
            <ArrowLeftOutlined /> <span>Exit</span>
          </StyledButton>
        </Col>
        <Col flex={4} className="user-flow-steps-wrap color-white">
          <StyledSteps current={currentStep} onChange={onChangeStep}>
            {steps.map((step) => (
              <StyledSteps.Step
                className="stepper-wrapper "
                key={step.title}
                title={step.title}
              />
            ))}
          </StyledSteps>
        </Col>
      </Row>
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={(currentStep === 0 && 50) || (currentStep === 1 && 100)}
        showInfo={false}
        className={styles.progress}
      />
    </>
  );
};
