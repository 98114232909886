import React from "react";
import { useBoolean } from "ahooks";
import { Col, Popover, Row } from "antd";
import { observer } from "mobx-react-lite";
import { QuestionCircleFilled } from "@ant-design/icons";
import {
  CommonTable,
  StyledButton,
  StyledProgress,
} from "../../../shared/commonStyles";
import { TableButtons } from "./Components/TableButtons";
import { reductionPlanStore } from "../WhatIfOnboarding/ReductionPlanStore";

import { AddTargetModal } from "../WhatIfOnboarding/Components/AddTargetModal";
import { getProgress } from "./ReductionPlanUtils";
import { getScopeNumber } from "../../Carbon/CalculationUtils";
import styles from "./ReductionPlanListView.module.scss";

export const ReductionPlanListView = observer(() => {
  const [
    isCustomTargetModalVisible,
    { setTrue: showAddCustomTargetModal, setFalse: hideAddCustomTargetModal },
  ] = useBoolean(false);

  const [
    isScienceTargetModalVisible,
    { setTrue: showAddScienceTargetModal, setFalse: hideAddScienceTargetModal },
  ] = useBoolean(false);

  const handleAddTarget = (data: TargetPayload) => {
    reductionPlanStore
      .saveTarget(data)
      .then(() => reductionPlanStore.getAllTargets());
    hideAddScienceTargetModal();
    hideAddCustomTargetModal();
  };

  const getPercentageValue = (
    value: number,
    base_year_consumption_amount: number
  ) => {
    if (value && base_year_consumption_amount >= 0) {
      return (
        (value - base_year_consumption_amount) /
        -(base_year_consumption_amount / 100)
      );
    } else return 0;
  };

  const handleDelete = (data: Target) => {
    reductionPlanStore.deleteTarget(data._id.$oid);
  };

  // Function to get number value from Scope_Name enum

  const columns = [
    {
      title: "Emission",
      dataIndex: "emissionName",
      key: "emissionName",
      width: "20%",
      sorter: (a: Target, b: Target) =>
        a.emissionName.localeCompare(b.emissionName),
    },

    {
      title: "Scenario",
      dataIndex: "scenario",
      key: "scenario",
      width: "10%",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      width: "5%",
      render: (value: ScopeName) => {
        return <>{getScopeNumber(value)}</>;
      },
    },
    {
      title: "Current Consumption Amount",
      dataIndex: "currentConsumptionAmount",
      key: "consumptionAmount",
      width: "15%",
    },
    {
      title: "Reduction amount",
      dataIndex: "goal",
      key: "goal",
      width: "10%",
      align: "center",
      render: (value: number, target: Target) => {
        return (
          <span>
            {getPercentageValue(value, target.baseYearConsumptionAmount)
              .toFixed(2)
              .replace(/\.00$/, "")}
            %
          </span>
        );
      },
    },
    {
      title: "Target year",
      dataIndex: "targetYear",
      key: "targetYear",
      width: "10%",
    },
    {
      title: "Progress",
      dataIndex: "goal",
      key: "goal",
      width: "15%",
      align: "end",
      render: (_: any, data: Target) => {
        return (
          <StyledProgress
            percent={getProgress(data)}
            format={(percent: number) => percent.toFixed(0) + "%"}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "_id",
      // width: "150px",
      render: (_: any, data: Target) => {
        return <TableButtons data={data} onDelete={() => handleDelete(data)} />;
      },
    },
  ];

  return (
    <>
      <Row className={styles.tableWrap}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <CommonTable
            rowKey={(record: Target) => record._id.$oid}
            scroll={{ x: true }}
            title={() => (
              <>
                <span className={styles.tableTitle}>Science based targets</span>{" "}
                <Popover
                  content={
                    <div className={styles.popoverInfo}>
                      Targets in-line with the goals of the Paris Agreement, which provide a clearly-defined pathway towards GHG emissions reduction
                    </div>
                  }
                  overlayClassName="popoverContent"
                >
                  <QuestionCircleFilled />
                </Popover>
              </>
            )}
            columns={columns}
            pagination={false}
            loading={reductionPlanStore.loadingTargets}
            dataSource={reductionPlanStore.targets.filter(
              (target) => target.type === "scienceBased"
            )}
          />
        </Col>
      </Row>
      <Row className={styles.tableButton}>
        <StyledButton onClick={showAddScienceTargetModal}>
          Add target
        </StyledButton>
      </Row>
      <Row className={styles.tableWrap}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <CommonTable
            rowKey={(record: Target) => record._id.$oid}
            scroll={{ x: true }}
            title={() => (
              <>
                <span className={styles.tableTitle}>Custom targets</span>{" "}
                <Popover
                  content={
                    <div className={styles.popoverInfo}>
                      User-defined GHG emission reduction targets, customizable for the company’s needs.
                    </div>
                  }
                  overlayClassName="popoverContent"
                >
                  <QuestionCircleFilled />
                </Popover>
              </>
            )}
            columns={columns}
            pagination={false}
            loading={reductionPlanStore.loadingTargets}
            dataSource={reductionPlanStore.targets.filter(
              (target) => target.type === "custom"
            )}
          />
        </Col>
      </Row>
      <Row className={styles.tableButton}>
        <StyledButton onClick={showAddCustomTargetModal}>
          Add target
        </StyledButton>
      </Row>
      <AddTargetModal
        isShown={isScienceTargetModalVisible}
        modalType={AddTargetModalType.SINGLE}
        targetType={TargetType.SCIENCE_BASED}
        onClose={hideAddScienceTargetModal}
        onConfirm={(data) => handleAddTarget(data)}
      />
      <AddTargetModal
        isShown={isCustomTargetModalVisible}
        modalType={AddTargetModalType.SINGLE}
        targetType={TargetType.CUSTOM}
        onClose={hideAddCustomTargetModal}
        onConfirm={(data) => handleAddTarget(data)}
      />
    </>
  );
});
