import React, { useState, useEffect } from "react";
import {
  Spin,
  Row,
  Col,
  Form,
  Typography,
  message,
  Alert,
  Space,
  Popover,
  Button,
  Badge,
  Dropdown,
} from "antd";
import {
  StyledButton,
  CommonTable,
  PageWrap,
  TableWrap,
  FormWrapper,
} from "../../../../shared/commonStyles";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  FileSearchOutlined,
  SyncOutlined,
  FilterOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import CreateMetaDataRecordModal from "./Components/CreateMetaDataRecordModal";
import { Auth } from "aws-amplify";
import { generateEditableCell } from "./MetaDataDocumentsFunctions";
import { getUserGroups } from "../../../../services/users";
import { EditableCell } from "./Components/EditableCell";
import {
  listMetaDataRecords,
  makeQueryMetadata,
  updateMetaDataRecord,
} from "../../../../services/dataSheet";
import { getUsersList } from "../../../../services/users";
import {
  addPermissions,
  updatePermission,
} from "../../../../services/permissions";
import { AssignUsersModal } from "./AssignUsers/AssignUsersModal";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import MetaDataSheetOptions from "./Components/MetaDataSheetOptions";
import { FiltersModal } from "./Components/FiltersModal";
import styles from "../DataSheetDocuments/DataSheetDocuments.module.scss";
import { SortDropdown } from "../../../../components/SortDropdown";
import { useBoolean } from "ahooks";
import { YearMonthDayFormat } from "../../../../shared/constants";
import moment from "moment";
const { Title } = Typography;

interface MetaDataDocumentsProps {
  dataSheet: DataSheet;
  loadingDataSheet: boolean;
  isDeleted: boolean;
}

interface UserGroupInfo extends UserGroup {
  assigned?: boolean;
  isGroup?: boolean;
}

export const MetaDataDocuments = ({
  dataSheet,
  loadingDataSheet,
  isDeleted,
}: MetaDataDocumentsProps) => {
  const [showAddNewRecordModal, setShowAddNewRecordModal] = useState(false);
  const [loadingMetaData, setLoadingMetaData] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [search, setSearch] = useState<Search>({} as Search);

  const [metaDataRecords, setMetaDataRecords] = useState([]);
  const [refreshMetaData, setRefreshMetaData] = useState(1);
  const [editingKey, setEditingKey] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<MetaDataRecord[]>([]);
  const [form] = Form.useForm();
  const [recordsCount, setRecordsCount] = useState({ deleted: 0, filtered: 0 });
  const [usersList, setUsersList] = useState<User[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroupInfo[]>([]);
  const [sorter, setSorter] = useState({
    sortAlphabetically: true,
    type: "key",
  });
  const [filterData, setFilterData] = useState({});
  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const getRecordTypes = () => {
    const includeTypes: RecordTypes[] = [];
    const excludeTypes: RecordTypes[] = [];
    if (isDeleted) {
      includeTypes.splice(0, 3, RecordTypes.ARCHIVED);
    } else {
      excludeTypes.push(RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING);
    }
    return {
      include: includeTypes,
      exclude: excludeTypes,
    };
  };
  const { checkRolesPermission } = useCheckRolesPermissions();

  const rowSelection = {
    onChange: (_selectedRowKeys: any, selectedItems: Array<MetaDataRecord>) => {
      setSelectedRows(selectedItems);
    },
  };

  const getUsers = async () => {
    const users = await getUsersList();
    setUsersList(users);
  };

  const loadMetaDataRecords = async (params: any) => {
    try {
      setLoadingMetaData(true);
      const payload = makeQueryMetadata({
        sheetId: dataSheet?._id?.$oid,
        recordTypes: getRecordTypes(),
        ...params,
      });
      const metaData = await listMetaDataRecords(payload);
      const records = metaData?.data;
      const { filters_list, filter, operator_type } = payload;
      setFilterData({ filters_list, filters_object: filter, operator_type });
      if (records) {
        setMetaDataRecords(records);
      }
      const { pagination: _pagination } = params;
      setPagination({
        ..._pagination,
        total: metaData?.filteredCount,
        showSizeChanger: metaData?.filteredCount > 10,
      });
      setRecordsCount({
        deleted: metaData?.deletedCount,
        filtered: metaData?.filteredCount,
      });
    } catch (error) {
      console.log("Error while loading metadata record");
    } finally {
      setLoadingMetaData(false);
    }
  };

  const loadUserGroups = async () => {
    const userGroups: UserGroup[] = await getUserGroups();
    const newGroups = userGroups?.map((v) => ({ ...v, isGroup: true }));
    setUserGroups(newGroups);
  };

  useEffect(() => {
    loadMetaDataRecords({ pagination, ...search, sorter });
    getUsers();
    loadUserGroups();
  }, [dataSheet, refreshMetaData, isDeleted]);

  const onClickAddNewRecord = () => {
    setShowAddNewRecordModal(true);
  };

  const onFinishUploadInsertDataFile = (record: MetaDataRecord) => {
    setRefreshMetaData((prev) => prev + 1);
  };

  const onFinishCreateNewData = (record: MetaDataRecord) => {
    setRefreshMetaData((prev) => prev + 1);
  };

  const onClickEditRecord = (record: MetaDataRecord) => {
    try {
      setEditingKey(record?.[dataSheet.sheet_name]?.id);
      const dateFields = dataSheet.sheet_schema?.filter(
        (v) => v.data_type === "date"
      );
      const dateValuesObj: any = {};
      dateFields?.forEach((item) => {
        if (record?.[dataSheet.sheet_name][item.entity_name as any]) {
          if (
            typeof record?.[dataSheet.sheet_name][item.entity_name as any] ===
            "object"
          ) {
            dateValuesObj[item.entity_name] = moment(
              (record?.[dataSheet.sheet_name][item.entity_name as any]).$date
            );
          } else {
            dateValuesObj[item.entity_name] = moment(
              record?.[dataSheet.sheet_name][item.entity_name as any]
            );
          }
        } else undefined;
      });
      form.setFieldsValue({
        ...record?.[dataSheet.sheet_name],
        ...dateValuesObj,
      });
    } catch (error) {
      console.log("Error when request for edit", error);
    }
  };

  const onCancelHandler = () => {
    setEditingKey("");
    form.resetFields();
  };
  const onClickAssignUsers = (records: MetaDataRecord[]) => {
    setSelectedRows(records);
    setShowAssignUsersModal(true);
  };
  const identifierField =
    dataSheet.sheet_schema.find((v) => v.isIdentifier)?.entity_name || "";

  const handleAssignUsers = (selectedData: any) => {
    try {
      const isMultiple = selectedRows?.length > 1;
      const payload = {
        permissionType: "REFERENCE_DATA",
        itemId: dataSheet._id.$oid,
        ...selectedData,
        assignedItems: isMultiple
          ? selectedRows.map((v) => v[v.sheet_name].id)
          : selectedRows[0][selectedRows[0].sheet_name].id,
      };
      if (isMultiple) {
        addPermissions(payload);
      } else {
        updatePermission(payload);
      }
      message.success("Users have been successfully assigned!");
      setSelectedRows([]);
      setShowAssignUsersModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const onClickUpdateRecord = async (record: MetaDataRecord) => {
    try {
      setSubmiting(true);
      const data = await Auth.currentSession();
      const username = data.getAccessToken()["payload"]["username"];
      const group_id = data
        .getAccessToken()
        ["payload"]["cognito:groups"].filter((element: any) =>
          element.includes("org:")
        )[0];
      const values = await form.validateFields();
      const sheetName = dataSheet?.sheet_name;
      values["group_id"] = group_id;
      values["username"] = username;
      values["id"] = record?.[dataSheet.sheet_name]?.id;
      const payload = {
        ...record,
        [sheetName]: { ...record?.[dataSheet.sheet_name], ...values },
      };

      const updatedRecord = await updateMetaDataRecord(payload);
      if (updatedRecord) {
        message.success("Record updated successfully!");
        await loadMetaDataRecords({ pagination, ...search, sorter });
        setEditingKey("");
        form.resetFields();
      }
    } catch (error) {
      message.error("Error while updating record!");
      console.log("Error while updating record!", error);
    } finally {
      setSubmiting(false);
    }
  };

  const onClickDeleteRestoreRecord = async (
    record: MetaDataRecord,
    isDeleted: boolean
  ) => {
    const sheetName = dataSheet["sheet_name"];
    const payload = {
      ...record,
      [sheetName]: { ...record?.[sheetName], archive: isDeleted },
    };

    await updateMetaDataRecord(payload).then(() =>
      loadMetaDataRecords({ pagination, ...search, sorter })
    );
  };

  const generateDynamicColumns = (dataSheetSchema: SheetSchema[]) => {
    if (dataSheetSchema?.length) {
      const columns = dataSheetSchema?.reduce((acc: any[], item) => {
        const column: any[] = [];
        column.push({
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: "100px",
          ellipsis: false,
          editable: true,
          render: (_: any, data: MetaDataRecord) => {
            try {
              let value: any =
                data[data.sheet_name]?.[item?.entity_name as any];
              if (item.data_type === "date") {
                if (typeof value === "object" && value !== null) {
                  value = moment.utc(value.$date).format(YearMonthDayFormat);
                } else {
                  value =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value).format(YearMonthDayFormat);
                }
              }
              return value;
            } catch (error) {
              return "";
            }
          },
        });

        return acc.concat(column);
      }, []);

      columns.push({
        title: "",
        dataIndex: "action",
        fixed: "right",
        width: "150px",
        align: "right",
        render: (_: any, record: MetaDataRecord) => {
          const editable = record?.[record.sheet_name]?.id === editingKey;
          return editable ? (
            <span>
              <StyledButton
                type="default"
                size="small"
                loading={submiting}
                onClick={() => onClickUpdateRecord(record)}
                style={{
                  marginRight: 8,
                }}
              >
                Update
              </StyledButton>
              <StyledButton
                type="default"
                onClick={() => onCancelHandler()}
                size="small"
              >
                Cancel
              </StyledButton>
            </span>
          ) : !isDeleted ? (
            <>
              <Space direction="horizontal">
                {" "}
                {checkRolesPermission(["role:admin"]) &&
                  selectedRows.length <= 1 && (
                    <Popover
                      title={
                        !identifierField && (
                          <span>
                            Since the Identifier field is not selected in the
                            data sheet, you cannot assign users.
                          </span>
                        )
                      }
                      overlayClassName="popoverContent"
                    >
                      <StyledButton
                        type="default"
                        onClick={() => onClickAssignUsers([record])}
                        icon={<FileSearchOutlined />}
                        disabled={record.isLocked || !identifierField}
                      >
                        <span>Assign users</span>
                      </StyledButton>{" "}
                    </Popover>
                  )}
                {!checkRolesPermission(["role:auditor"]) && (
                  <>
                    {" "}
                    <StyledButton
                      size="small"
                      type="default"
                      onClick={() => onClickEditRecord(record)}
                      icon={<EditOutlined />}
                    >
                      <span>Edit</span>
                    </StyledButton>
                    <StyledButton
                      size="small"
                      type="default"
                      onClick={() => onClickDeleteRestoreRecord(record, true)}
                      icon={<DeleteOutlined />}
                    >
                      <span>Delete</span>
                    </StyledButton>
                  </>
                )}
              </Space>
            </>
          ) : (
            <>
              {!checkRolesPermission(["role:auditor"]) && (
                <StyledButton
                  size="small"
                  type="default"
                  onClick={() => onClickDeleteRestoreRecord(record, false)}
                  icon={<RollbackOutlined />}
                >
                  <span>Restore</span>
                </StyledButton>
              )}
            </>
          );
        },
      });

      return columns;
    }
    return [];
  };

  const generateColumns = generateDynamicColumns(dataSheet?.sheet_schema);
  const mergedColumns = generateEditableCell(generateColumns, editingKey);

  const onApplyFilters = async (params: SearchParams) => {
    setSearch(params);
    await loadMetaDataRecords({ ...params, pagination, sorter });
  };
  const onResetFilters = async () => {
    setSearch({});
    await loadMetaDataRecords({
      filters: [],
      operatorType: "$and",
      pagination,
      sorter,
    });
  };
  const handleSort = () => {
    loadMetaDataRecords({
      pagination,
      ...search,
      sorter,
    });
  };
  const handleTableChange = (
    pagination: Pagination,
    _filters: any,
    sorterTable: any
  ) => {
    const newSorter = Object.keys(sorterTable).length
      ? {
          sortAlphabetically: sorterTable?.order !== "descend",
          type: !!sorterTable.order ? sorterTable?.field : "key",
        }
      : sorter;
    setSorter(newSorter);
    setSelectedRows([]);
    loadMetaDataRecords({ pagination, ...search, sorter: newSorter });
  };

  return (
    <>
      {showAddNewRecordModal && (
        <CreateMetaDataRecordModal
          visible={showAddNewRecordModal}
          onClose={() => setShowAddNewRecordModal(false)}
          onFinish={onFinishCreateNewData}
          dataSheet={dataSheet}
        />
      )}
      <Spin spinning={loadingDataSheet}>
        {dataSheet && (
          <PageWrap>
            {dataSheet?.archive ? (
              <Alert
                message="Sorry, you cannot access this datasheet as this is archived!!"
                type="info"
                showIcon
              />
            ) : (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <TableWrap>
                    {!isDeleted && (
                      <MetaDataSheetOptions
                        dataSheet={dataSheet}
                        filterData={filterData}
                        recordsCount={recordsCount}
                        onFinishUploadInsertDataFile={
                          onFinishUploadInsertDataFile
                        }
                      />
                    )}
                  </TableWrap>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form form={form} component={false}>
                    <TableWrap>
                      <FormWrapper>
                        <CommonTable
                          rowSelection={rowSelection}
                          title={() => (
                            <Row justify="space-between">
                              <div style={{ display: "flex" }}>
                                <Title className="color-white" level={4}>
                                  {dataSheet?.sheet_name}
                                </Title>
                                <span
                                  style={{
                                    color: "#C1BFCD",
                                    alignItems: "baseline",
                                    padding: "5px 16px",
                                  }}
                                >
                                  ({recordsCount.filtered})
                                </span>
                              </div>
                              <div>
                                {selectedRows?.length > 1 &&
                                  !checkRolesPermission(["role:auditor"]) && (
                                    <Popover
                                      title={
                                        !identifierField && (
                                          <span>
                                            Since the Identifier field is not
                                            selected in the data sheet, you
                                            cannot assign users.
                                          </span>
                                        )
                                      }
                                      overlayClassName="popoverContent"
                                    >
                                      <Button
                                        className={`margin-left-5 sortButton ${styles.filter}`}
                                        type="text"
                                        icon={<SyncOutlined />}
                                        disabled={!identifierField}
                                        onClick={() =>
                                          onClickAssignUsers(selectedRows)
                                        }
                                      >
                                        Assign users to facilities(
                                        {selectedRows.length})
                                      </Button>
                                    </Popover>
                                  )}

                                <Button
                                  type="text"
                                  className={`margin-left-5 sortButton ${styles.sort}`}
                                >
                                  <Dropdown
                                    overlay={
                                      <SortDropdown
                                        sorting={sorter}
                                        changeSorting={setSorter}
                                        options={dataSheet?.sheet_schema?.map(
                                          (option) => ({
                                            name: option.display_name,
                                            value: option.entity_name,
                                          })
                                        )}
                                        onConfirm={() => {
                                          handleSort();
                                          hideDropdown();
                                        }}
                                      />
                                    }
                                    trigger={["click"]}
                                    onVisibleChange={toggle}
                                    visible={isDropdownShown}
                                    placement="bottomRight"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <SwapOutlined rotate={90} />
                                      <span className={styles.sortSpan}>
                                        Sort
                                      </span>
                                    </a>
                                  </Dropdown>
                                </Button>
                                <Badge
                                  count={search.filters?.length}
                                  size="small"
                                  style={{
                                    backgroundColor: "#A68DFB",
                                  }}
                                >
                                  <Button
                                    className={`margin-left-5 sortButton ${styles.filter}`}
                                    type="text"
                                    icon={<FilterOutlined />}
                                    onClick={() => setShowFiltersModal(true)}
                                  >
                                    <span className="expandText">Filters</span>{" "}
                                  </Button>
                                </Badge>
                              </div>
                            </Row>
                          )}
                          loading={loadingMetaData}
                          rowKey={(record: MetaDataRecord) => {
                            return record?.[dataSheet.sheet_name]?.id;
                          }}
                          components={{
                            body: {
                              cell: EditableCell,
                            },
                          }}
                          dataSource={metaDataRecords}
                          columns={mergedColumns}
                          rowClassName={() =>
                            `dataSheetsTable actionsRightBorderRow ${styles.editIconRow}`
                          }
                          className="tableContiner"
                          scroll={{ x: true }}
                          pagination={pagination}
                          onChange={handleTableChange}
                          footer={() =>
                            !isDeleted && (
                              <StyledButton
                                type="custom"
                                icon={<PlusCircleOutlined />}
                                onClick={() => onClickAddNewRecord()}
                              >
                                Add New Record
                              </StyledButton>
                            )
                          }
                        />
                      </FormWrapper>
                    </TableWrap>
                  </Form>
                </Col>
              </Row>
            )}
          </PageWrap>
        )}
      </Spin>
      <AssignUsersModal
        dataSheet={dataSheet}
        visible={showAssignUsersModal}
        selectedRows={selectedRows}
        onCancel={() => {
          setShowAssignUsersModal(false);
        }}
        onConfirm={(v) => handleAssignUsers(v)}
        usersList={usersList}
        userGroupsData={userGroups}
      />
      <FiltersModal
        initialValues={search}
        visible={showFiltersModal}
        onClose={() => setShowFiltersModal(false)}
        dataSheet={dataSheet}
        onFinish={onApplyFilters}
        onReset={() => {
          onResetFilters();
          setShowFiltersModal(false);
        }}
      />
    </>
  );
};
