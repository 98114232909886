import React, { useState, useEffect } from "react";
import { Select, Input, Form, Row, Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { StyledButton, FormWrapper } from "../../shared/commonStyles";
import styles from "./FilterDropdown.module.scss";

export const FilterDropdown = ({
  filtering,
  options,
  onConfirm,
  onReset,
  valueOptions = null,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(null);
  const onFinishHandler = (data) => {
    onConfirm(data);
  };

  useEffect(() => {
    form.setFieldsValue(filtering);
    setInitialValues(filtering);
  }, [filtering]);

  const onChangeFieldsData = (data) => {
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };
  return (
    <div className={styles.sortDropdown} style={{ width: "500px" }}>
      <h3 className={styles.sortDropdownTitle}>Filter by</h3>
      <div className={styles.sortDropdownSelectorWrap}>
        <FormWrapper>
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={onFinishHandler}
            onFieldsChange={(data) => onChangeFieldsData(data)}
            autoComplete="off"
          >
            <Form.Item label="">
              <Form.List name="filters" key={"filters"}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={[16, 16]}>
                        <Col span={10}>
                          <Form.Item
                            name={[name, "type"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    form
                                      .getFieldsValue(true)
                                      .filters.filter((d) => {
                                        return d.type === value;
                                      }).length !== 1
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Duplicate selections are not allowed."
                                      )
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <Select placeholder="Select field to filter">
                              {options.map((option) => {
                                return (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name={[name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            {valueOptions &&
                            valueOptions[
                              form.getFieldsValue(true).filters[name]?.type
                            ] ? (
                              <Select placeholder="Select Value">
                                {valueOptions[
                                  form.getFieldsValue(true).filters[name]?.type
                                ]?.map((option) => {
                                  return (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input placeholder="Input variable" />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <StyledButton
                            type="default"
                            style={{ padding: "8px", marginTop: "0px" }}
                            //disabled={activityFields.length === 0}
                            onClick={() => remove(name)}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    ))}
                    <StyledButton type="custom" onClick={() => add()}>
                      <PlusCircleOutlined /> Add Field
                    </StyledButton>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <StyledButton
                  type="custom"
                  disabled={!form?.getFieldsValue(true).filters?.length}
                  onClick={onReset}
                >
                  Reset
                </StyledButton>
                <StyledButton
                  type="custom"
                  htmlType="submit"
                  disabled={!form?.getFieldsValue(true).filters?.length}
                >
                  Confirm
                </StyledButton>
              </Row>
            </Form.Item>
          </Form>
        </FormWrapper>
      </div>
    </div>
  );
};
