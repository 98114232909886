import {  
  Typography,
} from "antd";
import React from "react";
import Assignees from "./Assignees";
const { Title } = Typography;
const Permissions = ({ rowData }) => { 
  return (
    <>      
      <Title className="color-white" level={3}>
        Permissions
      </Title>
      <Assignees rowData={rowData} type="editor" />
      <Assignees rowData={rowData} type="auditor" /> 
    </>
  );
};

export default Permissions;