import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { datalake } from "../graphql/queries";

export const getEducationData = async () => {
  const response = await API.graphql(
    graphqlOperation(datalake, {
      request_type: "get_client_education",
    })
  );
  return JSON.parse(response["data"]["datalake"]);
};

export const addContent = async (payload) => {
  const response = await API.graphql(
    graphqlOperation(datalake, {
      request_type: "add_client_education",
      request_payload: JSON.stringify(payload),
    })
  );

  return JSON.parse(response["data"]["datalake"]);
};

export const updateContent = async (payload) => {
  const response = await API.graphql(
    graphqlOperation(datalake, {
      request_type: "update_client_education",
      request_payload: JSON.stringify(payload),
    })
  );

  return JSON.parse(response["data"]["datalake"]);
};

export const deleteContent = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_client_education",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};
