import * as queries from "../graphql/queries";

import { API, Auth, Storage } from "aws-amplify";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  Typography,
  message,
} from "antd";
import {
  BellOutlined,
  CaretDownOutlined,
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StyledButton, StyledHeader1 } from "./../shared/commonStyles";
/*import {
  boot as bootIntercom,
  shutdown as shutdownIntercom,
} from "../intercom";*/
import { useBoolean, useSize } from "ahooks";

import { AuthContext } from "../contexts/AuthContext";
import { NotificationsBox } from "./NotificationsBox";
import { fetchProfileImage } from "../shared/commonFunctions";
import logo from "./hydrus_app_logo.png";
import styles from "./Header.module.scss";
import { useCheckRolesPermissions } from "../hooks/useCheckRolesPermissions";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { Item, SubMenu } = Menu;
const { Sider } = Layout;

const Header = () => {
  const navigate = useNavigate();

  const [, setLogo] = useState(logo);
  const [userName, setUserName] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const location = useLocation();
  const [profileImage, setProfileImage] = useState(null);
  const { avatarUrl } = useContext(AuthContext);
  const [showBanner, setShowBanner] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [listConsultantSettings, SetListConsultantSettings] = useState([]);
  const [showGroup, setShowGroup] = useState(null);
  const [refreshSession, setRefreshSession] = useState(0);

  const { checkPermissions, checkLockModules } = useCheckRolesPermissions();
  const menuRef = useRef(null);
  const size = useSize(menuRef);
  const getProfileImage = async (username) => {
    try {
      const profile = await fetchProfileImage(username);
      setProfileImage(profile);
    } catch (error) {
      console.log("Error while load header logo", error);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem("modules");
      //shutdownIntercom();
      // Reboot Intercom in anonymous visitor mode
      /*bootIntercom({
        app_id: process.env.REACT_APP_APP_ID_ENV || "",
      });*/
      navigate("/");
    } catch (error) {
      console.log("Error while signing out ", error);
      message.error("something went wrong while signing out!");
    }
  };
  const [collapsed, { toggle: toggleCollapsed }] = useBoolean(true);
  useEffect(() => {
    if (userInfo) {
      getProfileImage(userInfo?.sub);
    }
  }, [userInfo]);

  const getFile = async () => {
    const data = await Auth.currentSession();
    const groups = data["accessToken"]["payload"]["cognito:groups"]?.filter(
      (element) => element.includes("org:")
    )[0];
    const aLogo = await Storage.get(
      `logos/${groups?.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
    );
    setLogo(aLogo);
  };

  const getUserInfo = () => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        setUserName(userInfo["attributes"]["name"]);
        setUserInfo(userInfo["attributes"]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserInfo();
    getFile();
  }, []);

  const getSelectedKeys = () => {
    const pathName = location.pathname.split("/")[1];
    if (pathName === "/" || !pathName) {
      return "/management";
    } else {
      return `/${pathName}`;
    }
  };

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          setRefreshSession(refreshSession + 1);
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  };

  const getCurrentGroup = async (refreshSession) => {
    setShowGroup(null);
    const data = await Auth.currentSession();
    const group = data["accessToken"]["payload"]["cognito:groups"]?.filter(
      (element) => element.includes("org:")
    )[0];
    setCurrentGroup(group);
    const access = data["accessToken"]["payload"]["cognito:groups"]?.filter(
      (element) => element.includes("access:")
    )[0];

    if (access && access === "access:consultant") {
      setShowGroup("group");
      setShowBanner(true);
    } else {
      setShowGroup("client_group");
      setShowBanner(false);
    }

    if (refreshSession > 0) {
      await checkLockModules();
      navigate("/management");
    }
  };

  useEffect(() => {
    getCurrentGroup(refreshSession);
  }, [refreshSession]);

  const getListConsultantSettingss = async () => {
    const response = await API.graphql({
      query: queries["listConsultantSettingss"],
    });
    if (response && response !== null) {
      const listConsultantSettingss =
        response["data"]["listConsultantSettingss"];
      if (listConsultantSettingss && listConsultantSettingss !== null) {
        const items = listConsultantSettingss["items"];
        if (items && items !== null) {
          SetListConsultantSettings(items);
        }
      }
    }
  };

  useEffect(() => {
    getListConsultantSettingss();
  }, [showGroup]);

  const ToClientGroup = async (group) => {
    const request_payload = {
      group_to_switch: group.client_group,
    };
    const response = await API.graphql({
      query: queries["manageUserAccess"],
      variables: {
        request_type: "switch-client",
        parameters: JSON.stringify(request_payload),
      },
    });

    if (response["data"]["manageUserAccess"] === "Success") {
      refreshToken();

      message.success(`Account successfully switched to ${group.client_group}`);
      setShowGroup("group");
    } else {
      message.error(`Failed to switch account to ${group.client_group}`);
    }
  };

  const ToOriginalGroup = async (group) => {
    const request_payload = {
      group_to_switch: group.client_group,
    };
    const response = await API.graphql({
      query: queries["manageUserAccess"],
      variables: {
        request_type: "return-home",
        parameters: JSON.stringify(request_payload),
      },
    });
    if (response["data"]["manageUserAccess"] === "Success - Return") {
      refreshToken();

      message.success(`Account successfully switched to ${group.client_group}`);
      setShowGroup("client_group");
    } else {
      message.error(`Failed to switch account to ${group.client_group}`);
    }
  };

  const handleOnClick = (index) => {
    const toGroup = listConsultantSettings[index];
    if (toGroup && showGroup === "group") {
      ToOriginalGroup(toGroup);
    }

    if (toGroup && showGroup === "client_group") {
      ToClientGroup(toGroup);
    }
  };

  const listAccounts =
    listConsultantSettings &&
    listConsultantSettings.length > 0 &&
    listConsultantSettings.map((item, index) => {
      if (item.access_type === "CONSULTANT") {
        return (
          <Item key={index} onClick={(e) => handleOnClick(e.key)}>
            {item.client_group}
          </Item>
        );
      }
    });

  const adaptiveListAccounts =
    listConsultantSettings &&
    listConsultantSettings.length > 0 &&
    listConsultantSettings.map((item, index) => {
      if (item.access_type === "CONSULTANT") {
        return (
          <span key={item.client_group} onClick={(e) => handleOnClick(e.key)}>
            {item.client_group}
          </span>
        );
      }
    });

  const navs = [
    {
      path: "/management",
      permission: "management-page",
      key: "/management",
      label: <Link to={"/management"}>Management</Link>,
    },
    {
      path: "/data",
      permission: "data-page-with-elevateduser",
      key: "/data",
      label: <Link to={"/data"}>Data</Link>,
    },
    {
      path: "/reporting",
      permission: "reporting-page",
      key: "/reporting",
      label: <Link to={"/reporting"}>Reporting</Link>,
    },
    {
      path: "/analytics",
      permission: "analytics-page",
      key: "/analytics",
      label: <Link to={"/analytics"}>Analytics</Link>,
    },
    {
      path: "/carbon",
      permission: "carbon-page-elevated",
      key: "/carbon",
      label: <Link to={"/carbon"}>Carbon</Link>,
    },
    {
      path: "/audit",
      permission: "audit-page",
      key: "/audit",
      label: <Link to={"/audit"}>Audit</Link>,
    },
    {
      path: "/tools",
      permission: "tools-page",
      key: "/tools",
      label: <Link to={"/tools"}>Tools</Link>,
    },
  ];

  const menu = (
    <Menu style={{ width: 170, padding: 12 }}>
      <>
        {listConsultantSettings && listConsultantSettings.length > 0 && (
          <>
            <SubMenu
              key="3"
              title="Switch Groups"
              icon={<UserSwitchOutlined />}
            >
              {showGroup && showGroup === "group" && (
                <>
                  <Item key="0" onClick={(e) => handleOnClick(e.key)}>
                    Return to Home
                  </Item>
                </>
              )}

              {showGroup && showGroup !== "group" && <>{listAccounts}</>}
            </SubMenu>
          </>
        )}
        <Menu.Item key="2" icon={<LogoutOutlined />} onClick={() => signOut()}>
          Sign Out
        </Menu.Item>
      </>
    </Menu>
  );

  const bannerMessage = (currentGroup) => {
    return (
      <p style={{ margin: "0px" }}>
        You are currently in consultant view.{" "}
        <Text strong>
          The group you are currently viewing is: {currentGroup}
        </Text>
        .{" "}
        {`Select "Switch Groups" on the menu to the right of your name to change groups.`}
        <StyledButton
          style={{ padding: "0 8px", borderRadius: "5px", margin: "0 5px" }}
          bordercolor="#000"
          color="#000"
          bgcolor="transparent"
          type="custom"
          onClick={() => handleOnClick(0)}
        >
          Return to Home
        </StyledButton>
      </p>
    );
  };

  return (
    <>
      {showBanner && currentGroup && (
        <Alert
          message={bannerMessage(currentGroup)}
          banner
          type="info"
          style={{
            padding: "5px 32px 5px 12px",
            margin: "0px 5px 0px 73px",
            borderRadius: "3px",
          }}
        />
      )}
      <StyledHeader1 className={styles.header}>
        <div className={styles.menuBurger}>
          <Button
            className={styles.buttonMenu}
            onClick={toggleCollapsed}
            type="primary"
          >
            {collapsed ? <MenuOutlined /> : <CloseOutlined />}
          </Button>
          <Sider
            style={collapsed ? { display: "none" } : { display: "block" }}
            trigger={null}
            collapsible
            collapsedWidth={0}
            collapsed={collapsed}
            className={styles.menuSider}
            onCollapse={toggleCollapsed}
          >
            <div
              className={`${styles.avatarWrap} 
              ${styles.avatarBurger}`}
            >
              <span
                className="margin-right-5"
                style={{
                  marginLeft: "3px",
                }}
              >
                {userName ? userName : null}
              </span>
              <Avatar
                className={styles.avatar}
                size={40}
                src={avatarUrl}
                icon={<UserOutlined style={{ color: "#cccccc" }} />}
              />
            </div>
            <div className={styles.menuContainer}>
              <Menu
                className={styles.menuSider}
                mode="inline"
                style={{ backgroundColor: "#322b46", zIndex: "999" }}
                defaultSelectedKeys={["1"]}
                items={navs.filter((nav) => checkPermissions([nav.permission]))}
              />
              <Menu
                className={styles.menuSider}
                mode="inline"
                style={{ backgroundColor: "#322b46", zIndex: "999" }}
                defaultSelectedKeys={["1"]}
                items={[
                  {
                    key: "/Switch-Groups",
                    label: "Switch Groups",
                    icon: <UserSwitchOutlined />,
                    children: [
                      {
                        label:
                          (showGroup && showGroup == "group" && (
                            <span key="0" onClick={(e) => handleOnClick(e.key)}>
                              Return to Home
                            </span>
                          )) ||
                          (showGroup !== "group" && (
                            <>{adaptiveListAccounts}</>
                          )),
                      },
                    ],
                  },
                  {
                    icon: <LogoutOutlined />,
                    label: (
                      <span key="2" onClick={() => signOut()}>
                        Sign Out
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          </Sider>
        </div>
        <div ref={menuRef} className={styles.menuWrap}>
          <Menu
            style={{ flex: "auto", minWidth: 0 }}
            mode="horizontal"
            theme="dark"
            items={navs.filter((nav) => checkPermissions([nav.permission]))}
            className={styles.headerNav}
            selectedKeys={[getSelectedKeys()]}
          />

          <div className={styles.avatarWrap}>
            <NotificationsBox />
            <Avatar
              className={styles.avatar}
              size={40}
              src={avatarUrl}
              icon={<UserOutlined style={{ color: "#cccccc" }} />}
            />
            {size?.width > 1085 && (
              <span className="margin-right-5" style={{ marginLeft: "3px" }}>
                {userName || null}
              </span>
            )}
            <Dropdown overlay={menu}>
              <CaretDownOutlined
                style={{
                  fontSize: "21px",
                  verticalAlign: "text-bottom",
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          </div>
        </div>
      </StyledHeader1>
    </>
  );
};

export default Header;
