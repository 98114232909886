import React,{useState} from 'react';
import { Form, Input,message,Select,Switch, Row, Col } from 'antd';
import { API, Auth } from 'aws-amplify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
import {customUploadAdapterPlugin} from '../DocumentsFunctions';
import * as queries from '../../../graphql/queries';
import { CommonModal,StyledButton, FormWrapper } from '../../../shared/commonStyles';

const {Option} = Select;

const DocumentAddModal = ({visible,onClose,modalTitle,documentTypes,setDocuments}) => {
    const [loaderSavingDoc,setLoaderSavingDoc] =  useState(false);
    const[form]=Form.useForm();
    
    const onSaveDocument = () =>{
        form.validateFields()
        .then(async (val)=>{
            try{
            setLoaderSavingDoc(true);
            const {type_id,title,description,document_data,status} = val;
            const statusText = status ? "ACTIVE" : "INACTIVE";
            const data = await Auth.currentSession();            
            const group_id = data['accessToken']['payload']['cognito:groups'].filter(element => element.includes("org:"))[0];
            const username = data['accessToken']['payload']['username'];
            const payload = {
                type_id,
                title,
                description,
                document_data,
                username,
                group_id,
                status : statusText,
            };
           
            const response = await API.graphql({ query: queries["datalake"], variables:{
                request_type: "add_client_documents",
                request_payload : JSON.stringify(payload)
              }});
            const createdClientDocument = JSON.parse(response['data']['datalake']);             
            const oid = createdClientDocument?.["$oid"];
            if(oid){
                message.success('Successfully saved document!');
                form.resetFields();
                const idData = {
                    _id : { $oid : oid}
                }
                setDocuments(prevDocuments =>
                    ([{...payload, ...idData},...prevDocuments])
                  );
               onClose();
            }
          }catch(error){
               console.log("error while save document",error);
               message.error("error while saving document!");
          }finally{
            setLoaderSavingDoc(false);
          }
        })
        .catch((e)=>{
            message.error('Something went wrong saving document.')
            console.log('Something went wrong saving document.',e)
        })
    }

    return(
        <CommonModal
            title={modalTitle}
            visible={visible}
            onCancel={onClose}
            width={1000}
            footer={
                <>
                  <StyledButton type="custom" onClick={() => onClose()}>Close</StyledButton>
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Row justify="end">
                 <Col>
                 <StyledButton type="custom" loading={loaderSavingDoc} onClick={() => onSaveDocument()}>Save</StyledButton>
                 </Col>
            </Row>
            <FormWrapper>
                <Form form={form} >
                    
                    <Form.Item 
                        labelCol={{ span: 24 }}
                        name="status"
                        label="Active"
                        valuePropName="checked" 
                        initialValue={true}
                        >
                        <Switch />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{ span: 24 }}
                        name="type_id"
                        label="Document Type"
                        rules={[{ required: true, message: 'This is a required field!' }]}
                        >
                        <Select style={{width:"25%"}}>
                            {documentTypes && documentTypes.map((item)=>{
                                const oid = item?.["_id"]?.["$oid"];
                                if(!oid) return;
                                return(
                                    <Option key={oid} value={oid}>
                                        {item.name}
                                    </Option>
                                )
                            })}  
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        labelCol={{ span: 24 }}
                        name="title" 
                        label="Title"
                        rules={[{ required: true, message: 'Please input document title!' }]}
                    >
                        <Input placeholder={''} />
                    </Form.Item>

                    <Form.Item 
                        labelCol={{ span: 24 }}
                        name="description" 
                        label="Description"
                        rules={[{ required: true, message: 'Please input document description!' }]}
                    >
                        <Input placeholder={''} />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{ span: 24 }}
                        name="document_data"
                        label="Document Data"
                        rules={[{ required: true, message: 'Please input document data!' }]}
                        >
                        <CKEditor
                            editor={ DecoupledDocumentEditor }
                            config={{
                                extraPlugins: [customUploadAdapterPlugin]
                            }}
                            data=""
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.                                
                                if(editor?.ui){
                                    editor.ui.view.editable.element.parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.view.editable.element
                                    );
                                }
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();                               
                                form.setFieldsValue({
                                document_data : data   
                                });
                            } }
                        /> 
                    </Form.Item>
                </Form>
            </FormWrapper>
       </CommonModal>
    );
}

export default DocumentAddModal;