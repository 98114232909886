import React, { useEffect, useState } from "react";
import { FormWrapper, StyledTabs } from "../../../shared/commonStyles";
import { Col, Row, Tabs, Typography } from "antd";
import {
  CheckOutlined,
  ExclamationOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { updateData } from "../../../services/dataSheet";
import { RecordsTable } from "./RecordsTable";
import { loadDataSheetRecords } from "../MassImportFunctions";
import styles from "./ImportData.module.scss";

interface ImportState {
  importId: string;
  action?: string;
}
interface ReviewDataProps {
  dataSheetsList: DataSheet[];
  importData?: DataImport;
  state?: ImportState;
  files?: ImportedFile[];
}

export const ReviewData = ({
  dataSheetsList,
  importData,
  state,
  files,
}: ReviewDataProps) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<DataSheetRecord[]>([]);
  const [activeTab, setActiveTab] = useState("");
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dataSheetsList?.length && setActiveTab(dataSheetsList[0]?._id?.$oid);
  }, [dataSheetsList?.length]);

  const loadSheetRecords = async (pagination: Pagination) => {
    setLoading(true);
    try {
      const sheetRecords = await loadDataSheetRecords(
        activeTab,
        importData?._id.$oid!,
        pagination
      );
      setDataSource(sheetRecords.data);
      setPagination({ ...pagination, total: sheetRecords.filteredCount });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUpdateRecord = async (record: DataSheetRecord) => {
    setLoading(true);
    updateData(record)
      .then(() => loadSheetRecords(pagination))
      .finally(() => setLoading(false));
  };

  const handleTableChange = (pagination: Pagination) => {
    loadSheetRecords(pagination);
  };

  useEffect(() => {
    activeTab && loadSheetRecords(pagination);
  }, [activeTab]);

  const getTabIcon = (status: string) => {
    switch (status) {
      case "Completed":
        return <CheckOutlined />;
      case "Warning":
        return <WarningOutlined style={{ color: "#E9D155" }} />;
      case "Error":
        return <ExclamationOutlined style={{ color: "#E9D155" }} />;
      default:
        return "";
    }
  };

  return (
    <FormWrapper height={"100%"} style={{ paddingTop: "20px" }}>
      <Row justify="space-between" style={{ alignItems: "baseline" }}>
        <Typography.Title style={{ padding: "0px" }} level={3}>
          Review uploaded data
        </Typography.Title>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={5} className="color-grey">
            Check if all the supporting files were attached to correct records.
            You can change all incorrect matches by manually selecting the
            correct ones.
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Typography.Title level={5} className="color-grey">
          <b>Note: You need to check ALL data to finish the upload process</b>
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <StyledTabs className={styles.tabsRow} onChange={setActiveTab}>
            {dataSheetsList?.map((dataSheet) => (
              <Tabs.TabPane
                key={dataSheet._id.$oid}
                tab={
                  <>
                    {/* {getTabIcon(dataSheet.status)}*/} {dataSheet.sheet_name}
                  </>
                }
              >
                <RecordsTable
                  dataSheet={dataSheetsList?.find(
                    (item) => item._id.$oid === activeTab
                  )}
                  loading={!dataSheetsList || loading}
                  dataSource={dataSource}
                  files={files}
                  pagination={pagination}
                  handleTableChange={handleTableChange}
                  updateRecord={handleUpdateRecord}
                  state={state}
                />
              </Tabs.TabPane>
            ))}
          </StyledTabs>
        </Col>
      </Row>
    </FormWrapper>
  );
};
