import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row, Spin } from "antd";
import _ from "lodash";
import { CommonModal, FormWrapper } from "../../../shared/commonStyles";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { InputPlaceholderModal } from "./InputPlaceholderModal";
import { YearMonthDayFormat } from "../../../shared/constants";
import { getSheetById } from "../../../services/dataSheet";
import moment from "moment";

interface DisplayFieldsModalProps {
  visible: boolean;
  dataSheet: any;
  onClose: () => void;
  onConfirm: (
    fields: string[],
    sheetFields: any[],
    isAppliedToAll?: boolean
  ) => void;
  initialSurveyValues: any;
}

export const DisplayFieldsModal = ({
  visible,
  dataSheet,
  onClose,
  onConfirm,
  initialSurveyValues,
}: DisplayFieldsModalProps) => {
  const [sheetFields, setSheetFields] = useState<any[]>([]);
  const [isInputModalVisible, setIsInputModalVisible] = useState(false);
  const [inputData, setInputData] = useState({} as any);
  const [placeholders, setPlaceholders] = useState<any>(dataSheet.placeholders);
  const [loading, setloading] = useState(false);
  const getFieldsList = async () => {
    setloading(true);
    const dataSheetWithConvertableUnits = await getSheetById(
      dataSheet._id.$oid,
      true
    );
    const sheetSchema = dataSheetWithConvertableUnits.sheet_schema.filter(
      (v: SheetSchema) => !v.isExpression && v.input_type !== "expression"
    );
    const fields = dataSheet.hide_fields
      ? sheetSchema
      : sheetSchema.filter((v: SheetSchema) => !v.metadata_entity_name);
    const newFields = fields.map((v: SheetSchema) =>
      dataSheet.display_fields?.find((item: string) => item === v.entity_name)
        ? { ...v, checked: true }
        : { ...v, checked: false }
    );
    setPlaceholders(dataSheet.placeholders);
    setSheetFields(newFields);
    setloading(false);
  };

  useEffect(() => {
    getFieldsList();
  }, [dataSheet]);

  const handleChangeChecked = (checked: boolean, field: any) => {
    const newValue = { ...field, checked };
    setSheetFields((prev) =>
      prev.map((v) => (v.entity_name === field.entity_name ? newValue : v))
    );
  };
  const handleRemovePlaceholder = (v: any) => {
    setPlaceholders((prev: any) => {
      delete prev[v.entity_name];
      return { ...prev };
    });
  };
  const handleAddPlaceholder = (v: any, index: number) => {
    setIsInputModalVisible(true);
    setInputData(v);
  };

  const handleClose = () => {
    onClose();
    setSheetFields([]);
    setPlaceholders([]);
  };

  const getPlaceholdersPreview = (item: any) => {
    let inputNode = null;
    switch (item?.input_type) {
      case "number":
        inputNode = (
          <>
            {placeholders?.[item.entity_name]}{" "}
            {placeholders?.[item.entity_name + "_unit_id"]}
          </>
        );
        break;
      case "date":
        inputNode = (
          <>
            {moment(placeholders?.[item.entity_name].$date).format(
              YearMonthDayFormat
            )}
          </>
        );
        break;
      case "string":
        inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        break;
      case "dropdown":
        inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        break;
      case "radio":
        inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        break;
      case "checkbox":
        if (item?.is_checkbox_group) {
          inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        } else {
          inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        }
        break;
      case "long-answer":
        inputNode = <>{placeholders?.[item.entity_name].toString()}</>;
        break;
      default:
        inputNode = null;
        break;
    }

    return inputNode;
  };

  const handleConfirmPlaceholder = (field: any) => {
    setPlaceholders((prev: any) => Object.assign(prev, field));
  };
  const handleConfirm = (isAppliedToAll?: boolean) => {
    const newFields = sheetFields
      .filter((v) => v.checked)
      .map((v) => v.entity_name);
    onConfirm(newFields, placeholders, isAppliedToAll);
    handleClose();
    setSheetFields([]);
    setPlaceholders([]);
  };

  return (
    <>
      <CommonModal
        title="Display fields"
        visible={visible}
        onCancel={handleClose}
        onOk={handleConfirm}
        okText="Select"
        footer={[
          <Button key={"cancel"} ghost onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="applyToAll"
            type="primary"
            onClick={() => handleConfirm(true)}
          >
            Apply Placeholders to All Data Sheets
          </Button>,
          <Button key="select" type="primary" onClick={() => handleConfirm()}>
            Select
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <div>Select fields you want to display in the survey</div>
          {sheetFields?.map((v, index) => (
            <FormWrapper key={v.entity_name} style={{ marginTop: 12 }}>
              <Row>
                <Checkbox
                  value={v.entity_name}
                  checked={v.checked}
                  onChange={(e) => handleChangeChecked(e.target.checked, v)}
                >
                  {v.display_name}
                </Checkbox>
                {!(
                  initialSurveyValues.defaultMetaDataSheetId &&
                  v.metadata_entity_name
                ) && (
                  <>
                    {Object.keys(placeholders)?.find(
                      (key) => key === v.entity_name
                    ) ? (
                      <>
                        <Col
                          style={{
                            backgroundColor: "#443A59",
                            padding: "5px",
                            borderRadius: "2px",
                          }}
                        >
                          {getPlaceholdersPreview(v)}
                        </Col>

                        <Col>
                          <Button
                            icon={
                              <DeleteOutlined style={{ color: "#A68DFB" }} />
                            }
                            style={{ color: "#A68DFB" }}
                            type="link"
                            onClick={() => handleRemovePlaceholder(v)}
                          >
                            Remove placeholder
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Button
                        icon={<PlusOutlined style={{ color: "#A68DFB" }} />}
                        style={{ color: "#A68DFB" }}
                        type="link"
                        onClick={() => handleAddPlaceholder(v, index)}
                      >
                        Add placeholder
                      </Button>
                    )}
                  </>
                )}
              </Row>
            </FormWrapper>
          ))}{" "}
        </Spin>
      </CommonModal>
      {isInputModalVisible && (
        <InputPlaceholderModal
          visible={isInputModalVisible}
          dataSheet={inputData}
          onClose={() => {
            setIsInputModalVisible(false);
          }}
          onConfirm={handleConfirmPlaceholder}
        />
      )}
    </>
  );
};
