import React, { useEffect, useState } from "react";
import moment from "moment";
import XLSX from "xlsx";
import { API } from "aws-amplify";
import { UploadOutlined, FileOutlined } from "@ant-design/icons";
import * as queries from "../../../../../graphql/queries";
import { YearMonthDayFormat } from "../../../../../shared/constants";

import { Row, Upload, Col, message, Badge } from "antd";

import { StyledButton } from "../../../../../shared/commonStyles";
import { useNavigate } from "react-router";
import { encodeUrlName } from "../../../../../shared/commonFunctions";
import { useCheckRolesPermissions } from "../../../../../hooks/useCheckRolesPermissions";
import { dataSheetFileExport } from "../../../../../services/dataSheet";
import { fetchDataWithRetry } from "../../../../../services/importData";

const MetaDataSheetOptions = ({
  dataSheet,
  filterData,
  onFinishUploadInsertDataFile,
  recordsCount,
}) => {
  const navigate = useNavigate();
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [loaderUploadFile, setLoaderUploadFile] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [columnName, setColumnName] = useState({});

  const onCreateSampleExcel = () => {
    try {
      //create a sample file to just know the header according to schema.
      const emptyObject = {};
      const sheetSchema = dataSheet?.sheet_schema;

      if (sheetSchema?.length) {
        sheetSchema.forEach((item) => {
          let testVal = "";
          if (item.data_type === "number") {
            testVal = 0;
          } else if (item.data_type === "string") {
            testVal = "Enter a text value here";
          } else if (item.data_type === "date") {
            testVal = moment().format(YearMonthDayFormat);
          }
          emptyObject[item.entity_name] = testVal;
        });
      } else {
        message.error("Sheet Schema is missing!");
        return;
      }

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([emptyObject]);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "SampleFile.xlsx");
    } catch (error) {
      message.error("Error while creating Sample File!");
    }
  };
  useEffect(() => {
    const emptyObject = {};
    if (dataSheet?.sheet_schema) {
      dataSheet?.sheet_schema.forEach((item) => {
        emptyObject[item.display_name] = item.entity_name;
      });
    } else {
      message.error("Sheet Schema is missing!");
      return;
    }
    setColumnName(emptyObject);
  }, [dataSheet]);

  const onClickExportExcel = async () => {
    if (!recordsCount.filtered) return;
    try {
      setExportLoader(true);
      const job_id = await dataSheetFileExport(
        "DOWNLOAD_METADATA_FILE",
        dataSheet?._id?.$oid,
        dataSheet?.sheet_name,
        filterData
      );
      const result = await fetchDataWithRetry(job_id);
      const resultSummary = result?.resultSummary || "";
      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        result?.message && message.success(resultSummary?.message);
      } else {
        return Promise.reject();
      }
      if (result === "Export Failed. Please contact support.") {
        message.error(job_id);
      } else {
        window.location.href = resultSummary;
      }
    } catch (error) {
      console.log("Error while generating Excel Report!", error);
    } finally {
      setExportLoader(false);
    }
  };

  return (
    <>
      {dataSheet ? (
        <>
          <Row justify="end">
            <style jsx="true">
              {`
                .upload-list-inline {
                  display: inline-block;
                }
              `}
            </style>
            <Col>
              <StyledButton
                type="custom"
                className="margin-5"
                onClick={() =>
                  navigate(
                    `/data/data-sheets/${dataSheet?._id?.$oid}}/${encodeUrlName(
                      dataSheet.sheet_name.replace("/", " ")
                    )}/documents/deleted`
                  )
                }
                disabled={!recordsCount.deleted}
              >
                View deleted documents
                <Badge
                  count={recordsCount.deleted}
                  className="margin-left-5"
                  style={{
                    backgroundColor: "#A68DFB",
                  }}
                />
              </StyledButton>
              <StyledButton
                type="custom"
                className="margin-5"
                icon={<FileOutlined />}
                onClick={onClickExportExcel}
                disabled={!recordsCount.filtered}
                loading={exportLoader}
              >
                Export Excel
              </StyledButton>
              {!checkRolesPermission(["role:auditor"]) && (
                <StyledButton
                  className="margin-5"
                  type="custom"
                  loading={loaderUploadFile}
                  icon={<UploadOutlined />}
                  onClick={() =>
                    navigate("/data/import-data/import-new-data", {
                      state: {
                        dataSheet,
                        type: "dataSheet",
                      },
                    })
                  }
                >
                  Upload Excel
                </StyledButton>
              )}

              <StyledButton
                type="custom"
                className="margin-5"
                icon={<FileOutlined />}
                onClick={onCreateSampleExcel}
              >
                Sample Excel
              </StyledButton>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default MetaDataSheetOptions;
