import React from "react";
import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useBoolean } from "ahooks";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import styles from "../../ReductionPlanListView.module.scss";
import { CreateNewPlanModal } from "./CreateNewPlanModal";

interface TableButtonsProps {
  data: Plan;
  index: number;
  firstBtnAction: (data: Plan) => void;
}

export const TargetPlansButtons = ({
  data,
  index,
  firstBtnAction,
}: TableButtonsProps) => {
  const [
    editPlanModal,
    { setTrue: showEditPlanModal, setFalse: hideEditPlanModal },
  ] = useBoolean(false);

  const handleEditPlan = (data: Plan) => {
    reductionPlanStore.updateTargetPlan(index, data);
    hideEditPlanModal;
  };

  const handleDeletePlan = () => {
    reductionPlanStore.deleteTargetPlan(index);
  };

  const handleShowGraph = () => {
    firstBtnAction(data);
  };

  return (
    <Space className={styles.btnIconsWrap}>
      <Button className={styles.btnIcon} onClick={handleShowGraph}>
        Show on graph
      </Button>
      <Button onClick={showEditPlanModal} className={styles.btnIcon}>
        <EditOutlined />
      </Button>
      <CreateNewPlanModal
        modalTitle="Edit reduction plan"
        data={data}
        isOpen={editPlanModal}
        onClose={hideEditPlanModal}
        onConfirm={(data) => handleEditPlan(data)}
      />
      <Popconfirm
        title="Are you sure to delete this?"
        onConfirm={handleDeletePlan}
        overlayClassName="popoverContent"
      >
        <Button className={styles.btnIcon}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </Space>
  );
};
