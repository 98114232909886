import { SwapOutlined } from "@ant-design/icons";
import { useBoolean } from "ahooks";
import { Button, Dropdown } from "antd";
import React, { useState } from "react";
import { SortDropdown } from "../../../../components/SortDropdown";
import styles from "../Calculator.module.scss";

interface SortDropdownCalculatorProps {
  onSort: (sortEmission: SortEmission) => void;
}

export const SortDropdownCalculator = ({
  onSort,
}: SortDropdownCalculatorProps) => {
  const [isDropdownShown, { toggle: toggleDropdown, setFalse: hideDropdown }] =
    useBoolean(false);
  const [sortEmissions, setSortEmissions] = useState<SortEmission>({
    type: "key",
    sortAlphabetically: true,
  });
  const scopesSortOptions = [
    { name: "Name", value: "name" },
    { name: "Region", value: "region_name" },
  ];
  return (
    <Button
      type="text"
      className={styles.sortButton}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Dropdown
        overlay={
          <SortDropdown
            sorting={sortEmissions}
            changeSorting={setSortEmissions}
            options={scopesSortOptions}
            onConfirm={() => {
              onSort(sortEmissions);
              hideDropdown();
            }}
          />
        }
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={toggleDropdown}
        visible={isDropdownShown}
      >
        <a onClick={(e) => e.preventDefault()}>
          <SwapOutlined rotate={90} />
          <span className={styles.expandText}>Sort</span>
        </a>
      </Dropdown>
    </Button>
  );
};
