import React, { useEffect, useState } from "react";
import { StyledButton } from "../../../shared/commonStyles";
import { SyncOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  addLastAnalyticsRecord,
  convertFiltersList,
} from "../../../services/dataSheet";
import { updateKpiMetric } from "../../../services/kpi";
import {
  aggregateRecords,
  countMissingFields,
} from "../../../services/dataSheet";

const AutoUpdateKpi = ({ kpiData, handleDataAfterRefresh }) => {
  const [loader, setLoader] = useState(false);
  const [isAggregateDataAdded, setIsAggregateDataAdded] = useState(false);

  const addMakeQuery = (params) => {
    const requestpayload = { sheet_id: kpiData?.sheetData?._id.$oid };
    const result = [];
    if (params) {
      const matchKeyPrefix = kpiData?.sheetData?.sheet_name;
      params.forEach((item) => {
        if (item.column) {
          result.push({ [`${matchKeyPrefix}.${item.column}`]: [item.op] });
        }
      });
    }
    return {
      request_details: result,
      ...requestpayload,
    };
  };
  const addAggregateData = async (params) => {
    try {
      setLoader(true);
      const requestPayload = addMakeQuery(params);
      await addLastAnalyticsRecord(requestPayload);
      setIsAggregateDataAdded(true);
    } catch (error) {
      console.log("error", error);
      //message.error("Something went wrong while loading data sheet records!");
    } finally {
      setLoader(false);
    }
  };

  const loadAnalyticData = async () => {
    try {
      setLoader(true);
      const payload = { ...kpiData };
      const aggregationField = {
        [`${kpiData?.aggregateColumn}`]: [kpiData.aggregateOp],
      };

      const conditionalFilters = kpiData?.conditional_filters?.length
        ? kpiData?.conditional_filters
        : kpiData.filters?.length
        ? convertFiltersList(kpiData.filters, kpiData.sheetData)
        : [];

      const aggregationRecordsResponse = await aggregateRecords(
        kpiData.sheetData._id.$oid,
        kpiData.sheetData.sheet_name,
        conditionalFilters,
        aggregationField
      );
      const fields = [`${kpiData.aggregateColumn}`];
      const missingFields = await countMissingFields(
        kpiData.sheetData._id.$oid,
        kpiData.sheetData.sheet_name,
        conditionalFilters,
        fields
      );
      const aggregationResult =
        aggregationRecordsResponse[0][`${kpiData?.aggregateColumn}`][0]
          ?.value || undefined;
      const analyticData = {
        [`${kpiData.aggregateColumn}_${kpiData.aggregateOp}`]:
          aggregationResult,
      };
      if (missingFields[`${kpiData.aggregateColumn}`] > 0) {
        payload.hasMissingFields = true;
      } else {
        payload.hasMissingFields && delete payload.hasMissingFields;
      }
      payload.analyticResult = analyticData;
      delete payload.aggregateColumnLabel;
      delete payload.aggregateOpLabel;
      delete payload.aggregateResult;
      delete payload.sheetData;
      const response = await updateKpiMetric(payload);
      if (response) {
        const dataIndex = `${kpiData?.aggregateColumn}_${kpiData?.aggregateOp}`;
        const aggregateResult = payload?.analyticResult
          ? payload?.analyticResult[dataIndex]
          : "";
        const newKpiData = { ...kpiData, aggregateResult };
        if (missingFields[`${kpiData.aggregateColumn}`] > 0) {
          newKpiData.hasMissingFields = true;
        } else {
          newKpiData.hasMissingFields && delete newKpiData.hasMissingFields;
        }
        handleDataAfterRefresh(newKpiData);
        setIsAggregateDataAdded(false);
      }
    } catch (error) {
      console.log("error", error);
      //message.error("Something went wrong while loading data sheet records!");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (isAggregateDataAdded) {
      loadAnalyticData();
    }
  }, [isAggregateDataAdded]);

  const autoUpdateKpiData = () => {
    addAggregateData(kpiData?.aggregate);
  };

  useEffect(() => {
    if (kpiData?.autoRefresh) {
      addAggregateData(kpiData?.aggregate);
    }
  }, [kpiData?.autoRefresh]);

  return (
    <>
      {!kpiData?.autoRefresh && (
        <StyledButton
          onClick={() => autoUpdateKpiData()}
          style={{ marginLeft: "0", flexGrow: "8" /*  width:'100%' */ }}
          type="default"
          disabled={kpiData.isOverride}
        >
          <SyncOutlined spin={loader} />
          Refresh
        </StyledButton>
      )}
    </>
  );
};

export default AutoUpdateKpi;
