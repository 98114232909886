import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Space, Spin, DatePicker, message, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { API, Auth } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import { getUserGroups, fetchUsers } from "../../../../services/users";
import {
  YearMonthDay24HourFormat,
  YearMonthDayFormat,
} from "../../../../shared/constants";
import {
  getSelectedStandardById,
  updateSelectedStandards,
} from "../../../../services/standards";
import moment from "moment";
const { RangePicker } = DatePicker;

const AssignPermissionsModal = ({ visible, onClose, rowData, refresh }) => {
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [loadingGroupsList, setLoadingGroupsList] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    group_name_id: "",
    user_ids: "",
    date_range: "",
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (rowData.permissions) {
      const permissions = rowData.permissions;
      const startDate = permissions?.startdate
        ? moment(permissions.startdate, YearMonthDayFormat)
        : null;
      const endDate = permissions?.enddate
        ? moment(permissions.enddate, YearMonthDayFormat)
        : null;
      const dateRange = [startDate, endDate];

      const initData = {
        group_name_id: permissions?.group_name_id
          ? permissions?.group_name_id
          : "",
        user_ids: permissions?.user_ids ? permissions?.user_ids : "",
        date_range: dateRange ? dateRange : "",
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    } else {
      const initData = {
        group_name_id: [],
        user_ids: [],
        date_range: [],
      };
      setInitialValues(initData);
      form.setFieldsValue(initData);
    }
  }, [rowData, usersList, groupsList]);  

  const getDataRelatedAttribute = (Attributes, key) => {
    try {
      return Attributes
        ? Attributes?.find((item) => item?.["Name"] === key && item?.["Value"])
        : null;
    } catch (error) {
      console.log("error while get attribute data in user management", error);
      return null;
    }
  };
  useEffect(() => {
    const dataLoad = async () => {
      setLoadingUsersList(true);
      try {
        const { users: usersList } = await fetchUsers({ parameters: "{}", users: [] })
        if (usersList) {
          const updatedList = await Promise.all(
            await usersList.map(async (user) => {
              const nameDataObject = getDataRelatedAttribute(
                user["Attributes"],
                "name"
              );
              let name = nameDataObject?.["Value"]
                ? nameDataObject?.["Value"]
                : "";
              if (!name && user?.["Username"]) {
                name = await getFirstAndLastNameByUsername(user["Username"]);
              }
              if (!name) {
                const emailDataObject = getDataRelatedAttribute(
                  user["Attributes"],
                  "email"
                );
                name = emailDataObject?.["Value"]
                  ? emailDataObject?.["Value"]
                  : "";
              }
              return {
                ...user,
                name,
                key: user["Username"],
                label: name,
                value: user["Username"],
              };
            })
          );
          setUsersList(updatedList);
        }
      } catch (error) {
        console.log("error while fetching users list", error);
        message.error("Error while fetching users list!");
      } finally {
        setLoadingUsersList(false);
      }
    };
    dataLoad();
  }, []);

  useEffect(() => {
    const loadGroups = async () => {
      setLoadingGroupsList(true);
      try {
        const listGroupNames = await getUserGroups();
        if (listGroupNames) {
          const addkeyUpdatedGroupsList = listGroupNames.map((item) => {
            return {
              ...item,
              key: item._id["$oid"],
              label: item.name,
              value: item._id["$oid"],
            };
          });
          setGroupsList(addkeyUpdatedGroupsList);
        }
      } catch (error) {
        console.log("Error while fetching getUserGroups.", error);
        message.error("Error while fetching getUserGroups.");
      } finally {
        setLoadingGroupsList(false);
      }
    };
    loadGroups();
  }, []);

  const onClickAssignBtn = async () => {
    form.validateFields().then(async (val) => {
      if (!rowData) return;
      try {
        setSubmitting(true);

        const { group_name_id, date_range, user_ids } = val;
        
        if ((!group_name_id || group_name_id.length === 0) && (!user_ids || user_ids.length === 0)) {
          message.error("Please select at least one group or user!");
          setSubmitting(false);
          return;
        }
        /*
        if (!date_range || date_range.length !== 2) {
          message.error("Please select a date range!");
          setSubmitting(false);
          return;
        }*/
        
        const startdate = Array.isArray(date_range) && date_range.length > 0
            ? date_range[0].format(YearMonthDayFormat)
            : null;
        const enddate = Array.isArray(date_range) && date_range.length > 1
            ? date_range[1].format(YearMonthDayFormat)
            : null;

        const permissions = {
          group_name_id: group_name_id ? group_name_id : null,
          user_ids: user_ids,
          startdate: startdate,
          enddate: enddate,
        };

        const formJson = JSON.stringify(rowData.form_json_code);
        const formUi = JSON.stringify(rowData.form_ui_code);
        const response = rowData.map(item => {
          const dataToSubmit = {
            ...item,
            form_json_code: formJson,
            form_ui_code: formUi,
            permissions: permissions,
          };
          return updateSelectedStandards(dataToSubmit);
        })
        Promise.all(response).then(() => {
          message.success("Assigned permissions successfully!");
          onClose();
          refresh();
        }).catch(() => {
          message.error("Failed to assign permissions!");
        })
      } catch (error) {
        console.log("Something went wrong while assigning permissions", error);
        message.error("Something went wrong while assigning permissions");
      } finally {
        setSubmitting(false);
      }
    });
  };

  return (
    <CommonModal
      title="Assign Permissions"
      visible={visible}
      onCancel={onClose}
      width={700}
      footer={null}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadingGroupsList || loadingUsersList}>
          <FormWrapper>
            <Form form={form} initialValues={initialValues}>
              <Row justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24}>
                  {/* <Form.Item
                    labelCol={{ span: 24 }}
                    name="status"
                    label="Active"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item> */}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Assign a group to the standard"
                    name="group_name_id"
                    rules={[
                      { required: false, message: "Please select a group!" },
                    ]}
                  >
                    <Select
                      placeholder="Please select a group!"
                      mode="multiple"
                      //   tagRender={tagRender}
                      showArrow
                      style={{ width: "100%" }}
                      loading={loadingGroupsList}
                      options={groupsList}
                    />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Assign a user to the standard"
                    name="user_ids"
                    rules={[
                      { required: false, message: "Please select a user!" },
                    ]}
                  >
                    <Select
                      placeholder="Please select a user!"
                      mode="multiple"
                      // tagRender={tagRender}
                      showArrow
                      style={{ width: "100%" }}
                      loading={loadingUsersList}
                      options={usersList}
                    />
                  </Form.Item>
                  <Form.Item
                    name="date_range"
                    label={
                      <span>
                        Specify an optional access timeline&nbsp;&nbsp;
                        <Tooltip title="If no access timeline is specified, the assigned users and groups will have indefinite access to this data sheet.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    labelCol={{ span: 24 }}
                    className="clearIcon"
                    rules={[
                      { required: false, message: "Please select a timeline" },
                    ]}
                  >
                    <RangePicker
                      placeholder={""}
                      style={{ background: "#625D75" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="align-items-right-wrapper">
                <StyledButton
                  type="custom"
                  onClick={onClickAssignBtn}
                  loading={submitting}
                >
                  Save
                </StyledButton>
              </div>
            </Form>
          </FormWrapper>
        </Spin>
      </Space>
    </CommonModal>
  );
};

export default AssignPermissionsModal;
