import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Steps,
  Divider,
  Button,
  Typography,
  message,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  fetchRecordData,
  makeQuery,
  massUpdateRecords,
} from "../../../../services/dataSheet";
import { ReplacementParameters } from "./ReplacementParameters";
import { DataSheetsRecordsTable } from "./DataSheetsRecordsTable";
import {
  FormWrap,
  FormWrapper,
  PageWrap,
  StyledButton,
  StyledProgress,
  StyledSteps,
} from "../../../../shared/commonStyles";
import styles from "../DataSheetDocuments/DataSheetDocuments.module.scss";
import { encodeUrlName } from "../../../../shared/commonFunctions";
import { ConditionalDataSheetFilters } from "../../../../components/ConditionalDataSheetFilters/ConditionalDataSheetFilters";

const { Step } = Steps;
interface StateValues {
  selectedRows: DataSheetRecord[];
  dataSheet: DataSheet;
}

interface RecordsForm {
  conditional_filters: any[];
  filters?: any[];
  column: string;
  column_value: any;
}

interface FiltersDataSheet {
  [column: string]: {
    [op: string]: any;
  };
}
const UpdateDataSheetsRecords = () => {
  const { state: stateObject } = useLocation();
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const state = stateObject as StateValues;
  const YearMonthDayFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState<DataSheetRecord[]>([]);
  const [recordsCount, setRecordsCount] = useState(0);

  const [formValues, setFormValues] = useState<RecordsForm>({
    conditional_filters: [] as any[],
  } as RecordsForm);

  const recordsTypes = {
    include: [],
    exclude: [RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING],
  };
  const recordsFilter = !!state.selectedRows.length && {
    _id: { $in: state.selectedRows },
  };
  const loadDataSheetRecords = async (conditionalFilters?: any[]) => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = makeQuery(
        { conditionalFilters },
        state.dataSheet,
        recordsTypes,
        recordsFilter
      );
      const sheetRecords = await fetchRecordData(requestPayload);
      const _dataSheetRecords = sheetRecords.data.map(
        (record: DataSheetRecord) => ({
          ...record,
          key: record._id.$oid,
          owner_users: record.owner_users || [record.username],
        })
      );
      setSelectedRows(_dataSheetRecords);
      setRecordsCount(sheetRecords.filteredCount);
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong while loading data sheet records!");
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  useEffect(() => {
    loadDataSheetRecords();
  }, []);

  useEffect(() => {
    const isFilterValues = formValues.conditional_filters.filter(
      (v) => v.type === "condition"
    )?.length;
    isFilterValues
      ? loadDataSheetRecords(formValues.conditional_filters)
      : loadDataSheetRecords();
  }, [formValues.conditional_filters]);

  const getFieldValue = (
    data: DataSheetRecord,
    item: SheetSchema,
    isUnitId?: boolean
  ) => {
    if (item.input_type === "date") {
      let value = data
        ? data?.[data?.sheet_name]?.[item?.entity_name]?.$date
        : "";
      value = value && moment(value).format(YearMonthDayFormat);
      return value;
    } else if (isUnitId) {
      const value =
        data?.[data?.sheet_name]?.[item?.entity_name + "_unit_id"] ||
        item.unit_id;
      return value;
    } else {
      const value =
        typeof data?.[data?.sheet_name]?.[item?.entity_name] === "object"
          ? ""
          : data?.[data?.sheet_name]?.[item?.entity_name];
      return value;
    }
  };

  const onChangeFieldsData = () => {
    const formValues = form.getFieldsValue(true);
    setFormValues(formValues);
  };

  const handleUpdate = async () => {
    try {
      const formValues = form.getFieldsValue(true);
      const payload = {
        sheet_id: state.dataSheet._id.$oid,
        ...(!!state?.selectedRows?.length && { filters: recordsFilter }),
        ...(!!formValues?.conditional_filters?.length && {
          conditional_filters: formValues.conditional_filters,
        }),
        column: formValues.column,
        value: formValues.column_value,
        operation: "Edit",
      };
      const result = await massUpdateRecords(payload);
      if (result) {
        setFormValues({ conditional_filters: [] as any[] } as RecordsForm);
        message.success("All records were updated successfully");
        loadDataSheetRecords(formValues.conditional_filters);
        form.resetFields();
        form.setFieldsValue({
          conditional_filters: formValues.conditional_filters,
        } as RecordsForm);
        setFormValues({
          conditional_filters: formValues.conditional_filters,
        } as RecordsForm);
      }
    } catch (error) {
      message.error("Something went wrong while updating records");
    }
  };

  const handleChangeFilters = (conditional_filters: any[]) => {
    setFormValues((prev) => ({ ...prev, conditional_filters }));
    form.setFieldsValue({ conditional_filters });
  };

  const handleChangeColumn = () => {
    const formValues = form.getFieldsValue(true);
    const isOwnersColumn =
      formValues.column === "owner_users" ||
      formValues.column === "owner_groups";
    form.setFieldsValue({
      ...formValues,
      column_value: isOwnersColumn ? [] : "",
    });
    setFormValues({
      ...formValues,
      column_value: isOwnersColumn ? [] : "",
    });
  };

  return (
    <>
      <Row className={styles.updateHeader}>
        <Col lg={1} md={4} sm={7} xs={10}>
          <StyledButton
            style={{ zIndex: "1" }}
            type="custom"
            onClick={() =>
              navigate(
                `/data/data-sheets/${state.dataSheet._id.$oid}/${encodeUrlName(
                  state.dataSheet.sheet_name
                )}/documents`
              )
            }
            hovercolor="#A68DFB"
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="#fff"
          >
            <ArrowLeftOutlined /> <span>Exit</span>
          </StyledButton>
        </Col>
        <Col
          className="user-flow-steps-wrap color-white"
          lg={22}
          md={19}
          sm={16}
          xs={13}
        >
          <StyledSteps current={1}>
            <Step
              className="stepper-wrapper "
              key={1}
              title={<span>Update records</span>}
            />
          </StyledSteps>
        </Col>
      </Row>
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={50}
        showInfo={false}
        className={styles.updateProgress}
      />
      <PageWrap>
        <FormWrap width={"100%"}>
          <FormWrapper height={"100%"}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className={styles.updateFormWrap}>
                  <Form
                    form={form}
                    initialValues={formValues}
                    onFieldsChange={onChangeFieldsData}
                  >
                    <Row
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Title level={3}>
                        Update multiple records
                      </Typography.Title>
                      <p className="color-grey">
                        Bulk update multiple records with just one click
                      </p>
                      {!state.selectedRows.length && (
                        <>
                          <Typography.Title level={5}>
                            Add data filters
                            <b className="color-grey"> (optional)</b>
                          </Typography.Title>
                          <Divider
                            style={{
                              backgroundColor: "#3A3C5A",
                              margin: "7px 0px 7px 0px",
                            }}
                          />
                          <Form.Item
                            label=""
                            className={styles.addFilterButton}
                          >
                            {/* <DataSheetFilters
                              initialFilters={formValues.filters}
                              formFilters={formValues.filters}
                              onChangeFilters={handleChangeFilters}
                              resetInput={resetInput}
                              dataSheet={state.dataSheet}
                              addButtonText="Add one more filter"
                            /> */}
                            <ConditionalDataSheetFilters
                              initialFilters={formValues.conditional_filters}
                              dataSheet={state.dataSheet}
                              onChangeFilters={handleChangeFilters}
                              addButtonText="Add datasheet filters"
                            />
                          </Form.Item>
                        </>
                      )}
                      <Typography.Title style={{ marginTop: "20px" }} level={5}>
                        Set up replacement parameters
                      </Typography.Title>
                      <Divider
                        style={{
                          backgroundColor: "#3A3C5A",
                          margin: "7px 0px 7px 0px",
                        }}
                      />
                      <ReplacementParameters
                        dataSheet={state.dataSheet}
                        column={formValues.column}
                        onChangeColumn={handleChangeColumn}
                      />
                    </Row>
                  </Form>
                  <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                      <Button onClick={() => navigate(-1)} ghost>
                        <span>Cancel</span>
                      </Button>
                      <StyledButton onClick={handleUpdate} type="primary">
                        <span>Update records</span>
                      </StyledButton>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: 20, paddingLeft: "30px" }}
              >
                <DataSheetsRecordsTable
                  dataSheet={state?.dataSheet}
                  dataSheetRecords={selectedRows}
                  getFieldValue={getFieldValue}
                  loading={loadingSheetRecords}
                  recordsCount={recordsCount}
                />
              </Col>
            </Row>
          </FormWrapper>
        </FormWrap>
      </PageWrap>
    </>
  );
};
export default UpdateDataSheetsRecords;
