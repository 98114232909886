import {
  RollbackOutlined,
} from "@ant-design/icons";
import { Space, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import {
  CommonTable,
  StyledButton,
} from "../../../../shared/commonStyles";
import Assignees from "../../components/generalGoalsDetails/Assignees";
import RelatedItems from "../../components/generalGoalsDetails/RelatedItems";
import { subGoals } from "../../../../services/goals";

const DeleteSubGoals = ({ goalData,onRestore }) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState(null);

  const loadGoals = async () => {
    try {
      setLoader(true);
      const goals = await subGoals(goalData?.id);
      setDataSource(goals);
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (goalData) {
      loadGoals();
    }
  }, [goalData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
    },
    {
      title: "Deadline",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Assignees",
      dataIndex: "assignees",
      width: "10%",
      render: (_, record) => {
        return <Assignees record={record} />;
      },
    },
    {
      title: "Related Items",
      dataIndex: "relatedItems",
      width: "20%",
      render: (_, record) => {
        return <RelatedItems record={record} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (_, record) => {
        return <Space direction="horizontal">
         {record.status ==="DELETED" ?
           <StyledButton
              type="default"
              style={{
                width: "100%",
                margin: "0",
                padding: "11px 18px",
                textAlign: "left",
              }}
              icon={<RollbackOutlined />}
              onClick={() => onRestore(record)}
            >
              Restore
            </StyledButton> : ''}
        </Space>;
      },
    },
  ];

  return (
    <Spin spinning={loader}>
      <div className="subgoals">
        {dataSource && (
          <CommonTable
            showHeader={false}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: true }}
            pagination={false}
            rowKey="id"
            rowClassName={(record) => {          
              return record?.status === 'DELETED' ? 'rowDenied' : ''
            }}
          />
        )}
      </div>
    </Spin>
  );
};
export default DeleteSubGoals;
