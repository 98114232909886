import React, { useEffect } from "react";
import { Spin } from "antd";
import SurveyView from "./Components/SurveyView";
import { PageWrap } from "../../shared/commonStyles";
import { ReportingPageProvider } from "../reportingPage/context/ReportingPageContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSize } from "ahooks";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { observer } from "mobx-react-lite";
import { useGetMetadataUserSettings } from "../../hooks/useGetMetadataUserSettings";
import { surveyStore } from "../../stores/SurveyStore";
import { KPIsForm } from "./Components/KPIsForm";

interface SurveyState {
  taskDeploymentId?: string;
  metadataRecordId?: string;
}

const ViewSurveyForAdmin = observer(() => {
  const { state } = useLocation();
  const { survey_id: surveyId } = useParams();
  const settingValue = useGetMetadataUserSettings();
  const navigate = useNavigate();
  const size = useSize(document.querySelector("body"));
  const { checkRolesPermission } = useCheckRolesPermissions();
  const isUser =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:elevateduser"]);

  const handleReturn = () => {
    isUser ? navigate(`/management`) : navigate(`/management/collect-data`);
  };
  useEffect(() => {
    surveyStore.resetState();
  }, []);
  useEffect(() => {
    if (state && (state as SurveyState)?.taskDeploymentId) {
      surveyStore.changeTaskDeploymentId(
        (state as SurveyState)?.taskDeploymentId!
      );
    }
  }, [state]);

  useEffect(() => {
    if (isUser && !(state as SurveyState)?.taskDeploymentId) handleReturn();
    if (surveyId && settingValue?.length) {
      surveyStore
        .getSurveyById(
          surveyId,
          settingValue,
          (state as SurveyState)?.taskDeploymentId,
          (state as SurveyState)?.metadataRecordId
        )
        .catch(() => handleReturn());
    }
  }, [surveyId, settingValue]);

  return (
    <ReportingPageProvider>
      <PageWrap style={{ padding: size && size?.width <= 600 && 0 }}>
        <Spin spinning={surveyStore.surveyLoading}>
          {surveyStore.surveyInfo &&
            surveyStore?.surveySteps?.length &&
            (surveyStore.surveyInfo.survey_type === "kpi" ? (
              <KPIsForm />
            ) : (
              <SurveyView />
            ))}
        </Spin>
      </PageWrap>
    </ReportingPageProvider>
  );
});

export default ViewSurveyForAdmin;
