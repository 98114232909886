import React, { useState } from "react";
import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useBoolean } from "ahooks";
import { observer } from "mobx-react-lite";
import { EditTargetModal } from "../../WhatIfOnboarding/Components/EditTargetModal";
import { reductionPlanStore } from "../../WhatIfOnboarding/ReductionPlanStore";
import styles from "../ReductionPlanListView.module.scss";

interface TableButtonsProps {
  data: Target;
  onDelete: () => void;
}

export const TableButtons = observer(
  ({ data, onDelete }: TableButtonsProps) => {
    const [
      editTargetModal,
      { setTrue: showEditTargetModal, setFalse: hideEditTargetModal },
    ] = useBoolean(false);

    const handleEditTarget = (data: Target) => {
      reductionPlanStore
        .updateTarget(data)
        .then(() => reductionPlanStore.getAllTargets());

      hideEditTargetModal();
    };

    return (
      <Space className={styles.btnIconsWrap}>
        <Button className={styles.btnIcon}>
          <Link
            to={`/carbon/reduction-plan/${data.emissionName}`}
            state={data._id.$oid}
          >
            <EyeOutlined />
          </Link>
        </Button>
        <Button onClick={showEditTargetModal} className={styles.btnIcon}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={onDelete}
          overlayClassName="popoverContent"
        >
          <Button className={styles.btnIcon}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
        <EditTargetModal
          target={data}
          isShown={editTargetModal}
          isCustom={data.type === "custom"}
          onClose={hideEditTargetModal}
          onConfirm={(data) => handleEditTarget(data as Target)}
        />
      </Space>
    );
  }
);
