import { Col, Divider, Row, Space, Steps, Tooltip, Typography } from "antd";
import {
  CommonModal,
  FormWrap,
  FormWrapper,
  IconWrap,
  StyledButton,
  StyledSteps,
} from "../../../shared/commonStyles";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";

import { Theme as AntDTheme } from "@rjsf/antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { handleLongString } from "../../../shared/commonFunctions";
import { withTheme } from "@rjsf/core";

const { Title } = Typography;
const Form = withTheme(AntDTheme);
const { Step } = Steps;

const StandardFormViewModal = ({
  visible,
  rowData,
  onClose,
  isShowSteps = false,
  sortedDataLakeList = [],
  setRowDataView = null,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const editorResponse = rowData?.response ?? "";
  const [formData, setFormData] = useState(editorResponse);
  const schema = useMemo(() => rowData?.form_json_code, [rowData]);
  const uiSchema = useMemo(() => rowData?.form_ui_code, [rowData]);
  if (!rowData) return <></>;

  const onFormDataChange = (value) => {
    setFormData(value);
  };

  const onStepChange = (currentStep, state) => {
    const pretarget = document.getElementById(`step${currentStep - 1}`);
    const nexttarget = document.getElementById(`step${currentStep + 1}`);
    switch (state) {
      case "previous":
        if (currentStep === 0) {
          return;
        }
        pretarget && pretarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep - 1);
        setRowDataView(sortedDataLakeList[currentStep - 1]);
        break;
      case "next":
        if (currentStep === sortedDataLakeList?.length - 1) {
          return;
        }
        nexttarget && nexttarget.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(currentStep + 1);
        setRowDataView(sortedDataLakeList[currentStep + 1]);
        break;
      default:
        break;
    }
  };

  const checkVariable = (variable) => {
    if (typeof variable === "string") {
      return variable;
    } else {
      return "";
    }
  };

  return (
    <FormWrap width="500px">
      <CommonModal
        title={rowData?.theme}
        visible={visible}
        onCancel={onClose}
        width={800}
        maskClosable={false}
        destroyOnClose
        footer={null}
      >
        {isShowSteps && (
          <Row style={{ padding: 10, backgroundColor: "#362E4B" }}>
            <Col span={16}>
              <div
                style={{ overflowX: "scroll", padding: 10 }}
                className="custom-scroll"
                id="scrollable-steps"
              >
                <StyledSteps
                  onChange={(value) => {
                    setRowDataView(sortedDataLakeList[value]);
                    setCurrentStep(value);
                  }}
                  current={currentStep}
                >
                  {sortedDataLakeList.map((data, index) => {
                    return (
                      <>
                        <Step
                          id={"step" + index}
                          className="stepper-wrapper"
                          key={index}
                          title={<span>Step {index + 1}</span>}
                        ></Step>
                      </>
                    );
                  })}
                </StyledSteps>
              </div>
            </Col>
            <Col span={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px 10px",
                }}
              >
                <IconWrap onClick={() => onStepChange(currentStep, "previous")}>
                  <LeftCircleOutlined
                    style={{
                      fontSize: 20,
                      color: "white",
                      marginRight: 10,
                    }}
                  />
                  <span className="color-white">Back</span>
                </IconWrap>

                <IconWrap onClick={() => onStepChange(currentStep, "next")}>
                  <RightCircleOutlined
                    style={{
                      fontSize: 20,
                      color: "white",
                      marginRight: 10,
                    }}
                  />
                  <span className="color-white">Next</span>
                </IconWrap>
              </div>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: "20px" }}>
          <Col
            span={16}
            style={{ padding: 10, backgroundColor: "#3C3453" }}
            className="preview-left-part"
          >
            <Title className="color-white" level={4}>
              Data <span className="color-grey">(View Only)</span>
            </Title>
            <Space direction="vertical" style={{ width: "100%" }}>
              <p className="color-white"> {rowData.modalTitle}</p>
              <Col>
                <CKEditor
                  editor={DecoupledDocumentEditor}
                  data={checkVariable(formData)}
                  onReady={(editor) => {
                    if (editor?.ui) {
                      editor.ui.view.editable.element.parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.view.editable.element
                      );
                    }
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    onFormDataChange(data);
                  }}
                />
              </Col>
              {/* <p className="color-white"> {rowData.description}</p> */}
              {/* {rowData.type === "welcome_page" ? (
                <>
                  <Title className="color-white" level={4}>
                    {rowData?.subject}
                  </Title>
                  <Divider
                    style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                  />
                  <FormWrapper>
                    <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rowData.body,
                        }}
                      ></div>
                    </Row>
                  </FormWrapper>
                </>
              ) : (
                // TODO: Need to clarify what exactly we need to do here
                // <FormWrapper>
                //   {Object.keys(rowData?.response)?.length > 0 ? (
                //     <Form formData={{}} uiSchema={uiSchema} schema={schema}>
                //       {
                //         <Col xs={24} sm={24} md={24} lg={24}>
                //           <StyledButton
                //             type="custom"
                //             htmlType="submit"
                //             style={{ display: "none" }}
                //           >
                //             Submit
                //           </StyledButton>
                //         </Col>
                //       }
                //     </Form>
                //   ) : (
                //     <div className="ckSmallHeight ">
                //       <CKEditor
                //         editor={DecoupledDocumentEditor}
                //         data=""
                //         onReady={(editor) => {
                //           // You can store the "editor" and use when it is needed.
                //           if (editor?.ui) {
                //             editor.ui.view.editable.element.parentElement.insertBefore(
                //               editor.ui.view.toolbar.element,
                //               editor.ui.view.editable.element
                //             );
                //           }
                //         }}
                //       />
                //     </div>
                //   )}
                // </FormWrapper>
                <></>
              )} */}
            </Space>
          </Col>
          <Col span={8} className="preview-right-part">
            <Title className="color-white " level={4}>
              Information
            </Title>
            <Space direction="vertical">
              <p className="color-white"> {rowData.description}</p>
              <div>
                {(rowData?.standardLink || []).map((val, index) => (
                  <Tooltip title={val.name} key={index}>
                    <Row>
                      <Col span={24}>
                        <a
                          className="padding-0"
                          href={val.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#7F5FEE" }}
                        >
                          Link to {`"${handleLongString(val.name, 10)}"`}
                          {val.page_num ? `, Page No: ${val.page_num}` : ""}
                        </a>
                      </Col>
                    </Row>
                  </Tooltip>
                ))}
              </div>
            </Space>
            <div style={{ display: "flex", flex: 1, alignItems: "flex-end" }}>
              <StyledButton
                key="1"
                type="custom"
                onClick={() => onClose()}
                style={{ marginTop: "80px" }}
              >
                Close Preview
              </StyledButton>
            </div>
          </Col>
        </Row>
      </CommonModal>
    </FormWrap>
  );
};

export default StandardFormViewModal;
