import React from 'react';
import {Typography} from "antd";
import { useNavigate } from "react-router-dom";
import { CommonModal, StyledButton } from "../../../../shared/commonStyles";


const { Title } = Typography;
export const AddNewStandardModal = ({ isVisible, onClose, setTemplateLibraryModal }) => {
  const navigate = useNavigate();

  return (
    <CommonModal
      width={500}
      title={"Add New Standard"}        
      visible={isVisible}
      footer={null}
      onCancel={onClose}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: 'center'
      }}>
        <Title level={3} style={{textAlign:'center'}}> {`How would you like to add a standard?`} </Title>
        <StyledButton 
          type='default' 
          style={{ margin: 10,  width:'100%', height:'75px', fontSize:'20px'}}
          onClick={() => {
            onClose()
            navigate(
              "/reporting/custom-standard/create"
            )
          }}
        >
          Create custom standard
        </StyledButton>
        <StyledButton 
          type='default' 
          style={{ margin: 10,  width:'100%', height:'75px', fontSize:'20px'}}
          onClick={() => {
            setTemplateLibraryModal(true);
            onClose()
          }}
        >
          Select from templates library
        </StyledButton>
      </div>
    </CommonModal>
  );
};

