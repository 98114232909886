import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import {
  Spin,
  Row,
  Col,
  Card,
  Form,
  Input,
  Divider,
  Typography,
  message,
  InputNumber,
} from "antd";
import * as mutations from "../../../../graphql/mutations";
import { getCompanyInfo } from "../../../../services/companyInfo";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";

const { Title } = Typography;

interface CompanyDetails {
  decimal_places_number: number;
}

const PlatformSettings = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [decimalPlacesNumber, setDecimalPlacesNumber] = useState(
    companyDetails.decimal_places_number || 0
  );

  useEffect(() => {
    try {
      form.setFieldsValue(companyDetails);
    } catch (error) {
      console.log("Error while set form values!");
      message.error("Something went wrong!");
    }
  }, [form, companyDetails]);

  const loadCompanyInfo = async () => {
    try {
      setLoading(true);
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";
        setCompanyDetails(companyData);
        setDecimalPlacesNumber(companyData.decimal_places_number);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const onFormSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setSubmitting(true);
          const data: any = await Auth.currentSession();
          const groupId: any = data["accessToken"]["payload"][
            "cognito:groups"
          ].filter((element: any) => element.includes("org:"))[0];
          if (Object.keys(companyDetails)?.length) {
            const payload = {
              data: `${JSON.stringify({ ...companyDetails, ...values })}`,
              group: groupId,
            };
            const response: any = await API.graphql({
              query: mutations.updateCompanyInfo,
              variables: { input: payload },
            });

            const updatedCompanyInfo = response.data.updateCompanyInfo;
            if (updatedCompanyInfo) {
              message.success("Data updated Successfully!");
            }
          } else {
            const payload = {
              data: `${JSON.stringify(values)}`,
              group: groupId,
            };
            const response: any = await API.graphql({
              query: mutations.createCompanyInfo,
              variables: { input: payload },
            });
            const createdCompanyInfo = response["data"]["createCompanyInfo"];
            if (createdCompanyInfo) {
              message.success("Data saved Successfully!");
            }
          }
        } catch (error) {
          message.error(`Error while submitting data ${error}`);
        } finally {
          setSubmitting(false);
        }
      })
      .catch((e) => {
        message.error("Something went wrong.");
        console.log(e);
      });
  };

  const actionButtons = (
    <Col span={24} style={{ textAlign: "right" }}>
      <Divider style={{ backgroundColor: "#4F4669", margin: "8px 0" }} />
      <Form.Item>
        <StyledButton
          type="custom"
          onClick={() => navigate("/management")}
          hovercolor="#A68DFB"
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#fff"
        >
          <span>Cancel</span>
        </StyledButton>
        <StyledButton loading={submitting} type="custom" htmlType="submit">
          Save changes
        </StyledButton>
      </Form.Item>
    </Col>
  );

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFormSubmit} initialValues={companyDetails}>
        <FormWrapper>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Title level={4} className="color-white">
                  Email Settings
                </Title>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name="send_from_email"
                      label="Send From Email Address"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: "Please Input Send From Email Address!",
                        },
                      ]}
                    >
                      <Input placeholder={"company@mail.com"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="reply_to_email"
                      label="Reply To Email Address"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: "Please Input Send From Email Address!",
                        },
                      ]}
                    >
                      <Input placeholder={"company@mail.com"} />
                    </Form.Item>
                  </Col>
                  {actionButtons}
                </Row>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Title level={4} className="color-white">
                  Number of decimal places
                </Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      name="decimal_places_number"
                      label="Specify the number of decimal places to round on the platform"
                      extra={`Example: ${
                        decimalPlacesNumber
                          ? (1.8888888888888)
                              .toString()
                              .slice(0, decimalPlacesNumber + 2)
                          : 1
                      }`}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "number",
                          message: "Please enter the number of decimal places!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={"0"}
                        onChange={(value) => setDecimalPlacesNumber(+value)}
                      />
                    </Form.Item>
                  </Col>
                  {actionButtons}
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                }}
                bodyStyle={{
                  padding: "0px",
                }}
              >
                <Title level={4} className="color-white">
                  Chart Base URL
                </Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      name="chart_base_url"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "url",
                          message: "Please input the Chart Base URL!",
                        },
                      ]}
                    >
                      <Input placeholder="www.company.io/charts" />
                    </Form.Item>
                  </Col>
                  {actionButtons}
                </Row>
              </Card>
            </Col>
          </Row>
        </FormWrapper>
      </Form>
    </Spin>
  );
};

export default PlatformSettings;
