import { useSize } from "ahooks";
import { Col, Row, Form, FormInstance } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { surveyStore } from "../../../stores/SurveyStore";
import { StyledButton } from "../../../shared/commonStyles";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";

interface SurveyFooterButtonsProps {
  onClickPrevious: () => void;
  onClickSave: (isSubmitting: boolean) => void;
  onClickNext: (isSkipped: boolean) => void;
  form?: FormInstance; // Making antd form optional
  arr_form_input_refs?: any[]; // array of field refs. for future use
}

export const SurveyFooterButtons = observer(
  ({ onClickPrevious, onClickSave, onClickNext, form, arr_form_input_refs }: SurveyFooterButtonsProps) => {
    const size = useSize(document.querySelector("body"));
    const [isSubmitModal, setSubmitModal] = useState(false);
    const clearData = () => {
      surveyStore.changeSurveyData([], "dataToSave");
    };
    const [isFormError, setIsFormError] = useState(false); // not used anymore
    return (
      <>
        <SubmitConfirmationModal
          visible={isSubmitModal}
          onClose={() => setSubmitModal(false)}
          onExit={() => surveyStore.changeSurveyAutosaving(true)}
          onComplete={() => onClickSave(true)}
        />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Row
            justify={size && size?.width <= 900 ? "space-between" : "center"}
            style={{ flexFlow: "nowrap" }}
          >
            {surveyStore.currentStep >= 0 && (
              <Col>
                <StyledButton
                  type="custom"
                  onClick={() => {
                    onClickPrevious();
                    clearData();
                  }}
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  className="PrevButton"
                  disabled={!!(surveyStore.currentStep === 0)}
                >
                  {size && size?.width <= 767 ? <>Back</> : "Previous Step"}
                </StyledButton>
              </Col>
            )}
            {(surveyStore.currentStepData.dataSheet ||
              surveyStore.surveySteps.length - 1 ===
                surveyStore.currentStep) && (
              <Col>
                <StyledButton
                  disabled={surveyStore.surveyInfo.status === "INACTIVE"}
                  type={size && size?.width > 767 ? "text" : "link"}
                  loading={surveyStore.surveySubmitting}
                  onClick={() => {
                    surveyStore.currentStep ===
                    surveyStore.surveySteps?.length - 1
                      ? setSubmitModal(true)
                      : onClickSave(true);
                    /*
                    if (form) {
                      form
                        .validateFields()
                        .then(() => {
                          setIsFormError(false)
                          //console.log('validation suceeded')
                          // Validation succeeded
                          //form.submit();
                          surveyStore.currentStep ===
                          surveyStore.surveySteps?.length - 1
                            ? setSubmitModal(true)
                            : onClickSave(true);
                        })
                        .catch((error) => {
                          setIsFormError(true)
                          // Validation failed
                          //console.error('Validation failed:', error);
                          //message.error('Please fill in all required fields.');
                        });
                    }
                    */
                  }}
                >
                  {surveyStore.surveySteps.length - 1 !==
                  surveyStore.currentStep
                    ? "Save & Go to the next step"
                    : "Submit and Complete Survey"}
                </StyledButton>
              </Col>
            )}
            {surveyStore.surveySteps.length - 1 !== surveyStore.currentStep ? (
              <Col>
                <StyledButton
                  type="custom"
                  onClick={() => {
                    onClickNext(true);
                    clearData();
                  }}
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                >
                  {size && size?.width <= 767
                    ? "Skip"
                    : "Skip and Go to the next Step"}
                </StyledButton>
              </Col>
            ) : (
              surveyStore.currentStepData.dataSheet && (
                <StyledButton
                  disabled={surveyStore.surveyInfo.status === "INACTIVE"}
                  type={size && size?.width > 767 ? "text" : "link"}
                  loading={surveyStore.surveySubmitting}
                  onClick={() => onClickSave(false)}
                >
                  Save
                </StyledButton>
              )
            )}
          </Row>
          {(isFormError) && (
          <p style={{ color: 'red', fontWeight: 'bold'}}>Form Validation Error. Please check your form.</p>
          )}
        </div>
      </>
    );
  }
);
