import React, { useState } from "react";
import { message, Typography } from "antd";
import { CommonModal } from "../../../shared/commonStyles";
import { sendReminderToAllUser } from "../../../services/taskDeployment";
import { useLocation, useNavigate } from "react-router";

const { Text } = Typography;
const RemindAllUsers = ({
  visible,
  onClose,
  title,
  description,
  surveyId,
  okText,
  setRemindUserModal,
  emailType = "reminder",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onRemindAllUser = async () => {
    if (!surveyId) return;
    try {
      setLoading(true);
      const response = await sendReminderToAllUser(
        emailType === "reminder" ? "SURVEY_NON_COMPLETE" : "DEPLOY_SURVEY",
        surveyId
      );
      if (response) {
        setRemindUserModal(false);
        navigate(location.pathname, { state: location.state });
        message.success("Reminder has been sent successfully");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        message.error("Failed to send reminder");
      }
    } catch (error) {
      console.log("Failed to send reminder", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <CommonModal
      title={title}
      visible={visible}
      okText={okText}
      onOk={onRemindAllUser}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Text>{description}</Text>
    </CommonModal>
  );
};

export default RemindAllUsers;
