import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import {
  CommonModal,
  FormWrapper,
  StyledDivider,
} from "../../../../shared/commonStyles";
import { Alert, Col, Form, Input, Row, Spin, Typography, message } from "antd";
import { getCompanyInfo } from "../../../../services/companyInfo";
import { API, Auth } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";

interface SendEmailModalProps {
  visible: boolean;
  onClose: () => void;
}

export const SendEmailModal = ({ visible, onClose }: SendEmailModalProps) => {
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [formData, setFormData] = useState<EmailTemplate>({
    email_subject: "",
    email_data: "",
  });
  const loadCompanyInfo = async () => {
    setLoading(true);
    const companyInfo = await getCompanyInfo();
    if (companyInfo) {
      const data = JSON.parse(companyInfo.data);
      const emailData = data?.email_templates?.record_denial;
      emailData && setFormData(emailData);
      setCompanyData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCompanyInfo();
  }, [visible]);

  const handleSaveTemplate = async () => {
    try {
      setLoading(true);
      const data: any = await Auth.currentSession();
      const groupId: any = data["accessToken"]["payload"][
        "cognito:groups"
      ].filter((element: any) => element.includes("org:"))[0];
      const formatData = companyData
        ? {
            ...companyData,
            email_templates: {
              ...companyData.email_templates,
              record_denial: formData,
            },
          }
        : { email_templates: { record_denial: formData } };
      const payload = {
        data: JSON.stringify(formatData),
        group: groupId,
      };
      const response: any = await API.graphql({
        query: mutations.updateCompanyInfo,
        variables: { input: payload },
      });
      const updatedCompanyInfo = response.data.updateCompanyInfo;
      if (updatedCompanyInfo) {
        message.success("Data updated successfully!");
      }
    } catch (error) {
      message.error(`Error while submitting data ${error}`);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <CommonModal
      width="60%"
      visible={visible}
      onCancel={onClose}
      okText="Save"
      onOk={handleSaveTemplate}
    >
      <Spin spinning={loading}>
        <FormWrapper>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Title level={3}>Email Template</Typography.Title>
              <Typography.Title level={4}>Email Settings</Typography.Title>
              <StyledDivider />
              <Form.Item
                labelCol={{ span: 24 }}
                label="Email Subject"
                rules={[
                  {
                    required: true,
                    message: "Please enter an email subject",
                  },
                ]}
              >
                <Input
                  value={formData.email_subject}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      email_subject: e.target.value,
                    }))
                  }
                  placeholder="Please enter an email subject"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>Email Body</Typography.Title>
              <StyledDivider />
              <Alert
                message="Email Options"
                description="There are 4 placeholders currently available: {SURVEY_NAME}, {COMMENTS}, {REVIEWER_NAME}, {SURVEY_URL}. The platform will send the survey name, any comments left by the reviewer, the reviewer's name, and provide a link to the survey for correction. The placeholders will be autofilled when the emails are sent and can be used in links as well."
                type="warning"
                showIcon
              />
              <Typography.Title style={{ fontSize: "14px" }}>
                Email Data
              </Typography.Title>
              <div className="ckMediumHeight">
                <CKEditor
                  editor={DecoupledDocumentEditor}
                  data={formData.email_data}
                  onReady={(editor: any) => {
                    if (editor?.ui) {
                      editor.ui.view.editable.element.parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.view.editable.element
                      );
                    }
                  }}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    setFormData((prev: any) => ({ ...prev, email_data: data }));
                  }}
                />
              </div>
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};
