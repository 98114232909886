import React, { useState } from "react";
import {
  FormWrapper,
  StyledButton,
  StyledDivider,
} from "../../../shared/commonStyles";
import { Button, Col, Row, Spin, Typography, Upload, message } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  InboxOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import styles from "./ImportData.module.scss";
import { CommentFile } from "./CommentFile";
import { downloadTemplate } from "../../../services/importData";
import {
  downloadDataLakeFileHandler,
  downloadDataSheetImportFileHandler,
} from "../../../shared/commonFunctions";

interface ImportState {
  importId: string;
  action: string;
}
interface ImportFileProps {
  files: ImportedFile[];
  setFiles: (files: ImportedFile[]) => void;
  state?: ImportState;
}

export const ImportFile = ({ files, setFiles, state }: ImportFileProps) => {
  const [commentModal, setCommentModal] = useState(false);
  const [currentFile, setCurrentFile] = useState<ImportedFile>();
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const handleDownloadTemplate = async () => {
    try {
      setLoadingTemplate(true);
      const resp = await downloadTemplate();
      if (resp && resp.statusCode === 200) {
        let fileName = "";
        const filenameMatch =
          resp?.headers?.["Content-Disposition"]?.match(/filename=([^;\n]+)/);
        if (filenameMatch) {
          fileName = filenameMatch[1];
          console.log(fileName);
        }
        const binaryString = atob(resp.body);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], {
          type: resp?.headers?.["Content-Type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (resp.statusCode >= 400) {
        message.error(resp?.body);
      }
      setLoadingTemplate(false);
    } catch (e) {
      message.error("Something went wrong while downloading sample excel");
      setLoadingTemplate(false);
    }
  };

  const handleComment = (file: ImportedFile) => {
    setCommentModal(true);
    setCurrentFile(file);
  };

  const handleDelete = (file: ImportedFile) => {
    const newFiles = files?.filter((v) => v.uid !== file.uid);
    setFiles(newFiles);
  };

  const handleAddComment = (comment: string) => {
    const newFiles = files.map((file) => {
      return file.uid === currentFile?.uid ? { ...file, comment } : file;
    });
    setFiles(newFiles);
    setCommentModal(false);
  };

  const handleDownloadFile = async (file: ImportedFile) => {
    file.isBaseFile
      ? await downloadDataSheetImportFileHandler(file.url, file.dataId)
      : await downloadDataLakeFileHandler(file.url, file.dataId);
  };

  return (
    <>
      <CommentFile
        visible={commentModal}
        file={currentFile}
        onCancel={() => setCommentModal(false)}
        onConfirm={handleAddComment}
      />
      <FormWrapper height={"100%"} style={{ paddingTop: "20px" }}>
        <Row justify="space-between" style={{ alignItems: "baseline" }}>
          <Typography.Title style={{ padding: "0px" }} level={3}>
            Upload data
          </Typography.Title>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Spin spinning={loadingTemplate}>
              <Typography.Title level={5} className="color-grey">
                Upload a file with a filled-in{" "}
                {state?.action !== "update" && state?.action !== "review" ? (
                  <span
                    className={styles.templateLink}
                    onClick={handleDownloadTemplate}
                  >
                    template
                  </span>
                ) : (
                  <span>template</span>
                )}{" "}
                with the data you want to add to the system.
              </Typography.Title>
            </Spin>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Typography.Title level={4}>Upload source file</Typography.Title>
          </Col>
          <StyledDivider />
        </Row>
        <Row>Upload file</Row>
        {!files?.length && (
          <>
            <Upload.Dragger
              disabled={
                state?.action === "update" || state?.action === "review"
              }
              multiple={true}
              fileList={[]}
              beforeUpload={(file, fileList) => {
                setFiles([...files, ...fileList]);
              }}
              customRequest={({ file, onSuccess }) => {
                onSuccess && onSuccess("ok");
              }}
              className={styles.uploadBox}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined className="color-white" />
              </p>
              <p className="ant-upload-text color-white">
                Drag file to this area to upload
              </p>

              <p className="ant-upload-hint color-white">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Upload.Dragger>
            <Row>Or</Row>
          </>
        )}
        <Row align="top">
          <Col span={24}>
            <Upload
              disabled={
                state?.action === "update" || state?.action === "review"
              }
              multiple={true}
              customRequest={({ file, onSuccess }) => {
                onSuccess && onSuccess("ok");
              }}
              fileList={[]}
              beforeUpload={(file, fileList) => {
                setFiles([
                  ...files,
                  ...fileList,
                  // .map((v) => ({
                  //   uid: v.uid,
                  //   name: v.name,
                  //   type: v.type,
                  // })),
                ]);
              }}
            >
              <StyledButton
                disabled={
                  state?.action === "update" || state?.action === "review"
                }
                className="margin-5"
                type="custom"
                padding={"5px 12px"}
                bgcolor={"#7F5FEE"}
                bordercolor={"#7F5FEE"}
              >
                <PlusCircleOutlined />
                <span>
                  {files?.length ? "Click to upload file" : "Upload file"}
                </span>
              </StyledButton>
            </Upload>
          </Col>
        </Row>
        {files.map((file) => (
          <Row
            key={file.uid}
            className={styles.uploadedFile}
            justify="space-between"
            align="middle"
            wrap={false}
            gutter={[8, 8]}
          >
            <Col>
              <Row
                className={styles.uploadedFileIcon}
                justify="center"
                align="middle"
              >
                <FilePdfOutlined />
              </Row>
            </Col>
            <Col flex="auto">
              <div className={styles.uploadedFileText}>{file.name}</div>
              {!!file.comment && (
                <div className="color-grey">{file.comment}</div>
              )}
            </Col>
            <Col>
              <Row justify="center" align="middle" wrap={false}>
                {/* <Button
                  onClick={() => handleComment(file)}
                  type="text"
                  icon={<MessageOutlined className="color-grey" />}
                /> */}
                {state?.action === "review" || state?.action === "update" ? (
                  <Button
                    onClick={() => handleDownloadFile(file)}
                    type="text"
                    icon={<DownloadOutlined className="color-grey" />}
                  />
                ) : (
                  <Button
                    disabled={
                      state?.action === "update" || state?.action === "review"
                    }
                    onClick={() => handleDelete(file)}
                    type="text"
                    icon={<DeleteOutlined className="color-grey" />}
                  />
                )}
              </Row>
            </Col>
          </Row>
        ))}
      </FormWrapper>
    </>
  );
};
