export const COLORS = {
  purpleMagenta: "#2C1337",
  purpleMagentaDark: "#1D0B24",
  purple5: "#7b61ff",
  purple6: "#722ed1",
  purple7: "#531dab",
  white: "#ffffff",
  rhino: "#343A75",
  haiti: "#1B1142",
  black: "#000",
  backgroundDark: "#1D1729",
  raisinBlack: "#1D1729",
  martinique: "#302A43",
};

export const siteThemeColors = {
  chartsDashboardBgColor: "transparent",
  headerBgColor: "#241B39",
  sideBarColors: {
    mainBgColor: "#241B39",
    menuBackground: "#2C1337",
    subMenuBackground: "#1D0B24",
    sidebarCollapseBackgroud: "",
  },
  commonColor: {
    bgColor: "#2D273F",
    color: "#fff",
  },
  pageHeaderColor: "",
  TagColor: {
    bgColor: "#A68DFB",
    color: "#fff",
  },
  ButtonColor: {
    bgColor: "#A68DFB",
    color: "#fff",
    hoverBgColor: "#7F5FEE",
    hoverColor: "#fff",
    defaultColor: "#fff",
    defaultBgColor: "#443A59",
    defaultHoverColor: "#7F5FEE",
    defaultHoverBgColor: "#7F5FEE",
  },
  ModalColor: {
    bgHeaderColor: "#362E4B",
    bgColor: "#2D273F",
    color: "#fff",
    bgButtonColor: "#7F5FEE",
    bgButtonHoverColor: "#664DB9",
    disabledColor: "#87809c",
    disabledBgColor: "#625d75",
  },
  StepColor: {
    bgColor: "#A68DFB",
    borderColor: "#716A88",
    color: "#fff",
  },
  TableColor: {
    bgColorHeader: "#423A5A",
    bgColor: "#2D273F",
    color: "#fff",
    bgColorHover: "#3A334E",
    editableColor: "#443A59",
    bgFilterColor: "#53476C",
    bgFilterHoverColor: "#413557",
  },
  TablePagintionColor: {
    bgColor: "#38304F",
    color: "#fff",
    borderColor: "#A68DFB",
    ellipsisColor: "#8E8B94",
  },
  TabColor: {
    bgColor: "#531dab",
    color: "#ffffff",
  },
  FormColor: {
    bgColor: "#443A59",
    bgFormColor: "#625D75",
    borderColor: "#87809C",
    color: "#fff",
    hoverBorderColor: "#A68DFB",
    uncheckColor: "#716A88",
    boxShadowColor: "rgba(83, 29, 171, 0.3)",
  },
  PageHeaderColor: {
    bgColor: "#423A5A",
    color: "#fff",
  },
  BreadcrumbsColor: {
    bgColor: "#2D273F",
  },
  SelectColor: {
    bgColor: "#625D75",
    color: "#fff",
    borderColor: "#87809C",
    borderHoverColor: "#A68DFB",
    bgOptionColor: "#2D273F",
    bgOptionHoverColor: "#3A334E",
  },
  DatePickerColor: {
    bgColor: "#625D75",
    borderColor: "#87809C",
    color: "#fff",
    selectedColor: "#A68DFB",
    rangeColor: "red",
  },
  TransferColor: {
    bgColor: "#312B42",
    bgItemColor: "#37304B",
    bgHeaderColor: "#4D4265",
    color: "#fff",
  },
  TimelineColor: {
    bgColor: "transparent",
    bgBorderColor: "#A68DFB",
    bgTailBorderColor: "#625D75",
  },
  FontColor: {
    primary: "#fff",
    secondary: "#B1AFBC",
  },
};
