import { Card, Col, Row, Typography, message, Tabs, Spin, Alert } from "antd";
import React, { useState, useEffect } from "react";
import {
  FormWrapper,
  StyledProgress,
  StyledTabs,
} from "../../../shared/commonStyles";
import ProgressTracker from "./ProgressTracker";
import {
  getGolasAndProgressById,
  sendTaskReminder,
} from "../../../services/goals";
import { sdgGoalsStaticList } from "../../../shared/constants";
const { Title } = Typography;
const { TabPane } = Tabs;

export const SdgMetrics = ({ metricsData, sdgId }) => {
  const [sdgGoal, setSdgGoal] = useState(null);
  const [loader, setLoader] = useState(false);
  const loadData = async () => {
    try {
      setLoader(true);
      const sdgData = await getGolasAndProgressById(sdgId);
      const sdgGoal = sdgGoalsStaticList?.find(
        (item) => item.goal === sdgData?.sdg_num
      );
      setSdgGoal(sdgGoal);
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (sdgId) {
      loadData();
    }
  }, [sdgId]);

  return (
    <Spin spinning={loader}>
      <Row gutter={[16, 16]}>
        <Col span={1}>
          <img src={sdgGoal?.image_url} width="100%" />
        </Col>
        <Col span={23}>
          <StyledProgress
            percent={metricsData[sdgId]?.progress}
            size="default"
            strokeWidth={24}
            trailColor="#3E3850"
            strokeColor="#A68DFB"
            width="60"
            style={{ width: "100%", margin: "15px 0" }}
          />
        </Col>
      </Row>
    </Spin>
  );
};
const OverviewGoals = () => {
  const [selectedTab, setSelectedTab] = useState("all_categories");
  const [loader, setLoader] = useState(false);
  const [metricData, setMetricData] = useState(null);
  const onTabChange = (selectedTabKey) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };
  const loadMetricData = async () => {
    try {
      setLoader(true);
      const data = await sendTaskReminder("GOALS_OVERVIEW");
      //{"data":{"sendTaskReminder":"{\"ERROR\": \"local variable 'sdg_progress_by_name' referenced before assignment\"}"}}
      //based on above response implemented below check, incase above response changes modify below check accordingly
      setMetricData(data && !JSON.parse(data).ERROR ? JSON.parse(data) : "");
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadMetricData();
  }, []);

  return (
    <Spin spinning={loader}>
      <FormWrapper>
        {metricData ? (
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Total Goals</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.overall_progress?.total}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>In Progress</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.overall_progress?.in_progress}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Title level={4}>Completed</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.overall_progress?.completed}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Total Progress</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {metricData?.overall_progress?.progress_percent}%
                </Title>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  width: "100%",
                }}
                title="Sustainable Development Goals"
                bordered={false}
              >
                <Row gutter={[16, 16]}>
                  {Object.keys(metricData?.sdg_progress_by_id).map(
                    (sdgId, index) => {
                      return (
                        <Col span={24} key={index}>
                          <SdgMetrics
                            metricsData={metricData?.sdg_progress_by_id}
                            sdgId={sdgId}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: "#2d273f",
                  width: "100%",
                }}
                title="General Goals"
                bordered={false}
              >
                <Row style={{ marginBottom: "61px" }}>
                  <Col span={24}>
                    <StyledTabs activeKey={selectedTab} onChange={onTabChange}>
                      <TabPane tab="All Categories" key="all_categories" />
                      <TabPane tab="Environmental" key="environmental" />
                      <TabPane tab="Social" key="social" />
                      <TabPane tab="Governance" key="governance" />
                    </StyledTabs>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    {selectedTab === "all_categories" && (
                      <ProgressTracker
                        metricData={metricData?.general_progress_by_name}
                      />
                    )}
                    {selectedTab === "environmental" && (
                      <ProgressTracker
                        metricData={metricData?.general_progress_by_name}
                        type={"environmental"}
                      />
                    )}
                    {selectedTab === "social" && (
                      <ProgressTracker
                        metricData={metricData?.general_progress_by_name}
                        type={"social"}
                      />
                    )}
                    {selectedTab === "governance" && (
                      <ProgressTracker
                        metricData={metricData?.general_progress_by_name}
                        type={"governance"}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            {!loader && (
              <Alert
                className="customInfoAlert"
                message="No data found. Please add some goals"
                type="info"
                style={{
                  padding: "13px",
                }}
                showIcon
              />
            )}
          </>
        )}
      </FormWrapper>
    </Spin>
  );
};

export default OverviewGoals;
