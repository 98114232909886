import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button, DatePicker, Divider, Input, Space } from "antd";
import moment, { Moment } from "moment";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import styles from "./UpdateCurrentDataModal.module.scss";

interface UpdateCurrentDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (key: string, emissionAmount: string, date: string) => void;
}

export const UpdateCurrentDataModal = observer(
  ({ isOpen, onClose }: UpdateCurrentDataModalProps) => {
    const [consumptionAmount, setConsumptionAmount] = useState<string>("");
    const [date, setDate] = useState<Moment>(moment());
    const { state } = useLocation();

    useEffect(() => {
      !reductionPlanStore.targets.length &&
        reductionPlanStore.getTargetById(state as string);
    }, []);

    const handleChangeConsumptionAmount = (
      e: ChangeEvent<HTMLInputElement>
    ): void => {
      setConsumptionAmount(e.target.value);
    };

    const handleClose = (): void => {
      onClose();
    };

    const handleChangeData = (date: Moment | null): void => {
      date && setDate(date);
    };
    const dateFormat = "YYYY-MM-DD";

    const handleConfirm = (): void => {
      reductionPlanStore.updateCurrentTargetData(state as string, {
        key: uuidv4(),
        date: date.format(dateFormat),
        consumptionAmount,
      });
      onClose();
    };

    return (
      <>
        <CommonModal
          title="Update current target data"
          visible={isOpen}
          footer={[
            <Button type="text" onClick={handleClose} key="cancel">
              Cancel
            </Button>,
            <Button type="primary" key="confirm" onClick={handleConfirm}>
              Update data
            </Button>,
          ]}
          onCancel={handleClose}
        >
          <FormWrapper>
            <div className={styles.inputBlock}>Current data</div>
            <Divider className={styles.divider} />
            <div> Date</div>
            <DatePicker
              className={styles.datePicker}
              onChange={handleChangeData}
              value={date}
              format={dateFormat}
              disabledDate={(current) => {
                return current.isAfter(moment());
              }}
            />
            <div>Consumption amount</div>
            <div>
              <Input
                className={styles.inputSuccessStep}
                placeholder="Enter amount"
                value={consumptionAmount}
                onChange={handleChangeConsumptionAmount}
              />
            </div>
          </FormWrapper>
        </CommonModal>
      </>
    );
  }
);
