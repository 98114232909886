import React from "react";
import {CommonModal} from "../../../shared/commonStyles";
import { Typography } from "antd";
const Text = Typography;
const ConfirmDeleteModal = ({description,isVisible,onConfirm,onClose,confirmLoading}) => {
  return (
    <>
      <CommonModal
        title={"Confirmation"}
        visible={isVisible}
        okText='Delete'
        onOk={onConfirm}
        onCancel={onClose}
        confirmLoading={confirmLoading}
      >
        <Text>{description}</Text>
      </CommonModal>
    </>
  );
};

export default ConfirmDeleteModal;
