import React, { useState } from 'react';
import { RightSideWrap, VideoHeader, StyledButton } from '../../../shared/commonStyles';
import { Typography, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ReactPlayer from 'react-player/lazy';
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";

const { Text, Title } = Typography;

export const VideoPlayerSection = ({ data, showUpdateModal, onClickDelete }) => {
  const { checkPermissions } = useCheckRolesPermissions();
  const [loadError, setLoadError] = useState(false);

  if (!data) return <></>;

  // Handle ReactPlayer load error
  const handlePlayerError = () => {
    setLoadError(true);
  };

  return (
    <RightSideWrap>
      <VideoHeader>
        <Text style={{ margin: 'auto 0px' }} className="color-white" strong>{data.name}</Text>
        {checkPermissions("manage-learning-content") && (
          <Space direction="horizontal" size="small">
            <StyledButton
              onClick={() => showUpdateModal(true)}
              type="custom"
              disabled={!data}
              style={{ borderRadius: '4px' }}
            >
              <EditOutlined /> Edit Content
            </StyledButton>
            <StyledButton
              onClick={() => onClickDelete()}
              type="custom"
              style={{ borderRadius: '4px' }}
            >
              <DeleteOutlined /> Delete Content
            </StyledButton>
          </Space>
        )}
      </VideoHeader>

      {!loadError ? (
        <ReactPlayer 
          url={data.content_url} 
          width='100%'
          height='550px'
          controls={true}
          onError={handlePlayerError}  // Handle error during loading video
        />
      ) : (
        <iframe 
          src={data.content_url} 
          width="100%" 
          height="1000px"
          style={{ border: 'none' }}
        />
      )}

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Title 
          level={4} 
          style={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            color: 'white',
            margin: '15px 0px',
          }} 
        >
          Description
        </Title>
        <div className="html-view-wrap" dangerouslySetInnerHTML={{ __html: data.description }} />     
      </div>
    </RightSideWrap>
  );
};
