import React, { useState } from "react";
import { CardsWrap, FormWrapper } from "../../../shared/commonStyles";
import { Col, Row, Typography, Form, Select, Input } from "antd";
import styles from "../CreateStandardsDataSheet.module.scss";
import {
  getSchemaForPortfolioCompanyList,
  getSchemaForRealEstateList,
  getSchemaForSupplierList,
} from "../utils";

const templateStandardSheets = require("../../../shared/Json/hydrus-standards-sheets.json");
const templateRegularSheets = require("../../../shared/Json/hydrus_sheets.json");

interface StandardsSelectionProps {
  initialValues: DataSheet;
  onSelectTemplate: (template: DataSheet) => void;
}

export const TemplateSheetSelection = ({
  initialValues,
  onSelectTemplate,
}: StandardsSelectionProps) => {
  const { is_reference_data, is_standard_data } = initialValues;
  const [searchData, setSearchData] = useState("");

  const metadataTemplates = [
    {
      sheet_name: "Real Estate List",
      sheet_schema: getSchemaForRealEstateList(),
    },
    { sheet_name: "Supplier List", sheet_schema: getSchemaForSupplierList() },
    {
      sheet_name: "Portfolio Company List",
      sheet_schema: getSchemaForPortfolioCompanyList(),
    },
  ];

  const cardsData = is_standard_data
    ? templateStandardSheets
    : is_reference_data
    ? [{ sheet_name: "Custom" }, ...metadataTemplates]
    : [{ sheet_name: "Custom" }, ...templateRegularSheets];

  return (
    <FormWrapper>
      <Typography.Title level={3}>
        Select the {is_standard_data ? "standard" : "template"}
      </Typography.Title>
      <Input
        value={searchData}
        onChange={(e) => setSearchData(e.target.value)}
        placeholder="Search template by name"
      />
      <Form.Item name="sheet_type">
        <CardsWrap>
          <Row gutter={[32, 32]} className={styles.container}>
            {cardsData
              ?.filter((card: DataSheet) =>
                card.sheet_name
                  .toLowerCase()
                  .includes(searchData?.toLowerCase())
              )
              .map((template: DataSheet) => (
                <Col key={template.sheet_name} span={6}>
                  <div
                    className={
                      initialValues.sheet_type === template.sheet_name
                        ? `${styles.cardBox} ${styles.cardBoxSelected}`
                        : styles.cardBox
                    }
                    onClick={() => onSelectTemplate(template)}
                  >
                    <div className={styles.cardTitle}>
                      {template.sheet_name}
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </CardsWrap>
      </Form.Item>
    </FormWrapper>
  );
};
