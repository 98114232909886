import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { StyledTabs } from "../../../../../shared/commonStyles";
import { carbonStore } from "../../../../Carbon/CarbonStore";
import { EmissionInfo } from "./EmissionInfo";
import { OffsetInfo } from "./OffsetInfo";
import styles from "./InfoAndNotesDrawer.module.scss";
import { Notes } from "./Notes";

interface InfoAndNotesDrawerProps {
  isCalculationLocked?: boolean;
  visible: boolean;
  typeDrawer: string;
  drillDownRecord?: DrillDownRecord;
  calculationEntry: Emission | Offset;
  onClose: () => void;
  calculationEntryType: CalculationEntryType;
}

export const InfoAndNotesDrawer = ({
  calculationEntry,
  isCalculationLocked,
  drillDownRecord,
  visible,
  typeDrawer,
  onClose,
  calculationEntryType,
}: InfoAndNotesDrawerProps) => {
  const [drawer, setDrawer] = useState(typeDrawer);

  useEffect(() => {
    setDrawer(typeDrawer);
  }, [typeDrawer]);

  const handleChange = (key: string) => {
    setDrawer(key);
  };

  return (
    <Drawer
      width={400}
      className={styles.drawer}
      closable={false}
      placement="right"
      visible={visible}
      onClose={onClose}
    >
      <h2 className={styles.drawerTitle}>
        {(calculationEntry as Emission)?.factor_data?.name}
      </h2>
      <StyledTabs activeKey={drawer} onChange={handleChange}>
        <StyledTabs.TabPane tab="Information" key="info">
          {calculationEntryType === CalculationEntryType.EMISSIONS ? (
            <EmissionInfo
              emission={calculationEntry as Emission}
              drillDownRecord={drillDownRecord}
              onClose={onClose}
            />
          ) : (
            <OffsetInfo data={calculationEntry as Offset} onClose={onClose} />
          )}
        </StyledTabs.TabPane>
        {!drillDownRecord && (
          <StyledTabs.TabPane tab="Notes" key="notes">
            <Notes
              onClose={onClose}
              calculationEntryType={calculationEntryType}
              calculationEntry={calculationEntry}
              isCalculationLocked={!!isCalculationLocked}
            />
          </StyledTabs.TabPane>
        )}
      </StyledTabs>
    </Drawer>
  );
};
