import { Drawer, Input } from "antd";
import React, { useState } from "react";
import { FormWrapper, StyledButton } from "../../../shared/commonStyles";
import styles from "./SurveyNotesDrawer.module.scss";

interface SurveyNotesDrawerProps {
  visible: boolean;
  notes: Note[];
  onClose: () => void;
  onSubmit: (text: string) => void;
  name?: string;
}

export const SurveyNotesDrawer = ({
  visible,
  notes,
  onClose,
  onSubmit,
  name,
}: SurveyNotesDrawerProps) => {
  const [note, setNote] = useState("");
  const handleSubmit = () => {
    onSubmit(note);
    setNote("");
  };
  return (
    <Drawer
      width={520}
      className={styles.drawer}
      placement="right"
      visible={visible}
      onClose={onClose}
      title="Add note"
    >
      <FormWrapper>
        <div className={styles.drawerContent}>
          <ul className={styles.drawerList}>
            <h2 className={styles.drawerTitle}>{name}</h2>
            <h2 className={styles.drawerTitle}>Add note</h2>
            <li className={styles.drawerListItem}>
              <div className={styles.drawerListItemWrapper}>
                <Input.TextArea
                  showCount
                  value={note}
                  maxLength={1000}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Enter note"
                  className={styles.drawerListItemTextArea}
                />
              </div>
            </li>
            {notes
              ?.sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((note) => (
                <li className={styles.drawerListItem} key={note.date}>
                  <div className={styles.drawerListItemWrapper}>
                    <div className={styles.drawerListItemHeader}>
                      <div className={styles.drawerListItemTitleDescription}>
                        {note.user}
                      </div>
                      <div>{note.date?.slice(0, 11)}</div>
                    </div>
                    <p className={styles.drawerListItemText}>{note.text}</p>
                  </div>
                </li>
              ))}
          </ul>
          <div className={styles.drawerFooter}>
            <StyledButton onClick={onClose}>Close</StyledButton>
            <StyledButton onClick={handleSubmit}>Submit</StyledButton>
          </div>
        </div>
      </FormWrapper>
    </Drawer>
  );
};
