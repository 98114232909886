import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, message, Row, Statistic } from "antd";
import { HourglassOutlined } from "@ant-design/icons";
import { API, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
/*import {
  boot as bootIntercom,
  shutdown as shutdownIntercom,
} from "../../intercom";*/
import * as queries from "../../graphql/queries";
import { CommonModal, StyledButton } from "../../shared/commonStyles";
import styles from "./TimeoutModal.module.scss";
import { useCountDown } from "ahooks";

const TimeoutModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalVisible, setModalVisible] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(3600);

  const getSessionTimeout = async () => {
    try {
      const data = await Auth.currentSession();
      const group = data
        .getAccessToken()
        ["payload"]["cognito:groups"].filter((element: any) =>
          element.includes("org:")
        )[0];
      const response = (await API.graphql({
        query: queries["getClientSettings"],
        variables: { group },
      })) as GraphQLResult<{ getClientSettings: any }>;

      setSessionTimeout(response?.data?.getClientSettings.session_timeout);
    } catch (error) {
      console.log("Error while load client Settings data", error);
    }
  };

  const sessionTime = Date.now() + sessionTimeout * 1000;
  const deadline = Date.now() + 15 * 60 * 1000;
  const [timeToEndSession, setTimeToEndSession] = useState(sessionTime);
  const modalTime = Date.now() + (timeToEndSession - deadline);

  const createNewTimeout = () => {
    setTimeToEndSession(sessionTime);
  };

  useEffect(() => {
    getSessionTimeout();
    createNewTimeout();
  }, []);

  useEffect(() => {
    createNewTimeout();
  }, [sessionTimeout]);

  useEffect(() => {
    createNewTimeout();
  }, [location]);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem("modules");
      //shutdownIntercom();
      /*bootIntercom({
        app_id: process.env.REACT_APP_APP_ID_ENV || "",
      });*/
      navigate("/");
    } catch (error) {
      console.log("Error while signing out ", error);
      message.error("something went wrong while signing out!");
    }
  };

  const [countdown] = useCountDown({
    targetDate: timeToEndSession,
    onEnd: () => {
      signOut();
    },
  });
  const [countdownModal] = useCountDown({
    targetDate: modalTime,
    onEnd: () => {
      handleOpenModal();
    },
  });
  const onCancel = () => {
    handleCloseModal();
  };

  const handleExtend = () => {
    handleCloseModal();
    createNewTimeout();
  };

  return (
    <div>
      <CommonModal
        visible={modalVisible}
        centered
        title="This session is about to expire"
        footer={null}
        onCancel={onCancel}
      >
        <Row align="middle" justify="center">
          <Col>
            <HourglassOutlined className={styles.timeIcon} />
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <Statistic.Countdown
              className={styles.countDown}
              value={Date.now() + countdown}
              format="mm:ss"
            />
          </Col>
        </Row>
        <Row align="middle" justify="center" className={styles.text}>
          <Col>This session is about to expire.</Col>
        </Row>
        <Row align="middle" justify="center" className={styles.text}>
          <Col>Click the button below to extend the session</Col>
        </Row>

        <Row align="middle" justify="center" className={styles.button}>
          <Col>
            <StyledButton type="custom" onClick={handleExtend}>
              Extend
            </StyledButton>
          </Col>
        </Row>
      </CommonModal>
    </div>
  );
};

export default TimeoutModal;
