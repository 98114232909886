import React, { useEffect, useState, useRef } from "react";
import { Form, Input, message, Row, Select, Checkbox } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
  CommonSelect,
} from "../../../shared/commonStyles";
import { getDataSheets } from "../../../services/dataSheet";
import { updateAnalyticsDashboardCharts } from "../../../services/analyticsChart";

const UpdateDashboardModal = ({
  isVisible,
  onClose,
  onFinish,
  catlist,
  rowData,
  action,
  refresh,
}) => {
  const [initialValues, setInitialValues] = useState({
    chart_name: "",
    chart_id: "",
    section: "",
    status: "",
    datasheet_id: [],
    metadata_sheet_id: "",
    is_carbon: false,
  });
  const [btnLoading, setBtnLoading] = useState();
  const [dataSheets, setDataSheets] = useState();
  const [modalForm] = Form.useForm();
  const [metaData, setMetadata] = useState("");
  const handleMetaDataChange = (option) => {
    setMetadata(option);
  };

  if (!rowData) {
    return;
  }
  useEffect(() => {
    loadDataSheets();
    let isMounted = true;
    if (rowData) {
      try {
        const data = {
          chart_name: rowData?.chart_name ? rowData?.chart_name : "",
          chart_id: rowData?.chart_id ? rowData?.chart_id : "",
          section: rowData?.section ? rowData?.section : "",
          status: rowData?.status ? rowData?.status : "",
          datasheet_id: rowData?.datasheet_id ? rowData?.datasheet_id : [],
          metadata_sheet_id: rowData?.metadata_sheet_id
            ? rowData?.metadata_sheet_id
            : "",
          is_carbon: rowData?.is_carbon ? rowData?.is_carbon : false,
        };
        setInitialValues(data);
        modalForm.setFieldsValue(data);
      } catch (error) {
        console.log("Error while pre fill form values", error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [rowData]);
  const loadDataSheets = async () => {
    // const payloadMeta = {
    //   filter: { is_reference_data: false },
    // };
    const dataSheets = await getDataSheets({});

    setDataSheets(dataSheets);
  };

  const onChangeCarbon = (option) => {
    setInitialValues((oldState) => ({
      ...oldState,
      is_carbon: option.target.checked,
    }));
  };
  const onChangeFieldsData = async (data) => {
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const onClickUpdate = async (data) => {
    setBtnLoading(true);
    const {
      chart_name,
      chart_id,
      section,
      status,
      datasheet_id,
      metadata_sheet_id,
      is_carbon,
    } = initialValues;
    try {
      const payload = {
        ...rowData,
        _id: rowData["_id"],
        chart_name: chart_name,
        chart_id: chart_id,
        section: section,
        status: status,
        datasheet_id: initialValues?.section === "Carbon" ? "" : datasheet_id,
        metadata_sheet_id:
          initialValues?.section === "Carbon" ? "" : metadata_sheet_id,
        is_carbon: is_carbon,
      };

      const updateChartResponse = await updateAnalyticsDashboardCharts(payload);

      if (updateChartResponse) {
        setBtnLoading(false);
        message.success("Dashboard Updated Successfully!");
        onClose();
        refresh();
      } else {
        message.error();
        ("Failed to update dashboard!");
      }
    } catch (error) {
      console.error(
        "Something went wrong while updating analytics dashboard!",
        error
      );
      message.error("Something went wrong while updating analytics dashboard!");
    }
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <>
      <CommonModal
        destroyOnClose={true}
        width={600}
        title={"Update Analytics Dashboard"}
        visible={isVisible}
        footer={null}
        onCancel={onClose}
      >
        <FormWrapper>
          <Form
            form={modalForm}
            onFieldsChange={(data) => onChangeFieldsData(data)}
            onFinish={(data) => onClickUpdate(data)}
            initialValues={initialValues}
            autoComplete="off"
          >
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Dashboard Name</span>}
              name="chart_name"
              rules={[
                {
                  required: true,
                  message: "Please input dashboard name!",
                },
              ]}
            >
              <Input
                placeholder="Type in a name for the dashboard Name"
                className="inner-color-input"
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Dashboard ID</span>}
              name="chart_id"
              rules={[
                {
                  required: true,
                  message: "Please input dashboard ID",
                },
              ]}
            >
              <Input
                placeholder="Type in a name for the dashboard ID"
                className="inner-color-input"
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Category</span>}
              name="section"
              rules={[
                {
                  required: true,
                  message: "Please input category name!",
                },
              ]}
            >
              <Select>
                {catlist.map((item, i) => {
                  return (
                    <Select.Option key={i} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="isCarbon">
              <Checkbox
                checked={initialValues?.is_carbon}
                onChange={onChangeCarbon}
              >
                Carbon
              </Checkbox>
            </Form.Item>
            {!initialValues.is_carbon && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={<span className="color-white">Meta data</span>}
                name="metadata_sheet_id"
              >
                <CommonSelect
                  showSearch
                  allowClear
                  showArrow
                  style={{ width: "100%" }}
                  placeholder="Select Metadata"
                  onChange={handleMetaDataChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={dataSheets
                    ?.filter((item) => item.is_reference_data)
                    .map((item) => ({
                      value: item._id?.$oid,
                      label: item.sheet_name,
                    }))}
                />
              </Form.Item>
            )}
            {!initialValues.is_carbon && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <span className="color-white">
                    Data Sheet Name
                    <p>
                      {" "}
                      (select only if the data for the dashboard is going to
                      come from a datasheet){" "}
                    </p>
                  </span>
                }
                name="datasheet_id"
              >
                <CommonSelect
                  showSearch
                  allowClear
                  showArrow
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Sheet Name"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={dataSheets
                    ?.filter((item) =>
                      metaData
                        ? !item.is_reference_data &&
                          metaData === item.metadata_sheet_id
                        : !item.is_reference_data
                    )
                    ?.map((item) => ({
                      value: item._id?.$oid,
                      label: item.sheet_name,
                    }))}
                />
              </Form.Item>
            )}
            <Row justify="end">
              <StyledButton type="default" onClick={() => handleCancel()}>
                Cancel
              </StyledButton>
              <StyledButton
                type="default"
                loading={btnLoading}
                htmlType="submit"
              >
                Update
              </StyledButton>
            </Row>
          </Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default UpdateDashboardModal;
