import { Auth, Storage } from "aws-amplify";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import {
  FormWrapper,
  ProfilePhotoWrap,
  StyledButton,
} from "../../../../shared/commonStyles";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../../contexts/AuthContext";
import ChangePasswordModal from "../../../../components/Modals/ChangePassword/ChangePasswordModal";
import { MailOutlined } from "@ant-design/icons";
import { countryCodes } from "../../../../shared/constants";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;
const { Option } = Select;
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

const ProfileDetail = () => {
  const navigate = useNavigate();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [fields, setFields] = useState(null);
  const [form] = Form.useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const { avatarUrl, getProfileImage } = useContext(AuthContext);
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [noImage, setNoImage] = useState(false);

  const prefixSelector = (
    <Form.Item name="country_code" noStyle>
      <Select
        showSearch={true}
        style={{ width: 150, backgroundColor: "#625D75" }}
      >
        {countryCodes.map((code, index) => {
          return (
            <Option key={index} value={code?.dial_code}>
              {code?.dial_code} {code?.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const fetchCurrentUserInfo = async () => {
    const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const { attributes } = data;
    const userInfomation = {
      name: attributes?.name,
      email: attributes?.email,
      phone_number: attributes?.phone_number,
      username: data?.username,
    };
    setUserInfo(userInfomation);
    const phone_number = attributes?.phone_number
      ? phoneUtil.parse(attributes?.phone_number)
      : null;
    setFields([
      {
        name: ["name"],
        value: attributes.name,
      },
      {
        name: ["country_code"],
        value: phone_number ? "+" + phone_number.getCountryCode() : null,
      },
      {
        name: ["phone"],
        value: phone_number ? phone_number.getNationalNumber() : null,
      },
    ]);
  };

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        setLoadingUserInfo(true);
        await fetchCurrentUserInfo();
      } catch (error) {
        message.error(error);
      } finally {
        setLoadingUserInfo(false);
      }
    };
    loadUserInfo();
  }, []);

  const UploadToS3 = async (file, filename) => {
    try {
      const resp = await Storage.put(`userprofile/${filename}.png`, file, {
        contentType: file.type,
      });
      setNoImage(false);
      message.success("Succefully uploaded user profile.");
      return resp;
    } catch (error) {
      message.error("Something went wrong!");
      console.log("UploadToS3: Error while upload image", error);
      return null;
    }
  };

  const onUploadProfileImage = async (file) => {
    const userId = userInfo?.username;
    if (file && userId) {
      try {
        setImageLoader(true);
        const validExtensions = ["png", "jpg", "jpeg"];
        const extension = file.name.split(".")?.pop()?.toLowerCase();
        if (!validExtensions.includes(extension) > 0) {
          message.error(
            `${file.name} is not a valid file type, valid file types are png, jpg, jpeg`
          );
          return false;
        }
        const uploadedImage = await UploadToS3(file, userId);

        if (uploadedImage?.key) {
          getProfileImage(userId);
        }
        return false;
      } catch (error) {
        message.error(`Some problem occured while uploading image ${error}`);
        return false;
      } finally {
        setImageLoader(false);
      }
    }
  };

  const submitUserAttribute = async () => {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      name: fields[0].value,
      phone_number: fields[1].value + fields[2].value,
    });
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      setSubmitLoader(true);
      await submitUserAttribute();
      message.success("User information updated successfully.");
    } catch (e) {
      if (e.message) {
        message.error(`Please check the error: ${e}`);
      }
    } finally {
      setSubmitLoader(false);
    }
  };

  const updateFieldState = (newFields) => {
    setFields(newFields);
  };

  const confirm = (e) => {
    console.log(e);
    const resp = deleteLogo();
    if (resp) {
      message.success("Logo removed successfully. Please wait for refresh…");
      setNoImage(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const deleteLogo = async () => {
    await Storage.remove(`userprofile/${userInfo?.username}.png`);
  };

  const onError = () => {
    setNoImage(true);
  };

  return (
    <FormWrapper>
      {changePasswordModalVisible && (
        <ChangePasswordModal
          visible={changePasswordModalVisible}
          onFinish={() => setChangePasswordModalVisible(false)}
        />
      )}
      <Spin spinning={loadingUserInfo}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card
              style={{
                backgroundColor: "#2d273f",
                padding: "20px",
              }}
              bodyStyle={{
                padding: "0px",
              }}
            >
              <Title level={4} className="color-white">
                Profile
              </Title>
              <small className="color-white">Profile Photo</small>
              <div style={{ display: "flex", paddingTop: "20px" }}>
                <Row gutter={[16]}>
                  <Col span={10} style={{ display: "flex" }}>
                    <ProfilePhotoWrap className="color-white">
                      <div className="color-white profileContaner">
                        <img src={avatarUrl} onError={onError} />
                      </div>
                    </ProfilePhotoWrap>
                  </Col>
                  <Col span={6} style={{ margin: "auto" }}>
                    <Upload
                      customRequest={({ file, onSuccess }) => {
                        onSuccess("ok");
                      }}
                      beforeUpload={onUploadProfileImage}
                      fileList={[]}
                      className="color-white"
                      multiple={false}
                    >
                      <StyledButton
                        type="custom"
                        hovercolor="#A68DFB"
                        hoverbgcolor="transparent"
                        bgcolor="transparent"
                        bordercolor="transparent"
                        color="#fff"
                        loading={imageLoader}
                      >
                        Update
                      </StyledButton>
                    </Upload>
                  </Col>
                  <Col span={6} style={{ margin: "auto" }}>
                    {!noImage && (
                      <Popconfirm
                        title="Are you sure to delete this logo?"
                        onConfirm={confirm}
                        onCancel={() => message.error("Logo not removed")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <StyledButton
                          type="custom"
                          hovercolor="#A68DFB"
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="#fff"
                        >
                          Remove
                        </StyledButton>
                      </Popconfirm>
                    )}
                  </Col>
                </Row>
              </div>
              <Form
                labelCol={layout.labelCol}
                wrapperCol={layout.wrapperCol}
                form={form}
                name="edit_account_info"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                  updateFieldState(allFields);
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: "Name is required" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="phone" label="Phone">
                      <Input addonBefore={prefixSelector} type="tel" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="language" label="Interface Language">
                      <Select defaultValue={"en"}>
                        <Option value="en" key="en">
                          <img
                            src="/flags/united_states_of_america_usa.png"
                            width="28"
                          />{" "}
                          English
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ display: "flex", margin: "auto" }}>
                    <Form.Item name="" label="Email Address">
                      <p className="color-white">
                        <MailOutlined />{" "}
                        {userInfo?.email ? userInfo?.email : ""}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Divider
                      style={{ backgroundColor: "#4F4669", margin: "8px 0" }}
                    />
                    <Form.Item>
                      <StyledButton
                        type="custom"
                        onClick={() => navigate("/management")}
                        hovercolor="#A68DFB"
                        hoverbgcolor="transparent"
                        bgcolor="transparent"
                        bordercolor="transparent"
                        color="#fff"
                      >
                        <span>Cancel</span>
                      </StyledButton>
                      <StyledButton
                        loading={submitLoader}
                        type="custom"
                        onClick={() => handleOk()}
                      >
                        Submit
                      </StyledButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              style={{
                backgroundColor: "#2d273f",
                padding: "20px",
              }}
              bodyStyle={{
                padding: "0px",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title level={5} className="color-white">
                    Change your password
                  </Title>
                  <small className="color-grey">
                    Change your password at any time
                  </small>
                </Col>
                <Col span={24}>
                  <StyledButton
                    type="custom"
                    style={{ margin: 0 }}
                    onClick={() => setChangePasswordModalVisible(true)}
                  >
                    Change Password
                  </StyledButton>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </FormWrapper>
  );
};

export default ProfileDetail;
