import React from "react";
import { useBoolean } from "ahooks";
import { stripHtmlTags } from "../../../../shared/commonFunctions";

interface RichTextColumnProps {
  data: DataSheetRecord;
  fieldName: string;
}

export const RichTextColumn = ({ data, fieldName }: RichTextColumnProps) => {
  const [isAllDescVisible, { toggle: toggleAllDescVisible }] =
    useBoolean(false);

  return (
    <>
      <span
        style={
          !isAllDescVisible
            ? {}
            : {
                overflow: "hidden",
                textOverflow: "ellipsis",
              }
        }
      >
        <>
          {isAllDescVisible ? (
            <span
              dangerouslySetInnerHTML={{
                __html: data[data.sheet_name][fieldName] || "",
              }}
            />
          ) : (
            stripHtmlTags(data[data.sheet_name][fieldName] || "").slice(0, 200)
          )}{" "}
          {stripHtmlTags(data[data.sheet_name][fieldName] || "").length >
            200 && (
            <span
              onClick={() => toggleAllDescVisible()}
              style={{ color: "#A68DFB", cursor: "pointer" }}
            >
              {isAllDescVisible ? " Show less" : " Read more"}
            </span>
          )}
        </>
      </span>
    </>
  );
};
