import React, { useState } from "react";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AddUnitGroupModal } from "./Components/AddUnitGroupModal";
import styles from "./UnitsManagement.module.scss";
import { ConfirmModal } from "../../carbonPage/Calculator/Components/ConfirmModal";
import { observer } from "mobx-react-lite";
import { unitsManagementStore } from "./UnitsManagementStore";

interface UnitsManagementButtonsProps {
  data: UnitGroup;
}

export const UnitsManagementButtons = observer(
  ({ data }: UnitsManagementButtonsProps) => {
    const [isEditUnitGroupModalVisible, setEditUnitGroupModalVisible] =
      useState(false);
    const [isDeleteUnitGroupModalVisible, setDeleteUnitGroupModalVisible] =
      useState(false);

    const infoConfirmModal = {
      title: `Are you sure you want to delete ${data.name}?`,
      description:
        "All information and units of this group will be lost. This action cannot be undone",
      confirmText: "Delete",
      cancelText: "Cancel",
    };

    const handleDeleteGroup = () => {
      unitsManagementStore.removeUnitGroup(data);
      setDeleteUnitGroupModalVisible(false);
    };

    return (
      <div className={styles.cardButtonWrap}>
        <Button className={styles.cardButton}>
          <Link to={`/data/units-management/${data.name}`} state={data.name}>
            <EyeOutlined className={styles.cardButtonIcon} />
            View units
          </Link>
        </Button>
        <Button
          className={styles.cardButton}
          onClick={() => setEditUnitGroupModalVisible(true)}
          disabled={!data.is_custom}
        >
          <EditOutlined className={styles.cardButtonIcon} />
          Edit
        </Button>
        <Button
          className={styles.cardButton}
          disabled={!data.is_custom}
          onClick={() => setDeleteUnitGroupModalVisible(true)}
        >
          <DeleteOutlined />
        </Button>
        <AddUnitGroupModal
          visible={isEditUnitGroupModalVisible}
          onClose={() => setEditUnitGroupModalVisible(false)}
          data={data}
        />
        <ConfirmModal
          onConfirm={handleDeleteGroup}
          visible={isDeleteUnitGroupModalVisible}
          setFalse={() => setDeleteUnitGroupModalVisible(false)}
          infoConfirmModal={infoConfirmModal}
        />
      </div>
    );
  }
);
