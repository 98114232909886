import React from "react";
import { useBoolean } from "ahooks";
import { Button, Drawer } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { EmissionInfo } from "../Calculator/Components/InfoAndNotesDrawer/EmissionInfo";
import styles from "./EmissionInfoButton.module.scss";
interface EmissionInfoButtonProps {
  data: Emission;
}

const EmissionInfoButton = ({ data }: EmissionInfoButtonProps) => {
  const [
    drawerVisible,
    { setTrue: setDrawerVisible, setFalse: setDrawerHidden },
  ] = useBoolean(false);

  return (
    <div className={styles.btnIconsWrap}>
      <Button onClick={setDrawerVisible} className={styles.btnIcon}>
        <EyeOutlined />
      </Button>
      <Drawer
        width={400}
        closable={false}
        placement="right"
        onClose={setDrawerHidden}
        visible={drawerVisible}
        className={styles.drawer}
      >
        <h2 className={styles.drawerTitle}>{data.factor_data.name}</h2>
        <EmissionInfo emission={data} onClose={setDrawerHidden} />
      </Drawer>
    </div>
  );
};

export default EmissionInfoButton;
