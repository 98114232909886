import { API, graphqlOperation } from "aws-amplify";
import { datalake, datalake_job } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { DatalakeQuery } from "../graphql/API";
import * as queries from "../graphql/queries";
import { calculatorStore } from "../pages/Carbon/CalculatorStore";

class CalculatorService {
  async makeRequest(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    return JSON.parse(response.data?.datalake || "{}");
  }
  async makeRequestJob(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake_job, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    const resp = response?.data?.datalake_job;
    const responseMatch = resp?.match(/job_id=([^;\n]+)/);
    const responseName = responseMatch
      ? responseMatch[1]?.replace(/}/g, "")
      : null;
    return responseName;
  }

  async makeQuery(query: string, variables?: any) {
    return (await API.graphql({
      query: queries[query as keyof typeof queries],
      variables: variables,
    })) as GraphQLResult<any>;
  }

  public async getCalculationById(id: string) {
    return await this.makeRequest("get_calculation", {
      id: id,
    });
  }
  public async getCalculationItems(payload?: PayloadFilters) {
    return await this.makeRequest("list_calculation_items", payload);
  }
  public async updateCalculation(
    payload: ICalculation,
    applyDateFilters: boolean
  ) {
    return await this.makeRequest("full_update_calculation", {
      ...payload,
      ...(!!applyDateFilters && { apply_date_filters: applyDateFilters }),
    });
  }
  public async updateCalculationJob(
    payload: ICalculation,
    applyDateFilters: boolean
  ) {
    return await this.makeRequestJob("full_update_calculation", {
      ...payload,
      ...(!!applyDateFilters && { apply_date_filters: applyDateFilters }),
    });
  }
  public async partialUpdateCalculation(payload: ICalculation) {
    return await this.makeRequest("partial_update_calculation_new", {
      ...payload,
    });
  }

  public async addCalculationEntries(
    calculationId: string,
    entries: Emission[] | Offset[]
  ) {
    return await this.makeRequest("add_calculation_items", {
      calculation_id: calculationId,
      items: entries,
    });
  }

  public async updateCalculationEntry(
    entry: Emission | Offset,
    attribution_factor?: number
  ) {
    return await this.makeRequest("full_update_item", {
      ...entry,
      attribution_factor: attribution_factor ?? 100,
    });
  }
  public async deleteItems(payload: {
    items: string[];
    calculation_id: string;
  }) {
    return await this.makeRequest("remove_item", { ...payload });
  }

  public async partialUpdateCalculationEntry(entry: Emission | Offset) {
    return await this.makeRequest("partial_update_item", {
      ...entry,
    });
  }
  public async getAutomatedConsumptionAmount(emission: AutomatedEmission) {
    return await this.makeRequest(
      "calculate_automated_consumption_amount",
      emission
    );
  }

  public async aggregateRecordsForDrillDown(payload: any) {
    return await this.makeRequest("aggregate_records_for_drill_down", payload);
  }

  public async massAggregateRecordsForDrillDown(payload: any) {
    return await this.makeRequest(
      "mass_aggregate_records_for_drill_down",
      payload
    );
  }
  public async massAggregateRecordsForDrillDown_job(payload: any) {
    return await this.makeRequestJob(
      "mass_aggregate_records_for_drill_down",
      payload
    );
  }

  public async uploadOffsetProof(proofId: string, fileName: string) {
    const payload = {
      request_type: DataLakeFilesTypes.EMISSIONS_UPLOAD,
      data_id: proofId,
      version: 1,
      file_name: fileName,
    };
    const response = await this.makeQuery("emissionsFiles", payload);
    return response.data?.emissionsFiles;
  }

  public async downloadOffsetProof(proofId: string, fileName: string) {
    const payload = {
      request_type: DataLakeFilesTypes.EMISSIONS_DOWNLOAD,
      data_id: proofId,
      version: 1,
      file_name: fileName,
    };
    const response = await this.makeQuery("emissionsFiles", payload);
    return response.data?.emissionsFiles;
  }
}
export default new CalculatorService();
