import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getAnalyticsDashboardCharts = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_dashboard_charts",
    },
  });
  return JSON.parse(response.data.datalake);
};

export const insertAnalyticsDashboardCharts= async(requestPayload) => {   
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "add_dashboard_charts",
    'request_payload': JSON.stringify(requestPayload)
  }});
  return JSON.parse(response['data']['datalake']);
};

export const updateAnalyticsDashboardCharts= async(requestPayload) => {   
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "update_dashboard_charts",
    'request_payload': JSON.stringify(requestPayload)
  }});
 return JSON.parse(response['data']['datalake']);
};

export const deleteAnalyticsDashboardCharts= async(requestPayload) => {   
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "remove_dashboard_charts",
    'request_payload': JSON.stringify(requestPayload)
  }});
  return JSON.parse(response['data']['datalake']);
};

export const updateToggleStatus= async(requestPayload) => {   
  
  const response = await API.graphql({ query: queries["datalake"], variables:{
    'request_type': "update_dashboard_charts",
    'request_payload': JSON.stringify(requestPayload)
  }});

  return JSON.parse(response['data']['datalake']);
};


export const getUniqueChartCategory = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_dashboard_charts",
    },
  });

  return JSON.parse(response.data.datalake);
};