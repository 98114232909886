import React, { useEffect } from "react";
import { Button, Form, InputNumber, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormWrapper } from "../../../../../shared/commonStyles";
import styles from "./AddManualEmissionsModal.module.scss";

interface Step2AddManualEmissionsProps {
  selectedEmissions: ManualEmission[];
  calculatedBusinessEmission: {
    index: number;
    value: number;
  };
  removeEmission: (index: number) => void;
  replaceEmission: (index: number, item: ManualEmission) => void;
  openApproximateEmissionCalculator: (index: number) => void;
}

export const Step2AddManualEmissions = ({
  selectedEmissions,
  calculatedBusinessEmission,

  removeEmission,
  replaceEmission,
  openApproximateEmissionCalculator,
}: Step2AddManualEmissionsProps) => {
  useEffect(() => {
    replaceEmission(calculatedBusinessEmission.index, {
      ...selectedEmissions[calculatedBusinessEmission.index],
      consumption_amount: calculatedBusinessEmission.value,
    });
  }, [calculatedBusinessEmission]);

  const handleRemoveEmission = (index: number) => {
    removeEmission(index);
  };

  const handleConsumptionAmount = (value: number, index: number) => {
    replaceEmission(index, {
      ...selectedEmissions[index],
      consumption_amount: value,
    });
  };

  const getUnitByKey = (key: string) => {
    return selectedEmissions
      .find((item) => item.key === key)
      ?.factor_data?.unit.split("/")
      .at(-1);
  };

  const [form] = Form.useForm();

  return (
    <>
      <div>
        <div>Enter emission calculation for </div>
        <div className={styles.emissionCalc}>
          {selectedEmissions.map((value, index) => {
            return (
              <div key={value?.factor_data?.name}>
                {selectedEmissions.length === 1 ? (
                  <span
                    key={value?.factor_data?.name}
                    className={styles.emissionNameHeader}
                  >
                    {" "}
                    {value?.factor_data?.name}
                  </span>
                ) : (
                  <div
                    key={value?.factor_data?.name}
                    className={styles.someEmissionsName}
                  >
                    {value?.factor_data?.name}
                    <CloseOutlined
                      className={styles.removeIcon}
                      onClick={() => {
                        handleRemoveEmission(index);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Form
        layout="vertical"
        form={form}
        className={
          selectedEmissions.length === 1
            ? styles.addEmissionsForm
            : styles.someEmissionsForm
        }
      >
        <FormWrapper className={styles.inputWrap}>
          {selectedEmissions.map((value, index) => (
            <Form.Item
              key={value?.key}
              label={
                <Tooltip title={value?.factor_data?.name}>
                  <span className={styles.selectorLabel}>
                    {value?.factor_data?.name}
                  </span>
                </Tooltip>
              }
            >
              <InputNumber
                addonBefore={getUnitByKey(value.key!)}
                className={styles.inputCalc}
                min={0}
                value={value.consumption_amount}
                onChange={(value) => handleConsumptionAmount(value || 0, index)}
              />
              {/* Approximate emission calculation for business travel */}
              {/* {value.name.includes("Business travel") && (
                <>
                  <div>or</div>
                  <Button
                    className={styles.approximateEmissionButton}
                    onClick={() => openApproximateEmissionCalculator(index)}
                  >
                    Calculate the approximate emission
                  </Button>
                </>
              )} */}
            </Form.Item>
          ))}
        </FormWrapper>
      </Form>
    </>
  );
};
