import React from "react";
import { Button, Typography } from "antd";
import { CommonModal } from "../../../../../shared/commonStyles";

interface ConfirmModalProps {
  onConfirm: () => void;
  visible: boolean;
  setFalse: () => void;
  infoConfirmModal: {
    title: string;
    description: string;
    confirmText: string;
    cancelText: string;
  };
}

export const ConfirmModal = ({
  onConfirm,
  visible,
  setFalse,
  infoConfirmModal,
}: ConfirmModalProps) => {
  return (
    <CommonModal
      visible={visible}
      title="Confirmation"
      onCancel={setFalse}
      onOk={onConfirm}
      footer={[
        <Button key="cancel" type="default" onClick={setFalse}>
          {infoConfirmModal.cancelText}
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          {infoConfirmModal.confirmText}
        </Button>,
      ]}
      centered
    >
      <div>
        <Typography.Text>{infoConfirmModal.title}</Typography.Text>
      </div>
      <div>
        <Typography.Text>{infoConfirmModal.description}</Typography.Text>
      </div>
    </CommonModal>
  );
};
