import React, { useEffect, useState } from "react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import { message, Spin } from "antd";
import moment from "moment";
import { PageWrap } from "../../../shared/commonStyles";
import { Core } from "@grapecity/activereports";
import {
  Viewer as ReportViewer,
  Designer,
} from "@grapecity/activereports-react";
import { useLocation } from "react-router-dom";
import { dataSource } from "./Component/dataSource";
import {
  addSavedReports,
  getReportById,
  updateSavedReports,
} from "../../../services/reports";
import ConfirmModal from "./Component/ConfirmModal";

Core.setLicenseKey(process.env.REACT_APP_AR_LICENSE_KEY);

const ReportTemplate = () => {
  const { state } = useLocation();
  const [templateType, setTemplateType] = useState(state?.type);
  const [designerVisible, setDesignerVisible] = React.useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [saveAsData, setSaveAsData] = useState();
  const [reportId, setReportId] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [loader, setLoader] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);
  const [ehsLoader, setEhsLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });
  const viewerRef = React.useRef();

  const getReportData = async () => {
    try {
      setReportLoader(true);
      const selectedReport = await getReportById(reportId);
      setSelectedReport(selectedReport);
    } catch (error) {
      message.error(`Error while getting reports : ${error}`);
    } finally {
      setReportLoader(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      getReportData();
    }
  }, [reportId]);

  useEffect(() => {
    if (state?.type) {
      setTemplateType(state?.type);
    }
  }, [state]);

  const updateReport = async (info) => {
    try {
      setReportId("");
      setReportLoader(true);
      const payload = {
        _id: { $oid: info.id },
        report_data: info,
      };
      const response = await updateSavedReports(payload);
      if (response) {
        setReportId(info.id);
      }
    } catch (error) {
      console.log("Error while saving report !", error);
    } finally {
      setReportLoader(false);
    }
  };

  const onSaveReport = (info) => {
    return updateReport(info);
  };
  const createNewReport = async (info) => {
    try {
      setLoader(true);

      let response = null;
      if (info?.id) {
        setReportId("");
        const payload = {
          _id: { $oid: info.id },
          report_data: info,
        };
        response = await updateSavedReports(payload);
        setReportId(info?.id);
      } else {
        const payload = {
          created_date: moment().format(),
          report_data: info,
        };
        response = await addSavedReports(payload);
        setReportId(response?.$oid);
      }
    } catch (error) {
      console.log("Error while saving report !", error);
    } finally {
      setLoader(false);
      setShowConfirmationModal(false);
    }
  };
  const onSaveAsReport = (info) => {
    setShowConfirmationModal(true);
    setSaveAsData(info);
  };

  const confirmSaveAsReport = () => {
    if (!saveAsData) return;
    const { name, description } = initialValues;
    if (name !== null) {
      saveAsData.displayName = name !== "" ? name : "Untitled";
      saveAsData.description = description;
      return createNewReport(saveAsData);
    }
  };

  function updateToolbar() {
    const designButton = {
      key: "$openDesigner",
      text: "Edit in Designer",
      iconCssClass: "mdi mdi-pencil",
      enabled: true,
      action: () => {
        setDesignerVisible(true);
      },
    };
    viewerRef.current.toolbar.addItem(designButton);
    viewerRef.current.toolbar.updateLayout({
      default: [
        "$openDesigner",
        "$split",
        "$navigation",
        "$split",
        "$refresh",
        "$split",
        "$history",
        "$split",
        "$zoom",
        "$fullscreen",
        "$split",
        "$print",
        "$split",
        "$singlepagemode",
        "$continuousmode",
        "$galleymode",
      ],
    });
  }
  function onReportPreview(report) {
    updateToolbar();
    setDesignerVisible(false);
    viewerRef.current.open(report.definition);
    return Promise.resolve();
  }

  const dataSource1 = dataSource(templateType, setEhsLoader);
  return (
    <PageWrap>
      <Spin spinning={templateType === "ehs_report" ? ehsLoader : reportLoader}>
        <div style={{ width: "100%", height: "80vh" }}>
          <>
            {templateType === "custom" && (
              <>
                <div
                  id="viewer-host"
                  style={{
                    display: designerVisible ? "none" : "block",
                    height: "100%",
                  }}
                >
                  <ReportViewer ref={viewerRef} />
                </div>
                <div
                  id="designer-host"
                  style={{
                    display: designerVisible ? "block" : "none",
                    height: "100%",
                  }}
                >
                  <Designer
                    report={
                      selectedReport
                        ? {
                            definition:
                              selectedReport?.report_data?.definition || "",
                            displayName:
                              selectedReport?.report_data?.displayName || "",
                            id: selectedReport?._id?.$oid,
                          }
                        : {}
                    }
                    onSave={onSaveReport}
                    onSaveAs={onSaveAsReport}
                    onRender={onReportPreview}
                  />
                </div>
              </>
            )}
            {templateType === "default" && (
              <>
                <div
                  id="viewer-host"
                  style={{
                    display: designerVisible ? "none" : "block",
                    height: "100%",
                  }}
                >
                  <ReportViewer viewMode="Continuous" ref={viewerRef} />
                </div>
                <div
                  id="designer-host"
                  style={{
                    display: designerVisible ? "block" : "none",
                    height: "100%",
                  }}
                >
                  <Designer
                    report={{
                      definition:
                        selectedReport?.report_data?.definition || dataSource1,
                      displayName:
                        selectedReport?.report_data?.displayName ||
                        "Default Report template",
                      id: selectedReport?._id?.$oid,
                    }}
                    onSave={onSaveReport}
                    onSaveAs={onSaveAsReport}
                    onRender={onReportPreview}
                  />
                </div>
              </>
            )}
            {templateType === "ehs_report" && (
              <>
                <div
                  id="viewer-host"
                  style={{
                    display: designerVisible ? "none" : "block",
                    height: "100%",
                  }}
                >
                  <ReportViewer viewMode="Continuous" ref={viewerRef} />
                </div>
                <div
                  id="designer-host"
                  style={{
                    display: designerVisible ? "block" : "none",
                    height: "100%",
                  }}
                >
                  <Designer
                    report={{
                      definition: dataSource1,
                      displayName: "EHS Report",
                      id: selectedReport?._id?.$oid,
                    }}
                    onSave={onSaveReport}
                    onSaveAs={onSaveAsReport}
                    onRender={onReportPreview}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </Spin>
      <ConfirmModal
        isVisible={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSave={confirmSaveAsReport}
        saveAsData={saveAsData}
        setInitialValues={setInitialValues}
        loader={loader}
      />
    </PageWrap>
  );
};

export default ReportTemplate;
