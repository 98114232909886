export const queryOperators = [
  { value: "$eq", label: "Equals" },
  { value: "$gt", label: "Greater than" },
  { value: "$lt", label: "Less than" },
  { value: "$gte", label: "Greater than or equal to" },
  { value: "$lte", label: "Less than or equal to" },
  { value: "$ne", label: "Not equal to" },
];

export const additionalQueryOperators = [
  { value: "$in", label: "Includes some of" },
  { value: "$all", label: "Includes all" },
  { value: "$regex", label: "Contains" },
  { value: "doesNotContain", label: "Does not contain" },
  { value: "isEmpty", label: "Is Empty" },
  { value: "isNotEmpty", label: "Is not Empty" },
];

export const aggregationOperators = [
  { value: "stdDevSamp", label: "Standard Deviation" },
  { value: "min", label: "Minimum" },
  { value: "max", label: "Maximum" },
  { value: "avg", label: "Average" },
  { value: "sum", label: "Sum" },
  { value: "count", label: "Count" },
  { value: "distinct", label: "Distinct" },
];

export const mathOperators = [
  { value: "$add", label: "Add" },
  { value: "$subtract", label: "Subtract" },
  { value: "$multiply", label: "Multiply" },
  { value: "$divide", label: "Divide" },
];

export const mathSymbolsOperators = [
  { value: "$add", label: "+" },
  { value: "$subtract", label: "-" },
  { value: "$multiply", label: "*" },
  { value: "$divide", label: "/" },
];

export const comparisonOperators = [
  { value: "$eq", label: "=" },
  { value: "$ne", label: "≠" },
  { value: "$gt", label: ">" },
  { value: "$gte", label: ">=" },
  { value: "$lt", label: "<" },
  { value: "$lte", label: "<=" },
];
