import React from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";

import { Form, Col, Typography } from "antd";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { TemplateSheetSelection } from "../StandardsSheetComponents/StandardsSelection";

interface TemplateSelectionProps {
  initialValues: DataSheet;
  onClearSheetType: () => void;
  onClickPrevious: () => void;
  onChangeSheetTypeTemplate: (value: string, sheetSchema?: any[]) => void;
  onClickSelectTemplateSheetType: () => Promise<void>;
}

const TemplateSelection = ({
  onClickPrevious,
  initialValues,
  onChangeSheetTypeTemplate,
  onClickSelectTemplateSheetType,
}: TemplateSelectionProps) => {
  const { sheet_type } = initialValues;

  const handleChangeTemplate = (template: DataSheet) => {
    onChangeSheetTypeTemplate(template.sheet_name, template?.sheet_schema);
  };

  return (
    <FormWrap width={"80%"}>
      <TemplateSheetSelection
        initialValues={initialValues}
        onSelectTemplate={handleChangeTemplate}
      />

      <Col span={24}>
        <Form.Item>
          <StyledButton onClick={() => onClickPrevious()} type="custom">
            <LeftOutlined /> <span>Back</span>
          </StyledButton>

          <StyledButton
            disabled={!sheet_type}
            onClick={() => onClickSelectTemplateSheetType()}
            type="custom"
            htmlType="submit"
          >
            <span>Continue to step 2</span> <RightOutlined />
          </StyledButton>
        </Form.Item>
      </Col>
    </FormWrap>
  );
};

export default TemplateSelection;
