import React, { useState, useEffect } from "react";
import { StyledButton } from "../../../shared/commonStyles";
import { FileOutlined, ShareAltOutlined, EditOutlined } from "@ant-design/icons";
import { Row } from "antd";
import DashboardButtonModal from "./Component/DashboardButtonModal";
import { getCompanyInfo } from "../../../services/companyInfo";
import { API, graphqlOperation } from "aws-amplify";
import { datalake } from "../../../graphql/queries";

const DashboardButtons = ({chartId}) => {

  const [shareModal, setShareModal] = useState(false);
  const [selectedDashboardData, setSelectedDashbaordData] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const { id } = chartId;

  const loadCompanyInfo = async () => {
    const baseUrlResponse = await getCompanyInfo();
    if (baseUrlResponse) {
      setCompanyDetails(JSON.parse(baseUrlResponse["data"]));
    }
  };
  
  const loadData = async (chartId) => {
    try {
      const response = await API.graphql(
        graphqlOperation(datalake, {
          request_type: "get_dashboard_charts",
        })
      );
      const records = JSON.parse(response["data"]["datalake"]);
      if (records?.data?.length) {
        const findRecord = records.data.find((item) => item._id.$oid === chartId);
        findRecord && setSelectedDashbaordData(findRecord);
      }
    } catch (error) {
      console.log("Error loading the chart information");
    }
  };
   
  useEffect(() => {
    loadCompanyInfo();
  }, []);
  
  useEffect(() => {
    setDashboardLoading(true);
    if (chartId) {
      loadData(chartId).catch((error) => {
        console.log(error);
        message.error(error);
      });
    }
    setDashboardLoading(false);
  }, [id]);  

  return (
    <>
    <DashboardButtonModal
        isVisible={shareModal}
        onClose={() => setShareModal(false)}
        chartId={chartId}
      />
      <Row justify="end">
      {companyDetails?.chart_base_url && selectedDashboardData?.chart_id ? (
        <>
          <a href={`${companyDetails.chart_base_url}/dashboards/${selectedDashboardData.chart_id}`} target="_blank">
            <StyledButton type="primary">
              <EditOutlined /> <span>Modify Dashboard</span>
            </StyledButton>
          </a>
          <a href={`${companyDetails.chart_base_url}/dashboards/${selectedDashboardData.chart_id}`} target="_blank">
            <StyledButton type="primary">
              <FileOutlined /> <span>Export Dashboard</span>
            </StyledButton>
          </a>
        </>
      ) : (
        <>
          <StyledButton type="primary" disabled>
            <EditOutlined /> <span>Modify Dashboard</span>
          </StyledButton>
          <StyledButton type="primary" disabled>
            <FileOutlined /> <span>Export Dashboard</span>
          </StyledButton>
        </>
      )}
        <StyledButton type="primary" onClick={() => setShareModal(true)}>
          <ShareAltOutlined /> <span>Share</span>
        </StyledButton>
      </Row>
    </>
  );
};

export default DashboardButtons;
