import { FormWrap, FormWrapper, PageWrap } from "../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import {
  getSelectedStandardById,
  parsedStandardData,
} from "../../services/standards";

import DataLakeMapDataForm from "./../UpdateStandard/components/DataLakeMapDataForm";
import { getAuditManagements } from "../../services/audits";
import { useParams } from "react-router-dom";

const UpdateAuditStandard = () => {
  const { audit_id: auditId, standard_id: standardId } = useParams();
  const [loadStandardData, setLoadStandardData] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [auditData, setAuditData] = useState(null);
  const [onRefreshData, setOnRefreshData] = useState(0);


  const refreshOnSubmit = () => {
    setOnRefreshData(onRefreshData + 1);
  };

  const loadData = async () => {
    try {
      setLoadStandardData(true);
      const auditData = await getAuditManagements(auditId);
      if (auditData) {
        setAuditData(auditData);
        const standardIds = JSON.parse(auditData?.audit_items);
        const selectedStandardId = standardIds?.find(
          (item) => item === standardId
        );
        if (selectedStandardId) {
          const standardData = await getSelectedStandardById(
            selectedStandardId
          );
          const parsedData = await parsedStandardData(standardData);
          setRowData(parsedData);
        }
      }
    } catch (error) {
      message.error("Error while loading Standard Form!");
    } finally {
      setLoadStandardData(false);
    }
  };

  useEffect(() => {
    if (auditId && standardId) {
      loadData();
    }
  }, [auditId, standardId, onRefreshData]);
  
  return (
    <PageWrap>
      <Spin spinning={loadStandardData}>
        {rowData && (
          <FormWrapper>
            <FormWrap width="100%" style={{ padding: 0 }}>
              <DataLakeMapDataForm
                rowData={rowData}
                auditData={auditData}
                refreshOnSubmit={refreshOnSubmit}
                pageType="audit"
              />
            </FormWrap>
          </FormWrapper>
        )}
      </Spin>
    </PageWrap>
  );
};

export default UpdateAuditStandard;
