import React, { useEffect, useState } from "react";
import moment from "moment";
import { Spin, Row, Typography, Popover } from "antd";

import { YearMonthDayFormat } from "../../../shared/constants";
import { fetchRecordData, makeQuery } from "../../../services/dataSheet";
import { CommonTable } from "../../../shared/commonStyles";
import _ from "lodash";
import { stripHtmlTags } from "../../../shared/commonFunctions";

const { Title } = Typography;

export const DataSheetDocuments = ({
  dataSheet,
  loadingDataSheet,
  initialValues,
}) => {
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(false);
  const [orgDataSheetRecords, setOrgDataSheetRecords] = useState([]);
  const [dataSheetRecords, setDataSheetRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsTypes = {
    include: [],
    exclude: ["archived", "imported_pending", "pending"],
  };
  const loadDataSheetRecords = async () => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = makeQuery(
        { conditionalFilters: initialValues?.conditional_filters },
        dataSheet,
        recordsTypes
      );
      const sheetRecords = await fetchRecordData(requestPayload);
      setTotalRecords(sheetRecords.filteredCount);

      const _dataSheetRecords = sheetRecords?.data?.map((record) => ({
        ...record,
        key: record._id.$oid,
      }));
      setOrgDataSheetRecords(_dataSheetRecords);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSheetRecords(false);
    }
  };
  const updateDataSheetRecords = async () => {
    if (!dataSheet.sheet_schema) {
      return;
    }
    setLoadingSheetRecords(true);
    const schemas = {};
    dataSheet.sheet_schema.forEach((schema) => {
      schemas[schema.entity_name] = schema.unit_id;
    });

    const tempRecords = await Promise.all(
      orgDataSheetRecords?.map(async (record) => {
        const records = record[record.sheet_name];
        let temp = {};
        Object.keys(records || {}).forEach((key) => {
          if (schemas[key]) {
            temp[`${key}_unit_id`] = schemas[key];
          }
        });

        temp = {
          ...temp,
          ...records,
        };

        return {
          ...record,
          [record.sheet_name]: temp,
        };
      }) || []
    );
    setDataSheetRecords(tempRecords);
    setLoadingSheetRecords(false);
  };
  useEffect(() => {
    if (dataSheet) {
      loadDataSheetRecords();
    }
  }, [dataSheet]);
  useEffect(() => {
    if (dataSheet && dataSheet.sheet_schema) {
      updateDataSheetRecords();
    }
  }, [dataSheet, dataSheet.sheet_schema, orgDataSheetRecords]);

  useEffect(() => {
    dataSheet && loadDataSheetRecords();
  }, [initialValues?.conditional_filters]);

  const generateDynamicColumns = (dataSheetSchema) => {
    if (dataSheetSchema?.length > 0) {
      const columns = dataSheetSchema?.reduce((acc, item) => {
        const column = [];

        column.push({
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: 250,
          ellipsis: "false",
          editable: "true",

          render: (_, data) => {
            try {
              if (item?.input_type === "date") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                let date;
                if (typeof value === "object" && value !== null) {
                  date =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value.$date).format(YearMonthDayFormat);
                } else {
                  date =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value).format(YearMonthDayFormat);
                }

                return date;
              } else if (item?.input_type === "checkbox") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                let newValue = "";
                if (Array.isArray(value)) {
                  newValue = value?.toString();
                } else {
                  newValue = value ? "TRUE" : "FALSE";
                }
                return newValue;
              } else {
                return data?.[data?.sheet_name]?.[item?.entity_name];
              }
            } catch (error) {
              console.log("error", error);
              return "";
            }
          },
        });

        if (item.unit_id) {
          column.push({
            title: item.display_name + " Unit",
            dataIndex: item.entity_name + "_unit_id",
            width: "100px",
            editable: false,
            render: (_, data) => {
              try {
                const value =
                  data?.[data?.sheet_name]?.[item.entity_name + "_unit_id"];
                return value ? value : null;
              } catch (error) {
                console.log("error", error);
                return "";
              }
            },
          });
        }
        return acc.concat(column);
      }, []);

      return columns;
    }
    return [];
  };
  const generateColumns = generateDynamicColumns(dataSheet?.sheet_schema);

  return (
    <Spin spinning={loadingDataSheet}>
      {dataSheet && (
        <CommonTable
          title={() => (
            <Row justify="space-between" wrap={false}>
              <Popover
                overlayClassName="popoverContent"
                placement="bottomLeft"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSheet.description,
                    }}
                  />
                }
              >
                <Typography.Title
                  className="color-white"
                  level={4}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {stripHtmlTags(dataSheet.description)}
                </Typography.Title>
              </Popover>
              <span
                style={{
                  color: "#C1BFCD",
                  alignItems: "baseline",
                  padding: "5px 16px",
                  whiteSpace: "nowrap",
                }}
              >
                ({totalRecords} records)
              </span>
            </Row>
          )}
          loading={loadingSheetRecords}
          rowKey={(data) => data?._id?.$oid}
          dataSource={dataSheetRecords}
          columns={generateColumns}
          rowClassName="dataSheetsTable"
          scroll={{ x: "max-content" }}
          pagination={false}
          className="tableUpperScroll"
        />
      )}
    </Spin>
  );
};
