import { ArrowsAltOutlined, RobotOutlined, RadiusSettingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CardsWrap, ContentSection } from "../../shared/commonStyles";
import { useNavigate } from "react-router-dom";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { Typography } from "antd";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { COLORS } from "../../shared/colors";
const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

export const ToolsPage = () => {
  const navigate = useNavigate();
  const { checkPermissions } = useCheckRolesPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);

  const popularActions = [
    {
      permission: "tools-page",
      cardTitle: "AI",
      cardIcon: <RobotOutlined />,
      iconColor: "linear-gradient(180deg, #FC7786 0%, #E66270 100%)",
      cardLink: "/tools/ai",
      type: "ai",
      moduleName: "REPORTING_STANDARDS",
    },
    {
      permission: "tools-page",
      cardTitle: "Improve ESG Score",
      cardIcon: <ArrowsAltOutlined />,
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/tools/esg-handbook",
      type: "ai",
      moduleName: "REPORTING_STANDARDS",
    },
    {
      permission: "tools-page",
      cardTitle: "AI Recommendations",
      cardIcon: <RadiusSettingOutlined />,
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/tools/improvement-recommedations",
      type: "ai",
      moduleName: "REPORTING_STANDARDS",
    },
  ];
  useEffect(() => {
    popularActions.map((card) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);
  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/tools/esg-handbook":
        navigate(section);
        break;
      case "/tools/ai":
        navigate(section);
        break;
      case "/tools/improvement-recommedations":
        navigate(section);
        break;
      default:
    }
  };

  return (
    <ContentSection>
      {popularActions.length > 0 && (
        <>
          {showPopluarActions && (
            <Typography.Title
              level={2}
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Popular Actions
            </Typography.Title>
          )}
          <CardsWrap>
            {popularActions.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <PopularActionCard
                    title={card.cardTitle}
                    iconColor={card.iconColor}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}
    </ContentSection>
  );
};
