import React, { useEffect, useState } from "react";
import { Row, Switch, message, Col, Space } from "antd";
import moment from "moment";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  StyledButton,
  CommonTable, 
} from "../../../../shared/commonStyles";
import FilterModalCommon from "../../../../components/Modals/CreateFilterModal/FilterModalCommon";
import {
  getReportFilters,
  removeReportFilters,
  updateReportFilters,
} from "../../../../services/filter";
import ChangeStatus from "./ChangeStatus";
import ConfirmModal from "../../../../components/Modals/CreateFilterModal/ConfirmModal";

const ReportingPeriodDataTable = ({
  section
}) => {
  const [rowData, setRowData] = useState(null);
  const [showDeleteChartModal, setShowDeleteChartModal] = useState(false);
  const [showReportPeriodModal, setShowReportPeriodModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [timePeriodsData, setTimePeriods] = useState(null);
  const [submitStatusLoader, setSubmitStatusLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [title, setTitle] = useState(); 

  const refreshReporting = async () => {
    try{
      setTableLoader(true)
      const payload = { filter: { filter_type: "reporting_period" } };
      const newResponse = await getReportFilters(payload);
      return setTimePeriods(newResponse);
    }finally{
      setTableLoader(false)
    }
  };

  useEffect(() => {
    refreshReporting()
  }, []);

  const onClickEdit = (record) => {
    setRowData(record);
    setShowReportPeriodModal(true);
  };
  const onClickDelete = (record) => {
    setRowData(record["_id"]);
    setShowDeleteChartModal(true);
  };
  const onChangeStatus = async (checked, record) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      setSubmitStatusLoader(true);
      const payload = {
        ...record,
        status,
      };

      const response = await updateReportFilters(payload);
      if (response) {
        refreshReporting();        
        message.success("Status updated successfully!");
      } 
     
    } catch (error) {
      message.error("Something went wrong updating the status.");
      console.log(error);
    }finally{
      setSubmitStatusLoader(false);
    }
  };

  const parseDate = (date) => moment(date, YearMonthDayFormat);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "25%",
    },
    {
      title: "Start Date",
      dataIndex: "date_start",
      width: "15%",
      sorter: (a, b) =>
        parseDate(b.date_start) > parseDate(a.date_start) ? 1 : -1,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      width: "15%",
      sorter: (a, b) =>
        parseDate(b.date_end) > parseDate(a.date_end) ? 1 : -1,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "30%",
      render: (_, rowData) => (
        <ChangeStatus rowData={rowData} onChangeStatus={onChangeStatus} /> 
      ),
      sorter: (a, b) =>
        a?.status && b?.status && b.status.localeCompare(a.status),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      key: "operation",
      width: "%",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton type="default" onClick={() => onClickEdit(record)}>
            <EditOutlined />
          </StyledButton>
          <StyledButton type="default" onClick={() => onClickDelete(record)}>
            <DeleteOutlined />
          </StyledButton>
        </Space>
      ),
    },
  ];  

  const onConfirmDeleteChart = async () => {
    try {
      setConfirmLoading(true);
      const payload = {
        _id: rowData,
      };

      const deleteReportingResponse = await removeReportFilters(payload);

      if (deleteReportingResponse) {
        const resp = await refreshReporting();
        if (resp) {
          message.success("Reporting period Deleted Successfully!");
        }
        setShowDeleteChartModal(false);
        setConfirmLoading(false);
      } else {
        message.error("Failed to delete reporting period!");
        console.log("Failed to update reporting period");
      }
    } catch (error) {
      console.log(
        "Something went wrong while updating reporting period!",
        error
      );
      message.error("Something went wrong while updating reporting period!");
    }
  };

  useEffect(() => {
    if (section === "reporting_period") {
      setTitle("reporting period");
    }
  }, [section]);

  return (
    <>
      <Row style={{ justifyContent: "flex-end" }}>
        <StyledButton
          className="margin-0"
          type="custom"
          onClick={() => setShowReportPeriodModal(true)}
        >
          <PlusOutlined />
          <span>Create New Report Period</span>
        </StyledButton>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <CommonTable
            loading={tableLoader || submitStatusLoader}
            rowKey={(data) => {
              return data?.["_id"]?.["$oid"];
            }}
            dataSource={timePeriodsData}
            columns={columns}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <FilterModalCommon
        visible={showReportPeriodModal}
        onClose={() => setShowReportPeriodModal(false)}
        rowData={rowData}
        section={section}
        refresh={() => refreshReporting()}
      />
      <ConfirmModal
        description={`Are you sure you want to delete this ${title} ?`}
        isVisible={showDeleteChartModal}
        onClose={() => setShowDeleteChartModal(false)}
        onConfirm={onConfirmDeleteChart}
        confirmLoading={confirmLoading}
      />
    </>
  );
};

export default ReportingPeriodDataTable;
