import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ESGTemplateModal from "./components/ESGTemplateModal";
import DataLakeMapTable from "./components/DataLakeMapTable";
import StandardCloneModal from "./components/StandardCloneModal";
import StandardRecategorizeModal from "./components/StandardRecategorizeModal";
import RecategorizeSingleDataModal from "./components/RecategorizeSingleDataModal";
import ReportCenterPage from "../ReportCenter/ReportCenterPage";
import ReportTitleModal from "./components/ReportTitleModal";
import IndividualReport from "../ReportCenter/IndividualReport/IndividualReport";
import { encodeUrlName } from "../../../shared/commonFunctions";

const PoliciesPage = ({
  report_period,
  location_id,
  business_unit_id,
  locationsList,
  businessUnitsList,
  reportPeriodsList,
  isDeleted,
}) => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState(null);
  const [templateLibraryModal, setTemplateLibraryModal] = useState(false);
  const [selectedListReportDataLake, setSelectedListReportDataLake] = useState(
    []
  );
  const [selectedListReportIds, setSelectedListReportIds] = useState(null);

  const [refreshListReportDataLake, setRefreshListReportDataLake] = useState(1);
  const [showReportCenterPage, setShowReportCenterPage] = useState(false);

  const [showRecategorizeSingleDataModal, setShowRecategorizeSingleDataModal] =
    useState(false);
  const [showStandardRecategorizeModal, setShowStandardRecategorizeModal] =
    useState(false);
  const [showStandardCloneModal, setShowStandardCloneModal] = useState(false);
  const [showIndivisualReport, setShowIndivisualReport] = useState(false);
  const [showReportTitleModal, setShowReportTitleModal] = useState(false);
  const [reportContentProps, setReportContentProps] = useState(null);

  const onTableRowEdit = (record) => {
    const standardId = record?._id.$oid;
    if (standardId) {
      navigate(
        `/reporting/sustainability-standards/${standardId}/${encodeUrlName(
          record?.theme.replace("/", " ")
        )}/update`
      );
    }
  };

  const refreshReportDataLake = () => {
    setRefreshListReportDataLake(refreshListReportDataLake + 1);
  };
  useEffect(() => {
    const getDataIdsFromSelectedData = () => {
      const newArr = [];
      if (selectedListReportDataLake.length > 0) {
        selectedListReportDataLake.map((item) => {
          newArr.push(item?._id?.$oid);
        });
      }
      setSelectedListReportIds(newArr);
    };

    getDataIdsFromSelectedData();
  }, [selectedListReportDataLake]);

  const onClickRecategorizeStandards = () => {
    setShowStandardRecategorizeModal(true);
  };

  const onReportBtnClick = () => {
    setShowReportTitleModal(true);
  };
  const onClickCloneStandards = () => {
    setShowStandardCloneModal(true);
  };

  const onClickIndivisualReportViewBtn = (record) => {
    // code for show indivisual data lake report View
    setRowData(record);
    setShowIndivisualReport(true);
  };

  const onClickRecategorizeSingleData = (record) => {
    setRowData(record);
    setShowRecategorizeSingleDataModal(true);
  };

  const onReportTitleModalSubmit = (values) => {
    setReportContentProps(values);
    setShowReportTitleModal(false);
    setShowReportCenterPage(true);
  };
  return (
    <>
      {showStandardCloneModal && (
        <StandardCloneModal
          visible
          onClose={() => setShowStandardCloneModal(false)}
          selectedListReportDataLake={selectedListReportDataLake}
          refreshReportDataLake={refreshReportDataLake}
          locationsList={locationsList}
          businessUnitsList={businessUnitsList}
          reportPeriodsList={reportPeriodsList}
        />
      )}
      {showStandardRecategorizeModal && (
        <StandardRecategorizeModal
          visible
          onClose={() => setShowStandardRecategorizeModal(false)}
          selectedListReportDataLake={selectedListReportDataLake}
          refreshReportDataLake={refreshReportDataLake}
          locationsList={locationsList}
          businessUnitsList={businessUnitsList}
          reportPeriodsList={reportPeriodsList}
        />
      )}
      {showRecategorizeSingleDataModal && (
        <RecategorizeSingleDataModal
          visible
          onClose={() => setShowRecategorizeSingleDataModal(false)}
          refreshReportDataLake={refreshReportDataLake}
          rowData={rowData}
          locationsList={locationsList}
          businessUnitsList={businessUnitsList}
          reportPeriodsList={reportPeriodsList}
        />
      )}

      {templateLibraryModal && (
        <ESGTemplateModal
          visible={templateLibraryModal}
          onClose={() => setTemplateLibraryModal(false)}
          onFinish={() => refreshReportDataLake()}
        />
      )}

      {showReportTitleModal && (
        <ReportTitleModal
          description="Please enter a report title modal"
          visible={showReportTitleModal}
          onCancel={() => setShowReportTitleModal(false)}
          onSubmit={(values) => onReportTitleModalSubmit(values)}
          ConfirmLoader={false}
        />
      )}

      {showIndivisualReport && (
        <IndividualReport
          report_period={report_period}
          location_id={location_id}
          business_unit_id={business_unit_id}
          rowDataLakeMap={rowData}
          setShowIndivisualReport={setShowIndivisualReport}
        />
      )}
      {showReportCenterPage && (
        <ReportCenterPage
          report_period={report_period}
          location_id={location_id}
          business_unit_id={business_unit_id}
          dataLakeMapReport={selectedListReportDataLake}
          setShowReportCenterPage={setShowReportCenterPage}
          {...reportContentProps}
        />
      )}
      <DataLakeMapTable
        onRowEdit={onTableRowEdit}
        onClickRecategorizeStandards={onClickRecategorizeStandards}
        onClickCloneStandards={onClickCloneStandards}
        selectedListReportIds={selectedListReportIds}
        onReportBtnClick={onReportBtnClick}
        onClickRecategorizeStandard={onClickRecategorizeSingleData}
        onClickIndivisualReportViewBtn={onClickIndivisualReportViewBtn}
        report_period={report_period}
        location_id={location_id}
        business_unit_id={business_unit_id}
        selectedListReportDataLake={selectedListReportDataLake}
        setSelectedListReportDataLake={setSelectedListReportDataLake}
        refreshListReportDataLake={refreshListReportDataLake}
        refreshReportDataLake={refreshReportDataLake}
        isDeleted={isDeleted}
      />
    </>
  );
};

export default PoliciesPage;
