import React, { useEffect, useState } from "react";
import { Row, Switch, message, Col, Space } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  StyledButton,
  CommonTable,
  FormWrapper,
} from "./../../../../shared/commonStyles";
import FilterModalCommon from "../../../../components/Modals/CreateFilterModal/FilterModalCommon";
import {
  getReportFilters,
  removeReportFilters,
  updateReportFilters,
} from "../../../../services/filter";
import ConfirmModal from "../../../../components/Modals/CreateFilterModal/ConfirmModal";
import ChangeStatus from "./ChangeStatus";

const parseDate = (date) => moment(date, YearMonthDayFormat);
const LocationsDataTable = ({ section }) => {
  const [rowData, setRowData] = useState(null);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDeleteChartModal, setShowDeleteChartModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [locationsData, setLocations] = useState(null);

  const refreshLocation= async()=>{
    try{
      setTableLoader(true)
      const payload = { filter: { filter_type: "location" } };
      const newResponse = await getReportFilters(payload);
      return setLocations(newResponse);
    }catch(e){

    }finally{
      setTableLoader(false)
    }
  }

  useEffect(() => {
    refreshLocation()
  }, []);

  const onClickEdit = (record) => {
    setRowData(record);
    setShowLocationModal(true);
  };

  const onChangeStatus = async (checked, record) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      setTableLoader(true);
      const payload = {
        ...record,
        status,
      };
      const response = await updateReportFilters(payload);
      if (response) {
        refreshLocation();        
        message.success("Status updated successfully!");
      } 
    } catch (error) {
      message.error("Something went wrong updating the status.");
      console.log(error);
    }finally{
      setTableLoader(false);
    }
  };
  const onClickDelete = (record) => {
    setRowData(record["_id"]);
    setShowDeleteChartModal(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
    },
    {
      title: "Date Created",
      dataIndex: "create_timestamp",
      width: "20%",
      sorter: (a, b) =>
        parseDate(b.create_timestamp) > parseDate(a.create_timestamp) ? 1 : -1,
      sortDirections: ["descend", "ascend"],
      render: (record) => {
        return record ? moment(record).format(YearMonthDayFormat) : null;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (_, rowData) => (
        <ChangeStatus rowData={rowData} onChangeStatus={onChangeStatus} /> 
      ),
      sorter: (a, b) =>
        a?.status && b?.status && b.status.localeCompare(a.status),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      key: "operation",
      width: "6%",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton type="default" onClick={() => onClickEdit(record)}>
            <EditOutlined />
          </StyledButton>
          <StyledButton type="default" onClick={() => onClickDelete(record)}>
            <DeleteOutlined />
          </StyledButton>
        </Space>
      ),
    },
  ];  
  const onConfirmDeleteChart = async () => {
    try {
      setConfirmLoading(true);
      const payload = {
        _id: rowData,
      };

      const deleteLocationResponse = await removeReportFilters(payload);

      if (deleteLocationResponse) {
        const newResponse =  await refreshLocation();
        if (newResponse) {
          message.success("Location data deleted Successfully!");
        }
          setShowDeleteChartModal(false);
          setConfirmLoading(false);
                    

      } else {
        message.error("Failed to delete Location data!");
        console.log("Failed to update Location data");
      }
    } catch (error) {
      console.log("Something went wrong while updating Location data!", error);
      message.error("Something went wrong while updating Location data!");
    }
  };
  return (
    <>
      <Row style={{ justifyContent: "flex-end" }}>
        <StyledButton
          className="margin-0"
          type="custom"
          onClick={() => setShowLocationModal(true)}
        >
          <PlusOutlined />
          <span>Create New Location</span>
        </StyledButton>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <CommonTable
            loading={tableLoader}
            rowKey={(data) => {
              return data?.["_id"]?.["$oid"];
            }}
            dataSource={locationsData}
            columns={columns}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      {showLocationModal && 
        <FilterModalCommon
          visible={showLocationModal}
          onClose={() => setShowLocationModal(false)}
          rowData={rowData}          
          section={section}          
          refresh={()=>refreshLocation()}
        />
      }

      {showDeleteChartModal && 
        <ConfirmModal
          description={`Are you sure you want to delete this ${section} ?`}
          isVisible={showDeleteChartModal}
          onClose={() => setShowDeleteChartModal(false)}
          onConfirm={onConfirmDeleteChart}
          confirmLoading={confirmLoading}
        />
      }
    </>
  );
};

export default LocationsDataTable;
