import React,{useState,createContext} from "react";
// create context 
export const LayoutContext = createContext(null);

// create provider
export const LayoutProvider = ({ children }) => {
    //showSectionByKey is for home page section when click on cards. we can use it on sidebar as well using this context api to check and go back to page from sidebar links. 
  const [showSectionByKey,setShowSectionByKey] = useState(null);

  const data = {
    showSectionByKey,
    setShowSectionByKey
  };

    return <LayoutContext.Provider value={data}>{children}</LayoutContext.Provider>;
};