import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import { getFirstAndLastNameByUsername } from "../shared/commonFunctions";

/* New Api's for user Group */

export const getUserGroups = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_user_groups",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const getUserGroupById = async (groupId) => {
  const payload = {
    filter: { _id: { $oid: groupId } },
  };
  const group = await getUserGroups(payload);
  return group[0];
};

export const addUserGroups = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_user_groups",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const updateUserGroups = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_user_groups",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const getWelcomeMessage = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_user_groups_message",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const fetchUsers = async ({ parameters, users }) => {
  const response = await API.graphql({
    query: queries["manageUserAccess"],
    variables: { request_type: "list", parameters },
  });

  const manageUserAccess = JSON.parse(response["data"]["manageUserAccess"]);
  const usersList = manageUserAccess["Users"];
  const nextToken = manageUserAccess["NextToken"];
  users.push(...usersList);

  if (nextToken) {
    return fetchUsers({
      parameters: JSON.stringify({ NextToken: nextToken }),
      users,
    });
  } else {
    return { users };
  }
};

export const getDataRelatedAttribute = (Attributes, key) => {
  try {
    return Attributes
      ? Attributes?.find((item) => item?.["Name"] === key && item?.["Value"])
      : null;
  } catch (error) {
    console.log("error while get attribute data in user management", error);
    return null;
  }
};

export const getUsersList = async () => {
  const { users: usersList } = await fetchUsers({
    parameters: "{}",
    users: [],
  });
  let updatedList = [];
  if (usersList) {
    updatedList = await Promise.all(
      await usersList.map(async (user) => {
        const nameDataObject = getDataRelatedAttribute(
          user["Attributes"],
          "name"
        );
        let name = nameDataObject?.["Value"] ? nameDataObject?.["Value"] : "";
        if (!name && user?.["Username"]) {
          name = await getFirstAndLastNameByUsername(user["Username"]);
        }
        if (!name) {
          const emailDataObject = getDataRelatedAttribute(
            user["Attributes"],
            "email"
          );
          name = emailDataObject?.["Value"] ? emailDataObject?.["Value"] : "";
        }
        return {
          ...user,
          name,
          key: user["Username"],
          label: name,
          value: user["Username"],
        };
      })
    );
  }
  return updatedList;
};
export const createUser = async (parameters) => {
  const response = await API.graphql({
    query: queries["manageUserAccess"],
    variables: {
      request_type: "add-user",
      parameters: JSON.stringify(parameters),
    },
  });
  return response;
};
export const getUsersGroupList = async () => {
  const payload = {
    filter: { status: "ACTIVE" },
  };
  const filterdUser = await getUserGroups(payload);
  if (filterdUser) {
    const addkeyUpdatedGroupsList = filterdUser.map((item) => {
      return {
        ...item,
        key: item?._id["$oid"],
        label: item?.name,
        value: item?._id["$oid"],
      };
    });
    return addkeyUpdatedGroupsList;
  }
};
