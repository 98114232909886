import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useBoolean } from "ahooks";
import {
  CommonModal,
  CommonTable,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import {
  getMetaDataRecords,
  insertData,
} from "../../../../../services/dataSheet";
import styles from "./AskPeriodModal.module.scss";
import { queryOperators } from "../../../../../services/mongoOperators";

type ColumnType = {
  dataIndex: string;
  key: number;
  title: string;
};

interface AskPeriodModalProps {
  visible: boolean;
  loading: boolean;
  dataSheet: DataSheet;
  existingLocations: number;
  onCount: (filters: any[]) => void;
  onConfirm: () => void;
  onClose: () => void;
}

export const AskPeriodModal = ({
  visible,
  loading,
  dataSheet,
  existingLocations,
  onCount,
  onConfirm,
  onClose,
}: AskPeriodModalProps) => {
  const [form] = Form.useForm();
  const [missingLocationsNumber, setMissingLocationsNumber] = useState(0);
  const [metaDataRecords, setMetaDataRecords] = useState<MetaDataRecord[]>();
  const [metaDataId, setMetaDataId] = useState("");
  const [activityFields, setActivityFields] = useState<
    { value: string; label: string }[]
  >([]);
  const [
    isLoading,
    { setTrue: showLoading, setFalse: hideLoading, set: setLoading },
  ] = useBoolean(false);

  useEffect(() => {
    showLoading();
    getMetaDataRecords({
      filter: { sheet_id: dataSheet?.metadata_sheet_id },
    }).then((metaData) => {
      setMetaDataRecords(metaData);
      hideLoading();
    });
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    try {
      if (dataSheet?.sheet_schema?.length) {
        const schemas: { value: string; label: string }[] = [];
        dataSheet.sheet_schema.forEach((schema) => {
          const metadataColumns = Object.keys(
            metaDataRecords?.[0][metaDataRecords[0].sheet_name][0] || {}
          ).find((v) => v === schema.entity_name);
          if (!metadataColumns) {
            schemas.push({
              value: schema.entity_name,
              label: schema.display_name,
            });
          }
        });
        setActivityFields(schemas);
      }
    } catch (error) {
      console.log("Something went wrong!", error);
      message.error("Something went wrong!");
    }
  }, [dataSheet, metaDataRecords]);

  const getColumns = () => {
    const columns: ColumnType[] = [];
    Object.keys(
      metaDataRecords?.[0]?.[metaDataRecords?.[0]?.sheet_name]?.[0] || {}
    ).forEach((recordKey: string, index: number) => {
      const keysToAvoid = ["id", "group_id", "username", "archive", "archived"];
      if (!keysToAvoid.includes(recordKey)) {
        columns.push({ title: recordKey, dataIndex: recordKey, key: index });
      }
    });
    return columns;
  };

  const tableDataSource =
    metaDataRecords?.[0]?.[metaDataRecords?.[0]?.sheet_name];

  const rowSelection = {
    onChange: (selectedRowKeys: string[]) => {
      setMetaDataId(selectedRowKeys[0]);
    },
  };

  const missingLocations =
    missingLocationsNumber - existingLocations >= 0
      ? missingLocationsNumber - existingLocations
      : 0;

  const handleConfirm = () => {
    for (let i = 0; i < missingLocations; i++) {
      createMissingDataSheets();
    }
  };

  const createMissingDataSheets = async () => {
    try {
      const metaDataSheetData = metaDataRecords?.[0][
        metaDataRecords[0].sheet_name
      ].find((v) => v.id === metaDataId);
      const sheetName = dataSheet["sheet_name"];
      const sheetId = dataSheet["_id"]["$oid"];
      const payload = {
        sheet_id: sheetId,
        sheet_name: sheetName,
        documents: [{ ...metaDataSheetData }],
      };

      const result = await insertData(payload);
      result && onConfirm();
    } catch (error) {
      message.error("Error while saving record!");
      console.log("Error while saving record!", error);
    }
  };

  const handleCount = (data: any) => {
    const request = data.filters
      ? [...data.filters, { column: "id", op: "$eq", column_value: metaDataId }]
      : [{ column: "id", op: "$eq", column_value: metaDataId }];
    onCount(request);
  };

  return (
    <CommonModal
      title="Check missing records"
      visible={visible}
      onCancel={onClose}
      onOk={handleConfirm}
      okText="Add missing locations"
    >
      <Spin spinning={isLoading}>
        <FormWrapper>
          <div className={styles.label}>Choose metadata record</div>
          <CommonTable
            rowKey="id"
            dataSource={tableDataSource}
            columns={getColumns()}
            rowSelection={{ ...rowSelection, type: "radio" }}
            scroll={{ x: true }}
            pagination={{ pageSize: 5 }}
          />
          <div className={styles.label}>
            How many locations do you want to have?
          </div>
          <InputNumber
            value={missingLocationsNumber}
            onChange={setMissingLocationsNumber}
          />
          <Divider />
          <FormWrapper>
            <Form form={form} onFinish={handleCount} autoComplete="off">
              <div>Choose filters</div>
              <Form.Item label="">
                <Form.List name="filters" key={"filters"}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          align="baseline"
                          style={{ marginBottom: "8px", display: "flex" }}
                        >
                          <Form.Item
                            name={[name, "column"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Datasheet field name"
                              options={activityFields}
                              style={{ minWidth: "200px" }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[name, "op"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Equality operator"
                              options={queryOperators}
                              style={{ minWidth: "100px" }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[name, "column_value"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input placeholder="Input variable" />
                          </Form.Item>
                          <StyledButton
                            type="default"
                            style={{ padding: "8px", marginTop: "0px" }}
                            disabled={activityFields.length === 0}
                            onClick={() => remove(name)}
                            icon={<DeleteOutlined />}
                          />
                        </Space>
                      ))}
                      <StyledButton
                        type="custom"
                        disabled={activityFields.length === 0}
                        onClick={add}
                      >
                        <PlusCircleOutlined /> Add
                      </StyledButton>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Row justify="end">
                <StyledButton
                  disabled={!metaDataId || !missingLocationsNumber}
                  htmlType="submit"
                >
                  Count
                </StyledButton>
              </Row>
            </Form>
          </FormWrapper>
          {existingLocations >= 0 && (
            <div className={styles.warningMessage}>
              <b>{missingLocations} missing locations</b> from metadata sheet
              were detected. This reduces the accuracy of future calculations.
            </div>
          )}
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};
