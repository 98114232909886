import { Form, Menu, Popover, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  FormWrapper,
  StyledButton,
  StyledTag,
  TableCommonMenu,
} from "../../shared/commonStyles";
import {
  CloseOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FiltersForm } from "./FiltersForm";
import styles from "./ConditionalDataSheetFilters.module.scss";
import { getSheetRecordsFilters } from "../../services/dataSheet";
import { getUserGroups, getUsersList } from "../../services/users";

interface ConditionalDataSheetFiltersProps {
  dataSheet: DataSheet;
  initialFilters?: any[];
  onChangeFilters: (filters: any[]) => void;
  addButtonText: string;
  pageType?: string;
}

export const ConditionalDataSheetFilters = ({
  dataSheet,
  initialFilters,
  onChangeFilters,
  addButtonText,
  pageType = "main",
}: ConditionalDataSheetFiltersProps) => {
  const [filterData, setFilterData] = useState([] as any[]);
  const [loader, setLoader] = useState(false);
  const [dropListVisible, setDropListVisible] = useState(false);
  const [conditionVisible, setConditionVisible] = useState(false);
  const [currentCondition, setCurrentCondition] = useState<any>(null);
  const [selectVisible, setSelectVisible] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  useEffect(() => {
    onChangeFilters(filterData);
  }, [filterData]);

  const getRecordTypes = () => {
    const includeTypes: RecordTypes[] = [];
    const excludeTypes: RecordTypes[] = [];
    switch (pageType) {
      case "deleted":
        includeTypes.splice(0, 3, RecordTypes.ARCHIVED);
        break;
      case "pending":
        includeTypes.splice(0, 3, RecordTypes.PENDING);
        excludeTypes.push(RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING);
        break;
      case "denied":
        includeTypes.splice(0, 3, RecordTypes.DENIED);
        excludeTypes.push(RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING);
        break;
      default:
        excludeTypes.push(RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING);
    }
    return {
      include: includeTypes,
      exclude: excludeTypes,
    };
  };

  const handleSelectChange = (value: any[]) => {
    const newValue = value?.map((item: any) =>
      item.value === "(" || item.value === ")"
        ? { label: item.label, value: item.value, type: "bracket" }
        : item.value === "$or" || item.value === "$and"
        ? { label: item.label, value: item.value, type: "operator" }
        : typeof item.value === "object" && {
            label: item.label,
            value: item.value,
            type: "condition",
          }
    );
    setFilterData(newValue);
  };
  const handleAddFilters = (filter: any) => {
    setFilterData((prev) => [...prev, { ...filter, type: "condition" }]);
  };

  const handleChangeFilter = (filter: any) => {
    setFilterData((prev) =>
      prev.map((item, index) =>
        index === currentCondition?.index
          ? { ...filter, type: "condition" }
          : item
      )
    );
    setCurrentCondition(null);
  };

  const handleOpenFilter = (item: any) => {
    setConditionVisible(true);
    const currentConditionIndex = filterData.findIndex(
      (v) => v.value === item.value
    );
    const condition = {
      ...filterData[currentConditionIndex],
      index: currentConditionIndex,
    };
    setCurrentCondition(condition);
  };

  const getFilters = async () => {
    try {
      setLoader(true);
      if (dataSheet?._id?.$oid) {
        const fields = dataSheet?.sheet_schema
          ?.filter(
            (v) =>
              v.input_type === "string" ||
              (v.input_type === "expression" && v.data_type === "string")
          )
          ?.map((v) => `${dataSheet.sheet_name}.${v.entity_name}`);
        const result = await getSheetRecordsFilters(
          dataSheet?._id?.$oid,
          fields,
          getRecordTypes()
        );
        if (result) {
          const {
            owner_users,
            username,
            owner_groups,
            ...rest
          }: { [key: string]: (string | number)[] } = result;

          const filterOptions = {} as any;
          Object.entries(rest).forEach(([key, value]) => {
            filterOptions[key] = value
              ?.filter((v) => typeof v !== "object")
              .map((v) => ({
                label: v,
                value: v,
              }));
          });
          if (owner_groups?.length) {
            const groups: UserGroup[] = await getUserGroups({
              filter: { _id: { $oid: result.owner_groups } },
            });
            const sheetGroups = groups
              .filter((v) => result.owner_groups?.includes(v._id.$oid))
              .map((item) => ({ label: item?.name, value: item?._id?.$oid }));
            filterOptions["owner_groups"] = sheetGroups;
          }
          if (owner_users?.length || username?.length) {
            const users: User[] = await getUsersList();
            const sheetUsers = users
              .filter(
                (v: User) =>
                  result.owner_users?.includes(v.key) ||
                  result?.username?.includes(v.key)
              )
              ?.map((v) => ({ label: v.label, value: v.value }));
            owner_users.some((v) => v === "SYSTEM") &&
              sheetUsers.push({
                label: "SYSTEM",
                value: "SYSTEM",
              });
            filterOptions["owner_users"] = sheetUsers;
          }
          setFilterOptions(filterOptions);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getFilters();
  }, [dataSheet]);

  useEffect(() => {
    if (initialFilters?.length) {
      setFilterData(initialFilters);
      setSelectVisible(true);
    }
  }, [initialFilters]);

  const dropdownMenu = (
    <TableCommonMenu className={styles.menu}>
      <Row style={{ margin: 20, color: "#fff" }}>Select option below</Row>
      <Menu.SubMenu key="bracket" title="(x) Add Bracket">
        <Menu.Item
          className="menuItem"
          disabled={
            !!filterData?.length &&
            (filterData?.filter((v) => v.value === ")")?.length <
              filterData?.filter((v) => v.value === "(")?.length ||
              filterData?.[filterData?.length - 1]?.value === "(")
          }
          key="("
          onClick={() => {
            setFilterData((prev: any[]) => [
              ...prev,
              { label: "(", value: "(", type: "bracket" },
            ]);
          }}
        >
          ( Open Bracket
        </Menu.Item>
        <Menu.Item
          className="menuItem"
          disabled={
            !filterData?.length ||
            filterData.filter((v) => v.value === "(")?.length <=
              filterData.filter((v) => v.value === ")")?.length ||
            filterData?.[filterData?.length - 1]?.value === "(" ||
            filterData?.[filterData?.length - 1]?.type === "operator"
          }
          key=")"
          onClick={() => {
            setFilterData((prev: any[]) => [
              ...prev,
              { label: ")", value: ")", type: "bracket" },
            ]);
          }}
        >
          ) Close Bracket
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="condition"
        className="menuItem"
        onClick={() => setDropListVisible((prev) => !prev)}
        disabled={
          filterData?.[filterData?.length - 1]?.type === "condition" ||
          filterData?.[filterData?.length - 1]?.value === ")"
        }
      >
        <a>
          <FilterOutlined /> Add Condition
        </a>
      </Menu.Item>
      {(filterData?.[filterData?.length - 1]?.type === "condition" ||
        filterData?.[filterData?.length - 1]?.value === ")") && (
        <Menu.SubMenu key="operator" title="/ Add Operator">
          <Menu.Item
            key="and"
            className="menuItem"
            onClick={() => {
              setFilterData((prev: any[]) => [
                ...prev,
                { label: "AND", value: "$and", type: "operator" },
              ]);
            }}
          >
            AND
          </Menu.Item>
          <Menu.Item
            key="or"
            className="menuItem"
            onClick={() => {
              setFilterData((prev: any[]) => [
                ...prev,
                { label: "OR", value: "$or", type: "operator" },
              ]);
            }}
          >
            OR
          </Menu.Item>
        </Menu.SubMenu>
      )}
    </TableCommonMenu>
  );

  return (
    <FormWrapper>
      <Spin spinning={loader}>
        <Form.Item name={"conditional_filters"} className="conditionalSelector">
          {selectVisible ? (
            <Popover
              visible={
                (conditionVisible && !!currentCondition) || dropListVisible
              }
              overlayClassName={styles.filterPopover}
              overlayStyle={{
                minWidth: document
                  .querySelector(".conditionalSelector")
                  ?.getBoundingClientRect().width,
              }}
              placement={dropListVisible ? "bottomLeft" : "topLeft"}
              trigger={["click"]}
              content={
                dropListVisible ? (
                  <FiltersForm
                    onVisibleChange={setDropListVisible}
                    dataSheet={dataSheet}
                    loader={loader}
                    onSelectFilters={handleAddFilters}
                    filterOptions={filterOptions}
                  />
                ) : (
                  <FiltersForm
                    onVisibleChange={(visible) => {
                      setConditionVisible(visible);
                      setCurrentCondition(null);
                    }}
                    initialFilter={
                      (currentCondition as any)?.value as AdvancedDataFilter
                    }
                    dataSheet={dataSheet}
                    loader={loader}
                    onSelectFilters={handleChangeFilter}
                    filterOptions={filterOptions}
                  />
                )
              }
            >
              <Select
                mode="tags"
                tagRender={(item) =>
                  item.label === "(" || item.label === ")" ? (
                    <span className="color-white margin-right-5">
                      {item.label}
                    </span>
                  ) : (
                    <StyledTag
                      bgcolor={
                        currentCondition &&
                        item?.value === (currentCondition as any)?.value
                          ? "#A68DFB"
                          : "#4A445D"
                      }
                      onClick={() =>
                        typeof item.value === "object" && handleOpenFilter(item)
                      }
                    >
                      {item.label} <CloseOutlined onClick={item.onClose} />
                    </StyledTag>
                  )
                }
                value={filterData}
                labelInValue
                onChange={handleSelectChange}
                dropdownRender={() => dropdownMenu}
                allowClear
              />
            </Popover>
          ) : (
            <StyledButton type="custom" onClick={() => setSelectVisible(true)}>
              <PlusCircleOutlined /> {addButtonText}
            </StyledButton>
          )}
        </Form.Item>
      </Spin>
    </FormWrapper>
  );
};
