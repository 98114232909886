import styled from 'styled-components';

// Report Section
export const Report = styled.div`
  font-family : Montserrat;
  background-color: white;
  .tableContainer {
    margin: 30px 8% 50px;
    background-color: white;
    padding: 15px;
  }

  .reportTableContainer {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .ant-table-container table > thead > tr:first-child th {
    background: ${({ secondary }) => secondary};
    color: ${({ tableHeaderTextColor }) => tableHeaderTextColor};
    padding: 14px 14px 6px;
    font-weight: bold;
    border-radius: 0px;
    border : none;
  }

  .ant-table-row td:first-child,
  .ant-table-row:hover td:first-child {
    background-color: ${({ primary }) => primary}10; // 10% opacity of primary
    color: ${({ topicTextColor }) => topicTextColor};
    font-weight: bold;
  }

  .reportTableContainer tbody tr td {
    border-bottom: 1px solid #c8c8c8;
  }

  .ant-spin-container:first-child {
    padding: 0;
  }

  .ant-card-bordered {
    border: none;
  }

  .report-header {
    background: ${({ primary }) => primary};
    padding: 2rem;
  }

  .header-content-wrap .report-title {
    color: ${({ reportTitleColor }) => reportTitleColor};
  }
  

  .header-content-wrap .description {
    color: ${({ reportDescriptionColor }) => reportDescriptionColor};
  }

  .header-content-wrap{
    width : 100%;
  }
  
  .header-logo-wrap{
    width : 100%;
    display: flex;
    justify-content: end;
  }

  .header-logo-wrap img{
    width : 15%;
    background-color : transparent;
    margin-bottom : 10px;
  }

  .report-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
  }
  .report-footer a {
    color: white;
  }

  .report-footer img {
    max-width: 100px;
    margin-left: 4px;
    filter: grayscale(100%);
  }
  table,
  thead,
  th,
  tr,
  td {
    border-collapse: collapse;
    border : none;
  }
  tr
  {
    border-bottom : 1px solid #f2ebeb;
  }
`;