import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { message, Row, Menu, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledButton, TableCommonMenu } from "../../../shared/commonStyles";
import { getKpiMetric } from "../../../services/kpi";
import XLSX from "xlsx";
import { getSheetById } from "../../../services/dataSheet";
import { aggregationOperators } from "../../../services/mongoOperators";
import { ExportKPIModal } from "../../../pages/kpi/components/ExportKPIModal";
import { Button } from "@aws-amplify/ui-react";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { encodeUrlName } from "../../../shared/commonFunctions";

export const AddNewKpiData = () => {
  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();
  const [kpiList, setKpiList] = useState();
  const [isExportVisible, setExportVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let sheetId = null;
  let sheetName = null;
  let mainSheetName = null;
  if (
    location.pathname.includes("/data/kpi") &&
    location.pathname.endsWith("details")
  ) {
    sheetId = location.pathname.split("/")[3];
    sheetName = location.pathname.split("/")[4];
    mainSheetName = decodeURIComponent(sheetName);
  }
  const loadData = async () => {
    try {
      const payload = makeQuery();
      const kpisList = await getKpiMetric(payload);
      const newKpiList = await Promise.all(
        kpisList?.map(async (item) => {
          const sheetData = await getSheetById(item.sheetId);
          const dataIndex = `${item?.aggregateColumn}_${item?.aggregateOp}`;

          const schemaData = sheetData?.sheet_schema?.find(
            (schema) => schema.entity_name === item?.aggregateColumn
          );
          const operationData = aggregationOperators?.find(
            (operation) => operation.value === item?.aggregateOp
          );
          const aggregateResult = item?.analyticResult
            ? item?.analyticResult[dataIndex]
            : "";

          return {
            ...item,
            dataSheetName: mainSheetName,
            aggregateColumnLabel: schemaData ? schemaData?.display_name : "",
            aggregateOpLabel: operationData?.label || "",
            aggregateResult,
            dataSheetType: sheetData.sheet_type,
            // totalValue: item.analyticResult[dataIndex],
          };
        })
      );
      if (newKpiList) {
        setKpiList(newKpiList);
      } else {
        message.error("Something went wrong while loading kpi list");
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong while loading kpi list");
    }
  };
  useEffect(() => {
    sheetId && loadData();
  }, [sheetId, sheetName]);

  const makeQuery = () => {
    const filter = { sheetId };

    return {
      filter: filter,
    };
  };

  const ExcelFields = {
    "Data Sheet Name": "dataSheetName",
    "KPI Name": "name",
    "Field Name": "aggregateColumnLabel",
    Operator: "aggregateOpLabel",
    Value: "aggregateResult",
    Description: "description",
  };

  const exportKpiReport = () => {
    try {
      const kpidata = kpiList.map((item) => {
        const emptyObj = {};
        for (const [key, value] of Object.entries(item)) {
          for (const [k, v] of Object.entries(ExcelFields)) {
            if (key === v) {
              emptyObj[k] = value;
            }
          }
        }
        return emptyObj;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(kpidata);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
    } catch (error) {
      console.log("Found Errors", error);
    }
  };
  const menu = (
    <TableCommonMenu>
      <Menu.Item key="pdf">
        <Button
          disabled={!kpiList?.length}
          onClick={() => setExportVisible(true)}
        >
          Export PDF
        </Button>
      </Menu.Item>
      <Menu.Item key="csv">
        <Button disabled={!kpiList?.length} onClick={() => exportKpiReport()}>
          Export CSV
        </Button>
      </Menu.Item>
    </TableCommonMenu>
  );

  return (
    <>
      <Row justify="end">
        {location.pathname.endsWith("details") && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <StyledButton type="primary" icon={<DownOutlined />}>
              Export Report
            </StyledButton>
          </Dropdown>
        )}
        {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
          <StyledButton
            type="primary"
            onClick={() => {
              navigate(
                sheetId
                  ? `/data/kpi/${sheetId}/${encodeUrlName(sheetName)}/create`
                  : `/data/kpi/create`
              );
            }}
          >
            <PlusCircleOutlined /> <span>Add New KPI</span>
          </StyledButton>
        )}
      </Row>
      {isExportVisible && (
        <ExportKPIModal
          visible={isExportVisible}
          hideModal={() => setExportVisible(false)}
          kpiList={kpiList}
          sheetName={mainSheetName}
        />
      )}
    </>
  );
};
