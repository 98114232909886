import { Row, message, Popover, FormInstance } from "antd";
import React, { useState } from "react";
import styles from "./CreateUserModal.module.scss";
import { CommonTable } from "../../../../shared/commonStyles";
import { TableEditableCell } from "./TableEditableCell";
import { listPermissions } from "../../../../services/permissions";
import { v4 as uuidv4 } from "uuid";

interface Item {
  key: string;
  group?: { name: string; id: string };
  groupsEntities?: any[] | undefined;
  entity?: { identifierId?: string; sheetId?: string; title?: string };
  isNewGroup?: boolean;
  isGroup?: boolean;
  action?: any;
  dataType?: "delete" | "add" | "initial";
}
interface GroupsAndEntitiesTableProps {
  form: FormInstance<any>;
  groups: UserGroup[];
  dataLoading?: boolean;
  loadingRefDataSheetsData: boolean;
  editingKey: string;
  dataSource: Item[];
  refDataSheets: any[];

  setDataSource: (updateFn: (prev: Item[]) => Item[]) => void;
  setEditingKey: (value: string) => void;
}

export const GroupsAndEntitiesTable = ({
  dataSource,
  refDataSheets,
  setDataSource,
  form,
  editingKey,
  groups,
  dataLoading,
  loadingRefDataSheetsData,

  setEditingKey,
}: GroupsAndEntitiesTableProps) => {
  const [value, setValue] = useState<string[]>([]);

  const [rowLoading, setRowLoading] = useState<boolean>(false);

  const [tableEntityData, setTableEntityData] = useState<any[]>([]);

  const columns = [
    {
      title: "Groups",
      width: "30%",
      dataIndex: "group",
      index: "group",
      render: (_: any, record: Item) => (
        <>{record?.group?.name ? <>{record?.group?.name}</> : <>-----</>}</>
      ),
    },
    {
      title: "Entity",
      width: "40%",
      dataIndex: "entity",
      index: "entity",
      render: (_: any, record: Item) => (
        <>
          {" "}
          {record?.isGroup ? (
            <>
              {" "}
              {record?.groupsEntities?.length ? (
                <>
                  {record?.groupsEntities?.length > 1 ? (
                    <Popover
                      content={
                        <ul
                          style={{
                            padding: "5px 10px",
                            margin: "0px 15px",
                            textAlign: "left",
                          }}
                        >
                          {record?.groupsEntities?.map((v) => {
                            return (
                              <li style={{ textAlign: "left" }}>{v?.label}</li>
                            );
                          })}
                        </ul>
                      }
                      autoAdjustOverflow
                      overlayClassName="popoverContent"
                    >
                      {" "}
                      <Row justify="start" style={{ alignItems: "baseline" }}>
                        {record?.groupsEntities?.length}
                        <p className="color-grey" style={{ marginLeft: "7px" }}>
                          (hover to view list)
                        </p>
                      </Row>
                    </Popover>
                  ) : (
                    <>{record?.groupsEntities[0].label}</>
                  )}
                </>
              ) : (
                <>----</>
              )}
            </>
          ) : (
            <>
              {record?.entity?.title ? (
                <> {record?.entity?.title}</>
              ) : (
                <>----</>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      index: "action",
    },
  ];

  const isValidate = (record: any, dataSource: Item[]) => {
    if (record.isGroup) {
      return dataSource?.some((v) => v.group?.id == record.group?.id);
    } else {
      return dataSource?.some(
        (v) => v.entity?.identifierId == record?.entity?.identifierId
      );
    }
  };
  const onSaveRecord = async (record: Item) => {
    try {
      setRowLoading(true);

      const formValue = form.getFieldsValue();

      const newEntitiesValues = tableEntityData.map((v: any) => ({
        ...record,
        key: uuidv4(),
        entity: v,
      }));

      const groupData = groups.find((v) => v._id.$oid == formValue.group);

      const payload = {
        permissionType: "REFERENCE_DATA",
        groupId: formValue?.group,
      };
      const groupPermissions = await listPermissions(payload);

      const groupMetaDataRecords = refDataSheets.reduce((result, obj) => {
        const matchingChildren = obj.children.filter((ite: any) => {
          const foundPermission = groupPermissions
            .flatMap((v: any) => v.assignedItems)
            .includes(ite.key);

          return foundPermission;
        });

        return result.concat(matchingChildren);
      }, []);

      const newGroupRecord = {
        isGroup: true,
        group: { id: formValue.group, name: groupData?.name || "" },
        groupsEntities: groupMetaDataRecords,
      };

      const isValidateRec = !isValidate(newGroupRecord, dataSource);

      const validateEntitiesRec = newEntitiesValues.filter((v) =>
        dataSource.every(
          (item) => v.entity?.identifierId !== item.entity?.identifierId
        )
      );

      if (record.isNewGroup) {
        if (isValidateRec) {
          setDataSource((prev) =>
            prev?.map((v) =>
              v.key === record.key
                ? {
                    ...v,
                    ...newGroupRecord,
                  }
                : v
            )
          );

          setEditingKey("");
        } else {
          message.error("This group is already selected");
        }
      } else {
        if (validateEntitiesRec.length > 0) {
          setDataSource((prev) => {
            const filteredData = prev.filter((v) => v.key !== record.key);

            return [...filteredData, ...validateEntitiesRec];
          });

          setEditingKey("");
        } else {
          message.error("This entity is already selected");
        }
      }

      setRowLoading(false);
    } catch (error) {
      console.error("Error while saving record:", error);
      message.error("Error while saving record");
      setRowLoading(false);
    }
  };
  const isEditing = (record: Item) => record?.key === editingKey;

  const onCancelRecord = (value: Item) => {
    setDataSource((prev: Item[]) => prev.filter((v) => v.key !== value.key));
    setEditingKey("");
  };
  const onDeleteRecord = (value: Item) => {
    setDataSource((prev: Item[]) =>
      prev
        .map((v: Item): Item => {
          if (v.key === value.key) {
            if (value.dataType === "add") {
              return {} as Item;
            } else if (value.dataType === "initial") {
              return {
                ...v,
                dataType: "delete",
              };
            } else {
              return v;
            }
          } else {
            return v;
          }
        })
        .filter((rec: Item) => rec.key)
    );
  };

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        dataIndex: col.dataIndex as keyof Item,
        title: col.title,
        editing: isEditing(record),
        form: form,
        groups: groups,
        refDataSheets: refDataSheets,
        value: value,
        loadingRefDataSheetsData,
        rowLoading,
        onSave: (value: Item) => onSaveRecord(value),
        onCancel: (newValue: Item) => onCancelRecord(newValue),
        onDelete: (value: Item) => onDeleteRecord(value),
        onChange: (newValue: string[], label: any, extra: any) =>
          onChange(newValue, label, extra, record),
      }),
    };
  });
  const transformData = (data: any[], record: Item) => {
    const transformedData: any = [];
    data.forEach((item: any) => {
      if (item?.children?.length > 0) {
        item?.children?.forEach((child: any) => {
          transformedData.push({
            identifierId: child?.props?.value,
            sheetId: item?.value,
            title: child?.props?.title ?? item?.title,
          });
        });
      } else {
        transformedData?.push({
          identifierId: item.value,
          sheetId: item.parent ?? item.value,
          title: item.title,
        });
      }
    });

    return transformedData;
  };
  const onChange = (
    newValue: string[],
    label: any,
    extra: any,
    record: Item
  ) => {
    const { allCheckedNodes } = extra;
    const allSelectedItems = [
      ...allCheckedNodes.map((item: any) => item?.node?.props || item?.props),
    ];

    const chekedDatasheets = allSelectedItems
      .filter((element: any) => element.children.length)
      .map((v) => ({
        sheetId: v.value,
        identifiers: v.children.map((item: any) => item.props.value),
      }));

    const groupedChekedRecords = {} as any;
    allSelectedItems
      .filter((element: any) => element.parent)
      .forEach((obj) => {
        const { parent, value } = obj;
        if (groupedChekedRecords[parent]) {
          groupedChekedRecords[parent].identifiers.push(value);
        } else {
          groupedChekedRecords[parent] = {
            sheetId: parent,
            identifiers: [value],
          };
        }
      });
    const checkedRecords = Object.values(groupedChekedRecords);

    const checkedData = [...chekedDatasheets, ...checkedRecords];

    const tableEntityData = transformData(allSelectedItems, record);
    setTableEntityData(tableEntityData);

    setValue(newValue);
  };
  return (
    <CommonTable
      className={styles.entitiesTable}
      dataSource={dataSource.filter((v: any) => v.dataType !== "delete")}
      columns={mergedColumns}
      loading={rowLoading || dataLoading}
      pagination={false}
      components={{
        body: {
          cell: TableEditableCell,
        },
      }}
    />
  );
};
