import React, { useEffect, useState } from "react";
import ReleaseNotes, { Version } from "../Components/ReleaseNotesPage";
import {
  getReleaseNotes,
  listReleaseNotes,
} from "../../../../services/releaseNotes";
import {
  getSelectedPatchVersion,
  getVersionComponents,
  transformVersions,
  updateSelectedVersion,
} from "../../../../shared/utils";
import { useAsync, useAsyncEffect } from "../../../../hooks/use-async";

type ListReleaseNotesResponse = {
  versions: string[];
};

type ReleaseNoteItemResponse = {
  version: string;
  date: {
    $date: string;
  };
  html: string;
};

export const ReleaseNotesPageContainer: React.FC = () => {
  const [tableOfContentsLocal, setTableOfContentsLocal] = useState<Version[]>();

  const [searchString, setSearchString] = useState<string>();
  const [tableOfContents, getTableOfContents] =
    useAsyncEffect<ListReleaseNotesResponse>({
      fn: async () => {
        let payload = {
          filter: {},
        };
        if (searchString && searchString !== "") {
          payload = {
            filter: {
              search: searchString,
            },
          };
        }
        return await listReleaseNotes(payload);
      },
      dependencies: [],
    });

  const [getReleaseNotesResult, getReleaseNotesCallable] =
    useAsync<ReleaseNoteItemResponse>({
      fn: async (params: any) => {
        const result = await getReleaseNotes({
          filter: {
            major: params.major,
            minor: params.minor,
            patch: params.patch,
          },
        });
        return result;
      },
    });

  useEffect(() => {
    if (tableOfContents.result?.versions) {
      const transformedData = transformVersions(
        tableOfContents.result.versions
      );
      setTableOfContentsLocal(transformedData);
    }
  }, [tableOfContents.result]);

  useEffect(() => {
    if (tableOfContentsLocal) {
      const selectedVersion = getSelectedPatchVersion(tableOfContentsLocal);
      if (selectedVersion) {
        const versionComponents = getVersionComponents(selectedVersion);
        if (versionComponents) {
          const [major, minor, patch] = versionComponents;
          (async () => {
            await getReleaseNotesCallable({ major, minor, patch });
          })();
        }
      }
    }
  }, [tableOfContentsLocal]);

  const handleVersionClick = (version: string) => {
    if (tableOfContentsLocal) {
      const updatedVersions = updateSelectedVersion(
        tableOfContentsLocal,
        version
      );
      setTableOfContentsLocal(updatedVersions);
    }
  };

  const handleSearchValueChange = (value: string) => {
    setSearchString(value);
  };

  const handleSearchClick = async () => {
    if(searchString && searchString !== '') {
      await getTableOfContents();
    }
  };

  useEffect(() => {
    if (searchString === '') {
      getTableOfContents();
    }
  }, [searchString]);

  return (
    <>
      <ReleaseNotes
        isLoadingContent={getReleaseNotesResult.isLoading}
        isLoadingTable={tableOfContents.isLoading}
        hasErrorForContent={!!getReleaseNotesResult.error}
        hasErrorForTable={!!tableOfContents.error}
        releaseNotes={tableOfContentsLocal ?? []}
        selectedVersion={
          getSelectedPatchVersion(tableOfContentsLocal ?? []) ?? ""
        }
        htmlContent={getReleaseNotesResult.result?.html}
        date={getReleaseNotesResult.result?.date.$date}
        searchString={searchString}
        onSearchValueChange={handleSearchValueChange}
        onSearchClick={handleSearchClick}
        onVersionClick={handleVersionClick}
      ></ReleaseNotes>
    </>
  );
};
