import { Alert, Col, Divider, Row, Typography, Upload } from "antd";
import {
  DownloadOutlined,
  InboxOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";

import React from "react";
import path from "path";

const { Title } = Typography;
const { Dragger } = Upload;

const step2 = ({
  isUser,
  files,
  setFiles,
  onClickNext,
  onClickPrevious,
  initialValues,
  state,
  isUploading,
  downloadFileHandler,
}) => {
  const beforeUpload = (file) => {
    setFiles([...files, file]);
  };

  const onRemove = (file) => {
    const filesList = files.filter((v) => v.uid !== file.uid);
    setFiles(filesList);
  };

  const structured_files_types = `
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
    text/csv, 
    `;

  const ocr_files_types = `
        image/png, 
        image/jpg, 
        image/jpeg, 
        image/JPG, 
        application/pdf,
    `;

  const downloadHandler = () => {
    downloadFileHandler();
  };

  const isOcrFileType = initialValues.source_file_type === "ocr_file";

  return (
    <FormWrap
      width="572px"
      style={{
        marginTop: "50px",
        backgroundColor: "#2D273F",
        borderRadius: "4px",
      }}
    >
      <FormWrapper style={{ margin: "20px 0px" }}>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3} style={{ fontWeight: "600" }}>
              Upload data
            </Title>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title
              level={5}
              className="color-grey"
              style={{ margin: "10px 0px 20px 0px" }}
            >
              Upload file and filter the data you want to add to the system
            </Title>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            {!isOcrFileType && (
              <Alert
                message="Warning! In your spreadsheet, you must format all date values to YYYY-MM-DD (eg. 2022-01-01). Failure to do so may result in data corruption."
                type="warning"
                showIcon
              />
            )}
          </Col>
          <Divider
            style={{ background: "#3A3C5A", margin: "5px 0px 25px 0px" }}
          />
        </Row>

        <Dragger
          multiple={false}
          customRequest={({ file, onSuccess }) => {
            onSuccess("ok");
          }}
          beforeUpload={beforeUpload}
          maxCount={1}
          fileList={[]}
          onRemove={onRemove}
          disabled={state?.action}
          style={{
            backgroundColor: "#423A5A",
            color: "white",
            padding: "5px 25px",
            border: "1px solid #423a5a",
          }}
          accept={
            initialValues.source_file_type === "structured_file"
              ? structured_files_types
              : ocr_files_types
          }
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined className="color-white" />
          </p>
          <p className="ant-upload-text color-white">
            Drag file to this area to upload
          </p>
          <p className="ant-upload-hint color-white">
            Supports a single upload. File type is restricted to the type of
            upload you have chosen.
          </p>
        </Dragger>
        <Row style={{ margin: "10px 0px 25px 0px" }}>
          <Col span={1}>
            {" "}
            <p style={{ marginTop: "6px" }}>Or</p>{" "}
          </Col>
          <Col span={22}>
            <Upload
              multiple={false}
              customRequest={({ file, onSuccess }) => {
                onSuccess("ok");
              }}
              beforeUpload={beforeUpload}
              fileList={files?.map((v) =>
                v.url ? { ...v, name: path.basename(v.url) } : v
              )}
              maxCount={1}
              onRemove={onRemove}
              disabled={state?.action}
              accept={
                initialValues.source_file_type === "structured_file"
                  ? structured_files_types
                  : ocr_files_types
              }
            >
              <StyledButton
                className="margin-5"
                type="custom"
                disabled={state?.action}
              >
                <PlusCircleOutlined />
                <span>Upload file</span>
              </StyledButton>
            </Upload>
          </Col>
        </Row>
        {/* ABLE TO DOWNLOAD FILE ON UPDATE TIME */}
        {state?.action === "update" && (
          <>
            <Row justify="end">
              <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "end" }}>
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  onClick={() => downloadHandler()}
                  style={{ marginRight: "0px" }}
                >
                  <DownloadOutlined />
                  <span>Download file</span>
                </StyledButton>
              </Col>
            </Row>
          </>
        )}
        {/* ABLE TO DOWNLOAD FILE ON UPDATE TIME */}
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <StyledButton
              type="custom"
              onClick={() => onClickPrevious()}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              style={{ padding: "11px 11px 11px 0px" }}
            >
              <span>Go back</span>
            </StyledButton>
            <StyledButton
              className="margin-5"
              loading={isUploading}
              onClick={() => onClickNext()}
              type="custom"
              disabled={!initialValues?._id?.$oid && files?.length == 0}
            >
              <span>Continue to step {isUser ? "2" : "3"}</span>
            </StyledButton>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default step2;
