import React, { useState, useEffect } from "react";

import { Spin, message } from "antd";
import DocumentView from "./Components/DocumentView";
import { PageWrap } from "../../../shared/commonStyles";
import { useSize } from "ahooks";
import { getDocumentByid } from "../../../services/documentReview";
import { useParams } from "react-router-dom";
const ViewDocument = () => {
  const [rowData, setRowData] = useState(null);
  const [loadSurvey, setLoadSurvey] = useState(false);

  const size = useSize(document.querySelector("body"));
  const { document_id: documentId } = useParams();
  
  const loadDocumentRowData = async () => {
    try {
      setLoadSurvey(true);
      const documentData = await getDocumentByid(documentId);
      if (documentData) {
        setRowData(documentData);
      } else {
        message.error("No document found");
      }
    } catch (error) {          
      message.error(`Something went wrong while load flow items! ${error}`);
    } finally {
      setLoadSurvey(false);
    }
  };

  useEffect(() => {
    if (documentId) {      
      loadDocumentRowData();
    }
  }, [documentId]);

  return (
    <PageWrap style={{ paddingLeft: size && size?.width <= 600 && 32 }}>
      <Spin spinning={loadSurvey}>
        {rowData && <DocumentView rowData={rowData} documentId={documentId} />}
      </Spin>
    </PageWrap>
  );
};

export default ViewDocument;
