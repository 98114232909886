import React, { useEffect, useState } from "react";
import { ImportFile } from "./components/ImportFile";
import { ImportHeader } from "./components/ImportHeader";
import { FormWrap, PageWrap } from "../../shared/commonStyles";
import { ReviewData } from "./components/ReviewData";
import { ImportFooter } from "./components/ImportFooter";
import { useLocation, useNavigate } from "react-router";
import { getDataSheets } from "../../services/dataSheet";
import { v4 as uuidv4 } from "uuid";
import {
  getImportDataById,
  removeImportData,
  updateImportData,
} from "../../services/importData";
import { Spin, message } from "antd";
import { uploadSingleDataSheetFile } from "../viewSurveyForAdmin/Components/SurveyFunctions";
import {
  createImport,
  getImportedFiles,
  updateImportStatus,
} from "./MassImportFunctions";

interface ImportState {
  importId: string;
  action: string;
}

export const MassImportData = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [files, setFiles] = useState<ImportedFile[]>([]);
  const [importData, setImportData] = useState<DataImport>();
  const [dataSheets, setDataSheets] = useState([]);

  const handleGetFiles = async (fileIds?: string[], dataId?: string) => {
    const newFiles = await getImportedFiles(fileIds || [], dataId);
    console.log("newF", newFiles);
    setFiles(newFiles);
  };

  const loadImportData = async (importId?: string) => {
    try {
      const importData = await getImportDataById(importId);
      console.log("importData", importData);
      if (importData?._id?.$oid) {
        await handleGetFiles(importData.files_list || [], importData.data_id);
      }
      setImportData(importData);
      return importData;
    } catch (e) {
      return Promise.reject();
    }
  };
  useEffect(() => {
    if (state) {
      loadImportData((state as ImportState).importId);
      (state as ImportState).action === "review" && setCurrentStep(1);
    }
  }, []);

  const loadSheetsData = async () => {
    setLoading(true);
    const payload = {
      filter: {
        _id: { $oid: importData?.sheet_ids },
      },
    };
    const dataSheetsList = await getDataSheets(payload);
    setDataSheets(dataSheetsList);
    setLoading(false);
  };

  const getBaseFile = () => {
    return files?.find((v) => v?.name?.endsWith(".xlsx"));
  };

  const getFilteredFiles = () => {
    const newFiles = files.filter((v) =>
      v.dataId
        ? v.dataId !== getBaseFile()?.dataId
        : v.uid !== getBaseFile()?.uid
    );
    return newFiles;
  };

  useEffect(() => {
    if (importData?._id?.$oid && importData?.sheet_ids) {
      loadSheetsData();
    }
  }, [importData?.sheet_ids?.length]);

  const handleAddFilesToImport = async (
    importData: DataImport,
    fileIds: string[]
  ) => {
    const payload = {
      ...importData,
      fileIds,
    };
    await updateImportData(payload).then(() =>
      loadImportData(importData?._id.$oid)
    );
  };

  const steps = [
    {
      title: "Data upload",
      component: (
        <ImportFile
          files={files}
          setFiles={setFiles}
          state={state as ImportState}
        />
      ),
    },
    {
      title: "Data review",
      component: (
        <ReviewData
          dataSheetsList={dataSheets}
          importData={importData}
          state={state as ImportState}
          files={getFilteredFiles()}
        />
      ),
    },
  ];

  const handleUploadAdditionalFiles = async (
    importData: DataImport,
    filesIds: string[]
  ) => {
    let fileIds = [];
    // for (const file of getFilteredFiles()) {
    //   // const dataId = uuidv4();
    //   const fileId = await uploadSingleDataSheetFile(file as File);
    //   fileIds.push(fileId);
    // }
    fileIds = [...filesIds];
    await handleAddFilesToImport(importData, []);
  };
  const getAdditonalFileIds = async () => {
    const fileList = [];
    for (const file of getFilteredFiles()) {
      const dataId = uuidv4();
      const fileId = await uploadSingleDataSheetFile(file as File);
      fileList.push(fileId);
    }
    console.log("fileList: " + fileList);
    return fileList;
  };
  const handleCreateImport = async () => {
    setLoading(true);
    try {
      const filedIds = await getAdditonalFileIds();
      if (!importData) {
        await createImport(getBaseFile()!, filedIds)
          .then((importId) => {
            loadImportData(importId).then((importData) => {
              handleUploadAdditionalFiles(importData, filedIds);
            });
          })
          .catch((e) => Promise.reject());
      }
    } catch (e) {
      message.error("Something went wrong while processing file!");
      setLoading(false);
    } finally {
      setCurrentStep((prev) => prev + 1);
      setLoading(false);
    }
  };

  const handleExit = () => {
    navigate(-1);
  };

  const handleUpdateImportStatus = async () => {
    setLoading(true);
    try {
      await updateImportStatus(importData);
      handleExit();
    } catch (e) {
      message.error("Something went wrong while approving the import");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    await handleUpdateImportStatus();
  };

  const handleChangeStep = (current: number) => {
    !!getBaseFile() && setCurrentStep(current);
  };

  const handleGoNext = async () => {
    if (currentStep === 0) {
      (state as ImportState)?.action === "update" ||
      (state as ImportState)?.action === "review"
        ? setCurrentStep((prev) => prev + 1)
        : await handleCreateImport();
    } else if (currentStep === 1) {
      handleConfirm();
    }
  };

  const handleGoBack = () => {
    currentStep === 0 ? handleExit() : setCurrentStep((prev) => prev - 1);
  };

  const handleUndo = async () => {
    const payload = {
      _id: importData?._id,
    };
    await removeImportData(payload);
    navigate(`/data/import-data`);
  };

  return (
    <>
      <ImportHeader
        steps={steps}
        currentStep={currentStep}
        onExit={handleExit}
        onChangeStep={handleChangeStep}
      />
      <PageWrap>
        <Spin spinning={loading}>
          <FormWrap width={currentStep === 0 ? "50%" : "100%"}>
            {steps[currentStep].component}
            <ImportFooter
              currentStep={currentStep}
              goBack={handleGoBack}
              goNext={handleGoNext}
              onUndo={handleUndo}
              isBaseFile={!!getBaseFile()}
              state={state as ImportState}
              importData={importData}
            />
          </FormWrap>
        </Spin>
      </PageWrap>
    </>
  );
};
