import React from "react";

import {CommonModal} from "../../../shared/commonStyles";
import { Typography } from "antd";
const Text = Typography;

const ConfirmRestoreModal = ({isVisible,onConfirm,onClose,confirmLoading}) => {
  return (
    <>
      <CommonModal
        title="Confirmation"
        visible={isVisible}
        okText="Restore"
        onOk={onConfirm}
        onCancel={onClose}
        confirmLoading={confirmLoading}

      >
        <Text>Are you sure you want to restore this data sheet?</Text>
      </CommonModal>
    </>
  );
};

export default ConfirmRestoreModal;
