import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import {
  FormWrapper,
  StyledButton,
  StyledDivider,
} from "../../../shared/commonStyles";
import { useSize } from "ahooks";
import styles from "./SurveyView.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { surveyStore } from "../../../stores/SurveyStore";
import { observer } from "mobx-react-lite";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";
import { useNavigate } from "react-router";

interface WelcomePageFormProps {
  onClickPrevious: () => void;
  onClickNext: () => void;
  completeSurvey: () => Promise<void>;
  openDrawer: () => void;
}

export const WelcomePageForm = observer(function WelcomePageForm({
  onClickPrevious,
  onClickNext,
  completeSurvey,
  openDrawer,
}: WelcomePageFormProps) {
  const size = useSize(document.querySelector("body"));
  const [welcomePageData, setWelcomePageData] = useState(
    surveyStore?.surveySteps?.[surveyStore.currentStep] as InfoSurveyStep
  );
  const [isSubmitModal, setSubmitModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setWelcomePageData(
      surveyStore?.surveySteps?.[surveyStore.currentStep] as InfoSurveyStep
    );
  }, [surveyStore.currentStep]);

  const handleClickNext = async () => {
    if (surveyStore.currentStep === surveyStore?.surveySteps?.length - 1) {
      await completeSurvey();
    } else {
      onClickNext();
    }
  };

  return (
    <>
      <SubmitConfirmationModal
        visible={isSubmitModal}
        onClose={() => setSubmitModal(false)}
        onExit={() => navigate("/management/collect-data")}
        onComplete={handleClickNext}
      />
      <div className={styles.formWrap}>
        <Typography.Title
          className="color-white"
          style={{ textAlign: "center" }}
          level={3}
        >
          {welcomePageData.subject}
        </Typography.Title>
        {size && size?.width <= 900 && (
          <div>
            <Button
              style={{
                color: " #fff",
                borderColor: "#fff",
                width: "100%",
                height: "40px",
              }}
              type="ghost"
              onClick={openDrawer}
              className="margin-5"
            >
              <QuestionCircleOutlined /> View Information & Progress
            </Button>
          </div>
        )}
        <StyledDivider className={styles.divider} />
        <FormWrapper>
          <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: welcomePageData.body,
              }}
            ></div>
          </Row>
        </FormWrapper>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Row
          justify={size && size?.width <= 900 ? "space-between" : "center"}
          style={{ flexFlow: "nowrap" }}
        >
          {surveyStore.currentStep >= 0 && (
            <Col>
              <StyledButton
                type="custom"
                onClick={() => onClickPrevious()}
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="white"
                className="PrevButton"
                disabled={!!(surveyStore.currentStep === 0)}
              >
                {size && size?.width <= 767 ? <>Back</> : "Previous Step"}
              </StyledButton>
            </Col>
          )}
          <Col>
            <StyledButton
              type={size && size?.width > 767 ? "text" : "link"}
              onClick={() => {
                surveyStore.currentStep === surveyStore.surveySteps?.length - 1
                  ? setSubmitModal(true)
                  : handleClickNext();
              }}
            >
              {surveyStore?.surveySteps?.length - 1 !== surveyStore.currentStep
                ? "Go to the next step"
                : "Submit and Complete Survey"}
            </StyledButton>
          </Col>
        </Row>
      </div>
    </>
  );
});
