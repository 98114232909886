import { isAirDistanceSheet } from "../../dataSheetsPage/dataSheetDocumentsPage/DataSheetDocumentsFunctions";

export const getFilteredDataSheetColumns = (
  dataSheet,
  identifierColumn,
  state
) => {
  const sheetSchema = dataSheet?.sheet_schema
    ?.filter((item) => {
      if (
        !state ||
        state?.type === "dataSheet" ||
        state?.type === "pendingKpi"
      ) {
        const isIdentifierColumn =
          item.metadata_entity_name && identifierColumn
            ? item.entity_name === identifierColumn
            : item;
        return (
          isIdentifierColumn &&
          !item.isExpression &&
          item.input_type !== "expression"
        );
      } else {
        return (
          !item.metadata_entity_name &&
          !item.isExpression &&
          item.input_type !== "expression"
        );
      }
    })
    ?.filter((item) =>
      isAirDistanceSheet(dataSheet)
        ? item.entity_name !== "distance" && item.entity_name !== "routing"
        : item
    );

  const filteredSchema = state?.displayFields
    ? sheetSchema?.filter((item) =>
        state?.displayFields.find((v) => item.entity_name === v)
      )
    : sheetSchema;

  const schemaWithUnits = filteredSchema
    ?.map((schema) =>
      schema.unit_id
        ? [
            schema,
            {
              entity_name: schema.entity_name + "_unit_id",
              display_name: schema.display_name + " Unit",
              input_type: schema.unit_id,
            },
          ]
        : schema
    )
    .flat();

  return schemaWithUnits;
};
