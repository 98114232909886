import React, { useState, useEffect } from "react";
import { Row, Col, Space, Popconfirm, message, Button, Dropdown } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UndoOutlined,
  SwapOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { EditCustomFactor } from "./EditCustomFactor";
import {
  TabContentWrap,
  CommonTable,
  StyledTitle,
  StyledButton,
} from "../../../shared/commonStyles";
import { carbonStore } from "../../Carbon";
import { observer } from "mobx-react-lite";
import styles from "./CustomFactors.module.scss";
import { SuggestedSearch } from "../../../components/SuggestedSearch";
import { DateSelector } from "../../../components/DateSelector";
import { EmissionsFilter } from "../../../components/EmissionsFIlter";
import { SortEmissionsLibrary } from "../../../components/SortEmissionsLibrary/SortEmissionsLibrary";
import {
  FormattedCategories,
  FormattedCategory,
} from "../Calculator/Components/EmissionsLibraryModal";
import { toJS } from "mobx";
import { useBoolean, useSetState } from "ahooks";
import { ColumnsType } from "antd/lib/table";
import { MassUploadFactors } from "./MassUploadFactors";

type Search = string | RegExp;
interface FetchParams {
  pagination?: Pagination;
  filter?: Array<Search>;
  sortEmission?: SortEmission;
}
export const CustomFactors = observer(() => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [factorDataForEdit, setFactorDataForEdit] = useState<Factor>(
    {} as Factor
  );
  const [customFactorModal, setCustomFactorModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filters, setFilters] = useState<IGHGCategories>({} as IGHGCategories);
  const [tablePagination, setTablePagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });
  const [sortEmission, setSortEmission] = useState<SortEmission>({
    type: "key",
    sortAlphabetically: true,
  });
  const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
    useBoolean(false);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);

  const fetch = (params: FetchParams) => {
    carbonStore.fetchFactors(true, {}, params);
  };

  useEffect(() => {
    fetch({ pagination: tablePagination, ...filters });
    carbonStore.fetchCategories({}, "region_name", () => {
      setFormattedCategories(
        getRefreshedFormattedGHGCategories(getFormattedGHGCategories())
      );
    });
  }, []);

  useEffect(() => {
    carbonStore.changeCustomFilters({ ...filters, name: [filterName] });
  }, [filters, filterName]);

  useEffect(() => {
    setDataSource(carbonStore.factors);
  }, [carbonStore.factors]);

  useEffect(() => {
    setTablePagination(carbonStore.factorsPagination);
  }, [carbonStore.factorsPagination]);

  const getFormattedGHGCategories = (): FormattedCategories => {
    const newCategories: FormattedCategories = structuredClone(
      toJS(carbonStore.ghgCategories)
    ) as unknown as FormattedCategories;
    let key: keyof FormattedCategories;
    for (key in newCategories) {
      toJS(carbonStore.ghgCategories)?.[key]?.forEach((item, index) => {
        newCategories[key][index] = {
          name: item,
          checked: false,
        };
      });
    }

    return newCategories;
  };

  const [formattedCategories, setFormattedCategories] =
    useSetState<FormattedCategories>(getFormattedGHGCategories());
  const edit = (record: { $oid: string }) => {
    setFactorDataForEdit(
      dataSource.find((source: Factor) => source._id.$oid === record.$oid) ||
        ({} as Factor)
    );
    setCustomFactorModal(true);
  };

  const onDelete = (record: { $oid: string }) => {
    carbonStore.deleteCustomFactor(record.$oid);
  };

  const getData = (dataFilters: any) => {
    fetch({
      pagination: { current: 1, pageSize: 10 },
      ...dataFilters,
    });
  };

  const handleChangeFilterName = (name: string) => {
    setFilterName(name);
    getData({ ...filters, name: [name] });
    return () => {
      carbonStore.fetchCategories({ ...filters, name: [name] });
    };
  };

  const handleTableChange = (
    pagination: Pagination,
    _filters: any,
    sorter?: any,
    extra?: any
  ) => {
    const newSorter =
      extra?.action === "sort"
        ? sorter?.column
          ? {
              sortAlphabetically: sorter?.order === "ascend",
              type: sorter?.field,
            }
          : { type: "key", sortAlphabetically: true }
        : sortEmission;
    extra?.action === "sort" && !!newSorter && setSortEmission(newSorter);
    fetch({
      pagination,
      sortEmission: newSorter,
      ...{ ...filters, name: filterName },
    });
  };

  const getRefreshedFormattedGHGCategories = (
    initialCategories?: FormattedCategories
  ): FormattedCategories => {
    const baseCategories = initialCategories || formattedCategories;
    if (Object.keys(baseCategories).length === 0) {
      return baseCategories;
    }
    const newCategories: FormattedCategories = structuredClone(
      toJS(carbonStore.customGhgCategories)
    ) as unknown as FormattedCategories;
    let key: keyof FormattedCategories;
    for (key in newCategories) {
      newCategories[key] = toJS(carbonStore.customGhgCategories)[key].map(
        (item) => {
          let isChecked = baseCategories[key].find(
            (element) => item === element.name
          )?.checked;
          if (key == "region_name" && filters.region_name) {
            isChecked = !!filters.region_name.find((region) => item === region);
          }
          return {
            name: item,
            checked: isChecked || false,
          };
        }
      );
    }
    return newCategories;
  };
  const handleChangeFilterItem = (data: FormattedCategory[], type: string) => {
    const newFilters = filters;
    const newCategories = {
      ...formattedCategories,
      [type]: data,
    };
    const newData = data.filter((v) => v.checked).map((v) => v.name);
    newFilters[type] = newData;
    carbonStore.fetchCategories(newFilters, "region_name", () => {
      setFormattedCategories(getRefreshedFormattedGHGCategories(newCategories));
    });
    newFilters["name"] = [filterName];
    fetch({
      pagination: { current: 1, pageSize: 10 },
      ...newFilters,
    });
    setFilters({ ...newFilters });
  };
  const clearFilters = () => {
    carbonStore.fetchCategories({}, "region_name", () => {
      setFormattedCategories(getFormattedGHGCategories());
    });
    setFilters({});
    setFilterName("");
  };

  const clearFitersTable = () => {
    clearFilters();
    getData({});
  };
  const handleSort = () => {
    fetch({
      pagination: tablePagination,
      ...filters,
      sortEmission,
    });
  };

  const columns: ColumnsType<FactorExtended> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      sorter: true,
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      sorter: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: true,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      sorter: true,
    },
    {
      title: "Source Link",
      dataIndex: "source_link",
      key: "source_link",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Unit Type",
      dataIndex: "unit_type",
      key: "unit_type",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (record: { $oid: string }) => (
        <Space>
          <Button className={styles.tableBtn} onClick={() => edit(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => onDelete(record)}
            overlayClassName="popoverContent"
          >
            <Button className={styles.tableBtn}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const firstRegionName = Object.keys(formattedCategories).sort((x, y) =>
    x == "region_name" ? -1 : y == "region_name" ? 1 : 0
  );

  return (
    <>
      <MassUploadFactors
        visible={isUploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
      />
      {customFactorModal && (
        <EditCustomFactor
          title="Edit custom emissions factor"
          initialData={factorDataForEdit}
          onSave={() => {
            setCustomFactorModal(false);
            message.success("Custom Factor successfully updated");
          }}
          onCancel={() => setCustomFactorModal(false)}
        />
      )}
      <TabContentWrap>
        <Row justify="space-between">
          <Col flex="0 0 50%">
            <StyledTitle level={4}>Custom Factors</StyledTitle>
          </Col>
        </Row>
        <Space>
          <SuggestedSearch
            filterName={filterName}
            tableData={dataSource}
            handleChangeFilterName={handleChangeFilterName}
            key={filterName} // set to re-render when key is changed fix for clear all
          />
          {firstRegionName.map((category) => (
            <div key={category}>
              {category === "year" ? (
                <DateSelector
                  data={
                    formattedCategories[category as keyof FormattedCategories]
                  }
                  datatype={category.split("_")[0]}
                  onChange={handleChangeFilterItem}
                />
              ) : (
                <EmissionsFilter
                  data={
                    formattedCategories[category as keyof FormattedCategories]
                  }
                  datatype={category}
                  onChange={handleChangeFilterItem}
                />
              )}
            </div>
          ))}
          <Button
            type="text"
            onClick={clearFitersTable}
            className={styles.clearSelectionsButton}
          >
            <UndoOutlined />
            <span>Clear all</span>
          </Button>
          <Button type="text" className={styles.sortButton}>
            <Dropdown
              overlay={
                <SortEmissionsLibrary
                  sorting={sortEmission}
                  changeSorting={setSortEmission}
                  onConfirm={() => {
                    handleSort();
                    hideDropdown();
                  }}
                />
              }
              trigger={["click"]}
              onVisibleChange={toggle}
              visible={isDropdownShown}
              placement="bottomRight"
            >
              <a onClick={(e) => e.preventDefault()}>
                <SwapOutlined rotate={90} className={styles.icon} />
                <span>Sort</span>
              </a>
            </Dropdown>
          </Button>
          <StyledButton onClick={() => setUploadModalVisible(true)}>
            <FileOutlined />
            Upload Factors
          </StyledButton>
        </Space>
        <CommonTable
          loading={carbonStore.loadingFactors}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: true }}
          pagination={tablePagination}
          onChange={handleTableChange}
        />
      </TabContentWrap>
    </>
  );
});
