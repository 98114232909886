import React, { useState } from "react";
import { Alert, Col, Row, Typography, Upload, message } from "antd";
import { InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import XLSX from "xlsx";
import { ExcelRenderer } from "react-excel-renderer";
import { carbonStore } from "../../Carbon/CarbonStore";
import { observer } from "mobx-react-lite";
import {
  CommonModal,
  StyledButton,
  FormWrapper,
  StyledDivider,
} from "../../../shared/commonStyles";
import moment from "moment";
import styles from "./MassUploadFactors.module.scss";
import {
  constituentGasesFields,
  requiredFields,
  sampleFields,
} from "./CustomFactorsUtils";

interface UploadedData extends Factor {
  fileId: string;
}

interface MassUploadFactorsProps {
  visible: boolean;
  onClose: () => void;
}

export const MassUploadFactors = observer(
  ({ visible, onClose }: MassUploadFactorsProps) => {
    const [factorsList, setFactorsList] = useState<UploadedData[]>([]);
    const [files, setFiles] = useState<any[]>([]);

    const handleCreateSampleExcel = () => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([sampleFields]);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "SampleFile.xlsx");
    };

    const readFile = (file: any) => {
      setFiles((prev) => [...prev, file]);
      ExcelRenderer(file, (err: any, result: any) => {
        if (err) {
          console.log("read file error : ", err);
        } else {
          result.rows.forEach((item: any, index: number) => {
            const isEmptyRow = item.every((cell: any) => cell === "");
            if (index === 0 || isEmptyRow) {
              return;
            }
            const objectArr = result.rows[0].reduce(
              (obj: any, key: string, i: number) => {
                obj[key] = item[i];
                return obj;
              },
              {}
            );
            const areRequiredFieldsFiled = requiredFields.every(
              (field) => objectArr[field]?.length
            );
            if (!areRequiredFieldsFiled) {
              message.warning(
                "Some records have not required fields and were not imported"
              );
              return;
            }
            setFactorsList((prev) => [
              ...prev,
              { ...objectArr, fileId: file?.uid },
            ]);
          });
        }
      });
    };
    const onRemoveFileList = (file: any) => {
      setFactorsList((prev) => prev.filter((v) => v.fileId !== file.uid));
      setFiles((prev) => prev.filter((v) => v.uid !== file.uid));
    };

    const handleCloseModal = () => {
      setFactorsList([]);
      setFiles([]);
      onClose();
    };
    const handleConfirmUploading = () => {
      let showError = false;
      if (!factorsList?.length) {
        message.warning("No data to uploading");
        return;
      } else
        try {
          factorsList?.forEach((item: any) => {
            const filteredItem: any = {};
            const constituentGasesItem: any = {};
            Object.keys(item).forEach((key: string) => {
              if (key in constituentGasesFields) {
                constituentGasesItem[key] = item[key];
              } else if (key in sampleFields) {
                switch (key) {
                  case "unit_type":
                    filteredItem[key] = [item[key]];
                    break;
                  default:
                    filteredItem[key] = item[key];
                }
              }
            });
            if (Object.keys(constituentGasesItem)?.length)
              filteredItem["constituent_gases"] = constituentGasesItem;
            if (Object.keys(filteredItem)?.length)
              carbonStore.saveCustomFactor(filteredItem);
            if (
              Object.keys(filteredItem)?.length +
                Object.keys(constituentGasesItem)?.length !==
              Object.keys(item)?.length
            )
              showError = true;
          });

          showError &&
            message.warning("Some data may be lost due to column mismatch");
          message.success("All records were uploaded successfully");
        } catch (e) {
          message.error("Error while adding records");
        } finally {
          handleCloseModal();
        }
    };

    return (
      <CommonModal
        centered
        visible={visible}
        onCancel={handleCloseModal}
        okText="Import"
        onOk={handleConfirmUploading}
      >
        <FormWrapper height={"100%"} className={styles.wrapper}>
          <Row
            justify="space-between"
            align="middle"
            className={styles.margin16}
          >
            <Col>
              <Typography.Title className={styles.title} level={3}>
                Upload Factors
              </Typography.Title>
            </Col>
            <Col>
              <StyledButton
                className="margin-5"
                type="custom"
                padding={"5px 12px"}
                bgcolor={"#7F5FEE"}
                bordercolor={"#7F5FEE"}
                onClick={handleCreateSampleExcel}
              >
                Download Sample Excel
              </StyledButton>
            </Col>
          </Row>
          <Row className={styles.margin16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Typography.Title level={5} className="white">
                Upload source file
              </Typography.Title>
            </Col>
          </Row>
          <Row className={styles.margin16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Typography.Title level={5} className="color-grey">
                Upload file you want to add to the system
              </Typography.Title>
            </Col>
          </Row>
          <Row className={styles.margin16} justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Alert
                message={
                  "The file should contain a single column with one of the following names: name / region / region_name / sector / category / source."
                }
                type="warning"
                showIcon
              />
            </Col>
            <StyledDivider />
          </Row>

          <Upload.Dragger
            fileList={[]}
            customRequest={({ file, onSuccess }) => {
              onSuccess && onSuccess("ok");
            }}
            beforeUpload={readFile}
            className={`${styles.uploader} ${styles.margin16}`}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined className="color-white" />
            </p>
            <p className="ant-upload-text color-white">
              Drag file to this area to upload
            </p>
            <p className="ant-upload-hint color-white">
              Supports for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Upload.Dragger>
          <Row align="top" className={styles.margin16}>
            <Col span={2}>or</Col>
            <Col span={22}>
              <Upload
                customRequest={({ file, onSuccess }) => {
                  onSuccess && onSuccess("ok");
                }}
                fileList={files}
                beforeUpload={readFile}
                onRemove={onRemoveFileList}
              >
                <StyledButton
                  type="custom"
                  margin={"0px"}
                  padding={"5px 12px"}
                  bgcolor={"#7F5FEE"}
                  bordercolor={"#7F5FEE"}
                >
                  <PlusCircleOutlined />
                  <span>Upload file</span>
                </StyledButton>
              </Upload>
            </Col>
          </Row>
        </FormWrapper>
      </CommonModal>
    );
  }
);
