import React from "react";
import { Typography } from "antd";
import { CommonModal } from "../../shared/commonStyles";

const { Text } = Typography;
const ConfirmModal = ({
  visible,
  onClose,
  title,
  description,
  onConfirm,
  ConfirmLoader,
  okText,
}) => {
  return (
    <CommonModal
      centered
      title={title}
      visible={visible}
      okText={okText}
      onOk={onConfirm}
      onCancel={onClose}
      confirmLoading={ConfirmLoader}
    >
      <Text>{description}</Text>
    </CommonModal>
  );
};

export default ConfirmModal;
