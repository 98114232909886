import React, { useState, useEffect } from "react";
import { message, Space, Dropdown, Menu, Row, Col, Tag } from "antd";
import moment from "moment";
import { YearMonthDayFormat } from "../../../shared/constants";
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import {
  CommonTable,
  StyledButton,
  TableStyledMenu,
  FormWrapper,
  StyledTag,
} from "../../../shared/commonStyles";
import { UpdateTaskDeploymentModal } from "./../../../components/Modals/CreateTaskDeployment/Components/UpdateTaskDeploymentModal";
import ConfirmModal from "./../../../components/ConfirmModal/ConfirmModal";
import {
  removeTaskDeployment,
  getTaskDeploymentsBySurveyId,
  sendEmailReminderToUser,
} from "./../../../services/taskDeployment";
import { MyResponsivePieDeploy } from "../../deploySurvey/Components/CustomPieChart";
import { MyCustomLineChart } from "../Components/CustomLineChart";
import {
  getModifiedForMetaData,
  getSheetById,
} from "../../../services/dataSheet";
import { getSurveyById } from "../../../services/surveys";
import { getTaskColor } from "../../viewSurveyForAdmin/Components/SurveyFunctions";
import { getUserGroupById } from "../../../services/users";
const DeploySurveyDataTable = () => {
  const { survey_id: surveyId } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshAllDeploySurveyData, setRefreshAllDeploySurveyData] =
    useState(1);
  const [loaderDeletingDeploySurvey, setLoaderDeletingDeploySurvey] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUserReminderConfirmModal, setShowUserReminderConfirmModal] =
    useState(false);
  const [showUpdateTaskDeploymentModal, setShowUpdateTaskDeploymentModal] =
    useState(false);
  const [reminderLoader, setReminderLoader] = useState(false);

  const { checkPermissions } = useCheckRolesPermissions();
  const { state } = useLocation();
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [emailFilter, setEmailFilter] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [entityFilter, setEntityFilter] = useState([]);
  const menu = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key={record.id + 1}
        onClick={() => handleEditDeploySurvey(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<EditOutlined />}
        >
          Edit
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key={record.id + 2}
        onClick={() => handleDeleteDeploySurvey(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  const getEntityName = async (sheetId, entityId) => {
    const payload = {
      skip: 0,
      limit: 10,
      filter: {
        sheet_id: sheetId,
      },
    };
    const metaDataRecord = await getModifiedForMetaData(payload);
    const dataSheet = await getSheetById(sheetId);
    const identifierField =
      dataSheet.sheet_schema.find((v) => v.isIdentifier).entity_name || "";
    if (metaDataRecord) {
      const records = metaDataRecord[metaDataRecord?.sheet_name];
      const filteredRecordValue = records?.find(
        (item) => item.id === entityId
      )?.[identifierField];

      return filteredRecordValue;
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await getTaskDeploymentsBySurveyId(surveyId);
      const deploySurveyData = response.items.map((record) => {
        return {
          ...record,
          key: record.id,
        };
      });
      const newData = await Promise.all(
        deploySurveyData?.map(async (task) => {
          const newTask = task;
          if (task.assignedGroup) {
            const assignedGroupData = await getUserGroupById(
              task.assignedGroup
            );
            newTask.assignedGroupName = assignedGroupData.name;
          }
          if (task.entityId) {
            const survey = await getSurveyById(surveyId);
            const entityName = await getEntityName(
              survey.defaultMetaDataSheetId,
              task.entityId
            );
            newTask.entityName = entityName;
          }
          return newTask;
        })
      );
      setDataSource(newData);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [refreshAllDeploySurveyData, state]);

  const handleUserReminder = (rowData) => {
    setRowData(rowData);
    setShowUserReminderConfirmModal(true);
  };

  const onRefreshAllDeploySurveyData = () => {
    setRefreshAllDeploySurveyData(refreshAllDeploySurveyData + 1);
  };

  const handleEditDeploySurvey = (rowData) => {
    setRowData(rowData);
    setShowUpdateTaskDeploymentModal(true);
  };

  const handleDeleteDeploySurvey = (rowData) => {
    setRowData(rowData);
    setShowConfirmModal(true);
  };

  const onConfirmDeploySurveyDelete = async () => {
    if (!rowData) return;
    try {
      setLoaderDeletingDeploySurvey(true);
      const payload = {};

      payload.id = rowData.id;

      await removeTaskDeployment(payload);

      onRefreshAllDeploySurveyData();
      message.success("Removed successfully!");
      setShowConfirmModal(false);
    } catch (error) {
      console.log("error while deleting document.", error);
      message.error("error while deleting document.");
    } finally {
      setLoaderDeletingDeploySurvey(false);
    }
  };

  const onUserReminderConfirmed = async () => {
    try {
      setReminderLoader(true);
      const response = await sendEmailReminderToUser(
        rowData.status === "Not contacted" ? "SURVEY" : "SURVEY_REMINDER",
        rowData.id
      );

      if (response) {
        message.success("Reminder notification send successfully!");
        setShowUserReminderConfirmModal(false);
      }
    } catch (error) {
      message.error("Something went wrong  while sending task notification!");
    } finally {
      onRefreshAllDeploySurveyData();
      setReminderLoader(false);
    }
  };

  const columns = [
    {
      title: "User/Group",
      dataIndex: "email",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      filteredValue: tableFilters.filteredInfo?.email
        ? tableFilters.filteredInfo.email
        : null,
      filters: emailFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) => record?.email?.indexOf(value) === 0,
      sortOrder:
        tableFilters.sortedInfo?.field === "email" &&
        tableFilters.sortedInfo?.order,
      render: (data, record) => {
        return record.assignedGroup ? record.assignedGroupName : data;
      },
    },
    {
      title: "Entity",
      dataIndex: "entityName",
      sorter: (a, b) =>
        (a?.entityName || "")?.localeCompare(b?.entityName || ""),
      filters: entityFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) => record?.entityName?.indexOf(value) === 0,
      filteredValue: tableFilters.filteredInfo?.entityName
        ? tableFilters.filteredInfo.entityName
        : null,
      sortOrder:
        tableFilters.sortedInfo?.field === "entityName" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
      sorter: (a, b) => a.description?.localeCompare(b.description),

      sortDirections: ["descend", "ascend"],
      filteredValue: tableFilters.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
      filters: descriptionFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) => record?.description?.indexOf(value) === 0,
      sortOrder:
        tableFilters.sortedInfo?.field === "description" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      width: "20%",
      sorter: (a, b) => a.priority - b.priority,
      sortDirections: ["descend", "ascend"],
      filteredValue: tableFilters.filteredInfo?.priority
        ? tableFilters.filteredInfo.priority
        : null,
      sortOrder:
        tableFilters.sortedInfo?.field === "priority" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Last Contacted",
      dataIndex: "last_contacted",
      width: "20%",
      sorter: (a, b) => a.last_contacted.localeCompare(b.last_contacted),
      sortDirections: ["descend", "ascend"],
      filteredValue: tableFilters.filteredInfo?.last_contacted
        ? tableFilters.filteredInfo.last_contacted
        : null,
      sortOrder:
        tableFilters.sortedInfo?.field === "last_contacted" &&
        tableFilters.sortedInfo?.order,

      render: (_, record) => {
        return record.last_contacted
          ? moment(record.last_contacted).format(YearMonthDayFormat)
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],

      filteredValue: tableFilters.filteredInfo?.status
        ? tableFilters.filteredInfo.status
        : null,
      filters: statusFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) => record?.status?.indexOf(value) === 0,
      sortOrder:
        tableFilters.sortedInfo?.field === "status" &&
        tableFilters.sortedInfo?.order,

      render: (record) => {
        return <StyledTag bgcolor={getTaskColor(record)}>{record}</StyledTag>;
      },
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "6%",
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton
            disabled={state.surveyStatus === "INACTIVE"}
            type="default"
            onClick={() => handleUserReminder(record)}
            icon={<BellOutlined />}
          />
          {checkPermissions(["manage-collect-data"]) && (
            <Dropdown overlay={() => menu(record)} trigger={["click"]}>
              <StyledButton type="default" icon={<EllipsisOutlined />} />
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (dataSource.length > 0) {
      const emailFilter = dataSource.reduce((arr, val) => {
        if ("email" in val && !arr.includes(val["email"])) {
          arr.push(val["email"]);
        }
        return arr;
      }, []);
      setEmailFilter(emailFilter);

      const statusFilter = dataSource.reduce((arr, val) => {
        if ("status" in val && !arr.includes(val["status"])) {
          arr.push(val["status"]);
        }
        return arr;
      }, []);
      setStatusFilter(statusFilter);
      const descriptionFilter = dataSource.reduce((arr, val) => {
        if ("description" in val && !arr.includes(val["description"])) {
          arr.push(val["description"]);
        }
        return arr;
      }, []);
      setDescriptionFilter(descriptionFilter);
      const entityFilter = dataSource.reduce((arr, val) => {
        if ("entityName" in val && !arr.includes(val["entityName"])) {
          arr.push(val["entityName"]);
        }
        return arr;
      }, []);
      setEntityFilter(entityFilter);
    }
  }, [dataSource]);
  const handleTableChange = (pagination, filters, sorter) => {
    loadData({ pagination });
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          description="Are you sure you want to delete this task deployment?"
          visible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmDeploySurveyDelete}
          ConfirmLoader={loaderDeletingDeploySurvey}
          okText="Delete"
        />
      )}
      {showUserReminderConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          description="Are you sure you want to remind the user?"
          visible={showUserReminderConfirmModal}
          onClose={() => setShowUserReminderConfirmModal(false)}
          onConfirm={() => onUserReminderConfirmed()}
          ConfirmLoader={reminderLoader}
        />
      )}
      {showUpdateTaskDeploymentModal && (
        <UpdateTaskDeploymentModal
          isVisible={showUpdateTaskDeploymentModal}
          onClose={() => setShowUpdateTaskDeploymentModal(false)}
          surveyId={surveyId}
          surveyName={state.surveyName}
          rowData={rowData}
          onRefreshAllDeploySurveyData={onRefreshAllDeploySurveyData}
        />
      )}
      <FormWrapper>
        <Row gutter={[16, 16]}>
          {dataSource.length > 0 && (
            <>
              <Col span={5}>
                <div style={{ background: "#2c263f", padding: "20px" }}>
                  <MyResponsivePieDeploy surveyId={surveyId} />
                </div>
              </Col>
              <Col span={19}>
                <div style={{ background: "#2c263f", padding: "20px" }}>
                  <MyCustomLineChart surveyId={surveyId} />
                </div>
              </Col>
            </>
          )}
          <Col span={24}>
            <CommonTable
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: true }}
              pagination={true}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </FormWrapper>
    </>
  );
};

export default DeploySurveyDataTable;
