import React, { useState } from "react";
import { ExportCalculations } from "./ExportCalculations";
import {
  StyledButton,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";
import { Link } from "react-router-dom";
import {
  PlusCircleOutlined,
  ExportOutlined,
  EllipsisOutlined,
  ReloadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { GroupCalculationsModal } from "./GroupCalculationsModal";
import { Dropdown, Menu, Row } from "antd";
import { calculationStore } from "../../../../Carbon/CalculationsStore";
import { observer } from "mobx-react-lite";
import { calculationGroupsStore } from "../../../../Carbon/CalculationGroupsStore";
import { getJobExecutionStatus } from "../../../../../services/importData";
import { message } from "antd";
import { useCheckRolesPermissions } from "../../../../../hooks/useCheckRolesPermissions";
export const CalculationsButtons = observer(() => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [groupModal, setGroupModal] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const fetchDataWithRetry = async (job_id: string | null) => {
    const maxRetries = 100;
    let retryCount = 0;
    calculationGroupsStore.dataRetry = true;
    while (retryCount < maxRetries) {
      try {
        const payload = {
          id: job_id,
        };
        const response = await getJobExecutionStatus(payload);

        if (!response.status) {
          throw new Error("Failed to fetch data");
        }

        if (response.status != "IN_PROGRESS") {
          calculationGroupsStore.dataRetry = false;
          return response;
        }
        retryCount++;
        if (retryCount < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } catch (error) {
        console.error("Error fetching data:");
        retryCount++;
        if (retryCount < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          throw new Error("Max retries reached");
        }
      }
    }
  };
  const handleRefreshJob = async () => {
    try {
      const jobId = await calculationStore.refreshCalculations_job(
        calculationGroupsStore.fetchCalculationGroups
      );
      calculationGroupsStore.loadingGroups = true;
      const result = await fetchDataWithRetry(jobId);
      const resultSummary = result?.resultSummary || "";

      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        result?.resultSummary?.message &&
          message.success(resultSummary?.message);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      message.error("Error while refreshing calculations!");
    } finally {
      calculationGroupsStore.loadingGroups = false;
    }
  };
  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="export">
        <StyledButton
          onClick={() => setExportModalVisible(true)}
          disabled={!calculationStore?.savedCalculations?.length}
          type="primary"
          icon={<ExportOutlined />}
        >
          Export Report
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="group">
        <StyledButton type="primary" onClick={() => setGroupModal(true)}>
          <DatabaseOutlined /> Create calculation group
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="refresh">
        <StyledButton type="primary" onClick={() => handleRefreshJob()}>
          <ReloadOutlined /> <span>Refresh Calculations</span>
        </StyledButton>
      </Menu.Item>
    </TableCommonMenu>
  );
  return (
    <>
      {checkRolesPermission(["role:elevateduser"]) ? (
        <Row>
          <StyledButton
            onClick={() => setExportModalVisible(true)}
            disabled={!calculationStore?.savedCalculations?.length}
            type="primary"
            icon={<ExportOutlined />}
          >
            Export Report
          </StyledButton>
        </Row>
      ) : (
        <Row>
          <StyledButton type="primary">
            <Link to="/carbon/new-calculation">
              <PlusCircleOutlined /> <span>Create new calculation</span>
            </Link>
          </StyledButton>
          <Dropdown overlay={moreButtons} trigger={["click"]}>
            <StyledButton
              style={{
                height: "44px",
                padding: "0px 18px ",
              }}
              type="default"
              icon={<EllipsisOutlined style={{ fontSize: "18px" }} />}
            />
          </Dropdown>
        </Row>
      )}
      <GroupCalculationsModal
        visible={groupModal}
        onClose={() => setGroupModal(false)}
      />
      <ExportCalculations
        visible={exportModalVisible}
        onClose={() => setExportModalVisible(false)}
      />
    </>
  );
});
