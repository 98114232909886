import { Row, Col, Form } from "antd";
import React, { useState, useEffect } from "react";
import { Auth, Storage } from "aws-amplify";
import "./style.css";
import {
  FormWrap,
  FormWrapper,
  StyledTitle,
  StyledButton,
  AppRowContainer,
} from "../../../../shared/commonStyles";
import { staticImagesList } from "../../../../shared/constants";

const step2 = ({
  step,
  setStep,
  primary,
  setPrimary,
  secondary,
  setSecondary,
  reportTitleColor,
  setReportTitleColor,
  reportDescriptionColor,
  setReportDescriptionColor,
  tableHeaderTextColor,
  setTableHeaderTextColor,
  topicTextColor,
  setTopicTextColor,
  setShowGeneratedReport,
  setGeneratedReportUsingActiveJs,
  onClickPrevious,
}) => {
  const [a_icon, setIcon] = useState(null);

  const getFile = async () => {
    const data = await Auth.currentSession();
    const groups = data["accessToken"]["payload"]["cognito:groups"].filter(
      (element) => element.includes("org:")
    )[0];
    const a_icon = await Storage.get(
      `logos/${groups.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.png`
    );
    setIcon(a_icon);
  };

  useEffect(() => {
    getFile();
  }, []);

  const rightBoxStyle = {
    width: "40%",
    backgroundColor: "#2D273F",
    padding: "35px 20px",
  };

  return (
    <FormWrap
      width="750px"
      height="600px"
      style={{ marginTop: "50px", backgroundColor: "#3C3453", padding: "0px" }}
    >
      <AppRowContainer>
        <FormWrapper style={{ width: "60%", margin: "auto" }}>
          <div className="reviewContianer">
            <Row
              style={{
                backgroundColor: primary,
                position: "relative",
                height: "100px",
              }}
              className="row"
            >
              <div className="header">
                <div className="title">
                  <h3 style={{ color: reportTitleColor }}>Title</h3>
                </div>
                <div className="description">
                  <p style={{ color: reportDescriptionColor }}>Description</p>
                </div>
                <div className="logo">
                  <img
                    src={a_icon ? a_icon : ""}
                    width="70"
                    alt="hydrus logo"
                  />
                </div>
              </div>
            </Row>
            <Row
              className="tableHeader row"
              style={{ backgroundColor: secondary }}
            ></Row>
            <Row
              className="reportTitle row"
              style={{ backgroundColor: "white" }}
            ></Row>
            <Row
              className="reportDesc row"
              style={{ backgroundColor: "white" }}
            >
              <span style={{ color: "#929292" }}>powered by</span>
              <img
                src={staticImagesList.hydrusGreyLogo}
                alt="hydrus logo"
                style={{ width: "80px" }}
              />
            </Row>
          </div>
        </FormWrapper>
        <FormWrapper style={rightBoxStyle}>
          <Row gutter={[16, 16]} style={{ rowGap: "0px" }}>
            <Col span={24}>
              <StyledTitle level={4}>Select a color</StyledTitle>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Main heading">
                <input
                  style={{ width: 110, height: 35 }}
                  htmlFor="primary"
                  type="color"
                  id="primary"
                  name="primary"
                  value={primary}
                  onChange={(e) => setPrimary(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Table heading">
                <input
                  style={{ width: 110, height: 35 }}
                  type="color"
                  id="secondary"
                  name="secondary"
                  value={secondary}
                  onChange={(e) => setSecondary(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Table heading text">
                <input
                  style={{ width: 110, height: 35 }}
                  type="color"
                  id="primary"
                  name="primary"
                  value={tableHeaderTextColor}
                  onChange={(e) => setTableHeaderTextColor(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Report topics text">
                <input
                  style={{ width: 110, height: 35 }}
                  type="color"
                  id="primary"
                  name="primary"
                  value={topicTextColor}
                  onChange={(e) => setTopicTextColor(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Report title">
                <input
                  style={{ width: 110, height: 35 }}
                  type="color"
                  id="primary"
                  name="primary"
                  value={reportTitleColor}
                  onChange={(e) => setReportTitleColor(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} label="Report description">
                <input
                  style={{ width: 110, height: 35 }}
                  type="color"
                  id="primary"
                  name="primary"
                  value={reportDescriptionColor}
                  onChange={(e) => setReportDescriptionColor(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <StyledButton
              type="custom"
              onClick={() => onClickPrevious()}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              style={{ padding: "11px 5px 11px 0px" }}
            >
              <span>Back</span>
            </StyledButton>
            <StyledButton
              className="margin-5"
              onClick={() => {
                setStep(step + 1);
                setGeneratedReportUsingActiveJs(true);
              }}
              type="custom"
            >
              <span>Generate Report</span>
            </StyledButton>
          </Row>
        </FormWrapper>
      </AppRowContainer>
    </FormWrap>
  );
};

export default step2;
