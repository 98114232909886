import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Form, message, Progress, Row, Space, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGoalSamples } from "../../../../services/goals";
import moment from "moment";

import {
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../../../shared/commonStyles";

const { Step } = Steps;
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import Step5 from "./components/step5";
import { v4 as uuidv4 } from "uuid";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  addGolasAndProgress,
  updateGolasAndProgress,
  getGolasAndProgressById,
} from "../../../../services/goals";

const CreateSdgGoal = () => {
  const { sdg_id: sdgId } = useParams();
  const [loader, setLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [action, setAction] = useState("create");
  const [rowData, setRowData] = useState(null);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [allGoalSamples, setAllGoalSamples] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    dateRange: "",
    userIds: [],
    groupIds: [],
    subGoals: [],
    subGoalsInitiative: [],
    relatedItems: [
      {
        type: null,
        value: null,
      },
    ],
  });

  const getGoalSamplesData = async () => {
    try {
      setLoader(true);
      const goalSamples = await getGoalSamples();
      setAllGoalSamples(goalSamples);
    } catch (e) {
      message.error(`Some problem occured while fetching Goal Samples ${e}`);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getGoalSamplesData();
  }, []);

  const getGoalData = async () => {
    const goalData = await getGolasAndProgressById(sdgId);
    if (goalData) {
      setRowData(goalData);
      setAction("update");
      const today = moment();
      const startDate = goalData?.start_date
        ? moment(goalData.start_date, YearMonthDayFormat)
        : null;
      const endDate = goalData?.end_date
        ? moment(goalData.end_date, YearMonthDayFormat)
        : null;
      const dateRange = startDate && endDate ? [startDate, endDate] : "";
      const userIds = JSON.parse(goalData?.assigned_users);
      const groupIds = JSON.parse(goalData?.assigned_groups);
      const relatedItems = goalData?.related_items
        ? JSON.parse(goalData?.related_items)
        : [];

      const indicators = JSON.parse(goalData?.sdg_indicators);

      const iniValue = {
        name: goalData?.name,
        description: goalData?.description,
        dateRange,
        userIds,
        groupIds,
        relatedItems,
        selectedGoals: goalData?.sdg_num,
        // subGoals: indicators && indicators[0] ? indicators[0]?.subGoals : [],
        // subGoalsInitiative:  indicators && indicators[1] ? indicators[1]?.subGoalsInitiative : [],
        subGoals:
          indicators && Object.keys(indicators[0]).length > 0
            ? indicators[0]?.subGoals
            : [],
        subGoalsInitiative:
          indicators && Object.keys(indicators[1]).length > 0
            ? indicators[1]?.subGoalsInitiative
            : [],
      };
      form.setFieldsValue(iniValue);
      setInitialValues(iniValue);
    }
  };

  useEffect(() => {
    if (sdgId) {
      getGoalData();
    }
  }, [sdgId]);

  const onStepChange = async (selectedStep) => {
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  // const onClickNext = async () => {
  //   try {
  //     await form.validateFields();
  //     if (step === 2) {
  //       const { subGoals, subGoalsInitiative } = initialValues;
  //       if (!subGoals && !subGoalsInitiative) {
  //         message.error(
  //           "Please select at least one SDG sub goal or SDG sub goal initiative"
  //         );
  //         return;
  //       }
  //     }
  //     setStep(step + 1);
  //   } catch (errorInfo) {
  //     console.log("Failed:", errorInfo);
  //   }
  // };
  const onClickNext = async () => {
    try {
      await form.validateFields();
      if (step === 2) {
        const { subGoals, subGoalsInitiative } = initialValues;
        if (
          (!subGoals && !subGoalsInitiative) ||
          (subGoals.length === 0 && subGoalsInitiative.length === 0)
        ) {
          message.error(
            "Please select at least one SDG sub goal or SDG sub goal initiative"
          );
          return;
        }

        // if (subGoals.length === 0 && subGoalsInitiative.length === 0) {
        //   message.error(
        //     "Please select at least one SDG sub goal or SDG sub goal initiative"
        //   );
        //   return;
        // }
      }
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  useEffect(() => {
    console.log("initialValues", initialValues);
    const { subGoals, subGoalsInitiative } = initialValues;
    if (subGoals && subGoalsInitiative) {
      console.log("initialValues subGoals length", subGoals.length);
      console.log(
        "initialValues subGoalsInitiative length",
        subGoalsInitiative.length
      );
    }
  }, [initialValues]);

  const onChangeFieldsData = (data) => {
    let newInitialValues = {};
    const formValues = form.getFieldsValue(true);
    Object.keys(formValues)?.forEach((key) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = formValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem = { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };
            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          newInitialValues = { ...newInitialValues, [key]: formValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const onSubmitForm = async () => {
    form.validateFields().then(async () => {
      try {
        setSubmitLoader(true);
        const {
          name,
          description,
          userIds,
          groupIds,
          dateRange,
          relatedItems,
          selectedGoals,
          subGoals,
          subGoalsInitiative,
        } = initialValues;
        if (!subGoals && !subGoalsInitiative) {
          message.error(
            "Please select at least one SDG sub goal or SDG sub goal initiative"
          );
          return;
        }
        const startdate = dateRange
          ? dateRange[0].format(YearMonthDayFormat)
          : null;
        const enddate = dateRange
          ? dateRange[1].format(YearMonthDayFormat)
          : null;
        const payload = {
          id: action === "update" ? rowData.id : uuidv4(),
          name,
          parent_id: 0,
          description,
          start_date: startdate,
          end_date: enddate,
          status: action === "update" ? rowData.status : "IN_PROGRESS",
          assigned_users: JSON.stringify(userIds),
          assigned_groups: JSON.stringify(groupIds),
          related_items: JSON.stringify(relatedItems),
          goal_type: "SDG_LONG",
          sdg_num: selectedGoals,
          sdg_indicators: JSON.stringify([
            { subGoals: subGoals },
            { subGoalsInitiative: subGoalsInitiative },
          ]),
        };
        let response = null;
        if (action === "update") {
          response = await updateGolasAndProgress(payload);
        } else {
          response = await addGolasAndProgress(payload);
        }
        if (response) {
          setSubmitLoader(false);
          setStep(step + 1);
        } else {
          message.success("Failed to add goals!");
        }
      } catch (error) {
        message.error(`Something went wrong while adding goals ${error}`);
      } finally {
        setSubmitLoader(false);
      }
    });
  };

  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return (
        <Step1 onClickNext={onClickNext} onClickPrevious={onClickPrevious} />
      );
    } else if (step === 2) {
      return (
        <Step2
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          initialValues={initialValues}
          allGoalSamples={allGoalSamples}
        />
      );
    } else if (step === 3) {
      return (
        <Step3 onClickNext={onClickNext} onClickPrevious={onClickPrevious} />
      );
    } else if (step === 4) {
      return (
        <Step4
          onClickPrevious={onClickPrevious}
          setInitialValues={setInitialValues}
          initialValues={initialValues}
          form={form}
          onSubmitForm={onSubmitForm}
          submitLoader={submitLoader}
        />
      );
    } else if (step === 5) {
      return <Step5 action={action} />;
    } else {
      console.log("completed");
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loader}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
                onClick={() => navigate("/management/goals-and-progress")}
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                <>
                  <Step
                    className="stepper-wrapper "
                    key={1}
                    title={<span>SDG Selection</span>}
                  />
                  <Step
                    className="stepper-wrapper"
                    key={2}
                    title={<span>Indicator Selection</span>}
                  />
                  <Step
                    className="stepper-wrapper"
                    key={3}
                    title={<span>Custom Goal Creation</span>}
                  />
                  <Step
                    className="stepper-wrapper"
                    key={4}
                    title={<span>Related items</span>}
                  />
                </>
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 33.33}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};

export default CreateSdgGoal;
