import { Button, Dropdown, Input, Radio, Row, Typography } from "antd";
import React, { useState } from "react";
import { FormWrapper, StyledButton } from "../../shared/commonStyles";
import { CommentOutlined, WarningOutlined } from "@ant-design/icons";
import styles from "./CreateNotePopup.module.scss";

interface CreateNotePopupProps {
  onConfirm: (note: string, noteType: "comment" | "warning") => void;
  columnData: any;
  isNotes: boolean;
}

export const CreateNotePopup = ({
  onConfirm,
  columnData,
  isNotes,
}: CreateNotePopupProps) => {
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [noteType, setNoteType] = useState<"comment" | "warning">("warning");
  const [note, setNote] = useState("");
  const handleClose = () => {
    setNote("");
    setNoteType("warning");
    setIsNotesModalVisible(false);
  };

  const handleConfirm = () => {
    onConfirm(note, noteType);
    handleClose();
  };
  const noteContent = (
    <FormWrapper>
      <Radio.Group
        value={noteType}
        onChange={(e) => setNoteType(e.target.value)}
        className={`${styles.radio} ${
          noteType === "warning" && styles.radioWarning
        }`}
      >
        <Radio.Button value={"warning"}>
          <span
            className={noteType === "warning" ? "" : styles.radioWarningText}
          >
            <WarningOutlined /> Warning
          </span>
        </Radio.Button>
        <Radio.Button value={"comment"}>
          <span>
            <CommentOutlined /> Comment
          </span>
        </Radio.Button>
      </Radio.Group>
      <Typography.Title level={5}>
        Add a {noteType} to this record
      </Typography.Title>
      <Input.TextArea
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Enter note"
      />
      <Row align="middle" style={{ marginTop: 10 }}>
        <Button type="text" onClick={handleClose}>
          Cancel
        </Button>
        <StyledButton
          bgcolor={noteType === "warning" && "#d8be3b"}
          onClick={handleConfirm}
        >
          {noteType === "comment" ? "Add Comment" : "Add Warning"}
        </StyledButton>
      </Row>
    </FormWrapper>
  );

  return (
    <Dropdown
      overlayClassName={`${styles.dropDown} ${
        noteType === "comment" ? styles.dropDownComment : styles.dropDownWarning
      }`}
      overlay={noteContent}
      visible={isNotesModalVisible}
      onVisibleChange={(open) => {
        setIsNotesModalVisible(open);
        setNote("");
        setNoteType("warning");
      }}
      trigger={["click"]}
    >
      <div
        className={`${styles.dropDownChildren} ${
          !isNotes && styles.dropDownChildrenEmpty
        }`}
        onClick={(e) => e.preventDefault()}
      >
        {columnData}
      </div>
    </Dropdown>
  );
};
