import React, { useState, useEffect, useContext } from "react";
import { Upload, message, Spin, Row, Col } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { downloadDataSheetAuditFileHandler } from "../../../../shared/commonFunctions";
import { fileUploadSize, fileUploadTypes } from "../../../../shared/constants";
import { StyledButton } from "../../../../shared/commonStyles";
import { AuthContext } from "../../../../contexts/AuthContext";
import { listDataSheetAuditFiles } from "../../../../services/dataSheet";

const AuditorsFilesModal = ({
  rowData,
  setFiles,
  files,
  setAuditRecord,
  auditRecord,
  refreshUploadedFiles,
}) => {
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const { auth } = useContext(AuthContext);

  const getAuditUniqueId = (rowData) => {
    try {
      const username = auth["auth"]["accessToken"]["payload"]["username"];
      const sheetName = rowData["sheet_name"];
      const dataSheetAudit = rowData[sheetName + " Audit"];
      const auditorRecord = dataSheetAudit?.find(
        (item) => item.username === username
      );
      setAuditRecord(auditorRecord);
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (rowData) {
      getAuditUniqueId(rowData);
    }
  }, [rowData]);
  const loadDataLakeFiles = async () => {
    setLoaderUploadedFilesList(true);
    try {
      const filesList = await listDataSheetAuditFiles(auditRecord?.id);
      const list = filesList
        ?.replace(/['"]+/g, "")
        .replace(/^\[(.+)\]$/, "$1")
        .split(",");
      setUploadedFilesList(list);
    } catch (error) {
      console.log("Error while fetching data sheet files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  useEffect(() => {
    if (auditRecord?.id) {
      loadDataLakeFiles();
    }
  }, [auditRecord, refreshUploadedFiles]);

  const beforeUpload = async (file) => {
    const filename = file?.name;
    const fileSize = file?.size;
    if (fileSize > fileUploadSize) {
      message.error(`File upload size is larger than 25MB`);
      return;
    }
    const ext = filename && filename.split(".")?.pop()?.toLowerCase();
    if (fileUploadTypes.includes(ext)) {
      setFiles([...files, file]);
    } else {
      message.error(`${file?.name} is not a valid file type`);
    }
  };

  const onRemoveFileFromListHandler = (file) => {
    const filesList = files.filter((v) => v.url !== file.url);
    setFiles(filesList);
  };

  const downloadFileHandler = async (key) => {
    try {
      setLoaderUploadedFilesList(true);
      await downloadDataSheetAuditFileHandler(key, auditRecord?.id);
    } catch (error) {
      console.log("Error while upload file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };
  return (
    <div className="file-upload" style={{ padding: 10 }}>
      {auditRecord?.id && (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <p className="color-white">Attach Related Files (optional)</p>
            <Upload
              customRequest={({ file, onSuccess }) => {
                onSuccess("ok");
              }}
              beforeUpload={beforeUpload}
              fileList={files}
              multiple={false}
              onRemove={onRemoveFileFromListHandler}
            >
              <StyledButton
                style={{ marginTop: 10 }}
                type="custom"
                icon={<UploadOutlined />}
              >
                Upload
              </StyledButton>
            </Upload>
            <Spin spinning={loaderUploadedFilesList}>
              {uploadedFilesList &&
                uploadedFilesList?.length > 0 &&
                uploadedFilesList?.map((item, index) => {
                  const fileName = item && item?.split("/").pop();
                  return (
                    <Row key={index}>
                      <Col span={24}>
                        <StyledButton
                          type="custom"
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="#A68DFB"
                          className="margin-2"
                          icon={<LinkOutlined />}
                          onClick={() => downloadFileHandler(item)}
                          style={{ padding: "0" }}
                        >
                          {fileName}
                        </StyledButton>
                      </Col>
                    </Row>
                  );
                })}
            </Spin>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AuditorsFilesModal;
