import Styled from "styled-components";

export const StepperFormWrap = Styled.div`
   min-height : 400px;
   min-width: 546px;
   width: fit-content;
   background: #38304F;
   margin: 60px auto 50px;
   backdrop-filter: blur(8px);
   border-radius: 4px;
   padding: 35px 40px;
`;
