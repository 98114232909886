import React, { useState } from "react";
import { PasswordlessSignin } from "./PasswordlessSignin";
import { StyledButton, FormWrapper } from "../../shared/commonStyles";
import { Form, Input, Typography, message } from "antd";
import { Auth } from "aws-amplify";
import styles from "./PasswordlessSignup.module.scss";

export const PasswordlessSignup = ({ email_id, group_id, awsmobile }) => {
  const [form] = Form.useForm();
  const [isSignupDone, setIsSignupDone] = useState(false);
  const [isUserAlreadyexists, setIsUserAlreadyexists] = useState(false);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: email_id,
    name: "",
  });
  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };
  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }
  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0");
  }
  const createAccount = () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateAccount(true);
          const { name, email } = initialValues;
          const params = {
            username: email,
            password: getRandomString(30),
            attributes: {
              name: name,
              "custom:group_id": group_id,
            },
          };
          const response = await Auth.signUp(params);

          setIsSignupDone(true);
          const timer = setTimeout(() => {
            window.location.href = `/?email_id=${initialValues.email}&action=sign_in`;
          }, 5000);
          return () => clearTimeout(timer);
        } catch (error) {
          if (
            error &&
            error.message === "An account with the given email already exists."
          ) {
            setIsUserAlreadyexists(true);
          } else {
            setIsUserAlreadyexists(false);
            message.error(`Error while creating account ${error}`);
            console.log("error while creating account", error);
          }
        } finally {
          setLoadingCreateAccount(false);
        }
      })
      .catch((error) => console.log("error form in account", error));
  };

  const redirectToSignIn = () => {
    window.location.href = `/?email_id=${initialValues.email}&action=sign_in`;
  };
  return (
    <>
      {isSignupDone ? (
        <>
          <Typography.Title level={2} className={styles.title}>
            Success!
          </Typography.Title>
          <p className="color-grey">
            Sign up successful! You will be redirected to sign-in in 5 seconds.
            Or
            <StyledButton
              type="custom"
              onClick={() => redirectToSignIn()}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#A68DFB"
              className={styles.buttonSignin}
            >
              click here
            </StyledButton>
            to proceed to immediately.
          </p>
        </>
      ) : (
        <>
          {isUserAlreadyexists ? (
            <PasswordlessSignin email_id={email_id} awsmobile={awsmobile} />
          ) : (
            <>
              <Typography.Title level={2} className={styles.titleSignup}>
                Welcome To Hydrus
              </Typography.Title>
              <p className="color-grey">
                Hydrus simplifies the process of collecting sustainability data
              </p>
              <FormWrapper>
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  <Form.Item
                    className={styles.formItem}
                    name="email"
                    label="Email"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please Input Email Address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your email"
                      className={styles.input}
                    />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    labelCol={{ span: 24 }}
                    label="Full Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please Input Full Name!" },
                    ]}
                  >
                    <Input
                      placeholder="Enter your full name"
                      className={styles.input}
                    />
                  </Form.Item>
                  <Form.Item>
                    <StyledButton
                      className={`margin-5 ${styles.buttonSignup}`}
                      onClick={() => createAccount()}
                      type="custom"
                      loading={loadingCreateAccount}
                    >
                      Create an Account
                    </StyledButton>
                  </Form.Item>
                </Form>
              </FormWrapper>
            </>
          )}
        </>
      )}
    </>
  );
};
