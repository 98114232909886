import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Typography,
  DatePicker,
  Select,
  Radio,
  Space,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../shared/commonStyles";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { listAuditManagements } from "../../../services/audits";
import { getSurveysList } from "../../../services/surveys";
import {
  addGolasAndProgress,
  updateGolasAndProgress,
} from "../../../services/goals";
import { YearMonthDayFormat } from "../../../shared/constants";
import UsersList from './UsersList'
import UsersGroupList from './UsersGroupList'
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const LongTermGoalModal = ({
  visible,
  onClose,
  action,
  type,
  rowData = null,
}) => {
  const [loader, setLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false); 
  const [audits, setAudits] = useState([]);
  const [surveysList, setSurveysList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    dateRange: "",
    userIds: [],
    groupIds: [],
    category: "environmental",
    relatedItems: [
      {
        type: null,
        value: null,
      },
    ],
  });

  const loadData = async () => {
    try {
      setLoader(true);      
      const audits = await listAuditManagements();
      if (audits) {
        setAudits(audits);
      }
      const surveyResponse = await getSurveysList();
      if (surveyResponse?.items) {
        setSurveysList(surveyResponse.items);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (action === "edit" && rowData) {
      const today = moment();
      const startDate = rowData?.start_date
        ? moment(rowData.start_date, YearMonthDayFormat)
        : null;
      const endDate = rowData?.end_date
        ? moment(rowData.end_date, YearMonthDayFormat)
        : null;
      const dateRange = startDate && endDate ? [startDate, endDate] : '';
      const userIds = JSON.parse(rowData?.assigned_users);
      const groupIds = JSON.parse(rowData?.assigned_groups);
      const relatedItems = rowData?.related_items
        ? JSON.parse(rowData?.related_items)
        : [];
      const iniValue = {
        name: rowData?.name,
        description: rowData?.description,
        dateRange,
        userIds,
        groupIds,
        relatedItems,
        category: rowData?.category,
      };
      form.setFieldsValue(iniValue);
      setInitialValues(iniValue);
    }
  }, [type]);

  const [form] = Form.useForm();
  const onSubmitForm = async () => {
    form.validateFields().then(async (val) => {
      try {
        setSubmitLoader(true);

        const {
          name,
          description,
          userIds,
          groupIds,
          dateRange,
          category,
          relatedItems,
        } = val;

        const startdate = dateRange
          ? dateRange[0].format(YearMonthDayFormat)
          : null;
        const enddate = dateRange
          ? dateRange[1].format(YearMonthDayFormat)
          : null;        
        let payload = null;
        if (action === "edit" && rowData) {
          payload = {
            id: rowData?.id,
            name,
            parent_id:
              action === "edit" && rowData
                ? rowData.parent_id
                : (type === "GEN_SHORT" || type === "SDG_SHORT") && rowData
                ? rowData?.id
                : 0,
            description,
            start_date: startdate,
            end_date: enddate,
            category: (type === "GEN_SHORT" || type === "SDG_SHORT") ? "" : category,
            status: "IN_PROGRESS",
            assigned_users: JSON.stringify(userIds),
            assigned_groups: JSON.stringify(groupIds),
            related_items: JSON.stringify(relatedItems),
            goal_type: type,
          };
        } else {
          payload = {
            id: uuidv4(),
            name,
            parent_id: (type === "GEN_SHORT" || type === "SDG_SHORT") && rowData ? rowData?.id : 0,
            description,
            start_date: startdate,
            end_date: enddate,
            category: (type === "GEN_SHORT" || type === "SDG_SHORT") ? "" : category,
            status: "IN_PROGRESS",
            assigned_users: JSON.stringify(userIds),
            assigned_groups: JSON.stringify(groupIds),
            related_items: JSON.stringify(relatedItems),
            goal_type: type,
          };
        }
        let response = null;
        if (action === "edit") {
          response = await updateGolasAndProgress(payload);
        } else {
          response = await addGolasAndProgress(payload);
        }
        if (response) {
          message.success("Goals saved successfully!");
          onClose();
          if (type === "GEN_LONG" && action === "create") {
            window.location.reload();
          }
        } else {
          message.success("Failed to add goals!");
        }
      } catch (error) {
        message.error(`Something went wrong while adding goals ${error}`);
      } finally {
        setSubmitLoader(false);
      }
    });
  };

  const onChangeFieldsData = (data) => {
    let newInitialValues = {};
    const formValues = form.getFieldsValue(true);
    Object.keys(formValues)?.forEach((key, index) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = formValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem = { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };
            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          newInitialValues = { ...newInitialValues, [key]: formValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const handleRelatedItem = (index) => {
    const formValues = form.getFieldsValue(true);
    const relatedItems = formValues?.relatedItems?.map((item, i) => {
      if (i === index) {
        item.value = null;
      }
      return { ...item };
    });
    setInitialValues({ ...formValues, ["relatedItems"]: relatedItems });
  };

  
  return (
    <CommonModal
      width={500}
      title={`${action === "edit" ? "Update" : "Create"} ${type === "GEN_SHORT" ? "Subgoal" : (type === "SDG_SHORT" ? 'SDG Subgoal' : "Goal")}`}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="Cancel" ghost onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="Create"
          loading={submitLoader}
          type="primary"
          onClick={onSubmitForm}
        >
          {action === "edit" ? "Update " : "Create "}
          {type === "GEN_SHORT" ? "Subgoal" : (type === "SDG_SHORT" ? 'SDG Subgoal' : "Goal")}
        </Button>,
      ]}
    >
      <Spin spinning={loader}>
        <FormWrapper>
          <Form
            form={form}
            initialValues={initialValues}
            onFieldsChange={(data) => onChangeFieldsData(data)}
          >
            <Form.Item labelCol={{ span: 24 }} name="name" label="Name" rules={[{ required: true, message: "Please input goal name!" }]} >
              <Input placeholder={"Enter name"} />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
            >
              <Input placeholder={"Enter Description"} />
            </Form.Item>
            <Form.Item
              name="dateRange"
              label="Specify a timeline for the goal"
              labelCol={{ span: 24 }}
              className="clearIcon"
            >
              <RangePicker
                placeholder={["Start date", "End date"]}
                style={{ background: "#625D75" }}
              />
            </Form.Item>
            
            <UsersList />
            <UsersGroupList />            
            {type === "GEN_LONG" && (
              <Form.Item
                name="category"
                label="Select category"
                labelCol={{ span: 24 }}
              >
                <Radio.Group style={{ marginTop: "5px" }}>
                  <Space direction="vertical" size="middle">
                    <Row>
                      <Col>
                        <Radio value="environmental">Environmental</Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio value="social">Social</Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio value="governance">Governance</Radio>
                      </Col>
                    </Row>
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}
            <Title level={5}>Attach related items</Title>
            <Form.Item label="">
              <Form.List name="relatedItems" key={"relatedItems"}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={[16, 16]}>
                        <Col span={10}>
                          <Form.Item name={[name, "type"]}>
                            <Select
                              placeholder="Select Field"
                              onChange={(value) =>
                                handleRelatedItem(name)
                              }
                            >
                              <Select.Option value="audits">
                                Audit
                              </Select.Option>                             
                              <Select.Option value="surveys">
                                Surveys
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item name={[name, "value"]}>
                            <Select placeholder="Select Value">
                              {initialValues?.relatedItems[name]?.type ===
                                "audits" &&
                                audits?.map((item, i) => {
                                  return (
                                    <Select.Option
                                      key={i}
                                      value={item?.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  );
                                })
                              }                              
                              {initialValues?.relatedItems[name]?.type ===
                                "surveys" &&
                                surveysList?.map((item, i) => {
                                  return (
                                    <Select.Option key={i} value={item?.id}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <StyledButton
                            type="default"
                            style={{ padding: "8px", marginTop: "0px" }}
                            onClick={() => remove(name)}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    ))}
                    <StyledButton type="custom" onClick={() => add()}>
                      <PlusCircleOutlined /> Attach Related Items
                    </StyledButton>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Form>
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};

export default LongTermGoalModal;
