import React from "react";
import { Checkbox, Collapse, Row, Space, Typography } from "antd";
import styles from "./SelectKPI.module.scss";

interface SelectKPIProps {
  dataSheetsWithKpi: any[];
  onChangeSelectionKPIs: (
    checked: boolean,
    itemId: string,
    type: "dataSheet" | "kpi"
  ) => void;
}

export const SelectKPI = ({
  dataSheetsWithKpi,
  onChangeSelectionKPIs,
}: SelectKPIProps) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Typography.Title level={3}>
        Select KPIs required for the current data collection survey
      </Typography.Title>
      <Collapse className={styles.collapse} collapsible="header">
        {dataSheetsWithKpi.map((item) => (
          <Collapse.Panel
            className={styles.collapsePanel}
            key={item?._id?.$oid}
            header=""
            extra={
              <Checkbox
                checked={item.checked}
                onChange={(e) =>
                  onChangeSelectionKPIs(
                    e.target.checked,
                    item?._id?.$oid,
                    "dataSheet"
                  )
                }
              >
                <span className="font-16">{item.sheet_name}</span>
              </Checkbox>
            }
          >
            {item?.kpiData?.map((v: any) => {
              return (
                <div className={styles.list} key={v?._id?.$oid}>
                  <Row className={styles.listItem} justify="space-between">
                    <Checkbox
                      checked={v.checked}
                      onChange={(e) =>
                        onChangeSelectionKPIs(
                          e.target.checked,
                          v?._id?.$oid,
                          "kpi"
                        )
                      }
                    >
                      <span className="font-14">{v.name}</span>
                    </Checkbox>
                  </Row>
                </div>
              );
            })}
          </Collapse.Panel>
        ))}
      </Collapse>
    </Space>
  );
};
