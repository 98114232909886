import * as queries from "../../graphql/queries";

import { Col, Form, Progress, Row, Space, Spin, Steps, message } from "antd";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import {
  getDataSheets,
  getPresignedUrl,
  getSheetById,
  listMetaDataRecords,
  makeQueryMetadata,
  metadataSheetTypesList,
  is_reference_sheet,
} from "../../services/dataSheet";
import { getImportDataById, removeImportData } from "../../services/importData";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { API } from "aws-amplify";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import { Step3PDF } from "./components/step3PDF";
import Step4 from "./components/step4";
import Step5 from "./components/step5";
import { checkPermission } from "../../services/permissions";
import { downloadDataSheetImportFileHandler } from "../../shared/commonFunctions";
import { getFilteredDataSheetColumns } from "../importNewData/common/utils.js";
import { getSurveyById } from "../../services/surveys";
import moment from "moment";
import { produce } from "immer";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { observer } from "mobx-react-lite";
import { userInfoStore } from "../Carbon/UserInfoStore";
import { encodeUrlName } from "../../shared/commonFunctions";

const { Step } = Steps;

const ImportNewData = observer(() => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [step, setStep] = useState(checkRolesPermission(["role:user"]) ? 2 : 1);
  const [loadingImportData, setLoadingImportData] = useState(false);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [initialValues, setInitialValues] = useState({
    source_file_type: "structured_file",
  });
  const [identifierValues, setIdentifierValues] = useState();
  const [identifierColumn, setIdentifierColumn] = useState();
  const [isPdfUploading, setIsPdfUploading] = useState(false);
  const [isPdfUploadFailed, setIsPdfUploadFailed] = useState(false);

  const [files, setFiles] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [dataSheet, setDataSheet] = useState({});

  const navigate = useNavigate();
  const { import_id: importId } = useParams();
  const { state } = useLocation();
  const [form] = Form.useForm();

  const [promptData, setPromptData] = useState();

  const handlePromptChange = (id, value) => {
    const updatedData = produce(promptData, (draft) => {
      const promptToUpdate = draft.find((draftItem) => draftItem.id === id);
      if (promptToUpdate) {
        promptToUpdate.value = value;
      }
    });
    setPromptData(updatedData);
  };

  // we are getting the file type here
  const fileType = form.getFieldValue("source_file_type");

  useEffect(() => {
    if (dataSheet && fileType === "ocr_file") {
      const filteredDataSheetColumns = getFilteredDataSheetColumns(
        dataSheet,
        identifierColumn,
        state
      );

      const initialPromptState = filteredDataSheetColumns?.map(
        (item, index) => {
          return {
            id: index,
            key: item.display_name,
            value: "",
          };
        }
      );

      setPromptData(initialPromptState);
    }
  }, [dataSheet]);

  useEffect(() => {
    setHeaders([]);
  }, [files]);

  useEffect(() => {
    const loadData = async () => {
      const payload = {
        filter: {
          archive: false,
          sheet_type: {
            $nin: metadataSheetTypesList,
          },
        },
      };
      const dataSheetsList = await getDataSheets(payload);
      setDataSheetsList(dataSheetsList);
    };

    loadData();
    userInfoStore.fetchUserInfo();
  }, []);

  const fetchSurveyData = async (surveyId) => {
    const surveyData = await getSurveyById(surveyId);
    setSurveyData(surveyData);
    const newInitValue = {
      ...initialValues,
      sheet_id: state?.dataSheet?._id?.$oid,
      import_name: surveyData?.name.concat(
        " " + moment().format("YYYY-MM-DD HH:mm:ss A")
      ),
      description: surveyData?.description,
    };
    setInitialValues(newInitValue);
    form.setFieldsValue(newInitValue);
    setStep(2);
  };

  const isMetadata = is_reference_sheet(dataSheet);

  useEffect(() => {
    if (state?.type === "survey" && state?.surveyId && state?.dataSheet) {
      fetchSurveyData(state?.surveyId);
    }
    if (
      (state?.type === "dataSheet" || state?.type === "pendingKpi") &&
      state?.dataSheet
    ) {
      const newInitValue = {
        ...initialValues,
        sheet_id: state?.dataSheet?._id?.$oid,
        import_name:
          state?.type === "pendingKpi"
            ? `Import for KPI ${state?.kpiData?.mainKPIName} ${moment().format(
                "YYYY-MM-DD HH:mm:ss A"
              )}`
            : state?.dataSheet?.sheet_name.concat(
                " " + moment().format("YYYY-MM-DD HH:mm:ss A")
              ),
        description: state?.dataSheet?.description,
      };
      setInitialValues(newInitValue);
      form.setFieldsValue(newInitValue);

      if (state.type === "pendingKpi" && state.currentStep) {
        setStep(state?.currentStep);
        setFiles([state?.kpiFile]);
      } else setStep(2);
    }
  }, [state]);

  useEffect(() => {
    const loadSheetData = async () => {
      if (initialValues?.sheet_id) {
        const dataSheet = await getSheetById(initialValues?.sheet_id);
        setDataSheet(dataSheet);
      }
    };

    loadSheetData();
  }, [initialValues?.sheet_id]);

  const import_type_list = {
    "image/png": "ocr_file",
    "image/jpg": "ocr_file",
    "image/jpeg": "ocr_file",
    "image/JPG": "ocr_file",
    "application/pdf": "ocr_file",
    "text/csv": "structured_file",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "structured_file",
  };

  const loadImportData = async () => {
    if (!importId) return;
    try {
      setLoadingImportData(true);
      const _import_data = await getImportDataById(importId);
      let import_type = "";
      Object.keys(import_type_list).forEach((key, indx) => {
        if (key === _import_data?.import_type) {
          import_type = import_type_list[key];
        }
      });
      const initValues = { ..._import_data, source_file_type: import_type };
      form.setFieldsValue(initValues);
      setInitialValues(initValues);
      const sheetData = await getSheetById(_import_data?.sheet_id);
      if (sheetData && state?.type !== "pendingKpi") {
        const sheetObj = sheetData?.sheet_schema;
        const _headers = [];
        sheetObj?.forEach((item) => {
          _headers.push({ item: item.entity_name });
        });
        setHeaders(_headers);
      }
      if (importId && state?.action === "review") {
        setStep(4);
      }
    } catch (error) {
      console.log("load error : ", error);
    } finally {
      setLoadingImportData(false);
    }
  };

  useEffect(() => {
    loadImportData();
  }, [importId]);

  const onChangeFieldsData = (data) => {
    // store data in state when form data change
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const handleGetIdentifierColumn = async () => {
    try {
      const metadataSheet = await getSheetById(dataSheet?.metadata_sheet_id);
      const identifier = metadataSheet?.sheet_schema?.find(
        (value) => value.isIdentifier
      )?.entity_name;
      if (identifier) {
        const payload = {
          permissionType: "REFERENCE_DATA",
          itemId: dataSheet?.metadata_sheet_id,
        };

        const permissions = await checkPermission(payload);
        const payloadMetadata = makeQueryMetadata({
          sheetId: dataSheet?.metadata_sheet_id,
          pagination: { current: 1, pageSize: 1000 },
          ...(!!permissions?.length && {
            filter: {
              [`${metadataSheet.sheet_name}.id`]: { $in: permissions },
            },
          }),
        });
        const metaDataDocuments = await listMetaDataRecords(payloadMetadata);

        const identifierValues =
          metaDataDocuments?.data?.map(
            (item) => item[item.sheet_name][identifier]
          ) || [];
        const identifierField = dataSheet?.sheet_schema?.find(
          (item) => item?.metadata_entity_name === identifier
        )?.entity_name;
        setIdentifierValues(identifierValues);
        setIdentifierColumn(identifierField);
      }
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    !!dataSheet &&
      (!importId || state?.action === "kpiMapping") &&
      handleGetIdentifierColumn();
  }, [dataSheet]);

  const uploadOCRBeforeStep3 = async () => {
    try {
      setIsPdfUploading(true);
      if (files && files.length > 0) {
        const preSignedUrl = await getPresignedUrl(files[0].name);
        // await uploadOCRFile(preSignedUrl, files);
      }
    } catch (e) {
      setIsPdfUploadFailed(true);
    } finally {
      setIsPdfUploading(false);
      setStep(step + 1);
    }
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      if (step === 2 && initialValues.source_file_type === "ocr_file") {
        await uploadOCRBeforeStep3();
      } else {
        setStep(step + 1);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleNextClickOCR = () => {
    setStep(step + 1);
  };

  const onRestartUploading = async (isUndo) => {
    setFiles([]);
    const payload = {
      _id: initialValues["_id"],
      sheet_name: dataSheet.sheet_name,
      is_metadata: isMetadata,
    };
    await removeImportData(payload);
    isUndo
      ? navigate(`/data/import-data`)
      : setInitialValues((prev) => {
          const newData = {
            ...prev,
            import_name: state?.dataSheet?.sheet_name.concat(
              " " + moment().format("YYYY-MM-DD HH:mm:ss A")
            ),
          };
          delete newData._id;
          return newData;
        });
    setStep(2);
  };

  const handleBack = () => {
    if (state?.type === "dataSheet") {
      dataSheet?.sheet_name &&
        navigate(
          `/data/data-sheets/${initialValues?.sheet_id}/${encodeUrlName(
            dataSheet?.sheet_name?.replace("/", " ")
          )}/documents`
        );
    } else if (state?.type === "survey") {
      navigate(
        `/management/collect-data/${surveyData?.id}/${encodeUrlName(
          surveyData?.name.replace("/", " ")
        )}/view-survey`,
        {
          state: {
            currentStep: state?.currentStep,
            ...(state?.taskDeploymentId && {
              taskDeploymentId: state?.taskDeploymentId,
            }),
            ...(state?.defaultMetaDataRecord && {
              metadataRecordId: state?.defaultMetaDataRecord.id,
            }),
          },
        }
      );
    } else if (state?.type === "pendingKpi") {
      localStorage.setItem(
        "pendingKpiImport",
        JSON.stringify({
          dataSheetId: state?.dataSheet?._id?.$oid,
          actionType: "data_imported",
          kpiId: state?.kpiData?._id?.$oid,
        })
      );
      navigate(`/data/pending-kpis`);
    } else navigate("/data/import-data");
  };

  const downloadFileHandler = async () => {
    try {
      const dataId = initialValues.data_id || initialValues.sheet_id;
      const response = await API.graphql({
        query: queries["dataSheetImport"],
        variables: {
          request_type: "DATASHEET_LIST",
          data_id: dataId,
        },
      });
      const filesList = response["data"]["dataSheetImport"];
      if (filesList) {
        const list = filesList
          .replace(/['"]+/g, "")
          .replace(/^\[(.+)\]$/, "$1")
          .split(",");
        const key = list[list?.length - 1];
        await downloadDataSheetImportFileHandler(key, dataId);
      }
    } catch (error) {
      console.log("Error while fetching data lake files", error);
    }
  };

  const onStepChange = async (selectedStep) => {
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = checkRolesPermission(["role:user"])
        ? selectedStep + 2
        : selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return (
        !checkRolesPermission(["role:user"]) && (
          <Step1
            onClickNext={onClickNext}
            onClickPrevious={handleBack}
            initialValues={initialValues}
            dataSheetsList={dataSheetsList}
            state={state}
          />
        )
      );
    } else if (step === 2) {
      return (
        <Step2
          isUser={checkRolesPermission(["role:user"])}
          files={files}
          setFiles={setFiles}
          onClickNext={onClickNext}
          onClickPrevious={() =>
            checkRolesPermission(["role:user"])
              ? handleBack()
              : onClickPrevious()
          }
          initialValues={initialValues}
          state={state}
          isUploading={isPdfUploading}
          downloadFileHandler={downloadFileHandler}
        />
      );
    } else if (step === 3) {
      return (
        <>
          {fileType === "structured_file" ? (
            <Step3
              files={files}
              isMetadata={isMetadata}
              headers={headers}
              setHeaders={setHeaders}
              onClickNext={onClickNext}
              onClickPrevious={onClickPrevious}
              dataSheetsList={dataSheetsList}
              identifierColumn={identifierColumn}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              dataSheet={dataSheet}
              setDataSheet={setDataSheet}
              state={state}
              currentStep={step}
              identifierValues={identifierValues}
            />
          ) : (
            <>
              {promptData && promptData.length > 0 && (
                <Step3PDF
                  promptData={promptData}
                  onPromptValueChange={handlePromptChange}
                  onClickPrevious={onClickPrevious}
                  onClickNext={handleNextClickOCR}
                />
              )}
            </>
          )}
        </>
      );
    } else if (step === 4) {
      return (
        <Step4
          files={files}
          isMetadata={isMetadata}
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          onRestartUploading={onRestartUploading}
          initialValues={initialValues}
          dataSheet={dataSheet}
          state={state}
          downloadFileHandler={downloadFileHandler}
          surveyData={surveyData}
        />
      );
    } else if (step === 5) {
      return <Step5 />;
    } else {
      console.log("completed");
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadingImportData}>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <StyledButton
                type="custom"
                onClick={() => handleBack()}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </div>
            <style jsx="true">
              {`
                .import-data-steps
                  .ant-steps-horizontal:not(.ant-steps-label-vertical)
                  .ant-steps-item {
                  padding-left: 0px;
                }

                .import-data-steps .ant-steps-label-horizontal {
                  // display: flex;
                  // align-items: center;
                  // justify-content: center;
                  text-align: center;
                }
              `}
            </style>
            <div
              className="user-flow-steps-wrap color-white import-data-steps"
              style={{ width: "60%", margin: "auto 0px" }}
            >
              <StyledSteps
                current={
                  checkRolesPermission(["role:user"]) ? step - 2 : step - 1
                }
                onChange={onStepChange}
              >
                {!checkRolesPermission(["role:user"]) && (
                  <Step
                    className="stepper-wrapper"
                    key={1}
                    title={<span>General information</span>}
                  />
                )}
                <Step
                  className="stepper-wrapper"
                  key={2}
                  title={<span>Data upload</span>}
                />
                <Step
                  className="stepper-wrapper"
                  key={3}
                  title={<span>Data mapping</span>}
                  disabled={!initialValues?._id?.$oid && files?.length == 0}
                />
                <Step
                  className="stepper-wrapper"
                  key={4}
                  title={<span>Data review</span>}
                  disabled={!initialValues?._id?.$oid}
                />
              </StyledSteps>
            </div>
          </div>
          <Progress
            percent={step * 24}
            showInfo={false}
            strokeColor={"#A68DFB"}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
});
export default ImportNewData;
