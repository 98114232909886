import {
  MinorVersion,
  PatchVersion,
  Version,
} from "../pages/settingsPage/releaseNotesPage/Components/ReleaseNotesPage";

import { NavigateFunction } from "react-router";
import { NotificationType } from "../pages/settingsPage/notificationsPage/Container/NotificationsPage";
import { getSurveyById } from "../services/surveys";
import { getTaskDeploymentById } from "../services/taskDeployment";
import { message } from "antd";
import { encodeUrlName } from "./commonFunctions";

// region notification

export const renderHTML = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  const applyStylesToElement = (element: HTMLElement) => {
    element.style.fontSize = "16px";
    element.style.color = "white";
  };

  const traverse = (node: Node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      applyStylesToElement(node as HTMLElement);
      const children = (node as HTMLElement).childNodes;
      for (let i = 0; i < children.length; i++) {
        traverse(children[i]);
      }
    }
  };
  traverse(doc.documentElement);

  return new XMLSerializer().serializeToString(doc);
};

export const paginateDataLocally = (
  currentPage: number,
  pageSize: number,
  totalItems: number,
  data: any[]
) => {
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  return data.slice(startIndex, endIndex);
};

export const executeOpenDataSheet = async (
  navigate: NavigateFunction,
  item: NotificationType,
  isUser: boolean
) => {
  const type = item.notificationType;
  if (type == "IMPORT_PENDING") {
    navigate(
      `/data/import-data/${item.itemId}/${encodeUrlName(item.itemName)}/review`,
      {
        state: { action: "review" },
      }
    );
  } else if (type == "SURVEY_PENDING") {
    navigate(
      `/data/data-sheets/${item.itemId}/${encodeUrlName(
        item.itemName
      )}/documents/pending`
    );
  } else if (type == "RECORD_DENIED") {
    const deployment = await getTaskDeploymentById(item.itemId);

    const survey =
      deployment && (await getSurveyById(deployment.surveyManagementID));

    if (survey) {
      if (survey.status === "ACTIVE") {
        navigate(
          `/management/collect-data/${survey?.id}/${encodeUrlName(
            survey?.name
          )}/view-survey`,
          {
            state: {
              taskDeploymentId: survey?.id,
              metadataRecordId: deployment?.entityId,
            },
          }
        );
      } else {
        isUser
          ? message.error("There's no such active survey anymore.")
          : navigate(
              `/management/collect-data/${survey?.id}/${encodeUrlName(
                survey?.name
              )}/view-survey`
            );
      }
    } else {
      isUser
        ? message.error("There's no such active survey anymore.")
        : navigate(`/management/collect-data`);
    }
  }
};

export const getTimeAgo = (item: NotificationType) => {
  const createDate = new Date(item.createdAt.$date);
  const now = new Date();
  var timeDiff = now.getTime() - createDate.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursDiff = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesDiff = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const secondsDiff = Math.floor((timeDiff % (1000 * 60)) / 1000);
  if (daysDiff) {
    return daysDiff + " days ago";
  } else if (hoursDiff) {
    return hoursDiff + " hours ago";
  } else if (minutesDiff) {
    return minutesDiff + " minutes ago";
  } else if (secondsDiff) {
    return secondsDiff + " seconds ago";
  }
};

export const getVersionComponents = (
  versionString: string
): [string, string, string] | null => {
  const versionRegex = /^(\d+)\.(\d+)\.(\d+)$/;

  const match = versionString.match(versionRegex);

  if (match) {
    const [, major, minor, patch] = match;
    return [major, minor, patch];
  }

  return null;
};

export const resetSelectedFlags = (versions: Version[]): Version[] => {
  return versions.map((majorVersion) => ({
    ...majorVersion,
    minor: majorVersion.minor.map((minorVersion) => ({
      ...minorVersion,
      isSelected: false,
      patch: minorVersion.patch
        ? minorVersion.patch.map((patchVersion) => ({
            ...patchVersion,
            isSelected: false,
          }))
        : [],
    })),
  }));
};

// TODO: move to utils
export const updateSelectedVersion = (
  versions: Version[],
  versionString: string
): Version[] => {
  // Reset all isSelected flags to false
  versions = resetSelectedFlags(versions);

  const [major, minor, patch] = versionString.split(".").map(Number);

  // Find the existing major version
  const majorVersion = versions.find(
    (version) => version.majorVersion === major.toString()
  );

  if (!majorVersion) {
    // If the major version doesn't exist, create it
    const newMajorVersion: Version = {
      id: major.toString(),
      majorVersion: major.toString(),
      minor: [
        {
          minorVersion: `${major}.${minor}`,
          isSelected: true,
          patch: [
            {
              patchVersion: versionString,
              isSelected: true,
            },
          ],
        },
      ],
    };

    return [newMajorVersion, ...versions];
  }

  // Find the existing minor version
  const minorVersion = majorVersion.minor.find(
    (minorVer) => minorVer.minorVersion === `${major}.${minor}`
  );

  if (!minorVersion) {
    // If the minor version doesn't exist, create it
    const newMinorVersion: MinorVersion = {
      minorVersion: `${major}.${minor}`,
      isSelected: true,
      patch: [
        {
          patchVersion: versionString,
          isSelected: true,
        },
      ],
    };

    // Add the new minor version to the major version
    majorVersion.minor.push(newMinorVersion);

    return versions;
  }

  // Find the existing patch version
  const patchVersion = minorVersion.patch?.find(
    (patchVer) => patchVer.patchVersion === versionString
  );

  if (!patchVersion) {
    // If the patch version doesn't exist, create it
    const newPatchVersion: PatchVersion = {
      patchVersion: versionString,
      isSelected: true,
    };

    // Add the new patch version to the minor version
    if (!minorVersion.patch) {
      minorVersion.patch = [];
    }
    minorVersion.patch.push(newPatchVersion);

    return versions;
  }

  // Set isSelected for the existing patch version to true
  patchVersion.isSelected = true;

  // Set isSelected for the parent minor version to true
  minorVersion.isSelected = true;

  return versions;
};

export const getSelectedPatchVersion = (versions: Version[]): string | null => {
  for (const majorVersion of versions) {
    for (const minorVersion of majorVersion.minor) {
      if (minorVersion.patch) {
        for (const patchVer of minorVersion.patch) {
          if (patchVer.isSelected) {
            return `${patchVer.patchVersion}`;
          }
        }
      }
    }
  }

  return null;
};

export const transformVersions = (versionStrings: string[]): Version[] => {
  const sortedVersions = versionStrings.sort((a, b) => {
    const [aMajor, aMinor] = a.split(".").map(Number);
    const [bMajor, bMinor] = b.split(".").map(Number);

    if (aMajor !== bMajor) return aMajor - bMajor;
    if (aMinor !== bMinor) return aMinor - bMinor;
    return a.localeCompare(b);
  });

  const versionMap = new Map<string, Version>();

  sortedVersions.forEach((versionString) => {
    const [major, minor, patch] = versionString.split(".").map(Number);
    const majorKey = major.toString();
    const minorKey = `${major}.${minor}`;
    const patchKey = `${major}.${minor}.${patch}`;

    // Create or get the major version object
    let majorVersion = versionMap.get(majorKey);
    if (!majorVersion) {
      majorVersion = {
        id: majorKey,
        majorVersion: majorKey,
        minor: [],
      };
      versionMap.set(majorKey, majorVersion);
    }

    // Create or get the minor version object
    let minorVersion = majorVersion.minor.find(
      (minor) => minor.minorVersion === minorKey
    );
    if (!minorVersion) {
      minorVersion = {
        minorVersion: minorKey,
        isSelected: false,
        patch: [],
      };
      majorVersion.minor.push(minorVersion);
    }

    // Create and add the patch version
    const patchVersion: PatchVersion = {
      patchVersion: patchKey,
      isSelected: false,
    };

    if (minorVersion && minorVersion.patch) {
      minorVersion.patch.push(patchVersion);
    }
  });

  // Set the isSelected flag for the smallest version
  if (versionMap.size > 0) {
    const smallestMajorVersion = Array.from(versionMap.values()).reduce(
      (prev, current) => (prev.id < current.id ? prev : current)
    );
    smallestMajorVersion.minor[0].isSelected = true;

    if (
      smallestMajorVersion.minor[0].patch &&
      smallestMajorVersion.minor[0].patch.length > 0
    ) {
      smallestMajorVersion.minor[0].patch[0].isSelected = true;
    }
  }

  return Array.from(versionMap.values());
};

export const formatTimestamp = (timestamp: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", options);
};

// end region
