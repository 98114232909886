import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import {
  FileTextOutlined,
  FileAddOutlined,
  CloudUploadOutlined,
  ThunderboltOutlined,
  ApiOutlined,
  BarChartOutlined,
  HourglassOutlined,
  FolderOpenOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { ContentSection, CardsWrap } from "../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { COLORS } from "../../shared/colors";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { DataSheetsTypeModal } from "../dataSheetsPage/Components/DataSheetsTypeModal";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

const cards = [
  {
    permission: "data-sheets-page",
    cardTitle: "Data Sheets",
    cardIcon: <FileTextOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/data-sheets",
    type: "data",
    moduleName: "DATA_SHEETS",
  },
  {
    permission: "standards-page",
    cardTitle: "Create Data sheet",
    cardIcon: <FileAddOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/data-sheets/create", // no route available for this in 'onClickCardsSelectSection'
    type: "data",
    moduleName: "DATA_SHEETS",
  },
  {
    permission: "data-page",
    cardTitle: "Pending Items",
    cardIcon: <HourglassOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/pending-items",
    type: "data",
    moduleName: "DATA_SHEETS",
  },
  {
    permission: "data-page",
    cardTitle: "Pending KPIs",
    cardIcon: <DashboardOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/pending-kpis",
    type: "data",
    moduleName: "DATA_KPIS",
  },
  {
    permission: "data-page",
    cardTitle: "Import Data",
    cardIcon: <CloudUploadOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/import-data",
    type: "data",
    moduleName: "DATA_SHEETS",
  },
  {
    permission: "data-page",
    cardTitle: "Mass Data Import",
    cardIcon: <FolderOpenOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/mass-data-import",
    type: "data",
    moduleName: "DATA_SHEETS",
  },
  {
    permission: "data-page",
    cardTitle: "Connect Utilities",
    cardIcon: <ThunderboltOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/connect-utilities",
    type: "data",
    moduleName: "CONNECT_UTILITIES",
  },
  {
    permission: "data-page",
    cardTitle: "Connect API",
    cardIcon: <ApiOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/connect-api",
    type: "data",
    moduleName: "CONNECT_API",
  },

  {
    permission: "data-page-with-elevateduser",
    cardTitle: "KPI",
    cardIcon: <BarChartOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/kpi",
    type: "data",
    moduleName: "DATA_KPIS",
  },
  {
    permission: "units-management",
    cardTitle: "Units Management",
    cardIcon: <FileTextOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/data/units-management",
    type: "data",
    moduleName: "UNITS_MANAGEMENT",
  },
];

const popularActions = [
  {
    permission: "data-page-with-elevateduser",
    cardTitle: "Data Sheets",
    cardIcon: <FileTextOutlined />,
    iconColor: "linear-gradient(180deg, #FC7786 0%, #E66270 100%)",
    cardLink: "/data/data-sheets",
    type: "data",
    moduleName: "DATA_SURVEYS",
  },
  {
    permission: "data-page-with-elevateduser",
    cardTitle: "KPI's",
    cardIcon: <BarChartOutlined />,
    iconColor: "linear-gradient(180deg, #44C6A6 0%, #31AC8F 100%)",
    cardLink: "/data/kpi",
    type: "data",
    moduleName: "DATA_SURVEYS",
  },
];

const Data = () => {
  const navigate = useNavigate();
  const { checkPermissions } = useCheckRolesPermissions();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const allowedDataCards = cards.filter((card) =>
    checkPermissions([card.permission])
  );

  const onClickDataSheetCreate = () => {
    setCreateModalVisible(true);
  };

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/data/data-sheets":
        navigate(section);
        break;
      case "/data/kpi":
        navigate(section);
        break;
      case "/data/custom-data-builder":
        navigate(section);
        break;
      case "/data/data-sheets/create":
        onClickDataSheetCreate();
        break;
      case "/management/collect-data":
        navigate(section);
        break;
      case "/data/import-data":
        navigate(section);
        break;
      case "/data/mass-data-import":
        navigate(section);
        break;
      case "/data/import-data/import-new-data":
        navigate(section);
        break;
      case "/data/connect-utilities":
        navigate(section);
        break;
      case "/data/connect-api":
        navigate(section);
        break;
      case "/data/units-management":
        navigate(section);
        break;
      case "/data/pending-items":
        navigate(section);
        break;
      case "/data/pending-kpis":
        navigate(section);
        break;
      default:
    }
  };

  return (
    <ContentSection>
      <DataSheetsTypeModal
        isVisible={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      />
      <Typography.Title
        level={2}
        style={{
          color: "white",
          fontFamily: "Roboto",
          fontWeight: "500",
          marginTop: "20px",
        }}
      >
        Popular Actions
      </Typography.Title>
      <CardsWrap>
        {popularActions.map(
          (card, index) =>
            checkPermissions([card.permission]) && (
              <PopularActionCard
                title={card.cardTitle}
                iconColor={card.iconColor}
                customCardStyle={customIconCardStyle.cardStyle}
                titleStyle={customIconCardStyle.titleStyle}
                onClick={onClickCardsSelectSection}
                iconComponent={card.cardIcon}
                section={card.cardLink}
                key={index}
                isLockModule={card.moduleName ? card.moduleName : false}
              />
            )
        )}
      </CardsWrap>
      {allowedDataCards.length > 0 && (
        <>
          <Typography.Title
            level={2}
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontWeight: "500",
              marginTop: "20px",
            }}
          >
            Data
          </Typography.Title>
          <CardsWrap>
            {allowedDataCards.map((card, index) => (
              <CustomIconCard
                title={card.cardTitle}
                customCardStyle={customIconCardStyle.cardStyle}
                titleStyle={customIconCardStyle.titleStyle}
                onClick={onClickCardsSelectSection}
                iconComponent={card.cardIcon}
                section={card.cardLink}
                key={index}
                isLockModule={card.moduleName ? card.moduleName : false}
              />
            ))}
          </CardsWrap>
        </>
      )}
    </ContentSection>
  );
};

export default Data;
