import React, { useState, useEffect } from "react";
import { Row, Col, message, Spin } from "antd";
import {
  PageWrap,
  OverviewContentWrap,
} from "./../../shared/commonStyles";
import ContentUpdateModal from "./Components/ContentUpdateModal";
import { VideoPlayerSection } from "./Components/VideoPlayerSection";
import { LeftSideMenu } from "./Components/LeftSideMenu";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import { deleteContent,getEducationData } from "../../services/clientEductaion";

const LearningPage = () => {
  const [educationData, setEducationData] = useState([]);
  const [loadingEducationData, setLoadingEducationData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [refreshData, setRefreshData] = useState(1);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const RefreshEducationData = () => {
    setRefreshData(refreshData + 1);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingEducationData(true); 
        const listClientEducations = await getEducationData();
        if (listClientEducations?.length) {
          // set video selected when first time page load
          refreshData === 1 && setRowData(listClientEducations[0]);
          const categoryMap = [];
          listClientEducations.forEach((item) => {
            const exist =
              categoryMap.filter((cat) => {
                return cat.type === item.category?.trim();
              }).length > 0;
            if (exist) {
              const indexData = categoryMap.findIndex(
                (element) => element.type === item.category?.trim()
              );
              categoryMap[indexData].data.push(item);
            } else {
              const data = {
                type: item.category.trim(),
                data: [item],
              };
              categoryMap.push(data);
            }
          });
          setEducationData(categoryMap);
        }
      } catch (error) {
        console.log("error while load education data.", error);
        message.error("error while load education data.");
      } finally {
        setLoadingEducationData(false);
      }
    };
    loadData();
  }, [refreshData]);

  const onDeleteClick = () => {
    setShowDeleteModal(true);
    setDeleteData(rowData["_id"]);
  };

  const onConfirmDelete = async () => {
    try {
      setConfirmLoading(true);
      const payload = {
        _id: deleteData,
      };

      const response = await deleteContent(payload);

      if (response) {
        message.success("Content Deleted Successfully!");
        setShowDeleteModal(false);
        setConfirmLoading(true);
        setRefreshData(refreshData + 1);
      } else {
        message.error();
        ("Failed to delete content!");
        console.log("Failed to delete content");
      }
    } catch (error) {
      console.error("Something went wrong while deleting content!", error);
      message.error("Something went wrong while deleteing content!");
    }
  };

  const handleClick = (data) => {
    setRowData(data);
  };

  return (
    <>
      {showUpdateModal && (
        <ContentUpdateModal
          rowData={rowData}
          visible
          onClose={() => setShowUpdateModal(false)}
          RefreshEducationData={RefreshEducationData}
          setRowData={setRowData}
        />
      )}
      <ConfirmDeleteModal
        description="Are you sure you want to delete this Content?"
        isVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onConfirmDelete}
        confirmLoading={confirmLoading}
      />
      <PageWrap>
        <OverviewContentWrap style = {{padding : '0',background : '#3C3451'}} >
          <Spin spinning={loadingEducationData}>
            <Row style={{ height: "100%", minHeight: 500,background : '#3C3451' }}>
              <Col xs={24} md={8} sm={24} lg={6} style = {{background: '#2C263F'}}>
                <LeftSideMenu
                  handleClick={handleClick}
                  educationData={educationData}
                />
              </Col>
              <Col xs={24} md={16} sm={24} lg={18} style = {{background : '#3C3451'}}>
                <VideoPlayerSection 
                  data={rowData}
                  showUpdateModal = {() => setShowUpdateModal(true)}
                  onClickDelete = {() => onDeleteClick()}
                  />
              </Col>
            </Row>
          </Spin>
        </OverviewContentWrap>
      </PageWrap>
    </>
  );
};

export default LearningPage;
