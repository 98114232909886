import React, { useState } from "react";
import { Space, Form, Input, Row, Col } from "antd";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { StyledButton } from "../../shared/commonStyles";
import styles from "./CustomFormBuilder.module.scss";

const RenderOptionsSchema = ({ restField, initialValues }) => {
  return (
    <Form.Item
      {...restField}
      labelCol={{ span: 24 }}
      label="Create Options"
      rules={[{ required: true, message: "Please create an option!" }]}
    >
      <Form.List name={"options"}>
        {(fields, { add, remove, move }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Row justify="space-between" gutter={[16, 16]}>
                      <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "label"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input label!",
                              validateTrigger: true,
                              validator: (_, value) => {
                                try {
                                  const data = value?.trim();
                                  if (!data) {
                                    throw new Error("Label is required!");
                                  } else {
                                    return Promise.resolve(true);
                                  }
                                } catch (err) {
                                  throw new Error("Label  is required!");
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            disabled={name < initialValues?.options?.length}
                            placeholder="Label"
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input
                            disabled={name < initialValues?.options?.length}
                            placeholder="Value"
                          />
                        </Form.Item>
                      </Col>
                      <Row justify="end">
                        <Col
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className={styles.extraButtons}
                        >
                          <Form.Item>
                            <StyledButton
                              type="default"
                              style={{ padding: "8px", marginTop: "0px" }}
                              onClick={() => move(name, name - 1)}
                              icon={<ArrowUpOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className={styles.extraButtons}
                        >
                          <Form.Item>
                            <StyledButton
                              type="default"
                              style={{ padding: "8px", marginTop: "0px" }}
                              onClick={() => move(name, name + 1)}
                              icon={<ArrowDownOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className={styles.extraButtons}
                        >
                          <Form.Item>
                            <StyledButton
                              type="default"
                              style={{ padding: "8px", marginTop: "0px" }}
                              onClick={() => remove(name)}
                              icon={<DeleteOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Space>
                );
              })}
              <Form.Item>
                <StyledButton
                  type="custom"
                  onClick={() => add()}
                  icon={<PlusCircleOutlined />}
                >
                  Add Option
                </StyledButton>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};
export default RenderOptionsSchema;
