import { CardsWrap, ContentSection } from "../../shared/commonStyles";
import {
  FileProtectOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Banner } from "../../shared/banner";
import { COLORS } from "../../shared/colors";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { Typography } from "antd";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { useNavigate } from "react-router-dom";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

const cards = [
  {
    permission: "audit-data-sheets",
    cardTitle: "Audit Data Sheets",
    cardIcon: <ReadOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/audit/audit-data-sheets",
    type: "standards",
    moduleName: "AUDIT_DATA_SHEETS",
  },
  {
    permission: "audit-standards-page",
    cardTitle: "Audit Standards",
    cardIcon: <ReadOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/audit/audit-standards",
    type: "standards",
    moduleName: "AUDIT_STANDARDS",
  },
  {
    permission: "audit-data-sheets",
    cardTitle: "Audit GHG Calculations",
    cardIcon: <ReadOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/audit/calculations-audit",
    type: "standards",
    moduleName: "AUDIT_EMISSION",
  },
  {
    permission: "standards-audits-page",
    cardTitle: "Audit data sheets",
    cardIcon: <ScheduleOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "#",
    type: "",
  },
  {
    permission: "standards-audits-page",
    cardTitle: "Audit data",
    cardIcon: <FileProtectOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "#",
    type: "",
  },
];

export const Audit = () => {
  const navigate = useNavigate();
  const { checkPermissions } = useCheckRolesPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/audit/audit-data-sheets":
        navigate(section);
        break;
      case "/audit/audit-standards":
        navigate(section);
        break;
      case "/audit/calculations-audit":
        navigate(section);
        break;
      case "/audit/manage":
        navigate(section);
        break;
      case "#":
        break;
      default:
    }
  };

  const allowedStandardsCards = cards.filter(
    (card) => card.type === "standards" && checkPermissions([card.permission])
  );
  const popularActions = [
    {
      permission: "manage-audits",
      cardTitle: "Manage Audit",
      cardIcon: <ReconciliationOutlined />,
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/audit/manage",
      type: "standards",
    },
  ];
  useEffect(() => {
    popularActions.map((card) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);
  return (
    <ContentSection>
      <Banner />
      {popularActions.length > 0 && (
        <>
          {showPopluarActions && (
            <Typography.Title
              level={2}
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Popular Actions
            </Typography.Title>
          )}
          <CardsWrap>
            {popularActions.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <PopularActionCard
                    title={card.cardTitle}
                    iconColor={card.iconColor}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index + 1}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}
      {allowedStandardsCards.length > 0 && (
        <>
          <Typography.Title
            level={2}
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontWeight: "500",
              marginTop: "20px",
            }}
          >
            Other Services
          </Typography.Title>
          <CardsWrap>
            {allowedStandardsCards.map((card, index) => (
              <CustomIconCard
                title={card.cardTitle}
                customCardStyle={customIconCardStyle.cardStyle}
                titleStyle={customIconCardStyle.titleStyle}
                onClick={onClickCardsSelectSection}
                iconComponent={card.cardIcon}
                section={card.cardLink}
                key={index + 1}
                isLockModule={card.moduleName ? card.moduleName : false}
              />
            ))}
          </CardsWrap>
        </>
      )}
    </ContentSection>
  );
};
