import React, { useState, useEffect, useRef } from "react";
import {
  Spin,
  Divider,
  Typography,
  Input,
  Row,
  Col,
  Form,
  UploadFile,
} from "antd";
import { StyledButton, FormWrapper } from "../../../../shared/commonStyles";

import { AvatarUsername } from "../../../../components/Avatar/AvatarUsername";
import { SignOffProgressBar } from "./SignOffProgressBar";

import { useAssignedAuditorsList } from "../../hooks/useAssignedAuditorsList";
import AuditorsFilesModal from "./AuditorsFilesModals";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useBoolean } from "ahooks";
const { Title } = Typography;

interface SignOffSectionProps {
  rowData: DataSheetRecord;
  auditRecord: Audit;
  submitSignoffStatus: (
    rowData: DataSheetRecord,
    comment: string,
    status: string,
    files?: UploadFile[]
  ) => string;
  submitting: boolean;
  onClose: () => void;
  refreshUploadedFiles: () => void;
}

interface SignOffUsersAvatarListProps {
  uniqueUsersList: any[];
}

interface InitialValues {
  comment: string;
  status: string;
}

export const SignOffSection = ({
  rowData,
  auditRecord,
  onClose,
  submitSignoffStatus,
  submitting,
  refreshUploadedFiles,
}: SignOffSectionProps) => {
  const [refreshData, setRefreshData] = useState(1);
  const [files, setFiles] = useState([]);
  const [auditorRecord, setAuditorRecord] = useState(null);
  const [signOffForm] = Form.useForm();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    comment: "",
    status: "",
  });
  const [
    isReasonVisible,
    { setTrue: setReasonVisible, setFalse: setReasonHidden },
  ] = useBoolean(false);

  const {
    loading,
    assignedAuditorsList,
    getUniqueUsersList,
    loadAssignedAuditors,
  } = useAssignedAuditorsList();

  const uniqueAuditorsList = getUniqueUsersList(assignedAuditorsList);
  useEffect(() => {
    const dataSheetAudit = rowData[`${rowData.sheet_name} Audit`]?.find(
      (v: any) => v.audit_id === auditRecord.id
    );

    setInitialValues({
      comment: dataSheetAudit?.comment || "",
      status: dataSheetAudit?.status || "",
    });

    if (rowData && auditRecord) {
      loadAssignedAuditors(auditRecord, rowData);
    }
  }, [auditRecord, rowData, refreshData,]);

  const onClickConfirmButton = async (comment: string, status: string) => {
    const response = await submitSignoffStatus(rowData, comment, status, files);
    if (response === "success") {
      setFiles([]);
      setRefreshData(refreshData + 1);
      signOffForm.resetFields();
      setReasonHidden();
    }
  };

  const onClickStatusBtn = (status: string) => {
    setInitialValues({
      status: status,
      comment:""
    });
    signOffForm.setFieldsValue({
      status:status,
      comment: null,
    });
    if (status ==="denied" || status ==="approved")setReasonVisible();
    
  };
  const inputAreaRef = useRef<HTMLDivElement>(null);
  const buttonApproveRef = useRef<HTMLDivElement>(null);
  const buttonDenyRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event:MouseEvent):void => {
    console.log("outside.click")
    if (inputAreaRef?.current instanceof HTMLElement && !inputAreaRef?.current.contains(event.target as Node) && buttonApproveRef?.current instanceof HTMLElement && !buttonApproveRef?.current.contains(event.target as Node) && buttonDenyRef?.current instanceof HTMLElement && !buttonDenyRef?.current.contains(event.target as Node)) {
      setReasonHidden()
      signOffForm.resetFields();
      onClickStatusBtn("n")
    }
  };
  useEffect(() => {

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="signoff-section">
      <Title className="color-white" level={4}>
        Signing Off
      </Title>
      <p className="color-grey">
        {" "}
        This section shows the audit users and actions that are possible. If you
        deny a record, you must provide a reason. If you approve a record,
        comments are optional. Hover over each user to see the audit status.
      </p>
      <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
      <Spin spinning={loading}>
        <div className="width-100">
          {uniqueAuditorsList && (
            <SignOffProgressBar
              uniqueUsersList={uniqueAuditorsList}
              type="stroke"
            />
          )}
          <br />
          {uniqueAuditorsList && (
            <SignOffUsersAvatarList uniqueUsersList={uniqueAuditorsList} />
          )}

          <Row>
            <Col span={24}>
              <p className="color-white">
                Click on the button to make a decision
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormWrapper>
                <Form
                  initialValues={initialValues}
                  form={signOffForm}
                  onFinish={({ comment, status }) =>
                    onClickConfirmButton(comment, status)
                  }
                >
                  <>
                    <Row>
                      <div className="buttons-wrapper">
                        <Form.Item labelCol={{ span: 24 }} name="status">
                          <StyledButton
                          ref={buttonApproveRef}
                            bgcolor={
                              initialValues.status === "approved" && "#7F5FEE"
                            }
                            type="default"
                            onClick={() =>
                              initialValues.status !== "approved" &&
                              onClickStatusBtn("approved")
                            }
                          >
                            {initialValues.status === "approved"
                              ? "Approved"
                              : "Approve"}
                          </StyledButton>
                          <StyledButton
                          ref={buttonDenyRef}
                            bgcolor={
                              initialValues.status === "denied" && "#7F5FEE"
                            }
                            type="default"
                            onClick={() =>
                              initialValues.status !== "denied" &&
                              onClickStatusBtn("denied")
                            }
                          >
                            {initialValues.status === "denied"
                              ? "Declined"
                              : "Deny"}
                          </StyledButton>
                        </Form.Item>
                      </div>
                    </Row>
                    {isReasonVisible && (
                      
                      <Row>
                        <span ref={inputAreaRef}>
                        <Col span={24}>
                          
                          <Form.Item
                            labelCol={{ span: 24 }}
                            name="comment"
                            label={
                              initialValues.status === "approved"
                                ? "Approval reason (Optional)"
                                : "Provide a reason for rejecting this record (Mandatory)"
                            }
                            rules={[
                              {
                                required: initialValues.status === "denied",
                                message: "Please input the comment",
                              },
                            ]}
                          >
                            <Input.TextArea  placeholder={""} />
                          </Form.Item>
                          
                        </Col>
                        
                        <Col span={24}>
                          <AuditorsFilesModal
                            rowData={rowData}
                            setFiles={setFiles}
                            files={files}
                            setAuditRecord={setAuditorRecord}
                            auditRecord={auditorRecord}
                            refreshUploadedFiles={refreshUploadedFiles}
                          />
                        </Col>
                        <Col span={24}>
                          <Form.Item labelCol={{ span: 24 }}>
                            <StyledButton
                              onClick={() => {
                                setReasonHidden();
                                onClose();
                              }}
                              type="custom"
                              hovercolor="#A68DFB"
                              hoverbgcolor="transparent"
                              bgcolor="transparent"
                              bordercolor="transparent"
                              color="#fff"
                            >
                              Close
                            </StyledButton>
                            <StyledButton
                              loading={submitting}
                              type="custom"
                              htmlType="submit"
                            >
                              Submit
                            </StyledButton>
                          </Form.Item>
                        </Col>
                        </span>
                      </Row>
                    )}
                  </>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

const SignOffUsersAvatarList = ({
  uniqueUsersList,
}: SignOffUsersAvatarListProps) => {
  return (
    <div className="signoff-users-horizontal-wrap">
      <Row style={{ width: "100%" }}>
        <Col span={12}>
          <Title className="color-white" level={5}>
            Approved
          </Title>
          {uniqueUsersList?.map((item, index) => {
            const approved =
              item?.audit_log_data?.status === "approved" ? true : false;
            if (approved) {
              return (
                <AvatarUsername
                  key={index}
                  size="large"
                  username={item.first_last_name}
                  customTooltipTitle={
                    item?.first_last_name && (
                      <>
                        {item.first_last_name}
                        <br />
                        <p style={{ fontSize: "11px" }}>
                          {item?.audit_log_data?.status === "approved"
                            ? "Approved"
                            : ""}
                        </p>
                        <br />
                        {item?.audit_log_data?.comment}
                      </>
                    )
                  }
                />
              );
            }
          })}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Title className="color-white" level={5}>
            Denied
          </Title>
          {uniqueUsersList?.map((item, index) => {
            const denied =
              item?.audit_log_data?.status === "denied" ? true : false;
            if (denied) {
              return (
                <AvatarUsername
                  key={index}
                  size="large"
                  username={item.first_last_name}
                  customTooltipTitle={
                    item?.first_last_name && (
                      <>
                        {item.first_last_name}
                        <br />
                        <p style={{ fontSize: "11px" }}>
                          {item?.audit_log_data?.status === "denied"
                            ? "denied"
                            : ""}
                        </p>
                        {item?.audit_log_data?.comment}
                      </>
                    )
                  }
                />
              );
            }
          })}
        </Col>
      </Row>
    </div>
  );
};
