import React, { useContext } from "react";
import { ReportingContext } from "./ReportingProvider";
import PoliciesPage from "../reportingPage/PoliciesPage/PoliciesPage";

interface StandardProps {
  isDeleted: boolean;
}

export const Standard = ({ isDeleted }: StandardProps) => {
  const {
    loadingUnits,
    businessUnits,
    orgLocations,
    reportPeriods,
    businessUnitsId,
    orgLocationId,
    reportPeriodId,
  } = useContext(ReportingContext);

  return (
    <>
      {!loadingUnits && (
        <PoliciesPage
          report_period={reportPeriodId}
          location_id={orgLocationId}
          business_unit_id={businessUnitsId}
          locationsList={orgLocations}
          businessUnitsList={businessUnits}
          reportPeriodsList={reportPeriods}
          isDeleted={isDeleted}
        />
      )}
    </>
  );
};
