import { Form, message, Button, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { CommonModal, FormWrapper } from "../../../../shared/commonStyles";
import { UploadOutlined } from "@ant-design/icons";

const UploadLogoModal = ({
  visible,
  onClose,
  rowData,
  updateBusinessUnitData,
}) => {
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isLogoChanged, setIsLogoChanged] = useState(0);
  const [businessUploadedLogo, setBusinessUploadedLogo] = useState(null);

  async function getFile() {
    //  not getting the file
    try {
      const resp = await Storage.get(
        `businessuniticons/${rowData?._id['$oid']}.png`
      );

      setBusinessUploadedLogo(resp);
    } catch (e) {
      // message.error("Can't get the file. Something went wrong");
      console.log("There was an error fetching image");
    }
  }

  useEffect(() => {
    if (rowData?._id) {
      
      getFile();
    }
  }, [rowData?._id, isLogoChanged]);

  const [form] = Form.useForm();
  

  const onClickUpdate = () => {
    if(isUploaded){
      onClose(!visible);
      message.success(`${file.name} file uploaded successfully`);
    }else{
      onClose(!visible);
    }    
  };

  const uploadToS3 = async (file,_id) => {
    try{
        const resp = await Storage.put(`businessuniticons/${_id['$oid']}.png`, file, {contentType: file.type});
        updateBusinessUnitData(resp);     
        return true
    }catch(error){
        return null;
    }
 }

  const onUploadBusinessUnitLogo = async(file) => {
    if(file && rowData?._id){
      try {
        const validExtensions = ['png','jpg','jpeg','JPG'];
        const extension = file.name.split(".")?.pop()?.toLowerCase();
        if (!validExtensions.includes(extension) > 0) {
          message.error(`${file.name} is not a valid file type, valid file types are png, jpg, jpeg`);
          return false;
        }
        const res = await uploadToS3(file, rowData?._id);
        if(res){
          setIsLogoChanged(isLogoChanged + 1);
          setFile(file);
          setIsUploaded(true);
        }else{
          return false;
        }
      } catch (error) {
          return false;
      } finally {
        //return false;
      }
    }
  }

  const download = (e) => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        e.target.src,
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "business_logo.jpg";
    element.click();
  };

  return (
    <>
      <CommonModal
        title="Update Report Logo"
        visible={visible}
        onOk={onClickUpdate}
        onCancel={(_) => onClose(!visible)}
        centered
        footer={[
          <Button key="Cancel" ghost onClick={(_) => onClose(!visible)}>
            Cancel
          </Button>,
          <Button key="Update" type="primary" onClick={onClickUpdate}>
            Update
          </Button>,
        ]}
      >
        <FormWrapper>
          <Form form={form}>
            <Form.Item labelCol={{ span: 24 }} name="logo" label="">
              <Upload
                customRequest={({ file, onSuccess }) => {
                  onSuccess("ok");
                }}
                beforeUpload={onUploadBusinessUnitLogo}
                multiple={false}
                style={{ display: "block", margin: "0 auto" }}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>
                  {!file ? "Upload" : "Change"} Logo
                </Button>
              </Upload>
            </Form.Item>
            {businessUploadedLogo && 
              <Form.Item>
                <img src={businessUploadedLogo} alt="BusinessLogo" width="60" onClick={(e) => download(e)} style = {{cursor : 'pointer'}}/>
              </Form.Item>
            }
          </Form>
        </FormWrapper>
      </CommonModal>
    </>
  );
};

export default UploadLogoModal;