import React from "react";
import { useBoolean } from "ahooks";
import { Col, Row } from "antd";
import {
  CommonDarkTransparentTable,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { PlusCircleOutlined } from "@ant-design/icons";
import { reductionPlanStore } from "../../../WhatIfOnboarding/ReductionPlanStore";
import { CreateNewPlanModal } from "./CreateNewPlanModal";
import { TargetPlansButtons } from "./TargetPlansButtons";
import moment from "moment";
import { observer } from "mobx-react-lite";
import styles from "./SingleTarget.module.scss";

interface ReductionPlansProps {
  onSetChart: (data: Plan) => void;
}

export const ReductionPlans = observer(
  ({ onSetChart }: ReductionPlansProps) => {
    const [
      isCreateNewPlanModalOpen,
      { setTrue: openCreateNewPlanModal, setFalse: hideCreateNewPlanModal },
    ] = useBoolean(false);

    const handleCreateNewPlan = (plan: Plan) => {
      reductionPlanStore.addTargetPlan(plan);
      hideCreateNewPlanModal();
    };

    const handleShow = (data: Plan) => {
      onSetChart(data);
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "30%",
        align: "left",
      },
      {
        title: "Period",
        dataIndex: "period",
        key: "period",
        width: "15%",
        align: "center",
        render: (_: any, plan: Plan) => {
          return (
            <span>
              {moment(plan.startDate).format("YYYY-MM-DD")} -{" "}
              {moment(plan.endDate).format("YYYY-MM-DD")}
            </span>
          );
        },
      },
      {
        title: "Goal",
        dataIndex: "goal",
        key: "goal",
        width: "15%",
        align: "center",
        render: (goal: number) => {
          return <span>Goal: {goal}</span>;
        },
      },
      {
        title: "Monthly Reduction",
        dataIndex: "monthlyReduction",
        key: "monthlyReduction",
        width: "15%",
        align: "center",
        render: (value: number) => {
          return <span>Monthly reduction: {value}</span>;
        },
      },
      {
        title: "",
        dataIndex: "_id",
        width: "150px",
        render: (_: any, data: Plan, index: number) => (
          <TargetPlansButtons
            data={data}
            index={index}
            firstBtnAction={() => handleShow(data)}
          />
        ),
      },
    ];

    return (
      <>
        <Row justify="space-between" className={styles.title}>
          <span>Reduction plans</span>
          <StyledButton onClick={openCreateNewPlanModal}>
            <PlusCircleOutlined />
            Create reduction plan
          </StyledButton>
          <CreateNewPlanModal
            modalTitle="Create new reduction plan"
            data={{} as Plan}
            isOpen={isCreateNewPlanModalOpen}
            onClose={hideCreateNewPlanModal}
            onConfirm={handleCreateNewPlan}
          ></CreateNewPlanModal>
        </Row>
        <Row>
          <Col span={24}>
            <CommonDarkTransparentTable
              columns={columns}
              pagination={false}
              dataSource={reductionPlanStore.currentTarget.plans}
              loading={reductionPlanStore.loadingTargets}
              showHeader={false}
            />
          </Col>
        </Row>
      </>
    );
  }
);
