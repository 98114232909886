import Styled from "styled-components";
import { siteThemeColors } from "../../../shared/colors";

export const StyledTabsContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 134px;
  min-width: 134px;
  background-color: ${siteThemeColors.commonColor.bgColor};
  margin: 10px 25px 10px 0px;
`;
