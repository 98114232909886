import React, { useState } from "react";
import { Form, Input, Row, Typography } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import {
  CommonModal,
  FormWrapper,
  StyledDivider,
} from "../../../shared/commonStyles";

interface CreateWelcomePageModalProps {
  visible: boolean;
  onClose: () => void;
  setSortedDataSurveyList: any;
}

export const CreateWelcomePageModal = ({
  visible,
  onClose,
  setSortedDataSurveyList,
}: CreateWelcomePageModalProps) => {
  const [form] = Form.useForm();
  const [welcomePageData, setWelcomePageData] = useState({
    subject: "",
    body: "",
  });

  const handleSave = () => {
    const newData = { ...welcomePageData, type: "welcome_page" };
    setSortedDataSurveyList((prev: any[]) => [
      ...prev,
      { ...newData, index: prev.length },
    ]);
    setWelcomePageData({
      subject: "",
      body: "",
    });
    onClose();
  };
  return (
    <CommonModal
      visible={visible}
      title="Welcome Page"
      onCancel={onClose}
      onOk={handleSave}
    >
      <FormWrapper>
        <Row>
          <Typography.Title level={5}>Welcome page details</Typography.Title>{" "}
        </Row>
        <StyledDivider />
        <Form form={form} layout="vertical">
          <Form.Item label="Welcome page subject">
            <Input
              value={welcomePageData.subject}
              onChange={(e) =>
                setWelcomePageData((prev) => ({
                  ...prev,
                  subject: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item label="Welcome page body">
            <CKEditor
              editor={DecoupledDocumentEditor}
              data={welcomePageData.body}
              onReady={(editor: any) => {
                if (editor?.ui) {
                  editor.ui.view.editable.element.parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.view.editable.element
                  );
                }
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setWelcomePageData((prev) => ({ ...prev, body: data }));
              }}
            />
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
