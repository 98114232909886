import React, { useEffect } from "react";
import { Col, Popover, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { unitsManagementStore } from "./UnitsManagementStore";
import { CardsWrap, PageWrap } from "../../../shared/commonStyles";
import { UnitsManagementButtons } from "./UnitsManagementButtons";
import styles from "./UnitsManagement.module.scss";

export const UnitsManagement = observer(() => {
  useEffect(() => {
    unitsManagementStore.fetchUnitGroups();
  }, []);

  const getUniqueMeasure = () => {
    let newList: any[] = [];
    unitsManagementStore.unitGroups.forEach(
      (item) => !newList.find((v) => v.name === item.name) && newList.push(item)
    );
    return newList;
  };

  return (
    <PageWrap>
      <Spin spinning={unitsManagementStore.loadingUnitGroups}>
        <CardsWrap>
          <Row gutter={[32, 32]} className={styles.container}>
            {getUniqueMeasure().map((item, index) => (
              <Col key={item.name + index} span={6}>
                <div className={styles.cardBox}>
                  <div className={styles.cardTitle}>
                    {item.name[0].toUpperCase() + item.name.slice(1)}
                  </div>
                  <div>
                    <Popover
                      content={
                        <div className={styles.cardPopover}>
                          {item.description}
                        </div>
                      }
                      autoAdjustOverflow
                      overlayClassName="popoverContent"
                    >
                      <div className={styles.cardDescription}>
                        {item.description}
                      </div>
                    </Popover>
                  </div>
                  <div>
                    <div className={styles.cardInformation}>
                      {item.units.length} units added
                    </div>
                    <UnitsManagementButtons data={item} />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </CardsWrap>
      </Spin>
    </PageWrap>
  );
});
