import React from "react";
import { Select } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { StyledButton } from "../../shared/commonStyles";
import styles from "./SortEmissionsLibrary.module.scss";

export const SortEmissionsLibrary = ({ sorting, changeSorting, onConfirm }) => {
  const options = [
    "name",
    "region",
    "sector",
    "category",
    "source",
    "year",
    "unit",
  ];

  return (
    <div className={styles.sortDropdown}>
      <h3 className={styles.sortDropdownTitle}>Sort by</h3>
      <div className={styles.sortDropdownSelectorWrap}>
        <Select
          value={sorting?.type || "key"}
          className={styles.sortDropdownSelector}
          dropdownClassName={styles.sortDropdownOptions}
          onChange={(value) => changeSorting({ ...sorting, type: value })}
        >
          <Select.Option value="key">Default</Select.Option>
          {options.map((item) => (
            <Select.Option key={item} value={item}>
              {item.charAt(0).toUpperCase() + item.slice(1).split("_")[0]}
            </Select.Option>
          ))}
        </Select>

        <span className={styles.sortDropdownFrom}>from</span>
        <StyledButton
          type={sorting?.sortAlphabetically ? "primary" : "default"}
          onClick={() =>
            changeSorting({ ...sorting, sortAlphabetically: true })
          }
        >
          A<ArrowRightOutlined />Z
        </StyledButton>
        <StyledButton
          type={sorting?.sortAlphabetically ? "default" : "primary"}
          onClick={() =>
            changeSorting({ ...sorting, sortAlphabetically: false })
          }
        >
          Z<ArrowRightOutlined />A
        </StyledButton>
      </div>
      <StyledButton type="primary" onClick={onConfirm}>
        Confirm
      </StyledButton>
    </div>
  );
};
