import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  Spin,
  message,
  Divider,
  Typography,
  Input,
  Row,
  Col,
  Form,
  Alert,
} from "antd";
import { StyledButton, FormWrapper } from "../../../shared/commonStyles";
import moment from "moment";
import AuditorsFilesModal from "./AuditorsFilesModal";
import { uploadDataLakeAuditFile } from "../../../services/audits";
import { YearMonthDay24HourFormat } from "../../../shared/constants";
import { AvatarUsername } from "../../../components/Avatar/AvatarUsername";
import { SignOffProgressBar } from "./SignOffProgressBar";
import {
  createStandardAuditDetail,
  updateStandardAuditDetail,
} from "./../../../services/standards";
import { useAssignedAuditorsList } from "./../../../hooks/useAssignedAuditorsList";

const { Title } = Typography;

export const SignOffSection = ({ rowData, auditRecord, refreshOnSubmit }) => {
  const [signOffForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [userAuditDetail, setUserAuditDetail] = useState(null);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const {
    loading,
    assignedAuditorsList,
    getUniqueUsersList,
    loadAssignedAuditors,
  } = useAssignedAuditorsList();

  const signedOffStatusUsersList = getUniqueUsersList(assignedAuditorsList);
  useEffect(() => {
    if (rowData && auditRecord) {
      loadAssignedAuditors(auditRecord, rowData);
    }
    if (rowData && rowData?.audit_details?.length) {
      getDataLog(rowData?.audit_details);
    }
  }, [rowData, auditRecord]);

  const getDataLog = async (auditDetails) => {
    const userObj = await Auth.currentSession();
    const username = userObj["accessToken"]["payload"]["username"];

    const selectedAuditDetail = auditDetails?.find(
      (item) => item?.username === username
    );
    setUserAuditDetail(selectedAuditDetail);
  };
  const onClose = () => {
    refreshOnSubmit();
    signOffForm.resetFields();
  };

  const uploadFile = async (file, dataId) => {
    //fileUploadState is for create or update
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      const signedURL = await uploadDataLakeAuditFile(dataId, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
            setFiles([]);
            setRefreshUploadedFiles((prev) => prev + 1);
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    }
  };

  const saveFiles = async (files, auditLogData) => {
    if (files && files.length && auditLogData?.id) {
      try {
        await Promise.all(
          await files.map(async (file) => {
            await uploadFile(file, auditLogData.id);
          })
        );
      } catch (error) {
        console.log("Something went wrong while uploading files!", error);
      }
    }
  };

  const submitSignoffStatus = async (comment, status) => {
    try {
      const data = await Auth.currentSession();
      const username = data["accessToken"]["payload"]["username"];
      setSubmitting(true);
      let response = null;
      let detailPayload = null;
      if (userAuditDetail) {
        detailPayload = {
          id: userAuditDetail.id,
          status,
          comment,
          username,
          create_timestamp: userAuditDetail.create_timestamp,
          modify_timestamp: moment().format(YearMonthDay24HourFormat),
        };
        const payload = {
          _id: rowData?._id,
          previous_audit_details: userAuditDetail,
          audit_details: detailPayload,
        };
        response = await updateStandardAuditDetail(payload);
      } else {
        detailPayload = {
          id: uuidv4(),
          status,
          comment,
          username,
          create_timestamp: moment().format(YearMonthDay24HourFormat),
          modify_timestamp: moment().format(YearMonthDay24HourFormat),
        };
        const payload = {
          _id: rowData?._id,
          audit_details: detailPayload,
        };
        response = await createStandardAuditDetail(payload);
      }
      if (response) {
        if (files.length > 0 && detailPayload) {
          saveFiles(files, detailPayload);
        }
        message.success("Record updated successfully!");
        onClose();
        //return 'success'
      } else {
        message.error("Error while updating record!");
      }
    } catch (error) {
      console.log("Error while approving data sheet record!", error);
      message.error("Error while approving data sheet record!");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="signoff-section">
      <Title className="color-white" level={3}>
        Signing Off
      </Title>
      <p className="color-grey">
        {" "}
        This section shows the audit users and actions that are possible. If you
        deny a record, you must provide a reason. If you approve a record,
        comments are optional. Hover over each user to see the audit status.
      </p>
      <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
      <Spin spinning={loading}>
        {rowData ? (
          <div className="width-100">
            {signedOffStatusUsersList && (
              <SignOffProgressBar
                uniqueUsersList={signedOffStatusUsersList}
                type="stroke"
              />
            )}
            <br />
            {signedOffStatusUsersList && (
              <SignOffUsersAvatarList
                signedOffStatusUsersList={signedOffStatusUsersList}
              />
            )}

            <Row>
              <Col span={24}>
                <p className="color-white">
                  Click on the button to make a decision
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormWrapper>
                  <Form
                    initialValues={{ comment: null, status: null }}
                    form={signOffForm}
                    onFinish={({ comment, status }) =>
                      submitSignoffStatus(comment, status)
                    }
                  >
                    {({ comment, status }) => {
                      const commentRequired = status === "denied";
                      const commentLabel =
                        status === "approved"
                          ? "Approval reason (Optional)"
                          : "Provide a reason for rejecting this record (Mandatory)";
                      return (
                        <>
                          <Row>
                            <div className="buttons-wrapper">
                              <Form.Item labelCol={{ span: 24 }} name="status">
                                {userAuditDetail &&
                                userAuditDetail?.status === "approved" ? (
                                  <StyledButton
                                    bgcolor="#7F5FEE"
                                    type="default"
                                  >
                                    Approved
                                  </StyledButton>
                                ) : (
                                  <StyledButton
                                    bgcolor={
                                      status === "approved"
                                        ? "#7F5FEE"
                                        : undefined
                                    }
                                    type="default"
                                    onClick={() => {
                                      signOffForm.setFieldsValue({
                                        status: "approved",
                                        comment: null,
                                      });
                                    }}
                                  >
                                    Approve
                                  </StyledButton>
                                )}

                                {userAuditDetail &&
                                userAuditDetail?.status === "denied" ? (
                                  <StyledButton
                                    bgcolor="#7F5FEE"
                                    type="default"
                                  >
                                    Denied
                                  </StyledButton>
                                ) : (
                                  <StyledButton
                                    bgcolor={
                                      status === "denied"
                                        ? "#7F5FEE"
                                        : undefined
                                    }
                                    type="default"
                                    onClick={() => {
                                      signOffForm.setFieldsValue({
                                        status: "denied",
                                        comment: null,
                                      });
                                    }}
                                  >
                                    Deny
                                  </StyledButton>
                                )}
                              </Form.Item>
                            </div>
                          </Row>
                          {status && (
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  name="comment"
                                  label={commentLabel}
                                  rules={[
                                    {
                                      required: commentRequired,
                                      message: "Please input the comment",
                                    },
                                  ]}
                                >
                                  <Input.TextArea placeholder={""} />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <AuditorsFilesModal
                                  auditRecord={userAuditDetail}
                                  setFiles={setFiles}
                                  files={files}
                                  refreshUploadedFiles={refreshUploadedFiles}
                                />
                              </Col>
                              <Col span={24}>
                                <Form.Item labelCol={{ span: 24 }}>
                                  <StyledButton
                                    onClick={() => onClose()}
                                    type="custom"
                                    hovercolor="#A68DFB"
                                    hoverbgcolor="transparent"
                                    bgcolor="transparent"
                                    bordercolor="transparent"
                                    color="#fff"
                                  >
                                    Close
                                  </StyledButton>
                                  <StyledButton
                                    loading={submitting}
                                    type="custom"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </StyledButton>
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </>
                      );
                    }}
                  </Form>
                </FormWrapper>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            {!loading && (
              <Alert
                message="Sorry, you are not allowed to assigned Sign-Off Status!!"
                type="info"
                showIcon
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

const SignOffUsersAvatarList = ({ signedOffStatusUsersList }) => {
  return (
    <div className="signoff-users-horizontal-wrap">
      <Row style={{ width: "100%" }}>
        <Col span={12}>
          <Title className="color-white" level={5}>
            Approved
          </Title>
          {signedOffStatusUsersList?.map((item, index) => {
            const approved =
              item?.audit_log_data?.status === "approved" ? true : false;
            if (approved) {
              return (
                <AvatarUsername
                  key={index}
                  size="large"
                  username={item.first_last_name}
                  customTooltipTitle={
                    <>
                      {item.first_last_name}
                      <br />
                      <p style={{ fontSize: "11px" }}>
                        {item?.audit_log_data?.status === "approved"
                          ? "Approved"
                          : ""}
                      </p>
                      <br />
                      {item?.audit_log_data?.comment}
                    </>
                  }
                />
              );
            }
          })}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Title className="color-white" level={5}>
            Denied
          </Title>
          {signedOffStatusUsersList?.map((item, index) => {
            const denied =
              item?.audit_log_data?.status === "denied" ? true : false;
            if (denied) {
              return (
                <AvatarUsername
                  key={index}
                  size="large"
                  username={item.first_last_name}
                  customTooltipTitle={
                    <>
                      {item.first_last_name}
                      <br />
                      <p style={{ fontSize: "11px" }}>
                        {item?.audit_log_data?.status === "denied"
                          ? "denied"
                          : ""}
                      </p>
                      {item?.audit_log_data?.comment}
                    </>
                  }
                />
              );
            }
          })}
        </Col>
      </Row>
    </div>
  );
};
