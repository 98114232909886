import React from 'react'
import { OverviewContentWrap, PageWrap, TabContentWrap } from '../../shared/commonStyles'
import ESGStandardsDataTable from "./components/ESGStandardsDataTable";

const EsgStandards = () => {
  return (
    <PageWrap>
    <TabContentWrap>
      <OverviewContentWrap style={{background: "transparent"}}>
        <ESGStandardsDataTable /> 
      </OverviewContentWrap>
    </TabContentWrap>
  </PageWrap>
  )
}

export default EsgStandards