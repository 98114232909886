import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, message, Steps, Spin, Tabs } from "antd";
import {
  StyledButton,
  FormWrapper,
  FormWrap,
  StyledTabs,
} from "../../../../shared/commonStyles";

import DataForm from "./DataForm";
import Information from "./Tabs/Information";
import Permissions from "./Tabs/Permissions";
const { Step } = Steps;
const { TabPane } = Tabs;

const DocumentView = ({ rowData, documentId }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [loadingSurveyItems, setLoadingSurveyItems] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepData, setCurrentStepData] = useState(null);
  const [stepName, setStepName] = useState([]);
  const [selectedTab, setSelectedTab] = useState("information");

  const onStepChange = (current) => {
    setCurrentStep(current);
    const stepData = dataSource[current];
    setCurrentStepData(stepData);
  };
  const onTabChange = (selectedTabKey) => {
    if (selectedTabKey === "") {
      message.info(" Please contact support@hydrus.ai for access.");
    } else {
      setSelectedTab(selectedTabKey);
    }
  };
  const loadData = async () => {
    try {
      const document_order = rowData?.sections;
      await Promise.all(
        document_order.map(async (item, index) => {
          const stepName = document_order
            ? item?.sectionName
            : `Step ${index + 1}`;
          setStepName((oldState) => ({ ...oldState, [index]: stepName }));
        })
      );
      setCurrentStepData(document_order[0]);
      setDataSource(document_order);
    } catch (error) {
      message.error(`Failed to load data ${error}`);
    } finally {
      setLoadingSurveyItems(false);
    }
  };

  useEffect(() => {
    if (state?.currentStep) {
      const stateCurrentStep = state?.currentStep;
      setCurrentStep(stateCurrentStep);
      const stepData = dataSource[stateCurrentStep];
      setCurrentStepData(stepData);
    }
  }, [state, dataSource]);

  useEffect(() => {
    if (rowData) {
      setCurrentStepData(null);
      setLoadingSurveyItems(true);

      loadData();
    }
  }, [rowData]);

  const createStepItem = (dataSource) => {
    if (dataSource) {
      return dataSource.map((item, index) => {
        return <Step key={item} title={`${stepName[index]}`} />;
      });
    }
  };
  const onClickNext = () => {
    const nextStepIndex = currentStep + 1;
    setCurrentStep(nextStepIndex);
    const stepData = dataSource[nextStepIndex];
    setCurrentStepData(stepData);
  };

  const onClickPrevious = () => {
    const previousStepIndex = currentStep - 1;
    setCurrentStep(previousStepIndex);
    const stepData = dataSource[previousStepIndex];
    setCurrentStepData(stepData);
  };

  return (
    <>
      <FormWrap width="100%" style={{ padding: 0 }}>
        <FormWrapper>
          <Spin spinning={loadingSurveyItems}>
            <Col span={24}>
              {dataSource?.length > 0 && (
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={18}
                    lg={18}
                    style={{ padding: "35px 20px" }}
                    className={`preview-left-part`}
                  >
                    <div className="user-flow-rightside-wrap">
                      {currentStepData && (
                        <>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                              <DataForm
                                currentStep={currentStep}
                                dataSource={dataSource}
                                rowData={currentStepData}
                                documentData={rowData}
                                onClickPrevious={onClickPrevious}
                                onClickNext={onClickNext}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={6}
                    lg={6}
                    className="preview-right-part"
                    style={{ padding: "35px 20px" }}
                  >
                    <Row style={{ marginBottom: "61px" }}>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Row justify="space-between">
                          <StyledTabs
                            activeKey={selectedTab}
                            onChange={onTabChange}
                          >
                            <TabPane tab="Information" key="information" />
                            <TabPane tab="Permissions" key="permissions" />
                          </StyledTabs>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTab === "information" && (
                          <div>
                            <Information
                              rowData={rowData}
                              dataSource={dataSource}
                              createStepItem={createStepItem}
                              currentStep={currentStep}
                              onStepChange={onStepChange}
                            />
                          </div>
                        )}
                        {selectedTab === "permissions" && (
                          <div>
                            <Permissions rowData={currentStepData} />
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Col>
                        <StyledButton
                          style={{ zIndex: "1" }}
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="white"
                          className="margin-5"
                          type="custom"
                          onClick={() => navigate("/reporting/document-review")}
                        >
                          <span>Exit</span>
                        </StyledButton>
                      </Col>
                      <Col>
                        <StyledButton
                          className="margin-5"
                          type="custom"
                          onClick={() => navigate("/reporting/document-review")}
                        >
                          <span>Submit</span>
                        </StyledButton>
                      </Col>
                      <Col>
                        <StyledButton
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="white"
                          className="margin-5"
                          type="custom"
                          onClick={() => navigate("/reporting/document-review")}
                        >
                          <span>Save & Exit</span>
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Spin>
        </FormWrapper>
      </FormWrap>
    </>
  );
};

export default DocumentView;
