import {
  QuestionCircleOutlined,
  SwapOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { useBoolean, useDynamicList } from "ahooks";
import { Button, Dropdown, Popover } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { SortDropdown } from "../../../../components/SortDropdown";
import { CommonTable, StyledButton } from "../../../../shared/commonStyles";
import { calculatorStore } from "../../../Carbon/CalculatorStore";
import { AddOffset } from "../../Calculator/Components/AddOffset";
import { ConfirmModal } from "../../Calculator/Components/ConfirmModal";
import TableOffsetButtons from "../../Calculator/Components/TableOffsetButtons/TableOffsetButtons";
import styles from "./Offset.module.scss";

interface OffsetProps {
  info: any;
  data: Offset[];
  goBack: () => void;
  goNext: () => void;
  emissionsUnit: string;
  conversionFactor: number;
}

interface SortOffset {
  type: string;
  sortAlphabetically: boolean;
}

export const Offset = observer(
  ({
    info,
    data,
    goBack,
    goNext,
    emissionsUnit,
    conversionFactor,
  }: OffsetProps) => {
    const [
      addOffsetModal,
      { setTrue: showOffsetModal, setFalse: hideOffsetModal },
    ] = useBoolean(false);

    const [isDropdownShown, { toggle, setFalse: hideDropdown }] =
      useBoolean(false);
    const [sortOffset, setSortOffset] = useState<SortOffset>({
      type: "key",
      sortAlphabetically: true,
    });

    const [
      isConfirmModalVisible,
      { setTrue: showConfirmModal, setFalse: hideConfirmModal },
    ] = useBoolean(false);

    const {
      list: tableData,
      resetList,
      replace,
      push,
      remove,
    } = useDynamicList(data);

    const offsetsSortOptions = [
      { name: "Name", value: "name" },
      { name: "Region", value: "region_name" },
    ];

    useEffect(() => {
      data && resetList(data);
    }, [data]);

    const handleSkip = () => {
      calculatorStore.offsets = [];
      goNext();
    };

    const handleSort = () => {
      const newData = [...tableData].sort((a, b) => {
        const aStatement = a[sortOffset.type as keyof Offset];
        const bStatement = b[sortOffset.type as keyof Offset];
        if (sortOffset.sortAlphabetically) {
          return aStatement > bStatement ? 1 : -1;
        } else {
          return aStatement < bStatement ? 1 : -1;
        }
      });
      resetList(newData);
    };

    const handleAddOffset = (data: Offset) => {
      calculatorStore.addOffset(data);
      push(data);
      hideOffsetModal();
    };
    const handleEditOffset = (data: Offset, index: number) => {
      calculatorStore.editNewOffset(data);
      replace(index, {
        ...data,
      });
    };
    const handleDeleteOffset = (data: Offset, index: number) => {
      calculatorStore.deleteNewOffset(data);
      remove(index);
    };

    const handleDownload = (offset: Offset) => {
      calculatorStore.downloadOffsetProof(offset);
    };

    const infoConfirmModal = {
      title: "Warning: Data may be lost",
      description: "Do you want to continue without saving?",
      confirmText: "Continue without saving",
      cancelText: "Return & Save",
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: Offset, b: Offset) => a.name.localeCompare(b.name),
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
      },
      {
        title: "Proof of offset",
        dataIndex: "proof",
        key: "proof",

        render: (link: string, offset: Offset) => {
          if (offset.proofName) {
            return (
              <a onClick={() => handleDownload(offset)}>
                <VerticalAlignBottomOutlined />
                <span className={styles.downloadFileText}>Download File</span>
              </a>
            );
          } else if (link) {
            return (
              <a target="_blank" rel="noreferrer" href={link}>
                {link}
              </a>
            );
          }
          return <span>-</span>;
        },
      },
      {
        title: `Offsetted Emission (${emissionsUnit} CO2e)`,
        dataIndex: "emission",
        key: "emission",
        render: (data: number) => {
          return data * conversionFactor;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "btns",
        render: (data: Offset, _: any, index: number) => {
          return (
            <TableOffsetButtons
              undo={false}
              data={data}
              editOffset={(data) => handleEditOffset(data, index)}
              onDelete={() => handleDeleteOffset(data, index)}
            />
          );
        },
      },
    ];

    return (
      <>
        <h2 className={styles.stepTitle}>
          <span>{info.title}</span>
          <Popover
            overlayClassName="popoverContent"
            content={
              <div className={styles.popoverOffset}>
                <h2>Offsets and certificates info</h2>
                <p>{info.content}</p>
              </div>
            }
            placement="right"
            autoAdjustOverflow
            arrowPointAtCenter
          >
            <QuestionCircleOutlined />
          </Popover>
        </h2>
        <div className={styles.stepSubtitle}>{info.subTitle}</div>
        <p
          className={
            !!tableData.length ? styles.stepTextFilled : styles.stepText
          }
        >
          {info.description}{" "}
          {!!calculatorStore.regions.length &&
            `in 
            ${calculatorStore.regions?.join(", ")}`}{" "}
          in {info.period}
        </p>
        {!!tableData.length && (
          <CommonTable
            rowKey={"key"}
            title={() => (
              <div className={styles.TableHeader}>
                <h1 className={styles.TableHeaderTitle}>
                  <span>{info.subTitle}</span>
                </h1>
                <Button type="text" className={styles.sortScopeButton}>
                  <Dropdown
                    overlay={
                      <SortDropdown
                        sorting={sortOffset}
                        changeSorting={setSortOffset}
                        options={offsetsSortOptions}
                        onConfirm={() => {
                          handleSort();
                          hideDropdown();
                        }}
                      />
                    }
                    trigger={["click"]}
                    onVisibleChange={toggle}
                    visible={isDropdownShown}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <SwapOutlined rotate={90} />
                      <span>Sort</span>
                    </a>
                  </Dropdown>
                </Button>
              </div>
            )}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: true }}
          />
        )}
        <div
          className={
            !!tableData.length ? styles.stepBtnWrapFilled : styles.stepBtnWrap
          }
        >
          <StyledButton
            className={styles.continueBtn}
            type="primary"
            onClick={showOffsetModal}
          >
            Add offset
          </StyledButton>
          <AddOffset
            visible={addOffsetModal}
            data={{} as Offset}
            modalName="Add offset"
            onCancel={hideOffsetModal}
            onConfirm={handleAddOffset}
          />
        </div>
        <div className={styles.stepBtnsGroup}>
          <Button onClick={goBack} className={styles.whiteBtn} type="text">
            Back
          </Button>
          <StyledButton
            className={styles.continueBtn}
            onClick={goNext}
            type="primary"
            disabled={!tableData.length}
          >
            Confirm & Finish
          </StyledButton>
          <Button
            onClick={!tableData.length ? goNext : showConfirmModal}
            className={styles.whiteBtn}
            type="text"
          >
            Skip for now & Finish
          </Button>
          <ConfirmModal
            infoConfirmModal={infoConfirmModal}
            onConfirm={handleSkip}
            visible={isConfirmModalVisible}
            setFalse={hideConfirmModal}
          />
        </div>
      </>
    );
  }
);
