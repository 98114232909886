import React from "react";
import { Table ,Typography} from 'antd';

const { Text} = Typography;

 export const SummaryRow = ({data}) => {
    if(!data?.length){return null}
    const total = {};
    data.forEach((item,index) => {
          for (const property in item) {
             if(index === 0 && property!=='key' && property!=='data'){   
               total[property] = 0;
             }
             if(property!=='key'&& property!=='data'){                
               if(!isNaN(item[property])){                   
               total[property]  += item[property];  
               }
             }
          }
    });
 
    const dynamicCells = (total) =>{
       const data = [];
       for (const property in total) {
          data.push(
          <Table.Summary.Cell key={property}>
              <Text>{total[property] ? total[property] : ""}</Text>
           </Table.Summary.Cell>
         );
       }
       return data;
    }
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell>Total</Table.Summary.Cell>
          {dynamicCells(total)}
        </Table.Summary.Row>
      </>
    );
  }