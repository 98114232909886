import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  CommonTable,
  StyledButton,
  StyledTabs,
  TableWrap,
} from "../../../shared/commonStyles";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { convertToLowUnderScore } from "../../../shared/commonFunctions";
import { SettingsInfo } from "./SettingsInfo";
import ValidationRules from "./ValidationRules";
import styles from "./FormBuilder.module.scss";
import { comparisonOperators } from "../../../services/mongoOperators";

interface UnitConversion {
  unitGroup?: string;
  unit?: string;
  conversionFactor?: number;
}
type ExtendedSheetSchema = SheetSchema & {
  schemasKey?: string;
};
interface FormBuilder {
  schema: ExtendedSheetSchema[];
  initialValidationRules?: ValidationRule[];
  measures: string[];
  units: any[];
  metaDataEntityItems?: any;
  loadingCreateDataSheet: boolean;
  submitKey?: number;
  isEditMode: boolean;
  onFinishHandler: (
    validationRules: ValidationRule[],
    value: SheetSchema[]
  ) => Promise<void>;
  onClickPrevious: () => void;
  isMetadata: boolean;
}

const dataTypes = {
  number: "number",
  string: "string",
  date: "date",
  dropdown: "string",
  radio: "string",
  checkbox: [
    {
      is_checkbox_group: true,
      data_type: "array",
    },
    {
      is_checkbox_group: false,
      data_type: "boolean",
    },
  ],
  ["long-answer"]: "text",
  ["rich-text"]: "rich-text",
  array: "array",
};

const FormBuilder = ({
  schema,
  initialValidationRules,
  measures,
  units,
  metaDataEntityItems,
  loadingCreateDataSheet,
  submitKey,
  isEditMode,
  onFinishHandler,
  onClickPrevious,
  isMetadata,
}: FormBuilder) => {
  const tableRef = useRef(null);
  const [form] = Form.useForm();
  const { Title } = Typography;
  const newSchema = schema?.map((item) => ({
    ...item,
    status: item.status === "ACTIVE",
  }));
  const [schemas, setSchemas] = useState<ExtendedSheetSchema[]>(
    (newSchema as ExtendedSheetSchema[]) || []
  );
  const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(0);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>(
    initialValidationRules || []
  );
  const [currentTab, setCurrentTab] = useState<"settings" | "rules">(
    "settings"
  );

  useEffect(() => {
    setSchemas((newSchema as ExtendedSheetSchema[]) || []);
  }, [schema]);

  const checkValidationFields = async () => {
    let isValid = true;
    try {
      await form.validateFields();
      isValid = checkValidRules();
    } catch (error: any) {
      form.setFields(error.errorFields);
      isValid = false;
    }
    return isValid;
  };

  const scrollToColumn = () => {
    const tableNode: any = tableRef?.current;
    if (tableNode) {
      const columns = tableNode.querySelectorAll(".ant-table-cell");
      if (columns && columns.length > currentFieldIndex) {
        columns[currentFieldIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  };
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ ...schemas[currentFieldIndex], validationRules });
    setTimeout(() => {
      scrollToColumn();
    }, 100);
  }, [currentFieldIndex]);

  useEffect(() => {
    form.setFieldsValue({ ...schemas[currentFieldIndex], validationRules });
  }, [currentTab]);

  const handleChangeTab = async (current: "settings" | "rules") => {
    const isValidSettings = await checkValidationFields();
    const isValid = current == "settings" ? checkValidRules() : isValidSettings;
    isValid && setCurrentTab(current);
  };

  const handleAddColumn = async () => {
    const isValid = await checkValidationFields();
    if (isValid) {
      const schemaField = {
        display_name: "",
        entity_name: "",
        input_type: "string",
        required: false,
      };

      setCurrentFieldIndex(schemas?.length);
      setSchemas((prev: any) => [...prev, schemaField]);
    }
  };

  const handleSelectCell = async (index: number) => {
    const isValid = await checkValidationFields();
    isValid && setCurrentFieldIndex(index);
  };

  const onHeaderCell = (value: any) => {
    return {
      onClick: () => {
        handleSelectCell(value?.index);
      },
    };
  };

  const handleFormChange = () => {
    const formValues = form.getFieldsValue(true);
    const { validationRules, ...rest } = formValues;
    const entityName = convertToLowUnderScore(rest?.display_name);
    const isPrimaryChecked = formValues.primaryKey;

    let newItem = {
      ...rest,
      entity_name: entityName,
      ...(isPrimaryChecked && { required: true }),
    };

    if (newItem?.input_type === "array") {
      const updatedArrayItems = (newItem?.items as any)?.map(
        (arrayItem: any) => {
          const entityNameArrayItem = convertToLowUnderScore(
            arrayItem.display_name
          );
          return {
            ...arrayItem,
            entity_name: entityNameArrayItem,
          };
        }
      );
      newItem = {
        ...newItem,
        items: updatedArrayItems,
        validationRules,
      };
    }
    const newSchema: SheetSchema[] = schemas?.map((item, index) => {
      if (index === currentFieldIndex) {
        return {
          ...newItem,
          ...(item.expressionProperties && {
            expressionProperties: item.expressionProperties,
          }),
          ...(item.expressionGroups && {
            expressionGroups: item.expressionGroups,
          }),
          ...(item.logicalExpression && {
            logicalExpression: item.logicalExpression,
          }),
          ...(item.operationType && {
            operationType: item.operationType,
          }),
          ...(item.expressionType && {
            expressionType: item.expressionType,
          }),
        };
      } else {
        return item;
      }
    });

    setSchemas(newSchema);
    setValidationRules(validationRules);
  };

  const displayDataType = (input_type: string, schema: SheetSchema) => {
    const options = schema?.options?.map((v: any) => {
      return { label: v?.label || "", value: v?.value || "" };
    });
    switch (input_type) {
      case "string":
        return "Text";
      case "number":
        return <>0 {schema?.unit_id || ""}</>;
      case "date":
        return moment().format("YYYY-MM-DD");
      case "dropdown":
        return <Select style={{ width: "100%" }} options={options} />;
      case "radio":
        return options?.map((v: { label: string; value: string }) => (
          <Radio value={v?.value}>{v?.label}</Radio>
        ));
      case "checkbox":
        return options?.length ? (
          options?.map((v: { label: string; value: string }) => (
            <Checkbox value={v?.value}>{v?.label}</Checkbox>
          ))
        ) : (
          <Checkbox />
        );

      case "long-answer":
        return <TextArea />;
      case "rich-text":
        return "Text";
      case "expression":
        return "0";
    }
  };
  const onMoveColumn = (index: number) => {
    moveItem(currentFieldIndex!, currentFieldIndex! + index);
    setCurrentFieldIndex((prev) => prev! + index);
  };
  const moveItem = (from: number, to: number) => {
    const newSchemas = schemas;
    const f = newSchemas.splice(from, 1)[0];
    newSchemas.splice(to, 0, f);
    setSchemas([...newSchemas]);
  };

  const onDeleteColumn = () => {
    setSchemas((prev) => prev.filter((v, index) => index != currentFieldIndex));
    currentFieldIndex == schemas.length - 1 &&
      currentFieldIndex !== 0 &&
      setCurrentFieldIndex((prev) => prev! - 1);
  };

  const getTableColumns = () => {
    const newArray = schemas?.map((item: any, index: number) => {
      const column: any = {
        title: item.display_name || "Enter column name",
        dataIndex: item.entity_name,
        className: index === currentFieldIndex ? "purpleBorder" : "",
        index: index,
        onHeaderCell: onHeaderCell,
        width: "100px",
        render: () => {
          return item.input_type && displayDataType(item.input_type, item);
        },
      };
      return column;
    });
    const addColBtn = {
      title: (
        <StyledButton
          className={styles.addColBtn}
          type="primery"
          icon={<PlusCircleOutlined />}
          onClick={() => handleAddColumn()}
        >
          Add column
        </StyledButton>
      ),
      width: "100px",
      className: "addButton",
      fixed: "right",
    };
    newArray?.push(addColBtn);

    return newArray;
  };

  const checkValidRules = () => {
    const rules =
      validationRules &&
      validationRules.map((item: ValidationRule) => item?.rule);
    let check = true;
    const isValidError = validationRules?.length
      ? validationRules.every(
          (item: ValidationRule) => item.errorMessage?.length
        )
      : true;
    if (rules?.[0]?.length) {
      const isValidRules = rules.every((item: any) =>
        item
          ? (item?.[item?.length - 1].type === "column" ||
              item?.[item?.length - 1].type === "number") &&
            item.find(
              (v: any) =>
                v.type === "operator" &&
                comparisonOperators.find(
                  (operator) => v.value === operator.value
                )
            )
          : true
      );
      const requiredOperator = comparisonOperators.map(
        (operator) => `"${operator.label}"`
      );
      if (!isValidRules) {
        message.error(
          `Rule should contain one of the following operators ${requiredOperator} and end with number or column`
        );
        check = false;
      }
      if (!isValidError) {
        check = false;
      }
    }
    return check;
  };
  const handleRemoveRule = (
    index: number,
    callback: (index: number) => void
  ) => {
    const formValues = form.getFieldsValue(true);
    const newValues = formValues.validationRules?.filter(
      (item: any, i: number) => i !== index
    );
    callback(index);
    form.setFieldsValue({ ...formValues, validationRules: newValues });
    setValidationRules(newValues);
  };

  const handleSubmit = async () => {
    const isValid = await checkValidationFields();
    const isMetadataValid = isMetadata
      ? !!schemas?.find((v) => v.isIdentifier)
      : true;
    !isMetadataValid && message.warning("An Identifier field must be selected");
    if (isValid && isMetadataValid) {
      const updatedRecordWithDataTypes = schemas?.map((item) => {
        const inputType = item?.input_type;
        let dataType = null;
        if (inputType === "expression") {
          return {
            added: true,
            data_type: item.operationType === "logical" ? "string" : "number",
            display_name: item.display_name,
            entity_name: convertToLowUnderScore(item.display_name),
            input_type: "expression",
            ...(item.expressionProperties && {
              expressionProperties: item.expressionProperties,
            }),
            ...(item.expressionGroups && {
              expressionGroups: item.expressionGroups,
            }),
            ...(item.logicalExpression && {
              logicalExpression: item.logicalExpression,
            }),
            ...(item.operationType && {
              operationType: item.operationType,
            }),
            ...(item.expressionType && {
              expressionType: item.expressionType,
            }),
            required: false,
            visible: true,
            description: item.description,
          };
        } else if (inputType === "checkbox") {
          const isCheckboxGroup = item?.is_checkbox_group
            ? item?.is_checkbox_group
            : false;
          const type = dataTypes?.checkbox?.find(
            (item) => item?.is_checkbox_group === isCheckboxGroup
          )?.data_type;
          dataType = type;
        } else if (inputType === "array") {
          dataType = dataTypes?.[inputType];

          const updatedArrayItems = (item?.items as any)?.map(
            (arrayItem: any) => {
              let inputType: string = arrayItem?.input_type as string;
              let dataType = null;
              if (inputType === "checkbox") {
                const isCheckboxGroup = arrayItem?.is_checkbox_group
                  ? arrayItem?.is_checkbox_group
                  : false;
                const type = dataTypes?.checkbox?.find(
                  (item) => item.is_checkbox_group === isCheckboxGroup
                )?.data_type;
                dataType = type;
              } else {
                dataType = (dataTypes as any)?.[inputType];
              }
              return {
                ...arrayItem,
                data_type: dataType,
              };
            }
          );
          item.items = updatedArrayItems;
        } else {
          dataType = (dataTypes as any)?.[inputType as any];
        }
        const statusText = item?.status ? "ACTIVE" : "INACTIVE";
        return {
          ...item,
          data_type: dataType,
          added: true,
          ...(isMetadata && { status: statusText }),
        };
      });
      onFinishHandler(
        validationRules,
        updatedRecordWithDataTypes as SheetSchema[]
      );
    }
  };

  const handleSaveConversions = (values: UnitConversion[]) => {
    const formValues = form.getFieldsValue(true);
    const newFields = { ...formValues, customConversions: values };
    form.setFieldsValue(newFields);
    handleFormChange();
  };

  const handleSaveExpression = (schema?: SheetSchema) => {
    form.setFieldsValue(schema!);
    setSchemas((prev: SheetSchema[]) =>
      prev.map((item: SheetSchema, index: number) =>
        index === currentFieldIndex ? schema! : item
      )
    );
  };

  const handleChangeColumnType = () => {
    const formValues = form.getFieldsValue(true);
    const { validationRules, display_name, input_type, required, ...rest } =
      formValues;
    const newItem = {
      validationRules,
      display_name,
      input_type,
      required,
    };
    form.resetFields();
    form.setFieldsValue(newItem);
    handleFormChange();
  };

  return (
    <Form
      form={form}
      initialValues={{
        ...(currentFieldIndex && {
          ...schemas[currentFieldIndex],
        }),
        validationRules: validationRules,
      }}
      layout={isMetadata ? "horizontal" : "vertical"}
      onFieldsChange={handleFormChange}
      onFinish={handleSubmit}
    >
      <Row justify="space-between">
        <Col span={15} style={{ padding: "30px" }}>
          <div>
            <Title level={3} className="color-white">
              Custom fields
            </Title>
            <p className="color-grey">
              Create a custom sheet using our form builder
              <br />
              Click on the button to add first column
            </p>
            <TableWrap style={{ marginTop: "0", width: "100%" }}>
              <CommonTable
                className={`tableContiner ${styles.schemaTable}`}
                hoverBgColor="#2b2636"
                dataSource={[{}, {}, {}, {}, {}]}
                columns={getTableColumns()}
                pagination={false}
                scroll={{ x: true }}
                border="none"
                ref={tableRef}
              />
            </TableWrap>
          </div>
          <Form.Item>
            <Row justify="start" align="middle">
              <Button type="text" onClick={onClickPrevious}>
                Back
              </Button>

              <StyledButton
                htmlType="submit"
                loading={loadingCreateDataSheet}
                disabled={submitKey}
              >
                Submit
              </StyledButton>
            </Row>
          </Form.Item>
        </Col>
        <Col span={8} style={{ backgroundColor: "#3C3453", padding: "20px" }}>
          <div>
            {!schemas?.length ? (
              <Typography.Title level={5} style={{ padding: "20px" }}>
                Click on the button to add first column
              </Typography.Title>
            ) : (
              <StyledTabs activeKey={currentTab} onChange={handleChangeTab}>
                <StyledTabs.TabPane tab="Settings & Actions" key="settings">
                  <SettingsInfo
                    isEditMode={isEditMode}
                    form={form}
                    handleMoveColumn={onMoveColumn}
                    schemasTemplate={schema}
                    currentSchema={schemas}
                    field={
                      schemas?.find(
                        (item, index) => index === currentFieldIndex
                      ) as SheetSchema
                    }
                    measures={measures}
                    units={units}
                    currentFieldIndex={currentFieldIndex}
                    metaDataEntityItems={metaDataEntityItems}
                    handleSaveConversions={handleSaveConversions}
                    handleSaveExpression={handleSaveExpression}
                    handleDeleteColumn={onDeleteColumn}
                    isMetadata={isMetadata}
                    handleChangeColumnType={handleChangeColumnType}
                  />
                </StyledTabs.TabPane>
                {!isMetadata && (
                  <StyledTabs.TabPane tab="Validation rules" key="rules">
                    <ValidationRules
                      form={form}
                      handleRemoveRule={handleRemoveRule}
                      checkValidRules={checkValidRules}
                      activityFields={schemas}
                    />
                  </StyledTabs.TabPane>
                )}
              </StyledTabs>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default FormBuilder;
