import React from "react";

const getaValueFromObject = (obj) => {
  const tempArr = [];
  for (const [_, value] of Object.entries(obj)) {
    tempArr.push(value);
  }
  return tempArr;
};

const getFormDataDetails = (formJsonCode, formData) => {
  const formDataDetails = [];
  const formData2 = [];
  try {    
    const lastRecord = [];
    if(Object.keys(formJsonCode).length === 0){
      lastRecord.push({title: 'Data', value: formData});
      //lastRecord = [{title: 'Data', value: formData}];
    }else{
      // get titles from formJsonCode
      for (const [_, value] of Object.entries(formJsonCode.properties)) {
        if (value.type === "array") {
          for (const [_, value2] of Object.entries(value.items.properties)) {
            formDataDetails.push(value2.title);
          }
        } else {
          formDataDetails.push(value.title);
        }
      }

      for (const [_, formDataValue] of Object.entries(formData)) {
        // get values from formData
        if (typeof formDataValue === "object") {
          formDataValue.forEach((data) => {
            const arrData = getaValueFromObject(data);
            arrData.map((r) => formData2.push(r));
          });
        } else {
          formData2.push(formDataValue);
        }
      }
      
      // merge formTitles and formValues array.
      formDataDetails.forEach((record, index) => {
        const obj = {
          title: record,
          value: (typeof formData2[index] === 'object') ? '' : formData2[index],
        };
        lastRecord.push(obj);
      });
    }
    /*let str = ''
    if(lastRecord.length){
      lastRecord?.map((item, index) => {
        if (item.notes) {
         str = str.concat(`<div key=${index} dangerouslySetInnerHTML=${{ __html: item.notes }} />`)         
        }else{
          str = str.concat(`<p>${item.title}: ${item.value}</p>`)
        }
        //return 
      })
    }
    console.log('str',str)*/
    return lastRecord;
  } catch (error) {
    console.log("error while getting questions from form json", error);
    return formDataDetails;
  }
};

/*const getFormDataDetailsForReport = (formJsonCode, formData) => {
  const formDataDetails = [];
  const formData2 = [];
  try {    
    const lastRecord = [];
    if(Object.keys(formJsonCode).length === 0){
      lastRecord.push({title: 'Data', value: <div dangerouslySetInnerHTML={{ __html: formData }} />});
      //lastRecord = [{title: 'Data', value: formData}];
    }else{
      // get titles from formJsonCode
      for (const [_, value] of Object.entries(formJsonCode.properties)) {
        if (value.type === "array") {
          for (const [_, value2] of Object.entries(value.items.properties)) {
            formDataDetails.push(value2.title);
          }
        } else {
          formDataDetails.push(value.title);
        }
      }

      for (const [_, formDataValue] of Object.entries(formData)) {
        // get values from formData
        if (typeof formDataValue === "object") {
          formDataValue.forEach((data) => {
            const arrData = getaValueFromObject(data);
            arrData.map((r) => formData2.push(r));
          });
        } else {
          formData2.push(formDataValue);
        }
      }
      
      // merge formTitles and formValues array.
      formDataDetails.forEach((record, index) => {
        const obj = {
          title: record,
          value: (typeof formData2[index] === 'object') ? '' : formData2[index],
        };
        lastRecord.push(obj);
      });
    }
    let str = ''
    if(lastRecord.length){
      lastRecord?.map((item, index) => {
        if (item.notes) {
         str = str.concat(item.notes)         
        }else{
          str = str.concat(`<p>${item.title}: ${item.value}</p>`)
        }
        //return 
      })
    }
    console.log('str',str)
    return str;
  } catch (error) {
    console.log("error while getting questions from form json", error);
    return formDataDetails;
  }
};*/


export const columnsB = (metricColumnTitle, reportingColumnTitle) => [
    {
      title: "TOPIC",
      dataIndex: "theme",
      key: "theme",
      width: "15%",
      ellipsis: false,
      render: (record) => record,
    },
    {
      title: "CODE",
      dataIndex: "specific_standard",
      key: "specific_standard",
      width: "10%",
      ellipsis: false,
      render: (record) => record,
    },
    {
      title: metricColumnTitle,
      dataIndex: "summary",
      key: "summary",
      width: "15%",
      ellipsis: false,
      render: (record) => record,
    },
    {
      title: reportingColumnTitle,
      dataIndex: "form_data_details",
      key: "form_data_details",
      width: "40%",
      ellipsis: false,
      render: (record) =>
        record &&
        record.map((item, index) => {
          if (item.notes) {
            return (
              <div key={index} dangerouslySetInnerHTML={{ __html: item.notes }} />
            );
          }
          return (
            <div key={index}>
              <p>
                {item.title}: {item.title === 'Data' ? <div key={index} dangerouslySetInnerHTML={{ __html: item.value }} /> : item.value}
              </p>
            </div>
          );
        }),
    },
  ];
  

export const parseDataFromResponseNew = (reportData, lakeData) => {
    if (reportData?.publish_status === false || !reportData) {
      return null;
    }
    const form_data = reportData.form_data;
  
    const form_data_details = getFormDataDetails(
      lakeData.form_json_code,
      form_data
    );
    /*const formDataDetailsForReport = getFormDataDetailsForReport(
      lakeData.form_json_code,
      form_data
    );*/
    if (reportData.notes) {
      form_data_details.push({ notes: reportData.notes });
    }
    return {
      ...reportData,
      publish_status: (reportData?.publish_status === true),
      form_data,
      form_data_details,
      standards: lakeData.standards,
      metric: lakeData.metric,
      theme: lakeData.theme,
      category: lakeData.category,
      summary: lakeData.summary,
      specific_standard: lakeData.specific_standard,
      form_json_code: lakeData.form_json_code,
      form_ui_code: lakeData.form_ui_code,
    };
  };

  export const parseDataFromResponse = (lakeData) => {
    //if (lakeData && lakeData?.publish_status === "true" && lakeData?.user_status === 'closed' && lakeData?.status === 'ACTIVE') {
      
      const form_data = lakeData.response;

      return {
        ...lakeData,
        publish_status: (lakeData?.publish_status === "true"),
        form_data,
        standards: lakeData?.frameworks ?? [],
        metric: lakeData?.name,
        theme: lakeData?.tags?.theme ?? "",
        category: lakeData?.tags?.category ?? "",
        summary: lakeData.summary,
        specific_standard: lakeData.specific_standard,
      };
    //}else{
      //return null
    //}
  };