import React from 'react'
import { Card, Tag, Carousel, Tooltip } from 'antd';
import { GlobalOutlined, TagOutlined } from "@ant-design/icons"
import { numberWithCommas, handleLongString } from "../../../../../shared/commonFunctions";
const { Meta } = Card;
function onChange(a, b, c) {
    console.log(a, b, c);
}
const gridStyle = {
    width: '100%',
    textAlign: 'center',
};

const ProductCardB = ({ data, onClickHandler }) => {
    //const mainPhoto = data?.['photos'][0]?.url;
    const productName = data?.name ? data?.name : "";
    const productDescription = data?.description ? data?.description : "";
    const country = data?.country ? data?.country : "";
    const remainingMassG = data?.remaining_mass_g ? data?.remaining_mass_g : 0;
    const price = data?.average_price_per_tonne_cents_usd ? data?.average_price_per_tonne_cents_usd / 100 : 0;
    //const technolgiesType = data?.technology_type?.parent_technology_type?.name;
    //const tonnes = (remainingMassG / 1000000)?.toFixed(2);

    return (
        <>
            <Card
                className='Products-card'
                style={gridStyle}
                hoverable
                cover={<Carousel afterChange={onChange}>
                    {(data?.photos || []).map((data, index) => {
                        return <div key={index}>
                            <h3><img alt="example" src={data.url} /></h3>
                        </div>
                    }
                    )}
                </Carousel>}
                onClick={() => onClickHandler(data?.id)}
            >
                <div className='products-title'>
                    <Meta
                        title={<Tooltip title={productName}>  <span> {productName} </span>  </Tooltip>}
                        description={handleLongString(productDescription, 100)}
                    />
                </div>
                <div style={{ textAlign: 'left', marginBottom: 10, marginTop: 30 }} >
                    <Tag className="margin-right-5" color="default" icon={<GlobalOutlined />}>{country.toUpperCase()}</Tag>
                    <Tag className="margin-right-5" color="default" icon={<TagOutlined />}>{`$${numberWithCommas(price)}`}</Tag>
                </div>

            </Card>
        </>
    )
}

export default ProductCardB;