import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Radio, RadioChangeEvent, Input, Form } from "antd";
import { v4 as uuidv4 } from "uuid";

import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import {
  StyledSteps,
  StyledButton,
  CommonModal,
  FormWrapper,
} from "../../../../../shared/commonStyles";
import { AutomatedEmissionsCalculation } from "../AutomatedEmissionsCalculation";
import { EmissionsLibraryModal } from "../EmissionsLibraryModal";
import { useBoolean, useDynamicList, useSetState } from "ahooks";
import { Step1AddManualEmissions } from "../AddManualEmissionsModal/Step1AddManualEmissions";
import { carbonStore } from "../../../../Carbon";
import { calculatorStore } from "../../../../Carbon";

import { getSheetById  } from "../../../../../services/dataSheet";
import { unitsManagementStore } from "../../../../Data/UnitsManagement/UnitsManagementStore";

import { Step2AddManualEmissions } from "../AddManualEmissionsModal/Step2AddManualEmissions";
import { ApproximateEmissionCalculator } from "../AddManualEmissionsModal/ApproximateEmissionCalculator";
import styles from "./AddEmissionsModal.module.scss";

import { handleAddEditEmission } from "../../EmissionsModalHelper";
import { CustomConversionFactorComponent } from "../CustomConversionFactorComponent";

import { unit } from "mathjs";

interface AddEmissionsModalProps {
  visible?: boolean;
  adding?: "automated" | "manual";
  scopeName: ScopeName;
  onClose: () => void;
  addEmission?: (newEmission: AutomatedEmission | ManualEmission[]) => void;
}




export const AddEmissionsModal = observer(
  ({
    scopeName,
    adding,
    addEmission,
    visible,
    onClose,
  }: AddEmissionsModalProps) => {
    const [form] = useForm();
    const [current, setCurrent] = useState<number>(0);

    const [activityField, setActivityField] = useState('');

    const [addingType, setAddingType] = useState<"automated" | "manual">(
      "automated"
    );

    const [manualStepNumber, setManualStepNumber] = useState<"step1" | "step2">(
      "step1"
    );
    const [calculatedBusinessEmission, setCalculatedBusinessEmission] =
      useSetState({
        index: 0,
        value: 0,
      });

    const {
      list: selectedEmissions,
      replace: replaceEmission,
      remove: removeEmission,
      resetList: resetSelectedEmissions,
    } = useDynamicList<ManualEmission>([]);

    const [autoEmission, setAutoEmission] = useState({} as AutomatedEmission);

    const [isAutoBtnDisable, setAutoBtnDisable] = useState(true);
    const [isShowCustomConversionFactor, setIsShowCustomConversionFactor] = useState(false);

    const [
      approximateEmissionCalculator,
      {
        setTrue: openApproximateEmissionCalculator,
        setFalse: hideApproximateEmissionCalculator,
      },
    ] = useBoolean(false);

    const selectedAutomatedData = {
      key: autoEmission?.key,
      name: autoEmission?.factor_data?.name,
      checked: true,
    };

    const initialValues = {
      organizationSize: 0,
      airMiles: "averageNumbers",
      averageDomesticFlights: 0,
      averageInternationalFlights: 0,
      carMiles: "averageNumbers",
      averageCarTrips: 0,
      railMiles: "averageNumbers",
      averageRailTrips: 0,
    };

    const [customConversionFactor, setCustomConversionFactor] = useState('');

    unitsManagementStore.fetchUnitGroups();

    useEffect(() => {
      adding?.length && setAddingType(adding);
    }, [adding, activityField]);

    const handleChangeActivitySheets = (activity_sheet: string) => {
      setAutoEmission(
        (prev) =>
          ({
            automatic: prev?.automatic,
            activity_sheet,
            activity_field: "",
          } as AutomatedEmission)
      );
    };

    const handleChangeValueActivityFields = (activity_field: string) => {
      setActivityField(activity_field);
      setIsShowCustomConversionFactor(false);
      setCustomConversionFactor('');
      setAutoEmission((prev) => ({ ...prev, activity_field }));
    };

    const handleChangeFilters = (filters: AdvancedDataFilter[]) => {
      setAutoEmission((prev) => ({ ...prev, filters }));
    };

    const handleChangeAutomatic = (automatic: boolean) => {
      setAutoEmission((prev) => ({ ...prev, automatic }));
    };

    // this is when emission library is selected
    const handleAddEmission = (data: any[]) => {
      handleAddEditEmission(data[0], autoEmission, setAutoEmission, setIsShowCustomConversionFactor);

      setAutoBtnDisable(false);

    };

    const handleRemoveEmission = () => {
      setAutoEmission(
        (prev) =>
          ({
            activity_field: prev?.activity_field,
            automatic: prev?.automatic,
            filters: prev?.filters,
            activity_sheet: prev?.activity_sheet,
          } as AutomatedEmission)
      );
      setAutoBtnDisable(true);
    };

    const next = () => {
      setCurrent(current + 1);
    };

    const prev = () => {
      setCurrent(current - 1);
      setIsShowCustomConversionFactor(false);
      setCustomConversionFactor('');
    };

    // main function once pop up closes to add auto emission
    const handleAddAutoEmission = async () => {
      setCurrent(0);
      onClose();

      // this should have the unit conversion factor already calculated
      const emission = await calculatorStore.getAutomatedConsumptionAmount(
        autoEmission
      );


      // if custom unit conversion factor exists and isShowCustomFactor is set to false then make this value 1
      // we want to make sure any change custom factor when it is set and  we are not using it this is set correctly in db
      // this can occur if switching back and forth between different activity fields in pop up
      const new_factor_data: any = {};
      if (emission?.factor_data?.custom_unit_conversion_factor !== undefined &&  isShowCustomConversionFactor === false) {
        // we set to 1 if we have an existing value and we aren't using custom conversion factor
        //console.log('cleaning up and setting custom unit conversion factor to 1')
        //new_factor_data.custom_unit_conversion_factor = 1;
        // we can set to undefined since we are adding and non value has been set by default this value does not exist/not defined unless used
        // when converting to json it will be ignored if undefined and get passed to backend
        new_factor_data.custom_unit_conversion_factor = undefined;
      }

      // we need to do logic here to do cleanup whether we use customConversionFactor or unit multiplier
      // so that our db can have the right settings
      // this can occur if switching back and forth between different activity fields in pop up
      const new_emission_data: any = {};
      if (emission?.datasheet_unit_conversion_factor !== undefined && isShowCustomConversionFactor === true) {
        // if we are showing custom conversion factor then that means we are not in the same unit group and so no unit conversion factor
        // and also weh ave a value in datasheet_unit_conversion_factor
        // this can occur if switching back and forth between different activity fields in pop up and going between using a multiplier and not
        // we set to 1 meaning no conversion
        //new_emission_data.datasheet_unit_conversion_factor = 1;
        // we can set to undefined since we are adding a new record and non value has been set by default this value does (not exist/not defined) unless used
        // when converting to json it will be ignored if undefined and get passed to backend
        new_emission_data.datasheet_unit_conversion_factor = undefined;
      }

      const newEmission = {
        ...emission,
        ...new_emission_data,
        factor_data: {
          ...emission.factor_data,
          ...new_factor_data
        },
        key: uuidv4(),
        automatic: autoEmission?.automatic || true,
        manual: false,
        scope: scopeName,
      } as AutomatedEmission;

      // addEmissions is typically defined this makes call to add to db
      if (addEmission) {
        addEmission(newEmission);
      } else {
        // doesn't get here
        // total emissions get calculated here, but doesn't get saved to databse
        calculatorStore.saveNewEmissions([newEmission], scopeName);
      }

      setIsShowCustomConversionFactor(false);
      setCustomConversionFactor('');
      setAutoEmission({} as AutomatedEmission);
    };

    const handleAddManualEmission = () => {
      setCurrent(0);
      setManualStepNumber("step1");
      onClose();
      if (addEmission) {
        addEmission(selectedEmissions);
      } else {
        calculatorStore.saveNewEmissions(selectedEmissions, scopeName);
      }

      setCurrent(0);
      resetSelectedEmissions([]);
    };

    const handleCancel = () => {
      onClose();
      setCurrent(0);
      resetSelectedEmissions([]);
      setManualStepNumber("step1");
    };

    const onRadioChange = (e: RadioChangeEvent) => {
      setAddingType(e.target.value);
    };

    const handleOpenApproximateEmissionCalculator = (index: number) => {
      openApproximateEmissionCalculator();
      setCalculatedBusinessEmission({ index });
    };

    const handleAddToEmissionsTracker = () => {
      hideApproximateEmissionCalculator();
      const values = form.getFieldsValue();
      //? Here should be calculation formula
      const result = values.organizationSize;
      setCalculatedBusinessEmission({ value: result });
      form.setFieldsValue(initialValues);
    };

    const steps = [
      {
        title: "Select emission type",
        content: (
          <>
            <span className={styles.selectEmissionTitle}>
              Select emission type
            </span>
            <Radio.Group
              className={styles.selectEmissionRadioGroup}
              onChange={onRadioChange}
              value={addingType}
            >
              <Radio className={styles.selectEmissionRadio} value={"automated"}>
                Automated emission calculation
              </Radio>
              <Radio className={styles.selectEmissionRadio} value={"manual"}>
                Manual emission calculation
              </Radio>
            </Radio.Group>
          </>
        ),
      },
      {
        title: "Add emission",
        content: (
          <>
            {addingType === "automated" ? (
              <div>
                <AutomatedEmissionsCalculation
                  autoEmissionData={autoEmission}
                  onChangeActivitySheet={handleChangeActivitySheets}
                  onChangeValueActivityField={handleChangeValueActivityFields}
                  onChangeFilters={handleChangeFilters}
                  onChangeAutomatic={handleChangeAutomatic}
                />
                { isShowCustomConversionFactor ? (
                  <CustomConversionFactorComponent
                    customConversionFactor={customConversionFactor}
                    setCustomConversionFactor={setCustomConversionFactor}
                    autoEmission={autoEmission}
                    setAutoEmission={setAutoEmission}
                  />
                ) : null }
                {!!autoEmission?.activity_field?.length && (
                  <>
                    <EmissionsLibraryModal
                      data={autoEmission.factor_data ? [autoEmission] : []}
                      onAddEmission={handleAddEmission}
                    />
                    {selectedAutomatedData.name && (
                      <div className={styles.selectedItems}>
                        <div className={styles.selectedCheckbox}>
                          {selectedAutomatedData.name}
                          <CloseOutlined
                            className={styles.removeIcon}
                            onClick={() => {
                              handleRemoveEmission();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {manualStepNumber === "step1" && (
                  <Step1AddManualEmissions
                    scopeName={scopeName}
                    selectedEmissions={selectedEmissions}
                    removeEmission={removeEmission}
                    resetSelectedEmissions={resetSelectedEmissions}
                  />
                )}
              </>
            )}
          </>
        ),
      },
    ];
    adding?.length && steps.splice(0, 1);
    addingType === "manual" &&
      steps.push({
        title: "Enter Amount",
        content: (
          <Step2AddManualEmissions
            selectedEmissions={selectedEmissions}
            calculatedBusinessEmission={calculatedBusinessEmission}
            removeEmission={removeEmission}
            replaceEmission={replaceEmission}
            openApproximateEmissionCalculator={
              handleOpenApproximateEmissionCalculator
            }
          />
        ),
      });

    return (
      <>
        <CommonModal
          centered
          title="Add emission"
          visible={visible}
          onOk={handleAddAutoEmission}
          onCancel={handleCancel}
          footer={
            <>
              {current === 0 && !adding?.length ? (
                <>
                  <Button type="text" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" onClick={next}>
                    Select emission type
                  </Button>
                </>
              ) : (
                <>
                  {addingType === "automated" && (
                    <>
                      <Button type="text" onClick={prev}>
                        Back
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleAddAutoEmission}
                        disabled={isAutoBtnDisable || (isShowCustomConversionFactor && (customConversionFactor.trim() === "" || isNaN(Number(customConversionFactor).valueOf())))}
                      >
                        Add Emissions
                      </Button>
                    </>
                  )}
                  {addingType === "manual" && (
                    <>
                      {manualStepNumber === "step1" ? (
                        <>
                          <Button
                            type="text"
                            className={styles.footerEmissionsButtonsCancel}
                            onClick={prev}
                          >
                            Back
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setManualStepNumber("step2");
                              next();
                            }}
                            disabled={selectedEmissions.length === 0}
                          >
                            Select calculation model
                          </Button>
                        </>
                      ) : (
                        <>
                          {approximateEmissionCalculator ? (
                            <>
                              <Button
                                type="text"
                                onClick={hideApproximateEmissionCalculator}
                              >
                                Back
                              </Button>
                              <Button
                                type="primary"
                                onClick={handleAddToEmissionsTracker}
                              >
                                Add to emissions tracker
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                type="text"
                                onClick={() => {
                                  setManualStepNumber("step1");
                                  prev();
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                type="primary"
                                onClick={handleAddManualEmission}
                              >
                                Add emissions
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          }
          width={758}
        >
          {approximateEmissionCalculator ? (
            <Form
              form={form}
              initialValues={initialValues}
              onFinish={handleAddToEmissionsTracker}
            >
              <ApproximateEmissionCalculator form={form} onSubmit={() => {}} />
            </Form>
          ) : (
            <>
              <FormWrapper>
                {" "}
                <StyledSteps
                  current={current}
                  className={styles.stepperAddEmissions}
                  onChange={setCurrent}
                >
                  {steps.map((item) => (
                    <StyledSteps.Step
                      disabled={
                        item.title == "Enter Amount" &&
                        selectedEmissions.length == 0
                      }
                      key={item.title}
                      title={item.title}
                    />
                  ))}
                </StyledSteps>
                {steps?.[current]?.content}
              </FormWrapper>
            </>
          )}
        </CommonModal>
      </>
    );
  }
);
