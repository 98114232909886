import { FileOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { StyledButton, StyledSteps } from "../../../../../shared/commonStyles";
const { Title } = Typography;

const Information = ({
  currentStep,
  onStepChange,
  createStepItem,
  dataSource,
  rowData
}) => {
  return (
    <>
      {rowData.description &&
        <>
          <Title className="color-white" level={3}>Description</Title>
          {rowData.description}
        </>
      }
      <Title className="color-white" level={3}>
        Sections
      </Title>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <StyledSteps
          current={currentStep}
          onChange={onStepChange}
          direction="vertical"
        >
          {createStepItem(dataSource)}
        </StyledSteps>
      </div>      
    </>
  );
};

export default Information;
