import React from 'react';
import { Drawer } from "antd";
import styles from "../../carbonPage/CalculatorAudit/EmissionInfoButton.module.scss";
import { StyledButton } from "../../../shared/commonStyles";

const AccountDetail = ({ showMetaDataUserInfoModal, setShowMetaDataUserInfoModal, rowData }) => {

    return (
        <Drawer
            className={styles.drawer}
            closable={false}
            placement='right'
            visible={showMetaDataUserInfoModal}
            width={400}
            onClose={() => { setShowMetaDataUserInfoModal(false) }}
        >
            <div className={styles.drawerContent}>
                <h2 className={styles.drawerTitle}>Account Details</h2>
                <ul className={styles.drawerList}>
                    <li className={styles.drawerListItem}>
                        <h3 className={styles.drawerListItemTitle}>Account Number</h3>
                        <p className={styles.drawerListItemText}>{rowData.account_number ? rowData.account_number : ''}</p>
                    </li>
                    {rowData.service_customer_name &&
                        <li className={styles.drawerListItem}>
                            <h4 className={styles.drawerListItemTitle}>Service Customer Name</h4>
                            <p className={styles.drawerListItemText}>{rowData.service_customer_name ? rowData.service_customer_name : ''}</p>
                        </li>}

                    <li className={styles.drawerListItem}>
                        <h4 className={styles.drawerListItemTitle}>Supplier Customer Id</h4>
                        <p className={styles.drawerListItemText}>{rowData.supplier_account_id ? rowData.supplier_account_id : ''}</p>
                    </li>
                    <li className={styles.drawerListItem}>
                        <h4 className={styles.drawerListItemTitle}>Account Status</h4>
                        <p className={styles.drawerListItemText}>{rowData.status ? rowData.status : ''}</p>
                    </li>

                </ul>
                <h3 className={styles.drawerSubtitle}>Address</h3>
                <ul className={styles.drawerList}>
                    <li className={styles.drawerListItem}>
                        <div className={styles.drawerListItemText}>
                            {rowData.service_address_street_one ? rowData.service_address_street_one + ",   ": ''}{rowData.service_address_street_two ? rowData.service_address_street_two + ",   " : ''}
                        </div>
                    </li>
                    <li className={styles.drawerListItem}>
                        <h4 className={styles.drawerListItemTitle}>City</h4>
                        <div className={styles.drawerListItemText}>
                            {rowData.service_address_city ? rowData.service_address_city : ''}
                        </div>
                    </li>
                    <li className={styles.drawerListItem}>
                        <h4 className={styles.drawerListItemTitle}>State</h4>
                        <div className={styles.drawerListItemText}>
                            {rowData.service_address_state ? rowData.service_address_state : ''}
                        </div>
                    </li>
                    <li className={styles.drawerListItem}>
                        <h4 className={styles.drawerListItemTitle}>Zip Code</h4>
                        <div className={styles.drawerListItemText}>
                            {rowData.service_address_zip ? rowData.service_address_zip : ''}
                        </div>
                    </li>
                </ul>
                <div className={styles.drawerFooter}>
                    <StyledButton onClick={() => { setShowMetaDataUserInfoModal(false) }}>Close</StyledButton>
                </div>
            </div>
        </Drawer>
    )
}

export default AccountDetail
