import React from "react";
import { Select } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FormWrapper, StyledButton } from "../../shared/commonStyles";
import styles from "./SortDropdown.module.scss";

export const SortDropdown = ({
  sorting,
  changeSorting,
  options,
  onConfirm,
}) => {
  return (
    <FormWrapper>
      <div className={styles.sortDropdown}>
        <h3 className={styles.sortDropdownTitle}>Sort by</h3>
        <div className={styles.sortDropdownSelectorWrap}>
          <Select
            value={sorting?.type || "key"}
            className={styles.sortDropdownSelector}
            onChange={(value) => changeSorting({ ...sorting, type: value })}
          >
            <Select.Option value="key">Default</Select.Option>
            {options.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              );
            })}
          </Select>
          <span className={styles.sortDropdownFrom}>from</span>
          <StyledButton
            type="custom"
            padding="5px 10px"
            bgcolor={sorting?.sortAlphabetically ? "#A68DFB" : "#443A59"}
            bordercolor={sorting?.sortAlphabetically ? "#A68DFB" : "#443A59"}
            onClick={() =>
              changeSorting({ ...sorting, sortAlphabetically: true })
            }
          >
            A<ArrowRightOutlined />Z
          </StyledButton>
          <StyledButton
            type="custom"
            padding="5px 10px"
            bgcolor={sorting?.sortAlphabetically ? "#443A59" : "#A68DFB"}
            bordercolor={sorting?.sortAlphabetically ? "#443A59" : "#A68DFB"}
            onClick={() =>
              changeSorting({ ...sorting, sortAlphabetically: false })
            }
          >
            Z<ArrowRightOutlined />A
          </StyledButton>
        </div>
        <StyledButton type="custom" onClick={onConfirm}>
          Confirm
        </StyledButton>
      </div>
    </FormWrapper>
  );
};
