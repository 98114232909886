export const getSchemaForRealEstateList = () => [
  {
    display_name: "Facility ID",
    entity_name: "facility_id",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Property Name",
    entity_name: "property_name",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Property Type",
    entity_name: "property_type",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Address",
    entity_name: "address",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "City",
    entity_name: "city",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "State",
    entity_name: "state",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Zip Code",
    entity_name: "zip_code",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Country",
    entity_name: "country",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Property Square Footage",
    entity_name: "property_square_footage",
    input_type: "number",
    unit_id: "",
    data_type: "number",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Current Property Valuation",
    entity_name: "current_property_valuation",
    input_type: "number",
    unit_id: "",
    data_type: "number",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
];

export const getSchemaForSupplierList = () => [
  {
    display_name: "Supplier ID",
    entity_name: "supplier_id",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Supplier Name",
    entity_name: "supplier_name",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Supplier Type",
    entity_name: "supplier_type",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Value of Supplies",
    entity_name: "value_of_supplies",
    input_type: "number",
    unit_id: "",
    data_type: "number",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Address",
    entity_name: "address",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "City",
    entity_name: "city",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "State",
    entity_name: "state",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Zip Code",
    entity_name: "zip_code",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Country",
    entity_name: "country",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
];

export const getSchemaForPortfolioCompanyList = () => [
  {
    display_name: "Company ID",
    entity_name: "company_id",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Company Name",
    entity_name: "company_name",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Industry",
    entity_name: "industry",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Company Value",
    entity_name: "company_value",
    input_type: "number",
    unit_id: "",
    data_type: "number",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Value of equity in company",
    entity_name: "value_of_equity_in_company",
    input_type: "number",
    unit_id: "",
    data_type: "number",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Headquarters Address",
    entity_name: "headquarters_address",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "City",
    entity_name: "city",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "State",
    entity_name: "state",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Zip Code",
    entity_name: "zip_code",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
  {
    display_name: "Country",
    entity_name: "country",
    input_type: "string",
    unit_id: "",
    data_type: "string",
    required: false,
    status: "ACTIVE",
    expose: true,
  },
];
