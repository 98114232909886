import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useBoolean } from "ahooks";

import { CommonTable } from "../../../shared/commonStyles";
import { InputReasonsModal } from "./Components/InputReasonsModal";
import { Button, Col, Dropdown, Popover, Row, message } from "antd";
import { CalculationsActions } from "./CalculationsActions";

import { calculationStore } from "../../Carbon";
import { calculatorStore } from "../../Carbon";

import styles from "./CalculationsAudit.module.scss";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import moment from "moment";
import { getCompanyInfo } from "../../../services/companyInfo";
import { doConversion } from "../../../services/dataSheet";
import { getEmissionsUnit } from "../../Carbon/CalculationUtils";
import { InfoCircleOutlined } from "@ant-design/icons";

interface CalculationExtended extends ICalculation {
  key: string;
}

export const CalculationsAudit = observer(() => {
  const { state } = useLocation();
  const [selectedRows, setSelectedRows] = useState<CalculationExtended[]>([]);

  const [selectedItemsData, setSelectedItemsData] = useState<
    CalculationExtended[]
  >([] as CalculationExtended[]);
  const [isChecked, { setTrue: setIsChecked, setFalse: hideChecked }] =
    useBoolean(false);

  const [
    isApproveHeaderModalVisible,
    { setFalse: setApproveHeaderModalFalse, set: setApprove },
  ] = useBoolean(false);

  const [
    isDeclineHeaderModalVisible,
    { setFalse: setDeclineHeaderModalFalse, set: setDecline },
  ] = useBoolean(false);

  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  const [conversionFactor, setConversionFactor] = useState(1);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  useEffect(() => {
    calculationStore.getAuditCalculations(state as Array<string>);
    userInfoStore.fetchUserInfo();
    loadCompanyInfo();
  }, []);

  useEffect(() => {
    setSelectedItemsData((prevData) => {
      return selectedRows?.map((item) => {
        return {
          ...item,
          key: item.key,
          name:
            selectedItemsData.find((v) => v.key === item.key)?.name ||
            prevData.find((v) => v.key === item.key)?.name ||
            "",
          checked: !!getIdsFromSelectedData(selectedRows).find(
            (key) => key === item.key
          ),
        };
      });
    });
  }, [selectedRows]);

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  const checkIsDisable = (audit_state: AuditState) =>
    !!selectedRows.some(
      (v) =>
        v?.audits?.find((item) => item.user_id === userInfoStore.userID)
          ?.status === audit_state
    );

  const handleConfirmCalculation = (
    id: string,
    reason: string,
    audit_state: AuditState
  ) => {
    const calculation = calculationStore.savedCalculations.find(
      (v) => v._id?.$oid === id
    );
    if (calculation) {
      const prevAudits =
        calculation.audits?.filter(
          (item) => item.user_id !== userInfoStore.userID
        ) || [];
      const payload: ICalculation = {
        _id: calculation._id,
        audits: [
          ...prevAudits,
          {
            status: audit_state,
            comments: reason,
            timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
            auditor_name: userInfoStore.userName,
            user_id: userInfoStore.userID,
          },
        ],
      } as ICalculation;
      calculatorStore.partialUpdateCalculation(payload).then(() => {
        calculationStore.getAuditCalculations();
      });
    }
  };

  const handleConfirmAllCalculation = (
    audit_state: AuditState,
    reason: string
  ) => {
    calculationStore.savedCalculations.forEach((v) => {
      const isRowSelected = selectedRows.find((row) => row.key === v._id?.$oid);
      if (isRowSelected) {
        const prevAudits =
          v.audits?.filter((item) => item.user_id !== userInfoStore.userID) ||
          [];
        const payload: ICalculation = {
          _id: v._id,
          audits: [
            ...prevAudits,
            {
              status: audit_state,
              comments: reason,
              timestamp: moment().format("YYYY-MM-DD").toLocaleString(),
              auditor_name: userInfoStore.userName,
              user_id: userInfoStore.userID,
            },
          ],
        } as ICalculation;
        calculatorStore.partialUpdateCalculation(payload).then(() => {
          calculationStore.getAuditCalculations();
        });
      }
    });
  };

  const getIdsFromSelectedData = (selectedData: CalculationExtended[]) => {
    return selectedData.map((item) => item.key);
  };

  const rowSelection = {
    onChange: (
      _selectedRowKeys: string,
      selectedItems: Array<CalculationExtended>
    ): void => {
      setSelectedRows(selectedItems);
      selectedItems.length ? setIsChecked() : hideChecked();
    },
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (_: any, calculation: ICalculation) => {
        return (
          <>
            {" "}
            {moment(
              (calculation.date_start as { $date: string })?.$date ||
                (calculation.date_start as string)
            ).format("YYYY-MM-DD")}{" "}
            -{" "}
            {moment(
              (calculation.date_end as { $date: string })?.$date ||
                (calculation.date_start as string)
            ).format("YYYY-MM-DD")}
          </>
        );
      },
    },
    {
      title: `Total (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      key: "total_kg_CO2e",
      dataIndex: "total_kg_CO2e",
      align: "center",
      render: (_: any, data: ICalculation): JSX.Element => {
        return (
          <>
            <span>
              {data.total_calculate?.all_total &&
              data.total_calculate?.all_total.toString().split(".")[1]?.length >
                6
                ? (
                    Number(
                      data.total_calculate?.all_total.toString().split("e")[0]
                    ) * conversionFactor
                  ).toFixed(companyDetails.decimal_places_number)
                : (
                    +data.total_calculate?.all_total! * conversionFactor
                  ).toFixed(companyDetails.decimal_places_number)}
            </span>
            <Popover
              content={
                <Row>
                  <Col span={24}>
                    Attribution factor:{" "}
                    {(data as ICalculation).attribution_factor}%
                  </Col>
                  <Col span={24}>
                    Unattributed -{" "}
                    {(
                      (data as ICalculation).total_calculate
                        ?.unattributed_total! * conversionFactor
                    ).toFixed(companyDetails.decimal_places_number)}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                  </Col>
                </Row>
              }
              overlayClassName="popoverContent"
            >
              <InfoCircleOutlined className={styles.icon} />
            </Popover>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_: any, data: ICalculation): JSX.Element => {
        return (
          <CalculationsActions
            data={data}
            onConfirm={(reason, audit_state) =>
              handleConfirmCalculation(
                data._id?.$oid || "",
                reason,
                audit_state
              )
            }
          />
        );
      },
    },
  ];

  return (
    <>
      {calculationStore.savedCalculations.length === 0 &&
      !calculationStore.loadingCalculations ? (
        <div className={styles.emptyCalculationsContainer}>
          <h2 className={styles.emptyCalculationsTitle}>
            You don't have any calculations to audit
          </h2>
        </div>
      ) : (
        <>
          {isChecked && (
            <div className={styles.headerButtonsGroup}>
              <Dropdown
                overlay={
                  <InputReasonsModal
                    onConfirm={(reason) =>
                      handleConfirmAllCalculation(AuditState.APPROVED, reason)
                    }
                    onClose={setApproveHeaderModalFalse}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
                disabled={checkIsDisable(AuditState.APPROVED)}
                onVisibleChange={(open) => setApprove(open)}
                visible={isApproveHeaderModalVisible}
              >
                <Button type="primary" className={styles.buttonHeader}>
                  Mark all as approved ({selectedRows.length})
                </Button>
              </Dropdown>
              <Dropdown
                overlay={
                  <InputReasonsModal
                    onConfirm={(reason) =>
                      handleConfirmAllCalculation(AuditState.DECLINED, reason)
                    }
                    onClose={setDeclineHeaderModalFalse}
                    isRequired
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
                disabled={checkIsDisable(AuditState.DECLINED)}
                onVisibleChange={(open) => setDecline(open)}
                visible={isDeclineHeaderModalVisible}
              >
                <Button type="primary" className={styles.buttonHeader}>
                  Decline all ({selectedRows.length})
                </Button>
              </Dropdown>
            </div>
          )}
          <CommonTable
            rowKey={(v: ICalculation) => v?._id?.$oid}
            rowSelection={rowSelection}
            loading={calculationStore.loadingCalculations}
            dataSource={calculationStore.savedCalculations}
            columns={columns}
            pagination={false}
            size="small"
          />
        </>
      )}
    </>
  );
});
