import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Comment, Input, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../../../pages/carbonPage/Calculator/Components/InfoAndNotesDrawer/InfoAndNotesDrawer.module.scss";
import { getFirstAndLastNameByUsername } from "../../../shared/commonFunctions";
import { FormWrapper, StyledButton } from "../../../shared/commonStyles";
import { userInfoStore } from "../../../pages/Carbon/UserInfoStore";

export type Comment = {
  comment_data: string;
  comment_id: string;
  create_timestamp: string;
  modify_timestamp: string;
  status: string;
  username: string;
};
export type CommentsListProps = {
  rowData: any;
  editLakeComment(comment: Comment, data: string): void;
  deleteLakeComment(data: any): void;
  addComment(data: any): void;
};
export const CommentsList = ({
  rowData,
  addComment,
  editLakeComment,
  deleteLakeComment,
}: CommentsListProps) => {
  const [note, setNote] = useState("");
  const [comments, setComments] = useState<any[]>([]);
  const [loadingLakeComments, setLoadingLakeComments] = useState(false);
  const [currentEditableNote, setCurrentEditableNote] = useState<Comment>(
    {} as Comment
  );

  const renderComments = async (comment: any) => {
    try {
      const first_last_name = await getFirstAndLastNameByUsername(
        comment.username
      );
      const data = { ...comment, first_last_name };
      return data;
    } catch (error) {
      console.log("error while rendering comments", error);
      message.error("error while rendering comments");
    }
  };

  const renderAllComments = async (comments: any[]) => {
    try {
      setLoadingLakeComments(true);
      const newData = await Promise.all(
        comments?.map(async (comment) => {
          return renderComments(comment);
        })
      );
      setComments(newData);
    } catch (error) {
      console.log("error while rendering comments", error);
      message.error("error while rendering comments");
    } finally {
      setLoadingLakeComments(false);
    }
  };
  useEffect(() => {
    if (rowData?.comments?.length > 0) {
      const activeComments = rowData?.comments?.filter(
        (item: any) => item.status === "ACTIVE"
      );
      if (activeComments) {
        renderAllComments(activeComments);
      }
    }
  }, [rowData]);

  if (loadingLakeComments) {
    return (
      <Row justify="center">
        <Spin spinning={loadingLakeComments} />
      </Row>
    );
  }
  const handleEditNote = (v: any) => {
    editLakeComment(v, currentEditableNote.comment_data);
    setCurrentEditableNote({} as Comment);
  };
  return (
    <div
      className="comments-list"
      style={{ marginBottom: loadingLakeComments ? 20 : 0 }}
    >
      <FormWrapper>
        <Spin spinning={false}>
          <div className={styles.drawerContent}>
            <>
              <p>Add your comment</p>
              <Input.TextArea
                className={styles.drawerItemInput}
                placeholder="Enter notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <StyledButton
                onClick={() => {
                  addComment(note);
                  setNote("");
                }}
              >
                Add comment
              </StyledButton>
              <Button
                type="text"
                //  onClick={handleClearNote}
              >
                Clear
              </Button>
            </>

            <ul className={styles.drawerList}>
              {!!comments.length &&
                comments.map((v: any) => (
                  <li className={styles.drawerListItem} key={v.key}>
                    <Row style={{ alignItems: "baseline" }}>
                      <Col span={16}>
                        <div className={styles.drawerListItemTitle}>
                          <div
                            className={styles.drawerListItemTitleDescription}
                          >
                            {v.first_last_name}
                          </div>
                          <div>{v?.create_timestamp?.slice(0, 11)}</div>
                        </div>{" "}
                      </Col>

                      {v.username === userInfoStore.userID &&
                        v.status !== "INACTIVE" && (
                          <Col span={8} className={styles.btnIconsWrap}>
                            {" "}
                            <Button
                              className={styles.btnIcon}
                              onClick={() => {
                                deleteLakeComment(v);
                              }}
                            >
                              <DeleteOutlined />
                            </Button>
                            <Button
                              className={styles.btnIcon}
                              onClick={() => setCurrentEditableNote(v)}
                            >
                              <EditOutlined />
                            </Button>
                          </Col>
                        )}
                    </Row>
                    {currentEditableNote?.comment_id === v.comment_id ? (
                      <>
                        <Input.TextArea
                          className={styles.drawerItemInput}
                          placeholder="Enter notes"
                          value={currentEditableNote.comment_data}
                          onChange={(e) =>
                            setCurrentEditableNote((prev) => ({
                              ...prev,
                              comment_data: e.target.value,
                            }))
                          }
                        />
                        <StyledButton onClick={() => handleEditNote(v)}>
                          Ok
                        </StyledButton>
                      </>
                    ) : v.isDeleted ? (
                      <del className={styles.drawerListItemText}>
                        {v.comment_data}
                      </del>
                    ) : (
                      <p className={styles.drawerListItemText}>
                        {v.comment_data}
                      </p>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </Spin>
      </FormWrapper>
    </div>
  );
};
