import React, { useEffect, useState } from "react";
import { Row, Switch, message, Col, Space } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import UploadLogoModal from "./UploadLogoModal";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  StyledButton,
  CommonTable,
} from "./../../../../shared/commonStyles";
import FilterModalCommon from "../../../../components/Modals/CreateFilterModal/FilterModalCommon";
import {
  getReportFilters,
  removeReportFilters,
  updateReportFilters,
} from "../../../../services/filter";
import ChangeStatus from "./ChangeStatus";
import ConfirmModal from "../../../../components/Modals/CreateFilterModal/ConfirmModal";

const parseDate = (date) => moment(date, YearMonthDayFormat);
const BusinessUnitsDataTable = ({
  section,
}) => {
  const [rowData, setRowData] = useState(null);
  const [showBusinessUnitModal, setShowBusinessUnitModal] = useState(false);
  const [showUploadLogoModal, setShowUploadModal] = useState(false);
  const [showDeleteChartModal, setShowDeleteChartModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [businessUnitsData, setBusinessUnits] = useState(null);
  const [title, setTitle] = useState();

  const refreshBusinessUnit = async () => {
    try {
      setTableLoader(true)
      const payload = { filter: { filter_type: "business_unit" } };
      const newResponse = await getReportFilters(payload);
      return setBusinessUnits(newResponse);
    } finally {
      setTableLoader(false)
    }
  };

  useEffect(() => {
    refreshBusinessUnit()
  }, []);

  const onClickEdit = (record) => {
    setRowData(record);
    setShowBusinessUnitModal(true);
  };
  const onClickDelete = (record) => {
    setRowData(record["_id"]);
    setShowDeleteChartModal(true);
  };

  const onClickUpload = (record) => {
    setRowData(record);
    setShowUploadModal(true);
  };

  const onChangeStatus = async (checked, record) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      setTableLoader(true);
      const payload = {
        ...record,
        status,
      };

      const response = await updateReportFilters(payload);
      if (response) {
        refreshBusinessUnit();
        message.success("Status updated successfully!");
      }
    } catch (error) {
      message.error("Something went wrong while updating the status.");
      console.log(error);
    } finally {
      setTableLoader(false);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setConfirmLoading(true);
      const payload = {
        _id: rowData,
      };
      const deleteChartResponse = await removeReportFilters(payload);

      if (deleteChartResponse) {
        const newResponse = await refreshBusinessUnit();
        if (newResponse) {
          message.success("Business Unit Deleted Successfully!");
        }
        setShowDeleteChartModal(false);
        setConfirmLoading(false);
      } else {
        message.error("Failed to delete Business Unit!");
      }
    } catch (error) {
      console.log("Something went wrong while updating Business Unit!", error);
      message.error("Something went wrong while updating Business Unit!");
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
    },
    {
      title: "Date Created",
      dataIndex: "create_timestamp",
      width: "15%",
      sorter: (a, b) =>
        parseDate(b.create_timestamp) > parseDate(a.create_timestamp) ? 1 : -1,
      sortDirections: ["descend", "ascend"],
      render: (record) => {
        return record ? moment(record).format(YearMonthDayFormat) : null;
      },
    },
    {
      title: "Logo",
      key: "operation",
      width: "10%",
      render: (_, record) => (
        <StyledButton type="default" onClick={() => onClickUpload(record)}>
          <UploadOutlined />
        </StyledButton>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (_, rowData) => (
        <ChangeStatus rowData={rowData} onChangeStatus={onChangeStatus} />
      ),
      sorter: (a, b) =>
        a?.status && b?.status && b.status.localeCompare(a.status),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      key: "operation",
      width: "6%",
      render: (_, record) => (
        <>
          <Space direction="horizontal">
            <StyledButton type="default" onClick={() => onClickEdit(record)}>
              <EditOutlined />
            </StyledButton>
            <StyledButton type="default" onClick={() => onClickDelete(record)}>
              <DeleteOutlined />
            </StyledButton>
          </Space>
        </>
      ),
    },
  ];

  const updateBusinessUnitData = (dataForUpdate) => {
    if (businessUnitsData && dataForUpdate) {
      const updatedBusinessUnits = businessUnitsData.map((item) => {
        if (item.id === dataForUpdate.id) {
          return { ...item, ...dataForUpdate };
        }
        return item;
      });
      setConfirmLoading(false);
      setBusinessUnits(updatedBusinessUnits);
    }
  };
  const onCloseModal = () => {
    setRowData(null);
    setShowBusinessUnitModal(false);
  };

  useEffect(() => {
    if (section === "business_unit") {
      setTitle("business unit");
    }
  }, [section]);
  return (
    <>
      <Row style={{ justifyContent: "flex-end" }}>
        <StyledButton
          className="margin-0"
          type="custom"
          onClick={() => setShowBusinessUnitModal(true)}
        >
          <PlusOutlined />
          <span>Create New Business Unit</span>
        </StyledButton>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <CommonTable
            loading={tableLoader}
            rowKey={(data) => {
              return data?.["_id"]?.["$oid"];
            }}
            dataSource={businessUnitsData}
            columns={columns}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      {showBusinessUnitModal &&
        <FilterModalCommon
          visible={showBusinessUnitModal}
          onClose={() => onCloseModal()}
          rowData={rowData}
          section={section}
          refresh={() => refreshBusinessUnit()}
        />
      }
      {showUploadLogoModal &&
        <UploadLogoModal
          visible={showUploadLogoModal}
          onClose={() => setShowUploadModal(false)}
          rowData={rowData}
          updateBusinessUnitData={updateBusinessUnitData}
        />
      }
      {showDeleteChartModal &&
        <ConfirmModal
          description={`Are you sure you want to delete this ${title} ?`}
          isVisible={showDeleteChartModal}
          onClose={() => setShowDeleteChartModal(false)}
          onConfirm={onConfirmDelete}
          confirmLoading={confirmLoading}
        />
      }
    </>
  );
};

export default BusinessUnitsDataTable;
