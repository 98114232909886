import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Drawer, Input, Row, Spin } from "antd";
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import moment from "moment";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import { fetchProfileImage } from "../../../../../shared/commonFunctions";
import styles from "./NotesDrawer.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useCheckRolesPermissions } from "../../../../../hooks/useCheckRolesPermissions";

interface NotesDrawerProps {
  visible: boolean;
  data: any;
  notes: Note[];
  loading: boolean;
  onClose: () => void;
  onSubmit: (text: string) => void;
  onUpdateNotes: (notes: Note[]) => void;
  isFieldNote?: boolean;
  isViewOnly?: boolean;
}

export const NotesDrawer = ({
  visible,
  data,
  notes,
  onClose,
  loading,
  onUpdateNotes,
  onSubmit,
  isFieldNote,
  isViewOnly,
}: NotesDrawerProps) => {
  const [note, setNote] = useState("");
  const [userInfo, setUserInfo] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [savedNotes, setSavedNotes] = useState<Note[]>();
  const [currentEditableNote, setCurrentEditableNote] = useState<Note>(
    {} as Note
  );
  const { checkRolesPermission } = useCheckRolesPermissions();

  useEffect(() => {
    if (userInfo) {
      getProfileImage(userInfo?.sub);
    }
  }, [userInfo]);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const notesWithKeys =
      notes && notes.map((item) => ({ ...item, key: item?.key || uuidv4() }));
    checkRolesPermission(["role:admin"])
      ? setSavedNotes(notesWithKeys)
      : setSavedNotes(notesWithKeys?.filter((v) => !v.isDeleted));
  }, [notes]);

  const getUserInfo = () => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo["attributes"]);
      })
      .catch((err) => console.error(err));
  };

  const getProfileImage = async (username: string) => {
    try {
      const profile = await fetchProfileImage(username);
      setProfileImage(profile);
    } catch (error) {
      console.error("Error while load header logo", error);
    }
  };

  const firstValue = !!data[0]?.["$date"]
    ? moment.utc(data[0]?.["$date"]).format("YYYY-MM-DD")
    : data[0];

  const secondValue = !!data[1]?.["$date"]
    ? moment.utc(data[1]?.["$date"]).format("YYYY-MM-DD")
    : data[1];

  const handleSubmit = () => {
    note.length ? onSubmit(note) : onClose();
    setNote("");
  };
  const handleClose = () => {
    setNote("");
    onClose();
  };
  const handleEditNote = (v: Note) => {
    if (savedNotes) {
      const newNotes = savedNotes.map((note) => {
        if (note.key == v.key) {
          return {
            ...note,
            text: currentEditableNote.text,
          };
        } else {
          return note;
        }
      });
      onUpdateNotes(newNotes);
    }
    setCurrentEditableNote({} as Note);
  };
  const handleDeleteNote = (v: Note) => {
    if (savedNotes) {
      const newNotes = savedNotes.map((note) => {
        if (note.key == v.key) {
          return {
            ...note,
            isDeleted: true,
          };
        } else {
          return note;
        }
      });
      onUpdateNotes(newNotes);
    }
    setCurrentEditableNote({} as Note);
  };

  return (
    <Drawer
      width={400}
      className={styles.drawer}
      placement="right"
      visible={visible}
      onClose={handleClose}
      title="Notes"
    >
      <FormWrapper>
        <Spin spinning={loading}>
          <div className={styles.drawerContent}>
            <h2 className={styles.drawerTitle}>
              {firstValue} {secondValue}
            </h2>
            <ul className={styles.drawerList}>
              {!isFieldNote && !isViewOnly && (
                <li className={styles.drawerListItem}>
                  <div>
                    <Avatar
                      src={profileImage}
                      icon={<UserOutlined style={{ color: "#cccccc" }} />}
                      size="large"
                    />
                  </div>
                  <div className={styles.drawerListItemWrapper}>
                    <Input.TextArea
                      showCount
                      value={note}
                      maxLength={100}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Add note"
                      className={styles.drawerListItemTextArea}
                    />
                  </div>
                </li>
              )}
              {savedNotes
                ?.sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((note) => (
                  <li className={styles.drawerListItem} key={note.key}>
                    <div className={styles.drawerListItemWrapper}>
                      <Row style={{ alignItems: "baseline" }}>
                        <Col span={16}>
                          <div className={styles.drawerListItemHeader}>
                            <div
                              className={styles.drawerListItemTitleDescription}
                            >
                              {note.user}
                            </div>
                            <div>{note.date?.slice(0, 11)}</div>
                          </div>
                        </Col>{" "}
                        {userInfo?.name === note?.user &&
                          !note.isDeleted &&
                          !isViewOnly && (
                            <Col span={8} className={styles.btnIconsWrap}>
                              <Button
                                className={styles.btnIcon}
                                onClick={() => {
                                  handleDeleteNote(note);
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                              <Button
                                className={styles.btnIcon}
                                onClick={() => setCurrentEditableNote(note)}
                              >
                                <EditOutlined />
                              </Button>
                            </Col>
                          )}{" "}
                      </Row>
                      {currentEditableNote?.key === note.key ? (
                        <>
                          <Input.TextArea
                            className={styles.drawerItemInput}
                            placeholder="Enter notes"
                            value={currentEditableNote.text}
                            onChange={(e) =>
                              setCurrentEditableNote((prev) => ({
                                ...prev,
                                text: e.target.value,
                              }))
                            }
                          />
                          <StyledButton onClick={() => handleEditNote(note)}>
                            Ok
                          </StyledButton>
                        </>
                      ) : note.isDeleted ? (
                        <del className={styles.drawerListItemText}>
                          {isFieldNote &&
                            (note.noteType === "warning" ? (
                              <WarningOutlined className={styles.iconOrange} />
                            ) : (
                              <CommentOutlined className={styles.iconViolet} />
                            ))}{" "}
                          {note.text}
                        </del>
                      ) : (
                        <p className={styles.drawerListItemText}>
                          {isFieldNote &&
                            (note.noteType === "warning" ? (
                              <WarningOutlined className={styles.iconOrange} />
                            ) : (
                              <CommentOutlined className={styles.iconViolet} />
                            ))}{" "}
                          {note.text}
                        </p>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
            <div className={styles.drawerFooter}>
              <StyledButton onClick={handleClose}>Close</StyledButton>
              {!isFieldNote && !isViewOnly && (
                <StyledButton
                  onClick={handleSubmit}
                  disabled={currentEditableNote?.key?.length}
                >
                  Submit
                </StyledButton>
              )}
            </div>
          </div>
        </Spin>
      </FormWrapper>
    </Drawer>
  );
};
