import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { EmissionLibrary } from "../EmissionLibrary";

export const EmissionLibraryPage = () => {
  const [factorsType, setFactorsType] = useState<
    "emissionsLibrary" | "customFactors"
  >("emissionsLibrary");

  return (
    <>
      <EmissionLibrary
        setFactorsType={setFactorsType}
        factorsType={factorsType}
      />
    </>
  );
};
