import React from "react";
import { Col, Divider, Form, Input, message, Row ,Typography} from "antd";
import {
  StyledButton,
  FormWrapper,
  FormWrap,
} from "../../shared/commonStyles";
import { API, Auth } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
const { Title } = Typography;
const AnalyticsBaseUrlForm = ({ companyDetails }) => {
  const [form] = Form.useForm();

  const onFormSubmit = () => {
    form.validateFields().then(async (values) => {
      const data = await Auth.currentSession();
      const groupId = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      companyDetails["chart_base_url"] = values["chart_base_url"];

        try {
          if (companyDetails) {
          const payload = {
            data: `${JSON.stringify(companyDetails)}`,
            group: groupId,
          };
          const response = await API.graphql({
            query: mutations.updateCompanyInfo,
            variables: { input: payload },
          });
          const updatedCompanyInfo = response["data"]["updateCompanyInfo"];
          if (updatedCompanyInfo) {
            message.success("Data updated Successfully!");
            setTimeout(window.location.reload(false), 3000);

          }
      }
      }catch (error) {
              message.error("Error while submitting data!");
              console.log("Error while submitting data!");
            } 
    });
  };

  return (
    <FormWrap>
        <FormWrapper><Form form={form}>
          <Row justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Title level={3}>
                Update Chart Base Url
              </Title>   
              <p className="color-grey">
          Or You Can update in company setting page
          </p>                           
              <Divider style={{backgroundColor:'#3A3C5A', marginTop:'15px'}} />

              <Form.Item
                labelCol={{ span: 24 }}
                label="Chart Base URL"
                name="chart_base_url"
                rules={[
                  { required: true, type: "url", message: "Please input Chart Base Url!" },
                ]}
              >
                <Input placeholder="Type in a chart base url" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>                            
              <Form.Item>
                <Row justify="flex-start">
                  <StyledButton
                    className="margin-5"
                    onClick={() => onFormSubmit()}
                    type="custom"
                  >
                    <span>Update</span>
                  </StyledButton>
                </Row>
              </Form.Item>
            </Col>
          </Row></Form>
        </FormWrapper>
      </FormWrap>
  );
};

export default AnalyticsBaseUrlForm;
