import React, { useState } from "react";
import { Form, Row, Typography, Divider, Radio } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../../../../shared/commonStyles";
import { DataSheetFilters } from "../../../../../components/DataSheetFilters";

const { Title } = Typography;
interface FiltersModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (data: SearchParams) => void;
  dataSheet: DataSheet;
  initialValues: Search;
  onReset: () => void;
}
export const FiltersModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
  initialValues,
  onReset,
}: FiltersModalProps) => {
  const [submitting] = useState(false);

  const [formInitialValue, setFormInitialValue] = useState(initialValues);
  const [form] = Form.useForm();
  const onFinishHandler = (data: SearchParams) => {
    onFinish(data);
    onClose();
  };

  const onClickReset = () => {
    onReset();
  };
  const resetInput = (key: number) => {
    const formValues = form.getFieldsValue(true);
    const filtersValues = formValues.filters.map(
      (item: AdvancedDataFilter, index: number) => {
        if (index === key) {
          return {
            ...item,
            op: null,
            column_value:
              item.column === "owner_users" || item.column === "owner_groups"
                ? []
                : "",
          };
        } else {
          return item;
        }
      }
    );
    form.setFieldsValue({ ...formValues, filters: filtersValues });
    setFormInitialValue({ ...formValues, filters: filtersValues });
  };
  const handleChangeFilters = () => {
    const formValues = form.getFieldsValue(true);
    setFormInitialValue(formValues);
  };

  return (
    <CommonModal
      title={"Filter Records"}
      visible={visible}
      onCancel={onClose}
      width={800}
      okText="Apply"
      onOk={onFinishHandler}
      footer={null}
      maskClosable={false}
      confirmLoading={submitting}
      destroyOnClose
      centered
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Title level={4}>Filter Datasheet fields</Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
          {formInitialValue?.filters &&
            formInitialValue?.filters?.length > 1 && (
              <>
                <Title level={5}>Filter Operator Type</Title>
                <Form.Item
                  name="operatorType"
                  key={"operatorType"}
                  rules={[
                    {
                      required: true,
                      message: "Select operator type",
                    },
                  ]}
                >
                  <Radio.Group style={{ marginBottom: 20 }}>
                    <Radio value="$and">AND</Radio>
                    <Radio value="$or">OR</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          <Form.Item>
            <Title level={5}>Datasheet filters</Title>
            <DataSheetFilters
              initialFilters={initialValues.filters || []}
              formFilters={formInitialValue.filters}
              onChangeFilters={handleChangeFilters}
              onAddFilters={handleChangeFilters}
              dataSheet={dataSheet}
              resetInput={resetInput}
              addButtonText="Add"
              hideOwnersField
            />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <StyledButton
                type="default"
                style={{ padding: "4px 15px" }}
                onClick={onClickReset}
              >
                Reset
              </StyledButton>
              <StyledButton type="custom" htmlType="submit">
                Apply
              </StyledButton>
            </Row>
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
