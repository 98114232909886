import React, { useState } from "react";
import { Space, Tooltip, Input, Form, message, Select, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Styled from "styled-components";
import { Auth } from "aws-amplify";
import {
  CommonModal,
  StyledButton,
  FormWrapper,
  CommonSelect,
} from "../../../../shared/commonStyles";
import { addReportFilters } from "../../../../services/filter";
import moment from "moment";
import { YearMonthDay24HourFormat } from "../../../../shared/constants";

const { Option } = Select;
const FloatRight = Styled.div`
// float: right; 
// margin: 0px 5px;
`;

export const BusinessUnitSelect = ({
  setSelectedBusinessUnit,
  selectedBusinessUnit,
  setBusinessUnits,
  businessUnits,
  visibleAddBtn = true,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {
  const [visible, setModalVis] = useState(false);
  const [form] = Form.useForm();
  const createNewBusinessUnit = async (val) => {
    const data = await Auth.currentSession();
    const group = data["accessToken"]["payload"]["cognito:groups"].filter(
      (element) => element.includes("org:")
    )[0];

    const payload = formatFormValues(val);

    const resp = await addReportFilters(payload);

    return resp;
  };

  const formatFormValues = ({ name, description }, group) => {
    // create payload for create business unit
    const payload = {
      name,
      description,
      filter_type: "",
      status: "ACTIVE",
      date_start: "",
      date_end: "",
      modify_timestamp: moment().format(YearMonthDay24HourFormat),
      create_timestamp: moment().format(YearMonthDay24HourFormat),
    };
    return payload;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((val) =>
        createNewBusinessUnit(val).then(({ data }) => {
          message.success("Successfully created new business unit!");
        })
      )
      .catch((e) => {
        message.error("Something went wrong creating the new business unit.");
        console.log(e);
      });

    setModalVis(!visible);
  };

  return (
    <FloatRight>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <BusinessUnitSelectFilter
          value={selectedBusinessUnit}
          options={businessUnits}
          setSelectedBusinessUnit={setSelectedBusinessUnit}
          extraOptions={extraOptions}
          selectWidth={selectWidth}
          changeFilter={changeFilter}
          {...rest}
        />

        {visibleAddBtn && (
          <AddBusinessUnitButton onclick={(_) => setModalVis(!visible)} />
        )}

        <CommonModal
          visible={visible}
          title="Create New Business Unit"
          onCancel={(_) => setModalVis(!visible)}
          onOk={submitForm}
          centered
          footer={[
            <Button ghost key={1} onClick={(_) => setModalVis(!visible)}>
              Cancel
            </Button>,
            <Button key={2} type="primary" onClick={submitForm}>
              Create
            </Button>,
          ]}
        >
          <FormWrapper>
            <AddBusinessUnitModal form={form} />
          </FormWrapper>
        </CommonModal>
      </Space>
    </FloatRight>
  );
};

const AddBusinessUnitButton = ({ onclick }) => (
  <Tooltip title="Add Business Unit">
    <StyledButton
      onClick={onclick}
      type="custom"
      shape="circle"
      icon={<PlusOutlined />}
    />
  </Tooltip>
);

const AddBusinessUnitModal = ({ form }) => {
  return (
    <Form form={form}>
      <Form.Item
        labelCol={{ span: 24 }}
        name="business_unit_name"
        label="Business Unit Name"
      >
        <Input placeholder={""} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        name="business_unit_desc"
        label="Business Unit Description"
      >
        <Input placeholder={""} />
      </Form.Item>
    </Form>
  );
};

const BusinessUnitSelectFilter = ({
  value,
  options,
  setSelectedBusinessUnit,
  extraOptions,
  selectWidth,
  changeFilter,
  ...rest
}) => {
  // set Selected business unit id
  const onChange = (val) => {
    localStorage.setItem("isReportFilterChanged", true);
    if (changeFilter === "no") {
      setSelectedBusinessUnit(val);
    } else {
      localStorage.setItem("selectedBusinessUnit", val);
      setSelectedBusinessUnit(val);
    }
  };

  return (
    <CommonSelect
      {...rest}
      showSearch
      style={{ width: selectWidth ? selectWidth : 180 }}
      onChange={onChange}
      filterOption={(input, option) => {
        return (
          option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );        
      }}
      value={value}
      placeholder="Select a Business Unit"
    >
      {extraOptions}
      {options &&        
        options.map((item, index) => {
          if (item.status === "ACTIVE") {
            return (
              <Option value={item._id["$oid"]} key={index}>
                {item.name}
              </Option>
            );
          }
        })}
    </CommonSelect>
  );
};
