import React, { useEffect } from "react";
import { Col, Row } from "antd";
import {
  CommonModal,
  StyledButton,
} from "../../../shared/commonStyles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {DecoupledDocumentEditor} from "ckeditor5-build-classic";
const InstructionsModal = ({
  visible,
  onClose,
  submitInstruction,
  rowData,
  instructions,
  setInstructions,
}) => {
  useEffect(() => {
    setInstructions(rowData?.instructions);
  }, [rowData]);

  return (
    <CommonModal
      destroyOnClose={true}
      width={600}
      title={"Add Instructions"}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="ckSmallHeight">
            <CKEditor
              editor={DecoupledDocumentEditor}
              data={instructions}
              onReady={(editor) => {
                if (editor?.ui) {
                  editor.ui.view.editable.element.parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.view.editable.element
                  );
                }
              }}
              onChange={(_, editor) => {
                const data = editor.getData();
                setInstructions(data);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row justify="end">
        <StyledButton
          className="margin-5"
          type="custom"
          hovercolor="#A68DFB"
          hoverbgcolor="transparent"
          bgcolor="transparent"
          bordercolor="transparent"
          color="#fff"
          onClick={() => onClose()}
        >
          <span>Cancel</span>
        </StyledButton>
        <StyledButton onClick={submitInstruction}>
          <span>Save</span>
        </StyledButton>
      </Row>
    </CommonModal>
  );
};

export default InstructionsModal;
