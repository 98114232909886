import React, { useEffect, useState } from "react";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Select, Row, message, Typography, Divider, Col } from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
} from "../../shared/commonStyles";

const { Title } = Typography;
export const AggregateAnalyticsModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
  initialValues,
  mongoOperations,
}) => {
  const [activityFields, setActivityFields] = useState([]);

  const [form] = Form.useForm();

  const onFinishHandler = (data) => {
    onFinish(data);
    onClose();
  };

  useEffect(() => {
    try {
      const schemasArray = [];
      if (dataSheet?.sheet_schema?.length) {
        dataSheet.sheet_schema.forEach((schema) => {
          if (schema.data_type === "number") {
            schemasArray.push({
              value: schema.entity_name,
              label: schema.display_name,
            });
          }
        });
      }
      setActivityFields(schemasArray);
    } catch (error) {
      console.log("Something went wrong!", error);
      message.error("Something went wrong!");
    }
  }, [dataSheet]);

  const formattedInitialValues = initialValues
    .map((item) => {
      const operators = item[Object.keys(item)].map((value) => {
        return value.operator;
      });
      return { column: Object.keys(item), operator: operators };
    })
    .flat();

  return (
    <CommonModal
      title={"Aggregate Analytics"}
      visible={visible}
      onCancel={onClose}
      width={800}
      okText="Apply"
      onOk={onFinishHandler}
      footer={null}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={{ aggregate: formattedInitialValues }}
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.Item label="">
            <Title level={4}>Aggregate Datasheet fields</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.List name="aggregate" key={"aggregate"}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name }) => (
                      <Row justify="space-between" key={key} gutter={[16]}>
                        <Col span={8}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="DataSheet Field"
                            name={[name, "column"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                              () => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    form
                                      .getFieldsValue(true)
                                      .aggregate.filter(
                                        (d) => d.column === value
                                      ).length !== 1
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Duplicate selections are not allowed."
                                      )
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <Select
                              placeholder="Datasheet field name"
                              options={activityFields}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="Aggregation operators"
                            name={[name, "operator"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select aggregation operators"
                              options={mongoOperations}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <StyledButton
                            type="default"
                            style={{ padding: "8px", marginTop: "40px" }}
                            disabled={activityFields.length === 0}
                            onClick={() => remove(name)}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    ))}
                    <StyledButton
                      type="custom"
                      disabled={
                        activityFields.length === 0 ||
                        activityFields.length ===
                          form?.getFieldsValue(true)?.aggregate?.length
                      }
                      onClick={() => add()}
                    >
                      <PlusCircleOutlined /> Add Datasheet Field
                    </StyledButton>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <StyledButton type="custom" htmlType="submit">
                Apply
              </StyledButton>
            </Row>
          </Form.Item>
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};
