import React from "react";
import { Form, Input, Row, Col, Typography, Divider, Select, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  FormWrapper,
  StyledButton,
  FormWrap,
} from "../../../shared/commonStyles";

const { Title } = Typography;
const { TextArea } = Input;

const GeneralInformation = ({
  onClickNext,
  metaDataSheetList,
  initialValues,
  entitiesList,
  entitiesLoading,
  isIdentifier,
}) => {
  return (
    <FormWrap>
      <FormWrapper>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>General information</Title>
            <Title level={4}>Name new data collection</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  pattern: new RegExp("^[a-zA-Z0-9 ()]*$"),
                  message: "Please input name!",
                },
              ]}
            >
              <Input placeholder="Type in a name for the data collection" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>Add description</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              name="description"
              label="Description"
              rules={[
                { required: false, message: "Please input description!" },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Provide a short description of what kind of data will be collected "
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>Entity search form settings</Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />

            <Form.Item
              labelCol={{ span: 24 }}
              name="defaultMetaDataSheetId"
              label="Default Metadata Sheet"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select default metadata sheet"
              >
                <Select.Option key="none" value="">
                  None
                </Select.Option>
                {metaDataSheetList?.map((sheet) => {
                  return (
                    <Select.Option
                      key={sheet?._id?.$oid}
                      value={sheet?._id?.$oid}
                    >
                      {sheet?.sheet_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {initialValues?.defaultMetaDataSheetId && (
            <>
              {isIdentifier && (
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Entities"
                    name="entityIds"
                  >
                    <Select
                      style={{ width: "100%" }}
                      mode="multiple"
                      allowClear
                      placeholder="Select entities"
                      notFoundContent={entitiesLoading && <Spin size="small" />}
                    >
                      {entitiesList?.map((item) => (
                        <Select.Option
                          key={item?.[item.sheet_name].id}
                          value={item?.[item.sheet_name].id}
                        >
                          {item?.[item.sheet_name].entity_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Info message"
                  name="facilityInfoMessage"
                  rules={[
                    {
                      required: true,
                      message: "Please input entity info message for users!",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Type in an info message for the survey users"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Search Dropdown Placeholder"
                  name="searchFieldLabel"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input search dropdown placeholder value!",
                    },
                  ]}
                >
                  <Input placeholder="Please input search dropdown placeholder value" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Search Field Placeholder"
                  name="searchFieldValueLabel"
                  rules={[
                    {
                      required: true,
                      message: "Please input search field placeholder value!",
                    },
                  ]}
                >
                  <Input placeholder="Please input search field placeholder value" />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item>
              <StyledButton
                className="margin-5"
                onClick={() => onClickNext()}
                type="custom"
              >
                <span>Continue to step 2</span> <RightOutlined />
              </StyledButton>
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  );
};

export default GeneralInformation;
