import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  FilterOutlined,
  SwapOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Select,
  Tag,
  Typography,
  Spin,
  message,
  Popover,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  CardsWrap,
  FiltersContainer,
  FormWrapper,
  PageWrap,
  StyledButton,
  TableStyledMenu,
} from "../../shared/commonStyles";
import { useNavigate, useParams } from "react-router-dom";
import KpiDetailCard from "./components/KpiDetailCard";
import AutoUpdateKpi from "./components/autoUpdateKpi";
import {
  getKpiMetric,
  removeKpiMetric,
  updateKpiMetric,
} from "../../services/kpi";
import { convertFiltersList, getSheetById } from "../../services/dataSheet";
import ConfirmModal from "./../../components/ConfirmModal/ConfirmModal";
import { SortDropdown } from "../../components/SortDropdown";
import { FilterDropdown } from "../../components/FilterDropdown";
import styles from "./kpiDetailPage.module.scss";
import { aggregationOperators } from "../../services/mongoOperators";
import { getCompanyInfo } from "../../services/companyInfo";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { encodeUrlName } from "../../shared/commonFunctions";
import { userInfoStore } from "../Carbon/UserInfoStore";
import moment from "moment";

const { Title } = Typography;
const KpiDetailPage = () => {
  const navigate = useNavigate();
  const { data_sheet_id: sheetId, sheet_name: sheetName } = useParams();
  const [loadkpis, setLoadKpis] = useState(false);
  const [kpisList, setKpisList] = useState([]);
  const [dataSheet, setDataSheet] = useState(null);
  const [rowDataView, setRowDataView] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [refreshAllKpiData, setRefreshAllKpiData] = useState(1);
  const [loaderDeletingKpi, setLoaderDeletingKpi] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [columnFiltering, setColumnFiltering] = useState(null);
  const [sortOffsets, setSortOffsets] = useState({
    type: "key",
    sortAlphabetically: true,
  });
  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [filterOffsets, setFilterOffsets] = useState({ filters: [] });
  const [companyDetails, setCompanyDetails] = useState({});
  const sortOptions = [
    { name: "Name", value: "name" },
    { name: "Column", value: "aggregateColumn" },
  ];

  const filterOptions = [
    { name: "KPI Name", value: "name" },
    { name: "Operator", value: "aggregateOp" },
  ];

  const valueOptions = { aggregateOp: aggregationOperators };

  const makeQuery = () => {
    const sortingData = {};
    let filter = { sheetId };
    filterOffsets?.filters.forEach((item) => {
      if (item.type !== "key") {
        filter = { ...filter, [item.type]: { $regex: item.value } };
      }
    });
    if (columnFiltering) {
      filter = { ...filter, ["aggregateColumn"]: columnFiltering };
    }
    return {
      filter: filter,
      sort: sortingData,
    };
  };

  const getLinkDataSheet = () => {
    const url = `/data/data-sheets/${dataSheet._id.$oid}/${encodeUrlName(
      dataSheet?.sheet_name?.replace("/", " ")
    )}/documents`;

    return { url };
  };

  const loadData = async () => {
    setLoadKpis(true);
    const sheetData = await loadDataSheet();
    const payload = makeQuery();
    const kpisList = await getKpiMetric(payload);
    const newKpisData = kpisList.map((item) => {
      const dataIndex = `${item?.aggregateColumn}_${item?.aggregateOp}`;
      const schemaData = sheetData?.sheet_schema.find(
        (schema) => schema.entity_name === item?.aggregateColumn
      );
      const operationData = aggregationOperators.find(
        (operation) => operation.value === item?.aggregateOp
      );
      const aggregateResult = item?.analyticResult?.[dataIndex]
        ? (typeof item?.analyticResult?.[dataIndex] === "object" && "NA") ||
          item?.analyticResult?.[dataIndex]
        : "";
      return {
        ...item,
        sheetData,
        aggregateColumnLabel: schemaData ? schemaData?.display_name : "",
        aggregateOpLabel: operationData?.label || "",
        aggregateResult,
      };
    });
    setKpisList(newKpisData);
    setLoadKpis(false);
  };

  useEffect(() => {
    if (sheetId) {
      loadData();
    }
  }, [filterOffsets, sheetId, refreshAllKpiData]);

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  const onRefreshKpiData = () => {
    setRefreshAllKpiData(refreshAllKpiData + 1);
  };

  const loadDataSheet = async () => {
    const sheetData = await getSheetById(sheetId);
    setDataSheet(sheetData);
    return sheetData;
  };

  const sortByAscending = (type) => {
    setLoadKpis(true);
    const sortedAsceding = [...kpisList]?.sort((a, b) => {
      return a[type] > b[type] ? -1 : 1;
    });
    setKpisList(sortedAsceding);
    setLoadKpis(false);
  };
  const handleAddNote = async (text, record) => {
    const formattedNote = {
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: text,
    };
    const kpiNotes = record?.notes?.length ? record.notes : [];
    const newItem = { ...record, notes: [...kpiNotes, formattedNote] };
    await updateKpiMetric(newItem);
    loadData();
    setRowData(newItem);
  };
  const handleUpdateNote = async (newNotes, record) => {
    const newItem = { ...record, notes: newNotes };
    await updateKpiMetric(newItem);
    loadData();
    setRowData(newItem);
  };
  const sortByDescending = (type) => {
    setLoadKpis(true);
    const sortedDescending = [...kpisList]?.sort((a, b) => {
      return a[type] > b[type] ? 1 : -1;
    });

    setKpisList(sortedDescending);
    setLoadKpis(false);
  };

  const handleSorting = () => {
    if (sortOffsets.type !== "key") {
      if (sortOffsets.sortAlphabetically) {
        sortByAscending(sortOffsets.type);
      } else {
        sortByDescending(sortOffsets.type);
      }
    }
  };

  const handleFiltering = (data) => {
    setFilterOffsets(data);
  };

  const resetFiltering = () => {
    setFilterOffsets({ filters: [] });
  };

  const fillColor = (record) => {
    let tagColoris = "#7D89B0";
    if (record === "Total Cost") {
      tagColoris = "#E69B56";
    }
    if (record === "Total Usage") {
      tagColoris = "#5690E6";
    }
    return (
      <Tag color={tagColoris} className="tag-rounded">
        {record}
      </Tag>
    );
  };
  const handleDeleteKpi = (rowData) => {
    setRowData(rowData);
    setShowConfirmModal(true);
  };

  const handleEditKpi = (rowData) => {
    navigate(
      `/data/kpi/${rowData?._id?.$oid}/${encodeUrlName(
        rowData?.name.replace("/", " ")
      )}/update`
    );
  };

  const onConfirmKpiDelete = async () => {
    if (!rowData) return;
    try {
      setLoaderDeletingKpi(true);
      const payload = {};

      payload._id = rowData?._id;
      await removeKpiMetric(payload);

      onRefreshKpiData();
      message.success("Removed successfully!");
      setShowConfirmModal(false);
    } catch (error) {
      console.log("error while deleting kpi.", error);
      message.error("error while deleting kpi.");
    } finally {
      setLoaderDeletingKpi(false);
    }
  };

  const handleColumnFiltering = (value) => {
    setColumnFiltering(value);
    onRefreshKpiData();
  };

  const handleDataAfterRefresh = (data) => {
    const newKpiData = kpisList?.map((item) => {
      if (item._id.$oid === data._id.$oid) {
        return data;
      }
      return item;
    });
    setKpisList(newKpiData);
    /*setKpisList((state) => {
      if(state._id === data._id){
        return {...state, ...data}
      }
    })*/
  };

  const menuButtons = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key="1"
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<EditOutlined />}
          onClick={() => handleEditKpi(record)}
          disabled={record.isOverride}
        >
          Edit
        </StyledButton>
      </Menu.Item>

      <Menu.Item
        key="2"
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteKpi(record)}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  return (
    <PageWrap>
      <Spin spinning={loadkpis}>
        {showConfirmModal && (
          <ConfirmModal
            title="Confirmation"
            description="Are you sure you want to delete this KPI?"
            visible={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={onConfirmKpiDelete}
            ConfirmLoader={loaderDeletingKpi}
            okText="Delete"
          />
        )}
        <FiltersContainer>
          <FormWrapper>
            <Row justify="start" gutter={[8, 8]}>
              <Col span={4} style={{ alignSelf: "center" }}>
                {dataSheet && (
                  <Select
                    allowClear
                    showArrow
                    style={{ width: "100%" }}
                    placeholder="All Columns"
                    onChange={handleColumnFiltering}
                  >
                    {dataSheet?.sheet_schema?.map((schema, index) => {
                      return (
                        <Select.Option key={index} value={schema.entity_name}>
                          {schema.display_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col span={20} style={{ textAlign: "end", paddingRight: "15px" }}>
                <StyledButton
                  className="margin-5"
                  type="transparent"
                  bgcolor="#1d1729"
                  bordercolor="#ffffff"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Dropdown
                    overlay={
                      <SortDropdown
                        sorting={sortOffsets}
                        changeSorting={setSortOffsets}
                        options={sortOptions}
                        onConfirm={() => {
                          handleSorting();
                          setShowSortPopup(false);
                        }}
                      />
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                    onVisibleChange={(open) => {
                      setShowSortPopup(open);
                    }}
                    visible={showSortPopup}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="color-white"
                    >
                      <SwapOutlined rotate={90} />
                      <span>Sort</span>
                    </a>
                  </Dropdown>
                </StyledButton>
                <StyledButton
                  className="margin-5"
                  type="transparent"
                  bgcolor="#1d1729"
                  bordercolor="#ffffff"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Dropdown
                    overlay={
                      <FilterDropdown
                        filtering={filterOffsets}
                        //changeFiltering={setFilterOffsets}
                        options={filterOptions}
                        valueOptions={valueOptions}
                        onConfirm={(data) => {
                          handleFiltering(data);
                          setShowFilterPopup(false);
                        }}
                        onReset={() => {
                          resetFiltering();
                          setShowFilterPopup(false);
                        }}
                      />
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                    onVisibleChange={(open) => {
                      setShowFilterPopup(open);
                    }}
                    visible={showFilterPopup}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="color-white"
                    >
                      <FilterOutlined />
                      <span>Filter</span>
                    </a>
                  </Dropdown>
                </StyledButton>
              </Col>
            </Row>
          </FormWrapper>
        </FiltersContainer>
        {kpisList?.length ? (
          <>
            <KpiDetailCard
              rowData={rowData}
              visible={rowDataView}
              onClose={() => setRowDataView(false)}
              handleAddNote={handleAddNote}
              handleUpdateNote={handleUpdateNote}
            />
            <CardsWrap style={{ marginTop: "10px" }}>
              <Row gutter={[16, 16]} style={{ width: "100%" }}>
                {kpisList.map((item, index) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={index}>
                    <div
                      style={{
                        width: "100%",
                        padding: "20px 16px 18px 18px",
                        // backgroundColor: "rgb(187 187 187 / 9%)",
                        backgroundColor: "#2D273F",
                        borderRadius: "4px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Row>
                        <Col span={16}>
                          <div style={{ paddingBottom: "15px" }}>
                            {fillColor(item.aggregateColumnLabel)}
                          </div>
                        </Col>
                      </Row>

                      <Title level={4} className="color-white">
                        {item.name}
                      </Title>
                      <p className="color-grey" style={{ marginBottom: "0" }}>
                        {item.aggregateColumnLabel} {item.aggregateOpLabel}{" "}
                      </p>

                      <Row className={styles.warning}>
                        <Title
                          level={4}
                          className={`color-white ${
                            item.hasMissingFields && styles.warningText
                          }`}
                          style={{ color: item.hasMissingFields && "#e9d155" }}
                        >
                          {
                            +(+item.aggregateResult).toFixed(
                              companyDetails.decimal_places_number
                            )
                          }
                        </Title>
                        {item.hasMissingFields && (
                          <Popover
                            placement="right"
                            overlayClassName={`popoverContent ${styles.warningPopover}`}
                            autoAdjustOverflow
                            content={
                              <>
                                <div className={styles.warningPopoverTitle}>
                                  Warning
                                </div>
                                <div>
                                  Some pieces of data are missing. Review the
                                  data sheet to increase the accuracy of the
                                  aggregation
                                </div>

                                <div className={styles.warningPopoverLink}>
                                  <Link
                                    to={getLinkDataSheet().url}
                                    target="_blank"
                                    onClick={() => {
                                      (item.conditional_filters?.length ||
                                        item.filters?.length) &&
                                        localStorage.setItem(
                                          "conditional_filters",
                                          JSON.stringify(
                                            item.conditional_filters?.length
                                              ? item.conditional_filters
                                              : convertFiltersList(
                                                  item.filters,
                                                  dataSheet
                                                )
                                          )
                                        );
                                    }}
                                  >
                                    View Data Sheet
                                  </Link>
                                </div>
                              </>
                            }
                          >
                            <WarningOutlined className={styles.warningIcon} />
                          </Popover>
                        )}
                      </Row>
                      {/* <Row gutter={16}>
                        <Space> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "stretch",
                          overflow: "hidden",
                          flexWrap: "wrap",
                        }}
                      >
                        <StyledButton
                          style={{
                            marginLeft: "0",
                            flexGrow: "8" /* width:'100%'  */,
                          }}
                          type="default"
                          onClick={() => {
                            setRowData(item);
                            setRowDataView(true);
                          }}
                        >
                          <EyeOutlined /> Details
                        </StyledButton>

                        {!checkRolesPermission(["role:auditor"]) && (
                          <>
                            <AutoUpdateKpi
                              handleDataAfterRefresh={handleDataAfterRefresh}
                              kpiData={item}
                            />
                            <Dropdown
                              overlay={() => menuButtons(item)}
                              trigger={["click"]}
                            >
                              <StyledButton
                                type="default"
                                style={{
                                  marginLeft: "0",
                                  marginRight: "",
                                  flexGrow: "8",
                                  // width:'100%'
                                }}
                              >
                                <EllipsisOutlined />
                              </StyledButton>
                            </Dropdown>{" "}
                          </>
                        )}
                      </div>
                      {/* </Space>
                      </Row> */}
                    </div>
                  </Col>
                ))}
              </Row>
            </CardsWrap>
          </>
        ) : (
          <>
            {!loadkpis && (
              <div style={{ marginTop: "110px", textAlign: "center" }}>
                <Title level={2} className="color-white">
                  {`You don't have any saved KPIs yet`}
                </Title>
                {!checkRolesPermission([
                  "role:elevateduser",
                  "role:auditor",
                ]) && (
                  <StyledButton
                    type="primary"
                    onClick={() => {
                      navigate(
                        `/data/kpi/${sheetId}/${encodeUrlName(
                          sheetName
                        )}/create`
                      );
                    }}
                  >
                    <PlusCircleOutlined /> Add New KPI
                  </StyledButton>
                )}
              </div>
            )}
          </>
        )}
      </Spin>
    </PageWrap>
  );
};

export default KpiDetailPage;
