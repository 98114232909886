import {Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

   export const UploadToS3 = async (file,filename) => {
        try{
            
            const resp = await Storage.put(`clientdocuments/${filename}.png`, file, {contentType: file.type})
            
            return resp
        }catch(error){
          console.log("UploadToS3: Error while upload image",error);  
          return null;
        }
    }

  export  const getSignedUrl = async(image_url) => {
        try{   
            const resp = await Storage.get(image_url)
        
            return resp;
        }
        catch(error){
            console.log('getSignedUrl error',error);
            return null;
        }
    }

   export const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

  export  const uploadAdapter = function(loader){
      return{
          upload:() =>{
              return new Promise((resolve,reject) => {
                  loader.file.then(async(file) => {                       
                       try{
                           const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                          const filename = file.name;
                          if (!allowedExtensions.exec(filename)) {
                              return reject(new Error('Please upload jpg|jpeg|png image type!'));
                          }
                            const createduuid = uuidv4();
                            const uploadedImagePath = await UploadToS3(file,createduuid);
                            if(uploadedImagePath?.key){
                                 const signedImageUrl = await getSignedUrl(uploadedImagePath.key);
                                 if(signedImageUrl){
                                     return resolve({
                                          default: signedImageUrl
                                      });
                                 }else{
                                      return reject(new Error("Error while get uploaded image signed url."));
                                 }
                            }else{
                               return reject(new Error("Error while upload image to s3"));
                            }                            
                       }
                       catch(error){
                        return reject(error);
                       }
                  });
              }) 
          }
      }
    }

  export  const customUploadAdapterPlugin = function( editor ){
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            return uploadAdapter( loader );
        };
    }
