import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageWrap, StyledButton, StyledSteps } from "../../shared/commonStyles";

import {
  getSheetById,
  updateDataSheet,
  getDataSheets,
  getDatasheetFilters,
} from "../../services/dataSheet";
import { Space, Form, Row, Col, message, Steps, Progress, Spin } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";

import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import { encodeUrlName } from "../../shared/commonFunctions";

const { Step } = Steps;

const UpdateDataSheet = () => {
  const { sheet_id: sheetId } = useParams();
  const [step, setStep] = useState(1);
  const fieldsRef = useRef();
  const { state } = useLocation();

  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [loadingCreateDataSheet, setLoadingCreateDataSheet] = useState(false);
  const [descriptionData, setDescriptionData] = useState("");
  const [sheetNames, setSheetNames] = useState([]);
  const [initialValues, setInitialValues] = useState({
    sheetName: "",
    description: "",
    sheetType: "",
    metadata_sheet_id: "",
    enableComments: true,
    enableChangeHistory: true,
    sheet_schema: [
      {
        display_name: "",
        entity_name: "",
        input_type: "",
        unit_id: "",
        data_type: "",
        required: false,
      },
    ],
  });

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const loadData = async () => {
    setLoadDataSheets(true);
    const payload = {
      filter: { archive: false, is_reference_data: true },
    };

    const dataSheetsList = await getDataSheets(payload);
    setDataSheetsList(dataSheetsList);
    setLoadDataSheets(false);
  };

  const getSheetNames = async () => {
    const dataSheetsList = await getDatasheetFilters();
    if (dataSheetsList?.sheet_name?.length) {
      setSheetNames(dataSheetsList?.sheet_name);
    }
  };

  useEffect(() => {
    loadData();
    getSheetNames();
  }, []);

  const loadSheetRowData = async () => {
    try {
      setLoadDataSheets(true);
      const sheetData = await getSheetById(sheetId, false);
      if (sheetData) {
        setRowData(sheetData);
      } else {
        message.error("Data Sheet not found");
        navigate(`/data/data-sheets`);
      }
    } catch (error) {
      console.log("Something went wrong while load flow items!", error);
      message.error("Something went wrong while load flow items!");
    } finally {
      setLoadDataSheets(false);
    }
  };

  useEffect(() => {
    if (sheetId) {
      loadSheetRowData();
    }
  }, [sheetId]);

  useEffect(() => {
    if (rowData) {
      const initData = {
        sheetName: rowData?.sheet_name,
        description: rowData?.description,
        sheetType: rowData?.sheet_type,
        metadata_sheet_id: rowData?.metadata_sheet_id,
        sheet_schema: rowData?.sheet_schema,
        enableComments: rowData?.enable_comments,
        enableChangeHistory: rowData?.enable_change_history,
        ...(rowData.validationRules && {
          validationRules: rowData?.validationRules,
        }),
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
    }
  }, [rowData]);

  const onChangeFieldsData = (data) => {
    let newInitialValues = {};
    const formValues = form.getFieldsValue(true);
    Object.keys(formValues)?.forEach((key, index) => {
      if (data?.[0]?.name[0] === key && data?.[0]?.name.length > 1) {
        const newItems = formValues[key]?.map((item, index) => {
          if (data?.[0]?.name[1] === index) {
            const newItem = { ...item, [data?.[0]?.name[2]]: data?.[0]?.value };
            return newItem;
          }
          return item;
        });
        newInitialValues = { ...newInitialValues, [key]: newItems };
      } else {
        if (data?.[0]?.name[0] && data?.[0]?.name[0] === key) {
          if (data?.[0]?.name[0] === "description") {
            newInitialValues = {
              ...newInitialValues,
              [key]: descriptionData,
            };
          } else
            newInitialValues = { ...newInitialValues, [key]: data?.[0]?.value };
        } else {
          newInitialValues = { ...newInitialValues, [key]: formValues[key] };
        }
      }
    });
    setInitialValues(newInitialValues);
  };

  const onStepChange = async (selectedStep) => {
    // steps component step value start from 0
    try {
      await form.validateFields();
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const goToDetailedPage = () => {
    if (sheetId) {
      navigate(
        `/data/data-sheets/${sheetId}/${encodeUrlName(
          initialValues?.sheetName?.replace("/", " ")
        )}/documents`
      );
    }
  };

  const onClickUpdateSheet = async (validationRules, sheetSchema) => {
    if (!sheetSchema?.length) {
      message.error("Please add at least one field...");
      return;
    }
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingCreateDataSheet(true);
          const {
            sheetName,
            sheetType,
            description,
            metadata_sheet_id,
            enableComments,
            enableChangeHistory,
          } = initialValues;

          const payload = {
            ...rowData,
            sheet_name: sheetName,
            sheet_type: sheetType,
            metadata_sheet_id,
            description: description,
            sheet_schema: sheetSchema,
            enable_comments: enableComments,
            enable_change_history: enableChangeHistory,
            validationRules: validationRules,
          };

          const response = await updateDataSheet(payload);

          if (response["data"]["datalake"] === "1") {
            setLoadingCreateDataSheet(false);
            setInitialValues((prev) => {
              return { ...prev, sheet_schema: sheetSchema };
            });
            form.setFieldsValue({
              ...initialValues,
              sheet_schema: sheetSchema,
            });
            state?.type === "detailedPage"
              ? goToDetailedPage()
              : setStep(step + 1);
          } else {
            if (typeof response["data"]["datalake"] === "string") {
              //backend error message
              const errorMessage = response["data"]["datalake"];
              message.error(errorMessage);
              return;
            }
          }
        } catch (error) {
          console.log("Something went wrong while updating data sheet!", error);
          message.error("Something went wrong while updating data sheet!");
          setLoadingCreateDataSheet(false);
        }
      })
      .catch((error) => console.log("error form in data sheet", error));
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const changeDescription = (data) => {
    setDescriptionData(data);
    setInitialValues((oldState) => ({
      ...oldState,
      description: data,
    }));
  };

  const showSectionsAccordingSteps = (step) => {
    if (step === 1) {
      return (
        <Step1
          form={form}
          onClickNext={onClickNext}
          dataSheetsList={dataSheetsList}
          initialValues={initialValues}
          changeDescription={changeDescription}
          sheetNames={sheetNames}
          rowData={rowData}
        />
      );
    } else if (step === 2) {
      return <div ref={fieldsRef} />;
    } else if (step === 3) {
      return <Step3 sheetId={sheetId} sheetName={initialValues.sheetName} />;
    } else {
      console.log("completed");
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                onClick={() => navigate(-1)}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps
                current={step - 1}
                onChange={onStepChange}
                style={{ display: "block", width: "85%", textAlign: "center" }}
              >
                <Step
                  className="stepper-wrapper "
                  key={1}
                  title={<span>Sheet Description</span>}
                />

                <Step
                  className="stepper-wrapper"
                  key={2}
                  title={<span>Custom Fields</span>}
                />
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * 50}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
                {step === 2 && (
                  <div container={fieldsRef.current}>
                    <Step2
                      onClickUpdateSheet={onClickUpdateSheet}
                      onClickPrevious={onClickPrevious}
                      initialValues={initialValues}
                      setInitialValues={setInitialValues}
                      loadingCreateDataSheet={loadingCreateDataSheet}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
export default UpdateDataSheet;
