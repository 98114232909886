import {
  ApartmentOutlined,
  CopyOutlined,
  DeleteOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import {
  Item,
  SustainabilityStandardComponent,
} from "../Component/SustainabilityStandardComponent";
import { Menu, message } from "antd";
import React, { useEffect, useState } from "react";
import { StyledButton, TableCommonMenu } from "../../../../shared/commonStyles";
import { fileUploadSize, fileUploadTypes } from "../../../../shared/constants";
import { getDataLakeFiles, uploadFile } from "../../../../services/standards";
import {
  getSelectedStandard,
  getUniqueFrameworks,
  updateSelectedStandard,
} from "../../../../services/sustainablilityStandards";
import { useAsync, useAsyncEffect } from "../../../../hooks/use-async";

import AssignPermissionsModal from "../../../reportingPage/PoliciesPage/components/AssignPermissionsModal";
import { COLORS } from "../../../../shared/colors";
import ConfirmArchiveModal from "../../../dataSheetsPage/Components/ConfirmArchiveModal";
import StandardCloneModal from "../../../reportingPage/PoliciesPage/components/StandardCloneModal";
import StandardRecategorizeModal from "../../../reportingPage/PoliciesPage/components/StandardRecategorizeModal";
import { downloadDataLakeFileHandler } from "../../../../shared/commonFunctions";
import { getReportFilters } from "../../../../services/filter";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AddNewStandardModal } from "../../../../components/Modals/AddNewStandard/Components/AddNewStandardModal";
import ESGTemplateModal from "../../../reportingPage/PoliciesPage/components/ESGTemplateModal";
import { getGeneratedAIContent } from "../../../../services/ai";
import { Auth } from "aws-amplify";
import { userInfoStore } from "../../../Carbon/UserInfoStore";

export type FileUploadProps = {
  isLoading: boolean;
  uploadedFilesList: any;
  files: any;
  onBeforeUpload: (file: any) => Promise<false | undefined>;
  onRemoveFileFromListHandler: (file: any) => void;
  onDownloadFileHandler: (key: any) => Promise<void>;
};

export type Filter = {
  key: string;
  isSelected: boolean;
  onClick: (clickedKey: any, framework: any[]) => void;
};

export type HistoryItem = {
  username: string;
  first_last_name: string;
  version_number: number;
  create_timestamp: {
    $date: Date;
  };
  status: string;
  user_status: string;
  publish_status: boolean;
  modify_reason: string;
};
export type HistoryProps = {
  history: HistoryItem[];
};
export type HistoryDataSectionProps = {
  rowData: HistoryProps;
  currentVersion: number;
  setVersionInfo: (info: string) => void;
  setLatestVersion: (version: HistoryItem) => void;
  onChangeHistoryHandler: (data: HistoryItem | null) => void;
};

type HistoryItemProps = {};

export type SelectedStandardsItem = {
  _id: {
    $oid: string;
  };
  name: string;
  description: string;
  frameworks: string[];
  standard_code: string;
  tags: any;
  resource_urls: any;
  report_period: string;
  location_id: string;
  business_unit: string;
  history: HistoryItemProps[];
  audit_details: any[];
  status: string;
  date_created: string;
  createdat: string;
  updatedat: string;
  standard_id: string;
  group_id: string;
  username: string;
  create_timestamp: {
    $date: string;
  };
  version_number: number;
  modify_timestamp: {
    $date: string;
  };
  user_status: string;
  form_data: any;
  modify_reason: string;
  publish_status: boolean;
  notes: string;
  permissions: any;
  comments: Comment[] | undefined;
  response: string;
};

export type Comment = {
  comment_data: string;
  comment_id: string;
  create_timestamp: string;
  modify_timestamp: string;
  status: string;
  username: string;
};

export type SelectedStandardsResponse = {
  data: SelectedStandardsItem[];
  filteredCount: number;
  total: number;
};
export type ListItems = { name: string; id: string };

export const SustainabilityStandardContainer: React.FC = () => {
  const navigate = useNavigate();
  const isAuditPage = false;
  const [tab, setTab] = useState("information-actions");
  const initialUnitsValue = {
    name: "All Units",
    id: "",
  };
  const initialLocationsValue = {
    name: "All Locations",
    id: "",
  };
  const initialPeriodsValue = {
    name: "All Periods",
    id: "",
  };
  const initialUsersValue = ["All User Statuses", "Published", "Unpublished"];
  const initialStatusesValue = [
    "All Publish Statuses",
    "Open",
    "Pending",
    "Escalated",
    "Closed",
  ];
  const [confirmModalShowing, setConfirmModalShowing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [modifyReason, setModifyReason] = useState<string | undefined>();
  const [standards, setStandards] = useState<string[]>([]);
  const [users, setUsers] = useState<string>("All User Statuses");
  const [statuses, setStatuses] = useState<string>("All Publish Statuses");
  // const [isUpdateOnceSuccessfull, setIsUpdateOnceSuccessfull] = useState(false);
  const [businessUnitsValue, setBusinessUnitsValue] =
    useState<ListItems>(initialUnitsValue);
  const [orgLocationValue, setOrgLocationValue] = useState<ListItems>(
    initialLocationsValue
  );

  const [reportPeriodValue, setReportPeriodValue] =
    useState<ListItems>(initialPeriodsValue);
  const [businessUnitsList, setBusinessUnitsList] = useState<ListItems[]>([
    initialUnitsValue,
  ]);
  const [orgLocationsList, setOrgLocationsList] = useState<ListItems[]>([
    initialLocationsValue,
  ]);
  const [reportPeriodList, setReportPeriodList] = useState<ListItems[]>([
    initialPeriodsValue,
  ]);
  const [standardsList, setStandardsList] = useState<string[]>([]);
  const [usersList, setUsersList] = useState<string[]>(initialUsersValue);
  const [statusesList, setStatusesList] =
    useState<string[]>(initialStatusesValue);
  const [recategorizeList, setRecagorizeList] = useState<any>();
  const [refreshListReport, setRefreshListReport] = useState(false);
  const [refreshListArchive, setRefreshListArchive] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Item[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [templateLibraryModal, setTemplateLibraryModal] = useState(false);
  const pageSize = 6;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // TODO: move to utils later
  const getDistinctItemsByStandardIdPaginated = (
    data: Item[],
    startIndex: number,
    endIndex: number
  ): Item[] => {
    const distinctItemsMap = new Map<string, Item>();

    // Loop through the data and store items in a Map using standard_id as the key
    for (const item of data) {
      distinctItemsMap.set(item.standard_id, item);
    }

    // Retrieve values from the Map to get distinct items
    const distinctItems: Item[] = Array.from(distinctItemsMap.values());

    // Apply pagination to the distinct items
    const paginatedItems: Item[] = distinctItems.slice(startIndex, endIndex);

    return paginatedItems;
  };

  // const currentItems = getDistinctItemsByStandardIdPaginated(data, startIndex, endIndex);
  const [currentItems, setCurrentItems] = useState<Item[]>([]);

  const [loader, setLoader] = useState(1);

  // region file uploads
  const [files, setFiles] = useState([]);
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const [isPublish, setIsPublish] = useState<any>();
  const [formData, setFormData] = useState<string>("");
  const [showStandardRecategorizeModal, setShowStandardRecategorizeModal] =
    useState(false);
  const [showAssignPermissionsModal, setShowAssignPermissionsModal] =
    useState(false);
  const [showStandardCloneModal, setShowStandardCloneModal] = useState(false);
  const [status, setStatus] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isLoadingStandardData, setIsLoadingStandardData] = useState(true);
  const [autoSave, setAutosave] = useState<boolean>(false);
  const [selectedStandard, setSelectedStandard] =
    useState<SelectedStandardsItem>();
  const [commentData, setCommentData] = useState<string>("");

  const loadDataLakeFiles = async (dataId: string) => {
    setFiles([]);
    setUploadedFilesList([]);
    setLoaderUploadedFilesList(true);
    try {
      const filesList = await getDataLakeFiles(dataId);
      if (filesList && filesList !== null) {
        const list = filesList
          .replace(/['"]+/g, "")
          .replace(/^\[(.+)\]$/, "$1")
          .split(",");
        setUploadedFilesList(list);
      }
    } catch (error) {
      console.log("Error while fetching files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const uploadFiles = async (file: any, dataId: string) => {
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      setLoaderUploadedFilesList(true);
      const signedURL = await uploadFile(dataId, fileName);
      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
            setRefreshUploadedFiles((prev) => prev + 1);
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const handleBeforeUpload = async (file: any) => {
    const filename = file?.name;
    const fileSize = file?.size;
    const ext = filename && filename?.split(".")?.pop()?.toLowerCase();

    if (!fileUploadTypes.includes(ext)) {
      message.error(`${file?.name} is not a valid file type`);
      return;
    }

    if (fileSize > fileUploadSize) {
      message.error(`File upload size is larger than 25MB`);
      return;
    }

    if (currentStandardId) {
      await uploadFiles(file, currentStandardId);
    }

    return false;
  };

  const handleRemoveFileFromListHandler = (file: any) => {
    const filesList = files.filter((v: any) => v.url !== file.url);
    setFiles(filesList);
  };

  const handleDownloadFileHandler = async (key: any) => {
    try {
      if (currentStandardId) {
        setLoaderUploadedFilesList(true);
        await downloadDataLakeFileHandler(key, currentStandardId);
      }
    } catch (error) {
      console.log("Error while upload file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  // end region

  const [
    getSelectedStandards,
    getSelectedStandardsCallable,
    setSelectedStandards,
  ] = useAsyncEffect<SelectedStandardsResponse>({
    fn: async () => {
      let groupData = "";
      if (!groupId.length) {
        const data: any = await Auth.currentSession();

        const group = data.accessToken.payload["cognito:groups"].filter(
          (element: any) => element.includes("org:")
        )[0];
        if (group) {
          setGroupId(group);
          groupData = group;
        }
      }

      let filter: any = {};
      if (businessUnitsValue && businessUnitsValue?.id) {
        filter.business_unit = businessUnitsValue.id;
      }
      if (orgLocationValue && orgLocationValue?.id) {
        filter.location_id = orgLocationValue.id;
      }
      if (reportPeriodValue && reportPeriodValue?.id) {
        filter.report_period = reportPeriodValue.id;
      }
      if (searchValue) {
        filter.name = searchValue;
      }
      if (standards && !standards?.find((item) => item === "All Standards")) {
        filter.frameworks = standards;
      }
      if (statuses && statuses !== "All Publish Statuses") {
        filter.user_status = statuses.toLowerCase();
      }
      if (users && users !== "All User Statuses") {
        filter.publish_status = users === "Published" ? true : false;
      }

      filter.group_id = groupData ? groupData : groupId;
      filter.status = "ACTIVE";
      const payload = {
        filter: filter,
        sort: { industry: -1 },
      };

      return (await getSelectedStandard(payload)) as any;
    },
    dependencies: [
      businessUnitsValue,
      orgLocationValue,
      reportPeriodValue,
      searchValue,
      statuses,
      users,
      standards,
    ],
  });

  const [getFrameworks, getFrameworksCallable] = useAsyncEffect({
    fn: async () => {
      const result = (await getUniqueFrameworks()) as any;
      return result;
    },
    dependencies: [],
  });

  const [updateSelectedStandardsOnce, updateSelectedStandardsCallableOnce] =
    useAsync({
      fn: async () => {
        const selectedItems = data.filter((item) => item.isSelected);

        let payload: any[] | null = null;

        const items = getSelectedStandards.result?.data;

        if (items && selectedItems.length > 0) {
          if (formData || actionStatus) {
            const val = items.filter((val: any) => {
              return selectedItems.some(
                (item: { _id: { $oid: string } }) =>
                  val._id.$oid === item._id.$oid
              );
            });
            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const yy = isoString.slice(0, 4);
            const mm = isoString.slice(5, 7);
            const dd = isoString.slice(8, 10);
            const hh = isoString.slice(11, 13);
            const min = isoString.slice(14, 16);
            const sec = isoString.slice(17, 19);
            const formattedDate = `${yy}-${mm}-${dd} ${hh}:${min}:${sec}`;

            payload = val.map((item: any) => {
              let commentsArray = Array.isArray(item.comments)
                ? item.comments
                : [];

              return {
                ...item,
                ...(formData && { response: formData }),
                ...{
                  comments: commentsArray,
                },
                ...{ publish_status: isPublish },
                ...{ user_status: actionStatus },
                ...{ modify_reason: modifyReason },
                ...(isArchive && { status: "INACTIVE" }),
              };
            });
          } else {
            payload = items.filter(
              (val: any) => val._id.$oid === selectedItems[0]._id.$oid
            );
          }
        }

        let response: any;
        if (payload && payload.length > 0) {
          response = payload.map(async (item) => {
            return await updateSelectedStandard(item);
          });
        }
        return Promise.all(response);
      },
    });

  const [updateSelectedStandards, updateSelectedStandardsCallable] = useAsync({
    fn: async () => {
      let selectedItems: any[];
      if (isArchive) {
        selectedItems = data.filter((item) => item.isSelectedCheckbox);
      } else {
        selectedItems = data.filter((item) => item.isSelected);
      }

      let payload: any[] | null = null;

      const items = getSelectedStandards.result?.data;

      if (items && selectedItems.length > 0) {
        if (formData || actionStatus || isArchive) {
          const val = items.filter((val: any) => {
            return selectedItems.some(
              (item: { _id: { $oid: string } }) =>
                val._id.$oid === item._id.$oid
            );
          });
          const currentDate = new Date();
          const isoString = currentDate.toISOString();
          const yy = isoString.slice(0, 4);
          const mm = isoString.slice(5, 7);
          const dd = isoString.slice(8, 10);
          const hh = isoString.slice(11, 13);
          const min = isoString.slice(14, 16);
          const sec = isoString.slice(17, 19);
          const formattedDate = `${yy}-${mm}-${dd} ${hh}:${min}:${sec}`;

          payload = val.map((item: any) => {
            let commentsArray = Array.isArray(item.comments)
              ? item.comments
              : [];

            return {
              ...item,
              ...(formData && { response: formData }),
              ...{
                comments: commentsArray,
              },
              ...{ publish_status: isPublish },
              ...{ user_status: actionStatus },
              ...(isArchive && { status: "INACTIVE" }),
            };
          });
        } else {
          payload = items.filter(
            (val: any) => val._id.$oid === selectedItems[0]._id.$oid
          );
        }
      }
      let response: any;
      if (payload && payload.length > 0) {
        response = payload.map(async (item) => {
          return await updateSelectedStandard(item);
        });
      }
      return Promise.all(response);
    },
  });

  const [actionStatus, setActionStatus] = useState<string | undefined>(
    selectedStandard?.user_status
  );

  useEffect(() => {
    if (updateSelectedStandardsOnce.result) {
      setConfirmModalShowing(false);

      // setIsUpdateOnceSuccessfull(true);
      (async () => {
        await getSelectedStandardsCallable();
      })();
    }
    if (selectedStandard && getSelectedStandards.result) {
      const updatedData = getSelectedStandards.result.data.map((item: any) => {
        if (item?._id?.$oid === selectedStandard?._id?.$oid) {
          return {
            ...item,
            response: formData,
            publish_status: isPublish,
            user_status: actionStatus ?? "",

            // Update other properties as needed...
          };
        }
        return item;
      });

      const updatedState = {
        data: updatedData,
        total: getSelectedStandards.result.total,
        filteredCount: getSelectedStandards.result.filteredCount,
      };

      setSelectedStandards(updatedState);
    }
  }, [updateSelectedStandards.result, updateSelectedStandardsOnce.result]);

  const [aiContent, getAiContentCallable] = useAsync<any>({
    fn: async () => {
      if (selectedStandard) {
        const { name, description, standard_code, frameworks } =
          selectedStandard;
        const result = await getGeneratedAIContent({
          name: name,
          description: description,
          standard_code: standard_code,
          frameworks: frameworks,
        });
        const content = result.content;

        setFormData(content);

        return result;
      }
    },
  });

  useEffect(() => {
    if (updateSelectedStandards.result) {
      setCommentData("");
    }
    if (isArchive && updateSelectedStandards.result) {
      try {
        const customMsg =
          status === "INACTIVE"
            ? "Standard archived successfully!"
            : "Standard restored successfully!";
        if (updateSelectedStandards.result) {
          message.success(customMsg);
          refreshReport();
        } else {
          message.error("Something went wrong while archiving the standard!");
        }
      } catch (error) {
        message.error("Something went wrong while archiving the standard!");
      } finally {
        setIsArchive(false);
        setShowAssignPermissionsModal(false);
        setShowConfirmModal(false);
      }
    }
  }, [updateSelectedStandards.result]);

  useEffect(() => {
    (async () => {
      await getFrameworksCallable();
    })();
  }, []);

  useEffect(() => {
    if (refreshListReport || refreshListArchive) {
      (async () => {
        await getSelectedStandardsCallable();
      })();
    }
  }, [refreshListReport, refreshListArchive]);

  useEffect(() => {
    !isAuditPage && localStorage.removeItem("isReportFilterChanged");
    getFilters();
  }, []);

  useEffect(() => {
    if (getSelectedStandards.result) {
      try {
        setTotalPage(
          Math.ceil(getSelectedStandards.result.filteredCount / pageSize)
        );
        const value = getSelectedStandards.result.data.map(
          (item: SelectedStandardsItem, index: number) => {
            let isSelected = false;
            if (loader > 1 && selectedStandard) {
              isSelected = item?._id?.$oid === selectedStandard?._id?.$oid;
            } else {
              isSelected = index === 0;
            }
            return {
              ...item,
              _id: {
                $oid: item._id.$oid,
              },
              name: item.name,
              standard_id: item.standard_id,
              type: item.frameworks,
              isSelected: isSelected,
              keyValue: item.standard_code,
              response: item.response,
              isSelectedCheckbox: selectAll ? true : false,
              onCheckboxClick: handleCheckboxClick,
              onClick: handleSelectedStandardClick,
            };
          }
        );

        setData(value);

        setIsLoadingStandardData(false);
      } catch (e) {
        setIsLoadingStandardData(false);
      }
    }
  }, [getSelectedStandards.result]);

  useEffect(() => {
    const currentPage = data.slice(startIndex, endIndex);
    setCurrentItems(currentPage);

    if (
      currentPage.length > 0 &&
      !currentPage.some((item) => item.isSelected)
    ) {
      const updatedItems = data.map((item, index) => ({
        ...item,
        isSelected: currentPage[0]._id.$oid === item._id.$oid,
      }));

      setData(updatedItems);
      setLoader(2);
    }
  }, [page, data]);

  useEffect(() => {
    if (getSelectedStandards.result) {
      setLoader(2);
    }
  }, [getSelectedStandards.result]);

  useEffect(() => {
    if (getFrameworks.result && getFrameworks.result !== "[]") {
      const frameworksArray = getFrameworks.result
        .slice(1, -1)
        .split(",")
        .map((item: string) => item.trim());
      const listValue = frameworksArray.map((item: any) => {
        return item;
      });
      {
        standardsList.length < 2 &&
          setStandardsList((prev) => [...prev, ...listValue]);
      }
    }
  }, [getFrameworks.result]);

  const getCheckBoxSelectedStandardsFromResponse = () => {
    const firstArray: SelectedStandardsItem[] =
      getSelectedStandards.result?.data ?? [];
    const secondArray: Item[] = data;

    const filteredItems: SelectedStandardsItem[] = secondArray
      .filter((item) => item.isSelectedCheckbox)
      .map((selectedItem) => {
        return firstArray.find(
          (item) => item?._id?.$oid === selectedItem?._id?.$oid
        );
      })
      .filter((item): item is SelectedStandardsItem => !!item);

    return filteredItems;
  };

  const getFilters = async () => {
    const payload = {
      filter_types: ["location", "business_unit", "reporting_period"],
    };
    const response = await getReportFilters(payload);
    if (response) {
      const businessUnit = response?.filter(
        (item: { filter_type: string }) => item.filter_type === "business_unit"
      );
      const location = response?.filter(
        (item: { filter_type: string }) => item.filter_type === "location"
      );
      const reportingPeriod = response?.filter(
        (item: { filter_type: string }) =>
          item.filter_type === "reporting_period"
      );
      getBusinessUnit(businessUnit);
      getLocation(location);
      getReportingPeriod(reportingPeriod);
    }
  };

  const getBusinessUnit = async (response: any[]) => {
    if (response) {
      const value: ListItems[] = response.map((item: any) => {
        return {
          name: item.name,
          id: item._id.$oid,
        };
      });
      setBusinessUnitsList((prev) => [...prev, ...value]);
    }
  };

  const getLocation = async (response: any[]) => {
    if (response) {
      // response.map((item: any) => {
      //   setGroupId(item.group_id);
      // });
      const value = response.map((item: any) => {
        return {
          name: item.name,
          id: item._id.$oid,
        };
      });
      setOrgLocationsList((prev) => [...prev, ...value]);
    }
  };

  const getReportingPeriod = async (response: any[]) => {
    if (response) {
      // response.map((item: any) => {
      //   setGroupId(item.group_id);
      // });
      const value = response.map((item: any) => {
        return {
          name: item.name,
          id: item._id.$oid,
        };
      });
      setReportPeriodList((prev) => [...prev, ...value]);
    }
  };

  const handleSearchClick = async () => {
    setLoader(1);
    setPage(1);
    await getSelectedStandardsCallable();
  };

  const handleTabChange = (value: string) => {
    setTab(value);
  };

  const handlePreviousPageClick = () => {
    setLoader(1);

    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPageClick = () => {
    setLoader(1);

    setPage((prevPage) => Math.min(prevPage + 1, totalPage));
  };

  const handleBusinessUnitsChange = async (selectedItem: ListItems) => {
    setBusinessUnitsValue((prevValue) => {
      return selectedItem;
    });
    setLoader(1);
    setPage(1);
  };

  const handleReportPeriodChange = async (selectedItem: ListItems) => {
    setLoader(1);
    setPage(1);

    setReportPeriodValue(selectedItem);
  };

  const handleOrgLocationChange = async (selectedItem: ListItems) => {
    setLoader(1);
    setPage(1);

    setOrgLocationValue(selectedItem);
  };

  const handleStandardsChange = async (value: string[]) => {
    setLoader(1);
    setPage(1);

    setStandards(value);
  };

  const handleUsersChange = async (value: string) => {
    setLoader(1);
    setPage(1);

    setUsers(value);
  };

  const handleStatusesChange = async (value: string) => {
    setLoader(1);
    setPage(1);

    setStatuses(value);
  };

  const handleSelectedStandardClick = (clickedKey: any, data: any[]) => {
    setTab("information-actions");
    const selectedStandard = getSelectedStandards.result?.data.find(
      (item: any) => item._id.$oid === currentStandardId
    );
    const updatedData = data.map((item) => {
      return {
        ...item,
        isSelected: item._id === clickedKey._id,
      };
    });
    setData(updatedData);

    setSelectedStandard(selectedStandard);
  };

  const handleCheckboxClick = (clickedKey: any, data: any[]) => {
    const updatedData = data.map((item: Item) => {
      if (item._id === clickedKey._id) {
        return {
          ...item,
          isSelectedCheckbox: !item.isSelectedCheckbox,
        };
      }
      return item;
    });
    setData(updatedData);
  };

  const selectedStandardName =
    data.find((item) => item.isSelected === true)?.name ?? "";

  const handleSelectAllClick = () => {
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const updatedData = data.map((item) => {
      return {
        ...item,
        isSelectedCheckbox: selectAll,
      };
    });

    setData(updatedData);
  }, [selectAll]);

  const [currentStandardId, setCurrentStandardId] = useState<string>();

  useEffect(() => {
    (async () => {
      if (currentStandardId) {
        await loadDataLakeFiles(currentStandardId);
      }
    })();
  }, [currentStandardId, refreshUploadedFiles]);

  useEffect(() => {
    if (data.length > 0) {
      const dataId = data.find((item) => item.isSelected === true)?._id.$oid;
      if (dataId) {
        setCurrentStandardId(dataId);
      }
      const selectedItems = data.filter((item) => item.isSelectedCheckbox);
      const items = getSelectedStandards.result?.data;
      const payload = items?.filter((val: any) => {
        return selectedItems.some((item) => val._id.$oid === item._id.$oid);
      });
      if (payload && payload.length > 0) {
        setRecagorizeList(payload);
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedStandard) {
      setFormData(selectedStandard.response);
      setIsPublish(selectedStandard.publish_status);
      setActionStatus(selectedStandard.user_status);
    }
  }, [selectedStandard]);

  useEffect(() => {
    if (getSelectedStandards.result && getSelectedStandards.result.data) {
      const selectedStandard = getSelectedStandards.result.data.find(
        (item: any) => item?._id.$oid === currentStandardId
      );

      setSelectedStandard(selectedStandard);
    }
  }, [getSelectedStandards.result, currentStandardId]);
  const currentDate = new Date();
  const isoString = currentDate.toISOString();
  const yy = isoString.slice(0, 4);
  const mm = isoString.slice(5, 7);
  const dd = isoString.slice(8, 10);
  const hh = isoString.slice(11, 13);
  const min = isoString.slice(14, 16);
  const sec = isoString.slice(17, 19);
  const formattedDate = `${yy}-${mm}-${dd} ${hh}:${min}:${sec}`;

  const handleCommentsSubmit = async (value: any) => {
    if (selectedStandard && getSelectedStandards.result) {
      const newComment: Comment = {
        comment_id: uuidv4(),
        username: userInfoStore.userID,
        comment_data: value,
        status: "ACTIVE",
        create_timestamp: formattedDate,
        modify_timestamp: formattedDate,
      };

      // Update comments by adding a new comment
      const updatedData = getSelectedStandards.result.data.map((item: any) => {
        if (item._id.$oid === selectedStandard._id.$oid) {
          // Create a new comments array by spreading the existing comments and adding the new comment
          const updatedComments = [...(item.comments || []), newComment];

          // Return the item with the updated comments
          return {
            ...item,
            comments: updatedComments,
            // Update other properties if needed...
          };
        }
        return item;
      });

      // Update the state with the modified data
      const updatedState = {
        data: updatedData,
        total: getSelectedStandards.result.total,
        filteredCount: getSelectedStandards.result.filteredCount,
      };

      // Set the state with the modified data
      setSelectedStandards(updatedState);
      const updatedItem = updatedData.find(
        (v) => v._id.$oid === selectedStandard._id.$oid
      );

      await updateSelectedStandard(updatedItem);
    }
  };

  const handlePushlishChange = () => {
    setIsPublish(!isPublish);
  };

  const handleAddOrSaveClick = async () => {
    setConfirmModalShowing(true);
  };

  const handleConfirmUpdate = async (values: any) => {
    if (values && values.editReason) {
      setModifyReason(values.editReason);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalShowing(false);
  };

  useEffect(() => {
    const updateStandardData = async () => {
      await updateSelectedStandardsCallableOnce();
      setModifyReason(undefined);
    };

    if (modifyReason) {
      updateStandardData();
    }
  }, [modifyReason]);

  const handleGenerateReport = () => {
    // navigate("/reporting/sustainability-standards/generate-report");
  };

  const handleAddNewStandardClick = () => {
    setShowAddModal(true);
  };

  const handleFormDataChange = (value: any) => {
    setFormData(value);
  };

  const onClickRecategorizeStandards = () => {
    setShowStandardRecategorizeModal(true);
  };

  const onClickAssignPermissionsData = () => {
    setShowAssignPermissionsModal(true);
  };

  const onClickArchive = (status: any) => {
    setStatus(status);
    setShowConfirmModal(true);
  };

  const onClickCloneStandards = () => {
    setShowStandardCloneModal(true);
  };

  const refreshReport = () => {
    setRefreshListReport(true);
  };

  const refreshArchive = () => {
    setRefreshListArchive(true);
  };

  const handleGenerateAI = async () => {
    await getAiContentCallable();
  };

  const hasData =
    getSelectedStandards.result?.data &&
    getSelectedStandards.result.data.length > 0;

  const listValue = {
    create_timestamp: "",
    date_end: "",
    date_start: "",
    description: "",
    filter_type: "",
    group_id: "",
    modify_timestamp: "",
    name: "",
    status: "ACTIVE",
    username: "",
    _id: { $oid: "" },
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="clone_standards">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickCloneStandards()}
          type="custom"
          disabled={data.filter((item) => item.isSelectedCheckbox).length < 1}
        >
          <CopyOutlined /> <span>Clone Standards</span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="recategorize">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickRecategorizeStandards()}
          type="custom"
          disabled={data.filter((item) => item.isSelectedCheckbox).length < 1}
        >
          <CopyOutlined /> <span>Recategorize</span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="permissions">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickAssignPermissionsData()}
          type="custom"
          disabled={data.filter((item) => item.isSelectedCheckbox).length < 1}
        >
          <ApartmentOutlined />
          <span>Permissions</span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="assign_auditors">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => {
            const id = data
              .filter((item) => item.isSelectedCheckbox)
              .map((item) => item._id.$oid);
            navigate("/reporting/sustainability-standards/create-audit", {
              state: {
                id: id.toString(),
                type: "SUSTAINABILITY_STANDARDS",
              },
            });
          }}
          type="custom"
          disabled={data.filter((item) => item.isSelectedCheckbox).length < 1}
        >
          <RightSquareOutlined /> <span>Assign Auditors </span>
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="archive">
        <StyledButton
          bgcolor={COLORS.raisinBlack}
          bordercolor={COLORS.white}
          onClick={() => onClickArchive("INACTIVE")}
          type="custom"
          disabled={data.filter((item) => item.isSelectedCheckbox).length < 1}
        >
          <DeleteOutlined /> <span>Archive</span>
        </StyledButton>
      </Menu.Item>
    </TableCommonMenu>
  );

  const selectedItems = getCheckBoxSelectedStandardsFromResponse();

  const handleStatusChanged = (status: string) => {
    setActionStatus(status);
  };

  const onConfirmArchive = async () => {
    setIsArchive(true);
  };

  useEffect(() => {
    (async () => {
      if (isArchive) {
        await updateSelectedStandardsCallable();
      }
    })();
  }, [isArchive]);

  const handleDeleteLakeComment = async (commentParam: Comment) => {
    if (selectedStandard && getSelectedStandards.result) {
      // Update comments based on comment_id
      const updatedData = getSelectedStandards.result.data.map((item: any) => {
        if (item._id.$oid === selectedStandard._id.$oid) {
          // Find the comment within the comments array in the item and mark its status as INACTIVE
          const updatedComments = item.comments?.map((comment: any) => {
            if (comment.comment_id === commentParam.comment_id) {
              // If the comment_id matches, mark the specific comment as INACTIVE
              return {
                ...comment,
                status: "INACTIVE",
                // Preserve other properties if needed...
              };
            }
            return comment; // Return unchanged comment if no match
          });

          // Return the item with the updated comments
          return {
            ...item,
            comments: updatedComments,
            // Update other properties if needed...
          };
        }
        return item;
      });

      // Update the state with the modified data
      const updatedState = {
        data: updatedData,
        total: getSelectedStandards.result.total,
        filteredCount: getSelectedStandards.result.filteredCount,
      };

      setSelectedStandards(updatedState);
      const updatedItem = updatedData.find(
        (v) => v._id.$oid === selectedStandard._id.$oid
      );

      await updateSelectedStandard(updatedItem);
    }
  };

  const handleEditLakeComment = async (commentParam: Comment, data: string) => {
    if (selectedStandard && getSelectedStandards.result) {
      const updatedData = getSelectedStandards.result.data.map((item) => {
        if (item._id.$oid === selectedStandard._id.$oid) {
          const updatedComments = item.comments?.map((comment) => {
            if (comment.comment_id === commentParam.comment_id) {
              return {
                ...comment,
                comment_data: data,
                modify_timestamp: new Date().toISOString(),
              };
            }
            return comment;
          });

          return {
            ...item,
            comments: updatedComments,
          };
        }
        return item;
      });

      const updatedState = {
        data: updatedData,
        total: getSelectedStandards.result.total,
        filteredCount: getSelectedStandards.result.filteredCount,
      };

      setSelectedStandards(updatedState);
      const updatedItem = updatedData.find(
        (v) => v._id.$oid === selectedStandard._id.$oid
      );

      await updateSelectedStandard(updatedItem);
    }
  };

  useEffect(() => {
    (async () => {
      await updateSelectedStandardsCallable();
    })();
  }, [autoSave]);
  return (
    <>
      <>
        {showAddModal && (
          <AddNewStandardModal
            isVisible={showAddModal}
            onClose={() => setShowAddModal(false)}
            setTemplateLibraryModal={setTemplateLibraryModal}
          />
        )}
        {templateLibraryModal && (
          <ESGTemplateModal
            visible={templateLibraryModal}
            onClose={() => setTemplateLibraryModal(false)}
            onFinish={() => setTemplateLibraryModal(false)}
          />
        )}
      </>
      {showStandardCloneModal && (
        <StandardCloneModal
          visible
          onClose={() => setShowStandardCloneModal(false)}
          selectedListReportDataLake={recategorizeList}
          refreshReportDataLake={refreshReport}
          locationsList={orgLocationsList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "org_location",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
          businessUnitsList={businessUnitsList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "business_unit",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
          reportPeriodsList={reportPeriodList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "report_period",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
        />
      )}
      {showStandardRecategorizeModal && (
        <StandardRecategorizeModal
          visible
          onClose={() => setShowStandardRecategorizeModal(false)}
          selectedListReportDataLake={recategorizeList}
          refreshReportDataLake={refreshReport}
          locationsList={orgLocationsList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "org_location",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
          businessUnitsList={businessUnitsList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "business_unit",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
          reportPeriodsList={reportPeriodList
            .filter((item) => item.id !== "")
            .map((item) => {
              return {
                ...listValue,
                filter_type: "report_period",
                name: item.name,
                _id: { $oid: item.id },
              };
            })}
        />
      )}
      {showAssignPermissionsModal && (
        <AssignPermissionsModal
          visible={showAssignPermissionsModal}
          onClose={() => setShowAssignPermissionsModal(false)}
          rowData={recategorizeList}
          refresh={refreshArchive}
        />
      )}
      {showConfirmModal && (
        <ConfirmArchiveModal
          isVisible={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmArchive}
          confirmLoading={confirmLoading}
        />
      )}
      <SustainabilityStandardComponent
        isLoadingAI={aiContent.isLoading}
        isUpdateLoading={updateSelectedStandardsOnce.isLoading}
        isLoading={loader !== 2}
        hasSelectedStandards={!!hasData}
        isLoadingStandardData={isLoadingStandardData}
        hasError={!!getSelectedStandards.error}
        searchString={searchValue}
        businessUnits={businessUnitsValue}
        orgLocations={orgLocationValue}
        reportPeriods={reportPeriodValue}
        standards={standards}
        users={users}
        statuses={statuses}
        tab={tab ?? "information-actions"}
        data={currentItems}
        selectedItems={selectedItems}
        totalItems={data}
        pagination={{
          currentPage: page,
          totalPage: totalPage,
          onPreviousClick: handlePreviousPageClick,
          onNextClick: handleNextPageClick,
        }}
        fileProps={{
          isLoading: loaderUploadedFilesList,
          files,
          uploadedFilesList,
          onBeforeUpload: handleBeforeUpload,
          onRemoveFileFromListHandler: handleRemoveFileFromListHandler,
          onDownloadFileHandler: handleDownloadFileHandler,
        }}
        selectedData={selectedStandardName}
        actionStatus={actionStatus ?? "open"}
        publishedStatus={isPublish}
        businessUnitsList={businessUnitsList ? businessUnitsList : []}
        orgLocationsList={orgLocationsList ? orgLocationsList : []}
        reportPeriodsList={reportPeriodList ? reportPeriodList : []}
        standardsList={standardsList ? standardsList : []}
        usersList={usersList ? usersList : []}
        statusesList={statusesList ? statusesList : []}
        rowData={selectedStandard}
        formData={formData}
        moreButtons={moreButtons}
        selectAll={selectAll}
        confirmModalShowing={confirmModalShowing}
        onStatusChanged={handleStatusChanged}
        onCommentsUpdate={handleCommentsSubmit}
        onChangeBusinessUnits={handleBusinessUnitsChange}
        onChangeOrgLocations={handleOrgLocationChange}
        onChangeReportPeriods={handleReportPeriodChange}
        onChangeStandards={handleStandardsChange}
        onChangeUsers={handleUsersChange}
        onChangeStatuses={handleStatusesChange}
        onTabChange={handleTabChange}
        onSearchClick={handleSearchClick}
        onSearchValueChange={setSearchValue}
        onSelectAllClick={handleSelectAllClick}
        onUpdateClick={handleAddOrSaveClick}
        onFormDataChange={handleFormDataChange}
        onPublishClick={handlePushlishChange}
        onAddNewStandardClick={handleAddNewStandardClick}
        onGenerateAI={handleGenerateAI}
        onConfirmUpdate={handleConfirmUpdate}
        onConfirmModalClose={handleConfirmModalClose}
        onDeleteLakeComment={handleDeleteLakeComment}
        onEditLakeComment={handleEditLakeComment}
      />
    </>
  );
};
