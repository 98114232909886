import React from "react";
import { Button, Col, Row } from "antd";
import { useBoolean, useSetState } from "ahooks";
import { PageWrap, StyledProgress } from "../../../shared/commonStyles";
import { ScienceBasedTargets } from "./Components/ScienceBasedTargets/ScienceBasedTargets";
import { CustomTargets } from "./Components/CustomTargets/CustomTargets";
import { HeaderWhatIf } from "./HeaderWhatIf";
import styles from "./WhatIfOnboarding.module.scss";

interface TargetsPayload {
  type: "custom" | "science-based";
  targets: Target[];
}

export const WhatIfOnboarding = () => {
  const [
    isScienceTargetsVisible,
    { setTrue: showScienceTargets, setFalse: hideScienceTargets },
  ] = useBoolean(false);
  const [
    isCustomTargetsVisible,
    { setTrue: showCustomTargets, setFalse: hideCustomTargets },
  ] = useBoolean(false);

  const [payload, setPayload] = useSetState<TargetsPayload>(
    {} as TargetsPayload
  );

  return (
    <>
      {(isScienceTargetsVisible && (
        <ScienceBasedTargets onBack={hideScienceTargets} />
      )) ||
        (isCustomTargetsVisible && (
          <CustomTargets onBack={hideCustomTargets} />
        )) || (
          <>
            <HeaderWhatIf />
            <StyledProgress
              strokeLinecap="square"
              strokeWidth={10}
              percent={20}
              showInfo={false}
              className={styles.progress}
            />
            <PageWrap className={styles.container}>
              <Row justify="center" align="middle">
                <Col>
                  <span className={styles.welcomeText}>
                    Welcome to reduction plan section!
                  </span>
                  <div>
                    <div className={styles.content}>
                      <h2 className={styles.title}>
                        Are you familiar with science based targets?
                      </h2>
                      <div className={styles.buttons}>
                        <Button
                          className={styles.buttonGhost}
                          onClick={showScienceTargets}
                        >
                          Yes
                        </Button>
                        <Button
                          className={styles.buttonGhost}
                          onClick={showCustomTargets}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </PageWrap>
          </>
        )}
    </>
  );
};
