import React, { useEffect, useState } from "react";
import { Spin, Tag } from "antd";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";
import { fetchProfileImage } from "../../../../../shared/commonFunctions";
import { listPermissions } from "../../../../../services/permissions";
import { AssignUserAndGroups } from "../../../../../components/AssignUserAndGroups/AssignUserAndGroups";

interface UserInfo extends User {
  profileImage?: string;
  assigned?: boolean;
}
interface UserGroupInfo extends UserGroup {
  assigned?: boolean;
  isGroup?: boolean;
}
interface SelectedData {
  assignedUsers: string[];
  assignedGroups: string[];
}
interface AssignUsersModalProps {
  dataSheet: DataSheet;
  visible: boolean;
  selectedRows: DataSheetRecord[] | MetaDataRecord[];
  onCancel: () => void;
  onConfirm: (selectedData: SelectedData) => void;
  usersList: User[];
  userGroupsData: UserGroupInfo[];
}

export const AssignUsersModal = ({
  dataSheet,
  visible,
  selectedRows,
  onCancel,
  onConfirm,
  usersList,
  userGroupsData,
}: AssignUsersModalProps) => {
  const [usersData, setUsersData] = useState<UserInfo[]>(usersList);
  const [userGroups, setUserGroups] = useState<UserGroupInfo[]>(userGroupsData);
  const [loadingData, setloadingData] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    setUserGroups(userGroupsData);
    if (selectedRows.length === 1 && visible) {
      loadPermissions();
    }
    if (visible === false) {
      setUsersData(usersList);
      setUserGroups([]);
    }
  }, [selectedRows, visible]);

  useEffect(() => {
    loadProfileImg();
  }, [usersList]);

  const loadPermissions = async () => {
    setloadingData(true);
    const payload = {
      permissionType: "REFERENCE_DATA",
      itemId: dataSheet._id.$oid,
      assignedItems: selectedRows[0][dataSheet.sheet_name]?.id,
    };
    const permissionsData = await listPermissions(payload);
    const assignedUsers = usersData.map((v) => {
      if (permissionsData.find((item: any) => item.username === v.key)) {
        return { ...v, assigned: true };
      } else {
        return v;
      }
    });
    const assignedGroups = userGroupsData.map((v) => {
      if (permissionsData.find((item: any) => item.groupId === v._id.$oid)) {
        return { ...v, assigned: true };
      } else {
        return v;
      }
    });

    const assignedEmails = permissionsData
      .filter((v: any) => v.email)
      .map((item: any) => item.email);
    setUsersData(assignedUsers);
    setUserGroups(assignedGroups);
    setEmails(assignedEmails);
    setloadingData(false);
  };

  const loadProfileImg = async () => {
    const newData = await Promise.all(
      usersList.map(async (item) => {
        const imgUrl = await fetchProfileImage(item.Username);

        return { ...item, profileImage: imgUrl };
      })
    );

    setUsersData(newData);
  };

  const handleAddUser = (user: UserInfo, assigned: boolean) => {
    const newUsers = usersData.map((v) =>
      v.key === user.key ? { ...v, assigned } : v
    );
    setUsersData(newUsers);
  };

  const handleAddUserGroup = (group: UserGroupInfo, assigned: boolean) => {
    const newGroups = userGroups.map((v) =>
      v._id.$oid === group._id.$oid ? { ...v, assigned } : v
    );
    setUserGroups(newGroups);
  };

  const getTags = () => {
    const column = dataSheet.sheet_schema.find((v) => v.isIdentifier);
    if (selectedRows.length > 1) {
      return (
        <>
          {column &&
            selectedRows.map((v) => (
              <Tag
                key={
                  dataSheet.is_reference_data
                    ? v?.[dataSheet.sheet_name]?.id
                    : v?._id?.$oid
                }
                style={{ borderRadius: "100px", marginBottom: "10px" }}
                color="#A68DFB"
              >
                {v[column.entity_name]}
              </Tag>
            ))}
        </>
      );
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    const users: string[] = usersData
      .filter((v) => v.assigned)
      .map((item) => {
        return item.key;
      });
    const data = {
      assignedEmails: emails,
      assignedUsers: users,
      assignedGroups: userGroups
        .filter((v) => v.assigned)
        .map((item) => {
          return item._id.$oid;
        }),
    };

    onConfirm(data);
  };
  return (
    <CommonModal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      centered
      okText="Assign"
      title="Select the users and groups you want to assign"
    >
      <Spin spinning={loadingData}>
        <FormWrapper>
          {getTags()}
          <AssignUserAndGroups
            usersList={usersData}
            groupsList={userGroups}
            emails={emails}
            handleChangeSelectionUser={handleAddUser}
            handleChangeSelectionGroup={handleAddUserGroup}
            setEmails={setEmails}
          />
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};
