import React from "react";

import { Button, Popconfirm, Space } from "antd";
import { useBoolean } from "ahooks";
import { EditTargetModal } from "../EditTargetModal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styles from "../../../ReductionPlan/ReductionPlanListView.module.scss";

interface TableRowButtonsProps {
  data: TargetPayload;
  onDelete: () => void;
  onConfirm: (data: TargetPayload) => void;
}

const TableRowButtons = ({
  data,
  onDelete,
  onConfirm,
}: TableRowButtonsProps) => {
  const [
    editTargetModal,
    { setTrue: showEditTargetModal, setFalse: hideEditTargetModal },
  ] = useBoolean(false);

  const handleEditTarget = (data: TargetPayload) => {
    hideEditTargetModal();
    onConfirm(data);
  };

  return (
    <>
      <Space className={styles.btnIconsWrap}>
        <Button className={styles.btnIcon}>
          <EditOutlined onClick={showEditTargetModal} />
        </Button>
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={onDelete}
          overlayClassName="popoverContent"
        >
          <Button className={styles.btnIcon}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Space>
      <EditTargetModal
        target={data}
        isShown={editTargetModal}
        isCustom={data?.type === "custom"}
        onClose={hideEditTargetModal}
        onConfirm={handleEditTarget}
      />
    </>
  );
};

export default TableRowButtons;
