import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Space,
  Divider,
  Typography,
} from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { YearMonthDayFormat } from "../../../../shared/constants";
import {
  StyledButton,
  TableWrap,
  FormWrapper,
} from "../../../../shared/commonStyles";
const { Title } = Typography;

interface DataSheetSingleFormProps {
  dataSheet: DataSheet;
  rowData: RecordRowData;
}

interface FormItemProps {
  name: string;
  rules: {
    required: boolean;
    message: string;
  }[];
  style: {
    width: string;
  };
  valuePropName?: string;
}

export const DataSheetSingleForm = ({
  dataSheet,
  rowData,
}: DataSheetSingleFormProps) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const dynamicFormInputs = (dataSheet: DataSheet, rowData: RecordRowData) => {
    const formFields = dataSheet.sheet_schema?.filter((v) =>
      !v.isExpression && v.input_type !== "expression" && rowData.display_fields
        ? rowData.display_fields?.find((item) => v.entity_name === item)
        : !rowData?.hide_fields && !v.metadata_entity_name
    );
    try {
      return formFields?.map((item: SheetSchema, index: number) => {
        let inputNode = null;
        let formItemProps: FormItemProps = {
          name: item.entity_name as string,
          rules: [
            { required: item.required, message: `This field is required!` },
          ],
          style: { width: "100%" },
        };

        const inputType = item?.input_type;
        if (inputType === "number") {
          inputNode = (
            <>
              <InputNumber type="number" style={{ width: "100%" }} />
            </>
          );
        } else if (inputType === "date") {
          inputNode = (
            <>
              <DatePicker
                style={{ width: "100%" }}
                format={YearMonthDayFormat}
              />
            </>
          );
        } else if (inputType === "string") {
          inputNode = (
            <>
              <Input />
            </>
          );
        } else if (inputType === "dropdown") {
          inputNode = (
            <Select style={{ width: "100%" }}>
              {item?.options?.map((item: any) => {
                return (
                  <>
                    <Select.Option key={item?.value} value={item?.value}>
                      {item?.label}
                    </Select.Option>
                  </>
                );
              })}
            </Select>
          );
        } else if (inputType === "radio") {
          inputNode = (
            <Radio.Group>
              {item?.options?.map((item: any) => {
                return (
                  <>
                    <Radio key={item?.value} value={item?.value}>
                      {item?.label}
                    </Radio>
                  </>
                );
              })}
            </Radio.Group>
          );
        } else if (inputType === "checkbox") {
          if (item?.is_checkbox_group) {
            inputNode = (
              <Checkbox.Group>
                <Row>
                  {item?.options?.map((item: any) => {
                    return (
                      <Col key={item?.value} xs={24} sm={24} md={24} lg={24}>
                        <Checkbox value={item?.value}>{item?.label}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            );
          } else {
            formItemProps = { ...formItemProps, valuePropName: "checked" };
            inputNode = (
              <>
                <Checkbox>{item.display_name}</Checkbox>
              </>
            );
          }
        } else if (inputType === "long-answer") {
          inputNode = (
            <>
              <Input.TextArea style={{ height: 100 }} />
            </>
          );
        } else {
          //string
          inputNode = null;
        }

        return (
          <Row gutter={[16, 16]} key={index}>
            <Col span={24}>
              <Form.Item wrapperCol={{ span: 24 }} label="">
                <Title level={5}>{item.display_name}</Title>
                {item.description && (
                  <p className="color-white">{item.description}</p>
                )}
                <Divider
                  style={{ backgroundColor: "#4F4669", margin: "8px 0" }}
                />
                <Row gutter={[16, 16]}>
                  <Col span={item.unit_id ? 14 : 24}>
                    <Form.Item {...formItemProps}>{inputNode}</Form.Item>
                  </Col>
                  {item.unit_id && (
                    <Col span={10}>
                      <Form.Item
                        name={item.entity_name + "_unit_id"}
                        initialValue={item.unit_id}
                        rules={[
                          {
                            required: true,
                            message: "Please select a Unit!",
                          },
                        ]}
                      >
                        <Select style={{ width: "100%" }} placeholder="Unit">
                          {item.convertable_units?.map((unit: any) => {
                            return (
                              <Select.Option key={unit.unit} value={unit.unit}>
                                {unit.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        );
      });
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      {dataSheet ? (
        <>
          <TableWrap style={{ marginTop: "0" }}>
            <FormWrapper>
              <Form {...layout}>
                {dynamicFormInputs(dataSheet, rowData)}

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title className="color-white" level={4}>
                      Attach related files
                    </Title>
                    <Title className="color-white" level={5}>
                      Attach files that are related to this field
                    </Title>
                    <Divider style={{ backgroundColor: "#4F4669" }} />
                    <Space direction="horizontal">
                      <StyledButton type="custom">
                        <UploadOutlined /> Upload
                      </StyledButton>
                    </Space>
                  </Col>
                </Row>
                {rowData.show_notes && (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Title className="color-white" level={4}>
                          Add notes
                        </Title>
                        <StyledButton
                          bgcolor="transparent"
                          bordercolor="#87809C"
                          type="primary"
                          ghost
                          icon={<EditOutlined />}
                        >
                          Add note
                        </StyledButton>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Form>
            </FormWrapper>
          </TableWrap>
        </>
      ) : null}
    </>
  );
};
