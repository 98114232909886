import React, { useEffect, useState } from "react";
import { Button, Radio, Space, Typography } from "antd";
import { CommonModal, FormWrapper } from "../../../../../shared/commonStyles";

interface IndicatorModalProps {
  visible: boolean;
  defaultValue?: "default" | "m" | "l";
  emissionName: string;
  onConfirm: (value: "default" | "m" | "l") => void;
  onCancel: () => void;
}

export const IndicatorModal = ({
  visible,
  defaultValue,
  emissionName,
  onConfirm,
  onCancel,
}: IndicatorModalProps) => {
  const [indicatorType, setIndicatorType] = useState(defaultValue || "default");

  useEffect(() => {
    setIndicatorType(defaultValue || "default");
  }, [defaultValue]);

  return (
    <CommonModal
      visible={visible}
      title="Indicator type"
      onCancel={onCancel}
      onOk={onConfirm}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => onConfirm(indicatorType)}
        >
          Submit
        </Button>,
      ]}
      centered
    >
      <div>
        <Typography.Text>
          Select indicator type for <b>{emissionName}</b>
        </Typography.Text>
      </div>
      <FormWrapper>
        <Radio.Group
          value={indicatorType}
          onChange={(e) => setIndicatorType(e.target.value)}
        >
          <Space direction="vertical">
            <Radio value="default">Default</Radio>
            <Radio value="m">Market-based</Radio>
            <Radio value="l">Location-based</Radio>
          </Space>
        </Radio.Group>
      </FormWrapper>
    </CommonModal>
  );
};
