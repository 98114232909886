import React from "react";
import {
  Col,
  Collapse,
  Dropdown,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  comparisonOperators,
  mathSymbolsOperators,
} from "../../../services/mongoOperators";
import {
  FormWrapper,
  StyledButton,
  StyledCollapse,
  StyledDivider,
  TableCommonMenu,
} from "../../../shared/commonStyles";

interface ValidationRulesProps {
  form: FormInstance<any>;
  activityFields: SheetSchema[];
  checkValidRules: () => boolean;
  handleRemoveRule: (index: number, callback: () => void) => void;
}

const ValidationRules = ({
  form,
  activityFields,
  checkValidRules,
  handleRemoveRule,
}: ValidationRulesProps) => {
  const validationOperators = [...mathSymbolsOperators, ...comparisonOperators];
  const columnsList = activityFields
    .filter((v) => v.input_type == "number")
    .map((item) => {
      return {
        value: item.entity_name,
        label: item.display_name,
        inputType: item.input_type,
      };
    });

  const isDisabledButton = (type: string, formKey: number) => {
    const formValues = form.getFieldsValue();
    const rule = formValues.validationRules?.[formKey]?.rule;
    const ruleOperators = rule?.filter(
      (item: any) => item.type === "operator"
    )?.length;
    const ruleColumns = rule?.filter(
      (item: any) => item?.type === "column" || item?.type === "number"
    )?.length;
    switch (type) {
      case "column":
        return ruleColumns !== ruleOperators;
      case "number":
        return ruleColumns !== ruleOperators;
      case "operator":
        return ruleColumns === ruleOperators;
    }
  };
  const getColumnRow = (
    name: number,
    index: number,
    formKey: number,
    remove: (key: number) => void
  ) => {
    const formValues = form.getFieldsValue();
    const rule = formValues.validationRules?.[formKey]?.rule;
    const isLastItem = rule?.length > 1 && index === rule?.length - 1;
    const columnType =
      formValues["validationRules"]?.[formKey]?.["rule"]?.[name]?.type;
    return (
      <>
        <Col span={isLastItem ? 20 : 24}>
          {(columnType === "column" && (
            <Form.Item
              name={[name, "value"]}
              label="Column"
              labelCol={{
                span: 24,
              }}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select placeholder="Select column" options={columnsList} />
            </Form.Item>
          )) ||
            (columnType === "operator" && (
              <Form.Item
                name={[name, "value"]}
                label="Operator"
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Select placeholder="Operator" options={validationOperators} />
              </Form.Item>
            )) ||
            (columnType === "number" && (
              <Form.Item
                name={[name, "value"]}
                label="Number"
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber placeholder="Input variable" />
              </Form.Item>
            ))}
        </Col>
        {isLastItem && (
          <Col span={4}>
            <Form.Item label=" ">
              <StyledButton
                type="default"
                style={{
                  padding: "8px",
                  marginTop: "0px",
                  high: "30px",
                }}
                onClick={() => {
                  remove(index);
                }}
                icon={
                  <>
                    <DeleteOutlined />
                  </>
                }
              />
            </Form.Item>
          </Col>
        )}
      </>
    );
  };

  const getColumnsForm = (formKey: number) => {
    return (
      <Form.List name={[formKey, "rule"]}>
        {(fields, { add, remove }) => {
          return (
            <Row>
              {fields.map(({ key, name, ...restField }, index) => {
                return getColumnRow(name, index, formKey, remove);
              })}
              <Col span={24}>
                <Form.Item>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <TableCommonMenu>
                        <Menu.Item
                          key="column"
                          style={{
                            borderBottom: "1px solid #8472C1",
                          }}
                        >
                          <StyledButton
                            type="custom"
                            disabled={isDisabledButton("column", formKey)}
                            onClick={() => add({ type: "column" })}
                          >
                            Column
                          </StyledButton>
                        </Menu.Item>
                        <Menu.Item
                          key="number"
                          style={{
                            borderBottom: "1px solid #8472C1",
                          }}
                        >
                          <StyledButton
                            type="custom"
                            disabled={isDisabledButton("number", formKey)}
                            onClick={() => add({ type: "number" })}
                          >
                            Number
                          </StyledButton>
                        </Menu.Item>
                        <Menu.Item key="operator">
                          <StyledButton
                            type="custom"
                            disabled={isDisabledButton("operator", formKey)}
                            onClick={() => add({ type: "operator" })}
                          >
                            Operator
                          </StyledButton>
                        </Menu.Item>
                      </TableCommonMenu>
                    }
                  >
                    <StyledButton
                      style={{ width: "100%" }}
                      type="custom"
                      margin="0"
                      padding="5px auto"
                      icon={<DownOutlined />}
                    >
                      Add item
                    </StyledButton>
                  </Dropdown>
                </Form.Item>
              </Col>
            </Row>
          );
        }}
      </Form.List>
    );
  };
  return (
    <FormWrapper width="fit-content">
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <Typography.Title level={3}>Create validation rules</Typography.Title>
          <Typography.Title level={5}>
            Click on the column header or select the column from the list to
            create a validation rule
          </Typography.Title>
          <StyledDivider />
        </Col>

        <Form.List name="validationRules">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <Col span={24} key={index}>
                      <StyledCollapse
                        key={index}
                        collapsible="header"
                        defaultActiveKey={index + 1}
                      >
                        <Collapse.Panel
                          header={<Row>Rule {index + 1}</Row>}
                          extra={
                            <Row justify="end">
                              <Col>
                                <StyledButton
                                  type="default"
                                  style={{ padding: "8px" }}
                                  onClick={() => {
                                    handleRemoveRule(index, () => remove(name));
                                  }}
                                  icon={<DeleteOutlined />}
                                />
                              </Col>
                            </Row>
                          }
                          key={index + 1}
                        >
                          <Row>
                            <Typography.Title level={5}>
                              Create validation rule
                            </Typography.Title>
                          </Row>
                          <StyledDivider margin="0 0 20px 0" />
                          {getColumnsForm(name)}
                          <StyledDivider />
                          <Form.Item
                            label="Add custom error message"
                            labelCol={{
                              span: 24,
                            }}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                            name={[name, "errorMessage"]}
                          >
                            <Input placeholder="Enter message" />
                          </Form.Item>
                        </Collapse.Panel>
                      </StyledCollapse>
                    </Col>
                  );
                })}
                <Col span={24}>
                  <Form.Item>
                    <StyledButton
                      type="custom"
                      onClick={() =>
                        checkValidRules() &&
                        add({
                          rule: [{ type: "column" }],
                        })
                      }
                      icon={<PlusCircleOutlined />}
                    >
                      Add New Rule
                    </StyledButton>
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.List>
      </Row>
    </FormWrapper>
  );
};

export default ValidationRules;
