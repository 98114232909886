import {
  Authenticator,
  Heading,
  View,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Col, Row, Typography } from "antd";
import React, { useEffect } from "react";

import { StyledButton } from "../../shared/commonStyles";

export const Login = ({ formType, setLoginRoute }) => {
  const { route } = useAuthenticator();

  useEffect(() => {
    setLoginRoute(route);
  }, [route]);

  const redirectToPasswordLessSignIn = () => {
    window.location.href = "/?email_id=' '&action=sign_in";
  };
  const components = {
    SignIn: {
      Header() {
        return (
          <View textAlign="center">
            <Heading
              level={3}
              style={{
                marginBottom: "15px",
                color: "#fff",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Welcome Back
            </Heading>
            <p className="color-grey">Please enter your details</p>
          </View>
        );
      },
      Footer() {
        const { toResetPassword, toSignUp } = useAuthenticator();

        return (
          <View textAlign="right">
            <Row>
              <Col span={24}>
                <StyledButton
                  fontWeight="500"
                  type="custom"
                  height="33px"
                  padding="0px !important"
                  onClick={redirectToPasswordLessSignIn}
                  bgcolor="#fff"
                  color="#000"
                  style={{
                    width: "100%",
                    padding: "0px 18px",
                    borderRadius: "0",
                    margin: "10px 0",
                  }}
                >
                  Sign in without password
                </StyledButton>
              </Col>
              <Col span={24}>
                <StyledButton
                  onClick={toResetPassword}
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#A68DFB"
                  style={{ padding: "0" }}
                >
                  Forgot Password
                </StyledButton>
              </Col>
              <Col
                span={24}
                style={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {`Don't have an account?`}
                <StyledButton
                  padding="0px !important"
                  height="30px"
                  type="text"
                  onClick={toSignUp}
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#A68DFB"
                  style={{ padding: "0" }}
                >
                  Sign Up
                </StyledButton>
              </Col>
            </Row>
          </View>
        );
      },
    },
    SignUp: {
      Header() {
        return (
          <View textAlign="center">
            <Heading
              level={3}
              style={{
                marginBottom: "15px",
                color: "#fff",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Welcome To Hydrus
            </Heading>
            <p className="color-grey">
              Hydrus simplifies the process of collecting sustainability data
            </p>
          </View>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="right">
            <Row>
              <Col
                span={24}
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                Already have an account?
                <StyledButton
                  onClick={toSignIn}
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="#A68DFB"
                  style={{ padding: "0", fontSize: "16px" }}
                >
                  Sign in
                </StyledButton>
              </Col>
            </Row>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <View textAlign="center">
            <Heading
              level={3}
              style={{
                marginBottom: "15px",
                color: "#fff",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Confirm Your Email
            </Heading>
          </View>
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <View textAlign="center">
            <Heading
              level={3}
              style={{
                marginBottom: "15px",
                color: "#fff",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Reset Your Password
            </Heading>
          </View>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <View textAlign="center">
            <Heading
              level={3}
              style={{
                marginBottom: "15px",
                color: "#fff",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Reset Your Password
            </Heading>
            <p className="color-grey">
              Please check your email for the verification code
            </p>
          </View>
        );
      },
    },
  };
  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email",
      },
      password: {
        labelHidden: false,
        placeholder: "Enter Password",
        isRequired: true,
        label: "Password",
      },
    },
    signUp: {
      name: {
        labelHidden: false,
        placeholder: "Enter Your Name Here",
        isRequired: true,
        label: "Name",
        order: 1,
      },
      email: {
        labelHidden: false,
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email",
        order: 2,
      },
      password: {
        labelHidden: false,
        placeholder: "Enter Password",
        isRequired: true,
        label: "Password",
        order: 3,
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "Enter Confirm Password",
        isRequired: true,
        label: "Confirm Password",
        order: 4,
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        placeholder: "Enter your Confirmation Code",
        label: "Confirmation Code",
        isRequired: true,
        order: 1,
      },
      password: {
        labelHidden: false,
        placeholder: "Enter your New Password",
        isRequired: true,
        label: "New Password",
        order: 2,
      },
      confirm_password: {
        labelHidden: false,
        label: "Confirm Password",
        placeholder: "Confirm your New Password",
        isRequired: true,
        order: 3,
      },
    },
  };

  return (
    <>
      {route === "autoSignIn" ? (
        <>
          <Typography.Title
            level={2}
            style={{ color: "#fff", marginTop: "10px" }}
          >
            Processing your request...
          </Typography.Title>
          <p className="color-grey">
            Please wait while we are processing your request.....
          </p>
        </>
      ) : (
        <div style={{ textAlign: "left" }}>
          <Authenticator
            formFields={formFields}
            initialState={formType}
            components={components}
            usernameAttributes="email" /*loginMechanisms={['email']}*/
          />
        </div>
      )}
    </>
  );
};
