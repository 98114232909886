import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5-build-classic";
import { message, Spin } from "antd";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";
import { ckeApi } from "../../../../services/cke";

const CkFormData = ({ rowData, type, documentData }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [editorId, setEditorId] = useState(null);
  const [value, setValue] = useState({ isLayoutReady: false });
  const [loader, setLoader] = useState(false);

  const sidebarElementRef = useRef(null);
  const presenceListElementRef = useRef(null);

  useEffect(() => {
    try {
      setLoader(true);
      setEditorId(uuidv4());
      setValue({ isLayoutReady: true });
    } catch (e) {
      message.error(e);
    }
  }, [rowData]);

  return (
    <Spin spinning={loader}>
      <div className="app-container">
        <div
          ref={presenceListElementRef}
          className="presence"
          id="presence-list-container"
        />
        <div className="ckcontainer">
          {value.isLayoutReady && (
            <CKEditor
              id={editorId}
              editor={ClassicEditor}
              config={{
                licenseKey:
                  "OUB+xqxnAUFlIsYf4unF0KQ/zm5dgWG34LS4DidoxWP93W2YNo1RlBl/Rw==",
                cloudServices: {
                  tokenUrl: () =>
                    ckeApi(user?.attributes?.email, user?.attributes?.name),
                  webSocketUrl: "wss://94562.cke-cs.com/ws",
                  uploadUrl: "https://94562.cke-cs.com/easyimage/upload/",
                },
                collaboration: {
                  channelId: documentData?._id?.$oid.concat(
                    "_" + rowData?.index + "_" + type
                  ),
                },
                sidebar: {
                  container: sidebarElementRef.current,
                },
                presenceList: {
                  container: presenceListElementRef.current,
                  collapseAt: 3,
                },
              }}
              data=""
              onReady={(editor) => {
                setLoader(false);
                console.log("editor is ready now", editor);
                // You can store the "editor" and use when it is needed.
              }}
            />
          )}
          <div ref={sidebarElementRef} className="cksidebar" id="cksidebar" />
        </div>
      </div>
    </Spin>
  );
};

export default CkFormData;
