import React, { useState, useEffect } from "react";
import { FormWrap, FormWrapper } from "../../../shared/commonStyles";

import { Typography, Spin } from "antd";
import { getUnits, getSheetById } from "../../../services/dataSheet";
import FormBuilder from "./FormBuilder";

interface CustomFieldsProps {
  initialValues: DataSheet;
  loadingCreateDataSheet: boolean;
  isDuplicateMode: boolean;
  schema: SheetSchema[];
  onClickPrevious: () => void;
  onClickCreateSheet: (
    validationRules: any,
    sheetSchema: SheetSchema[]
  ) => Promise<void>;
  submitKey?: number;
}

const CustomFields = ({
  onClickCreateSheet,
  onClickPrevious,
  initialValues,
  loadingCreateDataSheet,
  isDuplicateMode,
  schema,
  submitKey,
}: CustomFieldsProps) => {
  const [measures, setMeasures] = useState([]);
  const [units, setUnits] = useState([]);
  const [metaDataEntityItems, setMetaDataEntityItems] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState<boolean>(false);
  const { sheet_type, is_reference_data } = initialValues;
  useEffect(() => {
    async function validateFrom() {
      setLoadingUnits(true);
      if (initialValues?.metadata_sheet_id) {
        const dataSheet = await getSheetById(initialValues?.metadata_sheet_id);
        setMetaDataEntityItems(dataSheet?.sheet_schema);
      }

      const listUnits = await getUnits();

      const _measures = listUnits?.reduce(
        (
          result: string[],
          unit: {
            display_name: string;
            measure: string;
            unit_id: string;
          }
        ) => {
          if (result.indexOf(unit.measure) === -1) result.push(unit.measure);
          return result;
        },
        []
      );
      setMeasures(_measures);
      setUnits(listUnits);
      setLoadingUnits(false);
    }

    validateFrom();
  }, []);

  return (
    <FormWrap
      width={"100%"}
      style={{
        padding: "0px",
        height: "90%",
      }}
    >
      <Spin spinning={loadingUnits}>
        <FormWrapper>
          <FormBuilder
            isEditMode={false}
            schema={isDuplicateMode ? initialValues.sheet_schema : schema}
            initialValidationRules={initialValues?.validationRules}
            units={units}
            measures={measures}
            metaDataEntityItems={metaDataEntityItems}
            loadingCreateDataSheet={loadingCreateDataSheet}
            submitKey={submitKey}
            onFinishHandler={onClickCreateSheet}
            onClickPrevious={onClickPrevious}
            isMetadata={is_reference_data}
          />
        </FormWrapper>
      </Spin>
    </FormWrap>
  );
};

export default CustomFields;
