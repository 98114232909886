import React from "react";
import { Typography } from "antd";
import { FormWrapper } from "../../../shared/commonStyles";
import { AssignUserAndGroups } from "../../../components/AssignUserAndGroups/AssignUserAndGroups";

interface AddUsersManuallyProps {
  usersList: UserInfo[];
  groupsList: UserGroupInfo[];
  setUsersList: (data: UserInfo[]) => void;
  setGroupsList: (data: UserGroupInfo[]) => void;
  emails: string[];
  setEmails: (data: string[]) => void;
  taskDeployments?: SurveyDeployment[];
}

interface UserGroupInfo extends UserGroup {
  assigned?: boolean;
  isGroup?: boolean;
}

interface UserInfo extends User {
  profileImage?: string;
  assigned?: boolean;
}

export const AddUsersManually = ({
  usersList,
  groupsList,
  setUsersList,
  setGroupsList,
  emails,
  setEmails,
  taskDeployments,
}: AddUsersManuallyProps) => {
  const handleChangeSelectionUser = (user: UserInfo, assigned: boolean) => {
    const email = user.Attributes.find((v) => v.Name === "email")?.Value;
    if (!taskDeployments?.find((v) => !v.assignedGroup && v.email === email)) {
      const newUsers = usersList.map((v) =>
        v.key === user.key ? { ...v, assigned } : v
      );
      setUsersList(newUsers);
    }
  };

  const handleChangeSelectionGroup = (
    group: UserGroupInfo,
    assigned: boolean
  ) => {
    const groupId = group._id.$oid;
    if (!taskDeployments?.find((v) => v.assignedGroup === groupId)) {
      const newGroups = groupsList.map((v) =>
        v._id.$oid === groupId ? { ...v, assigned } : v
      );
      setGroupsList(newGroups);
    }
  };

  return (
    <FormWrapper height={"100%"}>
      <Typography.Title level={3}>
        Select the users and groups you want to assign
      </Typography.Title>
      <AssignUserAndGroups
        usersList={usersList}
        groupsList={groupsList}
        emails={emails}
        handleChangeSelectionUser={handleChangeSelectionUser}
        handleChangeSelectionGroup={handleChangeSelectionGroup}
        setEmails={setEmails}
      />
    </FormWrapper>
  );
};
