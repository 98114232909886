import { API, graphqlOperation } from "aws-amplify";
import { datalake, emissionsCalculation } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { DatalakeQuery, EmissionsCalculationQuery } from "../graphql/API";

class CarbonService {
  async makeRequest(requestType: string, payload?: any) {
    const response = (await API.graphql(
      graphqlOperation(datalake, {
        request_type: requestType,
        ...(payload && { request_payload: JSON.stringify(payload) }),
      })
    )) as GraphQLResult<DatalakeQuery>;
    return JSON.parse(response.data?.datalake || "{}");
  }

  public async ghgFactorCategories(payload?: GHGCategoriesPayload) {
    return await this.makeRequest("ghg_factor_categories", payload);
  }

  public async ghgFactorCategoriesCustom(payload: GHGCategoriesPayload) {
    return await this.makeRequest("ghg_factor_categories_custom", payload);
  }

  public async ghgAggregatedFactors(isCustom: boolean, query: ListQuery) {
    return await this.makeRequest("list_aggregated_factors", {
      ...query,
      is_custom: isCustom,
    });
  }
  public async ghgFactors(isCustom: boolean, query: ListQuery) {
    return await this.makeRequest("ghg_factors", {
      ...query,
      is_custom: isCustom,
    });
  }

  public async removeUserGhgFactors(id: string) {
    return await this.makeRequest("remove_user_ghg_factors", {
      _id: { $oid: id },
    });
  }

  public async updateUserGhgFactors(factorPayload: FactorPayload) {
    return await this.makeRequest("update_user_ghg_factors", factorPayload);
  }

  public async addUserGhgFactors(factorPayload: FactorPayload) {
    return await this.makeRequest("add_user_ghg_factors", factorPayload);
  }

  public async listSheets(payload: ListQuery) {
    return await this.makeRequest("list_sheets", payload);
  }

  public async listFactorsUnits() {
    return await this.makeRequest("list_factors_units");
  }

  public async listFactorsRegions() {
    return await this.makeRequest("list_factors_regions");
  }
  public async getEmissionsFactor(id: string) {
    const response = (await API.graphql(
      graphqlOperation(emissionsCalculation, {
        request_type: "get_emissions_factor",
        ...(id && { request_payload: JSON.stringify({ id: id }) }),
      })
    )) as GraphQLResult<EmissionsCalculationQuery>;

    return JSON.parse(response?.data?.emissionsCalculation || "{}");
  }
}
export default new CarbonService();
