import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const getReportFilters = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_report_filters",
      request_payload: JSON.stringify(payload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const addReportFilters = async (requestPayload) => {
  
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  requestPayload.username = username
  requestPayload.group_id = group

  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_report_filters",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const updateReportFilters = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  requestPayload.username = username
  requestPayload.group_id = group

  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_report_filters",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const removeReportFilters = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "remove_report_filters",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const getReportingStatus = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_reporting_status",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};
