import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Checkbox,
} from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
  CommonSelect,
} from "../../../shared/commonStyles";
import { getDataSheets } from "../../../services/dataSheet";
import {
  getUniqueChartCategory,
  insertAnalyticsDashboardCharts,
} from "../../../services/analyticsChart";

const CreateNewDashboardModal = ({ isVisible, onClose, onFinish }) => {
  const [btnLoading, setBtnLoading] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [dataSheets, setDataSheets] = useState();
  const [finalArr, setFinalArr] = useState([]);
  const [metaData, setMetadata] = useState("");
  const items = [];
  const [sheetName, setSheetName] = useState();
  const [isCarbon, setCarbon] = useState(false);
  const [category, setCategory] = useState();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  /* calling api function */

  const loadAnalyticsData = async () => {
    setLoading(true);
    try {
      const cateResponse = await getUniqueChartCategory();
      setDataSource(cateResponse);
    } catch (error) {
      console.log("found some error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const chartCategory = dataSource?.data?.map((e) => e.section);
    setFinalArr([...new Set(chartCategory)]);
  }, [dataSource]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    const index = 1;
    setFinalArr([...items, ...finalArr, name || `New item ${index}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setCarbon(false);
    loadAnalyticsData();
    loadDataSheets();
  }, []);
  const handleMetaDataChange = (option) => {
    setMetadata(option);
  };
  const onChangeCategory = (option) => {
    setCategory(option);
  };
  const onChangeCarbon = (option) => {
    setCarbon(option.target.checked);
  };
  const loadDataSheets = async () => {
    // const payloadMeta = {
    //   filter: { is_reference_data: false },
    // };
    const dataSheets = await getDataSheets({});

    setDataSheets(dataSheets);
  };

  const onFinishHandler = async (data) => {
    onFinish && onFinish(data);
    setBtnLoading(true);
    const chart_category = data["category"];
    const chart_id = data["chartId"];
    const chart_name = data["chartName"];
    const datasheet_id = data["category"] === "Carbon" ? "" : data["datasheet"];
    const metadata_sheet_id =
      data["category"] === "Carbon" ? "" : data["metadata"];

    try {
      const payload = {
        section: chart_category,
        chart_name: chart_name,
        chart_id: chart_id,
        status: "ACTIVE",
        datasheet_id: datasheet_id,
        metadata_sheet_id: metadata_sheet_id,
        is_carbon: isCarbon,
      };

      const savedChartRecord = await insertAnalyticsDashboardCharts(payload);

      if (savedChartRecord) {
        setBtnLoading(false);
        message.success("Chart Added Successfully!");
        onClose();
        window.location.reload();
      } else {
        message.error("Failed to add Chart!");
      }
    } catch (error) {
      console.error(
        "Something went wrong while adding analytics chart!",
        error
      );
      message.error("Something went wrong while adding analytics chart!");
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <CommonModal
      destroyOnClose={true}
      width={600}
      title={"Add New Dashboard"}
      visible={isVisible}
      footer={null}
      onCancel={() => {
        onClose();
        setCarbon(false);
      }}
    >
      <Spin spinning={loading}>
        <FormWrapper>
          <Form
            form={form}
            name="basic"
            onFinish={onFinishHandler}
            autoComplete="off"
          >
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Dashboard Name</span>}
              name="chartName"
              rules={[
                {
                  required: true,
                  message: "Please input Dashboard name!",
                },
              ]}
            >
              <Input
                placeholder="Type in a name for the Dashboard Name"
                className="inner-color-input"
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Dashboard Id</span>}
              name="chartId"
              rules={[
                {
                  required: true,
                  message: "Please input Dashboard Id",
                },
              ]}
            >
              <Input
                placeholder="Type in a name for the Dashboard Id"
                className="inner-color-input"
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              label={<span className="color-white">Category</span>}
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please input category name!",
                },
              ]}
            >
              <Select
                className="testt"
                placeholder="Please select category"
                onChange={onChangeCategory}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        className="chartCustomCatInput"
                        placeholder="Enter name"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                      />
                      <Button
                        type="text"
                        onClick={addItem}
                        disabled={name === "" ? true : false}
                      >
                        Add category
                      </Button>
                    </Space>
                  </>
                )}
              >
                {finalArr.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="isCarbon">
              <Checkbox onChange={onChangeCarbon}>Carbon</Checkbox>
            </Form.Item>
            {!isCarbon && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={<span className="color-white">Meta data</span>}
                name="metadata"
              >
                <CommonSelect
                  showSearch
                  allowClear
                  showArrow
                  style={{ width: "100%" }}
                  placeholder="Select Metadata"
                  onChange={handleMetaDataChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={dataSheets
                    ?.filter((item) => item.is_reference_data)
                    .map((item) => ({
                      value: item._id?.$oid,
                      label: item.sheet_name,
                    }))}
                />
              </Form.Item>
            )}
            {!isCarbon && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={<span className="color-white">Data Sheet Name</span>}
                name="datasheet"
              >
                <CommonSelect
                  showSearch
                  allowClear
                  showArrow
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Sheet Name"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={dataSheets
                    ?.filter((item) =>
                      metaData
                        ? !item.is_reference_data &&
                          metaData === item.metadata_sheet_id
                        : !item.is_reference_data
                    )
                    ?.map((item) => ({
                      value: item._id?.$oid,
                      label: item.sheet_name,
                    }))}
                />
              </Form.Item>
            )}
            <Row justify="end">
              <StyledButton type="default" onClick={() => handleCancel()}>
                Cancel
              </StyledButton>
              <StyledButton
                type="default"
                loading={btnLoading}
                htmlType="submit"
              >
                Add
              </StyledButton>
            </Row>
          </Form>
        </FormWrapper>
      </Spin>
    </CommonModal>
  );
};

export default CreateNewDashboardModal;
