import React, { Dispatch, SetStateAction } from 'react';

import { observer } from "mobx-react-lite";

import { Input } from "antd";

interface CustomConversionFactorComponentProps {
  customConversionFactor: string;
  setCustomConversionFactor: Dispatch<SetStateAction<string>>;
  autoEmission: AutomatedEmission;
  setAutoEmission: Dispatch<SetStateAction<AutomatedEmission>>;
}
export const CustomConversionFactorComponent = ({customConversionFactor, setCustomConversionFactor, autoEmission,  setAutoEmission}: CustomConversionFactorComponentProps) => {
  //console.log('customConversioN: ' + customConversionFactor)
  const handleCustomConversionFactorInputChange = (e: any) => {
      const value = e.target.value;
      setCustomConversionFactor(value);
      let custom_unit_conversion_factor: number | undefined = undefined; // we set to this so that it doesn't get passed to json
      if (value != "") {
        const converted_number = Number(value);
        if (!isNaN(converted_number) && typeof converted_number === 'number') {
          custom_unit_conversion_factor = converted_number;
        }
      }
      setAutoEmission({
        ...autoEmission,
        factor_data: { ...autoEmission.factor_data, custom_unit_conversion_factor },
      } as AutomatedEmission);
    };
    let emission_library_unit = '';
    if (autoEmission?.factor_data?.unit && autoEmission?.factor_data?.unit !== "" && autoEmission?.factor_data?.unit.split('/').length > 1) { 
        emission_library_unit = autoEmission?.factor_data?.unit.split('/')[1].trim() 
    }

  return (
      <div style={{ paddingTop: '10px' }}>
        <div>Conversion Units do not match. (
        { (autoEmission.datasheet_unit && autoEmission.datasheet_unit !== "") ? autoEmission.datasheet_unit : "N/A" } 
        &nbsp;and&nbsp; 
        { (autoEmission?.factor_data?.unit && autoEmission?.factor_data?.unit !== "" && autoEmission?.factor_data?.unit.split('/').length > 1) 
          ? autoEmission?.factor_data?.unit.split('/')[1].trim() 
          : "N/A" 
        }).  Please enter a Custom Conversion Factor.</div>

        <div style={{ display: 'flex'}}>
          <div style={{ flex: '0 1 auto', lineHeight: '31.6px' }}>
            1 { autoEmission.datasheet_unit } = &nbsp;
          </div>

          <div style={{ flex: '1' }}>
          <Input
            id="custom_unit_conversion_factor_input"
            value={(customConversionFactor !== undefined) ? customConversionFactor : ''}
            onChange={handleCustomConversionFactorInputChange}
            placeholder="Enter A Custom Conversion Factor..."
          />
          </div>
          { (emission_library_unit !== '')  && (
          <div style={{ flex: '0 1 auto', lineHeight:'31.6px' }}>
            &nbsp;{emission_library_unit}
          </div>
          )}
        </div>
        { (customConversionFactor.trim() === "" || isNaN(Number(customConversionFactor))) && (
          <span style={{ color: 'red' }}>Please Enter A Valid Custom Conversion Factor.</span>
        )}
      </div>
  )
};