import React from "react";
import { StyledButton, FormWrap } from "../../../shared/commonStyles";
import { Form, Row, Col, Typography, Divider } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import StandardsTable from "../../createStandardsSurvey/components/StandardsTable";

const { Title } = Typography;

const StandardsSelection = ({
  onClickNext,
  onClickPrevious,
  rowSelection,
  loadingDataLakeMap,
  dataLakeMapsList,
  listAllSurveys,
}) => {
  return (
    <FormWrap width="90%">
      <>
        <Row justify="space-between">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3} className="color-white">
              Standards selection
            </Title>
            <Title level={4} className="color-white">
              Select the standards required for the current data collection
              survey
            </Title>
            <Divider
              style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
            />
            <Form.Item
              labelCol={{ span: 24 }}
              name="selectedStandards"
              label={<span className="color-white">Standards</span>}
              rules={[{ required: true, message: "Please select standard!" }]}
            >
              <StandardsTable
                loading={loadingDataLakeMap}
                dataLakeMapsList={dataLakeMapsList}
                rowSelection={rowSelection}
                listAllSurveys={listAllSurveys}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="flex-start">
                <StyledButton
                  className="margin-5"
                  onClick={onClickPrevious}
                  type="custom"
                >
                  <LeftOutlined /> <span>Previous</span>
                </StyledButton>

                <StyledButton
                  className="margin-5"
                  onClick={onClickNext}
                  type="custom"
                >
                  <span>Continue to step 4</span> <RightOutlined />
                </StyledButton>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </>
    </FormWrap>
  );
};

export default StandardsSelection;
