import React, { useState } from "react";
import { message, Typography, Col, Row, Divider, Space } from "antd";
import { StyledButton } from "../../../../shared/commonStyles";
import CkFormData from "./CkFormData";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const DataForm = ({
  rowData,
  currentStep,
  dataSource,
  documentData,
  onClickPrevious,
  onClickNext,
}) => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmit = async () => {
    try {
      setSubmitting(true);
      if (dataSource.length - 1 !== currentStep) {
        onClickNext();
      } else {
        navigate("/reporting/document-review");
      }
    } catch (error) {
      message.error("Error while saving form data. Please contact support.");
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {rowData?.sectionName && (
        <>
          <Title
            className="color-white"
            style={{ textAlign: "center" }}
            level={3}
          >
            {rowData?.sectionName}
          </Title>
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
        </>
      )}
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <CkFormData
            data=""
            rowData={rowData}
            documentData={documentData}
            type="data"
          />
          <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Space>
              {currentStep >= 0 && (
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  className="PrevButton"
                  disabled={currentStep == 0 ? true : false}
                  onClick={() => onClickPrevious()}
                >
                  Previous Step
                </StyledButton>
              )}
              <StyledButton
                type="custom"
                onClick={() => onClickSubmit()}
              >
                {dataSource.length - 1 !== currentStep
                  ? "Save & Go to the next step"
                  : "Submit and Complete Document Review"}
              </StyledButton>
              {dataSource.length - 1 !== currentStep && (
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  onClick={() => onClickNext()}
                >
                  Skip and Go to the next Step
                </StyledButton>
              )}
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DataForm;
