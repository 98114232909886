import { GraphQLResult } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

import { API } from "aws-amplify";
import { DatalakeQuery } from "../graphql/API";

export const getSelectedStandard = async (requestPayload: any) => {
    const result = await (API.graphql({
        query: queries.datalake,
        variables: {
            request_type: "get_selected_standards",
            request_payload: JSON.stringify(requestPayload),
        },
    }) as Promise<GraphQLResult<DatalakeQuery>>);
    return JSON.parse(result.data?.datalake || "[]") as any;
};

export const getUniqueFrameworks = async () => {
    const result = await (API.graphql({
        query: queries.datalake,
        variables: {
            request_type: "get_unique_frameworks",
        },
    }) as Promise<GraphQLResult<DatalakeQuery>>);
    return result.data?.datalake || "[]" as any;
};

export const updateSelectedStandard = async (requestPayload: any) => {
    const result = await (API.graphql({
        query: queries.datalake,
        variables: {
            request_type: "update_selected_standards",
            request_payload: JSON.stringify(requestPayload),
        },
    }) as Promise<GraphQLResult<DatalakeQuery>>);
    return JSON.parse(result.data?.datalake || "[]") as any;
};