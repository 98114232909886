import {
  CommonTable,
  StyledButton,
  StyledTag,
} from "../../../../shared/commonStyles";
import React, { useContext, useEffect, useState } from "react";
import { Space, message } from "antd";
import {
  getDistinctCategories,
  getDistinctIndustries,
  getDistinctThemes,
  getUniqueFrameworks,
} from "../../../../../src/shared/commonFunctions";
import {
  listSelectedStandards,
  parseStandardDataForAudits,
  parsedStandardData,
} from "../../../../services/standards";

import ApproveStatus from "./ApproveStatus";
import { EyeOutlined } from "@ant-design/icons";
import { ReportingContext } from "../../../Reporting/ReportingProvider";
import SearchableColumn from "../../../../components/SearchableColumn";
import { SignOffCircleProgress } from "../../../../components/ReportingData/SignOffSection/SignOffCircleProgress";
import { useNavigate } from "react-router-dom";
import { encodeUrlName } from "../../../../shared/commonFunctions";

const StandardAudit = ({ auditRecord }) => {
  const { businessUnitsId, orgLocationId, reportPeriodId } =
    useContext(ReportingContext);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);
  const [standardFilter, setStandardFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [themeFilter, setThemeFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [codeFilter, setCodeFilter] = useState([]);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const [refreshAuditStandards, setRefreshAuditStandards] = useState(1);
  const handleChange = (pagination, filters, sorter) => {
    // table properties when onChange method run on table
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const onFinishFormSubmit = () => {
    setRefreshAuditStandards(refreshAuditStandards + 1);
  };
  const loadData = async (standardIds) => {
    try {
      setLoading(true);
      const filter = {
        _id: { $oid: standardIds },
        ...(businessUnitsId && { business_unit: businessUnitsId }),
        ...(orgLocationId && { location_id: orgLocationId }),
        ...(reportPeriodId && { report_period: reportPeriodId }),
        status: "ACTIVE",
      };
      const payload = {
        filter,
      };
      const standardRecords = await listSelectedStandards(payload);

      const data = standardRecords?.data ?? [];

      const parsedData = await Promise.all(
        (data ?? []).map(async (item) => {
          const result = await parseStandardDataForAudits(item);

          return result.name || result.standard_code
            ? { ...result, specific_standard: result.standard_code }
            : {
                ...result,
                name: result?.name ?? "",
                standard_code: result.specific_standard,
                tags: {
                  industry: result?.tags?.industry ?? "",
                  category: result?.tags?.category ?? "",
                  theme: result?.tags?.theme ?? "",
                },
              };
        })
      );
      setDataSource(parsedData);

      const categoryFilter = getDistinctCategories(data);

      setCategoryFilter(categoryFilter);

      const filterStandard = getUniqueFrameworks(data);

      setStandardFilter(filterStandard);

      const filterIndustry = getDistinctIndustries(data);

      setIndustryFilter(filterIndustry);

      // TODO: check what we need to do for specific_standard
      const filterCode = parsedData.reduce((arr, val) => {
        const { specific_standard } = val;
        if (!arr.includes(specific_standard)) {
          arr.push(specific_standard);
        }
        return arr;
      }, []);

      setCodeFilter(filterCode);

      const themeFilter = getDistinctThemes(data);

      setThemeFilter(themeFilter);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auditRecord?.audit_items) {
      const standardIds = JSON.parse(auditRecord?.audit_items);
      loadData(standardIds);
    }
  }, [
    auditRecord,
    refreshAuditStandards,
    businessUnitsId,
    reportPeriodId,
    orgLocationId,
  ]);

  const columns = [
    SearchableColumn({
      title: "Name",
      dataIndex: "name",
      width: 200,
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend"],
      searchPlaceHolder: "Search Metric",
      filteredValue: tableFilters.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
    }),
    {
      title: "Description",
      dataIndex: "description",
      width: 200,
    },
    {
      filteredValue: tableFilters.filteredInfo?.standards
        ? tableFilters.filteredInfo.standards
        : null,
      title: "Standard",
      dataIndex: "standards",
      width: 200,
      render: (record, data) =>
        record?.map((a, i) => (
          <StyledTag className="margin-2" key={data.id + i}>
            {a}
          </StyledTag>
        )),
      filters: standardFilter.map((fil) =>
        fil !== "" ? { text: fil, value: fil } : { text: "None", value: "" }
      ),
      onFilter: (value, record) => record?.standards?.includes(value),
      sortOrder:
        tableFilters.sortedInfo?.field === "standards" &&
        tableFilters.sortedInfo?.order,
    },
    {
      // TODO: need to fix this according to backend schema
      filteredValue: tableFilters.filteredInfo?.specific_standard
        ? tableFilters.filteredInfo.specific_standard
        : null,
      title: "Code",
      dataIndex: "specific_standard",
      width: 200,
      sorter: (a, b) => b.metric.localeCompare(a.metric),
      sortDirections: ["descend", "ascend"],
      filters: codeFilter.map((fil) => ({ text: fil, value: fil })),
      onFilter: (value, record) =>
        record?.specific_standard?.indexOf(value) === 0,
      sortOrder:
        tableFilters.sortedInfo?.field === "specific_standard" &&
        tableFilters.sortedInfo?.order,
    },
    {
      title: "Progress",
      dataIndex: "progress",
      width: 150,
      render: (_, record) => {
        return record && <SignOffCircleProgress rowData={record} />;
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space direction="horizontal">
          <StyledButton type="default" onClick={() => onEditRecord(record)}>
            <EyeOutlined />
          </StyledButton>
          <ApproveStatus
            rowData={record}
            onFinishFormSubmit={onFinishFormSubmit}
            refreshUploadedFiles={refreshUploadedFiles}
            setRefreshUploadedFiles={setRefreshUploadedFiles}
          />
        </Space>
      ),
    },
  ];

  const onEditRecord = (record) => {
    const standardId = record?._id.$oid;
    if (standardId) {
      navigate(
        `/audit/audit-standards/${auditRecord?.id}/${encodeUrlName(
          auditRecord?.name
        )}/${standardId}/detail`
      );
    }
  };

  return (
    <CommonTable
      loading={loading}
      rowKey={(data) => {
        return data?.["_id"]?.["$oid"];
      }}
      dataSource={dataSource}
      columns={columns}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
      }}
      scroll={{ x: true }}
      onChange={handleChange}
      style={{ marginTop: "30px" }}
    />
  );
};

export default StandardAudit;
