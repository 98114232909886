import React, { useEffect, useRef } from "react";
import { Viewer } from "@grapecity/activereports-react";
import { Rdl as ARJS } from "@grapecity/activereports/core";
import { staticImagesList } from "../../../shared/constants";
import { CommonModal } from "../../../shared/commonStyles";

const chartBg = require("../../../shared/Images/chartBackground.png");

interface ExportKPIModalProps {
  visible: boolean;
  hideModal: () => void;
  kpiList: any;
  sheetName: string;
}

export const ExportKPIModal = ({
  visible,
  hideModal,
  kpiList,
  sheetName,
}: ExportKPIModalProps) => {
  const viewerRef = useRef<Viewer>(null);
  const kpisData = kpiList.map((item: any, index: number) => {
    const analyticColumn = `${item.aggregateColumn}_${item.aggregateOp}`;
    const analyticValue =
      typeof item.analyticResult[analyticColumn] === "object"
        ? "NA"
        : item.analyticResult[analyticColumn];
    return {
      index: index + 1,
      name: item.name,
      analyticColumn: analyticColumn,
      analyticValue: analyticValue,
      description: item.description,
      aggregateOpLabel: item.aggregateOpLabel,
      dataSheetName: item.dataSheetName,
      dataSheetType: item.dataSheetType,
      aggregateColumnLabel: item.aggregateColumnLabel,
      percentValue: 100,
    };
  });

  const kpiTableStructure = [
    { name: "#", value: "index", length: 5 },
    { name: "Name", value: "name", length: 17 },
    { name: "Value", value: "analyticValue", length: 15 },
    { name: "Description", value: "description", length: 15 },
    { name: "Datasheet field", value: "aggregateColumnLabel", length: 10 },
    { name: "Aggregation operator", value: "aggregateOpLabel", length: 13 },
    { name: "Datasheet name", value: "dataSheetName", length: 15 },
    { name: "Datasheet type", value: "dataSheetType", length: 10 },
  ];

  const generateReport = async () => {
    const dataRows = () => {
      const row = [];
      for (let i = 0; i < kpisData.length; i += 3) {
        const cell = [];
        cell.push(kpisData[i]);
        if (kpisData[i + 1] !== undefined) cell.push(kpisData[i + 1]);
        if (kpisData[i + 2] !== undefined) cell.push(kpisData[i + 2]);
        row.push(cell);
      }
      return row;
    };
    const getChartTables = (dataSet: string, item: any) => {
      const chartTable: ARJS.Table = {
        Name: "chartTable",
        Type: "table",
        DataSetName: dataSet,
        Width: `${7.5 / 3}in`,
        TableColumns: [
          {
            Width: `${7.5 / 3}in`,
          },
        ],
        Header: {
          TableRows: [
            {
              Height: `${7.5 / 3}in`,
              TableCells: [
                {
                  Item: {
                    Type: "textbox",
                    Name: "kpiValue",
                    Value: `${item.analyticValue}`,
                    CanGrow: true,
                    ZIndex: 0,
                    Style: {
                      FontWeight: "Bold",
                      FontSize: "14pt",
                      VerticalAlign: "middle",
                      TextAlign: "center",
                      Color: "#fff",
                    },
                  },
                },
                {
                  Item: {
                    Type: "image",
                    Name: "kpiValue",
                    ZIndex: 1,
                    Value: chartBg,
                    Source: "External",
                    MIMEType: "image/png",
                    Sizing: "FitProportional",
                    Width: "170pt",
                    Height: "170pt",
                    HorizontalAlignment: "center",
                    VerticalAlignment: "center",
                    Style: {
                      PaddingBottom: "5pt",
                      PaddingTop: "5pt",
                      PaddingLeft: "5pt",
                      PaddingRight: "5pt",
                    },
                  },
                },
              ],
            },
          ],
        },
        Footer: {
          TableRows: [
            {
              Height: "20pt",
              TableCells: [
                {
                  Item: {
                    Type: "textbox",
                    Name: "kpiName",
                    Value: `${item.name}`,
                    CanGrow: true,
                    Style: {
                      FontWeight: "Bold",
                      FontSize: "12pt",
                      VerticalAlign: "middle",
                      TextAlign: "center",
                      Color: "#fff",
                      PaddingBottom: "15pt",
                    },
                  },
                },
              ],
            },
          ],
        },
      };
      return chartTable;
    };
    const getChartsKpiTable = (rowIndex: number) => {
      const chartsKpiTable: ARJS.Table = {
        Name: "KpiChartTable",
        Type: "table",
        DataSetName: "DataSetKPI",
        Left: "0.5in",
        Width: "7.5in",
        TableColumns: [
          {
            Width: `${7.5 / 3}in`,
          },
          {
            Width: `${7.5 / 3}in`,
          },
          {
            Width: `${7.5 / 3}in`,
          },
        ],
      };
      chartsKpiTable.Header = {
        TableRows: [
          {
            Height: `${7.5 / 3}in`,
            TableCells: dataRows()[rowIndex].map((v: any) => {
              return {
                Item: getChartTables(`DataSetChartKPI_${v.index}`, v),
              };
            }),
          },
        ],
      };
      return chartsKpiTable;
    };
    const kpiTable: ARJS.Table = {
      Name: "KpiTable",
      Type: "table",
      DataSetName: "DataSetKPI",
      Left: "0.5in",
      TableColumns: kpiTableStructure.map((f) => ({
        Width: `${(7.5 * f.length) / 100}in`,
      })),
      Header: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: kpiTableStructure.map((f) => ({
              Item: {
                Type: "textbox",
                Name: `kpi_header_${f.value}`,
                Value: f.name,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  Color: "#fff",
                  VerticalAlign: "middle",
                  FontWeight: "Bold",
                  FontSize: "8pt",
                  TextAlign: "left",
                  PaddingTop: "6pt",
                  PaddingLeft: "6pt",
                  PaddingRight: "6pt",
                  PaddingBottom: "6pt",
                  BackgroundColor: "#35294A",
                  BottomBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  TopBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  LeftBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "index" ? "1pt" : "0pt",
                  },
                  RightBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "dataSheetType" ? "1pt" : "0pt",
                  },
                },
              },
            })),
          },
        ],
      },
      Details: {
        TableRows: [
          {
            Height: "15pt",
            TableCells: kpiTableStructure.map((f) => ({
              Item: {
                Type: "textbox",
                Name: `kpi_value_${f.value}`,
                Value: `=Fields!${f.value}.Value`,
                CanGrow: true,
                KeepTogether: true,
                Style: {
                  VerticalAlign: "middle",
                  TextAlign: "left",
                  PaddingTop: "6pt",
                  PaddingLeft: "6pt",
                  PaddingRight: "6pt",
                  PaddingBottom: "6pt",
                  FontSize: "8pt",
                  FontWeight: f.value === "name" ? "Medium" : "Normal",
                  Color: "#fff",
                  BackgroundColor: "#42385F",
                  BottomBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  TopBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: "1pt",
                  },
                  LeftBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "index" ? "1pt" : "0pt",
                  },
                  RightBorder: {
                    Color: "#504769",
                    Style: "solid",
                    Width: f.value === "dataSheetType" ? "1pt" : "0pt",
                  },
                },
              },
            })),
          },
        ],
      },
    };
    const report: ARJS.Report = {
      Name: "report.rdlx",
      Width: "8.5in",
      Page: {
        PageWidth: "8.5in",
        PageHeight: "11.7in",
      },
      DataSources: [
        {
          Name: "DataSourceKPI",
          ConnectionProperties: {
            DataProvider: "JSONEMBED",
            ConnectString: "jsondata=" + JSON.stringify(kpisData),
          },
        },
        kpisData.map((item: any) => {
          return {
            Name: `DataSourceChartKPI_${item.index}`,
            ConnectionProperties: {
              DataProvider: "JSONEMBED",
              ConnectString: "jsondata=" + JSON.stringify([item]),
            },
          };
        }),
      ].flat(),
      DataSets: [
        {
          Name: "DataSetKPI",
          Query: {
            CommandText: "$.*",
            DataSourceName: "DataSourceKPI",
          },
          Fields: kpiTableStructure.map((v: any) => {
            return {
              Name: v.value,
              DataField: v.value,
            };
          }),
        },
        kpisData.map((item: any) => {
          return {
            Name: `DataSetChartKPI_${item.index}`,
            Query: {
              CommandText: "$.*",
              DataSourceName: `DataSourceChartKPI_${item.index}`,
            },
            Fields: kpiTableStructure.map((v: any) => {
              return {
                Name: v.value,
                DataField: v.value,
              };
            }),
          };
        }),
      ].flat(),
      Body: {
        Style: {
          BackgroundColor: "#342A48",
        },
        ReportItems: [
          dataRows().map((v: any, index: number) => getChartsKpiTable(index)),
          kpiTable,
        ].flat(),
      },
      PageHeader: {
        Height: "100pt",
        ReportItems: [
          {
            Type: "textbox",
            Name: "textbox_report_name",
            Value: `${sheetName}`,
            CanGrow: true,
            Style: {
              FontSize: "16pt",
              FontWeight: "bold",
              Color: "#fff",
              VerticalAlign: "middle",
              TextAlign: "center",
              PaddingTop: "35pt",
              PaddingBottom: "50pt",
            },
            Top: "0pt",
            Width: "8.5in",
            Height: "100pt",
          },
        ],
        Style: {
          BackgroundColor: "#342A48",
        },
      },
      PageFooter: {
        Height: "0.6in",
        ReportItems: [
          {
            Type: "rectangle",
            ReportItems: [
              {
                Type: "image",
                Sizing: "FitProportional",
                Source: "External",
                Value: staticImagesList.hydrusIconWithLetterLogo,
                Name: "Image2",
                Top: "0in",
                Left: "6.5in",
                Width: "1.5in",
                Height: "0.6in",
              },
            ],
            Name: "Container1",
            Width: "8.3in",
            Height: "0.6in",
            KeepTogether: true,
          },
        ],
        Style: {
          BackgroundColor: "#342A48",
        },
      },
    };
    return report;
  };

  const loadReport = () => {
    viewerRef.current?.open("report", {
      ResourceLocator: {
        getResource: () => {
          return generateReport() as any;
        },
      },
    });
  };

  useEffect(() => {
    loadReport();
  }, [visible]);

  const exportsSettings = {
    pdf: {
      title: sheetName,
      filename: sheetName,
      printing: "none",
      pdfVersion: "1.7",
      autoPrint: true,
    },
  };
  return (
    <CommonModal
      centered
      title={"Export"}
      visible={visible}
      onCancel={hideModal}
      footer={null}
      width={"70%"}
    >
      <div id="viewer-host">
        <Viewer
          ref={viewerRef}
          exportsSettings={exportsSettings}
          availableExports={["pdf"]}
        />
      </div>
    </CommonModal>
  );
};
