import React, { useState, useEffect } from "react";
import { Form, Input, message, Row, Slider, Select } from "antd";
import {
  FormWrapper,
  StyledButton,
  CommonSelect,
} from "../../../../shared/commonStyles";
import { updateTaskDeployment } from "../../../../services/taskDeployment";
import moment from "moment";
import { getUserGroups } from "../../../../services/users";
const { Option } = Select;

export const TaskDeploymentForm = ({
  onCloseModal,
  surveyId,
  rowData,
  onRefreshAllDeploySurveyData,
}) => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    description: "",
    status: "Contacted",
    priority: 1,
    last_contacted: moment(new Date()).format("YYYY-MM-DD"),
    completion_date: "1900-01-01",
  });
  const [form] = Form.useForm();
  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };

  const [loaderSubmitingData, setLoaderSubmitingData] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  const onClickCancel = () => {
    onCloseModal();
  };

  useEffect(() => {
    if (rowData) {
      const initData = {
        assignedGroup: rowData?.assignedGroup,
        email: rowData?.email,
        description: rowData?.description,
        status: rowData?.status,
        priority: rowData?.priority,
      };
      form.setFieldsValue(initData);
      setInitialValues(initData);
      getGroupsOptions();
    }
  }, [rowData]);

  const getGroupsOptions = async () => {
    if (rowData?.assignedGroup) {
      const groups = await getUserGroups();
      const newGroups = groups.map((item) => ({
        label: item.name,
        value: item._id.$oid,
      }));
      setUserGroups(newGroups);
    }
  };

  const onFormSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoaderSubmitingData(true);
          const payload = { ...values, surveyManagementID: surveyId };
          let _completion_date = "1900-01-01";
          if (values?.status === "Completed") {
            _completion_date = moment(new Date()).format("YYYY-MM-DD");
          }
          if (rowData.assignedGroup) {
            payload.email = rowData.email;
          }
          payload.id = rowData.id;
          payload.completion_date = _completion_date;
          const response = await updateTaskDeployment(payload);
          if (response) {
            onRefreshAllDeploySurveyData();
            message.success("Task deployment updated successfully.");
            onCloseModal();
          }
        } catch (error) {
          message.error("Login Error!");
          console.log("Error while submitting data!", error);
        } finally {
          setLoaderSubmitingData(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <FormWrapper>
      <Form form={form} initialValues={initialValues}>
        {rowData.assignedGroup ? (
          <Form.Item
            name={"assignedGroup"}
            label="Assigned Group"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please select the group",
              },
            ]}
          >
            <Select
              showSearch
              loading={!userGroups?.length}
              options={userGroups}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        ) : (
          <Form.Item
            name={"email"}
            label="Email Address"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input placeholder="Enter Email Address" />
          </Form.Item>
        )}
        <Form.Item
          name={"description"}
          label="Description"
          labelCol={{ span: 24 }}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          name={"priority"}
          label="Select Priority"
          labelCol={{ span: 24 }}
        >
          <Slider marks={marks} step={1} min={1} max={5} reverse={true} />
        </Form.Item>
        <Form.Item
          name={"status"}
          label="Status"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "Please choose a status." }]}
        >
          <CommonSelect
            allowClear
            showArrow
            style={{ width: "100%" }}
            placeholder="Select Status"
          >
            <Option key={1} value="Contacted">
              Contacted
            </Option>
            <Option key={2} value="In Progress">
              In Progress
            </Option>
            <Option key={3} value="Action required">
              Action Required
            </Option>
            <Option key={4} value="Pending">
              Pending
            </Option>
            <Option key={5} value="Completed">
              Completed
            </Option>
          </CommonSelect>
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <StyledButton
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              className="margin-5"
              type="default"
              onClick={() => onClickCancel()}
            >
              <span>Cancel</span>
            </StyledButton>
            <StyledButton
              onClick={onFormSubmit}
              loading={loaderSubmitingData}
              disabled={!initialValues?.email && !initialValues.assignedGroup}
              type="custom"
            >
              Submit
            </StyledButton>
          </Row>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};
