import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Form,
  message,
} from "antd";
import {
  CommonModal,
  FormWrapper,
  StyledSteps,
  StyledTitle,
} from "../../../shared/commonStyles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { ClickedCheckBoxGroup } from "./ClickedCheckBoxGroup";
import _ from "lodash";
import { carbonStore } from "../../Carbon";
import { constituentGasesData } from "./CustomFactorsUtils";
import moment from "moment";
import { observer } from "mobx-react-lite";
import styles from "./EditCustomFactor.module.scss";
import { toJS } from "mobx";
import { useSetState } from "ahooks";

const { Step } = Steps;

export interface CustomFactorData extends Omit<Factor, "constituent_gases"> {
  constituent_gases:
    | {
        value: string;
        label: string;
        checked: boolean;
        inputValue: number;
      }[]
    | FormattedGases;
}

type FormattedGases = {
  [gas: string]: number;
};

interface EditCustomFactorProps {
  title: string;
  initialData: Factor;
  onSave: () => void;
  onCancel: () => void;
}

export const EditCustomFactor = observer(
  ({ title, initialData, onSave, onCancel }: EditCustomFactorProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [customFactorData, setCustomFactorData] =
      useSetState<CustomFactorData>({} as CustomFactorData);
    const [categoriesData, setCategoriesData] = useSetState<IGHGCategories>({
      region_name: carbonStore.regions.map((item) => item.region_name),
    } as IGHGCategories);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const defaultSourceProtocol = initialData.source_link
      ? initialData.source_link.includes("https")
        ? "https://"
        : "http://"
      : "https://";
    const [sourceProtocol, setSourceProtocol] = useState<
      "https://" | "http://"
    >(defaultSourceProtocol);
    const changeInitialData = () => {
      setCustomFactorData({
        ...initialData,
        name: initialData?.name,
        constituent_gases: constituentGasesData.map((v) =>
          Array.isArray(initialData.constituent_gases)
            ? initialData.constituent_gases?.find(
                (item) => item.value === v.value
              ) || {
                ...v,
                checked: false,
                inputValue: 0,
              }
            : {
                inputValue:
                  initialData.constituent_gases?.[
                    v.value as keyof Factor["constituent_gases"]
                  ] || 0,
                value: v.value,
                label: v.label,
                checked: !!Object.keys(
                  initialData.constituent_gases || {}
                )?.find(
                  (key) =>
                    key === (v.value as keyof Factor["constituent_gases"])
                ),
              }
        ),
        year: initialData.year || moment().format("YYYY").toLocaleString(),
        source_link: initialData.source_link
          ? initialData.source_link.replace(
              /\b(?:http:\/\/|https:\/\/)\b/gi,
              ""
            )
          : "",
      });
    };

    useEffect(() => {
      changeInitialData();
    }, [initialData]);

    useEffect(() => {
      carbonStore.resetCategories();
      setCategoriesData(toJS(carbonStore.initialGhgCategories));
      carbonStore.fetchUnits();
      carbonStore.fetchRegions();
      carbonStore.fetchCategories();
    }, []);

    useEffect(() => {
      setCategoriesData({
        region_name: carbonStore.regions.map((item) => item.region_name),
        source: carbonStore.ghgCategories.source,
      });
    }, [carbonStore.regions]);

    const handleChangeCustomFactorsData = (key: string, value: string) => {
      setCustomFactorData({
        ...customFactorData,
        [key]: value,
      });
    };

    const handleChangeSector = (value: string, key: string) => {
      setCustomFactorData({
        ...customFactorData,
        [key]: value,
      });
      carbonStore.fetchCategories({ sector: [value] }, "sector");
    };

    const handleChangeConstituentGasesData = (data: any) => {
      setCustomFactorData({ constituent_gases: data });
    };

    const handleSaveData = () => {
      form
        .validateFields()
        .then(() => {
          // make sure factor value is a number not a string
          let factor_value = Number(0);
          if (!isNaN(Number(customFactorData.factor))) {
            factor_value = Number(customFactorData.factor)
          }
          setCustomFactorData({
            ...customFactorData,
            factor: factor_value,
          });
          const newCustomFactorsData = { ...customFactorData };
          let constituent_gases = {} as Factor["constituent_gases"];
          Array.isArray(customFactorData.constituent_gases) &&
            customFactorData.constituent_gases.forEach((v) => {
              if (v.checked) {
                constituent_gases[
                  v.value as keyof Factor["constituent_gases"]
                ] = v.inputValue;
              }
            });
          const payload = {
            ...initialData,
            ...newCustomFactorsData,
            constituent_gases,
            unit_type: toJS(
              carbonStore.units.filter(
                (item) => item.unit === newCustomFactorsData.unit
              )[0]?.unit_type || newCustomFactorsData.unit_type
            ),
            region: toJS(
              carbonStore.regions.filter(
                (item) => item.region_name === newCustomFactorsData.region_name
              )[0]?.region || newCustomFactorsData.region
            ),
            source_link:
              customFactorData?.source_link &&
              sourceProtocol.concat(customFactorData.source_link),
          } as Factor;

          carbonStore.saveCustomFactor(payload);
          onSave();
        })
        .catch(() => {
          message.error("Required fields are missing");
        });
    };

    const steps = [
      {
        number: "1",
        content: (
          <>
            <Form form={form} fields={[
              {
                name: ["name"],
                value: customFactorData.name ? customFactorData.name : '',
              },
            ]}
            >
              <Space direction="vertical" className={styles.stepContent}>
                <StyledTitle level={3}>Basic Information</StyledTitle>
                <Space direction="vertical" className={styles.customBlock}>
                  <div>Name</div>
                  <Form.Item
                      name="name"
                      rules={[{ required: true, message: "Name is required" }]}
                    >
                      <Input
                        placeholder="Type in a name for the factor"
                        onChange={(e) =>
                          handleChangeCustomFactorsData("name", e.target.value)
                        }
                      />
                    </Form.Item>
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  <div className={styles.customName}>Description</div>
                  <Input.TextArea
                    placeholder="Provide a short description of what this emissions factor is calculating"
                    value={customFactorData.description}
                    onChange={(e) =>
                      handleChangeCustomFactorsData(
                        "description",
                        e.target.value
                      )
                    }
                  />
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  {customFactorData?.year ? (
                    <DatePicker
                      placeholder="Emission Year"
                      allowClear={false}
                      picker="year"
                      className={styles.customField}
                      value={moment(customFactorData.year)}
                      onChange={(value) =>
                        handleChangeCustomFactorsData(
                          "year",
                          value?.format("YYYY").toLocaleString() || ""
                        )
                      }
                    />
                  ) : (
                    <Form.Item
                      name="year"
                      rules={[{ required: true, message: "Year is required" }]}
                    >
                      <DatePicker
                        placeholder="Emission Year"
                        allowClear={false}
                        picker="year"
                        className={styles.customField}
                        value={moment(customFactorData.year)}
                        onChange={(value) =>
                          handleChangeCustomFactorsData(
                            "year",
                            value?.format("YYYY").toLocaleString() || ""
                          )
                        }
                      />
                    </Form.Item>
                  )}
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  {customFactorData?.region_name ? (
                    <Select
                      showSearch
                      placeholder="Select region"
                      className={styles.customField}
                      value={customFactorData.region_name}
                      onChange={(value) =>
                        handleChangeCustomFactorsData("region_name", value)
                      }
                    >
                      {categoriesData.region_name?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Form.Item
                      name="region_name"
                      rules={[
                        { required: true, message: "Region is required" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select region"
                        className={styles.customField}
                        value={customFactorData.region_name}
                        onChange={(value) =>
                          handleChangeCustomFactorsData("region_name", value)
                        }
                      >
                        {categoriesData.region_name?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Space>
                <Divider className={styles.customDivider} />
                <StyledTitle level={3}>Categorization</StyledTitle>
                <Space direction="vertical" className={styles.customBlock}>
                  {customFactorData?.sector ? (
                    <Select
                      showSearch
                      placeholder="Select a Sector"
                      className={styles.customField}
                      value={customFactorData.sector}
                      onChange={(value) => handleChangeSector(value, "sector")}
                    >
                      {carbonStore.ghgCategories.sector?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Form.Item
                      name="sector"
                      rules={[
                        { required: true, message: "Sector is required" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Sector"
                        className={styles.customField}
                        value={customFactorData.sector}
                        onChange={(value) =>
                          handleChangeSector(value, "sector")
                        }
                      >
                        {carbonStore.ghgCategories.sector?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  {customFactorData?.category ? (
                    <Select
                      showSearch
                      className={styles.customField}
                      placeholder="Factor Category"
                      value={customFactorData.category}
                      onChange={(value) =>
                        handleChangeCustomFactorsData("category", value)
                      }
                    >
                      {carbonStore.ghgCategories.category?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Form.Item
                      name="factor"
                      rules={[
                        {
                          required: true,
                          message: "Factor Category is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        className={styles.customField}
                        placeholder="Factor Category"
                        value={customFactorData.category}
                        onChange={(value) =>
                          handleChangeCustomFactorsData("category", value)
                        }
                      >
                        {carbonStore.ghgCategories.category?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Space>
                <Divider className={styles.customDivider} />
                <StyledTitle level={3}>Source Information</StyledTitle>
                <Space direction="vertical" className={styles.customBlock}>
                  <Select
                    showSearch
                    placeholder="Select a standard source"
                    className={styles.customField}
                    value={customFactorData.source}
                    onChange={(value) =>
                      handleChangeCustomFactorsData("source", value)
                    }
                  >
                    {categoriesData.source?.map((item) => (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  <div>Link to source providing this emissions factor</div>
                  <Input
                    addonBefore={
                      <Select
                        value={sourceProtocol}
                        onChange={(value) => setSourceProtocol(value)}
                        style={{ backgroundColor: "#625D75" }}
                      >
                        <Select.Option value="http://">http://</Select.Option>
                        <Select.Option value="https://">https://</Select.Option>
                      </Select>
                    }
                    placeholder="Type or paste a link here"
                    value={customFactorData.source_link}
                    onChange={(e) =>
                      handleChangeCustomFactorsData(
                        "source_link",
                        e.target.value
                      )
                    }
                  />
                </Space>
              </Space>
            </Form>
          </>
        ),
      },
      {
        number: "2",
        content: (
          <>
            <Form form={form} fields={[
              {
                name: ["factor"],
                value: customFactorData.factor ? customFactorData.factor.toString() : '',
              },
            ]}
            >
              <Space direction="vertical" className={styles.stepContent}>
                <StyledTitle level={3}>Quantitative Information</StyledTitle>
                <Space direction="vertical" className={styles.customBlock}>
                  <div>Input the numeric value of the factor</div>
                  <Form.Item
                    name="factor"
                    rules={[
                      {
                        required: true,
                        message: "Numeric value of the factor is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Type in a number"
                      onChange={(value) => {
                        if (value !== null) {
                          handleChangeCustomFactorsData("factor", value.toString())
                        }
                      }
                      }
                    />
                  </Form.Item>
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  <div>Select a GHG scientific method</div>
                  <Radio.Group
                    value={customFactorData.factor_calculation_method}
                    onChange={(e) =>
                      handleChangeCustomFactorsData(
                        "factor_calculation_method",
                        e.target.value
                      )
                    }
                  >
                    <Row>
                      <Radio value="ar4">AR4 (2007)</Radio>
                    </Row>
                    <Row>
                      <Radio value="ar5">AR5 (2013)</Radio>
                    </Row>
                    <Row>
                      <Radio value="other">Other</Radio>
                    </Row>
                  </Radio.Group>
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  {customFactorData?.unit ? (
                    <Select
                      showSearch
                      placeholder="Select a unit of measure"
                      className={styles.customField}
                      value={customFactorData.unit}
                      onChange={(value) =>
                        handleChangeCustomFactorsData("unit", value as string)
                      }
                    >
                      {carbonStore.units?.map((item) => (
                        <Select.Option value={item.unit} key={item.unit}>
                          {toJS(item.unit)}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Form.Item
                      name="unit of measure"
                      rules={[
                        {
                          required: true,
                          message: "Unit of measure is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a unit of measure"
                        className={styles.customField}
                        value={customFactorData.unit}
                        onChange={(value) =>
                          handleChangeCustomFactorsData("unit", value as string)
                        }
                      >
                        {carbonStore.units?.map((item) => (
                          <Select.Option value={item.unit} key={item.unit}>
                            {toJS(item.unit)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Space>
                <Space direction="vertical" className={styles.customBlock}>
                  <p>
                    Select relevant constituent gases and input a numeric
                    quantity
                  </p>
                  {Array.isArray(customFactorData.constituent_gases) && (
                    <ClickedCheckBoxGroup
                      data={customFactorData.constituent_gases}
                      onChange={handleChangeConstituentGasesData}
                    />
                  )}
                </Space>
              </Space>
            </Form>
          </>
        ),
      },
    ];

    return (
      <CommonModal
        visible={true}
        title={title}
        footer={
          <>
            {currentStep < steps.length - 1 && (
              <>
                <Button
                  className="margin-5"
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        setCurrentStep(currentStep + 1);
                      })
                      .catch(() => {
                        message.error("Required fields are missing");
                      });
                  }}
                  type="primary"
                >
                  Next <RightOutlined />
                </Button>
              </>
            )}
            {currentStep === steps.length - 1 && (
              <>
                <Button
                  className="margin-5"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  type="primary"
                >
                  <LeftOutlined /> Previous
                </Button>
                <Button
                  className="margin-5"
                  onClick={handleSaveData}
                  type="primary"
                >
                  Done
                </Button>
              </>
            )}
          </>
        }
        width="50%"
        onCancel={onCancel}
        centered
      >
        {contextHolder}
        <Row>
          <Col span={24}>
            <Row justify="start">
              <div
                className={`user-flow-steps-wrap ${styles.customFactorSteps}`}
              >
                <StyledSteps
                  size="small"
                  current={currentStep}
                  type="navigation"
                >
                  {steps.map((item) => (
                    <Step key={item.number} />
                  ))}
                </StyledSteps>
              </div>
              <p>Fill in information about the custom emissions factor</p>
            </Row>
          </Col>
        </Row>
        <FormWrapper>
          <div>{steps[currentStep].content}</div>
        </FormWrapper>
      </CommonModal>
    );
  }
);
