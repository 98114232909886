import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import moment from "moment";
import {
  message,
  Space,
  Typography,
  Select,
  Timeline,
  Spin,
  Alert,
  Divider,
} from "antd";
import { getFirstAndLastNameByUsername } from "../../../shared/commonFunctions";
import { StyledButton, StyledTitle } from "../../../shared/commonStyles";
import { YearMonthDay24HourFormat } from "../../../shared/constants";
import { COLORS } from "../../../shared/colors";
import { UndoOutlined } from "@ant-design/icons";

export const HistoryDataSectionNew = ({
  onChangeHistoryHandler,
  rowData,
  setVersionInfo,
  setLatestVersion,
  currentVersion,
}) => {
  const [historyData, setHistoryData] = useState(null);
  //const [selectedHistoryVersion, setSelectedHistoryVersion] = useState(null);
  const [loadingHistoryData, setLoadingHistoryData] = useState(false);

  useEffect(() => {
    onClickRequestHistory(rowData?.history);
  }, [rowData]);

  const onClickRequestHistory = async (historyData) => {
    try {
      setLoadingHistoryData(true);

      if (historyData?.length > 0) {
        const versionIncreasedHistoryData = await Promise.all(
          historyData.map(async (item) => {
            const firstLastName = await getFirstAndLastNameByUsername(
              item.username
            );
            return {
              ...item,
              first_last_name: firstLastName,
            };
          })
        );
        const latestHistorySavedData = versionIncreasedHistoryData.slice(-1)[0];
        //setSelectedHistoryVersion(latestHistorySavedData.version_number);
        latestHistorySavedData?.form_data &&
          onChangeHistoryHandler(latestHistorySavedData);
        //setSelectedHistoryData(latestHistorySavedData);
        setHistoryData(versionIncreasedHistoryData);
        setLatestVersion(latestHistorySavedData)
      }
    } catch (error) {
      console.log("Error while load history data", error);
      message.error("Error while load history data");
    } finally {
      setLoadingHistoryData(false);
    }
  };

  const getPreviousIndexHistoryData = (previousIndex) => {
    return (
      historyData && historyData.find((data, index) => index === previousIndex)
    );
  };

  const renderColorByStatus = (key) => {
    if (key === "open") {
      return "green";
    } else if (key === "pending") {
      return "orange";
    } else if (key === "escalated") {
      return "red";
    } else {
      return "darkgrey";
    }
  };

  const getHistoryDataByVersionId = (versionId) => {
    return (
      historyData &&
      historyData.find((item) => item.version_number === versionId)
    );
  };

  const disabledRequestHistory =
    Array.isArray(historyData) && historyData.length > 0;


  const onChangeHistoryVersion = (version) => {
    //setSelectedHistoryVersion(version);
    const historyVersionData = getHistoryDataByVersionId(version);
    onChangeHistoryHandler(historyVersionData);
    const createDate = historyVersionData?.create_timestamp?.$date
      ? moment(historyVersionData?.create_timestamp?.$date).format(
          YearMonthDay24HourFormat
        )
      : null;
    setVersionInfo(historyVersionData.version_number + " :" + createDate);
  };
  return (
    <div className="history-section">
      <StyledTitle level={3}>History</StyledTitle>
      <Divider style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }} />
      <Spin spinning={loadingHistoryData} disabled={disabledRequestHistory}>
        {historyData ? (
          <>
            <div className="flex-space-between-wrapper">
              {/* <div>            
                <AvatarUsername username={selectedHistoryData?.first_last_name} />
              </div> */}
            </div>
            <div className="data-form-history-timeline-section">
              <Timeline>
                {historyData.map((item, index) => {
                  console.log('historyData', historyData.length)
                  const createDate = item?.create_timestamp?.$date
                    ? moment(item?.create_timestamp?.$date).format(
                        YearMonthDay24HourFormat
                      )
                    : null;
                  const dotColor = renderColorByStatus(
                    item?.status?.toUpperCase()
                  );
                  const previousHistoryVersionData =
                    getPreviousIndexHistoryData(index - 1);
                  if (index === 0) {
                    return (
                      <>
                        {" "}
                        <Timeline.Item key={index} color={dotColor}>
                          <p>
                            <b>
                              {historyData.length-1 === index ? (
                                <>
                                  Current Version: {createDate}
                                </>
                              ) : (
                                <>Version {item.version_number} : {createDate}</>
                              )}                             
                              <StyledButton
                                bgcolor={COLORS.martinique}
                                bordercolor={COLORS.white}
                                type="custom"
                                className="HistoryButton"
                                icon={currentVersion === item.version_number ? "" : <UndoOutlined />}
                                disabled={currentVersion === item.version_number ? true : false}
                                onClick={() =>onChangeHistoryVersion(item.version_number)}
                                style={{float: 'right',padding: '5px 10px'}}
                                >
                                 {currentVersion === item.version_number ?"Currently Open": "Open Version"}
                                </StyledButton>
                            </b>
                          </p>
                          <p>{item.first_last_name} created this data</p>
                          <p>
                            {item.first_last_name} set status{" "}
                            <b>{item.user_status}</b>
                          </p>
                          <p>
                            {item.first_last_name}{" "}
                            {item.publish_status ? (
                              <b>published </b>
                            ) : (
                              <b>unpublished</b>
                            )}{" "}
                            data
                          </p>
                        </Timeline.Item>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Timeline.Item key={index} color={dotColor}>
                          <p>
                            <b>
                              {historyData.length-1 === index ? (
                                <>
                                  Current Version: {createDate}
                                </>
                              ) : (
                                <>Version {item.version_number} : {createDate}</>
                              )} 
                              
                              <StyledButton
                                bgcolor={COLORS.martinique}
                                bordercolor={COLORS.white}
                                type="custom"
                                className="HistoryButton"
                                icon={currentVersion === item.version_number ? "" : <UndoOutlined />}
                                disabled={currentVersion === item.version_number ? true : false}
                                onClick={() =>onChangeHistoryVersion(item.version_number)}
                                style={{float: 'right',padding: '5px 10px'}}
                              >
                                {currentVersion === item.version_number ?"Currently Open": "Open Version"}
                              </StyledButton>
                            </b>
                          </p>
                          <p>
                            {item.first_last_name} modified data with reason{" "}
                            <b>{item.modify_reason}</b>
                          </p>
                          <p>
                            {item.first_last_name} set status{" "}
                            <b>{previousHistoryVersionData?.user_status}</b> to{" "}
                            <b>{item.user_status}</b>
                          </p>
                          <p>
                            {item.first_last_name}{" "}
                            {item.publish_status ? (
                              <b>published </b>
                            ) : (
                              <b>unpublished</b>
                            )}{" "}
                            data
                          </p>
                        </Timeline.Item>
                      </>
                    );
                  }
                })}
              </Timeline>
            </div>
          </>
        ) : (
          <>
            {!loadingHistoryData && (
              <Alert
                className="customInfoAlert"
                message="No modification history found."
                type="info"
                showIcon
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};
