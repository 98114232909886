import { useEffect, useReducer } from "react";

export type StartAction = {
  type: "start";
};

export type FinishAction<T> = {
  type: "finish";
  value: T;
};

export type ErrorAction = {
  type: "error";
  error: any;
};

// dispatch types
export type DispatchTypes<T> = StartAction | FinishAction<T> | ErrorAction;

// async state
export type AsyncState<T> = {
  isLoading: boolean;
  result?: T;
  error?: any;
};

// async return
export type AsyncReturn<T> = [
  state: AsyncState<T>,
  run: (params?: any) => Promise<void>,
  setState: (value: T) => void
];

export type UseAsyncProps<T> = {
  fn: (params?: any) => Promise<T>;
};

export function useAsync<T>({ fn }: UseAsyncProps<T>): AsyncReturn<T> {
  const intitialState = { isLoading: false, hasError: false, value: undefined };

  const stateReducer = (
    _state: AsyncState<T>,
    action: DispatchTypes<T>
  ): AsyncState<T> => {
    switch (action.type) {
      case "start":
        return { isLoading: true } as AsyncState<T>;
      case "finish":
        return { isLoading: false, result: action.value } as AsyncState<T>;
      case "error":
        return { isLoading: false, error: action.error };
      default:
        return { isLoading: true } as AsyncState<T>;
    }
  };

  const [state, dispatch] = useReducer(stateReducer, intitialState);

  const setState = (value: T) => {
    dispatch({ type: "finish", value });
  };

  const run = async (params?: any) => {
    try {
      dispatch({ type: "start" });
      const value = await fn(params);
      dispatch({ type: "finish", value });
    } catch (error) {
      dispatch({
        type: "error",
        error: error as any,
      });
    }
  };

  return [{ ...state }, run, setState];
}

export type UseAsyncEffectParams<T> = {
  fn: () => Promise<T>;
  dependencies: any[];
};

// use async effect hook

export function useAsyncEffect<T>({
  fn,
  dependencies,
}: UseAsyncEffectParams<T>): AsyncReturn<T> {
  const [state, run, setState] = useAsync({ fn });

  useEffect(() => {
    run();
  }, dependencies);

  return [state, run, setState];
}
