import React, { useState } from "react";
import {
  Col,
  Divider,
  Form,
  InputNumber,
  Popover,
  Radio,
  Row,
  Space,
} from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import styles from "./AddManualEmissionsModal.module.scss";

interface ApproximateEmissionCalculatorProps {
  form: FormInstance<any>;
  onSubmit: (value: number) => void;
}

export const ApproximateEmissionCalculator = ({
  form,
  onSubmit,
}: ApproximateEmissionCalculatorProps) => {
  const [calculation, setCalculation] = useState("");

  const airMiles = useWatch("airMiles", form);
  const carMiles = useWatch("carMiles", form);
  const railMiles = useWatch("railMiles", form);

  const popoverContent =
    "Explanation about the calculation. Consequat in varius tincidunt dapibus consequat arcu. Arcu etiam porttitor pretium nunc.";

  const handleCalculate = () => {
    console.log("form.getFieldsValue()", form.getFieldsValue());
    const values = form.getFieldsValue();
    //? Here should be calculation formula
    const result = values.organizationSize;
    setCalculation(result); //! test value
    onSubmit(result);
  };

  return (
    <>
      <div className={styles.approximateEmissionHeader}>
        Calculate the approximate Business travel emission
      </div>
      <FormWrapper>
        <div className={styles.approximateEmissionLabel}>Organization size</div>
        <Form.Item name="organizationSize">
          <InputNumber
            className={styles.approximateEmissionInput}
            addonBefore="Num of emp"
            placeholder="0"
          />
        </Form.Item>

        <div className={styles.approximateEmissionSubtitle}>Air miles</div>
        <Divider className={styles.approximateEmissionDivider} />
        <Form.Item name="airMiles">
          <Radio.Group>
            <Radio value="averageNumbers">
              <Space>
                Average numbers
                <Popover
                  placement="top"
                  title="Average numbers"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
            <Radio value="miles">
              <Space>
                Air miles
                <Popover
                  placement="top"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Row gutter={8} justify="space-between">
          <Col span={12}>
            <div className={styles.approximateEmissionLabel}>
              {airMiles === "averageNumbers" ? (
                <> Average round trip domestic flights per employee</>
              ) : (
                <>Domestic air miles for the year</>
              )}
            </div>
            <Form.Item name="averageDomesticFlights">
              <InputNumber
                className={styles.approximateEmissionInput}
                addonBefore="Num"
                placeholder="0"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.approximateEmissionLabel}>
              {airMiles === "averageNumbers" ? (
                <>Average round trip international flights per employee</>
              ) : (
                <>International air miles for the year</>
              )}
            </div>
            <Form.Item name="averageInternationalFlights">
              <InputNumber
                className={styles.approximateEmissionInput}
                addonBefore="Num"
                placeholder="0"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className={styles.approximateEmissionSubtitle}>Car miles</div>
        <Divider className={styles.approximateEmissionDivider} />
        <Form.Item name="carMiles">
          <Radio.Group defaultValue="averageNumbers">
            <Radio value="averageNumbers">
              <Space>
                Average numbers
                <Popover
                  placement="top"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
            <Radio value="miles">
              <Space>
                Car miles
                <Popover
                  placement="top"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className={styles.approximateEmissionLabel}>
          {carMiles === "averageNumbers" ? (
            <> Average car trips (excluding commuting) per employee</>
          ) : (
            <>Car miles for the year</>
          )}
        </div>
        <Form.Item name="averageCarTrips">
          <InputNumber
            className={styles.approximateEmissionInput}
            addonBefore="Num"
            placeholder="0"
          />
        </Form.Item>

        <div className={styles.approximateEmissionSubtitle}>Rail miles</div>
        <Divider className={styles.approximateEmissionDivider} />
        <Form.Item name="railMiles">
          <Radio.Group defaultValue="averageNumbers">
            <Radio value="averageNumbers">
              <Space>
                Average numbers
                <Popover
                  placement="top"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
            <Radio value="miles">
              <Space>
                Rail miles
                <Popover
                  placement="top"
                  content={popoverContent}
                  className={styles.infoIcon}
                  overlayClassName="popoverContent"
                  arrowPointAtCenter
                  autoAdjustOverflow
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className={styles.approximateEmissionLabel}>
          {railMiles === "averageNumbers" ? (
            <> Average rail trips (excluding commuting) per employee</>
          ) : (
            <>Rail miles for the year</>
          )}
        </div>
        <Form.Item name="averageRailTrips">
          <InputNumber
            className={styles.approximateEmissionInput}
            addonBefore="Num"
            placeholder="0"
          />
        </Form.Item>

        <StyledButton onClick={handleCalculate}>Calculate</StyledButton>
        <span>
          {calculation && (
            <>
              Approximate business travel emission: <b>{calculation} MT CO2e</b>
            </>
          )}
        </span>
        <Divider className={styles.approximateEmissionDivider} />
      </FormWrapper>
    </>
  );
};
